import { FilterDescriptor } from '@progress/kendo-data-query';
import { UnitLookupConcise } from '../models/lookups';

export const filterUnitsByFacility = (
    facilityFilter: FilterDescriptor,
    units: UnitLookupConcise[] | undefined
): UnitLookupConcise[] => {
    const searchFacilityIds: number[] = (facilityFilter.value as number[]) ?? [];
    const filteredUnits: UnitLookupConcise[] =
        units && units.length > 0 ? units.filter((u) => searchFacilityIds.includes(u.facilityId)) : [];

    return filteredUnits;
};
