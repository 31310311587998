import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { LoadingTypes } from 'src/app/shared/models';

export const selectVendorAssociationsFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectFacilityAssociationVendors = createSelector(
    selectVendorAssociationsFeature,
    (state: AdminState) => ({
        data: state?.associationVendors?.vendors,
        total: state?.associationVendors?.filtered,
        nonFilteredTotal: state?.associationVendors?.total
    })
);

export const selectFacilityAssociationVendorsIsLoading = createSelector(
    selectVendorAssociationsFeature,
    (state: AdminState) => [state.associationVendors.loadingState].some((x) => x === LoadingTypes.LOADING)
);

export const selectFacilityAssociationVendorsError = createSelector(
    selectVendorAssociationsFeature,
    (facilitiesState: AdminState) => facilitiesState.associationVendors.error
);

export const selectFacilityAssociationVendorsGridSearchQuery = createSelector(
    selectVendorAssociationsFeature,
    (facilitiesState: AdminState) => facilitiesState.associationVendors.gridSearchQuery
);

export const selectStatesLookup = createSelector(
    selectVendorAssociationsFeature,
    (state: AdminState) => state.associationVendors.states
);
