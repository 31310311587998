import { LoadingTypes } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';
import { VendorSubmittalDetails } from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';

export interface SubmittalsDetailState {
    loading: LoadingTypes;
    error: any;
    details: VendorSubmittalDetails;
    stages: ListItem[];
    stageReasons: ListItem[];
    hasChanges: boolean;
    loadingSave: LoadingTypes;
}

export const getInitialSubmittalsDetailState = (): SubmittalsDetailState => ({
    loading: LoadingTypes.INIT,
    error: undefined,
    details: null,
    stages: [],
    stageReasons: [],
    hasChanges: false,
    loadingSave: LoadingTypes.INIT
});
