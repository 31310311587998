import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Unit } from 'src/app/clinical/models/unit.model';
import { UnitFacilityInformation } from 'src/app/clinical/models';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-unit-systems',
    templateUrl: './unit-systems.component.html',
    styleUrls: ['./unit-systems.component.scss']
})
export class UnitSystemsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    private _unitInfo: Unit;

    unitSystemsform: UntypedFormGroup;

    isLoading = true;

    @Input() canShowContact = false;
    @Input() isWorkForceDisruption = false;
    @Input() readOnly = false;
    chartingSystems: any[];

    @Input()
    set unitInfo(unitInfo: UnitFacilityInformation) {
        this.isLoading = true;
        this._unitInfo = unitInfo?.unit;
        this.chartingSystems = unitInfo?.chartingSystems;

        this.setFormValues(this._unitInfo);
        this.isLoading = false;
    }

    @Output() valueChanged = new EventEmitter<{ unit: Partial<Unit>; isValid: boolean }>();

    @Input() isPrinting: boolean = false;

    constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _store: Store) {
        super();

        this.unitSystemsform = this._formBuilder.group({
            medicationSystemPyxis: this._formBuilder.control(null),
            medicationSystemAccudose: this._formBuilder.control(null),
            medicationSystemOmnicell: this._formBuilder.control(null),
            communicationSBAR: this._formBuilder.control(null),
            communicationOther: this._formBuilder.control(null, [Validators.maxLength(100)]),
            medicationSystemOther: this._formBuilder.control(null, [Validators.maxLength(100)]),
            documentationSystem: this._formBuilder.control(null, [Validators.maxLength(250)])
        });
    }

    ngOnInit() {
        this.unitSystemsform.valueChanges.pipe(takeUntil(this.d$)).subscribe((valueChange) => {
            const model = this.getModel();

            if (!this.isLoading) {
                this.valueChanged.emit({ unit: model, isValid: this.unitSystemsform.valid });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.unitSystemsform, changes.readOnly.currentValue);
        }
    }

    setFormValues(unitInfo: Unit) {
        if (this.unitSystemsform && unitInfo) {
            this.unitSystemsform.get('medicationSystemPyxis').patchValue(unitInfo.medicationSystemPyxis);
            this.unitSystemsform.get('medicationSystemAccudose').patchValue(unitInfo.medicationSystemAccudose);
            this.unitSystemsform.get('medicationSystemOmnicell').patchValue(unitInfo.medicationSystemOmnicell);
            this.unitSystemsform.get('communicationSBAR').patchValue(unitInfo.communicationSBAR);
            this.unitSystemsform.get('communicationOther').patchValue(unitInfo.communicationOther);
            this.unitSystemsform.get('medicationSystemOther').patchValue(unitInfo.medicationSystemOther);
            this.unitSystemsform.get('documentationSystem').patchValue(unitInfo.documentationSystem);
        }
    }

    getModel(): Partial<Unit> {
        const _unit: Partial<Unit> = {};

        if (this.unitSystemsform) {
            _unit.medicationSystemPyxis = this.unitSystemsform.get('medicationSystemPyxis')?.value;
            _unit.medicationSystemAccudose = this.unitSystemsform.get('medicationSystemAccudose')?.value;
            _unit.medicationSystemOmnicell = this.unitSystemsform.get('medicationSystemOmnicell')?.value;
            _unit.communicationSBAR = this.unitSystemsform.get('communicationSBAR')?.value;
            _unit.communicationOther = this.unitSystemsform.get('communicationOther')?.value;
            _unit.medicationSystemOther = this.unitSystemsform.get('medicationSystemOther')?.value;
            _unit.documentationSystem = this.unitSystemsform.get('documentationSystem')?.value;
        }
        return _unit;
    }
}
