import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ISnackBarWithActionData } from 'src/app/shared/models/snack-bar.models';

@Component({
    selector: 'snack-bar',
    templateUrl: 'snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarWithActionData,
        public snackBarRef: MatSnackBarRef<SnackBarComponent>
    ) {}

    onAction() {
        this.snackBarRef.dismissWithAction();
    }
}
