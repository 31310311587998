import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, mapTo, switchMap, tap } from 'rxjs/operators';
import * as coreActions from '../actions';
import { isPendoInitialized } from 'src/app/core/state/index';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import { IdentityService } from 'src/app/shared/services/identity.service';

// a variable called pendo is created after the Pendo snippet loads in index.html
declare let pendo: any;
@Injectable()
export class CorePendoEffects {
    initPendo$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(coreActions.initPendo),
                concatLatestFrom(() => [this.store.select(isPendoInitialized)]),
                tap(([_, isPendoInitializedFlag]) => {
                    const username = this.authService.getUserName();

                    if (!username) {
                        this.store.dispatch(coreActions.initPendoFail());
                        return;
                    }

                    const ticket = this._identityService.ticket;

                    const visitor = {
                        id: username,
                        role: ticket.role,
                        userType: ticket.type,
                        fullName: ticket.fullname,
                        isSSO: ticket.pendoInfo?.isSSOUser,
                        roleName: ticket.pendoInfo?.roleName,
                        brand: ticket.pendoInfo?.brand,
                        system: ticket.pendoInfo?.system,
                        firstName: ticket.pendoInfo?.firstName,
                        lastName: ticket.pendoInfo?.lastName
                    };

                    if (isPendoInitializedFlag) {
                        pendo.identify({
                            visitor
                        });
                    } else {
                        pendo.initialize({
                            visitor
                        });

                        this.store.dispatch(coreActions.initPendoSuccess());
                    }
                })
            );
        },
        { dispatch: false }
    );

    trackPendo$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(coreActions.trackPendo),
                switchMap((action) =>
                    this.store.select(isPendoInitialized).pipe(
                        filter((isPendoInitialized) => isPendoInitialized),
                        mapTo(action)
                    )
                ),
                tap((action) => {
                    const eventName = action.event.name;
                    const metadata = Object.keys(action.event.metadata).reduce((data, k) => {
                        const prop = this.cleanPropertyName(k);
                        const value = action.event.metadata[k];
                        return {
                            ...data,
                            [prop]: this.prepareValue(value)
                        };
                    }, {});

                    pendo.track(eventName, metadata);
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly authService: AuthService,
        private readonly store: Store,
        private readonly _identityService: IdentityService
    ) {}

    private cleanPropertyName(name: string) {
        // Property names must uselowercase letters, numbers, or underscores only
        return name
            .replace(/([A-Z])/g, '_$1')
            .replace(/^_+/g, '')
            .toLowerCase()
            .replace(/[\s\n]/g, '_')
            .replace(/[^a-z0-9_]/g, '');
    }

    private prepareValue(value: any) {
        if (value instanceof Date) {
            return dayjs(value).format();
        }
        return value;
    }
}
