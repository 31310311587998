import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anyCannotBeRejected'
})
export class AnyCannotBeRejectedPipe implements PipeTransform {

  transform(value: any[]): boolean {
    return value.length === 0 || value.some(x => x.canUserReject === false);
  }

}
