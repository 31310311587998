import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isEmpty } from './is-empty';

export function requiredIfNotEmptyRelativeValidator(relative: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (relative.value) {
            return Validators.required(control) ? { required: true } : null;
        }
        return null;
    };
}

export function requiredIfNotEmptyGroupValidator(group: UntypedFormGroup): ValidationErrors | null {
    const hasValue = Object.keys(group.controls)
        .map((key) => group.get(key))
        .some((c) => c.value);

    for (const k in group.controls) {
        const control = group.get(k);

        if (hasValue && isEmpty(control.value)) {
            control.setErrors({ required: true });
        } else {
            control.updateValueAndValidity({ onlySelf: true });
        }
    }

    return null;
}
