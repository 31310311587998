import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators';
import { vendorBankActions } from 'src/app/admin/store/actions/vendor-banking.actions';
import { VendorBankingService } from 'src/app/admin/vendor-details/banking/vendor-banking.service';

@Injectable()
export class VendorBankingEffects {
    getVendorBanks$ = createEffect(() => this._actions$.pipe(
        ofType(vendorBankActions.loadVendorBanks),
        exhaustMap(() => {
            return this._vendorBankingService.getBanks().pipe(
                map((result) => vendorBankActions.loadVendorBanksSuccess({ banks: result })),
                catchError((error) => of(vendorBankActions.loadVendorBanksFailure({ error })))
            )
        })
    ))

    addCustomBank$ = createEffect(() => this._actions$.pipe(
        ofType(vendorBankActions.addCustomBank),
        concatMap((action) => {
            return this._vendorBankingService.addCustomBank(action.bankName).pipe(
                map((result) => vendorBankActions.addCustomBankSuccess({ bank: result })),
                catchError((error) => of(vendorBankActions.addCustomBankFailure({ error })))
            )
        })
    ))

    constructor(
        private readonly _actions$: Actions,
        private readonly _vendorBankingService: VendorBankingService
    ) { }
}
