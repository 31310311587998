import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, dashboardStoreKey } from 'src/app/dashboard-new/store/dashboard.state';

export const getPendingTimecardsTotalCount = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    state => state.pendingTimecards.totalCount
);

export const getPendingTimecardsAgingDays = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    state => state.pendingTimecards.agingDays
);

export const getPendingTimecardsWarning = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    state => {return state.pendingTimecards.agingCount == 0 ? '' : `${state.pendingTimecards.agingCount} timecards pending approval for over ${state.pendingTimecards.agingDays} days`}
);
