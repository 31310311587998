import { Directive, EmbeddedViewRef, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { VersionCheckerService } from '../services/version-checker.service';
/**
 * The version checker could be started in app.component, but would require injection
 * and app.component already has too many dependencies. As a directive that is avoided.
 */
@Directive({
  selector: '[ayaVersionChecker]',
})
export class VersionCheckerDirective extends UnsubscribeOnDestroy implements OnInit {
  view: EmbeddedViewRef<unknown>;

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
    private readonly versionChecker: VersionCheckerService
  ) { 
    super();
  }

  ngOnInit() {
    this.viewContainer.clear();
    this.versionChecker.timedVersionPoll.pipe(
      takeUntil(this.d$),
    ).subscribe(({ newVersion }) => {
      this.viewContainer.clear();
      if (newVersion) {
        this.view = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }
}
