import { SimpleChange, SimpleChanges } from '@angular/core';
import { map } from 'rxjs/operators';

export function mapSimpleChanges() {  
    return map((changes: SimpleChanges) => {
        return Object
            .keys(changes)
            .reduce((g, key) => {
                return {
                    ...g,
                    [key]: changes[key].currentValue
                }
            }, {});
    })
}

export function buildSimpleChanges(obj: Object): SimpleChanges {
    const changes: SimpleChanges = {};

    for (const key in obj) {
        changes[key] = new SimpleChange(null, obj[key], true);
    }

    return changes;
}
