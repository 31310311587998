import { createAction, props } from '@ngrx/store';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import { ResourceAdd } from 'src/app/shared/models/internal-pool/resource-add.model';
import { ResourceEdit } from 'src/app/shared/models/internal-pool/resource-edit.model';
import { FilesToUpload } from 'src/app/shared/models/attachment';

export const addResource = createAction(
    '[Add Resource] Add Resource',
    props<{
        resource: ResourceAdd;
        systemId: number;
        documents?: FilesToUpload[];
        reload?: boolean;
        isNyuSystem?: boolean;
    }>()
);
export const addResourceSuccess = createAction('[Add Resource] Add Resource Success', props<{ systemId: number }>());
export const addResourceFail = createAction('[Add Resource] Add Resource Fail', props<{ error: any }>());

export const updateResource = createAction(
    '[Update Resource] Update Resource',
    props<{
        resource: ResourceEdit;
        systemId: number;
        reload?: boolean;
        isNyuSystem?: boolean;
    }>()
);
export const updateResourceSuccess = createAction(
    '[Update Resource] Update Resource Success',
    props<{ resource: Resource; systemId: number }>()
);
export const updateResourceFail = createAction('[Update Resource] Update Resource Fail', props<{ error: any }>());
