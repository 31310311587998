import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
    VendorTagDependency,
    vendorTagValidatorKey
} from 'src/app/admin/vendor-details/profile/vendor-tags-validity/vendor-tags.validator';
import { VendorType } from 'src/app/shared/models/vendor-type.model';

@Component({
    selector: 'ayac-vendor-tags-validity',
    templateUrl: './vendor-tags-validity.component.html',
    styleUrls: ['./vendor-tags-validity.component.scss']
})
export class VendorTagsValidityComponent implements OnChanges, OnDestroy {
    @Input() control: UntypedFormControl;
    @Input() tags: VendorType[] = [];
    errorMessage: {
        tagName: string;
        dependencyNames: string[];
    }[];

    controlSubscription?: Subscription;

    constructor() {}

    ngOnDestroy(): void {
        this.controlSubscription?.unsubscribe();
    }

    ngOnChanges(): void {
        this.controlSubscription?.unsubscribe();
        this.updateErrorMessage();

        this.controlSubscription = this.control.statusChanges.subscribe(() => {
            this.updateErrorMessage();
        });
    }

    updateErrorMessage(): void {
        const errors: VendorTagDependency[] = this.control.errors?.[vendorTagValidatorKey] ?? [];
        this.errorMessage = errors.map((e) => {
            const tagName = this._getTagName(e.tagName);
            const dependencyNames = e.dependencies.map((d) => this._getTagName(d));

            return {
                tagName,
                dependencyNames
            };
        });
    }

    private _getTagName(vendorType: string): string {
        return this.tags.find((t) => t.type === vendorType)?.type ?? String(vendorType);
    }
}
