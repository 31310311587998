import { createAction, props } from '@ngrx/store';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ResourceAssignment } from '../../models';

export const loadPreviousAssignments = createAction(
    '[Internal Pool Resource Assignments] Load Previous Resource Assignments',
    props<{ id: number }>()
);
export const loadResourcePreviousAssignmentsSuccess = createAction(
    '[Internal Pool Resource Assignments] Load Previous Resource Assignments Success',
    props<{ assignments: ResourceAssignment[], total: number}>()
);
export const loadPreviousResourceAssignmentsFail = createAction(
    '[Internal Pool Resource Assignments] Load Resource Previous Assignments Fail',
    props<{ error: any }>()
);
export const loadCurrentResourceAssignments = createAction(
    '[Internal Pool Resource Assignments] Load Current Resource Assignments',
    props<{ id: number }>()
);
export const loadCurrentResourceAssignmentsSuccess = createAction(
    '[Internal Pool Resource Assignments] Load Current Resource Assignments Success',
    props<{ assignments: ResourceAssignment[], total: number }>()
);
export const loadCurrentResourceAssignmentsFail = createAction(
    '[Internal Pool Resource Assignments] Load Current Resource Assignments Fail',
    props<{ error: any }>()
);

export const setPreviousAssignmentsQuery = createAction(
    '[Internal Pool Resource Assignments] Set Previous Assignments Search Query',
    props<{ query: Partial<GridSearchQuery> }>()
);

export const setCurrentAssignmentsQuery = createAction(
    '[Internal Pool Resource Assignments] Set Current Assignments Search Query',
    props<{ query: Partial<GridSearchQuery> }>()
);

export const clearResourceAssignments = createAction(
    '[Internal Pool Resource Assignments] Load Resource Assignments Clear'
);
