import { createAction, props } from '@ngrx/store';
import { ShiftsCount } from 'src/app/dashboard-new/models';

export const loadShiftsCount = createAction(
    '[Dashboard] Load Shifts Count'
);

export const loadShiftsSuccess = createAction(
    '[Dashboard] Load Shifts Success',
    props<{ shifts: ShiftsCount }>()
);

export const loadShiftsFail = createAction(
    '[Dashboard] Load Shifts Fail',
    props<{ error: any}>()
);
