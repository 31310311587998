import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as actions from '../invoices.actions';

export interface ApproveDraftInvoiceState {
    loading: LoadingTypes;
    error: string;
}

const initialState: ApproveDraftInvoiceState = {
    loading: LoadingTypes.INIT,
    error: null
};

export function approveDraftInvoicesReducer(state: ApproveDraftInvoiceState, action: Action) {
    return createReducer(
        initialState,
        on(actions.approveDraftInvoices, (state) => ({
            ...state,
            error: null,
            loading: LoadingTypes.LOADING
        })),
        on(actions.approveDraftInvoicesSuccess, (state) => ({
            ...state,
            error: null,
            loading: LoadingTypes.LOADED
        })),
        on(actions.approveDraftInvoicesFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        })),
        on(actions.approveDraftInvoicesReset, (state) => ({
            ...state,
            ...initialState
        }))
    )(state, action);
}

export function reducer(state: ApproveDraftInvoiceState | undefined, action: Action) {
    return approveDraftInvoicesReducer(state, action);
}
