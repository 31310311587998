export const VendorExpensesUrls = {
    VENDOR_EXPENSE_LIST: '/AyaConnect/vendors/expenses/search',
    VENDOR_EXPENSE_DETAILS: '/AyaConnect/vendors/expenses/{expenseId}/detail/',
    VENDOR_EXPENSE_DELETE: '/AyaConnect/vendors/expenses/{expenseId}/delete-expense',
    CANDIDATE_EXPENSE_HISTORY: '/AyaConnect/vendors/expenses/{expenseId}/history/',
    ADD_EXPENSE: '/AyaConnect/vendors/expenses/add',
    EDIT_EXPENSE: '/AyaConnect/vendors/expenses/{expenseId}',
    GET_EXPENSE_TIMECARDID_AND_UNITID: '/AyaConnect/vendors/expenses/timecard',
    GET_PROVIDERS: '/AyaConnect/vendors/expenses/providers',
    GET_PROVIDER_FACILITIES: '/AyaConnect/vendors/expenses/{providerId}/facilities',
    GET_VENDOR_FACILITIES: '/AyaConnect/vendors/expenses/facilities',
    GET_EXPENSE_TYPES: '/AyaConnect/expenses/expense-types'
} as const;

