import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { vendorNotesActions } from 'src/app/admin/store/actions/vendor-notes.actions';
import { selectVendorNotes, selectVendorNoteTypes } from 'src/app/admin/store/selectors/vendor-notes.selectors';
import { VendorNoteType } from 'src/app/admin/vendor-details/models/vendor-note-type.model';
import { VendorNote } from 'src/app/admin/vendor-details/models/vendor-note.model';
import { IdentityService } from 'src/app/shared/services/identity.service';
import * as utc from 'dayjs/plugin/utc';
import * as dayjs from 'dayjs';

dayjs.extend(utc);

@Component({
    selector: 'ayac-vendor-notes',
    templateUrl: './vendor-notes.component.html',
    styleUrls: ['./vendor-notes.component.scss']
})
export class VendorNotesComponent implements OnInit, OnDestroy {
    get noteText(): string {
        return this.noteForm.get('noteText').value?.toString() ?? '';
    }

    @Input()
    set vendorId(id: number) {
        if (id) {
            this._vendorId = id;
            this._store.dispatch(vendorNotesActions.loadVendorNotesByVendorId({ vendorId: id }));
        }
    }

    noteForm: UntypedFormGroup;
    notes$: Observable<VendorNote[]>;
    noteTypes$: Observable<VendorNoteType[]>;
    smallerScreen = false;

    private _vendorId = 0;

    constructor(
        private readonly _store: Store,
        private readonly _builder: UntypedFormBuilder,
        private readonly _identityService: IdentityService,
        private readonly _breakpointObserver: BreakpointObserver
    ) {}

    ngOnInit(): void {
        this._breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((value) => {
            if (value.breakpoints[Breakpoints.Large]) {
                this.smallerScreen = true;
            } else {
                this.smallerScreen = false;
            }
        });

        this.noteForm = this._builder.group({
            noteText: this._builder.control('', [Validators.required, Validators.maxLength(5000)]),
            noteType: this._builder.control('', [Validators.required])
        });

        this.notes$ = this._store.select(selectVendorNotes);

        this.noteTypes$ = this._store.select(selectVendorNoteTypes);

        this._store.dispatch(vendorNotesActions.loadVendorNoteTypes());
    }

    ngOnDestroy(): void {
        this._store.dispatch(vendorNotesActions.resetVendorNotes());
    }

    addNote(): void {
        const newNote: Partial<VendorNote> = {
            enteredBy: this._identityService.userId,
            enteredOn: dayjs().utc(true).toISOString(),
            noteDescription: this.noteForm.get('noteText').value,
            noteTypeId: Number(this.noteForm.get('noteType').value),
            vendorId: this._vendorId
        };

        this._store.dispatch(vendorNotesActions.addNote({ note: newNote, vendorId: this._vendorId }));
        this.noteForm.reset();
    }

    vendorNoteTrackBy(index: number, note: VendorNote): number {
        return note.id;
    }
}
