/**angular */
import { Injectable } from '@angular/core';

/**ngrx and rxjs */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap, switchMap } from 'rxjs/operators';

/**local */
import * as actions from '../actions/vendor-profile-certifications.actions';
import { VendorProfileCertificationsService } from 'src/app/admin/vendor-details/certifications/services/vendor-profile-certifications.service';
import { SubmittalsBaseEffect } from "src/app/submittals/store/effects/submittals-base.effect";
import { ToasterService } from "src/app/core/services";
import { formatDate } from '@angular/common';
import { downloadBlob } from 'src/app/core/utils';

/**shared */

@Injectable()
export class VendorProfileCertificationsEffects extends SubmittalsBaseEffect {
    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store,
        private readonly _vendorProfileCertificationsService: VendorProfileCertificationsService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    getVendorProfileCertifications$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadVendorProfileCertifications, actions.updateVendorProfileCertificationsSuccess),
            exhaustMap((x) => {
                return this._vendorProfileCertificationsService.getCertificationsByVendorId(x.vendorId).pipe(
                    map((result) =>
                        actions.loadVendorProfileCertificationsSuccess({ response: result, vendorId: x.vendorId })
                    ),
                    catchError((error) => of(actions.loadVendorProfileCertificationsFailure({ error })))
                );
            })
        )
    );

    getVendorProfileMetadata$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadVendorProfileMetadata),
            exhaustMap(() => {
                return this._vendorProfileCertificationsService.getCertificationsMetadata().pipe(
                    tap((result) =>
                        result.certifications.smallBusiness.sort((a, b) =>
                            a?.name.toLowerCase() < b?.name.toLowerCase() ? 1 : -1
                        )
                    ),
                    tap((result) =>
                        result.certifications.smallBusiness.sort((a, b) =>
                            a?.category?.name.toLowerCase() > b?.category?.name.toLowerCase() ? 1 : -1
                        )
                    ),
                    map((result) => actions.loadVendorProfileMetadataSuccess({ response: result })),
                    catchError((error) => of(actions.loadVendorProfileMetadataFailure({ error })))
                );
            })
        )
    );

    updateVendorProfileCertifications$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.updateVendorProfileCertifications),
            exhaustMap((action) => {
                return this._vendorProfileCertificationsService
                    .updateVendorCertifications(action.certificationRequest)
                    .pipe(
                        map(() => actions.updateVendorProfileCertificationsSuccess({ vendorId: action.certificationRequest.vendorId})),
                        catchError((error) => of(actions.updateVendorProfileCertificationsFailure({ error })))
                    );
            })
        )
    );

    downloadApplication$ = createEffect(() => this._actions$.pipe(
        ofType(actions.downloadCertificationFile),
        mergeMap(action => this._vendorProfileCertificationsService
            .downloadFile(action.vendorId, action.fileId).pipe(
                map(response => actions.downloadCertificationFileSuccess({payload: response})),
                catchError(error => of(actions.downloadCertificationFileFailure({error: error}))),
            )
        )
    ));

    downloadApplicationSuccess$ = createEffect(() => this._actions$.pipe(
            ofType(actions.downloadCertificationFileSuccess),
            map((action) => {
                this.saveFile(action.payload);
            })
        ),
        { dispatch: false }
    );

    exportVendorCertifications$ = createEffect((): any =>
        this._actions$.pipe(
            ofType(actions.exportVendorCertifications),
            switchMap((action) => {
                return this._vendorProfileCertificationsService.exportVendorCertifications(action.vendorId).pipe(
                    tap((blob) => {
                        const formattedDate = formatDate(new Date(), 'yyyy-MM-dd-HH_mm', 'en-US');
                        downloadBlob(blob, `${action.vendorName} Diversity Certifications - ${formattedDate}.xlsx`);
                    }),
                    map((result) => actions.exportVendorCertificationsSuccess({ result })),
                    catchError((error) => of(actions.exportVendorCertificationsFail({ error })))
                );
            })
        )
    );
}
