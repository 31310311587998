export enum CandidateOfferStatus {
    Pending = 1,
    APNQueue = 2,
    APNReview = 3,
    VendorReview = 4,
    ChangesRequested = 5,
    Accepted = 6,
    ContractRequested = 7,
    ContractChange = 8
}
