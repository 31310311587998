import { Action, createReducer, on } from '@ngrx/store';
import { systemActions } from 'src/app/systems/store';
import { LoadingTypes } from 'src/app/shared/models';
import { FeatureSettingCategory } from 'src/app/shared/feature-settings';

export interface SystemSettingsState {
    systemSettings: FeatureSettingCategory[],
    loadingState: LoadingTypes
}

export const initialSystemSettingsState = {
    systemSettings: [],
    loadingState: LoadingTypes.INIT
}

export function systemSettingsReducer(state: SystemSettingsState | undefined, action: Action) {
    return createReducer(
        initialSystemSettingsState,

        on(systemActions.loadSystemSettings,
            (): SystemSettingsState => ({
                ...state,
                loadingState: LoadingTypes.LOADING
            })),

        on(systemActions.loadSystemSettingsSuccess,
            (state, action): SystemSettingsState => ({
                ...state,
                systemSettings: action.settings,
                loadingState: LoadingTypes.LOADED
            })),

        on(systemActions.loadSystemSettingsFailed,
            (state): SystemSettingsState => ({
                ...state,
                loadingState: LoadingTypes.FAIL
            })),

        on(systemActions.updateSystemSettings,
            (state): SystemSettingsState => ({
                ...state,
                loadingState: LoadingTypes.LOADING
            })),
        on(systemActions.setSystemSetting,
            (state, action): SystemSettingsState => ({
                ...state,
                systemSettings: state.systemSettings.map(group => {
                    return {
                        ...group,
                        features: group.features.map(setting => {
                            if (setting.featureId === action.setting.featureId) {
                                return {
                                    ...setting,
                                    ...action.setting
                                }
                            }
                            return setting;
                        })
                    }
                })
            })),

        on(systemActions.updateSystemSettingsSuccess,
            (state): SystemSettingsState => ({
                ...state,
                loadingState: LoadingTypes.LOADED
            })),

        on(systemActions.updateSystemSettingsFailed,
            (state): SystemSettingsState => ({
                ...state,
                loadingState: LoadingTypes.FAIL
            })),

        on(systemActions.revertUpdateSystemSetting,
            (state): SystemSettingsState => ({
                ...state,
                loadingState: LoadingTypes.LOADED
            })),

        on(systemActions.showConfirmationDisableTearing,
            (state): SystemSettingsState => ({
                ...state,
                loadingState: LoadingTypes.LOADING
            })),
        

        on(systemActions.clearSystemSettings,
            (state): SystemSettingsState => ({
                ...initialSystemSettingsState
            }))

    )(state, action);
}
