import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';

export enum ToasterStatus {
    success = 'success',
    fail = 'fail',
    info = 'info'
}

@Injectable()
export class ToasterService {
    constructor(private readonly snackBar: MatSnackBar) {}

    open(message, action, status: ToasterStatus = ToasterStatus.info, additionalConfigs = {}) {
        let options = {
            panelClass: ['toaster', `toaster-${status}`]
        };
        options = additionalConfigs ? Object.assign(options, additionalConfigs) : options;
        const snackBarRef = this.snackBar.open(message, action, options);

        return snackBarRef;
    }

    openCustomToastWithDismiss(message, action, status: ToasterStatus = ToasterStatus.info, additionalConfigs) {
        return this.openCustom(message, action, status, additionalConfigs)
            .afterDismissed()
            .pipe(switchMap(({ dismissedByAction }) => (dismissedByAction ? of(dismissedByAction) : of())));
    }

    success(message: string, action?: string) {
        return this.openSimple(message, action, ToasterStatus.success);
    }

    fail(message: string, action?: string) {
        return this.openSimple(message, action, ToasterStatus.fail);
    }

    info(message: string, action?: string) {
        return this.openSimple(message, action, ToasterStatus.info);
    }

    private openCustom(message, action, status: ToasterStatus = ToasterStatus.info, additionalConfigs = {}) {
        const options = {
            panelClass: ['toaster', `toaster-${status}`],
            additionalConfigs: null
        };

        options.additionalConfigs = additionalConfigs ? Object.assign(options, additionalConfigs) : options;
        const snackBarRef = this.snackBar.open(message, '', options);

        return snackBarRef;
    }

    private openSimple(message, action, status: ToasterStatus = ToasterStatus.info) {
        return this.open(message, action, status)
            .afterDismissed()
            .pipe(switchMap(({ dismissedByAction }) => (dismissedByAction ? of(dismissedByAction) : of())));
    }
}
