import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/shift-release-settings.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { ReleaseGroupsState, getInitialReleaseGroupsState } from 'src/app/facilities/store/state/release-groups.state';

export function shiftReleaseGroupsReducers(state: ReleaseGroupsState | undefined, action: Action) {
    return createReducer(
        getInitialReleaseGroupsState(),
        on(actions.loadShiftReleaseSettingsPermissions,
            (state): ReleaseGroupsState =>
            ({
                ...state,
                releaseGroupsPermissionsLoadingState: LoadingTypes.LOADING
            })),

        on(actions.loadShiftReleaseSettingsPermissionsSuccess,
            (state, action): ReleaseGroupsState =>
            ({
                ...state,
                releaseGroupsPermissionsLoadingState: LoadingTypes.LOADED,
                releaseGroupsPermissions: action.releaseGroupsPermissions
            })),

        on(actions.loadShiftReleaseSettingsPermissionsFailure,
            (state): ReleaseGroupsState =>
            ({
                ...state,
                releaseGroupsPermissionsLoadingState: LoadingTypes.FAIL
            })),

        on(actions.loadShiftReleaseGroups,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.LOADING,
                releaseGroupVendorsLoadingState: LoadingTypes.INIT
            })),

        on(actions.loadShiftReleaseGroupsSuccess,
            (state, action): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.LOADED,
                releaseGroups: action.releaseGroups
            })),

        on(actions.loadShiftReleaseGroupsFailure,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.FAIL
            })),

        on(actions.deleteShiftReleaseGroup,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.LOADING
            })),

        on(actions.deleteShiftReleaseGroupSuccess,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroups: [],
                releaseGroupsLoadingState: LoadingTypes.LOADED
            })),

        on(actions.deleteShiftReleaseGroupFailure,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.LOADED
            })),

        on(actions.loadShiftReleaseGroupVendors,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupVendorsLoadingState: LoadingTypes.LOADING
            })),

        on(actions.loadShiftReleaseGroupVendorsSuccess,
            (state, action): ReleaseGroupsState => ({
                ...state,
                releaseGroupVendorsLoadingState: LoadingTypes.LOADED,
                releaseGroupVendors: action.vendors
            })),

        on(actions.loadShiftReleaseGroupVendorsFailure,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupVendorsLoadingState: LoadingTypes.FAIL
            })),

        on(actions.saveShiftReleaseGroup,
            (state): ReleaseGroupsState => ({
                ...state,
                saveReleaseGroupLoadingState: LoadingTypes.LOADING
            })),

        on(actions.saveShiftReleaseGroupSuccess,
            (state): ReleaseGroupsState => ({
                ...state, releaseGroupVendors: [],
                saveReleaseGroupLoadingState: LoadingTypes.LOADED
            })),

        on(actions.saveShiftReleaseGroupFailure,
            (state): ReleaseGroupsState => ({
                ...state,
                saveReleaseGroupLoadingState: LoadingTypes.FAIL
            })),

        on(actions.copyShiftReleaseSettingsFromFacility, actions.copyShiftReleaseSettingsToFacilities,
            (state) => ({
                ...state,
                copySettingsLoadingState: LoadingTypes.LOADING
            })),

        on(actions.copyShiftReleaseSettingsFromFacilitySuccess, actions.copyShiftReleaseSettingsToFacilitiesSuccess,
            (state) => ({
                ...state,
                copySettingsLoadingState: LoadingTypes.LOADED
            })),

        on(actions.copyShiftReleaseSettingsFromFacilityFailure, actions.copyShiftReleaseSettingsToFacilitiesFailure,
            (state) => ({
                ...state,
                copySettingsLoadingState: LoadingTypes.FAIL
            })),

    )(state, action);
}
