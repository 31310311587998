import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import { BackgroundHistoryFormComponent } from 'src/app/shared/forms/background-history-form/background-history-form.component';
import { CandidateProfileFormComponent } from 'src/app/shared/forms/candidate-profile-form/candidate-profile-form.component';
import { CertificationsFormComponent } from 'src/app/shared/forms/certifications-form/certifications-form.component';
import { ExpertiseFormComponent } from 'src/app/shared/forms/expertise-form/expertise-form.component';
import { LicensesFormComponent } from 'src/app/shared/forms/licenses-form/licenses-form.component';
import { PhoneFormComponent } from 'src/app/shared/forms/phone-form/phone-form.component';
import {
    BackgroundHistory,
    Candidate,
    CandidateProfileTabDetails,
    Certification,
    Expertise,
    Phone,
    StateLicense
} from 'src/app/shared/models/candidate';
import { CandidateService } from 'src/app/shared/services/candidate.service';
import { VendorContact } from 'src/app/shared/models/vendor-contact.model';
import { isEmptyOrSpaces } from 'src/app/shared/utilities/is-empty';
import { Store } from '@ngrx/store';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors';
import { LineOfBusinessEnum } from 'src/app/shared/models/facilities/enums/line-of-business.enum';

@Component({
    selector: 'ayac-profile-tab',
    templateUrl: './profile-tab.component.html',
    styleUrls: ['./profile-tab.component.scss']
})
export class ProfileTabComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges, OnDestroy {
    @ViewChild(CandidateProfileFormComponent, { static: false })
    candidateProfileForm: CandidateProfileFormComponent;

    @ViewChild(PhoneFormComponent, { static: false })
    phonesForm: PhoneFormComponent;

    @ViewChild(ExpertiseFormComponent, { static: false })
    expertiseForm: ExpertiseFormComponent;

    @ViewChild(CertificationsFormComponent, { static: false })
    certificationsForm: CertificationsFormComponent;

    @ViewChild(BackgroundHistoryFormComponent, { static: false })
    backgroundHistoryForm: BackgroundHistoryFormComponent;

    @ViewChild(LicensesFormComponent, { static: false })
    licensesForm: LicensesFormComponent;

    @Output() valueChanged = new EventEmitter<CandidateProfileTabDetails>();

    @Input() candidateId?: number;
    @Input() jobId?: number;
    @Input() saveCalled?: EventEmitter<void>;
    @Input() vendorId: number;
    @Output() onIsSavingChanged = new EventEmitter<boolean>();
    @Output() onSaveSuccess = new EventEmitter<{
        candidateId: number;
        isNew: boolean;
    }>();

    @Output() onSaveError = new EventEmitter<void>();
    @Output() onIsFormValidChanged = new EventEmitter<boolean>(true);
    @Output() onLoadCandidateSuccess = new EventEmitter<Candidate>();
    @Output() showNpiNotClearedBanner = new EventEmitter<boolean>();

    form: UntypedFormGroup;
    candidate?: Candidate;
    additionalValidation = true;
    featureFlag = FeatureFlag;
    certifications$!: Observable<ListItem[]>;
    vendorContacts$!: Observable<VendorContact[]>;
    phoneTypes$: Observable<ListItem[]>;
    hasAccessShiftsApp$: Observable<boolean>;
    npiRequired = false;
    isNpiValid = true;
    otherCandidateInfo$: Observable<any>;
    userChangedNpi = false;
    phoneInfoUpdated = false;
    private _isNew = true;
    specialtyInfoUpdated = false;
    certificationsInfoUpdated = false;
    backgroundInfoUpdated = false;
    licensureInfoUpdated = false;
    callEndpointsRelatedToUpdatedSectionsFlag = false;
    avoidRecreatingPhoneRecordsDuringVendorCandidateUpdate = false;
    setVendorCandidateNpiFromFormControl = false;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _candidateService: CandidateService,
        private readonly _store: Store,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
        this.form = this._formBuilder.group({});
    }

    get isNew(): boolean {
        return this._isNew;
    }

    private saveCalledSubscription: Subscription;

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.candidateId?.currentValue) {
            return;
        }

        if (changes.candidateId) {
            this._isNew = !changes.candidateId.currentValue || changes.candidateId.currentValue <= 0;
        }

        forkJoin({
            candidate: this.isNew
                ? of(null as Candidate)
                : this._candidateService.getVendorCandidate(changes.candidateId.currentValue),
            phones: this.isNew ? of([]) : this._candidateService.getPhones(changes.candidateId.currentValue),
            expertiseList: this.isNew
                ? of([])
                : this._candidateService.getCandidateExpertiseList(changes.candidateId.currentValue),
            certificationList: this.isNew
                ? of([])
                : this._candidateService.getCandidateCertifications(changes.candidateId.currentValue),
            backgroundHistory: this._candidateService.getBackgroundHistory(changes.candidateId.currentValue),
            stateLicenses: this.isNew
                ? of([])
                : this._candidateService.getStateLicenses(changes.candidateId.currentValue)
        })
            .pipe(first())
            .subscribe((result) => {
                this.candidate = result.candidate ?? { ...result.candidate, vendorId: this.vendorId };

                this.onLoadCandidateSuccess.emit(this.candidate);
                this.populate(
                    result.candidate,
                    result.phones,
                    result.expertiseList,
                    result.certificationList,
                    result.backgroundHistory,
                    result.stateLicenses
                );
            });

        if (this.candidateId > 0) {
            this._store.dispatch(actions.loadVendorCandidateShiftsApp({ candidateId: this.candidateId }));
        }
    }

    ngOnInit(): void {
        this._ldFeatureManager
            .isEnabled(FeatureFlag.UpdateCandidateCallEndpointsRelatedToUpdatedSections)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.callEndpointsRelatedToUpdatedSectionsFlag = flagIsEnabled;
            });

        this._ldFeatureManager
            .isEnabled(FeatureFlag.AvoidRecreatingPhoneRecordsDuringVendorCandidateUpdate)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.avoidRecreatingPhoneRecordsDuringVendorCandidateUpdate = flagIsEnabled;
            });

        this._ldFeatureManager
            .isEnabled(FeatureFlag.SetVendorCandidateNpiFromFormControl)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.setVendorCandidateNpiFromFormControl = flagIsEnabled;
            });

        if (this.jobId) {
            this._store.dispatch(actions.loadSubmittalJobDetails({ jobId: this.jobId }));
        }

        this.phoneTypes$ = this._candidateService.getPhoneTypes();

        this.form.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => {
            const isNpiValid = this.licensesForm?.npiFormControl?.valid;
            this.onIsFormValidChanged.emit(this.form.valid && isNpiValid);
        });

        if (!this.saveCalledSubscription && this.saveCalled) {
            this.saveCalledSubscription = this.saveCalled
                ?.pipe(
                    tap(() => this.onIsSavingChanged.emit(true)),
                    switchMap(() => {
                        const candidate = this.candidateProfileForm.getCandidateForUpdate();
                        candidate.professionId = this.expertiseForm.getSelectedProfession();
                        candidate.npi = this.setVendorCandidateNpiFromFormControl
                            ? this.licensesForm?.npiFormControl?.value
                            : this.licensesForm.npiValue;

                        if (this.isNew) {
                            candidate.vendorId = this.vendorId;
                        } else {
                            candidate.id = this.candidateId;
                        }

                        return this.isNew
                            ? this._candidateService.createCandidate(candidate)
                            : this._candidateService.updateCandidate(this.candidateId, candidate);
                    }),
                    switchMap((savedCandidate) => {
                        this.candidateProfileForm.clearStoredRto();
                        return forkJoin({
                            savePhonesResult: this.phoneInfoUpdated
                                ? this._candidateService.savePhones(savedCandidate.id, this.phonesForm.getPhones())
                                : of(this.phonesForm.getPhones()),
                            expertiseSaveResult:
                                !this.callEndpointsRelatedToUpdatedSectionsFlag || this.specialtyInfoUpdated
                                    ? this._candidateService.saveExpertise(
                                          savedCandidate.id,
                                          this.expertiseForm.getExpertiseList()
                                      )
                                    : of(this.expertiseForm.getExpertiseList()),
                            saveCertificationsResult:
                                !this.callEndpointsRelatedToUpdatedSectionsFlag || this.certificationsInfoUpdated
                                    ? this._candidateService.saveCertifications(
                                          savedCandidate.id,
                                          this.certificationsForm.getCertifications()
                                      )
                                    : of(this.certificationsForm.getCertifications()),
                            saveBackgroundHistoryResult:
                                !this.callEndpointsRelatedToUpdatedSectionsFlag || this.backgroundInfoUpdated
                                    ? this._candidateService.saveBackgroundHistory(
                                          savedCandidate.id,
                                          this.backgroundHistoryForm.getBackgroundHistory()
                                      )
                                    : of(this.backgroundHistoryForm.getBackgroundHistory()),
                            saveLicenseResult:
                                !this.callEndpointsRelatedToUpdatedSectionsFlag || this.licensureInfoUpdated
                                    ? this._candidateService.saveLicenses(
                                          savedCandidate.id,
                                          this.licensesForm.getLicenses()
                                      )
                                    : of(this.licensesForm.getLicenses()),
                            savedCandidate: of(savedCandidate)
                        });
                    }),
                    takeUntil(this.d$)
                )
                .subscribe(
                    (saveResult) => {
                        this.onIsSavingChanged.emit(false);
                        this.candidateId = saveResult.savedCandidate.id;
                        this.phoneInfoUpdated = false;
                        this.specialtyInfoUpdated = false;
                        this.certificationsInfoUpdated = false;
                        this.backgroundInfoUpdated = false;
                        this.licensureInfoUpdated = false;
                        if (this.avoidRecreatingPhoneRecordsDuringVendorCandidateUpdate) {
                            this.phonesForm.update(saveResult.savePhonesResult);
                        }
                        this.onSaveSuccess.emit({
                            candidateId: this.candidateId,
                            isNew: this.isNew
                        });
                    },
                    (error: unknown) => {
                        this.onSaveError.emit();
                    }
                );
        }

        this.certifications$ = this._candidateService.getCertifications();
        this.vendorContacts$ = this._candidateService.getVendorContacts().pipe(
            map((listItems) => {
                const vendorContacts: VendorContact[] = [];
                listItems.forEach((vendorContactListItem) => {
                    if (isEmptyOrSpaces(vendorContactListItem?.name)) {
                        return;
                    }
                    vendorContacts.push({
                        id: vendorContactListItem.id,
                        firstName: vendorContactListItem.name,
                        vendorId: this.vendorId
                    } as VendorContact);
                });
                return vendorContacts;
            })
        );
        this.hasAccessShiftsApp$ = this._store.select(selectors.selectVendorCandidateHasAccessShiftsApp);
        this._store
            .select(selectors.selectHospitalType)
            .pipe(takeUntil(this.d$))
            .subscribe((hospitalType: number) => {
                if (hospitalType) {
                    this.npiRequired = hospitalType === LineOfBusinessEnum.Locums ? true : false;
                }
            });
    }

    ngOnDestroy(): void {
        if (this.saveCalledSubscription) {
            this.saveCalledSubscription.unsubscribe();
        }
        this._store.dispatch(actions.clearSubmittalDetails());
        this._isNew = false;
    }

    populate(
        candidate: Candidate,
        phones: Phone[],
        expertiseList: Expertise[],
        certificationList: Certification[],
        backgroundHistory: BackgroundHistory[],
        licenses: StateLicense[]
    ): void {
        this.candidateProfileForm.populate(candidate);
        this.phonesForm.populate(phones, true);
        this.expertiseForm.populate(candidate?.professionId, expertiseList);
        this.certificationsForm.populate(certificationList);
        this.backgroundHistoryForm.populate(backgroundHistory);
        this.licensesForm.populate(licenses, candidate?.npi);
    }

    onPhoneValueChanged(): void {
        this.phoneInfoUpdated = true;
        this.onValueChanged();
    }

    onSpecialtyValueChanged(): void {
        this.specialtyInfoUpdated = true;
        this.onValueChanged();
    }

    onCertificationsValueChanged(): void {
        this.certificationsInfoUpdated = true;
        this.onValueChanged();
    }

    onBackgroundValueChanged(): void {
        this.backgroundInfoUpdated = true;
        this.onValueChanged();
    }

    onLicensureValueChanged(): void {
        this.licensureInfoUpdated = true;
        this.onValueChanged();
    }

    onValueChanged(): void {
        this.valueChanged.emit(this.getModel());
    }

    onNpiValidityChanged(validity: boolean) {
        this.isNpiValid = validity;
        this.onIsFormValidChanged.emit(this.form.valid && this.isNpiValid);
    }

    npiNameNotClearedChanged(notCleared: boolean) {
        this.showNpiNotClearedBanner.emit(notCleared);
    }

    getModel(): CandidateProfileTabDetails {
        return {
            candidate: this.candidateProfileForm.getCandidate(),
            phones: this.phonesForm.getPhones(),
            expertise: this.expertiseForm.getExpertiseList(),
            certifications: this.certificationsForm.getCertifications(),
            backgroundHistories: this.backgroundHistoryForm.getBackgroundHistory(),
            licenses: this.licensesForm.getLicenses()
        };
    }

    getOtherInfo() {
        this.otherCandidateInfo$ = this._candidateService.getOtherInfo(this.candidate.ssnId);
    }

    onUserChangedNpiChanged(value: boolean) {
        this.userChangedNpi = value;
    }
}
