import { Component, Input, OnInit } from '@angular/core';

/**
 * Component displays the long "description" string with new line after
 * every line break in the string.
 *
 * Symbols "\n\r", "\n" and "\r" are considered a line breaks.
 *
 * The component does not use `innerHtml` construction, so it is safe
 * of JS injections.
 */
@Component({
    selector: 'ayac-long-description',
    templateUrl: './long-description.component.html'
})
export class LongDescriptionComponent implements OnInit {
    /** Long description string, which should be displayed with line breaks */
    @Input() description: string;

    descriptionItems: string[] = [];

    ngOnInit() {
        if (this.description && this.description.trim().length > 0) {
            this.descriptionItems = this.description
                .split('\n\r')
                .reduce((c, a) => c.concat(a.split('\n')), [])
                .reduce((c, a) => c.concat(a.split('\r')), []);
        }
    }
}
