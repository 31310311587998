import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight'
})
export class HighlightSearchPipe implements PipeTransform {
    transform(value: string, searchTerm: string): any {
        if (!searchTerm) {
            return value;
        }

        const result = new RegExp(`(^| )${searchTerm}`, 'gi');

        return value.replace(result, '<b>$&</b>');
    }
}
