import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { PermJobGridActions } from '../actions';
import { PermanentJobService } from 'src/app/permanent-jobs/services/perm-jobs.service';
import { selectPermJobsQuery } from '../selectors/perm-jobs.selectors';
import { PermJobsGridResponse } from 'src/app/permanent-jobs/models/perm-jobs-grid.response';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { ToasterService } from 'src/app/core/services';

@Injectable()
export class PermJobsGridEffect extends BaseEffect {
    getPermJobs$ = createEffect(() => this.actions$.pipe(
        ofType(PermJobGridActions.getPermJobs),
        concatLatestFrom(() => this.store.select(selectPermJobsQuery)),
        switchMap(([_, query]) => {
            return this.permJobService.getFilteredPermJobs(query).pipe(
                map((result: PermJobsGridResponse) => {
                    return PermJobGridActions.getPermJobsSuccess({permJobs: result.data, permJobsTotal: result.total});
                }),
                catchError((error: unknown) => {
                    this.handleError(error as any);
                    return of(PermJobGridActions.getPermJobsFail());
                })
            )
        })
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly permJobService: PermanentJobService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

}
