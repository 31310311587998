import { NgModule } from '@angular/core';
import { AdminStoreModule } from 'src/app/admin/admin-store.module';
import { ClinicalStoreModule } from 'src/app/clinical/store/clinical.store.module';
import { SystemsStoreModule } from 'src/app/systems/systems-store.module';
import { AyaLeadsStoreModule } from './aya-leads/aya-leads-store.module';
import { PermJobsStoreModule } from './perm-jobs/perm-jobs-store.module';

@NgModule({
    imports: [AdminStoreModule, ClinicalStoreModule, SystemsStoreModule, AyaLeadsStoreModule, PermJobsStoreModule]
})
export class RootStoreModule {}
