/**angular */
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';

/**rxjs and ngrx */
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { first, takeUntil } from 'rxjs/operators';

/**shared */
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { FileType } from 'src/app/shared/models/file-type.model';

/**local */
import * as selectors from 'src/app/admin/store/selectors/vendor-detail-files.selectors';
import * as actions from 'src/app/admin/store/actions/vendor-details-files.actions';
import { VendorFilesService } from 'src/app/admin/vendor-details/services/vendor-files.service';
import { VendorFile } from 'src/app/admin/vendor-details/models/vendor-file.model';

@Component({
    selector: 'ayac-vendor-details-files',
    templateUrl: './vendor-details-files.component.html',
    styleUrls: ['./vendor-details-files.component.scss']
})
export class VendorDetailsFilesComponent extends UnsubscribeOnDestroy implements OnChanges {
    @ViewChild('fileUpload', { static: false }) fileUpload: any;
    private _vendorId = 0;

    @Input() set vendorId(id: number) {
        if (id && !Number.isNaN(Number(id))) {
            this._vendorId = id;
            this.initialize();
        }
    }

    get vendorId(): number {
        return this._vendorId;
    }

    searchQuery$: Observable<GridSearchQuery>;

    gridData$: Observable<GridDataResult>;

    deletingFileIds$: Observable<number[]>;
    isDeleting$: Observable<boolean>;
    isUploading = false;
    selectedFileType: FileType = null;

    fileTypes: FileType[] = null;
    fileTypes$: Observable<FileType[]>;

    get isNew(): boolean {
        if (Number.isNaN(Number(this.vendorId))) {
            return true;
        }
        return !this.vendorId || this.vendorId <= 0;
    }

    loading$: Observable<boolean> = this._store.select(selectors.selectVendorFilesLoading);

    constructor(private readonly _store: Store, private readonly _vendorFilesService: VendorFilesService) {
        super();
    }

    initialize(): void {
        this.searchQuery$ = this._store.select(selectors.selectVendorFilesSearchQuery).pipe(first());

        this.gridData$ = this._store.select(selectors.selectVendorFiles);

        this.fileTypes$ = this._store.select(selectors.selectFileTypes);

        this.deletingFileIds$ = this._store.select(selectors.selectVendorFilesDeletingFileIds);

        this.loading$ = this._store.select(selectors.selectVendorFilesLoading);

        this.isDeleting$ = this._store.select(selectors.selectVendorFileIsDeleting);

        this._store.dispatch(actions.loadFileTypes());

        this._store.dispatch(actions.loadVendorDetailFiles({ vendorId: this.vendorId }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.vendorId?.currentValue) {
            this.refreshGrid();
        }
    }

    refreshGrid(): void {
        if (!this.vendorId) {
            return;
        }

        this._store.dispatch(
            actions.loadVendorDetailFiles({
                vendorId: this.vendorId
            })
        );
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this._store.dispatch(
            actions.setVendorDetailFilesSearchQuery({
                searchQuery: state
            })
        );
        this.refreshGrid();
    }

    onDownloadFile(vendorFile: VendorFile): void {
        this._vendorFilesService.downloadFile(vendorFile);
    }

    onDeleteFile(candidateFile: VendorFile): void {
        this._store.dispatch(
            actions.deleteVendorDetailFiles({
                fileIds: [candidateFile.fileId],
                vendorId: this.vendorId
            })
        );
    }

    onFileSelected(newFiles: any[]): void {
        this.isUploading = true;
        this._vendorFilesService
            .addFiles(this.vendorId, newFiles)
            .pipe(takeUntil(this.d$))
            .subscribe(
                (fileIds) => {
                    this.isUploading = false;
                    this.refreshGrid();
                },
                (error) => {
                    this.isUploading = false;
                }
            );
    }
    uploadFile(fileType: any): void {
        this.selectedFileType = fileType;
        this.fileUpload.nativeElement.click();
    }
}
