import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ayac-contact-toolbar',
    templateUrl: './contact-toolbar.component.html',
    styleUrls: ['./contact-toolbar.component.scss']
})
export class ContactToolbarComponent {
    @Output() backClicked: EventEmitter<void> = new EventEmitter();
    @Output() saveClicked: EventEmitter<void> = new EventEmitter();
    @Output() cancelClicked: EventEmitter<void> = new EventEmitter();
    @Output() createNovaProfileClicked: EventEmitter<boolean> = new EventEmitter();
    @Output() completeSubmittalClicked: EventEmitter<void> = new EventEmitter();

    @Input() hasChanges: boolean;
    @Input() disableSaveAndCancel: boolean;
    @Input() qaId: string;
    @Input() showCancelButton = true;
    @Input() showCreateNovaButton = false;
    @Input() isProfileV1 = false;
    @Input() disableV1Profile = false;
    @Input() showCompleteSubmittal = false;
    @Input() disableCompleteSubmittal: boolean;
    @Input() showBackButtonIcon = true;
    @Input() showSaveButtonIcon = true;
    @Input() showOutlineBackButtonNoIcon = false;
    @Input() showSubmitButtonNoIcon = false;
    @Input() addExtraBottomPadding = false;

    back(): void {
        this.backClicked.emit();
    }

    save(): void {
        this.saveClicked.emit();
    }

    cancel(): void {
        this.cancelClicked.emit();
    }

    createNovaProfile(): void {
        this.createNovaProfileClicked.emit(!this.isProfileV1);
    }

    completeSubmittal(): void {
        this.completeSubmittalClicked.emit();
    }
}
