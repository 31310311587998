import { DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { AyaLeadsState, ayaLeadsStoreKey } from '../../aya-leads.state';
import { LoadingTypes } from 'src/app/shared/models';
import { ClientLookupsState } from '../../../../client/store/reducers/client-lookups.reducer';
import { DataResult, FilterDescriptor } from '@progress/kendo-data-query';
import { IsPendingReviewOverDue, OverDueBy } from 'src/app/shared/utilities/is-pending-review-over-due';
import { AyaLeadsListItem } from 'src/app/aya-leads/models/aya-leads-list-item';
import { AyaLeadsApplicationStatus } from 'src/app/aya-leads/enums/aya-leads-application-status.enum';
import { selectClientLookups } from './aya-leads-list-lookups.selectors';
import { applicationStatusIdsFilterName } from 'src/app/aya-leads/models/aya-leads.constants';

export const selectAyaLeadsFeature = createFeatureSelector<AyaLeadsState>(ayaLeadsStoreKey);

export type AyaLeadsResultsSelector = DataResult;

export const selectAyaLeadsResults: MemoizedSelector<
    object,
    AyaLeadsResultsSelector,
    DefaultProjectorFn<AyaLeadsResultsSelector>
> = createSelector(selectAyaLeadsFeature, (state: AyaLeadsState): AyaLeadsResultsSelector => {
    state.results.ayaLeads.forEach((ayaLead: AyaLeadsListItem) => {
        ayaLead.isNewApplication = ayaLead.applicationStatusId === AyaLeadsApplicationStatus.New;
        ayaLead.isPendingOver2Days = IsPendingReviewOverDue(
            ayaLead.isNewApplication,
            ayaLead.submissionSentOn,
            OverDueBy
        );
    });
    return { data: state.results.ayaLeads, total: state.results.ayaLeadsTotal };
});

export const selectAyaLeadsResultsTotal: MemoizedSelector<object, number, DefaultProjectorFn<number>> = createSelector(
    selectAyaLeadsResults,
    (state) => state.total
);

export const selectIsGridInRejectionView = createSelector(selectAyaLeadsFeature, (state) => {
    const applicationStatusFilter = state?.query?.filter?.filters?.find(
        (x) => (x as FilterDescriptor).field === applicationStatusIdsFilterName
    ) as FilterDescriptor;
    if (applicationStatusFilter && Array.isArray(applicationStatusFilter.value)) {
        return (
            applicationStatusFilter.value.length === 1 &&
            applicationStatusFilter.value.includes(AyaLeadsApplicationStatus.Rejected)
        );
    }

    return false;
});

export const selectAyaLeadsResultsIsLoading: MemoizedSelector<
    object,
    boolean,
    DefaultProjectorFn<boolean>
> = createSelector(
    selectAyaLeadsFeature,
    selectClientLookups,
    (ayaLeadsState: AyaLeadsState, clientLookupsState: ClientLookupsState): boolean =>
        [ayaLeadsState.results.loading, clientLookupsState.loadingSecurity].some(
            (x: LoadingTypes) => x === LoadingTypes.LOADING
        )
);
