import { ColorShade } from '../models';

export const HEX_COLOR_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export const getRgbFromHexColor = (hexColor: string): number[] => {
    const result = HEX_COLOR_REGEX.exec(hexColor);
    if (!result) {
        throw new Error(`Invalid hex color: ${hexColor}`);
    }

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return [r, g, b];
};

export const getHexValueForNumber = (color: number): string => {
    color = Math.round(color);
    if (color < 0) {
        color = 0;
    }
    if (color > 255) {
        color = 255;
    }

    let hexValue = color.toString(16);
    if (hexValue.length < 2) {
        hexValue = '0' + hexValue;
    }

    return hexValue;
};

export const getHexColorFromRgb = (r: number, g: number, b: number): string => {
    const RR = getHexValueForNumber(r);
    const GG = getHexValueForNumber(g);
    const BB = getHexValueForNumber(b);

    return `#${RR}${GG}${BB}`;
};

export const shadeColor = (hexColor: string | null, percentAsDecimal: number): string | null => {
    if (!hexColor) {
        return null;
    }

    let [r, g, b] = getRgbFromHexColor(hexColor);

    if (percentAsDecimal < 0) {
        r = (1 + percentAsDecimal) * r;
        g = (1 + percentAsDecimal) * g;
        b = (1 + percentAsDecimal) * b;
    } else {
        r = (1 - percentAsDecimal) * r + percentAsDecimal * 255;
        g = (1 - percentAsDecimal) * g + percentAsDecimal * 255;
        b = (1 - percentAsDecimal) * b + percentAsDecimal * 255;
    }

    return getHexColorFromRgb(r, g, b);
};

export const linearize = (color: number): number => {
    const v = color / 255;
    return v <= 0.04045 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
};

export const luminance = (r: number, g: number, b: number): number => {
    return 0.2126 * linearize(r) + 0.7152 * linearize(g) + 0.0722 * linearize(b);
};

export const contrast = (background: string, foreground: string): number => {
    const [r1, g1, b1] = getRgbFromHexColor(background);
    const [r2, g2, b2] = getRgbFromHexColor(foreground);

    const lum1 = luminance(r1, g1, b1);
    const lum2 = luminance(r2, g2, b2);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);

    return +((brightest + 0.05) / (darkest + 0.05)).toFixed(2);
};

export const getTextColorByConstrast = (hexColor: string, desiredTextColor: string): string => {
    const idealRatio = 4.5;

    if (contrast(hexColor, desiredTextColor) > idealRatio) {
        return desiredTextColor;
    } else if (contrast(hexColor, '#000000') > idealRatio) {
        return '#000000';
    } else if (contrast(hexColor, '#ffffff') > idealRatio) {
        return '#ffffff';
    }

    return desiredTextColor;
};

export const getColorShades = (color: string | null, hasActiveTextColor: boolean = false): ColorShade | null => {
    if (!color) {
        return null;
    }

    const valid = HEX_COLOR_REGEX.test(color);
    if (!valid) {
        throw new Error(`Invalid hex color: ${color}`);
    }

    const lighter = shadeColor(color, 0.7);
    const darker = shadeColor(color, 0.095);
    const text = getTextColorByConstrast(color, hasActiveTextColor ? '#D2D0CF' : '#ffffff');
    const textLighter = getTextColorByConstrast(lighter, '#ffffff');
    const textDarker = getTextColorByConstrast(darker, '#ffffff');

    return {
        color,
        lighter,
        darker,
        text,
        textActive: hasActiveTextColor ? '#ffffff' : null,
        textLighter,
        textDarker
    };
};
