import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { addFilterEqValue } from 'src/app/shared/utilities';

export interface TotalHeader {
    field: string,
    value: any;
    label: string,
    /***
     * If there is no total, a link will be displayed
     * that will apply the filter @field with the value @value
    */
    total: number | null
}

@Component({
    selector: 'ayac-total-header-toggler',
    templateUrl: './total-header-toggler.component.html',
    styleUrls: ['./total-header-toggler.component.scss']
})
export class TotalHeaderTogglerComponent implements OnInit {
    @Input() query: GridSearchQuery;
    @Input() totals: TotalHeader[]; 

    @Output() dataStateChange = new EventEmitter<GridSearchQuery>();

    constructor() { }

    ngOnInit(): void {
    }

    onSelectFilter(total: TotalHeader) {
        const filter = addFilterEqValue(total.field, total.value, this.query.filter);
        this.dataStateChange.emit({
            ...this.query,
            filter
        })
    }

}
