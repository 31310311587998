import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/facility-features.actions';
import {
    FacilityFeaturesState,
    getInitialFacilityFeaturesState
} from 'src/app/facilities/store/state/facility-features.state';
import { LoadingTypes } from 'src/app/shared/models';

export function facilityFeaturesReducer(state: FacilityFeaturesState | undefined, action: Action) {
    return createReducer(
        getInitialFacilityFeaturesState(),

        on(
            actions.loadConnectFacilityFeatures,
            (state): FacilityFeaturesState => ({ ...state, loadingState: LoadingTypes.LOADING })
        ),
        on(actions.loadConnectFacilityFeaturesSuccess, (state, action) => ({
            ...state,
            permissionCategories: action.features.connectPermissions,
            notifications: action.features.connectNotifications,
            loadingState: LoadingTypes.LOADED
        })),
        on(
            actions.loadConnectFacilityFeaturesFailure,
            (state): FacilityFeaturesState => ({ ...state, loadingState: LoadingTypes.FAIL })
        ),

        on(
            actions.updateFacilityFeature,
            (state): FacilityFeaturesState => ({ ...state, loadingState: LoadingTypes.LOADING })
        ),

        on(
            actions.updateFacilityFeatureSuccess,
            (state): FacilityFeaturesState => ({ ...state, loadingState: LoadingTypes.LOADED })
        ),

        on(
            actions.updateFacilityFeatureFailure,
            (state): FacilityFeaturesState => ({ ...state, loadingState: LoadingTypes.FAIL })
        ),

        on(actions.clearFacilityFeatures, (): FacilityFeaturesState => ({ ...getInitialFacilityFeaturesState() }))
    )(state, action);
}
