export function openLink(href: string, isNewTab: boolean = false) {
    const link = document.createElement("a");  

    link.setAttribute('href', href);

    if (isNewTab) {
        link.setAttribute('target', '_blank')
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
