import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ayac-exclude-vendor-modal',
    templateUrl: './exclude-vendor-modal.component.html',
    styleUrls: ['./exclude-vendor-modal.component.scss']
})
export class ExcludeVendorModalComponent {
    form = this.formBuilder.group({
        note: ['']
    });

    now = new Date();

    constructor(
        public dialogRef: MatDialogRef<ExcludeVendorModalComponent>,
        private readonly formBuilder: UntypedFormBuilder
    ) {}

    exclude(): void {
        this.dialogRef.close({ note: this.form.controls.note.value });
    }
}
