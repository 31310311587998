import { ActivePoolReport } from 'src/app/reports/models/active-pool-report/active-pool-report.model';
import { CancellationsReport } from 'src/app/reports/models/cancellations-report/cancellations-report.model';
import { ChartReport } from 'src/app/reports/models/chart-report.model';
import { ReportType } from 'src/app/reports/models/enums/report-type.enum';
import { FutureReport } from 'src/app/reports/models/future-report/future-report.model';
import { GridReportResponse } from 'src/app/reports/models/grid-report-response.model';
import { InactiveReport } from 'src/app/reports/models/inactive-report/inactive-report.model';
import { LinesOfBusiness } from 'src/app/reports/models/lines-of-business.model';
import { OnAssignmentReport } from 'src/app/reports/models/on-assignment-report/on-assignment-report.model';
import { OpenOrdersReport } from 'src/app/reports/models/open-orders-report/open-orders-report.model';
import { OpenShiftsReport } from 'src/app/reports/models/open-shifts-report/open-shifts-report.model';
import { OrderHistoryReport } from 'src/app/reports/models/order-history-report/order-history-report.model';
import { ReportListItem } from 'src/app/reports/models/report-list-item.model';
import { ReportUserSettings } from '../models/report-user-settings';

export const LinesOfBusinessResponse: LinesOfBusiness = {
    openJobsClinical: 20,
    openJobsNonClinical: 0,
    activeSubmitalsClinical: 10,
    activeSubmitalsNonClinical: 0,
    preStartTravelersClinical: 125,
    preStartTravelersNonClinical: 0,
    workingTravelersClinical: 188,
    workingTravelersNonClinical: 0
};

export const UserSettings: ReportUserSettings = {
    clientReportSortOrder: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
};

export const ReportListResponse: ReportListItem[] = [
    {
        reportId: 1,
        sectionName: 'Travel',
        groupName: 'Job Orders',
        reportName: 'Open Orders',
        reportViewName: 'TravelJobsOpen',
        isActive: true,
        typeId: 1,
        typeKind: 1,
        typeRealControlName: null
    },
    {
        reportId: 2,
        sectionName: 'Travel',
        groupName: 'Job Orders',
        reportName: 'Order History',
        reportViewName: 'TravelJobHistory',
        isActive: true,
        typeId: 1,
        typeKind: 1,
        typeRealControlName: null
    },
    {
        reportId: 3,
        sectionName: 'Travel',
        groupName: 'Assignments',
        reportName: 'On Assignment',
        reportViewName: 'TravelAssignmentsCurrent',
        isActive: true,
        typeId: 1,
        typeKind: 1,
        typeRealControlName: null
    },
    {
        reportId: 4,
        sectionName: 'Travel',
        groupName: 'Assignments',
        reportName: 'Future',
        reportViewName: 'TravelAssignmentsFuture',
        isActive: true,
        typeId: 1,
        typeKind: 1,
        typeRealControlName: null
    },
    {
        reportId: 5,
        sectionName: 'Travel',
        groupName: 'Assignments',
        reportName: 'Inactive',
        reportViewName: 'TravelAssignmentsInactive',
        isActive: true,
        typeId: 1,
        typeKind: 1,
        typeRealControlName: null
    },
    {
        reportId: 7,
        sectionName: 'Travel',
        groupName: 'Assignments',
        reportName: 'Cancellations',
        reportViewName: 'TravelAssignmentsCancellations',
        isActive: true,
        typeId: 1,
        typeKind: 1,
        typeRealControlName: null
    },
    {
        reportId: 8,
        sectionName: 'Per Diem',
        groupName: 'Local Pool',
        reportName: 'Active Pool',
        reportViewName: 'PerDiemPoolActive',
        isActive: true,
        typeId: 1,
        typeKind: 1,
        typeRealControlName: null
    },
    {
        reportId: 9,
        sectionName: 'Per Diem',
        groupName: 'Shifts',
        reportName: 'Open Shifts',
        reportViewName: 'PerDiemShiftsOpen',
        isActive: true,
        typeId: 1,
        typeKind: 1,
        typeRealControlName: null
    },
    {
        reportId: 10,
        sectionName: 'Per Diem',
        groupName: 'Shifts',
        reportName: 'Shift History',
        reportViewName: 'PerDiemShiftHistory',
        isActive: true,
        typeId: 1,
        typeKind: 1,
        typeRealControlName: null
    },
    {
        reportId: 11,
        sectionName: 'Spending',
        groupName: '',
        reportName: 'Spend by Month',
        reportViewName: 'report_detail_bar.html',
        isActive: true,
        typeId: 3,
        typeKind: 2,
        typeRealControlName: 'BarChart'
    },
    {
        reportId: 12,
        sectionName: 'Spending',
        groupName: '',
        reportName: 'Spend by Location',
        reportViewName: 'report_detail_bar.html',
        isActive: true,
        typeId: 3,
        typeKind: 2,
        typeRealControlName: 'BarChart'
    },
    {
        reportId: 13,
        sectionName: 'Spending',
        groupName: '',
        reportName: 'Spend by Unit',
        reportViewName: 'report_detail_bar.html',
        isActive: true,
        typeId: 3,
        typeKind: 2,
        typeRealControlName: 'BarChart'
    },
    {
        reportId: 14,
        sectionName: 'Spending',
        groupName: ' ',
        reportName: 'Spend by Specialty',
        reportViewName: 'report_detail_bar.html',
        isActive: true,
        typeId: 3,
        typeKind: 2,
        typeRealControlName: 'BarChart'
    },
    {
        reportId: 15,
        sectionName: 'Spending',
        groupName: ' ',
        reportName: 'Spend by Cost Center',
        reportViewName: 'report_detail_bar.html',
        isActive: true,
        typeId: 3,
        typeKind: 2,
        typeRealControlName: 'BarChart'
    },
    {
        reportId: 16,
        sectionName: 'Spending',
        groupName: ' ',
        reportName: 'Spend by Supplier',
        reportViewName: 'report_detail_bar.html',
        isActive: true,
        typeId: 3,
        typeKind: 2,
        typeRealControlName: 'BarChart'
    },
    {
        reportId: 201,
        sectionName: 'Power BI',
        groupName: 'UCSD',
        reportName: 'Executive Dashbord',
        reportViewName: 'PowerBIReport',
        isActive: true,
        typeId: 4,
        typeKind: 4,
        typeRealControlName: null
    }
];

//Travel
export const OpenOrdersReportResponse: GridReportResponse<OpenOrdersReport> = {
    data: [
        {
            jobId: 1157988,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2020-01-02T00:00:00',
            startDate: '2020-03-24T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Operating Room',
            unitName: '08-7020 Operating Room',
            costCenterName: '7020'
        },
        {
            jobId: 1157177,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-30T00:00:00',
            startDate: '2020-02-25T00:00:00',
            positionCount: 3,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '5EH MED SURG SPEC',
            costCenterName: '316077'
        },
        {
            jobId: 1152346,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-06T00:00:00',
            startDate: '2020-02-12T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '316072 3W NEURO ACUTE UNIT',
            costCenterName: '316072'
        },
        {
            jobId: 1159151,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2020-01-07T00:00:00',
            startDate: '2020-02-11T08:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '7 SA Med / Surg Transplant',
            costCenterName: '6072'
        },
        {
            jobId: 1157758,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2020-01-02T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Medical Surgical',
            unitName: '08-6099 8 N Rehab',
            costCenterName: '6099'
        },
        {
            jobId: 1158358,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2020-01-03T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '4EH MED/SURG',
            costCenterName: '316087'
        },
        {
            jobId: 1147983,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-11-19T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            costCenterName: '318700'
        },
        {
            jobId: 1158401,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2020-01-03T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '3E MEDICIN/TELEMETRY',
            costCenterName: '316071'
        },
        {
            jobId: 1157958,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2020-01-02T00:00:00',
            startDate: '2020-02-03T00:00:00',
            positionCount: 1,
            professionName: 'Radiology / Cardiology',
            specialtyDescription: 'Echo Tech',
            unitName: null,
            costCenterName: null
        },
        {
            jobId: 1158327,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2020-01-03T00:00:00',
            startDate: '2020-02-03T00:00:00',
            positionCount: 1,
            professionName: 'Surgical Services',
            specialtyDescription: 'Sterile Processing Tech (SPT)',
            unitName: null,
            costCenterName: null
        },
        {
            jobId: 1156703,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-12-26T00:00:00',
            startDate: '2020-01-28T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Transplant Coordinator',
            unitName: 'University of Washington Liver Transplant Program',
            costCenterName: null
        },
        {
            jobId: 1155956,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-12-20T00:00:00',
            startDate: '2020-01-28T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Ambulatory Care',
            unitName: "Mens's Health Clinic",
            costCenterName: null
        },
        {
            jobId: 1155643,
            facilityName: 'UW Medicine Eastside Specialty Center',
            enteredDate: '2019-12-19T00:00:00',
            startDate: '2020-01-28T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Endoscopy',
            unitName: 'East Side Specialty Clinic Endoscopy',
            costCenterName: null
        },
        {
            jobId: 1157909,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2020-01-02T00:00:00',
            startDate: '2020-01-27T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'ER',
            unitName: 'Emergency Department',
            costCenterName: null
        },
        {
            jobId: 1154975,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2019-12-17T00:00:00',
            startDate: '2020-01-27T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Operating Room',
            unitName: '7021A Operating Room',
            costCenterName: '7021'
        },
        {
            jobId: 1154998,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2019-12-17T00:00:00',
            startDate: '2020-01-27T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Operating Room',
            unitName: '7021A Operating Room',
            costCenterName: '7021'
        },
        {
            jobId: 1156452,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2019-12-23T00:00:00',
            startDate: '2020-01-27T00:00:00',
            positionCount: 1,
            professionName: 'Radiology / Cardiology',
            specialtyDescription: 'MRI Tech',
            unitName: 'MRI Tech- 083254',
            costCenterName: '83254'
        },
        {
            jobId: 1155297,
            facilityName: 'Valley Medical Center',
            enteredDate: '2019-12-18T00:00:00',
            startDate: '2020-01-20T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'CCU',
            unitName: 'Critical Care 4S',
            costCenterName: null
        },
        {
            jobId: 1156974,
            facilityName: 'Valley Medical Center',
            enteredDate: '2019-12-27T00:00:00',
            startDate: '2020-01-20T00:00:00',
            positionCount: 1,
            professionName: 'Laboratory',
            specialtyDescription: 'Medical Lab Technician',
            unitName: null,
            costCenterName: null
        },
        {
            jobId: 1145395,
            facilityName: 'Valley Medical Center',
            enteredDate: '2019-11-07T00:00:00',
            startDate: '2020-01-20T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'ER',
            unitName: 'ER -7230',
            costCenterName: null
        }
    ],
    total: 20,
    aggregates: null,
    error: null
} as any as GridReportResponse<OpenOrdersReport>;

export const OrderHistoryReportResponse: GridReportResponse<OrderHistoryReport> = {
    data: [
        {
            unitManager: 'Aimee Diffendafer',
            statusName: 'Closed',
            jobId: 1150994,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-03T00:00:00',
            startDate: '2020-05-05T07:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '7E General Surgery',
            costCenterName: '316082'
        },
        {
            unitManager: 'Natalie Shiflett, BS, RN, CNOR',
            statusName: 'Open',
            jobId: 1157988,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2020-01-02T00:00:00',
            startDate: '2020-03-24T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Operating Room',
            unitName: '08-7020 Operating Room',
            costCenterName: '7020'
        },
        {
            unitManager: 'Charisse Ramos',
            statusName: 'Closed',
            jobId: 1159018,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2020-01-07T00:00:00',
            startDate: '2020-03-02T08:00:00',
            positionCount: 0,
            professionName: 'Surgical Services',
            specialtyDescription: 'Anesthesia Tech',
            unitName: 'HMC ANESTHESIOLOGY',
            costCenterName: '317040'
        },
        {
            unitManager: 'Emilija Melezovic',
            statusName: 'Open',
            jobId: 1157177,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-30T00:00:00',
            startDate: '2020-02-25T00:00:00',
            positionCount: 3,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '5EH MED SURG SPEC',
            costCenterName: '316077'
        },
        {
            unitManager: 'Magda Hanna',
            statusName: 'Open',
            jobId: 1152346,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-06T00:00:00',
            startDate: '2020-02-12T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '316072 3W NEURO ACUTE UNIT',
            costCenterName: '316072'
        },
        {
            unitManager: 'Natalie Shiflett, BS, RN, CNOR',
            statusName: 'Closed',
            jobId: 1152365,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-12-06T00:00:00',
            startDate: '2020-02-11T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Operating Room',
            unitName: '08-7020 Operating Room',
            costCenterName: '7020'
        },
        {
            unitManager: 'Irene Krall, BSN, MHA, Nurse Manager',
            statusName: 'Open',
            jobId: 1159151,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2020-01-07T00:00:00',
            startDate: '2020-02-11T08:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '7 SA Med / Surg Transplant',
            costCenterName: '6072'
        },
        {
            unitManager: 'Carrie ferrulli',
            statusName: 'Closed',
            jobId: 1136964,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-10-08T00:00:00',
            startDate: '2020-02-11T08:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Neuro ICU',
            unitName: '2WH  NEURO ICU',
            costCenterName: '316015'
        },
        {
            unitManager: 'Nate Rozeboom',
            statusName: 'Open',
            jobId: 1158358,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2020-01-03T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '4EH MED/SURG',
            costCenterName: '316087'
        },
        {
            unitManager: 'Trang Pham',
            statusName: 'Open',
            jobId: 1158401,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2020-01-03T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '3E MEDICIN/TELEMETRY',
            costCenterName: '316071'
        },
        {
            unitManager: 'Timothy Meeks, MN, RN-BC',
            statusName: 'Closed',
            jobId: 1155978,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-20T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Behavioral Health',
            unitName: 'HMC Psychiatry -5WA, 5WB, 5MB',
            costCenterName: null
        },
        {
            unitManager: 'Lynn Farrier, MCSD/CCC-SL',
            statusName: 'Open',
            jobId: 1157758,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2020-01-02T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Medical Surgical',
            unitName: '08-6099 8 N Rehab',
            costCenterName: '6099'
        },
        {
            unitManager: 'Michael Kearney',
            statusName: 'Closed',
            jobId: 1150112,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-11-27T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Cath Lab',
            unitName: 'UWMC Montlake Cath Lab',
            costCenterName: '08-7122'
        },
        {
            unitManager: 'Carol Stewart',
            statusName: 'Open',
            jobId: 1147983,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-11-19T00:00:00',
            startDate: '2020-02-11T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            costCenterName: '318700'
        },
        {
            unitManager: 'KATRINA COLLINS',
            statusName: 'Closed',
            jobId: 1157202,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-30T00:00:00',
            startDate: '2020-02-10T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Rehab',
            unitName: '4W REHAB MEDICINE',
            costCenterName: '316400'
        },
        {
            unitManager: 'Jay Sandel',
            statusName: 'Closed',
            jobId: 1157204,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-30T00:00:00',
            startDate: '2020-02-10T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: 'HMC BOARDERS',
            costCenterName: '316070'
        },
        {
            unitManager: 'Michael Kearny',
            statusName: 'Closed',
            jobId: 1155078,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-12-17T00:00:00',
            startDate: '2020-02-10T08:00:00',
            positionCount: 0,
            professionName: 'Radiology / Cardiology',
            specialtyDescription: 'Electrophysiology Tech',
            unitName: 'UWMC Montlake EP Lab',
            costCenterName: '08-7715'
        },
        {
            unitManager: 'Carrie ferrulli',
            statusName: 'Closed',
            jobId: 1155958,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-20T00:00:00',
            startDate: '2020-02-10T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Neuro ICU',
            unitName: '2WH  NEURO ICU',
            costCenterName: '316015'
        },
        {
            unitManager: 'Carol Stewart',
            statusName: 'Closed',
            jobId: 1157205,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-30T00:00:00',
            startDate: '2020-02-03T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            costCenterName: '318700'
        },
        {
            unitManager: null,
            statusName: 'Closed',
            jobId: 1153386,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-12-11T00:00:00',
            startDate: '2020-02-03T08:00:00',
            positionCount: 0,
            professionName: 'Respiratory / Neuro Diagnostics',
            specialtyDescription: 'RRT',
            unitName: null,
            costCenterName: null
        },
        {
            unitManager: 'Carrie ferrulli',
            statusName: 'Closed',
            jobId: 1153985,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-12T00:00:00',
            startDate: '2020-02-03T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Neuro ICU',
            unitName: '2WH  NEURO ICU',
            costCenterName: '316015'
        },
        {
            unitManager: null,
            statusName: 'Open',
            jobId: 1157958,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2020-01-02T00:00:00',
            startDate: '2020-02-03T00:00:00',
            positionCount: 1,
            professionName: 'Radiology / Cardiology',
            specialtyDescription: 'Echo Tech',
            unitName: null,
            costCenterName: null
        },
        {
            unitManager: null,
            statusName: 'Open',
            jobId: 1158327,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2020-01-03T00:00:00',
            startDate: '2020-02-03T00:00:00',
            positionCount: 1,
            professionName: 'Surgical Services',
            specialtyDescription: 'Sterile Processing Tech (SPT)',
            unitName: null,
            costCenterName: null
        },
        {
            unitManager: 'Michelle A Jackson',
            statusName: 'Closed',
            jobId: 1153986,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-12T00:00:00',
            startDate: '2020-01-31T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Operating Room',
            unitName: 'OPERATING ROOM',
            costCenterName: '317020'
        },
        {
            unitManager: 'Jeanine Penberthy',
            statusName: 'Closed',
            jobId: 1155977,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-12-20T00:00:00',
            startDate: '2020-01-28T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Endoscopy',
            unitName: 'Endoscopy- Digestive Health Center',
            costCenterName: '7320'
        },
        {
            unitManager: null,
            statusName: 'Closed',
            jobId: 1155775,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-12-19T00:00:00',
            startDate: '2020-01-28T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '4 NE Med/Surg Neuro',
            costCenterName: '6094'
        },
        {
            unitManager: 'Nancy Weber',
            statusName: 'Open',
            jobId: 1155956,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-12-20T00:00:00',
            startDate: '2020-01-28T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Ambulatory Care',
            unitName: "Mens's Health Clinic",
            costCenterName: null
        },
        {
            unitManager: 'Rekha Matken',
            statusName: 'Open',
            jobId: 1155643,
            facilityName: 'UW Medicine Eastside Specialty Center',
            enteredDate: '2019-12-19T00:00:00',
            startDate: '2020-01-28T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Endoscopy',
            unitName: 'East Side Specialty Clinic Endoscopy',
            costCenterName: null
        },
        {
            unitManager: 'Jen Boyer',
            statusName: 'Open',
            jobId: 1156703,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-12-26T00:00:00',
            startDate: '2020-01-28T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Transplant Coordinator',
            unitName: 'University of Washington Liver Transplant Program',
            costCenterName: null
        },
        {
            unitManager: null,
            statusName: 'Closed',
            jobId: 1155076,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2019-12-17T00:00:00',
            startDate: '2020-01-27T08:00:00',
            positionCount: 0,
            professionName: 'Radiology / Cardiology',
            specialtyDescription: 'Interventional Radiology',
            unitName: null,
            costCenterName: null
        },
        {
            unitManager: 'Liza Skelton',
            statusName: 'Open',
            jobId: 1157909,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2020-01-02T00:00:00',
            startDate: '2020-01-27T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'ER',
            unitName: 'Emergency Department',
            costCenterName: null
        },
        {
            unitManager: null,
            statusName: 'Closed',
            jobId: 1156358,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2019-12-23T00:00:00',
            startDate: '2020-01-27T00:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Case Manager',
            unitName: null,
            costCenterName: null
        },
        {
            unitManager: null,
            statusName: 'Open',
            jobId: 1156452,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2019-12-23T00:00:00',
            startDate: '2020-01-27T00:00:00',
            positionCount: 1,
            professionName: 'Radiology / Cardiology',
            specialtyDescription: 'MRI Tech',
            unitName: 'MRI Tech- 083254',
            costCenterName: '83254'
        },
        {
            unitManager: 'Gail Rebischke-Smith',
            statusName: 'Open',
            jobId: 1154975,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2019-12-17T00:00:00',
            startDate: '2020-01-27T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Operating Room',
            unitName: '7021A Operating Room',
            costCenterName: '7021'
        },
        {
            unitManager: 'Gail Rebischke-Smith',
            statusName: 'Open',
            jobId: 1154998,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2019-12-17T00:00:00',
            startDate: '2020-01-27T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Operating Room',
            unitName: '7021A Operating Room',
            costCenterName: '7021'
        },
        {
            unitManager: 'Carol Stewart',
            statusName: 'Closed',
            jobId: 1149923,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-11-26T00:00:00',
            startDate: '2020-01-20T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            costCenterName: '318700'
        },
        {
            unitManager: 'Brian Hinnenkamp',
            statusName: 'Open',
            jobId: 1145395,
            facilityName: 'Valley Medical Center',
            enteredDate: '2019-11-07T00:00:00',
            startDate: '2020-01-20T00:00:00',
            positionCount: 2,
            professionName: 'Registered Nurse',
            specialtyDescription: 'ER',
            unitName: 'ER -7230',
            costCenterName: null
        },
        {
            unitManager: 'Donelle Chandler',
            statusName: 'Open',
            jobId: 1155297,
            facilityName: 'Valley Medical Center',
            enteredDate: '2019-12-18T00:00:00',
            startDate: '2020-01-20T00:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'CCU',
            unitName: 'Critical Care 4S',
            costCenterName: null
        },
        {
            unitManager: null,
            statusName: 'Closed',
            jobId: 1156610,
            facilityName: 'Northwest Hospital and Medical Center',
            enteredDate: '2019-12-26T00:00:00',
            startDate: '2020-01-20T00:00:00',
            positionCount: 0,
            professionName: 'Surgical Services',
            specialtyDescription: 'Sterile Processing Tech (SPT)',
            unitName: '7050- Sterile Processing/Instrument Tech',
            costCenterName: '7050'
        },
        {
            unitManager: null,
            statusName: 'Open',
            jobId: 1156974,
            facilityName: 'Valley Medical Center',
            enteredDate: '2019-12-27T00:00:00',
            startDate: '2020-01-20T00:00:00',
            positionCount: 1,
            professionName: 'Laboratory',
            specialtyDescription: 'Medical Lab Technician',
            unitName: null,
            costCenterName: null
        },
        {
            unitManager: 'Timothy Meeks, MN, RN-BC',
            statusName: 'Closed',
            jobId: 1154845,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-12-17T00:00:00',
            startDate: '2020-01-16T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Behavioral Health',
            unitName: 'HMC Psychiatry -5WA, 5WB, 5MB',
            costCenterName: null
        },
        {
            unitManager: 'Kelly Chalupnik',
            statusName: 'Closed',
            jobId: 1136318,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-10-04T00:00:00',
            startDate: '2020-01-15T00:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Ambulatory Care',
            unitName: 'CLINIC FLOAT POOL',
            costCenterName: '318709'
        },
        {
            unitManager: 'Hibo',
            statusName: 'Closed',
            jobId: 1137256,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-10-09T00:00:00',
            startDate: '2020-01-14T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'Medical Surgical',
            unitName: '6EH ORTHOPEDICS',
            costCenterName: '316075'
        },
        {
            unitManager: 'Sandra Olson',
            statusName: 'Closed',
            jobId: 1140276,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-10-21T00:00:00',
            startDate: '2020-01-14T08:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '8 NE Oncology/Blood & Marrow Transplant Unit',
            costCenterName: '6080'
        },
        {
            unitManager: 'Sandra Olson',
            statusName: 'Closed',
            jobId: 1140277,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-10-21T00:00:00',
            startDate: '2020-01-14T08:00:00',
            positionCount: 1,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '8 NE Oncology/Blood & Marrow Transplant Unit',
            costCenterName: '6080'
        },
        {
            unitManager: 'Carol Stewart',
            statusName: 'Closed',
            jobId: 1141467,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-10-24T00:00:00',
            startDate: '2020-01-14T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            costCenterName: '318700'
        },
        {
            unitManager: 'Carol Stewart',
            statusName: 'Closed',
            jobId: 1141559,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-10-24T00:00:00',
            startDate: '2020-01-14T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            costCenterName: '318700'
        },
        {
            unitManager: 'Sandra Olson',
            statusName: 'Closed',
            jobId: 1141584,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-10-24T00:00:00',
            startDate: '2020-01-14T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '8 NE Oncology/Blood & Marrow Transplant Unit',
            costCenterName: '6080'
        },
        {
            unitManager: 'Nicole Solvang - USE HER 1st',
            statusName: 'Closed',
            jobId: 1142650,
            facilityName: 'University of Washington Medical Center',
            enteredDate: '2019-10-29T00:00:00',
            startDate: '2020-01-14T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'CVICU',
            unitName: 'ICU Float Pool',
            costCenterName: null
        },
        {
            unitManager: 'Carol Stewart',
            statusName: 'Closed',
            jobId: 1143278,
            facilityName: 'Harborview Medical Center',
            enteredDate: '2019-10-30T00:00:00',
            startDate: '2020-01-14T08:00:00',
            positionCount: 0,
            professionName: 'Registered Nurse',
            specialtyDescription: 'MS/Tele',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            costCenterName: '318700'
        }
    ],
    total: 1599,
    aggregates: null,
    error: null
} as any as GridReportResponse<OrderHistoryReport>;

export const OnAssignmentReportResponse: GridReportResponse<OnAssignmentReport> = {
    data: [
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-08T00:00:00',
            endDate: '2020-04-03T00:00:00',
            candidateName: 'Elijah Stairs',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-08T00:00:00',
            endDate: '2020-04-12T00:00:00',
            candidateName: 'Pamela Hiner',
            professionName: 'Surgical Services',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-07T00:00:00',
            endDate: '2020-03-25T00:00:00',
            candidateName: 'Christopher M. Smith',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-01-07T00:00:00',
            endDate: '2020-07-05T00:00:00',
            candidateName: 'Eric Meliet',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-01-07T00:00:00',
            endDate: '2020-04-05T00:00:00',
            candidateName: 'Josh Higbee',
            professionName: 'Surgical Services',
            unitName: 'OPERATING ROOM',
            unitManager: 'Michelle A Jackson',
            costCenterName: '317020',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-01-26T00:00:00',
            candidateName: 'Michelle Obrien',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-05T00:00:00',
            candidateName: 'Maddison Kieffer',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-05T00:00:00',
            candidateName: 'Manuel Bermudez',
            professionName: 'Surgical Services',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-19T00:00:00',
            candidateName: 'Alisha Astill',
            professionName: 'Registered Nurse',
            unitName: 'ICU Float Pool',
            unitManager: 'Nicole Solvang - USE HER 1st',
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-02-23T00:00:00',
            candidateName: 'Camille Norris',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-02-09T00:00:00',
            candidateName: 'Alissa LaManno',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-03T00:00:00',
            candidateName: 'Kelsey Evelyn',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-03T00:00:00',
            candidateName: 'Juan Villarreal',
            professionName: 'Surgical Services',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-05T00:00:00',
            candidateName: 'Madalyn Itah',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-26T00:00:00',
            candidateName: 'Charlese Hillman',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-02-23T00:00:00',
            candidateName: 'Kristin Dara',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-12T00:00:00',
            candidateName: 'Corey Geer',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-02-02T00:00:00',
            candidateName: 'Erin Dunne',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-05T00:00:00',
            candidateName: 'Jessalynn Dest',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-02-16T00:00:00',
            candidateName: 'Eryn Battey',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-01-31T00:00:00',
            candidateName: 'Debbie Coleman',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2595,
            facilityName: 'Northwest Hospital and Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-04-05T00:00:00',
            candidateName: 'Emily Vranic',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-01-19T00:00:00',
            candidateName: 'Alyssa Bergdoll',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-01-10T00:00:00',
            candidateName: 'Viviana Rojas',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-02-13T00:00:00',
            candidateName: 'Jessica Cunningham',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-05T00:00:00',
            endDate: '2020-04-05T00:00:00',
            candidateName: 'Alejandra Olson',
            professionName: 'Surgical Services',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-03T00:00:00',
            endDate: '2020-03-01T00:00:00',
            candidateName: 'Patrick Kiernan',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-01-02T00:00:00',
            endDate: '2020-01-14T00:00:00',
            candidateName: 'Seth Radnothy',
            professionName: 'Registered Nurse',
            unitName: 'OPERATING ROOM',
            unitManager: 'Michelle A Jackson',
            costCenterName: '317020',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-30T00:00:00',
            endDate: '2020-04-05T00:00:00',
            candidateName: 'Shelly Vermani',
            professionName: 'Registered Nurse',
            unitName: '08-6099 8 N Rehab',
            unitManager: 'Lynn Farrier, MCSD/CCC-SL',
            costCenterName: '6099',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-30T00:00:00',
            endDate: '2020-01-09T00:00:00',
            candidateName: 'Bintu Konteh',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-30T00:00:00',
            endDate: '2020-04-04T00:00:00',
            candidateName: 'Beatrice Ananga',
            professionName: 'Surgical Services',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-30T00:00:00',
            endDate: '2020-03-28T00:00:00',
            candidateName: 'Radyl B. Velarde',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2860,
            facilityName: 'Valley Medical Center',
            startDate: '2019-12-30T00:00:00',
            endDate: '2020-04-25T00:00:00',
            candidateName: 'John Castiola',
            professionName: 'Registered Nurse',
            unitName: 'O.R. - 7020',
            unitManager: 'Stephanie Lilje',
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-28T00:00:00',
            endDate: '2020-02-05T00:00:00',
            candidateName: 'Megan Turner',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-23T00:00:00',
            endDate: '2020-03-21T00:00:00',
            candidateName: 'Maeleigh E. Soper',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-23T00:00:00',
            endDate: '2020-03-08T00:00:00',
            candidateName: 'Jesse Barney',
            professionName: 'Registered Nurse',
            unitName: '316072 3W NEURO ACUTE UNIT',
            unitManager: 'Magda Hanna',
            costCenterName: '316072',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-23T00:00:00',
            endDate: '2020-03-08T00:00:00',
            candidateName: 'Maribel Barney',
            professionName: 'Registered Nurse',
            unitName: '316072 3W NEURO ACUTE UNIT',
            unitManager: 'Magda Hanna',
            costCenterName: '316072',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-23T00:00:00',
            endDate: '2020-03-22T00:00:00',
            candidateName: 'Cleaveland Coleman',
            professionName: 'Surgical Services',
            unitName: 'OPERATING ROOM',
            unitManager: 'Michelle A Jackson',
            costCenterName: '317020',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-19T00:00:00',
            endDate: '2020-03-15T00:00:00',
            candidateName: 'Chivon Waller',
            professionName: 'Pharmacy',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-19T00:00:00',
            endDate: '2020-03-01T00:00:00',
            candidateName: 'Nicole Masters',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-19T00:00:00',
            endDate: '2020-03-29T00:00:00',
            candidateName: 'Elizabeth Schmidt',
            professionName: 'Registered Nurse',
            unitName: '8 SA',
            unitManager: 'Libbie Musladin',
            costCenterName: '6075',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-18T00:00:00',
            endDate: '2020-03-27T00:00:00',
            candidateName: 'Bethany Dunn',
            professionName: 'Registered Nurse',
            unitName: '7 SA Med / Surg Transplant',
            unitManager: 'Irene Krall, BSN, MHA, Nurse Manager',
            costCenterName: '6072',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-18T00:00:00',
            endDate: '2020-03-27T00:00:00',
            candidateName: 'Daniel Telhada',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2020-06-13T00:00:00',
            candidateName: 'Robyn Hankel',
            professionName: 'Radiology / Cardiology',
            unitName: 'UWMC Montlake EP Lab',
            unitManager: 'Michael Kearny',
            costCenterName: '08-7715',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2020-03-15T00:00:00',
            candidateName: 'Martha Schmidt',
            professionName: 'Registered Nurse',
            unitName: '7 SA Med / Surg Transplant',
            unitManager: 'Irene Krall, BSN, MHA, Nurse Manager',
            costCenterName: '6072',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2020-03-21T00:00:00',
            candidateName: 'DeShawn Petties',
            professionName: 'Surgical Services',
            unitName: 'OPERATING ROOM',
            unitManager: 'Michelle A Jackson',
            costCenterName: '317020',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2020-03-29T00:00:00',
            candidateName: 'Fermin Joshua Rodriguez III',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2020-03-15T00:00:00',
            candidateName: 'Cziara Lopez',
            professionName: 'Registered Nurse',
            unitName: '5EH MED SURG SPEC',
            unitManager: 'Emilija Melezovic',
            costCenterName: '316077',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2020-03-22T00:00:00',
            candidateName: 'Rochelle Lingao',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2020-03-22T00:00:00',
            candidateName: 'Melissa Fajardo',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        }
    ],
    total: 188,
    aggregates: null,
    error: null
} as any as GridReportResponse<OnAssignmentReport>;

export const FutureReportResponse: GridReportResponse<FutureReport> = {
    data: [
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-05-17T00:00:00',
            endDate: '2020-05-24T00:00:00',
            candidateName: 'Jose Cuicahua Perez',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-05-05T00:00:00',
            endDate: '2020-08-02T00:00:00',
            candidateName: 'Rachel Taylor',
            professionName: 'Registered Nurse',
            unitName: '7E General Surgery',
            unitManager: 'Aimee Diffendafer',
            costCenterName: '316082',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-05-05T00:00:00',
            endDate: '2020-08-16T00:00:00',
            candidateName: 'Catherine Szczewski',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-03-09T00:00:00',
            endDate: '2020-03-31T00:00:00',
            candidateName: 'Ana Copaceanu',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-03-09T00:00:00',
            endDate: '2020-06-06T00:00:00',
            candidateName: 'Jade Cabrera',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-03-08T00:00:00',
            endDate: '2020-06-06T00:00:00',
            candidateName: 'Carly Albert',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-03-02T00:00:00',
            endDate: '2020-05-31T00:00:00',
            candidateName: 'Jackquline Lee',
            professionName: 'Surgical Services',
            unitName: 'HMC ANESTHESIOLOGY',
            unitManager: 'Charisse Ramos',
            costCenterName: '317040',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-03-02T00:00:00',
            endDate: '2020-08-21T00:00:00',
            candidateName: 'Max Gouverne',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-25T00:00:00',
            endDate: '2020-05-24T00:00:00',
            candidateName: 'Mackenzie A Gray',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-24T00:00:00',
            endDate: '2020-06-14T00:00:00',
            candidateName: 'Rachel Robilotta',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-20T00:00:00',
            endDate: '2020-04-05T00:00:00',
            candidateName: 'Rachel M Johnson',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-20T00:00:00',
            endDate: '2020-05-24T00:00:00',
            candidateName: 'Gabriel Mercury Isidro',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-15T00:00:00',
            endDate: '2020-05-16T00:00:00',
            candidateName: 'Jose Cuicahua Perez',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-12T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Cecilia Martinez',
            professionName: 'Registered Nurse',
            unitName: '08-7020 Operating Room',
            unitManager: 'Natalie Shiflett, BS, RN, CNOR',
            costCenterName: '7020',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Ronald Marek',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Natali Inboden',
            professionName: 'Registered Nurse',
            unitName: 'UWMC Montlake Cath Lab',
            unitManager: 'Michael Kearney',
            costCenterName: '08-7122',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Jessica Parrish',
            professionName: 'Registered Nurse',
            unitName: '318700 NURSING FLOATS - CC',
            unitManager: 'Kathleen Therein',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Rosemarie Giudice',
            professionName: 'Registered Nurse',
            unitName: '2WH  NEURO ICU',
            unitManager: 'Carrie ferrulli',
            costCenterName: '316015',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Rebecca Webb',
            professionName: 'Registered Nurse',
            unitName: '2WH  NEURO ICU',
            unitManager: 'Carrie ferrulli',
            costCenterName: '316015',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Annabella White',
            professionName: 'Registered Nurse',
            unitName: '08-7440 Bone and Joint Surgery Clinic',
            unitManager: 'Jim  L Arteaga',
            costCenterName: '7440',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-09T00:00:00',
            candidateName: 'Amelia Jester',
            professionName: 'Registered Nurse',
            unitName: 'HMC Psychiatry -5WA, 5WB, 5MB',
            unitManager: 'Timothy Meeks, MN, RN-BC',
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Grecia Zamora',
            professionName: 'Registered Nurse',
            unitName: 'HMC Psychiatry -5WA, 5WB, 5MB',
            unitManager: 'Timothy Meeks, MN, RN-BC',
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Montoya Blenman',
            professionName: 'Registered Nurse',
            unitName: '316072 3W NEURO ACUTE UNIT',
            unitManager: 'Magda Hanna',
            costCenterName: '316072',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-11T00:00:00',
            endDate: '2020-05-17T00:00:00',
            candidateName: 'Julie Fiess',
            professionName: 'Registered Nurse',
            unitName: 'Endoscopy- Digestive Health Center',
            unitManager: 'Jeanine Penberthy',
            costCenterName: '7320',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-10T00:00:00',
            endDate: '2020-05-17T00:00:00',
            candidateName: 'Mark D. Taylor',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-10T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Rafael Trelles',
            professionName: 'Registered Nurse',
            unitName: 'HMC BOARDERS',
            unitManager: 'Jay Sandel',
            costCenterName: '316070',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2595,
            facilityName: 'Northwest Hospital and Medical Center',
            startDate: '2020-02-10T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Bobby Baros',
            professionName: 'Radiology / Cardiology',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-10T00:00:00',
            endDate: '2020-04-11T00:00:00',
            candidateName: 'Jalisa Martin',
            professionName: 'Registered Nurse',
            unitName: '4W REHAB MEDICINE',
            unitManager: 'KATRINA COLLINS',
            costCenterName: '316400',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-10T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Dean Shepard',
            professionName: 'Radiology / Cardiology',
            unitName: 'UWMC Montlake EP Lab',
            unitManager: 'Michael Kearny',
            costCenterName: '08-7715',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-10T00:00:00',
            endDate: '2020-05-08T00:00:00',
            candidateName: 'Stacey Jewell',
            professionName: 'Registered Nurse',
            unitName: '2WH  NEURO ICU',
            unitManager: 'Carrie ferrulli',
            costCenterName: '316015',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-10T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Zaire Burgess-Robinson',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-09T00:00:00',
            endDate: '2020-04-26T00:00:00',
            candidateName: 'Michelle Obrien',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-03T00:00:00',
            endDate: '2020-05-03T00:00:00',
            candidateName: 'Joseph Porter',
            professionName: 'Respiratory / Neuro Diagnostics',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-03T00:00:00',
            endDate: '2020-03-01T00:00:00',
            candidateName: 'Ann Stayer',
            professionName: 'Registered Nurse',
            unitName: '2WH  NEURO ICU',
            unitManager: 'Carrie ferrulli',
            costCenterName: '316015',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-03T00:00:00',
            endDate: '2020-05-03T00:00:00',
            candidateName: 'Lisette Duque',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-02-03T00:00:00',
            endDate: '2020-05-03T00:00:00',
            candidateName: 'Ami Hedlund',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-03T00:00:00',
            endDate: '2020-05-03T00:00:00',
            candidateName: 'Kate Morin',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-03T00:00:00',
            endDate: '2020-05-03T00:00:00',
            candidateName: 'Alexis Slater',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-03T00:00:00',
            endDate: '2020-05-03T00:00:00',
            candidateName: 'Kevin Hoang',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-02-03T00:00:00',
            endDate: '2020-05-16T00:00:00',
            candidateName: 'Sean Turner',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2020-01-31T00:00:00',
            endDate: '2020-05-02T00:00:00',
            candidateName: 'Patrick Kelly',
            professionName: 'Registered Nurse',
            unitName: 'OPERATING ROOM',
            unitManager: 'Michelle A Jackson',
            costCenterName: '317020',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-29T00:00:00',
            endDate: '2020-04-26T00:00:00',
            candidateName: 'Gang Qiu',
            professionName: 'Radiology / Cardiology',
            unitName: 'UWMC Montlake Cath Lab',
            unitManager: 'Michael Kearney',
            costCenterName: '08-7122',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-29T00:00:00',
            endDate: '2020-04-26T00:00:00',
            candidateName: 'Sarah Collins',
            professionName: 'Registered Nurse',
            unitName: '08-7020 Operating Room',
            unitManager: 'Natalie Shiflett, BS, RN, CNOR',
            costCenterName: '7020',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-28T00:00:00',
            endDate: '2020-07-24T00:00:00',
            candidateName: 'Janet Devasia',
            professionName: 'Registered Nurse',
            unitName: '08-8547 UWMC Heart Institute Heart Failure Program',
            unitManager: 'Jason Bjelkengren',
            costCenterName: '8547',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-28T00:00:00',
            endDate: '2020-05-03T00:00:00',
            candidateName: 'Hannah Wyatt',
            professionName: 'Registered Nurse',
            unitName: '08-6096 Labor & Delivery 6S',
            unitManager: 'Gigi Jurich',
            costCenterName: '6096',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-28T00:00:00',
            endDate: '2020-04-26T00:00:00',
            candidateName: 'Tiffany Gustafson',
            professionName: 'Registered Nurse',
            unitName: 'ED',
            unitManager: 'Sue Theiler',
            costCenterName: '7230',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-28T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Cynthia Martin',
            professionName: 'Registered Nurse',
            unitName: '08-7020 CVOR Cardiovascular Operating Room',
            unitManager: 'Natalie Shifle',
            costCenterName: '7020',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-28T00:00:00',
            endDate: '2020-05-03T00:00:00',
            candidateName: 'Sara Mata',
            professionName: 'Registered Nurse',
            unitName: '08-7020 CVOR Cardiovascular Operating Room',
            unitManager: 'Natalie Shifle',
            costCenterName: '7020',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-28T00:00:00',
            endDate: '2020-05-03T00:00:00',
            candidateName: 'Andrew Blunt',
            professionName: 'Registered Nurse',
            unitName: 'UWMC Montlake Cath Lab',
            unitManager: 'Michael Kearney',
            costCenterName: '08-7122',
            cancelReason: null
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-28T00:00:00',
            endDate: '2020-05-10T00:00:00',
            candidateName: 'Nicole Chmiel',
            professionName: 'Registered Nurse',
            unitName: '08-7020 Operating Room',
            unitManager: 'Natalie Shiflett, BS, RN, CNOR',
            costCenterName: '7020',
            cancelReason: null
        }
    ],
    total: 125,
    aggregates: null,
    error: null
} as any as GridReportResponse<FutureReport>;

export const InactiveReportResponse: GridReportResponse<InactiveReport> = {
    data: [
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-23T00:00:00',
            endDate: '2020-01-05T00:00:00',
            candidateName: 'Lauren Rodriguez',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2860,
            facilityName: 'Valley Medical Center',
            startDate: '2019-12-22T00:00:00',
            endDate: '2020-01-03T00:00:00',
            candidateName: 'Benita Moreno',
            professionName: 'Registered Nurse',
            unitName: 'PACU',
            unitManager: 'Christopher Parks',
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-18T00:00:00',
            endDate: '2019-12-29T00:00:00',
            candidateName: 'Kalee Cody',
            professionName: 'Registered Nurse',
            unitName: '318700 NURSING FLOATS - CC',
            unitManager: 'Kathleen Therein',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Anna van Zeijts',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'tyler ward',
            professionName: 'Registered Nurse',
            unitName: 'HMC BOARDERS',
            unitManager: 'Jay Sandel',
            costCenterName: '316070',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-16T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Maddison Kieffer',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-15T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Katrina Andal',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-09T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Jesse Barney',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-09T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Maribel Barney',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-09T00:00:00',
            endDate: '2019-12-15T00:00:00',
            candidateName: 'Jessica Moiseyev',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-02T00:00:00',
            endDate: '2019-12-18T00:00:00',
            candidateName: 'Seth Radnothy',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-02T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Lesley Steplock',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-02T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Lance Biggers',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-02T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Christopher M. Smith',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-01T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Rachel Gallivan',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-01T00:00:00',
            endDate: '2019-12-29T00:00:00',
            candidateName: 'Grace Sellman',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-25T00:00:00',
            endDate: '2019-12-12T00:00:00',
            candidateName: 'Robert Speight',
            professionName: 'Registered Nurse',
            unitName: '2WH  NEURO ICU',
            unitManager: 'Carrie ferrulli',
            costCenterName: '316015',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-25T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Lisette Duque',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-25T00:00:00',
            endDate: '2020-01-05T00:00:00',
            candidateName: 'Amanda Repas',
            professionName: 'Registered Nurse',
            unitName: 'UWMC Montlake Cath Lab',
            unitManager: 'Michael Kearney',
            costCenterName: '08-7122',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-25T00:00:00',
            endDate: '2019-12-15T00:00:00',
            candidateName: 'Megan Turner',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-25T00:00:00',
            endDate: '2020-01-05T00:00:00',
            candidateName: 'Michelle Obrien',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-21T00:00:00',
            endDate: '2019-12-18T00:00:00',
            candidateName: 'Elizabeth Sidney',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-21T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Kara Dwan',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-18T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Kate Morin',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-18T00:00:00',
            endDate: '2019-11-24T00:00:00',
            candidateName: 'Nathan Hemann',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-18T00:00:00',
            endDate: '2019-12-01T00:00:00',
            candidateName: 'Halie Chambers',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-18T00:00:00',
            endDate: '2019-11-24T00:00:00',
            candidateName: 'Tyler Wallace',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-18T00:00:00',
            endDate: '2019-12-15T00:00:00',
            candidateName: 'Tatum Tyler',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-18T00:00:00',
            endDate: '2019-12-15T00:00:00',
            candidateName: 'Kristen Elizabeth Ayers',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-17T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Catherine Szczewski',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-13T00:00:00',
            endDate: '2019-11-24T00:00:00',
            candidateName: "Kristen O'Donovan",
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-11T00:00:00',
            endDate: '2019-11-26T00:00:00',
            candidateName: 'Grace Sellman',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-11T00:00:00',
            endDate: '2019-12-12T00:00:00',
            candidateName: 'Alyssa Bergdoll',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-11T00:00:00',
            endDate: '2019-12-01T00:00:00',
            candidateName: 'Seth Radnothy',
            professionName: 'Registered Nurse',
            unitName: 'OPERATING ROOM',
            unitManager: 'Michelle A Jackson',
            costCenterName: '317020',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-11T00:00:00',
            endDate: '2019-12-20T00:00:00',
            candidateName: 'Maegan Bartman',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-11T00:00:00',
            endDate: '2019-12-29T00:00:00',
            candidateName: 'Patrick Kiernan',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-11T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Sarah Betts',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-11T00:00:00',
            endDate: '2019-12-15T00:00:00',
            candidateName: 'Martha Schmidt',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-11T00:00:00',
            endDate: '2019-12-15T00:00:00',
            candidateName: 'Addie Desmarais',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-04T00:00:00',
            endDate: '2019-11-24T00:00:00',
            candidateName: 'Elana Dawson',
            professionName: 'Registered Nurse',
            unitName: '8E BURN RECONST',
            unitManager: 'Tara Lerew',
            costCenterName: '316084',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-04T00:00:00',
            endDate: '2019-12-18T00:00:00',
            candidateName: 'Rachel A Lee',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-04T00:00:00',
            endDate: '2019-12-08T00:00:00',
            candidateName: 'Alexander R. Cantu',
            professionName: 'Registered Nurse',
            unitName: '2WH  NEURO ICU',
            unitManager: 'Carrie ferrulli',
            costCenterName: '316015',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-04T00:00:00',
            endDate: '2019-12-18T00:00:00',
            candidateName: 'Nicole Masters',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-04T00:00:00',
            endDate: '2019-12-01T00:00:00',
            candidateName: 'Miranda Gold',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-04T00:00:00',
            endDate: '2019-12-15T00:00:00',
            candidateName: 'Samantha Koerner',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-04T00:00:00',
            endDate: '2020-01-07T00:00:00',
            candidateName: 'Emmett Sandiford',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-02T00:00:00',
            endDate: '2019-11-22T00:00:00',
            candidateName: 'Marsha Figueroa',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-10-29T00:00:00',
            endDate: '2019-11-24T00:00:00',
            candidateName: 'Jessica M Wagner',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-10-28T00:00:00',
            endDate: '2019-11-11T00:00:00',
            candidateName: 'Emily Drabek',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-10-28T00:00:00',
            endDate: '2019-11-03T00:00:00',
            candidateName: 'Kara Dwan',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: null
        }
    ],
    total: 1632,
    aggregates: null,
    error: null
} as any as GridReportResponse<InactiveReport>;

export const CancellationsReportResponse: GridReportResponse<CancellationsReport> = {
    data: [
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-14T00:00:00',
            endDate: '2020-04-12T00:00:00',
            candidateName: 'Juan Gomez',
            professionName: 'Registered Nurse',
            unitName: 'University of Washington Liver Transplant Program',
            unitManager: 'Jen Boyer',
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-14T00:00:00',
            endDate: '2020-04-12T00:00:00',
            candidateName: 'Cristine Marte Anda',
            professionName: 'Registered Nurse',
            unitName: 'Neonatal ICU',
            unitManager: null,
            costCenterName: '6016',
            cancelReason: 'Low Census'
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2020-01-06T00:00:00',
            endDate: '2020-01-19T00:00:00',
            candidateName: 'Tarah Miller',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-10T00:00:00',
            endDate: '2020-03-08T00:00:00',
            candidateName: 'Heidi Rebholz',
            professionName: 'Registered Nurse',
            unitName: '318700 Acute Care Float MS/Tele Tele units',
            unitManager: 'Carol Stewart',
            costCenterName: '318700',
            cancelReason: 'Family Emergency'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-12-10T00:00:00',
            endDate: '2020-03-08T00:00:00',
            candidateName: 'Shelby Cecconi',
            professionName: 'Registered Nurse',
            unitName: 'HMC Psychiatry -5WA, 5WB, 5MB',
            unitManager: 'Timothy Meeks, MN, RN-BC',
            costCenterName: null,
            cancelReason: 'Family Emergency'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-12-10T00:00:00',
            endDate: '2020-03-08T00:00:00',
            candidateName: 'Sabrina Sniffen',
            professionName: 'Registered Nurse',
            unitName: '08-6099 8 N Rehab',
            unitManager: 'Lynn Farrier, MCSD/CCC-SL',
            costCenterName: '6099',
            cancelReason: 'Background Check'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-19T00:00:00',
            endDate: '2020-05-24T00:00:00',
            candidateName: 'Barbara Anderson',
            professionName: 'Registered Nurse',
            unitName: 'Endoscopy- Digestive Health Center',
            unitManager: 'Jeanine Penberthy',
            costCenterName: '7320',
            cancelReason: 'Took Perm Position'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-18T00:00:00',
            endDate: '2020-02-15T00:00:00',
            candidateName: 'Dietrich Campbell',
            professionName: 'Surgical Services',
            unitName: 'HMC ANESTHESIOLOGY',
            unitManager: 'Charisse Ramos',
            costCenterName: '317040',
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-12T00:00:00',
            endDate: '2020-02-08T00:00:00',
            candidateName: 'Tara Casillas',
            professionName: 'Radiology / Cardiology',
            unitName: 'UWMC Montlake Cath Lab',
            unitManager: 'Michael Kearney',
            costCenterName: '08-7122',
            cancelReason: 'Job Abandonment'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-05T00:00:00',
            endDate: '2020-02-02T00:00:00',
            candidateName: 'Nicole Kendall',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-05T00:00:00',
            endDate: '2020-02-02T00:00:00',
            candidateName: 'Brittney Gaffney-Butler',
            professionName: 'Registered Nurse',
            unitName: '5EH MED SURG SPEC',
            unitManager: 'Emilija Melezovic',
            costCenterName: '316077',
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-11-05T00:00:00',
            endDate: '2020-02-02T00:00:00',
            candidateName: 'Renee Apostol',
            professionName: 'Registered Nurse',
            unitName: 'HMC Psychiatry -5WA, 5WB, 5MB',
            unitManager: 'Timothy Meeks, MN, RN-BC',
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-05T00:00:00',
            endDate: '2020-02-02T00:00:00',
            candidateName: 'Sandra Quirk',
            professionName: 'Registered Nurse',
            unitName: '7 SA Med / Surg Transplant',
            unitManager: 'Irene Krall, BSN, MHA, Nurse Manager',
            costCenterName: '6072',
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-11-05T00:00:00',
            endDate: '2020-02-08T00:00:00',
            candidateName: 'Alyce Hill',
            professionName: 'Registered Nurse',
            unitName: '7 SA Med / Surg Transplant',
            unitManager: 'Irene Krall, BSN, MHA, Nurse Manager',
            costCenterName: '6072',
            cancelReason: 'Unexcused Absences/Tardiness'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-10-23T00:00:00',
            endDate: '2020-02-01T00:00:00',
            candidateName: 'Katie Buren',
            professionName: 'Registered Nurse',
            unitName: 'Ambulatory Surgical Specialties Clinic',
            unitManager: 'Jennifer R. Mars',
            costCenterName: null,
            cancelReason: 'Performance'
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-10-21T00:00:00',
            endDate: '2020-02-02T00:00:00',
            candidateName: 'Eamon Conheady',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Medication Administration'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-10-16T00:00:00',
            endDate: '2020-01-12T00:00:00',
            candidateName: 'Tammi Morris',
            professionName: 'Pharmacy',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Performance'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-10-09T00:00:00',
            endDate: '2020-01-04T00:00:00',
            candidateName: 'Julie Marie Sell',
            professionName: 'Registered Nurse',
            unitName: 'ED',
            unitManager: 'Sue Theiler',
            costCenterName: '7230',
            cancelReason: 'Took Perm Position'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-10-08T00:00:00',
            endDate: '2020-01-05T00:00:00',
            candidateName: 'Bradley Turner',
            professionName: 'Radiology / Cardiology',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Booking Issue'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-10-08T00:00:00',
            endDate: '2020-01-05T00:00:00',
            candidateName: 'Tarah Miller',
            professionName: 'Registered Nurse',
            unitName: '6 SA Medical and Oncology ICU',
            unitManager: 'Kelly Garth Green',
            costCenterName: '6015',
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-10-08T00:00:00',
            endDate: '2020-01-05T00:00:00',
            candidateName: 'Robert Baxter',
            professionName: 'Registered Nurse',
            unitName: 'OPERATING ROOM',
            unitManager: 'Michelle A Jackson',
            costCenterName: '317020',
            cancelReason: 'Performance Issues'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-10-08T00:00:00',
            endDate: '2020-01-11T00:00:00',
            candidateName: 'Stephen Rayne',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Booking Issue'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-10-07T00:00:00',
            endDate: '2020-01-11T00:00:00',
            candidateName: 'Jeffrey Destache',
            professionName: 'Surgical Services',
            unitName: 'Anesthesia Tech',
            unitManager: null,
            costCenterName: '7040',
            cancelReason: 'Conduct'
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-09-30T00:00:00',
            endDate: '2019-11-30T00:00:00',
            candidateName: 'Marvin Martinez',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Performance'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-09-24T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Destiny Holley',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'EXT',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-09-23T00:00:00',
            endDate: '2019-11-29T00:00:00',
            candidateName: 'Ashley Peshek',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-09-23T00:00:00',
            endDate: '2019-12-22T00:00:00',
            candidateName: 'Takeshia Seward',
            professionName: 'Medical Assistant',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unexcused Absences/Tardiness'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-09-10T00:00:00',
            endDate: '2019-12-15T00:00:00',
            candidateName: 'Colin Feeney',
            professionName: 'Registered Nurse',
            unitName: '5 SA Cardiovascular Critical Care',
            unitManager: 'Kim Yeary',
            costCenterName: '6014',
            cancelReason: 'Went To Another Agency'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-09-09T00:00:00',
            endDate: '2020-01-12T00:00:00',
            candidateName: 'Miracle Frazier',
            professionName: 'Medical Assistant',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Conduct'
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-09-02T00:00:00',
            endDate: '2019-12-15T00:00:00',
            candidateName: 'Angela Stumph',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-08-27T00:00:00',
            endDate: '2019-11-24T00:00:00',
            candidateName: 'Carol Shelley',
            professionName: 'Pharmacy',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Booking Issue'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-08-26T00:00:00',
            endDate: '2019-11-24T00:00:00',
            candidateName: 'Yubak Gurung',
            professionName: 'Surgical Services',
            unitName: 'CENTRAL PROCESSING UNIT',
            unitManager: 'Arnold Campbell',
            costCenterName: '318315',
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'EXT',
            facilityId: 2860,
            facilityName: 'Valley Medical Center',
            startDate: '2019-08-20T00:00:00',
            endDate: '2019-11-23T00:00:00',
            candidateName: 'Christine DeFontes',
            professionName: 'Registered Nurse',
            unitName: 'Wound Care',
            unitManager: 'Jessica Madison',
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-08-20T00:00:00',
            endDate: '2019-11-16T00:00:00',
            candidateName: 'Erica Wilson',
            professionName: 'Surgical Services',
            unitName: 'OPERATING ROOM',
            unitManager: 'Michelle A Jackson',
            costCenterName: '317020',
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-08-20T00:00:00',
            endDate: '2020-02-15T00:00:00',
            candidateName: 'Christina Funaro-Pagani',
            professionName: 'Registered Nurse',
            unitName: '5 SE Cardiac Care Unit',
            unitManager: 'Renee Paquet',
            costCenterName: '6013',
            cancelReason: 'Performance'
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-08-19T00:00:00',
            endDate: '2019-11-24T00:00:00',
            candidateName: 'Madison Beussink',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Family Emergency'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-08-19T00:00:00',
            endDate: '2019-11-17T00:00:00',
            candidateName: 'Nakita Banks',
            professionName: 'Medical Assistant',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-08-06T00:00:00',
            endDate: '2019-11-10T00:00:00',
            candidateName: 'Edward John Calderon',
            professionName: 'Registered Nurse',
            unitName: 'ICU Float Pool',
            unitManager: 'Nicole Solvang - USE HER 1st',
            costCenterName: null,
            cancelReason: 'Took Perm Position'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-07-23T00:00:00',
            endDate: '2019-10-27T00:00:00',
            candidateName: 'Katie Lopez',
            professionName: 'Registered Nurse',
            unitName: '5 SE Cardiac Care Unit',
            unitManager: 'Renee Paquet',
            costCenterName: '6013',
            cancelReason: 'Performance'
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-07-08T00:00:00',
            endDate: '2019-10-20T00:00:00',
            candidateName: 'Justine Veron Fernandez',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Went To Another Agency'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-06-24T00:00:00',
            endDate: '2019-09-28T00:00:00',
            candidateName: 'Ruth Knight',
            professionName: 'Radiology / Cardiology',
            unitName: 'UWMC Montlake Cath Lab',
            unitManager: 'Michael Kearney',
            costCenterName: '08-7122',
            cancelReason: 'Booking Issue'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-06-18T00:00:00',
            endDate: '2019-09-15T00:00:00',
            candidateName: 'Renaldon Perkins',
            professionName: 'Radiology / Cardiology',
            unitName: 'UWMC Montlake EP Lab',
            unitManager: 'Michael Kearny',
            costCenterName: '08-7715',
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-06-17T00:00:00',
            endDate: '2019-09-15T00:00:00',
            candidateName: 'Mara Bennett',
            professionName: 'Registered Nurse',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Booking Issue'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-06-17T00:00:00',
            endDate: '2019-09-15T00:00:00',
            candidateName: 'Susan Herman',
            professionName: 'Medical Assistant',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Performance Issues'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-06-10T00:00:00',
            endDate: '2019-09-08T00:00:00',
            candidateName: 'Reshawn Hayes',
            professionName: 'Respiratory / Neuro Diagnostics',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-06-04T00:00:00',
            endDate: '2019-09-01T00:00:00',
            candidateName: 'Michelle Bernier',
            professionName: 'Registered Nurse',
            unitName: '2WH  NEURO ICU',
            unitManager: 'Carrie ferrulli',
            costCenterName: '316015',
            cancelReason: 'Family Emergency'
        },
        {
            contractType: 'EXT',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-06-03T00:00:00',
            endDate: '2019-09-01T00:00:00',
            candidateName: 'Kristina Passey',
            professionName: 'CNA / Nurse Assistant',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-05-27T00:00:00',
            endDate: '2019-08-25T00:00:00',
            candidateName: 'Jose Fuentes',
            professionName: 'Surgical Services',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 2569,
            facilityName: 'Harborview Medical Center',
            startDate: '2019-05-22T00:00:00',
            endDate: '2019-08-18T00:00:00',
            candidateName: 'Kayanna Moreland-Law',
            professionName: 'Medical Assistant',
            unitName: null,
            unitManager: null,
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        },
        {
            contractType: 'NEW',
            facilityId: 36765,
            facilityName: 'University of Washington Medical Center',
            startDate: '2019-05-21T00:00:00',
            endDate: '2019-08-18T00:00:00',
            candidateName: 'Courtney Green',
            professionName: 'Registered Nurse',
            unitName: 'PreAnesthesia Clinic',
            unitManager: 'Joan DiGiacomo',
            costCenterName: null,
            cancelReason: 'Unable to Fulfill Contract'
        }
    ],
    total: 170,
    aggregates: null,
    error: null
} as any as GridReportResponse<CancellationsReport>;

//Per Diem
export const ActivePoolReportResponse: GridReportResponse<ActivePoolReport> = {
    data: [
        {
            facilityName: 'Per Diem - Harborview Medical Center',
            candidateName: 'Pamela Kaye Fudolig',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Inpatient',
            dateCompliant: '2020-01-03T00:00:00',
            shiftsConfirmed: 0
        },
        {
            facilityName: 'Per Diem - Harborview Medical Center',
            candidateName: 'Lythia Mathisen',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Inpatient',
            dateCompliant: '2019-10-21T00:00:00',
            shiftsConfirmed: 0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            candidateName: 'Kristin Wright',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            dateCompliant: '2019-10-11T00:00:00',
            shiftsConfirmed: 8
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            candidateName: 'Ashima Bhatti',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            dateCompliant: '2019-10-02T00:00:00',
            shiftsConfirmed: 8
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            candidateName: 'Teresa Wheeler',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            dateCompliant: '2019-06-12T00:00:00',
            shiftsConfirmed: 18
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            candidateName: 'Hanieyien Powell',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            dateCompliant: null,
            shiftsConfirmed: 127
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            candidateName: 'SUKHJINDER BAL',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            dateCompliant: null,
            shiftsConfirmed: 48
        },
        {
            facilityName: 'Per Diem - Harborview Medical Center',
            candidateName: 'Roman Yimer',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Inpatient',
            dateCompliant: null,
            shiftsConfirmed: 0
        }
    ],
    total: 8,
    aggregates: null,
    error: null
} as any as GridReportResponse<ActivePoolReport>;

export const OpenShiftsReportResponse: GridReportResponse<OpenShiftsReport> = {
    data: [
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-15T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-14T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-13T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-12T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-11T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-08T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-07T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-06T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-05T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-04T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2020-05-01T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-12-27T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-12-26T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-12-20T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-12-13T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-12-06T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-11-22T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-11-20T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-11-19T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-11-18T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-11-15T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-11-08T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-11-01T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-31T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-30T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-29T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-28T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-10-23T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - UW Medical Center - Roosevelt',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-23T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - UW Medical Center - Roosevelt',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-22T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - UW Medical Center - Roosevelt',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-21T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - UW Medical Center - Roosevelt',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-18T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - UW Medical Center - Roosevelt',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-17T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - UW Medical Center - Roosevelt',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-16T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-10-16T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-30T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-27T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-27T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-26T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-25T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-24T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-23T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-20T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-20T00:00:00',
            shiftStartTime: '2000-01-01T07:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-19T00:00:00',
            shiftStartTime: '2000-01-01T07:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-19T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-18T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-18T00:00:00',
            shiftStartTime: '2000-01-01T07:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-17T00:00:00',
            shiftStartTime: '2000-01-01T07:00:00',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-09-17T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            shiftHours: 8.0
        }
    ],
    total: 188,
    aggregates: null,
    error: null
} as any as GridReportResponse<OpenShiftsReport>;

export const ShiftHistoryReportResponse: GridReportResponse<OpenShiftsReport> = {
    data: [
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-04T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-05T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-06T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-07T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-08T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-11T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Cancellation Confirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-12T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-13T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-14T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-15T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-18T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-18T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-18T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Open Request',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-19T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Open Request',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-19T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-19T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Taifa Peaks',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-20T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-20T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-20T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-21T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-21T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Taifa Peaks',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-21T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Taifa Peaks',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-22T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-22T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-22T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-25T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-25T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            clinicianName: 'Taifa Peaks',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-25T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-25T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Open Request',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-26T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Open Request',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            clinicianName: 'Taifa Peaks',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-26T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-26T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-26T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            clinicianName: 'Taifa Peaks',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-27T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-27T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Open Request',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-27T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-27T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-28T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-28T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            clinicianName: 'Taifa Peaks',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-28T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-28T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Open Request',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            clinicianName: 'Sherryl Holland',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-28T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            clinicianName: 'Sherryl Holland',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-29T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-29T00:00:00',
            shiftStartTime: '2000-01-01T08:30:00',
            statusName: 'Open Request',
            shiftHours: 8.0
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            clinicianName: 'Taifa Peaks',
            specialtyDescription: 'Pharmacist Outpatient',
            shiftStartDate: '2019-03-29T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-29T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-03-29T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-04-01T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medical Center',
            professionName: 'Pharmacy',
            clinicianName: null,
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-04-01T00:00:00',
            shiftStartTime: '2000-01-01T09:00:00',
            statusName: 'Open Request',
            shiftHours: 8.5
        },
        {
            facilityName: 'Per Diem - University of Washington Medicine Eastside Specialty Center',
            professionName: 'Pharmacy',
            clinicianName: 'Hanieyien Powell',
            specialtyDescription: 'Pharmacy Tech Outpatient',
            shiftStartDate: '2019-04-01T00:00:00',
            shiftStartTime: '2000-01-01T08:00:00',
            statusName: 'Reconfirmed',
            shiftHours: 8.0
        }
    ],
    total: 1234,
    aggregates: null,
    error: null
} as any as GridReportResponse<OpenShiftsReport>;

export function getGridReportMockDataByType(reportType: ReportType): GridReportResponse<any> {
    switch (reportType) {
        case ReportType.TravelJobsOpen:
            return OpenOrdersReportResponse;
        case ReportType.TravelJobHistory:
            return OrderHistoryReportResponse;
        case ReportType.TravelAssignmentsCurrent:
            return OnAssignmentReportResponse;
        case ReportType.TravelAssignmentsFuture:
            return FutureReportResponse;
        case ReportType.TravelAssignmentsInactive:
            return InactiveReportResponse;
        case ReportType.TravelAssignmentsCancellations:
            return CancellationsReportResponse;
        case ReportType.PerDiemPoolActive:
            return ActivePoolReportResponse;
        case ReportType.PerDiemShiftsOpen:
            return OpenShiftsReportResponse;
        case ReportType.PerDiemShiftHistory:
            return ShiftHistoryReportResponse;
    }

    return {
        data: [],
        aggregates: null,
        error: null,
        total: 0
    } as GridReportResponse<any>;
}

//Spending
export const SendByMonthReportResponse: ChartReport[] = [
    { hAxis: 'Aug 2019', vAxis: 28105.3 },
    { hAxis: 'Jul 2019', vAxis: 186658.15 },
    { hAxis: 'Jun 2019', vAxis: 11791.03 },
    { hAxis: 'Oct 2018', vAxis: 701213.0 },
    { hAxis: 'Sep 2018', vAxis: 354841.5 },
    { hAxis: 'Aug 2018', vAxis: 441371.5 },
    { hAxis: 'Jul 2018', vAxis: 690524.0 },
    { hAxis: 'Jun 2018', vAxis: 726518.5 },
    { hAxis: 'May 2018', vAxis: 597012.0 },
    { hAxis: 'Apr 2018', vAxis: 585669.5 },
    { hAxis: 'Mar 2018', vAxis: 460294.0 },
    { hAxis: 'Feb 2018', vAxis: 658735.0 },
    { hAxis: 'Jan 2018', vAxis: 172315.0 }
];

export const SpendByLocationReportResponse: ChartReport[] = [
    { hAxis: 'DEMO - General Hospital', vAxis: 2609241.0 },
    { hAxis: 'DUPLICATE', vAxis: 1231344.0 },
    { hAxis: 'Non-Clinical - DEMO - General Hospital', vAxis: 1086328.0 },
    { hAxis: 'TEST Samson Regional Hospital', vAxis: 233175.0 },
    { hAxis: 'TEST Rogers Medical Center', vAxis: 206300.0 },
    { hAxis: 'Elkhart General Hospital', vAxis: 117866.25 },
    { hAxis: 'Memorial Hospital of South Bend', vAxis: 82038.1 },
    { hAxis: 'Community Hospital of Bremen', vAxis: 26650.13 },
    { hAxis: 'United Memorial Medical Center', vAxis: 22106.0 }
];

export const SpendByUnitReportResponse: ChartReport[] = [
    { hAxis: 'ER 6174', vAxis: 1879634.0 },
    { hAxis: 'NICU 12 West', vAxis: 744625.0 },
    { hAxis: 'ICU 3053', vAxis: 515305.0 },
    { hAxis: 'Hospitality and Food Services', vAxis: 502068.0 },
    { hAxis: 'PICU 4', vAxis: 408450.0 },
    { hAxis: 'Security 5580', vAxis: 366593.0 },
    { hAxis: 'IT - System Integration', vAxis: 217667.0 },
    { hAxis: 'OR 5510', vAxis: 170090.0 },
    { hAxis: 'ICU South', vAxis: 148737.5 },
    { hAxis: 'ED North', vAxis: 119950.0 },
    { hAxis: 'ED South', vAxis: 86350.0 },
    { hAxis: 'Physical Therapy 5E', vAxis: 67841.0 },
    { hAxis: 'Med Surg 5W', vAxis: 47862.5 },
    { hAxis: 'Oncology 1201', vAxis: 44212.0 },
    { hAxis: 'Emergency Department', vAxis: 40191.4 },
    { hAxis: 'Labor and Delivery', vAxis: 36575.0 },
    { hAxis: 'Intermediate Care Center', vAxis: 33070.95 },
    { hAxis: '8th Floor Ortho/Neuro Trauma', vAxis: 27148.8 },
    { hAxis: 'Progressive Care Unit (Cardiac Step-Down)', vAxis: 26588.1 },
    { hAxis: '11S - General Medical', vAxis: 23665.28 },
    { hAxis: null, vAxis: 22106.0 },
    { hAxis: 'Laboratory - 6723', vAxis: 20442.13 },
    { hAxis: 'ICU', vAxis: 15747.44 },
    { hAxis: 'Surgical Services (OR)', vAxis: 13496.0 },
    { hAxis: 'Peds 3 North', vAxis: 10428.0 },
    { hAxis: '7th Floor Intermediate', vAxis: 6342.6 },
    { hAxis: 'Medical Surgical', vAxis: 6208.0 },
    { hAxis: 'Heart and Vascular Center 3rd Floor', vAxis: 4748.7 },
    { hAxis: 'Rehab', vAxis: 4519.8 },
    { hAxis: '10S Medical Oncology', vAxis: 2336.0 },
    { hAxis: 'Float Team- MS/Tele', vAxis: 2049.28 }
];

export const SpendBySpecialityReportResponse: ChartReport[] = [
    { hAxis: 'RN-ICU', vAxis: 2205535.44 },
    { hAxis: 'RN-ER', vAxis: 1229841.4 },
    { hAxis: 'PTA - Home Health', vAxis: 922728.0 },
    { hAxis: 'Environmental Services (General Specialty)', vAxis: 572310.0 },
    { hAxis: 'Security Guard-62600 (General Specialty)', vAxis: 379273.0 },
    { hAxis: 'System Analyst', vAxis: 134745.0 },
    { hAxis: 'RN-Medical Surgical', vAxis: 59071.36 },
    { hAxis: 'RN-Step Down', vAxis: 33070.95 },
    { hAxis: 'RN-PCU', vAxis: 26588.1 },
    { hAxis: 'Lab - Phlebotomist', vAxis: 20442.13 },
    { hAxis: 'RN-Operating Room', vAxis: 13496.0 },
    { hAxis: 'RN-Telemetry', vAxis: 11091.3 },
    { hAxis: 'RN-Rehab', vAxis: 4519.8 },
    { hAxis: 'RN-Oncology', vAxis: 2336.0 }
];

export const SpendByCostCenterReportResponse: ChartReport[] = [
    { hAxis: '6174', vAxis: 1879634.0 },
    { hAxis: '31008405', vAxis: 744625.0 },
    { hAxis: '3053', vAxis: 515305.0 },
    { hAxis: '6000', vAxis: 502068.0 },
    { hAxis: '31005840', vAxis: 476291.0 },
    { hAxis: '5580', vAxis: 366593.0 },
    { hAxis: '5590', vAxis: 217667.0 },
    { hAxis: '5510', vAxis: 170090.0 },
    { hAxis: '340', vAxis: 148737.5 },
    { hAxis: '520', vAxis: 119950.0 },
    { hAxis: '540', vAxis: 86350.0 },
    { hAxis: '134', vAxis: 47862.5 },
    { hAxis: '4400', vAxis: 44212.0 },
    { hAxis: null, vAxis: 42548.13 },
    { hAxis: '3785', vAxis: 40191.4 },
    { hAxis: '350', vAxis: 36575.0 },
    { hAxis: '3905', vAxis: 33070.95 },
    { hAxis: '6034', vAxis: 27148.8 },
    { hAxis: '3888', vAxis: 26588.1 },
    { hAxis: '6026', vAxis: 23665.28 },
    { hAxis: '6121', vAxis: 15747.44 },
    { hAxis: '3765', vAxis: 13496.0 },
    { hAxis: '31005820', vAxis: 10428.0 },
    { hAxis: '6040', vAxis: 6342.6 },
    { hAxis: '6729', vAxis: 6208.0 },
    { hAxis: '6047', vAxis: 4748.7 },
    { hAxis: '3960', vAxis: 4519.8 },
    { hAxis: '6041', vAxis: 2336.0 },
    { hAxis: '6013', vAxis: 2049.28 }
];

export const SpendBySupplierReportResponse: ChartReport[] = [
    { hAxis: 'Aya Healthcare', vAxis: 665790.0 },
    { hAxis: 'Cross Country', vAxis: 625816.0 },
    { hAxis: 'Fusion', vAxis: 560644.0 },
    { hAxis: 'Aya', vAxis: 446841.25 },
    { hAxis: 'Aureus Nursing', vAxis: 379374.0 },
    { hAxis: 'Host Healthcare', vAxis: 308210.0 },
    { hAxis: 'Triage', vAxis: 276420.0 },
    { hAxis: 'Trustaff Inc', vAxis: 254511.0 },
    { hAxis: 'Cynet Health', vAxis: 197948.0 },
    { hAxis: 'Accountable Healthcare', vAxis: 186420.0 },
    { hAxis: 'Trusted', vAxis: 182934.0 },
    { hAxis: 'Workbeast', vAxis: 177547.0 },
    { hAxis: 'Optimum Healthcare IT', vAxis: 147039.0 },
    { hAxis: 'RTG Medical', vAxis: 128105.0 },
    { hAxis: 'PPR', vAxis: 126105.0 },
    { hAxis: 'B2B Staffing Services, Inc.', vAxis: 121152.0 },
    { hAxis: 'Soliant Health', vAxis: 114743.0 },
    { hAxis: 'Empower HealthIT LLC', vAxis: 106211.0 },
    { hAxis: 'Flexcare', vAxis: 102468.0 },
    { hAxis: 'Health Care Select', vAxis: 89350.0 },
    { hAxis: 'Vtech Solutions Inc', vAxis: 82988.0 },
    { hAxis: 'Higherground Personnel Services Inc', vAxis: 72503.0 },
    { hAxis: 'Westways', vAxis: 65894.0 },
    { hAxis: 'RCM Technologies', vAxis: 48526.0 },
    { hAxis: 'Lawrence Recruiting Specialists Inc. (LRS Healthca', vAxis: 36650.53 },
    { hAxis: 'Health Carousel Travel Network, LLC', vAxis: 24196.6 },
    { hAxis: 'Aureus Nursing, LLC', vAxis: 22815.2 },
    { hAxis: 'Green Key Solutions LLC', vAxis: 17671.0 },
    { hAxis: 'Medical Staffing Solutions, LLC', vAxis: 10180.1 },
    { hAxis: 'FlexCare, LLC (FlexCare Medical Staffing)', vAxis: 7330.4 },
    { hAxis: 'Travel Nurse Across America, LLC', vAxis: 6732.0 },
    { hAxis: 'Host Healthcare, Inc.', vAxis: 6460.0 },
    { hAxis: 'ProLink Healthcare, LLC (ProLink Travel)', vAxis: 6342.6 },
    { hAxis: 'Fusion Medical Staffing LLC', vAxis: 4862.0 },
    { hAxis: 'SHC Services, Inc. (Supplemental Health Care)', vAxis: 4268.8 }
];

export function getChartReportMockDataByType(reportType: ReportType): ChartReport[] {
    switch (reportType) {
        case ReportType.SpendingByMonth:
            return SendByMonthReportResponse;
        case ReportType.SpendingByLocation:
            return SpendByLocationReportResponse;
        case ReportType.SpendingByUnit:
            return SpendByUnitReportResponse;
        case ReportType.SpendingBySpecialty:
            return SpendBySpecialityReportResponse;
        case ReportType.SpendingByCostCenter:
            return SpendByCostCenterReportResponse;
        case ReportType.SpendingBySupplier:
            return SpendBySupplierReportResponse;
    }

    return [];
}
