
import { Action, createReducer, on } from '@ngrx/store';
import { loadShiftsSuccess, loadTravelers } from 'src/app/dashboard-new/store/actions';
import { ShiftsCount } from 'src/app/dashboard-new/models';

export interface ShiftsState {
    shifts: ShiftsCount;
}

export const initialShiftsState: ShiftsState = {
    shifts: null,
}

export function shiftsReducer(state: ShiftsState, action: Action) {
    return createReducer(
        initialShiftsState,
        on(loadTravelers, (state, action) => (
            {
                ...state
            }
        )),

        on(loadShiftsSuccess, (state, action) => (
            {
                ...state,
                shifts: action.shifts
            }
        ))
    )(state, action);
}

export function reducer(state: ShiftsState | undefined, action: Action) {
    return shiftsReducer(state, action);
}
