import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, switchMap, debounceTime } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'app-search',
    templateUrl: './app-search.component.html',
    styleUrls: ['./app-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppSearchComponent implements OnInit {
    searchInput = new UntypedFormControl();
    searchResult$: Observable<any>;

    constructor() {
        this.searchResult$ = this.searchInput.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            switchMap((searchText) => (searchText ? this.filteredData(searchText) : of([])))
        );
    }

    ngOnInit() {}

    onSelect(event: MatAutocompleteSelectedEvent) {
        const item = event.option.value;
        console.log('Selected', item);
    }
    displayFn(val): string | undefined {
        return val ? val.title : undefined;
    }

    viewAll(group) {
        console.log(group);
    }

    private filteredData(value: string) {
        const searchResult = [
            {
                name: 'Jobs',
                type: 'jobs',
                total: 23,
                items: [
                    {
                        id: '123',
                        title: 'Test',
                        facility: 'Facility',
                        unit: 'Unit'
                    },
                    {
                        id: '123',
                        title: 'Test',
                        facility: 'Facility',
                        unit: 'Unit'
                    }
                ]
            },
            {
                name: 'Caregivers',
                type: 'caregivers',
                total: 1,
                items: [
                    {
                        id: '123',
                        title: 'Test',
                        facility: 'Facility',
                        unit: 'Unit'
                    }
                ]
            }
        ];

        return of(searchResult);
    }
}
