import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { FlatGridColumnDefs } from '../../timecards/models/timecard-bill-rate-column-defs';
import { FlatGridCellItemTypeEnum } from './enums/flat-grid-cell-item-type.enum';

@Component({
    selector: 'ayac-flat-grid',
    templateUrl: './flat-grid.component.html',
    styleUrls: ['./flat-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatGridComponent implements OnInit {
    @Input() dataSource: any[] = [];
    @Input() columnDefs: FlatGridColumnDefs[] = [];
    @Input() tableStylingClass = '';
    @Input() tableDataQaId = '';
    @Input() thCellItemDataQaIdText = '';
    @Input() tdCellItemDataQaIdText = '';
    @Input() tdFooterCellDataQaIdText = '';
    @Input() ariaTableDescriptionId = '';
    @Input() hasFooterRow = false;
    @Input() index = -1;
    @Input() footerRowValues: Array<string | number | boolean | Date> = [];
    cellTypeEnum = FlatGridCellItemTypeEnum;

    columnDefsDisplay: string[] = [];

    constructor() {}

    ngOnInit(): void {
        this.columnDefsDisplay = this.columnDefs.map((def) => def.field);
    }
}
