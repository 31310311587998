import { Action, createReducer, on } from '@ngrx/store';
import { vendorNotesActions } from 'src/app/admin/store/actions/vendor-notes.actions';
import { getInitialVendorNotesState, VendorNotesState } from 'src/app/admin/store/state/vendor-notes.state';
import { LoadingTypes } from 'src/app/shared/models';

export function vendorNotesReducer(state: VendorNotesState, action: Action) {
    return createReducer(
        getInitialVendorNotesState(),

        on(vendorNotesActions.loadVendorNoteTypes, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING,
            noteTypes: []
        })),

        on(vendorNotesActions.loadVendorNoteTypesSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,
            noteTypes: action.types
        })),

        on(vendorNotesActions.loadVendorNoteTypesFailure, (state) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            noteTypes: []
        })),

        on(vendorNotesActions.loadVendorNotesByVendorId, (state, action) => ({
            ...state,
            notes: [],
            loading: LoadingTypes.LOADING
        })),

        on(vendorNotesActions.loadVendorNotesByVendorIdSuccess, (state, action) => ({
            ...state,
            notes: action.notes,
            loading: LoadingTypes.LOADED
        })),

        on(vendorNotesActions.loadVendorNotesByVendorIdFailure, (state, action) => ({
            ...state,
            notes: [],
            loading: LoadingTypes.FAIL
        })),
        on(vendorNotesActions.resetVendorNotes, (state) => ({
            ...state,
            notes: []
        }))
    )(state, action);
}

export function reducer(state: VendorNotesState | undefined, action: Action) {
    return vendorNotesReducer(state, action);
}
