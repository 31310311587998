import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { SortTypes } from 'src/app/shared/models';
import * as actions from 'src/app/admin/store/actions/admin.vendor-candidates-list.actions';
import {
    selectAdminVendorCandidatesListQuery,
    selectAdminVendorCandidatesListQueryForExport
} from 'src/app/admin/store/selectors/admin.vendor-candidates.selectors';
import { VendorCandidateService } from 'src/app/admin/vendor-candidates/vendor-candidate.service';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { buildExportTable } from 'src/app/core/utils/build-export-table';
import { downloadBlob } from 'src/app/core/utils';
import { AdminVendorCandidatesQueryState } from '../state/admin.vendor-candidates.state';

@Injectable()
export class VendorCandidatesListEffect {
    getVendorCandidateList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadAdminVendorCandidatesList, actions.setVendorCandidatesListSearchQuery),
            withLatestFrom(this._store$.select(selectAdminVendorCandidatesListQuery)),
            switchMap(([action, query]) => {
                const { pagination, sortArgs } = this.prepareParams(query);
                const matchArgs = flattenFilter(query.filter);
                return this._vendorCandidatesService.getVendorCandidates(pagination, sortArgs, matchArgs).pipe(
                    map((response) =>
                        actions.loadAdminVendorCandidatesListSuccess({
                            candidates: response
                        })
                    ),
                    catchError((error) => of(actions.loadAdminVendorCandidatesListFailure({ error })))
                );
            })
        )
    );

    exportVendorCandidateList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.exportAdminVendorCandidatesList),
            withLatestFrom(this._store$.select(selectAdminVendorCandidatesListQueryForExport)),
            switchMap(([action, query]) => {
                const { pagination, sortArgs } = this.prepareParams(query);
                const matchArgs = flattenFilter(query.filter);

                return this._vendorCandidatesService.getVendorCandidatesExport(pagination, sortArgs, matchArgs).pipe(
                    map((response) => {
                        const dataToExport = this.getTableDataToExport(response.data);
                        const blob = buildExportTable(dataToExport.titles, dataToExport.data);
                        downloadBlob(blob, 'VendorCandidates.xls');
                        return actions.exportAdminVendorCandidatesListSuccess();
                    }),
                    catchError((error) => of(actions.exportAdminVendorCandidatesListFailure({ error })))
                );
            })
        )
    );

    getVendorCandidateListExpertises$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadAdminVendorCandidateExpertises),
            exhaustMap(() => {
                return this._lookupService.getExpertise().pipe(
                    map((expertises) => actions.loadAdminVendorCandidatesExpertisesSuccess({ expertises: expertises })),
                    catchError((error) => of(actions.loadAdminVendorCandidatesExpertiseFailure({ error })))
                );
            })
        )
    );

    getVendorCandidateListStates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadAdminVendorCandidateStates),
            exhaustMap(() => {
                return this._lookupService.getStates().pipe(
                    map((states) => actions.loadAdminVendorCandidateStatesSuccess({ states })),
                    catchError((error) => of(actions.loadAdminVendorCandidateStatesFailure({ error })))
                );
            })
        )
    );

    getVendorCandidateListProffesions$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadAdminVendorCandidateProfessions),
            exhaustMap(() => {
                return this._lookupService.getProfessions().pipe(
                    map((professions) => actions.loadAdminVendorCandidatesProfessionsSuccess({ professions })),
                    catchError((error) => of(actions.loadAdminVendorCandidatesProfessionsFailure({ error })))
                );
            })
        )
    );

    getVendorCandidateListVendors$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadAdminVendorCandidateVendors),
            exhaustMap(() => {
                return this._lookupService.getAdminVendors().pipe(
                    map((vendors) => actions.loadAdminVendorCandidatesVendorsSuccess({ vendors })),
                    catchError((error) => of(actions.loadAdminVendorCandidatesVendorsFailure({ error })))
                );
            })
        )
    );

    private getTableDataToExport(source) {
        const titles = ['Name', 'Email', 'Vendor', 'Profession'];

        var data = source.map(function (candidate) {
            return [
                candidate.fullName ? candidate.fullName : '',
                candidate.email ? candidate.email : '',
                candidate.vendor ? candidate.vendor : '',
                candidate.profession ? candidate.profession : ''
            ];
        });

        return {
            titles: titles,
            data: data
        };
    }

    private prepareParams(query: AdminVendorCandidatesQueryState): { pagination: any; sortArgs: any } {
        const sortCondition = query.sort && query.sort.length ? query.sort : undefined;

        const pagination = {
            pageSize: query.take,
            skip: query.skip
        };

        const sortArgs = {
            sortField: sortCondition && sortCondition[0].field,
            sortType: sortCondition && (sortCondition[0].dir as SortTypes)
        };

        return { pagination, sortArgs };
    }

    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store<{}>,
        private readonly _vendorCandidatesService: VendorCandidateService,
        private readonly _lookupService: LookupsService
    ) {}
}
