export interface WalkScore {
    status: WalkScoreStatus;
    walkscore?: number;
    description: string | null;
    updated?: Date;
    logo_url: string;
    more_info_icon: string;
    more_info_link: string;
    ws_link: string;
    help_link: string;
    snapped_lat: number;
    snapped_lon: number;
    transit?: SecondaryWalkScore;
    bike?: SecondaryWalkScore;
}

export interface SecondaryWalkScore {
    score?: number;
    description: string | null;
    summary: string | null;
}

export enum WalkScoreStatus {
    Success = 1,
    NotYetAvailable = 2,
    InvalidCoordinates = 30,
    InternalError = 31,
    InvalidApiKey = 40,
    QuotaExceeded = 41,
    IPAddressBlocked = 42
}
