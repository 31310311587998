import { LoadingTypes } from 'src/app/shared/models';
import { Role } from '../models';

export const rolePermissionsStoreKey = 'role-permissions';

export interface RolePermissionsState {
    loadingState: LoadingTypes;
    roles: Role[];
    error: string;
}

export const getInitialRolePermissionsState = (): RolePermissionsState => ({
    loadingState: LoadingTypes.INIT,
    roles: [],
    error: ''
});
