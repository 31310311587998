import { Input, Directive } from "@angular/core";
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';


const flatten = filter => {
    const filters = (filter || {}).filters;
    if (filters) {
        return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
    }
    return [];
};


@Directive()
export abstract class ListFilterBaseRow extends BaseFilterCellComponent {
    @Input() public field: string;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() public data: any[];
    @Input() public textField: string;
    @Input() public valueField: string;
    @Input() public applyFilterOnChange: boolean = true;

    protected abstract supportsMultipleValues: boolean;

    public get selectedValue(): any {
        const filter = this.filterByField(this.field);
        let returnVal: any;
        if (this.supportsMultipleValues) {
            returnVal = filter ? [].concat(filter.value) : null;
        } else {
            returnVal = filter ? filter.value : null;
        }
        
        if (returnVal && returnVal.length === 0) {
            returnVal = null;
        }
        
        return returnVal;
    }

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public onChange(changedValue: any): void {
        if (this.applyFilterOnChange) {
            this.applyFilter(
                changedValue === null ? // value of the default item
                    this.removeFilter(this.field) : // remove the filter
                    this.updateFilter({ // add a filter for the field with the value
                        field: this.field,
                        operator: 'eq',
                        value: changedValue
                    })
            ); // update the root filter
        } else {
            changedValue === null ? // value of the default item
                    this.removeFilter(this.field) : // remove the filter
                    this.updateFilter({ // add a filter for the field with the value
                        field: this.field,
                        operator: 'eq',
                        value: changedValue
                    });
        }
    }
}
