import { Pipe, PipeTransform } from '@angular/core';
import { Unit } from 'src/app/clinical/models';

@Pipe({ name: 'disciplinesName' })
export class DisciplinesNamePipe implements PipeTransform {
    transform(unit: Unit) {
        if (unit && unit.professions && unit.professions.length > 0) {
            return unit.professions.map((t) => t.professionName).join(', ');
        } else {
            return '';
        }
    }
}
