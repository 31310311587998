import { JobDetail } from 'src/app/admin/jobs/models/job-detail.model';
import { JobGridItem } from 'src/app/admin/jobs/models/job-grid-item.model';
import { JobLookup } from 'src/app/admin/jobs/models/job-lookup.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes } from 'src/app/shared/models';
import { FileAttachment } from 'src/app/shared/models/attachment';
import { ReleaseGroup } from 'src/app/shared/models/vendor-releases/release-group.model';

export interface AdminJobsQueryState extends GridSearchQuery {}

export const getInitialAdminJobsQueryState = (): AdminJobsQueryState => ({
    sort: [{ field: 'rateCategoryOrder', dir: 'asc' }],
    take: 10,
    skip: 0
});

export interface AdminJobsState {
    canEditVendorBaseRate: boolean;
    error: string;
    filteredFacilityGroupId: number;
    filteredAllPositionsCount: number;
    filteredOpenPositionsCount: number;
    gridSearchQuery: AdminJobsQueryState;
    jobAttachments: FileAttachment[];
    jobAttachmentPreview?: Blob;
    jobAttachmentTotalPages: number;
    jobDetails?: JobDetail;
    jobs: JobGridItem[];
    jobLookups?: JobLookup;
    loadingState: LoadingTypes;
    releaseGroups: ReleaseGroup[];
    total: number;
}

export const getInitialAdminJobsState = (): AdminJobsState => ({
    canEditVendorBaseRate: false,
    error: '',
    filteredFacilityGroupId: 0,
    filteredAllPositionsCount: 0,
    filteredOpenPositionsCount: 0,
    gridSearchQuery: getInitialAdminJobsQueryState(),
    jobAttachments: [],
    jobAttachmentPreview: undefined,
    jobAttachmentTotalPages: 0,
    jobDetails: undefined,
    jobs: [],
    jobLookups: undefined,
    loadingState: LoadingTypes.INIT,
    releaseGroups: [],
    total: 0
});
