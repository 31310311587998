import { Component, Inject, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { JobReleaseHistory } from 'src/app/admin/jobs/models/job-release-history.model';

@Component({
    selector: 'aya-release-history-dialog',
    templateUrl: './release-history-dialog.component.html',
    styleUrls: ['./release-history-dialog.component.scss']
})
export class JobReleaseHistoryDialogComponent implements AfterViewInit {
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    releaseHistory: MatTableDataSource<JobReleaseHistory> = new MatTableDataSource([]);

    displayedColumns = ['action', 'method', 'agency', 'when', 'who'];

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _data: { history: JobReleaseHistory[]; columns: [] },
        private readonly cdr: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.releaseHistory.data = this._data.history;
        if (this._data.columns !== null && this._data.columns.length > 0) {
            this.displayedColumns = this._data.columns;
        }
        this.releaseHistory.filterPredicate = (data, value) => {
            return data.vendor.operatingName?.toLowerCase().includes(value.trim().toLowerCase());
        };

        this.releaseHistory.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'method':
                    return data.isManual ? 1 : 0;
                case 'action':
                    return data.isReleased ? 1 : 0;
                case 'agency':
                    return data.vendor && data.vendor.operatingName ? data.vendor.operatingName : '';
                case 'who':
                    return data.updatedBy ? data.updatedBy : '';
                case 'when':
                    return data.updatedDate ? data.updatedDate : '';
                default:
                    return 0;
            }
        };

        this.releaseHistory.sort = this.sort;
        this.cdr.detectChanges();
    }

    filterAgency(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.releaseHistory.filter = filterValue;
    }
}
