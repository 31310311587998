import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { adminReducers } from 'src/app/admin/store/reducers/admin.reducers';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { adminEffects } from './store/effects/admin.effects';

@NgModule({
    imports: [StoreModule.forFeature(adminFeatureKey, adminReducers), EffectsModule.forFeature(adminEffects)]
})
export class AdminStoreModule {}
