import { DefaultProjectorFn, MemoizedSelector, createSelector } from '@ngrx/store';
import { GridLookup, GridSearchQuery } from 'src/app/shared/grid/models';
import { selectAyaLeadsLookupFacilities, selectAyaLeadsLookupUnits } from './aya-leads-list-lookups.selectors';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { searchFacilityIdFilterName } from 'src/app/shared/kendo/kendo-filter.constants';
import { UnitLookupConcise } from 'src/app/shared/models/lookups';
import { KendoFindFilter } from 'src/app/shared/kendo/kendo-find-filter.model';
import { selectSubmittalQuery } from './aya-leads-list-query.selectors';
import { filterUnitsByFacility } from 'src/app/shared/utilities/filter-units-by-facility';

export const selectAyaLeadsFacilityFilterValue: MemoizedSelector<
    object,
    number | null,
    DefaultProjectorFn<number | null>
> = createSelector(
    selectAyaLeadsLookupFacilities,
    selectSubmittalQuery,
    (_: GridLookup[], query: GridSearchQuery): number | null => {
        if (!query?.filter?.filters) {
            return null;
        }

        const filters = query.filter.filters as FilterDescriptor[];
        const filter = filters.find((f) => f.field === searchFacilityIdFilterName);
        return filter ? filter.value : null;
    }
);

export const selectIsFacilitySelected: MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> = createSelector(
    selectAyaLeadsFacilityFilterValue,
    (filterValue: number | null): boolean => !!filterValue
);

export const selectUnitFilterTooltip: MemoizedSelector<object, string, DefaultProjectorFn<string>> = createSelector(
    selectIsFacilitySelected,
    (facilityIsSelected: boolean): string => (facilityIsSelected ? null : 'Please select a facility first')
);

export const selectFilteredUnits: MemoizedSelector<
    object,
    UnitLookupConcise[],
    DefaultProjectorFn<UnitLookupConcise[]>
> = createSelector(
    selectSubmittalQuery,
    selectAyaLeadsLookupUnits,
    (query: GridSearchQuery, units: UnitLookupConcise[]): UnitLookupConcise[] => {
        if (!query?.filter?.filters) {
            return [];
        }

        const filters: Array<FilterDescriptor | CompositeFilterDescriptor> = query.filter.filters;

        const facilityFilter: KendoFindFilter = filters.find(
            (filterDescriptor) => (filterDescriptor as FilterDescriptor).field === searchFacilityIdFilterName
        );

        if (!facilityFilter) {
            return [];
        }

        const filteredUnitsByFacility: UnitLookupConcise[] = filterUnitsByFacility(
            facilityFilter as FilterDescriptor,
            units
        );

        return filteredUnitsByFacility;
    }
);

export const selectFacilityFilterDefaultText: MemoizedSelector<
    object,
    string,
    DefaultProjectorFn<string>
> = createSelector(
    selectAyaLeadsLookupFacilities,
    selectIsFacilitySelected,
    (facilities: GridLookup[], facilityIsSelected: boolean): string => {
        return facilities && facilities.length === 1 && facilityIsSelected ? facilities[0].name : 'All facilities';
    }
);
