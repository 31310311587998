import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { SubmittalsRoutingModule } from './submittals-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubmittalsService } from './services/submittals.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { submittalsStoreKey } from './store/submittals.state';
import { submittalsReducer } from './store/reducers';
import { submittalsEffects } from './store/effects';
import { SubmittalsComponent } from './components/submittals/submittals.component';
import { SubmittalDetailsComponent } from './components/submittal-details/submittal-details.component';
import { DeclineSubmittalDialogComponent } from './components/submittal-details/decline-submittal-dialog/decline-submittal-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { SubmittalDetailsInternalComponent } from './components/submittal-details-internal/submittal-details-internal.component';
import { BookCandidateModalComponent } from './components/book-candidate-modal/book-candidate-modal.component';
import { OfferCandidateModalComponent } from './components/offer-candidate-modal/offer-candidate-modal.component';
import { SubmittalDocumentViewerComponent } from './components/submittal-document-viewer/submittal-document-viewer.component';
import { SubmittalEducationSectionComponent } from './components/submittal-details/submittal-education-section/submittal-education-section.component';
import { SubmittalWorkHistorySectionComponent } from './components/submittal-details/submittal-work-history-section/submittal-work-history-section.component';
import { SubmittalEmrSectionComponent } from './components/submittal-details/submittal-emr-section/submittal-emr-section.component';
import { SubmittalCertificationsSectionComponent } from './components/submittal-details/submittal-certifications-section/submittal-certifications-section.component';
import { UpdateStatusDialogComponent } from './components/submittal-details/update-status-dialog/update-status-dialog.component';
import { DiversityInfoTooltipComponent } from './components/diversity-info-tooltip/diversity-info-tooltip.component';
import { SubmittalActivitySectionComponent } from './components/submittal-details/submittal-activity-section/submittal-activity-section.component';
import { DeclineSubmittalInternalDialogComponent } from './components/submittal-details-internal/decline-submittal-internal-dialog/decline-submittal-internal-dialog.component';
import { SubmittalsModalComponent } from './components/submittals-modal/submittals-modal.component';
import { SubmittalOfferDetailsComponent } from 'src/app/submittals/components/submittal-details/submittal-offer-details/submittal-offer-details.component';
import { RescindOfferDialogComponent } from 'src/app/submittals/components/submittal-details/rescind-offer-dialog/rescind-offer-dialog.component';
import { AddOfferNoteDialogComponent } from './components/submittal-details/add-offer-note-dialog/add-offer-note-dialog.component';
import { SubmittalOfferNotesComponent } from './components/submittal-details/submittal-offer-notes/submittal-offer-notes.component';

const COMPONENTS = [
    SubmittalsComponent,
    SubmittalDetailsComponent,
    SubmittalDetailsInternalComponent,
    DeclineSubmittalDialogComponent,
    DeclineSubmittalInternalDialogComponent,
    BookCandidateModalComponent,
    OfferCandidateModalComponent,
    SubmittalDocumentViewerComponent,
    SubmittalEducationSectionComponent,
    SubmittalWorkHistorySectionComponent,
    SubmittalEmrSectionComponent,
    SubmittalCertificationsSectionComponent,
    UpdateStatusDialogComponent,
    DiversityInfoTooltipComponent,
    SubmittalActivitySectionComponent,
    SubmittalOfferDetailsComponent,
    RescindOfferDialogComponent,
    AddOfferNoteDialogComponent,
    SubmittalOfferNotesComponent
];

@NgModule({
    declarations: [...COMPONENTS, SubmittalsModalComponent],
    exports: [...COMPONENTS],
    imports: [
        CommonModule,
        SubmittalsRoutingModule,
        SharedModule,
        StoreModule.forFeature(submittalsStoreKey, submittalsReducer),
        EffectsModule.forFeature(submittalsEffects),
        MatMenuModule
    ],
    providers: [SubmittalsService, DatePipe, TitleCasePipe]
})
export class SubmittalsModule {}
