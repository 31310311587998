import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import AyaIcons from './icons-array.json';

export interface IconData {
    name: string;
    path: string;
}

@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class AyaIconsModule {
    private readonly _icons;
    constructor(private readonly iconRegistry: MatIconRegistry, private readonly sanitizer: DomSanitizer) {
        this._icons = AyaIcons as IconData[];
        this._icons.forEach((icon) => {
            this.iconRegistry.addSvgIcon(icon.name, this.sanitizer.bypassSecurityTrustResourceUrl(icon.path));
        });
    }
}
