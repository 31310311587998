
import { Action, createReducer, on } from '@ngrx/store';
import { loadAwaitingClearanceSuccess } from '../actions/awaiting-clearance.actions';
import { loadTravelers } from '../actions';

export interface AwaitingClearanceState {
    awaitingClearanceCount: number;
}

export const initialAwaitingClearanceState: AwaitingClearanceState = {
    awaitingClearanceCount: 0,
}

export function awaitingClearanceReducer(state: AwaitingClearanceState, action: Action) {
    return createReducer(
        initialAwaitingClearanceState,
        on(loadTravelers, (state, action) => (
            {
                ...state,
            }
        )),

        on(loadAwaitingClearanceSuccess, (state, action) => (
            {
                ...state,
                awaitingClearanceCount: action.awaitingClearanceCount
            }
        ))
    )(state, action);
}

export function reducer(state: AwaitingClearanceState | undefined, action: Action) {
    return awaitingClearanceReducer(state, action);
}
