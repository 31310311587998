import * as actions from 'src/app/admin/store/actions/vendor-facilities.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    VendorFacilitiesState,
    getInitialVendorFacilitiesState
} from 'src/app/admin/store/state/vendor-facilities.state';

const initVendorFacilitiesReducer = createReducer(
    getInitialVendorFacilitiesState(),

    on(actions.loadVendorFacilitiesSuccess, (state, action) => ({
        ...state,
        facilities: action.facilities,
        error: '',
        loadingState: LoadingTypes.LOADED
    })),

    on(actions.loadVendorFacilitiesFailure, (state, action) => ({
        ...state,
        facilities: { data: [], total: 0, filtered: 0 },
        error: JSON.stringify(action.error),
        loadingState: LoadingTypes.FAIL
    })),

    on(actions.loadVendorFacilityIdsSuccess, (state, action) => ({
        ...state,
        ids: action.ids,
        error: '',
        loadingState: LoadingTypes.LOADED
    })),

    on(actions.loadVendorFacilityIdsFailure, (state, action) => ({
        ...state,
        ids: [],
        error: JSON.stringify(action.error),
        loadingState: LoadingTypes.FAIL
    })),

    on(actions.setVendorFacilitiesSearchQuery, (state, action) => ({
        ...state,
        gridSearchQuery: action.searchQuery,
        loadingState: LoadingTypes.LOADING
    })),

    on(actions.loadVendorFacilitiesLookups, (state) => ({
        ...state,
        loadingState: LoadingTypes.LOADING
    })),

    on(actions.loadVendorFacilitiesLookupsSuccess, (state, action) => ({
        ...state,
        lookups: action.lookups,
        loadingState: LoadingTypes.LOADED,
        error: ''
    })),

    on(actions.loadVendorFacilitiesLookupsFailure, (state) => ({
        ...state,
        loadingState: LoadingTypes.FAIL
    })),

    on(actions.updateFacilityStatus, (state, action) => ({
        ...state,
        params: action.params,
        loadingState: LoadingTypes.LOADING
    })),

    on(actions.updateFacilityStatusFailure, (state) => ({
        ...state,
        loadingState: LoadingTypes.FAIL
    })),

    on(actions.updateCtsSigned, (state, action) => ({
        ...state,
        params: action.params,
        loadingState: LoadingTypes.LOADING
    })),

    on(actions.updateCtsSignedFailure, (state) => ({
        ...state,
        loadingState: LoadingTypes.FAIL
    })),

    on(actions.exportSelected, (state) => ({
        ...state,
        loadingState: LoadingTypes.LOADING
    })),

    on(actions.exportSelectedSuccess, (state) => ({
        ...state,
        loadingState: LoadingTypes.LOADED,
        error: ''
    })),

    on(actions.exportSelectedFailure, (state) => ({
        ...state,
        loadingState: LoadingTypes.FAIL
    }))
);

export function vendorFacilitiesReducer(state: VendorFacilitiesState | undefined, action: Action) {
    return initVendorFacilitiesReducer(state, action);
}
