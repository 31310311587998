import {
    getInitialPerdiemSchedulerDetailsState,
    PerdiemSchedulerDetailsState
} from './reducers/perdiemscheduler-details.reducer';
import {
    getInitialPerdiemSchedulerCancelReasonsState,
    PerdiemSchedulerCancelReasonsState
} from './reducers/perdiemscheduler-cancel-reasons.reducer';
import {
    getInitialPerdiemSchedulerCandidatesState,
    PerdiemSchedulerCandidatesState
} from './reducers/perdiemscheduler-candidates.reducer';
import {
    getInitialPerdiemSchedulerFacilitiesState,
    PerdiemSchedulerFacilitiesState
} from './reducers/perdiemscheduler-facilities.reducer';
import {
    getInitialPerdiemSchedulerListState,
    PerdiemSchedulerListState
} from './reducers/perdiemscheduler-list.reducer';
import {
    getInitialPerdiemSchedulerProfessionsState,
    PerdiemSchedulerProfessionsState
} from './reducers/perdiemscheduler-professions.reducer';
import {
    getInitialPerdiemSchedulerQueryState,
    PerdiemSchedulerQueryState
} from './reducers/perdiemscheduler-query.reducer';
import {
    getInitialPerdiemSchedulerSelectedShiftsState,
    PerdiemSchedulerSelectedShiftsState
} from './reducers/perdiemscheduler-selected-shifts.reducer';
import {
    getInitialPerdiemSchedulerSpecialtiesState,
    PerdiemSchedulerSpecialtiesState
} from './reducers/perdiemscheduler-specialties.reducer';
import {
    getInitialPerdiemSchedulerStatesState,
    PerdiemSchedulerStatesState
} from './reducers/perdiemscheduler-states.reducer';
import {
    initialShiftAttachmentsState,
    ShiftsAttachmentsState
} from 'src/app/shifts/store/reducers/shift-attachments.reducer';
import { getInitialDefaultDateFromState, PerdiemSchedulerDefaultDateFromState } from './reducers/perdiemscheduler-min-date.reducer';

export const perdiemSchedulerStoreKey = 'vendor/perdiemscheduler';

export interface PerdiemSchedulerState {
    list: PerdiemSchedulerListState;
    query: PerdiemSchedulerQueryState;
    professions: PerdiemSchedulerProfessionsState;
    specialties: PerdiemSchedulerSpecialtiesState;
    facilities: PerdiemSchedulerFacilitiesState;
    states: PerdiemSchedulerStatesState;
    selectedShifts: PerdiemSchedulerSelectedShiftsState;
    candidates: PerdiemSchedulerCandidatesState;
    cancelReasons: PerdiemSchedulerCancelReasonsState;
    detailShifts: PerdiemSchedulerDetailsState;
    attachments: ShiftsAttachmentsState;
    minDate: PerdiemSchedulerDefaultDateFromState;
}

export const getInitialPerdiemSchedulerState = (): PerdiemSchedulerState => ({
    list: getInitialPerdiemSchedulerListState(),
    query: getInitialPerdiemSchedulerQueryState(),
    professions: getInitialPerdiemSchedulerProfessionsState(),
    specialties: getInitialPerdiemSchedulerSpecialtiesState(),
    facilities: getInitialPerdiemSchedulerFacilitiesState(),
    states: getInitialPerdiemSchedulerStatesState(),
    selectedShifts: getInitialPerdiemSchedulerSelectedShiftsState(),
    candidates: getInitialPerdiemSchedulerCandidatesState(),
    cancelReasons: getInitialPerdiemSchedulerCancelReasonsState(),
    detailShifts: getInitialPerdiemSchedulerDetailsState(),
    attachments: initialShiftAttachmentsState,
    minDate: getInitialDefaultDateFromState()
});
