import { Component, Inject } from '@angular/core';
import { formatAppVersion } from 'src/app/shared/services/version-checker.service';
import { ENV, Environment } from 'src/environments/environment.provider';

@Component({
    selector: 'app-version-number',
    templateUrl: './app-version-number.component.html',
    styleUrls: ['./app-version-number.component.scss']
})
export class VersionNumberComponent {
    readonly currentAppVersion = this.env.production
    ? formatAppVersion(this.env.appVersion).replace(/-\d*$/, '')
    : formatAppVersion(this.env.appVersion);
    readonly currentAppVersionFull = formatAppVersion(this.env.appVersion);

    constructor(
        @Inject(ENV) private readonly env: Environment,
    ) {}
}
