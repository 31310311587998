import { VendorBank } from 'src/app/admin/vendor-details/models/vendor-bank.model';
import { VendorBusinessType } from 'src/app/admin/vendor-details/models/vendor-business-types.model';
import { VendorWebsiteType } from 'src/app/admin/vendor-details/models/vendor-website-type.model';
import { VendorWebsite } from 'src/app/admin/vendor-details/models/vendor-website.model';
import { VendorSpecificContractLineType } from 'src/app/shared/models/contract-line-contract-type-update.model';
import { ContractLineContractType } from 'src/app/shared/models/contract-line-contract-type.model';
import { VendorDetail } from 'src/app/shared/models/vendor-detail.model';
import { SelectedVendorType, VendorType } from 'src/app/shared/models/vendor-type.model';
import { JobSettingsLookupsResponse } from '../../vendor-details/models/job-settings-lookups-response.model';
import { VendorJobSettingsResponse } from '../../vendor-details/models/job-settings-response.model';
import { JobSettingsSaveRequest } from '../../vendor-details/models/job-settings.model';
import { VendorContactsPagination } from 'src/app/shared/models/vendor-contacts-pagination.model';
import { VendorJobRuleDetails, VendorJobRulesList } from '../../vendor-details/profile/models/job-rules.model';
import { LoadingTypes } from 'src/app/shared/models';
import {
    JobRuleProfessionTree,
    JobRuleProfessionTreePayload
} from 'src/app/admin-vendors/models/job-rule-profession-specialties.model';

export interface VendorProfileState {
    loading: LoadingTypes;
    contractLineTypes: ContractLineContractType[];
    vendorContractLineTypes: VendorSpecificContractLineType[];
    details: VendorDetail;
    banks: VendorBank[];
    businessTypes: VendorBusinessType[];
    websiteTypes: VendorWebsiteType[];
    websites: VendorWebsite[];
    hasChanges: boolean;
    error: unknown;
    vendorTypes: VendorType[];
    selectedVendorTypes: SelectedVendorType[];
    jobSettings: JobSettingsSaveRequest | null;
    jobLookups: JobSettingsLookupsResponse | null;
    jobPermissions: VendorJobSettingsResponse | null;
    contactsPagination: VendorContactsPagination;
    jobRulesList: VendorJobRulesList[];
    jobRuleDetails: VendorJobRuleDetails;
    professionTypes: JobRuleProfessionTree;
    professionTreeValues: JobRuleProfessionTreePayload[];
}

export const getInitialVendorProfileState = (): VendorProfileState => ({
    loading: LoadingTypes.INIT,
    contractLineTypes: [],
    vendorContractLineTypes: [],
    details: undefined,
    banks: [],
    businessTypes: [],
    websiteTypes: [],
    websites: [],
    hasChanges: false,
    error: undefined,
    vendorTypes: [],
    selectedVendorTypes: [],
    jobSettings: null,
    jobLookups: null,
    jobPermissions: null,
    contactsPagination: { pageSize: 10, skip: 0, vendorId: null },
    jobRulesList: [],
    jobRuleDetails: null,
    professionTypes: null,
    professionTreeValues: []
});
