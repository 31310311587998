export class PaginationRequest {
    sort?: Sort[] | null;
    filter?: Filter | null;
    take?: number;
    skip?: number | null;

    constructor(
        take: number = 0,
        skip: number | null = null,
        sort: Sort[] | null = null,
        filter: Filter | null = null
    ) {
        this.take = take;
        this.skip = skip;
        this.sort = sort;
        this.filter = filter;
    }
}

export type Direction = 'asc' | 'desc';

export type Logic = 'and' | 'or';

export type Operator = 'startsWith' | 'contains' | 'eq';

export interface Sort {
    field: string;
    dir: Direction;
}

export interface Filter {
    logic: Logic;
    filters: FilterField[];
}

export interface FilterField {
    field: string;
    value: string | number | boolean;
    operator: Operator;
    ignoreCase?: boolean;
}
