import { GridSearchQuery } from './../shared/grid/models/grid-search-query.model';
import { IDialogOptions } from './../shared/models/dialog.models';
import { DialogService } from './../shared/services/dialog.service';
import { ListItem } from './../shared/models/list-item';
import { filter, map, switchMap, takeWhile, withLatestFrom } from 'rxjs/operators';
import { PagingToken } from './../shared/models/paging-token';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { ResourceList } from '../shared/models/internal-pool/resource-list.model';
import * as internalPoolSelectors from './store/selectors';
import * as internalPoolActions from './store/actions';
import { DomainService } from '../shared/services/domain.service';
import { Router } from '@angular/router';
import { DropDownFilterSettings, PopupSettings } from '@progress/kendo-angular-dropdowns';
import { GridStateChangeEvent } from '../shared/grid/models';
import { ResourceListDialogComponent } from './resource-list-dialog/resource-list-dialog.component';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { InternalPoolAuthService } from './services/internal-pool-auth.service';
import { SpecialtyLookup } from '../shared/models/lookups/specialty-lookup.model';
import { ResourcesService } from './services/resources.service';
import { FacilityLookup } from '../shared/models/lookups/facility-lookup.model';
import { loadClientLookups } from '../shifts/store/actions';
import { selectClientLookups } from '../shifts/store/shifts.selectors';
import { PerDiemCancelReason } from '../shared/models/shifts/client-lookups.model';
import { loadSecurityLookups } from 'src/app/dashboard-new/store/actions';
import * as fromCore from "../core/state";
import { CustomSystemFieldLookup } from "../core/models/system-custom-field.model";
import { getFilterValue } from 'src/app/shared/utilities';
import { TotalHeader } from 'src/app/shared/components/total-header-toggler/total-header-toggler.component';
import { FeatureFlag } from '../shared/models/enums/feature-flag.enum';
import { FilterParams } from '../shared/models/shifts';

@Component({
    selector: 'ayac-internal-pool',
    templateUrl: './internal-pool.component.html',
    styleUrls: ['./internal-pool.component.scss']
})
export class InternalPoolComponent implements OnInit, OnDestroy {
    environment: string;
    resources$: Observable<PagingToken<ResourceList[]>>;
    isLoading$: Observable<boolean>;
    resourcesQuery$: Observable<GridSearchQuery>;
    featureFlag = FeatureFlag;
    professions$: Observable<ListItem[]>;
    specialties$: Observable<SpecialtyLookup[]>;
    specialtiesFiltered$: Observable<SpecialtyLookup[]>;
    facilities$: Observable<FacilityLookup[]>;
    facilityFilters$: Observable<ListItem[]>;
    statuses$: Observable<ListItem[]>;
    availabilityLookups$: Observable<ListItem[]>;
    epicSkillLookups$: Observable<ListItem[]>;
    resourceTypes$: Observable<ListItem[]>;
    languages$: Observable<ListItem[]>;
    selectedFacilityName: string;
    selectedSpecialtyName: string;
    selectedCustomPreferredLocation: string;
    perDiemCancelReasonsByFacility$: Observable<Array<PerDiemCancelReason>>;
    isNyuSystem$: Observable<boolean>;
    customFieldsLookup$: Observable<CustomSystemFieldLookup[]>;
    resourceTotals$: Observable<TotalHeader[]>;

    preferredLocationLookups$: Observable<ListItem[]>;

    canEditInternalPool: boolean;
    showAddWorker$: Observable<boolean>;
    isArchiveFilter$: Observable<boolean>;

    componentActive = true;
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };
    popupSettings: PopupSettings = {
        popupClass: 'hide-selected'
    };
    systemId: number;
    removeGridFieldsForNyu$: Observable<boolean>;

    constructor(
        private readonly store: Store<{}>,
        private readonly router: Router,
        private readonly dialogService: DialogService,
        private readonly internalPoolAuthService: InternalPoolAuthService,
        domainService: DomainService,
        private readonly resourcesService: ResourcesService
    ) {
        this.environment = domainService.environment();
    }

    ngOnInit() {
        this.store.dispatch(internalPoolActions.loadSystemIdWithLookupsAndResources());
        this.store.dispatch(loadClientLookups());
        this.store.dispatch(loadSecurityLookups());
        this.store.dispatch(internalPoolActions.loadIsNyuSystem());
        this.perDiemCancelReasonsByFacility$ = this.store.select(selectClientLookups).pipe(
            map(lookups => lookups.perDiemCancelReasonsByFacility));
        this.resourcesQuery$ = this.store.pipe(select(internalPoolSelectors.selectResourcesQuery));
        this.resources$ = this.store.pipe(select(internalPoolSelectors.selectResourcesData));
        this.professions$ = this.store.pipe(select(internalPoolSelectors.selectProfessionLookups));
        this.specialties$ = this.store.pipe(select(internalPoolSelectors.selectSpecialtiesLookups));
        this.facilities$ = this.store.pipe(select(internalPoolSelectors.selectFacilitiesLookups));
        this.statuses$ = this.store.pipe(select(internalPoolSelectors.selectStatusesLookups));
        this.resourceTypes$ = this.store.select(internalPoolSelectors.selectResourceTypesLookups);
        this.availabilityLookups$ = this.store.pipe(select(internalPoolSelectors.selectAvailabilities));
        this.customFieldsLookup$ = this.store.select(fromCore.getCustomSystemFields);
        this.languages$ = this.store.pipe(select(internalPoolSelectors.selectLanguages));
        this.isLoading$ = this.store.pipe(select(internalPoolSelectors.selectIsResourcesGridLoading));
        this.isNyuSystem$ = this.store.select(internalPoolSelectors.selectIsNyuSystem);
        this.specialtiesFiltered$ = this.resourcesQuery$.pipe(
            map((query) => {
                const filters = query.filter.filters as FilterDescriptor[];
                const filter = filters.find((f: FilterDescriptor) => f.field === 'professionId');
                return filter ? filter.value : null;
            }),
            switchMap((professionId) =>
                this.specialties$.pipe(
                    map((specialties) =>
                        specialties.filter(s => s.professionExpertiseCodes.includes(professionId))
                    ))
            )
        );
        this.resourceTotals$ = combineLatest([
            this.resources$,
            this.resourcesQuery$
        ]).pipe(map(([data, query]) => {
            const showArchive = getFilterValue('showArchive', query.filter);
            return [{
                label: 'Opened',
                field: 'showArchive',
                value: false,
                total: showArchive ? null : data.total
            }, {
                label: 'Archived',
                field: 'showArchive',
                value: true,
                total: showArchive ? data.total : null
            }]
        }))

        /** Security */
        this.canEditInternalPool = this.internalPoolAuthService.canEditResource();

        this.isArchiveFilter$ = this.resourcesQuery$.pipe(
            map(query => 
                getFilterValue('showArchive', query.filter) ?? false
            )
        );

        this.showAddWorker$ = this.isArchiveFilter$.pipe(
            map(showArchive => {
                return this.canEditInternalPool && !showArchive;
            })
        );

        // Get filter names for tricky sorting
        this.getSelectedFacilityName();
        this.getSelectedSpecialtyName();
        this.getSelectedCustomPreferredLocation();
        this.facilityFilters$ = this.facilities$.pipe(
            map((facilities) => {
                return facilities.map((facility) => ({ id: facility.id, name: facility.name }));
            })
        );

        this.store.select(internalPoolSelectors.selectSystemId)
            .pipe(
                takeWhile(() => this.componentActive),
                filter(systemId => systemId > 0),
            )
            .subscribe(systemId => this.systemId = +systemId);

        this.epicSkillLookups$ = combineLatest([
            this.customFieldsLookup$
        ]).pipe(
            map(([customSystemFields]) => {
                if (customSystemFields.length > 0) {
                    const filteredField = customSystemFields.filter(cf => cf && cf.name === 'EpicSkills')[0];
                    return filteredField.fieldValues;
                }
                return null;
            })
        );

        this.preferredLocationLookups$ = this.store.select(fromCore.getCustomSystemFieldValue('PreferredLocation'));

        this.removeGridFieldsForNyu$ = this.isNyuSystem$.pipe(takeWhile(() => this.componentActive));
    }

    onAddInternalPoolClick() {
        if (this.canEditInternalPool) {
            this.router.navigate(['/client', 'internalpool', 'edit', 0]);
        }
    }

    onRowClicked(row: ResourceList) {

        this.router.navigate(['/client', 'internalpool', 'edit', row.id]);

    }

    onDataStateChange(query: GridStateChangeEvent) {
        this.store.dispatch(internalPoolActions.setResourcesQueryAndLoadResources({ query, systemId: this.systemId }));
    }

    onFacilityClick(resource: string, facilities: string[]) {
        return this.dialogService.openDialog(
            ResourceListDialogComponent,
            {
                data: {
                    resourceName: resource,
                    listTitle: 'Facilities',
                    list: facilities
                },
                width: '33%'
            } as IDialogOptions<any>
        );
    }

    onSpecialtyClick(resource: string, specialties: string[]) {
        return this.dialogService.openDialog(
            ResourceListDialogComponent,
            {
                data: {
                    resourceName: resource,
                    listTitle: 'Specialties',
                    list: specialties
                },
                width: '33%'
            } as IDialogOptions<any>
        );
    }

    onPreferedLocationsClick(resource: string, locations: ListItem[]) {
        const list = locations.map(l => l.name);
        return this.dialogService.openDialog(
            ResourceListDialogComponent,
            {
                data: {
                    resourceName: resource,
                    listTitle: 'Preferred Locations',
                    list
                 },
                width: '33%'
            } as IDialogOptions<any>
        );
    }

    resourceStatus(resource: ResourceList) {
        return this.resourcesService.getStatus(resource);
    }

    // Grab selected facility filter name to display it correctly in the grid.
    // This is workaround to implement tricky sorting in facility column when
    // selected facility is displayed first.
    private getSelectedFacilityName() {
        this.isLoading$
            .pipe(
                takeWhile(() => this.componentActive),
                filter(loading => !loading),
                withLatestFrom(this.facilities$, this.resourcesQuery$),
                map(([, fl, query]) => {
                    const facilityFilter = query.filter.filters.find(x => (x as FilterDescriptor).field === 'facilityId');
                    return facilityFilter ? (fl.find(x => x.id === (facilityFilter as FilterDescriptor).value).name) : null;
                }))
            .subscribe(x => this.selectedFacilityName = x);
    }

    // The same solution as for facility name
    getSelectedCustomPreferredLocation() {
        this.isLoading$
            .pipe(
                takeWhile(() => this.componentActive),
                filter(loading => !loading),
                withLatestFrom(this.customFieldsLookup$, this.resourcesQuery$),
                map(([, fl, query]) => {
                    const preferredLocationFilter = query.filter.filters.find(x => (x as FilterDescriptor).field === 'customPreferredLocationIds');
                    return preferredLocationFilter ? (fl.find(x => x.id === (preferredLocationFilter as FilterDescriptor).value)?.name) : null;
                }))
            .subscribe(x => this.selectedCustomPreferredLocation = x);
    }

    private getSelectedSpecialtyName() {
        this.isLoading$
            .pipe(
                takeWhile(() => this.componentActive),
                filter(loading => !loading),
                withLatestFrom(this.specialties$, this.resourcesQuery$),
                map(([, fl, query]) => {
                    const specialtyFilter = query.filter.filters.find(x => (x as FilterDescriptor).field === 'expertiseId');
                    return specialtyFilter ? (fl.find(x => x.id == (specialtyFilter as FilterDescriptor).value).name) : null;
                }))
            .subscribe(x => this.selectedSpecialtyName = x);
    }

    ngOnDestroy() {
        this.componentActive = false;
    }

    onUnarchive(item: ResourceList) {
        this.store.dispatch(internalPoolActions.unarchiveWorkerConfirmation({
            id: item.id,
            navigateBack: false,
            refreshList: true
        }));
    }    
    
    get canArchiveResource() {
        return this.internalPoolAuthService.canArchiveResource();
    }
}
