import { Component, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'ayac-dropdown-multiselect',
    templateUrl: './dropdown-multiselect.component.html',
    styleUrls: ['./dropdown-multiselect.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DropdownMultiselectComponent),
        multi: true
    }]
})
export class DropdownMultiselectComponent implements ControlValueAccessor {
    @ViewChild('select') select: MatSelect;

    @Output() onSelected = new EventEmitter<unknown[]>();

    @Input() data: Array<object> = [];
    @Input() required: boolean = false;
    @Input() placeholder?: string;
    @Input() hasSelectAll: boolean = false;
    @Input() textField: string = 'text';
    @Input() valueField: string = 'value';
    @Input() width: number;

    selected: Array<unknown> = [];
    _prevSelected: Array<unknown> = [];
    selectedText: string = '';
    isSelectedAll: boolean = false;
    isIndeterminate: boolean = false;
    isDisabled: boolean = false;
    _onChanged?: (_: Array<unknown>) => void;

    constructor() { }


    writeValue(val: unknown[]): void {
        this.selected = val ?? [];
        this.updateSelectedAll();
        this.updateSelectedText();
    }

    registerOnChange(fn: any): void {
        this._onChanged = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    onSelect(event: MatSelectChange) {
        this.selected = event.value;
        this.updateSelectedAll();
        this.updateSelectedText();
        if (typeof this._onChanged === 'function') {
            this._onChanged(this.selected);
        }
    }

    updateSelectedAll() {
        this.isSelectedAll = this.data.length === this.selected.length;
        this.isIndeterminate = this.selected.length > 0
            && this.selected.length < this.data.length;
    }

    updateSelectedText() {
        if (this.selected.length === 1) {
            const selectedItem = this.getItem(this.selected[0]);
            this.selectedText = selectedItem[this.textField]
        }
        else if (this.data.length === this.selected.length) {
            this.selectedText = 'All';
        }
        else if (this.selected.length === 0) {
            this.selectedText = 'All';
        }
        else {
            this.selectedText = `${this.selected.length} selected`;
        }
    }

    onSelectAll(selected: boolean): void {
        if (selected) {
            this.select.options.forEach((item: MatOption) => item.select());
        } else {
            this.select.options.forEach((item: MatOption) => item.deselect());
        }
    }

    onPanelToggle(opened: boolean): void {
        if (opened) {
            this._prevSelected = this.selected.slice();
        }
        else if (this.hasChanges) {
            this.onSelected.emit(this.selected);
        }
    }

    private get hasChanges(): boolean {
        const current = new Set(this.selected);
        const previous = new Set(this._prevSelected);
        const sameSize = current.size === previous.size;
        const everyMatch = Array.from(current).every(v => previous.has(v));

        return !(sameSize && everyMatch)
    }

    private getItem(value: unknown): object {
        return this.data.find(d => d[this.valueField] === value);
    }

}
