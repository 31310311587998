import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes } from 'src/app/shared/models';
import { FileType } from 'src/app/shared/models/file-type.model';

export const vendorCandidateFileStoreKey = 'vendor-candidates-detail-files';

export interface VendorCandidateFileState {
    loading: LoadingTypes;
    candidateFiles: GridDataResult;
    gridSearchQuery: GridSearchQuery;
    fileTypes: FileType[];
    candidateId: number;
    error: unknown;
    isDeleting: boolean;
    deletingFileIds: number[];
    isDeleteSuccess?: boolean;
}

export const getInitialVendorCandidateFileState = (): VendorCandidateFileState => ({
    loading: LoadingTypes.INIT,
    candidateFiles: {
        data: [],
        total: 0,
        filtered: 0
    } as GridDataResult,
    candidateId: null,
    gridSearchQuery: getInitialVendorCandidateFileQueryState(),
    fileTypes: [],
    error: undefined,
    isDeleting: false,
    deletingFileIds: []
});

const getInitialVendorCandidateFileQueryState = (): GridSearchQuery => ({
    sort: [],
    filter: null,
    take: 10,
    skip: 0,
    groups: []
});
