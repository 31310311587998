import { ResourcesLookupsState, initialResourcesLookupsState } from './reducers/resources-lookups.reducer';
import { ResourcesState, initialResourcesState } from './reducers/resources.reducer';
import { ResourceDetailsState, initialDetailsState } from './reducers/resource-details.reducer';
import { InternalPoolSystemState, initialInternalPoolSystemState } from './reducers/system.reducer';
import { ResourceAssignmentsState, initialAssignmentsState } from './reducers/resource-assignments.reducer';
import { ResourceDocumentsState, initialDocumentsState } from './reducers/resource-documents.reducer';
import { FacilityLookupsState, initialState as initialFacilityLookupsState } from './reducers/resource-facility-lookups.reducer';
import {
    initialSecurityLookupsState,
    SecurityLookupsState
} from "../../dashboard-new/store/reducers/security-lookups.reducer";

export const internalPoolStoreKey = 'internalpool';

export interface InternalPoolState {
    resources: ResourcesState,
    lookups: ResourcesLookupsState,
    details: ResourceDetailsState
    system: InternalPoolSystemState,
    assignments: ResourceAssignmentsState,
    documents: ResourceDocumentsState,
    securityLookups: SecurityLookupsState,
    facilityLookups: FacilityLookupsState,
}

export const initialInternalPoolState = (): InternalPoolState => ({
    resources: initialResourcesState,
    lookups: initialResourcesLookupsState,
    details: initialDetailsState,
    system: initialInternalPoolSystemState,
    assignments: initialAssignmentsState,
    documents: initialDocumentsState,
    securityLookups: initialSecurityLookupsState,
    facilityLookups: initialFacilityLookupsState
});
