import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Utilities, downloadBlob } from 'src/app/core/utils';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { FacilityAssociationsService } from 'src/app/facilities/services/facility-associations.service';
import * as actions from 'src/app/facilities/store/actions/association-vendors.actions';
import { SortTypes } from 'src/app/shared/models';
import * as selectors from 'src/app/facilities/store/selectors/association-vendors.selectors';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { mapFilters } from 'src/app/shared/grid/utils/map-filters';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { FacilitiesService } from 'src/app/facilities/services/facilities.service';

@Injectable()
export class FacilityAssociationVendorsEffects {
    loadFacilityAssociationVendors$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadFacilityAssociationVendors, actions.setFacilityAssociationVendorsSearchQuery),
            withLatestFrom(this._store$.select(selectors.selectFacilityAssociationVendorsGridSearchQuery)),
            switchMap(([action, query]) => {
                const filters = this.setFilters(query, action.hospitalSystemId, action.isActive);
                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };
                const sortArgs = {
                    sortField: query.sort && query.sort[0].field,
                    sortType: query.sort && (query.sort[0].dir as SortTypes)
                };
                const matchArgs = flattenFilter({ ...query.filter, filters });

                return this._facilityAssociationsService
                    .getFacilityAssociationVendors(pagination, sortArgs, matchArgs)
                    .pipe(
                        map((res) =>
                            actions.loadFacilityAssociationVendorsSuccess({
                                vendors: res.data,
                                total: res.total,
                                filtered: res.filtered
                            })
                        ),
                        catchError((err) => {
                            return of(actions.loadFacilityAssociationVendorsFailure({ error: err }));
                        })
                    );
            })
        );
    });

    updateVendorFacilitiesStatus$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.updateVendorFacilitiesStatus),
            exhaustMap((action) =>
                this._facilitiesService.updateVendorFacilitiesStatus(action.params).pipe(
                    map(() => actions.updateVendorFacilitiesStatusSuccess()),
                    catchError((err) => {
                        return of(actions.updateVendorFacilitiesStatusFailure({ error: err }));
                    })
                )
            )
        )
    );

    exportVendors$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.exportAssociationVendorsAll),
            withLatestFrom(this._store$.select(selectors.selectFacilityAssociationVendorsGridSearchQuery)),
            switchMap(([action, query]) => {
                const filters = this.setFilters(query, action.hospitalSystemId, action.isActive);

                const sortArgs = {
                    sortField: query.sort && query.sort[0].field,
                    sortType: query.sort && (query.sort[0].dir as SortTypes)
                };

                const matchArgs = flattenFilter({ ...query.filter, filters });

                return this._facilityAssociationsService.exportAllVendors(sortArgs, matchArgs).pipe(
                    map((blob) => actions.exportAssociationVendorsAllSuccess({ blob })),
                    catchError((err) => {
                        return of(actions.exportAssociationVendorsAllFailure({ error: err }));
                    })
                );
            })
        )
    );

    exportSelected$ = createEffect((): any =>
        this._actions$.pipe(
            ofType(actions.exportAssociationVendorsSelected),
            mergeMap((action) =>
                this._facilityAssociationsService
                    .exportSelectedVendors(action.hospitalSystemId, action.vendorIds, action.isActive)
                    .pipe(
                        map((blob) => actions.exportAssociationVendorsSelectedSuccess({ blob })),
                        catchError((error) => of(actions.exportAssociationVendorsSelectedFailure({ error })))
                    )
            )
        )
    );

    loadVendorListStates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadVendorListStates),
            exhaustMap(() => {
                return this._lookupService.getStates().pipe(
                    map((states) => actions.loadVendorListStatesSuccess({ states })),
                    catchError((error) => of(actions.loadVendorListStatesFailure({ error })))
                );
            })
        )
    );

    exportAssociationVendorsAllSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.exportAssociationVendorsAllSuccess),
                map((action) => {
                    return downloadBlob(action.blob, 'vendors.xlsx');
                })
            ),
        { dispatch: false }
    );

    exportAssociationSelectedVendorsSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.exportAssociationVendorsSelectedSuccess),
                tap((action) => Utilities.downloadBlob(action.blob, 'vendors.xlsx'))
            );
        },
        { dispatch: false }
    );

    loadFacilityAssociationVendorsFailure$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.loadFacilityAssociationVendorsFailure),
                map((action) => {
                    return this._dialogService.openSnackBarError(action.error.message);
                })
            ),
        { dispatch: false }
    );

    private setFilters(query: GridSearchQuery, hospitalSystemId: number, isActive: boolean): any {
        const filters =
            query.filter && query.filter.filters
                ? mapFilters(query.filter.filters, {
                      status: 'statusId'
                  })
                : query.filter.filters;

        filters.push({
            field: 'hospitalSystemId',
            operator: 'eq',
            value: hospitalSystemId
        });

        filters.push({
            field: 'isActiveForContractLine',
            operator: 'eq',
            value: isActive
        });

        return filters;
    }

    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store,
        private readonly _dialogService: DialogService,
        private readonly _lookupService: LookupsService,
        private readonly _facilitiesService: FacilitiesService,
        private readonly _facilityAssociationsService: FacilityAssociationsService
    ) {}
}
