export abstract class StateParams {
    [key: string]: string;
}

export function stateParamsFactory(i: any) {
    return i.get('$stateParams');
}

export const stateParamsProvider = {
    provide: StateParams,
    useFactory: stateParamsFactory,
    deps: ['$injector']
};
