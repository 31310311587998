import { DefaultProjectorFn, MemoizedSelector, createSelector } from '@ngrx/store';
import { AyaLeadsListQueryState } from '../../reducers/aya-leads-list-query.reducer';
import { selectAyaLeadsFeature } from './aya-leads-list.selectors';
import { AyaLeadsState } from '../../aya-leads.state';
import { selectAyaLeadsLookupFacilities, selectAyaLeadsLookupUnits } from './aya-leads-list-lookups.selectors';
import { GridLookup, GridSearchQuery } from 'src/app/shared/grid/models';
import { UnitLookupConcise } from 'src/app/shared/models/lookups';
import { KendoFindFilter } from 'src/app/shared/kendo/kendo-find-filter.model';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { searchFacilityIdFilterName, searchUnitFilterName } from 'src/app/shared/kendo/kendo-filter.constants';
import { filterUnitsByFacility } from 'src/app/shared/utilities/filter-units-by-facility';

const selectAyaLeadsListQuery: MemoizedSelector<
    object,
    AyaLeadsListQueryState,
    DefaultProjectorFn<AyaLeadsListQueryState>
> = createSelector(selectAyaLeadsFeature, (state: AyaLeadsState): AyaLeadsListQueryState => state.query);

// after implementing https://ayadev.atlassian.net/browse/PERM-729, rename to "selectSubmittalQuery"
export const selectSubmittalQuery: MemoizedSelector<
    object,
    AyaLeadsListQueryState,
    DefaultProjectorFn<AyaLeadsListQueryState>
> = createSelector(
    selectAyaLeadsListQuery,
    selectAyaLeadsLookupFacilities,
    selectAyaLeadsLookupUnits,
    (queryState: AyaLeadsListQueryState, facilities: GridLookup[], units: UnitLookupConcise[]): GridSearchQuery => {
        if (!queryState?.filter?.filters) {
            return queryState;
        }

        const query: GridSearchQuery = {
            // for the sake of performance, only copying the objects in state that I need to mutate
            ...queryState,
            filter: {
                ...queryState.filter,
                filters: [...queryState.filter.filters]
            }
        };

        let facilityFilter: KendoFindFilter = query.filter.filters.find(
            (filterDescriptor) => (filterDescriptor as FilterDescriptor).field === searchFacilityIdFilterName
        );
        const unitFilter: KendoFindFilter = query.filter.filters.find(
            (filterDescriptor) => (filterDescriptor as FilterDescriptor).field === searchUnitFilterName
        );

        if (facilities && facilities.length === 1 && !facilityFilter) {
            facilityFilter = {
                field: searchFacilityIdFilterName,
                operator: 'eq',
                value: [facilities[0].id]
            };
            query.filter.filters.push(facilityFilter);
        }

        if (!unitFilter) {
            return query;
        }

        if (!facilityFilter) {
            // if no facility is inside of a filter, then unit filters should be cleared
            query.filter.filters = query.filter.filters.filter(
                (filterDescriptor) => (filterDescriptor as FilterDescriptor).field !== searchUnitFilterName
            );
            return query;
        }

        const unitIdInFilter = (unitFilter as FilterDescriptor).value;
        const filteredUnitsByFacility: UnitLookupConcise[] = filterUnitsByFacility(
            facilityFilter as FilterDescriptor,
            units
        );
        const foundUnitInFilter = filteredUnitsByFacility.find((u) => u.id === unitIdInFilter);
        if (!foundUnitInFilter) {
            // if currently selected unit could not be found inside of a facility, then remove unit filter from query
            query.filter.filters = query.filter.filters.filter(
                (filterDescriptor) => (filterDescriptor as FilterDescriptor).field !== searchUnitFilterName
            );
        }

        return query;
    }
);
