import { createAction, props } from '@ngrx/store';
import { VendorWebsiteType } from 'src/app/admin/vendor-details/models/vendor-website-type.model';
import { VendorWebsite } from 'src/app/admin/vendor-details/models/vendor-website.model';

const loadVendorProfileWebsites = createAction('[VENDOR PROFILE WEBSITES COMPONENT] Load Vendor Profile Websites By Vendor Id', props<{ vendorId: number }>());
const loadVendorProfileWebsitesSuccess = createAction('[VENDOR PROFILE WEBSITES COMPONENT] Load Vendor Profile Websites By Vendor Id Success', props<{ sites: VendorWebsite[] }>());
const loadVendorProfileWebsitesFailure = createAction('[VENDOR PROFILE WEBSITES COMPONENT] Load Vendor Profile Websites By Vendor Id Failure', props<{ error: unknown }>());

const loadVendorProfileWebsiteTypes = createAction('[VENDOR PROFILE WEBSITES COMPONENT] Load Vendor Profile Website Types');
const loadVendorProfileWebsiteTypesSuccess = createAction('[VENDOR PROFILE WEBSITES COMPONENT] Load Vendor Profile Website Types Success', props<{ types: VendorWebsiteType[] }>());
const loadVendorProfileWebsiteTypesFailure = createAction('[VENDOR PROFILE WEBSITES COMPONENT] Load Vendor Profile Website Types Failure', props<{ error: unknown }>());

const updateWebsite = createAction('[VENDOR PROFILE WEBSITES COMPONENT] Update Vendor Profile Website', props<{ siteId: number, siteType: number, siteUrl: string }>());
const deleteWebsite = createAction('[VENDOR PROFILE WEBSITES COMPONENT] Delete Vendor Profile Website', props<{ siteId: number }>());

const addWebsite = createAction('[VENDOR PROFILE WEBSITES COMPONENT ADD WEBSITE BUTTON] Add Vendor Profile Website', props<{ site: VendorWebsite }>());

const addWebsites = createAction('[VENDOR PROFILE LEGACY ANGULARJS] Add Websites From Legacy AngularJS', props<{ vendorId: number }>());
const addWebsitesSuccess = createAction('[VENDOR PROFILE WEBSITES API] Add Websites Successfully', props<{ vendorId: number }>());
const addWebsitesFailure = createAction('[VENDOR PROFILE WEBSITES API] Add Websites Failure', props<{ error: unknown }>());

const deleteWebsites = createAction('[VENDOR PROFILE LEGACY ANGULARJS] Delete Websites From Legacy AngularJS', props<{ vendorId: number }>());
const deleteWebsitesSuccess = createAction('[VENDOR PROFILE WEBSITES API] Delete Websites Successfully', props<{ vendorId: number }>());
const deleteWebsitesFailure = createAction('[VENDOR PROFILE WEBSITES API] Delete Websites Failure', props<{ error: unknown }>());

const updateWebsites = createAction('[VENDOR PROFILE LEGACY ANGULARJS] Update Websites From Legacy AngularJS', props<{ vendorId: number }>());
const updateWebsitesSuccess = createAction('[VENDOR PROFILE WEBSITES API] Update Websites Successfully', props<{ vendorId: number }>());
const updateWebsitesFailure = createAction('[VENDOR PROFILE WEBSITES API] Update Websites Failure', props<{ error: unknown }>());

const resetWebsitesSection = createAction('[VENDOR PROFILE WEBSITES SECTION] Reset Websites Section');

export const vendorProfileWebsiteActions = {
    loadVendorProfileWebsites,
    loadVendorProfileWebsitesSuccess,
    loadVendorProfileWebsitesFailure,
    loadVendorProfileWebsiteTypes,
    loadVendorProfileWebsiteTypesSuccess,
    loadVendorProfileWebsiteTypesFailure,
    updateWebsite,
    deleteWebsite,
    addWebsite,
    addWebsites,
    addWebsitesSuccess,
    addWebsitesFailure,
    deleteWebsites,
    deleteWebsitesSuccess,
    deleteWebsitesFailure,
    updateWebsites,
    updateWebsitesSuccess,
    updateWebsitesFailure,
    resetWebsitesSection
};
