import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { PatientAgeGroup, Unit, UnitFacilityInformation } from 'src/app/clinical/models';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-facility-patient-age-group',
    templateUrl: './facility-patient-age-group.component.html',
    styleUrls: ['./facility-patient-age-group.component.scss']
})
export class FacilityPatientAgeGroupComponent extends UnsubscribeOnDestroy implements OnDestroy, OnChanges {
    @Output() valueChanged = new EventEmitter<Partial<Unit>>();

    patientAgeGroupForm: UntypedFormGroup;
    shiftTypes: string[] = ['Days', 'Nights', 'Weekends'];

    get patientAgeGroups(): UntypedFormArray {
        return this.patientAgeGroupForm.get('patientAgeGroups') as UntypedFormArray;
    }

    _unitInfo: UnitFacilityInformation;

    @Input() set unitInfo(unitInfo: UnitFacilityInformation) {
        if (!unitInfo || !unitInfo.unit) {
            return;
        }
        this._unitInfo = unitInfo;
        this.setFormValues(unitInfo);
        this.watchFormChanges();
        refreshDisableEnableState(this.patientAgeGroupForm, this.readOnly);
    }
    @Input() readOnly = false;
    constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _store: Store) {
        super();
        this.buildForm();
    }
    @Input() isPrinting: boolean = false;

    buildForm(): void {
        this.patientAgeGroupForm = this._formBuilder.group({
            patientAgeGroups: this._formBuilder.array([]),
            staffingRatioDays: this._formBuilder.control(null),
            staffingRatioNights: this._formBuilder.control(null),
            staffingRatioWeekends: this._formBuilder.control(null)
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.patientAgeGroupForm, changes.readOnly.currentValue);
        }
    }

    setFormValues(unitInfo: UnitFacilityInformation): void {
        unitInfo?.patientAgeGroups?.forEach((patientAgeGroup: PatientAgeGroup) => {
            this.patientAgeGroups.push(
                this._formBuilder.group({
                    id: this._formBuilder.control(patientAgeGroup.id),
                    title: this._formBuilder.control(patientAgeGroup.title),
                    sort: this._formBuilder.control(patientAgeGroup.sort),
                    selected: this._formBuilder.control(
                        unitInfo.unit?.patientAgeGroups?.find(
                            (unitPatientAgeGroup) => unitPatientAgeGroup.id === patientAgeGroup.id
                        )
                            ? true
                            : false
                    )
                })
            );
        });

        this.patientAgeGroupForm.get('staffingRatioDays').setValue(unitInfo.unit.staffingRatioDays);
        this.patientAgeGroupForm.get('staffingRatioNights').setValue(unitInfo.unit.staffingRatioNights);
        this.patientAgeGroupForm.get('staffingRatioWeekends').setValue(unitInfo.unit.staffingRatioWeekends);
    }

    getFormValues(): Partial<Unit> {
        const formArray: UntypedFormArray = this.patientAgeGroupForm.get('patientAgeGroups') as UntypedFormArray;
        const patientAgeGroups: PatientAgeGroup[] = [];

        formArray.controls.forEach((ageGroup) => {
            if (ageGroup.get('selected').value === true) {
                patientAgeGroups.push({
                    id: ageGroup.get('id').value,
                    title: ageGroup.get('title').value,
                    sort: ageGroup.get('sort').value,
                    selected: ageGroup.get('selected').value
                });
            }
        });

        const unit: Partial<Unit> = {
            patientAgeGroups: patientAgeGroups ?? [],
            staffingRatioDays: this.patientAgeGroupForm.get('staffingRatioDays').value,
            staffingRatioNights: this.patientAgeGroupForm.get('staffingRatioNights').value,
            staffingRatioWeekends: this.patientAgeGroupForm.get('staffingRatioWeekends').value
        };

        return unit;
    }

    watchFormChanges(): void {
        this.patientAgeGroupForm.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            this.valueChanged.emit(this.getFormValues());
        });
    }
}
