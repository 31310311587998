import { Action, createReducer, on } from '@ngrx/store';
import { ShiftListItem } from 'src/app/shifts/models/shift-list-item.model';
import { setSelectedShifts } from '../perdiemscheduler.actions';

export interface PerdiemSchedulerSelectedShiftsState {
    shifts: ShiftListItem[];
}

export const getInitialPerdiemSchedulerSelectedShiftsState = (): PerdiemSchedulerSelectedShiftsState => ({
    shifts: []
});

export function perdiemSchedulerSelectedShiftsReducer(state: PerdiemSchedulerSelectedShiftsState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerSelectedShiftsState(),
        on(setSelectedShifts, (state, action) => ({
            ...state,
            shifts: action.shifts
        }))
    )(state, action);
}

export function reducer(state: PerdiemSchedulerSelectedShiftsState | undefined, action: Action) {
    return perdiemSchedulerSelectedShiftsReducer(state, action);
}
