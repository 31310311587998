import { createAction, props } from '@ngrx/store';
import { HospitalAssociationVendorsGridItem } from 'src/app/facilities/models/hospital-association-vendors-grid-item.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { FacilitiesVendorStatusUpdate } from 'src/app/facilities/models/facility-vendor.model';
import { State } from 'src/app/shared/grid/models/state.model';

export const loadFacilityAssociationVendors = createAction(
    '[FACILITY ASSOCIATIONS COMPONENT] Load Facility Association Vendors',
    props<{ hospitalSystemId: number; isActive: boolean }>()
);
export const loadFacilityAssociationVendorsSuccess = createAction(
    '[FACILITY ASSOCIATIONS API] Load Facility Association Vendors Success',
    props<{
        vendors: HospitalAssociationVendorsGridItem[];
        total: number;
        filtered: number;
    }>()
);
export const loadFacilityAssociationVendorsFailure = createAction(
    '[FACILITY ASSOCIATIONS API] Load Facility Association Vendors Failure',
    props<{ error: any }>()
);
export const setFacilityAssociationVendorsSearchQuery = createAction(
    '[FACILITY ASSOCIATIONS COMPONENT] Set Facility Association Vendors Search Query',
    props<{ searchQuery: GridSearchQuery; hospitalSystemId?: number; isActive?: boolean }>()
);

export const exportAssociationVendorsAll = createAction(
    '[FACILITY ASSOCIATIONS VENDORS EXPORT ALL COMPONENT] Export All Request',
    props<{ hospitalSystemId: number; isActive: boolean }>()
);
export const exportAssociationVendorsAllSuccess = createAction(
    '[FACILITY ASSOCIATIONS VENDORS EXPORT ALL SUCCESS API] Export All Success',
    props<{ blob: any }>()
);
export const exportAssociationVendorsAllFailure = createAction(
    '[FACILITY ASSOCIATIONS VENDORS EXPORT ALL FAILURE API] Export All Failure',
    props<{ error: any }>()
);

export const exportAssociationVendorsSelected = createAction(
    '[FACILITY ASSOCIATIONS VENDORS EXPORT SELECTED COMPONENT] Export Selected Request',
    props<{ hospitalSystemId: number; vendorIds: number[], isActive: boolean }>()
);
export const exportAssociationVendorsSelectedSuccess = createAction(
    '[FACILITY ASSOCIATIONS VENDORS EXPORT SELECTED SUCCESS API] Export Selected Success',
    props<{ blob: Blob }>()
);
export const exportAssociationVendorsSelectedFailure = createAction(
    '[FACILITY ASSOCIATIONS VENDORS EXPORT SELECTED FAILURE API] Export Selected Failure',
    props<{ error: any }>()
);

export const updateVendorFacilitiesStatus = createAction(
    '[FACILITIES ASSOCIATIONS VENDORS UPDATE STATUS COMPONENT] Update Facilities Vendors Status Request',
    props<{ params: FacilitiesVendorStatusUpdate }>()
);
export const updateVendorFacilitiesStatusSuccess = createAction(
    '[FACILITIES ASSOCIATIONS VENDORS UPDATE STATUS SUCCESS API] Update Facilities Vendors Status Success'
);
export const updateVendorFacilitiesStatusFailure = createAction(
    '[FACILITIES ASSOCIATIONS VENDORS UPDATE STATUS FAILURE API] Update Facilities Vendors Status Failure',
    props<{ error: any }>()
);

export const loadVendorListStates = createAction(
    '[FACILITY ASSOCIATIONS VENDORS STATES COMPONENT] Association Vendors States'
);
export const loadVendorListStatesSuccess = createAction(
    '[FACILITY ASSOCIATIONS VENDORS STATES SUCCESS API] Association Vendors States Success',
    props<{ states: State[] }>()
);
export const loadVendorListStatesFailure = createAction(
    '[FACILITY ASSOCIATIONS VENDORS STATES FAILURE API] Association Vendors States Failure',
    props<{ error: any }>()
);
