export interface HelpLink {
    title?: string;
    blob?: boolean;
    link?: string;
    matIcon?: string;
    hidden?: boolean;
    description?: string;
    email?: string;
}

export interface HelpData {
    subsectionTitle?: string;
    data: HelpLink[];
}

const CONNECT_FACILITY_USER_GUIDE = 'Connect Facility User Guide';

//#region Aya
export const ayaSystemDocumentLinks: HelpData[] = [
    {
        subsectionTitle: 'Facility User Guide',
        data: [
            {
                title: CONNECT_FACILITY_USER_GUIDE,
                link: '/content/ayaforms/help/Aya Connect Facility User Guide.pdf'
            }
        ]
    }
];

export const ayaAdminDocumentLinks: HelpData[] = [
    {
        subsectionTitle: 'Admin User Guide',
        data: [
            {
                title: CONNECT_FACILITY_USER_GUIDE,
                link: '/content/ayaforms/help/Aya Connect Facility User Guide.pdf'
            },
            {
                title: 'Connect Admin User Guide',
                link: '/content/ayaforms/help/Aya Connect Admin User Guide.pdf'
            }
        ]
    }
];

export const ayaVendorDocumentLinks = (): HelpData[] => [
    {
        subsectionTitle: 'Agency User Guide',
        data: [
            {
                title: 'Partner Network Hub',
                blob: false,
                link: 'https://www.ayahealthcare.com/partner-network-hub',
                matIcon: 'launch'
            },
            {
                title: 'Onboarding Presentation',
                blob: true,
                link: 'content/ayaforms/help/Partner Experience Clinical Orientation Presentation.pdf',
                matIcon: 'launch'
            },
            {
                title: 'Aya Connect User Guide',
                blob: true,
                link: 'content/ayaforms/help/Aya Connect Partner User Guide.pdf',
                matIcon: 'launch'
            },
            {
                title: 'Submission Requirements',
                blob: true,
                link: 'content/ayaforms/help/Aya Connect- Submission Requirements.pdf',
                matIcon: 'launch'
            },
            {
                title: 'Credentialing Requirements Process',
                blob: false,
                link: 'content/ayaforms/help/Aya Connect_ Credentialing Requirements Process.pdf',
                matIcon: 'launch'
            },
            {
                title: 'Recommended Background Check Vendors',
                blob: false,
                link: 'content/ayaforms/help/Aya Connect_ Background Check.pdf',
                matIcon: 'launch'
            },
            {
                title: 'Timekeeping and Billing Procedures',
                blob: false,
                link: 'content/ayaforms/help/Aya Connect_Timekeeping & Billing Procedures.pdf',
                matIcon: 'launch'
            },
            {
                title: 'Managing Your Client List in Connect',
                blob: true,
                link: 'content/ayaforms/help/Aya Connect- Managing Your Client List v2.pdf',
                matIcon: 'launch'
            },
            {
                title: 'How to Clear Your Browser Cache',
                blob: true,
                link: 'content/ayaforms/help/How to Clear Your Browser Cache - Aya.pdf',
                matIcon: 'launch'
            },
            {
                title: 'Partner Network Contact Sheet',
                blob: true,
                link: 'content/ayaforms/help/Partner Network Contact Sheet.pdf',
                matIcon: 'launch'
            },
            {
                title: 'Facility Compliance Tiering',
                blob: true,
                link: 'content/ayaforms/help/Facility Compliance Tiering.pdf',
                matIcon: 'launch'
            },
            {
                title: 'Qualivis Contract Lines Explained',
                blob: true,
                link: 'content/ayaforms/help/Qualivis Contract Lines Explained.pdf',
                matIcon: 'launch'
            }
        ]
    }
];

export const ayaVideoLinks = (): HelpData[] => [
    {
        data: [
            {
                title: 'Aya Connect Overview Training',
                blob: false,
                link: 'https://vimeo.com/720033092/da20f1a53a',
                matIcon: 'smart_display'
            },
            {
                title: 'Aya Connect Timecard Training',
                blob: false,
                link: 'https://vimeo.com/720056306/b7e4d78607',
                matIcon: 'smart_display'
            },
            {
                title: 'Vaccination Requirements',
                blob: false,
                link: 'https://vimeo.com/720064119/34e4cb1a10',
                matIcon: 'smart_display'
            },
            {
                title: 'Compliance Document Upload',
                blob: false,
                link: 'https://vimeo.com/720063775/cb51969167',
                matIcon: 'smart_display'
            },
            {
                title: 'Compliance Reporting Dashboard Training Video',
                blob: false,
                link: 'https://vimeo.com/user78354540/review/763972307/8427575a83',
                matIcon: 'smart_display'
            }
        ]
    }
];

export const ayaContactLinksFull: HelpData[] = [
    {
        data: [
            {
                description: 'For general questions, operational support, and escalations, please reach out to:',
                email: 'PartnerExperience@lotusworkforce.com'
            },
            {
                description: 'To inquire about expanding panels, booking strategies or support needs, please contact:',
                email: 'PartnerEngagement@ayahealthcare.com'
            },
            {
                description:
                    'For any questions concerning compliance items, deadlines, or uploading documents, reach out to:',
                email: ' AgencyCompliance@ayahealthcare.com'
            },
            {
                description: 'Please send any inquiries or discrepancies regarding billing to:',
                email: 'BillingQuestions@ayahealthcare.com'
            }
        ]
    }
];
export const ayaContactLinksPartial: HelpData[] = [
    {
        data: [
            {
                email: 'PartnerEngagement@ayahealthcare.com'
            }
        ]
    }
];
//#endregion

//#region LTS

export const ltsSystemDocumentLinks: HelpData[] = [
    {
        subsectionTitle: 'Facility User Guide',
        data: [
            {
                title: 'Connect Facility User Guide',
                link: '/content/hcsforms/help/Lotus Connect Facility User Guide.pdf'
            }
        ]
    }
];

export const ltsVendorDocumentLinks: HelpData[] = [
    {
        subsectionTitle: 'Agency User Guide',
        data: [
            {
                title: 'Connect Agency User Guide',
                link: '/content/hcsforms/help/Lotus Connect Partner User Guide.pdf'
            }
        ]
    }
];

export const ltsVideoLinks: HelpData[] = [
    {
        data: [
            {
                title: 'Lotus Connect Overview Training',
                blob: false,
                link: 'https://vimeo.com/user78354540/review/567657359/04f89d0c1a',
                matIcon: 'smart_display'
            },
            {
                title: 'Lotus Connect Timecard Training',
                blob: false,
                link: 'https://vimeo.com/user78354540/review/570390885/8ee319d93b',
                matIcon: 'smart_display'
            },
            {
                title: 'Vaccination Requirements',
                blob: false,
                link: 'https://vimeo.com/630464191/6fac2c1586',
                matIcon: 'smart_display'
            },
            {
                title: 'Compliance Document Upload',
                blob: false,
                link: 'https://vimeo.com/630463407/d2c22dade4',
                matIcon: 'smart_display'
            }
        ]
    }
];

export const ltsContactLinks: HelpData[] = [
    {
        data: [
            {
                email: 'PartnerExperience@lotusworkforce.com'
            }
        ]
    }
];

//#endregion
