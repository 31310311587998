export interface VendorType {
    type: string;
    vendorTypeId: number;
}

export interface SelectedVendorType {
    vendorId: number;
    vendorType: VendorType;
    vendorTypeId: number;
}

export enum VendorTypeTags {
    SHIFTS_APP = 'Shifts App',
    PER_DIEM = 'Per Diem'
}
