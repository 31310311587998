import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../shared/services/logger.service';

@Component({
    selector: 'ayac-datadog-logger',
    template: `<div><h1>Aya Connect - DataDog Logger Test</h1></div
        ><br />
        <div
            class="front-strip"
            *ngIf="errTest?.length > 0"
            ><span>{{ errTest }}</span></div
        >
        <div
            *ngIf="errTest?.length === 0"
            class="front-strip"
            ><span>{{ debug }}</span
            ><br /><span>{{ info }}</span
            ><br /><span>{{ error }}</span
            ><br /><span>{{ warn }}</span></div
        >`,
    styles: [
        `
            :host {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        `,
        `
            .front-strip {
                width: 100%;
                padding: 10px 0px;
                text-align: center;
            }
        `,
        `
            .h1 {
                font-size: 44px;
                font-weight: normal;
                margin: 0px;
            }
        `
    ]
})
export class DatadogLoggerComponent implements OnInit {
    debug = 'Debug : message logged';
    info = 'Info : message logged';
    error = 'Error : message logged';
    warn = 'Warn : message logged';
    errTest = '';

    constructor(private readonly logger: LoggerService) {}

    ngOnInit(): void {
        try {
            this.logger.debug(this.debug);
            this.logger.info(this.info);
            this.logger.error(this.error);
            this.logger.warn(this.warn);
        } catch (err) {
            this.errTest = 'Datadog message logged failed';
        }
    }
}
