import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportActionType } from 'src/app/reports/models';

@Component({
    selector: 'ayac-powerbi-action-confirm-modal',
    templateUrl: './powerbi-action-confirm-modal.component.html',
    styleUrls: ['./powerbi-action-confirm-modal.component.scss']
})
export class PowerBiReportActionConfirmationModalComponent {
    reportName: string;
    title: string;
    message: string;

    private readonly actionMessages = new Map<ReportActionType, string>([
        [
            ReportActionType.RestoreSettings,
            'This action will restore default system filter settings for this report. Are you sure you want to continue?'
        ],
        [
            ReportActionType.SaveSettings,
            'This action will override previous filter settings. Are you sure you want to continue?'
        ]
    ]);

    private readonly actionTitles = new Map<ReportActionType, string>([
        [ReportActionType.RestoreSettings, 'Restore Original for'],
        [ReportActionType.SaveSettings, 'Save Settings for']
    ]);

    constructor(
        private dialogRef: MatDialogRef<PowerBiReportActionConfirmationModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: { actionType: ReportActionType; reportName: string }
    ) {
        this.reportName = data.reportName;
        this.message = this.actionMessages.get(data.actionType);
        this.title = `${this.actionTitles.get(data.actionType)} ${this.reportName}`;
    }

    confirmReportAction(confirm: boolean) {
        this.dialogRef.close(confirm);
    }
}
