import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import * as invoicesActions from './../invoices.actions';
import { ToasterService } from 'src/app/core/services';

@Injectable()
export class InvoiceDisputeCreateEffect {
    createInvoiceDispute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(invoicesActions.createInvoiceDispute),
            switchMap((action) => {
                const createInvoiceDispute = {
                    invoiceId: action.invoiceId,
                    lineItems: action.lineItems,
                    notes: action.notes,
                    isBillable: action.isBillable
                };

                return this.repository.createInvoiceDispute(createInvoiceDispute).pipe(
                    map((response) => {
                        return invoicesActions.createInvoiceDisputeSuccess({
                            invoiceDetails: response.body,
                            isNew: response.status === 201
                        });
                    }),
                    catchError((error) => {
                        return of(invoicesActions.createInvoiceDisputeFail({ error }));
                    })
                );
            })
        )
    );

    createInvoiceDisputeSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(invoicesActions.createInvoiceDisputeSuccess),
                tap((action) => {
                    const message = action.isNew ? 'Dispute Created' : 'Dispute Updated';
                    this.toaster.success(message);
                })
            ),
        { dispatch: false }
    );

    createInvoiceDisputeFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(invoicesActions.createInvoiceDisputeFail),
                tap((action) => {
                    this.toaster.fail('Dispute Failed');
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly repository: InvoicesRepositoryService,
        private readonly toaster: ToasterService
    ) {}
}
