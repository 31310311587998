import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/job-release-settings.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { ReleaseGroupsState, getInitialReleaseGroupsState } from 'src/app/facilities/store/state/release-groups.state';

export function jobReleaseGroupsReducers(state: ReleaseGroupsState | undefined, action: Action) {
    return createReducer(
        getInitialReleaseGroupsState(),
        on(actions.loadJobReleaseSettingsPermissions,
            (state): ReleaseGroupsState =>
            ({
                ...state,
                releaseGroupsPermissionsLoadingState: LoadingTypes.LOADING
            })),

        on(actions.loadJobReleaseSettingsPermissionsSuccess,
            (state, action): ReleaseGroupsState =>
            ({
                ...state,
                releaseGroupsPermissionsLoadingState: LoadingTypes.LOADED,
                releaseGroupsPermissions: action.releaseGroupsPermissions
            })),

        on(actions.loadJobReleaseSettingsPermissionsFailure,
            (state): ReleaseGroupsState =>
            ({
                ...state,
                releaseGroupsPermissionsLoadingState: LoadingTypes.FAIL
            })),

        on(actions.loadJobReleaseGroups,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroups: [],
                releaseGroupsLoadingState: LoadingTypes.LOADING,
                releaseGroupVendorsLoadingState: LoadingTypes.INIT
            })),

        on(actions.loadJobReleaseGroupsSuccess,
            (state, action): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.LOADED,
                releaseGroups: action.releaseGroups
            })),

        on(actions.loadJobReleaseGroupsFailure,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.FAIL
            })),

        on(actions.deleteJobReleaseGroup,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.LOADING
            })),

        on(actions.deleteJobReleaseGroupSuccess,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.LOADED
            })),
        on(actions.deleteJobReleaseGroupFailure,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupsLoadingState: LoadingTypes.LOADED
            })),

        on(actions.loadJobReleaseGroupVendors,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupVendors: [],
                releaseGroupVendorsLoadingState: LoadingTypes.LOADING
            })),
        on(actions.loadJobReleaseGroupVendorsSuccess,
            (state, action): ReleaseGroupsState => ({
                ...state,
                releaseGroupVendorsLoadingState: LoadingTypes.LOADED,
                releaseGroupVendors: action.vendors
            })),
        on(actions.loadJobReleaseGroupVendorsFailure,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupVendorsLoadingState: LoadingTypes.FAIL
            })),
        on(actions.saveJobReleaseGroup,
            (state): ReleaseGroupsState => ({
                ...state,
                saveReleaseGroupLoadingState: LoadingTypes.LOADING
            })),
        on(actions.saveJobReleaseGroupSuccess,
            (state): ReleaseGroupsState => ({
                ...state,
                releaseGroupVendors: [],
                saveReleaseGroupLoadingState: LoadingTypes.LOADED
            })),
        on(actions.saveJobReleaseGroupFailure,
            (state): ReleaseGroupsState => ({
                ...state,
                saveReleaseGroupLoadingState: LoadingTypes.FAIL
            })),
        on(actions.copyJobReleaseSettingsToFacilities,
            actions.copyScheduledJobReleaseRulesToFacilities,
            actions.copyJobReleaseSettingsFromFacility,
            (state) => ({ ...state, copySettingsLoadingState: LoadingTypes.LOADING })),

        on(actions.copyJobReleaseSettingsToFacilitiesSuccess,
            actions.copyScheduledJobReleaseRulesToFacilitiesSuccess,
            actions.copyJobReleaseSettingsFromFacilitySuccess,
            (state) => ({ ...state, copySettingsLoadingState: LoadingTypes.LOADED })),

        on(actions.copyJobReleaseSettingsToFacilitiesFailure,
            actions.copyScheduledJobReleaseRulesToFacilitiesFailure,
            actions.copyJobReleaseSettingsFromFacilityFailure,
            (state) => ({ ...state, copySettingsLoadingState: LoadingTypes.FAIL }))

    )(state, action);
}
