import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VendorBusinessType } from 'src/app/admin/vendor-details/models/vendor-business-types.model';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { VendorSpecificContractLineType } from 'src/app/shared/models/contract-line-contract-type-update.model';
import { ContractLineContractType } from 'src/app/shared/models/contract-line-contract-type.model';
import { VendorDetail } from 'src/app/shared/models/vendor-detail.model';
import { SelectedVendorType, VendorType } from 'src/app/shared/models/vendor-type.model';
import { VendorDetailsUrls } from '../enums/vendor-detail-urls.enum';
import { TypeListItem, VendorEmail } from './models/vendor.model';
import { interpolateUrl } from 'src/app/core/utils';
import { VendorJobRuleDetails, VendorJobRulesList } from './models/job-rules.model';
import { JobRuleProfessionTree } from 'src/app/admin-vendors/models/job-rule-profession-specialties.model';

@Injectable({
    providedIn: 'root'
})
export class VendorProfileService {
    constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    getContractLineTypes(): Observable<ContractLineContractType[]> {
        return this._httpClient.get<ContractLineContractType[]>(
            `${this._settings.CORE}${VendorDetailsUrls.VENDOR_PROFILE}/contract-line-contract-types`
        );
    }

    getContractLineTypesByVendorId(vendorId: number): Observable<VendorSpecificContractLineType[]> {
        return this._httpClient.get<VendorSpecificContractLineType[]>(
            `${this._settings.CORE}${VendorDetailsUrls.VENDOR_PROFILE}/${vendorId}/contract-line-contract-types`
        );
    }

    updateContractLineTypesByVendorId(vendorId: number, types: VendorSpecificContractLineType[]): Observable<unknown> {
        return this._httpClient.post<unknown>(
            `${this._settings.CORE}${VendorDetailsUrls.VENDOR_PROFILE}/${vendorId}/contract-line-contract-types`,
            types
        );
    }

    getVendorDetails(vendorId: number): Observable<VendorDetail> {
        return this._httpClient.get<VendorDetail>(`${this._settings.CORE}/ayaconnect/admin/vendors/${vendorId}`);
    }

    getVendorBusinessTypes(): Observable<VendorBusinessType[]> {
        return this._httpClient.get<VendorBusinessType[]>(
            `${this._settings.CORE}/ayaconnect/admin/vendors/business-types`
        );
    }

    getVendorTypes(): Observable<VendorType[]> {
        return this._httpClient.get<VendorType[]>(`${this._settings.CORE}/ayaconnect/admin/vendors/Types`);
    }

    getSelectedVendorTypes(vendorId: number): Observable<SelectedVendorType[]> {
        return this._httpClient.get<SelectedVendorType[]>(
            `${this._settings.CORE}/ayaconnect/admin/vendors/${vendorId}/typeTags`
        );
    }

    updateVendorTypeTags(vendorId: number, tagIds: number[]): Observable<unknown> {
        return this._httpClient.put<unknown>(
            `${this._settings.CORE}/ayaconnect/admin/vendors/${vendorId}/typeTags`,
            tagIds
        );
    }

    addPermissionsToVendorContacts(vendorId: number, permissions: string[]): Observable<unknown> {
        return this._httpClient.put(
            `${this._settings.CORE}/AyaConnect/Auth/vendor/${vendorId}/permissions`,
            permissions
        );
    }

    removePermissionsToVendorContacts(vendorId: number, permissions: string[]): Observable<unknown> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: permissions
        };

        return this._httpClient.delete(
            `${this._settings.CORE}/AyaConnect/Auth/vendor/${vendorId}/permissions`,
            options
        );
    }

    getEmails(vendorId: number): Observable<VendorEmail[]> {
        const url = `${this._settings.CORE}${VendorDetailsUrls.VENDOR_PROFILE}/${vendorId}/emails`;
        return this._httpClient.get<VendorEmail[]>(url);
    }

    getEmailTypes(): Observable<TypeListItem[]> {
        const url = `${this._settings.CORE}${VendorDetailsUrls.VENDOR_PROFILE}/email-types`;
        return this._httpClient.get<TypeListItem[]>(url);
    }

    getJobRulesList(vendorId: number): Observable<VendorJobRulesList[]> {
        const url = interpolateUrl(VendorDetailsUrls.VENDOR_JOB_RULES_LIST, { vendorId });
        return this._httpClient.get<VendorJobRulesList[]>(`${this._settings.CORE}${url}`);
    }

    getJobRuleDetails(jobRuleId: number): Observable<VendorJobRuleDetails> {
        const url = interpolateUrl(VendorDetailsUrls.VENDOR_JOB_RULE_DETAILS, { jobRuleId });
        return this._httpClient.get<VendorJobRuleDetails>(`${this._settings.CORE}${url}`);
    }

    getProfessionTypes(): Observable<JobRuleProfessionTree> {
        return this._httpClient.get<JobRuleProfessionTree>(
            `${this._settings.CORE}${VendorDetailsUrls.VENDOR_JOB_RULE_PROFESSIONS}`
        );
    }
}
