import { createAction, props } from '@ngrx/store';
import { FeatureSettingCategory, FeatureSettingItem } from 'src/app/shared/feature-settings';

export const loadSystemSettings = createAction(
    '[Systems] Load System Settings',
    props<{ systemId: number }>()
);

export const loadSystemSettingsSuccess = createAction(
    '[Systems] Load System Settings Success',
    props<{ settings: FeatureSettingCategory[] }>()
);

export const loadSystemSettingsFailed = createAction(
    '[Systems] Load System Settings Failed',
    props<{ error: any }>()
);

export const updateSystemSettings = createAction(
    '[Systems] Update System Settings',
    props<{ systemId: number, setting: FeatureSettingItem, reload?: boolean }>()
);

export const updateSystemSettingsSuccess = createAction(
    '[Systems] Update System Settings Success'
);

export const updateSystemSettingsFailed = createAction(
    '[Systems] Update System Settings Failed',
    props<{ error: any }>()
);

export const clearSystemSettings = createAction(
    '[Systems] Clear System Settings',
    props<{ error: any }>()
);

export const setSystemSetting = createAction(
    '[Systems] Set System Setting',
    props<{ setting: FeatureSettingItem }>()
);

export const confirmUpdateSystemSetting = createAction(
    '[Systems] Confirm Update System Setting',
    props<{
        systemId: number,
        current: FeatureSettingItem,
        previous: FeatureSettingItem,
        reload?: boolean
    }>()
);

export const revertUpdateSystemSetting = createAction(
    '[Systems] Revert Update System Setting',
);

export const applyUpdateSystemSetting = createAction(
    '[Systems] Apply Update System Setting',
);
