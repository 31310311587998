import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { PerdiemCandidate } from '../models/perdiem-candidate.model';
import { ShiftList } from '../models/shift-list.model';
import {
    loadFilteredCandidates,
    loadCandidates,
    selectCandidatesIsLoading,
    selectFilteredCandidates,
    bookCandidate
} from '../store';

@Component({
    templateUrl: './book-candidate-dialog.component.html',
    styleUrls: ['./book-candidate-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookCandidateDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    candidates$: Observable<GridDataResult>;
    isLoading$: Observable<boolean>;

    shiftData: ShiftList;
    canBook = false;
    selectedCandidate: PerdiemCandidate;
    searchForm: UntypedFormGroup;
    currentQuery: { take: number; skip: number; search: string } = { take: 10, skip: 0, search: null };

    constructor(
        public dialogRef: MatDialogRef<BookCandidateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly initialShiftData: ShiftList,
        private readonly store: Store,
        private readonly formBuilder: UntypedFormBuilder
    ) {
        super();
        this.searchForm = this.formBuilder.group({
            search: this.formBuilder.control(null)
        });
    }

    ngOnInit(): void {
        this.shiftData = this.initialShiftData;
        this.candidates$ = this.store.select(selectFilteredCandidates);
        this.isLoading$ = this.store.select(selectCandidatesIsLoading);

        this.searchForm
            .get('search')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((search) => {
                this.currentQuery.search = search;
                this.store.dispatch(loadFilteredCandidates(this.currentQuery));
            });

        this.store.dispatch(
            loadCandidates({
                professionId: this.shiftData.professionId,
                facilityId: this.shiftData.facilityId,
                seriesId: this.shiftData.seriesId,
                specialtyId: this.shiftData.expertiseId,
                query: this.currentQuery,
                shiftId: this.shiftData.id
            })
        );
    }

    onRowSelected(rows: PerdiemCandidate[] = []): void {
        this.canBook = !!rows.length;
        this.selectedCandidate = rows[0];
    }

    onDataStateChange(state: GridSearchQuery): void {
        this.currentQuery = { ...this.currentQuery, skip: state.skip ?? 0, take: state.take };
        this.store.dispatch(loadFilteredCandidates(this.currentQuery));
    }

    onBookCandidate(): void {
        if (!this.selectedCandidate) {
            return;
        }

        this.store.dispatch(
            bookCandidate({
                dialogId: this.dialogRef.id,
                candidateId: this.selectedCandidate.userId,
                shiftId: this.shiftData.id,
                seriesId: this.shiftData.seriesId
            })
        );
    }
}
