import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { loadCancelReasons, removeCandidate, selectCancelReasons } from '../store';

@Component({
    templateUrl: './remove-candidate-dialog.component.html',
    styleUrls: ['./remove-candidate-dialog.component.scss']
})
export class RemoveCandidateDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    cancelReasons$: Observable<string[]>;

    selectionForm: UntypedFormGroup;
    shiftData: { shiftId: number; seriesId: number };
    selectedReason: string;
    canSelectReason = false;

    constructor(
        public dialogRef: MatDialogRef<RemoveCandidateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly initialShiftData: { shiftId: number; seriesId: number },
        private readonly store: Store,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly dialogService: DialogService
    ) {
        super();
        this.selectionForm = this.formBuilder.group({
            reason: this.formBuilder.control(null)
        });
    }

    ngOnInit(): void {
        this.dialogService
            .openConfirmationDialog({
                data: {
                    text: 'Are you sure you want to remove this candidate from this shift? You will not be able to rebook.',
                    title: 'Remove Candidate',
                    confirmButtonText: 'Yes, Remove Candidate',
                    cancelButtonText: 'No, Keep Candidate'
                }
            })
            .then((shouldRemove) => {
                if (shouldRemove) {
                    this.canSelectReason = true;
                } else {
                    this.dialogRef.close(false);
                }
            });

        this.cancelReasons$ = this.store.select(selectCancelReasons);
        this.shiftData = this.initialShiftData;

        this.selectionForm
            .get('reason')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((reason) => (this.selectedReason = reason));

        this.store.dispatch(loadCancelReasons());
    }

    onRemoveCandidate(): void {
        if (!this.selectedReason) {
            return;
        }

        this.store.dispatch(
            removeCandidate({
                dialogId: this.dialogRef.id,
                cancelReason: this.selectedReason,
                shiftId: this.shiftData.shiftId,
                seriesId: this.shiftData.seriesId
            })
        );
    }
}
