import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { notWhiteSpaceValidator } from '../../utilities/empty-validator';

@Component({
    selector: 'reject-confirm-modal',
    templateUrl: 'reject-confirm-modal.component.html',
    styles: [
        `
            button {
                width: 150px;
            }
            .k-form {
                padding: 0;
            }
        `
    ]
})
export class RejectConfirmModalComponent {
    readonly MAX_CHARS_COUNT = 450;

    form = new UntypedFormGroup({
        comment: new UntypedFormControl('', [Validators.required, Validators.maxLength(450), notWhiteSpaceValidator()])
    });

    constructor(
        public dialogRef: MatDialogRef<RejectConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string }
    ) {}

    onYesClick(): void {
        this.dialogRef.close(this.form.get('comment').value);
    }

    onNoClick(): void {
        this.dialogRef.close(null);
    }
}
