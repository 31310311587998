import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

enum BannerColorEnum {
    primary = 'primary',
    accent = 'accent',
    accentLight = 'accent-light',
    warn = 'warn',
    warn2 = 'warn-2',
    warn3 = 'warn-3',
    highlight = 'highlight',
    grey = 'grey'
}
type BannerColorUnion = `${BannerColorEnum}`;

@Component({
    selector: 'ayac-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent implements OnInit {
    @Input() icon: string | undefined;
    @Input() iconSize: 'small' | 'extra-small' = 'small';
    @Input() iconAlignment: 'middle' | 'top' | 'bottom' = 'middle';
    @Input() color: BannerColorUnion = BannerColorEnum.primary;
    @Input() align: 'left' | 'center';
    @Input() closeHidden = false;
    @Output() closed = new EventEmitter();

    isHidden = false;

    constructor() {}

    ngOnInit(): void {}

    onClose() {
        this.isHidden = true;
        this.closed.emit();
    }

    get className() {
        return {
            'text-left': this.align === 'left',
            'banner-primary': this.color === BannerColorEnum.primary,
            'banner-accent': this.color === BannerColorEnum.accent,
            'banner-accent-light': this.color === BannerColorEnum.accentLight,
            'banner-warn': this.color === BannerColorEnum.warn,
            'banner-warn-2': this.color === BannerColorEnum.warn2,
            'banner-warn-3': this.color === BannerColorEnum.warn3,
            'banner-highlight': this.color === BannerColorEnum.highlight,
            'banner-grey': this.color === BannerColorEnum.grey,
        };
    }

    get iconClassName() {
        return {
            'mat-icon-small': this.iconSize === 'small',
            'mat-icon-extra-small': this.iconSize === 'extra-small',
            'align-self-center': this.iconAlignment === 'middle',
            'align-self-start': this.iconAlignment === 'top',
            'align-self-end': this.iconAlignment === 'bottom'
        };
    }
}
