import { NgModule } from '@angular/core';
import { TerminologyDirective } from './terminology.directive';
import { TerminologyPipe } from './terminology.pipe';

@NgModule({
    imports: [],
    exports: [TerminologyPipe, TerminologyDirective],
    declarations: [TerminologyPipe, TerminologyDirective],
    providers: []
})
export class TerminologyModule {}
