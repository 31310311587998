export interface RentEstimate {
    minRent: number;
    averageRent: number;
    maxRent: number;
    comparisonPropertyCount: number;
    source: RentEstimateSource;
}

export enum RentEstimateSource {
    Internal = 1,
    RealtyMole = 2
}
