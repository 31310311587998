import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import { ResourcesService } from '../services/resources.service';
import * as internalPoolSelectors from '../store/selectors';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FeatureFlags } from 'src/app/shared/models/enums/feature-flag.enum';

@Component({
    selector: 'ayac-shifts-app-section',
    templateUrl: './shifts-app-section.component.html',
    styleUrls: ['./shifts-app-section.component.scss']
})
export class ShiftsAppSectionComponent extends UnsubscribeOnDestroy implements OnInit {
    resource$: Observable<Resource>;
    resourceStatus$: Observable<string>;
    resourceUsername$: Observable<string>;
    updateForm$: Observable<any>;
    featureFlags = FeatureFlags;

    form: UntypedFormGroup;

    @Input('edit')
    editMode = false;
    @Output() resendEmailEvent: EventEmitter<any> = new EventEmitter<any>();

    @Input('showButtonInvitation') showButtonInvitation = true;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly store: Store<{}>,
        private readonly resourcesService: ResourcesService
    ) {
        super();
    }

    ngOnInit() {
        this.form = this.fb.group({
            sendInvitationEmail: [false]
        });

        this.resource$ = this.store.select(internalPoolSelectors.selectCurrentResource);
        this.resourceStatus$ = this.resource$.pipe(map((resource) => this.resourcesService.getStatus(resource)));
        this.resourceUsername$ = this.store.select(internalPoolSelectors.selectResourceUsername);

        this.updateForm$ = this.resource$.pipe(
            tap((resource) => {
                this.form.patchValue({
                    ...resource
                });
            })
        );
    }

    resendEmail() {
        this.resendEmailEvent.emit();
    }
}
