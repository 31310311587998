import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap, pairwise } from 'rxjs/operators';
import { vendorProfileActions } from 'src/app/admin/store/actions/vendor-profile.actions';
import {
    selectContractLinesWithVendorSpecific,
    selectVendorBusinessTypes,
    selectVendorTypesWithSelected
} from 'src/app/admin/store/selectors/vendor-profile.selectors';
import { VendorBusinessType } from 'src/app/admin/vendor-details/models/vendor-business-types.model';
import { vendorTagsValidator } from 'src/app/admin/vendor-details/profile/vendor-tags-validity/vendor-tags.validator';
import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS } from 'src/app/shared/adapters/dayjs-date-adapter';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { State } from 'src/app/shared/grid/models/state.model';
import { VendorSpecificContractLineType } from 'src/app/shared/models/contract-line-contract-type-update.model';
import { ContractLineContractType } from 'src/app/shared/models/contract-line-contract-type.model';
import { FeatureFlags } from 'src/app/shared/models/enums/feature-flag.enum';
import { VendorDetail } from 'src/app/shared/models/vendor-detail.model';
import { SelectedVendorType, VendorType } from 'src/app/shared/models/vendor-type.model';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD-MMMM-YYYY'
    },
    display: {
        dateInput: 'DD-MMMM-YYYY',
        monthYearLabel: 'MMM YYYY'
    }
};

@Component({
    selector: 'ayac-vendor-profile',
    templateUrl: './vendor-profile.component.html',
    styleUrls: ['./vendor-profile.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        {
            provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true }
        },
        {
            provide: DateAdapter,
            useClass: DayjsDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
        }
    ]
})
export class VendorProfileComponent implements OnInit, OnDestroy {
    @Input()
    states: State[];

    @Output()
    formChanged = new EventEmitter<{
        vendor: VendorDetail;
        typeTags: VendorType[];
        isValid: boolean;
    }>();

    contractLines$: Observable<{
        lines: ContractLineContractType[];
        vendorSpecific: VendorSpecificContractLineType[];
    }>;

    businessTypes$: Observable<VendorBusinessType[]>;
    vendorTypes$: Observable<{
        types: VendorType[];
        selectedTypes: VendorType[];
    }>;

    vendorSpecificContractLines$: Observable<VendorSpecificContractLineType[]>;

    vendorProfileForm = this._formBuilder.group({
        legalName: this._formBuilder.control('', [Validators.required]),
        operationName: this._formBuilder.control(''),
        businessType: this._formBuilder.control(''),
        fedTaxId: this._formBuilder.control(''),
        stateIncorporated: this._formBuilder.control(''),
        dateIncorporated: this._formBuilder.control(null),
        agreementDate: this._formBuilder.control(''),
        agencyActiveDate: this._formBuilder.control(''),
        autoOfferNewFacility: this._formBuilder.control(false),
        tags: this._formBuilder.control(''),
        accountingId: this._formBuilder.control('', [Validators.maxLength(9)])
    });

    datePickerOptions = {
        displayFormat: 'dd-MMMM-yyyy',
        inputFormat: 'yyyy-MM-dd'
    };

    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

    private _vendorDetails: VendorDetail;
    private _cleanup$ = new Subject();
    private _contractLines: ContractLineContractType[] = [];
    private _selectedContractLines: VendorSpecificContractLineType[] = [];
    featureFlags = FeatureFlags;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _store: Store,
        private readonly featureManager: LDFeatureManager
    ) {}

    @Input()
    set vendorDetails(vendorDetails: VendorDetail) {
        this._vendorDetails = vendorDetails;

        if (this._vendorDetails && this._vendorDetails.id) {
            this._store.dispatch(
                vendorProfileActions.loadContractLineTypesByVendorId({ vendorId: this._vendorDetails.id })
            );
            this._store.dispatch(
                vendorProfileActions.loadSelectedVendorTypesWithVendorId({ vendorId: this._vendorDetails.id })
            );
        }

        if (this.vendorProfileForm && this._vendorDetails) {
            this.vendorProfileForm.get('legalName').patchValue(vendorDetails.legalName);
            this.vendorProfileForm.get('operationName').patchValue(vendorDetails.operatingName);
            this.vendorProfileForm.get('businessType').patchValue(vendorDetails.businessTypeId);
            this.vendorProfileForm.get('fedTaxId').patchValue(vendorDetails.fedTaxId);
            this.vendorProfileForm.get('stateIncorporated').patchValue(vendorDetails.incorporatedInStateId);

            // Allow the date picker inputs to show an empty or null value if it is a new vendor
            this.vendorProfileForm
                .get('dateIncorporated')
                .patchValue(
                    vendorDetails.dateOfIncorporation
                        ? new Date(vendorDetails.dateOfIncorporation)
                        : vendorDetails.dateOfIncorporation
                );
            this.vendorProfileForm
                .get('agreementDate')
                .patchValue(
                    vendorDetails.subAgreementSignedDate
                        ? new Date(vendorDetails.subAgreementSignedDate)
                        : vendorDetails.subAgreementSignedDate
                );
            this.vendorProfileForm
                .get('agencyActiveDate')
                .patchValue(
                    vendorDetails.subAgreementExpDate
                        ? new Date(vendorDetails.subAgreementExpDate)
                        : vendorDetails.subAgreementExpDate
                );

            this.vendorProfileForm
                .get('autoOfferNewFacility')
                .patchValue(vendorDetails.automaticallyOfferNewFacilities);
            this.vendorProfileForm.get('accountingId').patchValue(vendorDetails.accountingId);
        }
    }

    ngOnInit(): void {
        const tagsControl = this.vendorProfileForm.get('tags');
        tagsControl.setValidators([vendorTagsValidator]);

        this.contractLines$ = this._store.select(selectContractLinesWithVendorSpecific).pipe(
            tap((result) => {
                this._contractLines = result.lines;
                this._selectedContractLines = result.vendorSpecific;

                result.lines.forEach((item) => {
                    this.vendorProfileForm.addControl(item.id.toString(), this._formBuilder.group({}));

                    item.contractTypes.forEach((type) => {
                        const control = this.vendorProfileForm.get(item.id.toString()) as UntypedFormGroup;
                        control.addControl(type.id.toString(), this._formBuilder.control(false));
                    });
                });

                result.vendorSpecific.forEach((item) => {
                    if (this.vendorProfileForm) {
                        const control = this.vendorProfileForm.get(item.contractLineId.toString()) as UntypedFormArray;

                        if (control) {
                            control.get(item.contractTypeId.toString()).setValue(item.isActive);
                        }
                    }
                });
            })
        );

        this.businessTypes$ = this._store.select(selectVendorBusinessTypes);
        this.vendorTypes$ = this._store.select(selectVendorTypesWithSelected).pipe(
            tap((result) => {
                if (this.vendorProfileForm) {
                    this.vendorProfileForm.get('tags').reset();
                    this.vendorProfileForm.get('tags').patchValue(result.selectedTypes);
                }
            })
        );

        this.vendorProfileForm.valueChanges
            .pipe(
                pairwise(),
                map(([oldState, newState]) => {
                    const changes = {};
                    for (const key in newState) {
                        if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
                            changes[key] = newState[key];
                        }
                    }
                    return changes;
                }),
                filter((changes) => Object.keys(changes).length !== 0),
                takeUntil(this._cleanup$)
            )
            .subscribe((value) => {
                if (this.vendorProfileForm.dirty) {
                    // Set the vendor details anytime the form changes, just to cut down on valueChanges subs
                    this._vendorDetails.legalName = this.vendorProfileForm.get('legalName').value;
                    this._vendorDetails.operatingName = this.vendorProfileForm.get('operationName').value;
                    this._vendorDetails.businessTypeId = this.vendorProfileForm.get('businessType').value;
                    this._vendorDetails.fedTaxId = this.vendorProfileForm.get('fedTaxId').value;
                    this._vendorDetails.incorporatedInStateId = this.vendorProfileForm.get('stateIncorporated').value;
                    this._vendorDetails.dateOfIncorporation = this.vendorProfileForm.get('dateIncorporated').value;
                    this._vendorDetails.subAgreementSignedDate = this.vendorProfileForm.get('agreementDate').value;
                    this._vendorDetails.subAgreementExpDate = this.vendorProfileForm.get('agencyActiveDate').value;
                    this._vendorDetails.automaticallyOfferNewFacilities =
                        this.vendorProfileForm.get('autoOfferNewFacility').value;
                    this._vendorDetails.accountingId = this.vendorProfileForm.get('accountingId').value;

                    const tags: VendorType[] = this.vendorProfileForm.get('tags').value ?? [];
                    const id = Number(Object.keys(value)[0]);

                    if (!Number.isNaN(id)) {
                        this._updateSelectedContractLines(value);
                    }

                    this._store.dispatch(
                        vendorProfileActions.updateSelectedVendorTypes({
                            vendorId: this._vendorDetails.id,
                            vendorTypes: tags
                        })
                    );

                    this.formChanged.emit({
                        vendor: this._vendorDetails,
                        typeTags: tags,
                        isValid: this.vendorProfileForm.valid
                    });

                    this.vendorProfileForm.markAsPristine();
                }
            });

        this._store.dispatch(vendorProfileActions.vendorProfileComponentLoaded());
    }

    ngOnDestroy(): void {
        this._cleanup$.next(true);
        this._cleanup$.complete();

        this._store.dispatch(vendorProfileActions.resetVendorProfile());
    }

    private _updateSelectedContractLines(value: { [key: number]: { [key: number]: boolean } }): void {
        this._contractLines.forEach((item) => {
            if (value[item.id]) {
                const keys = Object.keys(value[item.id]);

                keys.forEach((key) => {
                    const exists = this._selectedContractLines.some(
                        (line) => line.contractLineId === item.id && Number(line.contractTypeId) === Number(key)
                    );

                    if (value[item.id][key] && !exists) {
                        this._selectedContractLines.push({
                            contractLineId: item.id,
                            contractTypeId: Number(key),
                            isActive: value[item.id][key]
                        } as VendorSpecificContractLineType);
                    } else if (!value[item.id][key] && exists) {
                        this._selectedContractLines.forEach((line) => {
                            if (line.contractLineId === item.id && Number(line.contractTypeId) === Number(key)) {
                                line.isActive = false;
                            }
                        });
                    } else if (value[item.id][key] && exists) {
                        this._selectedContractLines.forEach((line) => {
                            if (line.contractLineId === item.id && Number(line.contractTypeId) === Number(key)) {
                                line.isActive = value[item.id][key];
                            }
                        });
                    } else {
                        // If you get here, this means it is safe to remove from the array otherwise we just need to update the isActive property correctly
                        const lineIndex = this._selectedContractLines.findIndex(
                            (line) => line.contractLineId === item.id && line.contractTypeId === Number(key)
                        );

                        if (lineIndex > -1) {
                            this._selectedContractLines.splice(lineIndex, 1);
                        }
                    }
                });
            }
        });

        this._store.dispatch(
            vendorProfileActions.updateSelectedVendorContractLines({ lines: this._selectedContractLines })
        );
    }
}
