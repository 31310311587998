import { perdiemSchedulerDetailsReducer } from 'src/app/vendor-perdiemscheduler/store/reducers/perdiemscheduler-details.reducer';
import { perdiemschedulerShiftsAttachmentsReducer } from 'src/app/vendor-perdiemscheduler/store/reducers/perdiemscheduler-shift-attachments.reducer';
import { perdiemSchedulerCancelReasonsReducer } from './perdiemscheduler-cancel-reasons.reducer';
import { perdiemSchedulerCandidatesReducer } from './perdiemscheduler-candidates.reducer';
import { perdiemSchedulerFacilitiesReducer } from './perdiemscheduler-facilities.reducer';
import { perdiemSchedulerListReducer } from './perdiemscheduler-list.reducer';
import { perdiemSchedulerProfessionsReducer } from './perdiemscheduler-professions.reducer';
import { perdiemSchedulerQueryReducer } from './perdiemscheduler-query.reducer';
import { perdiemSchedulerSelectedShiftsReducer } from './perdiemscheduler-selected-shifts.reducer';
import { perdiemSchedulerSpecialtiesReducer } from './perdiemscheduler-specialties.reducer';
import { perdiemSchedulerStatesReducer } from './perdiemscheduler-states.reducer';
import { perdiemSchedulerDefaultDateFromReducer } from './perdiemscheduler-min-date.reducer';

export const perdiemSchedulerReducer = {
    list: perdiemSchedulerListReducer,
    query: perdiemSchedulerQueryReducer,
    professions: perdiemSchedulerProfessionsReducer,
    specialties: perdiemSchedulerSpecialtiesReducer,
    facilities: perdiemSchedulerFacilitiesReducer,
    states: perdiemSchedulerStatesReducer,
    selectedShifts: perdiemSchedulerSelectedShiftsReducer,
    candidates: perdiemSchedulerCandidatesReducer,
    cancelReasons: perdiemSchedulerCancelReasonsReducer,
    detailShifts: perdiemSchedulerDetailsReducer,
    attachments: perdiemschedulerShiftsAttachmentsReducer,
    minDate: perdiemSchedulerDefaultDateFromReducer
};
