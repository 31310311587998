import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

enum Workdays {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY
}

@Component({
    selector: 'ayac-workdays-select',
    templateUrl: './workdays-select.component.html',
    styleUrls: ['./workdays-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WorkdaysSelectComponent),
            multi: true
        }
    ]
})
export class WorkdaysSelectComponent extends UnsubscribeOnDestroy implements OnInit, ControlValueAccessor {
    changed: any;
    Workdays = Workdays;

    form = this.fb.group({
        [Workdays.SUNDAY]: [false],
        [Workdays.MONDAY]: [false],
        [Workdays.TUESDAY]: [false],
        [Workdays.WEDNESDAY]: [false],
        [Workdays.THURSDAY]: [false],
        [Workdays.FRIDAY]: [false],
        [Workdays.SATURDAY]: [false]
    });

    @Input() maxCount: number;
    @Input() disable: boolean = false;

    constructor(private readonly fb: UntypedFormBuilder) {
        super();
    }

    writeValue(val: Workdays[]): void {
        this.formValue = val;
    }

    registerOnChange(fn: any): void {
        this.changed = fn;
    }

    registerOnTouched(fn: any): void {}

    ngOnInit() {
        this.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            if (this.changed) {
                this.changed(this.formValue);
            }

            if (this.maxCount) {
                this.updateSelectedCount();
            }
        });

        if(this.disable){
            this.form.disable();
        }
    }

    get formValue(): Workdays[] {
        const formValue = this.form.value;
        return Object.keys(formValue)
            .filter((k) => formValue[k])
            .map((d) => Number(d) as Workdays);
    }

    set formValue(val: Workdays[]) {
        const data = val.reduce(
            (g, cur) => ({
                ...g,
                [cur]: true
            }),
            {}
        );

        this.form.patchValue(data);
    }

    updateSelectedCount() {
        const value = this.form.value;
        const selectedCount = Object.keys(value).filter((k) => value[k]).length;

        if (selectedCount === Number(this.maxCount)) {
            this.disableUnselected();
        } else {
            this.enableAll();
        }
    }

    private disableUnselected() {
        for (const key in this.form.controls) {
            const control = this.form.get(key);
            if (!control.value) {
                control.disable({ emitEvent: false });
            }
        }
    }
    private enableAll() {
        for (const key in this.form.controls) {
            const control = this.form.get(key);
            control.enable({ emitEvent: false });
        }
    }
}
