import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AppIdInterceptor implements HttpInterceptor {
    private readonly appId = '7';
    private readonly excl = '/connect/token';

    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.search(this.excl) === -1) {
            return next.handle(
                request.clone({
                    setHeaders: {
                        'aya-app-id': this.appId
                    }
                })
            );
        } else {
            return next.handle(request);
        }
    }
}
