import { initialClientGridState } from './reducers/client-grid.reducer';
import { initialClinicalUnitsState } from './reducers/client-units.reducer';
import { initialClientState } from './reducers/client.reducer';
import { ClinicalClientGridState } from './state/client-grid.state';
import { ClientUnitsState } from './state/client-units.state';
import { ClinicalClientState } from './state/client.state';
export const clinicalStoreKey = 'client/clinical';

export interface ClinicalState {
    ClientUnitsState: ClientUnitsState;
    ClientState: ClinicalClientState;
    ClientGridState: ClinicalClientGridState;
}

export const initialClinicalState = (): ClinicalState => ({
    ClientUnitsState: initialClinicalUnitsState(),
    ClientState: initialClientState(),
    ClientGridState: initialClientGridState()
});
