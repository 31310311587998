import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, dashboardStoreKey } from 'src/app/dashboard-new/store/dashboard.state';

export const selectPendingExpensesTotalCount = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => state.pendingExpenses.totalCount
);

export const selectPendingExpensesAgingDays = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => state.pendingExpenses.agingDays
);

export const selectPendingExpensesWarning = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    (state) => {
        return state.pendingExpenses.agingCount === 0
            ? ''
            : `${state.pendingExpenses.agingCount} ${
                  state.pendingExpenses.agingCount === 1 ? 'expense' : 'expenses'
              } pending approval for over ${state.pendingExpenses.agingDays} days`;
    }
);
