import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, takeUntil, tap, take, flatMap, switchMap } from 'rxjs/operators';
import { enableSystemFormFieldsValidator, UnsubscribeOnDestroy } from 'src/app/core/utils';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import { ListItem } from 'src/app/shared/models/list-item';
import * as internalPoolSelectors from '../store/selectors';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { SpecialtyLookup } from 'src/app/shared/models/lookups/specialty-lookup.model';
import { FormValueState, requiredIfNotEmptyGroupValidator } from 'src/app/shared/utilities';
import { ResourcesService } from '../services/resources.service';
import { PreferredShiftDays, ResourceTypes } from '../models';
import { getSystemFieldsByModule } from 'src/app/core/state';
import { loadSecurityLookups } from 'src/app/dashboard-new/store/actions';
import * as fromCore from 'src/app/core/state/selectors';
import { CustomSystemFieldLookup } from '../../core/models/system-custom-field.model';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { ProfessionLookup } from 'src/app/shared/models/lookups';

@Component({
    selector: 'ayac-professional-details-section',
    templateUrl: './professional-details-section.component.html',
    styleUrls: ['./professional-details-section.component.scss']
})
export class ProfessionalDetailsSectionComponent
    extends UnsubscribeOnDestroy
    implements OnInit, OnDestroy, AfterViewInit
{
    resource$: Observable<Resource>;
    readonlyFacilities$: Observable<ListItem[]>;
    hasReadonlyFacilities$: Observable<boolean>;
    preferredShifts$: Observable<ListItem[]>;
    availabilityLookups$: Observable<ListItem[]>;
    skillLookups$: Observable<ListItem[]>;
    systemLookups$: Observable<ListItem[]>;
    facilityLookups$: Observable<FacilityLookup[]>;
    filteredFacilityLookups$: Observable<FacilityLookup[]>;
    availableFacilityLookups$: Observable<FacilityLookup[]>;
    professionLookups$: Observable<ProfessionLookup[]>;
    specialtyLookups$: Observable<SpecialtyLookup[]>;
    filteredSpecialtyLookups$: Observable<SpecialtyLookup[]>;
    languageLookups$: Observable<ListItem[]>;
    languageSkillLookups$: Observable<ListItem[]>;
    epicSkillsLookup$: Observable<ListItem[]>;
    customFieldsLookup$: Observable<CustomSystemFieldLookup[]>;
    preferredFacilityLookups$: Observable<FacilityLookup[]>;
    resourceTypeLookups$: Observable<ListItem[]>;
    resourceType$: Observable<string>;
    showFacilityBanner$: Observable<boolean>;

    updateForm$: Observable<any>;

    systemsCount = 0;

    form: UntypedFormGroup;
    formState: FormValueState;
    featureFlag = FeatureFlag;

    @Input('edit')
    editMode = false;

    timeSteps = {
        hour: 1,
        minute: 5
    };
    filterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };
    PreferredShiftDays = PreferredShiftDays;
    isNyuSystem$: Observable<boolean>;
    @ViewChild('facilityMultiSelect', { static: false }) public facilityMultiSelect: any;
    isFacilitiesExpanded = false;
    selectedCustomLocations: any;

    removeNyuRequiredFields$: Observable<boolean>;
    availableFacilityLookups: FacilityLookup[];
    private ffIRPCustomizedProfAndSpec: boolean;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly store: Store<{}>,
        private readonly resourcesService: ResourcesService,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit() {
        this.isNyuSystem$ = this.store.select(internalPoolSelectors.selectIsNyuSystem);

        this.form = this.fb.group({
            id: [0],
            systemId: [''],
            isActive: [true],
            availabilityId: [''],
            jobTitle: [{ value: '', disabled: true }, Validators.required],
            facilityIds: [{ value: [], disabled: true }, Validators.required],
            professionId: ['', Validators.required],
            expertiseIds: [{ value: [], disabled: true }, Validators.required],
            hourlyRate: [0],
            preferredShiftId: [''],
            preferredShifts: [''],
            preferredLocationIds: [[]],
            languageSkills: this.fb.array([this.createLanguageSkill()]),
            epicSkills: [''],
            customPreferredLocationIds: [''],
            customFields: [[]],
            irpRecordType: [ResourceTypes.Float]
        });

        this.removeNyuRequiredFields$ = this.isNyuSystem$.pipe(takeUntil(this.d$));

        this.formState = new FormValueState(this.form);

        this.store.dispatch(loadSecurityLookups());
        this.resource$ = this.store.select(internalPoolSelectors.selectCurrentResourceWithSystem);
        this.preferredShifts$ = this.store.select(internalPoolSelectors.selectPreferredShiftsLookups);

        this.availabilityLookups$ = this.store.select(internalPoolSelectors.selectAvailabilities);
        this.specialtyLookups$ = this.store.select(internalPoolSelectors.selectSpecialtiesLookups);
        this.skillLookups$ = this.store.select(internalPoolSelectors.selectEpicSkills);

        this.systemLookups$ = this.store.select(internalPoolSelectors.selectSystemsLookups);
        this.facilityLookups$ = this.store.select(internalPoolSelectors.selectFacilitiesLookups);
        this.readonlyFacilities$ = this.store.select(internalPoolSelectors.selectResourceReadonlyFacilities);

        this.professionLookups$ = this.store.select(internalPoolSelectors.selectProfessionLookups);
        this.languageLookups$ = this.store.select(internalPoolSelectors.selectLanguages);
        this.languageSkillLookups$ = this.store.select(internalPoolSelectors.selectLanguageSkills);
        this.customFieldsLookup$ = this.store.select(fromCore.getCustomSystemFields);
        this.isNyuSystem$ = this.store.select(internalPoolSelectors.selectIsNyuSystem);
        this.resourceTypeLookups$ = this.store.select(internalPoolSelectors.selectResourceTypesLookups);
        this.resourceType$ = this.store.select(internalPoolSelectors.selectCurrentResourceType);

        this.form
            .get('systemId')
            .valueChanges.pipe(
                takeUntil(this.d$),
                switchMap((values) =>
                    this.ldFeatureManager.isEnabled(FeatureFlag.IRPCustomizedProfAndSpec).pipe(
                        filter((enabled) => !enabled),
                        map(() => values)
                    )
                )
            )
            .subscribe((systemId) => {
                const facilityIdsControl = this.form.get('facilityIds');
                if (systemId !== null && systemId >= 0) {
                    facilityIdsControl.reset();
                    facilityIdsControl.enable({ onlySelf: true });
                } else {
                    facilityIdsControl.disable({ onlySelf: true });
                }
            });

        combineLatest([this.formState.state$, this.isNyuSystem$])
            .pipe(
                takeUntil(this.d$),
                switchMap((values) =>
                    this.ldFeatureManager.isEnabled(FeatureFlag.IRPCustomizedProfAndSpec).pipe(
                        filter((enabled) => enabled),
                        map(() => values)
                    )
                )
            )
            .subscribe(([{ systemId, professionId, expertiseIds }, isNyu]) => {
                const facilityIdsControl = this.form.get('facilityIds');
                const hasSystemId = systemId != null && systemId >= 0;
                const hasProfessionId = professionId != null && professionId > 0;
                const hasExpertiseIds = expertiseIds != null && expertiseIds.length > 0;

                if (hasSystemId && (isNyu || (hasProfessionId && hasExpertiseIds))) {
                    if (facilityIdsControl.disabled) {
                        facilityIdsControl.enable({ onlySelf: true });
                    }
                } else {
                    if (facilityIdsControl.enabled) {
                        facilityIdsControl.disable({ onlySelf: true });
                    }
                }
            });

        this.removeNyuRequiredFields$.subscribe((removeNyuRequiredFields) => {
            if (removeNyuRequiredFields) {
                this.form.get('jobTitle').setValidators(null);
                this.form.get('jobTitle').setErrors(null);
                this.form.get('facilityIds').setValidators(null);
                this.form.get('facilityIds').setErrors(null);
                this.form.get('expertiseIds').setValidators(null);
                this.form.get('expertiseIds').setErrors(null);
                this.form.get('professionId').setValidators(null);
                this.form.get('professionId').setErrors(null);
                this.form.get('professionId').setValue(0);
                this.form.get('systemId').setValidators(null);
                this.form.get('systemId').setErrors(null);
                this.form.get('systemId').setValue(0);

                this.filteredFacilityLookups$ = combineLatest([this.facilityLookups$]).pipe(
                    map(([facilities]) => facilities.filter((value) => value))
                );
            } else {
                this.filteredFacilityLookups$ = combineLatest([
                    this.facilityLookups$,
                    this.formState.get('systemId')
                ]).pipe(map(([facilities, systemId]) => facilities.filter((value) => value.systemId === systemId)));
            }
        });

        this.preferredFacilityLookups$ = this.store.select(internalPoolSelectors.selectFacilitiesLookups);

        this.filteredSpecialtyLookups$ = combineLatest([
            this.specialtyLookups$,
            this.formState.get('professionId')
        ]).pipe(
            map(([specialties, professionId]) =>
                specialties.filter((value) => value.professionExpertiseCodes.includes(professionId))
            )
        );

        this.hasReadonlyFacilities$ = this.readonlyFacilities$.pipe(map((facilities) => facilities.length > 0));

        this.form
            .get('professionId')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((professionId) => {
                const expertiseIdsControl = this.form.get('expertiseIds');
                if (professionId) {
                    expertiseIdsControl.reset();
                    expertiseIdsControl.enable({ onlySelf: true });
                } else {
                    expertiseIdsControl.disable({ onlySelf: true });
                }
                expertiseIdsControl.setValue([]);
            });

        this.epicSkillsLookup$ = combineLatest([this.customFieldsLookup$]).pipe(
            map(([customSystemFields]) => {
                const filteredField = customSystemFields.filter((cf) => cf && cf.name === 'EpicSkills')[0];
                return filteredField?.fieldValues;
            })
        );

        this.store.pipe(select(getSystemFieldsByModule('internalPool')), takeUntil(this.d$)).subscribe((fields) => {
            this.form.setValidators([enableSystemFormFieldsValidator(fields)]);
            this.form.updateValueAndValidity();
        });

        this.updateForm$ = combineLatest([
            this.resource$,
            this.epicSkillsLookup$,
            this.facilityLookups$,
            this.customFieldsLookup$
        ]).pipe(
            filter(([resource]) => resource != null),
            tap(([resource, epicSkillsLookup, facilities, customFieldsLookup]) => {
                if (Array.isArray(resource.languageSkills)) {
                    this.languageSkills.clear();
                    this.onAddLanguageSkill(resource.languageSkills.length || 1);
                }
                let facilityIds = resource.facilities ? resource.facilities.map((f) => f.facilityId) : [];

                const facilitiesSet = new Set(facilities.map((s) => s.id));
                facilityIds = facilityIds.filter((id) => facilitiesSet.has(id));

                const expertiseIds = resource.expertises ? resource.expertises.map((e) => e.expertiseId) : [];
                const preferredLocationIds = resource.preferredFacilities
                    ? resource.preferredFacilities.map((f) => f.facilityId)
                    : [];

                let epicSkills = null;
                let customPreferredLocationIds = null;
                if (resource.epicSkills && resource.epicSkills.length > 0) {
                    epicSkills = epicSkillsLookup
                        .filter((x) => resource.epicSkills.includes(x.name))
                        .map((epicSkill) => epicSkill.id);
                }
                if (resource.customFields?.length) {
                    const customFieldLookup = this.getCustomFieldByName('PreferredLocation');
                    customPreferredLocationIds = resource.customFields
                        .filter((x) => x.customFieldId === customFieldLookup.id)
                        .flatMap((x) => x.value);
                }
                this.form.markAsPristine();
                this.form.patchValue({
                    ...resource,
                    facilityIds,
                    expertiseIds,
                    preferredLocationIds,
                    epicSkills,
                    customPreferredLocationIds
                });
            })
        );

        combineLatest([this.resource$, this.hasReadonlyFacilities$, this.removeNyuRequiredFields$])
            .pipe(takeUntil(this.d$))
            .subscribe(([resource, hasReadonlyFacilities, removeNyuRequiredFields]) => {
                if (removeNyuRequiredFields) {
                    return;
                }
                if (!resource) {
                    return;
                }

                const control = this.form.get('facilityIds');
                const hasResource = resource.id > 0;
                if (hasResource && hasReadonlyFacilities) {
                    control.clearValidators();
                } else {
                    control.setValidators(Validators.required);
                }
            });

        this.handleAvailableFacilities();
    }

    handleAvailableFacilities() {
        this.showFacilityBanner$ = combineLatest([this.formState.state$, this.isNyuSystem$]).pipe(
            map(([{ professionId }, isNyu]) => (isNyu ? false : professionId != null && professionId > 0))
        );

        this.availableFacilityLookups$ = combineLatest([this.formState.state$, this.isNyuSystem$]).pipe(
            switchMap(([{ professionId, expertiseIds }, isNyu]) =>
                isNyu
                    ? this.facilityLookups$
                    : this.store.select(
                          internalPoolSelectors.selectAvailableFacilityLookups(
                              professionId ? [professionId] : [],
                              expertiseIds ?? []
                          )
                      )
            )
        );

        this.availableFacilityLookups$.pipe(takeUntil(this.d$)).subscribe((lookups) => {
            this.availableFacilityLookups = lookups;
        });

        this.ldFeatureManager
            .isEnabled(FeatureFlag.IRPCustomizedProfAndSpec)
            .pipe(takeUntil(this.d$))
            .subscribe((enabled) => {
                this.ffIRPCustomizedProfAndSpec = enabled;
            });

        this.availableFacilityLookups$
            .pipe(
                takeUntil(this.d$),
                switchMap((values) =>
                    this.ldFeatureManager.isEnabled(FeatureFlag.IRPCustomizedProfAndSpec).pipe(
                        filter((enabled) => enabled),
                        map(() => values)
                    )
                ),
                switchMap((values) =>
                    this.isNyuSystem$.pipe(
                        filter((isNyu) => !isNyu),
                        map(() => values)
                    )
                )
            )
            .subscribe((lookups) => {
                const facilityIdsControl = this.form.get('facilityIds');
                if (facilityIdsControl.value?.length > 0) {
                    const availableValue = facilityIdsControl.value.filter((id) => lookups.some((l) => l.id === id));
                    if (availableValue.length !== facilityIdsControl.value) {
                        facilityIdsControl.setValue(availableValue, { onlySelf: false, emitEvent: false });
                    }
                }
            });
    }

    ngAfterViewInit(): void {
        this.systemLookups$.pipe(takeUntil(this.d$)).subscribe((systems) => {
            const systemsCount = systems.length;
            const systemIdControl = this.form.get('systemId');
            if (systemsCount === 1) {
                const singleSystemId = systems[0].id;
                systemIdControl.setValue(singleSystemId);
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.formState) {
            this.formState.complete();
        }
    }

    createLanguageSkill(): UntypedFormGroup {
        const group = this.fb.group(
            {
                languageId: [''],
                skillLevelId: ['']
            },
            {
                validators: [requiredIfNotEmptyGroupValidator]
            }
        );

        return group;
    }

    get languageSkills() {
        return this.form.get('languageSkills') as UntypedFormArray;
    }

    onAddLanguageSkill(count = 1) {
        for (; count > 0; count--) {
            this.languageSkills.push(this.createLanguageSkill());
        }
        this.form.markAsDirty();
    }

    onRemoveLanguageSkill(i: number) {
        this.languageSkills.removeAt(i);
        this.form.markAsDirty();
    }

    onEditPreferredShift() {
        const preferredShifts = this.form.get('preferredShifts');
        const data = preferredShifts.value;

        this.resourcesService
            .showPreferredShiftDialog(data)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                if (result) {
                    preferredShifts.setValue(result);
                }
            });
    }

    selectAll() {
        const facilityIdsControl = this.form.get('facilityIds');
        this.filteredFacilityLookups$
            .pipe(
                map((lookup) => {
                    return lookup
                        .filter((x, i) => !this.facilityDisabledFn({ dataItem: x, index: i }))
                        .filter((x) => x.id)
                        .map((x) => x.id);
                }),
                take(1)
            )
            .subscribe((val) => {
                facilityIdsControl.setValue(val, { onlySelf: false, emitEvent: false });
            });
        this.facilityMultiSelect.toggle();
    }

    getCustomFieldValueList(customFieldName: string): Observable<ListItem[]> {
        return this.customFieldsLookup$.pipe(
            flatMap((customFields) => {
                return customFields
                    .filter((cf) => cf && cf.name.toLowerCase() === customFieldName.toLowerCase())
                    .map((cf) => cf.fieldValues);
            })
        );
    }

    getCustomFieldByName(name: string): CustomSystemFieldLookup {
        let customField: CustomSystemFieldLookup;
        this.customFieldsLookup$.pipe(take(1)).subscribe((fields) =>
            fields
                .filter((cf) => cf.name.toLowerCase() === name.toLowerCase())
                .map((cf) => {
                    customField = cf;
                })
        );
        return customField;
    }

    customFieldValueChanged(customFieldName: string, $event: number[]) {
        const customFieldControl = this.form.get('customFields');
        const customField = this.getCustomFieldByName(customFieldName);
        if (customField && customFieldControl.value.find((x) => x.customFieldId === customField.id)) {
            let currentVal = customFieldControl.value;
            let existing = currentVal.find((x) => x.customFieldId === customField.id);
            existing.value = $event;
            customFieldControl.setValue(currentVal);
        } else {
            let currentVal = customFieldControl.value;
            currentVal.push({ customFieldId: customField.id, value: $event });
            customFieldControl.setValue(currentVal);
        }
    }

    facilityDisabledFn = (item: { dataItem: FacilityLookup; index: number }) => {
        if (!this.ffIRPCustomizedProfAndSpec) {
            return false;
        }

        for (const facility of this.availableFacilityLookups) {
            if (facility.id === item.dataItem.id) {
                return false;
            }
        }

        return true;
    };
}
