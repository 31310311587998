import { createReducer, Action, on } from "@ngrx/store";
import { LoadingTypes } from "src/app/shared/models";
import { FacilityLookup, ProfessionLookup, SpecialtyLookup } from "src/app/shared/models/lookups";
import { FacilityConfiguration } from "src/app/shifts/models/facility-lookups.model";

import * as internalPoolActions from 'src/app/internal-pool/store/actions';


export interface FacilityLookupsState {
    error: string;
    loading: LoadingTypes;
    facilities: FacilityLookup[];
    facilityConfigurations: FacilityConfiguration[];
    professions: ProfessionLookup[];
    specialties: SpecialtyLookup[];
}

export const initialState: FacilityLookupsState = {
    error: '',
    loading: LoadingTypes.INIT,
    facilities: [],
    facilityConfigurations: [],
    professions: [],
    specialties: []
};

const reducer = createReducer(
    initialState,
    on(internalPoolActions.loadFacilityLookups, (state) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(internalPoolActions.loadFacilityLookupsFail, (state) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        error: state.error
    })),
    on(internalPoolActions.loadFacilityLookupsSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        facilities: action.facilityLookups.facilities,
        facilityConfigurations: action.facilityLookups.facilityConfigurations,
        professions: action.facilityLookups.professions,
        specialties: action.facilityLookups.specialties
    }))
);

export function facilityLookupsReducer(state: FacilityLookupsState | undefined, action: Action) {
    return reducer(state, action);
}
