import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as internalPoolActions from '../actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ClientService } from 'src/app/shared/services/client.service';

@Injectable()
export class SystemEffect {

    constructor(
        private readonly clientService: ClientService,
        private readonly actions$: Actions
    ) { }

    loadIsNyuSystem$ = createEffect(() => this.actions$.pipe(
        ofType(internalPoolActions.loadIsNyuSystem),
        switchMap(() => this.clientService.isNyuSystem().pipe(
            map(response => internalPoolActions.loadIsNyuSystemSuccess({ isNyuSystem: response })),
            catchError((error) => of(internalPoolActions.loadIsNyuSystemFail({ error })))
        ))
    ))
}
