import { Pipe, PipeTransform } from '@angular/core';

const nonAlphaNumericCharacters = /[^\w]/g;
const containsDigits = /\d/gi;
const containsOr = /\sor\s/gi;
const ext = /ext/i;
const newFormat = /\d{10}( \d*)?/;

const or = ' or ';
const usCountryCode = '1';
const extensionSeparator = 'Ext.';

/**
 * Formats a facility's phone number into the standard `(xxx) xxx-xxxx` format.
 *
 * This pipe is less opinionated than the `phoneFormat` pipe to handle arbitrary
 * data in the facility's phone number field: multiple numbers, names, toll free
 * indicators, mnemonic numbers, etc.
 *
 * When this pipe encounters an unexpected value, it returns the value passed in
 * rather than attempting to force the value into an expected format.
 *
 * Example:
 * ```typescript
 * const pipe = new FacilityPhonePipe();
 *
 * console.log(pipe.transform('5555555555')); // (555) 555-5555
 * console.log(pipe.transform('4444444444 Toll Free: 1-800-CALL-JOHN)); // 4444444444 Toll Free: 1-800-CALL-JOHN
 * ```
 */
@Pipe({
    name: 'facilityPhone'
})
export class FacilityPhonePipe implements PipeTransform {
    transform(value: string | null | undefined): string {
        if (!value) {
            return '';
        }

        value = value.trim();

        if (containsOr.test(value)) {
            return value
                .split(containsOr)
                .map((x) => this.transform(x))
                .join(or);
        }

        const fields = newFormat.test(value) ? value.split(' ') : value.split(ext);

        if (!containsDigits.test(fields[0])) {
            // Pass through words that happen to be the same length as a valid
            // phone number but contain 0 numbers. Phone numbers containing
            //mnemonic phrases typically contain a numeric area code.
            return value;
        }

        const [phone, extension] = fields.map((x) => x.replace(nonAlphaNumericCharacters, ''));

        let countryCode = usCountryCode;
        let areaCode: string;
        let phoneNumber = '';

        switch (phone.length) {
            case 10:
                areaCode = phone.slice(0, 3);
                phoneNumber = phone.slice(3);
                break;
            case 11:
                countryCode = phone[0];
                areaCode = phone.slice(1, 4);
                phoneNumber = phone.slice(4);
                break;
            case 12:
                countryCode = phone.slice(0, 3);
                areaCode = phone.slice(3, 5);
                phoneNumber = phone.slice(5);
                break;
            default:
                // Pass through non-standard values through rather than
                // attempting to force them into a format.
                return value;
        }

        countryCode = this.normalizeCountry(countryCode);
        const effectiveExtension = this.normalizeExtension(extension);
        const exchange = phoneNumber.slice(0, 3);
        const station = phoneNumber.slice(3);

        return `${countryCode} (${areaCode}) ${exchange}-${station} ${effectiveExtension}`.trim();
    }

    private normalizeExtension(extension: string): string {
        if (!extension) {
            return '';
        }

        return extensionSeparator + extension;
    }

    private normalizeCountry(country: string): string {
        if (country === usCountryCode) {
            return '';
        }

        return country;
    }
}
