import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'dialog-layout',
    templateUrl: 'dialog-layout.component.html',
    styleUrls: ['./dialog-layout.component.scss']
})
export class DialogLayoutComponent {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() isLoading: boolean;
    @Input() upperButtonTitle: string;
    @Input() backArrow?: boolean;
    @Output() upperButtonClicked = new EventEmitter();

    constructor(public dialogRef: MatDialogRef<DialogLayoutComponent>) {}

    close(): void {
        this.dialogRef.close();
    }

    upperButtonClick() {
        this.upperButtonClicked.emit();
    }
}
