import { createAction, props } from '@ngrx/store';
import { VendorBank } from 'src/app/admin/vendor-details/models/vendor-bank.model';

export const loadVendorBanks = createAction('[VENDOR BANKING COMPONENT] Load Vendor Banks');
export const loadVendorBanksSuccess = createAction('[CONNECT BANKS API] Load Vendor Banks Success', props<{ banks: VendorBank[] }>());
export const loadVendorBanksFailure = createAction('[CONNECT BANKS API] Load Vendor Banks Failure', props<{ error: unknown }>());

export const addCustomBank = createAction('[VENDOR BANKING COMPONENT BANK DROPDOWN] Add Custom Bank', props<{ bankName: string }>());
export const addCustomBankSuccess = createAction('[CONNECT BANKS API] Add Custom Bank Success', props<{ bank: VendorBank }>());
export const addCustomBankFailure = createAction('[CONNECT BANKS API] Add Custom Bank Failure', props<{ error: unknown }>());

export const vendorBankActions = {
    loadVendorBanks,
    loadVendorBanksSuccess,
    loadVendorBanksFailure,
    addCustomBank,
    addCustomBankSuccess,
    addCustomBankFailure
}
