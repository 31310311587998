import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { PhoneType } from 'src/app/shared/models/candidate';
import { VendorPhone } from '../models/vendor.model';
import { VendorService } from '../services/vendor.service';

@Component({
    selector: 'ayac-vendor-phones',
    templateUrl: './vendor-phones.component.html',
    styleUrls: ['./vendor-phones.component.scss']
})
export class VendorPhonesComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    get phonesFormArray(): UntypedFormArray {
        return this.form.get('phones') as UntypedFormArray;
    }
    form: UntypedFormGroup;
    phoneTypes: PhoneType[] = [];
    @Input() vendorId?: number;
    @Input() saveCalled?: Subject<void>;
    @Input() isSaving: boolean = false;
    @Output() onSaveSuccess = new EventEmitter();
    @Output() onSaveError = new EventEmitter<string>();
    @Output() onChangesMade = new EventEmitter();
    phoneIdsToDelete: number[] = [];
    isPopulated = false;
    private getEmailsSubscription?: Subscription;

    constructor(private readonly formBuilder: UntypedFormBuilder, private readonly vendorService: VendorService) {
        super();
        this.form = this.formBuilder.group({
            phones: this.formBuilder.array([])
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.vendorId) {
            this.reloadPhones();
        }
    }

    ngOnInit(): void {
        this.vendorService
            .getPhoneTypes()
            .pipe(takeUntil(this.d$))
            .subscribe((phoneTypes) => (this.phoneTypes = phoneTypes));

        this.saveCalled?.pipe(takeUntil(this.d$))?.subscribe((_) => {
            this.save();
        });
    }

    addPhone(phone?: VendorPhone): void {
        const phoneForm = this.formBuilder.group({
            id: this.formBuilder.control(phone?.id),
            countryCode: this.formBuilder.control(phone?.countryCode ?? '1'),
            note: this.formBuilder.control(phone?.note),
            phoneTypeId: this.formBuilder.control(
                phone ? phone.vendorPhoneNumberTypeId : this.phoneTypes?.find((x) => x)?.id,
                Validators.required
            ),
            phoneNumber: this.formBuilder.control(
                phone ? `${phone.areaCode}${phone.exchange}${phone.station}${phone.extension}` : '',
                Validators.required
            )
        });

        this.phonesFormArray.push(phoneForm);
    }

    getPhones(): VendorPhone[] {
        const result: VendorPhone[] = [];
        this.phonesFormArray.controls.forEach((phoneForm: UntypedFormGroup) => {
            const phone = phoneForm.get('phoneNumber').value.replace(/\D/g, '');
            if (!phone) {
                return;
            }

            result.push({
                id: phoneForm.get('id').value,
                vendorId: this.vendorId,
                vendorPhoneNumberTypeId: phoneForm.get('phoneTypeId').value,
                countryCode: phoneForm.get('countryCode').value,
                areaCode: phone.substring(0, 3),
                exchange: phone.substring(3, 6),
                station: phone.substring(6, 10),
                extension: phone.length > 10 ? phone.substring(10, 14) : null,
                note: phoneForm.get('note').value
            });
        });

        return result;
    }

    removePhone(index: number): void {
        const phoneId = this.phonesFormArray.at(index).get('id').value;
        if (phoneId) {
            this.phoneIdsToDelete.push(phoneId);
        }

        this.phonesFormArray.removeAt(index);
    }

    save(): void {
        const phones = this.getPhones();
        const saveCalls: Observable<VendorPhone>[] = [];
        this.phoneIdsToDelete.forEach((x) => saveCalls.push(this.vendorService.deletePhoneNumber(x)));
        phones.forEach((phone) => {
            if (phone.id) {
                saveCalls.push(this.vendorService.updatePhoneNumber(phone));
            } else {
                saveCalls.push(this.vendorService.addPhoneNumber(phone));
            }
        });

        forkJoin(saveCalls)
            .pipe(takeUntil(this.d$))
            .subscribe(
                (saveResults) => {
                    this.onSaveSuccess.emit();
                    this.reloadPhones();
                },
                (error) => {
                    this.onSaveError.emit('An error occured while saving vendor phones. Please try again later.');
                }
            );
    }

    reloadPhones(): void {
        if (this.isSaving) {
            return;
        }

        this.getEmailsSubscription?.unsubscribe();

        if (!this.vendorId) {
            this.getEmailsSubscription = this.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((valueChange) => {
                this.onChangesMade.emit();
            });
            return;
        }

        this.isPopulated = false;
        this.phonesFormArray.clear();

        this.vendorService
            .getPhones(this.vendorId)
            .pipe(first())
            .subscribe((phones) => {
                phones.forEach((phone) => this.addPhone(phone));
                this.isPopulated = true;

                setTimeout(() => {
                    this.getEmailsSubscription = this.form.valueChanges
                        .pipe(takeUntil(this.d$))
                        .subscribe((valueChange) => {
                            this.onChangesMade.emit();
                        });
                });
            });
    }
}
