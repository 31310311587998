import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap,filter, mapTo, map, catchError } from 'rxjs/operators';
import { showWelcome, disableWelcome, updatePreference } from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeDialogComponent } from 'src/app/shared/components/welcome-dialog/welcome-dialog.component';
import { CoreRepositoryService } from '../../services/core-repository.service';
import { EMPTY } from 'rxjs';
const DASHBOARD_PREFERENCE_ID = 9;
const DASHBOARD_PREFERENCE_FIELD = 'tutorial_shown';

@Injectable()
export class CoreWelcomeEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly dialog: MatDialog,
        private readonly coreRepository: CoreRepositoryService
    ) { }

    showWelcomeDialog$ = createEffect(() => this.actions$.pipe(
        ofType(showWelcome),
        switchMap((action) => {
            return this.coreRepository.getPreferences(DASHBOARD_PREFERENCE_ID).pipe(
                filter((response) => {
                    if (response.preference) {
                        try {
                            const preference = JSON.parse(response.preference);
                            return !preference[DASHBOARD_PREFERENCE_FIELD];
                        } catch(e) {
                            return false;
                        }
                    } else {
                        return true
                    }

                }),
                catchError((e) => {
                    console.error(e);
                    return EMPTY;
                })
            )
        }),
        switchMap(() => {
            const options = {
                hasBackdrop: true
            }
            const ref = this.dialog.open(WelcomeDialogComponent, options);

            return ref.afterClosed()
                .pipe(mapTo(disableWelcome()))
        })
    ));

    disableWelcome$ = createEffect(() => this.actions$.pipe(
        ofType(disableWelcome),
        map(() => {
            const preference = {[DASHBOARD_PREFERENCE_FIELD]: true};
            return updatePreference({id: DASHBOARD_PREFERENCE_ID, preference });
        })
    ));
}
