import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { AyaLeadsJobDetailsActions, AyaLeadsPageActions } from '../actions';
import { map, catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ServerResponseStatus } from 'src/app/shared/models/submittals';
import { Router } from '@angular/router';
import { AyaLeadsJobDetailsResponse } from 'src/app/aya-leads/models/aya-leads-job-details.response';
import { AyaLeadsJobApplicantsResponse } from 'src/app/aya-leads/models/aya-leads-job-applicants.response';
import { ApplicationBaseResponse } from 'src/app/aya-leads/models/aya-lead-application-base.response';
import { AyaLeadsService } from 'src/app/aya-leads/services/aya-leads.service';

@Injectable()
export class AyaLeadsJobDetailsEffect extends BaseEffect {
    getAyaLeadsJobDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsJobDetailsActions.loadAyaLeadsJobDetails),
            exhaustMap((action) =>
                this.ayaLeadsService.getJobDetails(action.jobId).pipe(
                    map((response: AyaLeadsJobDetailsResponse) => {
                        if (response.responseStatus === ServerResponseStatus.SUCCESS) {
                            return AyaLeadsJobDetailsActions.loadAyaLeadsJobDetailsSuccess({
                                jobDetails: response.job
                            });
                        } else {
                            return AyaLeadsJobDetailsActions.loadAyaLeadsJobDetailsFail({
                                error: response.errors?.length
                                    ? response.errors[0]
                                    : 'Failed to load Aya leads Job Details'
                            });
                        }
                    }),
                    catchError((error: unknown) => of(AyaLeadsJobDetailsActions.loadAyaLeadsJobDetailsFail({ error })))
                )
            )
        );
    });

    getAyaLeadsJobApplicants$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsJobDetailsActions.loadAyaLeadsJobApplicants),
            exhaustMap((action) =>
                this.ayaLeadsService.getJobApplicants(action.jobId).pipe(
                    map((response: AyaLeadsJobApplicantsResponse) => {
                        if (response.responseStatus === ServerResponseStatus.SUCCESS) {
                            return AyaLeadsJobDetailsActions.loadAyaLeadsJobApplicantsSuccess({
                                jobApplicants: response.job
                            });
                        } else {
                            return AyaLeadsJobDetailsActions.loadAyaLeadsJobApplicantsFail({
                                error: response.errors?.length
                                    ? response.errors[0]
                                    : 'Failed to load Aya leads Job Applicants'
                            });
                        }
                    }),
                    catchError((error: unknown) =>
                        of(AyaLeadsJobDetailsActions.loadAyaLeadsJobApplicantsFail({ error }))
                    )
                )
            )
        );
    });

    loadAyaLeadsJobApplicantsCardData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsJobDetailsActions.loadAyaLeadsJobApplicantsCardData),
            mergeMap(({ jobId }) => [
                AyaLeadsJobDetailsActions.loadAyaLeadsJobApplicants({ jobId }),
                AyaLeadsPageActions.loadAyaLeadApplicationStatuses()
            ])
        );
    });

    removeAyaLeadsJob$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsJobDetailsActions.removeAyaLeadsJob),
            exhaustMap((action) =>
                this.ayaLeadsService.removeJob(action.jobId).pipe(
                    map((response: ApplicationBaseResponse) => {
                        if (response.responseStatus === ServerResponseStatus.SUCCESS) {
                            this.router.navigate(['/client', 'jobs']);
                            return AyaLeadsJobDetailsActions.removeAyaLeadsJobSuccess();
                        } else {
                            return AyaLeadsJobDetailsActions.removeAyaLeadsJobFail({
                                error: response.errors?.length ? response.errors[0] : 'Failed to remove Aya leads Job'
                            });
                        }
                    }),
                    catchError((error: unknown) => of(AyaLeadsJobDetailsActions.removeAyaLeadsJobFail({ error })))
                )
            )
        );
    });

    constructor(
        private readonly actions$: Actions,
        private readonly ayaLeadsService: AyaLeadsService,
        toasterService: ToasterService,
        private readonly router: Router
    ) {
        super(toasterService);
    }
}
