import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { VendorDetailsFilesComponent } from './vendor-details-files.component';

@NgModule({
    imports: [CommonModule, SharedModule],
    exports: [VendorDetailsFilesComponent],
    declarations: [VendorDetailsFilesComponent]
})
export class VendorDetailsFilesModule {}
