import { Action, createReducer, on } from "@ngrx/store";
import { AyaLeadsApplicationStatusDetails } from "src/app/aya-leads/models/aya-leads-application-status-details.model";
import { LoadingTypes } from "src/app/shared/models";
import { AyaLeadsApiActions, AyaLeadsPageActions } from "../actions";

export interface AyaLeadsLookupsState {
    ayaLeadsApplicationStatuses: AyaLeadsApplicationStatusDetails[];
    ayaLeadsApplicationStatusesLoading: LoadingTypes;
    ayaLeadsApplicationStatusesApiLoadTimeMs: number;
}

export const ayaLeadsLookupsInitialState: AyaLeadsLookupsState = {
    ayaLeadsApplicationStatuses: [],
    ayaLeadsApplicationStatusesLoading: LoadingTypes.INIT,
    ayaLeadsApplicationStatusesApiLoadTimeMs: undefined
};

export const ayaLeadsLookupsReducer = (reducerState: AyaLeadsLookupsState, reducerAction: Action) => {
    return createReducer(
        ayaLeadsLookupsInitialState,
        on(
            AyaLeadsPageActions.loadAyaLeadApplicationStatuses,
            (state): AyaLeadsLookupsState => ({
                ...state,
                ayaLeadsApplicationStatusesLoading: LoadingTypes.LOADING
            })
        ),
        on(
            AyaLeadsApiActions.loadAyaLeadApplicationStatusesSuccess,
            (state, action): AyaLeadsLookupsState => ({
                ...state,
                ayaLeadsApplicationStatuses: action.ayaLeadsApplicationStatuses,
                ayaLeadsApplicationStatusesLoading: LoadingTypes.LOADED,
                ayaLeadsApplicationStatusesApiLoadTimeMs: action.loadTimeMs
            })
        ),
        on(
            AyaLeadsPageActions.loadAyaLeadApplicationStatusesFromStoreSuccess,
            (state): AyaLeadsLookupsState => ({
                ...state,
                ayaLeadsApplicationStatusesLoading: LoadingTypes.LOADED
            })
        ),        
        on(
            AyaLeadsApiActions.loadAyaLeadApplicationStatusesFailure,
            (state): AyaLeadsLookupsState => ({
                ...state,
                ayaLeadsApplicationStatuses: [],
                ayaLeadsApplicationStatusesLoading: LoadingTypes.FAIL,
                ayaLeadsApplicationStatusesApiLoadTimeMs: undefined
            })
        )
    )(reducerState, reducerAction);
};
