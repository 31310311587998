import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from 'src/app/admin/store/actions/vendor-candidates-detail-files.actions';
import * as selectors from 'src/app/admin/store/selectors/vendor-candidates-detail-files.selectors';
import { catchError, exhaustMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { ToasterService } from 'src/app/core/services';
import { select, Store } from '@ngrx/store';
import { VendorCandidatesDetailFilesService } from 'src/app/admin/vendor-candidates-detail/services/vendor-candidates-detail-files.service';

@Injectable()
export class VendorCandidateFilesEffects extends BaseEffect {
    loadVendorCandidateFileList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadVendorCandidateDetailFiles),
            withLatestFrom(this._store$.pipe(select(selectors.selectVendorCandidateFilesSearchQuery))),
            switchMap(([action, query]) =>
                this._vendorCandidateFileService.getVendorCandidateFiles(action.candidateId, query).pipe(
                    map((response) =>
                        actions.loadVendorCandidateDetailFilesSuccess({
                            candidateFiles: response,
                            candidateId: action.candidateId
                        })
                    ),
                    catchError((error) => of(actions.loadVendorCandidateDetailFilesFailure({ error })))
                )
            )
        )
    );

    loadFileTypes$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadFileTypes),
            mergeMap((x) =>
                this._vendorCandidateFileService.getFileTypes().pipe(
                    map((response) =>
                        actions.loadFileTypesSuccess({
                            fileTypes: response
                        })
                    ),
                    catchError((error) => of(actions.loadFileTypesFailure({ error })))
                )
            )
        )
    );

    deleteVendorCandidateFiles$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.deleteVendorCandidateDetailFiles),
            exhaustMap((action) => {
                return this._vendorCandidateFileService.deleteFiles(action.candidateId, action.fileIds).pipe(
                    switchMap(() => [
                        actions.deleteVendorCandidateDetailFilesSuccess({
                            success: true
                        }),
                        actions.loadVendorCandidateDetailFiles({
                            candidateId: action.candidateId
                        })
                    ]),
                    catchError((error) => of(actions.deleteVendorCandidateDetailFilesFailure({ error })))
                );
            })
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _vendorCandidateFileService: VendorCandidatesDetailFilesService,
        private readonly _dialogService: DialogService,
        private readonly _toasterService: ToasterService,
        private readonly _store$: Store<{}>
    ) {
        super(_toasterService);
    }
}
