import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmationDialogData } from 'src/app/shared/models/dialog.models';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { PasswordValidators } from 'src/app/shared/utilities/password-validators';
import { CoreStoreFacade } from '../state/core-store.facade';
import { Observable, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { CoreActionTypes } from '../state/core.actions';

@Component({
    selector: 'change-password-dialog',
    templateUrl: 'change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
    form = new UntypedFormGroup({
        currentPassword: new UntypedFormControl('', [Validators.required]),
        newPassword: new UntypedFormControl('', [
            Validators.required,
            PasswordValidators.minDigits,
            PasswordValidators.minLowers,
            PasswordValidators.minUppers,
            PasswordValidators.minChars
        ]),
        confirmPassword: new UntypedFormControl('', [
            Validators.required,
            PasswordValidators.matchValues('newPassword')
        ])
    });

    loading$: Observable<boolean>;

    private readonly subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogData,
        private readonly store: CoreStoreFacade,
        private readonly actions$: Actions
    ) {
        this.loading$ = this.store.isLoading$;
    }

    ngOnInit() {
        this.subscription.add(
            this.actions$.pipe(ofType(CoreActionTypes.ResetPasswordSuccess)).subscribe(() => this.dialogRef.close())
        );
    }

    submit() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        const { currentPassword, newPassword } = this.form.value;
        this.store.resetPassword(currentPassword, newPassword);
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
