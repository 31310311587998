import { Component, EventEmitter, Output, Input, ViewChild, ElementRef, HostListener } from "@angular/core";

@Component({
	selector: 'grid-filter-popup',
	templateUrl: './filter-popup.component.html',
	host: {
		'class': 'k-filterpopup'
	}
})
export class FilterPopupComponent {
	isOpened: boolean = false;

	@Input() width: number = 200;
    @Input() isFilterActive: boolean = false;
    @Input() disableClear: boolean = false;
	@Input() uniqueFilterClass: string;
	@Output() onResetClick = new EventEmitter<any>();
	@Output() onFilterClick = new EventEmitter<any>();
	@Output() onFilterToggle = new EventEmitter<boolean>();
	@Output() onOpen = new EventEmitter();
	@Output() onClose = new EventEmitter();

	@ViewChild('popupAnchor', { static: true }) public popupAnchor: ElementRef;
	@ViewChild('popup', { read: ElementRef }) public popup: ElementRef;

	@HostListener('keydown', ['$event'])
	public keydown(event: any): void {
		if (event.keyCode === 27) {
			this.close();
		}
	}

	@HostListener('document:click', ['$event'])
	public documentClick(event: any): void {
		if (!this.contains(event.target) && !event.target.closest("kendo-calendar")) {
			this.close();
		}
	}

	filterToggled(): void {
		this.isOpened = !this.isOpened;

		this.onFilterToggle.emit(this.isOpened);
	}

	onResetClicked($event): void {
		this.close();
		this.onResetClick.emit($event);
	}

	onFilterClicked($event): void {
		this.close();
		this.onFilterClick.emit($event)
	}

	close(): void {
		this.isOpened = false;
	}

	onPopupOpen(): void {
		this.onOpen.emit();
	}

	onPopupClose(): void {
		this.onClose.emit();
	}

	//if the click was in popup, stop propagation to prevent popup closing
	popupClicked($event):void {
		$event.stopPropagation();
	}

	private contains(target: any): boolean {
		return this.popupAnchor.nativeElement.contains(target) ||
			(this.popup ? this.popup.nativeElement.contains(target) : false);
	}
}
