import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmationDialogData } from 'src/app/shared/models/dialog.models';

@Component({
	selector: 'information-dialog',
	templateUrl: 'information-dialog.component.html',
	styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent {
	constructor(public dialogRef: MatDialogRef<InformationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogData) { }

	close(isConfirmed: boolean = false): void {
		this.dialogRef.close(isConfirmed);
	}
}
