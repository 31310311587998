import { AbstractControl } from "@angular/forms";

export function isFormControlRequired(control?: AbstractControl): boolean {
    if (control && control.validator) {
        const validator = control.validator({} as AbstractControl);

        if (validator && validator.required) {
            return true;
        }
    }
    return false;
}
