import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { IdentityService } from 'src/app/shared/services/identity.service';

@Component({
    selector: 'ayac-iframe-page',
    templateUrl: './iframe-page.component.html',
    styleUrls: ['./iframe-page.component.scss']
})
export class IframePageComponent implements OnInit {
    pageUrl$: Observable<SafeUrl>;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly sanitizer: DomSanitizer,
        private readonly identity: IdentityService,
    ) { }

    ngOnInit(): void {
        this.pageUrl$ = this.route.data.pipe(
            map(d => {
                const url = new URL(d['url']);
                const sendEmail = d['sendEmail'];

                if (sendEmail) {
                    const email = this.identity.userIdentity.email;
                    url.searchParams.append('email', email);
                }
                return url.toString();
            }),
            map(d => this.sanitizer.bypassSecurityTrustResourceUrl(d)),
            catchError(() => of(null))
        );
    }

}
