import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators';
import * as coreActions from 'src/app/core/state/actions';
import { ClientService } from 'src/app/shared/services/client.service';
import { AppState } from 'src/app/store/state/App.state';
import * as coreSelectors from "src/app/core/state/selectors";
import { Store, select } from '@ngrx/store';
import { CoreActionTypes } from 'src/app/core/state/core.actions';

@Injectable()
export class ClientSharedDataEffect {
    constructor(
        private readonly actions: Actions,
        private readonly clientService: ClientService,
        private readonly store: Store<AppState>
    ) {
    }

    loadClientSharedData$ = createEffect(() => this.actions.pipe(
        ofType(coreActions.loadClientSharedData),
        withLatestFrom(this.store.pipe(select(coreSelectors.selectIsSharedDataLoaded))),
        filter(([action, isLoaded]) => !isLoaded),
        exhaustMap(() => this.clientService.getClientSharedData().pipe(
            map(response => coreActions.loadClientSharedDataSuccess(
                {
                    data: response
                })),
            catchError((error) => of(coreActions.loadClientSharedDataFail({ error })))
        ))
    ));

    clearClientSharedData$ = createEffect(() => this.actions.pipe(
        ofType(CoreActionTypes.Logout),
        map((action) => {
            return coreActions.clearClientSharedData();
        })
    ));
}
