import { createAction, props } from '@ngrx/store';
import { FileAttachment, FileValidationResult } from 'src/app/shared/models/attachment';
import { FullShiftEditModel } from 'src/app/shared/models/shifts/full-shift-edit.model';
import { ShiftAttachment } from '../../models';
import { AttachmentFeature } from 'src/app/shared/models';

export const loadShiftAttachments = createAction(
    '[SHIFTS ATTACHMENTS] Load Attachments',
    props<{ shiftId: number }>()
);
export const loadShiftAttachmentsSuccess = createAction(
    '[SHIFTS ATTACHMENTS] Load Attachments Success',
    props<{ attachments: ShiftAttachment[] }>()
);

export const loadShiftAttachmentsFail = createAction(
    '[SHIFTS ATTACHMENTS] Load Attachments Failed',
    props<{ error: any }>()
);

export const loadSeriesAttachments = createAction(
    '[SHIFTS ATTACHMENTS] Load Series Attachments',
    props<{ seriesId: string }>()
);

export const deleteShiftAttachment = createAction(
    "[SHIFTS ATTACHMENTS] Delete Shift Attachments", 
    props<{ shiftId: number, id: number }>()
);
export const deleteShiftAttachmentSuccess = createAction(
    "[SHIFTS ATTACHMENTS] Delete Shift Attachments success",
    props<{ shiftId: number, id: number }>()
);
export const deleteShiftAttachmentFail = createAction(
    "[SHIFTS ATTACHMENTS] Delete Shift Attachments fail",
    props<{ error: any }>()
);

export const saveShiftAttachment = createAction(
    "[SHIFTS ATTACHMENTS] Save Shift Attachment",
    props<{ shiftId: number, fileToUpload: File, internalOnly?: boolean }>()
);
export const saveShiftAttachmentSuccess = createAction(
    "[SHIFTS ATTACHMENTS] Save Shift Attachment success",
    props<{fileValidationResult: FileValidationResult[], shiftId: number}>()
);
export const saveShiftAttachmentFail = createAction(
    "[SHIFTS ATTACHMENTS] Save Shift Attachment fail",
    props<{ fileValidationResult: FileValidationResult, error: any }>()
);

export const loadShiftAttachment = createAction(
    "[SHIFTS ATTACHMENTS] Load Shift Attachment",
    props<{ shiftId: number, id: number, fileName?: string }>()
);
export const loadShiftAttachmentSuccess = createAction(
    "[SHIFTS ATTACHMENTS] Load Shift Attachment success"
);
export const loadShiftAttachmentFail = createAction(
    "[SHIFTS ATTACHMENTS] Load Shift Attachment fail",
    props<{ error: any }>()
);

export const updateShiftAttachment = createAction(
    "[SHIFTS ATTACHMENTS] Update Shift Attachments",
    props<{ shiftId: number, fileToUpdate: FileAttachment, internalOnly: boolean }>()
);
export const updateShiftAttachmentSuccess = createAction(
    "[SHIFTS ATTACHMENTS] Update Shift Attachments success",
    props<{ shiftId: number}>()
);
export const updateShiftAttachmentFail = createAction(
    "[SHIFTS ATTACHMENTS] Update Shift Attachments fail", 
    props<{ error: any }>()
);

export const loadPreviewShiftAttachments = createAction(
    "[SHIFTS ATTACHMENTS] Load Preview",
    props<{ shiftId: number, id: number, pageNum: number, file: FileAttachment}>()
);
export const loadPreviewShiftAttachmentsSuccess = createAction(
    "[SHIFTS ATTACHMENTS] Load Preview Success",
    props<{ attachmentPreview: Blob }>()
);
export const loadPreviewShiftAttachmentsFail = createAction(
    "[SHIFTS ATTACHMENTS] Load Preview Fail",
    props<{ error: any }>()
);
export const loadPreviewNextPage = createAction(
    "[SHIFTS ATTACHMENTS] Load Preview Next Page",
    props<{ pageNum: number }>()
);

export const loadPreviewShiftPageCount = createAction(
    "[SHIFTS ATTACHMENTS] Load Preview Page Count Shift Attachments",
    props<{ shiftId: number, id: number }>()
);
export const loadPreviewShiftPageCountSuccess = createAction(
    "[SHIFTS ATTACHMENTS] Load Preview Page Count Shift Attachments Success",
    props<{ total: number }>()
);
export const loadPreviewShiftPageCountFail = createAction(
    "[SHIFTS ATTACHMENTS] Load Preview Page Count Shift Attachments fail",
    props<{ error: any }>()
);

export const addShiftWithAttachments = createAction(
    "[SHIFTS ATTACHMENTS] Add Shift with Attachments",
    props<{
        shift: FullShiftEditModel,
        attachments: FileAttachment[],
        internalOnly?: boolean,
        reload?: boolean
    }>()
);
export const addSeriesWithAttachments = createAction(
    "[SHIFTS ATTACHMENTS] Add Series with Attachments",
    props<{
        shift: FullShiftEditModel,
        attachments: FileAttachment[],
        internalOnly?: boolean,
        reload?: boolean
    }>()
);

                    
export const clearShiftAttachments = createAction("[SHIFTS ATTACHMENTS] Clear Shift Attachments");
export const clearShiftAttachmentPreview = createAction("[SHIFTS ATTACHMENTS] Clear Shift Attachment Preview");

export const loadShiftAttachmentFeatures = createAction("[SHIFTS] Load Shift Attachment Features", props<{ facilityId: number }>() ); 

export const loadShiftAttachmentFeaturesSuccess = createAction( "[SHIFTS] Load Shift Attachment Features success", props<{attachmentFeatures : AttachmentFeature }>());

export const loadShiftAttachmentFeaturesFail = createAction("[SHIFTS] Load Shift Attachment Features fail", props<{ error: any }>()); 
