import { Component, Input, OnInit } from "@angular/core";
import { FilterService } from '@progress/kendo-angular-grid';
import { ListFilterBaseRow } from "./../base/listfilter-base-row";
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
    selector: 'grid-multiselect-filter-row',
    templateUrl: './multiselect-filter-row.component.html'
})
export class MultiSelectFilterRowComponent extends ListFilterBaseRow implements OnInit {
    @Input() public allowCustom: boolean = false;
    supportsMultipleValues = true;
    public filteredValue: any = [];
    constructor(filterService: FilterService) {
        super(filterService);
    }

    ngOnInit() {
        const stored = this.filterByField(this.field);
        this.filteredValue = stored ? [].concat(stored.value) : null;
    }    

    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

    public onFilterChange(changedValue: any): void {
        if (this.applyFilterOnChange) {
            this.applyFilter(
                this.filteredValue === null ? // value of the default item
                    this.removeFilter(this.field) : // remove the filter
                    this.updateFilter({ // add a filter for the field with the value
                        field: this.field,
                        operator: 'eq',
                        value: this.filteredValue
                    })
            ); // update the root filter
        } else {
            this.filteredValue === null ? // value of the default item
                    this.removeFilter(this.field) : // remove the filter
                    this.updateFilter({ // add a filter for the field with the value
                        field: this.field,
                        operator: 'eq',
                        value: this.filteredValue
                    });
        }
    }
}
