import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities';
import { interpolateParams } from 'src/app/core/utils';

@Injectable({ providedIn: 'root' })
export class VendorContactService {
    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly settings: Settings) {}

    doesUsernameExist(username: string): Observable<boolean> {
        const url = combine(this.settings.CORE, 'ayaconnect', 'auth', 'connect-user-exists');
        const params = interpolateParams({
            username
        });

        return this.http.get<boolean>(url, { params });
    }
}
