import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSearchQuery } from 'src/app/shared/grid/models';

export const getSortCriterion = <T extends GridSearchQuery>(query: T): SortDescriptor[] => {
    return query.sort?.length
        ? query.sort.map((q) => {
              if (q.field === 'unitId') {
                  return { field: 'unit', dir: q.dir };
              } else if (q.field === 'searchFacilityIds') {
                  return { field: 'facility', dir: q.dir };
              } else if (q.field === 'statusId') {
                  return { field: 'status', dir: q.dir };
              } else {
                  return q;
              }
          })
        : query.sort;
};
