import { Component, Inject, OnInit, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'timepicker-popup-dialog',
    templateUrl: 'timepicker-popup-dialog.component.html',
    styleUrls: ['timepicker-popup-dialog.component.scss']
})
export class TimepickerPopupDialogComponent implements OnInit {
    ngOnInit(): void {
        const inputs = this.element.nativeElement.querySelectorAll('input');
        [].forEach.call(inputs, (elm: any) => {
            elm.setAttribute('type', 'number');
        });
    }
    constructor(
        public dialogRef: MatDialogRef<TimepickerPopupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public element: ElementRef
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
