import { AyaLeadsJobDetailsState } from './reducers/aya-leads-job-details.reducer';
import { AyaLeadsListQueryState } from './reducers/aya-leads-list-query.reducer';
import { AyaLeadsListResultsState } from './reducers/aya-leads-list-results.reducer';
import { AyaLeadsLookupsState } from './reducers/aya-leads-lookups.reducer';
import { AyaLeadsPageState } from './reducers/aya-leads-page.reducer';

export const ayaLeadsStoreKey = 'client/ayaleads';

export interface AyaLeadsState {
    lookups: AyaLeadsLookupsState;
    results: AyaLeadsListResultsState;
    query: AyaLeadsListQueryState;
    details: AyaLeadsPageState;
    jobDetails: AyaLeadsJobDetailsState;
}
