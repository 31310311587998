import { createAction } from '@ngrx/store';

export const showWelcome = createAction(
    '[CORE] Show Welcome'
)

export const disableWelcome = createAction(
    '[CORE] Disable Welcome'
    // Looks like "We are no longer glad to see you"
)
