import { Component, Input, Output, EventEmitter, Renderer2, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { DropDownFilterSettings, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

const TEXT_INPUT_WIDTH = 40;
const TAG_SIZE = 42;
const TAG_GAP = 2;
const TAG_LABEL_SIZE = 4;

@Component({
    selector: 'grid-multiselect-filter',
    templateUrl: './multiselect-filter.component.html',
    styleUrls: ['./multiselect-filter.component.scss']
})
export class MultiSelectFilterComponent extends BaseFilterCellComponent implements AfterViewInit {
    @Input() public field: string;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() public data: any[];
    @Input() public textField: string;
    @Input() public disabled: boolean;
    @Input() public valueField: string;
    @Input() public defaultText: string;
    @Input() public addMoreText: string | undefined;
    @Input() public width: number;
    @Input() uniqueFilterClass: string;
    @Input() public singleLine: boolean = false;
    @Output() public filterChanging = new EventEmitter<any>();
    @ViewChild(MultiSelectComponent, { static: true }) multiselectComponent: MultiSelectComponent;

    inputElement: any | undefined;
    inputValuesElement: any | undefined;
    inputCheckElement: any | undefined;
    tagsHiddenCount: number = 0;

    @Input() filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'startsWith'
    };

    public get selectedValue(): any {
        const filter = this.filterByField(this.field);
        return filter ? filter.value : null;
    }

    public popupSettings = {
        width: 'auto'
    };

    constructor(filterService: FilterService,
        private readonly renderer: Renderer2,
        private readonly el: ElementRef
    ) {
        super(filterService);
    }

    public onChange(value: any): void {
        this.filterChanging.emit({ value, filter: this.filter });

        this.applyFilter(
            value === null
                ? this.removeFilter(this.field)
                : this.updateFilter({
                    field: this.field,
                    operator: 'eq',
                    value
                })
        );
        this.updateInputPlaceholder();
    }

    ngAfterViewInit() {
        if (this.width && this.multiselectComponent) {
            this.renderer.setStyle(this.multiselectComponent['wrapper'].nativeElement, 'width', `${this.width}px`);
        }
        this.updateInputPlaceholder();

        this.inputElement = this.el.nativeElement?.querySelector('.k-input-inner');
        this.inputValuesElement = this.el.nativeElement?.querySelector('.k-input-values');
        this.inputCheckElement = this.el.nativeElement?.querySelector('.input-placeholder-сheck');

        this.updateTags();
    }

    async updateTags() {
        await new Promise((resolve) => setTimeout(resolve, 1));
        this.multiselectComponent.onTagMapperChange();
    }

    async updateInputPlaceholder() {
        await new Promise((resolve) => setTimeout(resolve, 1));
        const chipsCount = this.selectedValue?.length ?? 0;

        if (chipsCount > 0) {
            const addMoreText = this.addMoreText ?? '';
            if (this.isPlaceholderFit(addMoreText)) {
                this.inputPlaceholder = addMoreText;
            }
        } else {
            this.inputPlaceholder = this.defaultText;
        }
    }

    public tagMapper = (tags: any[]) => {
        if (!this.singleLine) {
            return tags;
        }
        tags = tags
            .slice()
            .sort((a, b) =>
                this.data.findIndex((d) => d[this.valueField] === a[this.valueField]) -
                this.data.findIndex((d) => d[this.valueField] === b[this.valueField]));

        if (this.inputValuesElement && this.inputCheckElement) {
            const visibleTagsCount = this.countVisibleTags(tags);
            if (visibleTagsCount < tags.length) {
                this.tagsHiddenCount = tags.length - this.countVisibleTags(tags, true);

                return [
                    ...tags.slice(0, tags.length - this.tagsHiddenCount), 
                    tags.slice(this.tagsHiddenCount * -1), 
                ];
            }
        }

        this.tagsHiddenCount = 0;
        return tags;
    };

    public set inputPlaceholder(val: string) {
        if (this.inputElement) {
            this.renderer.setAttribute(this.inputElement, 'placeholder', val);
        }
    }

    /** It should calculate dynamic width for the placeholder text
     * to check if the input size allows to display full text */
    public isPlaceholderFit(val: string): boolean {
        if (!(this.inputElement && this.inputCheckElement)) {
            return false;
        }
        const computedStyle = getComputedStyle(this.inputElement);
        const inputWidth = this.inputElement.clientWidth -
            parseFloat(computedStyle.paddingLeft) -
            parseFloat(computedStyle.paddingRight);

        this.inputCheckElement.innerText = val;

        return inputWidth >= this.inputCheckElement.clientWidth;
    }

    public countVisibleTags(tags: any[], includeLabel: boolean = false): number {
        const maxWidth = this.inputValuesElement.offsetWidth - TEXT_INPUT_WIDTH;

        let sum = 0;
        let count = 0;
        let labelWidth = 0;

        for (let tag of tags) {
            this.inputCheckElement.innerText = tag[this.textField];
            sum += TAG_GAP;
            sum += this.inputCheckElement.offsetWidth + TAG_SIZE + TAG_GAP;

            if (includeLabel) {
                this.inputCheckElement.innerText = this.labelText(tags);
                labelWidth = TAG_GAP;
                labelWidth += this.inputCheckElement.offsetWidth + TAG_LABEL_SIZE + TAG_GAP;
            }

            if (sum + labelWidth > maxWidth) {
                return count;
            }
            count++;
        }

        return count;
    }

    labelText(tags: any[]): string {
        return `(+${tags.length} more)`
    }
}
