import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { LookupUnitItem } from 'src/app/clinical/models/lookup-unit-item-model';
import { UnitOtherRequirement } from 'src/app/clinical/models/unit-other-requirement.model';
import * as actions from 'src/app/clinical/store/actions/client-units.actions';
import {
    selectOtherRequirements,
    selectUnitOtherRequirements
} from 'src/app/clinical/store/selectors/client-units.selectors';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { IdentityService } from 'src/app/shared/services/identity.service';

@Component({
    selector: 'locums-other-requirement',
    templateUrl: './other-requirement.component.html'
})
export class OtherRequirementComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() unitDescriptionFormId: string;
    @Input() readOnly = false;
    @Input() isPrinting = false;

    @Output() valueChanged = new EventEmitter<UnitOtherRequirement[]>();

    unitOtherRequirements: UnitOtherRequirement[];
    otherRequirements: LookupUnitItem[];
    otherRequirementsForm: FormGroup;

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _identityService: IdentityService,
        private readonly store: Store
    ) {
        super();
        this.createForm();
    }

    get visibleOtherRequirements() {
        return this.otherRequirementsForm.get('visibleOtherRequirements') as FormArray;
    }

    ngOnInit(): void {
        this.store.dispatch(actions.loadOtherRequirementComponent({ unitId: this.unitDescriptionFormId }));

        forkJoin({
            otherRequirementState$: this.store
                .select(selectOtherRequirements)
                .pipe(filter((state) => state.otherRequirements !== null))
                .pipe(first()),
            unitOtherRequirementState$: this.store
                .select(selectUnitOtherRequirements)
                .pipe(filter((state) => state.unitOtherRequirements !== null))
                .pipe(first())
        }).subscribe((state) => {
            this.unitOtherRequirements = state.unitOtherRequirementState$.unitOtherRequirements;
            this.otherRequirements = state.otherRequirementState$.otherRequirements;
            this.createFormRows();
        });
    }

    createForm(): void {
        this.otherRequirementsForm = this._formBuilder.group({
            visibleOtherRequirements: this._formBuilder.array([])
        });
    }

    createFormRows(): void {
        this.otherRequirements.forEach((otherRequirement: LookupUnitItem) => {
            this.createOtherRequirementFormEntry(otherRequirement);
        });

        if (!this.readOnly && !this.isPrinting) {
            this.createOtherRequirementFormEntry(null);
        }

        if (this.unitOtherRequirements?.length > 0) {
            this.valueChanged.emit(this.unitOtherRequirements);
        }

        this.visibleOtherRequirements.valueChanges
            .pipe(takeUntil(this.d$))
            .subscribe((x: UnitOtherRequirement[]) => this.otherRequirementsChanged(x));
    }

    createOtherRequirementFormEntry(otherRequirement: LookupUnitItem | null): void {
        if (otherRequirement) {
            const match = this.unitOtherRequirements?.filter((orig) => {
                return orig.lookupUnitOtherRequirementId === otherRequirement.id;
            });

            let isRequired = false;
            if (match?.length) {
                isRequired = match[0].isRequired;
            }

            this.visibleOtherRequirements.push(
                this._formBuilder.group({
                    lookupUnitOtherRequirementId: this._formBuilder.control(otherRequirement?.id),
                    title: this._formBuilder.control(otherRequirement?.title),
                    isRequired: this._formBuilder.control(isRequired)
                })
            );
        }
    }

    otherRequirementsChanged(otherRequirements: UnitOtherRequirement[]): void {
        this.valueChanged.emit(otherRequirements.filter((x) => x.lookupUnitOtherRequirementId !== null));
    }
}
