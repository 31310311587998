import { createFeatureSelector, createSelector } from '@ngrx/store';
import { coreStoreKey, CoreStoreState } from 'src/app/core/state/core.state';
import { LoadingTypes } from 'src/app/shared/models';

const selectFeature = createFeatureSelector<CoreStoreState>(coreStoreKey);

export const selectCoreFeatureState = createSelector(selectFeature, (state) => state.core);

export const selectError = createSelector(selectCoreFeatureState, (state) => state.error);

export const getMenu = createSelector(selectCoreFeatureState, (state) => state.menu);

export const getFeaturedLogo = createSelector(selectCoreFeatureState, (state) => state.featuredLogo);

export const getFeaturedLogoSystemId = createSelector(selectCoreFeatureState, (state) => state.systemId);

export const isLoading = createSelector(selectCoreFeatureState, (state) => state.loading === LoadingTypes.LOADING);

export const isLoaded = createSelector(selectCoreFeatureState, (state) => state.loading === LoadingTypes.LOADED);

export const getSystemFields = createSelector(selectCoreFeatureState, (state) => state.systemFormFields);

export const getSystemFieldsByModule = (moduleName) => {
    return createSelector(getSystemFields, (systemFormFields) =>
        systemFormFields.filter((s) => s.moduleName === moduleName)
    );
};

export const getCustomSystemFields = createSelector(selectCoreFeatureState, (state) => state.customSystemFields);

export const getCustomSystemFieldValue = (field: string) => {
    return createSelector(selectCoreFeatureState, (state) =>
        state.customSystemFields
            .filter((cf) => cf && cf.name.toLowerCase() === field.toLowerCase())
            .reduce((arr, cf) => arr.concat(cf.fieldValues), [])
    );
};

export const isPendoInitialized = createSelector(selectCoreFeatureState, (state) => state.isPendoInitialized);

export const selectIsAuthenticated = createSelector(selectCoreFeatureState, (state) => state.isAuthenticated);

export const selectPortalUser = createSelector(selectCoreFeatureState, (state) => state.connectPortalUser);

export const selectUserTokenTerms = createSelector(selectCoreFeatureState, (state) => {
    return {
        user: state.connectPortalUser,
        termsAccepted: state.hasAcceptedTermsAndConditions
    };
});

export const selectUserProfiles = createSelector(selectCoreFeatureState, (state) => state.userProfiles);
export const selectProfilesExist = createSelector(
    selectCoreFeatureState,
    (state) => state.userProfiles && state.userProfiles.length > 1
);
