import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsGridComponent } from './components/documents-grid.component';
import { AppGridModule } from '../grid/grid.module';

@NgModule({
  imports: [
    CommonModule,
    AppGridModule
  ],
  declarations: [DocumentsGridComponent],
  exports: [DocumentsGridComponent]
})
export class DocumentsGridModule { }
