import { Pipe, PipeTransform } from '@angular/core';

/**
 * Lookup pipe used to find an object in another array that matches value.
 *
 * Example: value | lookup:array:'id'
 */
@Pipe({
    name: 'lookup'
})
export class LookupPipe implements PipeTransform {
    transform(value: number | string, items: unknown[], propertyName: string): unknown | undefined {
        if (!Array.isArray(items)) {
            return;
        }

        return items.find((item: unknown) => item[propertyName] === value);
    }
}
