import { Pipe, PipeTransform } from '@angular/core';
import { findAndReplaceTerminology } from './terminology.helper';
import { IdentityService } from '../services/identity.service';
import { ApplicationPermissions } from '../models/enums/application-permissions.enum';

@Pipe({
    name: 'terminology'
})
export class TerminologyPipe implements PipeTransform {
    hasTerminologyPermission = false;

    constructor(private readonly _identityService: IdentityService) {
        this.hasTerminologyPermission = this._identityService.hasSecurityPermission(
            ApplicationPermissions.LocumsTerminology
        );
    }

    transform(value: string): string {
        if (this.hasTerminologyPermission) {
            return findAndReplaceTerminology(value);
        }

        return value;
    }
}
