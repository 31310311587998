import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, dashboardStoreKey } from 'src/app/dashboard-new/store/dashboard.state';

const selectFeature = createFeatureSelector<DashboardState>(dashboardStoreKey);

export const selectAyaLeadsCount = createSelector(selectFeature, (state) => state.ayaLeadsCount.ayaLeadsCount);

export const selectAyaLeadsApplicationStatusIdsFilter = createSelector(
    selectFeature,
    (state) => state.ayaLeadsCount.applicationStatusIds
);
