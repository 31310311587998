import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShiftListItem } from 'src/app/shifts/models/shift-list-item.model';

@Component({
    selector: 'ayac-shifts-tab',
    templateUrl: './shifts-tab.component.html',
    styleUrls: ['./shifts-tab.component.scss']
})
export class ShiftsTabComponent {
    @Input() candidateId?: number;
    @Input() oldUserId?: number;
    @Output() viewCurrentShiftRequested = new EventEmitter<ShiftListItem>();
}
