import { FacilityAdministration, FacilityFeatureItems } from 'src/app/facilities/models/facility-administration.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { getEmptyPagingToken, PagingToken } from 'src/app/shared/models/paging-token';

const getInitialGridState = (): GridSearchQuery => ({
    filter: { logic: 'and', filters: [] },
    sort: [{ field: 'name', dir: SortTypes.ASC }],
    take: 20,
    skip: 0
});

export interface FacilitiesListState {
    loadingState: LoadingTypes;
    facilities: PagingToken<FacilityAdministration[]>;
    gridSearchQuery: GridSearchQuery;
    features: FacilityFeatureItems[];
    error: string;
}

export const getInitialFacilitiesListState = (): FacilitiesListState => ({
    loadingState: LoadingTypes.INIT,
    facilities: getEmptyPagingToken<FacilityAdministration>(),
    gridSearchQuery: getInitialGridState(),
    features: [],
    error: ''
});
