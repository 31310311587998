import { Pipe, PipeTransform } from '@angular/core';
import { WebsiteTypeId } from 'src/app/admin/vendor-details/enums/website-type-id.enum';
import { WebsiteTypeLabel } from 'src/app/admin/vendor-details/enums/website-type-label.enum';

@Pipe({
    name: 'websiteTypeLabel'
})
export class WebsiteTypeLabelPipe implements PipeTransform {
    transform(websiteTypeId: number): string {
        switch(websiteTypeId) {
            case WebsiteTypeId.MAIN:
                return WebsiteTypeLabel.MAIN;
            case WebsiteTypeId.BLOG:
                return WebsiteTypeLabel.BLOG;
            default:
                return WebsiteTypeLabel.MAIN;
        }
    }
}
