import { LoadingTypes, AttachmentFeature } from 'src/app/shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import { loadShiftAttachments, loadShiftAttachmentsSuccess, loadShiftAttachmentsFail, clearShiftAttachments,
         loadPreviewShiftAttachmentsSuccess, loadPreviewShiftPageCountSuccess, loadPreviewShiftAttachments,
         loadPreviewShiftAttachmentsFail, clearShiftAttachmentPreview, saveShiftAttachment, saveShiftAttachmentSuccess,
         loadPreviewShiftPageCount, loadPreviewShiftPageCountFail, loadShiftAttachmentFeaturesSuccess,
         loadShiftAttachmentFeaturesFail, addShiftWithAttachments, addSeriesWithAttachments, deleteShiftAttachmentSuccess } from '../actions';
import { FileAttachment } from 'src/app/shared/models/attachment';
import { ShiftAttachment } from '../../models';

export interface ShiftsAttachmentsState {
	attachments: ShiftAttachment[],
	loading: LoadingTypes,
	preview: Blob,
	previewTotal: number,
	loadingPreview: LoadingTypes,
	currentFile: FileAttachment,
	attachmentFeatures : AttachmentFeature,
	attachmentChange: boolean
}

export const initialShiftAttachmentsState: ShiftsAttachmentsState = {
	attachments: [],
	loading: LoadingTypes.INIT,
	preview: null,
	previewTotal: 0,
	loadingPreview: LoadingTypes.INIT,
	currentFile: null,
	attachmentFeatures: null,
	attachmentChange: false
}

export function shiftsAttachmentsReducer(state: ShiftsAttachmentsState, action: Action) {
	return createReducer(
		initialShiftAttachmentsState,
		on(loadShiftAttachments, (state) => {
			return (
			{
                ...initialShiftAttachmentsState,
                loading: LoadingTypes.LOADING,
				attachmentChange: state.attachmentChange
			}
        )}),

        on(saveShiftAttachment, addShiftWithAttachments, addSeriesWithAttachments, loadPreviewShiftPageCount, (state) => (
			{
                ...state,
                loadingPreview: LoadingTypes.LOADING,
                loading: LoadingTypes.LOADING
			}
        )),

        on(saveShiftAttachmentSuccess, (state) => (
			{
                ...state,
                loadingPreview: LoadingTypes.LOADED,
                loading: LoadingTypes.LOADED,
				attachmentChange: true
			}
		)),

		on(deleteShiftAttachmentSuccess, (state) => (
			{
				...state,
				loadingPreview: LoadingTypes.LOADED,
				loading: LoadingTypes.LOADED,
				attachmentChange: true
			}
		)),

		on(loadShiftAttachmentsSuccess, (state, action) => (
			{
                ...state,
                attachments: action.attachments,
                loading: LoadingTypes.LOADED,
			}
		)),

		on(loadShiftAttachmentsFail, (state) => (
			{
                ...state,
                loading: LoadingTypes.FAIL
			}
		)),

		on(loadPreviewShiftAttachments, (state, action) => (
			{
				...state,
				currentFile: action.file,
                loadingPreview: LoadingTypes.LOADING,
                loading: LoadingTypes.LOADING
			}
		)),

		on(loadPreviewShiftAttachmentsSuccess, (state, action) => (
			{
				...state,
				preview: action.attachmentPreview,
                loadingPreview: LoadingTypes.LOADED,
                loading: LoadingTypes.LOADED
			}
		)),

		on(loadPreviewShiftAttachmentsFail, (state, action) => (
			{
				...state,
                loadingPreview: LoadingTypes.FAIL,
                loading: LoadingTypes.FAIL
			}
		)),

		on(loadPreviewShiftPageCountSuccess, (state, action) => (
			{
                ...state,
                loadingPreview: LoadingTypes.LOADED,
                loading: LoadingTypes.LOADED,
				previewTotal: action.total
			}
		)),

        on(loadPreviewShiftPageCountFail, (state, action) => (
			{
                ...state,
                loadingPreview: LoadingTypes.FAIL,
                loading: LoadingTypes.FAIL,
			}
		)),

		on(clearShiftAttachmentPreview, (state) => (
			{
				...state,
				preview: initialShiftAttachmentsState.preview,
				previewTotal: initialShiftAttachmentsState.previewTotal
			}
		)),

		on(clearShiftAttachments, (state) => (
			{
                ...state,
                ...initialShiftAttachmentsState
			}
		)),
		on(loadShiftAttachmentFeaturesSuccess, (state, action) => (
			{
                ...state,
                attachmentFeatures: action.attachmentFeatures,
				loading: LoadingTypes.LOADED
			}
		)),

		on(loadShiftAttachmentFeaturesFail, (state, action) => (
			{
				...state,
				loading: LoadingTypes.FAIL
			}
		)),


	)(state, action);
}

export function reducer(state: ShiftsAttachmentsState | undefined, action: Action) {
	return shiftsAttachmentsReducer(state, action);
}
