export enum VendorFacilityStatusCode {
    None = 0,
    Offered = 1,
    Excluded = 2,
    Accepted = 3,
    Declined = 4,
    Removed = 5,
    Pending = 6,
    FacilityRemoved = 7,
    VendorRemoved = 8
}

export const FACILITY_STATUS_IDS = [
    VendorFacilityStatusCode.Offered,
    VendorFacilityStatusCode.Excluded,
    VendorFacilityStatusCode.Accepted,
    VendorFacilityStatusCode.Declined,
    VendorFacilityStatusCode.Removed,
    VendorFacilityStatusCode.Pending
];
