import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ruleName' })
export class RuleNamePipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'daybefore': {
                return 'day before';
            }
            case 'dayof': {
                return 'day of';
            }
            case 'dayafter': {
                return 'day after';
            }
            default: {
                return '';
            }
        }
    }
}
