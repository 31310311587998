
import { Action, createReducer, on } from '@ngrx/store';
import { loadPendingWoc, loadPendingWocSuccess } from '../actions/woc.actions';

export interface WocState {
    pendingWocCount: number;
}

export const initialWocState: WocState = {
    pendingWocCount: 0,
}

export function wocReducer(state: WocState, action: Action) {
    return createReducer(
        initialWocState,
        on(loadPendingWoc, (state, action) => (
            {
                ...state,
            }
        )),

        on(loadPendingWocSuccess, (state, action) => (
            {
                ...state,
                pendingWocCount: action.pendingWocCount
            }
        )),
    )(state, action);
}

export function reducer(state: WocState | undefined, action: Action) {
    return wocReducer(state, action);
}
