import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FeatureSettingCategory, FeatureSettingChangesEvent } from 'src/app/shared/feature-settings';
import { AppState } from 'src/app/store/state/App.state';
import { systemActions, systemSelectors } from 'src/app/systems/store';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ToasterService } from 'src/app/core/services';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlags } from 'src/app/shared/models/enums/feature-flag.enum';
@Component({
    selector: 'ayac-system-settings',
    templateUrl: './system-settings.component.html',
    styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent extends UnsubscribeOnDestroy implements OnInit {
    isLoading$: Observable<boolean>;
    systemSettings$: Observable<FeatureSettingCategory[]>;
    isSystemSettingsEmpty$: Observable<boolean>;
    _systemId: number;
    ffShowConfirmationDialog: boolean;

    @Input() set systemId(id) {
        this._systemId = id;
    }

    get systemId() {
        return this._systemId || Number(this._route.snapshot.paramMap.get('id'));
    }

    constructor(
        private readonly _store: Store<AppState>,
        private readonly _route: ActivatedRoute,
        private readonly toasterService: ToasterService,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit() {
        this.isLoading$ = this._store.select(systemSelectors.selectSystemSettingsLoading);
        this.systemSettings$ = this._store.select(systemSelectors.selectSystemSettings);

        this.isSystemSettingsEmpty$ = this.systemSettings$.pipe(
            map((settings) => settings.every((category) => category.features.length === 0))
        );

        this._store.dispatch(systemActions.loadSystemSettings({ systemId: this.systemId }));

        this._ldFeatureManager.isEnabled(FeatureFlags.SystemSettingsWarningPopupForDisableShiftsTiering)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled) => {
                this.ffShowConfirmationDialog = isEnabled
            });

    }

    onFeatureChanged(event: FeatureSettingChangesEvent) {
        const feature = event.current;
        const reload = event.current.isEnabled !== event.previous.isEnabled;

        if (this.ffShowConfirmationDialog) {
            this._store.dispatch(
                systemActions.confirmUpdateSystemSetting({
                    systemId: this.systemId,
                    current: event.current,
                    previous: event.previous,
                    reload
                })
            );
        } else {
            this._store.dispatch(
                systemActions.updateSystemSettings({
                    systemId: this.systemId,
                    setting: feature,
                    reload
                })
            );
        }
    }
}
