import { ConnectFacilityFeature, FaciltyFeatureDropdown } from 'src/app/facilities/models/facility-feature.model';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateUrl } from 'src/app/core/utils';
import { ContactGroupFeaturesAdminUrls } from 'src/app/shared/models/facilities/enums/contact-group-features.enum';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';

@Injectable({
    providedIn: 'root'
})
export class ContractGroupFeaturesService {
    constructor(private _http: HttpClient, @Inject(APP_CONFIG) private _settings: Settings) {}

    getContractGroupFeatures(contractGroupId: number): Observable<ConnectFacilityFeature> {
        const url = interpolateUrl(ContactGroupFeaturesAdminUrls.CONTRACT_GROUP_FEATURES, { contractGroupId });
        return this._http.get<ConnectFacilityFeature>(`${this._settings.CORE}${url}`);
    }

    putContractGroupFeatures(contractGroupId: number, permissionId: number, isEnabled: boolean): Observable<unknown> {
        const data = {
            featureId: permissionId,
            isEnabled
        };

        const url = interpolateUrl(ContactGroupFeaturesAdminUrls.CONTRACT_GROUP_FEATURES, { contractGroupId });
        return this._http.put<unknown>(`${this._settings.CORE}${url}`, data);
    }

    getFacilities(id: number, request: GridSearchQuery): Observable<GridDataResult> {
        const queryParams = getStringQueryFromGridSearch(request);
        const url = interpolateUrl(ContactGroupFeaturesAdminUrls.CONTRACT_GROUP_FACILITIES, { id });
        return this._http.get<GridDataResult>(`${this._settings.CORE}${url}${queryParams}`);
    }

    getFacilityFeatures(): Observable<FaciltyFeatureDropdown> {
        const url = ContactGroupFeaturesAdminUrls.CONTRACT_GROUP_FACILITY_FEATURES;
        return this._http.get<FaciltyFeatureDropdown>(`${this._settings.CORE}${url}`);
    }

    putFacilityFeature(featureId: number, params: Array<{ id: number; isEnabled: boolean }>): Observable<any> {
        const url = interpolateUrl(ContactGroupFeaturesAdminUrls.CONTRACT_GROUP_FEATURE_UPDATE, { featureId });
        return this._http.put<any>(`${this._settings.CORE}${url}`, params);
    }

    getFeatureEnabledTotal(featureId: number, contractGroupId: number): Observable<number> {
        const url = interpolateUrl(ContactGroupFeaturesAdminUrls.CONTRACT_GROUP_FEATURE_ENABLED_TOTAL, {
            contractGroupId,
            featureId
        });
        return this._http.get<number>(`${this._settings.CORE}${url}`);
    }
}

export function getStringQueryFromGridSearch(query: GridSearchQuery): string {
    let result = `?skip=${query.skip}&pagesize=${query.take}`;
    query.sort?.forEach((sort) => {
        result += `&sort.field=${sort.field}&sort.type=${sort.dir}`;
    });

    query.filter?.filters?.forEach((filter: FilterDescriptor) => {
        if (filter.field === 'featureId' || filter.field === 'isEnabled') {
            result += `&filter.feature.${filter.field}=${encodeURIComponent(filter.value)}`;
        } else {
            result += `&filter.${filter.field}=${encodeURIComponent(filter.value)}`;
        }
    });

    return result;
}
