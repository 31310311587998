import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { SubmittalsQueryState } from 'src/app/admin/store/state/submittals.state';
import { LoadingTypes } from 'src/app/shared/models';

export const selectSubmittalsFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectSubmittals = createSelector(selectSubmittalsFeature, (state) => state.submittalsState.submittals);

export const selectSubmittalsIsLoading = createSelector(
    selectSubmittalsFeature,
    (state) => state.submittalsState.loading === LoadingTypes.LOADING
);
/** @deprecated use selectAdminSubmittalsSearchQuery */
export const selectSubmittalssSearchQuery = createSelector(
    selectSubmittalsFeature,
    (state) => state.submittalsState.gridSearchQuery
);

export const selectSubmittalsQueryForExport = createSelector(selectSubmittalsFeature, (state) => {
    const queryForExport: SubmittalsQueryState = {
        ...state.submittalsState.gridSearchQuery,
        take: state.submittalsState.submittals.total
    };
    return queryForExport;
});

export const selectAdminSubmittalsSearchQuery = createSelector(
    selectSubmittalsFeature,
    (state) => state.submittalsState.searchQuery
);

export const selectAdminSubmittals = createSelector(
    selectSubmittalsFeature,
    (state) => state.submittalsState.submittals
);

//LOOKUPS//
export const selectSubmittalAdminLookupsStatuses = createSelector(
    selectSubmittalsFeature,
    (state) => state.submittalsState.lookups.statuses
);

export const selectSubmittalAdminLookupsSubStatuses = createSelector(
    selectSubmittalsFeature,
    (state) => state.submittalsState.lookups.subStatuses
);

export const selectSubmittalAdminLookupsSubStatusesCount = createSelector(
    selectSubmittalsFeature,
    (state) => state.submittalsState.lookups?.subStatuses?.length
);
