import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

/** Custom header component for datepicker. */
@Component({
  selector: 'example-header',
  templateUrl: 'multi-select-calendar-header.component.html',
  styleUrls: ['multi-select-calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectCalendarHeaderComponent<D> extends UnsubscribeOnDestroy {

  constructor(
    private readonly _calendar: MatCalendar<D>,
    private readonly _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private readonly _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    super();
    
    _calendar.stateChanges
      .pipe(takeUntil(this.d$))
      .subscribe(() => cdr.markForCheck());
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: any) {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  nextClicked(mode: any) {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }

  get prevDisabled() {
    const currentMonth = Number(this._dateAdapter.format(this._calendar.activeDate, {month: 'numeric'}));
    const currentYear = Number(this._dateAdapter.format(this._calendar.activeDate, {year: 'numeric'}));
    const current = currentYear * 12 + currentMonth;
    

    if (this._calendar.minDate) {
      const minMonth = Number(this._dateAdapter.format(this._calendar.minDate, {month: 'numeric'}));
      const minYear = Number(this._dateAdapter.format(this._calendar.minDate, {year: 'numeric'}));
      const min = minYear * 12 + minMonth;

      if (current - 1 < min) {
        return true;
      }
    }
    return false;
  }
}
