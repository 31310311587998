export enum JobsUrl {
    JOBS = '/AyaConnect/JobClient/GetFilteredJobs?ViewTypeId=3',
    JOBS_DETAILS = '/AyaConnect/JobClient/GetJobById',
    JOBS_DELETE = '/AyaConnect/JobClient/DeleteJob',
    JOBS_LOOKUPS = '/AyaConnect/JobClient/GetLookups',
    JOBS_PAYROLL_LOCATION_LOOKUPS = '/AyaConnect/Client/Integration/Nyu/payroll-locations',
    JOBS_BUSINESS_UNIT_LOOKUPS = '/BusinessUnits',
    JOBS_BUILDING_LOOKUPS = '/AyaConnect/Client/Integration/Nyu/buildings',
    JOBS_DEPARTMENT_LOOKUPS = '/AyaConnect/Client/Integration/Nyu/departments',
    JOBS_APPROVE = '/AyaConnect/JobApproval/ApproveJobs',
    JOBS_REJECT = '/AyaConnect/JobApproval/RejectJobs',
    JOBS_RELEASE = '/AyaConnect/JobApproval/ReleaseJobs',
    JOBS_SET_POSITIONS = '/AyaConnect/JobClient',
    JOBS_SUBMITTALS = '/AyaConnect/SubmittalClient/GetFilteredSubmittals',
    GROUP_METADATA = '/AyaConnect/JobClient/GroupMetadata',
    FACILITY_METADATA = '/AyaConnect/JobClient/FacilityMetadata',
    JOB_BILLINGRATE = '/AyaConnect/JobClient/precalculated-billing-rate',
    SAVE_JOB = '/AyaConnect/JobClient/SaveJob',
    JOBS_CONTROLLER = '/AyaConnect/JobClient',
    JOBS_IMPORT_CONTROLLER = '/ayaconnect/client/integration/jobsimport',
    EXPORT = '/AyaConnect/JobClient/Export',
    JOB_TEMPLATES = '/AyaConnect/JobTemplates/GetFilteredJobTemplates',
    JOB_TEMPLATES_LOOKUPS = '/AyaConnect/JobTemplates/GetLookups?ViewTypeId=3',
    JOB_TEMPLATE = '/AyaConnect/JobTemplates/{jobTemplateId}/expanded',
    JOB_TEMPLATE_DETAILS = '/AyaConnect/JobTemplates/{templateId}/expanded',
    JOB_TEMPLATE_DELETE = '/AyaConnect/JobTemplates/DeleteJobTemplateById?ViewTypeId=3',
    JOB_TEMPLATE_SAVE = '/AyaConnect/JobTemplates',
    HOSPITAL_PROFILE = '/AyaConnect/Client/hospital-profile',
    SAVE_PRISMA_JOB = '/AyaConnect/MSPJob/prisma',
    SAVE_BJC_JOB = '/AyaConnect/MSPJob/bjc',
    SAVE_NYU_JOB = '/AyaConnect/MSPJob/nyu',
    WORK_LOCATIONS = '/AyaConnect/Client/Geofencing/Work-Locations',
    JOB_CANDIDATES = '/AyaConnect/Client/Job/InternalPool/Submittals/{jobId}/Available-Candidates',
    JOB_SUBMIT_CANDIDATES = '/AyaConnect/Client/Job/InternalPool/Submittals/{jobId}',
    JOB_CANDIDATE_LOOKUPS = '/AyaConnect/InternalPool/Resources/913/GetLookups',
    JOB_INTERNAL_POOL_SUBMITALS = '/AyaConnect/Client/Job/InternalPool/Submittals/{jobId}/Submittals'
}
