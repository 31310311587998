import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import { InvoicesState } from '../invoices.state';
import {
    exportInvoiceLineDisputes,
    exportInvoiceLineDisputesSuccess,
    exportInvoiceLineDisputesFail,
    exportInvoiceLineDisputesHistory,
    exportInvoiceLineDisputesHistorySuccess,
    exportInvoiceLineDisputesHistoryFail
} from '../invoices.actions';
import { of } from 'rxjs';
import { selectInvoicesQuery } from '../invoices.selectors';
import { downloadBlob } from 'src/app/core/utils';
import * as moment from 'moment';
import { ToasterService } from 'src/app/core/services';

@Injectable()
export class InvoiceLineDisputesExportEffect {
    exportInvoiceDisputesHistory$ = createEffect(() => this.actions$.pipe(
        ofType(exportInvoiceLineDisputesHistory),
        switchMap((action) => {
            const fnameIds = action.invoiceIds.length === 1 ? `-${action.invoiceIds[0]}-` : '-';
            const filename = `InvoiceLinesDisputes-History${fnameIds}${moment().format('MMDDYYYY')}`;

            return this.invoicesRepository.exportInvoiceLineItemDisputes(action.invoiceIds, true)
                .pipe(
                    map(response =>
                        exportInvoiceLineDisputesHistorySuccess({
                            blob: response,
                            filename: filename
                        })
                    ),
                    catchError((error) =>
                        of(exportInvoiceLineDisputesHistoryFail({ error }))
                    ),
                )
        })
    ));

    downloadInvoiceDisputesHistory$ = createEffect(() => this.actions$.pipe(
        ofType(exportInvoiceLineDisputesHistorySuccess),
        tap((action) => {
            downloadBlob(action.blob, action.filename);
        })
    ), { dispatch: false });

    exportInvoiceDisputesHistoryFail$ = createEffect(() => this.actions$.pipe(
        ofType(exportInvoiceLineDisputesHistoryFail),
        tap((action) =>
            this.toaster.fail('Failed to export Invoice Line Disputes')
        )
    ), { dispatch: false });

    exportInvoiceDisputes$ = createEffect(() => this.actions$.pipe(
        ofType(exportInvoiceLineDisputes),
        switchMap((action) => {
            const fnameIds = action.invoiceIds.length === 1 ? `-${action.invoiceIds[0]}-` : '-';
            const filename = `InvoiceLinesDisputes${fnameIds}${moment().format('MMDDYYYY')}`;

            return this.invoicesRepository.exportInvoiceLineItemDisputes(action.invoiceIds, false)
                .pipe(
                    map(response =>
                        exportInvoiceLineDisputesSuccess({
                            blob: response,
                            filename: filename
                        })
                    ),
                    catchError((error) =>
                        of(exportInvoiceLineDisputesFail({ error }))
                    ),
                )
        })
    ));

    downloadInvoiceDisputes$ = createEffect(() => this.actions$.pipe(
        ofType(exportInvoiceLineDisputesSuccess),
        tap((action) => {
            downloadBlob(action.blob, action.filename);
        })
    ), { dispatch: false });

    exportInvoiceDisputesFail$ = createEffect(() => this.actions$.pipe(
        ofType(exportInvoiceLineDisputesFail),
        tap((action) =>
            this.toaster.fail('Failed to export Invoice Line Disputes')
        )
    ), { dispatch: false });


    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<InvoicesState>,
        private readonly invoicesRepository: InvoicesRepositoryService,
        private readonly toaster: ToasterService
    ) { }
}
