import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { systemsFeatureKey, SystemsState } from 'src/app/systems/store/systems.state';

export const selectFeature = createFeatureSelector<SystemsState>(systemsFeatureKey);

export const settingsFeature = createSelector(selectFeature, (state) => state.settings);

export const selectSystemSettings = createSelector(settingsFeature, (state) => state.systemSettings);

export const selectSystemSettingsLoading = createSelector(
    settingsFeature,
    (state) => state.loadingState === LoadingTypes.LOADING
);
