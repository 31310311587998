import { InjectionToken } from '@angular/core';
import { environment } from './environment';
import { AppVersion } from '../app/shared/services/version-checker.service';

export interface Environment {
    production: boolean;
    appVersion: AppVersion;
}

export const ENV = new InjectionToken<Environment>('env');

export function getEnv(): Environment {
    return environment;
}
