import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { AyaLeadsPageActions, AyaLeadsApiActions } from '../actions';
import { AyaLeadResumeDocumentInfo } from 'src/app/aya-leads/models/aya-lead-resume-document-info.model';
import { AyaLeadApplicationInfo } from 'src/app/aya-leads/models/aya-lead-application-info.model';

export type AyaLeadsPageState = {
    ayaLeadsApplicationId: number;
    applicationInfo: AyaLeadApplicationInfo;
    canUpdateStatus: boolean;
    detailsLoading: LoadingTypes;
    error: unknown;
    resumeInfo: AyaLeadResumeDocumentInfo[];
    resumePreview: { blobUrl: string; resumeNotFound: string | null };
};

export const ayaLeadsPageInitialState: AyaLeadsPageState = {
    ayaLeadsApplicationId: null,
    applicationInfo: null,
    canUpdateStatus: false,
    detailsLoading: LoadingTypes.INIT,
    error: null,
    resumeInfo: null,
    resumePreview: { blobUrl: null, resumeNotFound: null }
};

export const ayaLeadsPageReducer = (reducerState: AyaLeadsPageState, reducerAction: Action) => {
    return createReducer(
        ayaLeadsPageInitialState,
        on(
            AyaLeadsPageActions.loadAyaLeadApplicationInfo,
            AyaLeadsApiActions.loadAyaLeadApplicationInfoFromApi,
            (state, action): AyaLeadsPageState => ({
                ...state,
                ayaLeadsApplicationId: action.id,
                detailsLoading: LoadingTypes.LOADING
            })
        ),
        on(
            AyaLeadsPageActions.rejectAyaLeadApplicant,
            (state): AyaLeadsPageState => ({
                ...state,
                detailsLoading: LoadingTypes.LOADING
            })
        ),
        on(AyaLeadsApiActions.loadAyaLeadApplicationInfoSuccess, (state, action): AyaLeadsPageState => {
            return {
                ...state,
                applicationInfo: action.details,
                canUpdateStatus: action.canUpdateStatus,
                detailsLoading: LoadingTypes.LOADED
            };
        }),
        on(
            AyaLeadsApiActions.rejectAyaLeadApplicantSuccess,
            (state): AyaLeadsPageState => ({
                ...state,
                detailsLoading: LoadingTypes.LOADED
            })
        ),
        on(
            AyaLeadsApiActions.loadAyaLeadApplicationInfoFail,
            (state): AyaLeadsPageState => ({
                ...state,
                ayaLeadsApplicationId: null,
                applicationInfo: null,
                canUpdateStatus: false,
                detailsLoading: LoadingTypes.FAIL
            })
        ),
        on(
            AyaLeadsApiActions.loadAyaLeadResumeInfoSuccess,
            (state, action): AyaLeadsPageState => ({
                ...state,
                resumeInfo: action.result
            })
        ),
        on(
            AyaLeadsPageActions.clearAyaLeadApplicationDetails,
            (state): AyaLeadsPageState => ({
                ...state,
                ayaLeadsApplicationId: null,
                applicationInfo: null,
                resumeInfo: null
            })
        ),
        on(
            AyaLeadsApiActions.loadAyaLeadResumePreviewSuccess,
            (state, action): AyaLeadsPageState => ({
                ...state,
                resumePreview: { blobUrl: action.blobUrl, resumeNotFound: null }
            })
        ),
        on(
            AyaLeadsApiActions.loadAyaLeadResumePreviewNotFound,
            (state): AyaLeadsPageState => ({
                ...state,
                resumePreview: { blobUrl: null, resumeNotFound: 'The Candidate did not upload a resume.' }
            })
        ),
        on(
            AyaLeadsPageActions.clearAyaLeadResumePreviewSuccess,
            AyaLeadsPageActions.clearAyaLeadResumePreviewFail,
            (state): AyaLeadsPageState => ({
                ...state,
                resumePreview: ayaLeadsPageInitialState.resumePreview
            })
        )
    )(reducerState, reducerAction);
};

export const reducer = (state: AyaLeadsPageState | undefined, action: Action): AyaLeadsPageState => {
    return ayaLeadsPageReducer(state, action);
};
