import { Injectable } from '@angular/core';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';

@Injectable()
export class CoreService {
    constructor(private readonly dialog: MatDialog) {}

    openChangePasswordDialog() {
        const options: IConfirmationDialogOptions = {
            data: {
                title: 'Change Password',
                text: ''
            },
            width: '800px',
            height: 'auto',
            hasBackdrop: true
        };
        const dialogRef = this.dialog.open(ChangePasswordDialogComponent, options);

        return dialogRef.afterClosed();
    }

    openForgotPasswordDialog(username?: string) {
        const options: IConfirmationDialogOptions = {
            data: {
                title: `What's My Password? `,
                text: '',
                username: username
            },
            width: '600px',
            height: 'auto',
            hasBackdrop: true
        };
        const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, options);

        return dialogRef.afterClosed();
    }
}
