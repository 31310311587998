import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CustomFieldValueType } from 'src/app/shared/models/custom-fields/custom-field-value-type.enum';
import { EntityType } from 'src/app/shared/models/custom-fields/entity-types.enum';
import { CustomFieldService } from 'src/app/shared/services/custom-field.service';

export interface ParentDropdown {
    parentDropdownName: string;
    parentDropdownDefinitionId: number;
    parentDropdownOptions: string[];
    entityType: EntityType;
}

@Component({
    selector: 'formly-child-dropdown-input',
    templateUrl: './child-dropdown-input.component.html',
    styleUrls: ['./child-dropdown-input.component.scss']
})
export class ChildDropdownInputComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
    d$ = new Subject<void>();
    parentChildOptions: Array<{ childOption: string; parentOption: string; childOptionDescription }> = [];
    parentDropdowns: ParentDropdown[] = [];

    childOptionsForm = this.fb.group({
        parentDropdown: [''],
        parentDropdownOption: [''],
        optionValue: [''],
        optionDescription: ['']
    });

    entityType: EntityType = EntityType['Job Details'];
    parentDropdownOptions: string[];

    private cfService = inject(CustomFieldService);
    private activatedRoute = inject(ActivatedRoute);

    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.data
            .pipe(
                switchMap((route: any) =>
                    this.cfService
                        .getFieldsByContractGroupId(route.data.contractGroupId)
                        .pipe(map((results) => [results, route]))
                ),
                takeUntil(this.d$)
            )
            .subscribe(([results, route]) => {
                const dropdowns = results.filter(
                    (x) =>
                        x.valueType === CustomFieldValueType.Dropdown ||
                        x.valueType === CustomFieldValueType.RichDropdown
                );
                this.parentDropdowns = dropdowns
                    .map((x) => ({
                        parentDropdownDefinitionId: x.id,
                        parentDropdownName: x.name,
                        parentDropdownOptions:
                            x.valueType === CustomFieldValueType.RichDropdown
                                ? x.richOptions?.map((x) => x.value)
                                : x.options,
                        entityType: x.entityType
                    }))
                    .filter((option) => option?.parentDropdownDefinitionId !== route.data?.field?.id);

                if (route.data.field) {
                    this.parentDropdownOptions = this.parentDropdowns.find(
                        (x) => x.parentDropdownDefinitionId === +route.data.field.parentDropdownDefinitionId
                    ).parentDropdownOptions;
                    this.entityType = route.data.field.entityType;
                    this.childOptionsForm.patchValue({
                        parentDropdown: route.data.field.parentDropdownDefinitionId,
                        parentDropdownOption: this.parentDropdownOptions[0]
                    });
                    this.parentChildOptions = route.data.field.parentChildOptions;
                }
                this.setModelOptions();
            });

        this.field.form
            .get('entityType')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((entityType) => {
                this.entityType = entityType;
                this.parentChildOptions = [];
                this.childOptionsForm.patchValue({
                    optionValue: '',
                    parentDropdown: '',
                    parentDropdownOption: '',
                    optionDescription: ''
                });
                this.setModelOptions();
                this.parentDropdownOptions = [];
            });

        this.childOptionsForm.setParent(this.field.form);
    }

    ngOnDestroy(): void {
        this.d$.next();
        this.d$.complete();
    }

    add() {
        const childOption = {
            childOption: this.childOptionsForm.get('optionValue').value,
            childOptionDescription: this.childOptionsForm.get('optionDescription').value,
            parentOption: this.childOptionsForm.get('parentDropdownOption').value
        };
        if (
            !this.parentChildOptions.some(
                (c) =>
                    c.childOption.toLowerCase() === childOption.childOption.toLowerCase() &&
                    c.parentOption.toLowerCase() === childOption.parentOption.toLowerCase()
            )
        ) {
            this.parentChildOptions.push(childOption);
            this.setModelOptions();
            this.childOptionsForm.get('optionValue').setValue('');
            this.childOptionsForm.get('optionDescription').setValue('');
        }
    }

    delete(value: { childOption: string; parentOption: string }) {
        this.parentChildOptions = this.parentChildOptions.filter(
            (x) => !(x.childOption === value.childOption && x.parentOption === value.parentOption)
        );
        this.setModelOptions();
        this.childOptionsForm.get('optionValue').setValue(this.childOptionsForm.get('optionValue').value);
    }

    handleParentDropdownChange(parentDropdownSelection) {
        this.parentDropdownOptions = this.parentDropdowns.find(
            (x) => x.parentDropdownDefinitionId === parentDropdownSelection.value
        ).parentDropdownOptions;
        this.parentChildOptions = [];
        this.childOptionsForm.get('parentDropdownOption').setValue('');
        this.setModelOptions();
    }

    filteredParentDropdowns() {
        return this.parentDropdowns.filter((x) => x.entityType === this.entityType);
    }

    private setModelOptions() {
        this.model['parentChildOptions'] = this.parentChildOptions;
        this.model['parentDropdownDefinitionId'] = this.childOptionsForm.get('parentDropdown').value;
    }
}
