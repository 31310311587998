import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

export function emailValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }
    const emailPattern = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.]+[a-zA-Z0-9-.]+$');
    const isValid = emailPattern.test(control.value);

    return isValid ? null : { email: { value: control.value } };
}

export function notEmployeeEmailValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }
    const isValid = control.value.toString().toLowerCase().indexOf('@ayahealthcare.com') === -1;

    return isValid ? null : { employee: { value: control.value } };
}

export function validateEmailViaServer(request: (value) => Observable<boolean>) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const value = control.value;
        return request(value).pipe(
            debounceTime(500),
            map((isValid) => (isValid ? null : { 'email-is-exist': true }))
        );
    };
}
