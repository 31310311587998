import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogProgramTypesData } from 'src/app/clinical/models/dialog-program-types-data.model';

@Component({
    selector: 'ayac-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
    verifyForm: UntypedFormGroup;
    showError = false;

    constructor(
        private readonly _dialogRef: MatDialogRef<VerifyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogProgramTypesData,
        private readonly _formBuilder: UntypedFormBuilder
    ) {}

    get programTypes() {
        return this.verifyForm.get('programType');
    }

    ngOnInit(): void {
        this.verifyForm = this._formBuilder.group({
            programType: this._formBuilder.control(0)
        });
        this.verifyForm.get('programType').setValue(this.data.unitProgramType);

        this.verifyForm.get('programType').valueChanges.subscribe((value) => {
            if (value) {
                this.showError = false;
            }
        });
    }

    close(event: boolean = false): void {
        if (event && !this.verifyForm.get('programType').value) {
            this.showError = true;
            return;
        }
        this.showError = false;
        const id = event ? this.verifyForm.get('programType').value ?? -1 : -1;
        this._dialogRef.close(id);
    }
}
