import { Component, Input } from '@angular/core';
import { SubmittalDiversityCertification } from 'src/app/shared/models/submittals/diversity-info.model';

@Component({
    selector: 'ayac-diversity-info-tooltip',
    templateUrl: './diversity-info-tooltip.component.html',
    styleUrls: []
})
export class DiversityInfoTooltipComponent {
    @Input() certifications: SubmittalDiversityCertification[];
    constructor() {}
}
