import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { vendorNotesActions } from 'src/app/admin/store/actions/vendor-notes.actions';
import { VendorNoteService } from 'src/app/admin/vendor-details/notes/vendor-note.service';

@Injectable()
export class VendorNotesEffects {

    getVendorNoteTypes$ = createEffect(() => this._actions$.pipe(
        ofType(vendorNotesActions.loadVendorNoteTypes),
        exhaustMap(() => {
            return this._vendorNoteService.getVendorNoteTypes().pipe(
                map((result) => vendorNotesActions.loadVendorNoteTypesSuccess({ types: result })),
                catchError((error) => of(vendorNotesActions.loadVendorNoteTypesFailure({ error })))
            )
        })
    ))

    getVendorNotesByVendorId$ = createEffect(() => this._actions$.pipe(
        ofType(vendorNotesActions.loadVendorNotesByVendorId, vendorNotesActions.addNoteSuccess),
        exhaustMap((action) => {
            return this._vendorNoteService.getVendorNotesByVendorId(action.vendorId).pipe(
                map((result) => vendorNotesActions.loadVendorNotesByVendorIdSuccess({ notes: result })),
                catchError((error) => of(vendorNotesActions.loadVendorNotesByVendorIdFailure({ error })))
            )
        })
    ))

    addVendorNote$ = createEffect(() => this._actions$.pipe(
        ofType(vendorNotesActions.addNote),
        exhaustMap((action) => {
            return this._vendorNoteService.addVendorNote(action.note, action.vendorId).pipe(
                map(() => vendorNotesActions.addNoteSuccess({ vendorId: action.vendorId })),
                catchError((error) => of(vendorNotesActions.addNoteFailure({ error })))
            )
        })
    ))

    constructor(
        private readonly _actions$: Actions,
        private readonly _vendorNoteService: VendorNoteService
    ) {}
}
