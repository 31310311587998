import { createSelector } from '@ngrx/store';
import { AyaLeadsState } from '../aya-leads.state';
import { AyaLeadsLookupsState } from '../reducers/aya-leads-lookups.reducer';
import { ListItem } from 'src/app/shared/models/list-item';
import { AyaLeadsApplicationStatusDetails } from 'src/app/aya-leads/models/aya-leads-application-status-details.model';
import { selectAyaLeadsFeature } from './aya-leads-list-selectors/aya-leads-list.selectors';
import { AyaLeadsApplicationStatus } from 'src/app/aya-leads/enums/aya-leads-application-status.enum';

export const selectAyaLeadsLookupsState = createSelector(
    selectAyaLeadsFeature,
    (state: AyaLeadsState) => state.lookups
);

export const selectAyaLeadsApplicationStatuses = createSelector(
    selectAyaLeadsLookupsState,
    (state: AyaLeadsLookupsState) => state.ayaLeadsApplicationStatuses
);

export const selectAyaLeadsApplicationStatusMap = createSelector(
    selectAyaLeadsApplicationStatuses,
    (statuses: AyaLeadsApplicationStatusDetails[]) => {
        if (!statuses || statuses.length < 1) {
            return undefined;
        }

        return statuses.reduce((statusMap, curStatus) => {
            return (statusMap[curStatus.statusId] = curStatus.statusName, statusMap);
        }, {});
    }
);

export const selectAyaLeadsApplicationStatusesLoading = createSelector(
    selectAyaLeadsLookupsState,
    (state: AyaLeadsLookupsState) => state.ayaLeadsApplicationStatusesLoading
);

export const selectAyaLeadsApplicationStatusesAgeInMinutes = createSelector(
    selectAyaLeadsLookupsState,
    (state: AyaLeadsLookupsState) => {

        const diffInMs = new Date().getTime() - (state.ayaLeadsApplicationStatusesApiLoadTimeMs || 0);
        const diffInMinutes = Math.floor(Math.floor(diffInMs / 1000) / 60);

        return diffInMinutes;
    }
);

export const selectAyaLeadsApplicationStatusesFilter = createSelector(
    selectAyaLeadsLookupsState,
    (state: AyaLeadsLookupsState) =>
        state.ayaLeadsApplicationStatuses.map(
            (applicationStatus): ListItem => ({
                id: applicationStatus.statusId,
                name: applicationStatus.statusName
            })
        )
);

export const selectAyaLeadsApplicationStatusLabels = createSelector(
    selectAyaLeadsApplicationStatusMap,
    (statuses) => {
        if (!statuses) {
            return undefined;
        }
        return {
            newLabel: statuses[AyaLeadsApplicationStatus.New],
            viewedLabel: statuses[AyaLeadsApplicationStatus.Viewed],
            rejectedLabel: statuses[AyaLeadsApplicationStatus.Rejected]
        };
    }
);
