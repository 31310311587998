import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClinicalClient } from 'src/app/clinical/models/client.model';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-clinical-client-details',
    templateUrl: './clinical-client-details.component.html',
    styleUrls: ['./clinical-client-details.component.scss']
})
export class ClinicalClientDetailsComponent extends UnsubscribeOnDestroy {
    @Output() saveSuccess = new EventEmitter<boolean>();
    isLoaded = false;
    private _client: ClinicalClient;
    constructor() {
        super();
    }

    get client(): any {
        return this._client;
    }

    @Input() set client(value: any) {
        this._client = value;
        if (value && !this.isLoaded) {
            this.isLoaded = true;
        }
    }
}
