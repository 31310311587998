import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'grid-dropdown-filter',
    templateUrl: './dropdown-filter.component.html'
})
export class DropDownFilterComponent extends BaseFilterCellComponent {
    @Input() public field: string;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() public data: any[];
    @Input() public textField: string;
    @Input() public disabled: boolean;
    @Input() public valueField: string;
    @Input() public defaultText: string;
    @Input() public defaultToFirstItem: boolean;
    @Input() uniqueFilterClass: string;
    @Input() width: number;
    @Input() customDropdownListTemplate: TemplateRef<any>;
    @Input() customDropdownListItemTemplate: TemplateRef<any>;
    @Output() public filterChanging = new EventEmitter<any>();

    public get selectedValue(): any {
        const filter = this.filterByField(this.field);
        return filter ? filter.value : null;
    }

    public get defaultItem(): any {
        return {
            [this.textField]: this.defaultText || 'Select item...',
            [this.valueField]: null
        };
    }

    public settings = {
        width: 'auto'
    };

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public onChange(value: any): void {
        this.filterChanging.emit({ value: value, filter: this.filter });

        this.applyFilter(
            value === null
                ? this.removeFilter(this.field)
                : this.updateFilter({
                      field: this.field,
                      operator: 'eq',
                      value: value
                  })
        );
    }
}
