import { GridSearchQuery } from "src/app/shared/grid/models";
import { LoadingTypes, SortTypes } from "src/app/shared/models";
import { VendorCandidate } from "src/app/admin/vendor-candidates/vendor-candidate.model";
import { getEmptyPagingToken, PagingToken } from "src/app/shared/models/paging-token";
import { Expertise } from "src/app/shared/models/shifts/expertise.model";
import { State } from "src/app/shared/grid/models/state.model";
import { ListItem } from "src/app/shared/models/list-item";

export const vendorCandidatesListStoreKey = 'vendor-candidates';

export interface AdminVendorCandidatesQueryState extends GridSearchQuery {
}

const getAdminInitialVendorCandidatesGridState = (): AdminVendorCandidatesQueryState => ({
    sort: [{ field: 'fullName', dir: SortTypes.ASC }],
    take: 10,
    skip: 0
})

export interface AdminVendorCandidatesListState {
    loadingState: LoadingTypes,
    candidates: PagingToken<VendorCandidate[]>,
    gridSearchQuery: AdminVendorCandidatesQueryState,
    error: string,
    expertises: Expertise[],
    states: State[],
    vendors: ListItem[],
    professions: ListItem[],
}

export const getInitialAdminVendorCandidatesListState = (): AdminVendorCandidatesListState => ({
    loadingState: LoadingTypes.INIT,
    candidates: getEmptyPagingToken<VendorCandidate>(),
    gridSearchQuery: getAdminInitialVendorCandidatesGridState(),
    error: '',
    expertises: [],
    states: [],
    vendors: [],
    professions: []
});
