import { PowerBiReportAccessConfigState, initialPowerBiReportAccessConfigState } from './reducers/powerbi-report-access-config.reducer';
import { initialPowerBiReportExportState, PowerBiReportExportState } from './reducers/powerbi-report-export.reducer';
import { PowerBiReportSettingsState, initialPowerBiReportSettingsState } from './reducers/powerbi-report-settings.reducer';

export const powerBiStoreKey = 'powerBiReport';

export interface PowerBiReportState {
    accessConfig: PowerBiReportAccessConfigState,
    settings: PowerBiReportSettingsState,
    export: PowerBiReportExportState
}

export const getInitialPowerBiReportState = (): PowerBiReportState => ({
    accessConfig: initialPowerBiReportAccessConfigState,
    settings: initialPowerBiReportSettingsState,
    export: initialPowerBiReportExportState
});
