import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { VendorFacility } from 'src/app/shared/models/facilities';
import { ListItem } from 'src/app/shared/models/list-item';
import { getEmptyPagingToken, PagingToken } from 'src/app/shared/models/paging-token';

export interface VendorFacilitiesQueryState extends GridSearchQuery {}

const getInitialGridState = (): VendorFacilitiesQueryState => ({
    filter: {
        filters: [{ field: 'vendorId', logic: 'and', operator: 'eq', value: null }],
        logic: 'and'
    },
    sort: [{ field: 'name', dir: SortTypes.ASC }],
    take: 25,
    skip: 0
});

export interface VendorFacilitiesState {
    loadingState: LoadingTypes;
    facilities: PagingToken<VendorFacility[]>;
    gridSearchQuery: VendorFacilitiesQueryState;
    lookups: VendorFacilitiesLookupsState;
    error: unknown;
    ids: number[];
}

export const getInitialVendorFacilitiesState = (): VendorFacilitiesState => ({
    loadingState: LoadingTypes.INIT,
    facilities: getEmptyPagingToken<VendorFacility>(),
    gridSearchQuery: getInitialGridState(),
    lookups: getInitialVendorFacilitiesLookupsState(),
    error: '',
    ids: []
});

export interface VendorFacilitiesLookupsState {
    states: ListItem[];
    statuses: ListItem[];
}

const getInitialVendorFacilitiesLookupsState = () => ({
    states: [],
    statuses: []
});
