/**angular */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

/** ngrx & rxjs*/
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

/**local */
import { GroupedCertification } from 'src/app/admin/vendor-details/certifications/models/profile-metadata-response.model';
import { VendorCertification } from 'src/app/admin/vendor-details/certifications/models/vendor-certification.model';
import * as selectors from 'src/app/admin/store/selectors/vendor-profile-certifications.selectors';
import * as actions from 'src/app/admin/store/actions/vendor-profile-certifications.actions';
import { MetadataCertification } from 'src/app/admin/vendor-details/certifications/models/metadata-certification.model';
import { CertificationLevel } from 'src/app/admin/vendor-details/certifications/models/certification-level.model';
import { CertificationMultipleRequest } from 'src/app/admin/vendor-details/certifications/models/certification-multiple-request.model';
import { CertificationsResult } from 'src/app/shared/models/enums/certifications-result.enum';
import { SmallBusinessCertification } from 'src/app/admin/vendor-details/certifications/models/small-business-certification.model';
import { EthnicityType } from 'src/app/admin/vendor-details/certifications/models/ethnicity-type.model';
import { LoadingTypes } from 'src/app/shared/models';
import { VendorProfileCertificationsDetailComponent } from '../vendor-profile-certifications-detail/vendor-profile-certifications-detail.component';
import { VendorCertificationBase } from 'src/app/admin/vendor-details/certifications/models/vendor-certification-base.model';

@Component({
    selector: 'ayac-vendor-profile-certifications',
    templateUrl: './vendor-profile-certifications.component.html',
    styleUrls: ['./vendor-profile-certifications.component.scss']
})
export class VendorProfileCertificationsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @Input() set vendorId(id: number) {
        if (id && !Number.isNaN(Number(id))) {
            this._vendorId = id;
        }
    }

    get vendorId(): number {
        return this._vendorId;
    }

    @Input() vendorName = '';

    @Output() saveResult = new EventEmitter<any>();
    @Output() isLoading = new EventEmitter<boolean>();

    vendorCertifications: VendorCertification[];
    locationLevels: CertificationLevel[];
    certifications: MetadataCertification = null;
    groupedCertifications: GroupedCertification[] = [];
    certificationForm: UntypedFormGroup;
    ethnicityTypes: EthnicityType[];

    @ViewChild('standartChild', { static: false })
    childComponent!: VendorProfileCertificationsDetailComponent;

    changeTracking = {
        certifications: {
            smallBusinessSelected: 0,
            changed: false,
            data: []
        }
    };
    isFormValid = false;

    private _vendorId = 0;

    constructor(private readonly _store$: Store, private readonly fb: UntypedFormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.certificationForm = this.fb.group({});

        this._store$
            .select(selectors.selectVendorProfileMetadata)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                this.certifications = result?.certifications;
                this.locationLevels = result?.locationLevels;
                if (this.certifications) {
                    if (this.groupedCertifications.length === 0) {
                        this.groupBySmallBusinessCertifications();
                    }
                    this.countSmallBusinessCertificationsSelected();
                }
                this.ethnicityTypes = result?.ethnicityTypes;
            });

        this._store$
            .select(selectors.selectVendorProfileCertifications)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                this.vendorCertifications = result?.certifications;
                this.resetCertifications();
            });

        this._store$
            .select(selectors.selectVendorProfileCertificationsIsLoading)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                this.isLoading.emit(result);
            });

        this._store$
            .select(selectors.selectVendorProfileCertificationsLoadingSave)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                this.isLoading.emit(result === LoadingTypes.LOADING);

                if (result === LoadingTypes.LOADED) {
                    this.saveResult.emit(CertificationsResult.SUCCESS);
                }

                if (result === LoadingTypes.FAIL) {
                    this.saveResult.emit(CertificationsResult.FAIL);
                }
            });

        this._store$.dispatch(actions.loadVendorProfileCertifications({ vendorId: this.vendorId }));

        this._store$.dispatch(actions.loadVendorProfileMetadata());
    }

    resetCertifications() {
        if (this.changeTracking === undefined) {
            this.setDefaultChangeTracker();
        }

        this.changeTracking.certifications.data = [];
        this.vendorCertifications?.forEach((vc) => {
            const certificationId = vc?.certificationId;
            if (certificationId > 0) {
                if (!this.changeTracking.certifications.data[certificationId]) {
                    this.changeTracking.certifications.data[certificationId] = [];
                }

                this.changeTracking.certifications.data[certificationId].push({
                    id: vc?.id,
                    certificationId: vc?.certificationId,
                    expiration: vc?.expiration ? vc?.expiration : null,
                    level: vc?.level,
                    location: vc?.location,
                    organization: vc?.organization,
                    ethnicity: vc?.ethnicity,
                    files: vc?.files
                });
            }
        });
        this.changeTracking.certifications.changed = false;
        this.countSmallBusinessCertificationsSelected();
    }

    saveCertifications() {
        if (this.vendorId <= 0) {
            this.saveResult.emit(CertificationsResult.FAIL);
        } else {
            const selected = [];
            for (const cid in this.changeTracking.certifications.data) {
                if (this.changeTracking.certifications.data.hasOwnProperty(cid)) {
                    const vc: VendorCertificationBase[] = this.changeTracking.certifications.data[cid];

                    vc.forEach((c) => {
                        selected.push({
                            id: c.id,
                            certificationId: cid,
                            expiration: c.expiration ? new Date(c.expiration) : null,
                            level: c.level,
                            location: c.location,
                            organization: c.organization,
                            ethnicity: c.ethnicity,
                            files: c.files,
                            uploadFileIds: [],
                            uploadFiles: c.uploadFiles
                        });
                    });
                }
            }
            const request = {
                vendorId: this.vendorId,
                certifications: selected
            } as CertificationMultipleRequest;

            this._store$.dispatch(actions.updateVendorProfileCertifications({ certificationRequest: request }));
        }
    }

    certificationSelectChanged() {
        this.changeTracking.certifications.changed = true;
    }

    groupCertificationsByCategory(c) {
        return c?.category?.name;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.changeTracking?.currentValue) {
            this.countSmallBusinessCertificationsSelected();
        }
    }

    countSmallBusinessCertificationsSelected() {
        let count = 0;
        const smallBusinessIds = this.certifications?.smallBusiness?.map((c) => {
            return c.id;
        });
        for (const cid in this.changeTracking?.certifications?.data) {
            if (this.changeTracking?.certifications?.data?.hasOwnProperty(cid)) {
                const isDiversity = smallBusinessIds?.indexOf(parseInt(cid)) > -1;
                if (isDiversity && this.changeTracking?.certifications?.data[cid]?.length > 0) {
                    count++;
                }
            }
        }
        this.changeTracking.certifications.smallBusinessSelected = count;
    }

    groupBySmallBusinessCertifications() {
        let groupIndex: number = 0;
        this.groupedCertifications = this.certifications?.smallBusiness?.reduce<GroupedCertification[]>(
            (group: GroupedCertification[], product: SmallBusinessCertification, index: number) => {
                const { category } = product;
                if (index === 0) {
                    group[groupIndex] = group[groupIndex] ?? { name: category?.name, certifications: [] };
                } else if (group[groupIndex].name !== category?.name) {
                    groupIndex++;
                    group[groupIndex] = group[groupIndex] ?? { name: category?.name, certifications: [] };
                }
                group[groupIndex].certifications?.push(product);
                return group;
            },
            this.groupedCertifications
        );
    }

    updateVendorCertification(event?: FormGroup) {
        this.isFormValid = event?.valid ?? true;
        this.certificationSelectChanged();
        this.countSmallBusinessCertificationsSelected();
    }

    setDefaultChangeTracker() {
        this.changeTracking = {
            certifications: {
                smallBusinessSelected: 0,
                changed: false,
                data: []
            }
        };
    }

    downloadFile(fileId: number) {
        this._store$.dispatch(actions.downloadCertificationFile({ vendorId: this.vendorId, fileId: fileId }));
    }

    exportCertifications() {
        this._store$.dispatch(
            actions.exportVendorCertifications({ vendorId: this.vendorId, vendorName: this.vendorName })
        );
    }
}
