import { InvoicesListEffect } from './invoices-list.effect';
import { InvoicesLookupsEffect } from './invoices-lookups.effect';
import { InvoicesExportEffect } from './invoices-export.effect';
import { InvoicesDocumentEffect } from './invoices-document.effect';
import { InvoiceDetailsEffect } from './invoice-details.effect';
import { InvoiceDisputeCreateEffect } from './invoices-dispute-create.effect';
import { InvoiceLineDisputesExportEffect } from './invoice-line-disputes-export.effect';
import { ApproveDraftInvoiceEffect } from './approve-draft-invoice.effect';
import { InvoiceAttachmentsEffect } from './invoice-attachments.effect';
import { InvoiceAttachmentsEnhancedEffect } from './invoice-attachments-enhanced.effect';

export const invoicesEffects = [
    InvoicesListEffect,
    InvoicesLookupsEffect,
    InvoicesExportEffect,
    InvoicesDocumentEffect,
    InvoiceDetailsEffect,
    InvoiceAttachmentsEffect,
    InvoiceAttachmentsEnhancedEffect,
    InvoiceDisputeCreateEffect,
    InvoiceLineDisputesExportEffect,
    ApproveDraftInvoiceEffect
];
