import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import dayjs, { Dayjs } from 'dayjs';
import { takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-multi-select-calendar-header-dayjs',
    templateUrl: './multi-select-calendar-header-dayjs.component.html',
    styleUrls: ['./multi-select-calendar-header-dayjs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectCalendarHeaderDayjsComponent extends UnsubscribeOnDestroy {
    constructor(
        private readonly _calendar: MatCalendar<Dayjs>,
        private readonly _dateAdapter: DateAdapter<Dayjs>,
        @Inject(MAT_DATE_FORMATS) private readonly _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef
    ) {
        super();

        _calendar.stateChanges.pipe(takeUntil(this.d$)).subscribe(() => cdr.markForCheck());
    }

    get periodLabel() {
        return this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
            .toLocaleUpperCase();
    }

    get prevDisabled() {
        const activeDate = this._calendar.activeDate;
        const currentMonth = activeDate.month() + 1; // Day.js months are 0-indexed
        const currentYear = activeDate.year();
        const current = currentYear * 12 + currentMonth;

        if (this._calendar.minDate) {
            const minDate = this._calendar.minDate;
            const minMonth = minDate.month() + 1; // Day.js months are 0-indexed
            const minYear = minDate.year();
            const min = minYear * 12 + minMonth;

            if (current - 1 < min) {
                return true;
            }
        }
        return false;
    }

    previousClicked(mode: any) {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
    }

    nextClicked(mode: any) {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
    }
}
