import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { systemsFeatureKey } from 'src/app/systems/store';
import { systemsEffects } from 'src/app/systems/store/effects';
import { systemsReducers } from 'src/app/systems/store/reducers/system.reducers';

@NgModule({
    imports: [
        StoreModule.forFeature(systemsFeatureKey, systemsReducers),
		EffectsModule.forFeature(systemsEffects)
    ]
})
export class SystemsStoreModule {}
