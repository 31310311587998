import { IdentityService } from 'src/app/shared/services/identity.service';
import { JobsClientPermissions } from 'src/app/jobs/models/enums/jobs-client-permissions.enum';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class JobsAuthService {
    constructor(private readonly identityService: IdentityService) {}

    canManageTemplates(): boolean {
        return this.identityService.inScope('client', JobsClientPermissions.JobTemplates);
    }

    canEditTemplates(): boolean {
        return this.identityService.inScope('client', JobsClientPermissions.JobTemplateEdit);
    }

    canEditJobs(): boolean {
        return this.identityService.inScope('client', JobsClientPermissions.JobsEdit);
    }

    canRemoveJobs(): boolean {
        return this.identityService.inScope('client', JobsClientPermissions.JobsRemove);
    }

    canAddJobs(): boolean {
        return this.identityService.inScope('client', JobsClientPermissions.JobsAdd);
    }

    canViewJobDetails(): boolean {
        return this.identityService.inScope('client', JobsClientPermissions.JobsDetail);
    }

    canViewJobApprovalProcesses(): boolean {
        return this.identityService.inScope('jobapprovalprocesses', 'view');
    }

    canViewAttachments() {
        return this.identityService.inScope('client', JobsClientPermissions.AttachmentsView);
    }

    canEditAttachments() {
        return this.identityService.inScope('client', JobsClientPermissions.AttachmentsEdit);
    }

    canEditIRPSubmittals() {
        return this.identityService.inScope('client', JobsClientPermissions.IRPSubmittalsEdit);
    }

    canViewIRPSubmittalDetails() {
        return this.identityService.inScope('client', JobsClientPermissions.IRPSubmittalsDetail);
    }
}
