import { GridSearchQuery } from 'src/app/shared/grid/models/grid-search-query.model';
import { SortTypes } from 'src/app/shared/models/enums/sort-types.enum';
import { Action, createReducer, on } from '@ngrx/store';
import { AyaLeadsListActions, AyaLeadsPageActions } from '../actions';
import { TypedAction } from '@ngrx/store/src/models';
import { addFilterEqValue } from 'src/app/shared/utilities';
import { cloneDeep } from 'lodash';
import {
    applicationStatusIdsFilterName,
    getOnlyNewApplicantsFilterName
} from 'src/app/aya-leads/models/aya-leads.constants';
import { AyaLeadsApplicationStatus } from 'src/app/aya-leads/enums/aya-leads-application-status.enum';
import { AyaLeadsSortTypes } from 'src/app/aya-leads/enums/aya-leads-sort-types';

export type AyaLeadsListQueryState = GridSearchQuery;

export const ayaLeadsQueryInitialState: AyaLeadsListQueryState = {
    sort: [{ field: AyaLeadsSortTypes.APPLICATION_DATE, dir: SortTypes.DESC }],
    take: 10,
    skip: 0,
    filter: {
        logic: 'and',
        filters: [
            {
                field: applicationStatusIdsFilterName,
                operator: 'eq',
                value: [AyaLeadsApplicationStatus.New, AyaLeadsApplicationStatus.Viewed]
            }
        ]
    }
};

type SetAyaLeadsSearchQueryActionType = {
    query: GridSearchQuery;
} & TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.SetAyaLeadsSearchQuery>;

type GetAyaLeadsActionType = {
    query: GridSearchQuery;
} & TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.UpdateAyaLeadsResults>;

export const ayaLeadsQueryReducer = (
    reducerState: AyaLeadsListQueryState,
    reducerAction: Action
): AyaLeadsListQueryState => {
    return createReducer(
        ayaLeadsQueryInitialState,
        on(
            AyaLeadsListActions.setAyaLeadsSearchQuery,
            AyaLeadsListActions.updateAyaLeadsResults,
            AyaLeadsListActions.setAyaLeadsGridFilterStateAndUpdateResults,
            (
                state: AyaLeadsListQueryState,
                action: SetAyaLeadsSearchQueryActionType | GetAyaLeadsActionType
            ): AyaLeadsListQueryState => ({
                ...state,
                ...action.query
            })
        ),
        on(AyaLeadsPageActions.dashboardShowAyaLeadsFilteredByStatus, (state, action): AyaLeadsListQueryState => {
            let updatedFilter = cloneDeep(state.filter);

            updatedFilter = addFilterEqValue(applicationStatusIdsFilterName, action.applicationStatuses, updatedFilter);

            const newOnlyEnabled =
                action.applicationStatuses.length === 1 &&
                action.applicationStatuses.includes(AyaLeadsApplicationStatus.New);
            updatedFilter = addFilterEqValue(getOnlyNewApplicantsFilterName, newOnlyEnabled, updatedFilter);

            return {
                ...state,
                filter: updatedFilter
            };
        })
    )(reducerState, reducerAction);
};

export const reducer = (state: AyaLeadsListQueryState | undefined, action: Action) => {
    return ayaLeadsQueryReducer(state, action);
};
