import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import {
    ConnectFacilityFeature,
    FacilityFeaturePermissionCategory,
    FacilityFeatureNotifications
} from '../../models/facility-feature.model';

export interface ContractGroupFeaturesState {
    permissionCategories: FacilityFeaturePermissionCategory[];
    notifications: FacilityFeatureNotifications[];
    totalFacilities: number;
    groupFeatures: ConnectFacilityFeature;
    loadingState: LoadingTypes;
    error: string;
}

export const getContractGroupFeaturesState = () => ({
    permissionCategories: [],
    notifications: [],
    totalFacilities: null,
    groupFeatures: null,
    loadingState: LoadingTypes.INIT,
    error: ''
});
