import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { PerdiemCandidate } from '../../models/perdiem-candidate.model';
import { loadCandidates, loadCandidatesFail, loadCandidatesSuccess } from '../perdiemscheduler.actions';

export interface PerdiemSchedulerCandidatesState {
    list: PerdiemCandidate[];
    filteredList: PerdiemCandidate[];
    total: number;
    loading: LoadingTypes;
}

export const getInitialPerdiemSchedulerCandidatesState = (): PerdiemSchedulerCandidatesState => ({
    list: [],
    filteredList: [],
    total: 0,
    loading: LoadingTypes.INIT
});

export function perdiemSchedulerCandidatesReducer(state: PerdiemSchedulerCandidatesState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerCandidatesState(),
        on(loadCandidates, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadCandidatesSuccess, (state, action) => ({
            ...state,
            list: action.candidates,
            filteredList: action.filteredCandidates,
            total: action.total,
            loading: LoadingTypes.LOADED
        })),
        on(loadCandidatesFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: PerdiemSchedulerCandidatesState | undefined, action: Action) {
    return perdiemSchedulerCandidatesReducer(state, action);
}
