import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomModule } from './custom/custom.module';

// Components
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { DialogLayoutComponent } from 'src/app/shared/components/dialog/dialog-layout.component';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationReasonDialogComponent } from 'src/app/shared/components/dialog/confirmation-reason-dialog/confirmation-reason-dialog.component';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { SpinnerDirective } from 'src/app/shared/components/spinner/spinner.directive';
import { FlatGridComponent } from 'src/app/shared/flat-grid/flat-grid.component';

// Services
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonService } from './services/common.service';
import { DomainService } from './services/domain.service';
import { KendoModule } from './kendo/kendo.model';
import { AppGridModule } from './grid/grid.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { TimepickerFreeTextComponent } from './components/timepicker-free-text/timepicker-free-text.component';
import { TimepickerInlineComponent } from './components/timepicker-inline/timepicker-inline.component';
import { TimepickerPopupComponent } from './components/timepicker-popup/timepicker-popup.component';
import { PromptDialogComponent } from './components/prompt-dialog/prompt-dialog.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import { DocumentsGridModule } from './documents-grid/documents-grid.module';
import { PipesModule } from './pipes/pipes.module';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { InformationDialogComponent } from './components/dialog/information-dialog/information-dialog.component';
import { ClientService } from './services/client.service';
import { PortalPageHeaderComponent } from './components/portal-page-header/portal-page-header.component';
import { SkeletonOverlayDirective } from './directives/skeleton-overlay.directive';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { WelcomeDialogComponent } from './components/welcome-dialog/welcome-dialog.component';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { HoverClassDirective } from './directives/hover-class.directive';
import { TimepickerDropdownComponent } from './components/timepicker-dropdown/timepicker-dropdown.component';
import { KendoAutocompleteFixDirective } from './directives/kendo-autocomplete-fix.directive';
import { HasSystemFieldsDirective } from './directives/has-system-fields.directive';
import { FileAttachmentsComponent } from './components/file-attachments/file-attachments.component';
import { MultiSelectCalendarHeaderComponent } from './components/datepicker-multiple/multi-select-calendar-header.component';
import { MultiSelectCalendarComponent } from './components/datepicker-multiple/multi-select-calendar.component';
import { DatepickerMultipleComponent } from './components/datepicker-multiple/datepicker-multiple.component';
import { IsSystemFieldsRequiredDirective } from './directives/if-system-field-required.directive';
import { FileAttachmentsPreviewComponent } from './components/file-attachments-preview/file-attachments-preview.component';
import { FileAttachmentsViewerComponent } from './components/file-attachments-viewer/file-attachments-viewer.component';
import { MatTableComponent } from './components/mat-table/mat-table.component';
import { FileAttachmentsListComponent } from './components/file-attachments-list/file-attachments-list.component';
import { CalendarScheduleComponent } from './components/calendar-schedule/calendar-schedule.component';
import { PendingChangesGuard } from 'src/app/shared/guards/pending-changes.guard';
import { VersionCheckerService } from './services/version-checker.service';
import { LoggerService } from './services/logger.service';
import { VersionCheckerDirective } from './directives/version-checker.directive';
import { RejectConfirmModalComponent } from './components/reject-confirm-modal/reject-confirm-modal.component';
import { FollowBackUrlDirective } from './directives/follow-back-url.directive';
import { BrandingService } from './services/branding.service';
import { WorkdaysSelectComponent } from './components/workdays-select/workdays-select.component';
import { CardAlertComponent } from './components/card-alert/card-alert.component';
import { SharedSubmittalDetailsComponent } from './components/submittal-details/submittal-details.component';
import { AnyCannotBeRejectedPipe } from './pipes/any-cannot-be-rejected.pipe';
import { AnyCannotBeApprovedPipe } from './pipes/any-cannot-be-approved.pipe';
import { LongDescriptionComponent } from './components/long-description/long-description.component';
import { ExpandButtonComponent } from './components/expand-button/expand-button.component';
import { CustomFieldService } from './services/custom-field.service';
import { HasCustomSystemFieldDirective } from './directives/has-custom-system-field.directive';
import { PendoTrackClickDirective } from './directives/pendo-track.directive';
import { DocumentsViewerComponent } from './components/documents-viewer/documents-viewer.component';
import { DragAndDropUploaderComponent } from './components/drag-and-drop-uploader/drag-and-drop-uploader.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { OptionsInputComponent } from './formly/templates/options-input/options-input.component';
import { KendoWrapperComponent } from './formly/wrappers/kendo-wrapper.component';
import { KendoInputComponent } from './formly/templates/kendo-input/kendo-input.component';
import { KendoFieldComponent } from './formly/templates/kendo-field/kendo-field.component';
import { PopoverDirective } from './components/popover/popover.directive';
import { PopoverContentComponent } from './components/popover/popover-content.component';
import { CustomFormComponent } from './components/custom-form/custom-form.component';
import { CustomFormGridCellComponent } from './components/custom-form-grid-cell/custom-form-grid-cell.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyKendoModule } from '@ngx-formly/kendo';
import { formlyConfig } from 'src/app/shared/formly/formly.config';
import { KendoWorkerDetailsComponent } from './formly/templates/kendo-worker-details-label/kendo-worker-details-label.component';
import { KendoWorkerDetailsInputComponent } from './formly/templates/kendo-worker-details-input/kendo-worker-details-input.component';
import { KendoDropdownComponent } from './formly/templates/kendo-dropdown/kendo-dropdown.component';
import { TextHighlightDirective } from './components/text-highlight/text-highlight.directive';
import { SharedFilterSearchPopOpenComponent } from './components/filter-search-pop-open/filter-search-pop-open.component';
import { IfLDFeatureDirective } from 'src/app/shared/directives/if-ld-feature.directive';
import { ExcludeActiveDialogComponent } from './components/exclude-active-dialog/exclude-active-dialog.component';
import { InputDropdownComponent } from './components/input-dropdown-combo/input-dropdown-combo.component';
import { PluralizeDialogTitlePipe } from 'src/app/shared/pipes/pluralize-dialog-title.pipe';
import { TotalHeaderTogglerComponent } from './components/total-header-toggler/total-header-toggler.component';
import { CertificationsFormComponent } from './forms/certifications-form/certifications-form.component';
import { FormControlWrapperComponent } from './forms/form-control-wrapper/form-control-wrapper.component';
import { ExpertiseFormComponent } from './forms/expertise-form/expertise-form.component';
import { CandidateProfileFormComponent } from './forms/candidate-profile-form/candidate-profile-form.component';
import { BackgroundHistoryFormComponent } from './forms/background-history-form/background-history-form.component';
import { LicensesFormComponent } from './forms/licenses-form/licenses-form.component';
import { PhoneFormComponent } from './forms/phone-form/phone-form.component';
import { ContactToolbarComponent } from './components/contact-toolbar/contact-toolbar.component';
import { ContactProfileComponent } from './components/contact-profile/contact-profile.component';
import { FileAttachmentsGridComponent } from './components/file-attachments-grid/file-attachments-grid.component';
import { ChipsAutocompleteComponent } from './components/chips-autocomplete/chips-autocomplete.component';
import { ActiveTabButtonBarComponent } from 'src/app/facilities/facilities-shared/active-tab-button-bar/active-tab-button-bar.component';
import { CustomFieldIncludesPipe } from 'src/app/shared/pipes/custom-field-includes.pipe';
import { CustomFieldFindPipe } from 'src/app/shared/pipes/custom-field-find.pipe';
import { FileAttachmentsFormsComponent } from './components/file-attachments-form/file-attachments-form.component';
import { TermsAndConditionsComponent } from 'src/app/portal/terms-and-conditions/terms-and-conditions.component';
import { VersionNumberComponent } from 'src/app/core/app-version-number/app-version-number.component';
import { ShiftScheduleCalendarComponent } from 'src/app/shared/components/shift-schedule-calendar/shift-schedule-calendar.component';
import { ShiftScheduleModalComponent } from 'src/app/shared/components/shift-schedule-modal/shift-schedule-modal.component';
import { RejectOfferDialogComponent } from './components/reject-offer-dialog/reject-offer-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { IframePageComponent } from './components/iframe-page/iframe-page.component';
import { RichOptionsInputComponent } from './formly/templates/rich-options-input/rich-options-input.component';
import { ChildDropdownInputComponent } from './formly/templates/child-dropdown/child-dropdown-input.component';
import { RichToolTipComponent } from './components/rich-tooltip/rich-tooltip.component';
import { RadioButtonToggleDirective } from './directives/radio-button-toggle.directive';
import { AutocompletePillInputComponent } from './components/autocomplete-pill-input/autocomplete-pill-input.component';
import { JobReleaseHistoryDialogComponent } from './release-history/release-history-dialog.component';
import { KendoSearchableJobDropdownComponent } from './formly/templates/kendo-searchable-job-dropdown/kendo-searchable-job-dropdown.component';
import { KendoSearchableWorkerDropdownComponent } from './formly/templates/kendo-searchable-worker-dropdown/kendo-searchable-worker-dropdown.component';
import { NestedCheckboxTreeComponent } from './components/nested-checkbox-tree/nested-checkbox-tree.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { KendoJobDropdownComponent } from './formly/templates/kendo-job-dropdown/kendo-job-dropdown.component';
import { HelpTextComponent } from './formly/components/help-text/help-text.component';
import { DropdownMultiselectComponent } from './components/dropdown-multiselect/dropdown-multiselect.component';
import { MatInputCommifiedDirective } from './directives/mat-input-commified.directive';
import { TerminologyModule } from './terminology/terminology.module';
import { GetAllFacilitiesCountPipe } from './pipes/get-all-facilities-count.pipe';
import { AyaIconsModule } from '@aya/controls/aya-icons';
import { LimitLinesTextComponent } from './components/limit-lines-text/limit-lines-text.component';
import { ColumnsLayoutComponent } from './components/columns-layout/columns-layout.component';
import { ExpenseAttachmentsComponent } from './components/expense-attachments/expense-attachments.component';
import { BannerComponent } from './components/banner/banner.component';
import { DatepickerMultipleDayjsComponent } from './components/datepicker-multiple-dayjs/datepicker-multiple-dayjs.component';
import { MultiSelectCalendarHeaderDayjsComponent } from './components/datepicker-multiple-dayjs/multi-select-calendar-header-dayjs/multi-select-calendar-header-dayjs.component';
import { MultiSelectCalendarDayjsComponent } from './components/datepicker-multiple-dayjs/multi-select-calendar-dayjs/multi-select-calendar-dayjs.component';
import { ViewRequirementDetailsDialogComponent } from './components/view-requirement-details-dialog/view-requirement-details-dialog.component';
import { SubmittalAddNoteDialogComponent } from 'src/app/shared/components/submittal-add-note-dialog/submittal-add-note-dialog.component';
import { NgxEditorModule } from 'ngx-editor';
import { TextAreaWithCounterComponent } from '../submittals/components/text-area-with-counter/text-area-with-counter.component';
import { ActivityAndCommentsSectionComponent } from '../submittals/components/submittal-details/submittal-activity-and-comments-section/activity-and-comments-section.component';

export const LAZY_MODULES = new InjectionToken<{ [key: string]: string }>('LAZY_MODULES');

@NgModule({
    imports: [
        // angular deps
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        // angular material
        MaterialModule,
        ScrollingModule,
        KendoModule,
        AppGridModule,
        DocumentsGridModule,
        RouterModule,
        PipesModule,
        NgxPowerBiModule,
        ClipboardModule,
        FormlyModule.forRoot(formlyConfig),
        FormlyKendoModule,
        NgxMaskModule.forRoot(),
        TerminologyModule,
        AyaIconsModule,
        NgxEditorModule
    ],
    declarations: [
        AutocompleteComponent,
        DialogLayoutComponent,
        ConfirmationDialogComponent,
        ConfirmationReasonDialogComponent,
        InformationDialogComponent,
        SnackBarComponent,
        SpinnerDirective,
        BreadcrumbComponent,
        TimepickerFreeTextComponent,
        TimepickerInlineComponent,
        TimepickerPopupComponent,
        TimepickerDropdownComponent,
        PromptDialogComponent,
        WelcomeDialogComponent,
        ValidationMessageComponent,
        WarningMessageComponent,
        PortalPageHeaderComponent,
        SkeletonOverlayDirective,
        LoadingIndicatorComponent,
        KendoAutocompleteFixDirective,
        HasSystemFieldsDirective,
        IsSystemFieldsRequiredDirective,
        ClickStopPropagationDirective,
        HoverClassDirective,
        FileAttachmentsComponent,
        DatepickerMultipleComponent,
        MultiSelectCalendarComponent,
        MultiSelectCalendarHeaderComponent,
        FileAttachmentsComponent,
        DragAndDropUploaderComponent,
        FileAttachmentsViewerComponent,
        FileAttachmentsPreviewComponent,
        MatTableComponent,
        FileAttachmentsListComponent,
        CalendarScheduleComponent,
        VersionCheckerDirective,
        RejectConfirmModalComponent,
        FollowBackUrlDirective,
        WorkdaysSelectComponent,
        CardAlertComponent,
        SharedSubmittalDetailsComponent,
        ActivityAndCommentsSectionComponent,
        AnyCannotBeRejectedPipe,
        AnyCannotBeApprovedPipe,
        LongDescriptionComponent,
        ExpandButtonComponent,
        HasCustomSystemFieldDirective,
        IfLDFeatureDirective,
        PendoTrackClickDirective,
        DocumentsViewerComponent,
        DragAndDropDirective,
        OptionsInputComponent,
        RichOptionsInputComponent,
        ChildDropdownInputComponent,
        KendoWrapperComponent,
        KendoInputComponent,
        KendoFieldComponent,
        PopoverDirective,
        PopoverContentComponent,
        CustomFormComponent,
        CustomFormGridCellComponent,
        KendoWorkerDetailsComponent,
        KendoWorkerDetailsInputComponent,
        KendoDropdownComponent,
        KendoSearchableJobDropdownComponent,
        KendoSearchableWorkerDropdownComponent,
        TextHighlightDirective,
        SharedFilterSearchPopOpenComponent,
        ExcludeActiveDialogComponent,
        InputDropdownComponent,
        PluralizeDialogTitlePipe,
        TotalHeaderTogglerComponent,
        CertificationsFormComponent,
        FormControlWrapperComponent,
        ExpertiseFormComponent,
        CandidateProfileFormComponent,
        BackgroundHistoryFormComponent,
        LicensesFormComponent,
        PhoneFormComponent,
        ContactToolbarComponent,
        ContactProfileComponent,
        FileAttachmentsGridComponent,
        ChipsAutocompleteComponent,
        ActiveTabButtonBarComponent,
        CustomFieldIncludesPipe,
        CustomFieldFindPipe,
        FileAttachmentsFormsComponent,
        TermsAndConditionsComponent,
        VersionNumberComponent,
        ShiftScheduleCalendarComponent,
        ShiftScheduleModalComponent,
        RejectOfferDialogComponent,
        IframePageComponent,
        RichToolTipComponent,
        RadioButtonToggleDirective,
        AutocompletePillInputComponent,
        JobReleaseHistoryDialogComponent,
        NestedCheckboxTreeComponent,
        NotFoundComponent,
        FlatGridComponent,
        KendoJobDropdownComponent,
        HelpTextComponent,
        DropdownMultiselectComponent,
        MatInputCommifiedDirective,
        GetAllFacilitiesCountPipe,
        LimitLinesTextComponent,
        ColumnsLayoutComponent,
        ExpenseAttachmentsComponent,
        BannerComponent,
        DatepickerMultipleDayjsComponent,
        MultiSelectCalendarHeaderDayjsComponent,
        MultiSelectCalendarDayjsComponent,
        ViewRequirementDetailsDialogComponent,
        SubmittalAddNoteDialogComponent,
        TextAreaWithCounterComponent
    ],
    exports: [
        CommonModule,
        MaterialModule,
        ScrollingModule,
        KendoModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        CustomModule,
        AppGridModule,
        DocumentsGridModule,
        NgxMaskModule,
        // pipes
        PipesModule,
        NgxPowerBiModule,
        TerminologyModule,
        AyaIconsModule,
        // components
        AutocompleteComponent,
        DialogLayoutComponent,
        ConfirmationDialogComponent,
        ConfirmationReasonDialogComponent,
        InformationDialogComponent,
        SnackBarComponent,
        SpinnerDirective,
        BreadcrumbComponent,
        TimepickerFreeTextComponent,
        TimepickerInlineComponent,
        TimepickerPopupComponent,
        TimepickerDropdownComponent,
        PromptDialogComponent,
        ValidationMessageComponent,
        WarningMessageComponent,
        PortalPageHeaderComponent,
        SkeletonOverlayDirective,
        LoadingIndicatorComponent,
        KendoAutocompleteFixDirective,
        HasSystemFieldsDirective,
        IsSystemFieldsRequiredDirective,
        ClickStopPropagationDirective,
        HoverClassDirective,
        FileAttachmentsComponent,
        DatepickerMultipleComponent,
        MultiSelectCalendarComponent,
        MultiSelectCalendarHeaderComponent,
        FileAttachmentsPreviewComponent,
        FileAttachmentsComponent,
        DragAndDropUploaderComponent,
        FileAttachmentsListComponent,
        MatTableComponent,
        CalendarScheduleComponent,
        VersionCheckerDirective,
        FollowBackUrlDirective,
        WorkdaysSelectComponent,
        CardAlertComponent,
        SharedSubmittalDetailsComponent,
        ActivityAndCommentsSectionComponent,
        AnyCannotBeApprovedPipe,
        AnyCannotBeRejectedPipe,
        LongDescriptionComponent,
        ExpandButtonComponent,
        HasCustomSystemFieldDirective,
        IfLDFeatureDirective,
        PendoTrackClickDirective,
        DocumentsViewerComponent,
        DragAndDropDirective,
        OptionsInputComponent,
        RichOptionsInputComponent,
        ChildDropdownInputComponent,
        KendoWrapperComponent,
        KendoInputComponent,
        KendoFieldComponent,
        PopoverDirective,
        PopoverContentComponent,
        CustomFormComponent,
        CustomFormGridCellComponent,
        KendoWorkerDetailsComponent,
        KendoWorkerDetailsInputComponent,
        KendoDropdownComponent,
        KendoSearchableJobDropdownComponent,
        KendoSearchableWorkerDropdownComponent,
        TextHighlightDirective,
        SharedFilterSearchPopOpenComponent,
        ExcludeActiveDialogComponent,
        InputDropdownComponent,
        PluralizeDialogTitlePipe,
        TotalHeaderTogglerComponent,
        CertificationsFormComponent,
        FormControlWrapperComponent,
        ExpertiseFormComponent,
        CandidateProfileFormComponent,
        BackgroundHistoryFormComponent,
        LicensesFormComponent,
        PhoneFormComponent,
        ContactToolbarComponent,
        FileAttachmentsGridComponent,
        ContactProfileComponent,
        ActiveTabButtonBarComponent,
        CustomFieldIncludesPipe,
        CustomFieldFindPipe,
        FileAttachmentsFormsComponent,
        TermsAndConditionsComponent,
        VersionNumberComponent,
        ShiftScheduleCalendarComponent,
        ShiftScheduleModalComponent,
        RejectOfferDialogComponent,
        RichToolTipComponent,
        RadioButtonToggleDirective,
        AutocompletePillInputComponent,
        NestedCheckboxTreeComponent,
        NotFoundComponent,
        FlatGridComponent,
        DropdownMultiselectComponent,
        MatInputCommifiedDirective,
        GetAllFacilitiesCountPipe,
        LimitLinesTextComponent,
        ColumnsLayoutComponent,
        ExpenseAttachmentsComponent,
        BannerComponent,
        DatepickerMultipleDayjsComponent,
        MultiSelectCalendarHeaderDayjsComponent,
        MultiSelectCalendarDayjsComponent,
        ViewRequirementDetailsDialogComponent,
        SubmittalAddNoteDialogComponent,
        TextAreaWithCounterComponent
    ],
    providers: [
        DialogService,
        IdentityService,
        DomainService,
        LoaderService,
        CommonService,
        ClientService,
        PendingChangesGuard,
        VersionCheckerService,
        LoggerService,
        BrandingService,
        CustomFieldService
    ]
})
export class SharedModule {}
