import { Component, Input, OnInit } from '@angular/core';
import { MenuItemDirective } from '../app-side-nav/menu-item.directive';
import { HelpService } from 'src/app/help/help.service';
import { DomainService } from 'src/app/shared/services/domain.service';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from '../utils';

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['../app-side-nav/nav-menu-link.component.scss', './app-footer.component.scss']
})
export class AppFooterComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() isExpanded = false;
    linkData: MenuItemDirective;
    currentYear: number;
    privacyUrl: string;
    termsOfServiceUrl: string;
    electronicConsentUrl: string;
    copyright: string;
    featureFlag = FeatureFlag;

    constructor(
        private readonly helpService: HelpService,
        private readonly domainService: DomainService,
        private readonly featureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();

        this.linkData = {
            title: 'Help',
            icon: 'help',
            subItems: this.helpService
                .helpItems()
                .reduce((arr, item) => arr.concat(item.data), [])
                .map((item) => ({
                    path: item.link,
                    title: item.title,
                    icon: item.icon
                }))
        } as MenuItemDirective;

        // Can remove this once Feature Flag with key REPLACE_CURRENT_HELP_SECTION_WITH_PENDO_RESOURCE_CENTER is removed.
        this.featureManager
            .isEnabled(FeatureFlag.ReplaceCurrentHelpSectionWithPendoResourceCenter)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                if (flagIsEnabled) {
                    this.privacyUrl = this.domainService.getValue('PRIVACY_URL');
                    this.termsOfServiceUrl = this.domainService.getValue('TERMS_OF_USE_URL');
                    this.electronicConsentUrl = this.domainService.getValue('ELECTRONIC_CONSENT');
                    this.copyright = this.domainService.getValue('FOOTER_COPY');
                }
            });
    }
}
