import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { NavigationEnd, Router } from '@angular/router';
import { upgradedUrlSegments } from 'js/ng-upgrade';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { CoreStoreFacade } from 'src/app/core/state';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';

@Component({
    selector: 'app-shell',
    templateUrl: './app-shell.component.html',
    styleUrls: ['./app-shell.component.scss']
})
export class AppShellComponent extends UnsubscribeOnDestroy implements OnInit {
    upgradeStyle = false;

    menuExpanded = false;
    fullScreen = false;
    userType = '';
    isShiftResetPassword = false;
    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map((result) => result.matches),
        shareReplay()
    );

    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        private readonly _identityService: IdentityService,
        private readonly router: Router,
        private readonly _coreStoreFacade: CoreStoreFacade,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit() {
        if (this._identityService.isSignedIn()) {
            this._ldFeatureManager.changeUser(this._identityService.userIdentity);
            this._coreStoreFacade.tryLoadMenu();
            this._coreStoreFacade.loadClientSharedData();
            this._coreStoreFacade.loadSystemFields();
        }

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this.d$)
            )
            .subscribe((value: NavigationEnd) => {
                const segment = upgradedUrlSegments.find((obj) => {
                    return obj.path instanceof RegExp
                        ? new RegExp(value.urlAfterRedirects.replace('/', '')).test(obj.path)
                        : value.urlAfterRedirects.startsWith(`/${obj.path}`);
                });

                this.upgradeStyle = segment && segment.upgradeStyle;
                this.fullScreen = value.urlAfterRedirects.startsWith("/login");
            });

        this.userType = this._identityService.type;

        this.isShiftResetPassword = (window.location.href.indexOf('definepassword') !== -1)


    }

}
