import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { IConfirmationDialogOptions } from '../models/dialog.models';

export interface PendingChangesComponent {
    canDeactivate: () => boolean | Promise<boolean> | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<PendingChangesComponent> {
    constructor(private readonly _dialogService: DialogService) {
    }
    canDeactivate(component: PendingChangesComponent): boolean | Promise<boolean> | Observable<boolean> {
        if (component == null) {
            return this.confirmNavigationOff()
        }
        return component.canDeactivate();
    }

    confirmNavigationOff(): Promise<boolean> {
        return this._dialogService.openConfirmationDialog({
            data: {
                title: "Are you sure you want to go back",
                text: "Any unsaved changes will be lost. This cannot be undone."
            }
        } as IConfirmationDialogOptions)
    }
}
