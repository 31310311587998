import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/admin/store/actions/submittals.actions';
import { getInitialSubmittalsState, SubmittalsState } from 'src/app/admin/store/state/submittals.state';
import { LoadingTypes } from 'src/app/shared/models';
import { getEmptyPagingToken } from 'src/app/shared/models/paging-token';

export function adminSubmittalsReducer(state: SubmittalsState, action: Action) {
    return createReducer(
        getInitialSubmittalsState(),
        /** @deprecated use loadSubmittals_V2 */
        on(actions.loadSubmittals, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        /** @deprecated use loadSubmittalsSuccess_V2 */
        on(actions.loadSubmittalsSuccess, (state, action) => ({
            ...state,
            submittals: action.submittals,
            loading: LoadingTypes.LOADED
        })),
        /** @deprecated use loadSubmittalsFailure_V2 */
        on(actions.loadSubmittalsFailure, (state, action) => ({
            ...state,
            submittals: null,
            loading: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })),

        on(actions.exportSubmittals, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(actions.exportSubmittalsSuccess, (state) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(actions.exportSubmittalsFailure, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })),
        /** @deprecated use setSubmittalsSearchQuery_V2 */
        on(actions.setSubmittalsSearchQuery, (state, action) => ({
            ...state,
            gridSearchQuery: action.searchQuery,
            loading: LoadingTypes.LOADING
        })),
        on(
            actions.loadSubmittals_V2,
            (state, action): SubmittalsState => ({
                ...state,
                loading: LoadingTypes.LOADING,
                submittals: getEmptyPagingToken()
            })
        ),
        on(
            actions.loadSubmittalsSuccess_V2,
            (state, action): SubmittalsState => ({
                ...state,
                loading: LoadingTypes.LOADED,
                submittals: action.submittals
            })
        ),
        on(
            actions.loadSubmittalsFailure_V2,
            (state, action): SubmittalsState => ({
                ...state,
                loading: LoadingTypes.FAIL,
                submittals: getEmptyPagingToken(),
                error: action.error
            })
        ),

        on(actions.setSubmittalsSearchQuery_V2, (state, action) => ({
            ...state,
            searchQuery: action.searchQuery,
            loading: LoadingTypes.LOADING
        })),

        on(
            actions.loadSubmittalsLookupStatuses,
            (state, action): SubmittalsState => ({
                ...state,
                loading: LoadingTypes.LOADING
            })
        ),
        on(actions.loadSubmittalsLookupStatusesSuccess, (state, action): SubmittalsState => {
            const lookups = state.lookups;
            lookups.statuses = action.statuses;
            return { ...state, lookups };
        }),
        on(
            actions.loadSubmittalsLookupStatusesFail,
            (state, action): SubmittalsState => ({
                ...state,
                loading: LoadingTypes.FAIL,
                error: action.error
            })
        ),

        on(
            actions.loadSubmittalsLookupSubStatuses,
            (state, action): SubmittalsState => ({
                ...state,
                loading: LoadingTypes.LOADING
            })
        ),
        on(
            actions.loadSubmittalsLookupSubStatusesSuccess,
            (state, action): SubmittalsState => ({
                ...state,
                lookups: {
                    statuses: state.lookups.statuses,
                    subStatuses: action.subStatuses
                },
                loading: LoadingTypes.LOADED
            })
        ),
        on(
            actions.loadSubmittalsLookupSubStatusesFail,
            (state, action): SubmittalsState => ({
                ...state,
                loading: LoadingTypes.FAIL,
                error: action.error
            })
        )
    )(state, action);
}

export function reducer(state: SubmittalsState | undefined, action: Action) {
    return adminSubmittalsReducer(state, action);
}
