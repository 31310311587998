import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { ToasterService } from '../services/toaster.service';
import { Router } from '@angular/router';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { CoreService } from '../services/core.service';
import { AuthService } from '../auth/services/auth.service';
import { ShiftsAuthService } from 'src/app/shifts/services/shifts-auth.service';
import { JobsAuthService } from 'src/app/jobs/services/jobs-auth.service';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import * as fromCore from 'src/app/core/state';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { Observable, combineLatest, of } from 'rxjs';
import { UserProfile } from 'src/app/shared/models/account/user-profile.model';
import * as coreActions from 'src/app/core/state/actions';
import { loadBranding } from 'src/app/core/state/actions';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { getUnreadThreadsCount } from 'src/app/client/store/actions/message-center.actions';
import { selectUnreadThreadsCount } from 'src/app/client/store/selectors/message-center.selectors';
import { MessageCenterService } from 'src/app/client/message-center/message-center.service';

@Component({
    selector: 'app-top-nav',
    templateUrl: './app-top-nav.component.html',
    styleUrls: ['./app-top-nav.component.scss']
})
export class TopNavComponent extends UnsubscribeOnDestroy implements OnInit {
    canAddJob: boolean;
    canEditShift: boolean;
    canAddShift: boolean;

    featuredLogo: string | ArrayBuffer;

    featureFlag = FeatureFlag;
    profiles$: Observable<UserProfile[]> = of([]);
    profilesExist$: Observable<boolean>;

    messageCenterEnabled$!: Observable<boolean>;
    unreadMessages$!: Observable<number>;

    constructor(
        private readonly toaster: ToasterService,
        private readonly router: Router,
        private readonly identity: IdentityService,
        private readonly authService: AuthService,
        private readonly coreService: CoreService,
        private readonly shiftsAuthService: ShiftsAuthService,
        private readonly jobsAuthService: JobsAuthService,
        private readonly store: Store<{}>,
        private readonly _ldFeatureManager: LDFeatureManager,
        private readonly _messageCenterService: MessageCenterService
    ) {
        super();
    }

    @HostBinding('class.sticky')
    @Input()
    sticky = true;

    ngOnInit() {
        this.loadFeaturedLogoAndBrand();
        this.canAddJob = this.jobsAuthService.canAddJobs() || this.jobsAuthService.canEditJobs();
        this.canEditShift = this.shiftsAuthService.canEditShift();
        this.canAddShift = this.shiftsAuthService.canAddShift();

        this.store.dispatch(new coreActions.LoadUserProfiles());
        this.profiles$ = this.store.select(fromCore.selectUserProfiles);
        this.profilesExist$ = this.store.select(fromCore.selectProfilesExist);
        this.store
            .select(fromCore.getFeaturedLogo)
            .pipe(takeUntil(this.d$))
            .subscribe((featuredLogo) => this.createImageFromBlob(featuredLogo));

        this.messageCenterEnabled$ = combineLatest([
            this._ldFeatureManager.isEnabled(FeatureFlag.ConnectClientMessageCenter),
            this._messageCenterService.isMessageCenterEnabledForUser()
        ]).pipe(
            map(([flagEnabled, userHasAccess]) => {
                if (flagEnabled && userHasAccess) {
                    this.store.dispatch(getUnreadThreadsCount());
                    this.unreadMessages$ = this.store.select(selectUnreadThreadsCount);
                }
                return flagEnabled && userHasAccess;
            }),
            takeUntil(this.d$)
        );
    }

    loadFeaturedLogoAndBrand() {
        this.store.dispatch(new coreActions.LoadFeaturedLogoWithSystemId());
        this.store.dispatch(loadBranding());
    }

    addNewJob() {
        this.router.navigate(['client', 'jobs', 'edit']);
    }

    addNewShift() {
        this.router.navigate(['client', 'perdiemscheduler', 'edit', '0']);
    }

    onNotifications() {
        this.toaster.fail('Shift could not be saved', 'View Shift').subscribe((result) => {
            if (result) {
                this.toaster.info('Action clicked');
            }
        });
    }

    onSettings() {}

    onAdminSettings() {
        this.router.navigate(['client', 'admin-settings']);
    }

    onChangePassword() {
        this.coreService.openChangePasswordDialog();
    }
    onNotificationSettings() {
        this.router.navigate(['client', 'notification-settings']);
    }
    onSignout() {
        this.store.dispatch(new coreActions.Logout());
    }

    onCorpDirectory() {
        this.authService.openCorpDirectory();
    }

    get loggedName() {
        const user = this.identity.userIdentity;
        return user ? user.firstName : '';
    }

    get showAddMenu() {
        return this.canAddJob || this.canEditShift || this.canAddShift;
    }

    createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.featuredLogo = reader.result;
            },
            false
        );
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    changeProfile(userId: string) {
        this.identity.changeProfile(userId);
    }
}
