import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/admin-clients/store/actions/admin-client-details.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { getEmptyPagingToken } from 'src/app/shared/models/paging-token';
import { AdminClientListItem } from 'src/app/admin-clients/models/admin-clients.model';
import {
    AdminClientDetailState,
    getInitialAdminClientDetailState
} from 'src/app/admin-clients/store/state/admin-client-detail.state';

const initialState: AdminClientDetailState = {
    loadingState: LoadingTypes.INIT,
    adminClient: getEmptyPagingToken<AdminClientListItem>(),
    error: '',
    facilityLookup: [],
    facilityProfileGroup: [],
    legacyId: null
};

export function AdminClientDetialsReducer(state: AdminClientDetailState | undefined, action: Action) {
    return createReducer(
        getInitialAdminClientDetailState(),

        on(
            actions.loadAdminClientDetails,
            (state): AdminClientDetailState => ({
                ...state,
                adminClient: getEmptyPagingToken<AdminClientListItem>(),
                loadingState: LoadingTypes.LOADING
            })
        ),

        on(
            actions.adminClientDetailsSuccess,
            (state, action): AdminClientDetailState => ({
                ...state,
                adminClient: action.adminClient,
                loadingState: LoadingTypes.LOADED,
                error: ''
            })
        ),

        on(
            actions.adminClientDetailsFailure,
            (state, action): AdminClientDetailState => ({
                ...state,
                adminClient: getEmptyPagingToken(),
                loadingState: LoadingTypes.FAIL,
                error: action.error
            })
        ),

        on(
            actions.loadFacilityLookup,
            (state): AdminClientDetailState => ({ ...state, facilityLookup: [], loadingState: LoadingTypes.LOADING })
        ),

        on(
            actions.loadFacilityLookupSuccess,
            (state, action): AdminClientDetailState => ({
                ...state,
                facilityLookup: action.facilityLookup,
                loadingState: LoadingTypes.LOADED,
                error: ''
            })
        ),

        on(
            actions.loadFacilityLookupError,
            (state, action): AdminClientDetailState => ({
                ...state,
                facilityLookup: [],
                loadingState: LoadingTypes.FAIL,
                error: action.error
            })
        ),

        on(
            actions.loadFacilityProfileGroups,
            (state): AdminClientDetailState => ({
                ...state,
                facilityProfileGroup: [],
                loadingState: LoadingTypes.LOADING
            })
        ),

        on(
            actions.loadFacilityProfileGroupsSuccess,
            (state, action): AdminClientDetailState => ({
                ...state,
                facilityProfileGroup: action.facilityProfileGroup,
                loadingState: LoadingTypes.LOADED,
                error: ''
            })
        ),

        on(
            actions.loadFacilityProfileGroupsError,
            (state, action): AdminClientDetailState => ({
                ...state,
                facilityProfileGroup: [],
                loadingState: LoadingTypes.FAIL,
                error: action.error
            })
        ),

        on(
            actions.createAdminClient,
            (state): AdminClientDetailState => ({ ...state, loadingState: LoadingTypes.LOADING })
        ),

        on(
            actions.createClientSuccess,
            (state, action): AdminClientDetailState => ({
                ...state,
                legacyId: action.legacyId,
                loadingState: LoadingTypes.LOADED,
                error: ''
            })
        ),

        on(
            actions.createClientError,
            (state, action): AdminClientDetailState => ({
                ...state,
                legacyId: null,
                loadingState: LoadingTypes.FAIL,
                error: action.error
            })
        ),

        on(
            actions.updateAdminClient,
            (state): AdminClientDetailState => ({ ...state, loadingState: LoadingTypes.LOADING })
        ),

        on(
            actions.updateClientSuccess,
            (state): AdminClientDetailState => ({ ...state, loadingState: LoadingTypes.LOADED, error: '' })
        ),

        on(
            actions.updateClientError,
            (state, action): AdminClientDetailState => ({
                ...state,
                loadingState: LoadingTypes.FAIL,
                error: action.error
            })
        ),

        on(actions.loadIsLegacyId, (state): AdminClientDetailState => ({ ...state, isLegacyId: false })),

        on(
            actions.isLegacyIdSuccess,
            (state, action): AdminClientDetailState => ({ ...state, isLegacyId: action.isLegacyId, error: '' })
        ),

        on(
            actions.isLegacyIdError,
            (state, action): AdminClientDetailState => ({ ...state, isLegacyId: false, error: action.error })
        ),

        on(actions.clearDetailState, (state): AdminClientDetailState => ({ ...state, ...initialState }))
    )(state, action);
}
