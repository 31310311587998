export enum DocumentAttributeFieldTypes {
    TrueFalse = 'trueFalse',
    Date = 'date',
    DateCompare = 'dateCompare',
    Numeric = 'numeric',
    Multiselect = 'multiselect',
    Singleselect = 'singleselect',
    Text = 'text',
    Textarea = 'textarea'
}
