import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { UnitStateControlledSubstances } from 'src/app/clinical/models/unit-state-controlled-substances';
import * as actions from 'src/app/clinical/store/actions/client-units.actions';
import {
    selectStates,
    selectUnitStateControlledSubstances
} from 'src/app/clinical/store/selectors/client-units.selectors';
import { ToasterService } from 'src/app/core/services';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { StateLookup } from 'src/app/jobs/models/lookups/state-lookup';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { QuesoFormaggioFeatureFlags } from 'src/app/shared/models/enums/queso-formaggio-feature-flags.enum';

@Component({
    selector: 'locums-state-controlled-substances',
    templateUrl: './state-controlled-substances.component.html'
})
export class StateControlledSubstancesComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() unitDescriptionFormId: string;
    @Input() isPrinting = false;
    @Input() readOnly = false;

    @Output() valuesChanged = new EventEmitter<UnitStateControlledSubstances[]>();

    states: StateLookup[];
    stateControlledSubstances: UnitStateControlledSubstances[] = [];
    stateControlledSubstancesForm: FormGroup;
    isLocumsUnitDescriptionFieldValidationFlag$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly store: Store,
        private readonly toasterService: ToasterService,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
        this.createForm();
    }

    get visibleStateControlledSubstances() {
        return this.stateControlledSubstancesForm.get('visibleStateControlledSubstances') as FormArray;
    }

    ngOnInit(): void {
        this.store.dispatch(actions.loadStateControlledSubstancesComponent({ unitId: this.unitDescriptionFormId }));

        forkJoin({
            statesState$: this.store
                .select(selectStates)
                .pipe(filter((state) => state.states !== null))
                .pipe(first()),
            unitStateControlledSubstancesState$: this.store
                .select(selectUnitStateControlledSubstances)
                .pipe(filter((state) => state.selectUnitStateControlledSubstances !== null))
                .pipe(first())
        }).subscribe((state) => {
            this.states = state.statesState$.states;
            this.stateControlledSubstances =
                state.unitStateControlledSubstancesState$.selectUnitStateControlledSubstances;
            this.createFormRows();
        });

        this.ldFeatureManager
            .isEnabled(QuesoFormaggioFeatureFlags.LocumsUnitDescriptionFieldValidation)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                if (flagIsEnabled !== this.isLocumsUnitDescriptionFieldValidationFlag$.value) {
                    this.isLocumsUnitDescriptionFieldValidationFlag$.next(flagIsEnabled);
                }
            });
    }

    createForm(): void {
        this.stateControlledSubstancesForm = this._formBuilder.group({
            visibleStateControlledSubstances: this._formBuilder.array([])
        });
    }

    createFormRows(): void {
        this.stateControlledSubstances.forEach((stateControlledSubstance: UnitStateControlledSubstances) => {
            this.createStateControlledSubstanceFormEntry(stateControlledSubstance);
        });

        if (!this.readOnly && !this.isPrinting) {
            this.createStateControlledSubstanceFormEntry(null);
        }

        if (this.stateControlledSubstances?.length > 0) {
            this.valuesChanged.emit(this.stateControlledSubstances);
        }

        this.visibleStateControlledSubstances.valueChanges
            .pipe(takeUntil(this.d$))
            .subscribe((x: UnitStateControlledSubstances[]) => this.stateControlledSubstanceChanged(x));
    }

    createStateControlledSubstanceFormEntry(unitStateControlledSubstance: UnitStateControlledSubstances | null): void {
        this.visibleStateControlledSubstances.push(
            this._formBuilder.group({
                id: this._formBuilder.control(unitStateControlledSubstance?.id),
                stateId: this._formBuilder.control(unitStateControlledSubstance?.stateId),
                isRequired: this._formBuilder.control(this.getIsRequired(unitStateControlledSubstance)),
                stateName: this._formBuilder.control(this.getStateName(unitStateControlledSubstance?.stateId))
            })
        );
    }

    getIsRequired(unitStateControlledSubstance: UnitStateControlledSubstances | null): boolean {
        return this.isLocumsUnitDescriptionFieldValidationFlag$.value && unitStateControlledSubstance !== null
            ? true
            : unitStateControlledSubstance?.isRequired ?? false;
    }

    getStateName(id: number): string {
        return this.states?.find((x) => x.id === id)?.name;
    }

    validateUnitStateControlledSubstance(selection: MatSelectChange, index: number): void {
        if (
            this.visibleStateControlledSubstances.controls.find(
                (x: any, i: number) => x.get('stateId').value === selection.value && i !== index
            )
        ) {
            this.removeStateControlledSubstances(index);
            this.toasterService.fail('You may not enter the same state more than once.');
        } else {
            if (this.isLocumsUnitDescriptionFieldValidationFlag$.value) {
                this.visibleStateControlledSubstances.controls
                    .find((x: any, i: number) => x.get('stateId').value === selection.value)
                    ?.get('isRequired')
                    .setValue(true);
            }
        }
    }

    removeStateControlledSubstances(index: number): void {
        this.visibleStateControlledSubstances.removeAt(index);
    }

    stateControlledSubstanceChanged(unitStateControlledSubstances: UnitStateControlledSubstances[]): void {
        this.addNewStateControlledSubstancesIfNoneExists(unitStateControlledSubstances);
        this.valuesChanged.emit(unitStateControlledSubstances.filter((x) => x.stateId !== null));
    }

    addNewStateControlledSubstancesIfNoneExists(unitStateControlledSubstances: any[]): void {
        if (!unitStateControlledSubstances.find((y) => y.stateId === null)) {
            this.createStateControlledSubstanceFormEntry(null);
        }
    }
}
