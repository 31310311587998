import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/admin-clients/store/actions/admin-clients-list.actions';
import {
    AdminClientsListState,
    getInitialAdminClientsListState
} from 'src/app/admin-clients/store/state/admin-clients-list.state';
import { LoadingTypes } from 'src/app/shared/models';
import { getEmptyPagingToken } from 'src/app/shared/models/paging-token';

export function AdminClientsListReducer(state: AdminClientsListState | undefined, action: Action) {
    return createReducer(
        getInitialAdminClientsListState(),

        on(
            actions.loadAdminClientsList,
            (state, action): AdminClientsListState => ({
                ...state,
                adminClients: getEmptyPagingToken(),
                loadingState: LoadingTypes.LOADING
            })
        ),

        on(
            actions.adminClientsAdminClientsSuccess,
            (state, action): AdminClientsListState => ({
                ...state,
                adminClients: action.adminClients,
                loadingState: LoadingTypes.LOADED,
                error: ''
            })
        ),

        on(
            actions.adminClientsAdminClientsFailure,
            (state, action): AdminClientsListState => ({
                ...state,
                adminClients: getEmptyPagingToken(),
                loadingState: LoadingTypes.FAIL,
                error: action.error
            })
        ),

        on(
            actions.setAdminClientListSearchQuery,
            (state, action): AdminClientsListState => ({ ...state, gridSearchQuery: action.searchQuery })
        ),

        on(
            actions.exportAdminClientList,
            (state, action): AdminClientsListState => ({ ...state, loadingState: LoadingTypes.LOADING })
        ),

        on(
            actions.exportAdminClientListSuccess,
            (state, action): AdminClientsListState => ({ ...state, loadingState: LoadingTypes.LOADED, error: '' })
        ),

        on(
            actions.adminClientsAdminClientsFailure,
            (state, action): AdminClientsListState => ({
                ...state,
                loadingState: LoadingTypes.FAIL,
                error: action.error
            })
        )
    )(state, action);
}
