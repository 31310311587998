import { SubmittalsListEffect } from './submittals-list.effect';
import { SubmittalsDetailsEffect } from './submittals-details.effect';
import { SubmittalsApplicationEffect } from './submittals-application.effect';
import { SubmittalsLookupsEffect } from './submittals-lookups.effect';
import { SubmittalsDetailsInternalEffect } from './submittals-details-internal.effect';
import { SubmittalsBookEffect } from './submittals-book.effect';
import { SubmittalsJobsDetailsEffect } from './submittals-job-details.effects';
import { SubmittalOfferDetailsEffect } from 'src/app/submittals/store/effects/submittal-offer-details.effect';

export const submittalsEffects = [
    SubmittalsListEffect,
    SubmittalsDetailsEffect,
    SubmittalsApplicationEffect,
    SubmittalsLookupsEffect,
    SubmittalsDetailsInternalEffect,
    SubmittalsBookEffect,
    SubmittalsJobsDetailsEffect,
    SubmittalOfferDetailsEffect
];
