import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { FileAttachmentPreview } from 'src/app/shared/components/file-attachments-preview/file-attachments-preview.component';
import { LoadingTypes } from 'src/app/shared/models';
import { ReleaseGroup } from 'src/app/shared/models/vendor-releases/release-group.model';

const selectAdminJobsFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectAdminJobs = createSelector(selectAdminJobsFeature, (state: AdminState) => state.adminJobsState.jobs);

export const selectAdminJobsFilteredOpenCount = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.filteredOpenPositionsCount
);

export const selectAdminJobsFilteredAllCount = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.filteredAllPositionsCount
);

export const selectFilteredFacilityGroupId = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.filteredFacilityGroupId
);

export const selectAdminJobsTotal = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.total
);

export const selectAdminJobLookups = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.jobLookups
);

export const selectIsLoading = createSelector(
    selectAdminJobsFeature,
    (state) => state.adminJobsState.loadingState === LoadingTypes.LOADING
);

export const selectAdminJobsError = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.error
);

export const selectAdminJobsGridSearchQuery = createSelector(
    selectAdminJobsFeature,
    selectFilteredFacilityGroupId,
    (state: AdminState) => state.adminJobsState.gridSearchQuery
);

export const selectAdminReleaseGroups = createSelector(selectAdminJobsFeature, (state: AdminState) =>
    state.adminJobsState.releaseGroups.sort((first, second) =>
        first.name.toLowerCase() < second.name.toLowerCase() ? -1 : 1
    )
);

export const selectAdminReleaseGroupsWithOriginalOrder = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.releaseGroups
);

export const selectReleaseGroupsAreEmpty = createSelector(
    selectAdminJobsFeature,
    (state) => state.adminJobsState.releaseGroups.length === 0
);

export const selectMappedAdminJobsReleaseGroups = createSelector(selectAdminJobsFeature, (state: AdminState) => {
    const mapped: { [key: string]: ReleaseGroup[] } = {};

    state.adminJobsState.releaseGroups.forEach((item) => {
        item.vendors = item.vendors.sort((first, second) =>
            Intl.Collator().compare(first.operatingName, second.operatingName)
        );

        if (item.hospitalType.name in mapped) {
            return mapped[item.hospitalType.name].push(item);
        }

        mapped[item.hospitalType.name] = [item];
    });

    return mapped;
});

export const selectAdminJobDetails = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.jobDetails
);

export const selectAdminJobCanEditVendorRate = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.canEditVendorBaseRate
);

export const selectJobAttachments = createSelector(selectAdminJobsFeature, (state: AdminState) =>
    state.adminJobsState.jobAttachments.filter((item) => !item.isDeleted)
);

export const selectHasJobAttachments = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.jobAttachments.length > 0
);

export const selectJobAttachment = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.jobAttachmentPreview
);

export const selectJobAttachmentTotalPages = createSelector(
    selectAdminJobsFeature,
    (state: AdminState) => state.adminJobsState.jobAttachmentTotalPages
);

export const selectJobAttachmentPreview = createSelector(
    selectJobAttachment,
    selectJobAttachmentTotalPages,
    (preview, totalPages) => {
        const filePreview: FileAttachmentPreview = {
            blob: preview,
            totalPages
        };

        return filePreview;
    }
);
