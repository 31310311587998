import { createAction, props } from '@ngrx/store';
import { AyaLeadsJobApplicants } from 'src/app/aya-leads/models/aya-leads-job-applicants';
import { AyaLeadsJobDetails } from 'src/app/aya-leads/models/aya-leads-job-details';

export enum AyaLeadsJobDetailsEnum {
    LoadAyaLeadsJobDetails = '[Aya Leads Job Info Card Component] Load Aya Leads Job Details',
    LoadAyaLeadsJobDetailsSuccess = '[Aya Leads Job Details Api] Load Aya Leads Job Details Success',
    LoadAyaLeadsJobDetailsFailure = '[Aya Leads Job Details Api] Load Aya Leads Job Details Failure',
    LoadAyaLeadsJobApplicants = '[Aya Leads Job Details Component] Load Aya Leads Job Applicants',
    LoadAyaLeadsJobApplicantsSuccess = '[Aya Leads Job Details Api] Load Aya Leads Job Applicants Success',
    LoadAyaLeadsJobApplicantsFailure = '[Aya Leads Job Details Api] Load Aya Leads Job Applicants Failure',
    LoadAyaLeadsJobApplicantsCardData = '[Aya Leads Job Applicants Card Component] Load Aya Leads Job Applicants Card Data',
    RemoveAyaLeadsJob = '[Aya Leads Job Info Card Component] Remove Aya Leads Job',
    RemoveAyaLeadsJobSuccess = '[Aya Leads Job Details Api] Remove Aya Leads Job Success',
    RemoveAyaLeadsJobFailure = '[Aya Leads Job Details Api] Remove Aya Leads Job Failure'
}

export const loadAyaLeadsJobDetails = createAction(
    AyaLeadsJobDetailsEnum.LoadAyaLeadsJobDetails,
    props<{ jobId: number }>()
);

export const loadAyaLeadsJobDetailsSuccess = createAction(
    AyaLeadsJobDetailsEnum.LoadAyaLeadsJobDetailsSuccess,
    props<{ jobDetails: AyaLeadsJobDetails }>()
);

export const loadAyaLeadsJobDetailsFail = createAction(
    AyaLeadsJobDetailsEnum.LoadAyaLeadsJobDetailsFailure,
    props<{ error: any }>()
);

export const loadAyaLeadsJobApplicants = createAction(
    AyaLeadsJobDetailsEnum.LoadAyaLeadsJobApplicants,
    props<{ jobId: number }>()
);

export const loadAyaLeadsJobApplicantsSuccess = createAction(
    AyaLeadsJobDetailsEnum.LoadAyaLeadsJobApplicantsSuccess,
    props<{ jobApplicants: AyaLeadsJobApplicants }>()
);

export const loadAyaLeadsJobApplicantsFail = createAction(
    AyaLeadsJobDetailsEnum.LoadAyaLeadsJobApplicantsFailure,
    props<{ error: any }>()
);

export const loadAyaLeadsJobApplicantsCardData = createAction(
    AyaLeadsJobDetailsEnum.LoadAyaLeadsJobApplicantsCardData,
    props<{ jobId: number }>()
);

export const removeAyaLeadsJob = createAction(AyaLeadsJobDetailsEnum.RemoveAyaLeadsJob, props<{ jobId: number }>());

export const removeAyaLeadsJobSuccess = createAction(AyaLeadsJobDetailsEnum.RemoveAyaLeadsJobSuccess);

export const removeAyaLeadsJobFail = createAction(
    AyaLeadsJobDetailsEnum.RemoveAyaLeadsJobFailure,
    props<{ error: any }>()
);
