import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as tierActions from 'src/app/shifts/store/actions/shift-release-tiers.actions';
import { ShiftReleaseSetting, ShiftReleaseSettingTemplate } from 'src/app/shifts/models';

export interface ShiftReleaseSettingTemplateState {
    error: string;
    loading: LoadingTypes;
    template: ShiftReleaseSettingTemplate;
    calculatedSettings: ShiftReleaseSetting[];
}

export const initialState: ShiftReleaseSettingTemplateState = {
    error: '',
    loading: LoadingTypes.INIT,
    template: {} as ShiftReleaseSettingTemplate,
    calculatedSettings: [],
};

const reducer = createReducer(
    initialState,
    on(tierActions.loadShiftReleaseSettingTemplate, (state) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(tierActions.loadShiftReleaseSettingTemplateFail, (state) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        error: state.error
    })),
    on(tierActions.loadShiftReleaseSettingTemplateSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        template: action.settings
    })),
    on(tierActions.calculateShiftTiers, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADING,
    })),
    on(tierActions.calculateShiftTiersSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        calculatedSettings: action.settings,
    })),
    on(tierActions.calculateShiftTiersFail, (state, action) => ({
        ...state,
        loading: LoadingTypes.FAIL,
    })),
    on(tierActions.clearShiftReleaseSettingTemplate, (state) => ({
        ...state,
        ...initialState
    })),
);

export function shiftReleaseSettingTemplateReducer(
    state: ShiftReleaseSettingTemplateState | undefined,
    action: Action
) {
    return reducer(state, action);
}
