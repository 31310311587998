import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { catchError, exhaustMap, map, of, tap, withLatestFrom, EMPTY } from 'rxjs';
import { AyaLeadsApiActions, AyaLeadsPageActions } from '../actions';
import { Router } from '@angular/router';
import { ServerResponseStatus } from 'src/app/shared/models/submittals';
import { Store } from '@ngrx/store';
import { AyaLeadsPageSelectors } from '../selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { AyaLeadsService } from 'src/app/aya-leads/services/aya-leads.service';
import { AyaLeadResumeDocumentInfo } from 'src/app/aya-leads/models/aya-lead-resume-document-info.model';
import { AyaLeadsApplicationStatus } from 'src/app/aya-leads/enums/aya-leads-application-status.enum';
import { AyaLeadsStatisticsRequest } from 'src/app/aya-leads/models/aya-leads-statistics-request';
import { AyaLeadsStatisticsType } from 'src/app/aya-leads/enums/aya-leads-statistics-type.enum';
import { selectAyaLeadsApplicationId } from '../selectors/aya-leads-page.selectors';

@Injectable()
export class AyaLeadsPageEffects extends BaseEffect {
    getFailures$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    AyaLeadsApiActions.loadAyaLeadApplicationInfoFail,
                    AyaLeadsApiActions.loadAyaLeadResumeInfoFail,
                    AyaLeadsApiActions.downloadResumeFail,
                    AyaLeadsApiActions.loadAyaLeadResumePreviewFail
                ),
                map((action) => action.error),
                tap((error) => {
                    this.handleError(error);
                })
            );
        },
        { dispatch: false }
    );

    getAyaLeadApplicationDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AyaLeadsPageActions.loadAyaLeadApplicationInfo,
                AyaLeadsApiActions.loadAyaLeadApplicationInfoFromApi
            ),
            map((action) => action.id),
            exhaustMap((id: number) =>
                this.ayaLeadsService.getAyaLeadApplicationInfo(id).pipe(
                    map((result) => {
                        if (result && result.responseStatus === ServerResponseStatus.SUCCESS) {
                            return AyaLeadsApiActions.loadAyaLeadApplicationInfoSuccess({
                                details: result.applicationInfo,
                                canUpdateStatus: result.canUpdateStatus
                            });
                        }
                        return AyaLeadsApiActions.loadAyaLeadApplicationInfoFail({
                            error: {
                                message: result?.errors?.length
                                    ? result.errors[0]
                                    : 'Failed to load Aya Lead Application Info.'
                            }
                        });
                    }),
                    catchError((error: unknown) => of(AyaLeadsApiActions.loadAyaLeadApplicationInfoFail({ error })))
                )
            )
        );
    });

    loadAyaLeadResumeInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsPageActions.loadAyaLeadResumeInfo),
            exhaustMap((action) =>
                this.ayaLeadsService.getAyaLeadResumeInfo(action.candidateId, action.facilityId).pipe(
                    map((result: AyaLeadResumeDocumentInfo[]) =>
                        AyaLeadsApiActions.loadAyaLeadResumeInfoSuccess({ result })
                    ),
                    catchError((error: unknown) => of(AyaLeadsApiActions.loadAyaLeadResumeInfoFail({ error })))
                )
            )
        );
    });

    downloadAyaLeadResume$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsPageActions.downloadAyaLeadResume),
            concatLatestFrom(() => this.store.select(AyaLeadsPageSelectors.selectResumeInfo)),
            exhaustMap(([action, resumeInfo]) =>
                this.ayaLeadsService.downloadAyaLeadResume(action.candidateId, action.facilityId).pipe(
                    map((result) => {
                        this.saveFile(result);
                        return AyaLeadsApiActions.downloadResumeSuccess();
                    }),
                    catchError((error: unknown) => {
                        return of(AyaLeadsApiActions.downloadResumeFail({ error }));
                    })
                )
            )
        );
    });

    loadResumePreview$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsPageActions.loadAyaLeadResumePreview),
            exhaustMap((action) =>
                this.ayaLeadsService.previewAyaLeadResume(action.candidateId, action.facilityId).pipe(
                    map((result) => {
                        return AyaLeadsApiActions.loadAyaLeadResumePreviewSuccess({
                            blobUrl: URL.createObjectURL(result.body)
                        });
                    }),
                    catchError((error: unknown) => {
                        if (error instanceof HttpErrorResponse && error.status === 404) {
                            return of(AyaLeadsApiActions.loadAyaLeadResumePreviewNotFound());
                        }

                        return of(AyaLeadsApiActions.loadAyaLeadResumePreviewFail({ error }));
                    })
                )
            )
        );
    });

    loadAyaLeadResumePreviewFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AyaLeadsApiActions.loadAyaLeadResumePreviewFail),
                tap(() => this.toasterService.fail('Failed to load resume due to error.'))
            );
        },
        { dispatch: false }
    );

    clearResumePreview$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsPageActions.clearAyaLeadResumePreview),
            concatLatestFrom(() => this.store.select(AyaLeadsPageSelectors.selectResumePreviewBlobUrl)),
            exhaustMap(([, resumeBlobUrl]) => {
                if (!resumeBlobUrl) {
                    return of(AyaLeadsPageActions.clearAyaLeadResumePreviewSuccess());
                }

                try {
                    URL.revokeObjectURL(resumeBlobUrl);
                    return of(AyaLeadsPageActions.clearAyaLeadResumePreviewSuccess());
                } catch (error) {
                    return of(AyaLeadsPageActions.clearAyaLeadResumePreviewFail());
                }
            })
        );
    });

    updateAyaLeadApplicationReviewStatusFromButton$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsPageActions.updateAyaLeadApplicationReviewStatusFromButton),
            exhaustMap((action: { id: number; newStatus: AyaLeadsApplicationStatus }) =>
                this.ayaLeadsService.updateAyaLeadApplicationStatus(action.id, action.newStatus).pipe(
                    map(() => AyaLeadsApiActions.loadAyaLeadApplicationInfoFromApi({ id: action.id })),
                    catchError((error: unknown) =>
                        of(AyaLeadsApiActions.updateAyaLeadApplicationReviewStatusFail({ error }))
                    )
                )
            )
        );
    });

    public updateAyaLeadApplicationStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsPageActions.updateAyaLeadApplicationReviewStatus),
            exhaustMap((action: { id: number; newStatus: AyaLeadsApplicationStatus }) =>
                this.ayaLeadsService.updateAyaLeadApplicationStatus(action.id, action.newStatus).pipe(
                    map(() => AyaLeadsApiActions.updateAyaLeadApplicationReviewStatusSuccess()),
                    catchError((error: unknown) =>
                        of(AyaLeadsApiActions.updateAyaLeadApplicationReviewStatusFail({ error }))
                    )
                )
            )
        );
    });

    public rejectAyaLeadApplicant$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsPageActions.rejectAyaLeadApplicant),
            exhaustMap((action) =>
                this.ayaLeadsService
                    .updateAyaLeadApplicationStatus(action.ayaLeadsApplicationId, AyaLeadsApplicationStatus.Rejected)
                    .pipe(
                        map(() => AyaLeadsApiActions.rejectAyaLeadApplicantSuccess()),
                        catchError((error: unknown) => of(AyaLeadsApiActions.rejectAyaLeadApplicantFailure({ error })))
                    )
            )
        );
    });

    public rejectAyaLeadApplicantSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AyaLeadsApiActions.rejectAyaLeadApplicantSuccess),
                tap(() => this.router.navigate(['client', 'ayaleads']))
            );
        },
        { dispatch: false }
    );

    public rejectAyaLeadApplicantFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AyaLeadsApiActions.rejectAyaLeadApplicantFailure),
                tap((action) =>
                    this.toasterService.fail(
                        typeof action.error === 'string'
                            ? action.error
                            : 'Failed rejecting an applicant. Please try again.'
                    )
                )
            );
        },
        { dispatch: false }
    );

    dashboardShowAyaLeads$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AyaLeadsPageActions.dashboardShowAyaLeadsFilteredByStatus),
                tap(() => this.router.navigate(['client', 'ayaleads']))
            );
        },
        { dispatch: false }
    );

    recordAyaLeadsResumeDownload$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsApiActions.downloadResumeSuccess),
            withLatestFrom(this.store.select(selectAyaLeadsApplicationId)),
            exhaustMap(([action, ayaLeadsApplicationId]) => {
                if (!ayaLeadsApplicationId) {
                    return EMPTY;
                }

                return of(
                    AyaLeadsPageActions.recordAyaLeadsStatistics({
                        ayaLeadsApplicationId,
                        statisticType: AyaLeadsStatisticsType.AyaLeadsDetailPageResumeDownload
                    })
                );
            })
        );
    });

    recordAyaLeadsStatistics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsPageActions.recordAyaLeadsStatistics),
            exhaustMap((request: AyaLeadsStatisticsRequest) =>
                this.ayaLeadsService.recordAyaLeadsStatistics(request).pipe(
                    map(() => AyaLeadsApiActions.recordAyaLeadsStatisticsSuccess()),
                    catchError((error: unknown) => of(AyaLeadsApiActions.recordAyaLeadsStatisticsFailure({ error })))
                )
            )
        );
    });

    constructor(
        private readonly actions$: Actions,
        private readonly ayaLeadsService: AyaLeadsService,
        private readonly router: Router,
        toasterService: ToasterService,
        private readonly store: Store
    ) {
        super(toasterService);
    }
}
