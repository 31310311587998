/**angular */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

/**rxjs */
import { forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

/**local */

/**shared */
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities';
import { saveAs } from 'js/lib/file-saver/FileSaver';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { getStringQueryFromGridSearch } from 'src/app/shared/utilities/filter-descriptor-utility';

/**kendo */
import { GridDataResult } from '@progress/kendo-angular-grid';
import { VendorFile } from 'src/app/admin/vendor-details/models/vendor-file.model';
import { FileType } from 'src/app/shared/models/candidate';

@Injectable({
    providedIn: 'root'
})
export class VendorFilesService {
    private readonly apiPath: string;
    getStringQueryFromGridSearchRef = getStringQueryFromGridSearch;

    constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {
        this.apiPath = combine(_settings.CORE, 'ayaconnect', 'admin', 'vendors');
    }

    public getVendorFiles(vendorId: number, request: GridSearchQuery): Observable<GridDataResult> {
        const queryParams = this.getStringQueryFromGridSearchRef(request);
        const url = `${combine(this.apiPath, vendorId.toString(), 'files')}${queryParams}`;

        return this._httpClient.get<GridDataResult>(url);
    }

    public downloadFile(vendorFile: VendorFile): void {
        const url = combine(this.apiPath, vendorFile?.vendorId?.toString(), 'files', vendorFile?.fileId?.toString());

        this._httpClient
            .get(url, { responseType: 'blob' })
            .pipe(first())
            .subscribe((data) => {
                const blob = new Blob([data]);
                saveAs(blob, vendorFile.file.name + vendorFile.file.type);
            });
    }

    public deleteFiles(vendorId: number, fileIds: number[]): Observable<any> {
        const fileIdQuery = fileIds.map((fileId) => `fileIds=${fileId}`).join('&');
        const url = `${combine(this.apiPath, vendorId.toString(), 'files')}?${fileIdQuery}`;
        return this._httpClient.delete(url);
    }

    getFileTypes(): Observable<FileType[]> {
        const url = `${combine(this._settings.CORE, 'ayaconnect', 'admin', 'vendors', 'file-types')}`;

        return this._httpClient.get<FileType[]>(url);
    }

    public addFiles(vendorId: number, vendorFiles: any[]): Observable<number[]> {
        const requests: Array<Observable<number>> = vendorFiles.map((file) => {
            const uploadData = new FormData();
            uploadData.append('fileTypeId', file.fileTypeId);
            uploadData.append('file', file.fileToUpload);
            const url = combine(this.apiPath, vendorId.toString(), 'files');
            return this._httpClient.post<number>(url, uploadData);
        });

        return forkJoin(requests);
    }
}
