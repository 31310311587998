import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/copy-release-settings-to-modal.actions';
import { CopyReleaseSettingsToModalState, getInitialCopyReleaseSettingsToModalState } from 'src/app/facilities/store/state/copy-release-settings-to-modal.state';
import { LoadingTypes } from 'src/app/shared/models';
import { getEmptyPagingToken } from 'src/app/shared/models/paging-token';

export function copyReleaseSettingsToModalReducer(state: CopyReleaseSettingsToModalState | undefined, action: Action) {
    return createReducer(
        getInitialCopyReleaseSettingsToModalState(),

        //load facilities list
        on(actions.loadCopyReleaseSettingsToModal,
            (state, action): CopyReleaseSettingsToModalState => ({ ...state, facilities: getEmptyPagingToken(), loadingState: LoadingTypes.LOADING })),

        on(actions.loadCopyReleaseSettingsToModalSuccess,
            (state, action): CopyReleaseSettingsToModalState => ({ ...state, facilities: action.facilities, loadingState: LoadingTypes.LOADED, error: '' })),

        on(actions.loadCopyReleaseSettingsToModalFailure,
            (state, action): CopyReleaseSettingsToModalState => ({ ...state, facilities: getEmptyPagingToken(), loadingState: LoadingTypes.FAIL, error: action.error })),

        on(actions.setCopyReleaseSettingsToModalSearchQuery,
            (state, action): CopyReleaseSettingsToModalState => ({ ...state, gridSearchQuery: action.searchQuery })),

        on(actions.clearCopyReleaseSettingsToModal,
            (state, action): CopyReleaseSettingsToModalState => ({ ...state, facilities: getEmptyPagingToken() })),
    )(state, action);
}
