import { PipeTransform, Pipe } from '@angular/core';
import * as _ from 'lodash/fp';

@Pipe({
    name: 'predictiveSearchFilter'
})
export class PredictiveSearchFilterPipe implements PipeTransform {
    transform(items, searchTerm) {
        if (searchTerm) {
            const newSearchTerm = !isNaN(searchTerm) ? searchTerm.toString() : searchTerm.toString().toUpperCase();
            return items.filter((item) => {
                return this.lookForNestedObject(item, newSearchTerm);
            });
        } else {
            return items;
        }
    }

    lookForNestedObject(item, newSearchTerm) {
        const origItem = {
            ...item
        };
        const that = this;
        let count = 0;
        parseNestedObject(item);

        function parseNestedObject(item) {
            for (const key in item) {
                if (_.isPlainObject(item[key])) {
                    if (origItem[key]) {
                        delete origItem[key];
                    }
                    parseNestedObject(item[key]);
                } else if (Array.isArray(item[key])) {
                    if (origItem[key]) {
                        delete origItem[key];
                    }
                    parseNestedObject(item[key]);
                } else {
                    count++;
                    if (origItem[key]) {
                        delete origItem[key];
                    }
                    origItem[key + count] = item[key];
                }
            }
        }
        return that.search(item, origItem, newSearchTerm);
    }

    search(item, origItem, newSearchTerm) {
        const filteredList = [];
        let prop = '';
        for (const koy in origItem) {
            prop = isNaN(origItem[koy]) ? origItem[koy].toString().toUpperCase() : origItem[koy].toString();
            if (prop.indexOf(newSearchTerm) > -1) {
                filteredList.push(item);
                return filteredList;
            }
        }
    }
}
