import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { GridComponent } from 'src/app/shared/grid/components/grid.component';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { ListItem } from 'src/app/shared/models/list-item';
import { OptionModel } from 'src/app/shared/models/option.model';
import * as selectors from 'src/app/admin/store/selectors/vendor-facilities.selectors';
import * as actions from 'src/app/admin/store/actions/vendor-facilities.actions';
import { VendorFacilitiesQueryState } from 'src/app/admin/store/state/vendor-facilities.state';
import { SortTypes } from 'src/app/shared/models';
import { VendorFacility } from 'src/app/shared/models/facilities';
import { UntypedFormControl } from '@angular/forms';
import { VendorFacilityStatus } from 'src/app/shared/models/enums/vendor-facility-status.enum';
import { StatusHistoryDialogComponent } from 'src/app/facilities/facility-details/status-history-dialog/status-history-dialog.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IDialogOptions } from 'src/app/shared/models/dialog.models';
import * as vendorsActions from 'src/app/facilities/store/actions/facility-vendors.actions';
import { VendorFacilityStatusCode } from 'src/app/facilities/models/enums/vendor-facility-status-code.enum';
import { ExcludeActiveDialogComponent } from 'src/app/shared/components/exclude-active-dialog/exclude-active-dialog.component';
import { ToasterService } from 'src/app/core/services';

@Component({
    selector: 'ayac-vendor-facilities',
    templateUrl: './vendor-facilities.component.html',
    styleUrls: ['./vendor-facilities.component.scss']
})
export class VendorFacilitiesComponent extends UnsubscribeOnDestroy {
    vendorIdentifier: number = null;
    @Input()
    set vendorId(id: number) {
        if (id) {
            this.vendorIdentifier = id;
            this.initialize();
        }
    }
    @Input()
    set vendorNotes(notes: string) {
        if (notes) {
            this.includeExcludeNotes.setValue(notes, { emitEvent: false });
        }
    }
    facilities$: Observable<{ data: VendorFacility[]; total: number; nonFilteredTotal: number }>;
    isLoading$: Observable<boolean>;
    searchQuery$: Observable<GridSearchQuery>;
    statesLookup$: Observable<ListItem[]>;
    statusesLookup$: Observable<ListItem[]>;
    termSheetRequired$: Observable<boolean>;
    notAddedJobVendorsCount$: Observable<number>;
    selectedFacilities: OptionModel[] = [];
    vendorFacilityStatusEnum = VendorFacilityStatus;
    yesNoOptions = [
        { name: 'Yes', value: true },
        { name: 'No', value: false }
    ];

    isActiveToggle: boolean;
    selectedRows: any[] = [];
    selectedCount = 0;
    statuses = VendorFacilityStatusCode;
    isAllSelected = false;
    includeExcludeNotes = new UntypedFormControl();
    rowCount = 0;
    total = 0;

    facilityIds = [];

    searchQuery: VendorFacilitiesQueryState = {
        filter: {
            filters: [
                {
                    field: 'vendorId',
                    logic: 'and',
                    operator: 'eq',
                    value: this.vendorIdentifier
                }
            ],
            logic: 'and'
        },
        sort: [{ field: 'name', dir: SortTypes.ASC }],
        take: 25,
        skip: 0
    };

    @Output() notesChange = new EventEmitter<string>();
    @ViewChild(GridComponent, { static: true }) grid: GridComponent;

    constructor(
        private readonly _store: Store,
        private readonly _dialogService: DialogService,
        private readonly _toaster: ToasterService
    ) {
        super();
    }

    initialize(): void {
        // Select
        this.statesLookup$ = this._store.select(selectors.selectStatesLookup);
        this.statusesLookup$ = this._store.select(selectors.selectStatusLookup);
        this.isLoading$ = this._store.select(selectors.selectIsLoading);
        this.searchQuery$ = this._store.select(selectors.selectVendorFacilitiesGridSearchQuery);
        this.facilities$ = this._store.select(selectors.selectVendorFacilities).pipe(
            tap((f) => {
                this.rowCount = f?.data.length;
                this.total = f.total;
                if (this.selectedRows.length) {
                    setTimeout(() => {
                        const pageSelection = this.grid.data['data'].filter(
                            (d) => this.selectedRows.findIndex((s) => s === d) !== -1
                        );

                        if (pageSelection.length) {
                            this.grid.selectRows(pageSelection);
                        } else {
                            this.clearGridSelection();
                            this.isAllSelected = false;
                        }
                    }, 0);
                }
            }),
            takeUntil(this.d$)
        );

        this._store
            .select(selectors.selectFacilityIds)
            .pipe(takeUntil(this.d$))
            .subscribe((ids) => {
                this.facilityIds = ids;
            });

        // Dispatch
        this._store.dispatch(
            actions.setVendorFacilitiesSearchQuery({
                searchQuery: this.searchQuery
            })
        );
        this._store.dispatch(
            actions.loadVendorFacilities({
                vendorId: this.vendorIdentifier
            })
        );
        this._store.dispatch(actions.loadVendorFacilitiesLookups());

        this.handleNotesChange();
    }

    onDataStateChange(state: GridStateChangeEvent): void {
        this._store.dispatch(actions.setVendorFacilitiesSearchQuery({ searchQuery: state }));
        this.clearGridSelection();
    }

    handleNotesChange() {
        this.includeExcludeNotes.valueChanges.pipe(takeUntil(this.d$)).subscribe((changes) => {
            this.notesChange.emit(changes);
        });
    }

    toggleAllSelection(): void {
        this.isAllSelected = !this.isAllSelected;

        if (this.isAllSelected) {
            if (this.selectedCount === this.rowCount) {
                this.selectedCount = this.total;
                return;
            }

            this.selectedCount = this.total;
            this.grid.selectAll();
            this.selectedRows = this.grid.data['data'];
            return;
        }

        this.clearGridSelection();
    }

    clearGridSelection(): void {
        this.grid.clearSelection();
        this.selectedRows = [];
        this.selectedCount = this.selectedRows.length;
    }

    onRowSelected(rows: any[] = []): void {
        if (this.isAllSelected) {
            if (rows.length === 0) {
                this.isAllSelected = false;
                this.selectedRows = rows;
                this.selectedCount = rows.length;
            } else {
                const unSelected = this.rowCount - rows.length;
                this.selectedRows = rows;
                this.selectedCount = this.total - unSelected;
            }
            return;
        }

        this.selectedRows = rows;
        this.selectedCount = rows.length;
    }

    refreshGrid() {
        this._store.dispatch(
            actions.setVendorFacilitiesSearchQuery({
                searchQuery: this.searchQuery
            })
        );
    }

    updateCtsSigned(signed: boolean): void {
        if (this.isAllSelected) {
            this.facilities$.pipe(take(1)).subscribe((f) => {
                const facilityIds = this.getSelectedFacilityIds(f.data);

                const params = {
                    vendorId: this.vendorIdentifier,
                    termSheetSigned: signed,
                    ids: facilityIds
                };
                this._store.dispatch(actions.updateCtsSigned({ params }));
            });
        } else {
            const params = {
                vendorId: this.vendorIdentifier,
                termSheetSigned: signed,
                ids: this.selectedRows.map((row) => row.id)
            };

            this._store.dispatch(actions.updateCtsSigned({ params }));
        }
    }

    ctsSignedChange(ev: any, row: any) {
        const params = {
            vendorId: this.vendorIdentifier,
            termSheetSigned: ev.value,
            ids: [row.id]
        };

        this._store.dispatch(
            actions.updateCtsSigned({
                params
            })
        );
    }

    handleFacilitiesStatusUpdate(status: VendorFacilityStatus, note: string = null): void {
        if (this.selectedRows.length === 0 && !this.isAllSelected) {
            this._toaster.fail('Please select a facility.');
            return;
        }
        if (this.isAllSelected) {
            this.facilities$.pipe(take(1)).subscribe((f) => {
                const facilityIds = this.getSelectedFacilityIds(f.data);

                const params = {
                    facilityIds,
                    status: status,
                    note: note
                };
                this._store.dispatch(actions.updateFacilityStatus({ vendorId: this.vendorIdentifier, params }));
            });
        } else {
            const params = {
                facilityIds: this.selectedRows.map((f) => f.id),
                status: status,
                note: note
            };
            this._store.dispatch(actions.updateFacilityStatus({ vendorId: this.vendorIdentifier, params }));
        }
    }

    getSelectedFacilityIds(facilities: VendorFacility[]): number[] {
        // Grab the selected grid items
        const selected = this.selectedRows.map((f) => f.id),
            // Get the unselected grid items
            unselected = facilities.map((f) => f.id).filter((x) => !selected.includes(x)),
            // Filter the unselected items from the facility ids
            facilityIds = this.facilityIds.filter((x) => !unselected.includes(x));
        return facilityIds;
    }

    openExcludeDialog() {
        const dialogRef = this._dialogService.openDialog(ExcludeActiveDialogComponent, {
            width: '25%',
            data: {
                title: 'Exclude Selected Facilities'
            }
        } as IDialogOptions<any>);

        dialogRef.afterClosed().subscribe((excludeData) => {
            if (excludeData) {
                this.handleFacilitiesStatusUpdate(VendorFacilityStatus.Exclude, excludeData.note);
            }
        });
    }

    openStatusHistory(item: VendorFacility) {
        this._store.dispatch(
            vendorsActions.getVendorStatusHistory({
                facilityId: item.id,
                vendorId: this.vendorIdentifier
            })
        );
        this._dialogService.openDialog(StatusHistoryDialogComponent, {
            width: '55%',
            data: {
                vendorName: item.name,
                vendorId: this.vendorIdentifier,
                facilityId: item.id
            }
        } as IDialogOptions<any>);
    }

    exportSelected(): void {
        if (this.isAllSelected) {
            this.facilities$.pipe(take(1)).subscribe((f) => {
                const facilityIds = this.getSelectedFacilityIds(f.data);

                this._store.dispatch(
                    actions.exportSelected({ facilities: facilityIds, vendorId: this.vendorIdentifier })
                );
            });
        } else {
            this._store.dispatch(
                actions.exportSelected({
                    facilities: this.selectedRows.map((x) => x.id),
                    vendorId: this.vendorIdentifier
                })
            );
        }
    }

    exportAll(): void {
        this._store.dispatch(actions.exportSelected({ facilities: this.facilityIds, vendorId: this.vendorIdentifier }));
    }

    getCtsRequiredText(value?: boolean): string {
        switch (value) {
            case true:
                return 'Yes';
            case false:
                return 'No';
            default:
                return 'N/A';
        }
    }
}
