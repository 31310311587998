import { CandidateOfferStatus } from 'src/app/shared/models/offers/enums/candidate-offer-status.enum';

// Candidate Offer Status is used for certain dropdowns so don't want to add the statuses after ContractChange there.
enum ExtendedOfferStatus {
    Rescinded = 9,
    Declined = 10,
    RecruiterReview = 11,
    SoftDeleted = 12
}

type OfferStatus = CandidateOfferStatus | ExtendedOfferStatus;

export const OfferStatus = { ...CandidateOfferStatus, ...ExtendedOfferStatus };
