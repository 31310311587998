import { createAction, props } from '@ngrx/store';
import { ConnectFacilityFeature } from '../../models/facility-feature.model';

export const loadGroupFeature = createAction(
    '[CONTRACT GROUP FEATURES COMPONENT] Load Facility Group Feature',
    props<{ contractGroupId: number }>()
);
export const loadGroupFeatureSuccess = createAction(
    '[CONTRACT GROUP FEATURES SUCCESS API] Load Facility Group Feature Success',
    props<{ groupFeatures: ConnectFacilityFeature }>()
);
export const loadGroupFeatureFailure = createAction(
    '[CONTRACT GROUP FEATURES FAILURE API] Load Facility Group Feature Failure',
    props<{ error: string }>()
);

export const updateGroupFeature = createAction(
    '[CONTRACT GROUP ASSOCIATION DETAILS COMPONENT] Update Group Feature',
    props<{ contractGroupId: number; featureId: number; featureName: string; isEnabled: boolean }>()
);

export const updateGroupFeatureSuccess = createAction(
    '[CONTRACT GROUP FEATURES API] Update Group Feature Success',
    props<{ contractGroupId: number }>()
);

export const updateGroupFeatureFailure = createAction(
    '[CONTRACT GROUP FEATURES API] Update Group Feature Failure',
    props<{ error: unknown }>()
);
