import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors';
import { Store } from '@ngrx/store';
import { IPDFViewerApplication, PageRenderedEvent, PageViewModeType } from 'ngx-extended-pdf-viewer';

/**
 * These methods and variables are necessary to be global for new viewer to work.
 *
 * The methods accessed within the ng-templates MUST be attached to the window object
 * as the ng-templates ARE NOT true angular templates but are used for custom templates
 * within new viewer. They do not live within the Angular lifecycle.
 *
 * See here for examples: https://pdfviewer.net/extended-pdf-viewer/custom-thumbnails
 */
const viewer = '#viewer';
const sidebarThumbnail = '#sidebarContainer #thumbnailView';

// This regex pull out the numbers for degree rotation
// Regex example: rotate(90deg) becomes 90
const rotateDegreeRegex = /rotate\(([-]?[0-9]{0,3})deg\)/;
let canvas = null;
let canvasContext = null;
let wrapperDiv = null;
let pageDiv = null;

export let pages: { pageNumber: number; rotation: number; checked: boolean }[] = [];
let previousRotation = 0;

(window as any).updateThumbnailSelection = (page: number) => {
    (window as any).PDFViewerApplication.page = page;
    setTimeout(() => {
        setCheckboxes(page);
    });
};

const CANVAS_ROTATION = 90;

(window as any).onRotatePage = () => {
    const page = (window as any).PDFViewerApplication.page;
    const imgContainer: HTMLElement = document.querySelector(
        `${sidebarThumbnail} div[data-page-number="${page}"] div.image-container`
    );

    // Page rotation isn't always '0' so need to get the current page rotation
    previousRotation = pages.find((p) => p.pageNumber === page).rotation;

    let rotation = 0;
    rotation = (previousRotation + 360 + 90) % 360;
    previousRotation = rotation;

    const rotate = `rotate(${rotation}deg)`;

    _rotatePage(page);

    if (imgContainer) {
        imgContainer.style.transform = rotate;
    }

    // Set the new rotation so
    // assignments capture it correctly
    pages = pages.map((p) => {
        if (p.pageNumber === page) {
            return {
                ...p,
                rotation
            };
        }

        return p;
    });
};

@Component({
    selector: 'ayac-document-preview',
    templateUrl: './document-preview.component.html',
    styleUrls: ['./document-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentPreviewComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() sidebarVisible = true;
    @Input() showSidebarButton = true;
    @Input() showToolbar = true;
    @Input() pageViewMode: PageViewModeType = 'multiple';
    @Input() allowPageSelection = false;
    @Input() page = 1;

    @Output() pageSelectedByUser = new EventEmitter();
    @Output() pagesNumber: EventEmitter<number> = new EventEmitter();

    document$: Observable<Blob>;
    zoom = 'auto';

    private _zoomChanged = false;
    private _pagesLoaded = false;

    constructor(private readonly store: Store, @Inject(DOCUMENT) private readonly _document: Document) {
        super();
    }

    ngOnInit() {
        this.document$ = this.store.select(selectors.selectCandidateDocumentPreviewFile);
    }

    pageSelected(): void {
        this.pageSelectedByUser.emit();
    }

    pagesLoaded(): void {
        const PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;
        this.pagesNumber.emit(PDFViewerApplication.pdfViewer._pages.length);
        pages = PDFViewerApplication.pdfViewer._pages.map((page) => {
            return {
                rotation: page['rotation'],
                pageNumber: page['pdfPage']['pageNumber'],
                checked: false
            };
        });

        const pdfOutline = this._document.getElementById('viewOutline') as HTMLButtonElement;

        if (pdfOutline) {
            pdfOutline.style.display = 'none';
        }

        this._pagesLoaded = true;
        this._zoomChanged = false;
    }

    zoomChanged(): void {
        if (this._pagesLoaded) {
            this._zoomChanged = true;
        }
    }

    parentPageCollapseMutationHandler(list: MutationRecord[]): void {
        for (const mutation of list) {
            if (
                mutation.type === 'attributes' &&
                mutation.attributeName === 'class' &&
                mutation.target.nodeType === 1
            ) {
                const target = mutation.target as Element;
                if (target.classList.contains('isToggled') || target.classList.contains('isCollapsed')) {
                    setTimeout(() => {
                        this.magnifyDocumentFromHtmlElement(target);
                    }, 500); //delay the call to reset() since there is a CSS animation to wait for
                    break;
                }
            }
        }
    }

    magnifyDocumentFromHtmlElement(target: Element): void {
        this.zoom = target.classList.contains('isToggled') ? 'page-width' : 'auto';
    }

    pageRendered(event: PageRenderedEvent): void {
        if (this._zoomChanged && this._pagesLoaded && this.pageViewMode !== 'single') {
            _rotatePage(event.pageNumber, this._zoomChanged);
            this._zoomChanged = false;
        }
    }
}

const _rotatePage = (page: number, zoomChanged?: boolean): void => {
    // Get the underlying page canvas from the main viewer NOT the left toolbar
    canvas = document.querySelector(`#viewer div[data-page-number="${page}"] canvas`) as HTMLCanvasElement;
    canvasContext = canvas?.getContext('2d') as CanvasRenderingContext2D;

    wrapperDiv = document.querySelector(`#viewer div[data-page-number="${page}"] div.canvasWrapper`) as HTMLDivElement;
    pageDiv = document.querySelector(`#viewer div[data-page-number="${page}"]`) as HTMLDivElement;

    let image = new Image();
    image.src = canvas?.toDataURL();

    image.onload = () => {
        canvas.width = image.height;
        canvas.height = image.width;

        canvasContext.clearRect(0, 0, canvas.width, canvas.height);

        // Center our rotation in the middle of the image
        canvasContext.translate(image.height / 2, image.width / 2);

        if (zoomChanged) {
            const imgContainer: HTMLElement = document.querySelector(
                `${sidebarThumbnail} div[data-page-number="${page}"] div.image-container`
            );

            if (imgContainer) {
                const rotation = Number(imgContainer.style.transform.replace(rotateDegreeRegex, '$1'));
                canvasContext.rotate(rotation * (Math.PI / 180));

                // Based on the image rotation... need to compensate for how the width and height are oriented.
                if (rotation === 180 || rotation === 0) {
                    canvasContext.drawImage(image, -image.height / 2, -image.width / 2, canvas.width, canvas.height);
                    setDocumentSize(image.width, image.height);
                } else {
                    canvasContext.drawImage(image, -image.width / 2, -image.height / 2, canvas.height, canvas.width);
                    setDocumentSize(image.height, image.width);
                }
            }
        } else {
            // Rotate 90 degrees
            canvasContext.rotate(CANVAS_ROTATION * (Math.PI / 180));
            canvasContext.drawImage(image, -image.width / 2, -image.height / 2);
            setDocumentSize(pageDiv.clientHeight, pageDiv.clientWidth);
        }

        image = null;
    };
};

const setDocumentSize = (width: number, height: number): void => {
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';

    wrapperDiv.style.width = width + 'px';
    wrapperDiv.style.height = height + 'px';

    pageDiv.style.width = width + 'px';
    pageDiv.style.height = height + 'px';
};

const _getCheckboxes = (): unknown[] => {
    return Array.from(document.getElementsByClassName('thumbnail-checkbox'));
};

export const globalSelectCheckboxes = (selectAll = false): void => {
    const checkboxes = _getCheckboxes();

    pages = pages.map((selection, index) => {
        const pageContainer: HTMLElement = document.querySelector(`${viewer} div[data-page-number="${index + 1}"]`);

        return {
            ...selection,
            rotation: Number(pageContainer.style.transform.replace(rotateDegreeRegex, '$1')),
            checked: selectAll
        };
    });

    for (const checkbox of checkboxes) {
        const cbx = checkbox as HTMLInputElement;

        cbx.checked = selectAll;
    }
};

const setCheckboxes = (page: number) => {
    const checkbox = document.getElementById(`checkbox_${page}`) as HTMLInputElement;

    checkbox.checked = !checkbox.checked;

    pages = pages.map((selection) => {
        if (selection.pageNumber === page) {
            const pageContainer = document.querySelector(`${viewer} div[data-page-number="${page}"]`) as HTMLElement;

            return {
                ...selection,
                rotation: Number(pageContainer.style.transform.replace(rotateDegreeRegex, '$1')),
                checked: checkbox.checked
            };
        }

        return selection;
    });

    // Dispatch a custom event since this is attached to the window
    // to tell whether all pages have been selected
    // This can be listened to within Angular components via HostListener
    const areAllChecked = pages.every((p) => p.checked);

    const checkedEvent = new CustomEvent('allPagesChecked', { detail: { allChecked: areAllChecked } });
    window.dispatchEvent(checkedEvent);
};
