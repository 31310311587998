import {
    CompositeFilterDescriptor,
    FilterDescriptor,
} from '@progress/kendo-data-query';
import { GridSearchQuery } from '../grid/models';
import { mapFilters } from '../grid/utils/map-filters';

/**
 * Sets the field value to the filter
 *
 * The field should be equal to the given value.
 * If filter record does not exist - it will be added. If it does exist
 * it should be updated.
 *
 * @param field Field name to be filtered
 * @param value Value to be compared with
 * @param filter Existing composite filter
 * @returns new instance of the CompositeFilter with new value set
 */
export function addFilterEqValue(
    field: string,
    value: any,
    filter: CompositeFilterDescriptor
): CompositeFilterDescriptor {
    return addFilterValue(field, 'eq', value, filter);
}

export function addFilterValue(
    field: string,
    operator: string,
    value: any,
    filter: CompositeFilterDescriptor
): CompositeFilterDescriptor {
    const result = filter || { logic: 'and', filters: [] };
    const existingFilterIdx = filter.filters.findIndex(
        (x) => (x as FilterDescriptor).field === field
    );
    if (value === null) {
        if (existingFilterIdx >= 0) {
            result.filters.splice(existingFilterIdx, 1);
        }
        return result;
    }
    if (existingFilterIdx >= 0) {
        (result.filters[existingFilterIdx] as FilterDescriptor).value = value;
    } else {
        result.filters.push({ field, operator, value });
    }
    return result;
}

export function getStringQueryFromGridSearch(query: GridSearchQuery): string {
    if (!query) {
        return '';
    }
    let result = `?skip=${query.skip}&pagesize=${query.take}`;
    query.sort?.forEach((sort) => {
        result += `&sort.field=${sort.field}&sort.type=${sort.dir}`;
    });

    query.filter?.filters?.forEach((filter: FilterDescriptor) => {
        result += `&filter.${filter.field}=${encodeURIComponent(filter.value)}`;
    });

    return result;
}

export function flattenFilterPrefix(filter: CompositeFilterDescriptor): {[key: string]: any} {
    if (!filter) {
        return null;
    }

    return Object.keys(filter).reduce((group, key) => {
        if (key === 'filters') {
            return filter[key].reduce((group, filter: any) => {
                group['filter.' + filter.field] = filter.value;
                return group;
            }, group);
        }
        return group;
    }, {});
}

export function setFilters(query: GridSearchQuery): any {
    const filters =
        query.filter && query.filter.filters
            ? mapFilters(query.filter.filters, {
                  status: 'statusId',
              })
            : query.filter.filters;

    return filters;
}

export function getFilterValue(field: string, filter: CompositeFilterDescriptor) {
    if (!filter) {
        return null;
    }

    for (const f of filter.filters) {
        const filter = f as FilterDescriptor;
        if (filter.field === field) {
            return filter.value;
        }
    }
    return null;
}

export function getStringQueryFromGridSearchUpdateFilterNames(
    query: GridSearchQuery,
    updateFilters: Array<{ filterName: string; updateFilterTo: string }>
): string {
    if (!query) {
        return '';
    }
    let result = `?skip=${query.skip}&pagesize=${query.take}`;
    query.sort?.forEach((sort) => {
        result += `&sort.field=${sort.field}&sort.type=${sort.dir}`;
    });

    query.filter?.filters?.forEach((filter: FilterDescriptor) => {
        const matchedUpdateFilters = updateFilters.filter((updateFilter) => updateFilter.filterName === filter.field);

        if (matchedUpdateFilters.length > 0) {
            matchedUpdateFilters.forEach((updateFilter) => {
                const fieldName = updateFilter.updateFilterTo;
                const values = Array.isArray(filter.value)
                    ? filter.value.map((value) => `filter.${fieldName}=${encodeURIComponent(value)}`)
                    : [`${fieldName}=${filter.value}`];
                result += `&${values.join('&')}`;
            });
        } else {
            result += `&filter.${filter.field}=${encodeURIComponent(filter.value)}`;
        }
    });

    return result;
}
