import { Injectable } from '@angular/core';
import { InternalPoolService } from '../../internal-pool.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as internalPoolActions from '../actions';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromResources from '../internal-pool.selectors';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { mapFields } from 'src/app/shared/grid/utils/map-fields';
import { Store } from '@ngrx/store';
import { SortTypes } from 'src/app/shared/models';
import { CoreStoreFacade } from 'src/app/core/state/core-store.facade';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { ToasterService } from 'src/app/core/services';

@Injectable()
export class ResourcesEffects extends BaseEffect {
    constructor(
        private readonly internalPoolService: InternalPoolService,
        private readonly coreStoreFacade: CoreStoreFacade,
        private readonly actions$: Actions,
        private readonly store: Store,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    loadSystemIdWithLookupsAndResources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(internalPoolActions.loadSystemIdWithLookupsAndResources),
            switchMap(() =>
                this.coreStoreFacade.getClientSharedData().pipe(
                    mergeMap((response) => [
                        internalPoolActions.loadSystemIdSuccess({ systemId: response.systemId }),
                        internalPoolActions.loadResources({ systemId: response.systemId }),
                        internalPoolActions.loadResourcesLookups()
                    ]),
                    catchError((error) => of(internalPoolActions.loadSystemIdFail({ error })))
                )
            )
        )
    );

    setResourcesQueryAndLoadResources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(internalPoolActions.setResourcesQueryAndLoadResources),
            switchMap((response) => [
                internalPoolActions.setResourcesQuery({ query: response.query }),
                internalPoolActions.loadResources({ systemId: response.systemId })
            ]),
            catchError((error) => of(internalPoolActions.loadResourcesFail({ error })))
        )
    );

    loadSystemId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(internalPoolActions.loadSystemId),
            switchMap(() =>
                this.coreStoreFacade.getClientSharedData().pipe(
                    map((response) =>
                        internalPoolActions.loadSystemIdSuccess({
                            systemId: response.systemId
                        })
                    ),
                    catchError((error) => of(internalPoolActions.loadSystemIdFail({ error })))
                )
            )
        )
    );

    loadResourcesCurrentSystem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(internalPoolActions.loadResourcesCurrentSystem),
            switchMap(() =>
                this.coreStoreFacade.getClientSharedData().pipe(
                    map((response) =>
                        internalPoolActions.loadResources({
                            systemId: response.systemId
                        })
                    )
                )
            )
        )
    );

    loadResources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(internalPoolActions.loadResources),
            withLatestFrom(this.store.select(fromResources.selectResourcesQuery)),
            switchMap(([action, query]) => {
                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };

                const sortArgs = {
                    sortField: query.sort && query.sort[0].field,
                    sortType: query.sort && (query.sort[0].dir as SortTypes)
                };

                const matchArgs = mapFields(flattenFilter(query.filter), {
                    status: {
                        value: (v) => {
                            switch (v) {
                                case 0:
                                    return false;
                                case 1:
                                    return true;
                                default:
                                    return v;
                            }
                        },
                        name: 'isActive'
                    }
                });

                if (action.systemId === 0) {
                    return of(internalPoolActions.loadResourcesFail({ error: 'Invalid system Id.' }));
                }

                return this.internalPoolService.getResources(action.systemId, pagination, sortArgs, matchArgs).pipe(
                    map((resources) => internalPoolActions.loadResourcesSuccess({ resources: resources })),
                    catchError((error) => of(internalPoolActions.loadResourcesFail({ error: error })))
                );
            })
        )
    );

    loadResourcesFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(internalPoolActions.loadResourcesFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );
}
