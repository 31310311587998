import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export const SSOIdpRequiredIfNotFalseRelativeValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    if (!fg || !fg.get('ssoUser').value) {
        return null;
    } else {
        const identityProvider = fg.get('identityProvider').value;
        const ssoIdpIsInvalid = !identityProvider;
        return ssoIdpIsInvalid ? { SSOIdpRequired: true } : null;
    }
};
