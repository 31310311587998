import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
    selector: 'ayac-rich-tooltip',
    template: ` <span
            kendoTooltip
            [tooltipTemplate]="tooltipTemplate"
            filter=".visible"
            position="bottom"
            [tooltipWidth]="width"
            (click)="showTooltip(tooltipEl)">
            <span
                #tooltipEl
                [class.visible]="isVisible">
                <ng-content></ng-content>
            </span>
        </span>

        <ng-template #tooltipTemplate>
            <ng-container
                *ngIf="displayTemplate"
                [ngTemplateOutlet]="displayTemplate"
                [ngTemplateOutletContext]="{ data: data }">
            </ng-container>
        </ng-template>`
})
export class RichToolTipComponent {
    @ViewChild(TooltipDirective)
    public tooltipDir: TooltipDirective;

    @Input() data: any;
    @Input() displayTemplate: TemplateRef<any>;
    @Input() isVisible = true;

    // Default to Infinity to mimic "auto" width
    // This also matches the jQuery version of Kendo
    private _width = Infinity;

    get width(): number {
        return this._width;
    }

    @Input() set width(tooltipWidth: number | string) {
        this._width = coerceNumberProperty(tooltipWidth);
    }

    public showTooltip(eventTarget: Element): void {
        this.tooltipDir.toggle(eventTarget);
    }
}
