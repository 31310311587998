import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LoadingTypes } from "src/app/shared/models";
import { InternalPoolState, internalPoolStoreKey } from "src/app/internal-pool/store/internal-pool.state";

const selectInternalPoolState = createFeatureSelector<InternalPoolState>(
    internalPoolStoreKey
);

export const selectAssignmentsFeature = createSelector(
    selectInternalPoolState,
    (state) => state.assignments
);


export const selectCurrentAssignments = createSelector(
    selectAssignmentsFeature,
    (state) => state.currentAssignments
);

export const selectHasCurrentAssignment = createSelector(
    selectAssignmentsFeature,
    (state) => state.currentAssignments
        .filter(a => a.statusName === 'Confirmed')
        .length > 0
);

export const selectCurrentAssignmentsLoading = createSelector(
    selectAssignmentsFeature,
    (state) => state.currentLoading === LoadingTypes.LOADING
);

export const selectCurrentAssignmentsQuery = createSelector(
    selectAssignmentsFeature,
    (state) => state.currentQuery
);

export const selectCurrentAssignmentsGridData = createSelector(
    selectAssignmentsFeature,
    (state) => {
        return {
            data: state.currentAssignments,
            total: state.currentAssignmentsTotal
        }
    }
);


export const selectPreviousAssignments = createSelector(
    selectAssignmentsFeature,
    (state) => state.previousAssignments
);

export const selectPreviousAssignmentsLoading = createSelector(
    selectAssignmentsFeature,
    (state) => state.previousLoading === LoadingTypes.LOADING
);

export const selectPreviousAssignmentsQuery = createSelector(
    selectAssignmentsFeature,
    (state) => state.previousQuery
);

export const selectPreviousAssignmentsGridData = createSelector(
    selectAssignmentsFeature,
    (state) => {
        return {
            data: state.previousAssignments,
            total: state.previousAssignmentsTotal
        }
    }
);

