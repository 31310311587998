import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromptDialogOptions } from '../../models/promt-dialog-options.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';
import { FeatureFlag } from '../../models/enums/feature-flag.enum';

@Component({
    selector: 'ayac-prompt-dialog',
    templateUrl: './prompt-dialog.component.html',
    styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent implements OnInit {
    form: UntypedFormGroup;
    filterSettings: DropDownFilterSettings;
    dialogData = undefined;
    readonly featureFlag = FeatureFlag;
    constructor(
        fb: UntypedFormBuilder,
        private readonly dialogRef: MatDialogRef<PromptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: PromptDialogOptions,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        this.dialogData = data;
        const inputValidators = [];
        const value = data.value === undefined ? '' : data.value;
        if (data.inputRequire) {
            inputValidators.push(Validators.required);
        }

        this.form = fb.group({
            input: fb.control(value, inputValidators)
        });
    }

    ngOnInit() {
        this.filterSettings = {
            caseSensitive: false,
            operator: 'contains'
        };
    }

    dismiss() {
        this.dialogRef.close();
    }

    submit() {
        if (this.form.invalid) {
            this.form.updateValueAndValidity();
            return;
        }
        this.dialogRef.close(this.form.value);
    }
}
