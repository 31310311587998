import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import { loadTimecardsLinesOfBusiness, loadTimecardsLinesOfBusinessSucesss, loadTimecardsLinesOfBusinessFail } from 'src/app/lookups/state/actions';

export interface TimecardsLinesOfBusinessState {
    linesOfBusiness: Array<ListItem>;
    loading: LoadingTypes;
    error: unknown;
}

export const initialTimecardsLinesOfBusinessState: TimecardsLinesOfBusinessState = {
    linesOfBusiness: [],
    loading: LoadingTypes.INIT,
    error: null
}

export function timecardsLinesOfBusinessReducer(state: TimecardsLinesOfBusinessState, action: Action) {
    return createReducer(
        initialTimecardsLinesOfBusinessState,
        on(loadTimecardsLinesOfBusiness, (state, action) => (
            {
                ...state,
                loading: LoadingTypes.LOADING
            }
        )),
        on(loadTimecardsLinesOfBusinessSucesss, (state, action) => (
            {
				...state,
                linesOfBusiness: action.linesOfBusiness,
				loading: LoadingTypes.LOADED
			}
        )),
        on(loadTimecardsLinesOfBusinessFail, (state, action) => (
			{
                ...state,
                error: action.error,
				loading: LoadingTypes.FAIL
			}
		))
    )(state, action);
}

export function reducer(state: TimecardsLinesOfBusinessState | undefined, action: Action) {
    return timecardsLinesOfBusinessReducer(state, action);
}
