import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { loadInvoices, loadInvoicesSuccess, loadInvoicesFail } from '../invoices.actions';
import { InvoiceModel } from '../../models';

export interface InvoicesListState {
	invoices: Array<InvoiceModel>;
	invoicesTotal: number;
	loading: LoadingTypes
}

const initialState: InvoicesListState = {
	invoices: [],
	invoicesTotal: 0,
	loading: LoadingTypes.INIT
}

export function invoicesListReducer(state: InvoicesListState, action: Action) {
	return createReducer(
		initialState,
		on(loadInvoices, (state, action) => (
			{
				...state,
				loading: LoadingTypes.LOADING
			}
		)),

		on(loadInvoicesSuccess, (state, action) => (
			{
				...state,
				invoices: action.invoices,
				invoicesTotal: action.total,
				loading: LoadingTypes.LOADED
			}
		)),

		on(loadInvoicesFail, (state, action) => (
			{
				...state, 
				loading: LoadingTypes.LOADED
			}
		))
	)(state, action);
}

export function reducer(state: InvoicesListState | undefined, action: Action) {
	return invoicesListReducer(state, action);
}
