import { LookupsService } from '../../services/lookups.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { loadShiftTypesSuccess, loadShiftTypesFail, loadShiftTypes } from 'src/app/lookups/state/actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { getShiftTypes } from '../lookups.selectors';

@Injectable()
export class ShiftTypesEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<{}>,
        private readonly service: LookupsService
    ) {
    }
    getLookupsList$ = createEffect(() => this.actions$.pipe(
        ofType(loadShiftTypes),
        withLatestFrom(this.store.select(getShiftTypes)),
        switchMap(([action, shiftTypes]) => {

            // lazy loading
            if (shiftTypes.length > 0) {
                return of(loadShiftTypesSuccess({ shiftTypes: shiftTypes }));
            }

            return this.service.getShiftTypes()
                .pipe(
                    map(response =>
                        loadShiftTypesSuccess({ shiftTypes: response })
                    ),
                    catchError(error => of(
                        loadShiftTypesFail({ error })
                    ))
                )
        })
    ));
}
