import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ayac-exclude-active-dialog',
    templateUrl: './exclude-active-dialog.component.html',
    styleUrls: ['./exclude-active-dialog.component.scss']
})
export class ExcludeActiveDialogComponent {
    title = 'Exclude Records';
    form = this.formBuilder.group({
        note: ['']
    });

    now = new Date();

    constructor(
        public dialogRef: MatDialogRef<ExcludeActiveDialogComponent>,
        private readonly formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: { title: string }
    ) {}

    ngOnInit(): void {
        this.title = this.data.title;
    }

    exclude(): void {
        this.dialogRef.close({ note: this.form.controls.note.value });
    }
}
