export interface InvoiceDisputeReasonWithDisputeTypeLookup {
    id: number;
    name: string;
    invoiceDisputeTypeId: number;
}

export enum DisputeType {
    Timecard = 1,
    OtherCost = 2
}
