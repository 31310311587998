import { Pipe, PipeTransform } from '@angular/core';

/**
 * FilterBy pipe used to filter arrays and observables by key and value
 *
 * Example: data$ | async | filterBy: { name: 'value' }
 *
 * Will search the data$ observable for the property 'name' and match anything to 'value'.
 * String values are case insensitive, otherwise all other values must be an exact match.
 */
@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform<T>(items: T[] | null, search: { [key: string]: unknown } | null): T[] | null {
        if (!Array.isArray(items) || !search) {
            return null;
        }

        return items.filter((item) =>
            Object.keys(search).every((t: unknown) => {
                const key = t as keyof typeof t;
                const arrayValue: unknown = item[key];
                const searchValue: unknown = search[key];

                if (!searchValue) {
                    return true;
                } else if (typeof arrayValue === 'string' && typeof searchValue === 'string') {
                    return arrayValue.toLowerCase().includes(searchValue.toLowerCase());
                } else {
                    return arrayValue === searchValue;
                }
            })
        );
    }
}
