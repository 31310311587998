import { FacilityFeaturesState } from 'src/app/facilities/store/state/facility-features.state';
import { createSelector } from '@ngrx/store';
import { selectFeature } from 'src/app/facilities/store/selectors/facilities.selectors';
import { FacilitiesState } from 'src/app/facilities/store/state/facilities.state';
import { convertToSearchRequest } from 'src/app/shared/grid/utils/convert-to-search-request';
import { FacilityVendor } from '../../models/facility-vendor.model';
import { LoadingTypes } from 'src/app/shared/models';

export const facilityVendors = createSelector(selectFeature, (state: FacilitiesState) => ({
    data: state.facilityVendors.vendors,
    total: state.facilityVendors.filtered,
    nonFilteredTotal: state.facilityVendors.total
}));

export const selectIsFacilityVendorsGridLoading = createSelector(selectFeature, (state: FacilitiesState) =>
    [state.facilityVendors.loadingState].some((x) => x === LoadingTypes.LOADING)
);

export const facilityVendorsSearchRequest = createSelector(selectFeature, (state: FacilitiesState) =>
    convertToSearchRequest<FacilityVendor>(state.facilityVendors.gridSearchQuery)
);

export const selectFacilitiesVendorGridSearchQuery = createSelector(
    selectFeature,
    (state: FacilitiesState) => state.facilityVendors.gridSearchQuery
);

export const selectStatesLookup = createSelector(
    selectFeature,
    (state: FacilitiesState) => state.facilityVendors.lookups.states
);

export const selectStatusLookup = createSelector(
    selectFeature,
    (state: FacilitiesState) => state.facilityVendors.lookups.statuses
);

export const selectFilteredTotal = createSelector(
    selectFeature,
    (state: FacilitiesState) => state.facilityVendors.total
);

export const selectStatusHistory = createSelector(
    selectFeature,
    (state: FacilitiesState) => state.facilityVendors.history
);

export const selectVendorIds = createSelector(selectFeature, (state: FacilitiesState) => state.facilityVendors.ids);
