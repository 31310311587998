import { submittalsListReducer } from './submittals-list.reducer';
import { submittalsQueryReducer } from './submittals-query.reducer';
import { submittalsLookupsReducer } from './submittals-lookups.reducer';
import { submittalDetailsReducer } from './submittal-details.reducer';
import { submittalApplicationReducer } from './submittal-application.reducer';
import { submittalDetailsInternalReducer } from './submittal-details-internal.reducer';
import { submittalJobDetailsReducer } from './submittal-job-details.reducer';
import { submittalOfferDetailsReducer } from './submittal-offer-details.reducer';

export const submittalsReducer = {
    list: submittalsListReducer,
    query: submittalsQueryReducer,
    lookups: submittalsLookupsReducer,
    details: submittalDetailsReducer,
    detailsInternal: submittalDetailsInternalReducer,
    application: submittalApplicationReducer,
    job: submittalJobDetailsReducer,
    offer: submittalOfferDetailsReducer
};
