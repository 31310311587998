/* eslint-disable @angular-eslint/component-selector */
import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { Observable, map, startWith } from 'rxjs';

@Component({
    selector: 'grid-dropdown-multiselect-filter',
    templateUrl: './dropdown-multiselect-filter.component.html',
    styleUrls: ['./dropdown-multiselect-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropDownMultiSelectFilterComponent extends BaseFilterCellComponent implements OnChanges {
    @ViewChild('select') select: MatSelect;
    @Output() filterChanging = new EventEmitter<{ value: any[]; filter: CompositeFilterDescriptor }>();
    @Input() filter: CompositeFilterDescriptor;
    @Input() selectAll: string;
    @Input() field: string;
    @Input() textField: string;
    @Input() required = true;
    @Input() valueField: string;
    @Input() defaultText: string;
    @Input() defaultToFirstItem = true;
    @Input() reverseAllAndNoneSelectedOnChange = true;
    @Input() data: any[];
    @Input() defaultToAllSelected: boolean;
    @Input() width: number;
    @Input() search: boolean = false;
    @Input() clearButton: boolean = false;
    selectedValuesControl = new FormControl();
    showIndeterminate = false;
    firstSelectedItem: any = null;
    selectedValues = [];
    selectedAll = false;

    searchInput = new FormControl<string>(null);
    filteredData$: Observable<{ data: unknown, isVisible: boolean }[]>;
    filteredDataEmpty$: Observable<boolean>;
    panelClass: string[] = [];

    constructor(filterService: FilterService) {
        super(filterService);
    }

    get charLimit(): number {
        return this.width ? Math.floor(this.width / 13) : 14;
    }

    get fieldFilter(): FilterDescriptor {
        return this.filter?.filters?.find(
            (f: FilterDescriptor) => f.field === this.field
        ) as unknown as FilterDescriptor;
    }

    get selectedLength(): number {
        return this.selectedValuesControl.value?.length || 0;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.fieldFilter) {
            this.selectedValuesControl.setValue([...this.fieldFilter.value]);
            this.selectedValues = [...this.fieldFilter.value];
            this.selectedValuesControl.updateValueAndValidity();
            this.selectItem({ value: [...this.fieldFilter.value], source: null });
        } else if (this.defaultToAllSelected && changes.data?.currentValue) {
            const values = changes.data.currentValue.map((item) => item[this.valueField]);
            this.selectedValuesControl.setValue(values);
            this.selectedValues = values;
            this.selectedAll = true;
        }

        if (this.data) {
            this.filteredData$ = this.searchInput.valueChanges.pipe(
                startWith(null),
                map((searchQuery) => {
                    return this.data.map(d => {
                        const isVisible = searchQuery
                            ? ((d.name as string).toLocaleLowerCase()).includes(searchQuery.toLocaleLowerCase())
                            : true;
                        return {
                            data: d,
                            isVisible
                        }
                    })
                })
            )

            this.filteredDataEmpty$ = this.filteredData$.pipe(
                map(data => data.every(d => !d.isVisible)))
        }

        this.panelClass = [
            'kendo-grid-lookalike-multiselect-panel',
            this.search ? 'kendo-grid-lookalike-multiselect-panel__has-search' : '',
        ]
    }

    selectItem(items: MatSelectChange): void {
        const allSelected = items.value.length === this.data.length;
        const noneSelected = items.value.length === 0;

        if (allSelected) {
            this.selectedAll = true;
            this.showIndeterminate = false;
            this.firstSelectedItem = this.data[0];
        } else if (noneSelected) {
            this.selectedAll = false;
            this.showIndeterminate = false;
            this.firstSelectedItem = null;
        } else {
            this.selectedAll = false;
            this.firstSelectedItem = this.data.find((item) => item[this.valueField] === items.value[0]);
            this.showIndeterminate = true;
        }
    }

    panelStateChange(opened: boolean): void {
        if (opened === false) {
            const selectedItems = this.selectedValuesControl.value;
            const allSelected = selectedItems?.length === this.data.length;
            const noneSelected = selectedItems?.length === 0;
            this.searchInput.reset();

            if (this.reverseAllAndNoneSelectedOnChange) {
                if (allSelected) {
                    this.onChange([]);
                } else if (noneSelected) {
                    this.onChange(this.data.map((item) => item[this.valueField]));
                } else {
                    this.onChange(selectedItems);
                }
            } else {
                this.onChange(selectedItems);
            }
        }
    }

    onChange(value: any[]): void {
        if (JSON.stringify(value) === JSON.stringify(this.selectedValues)) {
            return; // Values unchanged, do nothing
        }

        this.selectedValues = value;
        this.filterChanging.emit({ value, filter: this.filter });

        this.applyFilter(
            value === null
                ? this.removeFilter(this.field)
                : this.updateFilter({
                      field: this.field,
                      operator: 'eq',
                      value
                  })
        );
    }

    toggleSelectAll(checked: boolean): void {
        if (checked) {
            this.select.options.forEach((item: MatOption) => item.select());
        } else {
            this.select.options.forEach((item: MatOption) => item.deselect());
        }
    }

    onClear() {
        this.select.options.forEach((item: MatOption) => item.deselect());
        this.selectedAll = false;
        this.onChange([]);
    }
}
