import { createReducer, on } from '@ngrx/store';

import { ClinicalClientState } from '../state/client.state';
import {
    loadClientInformation,
    loadClientInformationFailure,
    loadClientInformationSuccess,
    setClientId
} from '../actions/client.actions';

export const initialClientState = (): ClinicalClientState => ({
    error: undefined,
    clientId: null,
    client: null
});

export const clientReducer = createReducer(
    initialClientState(),

    on(loadClientInformation, (state) => ({
        ...state
    })),

    on(loadClientInformationSuccess, (state, action) => ({
        ...state,
        client: action.clinicalClient
    })),

    on(loadClientInformationFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error)
    })),

    on(setClientId, (state, action) => ({
        ...state,
        clientId: action.clientId
    }))
);
