import { Component, Input, EventEmitter, Output, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'timepicker-free-text',
    templateUrl: 'timepicker-free-text.component.html',
    styleUrls: ['timepicker-free-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimepickerFreeTextComponent {
    dateView: string;
    roundTo = 0.25;
    @Input()
    maxViewValue = 24;
    @Input()
    inputType = 'text';
    @Input()
    isDisabled = false;
    @Input()
    set dateModel(value: Date) {
        const date = moment(value);
        if (date.isValid() && !this.isIosMinDate(value)) {
            const hours = date.hours();
            const minutes = date.minutes();
            const minutesFree = parseFloat((minutes / 60).toFixed(2));
            this.dateView = String(hours + minutesFree);
        }
    }

    get dateModel() {
        const _date = moment().startOf('day').add(this.roundedDateView, 'hour');
        return _date.isValid() ? _date.toDate() : null;
    }

    get roundedDateView(): string {
        const newVal = Math.round(parseFloat(this.dateView) / this.roundTo) * this.roundTo;
        return (newVal > this.maxViewValue ? 0 : newVal).toFixed(2);
    }
    @ViewChild('inputDate', { static: true })
    inputDate: ElementRef;

    @Output()
    dateModelChange: EventEmitter<Date> = new EventEmitter<Date>();

    @Output()
    edited: EventEmitter<Date> = new EventEmitter<Date>();

    onChange(event: string) {
        this.fixValue();
        this.dateModelChange.emit(this.dateModel);
    }

    onBlur() {
        this.fixValue();
        this.edited.emit(this.dateModel);
    }
    onClick(event) {
        this.fixValue();
        this.inputDate.nativeElement.select();
    }

    onHourInput() {
        this.fixValue();
        this.edited.emit(this.dateModel);
        this.dateModelChange.emit(this.dateModel);
    }

    private fixValue() {
        if (parseFloat(this.dateView) > this.maxViewValue) {
            this.dateView = this.roundedDateView;
        }
    }

    private isIosMinDate(value: any) {
        const date = moment(value);
        //IOS issue
        let isMinDate = false;
        if (value && value.toString()) {
            if (value.toString().includes('0001')) {
                if (date.year() === 2001) {
                    isMinDate = true;
                }
            }
        }
        return isMinDate;
    }
}
