import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as shiftsActions from 'src/app/shifts/store/actions';
import { ResourceList } from 'src/app/shared/models/internal-pool/resource-list.model';

export interface ShiftCandidatesState {
    candidates: ResourceList[];
    loading: LoadingTypes;
}

export const initialShiftCandidatesState: ShiftCandidatesState = {
    candidates: [],
    loading: LoadingTypes.INIT
}

export function shiftCandidatesReducer(state: ShiftCandidatesState, action: Action) {
	return createReducer(
		initialShiftCandidatesState,
		on(shiftsActions.loadShiftCandidates, (state, action) => (
			{
                ...state,
                loading: action.silent ? state.loading : LoadingTypes.LOADING,
			}
		)),

		on(shiftsActions.loadShiftCandidatesSuccess, (state, action) => (
			{
				...state,
				candidates: action.candidates,
                loading: LoadingTypes.LOADED
			}
		)),

		on(shiftsActions.loadShiftCandidatesFail, (state, action) => (
			{
                ...state,
                candidates: [],
                loading: LoadingTypes.FAIL
			}
        )),

        on(shiftsActions.clearShiftCandidates, (state, action) => (
			{
                ...state,
                ...initialShiftCandidatesState
			}
        )),
	)(state, action);
}

export function reducer(state: ShiftCandidatesState | undefined, action: Action) {
	return shiftCandidatesReducer(state, action);
}
