import { Pipe, PipeTransform } from '@angular/core';
import { ChartingSystem } from 'src/app/clinical/models/charting-systems.model';

@Pipe({ name: 'chartingSystemName' })
export class ChartingSystemNamePipe implements PipeTransform {
    transform(value: number, chartingSystems: ChartingSystem[]) {
        if (value && chartingSystems && chartingSystems.length > 0) {
            return chartingSystems.find((c) => c.id === value)?.systemName;
        } else {
            return '';
        }
    }
}
