import { Directive, HostListener, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Directive({
    selector: '[ayaRadioButtonToggle]'
})
export class RadioButtonToggleDirective {
    @Input() form: UntypedFormGroup;
    @Input() field: string;
    @Input() value: any;
    @Input() defaultValue: any = undefined;

    @HostListener('mousedown')
    onMouseDown() {
        this.form
            .get(this.field)
            .setValue(this.value === this.form.get(this.field).value ? this.defaultValue : this.value);
    }

    @HostListener('click', ['$event.target'])
    onClick(target: any) {
        target.checked = this.form.get(this.field).value !== this.defaultValue;
    }
}
