import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ViewEncapsulation,
    ContentChild,
    TemplateRef,
    OnDestroy
} from '@angular/core';
import { GridStateChangeEvent } from '../../grid/models';
import { DocumentItem } from '../documents-grid.model';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ListItem } from '../../models/list-item';
import { Store } from '@ngrx/store';

@Component({
    selector: 'ayac-documents-grid',
    templateUrl: './documents-grid.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./documents-grid.component.scss']
})
export class DocumentsGridComponent implements OnInit, OnDestroy {
    @Input() loading: boolean;
    @Input() filteredDocuments: GridDataResult;
    @Input() exportPdf = false;
    @Input() fileName: string;

    selected = [];

    @Output() onOpenDocument: EventEmitter<number> = new EventEmitter<number>();
    @Output() downloadSelectedDocuments: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() rowsSelected: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() dataStateChanged: EventEmitter<GridStateChangeEvent> = new EventEmitter<GridStateChangeEvent>();
    componentActive: boolean = true;

    @ContentChild('pdfHeader', { static: true }) pdfHeader: TemplateRef<any>;
    complianceExpirationDatesExtended: ListItem[] = [
        { id: 0, name: 'Expired' },
        { id: 30, name: 'Expires: 0-30 days' },
        { id: 60, name: 'Expires: 31-60 days' },
        { id: 90, name: 'Expires: 61-90 days' }
    ];

    constructor(private readonly store: Store) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.componentActive = false;
    }

    canSelectRow(item: DocumentItem) {
        if (item.fileName) {
            return item.fileName.length > 0;
        }

        return false;
    }

    downloadSelected(documentIds: number[]) {
        // Output event with contract id and selected document ids instead
        this.downloadSelectedDocuments.emit(documentIds);
    }

    openDocument(documentId: number) {
        this.onOpenDocument.emit(documentId);
    }

    onRowSelected(rows) {
        this.selected = rows;
        this.rowsSelected.emit(this.selected.map((x) => x.id));
    }

    onDataStateChange(state: GridStateChangeEvent) {
        // filter documents
        this.dataStateChanged.emit(state);
    }

    fileIsMissing(fileName: string): boolean {
        return fileName == null || fileName.length === 0;
    }
}
