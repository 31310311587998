import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, catchError, withLatestFrom, switchMap, tap, mergeMap } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { downloadBlob } from 'src/app/core/utils';
import { Filter, FilterField } from 'src/app/manage-permissions/models';
import { ShiftScheduleModalComponent } from 'src/app/shared/components/shift-schedule-modal/shift-schedule-modal.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { isEmpty } from 'src/app/shared/utilities';
import { ShiftsAttachmentsService } from 'src/app/shifts/services/shifts-attachments.service';
import { ShiftsService } from 'src/app/shifts/services/shifts.service';
import { ShiftSubmittalCandidate } from 'src/app/vendor-perdiemscheduler/models/shift-submittal-candidate.model';
import { PerdiemSchedulerService } from 'src/app/vendor-perdiemscheduler/services/perdiemscheduler.service';
import * as actions from 'src/app/vendor-perdiemscheduler/store/actions/perdiemscheduler-details.actions';
import * as attachmentActions from 'src/app/vendor-perdiemscheduler/store/actions/perdiemscheduler-shifts-attachments.actions';

import * as selectors from 'src/app/vendor-perdiemscheduler/store/perdiemscheduler.selectors';

@Injectable()
export class PerDiemSchedulerDetailsEffects {
    getUserPermissions$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadShiftCandidates),
            withLatestFrom(this._store.select(selectors.selectShiftsDetail)),
            switchMap(([action, shifts]) => {
                return this._service.getShiftCandidates(action.shift.id).pipe(
                    map((candidates: ShiftSubmittalCandidate[]) => {
                        action.shift.candidates = candidates;
                        action.shift.isLoadingCandidates = false;
                        return actions.loadShiftCandidatesSuccess({ shifts: shifts });
                    }),
                    catchError((error: unknown) => {
                        action.shift.isLoadingCandidates = false;
                        return of(actions.loadShiftCandidatesFailure({ error }));
                    })
                );
            })
        );
    });

    getShiftAttachments$ = createEffect(() =>
        this._actions$.pipe(
            ofType(attachmentActions.loadPerdiemschedulerShiftAttachments),
            switchMap((action) => {
                return this._attachmentService.getAttachments(action.shiftId).pipe(
                    map((response) => {
                        return attachmentActions.loadPerdiemschedulerShiftAttachmentsSuccess({ attachments: response });
                    }),
                    catchError((error) => of(attachmentActions.loadPerdiemschedulerShiftAttachmentsFail({ error })))
                );
            })
        )
    );

    getShiftAttachment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(attachmentActions.loadPerdiemschedulerShiftAttachment),
            switchMap((action) =>
                this._attachmentService.getAttachmentBlob(action.shiftId, action.id).pipe(
                    map((blob) =>
                        attachmentActions.loadPerdiemschedulerShiftAttachmentSuccess({
                            blob,
                            fileName: action.fileName
                        })
                    ),
                    catchError((error) => {
                        this._toasterService.success(`Try re-downloading ${action.fileName}...`);
                        return of(attachmentActions.loadPerdiemschedulerShiftAttachmentFail({ error }));
                    })
                )
            )
        )
    );

    downloadBlob$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(attachmentActions.loadPerdiemschedulerShiftAttachmentSuccess),
                tap((action) => {
                    downloadBlob(action.blob, action.fileName);
                })
            ),
        { dispatch: false }
    );

    deleteShiftAttachment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(attachmentActions.deletePerdiemschedulerShiftAttachment),
            switchMap((action) => {
                return this._attachmentService.deleteAttachment(action.shiftId, action.id).pipe(
                    mergeMap((resource) => [
                        attachmentActions.deletePerdiemschedulerShiftAttachmentSuccess(action),
                        attachmentActions.loadPerdiemschedulerShiftAttachments({ shiftId: action.shiftId })
                    ]),
                    catchError((error) => of(attachmentActions.deletePerdiemschedulerShiftAttachmentFail({ error })))
                );
            })
        )
    );

    saveShiftAttachment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(attachmentActions.savePerdiemschedulerShiftAttachment),
            mergeMap((action) => {
                const fileName = action.fileToUpload.name;
                return this._attachmentService
                    .postAttachment(action.shiftId, action.fileToUpload, action.internalOnly)
                    .pipe(
                        tap((resource) => {
                            this._toasterService.success(`${fileName} has been uploaded.`);
                        }),
                        mergeMap((resource) => [
                            attachmentActions.savePerdiemschedulerShiftAttachmentSuccess({
                                fileValidationResult: [resource],
                                shiftId: action.shiftId
                            }),
                            attachmentActions.loadPerdiemschedulerShiftAttachments({ shiftId: action.shiftId })
                        ]),
                        catchError((error) =>
                            of(
                                attachmentActions.savePerdiemschedulerShiftAttachmentFail({
                                    fileValidationResult: {
                                        isValid: false,
                                        name: fileName,
                                        message: `Error uploading ${fileName}`
                                    },
                                    error: `Error uploading ${fileName}`
                                })
                            )
                        )
                    );
            })
        )
    );

    getPreviewShiftAttachment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(attachmentActions.loadPreviewPerdiemschedulerShiftAttachments),
            switchMap((action) =>
                this._attachmentService.getPreviewAttachmentBlob(action.shiftId, action.id, action.pageNum).pipe(
                    map((shiftPreviewAttachments) =>
                        attachmentActions.loadPreviewPerdiemschedulerShiftAttachmentsSuccess({
                            attachmentPreview: shiftPreviewAttachments
                        })
                    ),
                    catchError((error) => {
                        this._toasterService.success(
                            `Error opening preview for this file: ${action.file.fileName} mode please download the file!`
                        );
                        return of(attachmentActions.loadPreviewPerdiemschedulerShiftAttachmentsFail({ error }));
                    })
                )
            )
        )
    );

    getPreviewNextPage$ = createEffect(() =>
        this._actions$.pipe(
            ofType(attachmentActions.loadPreviewPerdiemschedulerShiftAttachments),
            switchMap((action) =>
                this._actions$.pipe(
                    ofType(attachmentActions.loadPreviewNextPage),
                    map(({ pageNum }) =>
                        attachmentActions.loadPreviewPerdiemschedulerShiftAttachments({
                            ...action,
                            pageNum
                        })
                    )
                )
            )
        )
    );

    loadPreviewShiftPageCount$ = createEffect(() =>
        this._actions$.pipe(
            ofType(attachmentActions.loadPreviewShiftPageCount),
            switchMap((action) =>
                this._attachmentService.getTotalNumOfDoc(action.shiftId, action.id).pipe(
                    map((totalPage) => attachmentActions.loadPreviewShiftPageCountSuccess({ total: totalPage })),
                    catchError((error) => {
                        this._toasterService.fail(`Error getting total page number for a file: ${action.id}!`);
                        return of(attachmentActions.loadPreviewShiftPageCountFail({ error }));
                    })
                )
            )
        )
    );

    loadShiftSeries$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadShiftSeries),
            switchMap((action) =>
                this._shiftsService.getSeriesDetails(action.seriesId, action.shiftId).pipe(
                    map((response) => actions.loadShiftSeriesSuccess({ shiftSeries: response })),
                    catchError((error) => of(actions.loadShiftSeriesFail({ error })))
                )
            )
        )
    );

    viewShiftSeries$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.loadShiftSeriesSuccess),
                map((action) => {
                    const data = {
                        schedules: action.shiftSeries.shiftSchedules || [
                            {
                                shiftLength: action.shiftSeries.duration,
                                startDates: [action.shiftSeries.startDate],
                                startTime: action.shiftSeries.startTime,
                                endTime: action.shiftSeries.endTime
                            }
                        ]
                    };

                    this._dialogService.openDialog(ShiftScheduleModalComponent, { data });
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store,
        private readonly _service: PerdiemSchedulerService,
        private readonly _attachmentService: ShiftsAttachmentsService,
        private readonly _shiftsService: ShiftsService,
        private readonly _toasterService: ToasterService,
        private readonly _dialogService: DialogService
    ) {}

    private _updateFilters(newFilter: Filter | null, oldFilter: Filter | null): Filter {
        if (!newFilter && !oldFilter) {
            return null;
        } else if (!newFilter) {
            return oldFilter;
        }

        let updatedFilters: FilterField[] = oldFilter
            ? newFilter.filters.concat(
                  oldFilter.filters.filter((s) => !newFilter.filters.find((t) => t.field === s.field))
              )
            : newFilter.filters;
        updatedFilters = updatedFilters.filter((f: FilterField) => !isEmpty(f.value));

        if (updatedFilters.length === 0) {
            return null;
        }

        return { logic: newFilter.logic, filters: updatedFilters };
    }
}

export const managePermissionsEffects = [PerDiemSchedulerDetailsEffects];
