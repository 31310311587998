import { Action } from '@ngrx/store';
import { permJobsGridReducer, PermJobsGridState } from './perm-jobs-grid-results.reducer';
import { PermJobsGridQueryState, permJobsQueryReducer } from './perm-jobs-grid-query.reducer';

export interface PermJobsReducer {
    results(reducerState: PermJobsGridState, reducerAction: Action): PermJobsGridState;
    query(reducerState: PermJobsGridQueryState, reducerAction: Action): PermJobsGridQueryState;
}
export const permJobsReducer: PermJobsReducer = {
    results: permJobsGridReducer,
    query: permJobsQueryReducer,
};
