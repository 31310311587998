import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap, map, catchError } from 'rxjs/operators';
import { InvoicesRepositoryService } from 'src/app/invoices/services/invoices-repository.service';
import { InvoicesState } from '../invoices.state';
import { loadInvoiceAttachments, loadInvoiceAttachmentsFail, loadInvoiceAttachmentsSuccess } from '../invoices.actions';
import { of } from 'rxjs';
import { uriFormat } from '../../../shared/utilities';

@Injectable()
export class InvoiceAttachmentsEffect {
    getInvoiceAttachments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadInvoiceAttachments),
            switchMap((action) => {
                return this.invoicesRepository.getInvoiceAttachments(action.id).pipe(
                    map((response) =>
                        loadInvoiceAttachmentsSuccess({
                            invoiceAttachments: response.map((r) => {
                                r.fileName = uriFormat(r.fileName);
                                return r;
                            })
                        })
                    ),
                    catchError((error) => of(loadInvoiceAttachmentsFail({ error })))
                );
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<InvoicesState>,
        private readonly invoicesRepository: InvoicesRepositoryService
    ) {}
}
