import { Pipe, PipeTransform } from '@angular/core';
import { LocumsCertification } from '../models/jobs/locums-certification.model';

@Pipe({ name: 'certificationDetails' })
export class CertificationDetailsPipe implements PipeTransform {
    transform(value: LocumsCertification): string {
        if (value?.isRequired) {
            return 'Required';
        }

        if (value?.isPreferred) {
            return 'Preferred';
        }
        return null;
    }
}
