import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LoadingTypes } from "src/app/shared/models";
import { AdminVendorCandidatesQueryState } from "src/app/admin/store/state/admin.vendor-candidates.state";
import { adminFeatureKey } from "./admin.selectors";
import { AdminState } from "../state/admin.state";

export const selectFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectAdminVendorCandidatesList = createSelector(
	selectFeature,
    (state) => state.vendorCandidatesState.candidates
);

export const selectAdminVendorCandidatesListIsLoading = createSelector(
    selectFeature,
    (state) => state.vendorCandidatesState.loadingState === LoadingTypes.LOADING
);

export const selectAdminVendorCandidatesListQuery = createSelector(
	selectFeature,
    (state) =>
        state.vendorCandidatesState.gridSearchQuery
);

export const selectAdminVendorCandidatesListQueryForExport = createSelector(
    selectFeature,
    (state) => {
        const queryForExport: AdminVendorCandidatesQueryState = { 
            ...state.vendorCandidatesState.gridSearchQuery,
            take: state.vendorCandidatesState.candidates.total
        };
        return queryForExport;
    }
)

export const selectAdminVendorCandidatesListView = createSelector(
    selectAdminVendorCandidatesList,
    (state) => {
        return {
            data: state.data,
            total: state.total
        }
    }
)



export const selectAdminVendorCandidateExpertises = createSelector(
    selectFeature,
    (state) => state.vendorCandidatesState.expertises
)

export const selectAdminVendorCandidateStates = createSelector(
    selectFeature,
    (state) => state.vendorCandidatesState.states
)

export const selectAdminVendorCandidatesProfessions = createSelector(
    selectFeature,
    (state) => state.vendorCandidatesState.professions
)

export const selectAdminVendors = createSelector(
    selectFeature,
    (state) => state.vendorCandidatesState.vendors
)
