import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { loadInvoiceAttachments, loadInvoiceAttachmentsSuccess, loadInvoiceAttachmentsFail } from '../invoices.actions';
import { InvoiceAttachment } from 'src/app/invoices/models';

export interface InvoiceAttachmentsState {
    invoicesAttachments: InvoiceAttachment[];
    loading: LoadingTypes;
    error: string;
    totalPages: number;
}

const initialState: InvoiceAttachmentsState = {
    invoicesAttachments: null,
    loading: LoadingTypes.INIT,
    error: null,
    totalPages: 0
};

export function invoiceAttachmentsReducer(state: InvoiceAttachmentsState, action: Action) {
    return createReducer(
        initialState,
        on(loadInvoiceAttachments, (state, action) => ({
            invoicesAttachments: null,
            error: null,
            loading: LoadingTypes.LOADING,
            totalPages: 0
        })),

        on(loadInvoiceAttachmentsSuccess, (state, action) => ({
            invoicesAttachments: action.invoiceAttachments,
            error: null,
            loading: LoadingTypes.LOADED,
            totalPages: 0
        })),

        on(loadInvoiceAttachmentsFail, (state, action) => ({
            invoicesAttachments: null,
            error: action.error,
            loading: LoadingTypes.FAIL,
            totalPages: 0
        }))
    )(state, action);
}

export function reducer(state: InvoiceAttachmentsState | undefined, action: Action) {
    return invoiceAttachmentsReducer(state, action);
}
