export type FileExtension = (typeof fileExtensions)[number];
type FileExtensionWithoutDot = RemoveDot<(typeof fileExtensions)[number]>;

export type MimeTypeString = {
    [K in keyof typeof fileExtensions]: FileExtensionWithoutDot extends string ? (typeof mimeTypes)[K] : never;
}[number];

export type ExtensionString = {
    [K in keyof typeof fileExtensions]: FileExtensionWithoutDot extends string ? FileExtensionWithoutDot : never;
}[number];

// do not export, use the map
const extensionsAndMimeTypes: Record<ExtensionString, MimeTypeString> = {
    msg: 'application/vnd.ms-outlook',
    pdf: 'application/pdf',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
    csv: 'text/csv',
    zip: 'application/zip',
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    gif: 'image/gif',
    bmp: 'image/bmp',
    txt: 'text/plain',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    doc: 'application/msword',
    eml: 'message/rfc822',
    tiff: 'image/tiff',
    '': 'application/octet-stream'
} as const;

// Manually define the file extensions array
export const fileExtensions = [
    '.msg',
    '.pdf',
    '.xlsx',
    '.xls',
    '.csv',
    '.zip',
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.bmp',
    '.txt',
    '.docx',
    '.doc',
    '.eml',
    '.tiff',
    ''
] as const;

// Manually define the MIME types array
export const mimeTypes = [
    'application/vnd.ms-outlook',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/csv',
    'application/zip',
    'image/png',
    'image/jpeg',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'message/rfc822',
    'image/tiff',
    'application/octet-stream'
] as const;

/**
 * A Map for quick lookup of MIME types based on file extensions (minus the dot)
 **/
export const mimeTypeMap = new Map<ExtensionString, MimeTypeString>(
    Object.entries(extensionsAndMimeTypes) as Array<[ExtensionString, MimeTypeString]>
);

// utils

type RemoveDot<T extends string> = T extends `.${infer R}` ? R : T;
