import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import * as utc from 'dayjs/plugin/utc';
import * as dayjs from 'dayjs';

dayjs.extend(utc);

export const selectVendorNotesFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectVendorNotes = createSelector(selectVendorNotesFeature, (state) =>
    state.vendorNotesState.notes.map((note) => {
        note.modifiedEnteredOn = dayjs(`${note.enteredOn}Z`).utc().format();
        return note;
    })
);

export const selectVendorNoteTypes = createSelector(
    selectVendorNotesFeature,
    (state) => state.vendorNotesState.noteTypes
);
