import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { UnitBoardCertification } from 'src/app/clinical/models/unit-board-certification.model';
import * as actions from 'src/app/clinical/store/actions/client-units.actions';
import {
    selectPhysicianSpecialties,
    selectUnitBoardCertifications
} from 'src/app/clinical/store/selectors/client-units.selectors';
import { ToasterService } from 'src/app/core/services';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ExpertiseLookup } from 'src/app/jobs/models';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { QuesoFormaggioFeatureFlags } from 'src/app/shared/models/enums/queso-formaggio-feature-flags.enum';

@Component({
    selector: 'locums-board-certifications',
    templateUrl: './board-certifications.component.html',
    styleUrls: ['./board-certifications.component.scss']
})
export class BoardCertificationsComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() unitDescriptionFormId: string;
    @Input() isPrinting = false;
    @Input() readOnly = false;

    @Output() valuesChanged = new EventEmitter<UnitBoardCertification[]>();

    physicianSpecialties: ExpertiseLookup[];
    boardCertifications: UnitBoardCertification[] = [];
    boardCertificationsForm: FormGroup;
    isLocumsUnitDescriptionFieldValidationFlag$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly store: Store,
        private readonly toasterService: ToasterService,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
        this.createForm();
    }

    get visibleCertifications() {
        return this.boardCertificationsForm.get('visibleCertifications') as FormArray;
    }

    ngOnInit(): void {
        this.store.dispatch(actions.loadBoardCertificationsComponent({ unitId: this.unitDescriptionFormId }));

        forkJoin({
            physicianState$: this.store
                .select(selectPhysicianSpecialties)
                .pipe(filter((state) => state.physicianSpecialties !== null))
                .pipe(first()),
            boardCertificationState$: this.store
                .select(selectUnitBoardCertifications)
                .pipe(filter((state) => state.boardCertifications !== null))
                .pipe(first())
        }).subscribe((state) => {
            this.physicianSpecialties = state.physicianState$.physicianSpecialties;
            this.boardCertifications = state.boardCertificationState$.boardCertifications;
            this.createFormRows();
        });

        this.ldFeatureManager
            .isEnabled(QuesoFormaggioFeatureFlags.LocumsUnitDescriptionFieldValidation)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                if (flagIsEnabled !== this.isLocumsUnitDescriptionFieldValidationFlag$.value) {
                    this.isLocumsUnitDescriptionFieldValidationFlag$.next(flagIsEnabled);
                }
            });
    }

    createForm(): void {
        this.boardCertificationsForm = this._formBuilder.group({
            visibleCertifications: this._formBuilder.array([])
        });
    }

    createFormRows(): void {
        this.boardCertifications.forEach((certification: UnitBoardCertification) => {
            this.createBoardCertificationFormEntry(certification);
        });

        if (!this.readOnly && !this.isPrinting) {
            this.createBoardCertificationFormEntry(null);
        }

        if (this.boardCertifications?.length > 0) {
            this.valuesChanged.emit(this.boardCertifications);
        }

        this.visibleCertifications.valueChanges
            .pipe(takeUntil(this.d$))
            .subscribe((x: UnitBoardCertification[]) => this.boardCertificationsChanged(x));
    }

    createBoardCertificationFormEntry(certification: UnitBoardCertification | null): void {
        this.visibleCertifications.push(
            this._formBuilder.group({
                id: this._formBuilder.control(certification?.id),
                expertiseId: this._formBuilder.control(certification?.expertiseId),
                isBoardCertified: this._formBuilder.control(certification?.isBoardCertified ?? false),
                isBoardEligible: this._formBuilder.control(certification?.isBoardEligible ?? false),
                expertiseName: this._formBuilder.control(this.getExpertiseName(certification?.expertiseId)),
                showError: this._formBuilder.control(this.showError(certification))
            })
        );
    }

    getExpertiseName(id: number): string {
        return this.physicianSpecialties?.find((x) => x.id === id)?.name;
    }

    validateCertification(selection: MatSelectChange, index: number): void {
        if (
            this.visibleCertifications.controls.find(
                (x: any, i: number) => x.get('expertiseId').value === selection.value && i !== index
            )
        ) {
            this.removeBoardCertification(index);
            this.toasterService.fail('You may not enter the same board certification more than once.');
        }
    }

    removeBoardCertification(index: number): void {
        this.visibleCertifications.removeAt(index);
    }

    boardCertificationsChanged(certifications: UnitBoardCertification[]): void {
        this.addNewBoardCertificationIfNoneExists(certifications);
        this.valuesChanged.emit(certifications.filter((x) => x.expertiseId !== null));
    }

    addNewBoardCertificationIfNoneExists(certifications: any[]): void {
        if (!certifications.find((y) => y.expertiseId === null)) {
            this.createBoardCertificationFormEntry(null);
        }
    }

    showError(certification: UnitBoardCertification | null): boolean {
        return (
            this.isLocumsUnitDescriptionFieldValidationFlag$.value &&
            certification?.expertiseId !== null &&
            certification?.expertiseId !== undefined &&
            !certification?.isBoardCertified &&
            !certification?.isBoardEligible
        );
    }

    markAsTouched(): void {
        this.visibleCertifications.controls.forEach((x) => {
            this.setShowError(x);
        });
    }

    get isValidForSubmit(): boolean {
        var isValid = true;
        if (this.isLocumsUnitDescriptionFieldValidationFlag$.value && this.boardCertificationsForm) {
            this.visibleCertifications?.controls?.forEach((x) => {
                if (x.get('expertiseId').value && !x.get('isBoardCertified').value && !x.get('isBoardEligible').value) {
                    isValid = false;
                }
            });
        }
        return isValid;
    }

    setShowError(certification: AbstractControl) {
        if (
            this.isLocumsUnitDescriptionFieldValidationFlag$.value &&
            certification.get('expertiseId').value &&
            !certification.get('isBoardCertified').value &&
            !certification.get('isBoardEligible').value
        ) {
            certification.get('showError').setValue(true);
        } else {
            certification.get('showError').setValue(false);
        }
    }
}
