import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubmittalJob } from 'src/app/shared/models/submittals/submittal-job.model';
import { Observable } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';
import { Store } from '@ngrx/store';
import * as submittalActions from 'src/app/submittals/store/actions';
import * as submittalSelectors from 'src/app/submittals/store/selectors';
import { CandidateJobSubmittalProfile } from 'src/app/submittals/models';
import { PortalHeaderBadge } from 'src/app/shared/models/page-header-badge.model';
import { filter, map, startWith } from 'rxjs/operators';
import { SubmittalStatusService } from 'src/app/submittals/services/submittal-status.service';
import { SubmittalStatusMappedLookup } from 'src/app/shared/models/submittals/lookups/submittal-status-mapped-lookup.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DeclineSubmittalDialogComponent } from 'src/app/submittals/components/submittal-details/decline-submittal-dialog/decline-submittal-dialog.component';
import { ShiftSumitalStatuses } from 'src/app/shifts-submittals/models';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { SubmittalAuthService } from 'src/app/submittals/services/submittal-auth.service';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { DeclineSubmittalInternalDialogComponent } from './decline-submittal-internal-dialog/decline-submittal-internal-dialog.component';

@Component({
    selector: 'ayac-submittal-details-internal',
    templateUrl: './submittal-details-internal.component.html',
    styleUrls: ['./submittal-details-internal.component.scss']
})
export class SubmittalDetailsInternalComponent extends UnsubscribeOnDestroy implements OnInit {
    jobsDisplayedColumns = ['id', 'facility', 'profession', 'expertise', 'startDate', 'shiftText', 'note'];

    jobs$: Observable<Array<SubmittalJob>>;
    profile$: Observable<CandidateJobSubmittalProfile>;
    isLoading$: Observable<boolean>;
    isStatusLoading$: Observable<boolean>;
    statusBadge$: Observable<PortalHeaderBadge>;
    statusLookupsMapped$: Observable<Array<SubmittalStatusMappedLookup>>;

    canShowOffer$: Observable<boolean>;
    canShowDecline$: Observable<boolean>;
    canBookCandidate: boolean;

    title$: Observable<string>;

    featureFlag = FeatureFlag;
    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: Store<{}>,
        private readonly statusService: SubmittalStatusService,
        private readonly dialogService: DialogService,
        private readonly auth: SubmittalAuthService,
        private readonly _featureManager: LDFeatureManager
    ) {
        super();
    }

    get submittalId(): number {
        return Number(this.route.snapshot.paramMap.get('id'));
    }

    get candidateId(): number {
        return Number(this.route.snapshot.queryParamMap.get('candidateId'));
    }

    ngOnInit() {
        const id = this.submittalId;
        this.store.dispatch(submittalActions.loadSubmittalsLookupsStatuses());
        this.store.dispatch(submittalActions.loadIRPSubmittalDetails({ id }));
        this.store.dispatch(submittalActions.loadIRPSubmittalJobs({ id }));

        this.jobs$ = this.store
            .select(submittalSelectors.selectSubmittalsInternalJobs)
            .pipe(filter((profile) => profile != null));

        this.profile$ = this.store
            .select(submittalSelectors.selectSubmittalsInternalProfile)
            .pipe(filter((profile) => profile != null));

        this.isLoading$ = this.store.select(submittalSelectors.selectSubmittalsInternalLoading);
        this.isStatusLoading$ = this.store.select(submittalSelectors.selectSubmittalsInternalStatusLoading);
        this.statusLookupsMapped$ = this.store.select(submittalSelectors.selectIrpStatusesDropDown);

        this.title$ = this.profile$.pipe(
            map((profile) => profile.candidateName),
            startWith('Candidate')
        );

        this.statusBadge$ = this.profile$.pipe(
            map((profile) => ({
                displayText: profile.status,
                backgroundColor: this.statusService.getColor(profile.statusId)
            }))
        );

        this.canShowDecline$ = this.profile$.pipe(
            map((profile) => this.statusService.canShowDecline(profile.statusId))
        );
        this.canShowOffer$ = this.profile$.pipe(map((profile) => this.statusService.canShowOffer(profile.statusId)));
        this.canBookCandidate = this.auth.canEditIRPJobSubmittals();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.store.dispatch(submittalActions.clearIRPSubmittals());
    }

    updateCandidateStatus(statusId: ShiftSumitalStatuses) {
        this.store.dispatch(
            submittalActions.updateIRPSubmittalStatus({
                submittalId: this.submittalId,
                statusId,
                note: null,
                declineReasonId: null
            })
        );
    }

    declineCandidate() {
        this.dialogService
            .openDialog(DeclineSubmittalDialogComponent, { width: '750px', data: {} })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.store.dispatch(
                        submittalActions.updateIRPSubmittalStatus({
                            submittalId: this.submittalId,
                            statusId: ShiftSumitalStatuses.DECLINED,
                            note: result.notes,
                            declineReasonId: result.declineReasonId
                        })
                    );
                }
            });
    }

    declineCandidateV2() {
        this.dialogService
            .openDialog(DeclineSubmittalInternalDialogComponent, { width: '750px', data: {} })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.store.dispatch(
                        submittalActions.updateIRPSubmittalStatus({
                            submittalId: this.submittalId,
                            statusId: ShiftSumitalStatuses.DECLINED,
                            note: result.notes,
                            declineReasonId: result.declineReasonId
                        })
                    );
                }
            });
    }

    offerCandidate() {
        this.store.dispatch(
            submittalActions.updateIRPSubmittalStatus({
                submittalId: this.submittalId,
                statusId: ShiftSumitalStatuses.OFFER,
                note: null,
                declineReasonId: null
            })
        );
    }

    onViewApplication(candidateId: number, candidateName: string) {
        this.router.navigate(['client', 'shiftssubmittals', 'detail', candidateId, 'documents'], {
            queryParams: { candidateName }
        });
    }

    onShowBookCandidates(profile: CandidateJobSubmittalProfile) {
        this.store.dispatch(submittalActions.showBookCandidateModal({ profile }));
    }
}
