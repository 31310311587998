export enum InternalPoolClientPermissions {
    InternalPoolEdit = 'internalpool_Edit',
    InternalPoolView = 'internalpool',
    InternalPoolArchive = 'internalpool_archive',
    BlockSeriesEdit = 'blockseries_edit',
    BlockSeriesView = 'blockseries_view',
    ShiftSubmittalEdit = 'shiftsubmittal_edit',
    ShiftSubmittalView = 'shiftsubmittal_view',
    ConnectDetailIRPJobSubmittals = 'jobs_irp-submittals_Detail',
    EditIRPJobSubmittal = 'jobs_irp-submittals_edit',
    ResourceFulfillmentView = 'resource_fulfillment_view',
    ResourceFulfillmentEdit = 'resource_fulfillment_edit',
    ResourceFulfillmentShiftView = 'resource_fulfillment_shift_view',
    ResourceFulfillmentShiftEdit = 'resource_fulfillment_shift_edit',
    ProfessionsManagementView = 'profession_Specialty_Limiting_View',
    ProfessionsManagementEdit = 'profession_Specialty_Limiting_Edit',
    ShiftBonusManagementEdit = 'shift_bonus_management',
    ShiftRedirect = 'shift_redirect',
    ShiftAddEditBonus = 'shift_bonus_assignment'
}
