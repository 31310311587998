export enum SubmittalsUrl {
    SUBMITTALS = '/AyaConnect/Client/Submittals',
    SUBMITTALS_IRP = '/AyaConnect/Client/Job/InternalPool/Submittals',
    SUBMITTALS_LOOKUPS_STATUSES_IRP = '/AyaConnect/Client/Job/InternalPool/Submittals/statuses',
    SUBMITTALS_LOOKUPS_SECURITY = '/AyaConnect/Client/Submittals/security-lists',
    SUBMITTALS_LOOKUPS_STATUSES = '/AyaConnect/Client/Submittals/statuses',
    SUBMITTALS_EXPORT = '/AyaConnect/Client/Submittals/Export/Excel',
    SUBMITTAL_DOWNLOAD_FILE = '/AyaConnect/Client/Submittals/{submittalId}/submittal-file',
    SUBMITTAL_MULTIPLE_ATTACHMENTS_DOWNLOAD_FILE = '/AyaConnect/Client/Submittals/{submittalId}/attachment',
    SUBMITTAL_DETAILS = '/AyaConnect/Client/Submittals/{submittalId}/expanded',
    SUBMITTAL_JOBS = '/AyaConnect/Client/Submittals/{submittalId}/jobs',
    SUBMITTAL_UPDATE_STATUS = '/AyaConnect/Client/Submittals/{submittalId}/status',
    SUBMITTAL_SEND_OFFER = '/AyaConnect/Client/Submittals/send-offer',
    SUBMITTAL_EMAIL_DATA = '/AyaConnect/Client/Submittals/{submittalId}/email-data',
    SUBMITTALS_LOOKUPS_DECLINE_REASONS = '/AyaConnect/Client/Submittals/decline-reasons',
    SUBMITTAL_ATTACHMENTS_META_DATA = '/AyaConnect/Client/Submittals/attachments/meta-data',
    SUBMITTAL_OFFER_RESCIND = '/AyaConnect/Client/SubmittalOffer/rescind/{clientSubmittalId}',
    SUBMITTAL_OFFER_DETAILS = '/AyaConnect/Client/SubmittalOffer/{clientSubmittalId}',
    SUBMITTAL_OFFER_NOTES = '/AyaConnect/Client/SubmittalOffer/{clientSubmittalId}/notes',
    SUBMITTAL_OFFER_DECLINE_CHANGE_REQUEST = '/AyaConnect/Client/SubmittalOffer/decline-change-request/{clientSubmittalId}',

    IRP_CANDIDATE_DETAILS = '/AyaConnect/Client/Job/InternalPool/Submittals/{submittalId}/profile',
    IRP_CANDIDATE_APPLIED = '/AyaConnect/Client/Job/InternalPool/Submittals/{submittalId}/applied',
    IRP_SUBMITTAL_UPDATE_STATUS = '/AyaConnect/Client/Job/InternalPool/Submittals/{submittalId}/statuses',
    IRP_SUBMITTAL_EMAIL_DATA = '/AyaConnect/Client/Submittals/{submittalId}/email-data',

    BOOK_CANDIDATE = '/AyaConnect/Client/Job/InternalPool/Submittals/convert-job-to-shift',
    CANDIDATE_EDUCATION = '/AyaConnect/Client/Leads/{candidateId}/education',
    CANDIDATE_WORK_HISTORY = '/AyaConnect/Client/Leads/{candidateId}/work-history',
    CANDIDATE_QUALIFICATIONS = '/AyaConnect/Client/Leads/{candidateId}/qualifications',
    SUBMITTALS_DIVERSITY_INFO = '/AyaConnect/Client/Submittals/diversity-info',
    SUBMITTAL_ACTIVITIES = '/AyaConnect/Client/Submittals/{submittalId}/activity',
    SUBMITTAL_NOTES = '/AyaConnect/Client/Submittals/{submittalId}/notes',

    OFFER_CREATE = '/AyaConnect/Client/SubmittalOffer/create-offer'
}
