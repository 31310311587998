import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { permJobsStoreKey } from './perm-jobs.state';
import { permJobsReducer } from './reducers';
import { permJobEffects } from './effects';

@NgModule({
    imports: [StoreModule.forFeature(permJobsStoreKey, permJobsReducer), EffectsModule.forFeature(permJobEffects)]
})
export class PermJobsStoreModule {}
