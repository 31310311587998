import { Action } from '@ngrx/store';
import { AyaLeadsListQueryState, ayaLeadsQueryReducer } from './aya-leads-list-query.reducer';
import { AyaLeadsListResultsState, ayaLeadsListResultsReducer } from './aya-leads-list-results.reducer';
import { AyaLeadsPageState, ayaLeadsPageReducer } from './aya-leads-page.reducer';
import { AyaLeadsJobDetailsState, ayaLeadsJobDetailsReducer } from './aya-leads-job-details.reducer';
import { AyaLeadsLookupsState, ayaLeadsLookupsReducer } from './aya-leads-lookups.reducer';

export interface AyaLeadsReducer {
    lookups(reducerState: AyaLeadsLookupsState, reducerAction: Action): AyaLeadsLookupsState;
    results(reducerState: AyaLeadsListResultsState, reducerAction: Action): AyaLeadsListResultsState;
    query(reducerState: AyaLeadsListQueryState, reducerAction: Action): AyaLeadsListQueryState;
    details(reducerState: AyaLeadsPageState, reducerAction: Action): AyaLeadsPageState;
    jobDetails(reducerState: AyaLeadsJobDetailsState, reducerAction: Action): AyaLeadsJobDetailsState;
}

export const ayaLeadsReducer: AyaLeadsReducer = {
    lookups: ayaLeadsLookupsReducer,
    results: ayaLeadsListResultsReducer,
    query: ayaLeadsQueryReducer,
    details: ayaLeadsPageReducer,
    jobDetails: ayaLeadsJobDetailsReducer
};
