import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { VendorContact } from 'src/app/shared/models';
import { combine } from 'src/app/shared/utilities';
import { VendorContactChange } from './vendor-contacts-change.model';
import { Observable } from 'rxjs';

/**
 * Service used to migrate data into proper format to work with AngularJS
 */
@Injectable({ providedIn: 'root' })
export class VendorContactMigrationService implements VendorContactChange {
    vendorContact: VendorContact;
    hasChanges = true; // Defaults to true so AngularJS knows to save all vendor contacts using this
    toBeDeleted = false;
    isInvalid = false;
    url: string;

    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings,
        contact: VendorContactChange
    ) {
        this.url = combine(this.settings.CORE, 'ayaconnect', 'admin', 'vendor-contacts');
        this.vendorContact = contact.vendorContact;
        this.toBeDeleted = contact.toBeDeleted;
        this.isInvalid = contact.isInvalidChange;
    }

    /**
     * Always returns true since invalid contact information is not stored in this class
     */
    canSave(): boolean {
        return true;
    }

    /**
     * Creates new vendor contact if id is zero
     *
     * Deletes vendor contact if 'toBeDeleted' is true
     *
     * Saves vendor contact otherwise
     */
    save(): Observable<any> {
        let response;

        if (this.toBeDeleted) {
            response = this.http.delete<any>(combine(this.url, this.vendorContact.id.toString()));
        } else if (this.vendorContact.id === 0) {
            response = this.http.post<any>(this.url, this.vendorContact);
        } else {
            response = this.http.put<any>(this.url, this.vendorContact);
        }

        return response;
    }
}
