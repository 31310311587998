import { Injectable } from '@angular/core';
import { ShiftReleaseConfig as ShiftReleaseConfig, ShiftReleaseSettingBase } from 'src/app/shifts/models';

@Injectable({
    providedIn: 'root'
})
export class ShiftsSettingsService {

    constructor() { }

    readonly groups = ['core', 'float', 'agency'] as const;

    readonly labels = {
        all: 'All',
        float: 'Float Pool',
        core: 'Core Staff',
        agency: 'Agency'
    };

    readonly colors = {
        all: '#E3E3E4',
        float: '#ECAC86',
        core: '#8EBAAC',
        agency: '#F5E2B6'
    };

    disassembleShiftSetting(setting: ShiftReleaseSettingBase): ShiftReleaseConfig[] {
        return this.groups.map(g => this.mapShiftReleaseConfig(g, setting));
    }

    mapShiftReleaseConfig(releaseType: typeof this.groups[number], setting: ShiftReleaseSettingBase): ShiftReleaseConfig {
        switch (releaseType) {
            case 'core':
                return {
                    label: this.labels['core'],
                    color: this.colors['core'],
                    days: setting.coreStaffDays,
                    isEnabled: setting.coreStaffEnabled,
                    isImmediately: setting.coreStaffReleaseImmediately,
                    startDate: setting.coreStaffStartDateUTC,
                    endDate: setting.coreStaffEndDateUTC,
                };
            case 'float':
                return {
                    label: this.labels['float'],
                    color: this.colors['float'],
                    days: setting.floatPoolDays,
                    isEnabled: setting.floatPoolEnabled,
                    isImmediately: setting.floatPoolReleaseImmediately,
                    startDate: setting.floatStartDateUTC,
                    endDate: setting.floatEndDateUTC,
                };
            case 'agency':
                return {
                    label: this.labels['agency'],
                    color: this.colors['agency'],
                    days: setting.agencyDays,
                    isEnabled: setting.agencyEnabled,
                    isImmediately: setting.agencyReleaseImmediately,
                    startDate: setting.agencyStartDateUTC,
                    endDate: setting.agencyEndDateUTC,
                };
        }
    }
}
