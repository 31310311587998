import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { vendorProfileWebsiteActions } from 'src/app/admin/store/actions/vendor-profile-website.actions';
import { VendorProfileWebsiteService } from 'src/app/admin/vendor-details/websites/vendor-profile-website.service';
import { exhaustMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    selectWebsitesToAdd,
    selectWebsitesToDelete,
    selectWebsitesToUpdate
} from 'src/app/admin/store/selectors/vendor-profile.selectors';

@Injectable()
export class VendorProfileWebsiteEffects {
    getVendorProfileWebsiteTypes$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileWebsiteActions.loadVendorProfileWebsiteTypes),
            exhaustMap(() => {
                return this._vendorProfileWebsiteService.getVendorProfileWebsiteTypes().pipe(
                    map((result) =>
                        vendorProfileWebsiteActions.loadVendorProfileWebsiteTypesSuccess({ types: result })
                    ),
                    catchError((error: unknown) =>
                        of(vendorProfileWebsiteActions.loadVendorProfileWebsiteTypesFailure({ error }))
                    )
                );
            })
        );
    });

    getVendorProfileWebsites$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(
                vendorProfileWebsiteActions.loadVendorProfileWebsites,
                vendorProfileWebsiteActions.addWebsitesSuccess,
                vendorProfileWebsiteActions.deleteWebsitesSuccess,
                vendorProfileWebsiteActions.updateWebsitesSuccess
            ),
            exhaustMap((action) => {
                return this._vendorProfileWebsiteService.getVendorProfileWebsites(action.vendorId).pipe(
                    map((result) => vendorProfileWebsiteActions.loadVendorProfileWebsitesSuccess({ sites: result })),
                    catchError((error: unknown) =>
                        of(vendorProfileWebsiteActions.loadVendorProfileWebsitesFailure({ error }))
                    )
                );
            })
        );
    });

    addWebsites$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileWebsiteActions.addWebsites),
            withLatestFrom(this._store.select(selectWebsitesToAdd)),
            exhaustMap(([action, sites]) => {
                const adds = sites.map((item) => {
                    item.vendorId = action.vendorId;
                    return this._vendorProfileWebsiteService.addWebsite(item);
                });

                return forkJoin(adds).pipe(
                    map(() => vendorProfileWebsiteActions.addWebsitesSuccess({ vendorId: action.vendorId })),
                    catchError((error: unknown) => of(vendorProfileWebsiteActions.addWebsitesFailure({ error })))
                );
            })
        );
    });

    updateWebsites$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileWebsiteActions.updateWebsites),
            withLatestFrom(this._store.select(selectWebsitesToUpdate)),
            exhaustMap(([action, sites]) => {
                const updates = sites.map((item) => this._vendorProfileWebsiteService.updateWebsite(item));

                return forkJoin(updates).pipe(
                    map(() => vendorProfileWebsiteActions.updateWebsitesSuccess({ vendorId: action.vendorId })),
                    catchError((error: unknown) => of(vendorProfileWebsiteActions.updateWebsitesFailure({ error })))
                );
            })
        );
    });

    deleteWebsites$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(vendorProfileWebsiteActions.deleteWebsites),
            withLatestFrom(this._store.select(selectWebsitesToDelete)),
            exhaustMap(([action, sites]) => {
                const deletes = sites.map((item) => this._vendorProfileWebsiteService.deleteWebsite(item.id));

                return forkJoin(deletes).pipe(
                    map(() => vendorProfileWebsiteActions.deleteWebsitesSuccess({ vendorId: action.vendorId })),
                    catchError((error: unknown) => of(vendorProfileWebsiteActions.deleteWebsitesFailure({ error })))
                );
            })
        );
    });

    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store,
        private readonly _vendorProfileWebsiteService: VendorProfileWebsiteService
    ) {}
}
