import { Pipe, PipeTransform } from '@angular/core';
import { VendorContact } from '../models';

@Pipe({
    name: 'vendorContactEmail'
})
export class VendorContactEmailPipe implements PipeTransform {
    transform(vendorContact: VendorContact): string {
        if (!vendorContact) {
            return '';
        }

        let vendorContactEmail = vendorContact.vendorContactEmails?.find((x) => x.vendorContactEmailTypeID === 1);
        if (!vendorContactEmail) {
            vendorContactEmail = vendorContact.vendorContactEmails?.find((x) => x?.emailAddress);
        }

        return vendorContactEmail ? vendorContactEmail.emailAddress : '';
    }
}
