export enum PerdiemSchedulerUrl {
    VENDOR_PROFESSIONS = '/AyaConnect/vendors/submittals/professions',
    VENDOR_SPECIALTIES = '/AyaConnect/vendors/submittals/{id}/profession-expertises',
    VENDOR_FACILITIES = '/AyaConnect/PerDiemScheduler/GetPerDiemFacilitiesForVendor',
    VENDOR_CANDIDATES = '/AyaConnect/PerDiemScheduler/GetPerDiemCandidatesForVendor',
    BOOK_CANDIDATE = '/AyaConnect/PerDiemScheduler/BookCandidate',
    VENDOR_CANCEL_REASONS = '/AyaConnect/PerDiemScheduler/GetCancelReasons',
    REMOVE_CANDIDATE = '/AyaConnect/PerDiemScheduler/RemoveCandidate',
    CLIENT_SHIFTS = '/AyaConnect/Client/Shifts',
    VENDOR_SHIFTS_GRID = '/AyaConnect/PerDiemScheduler/VendorShiftsGrid'
}
