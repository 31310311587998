import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FacilitySystemsComponent } from 'src/app/admin/facility-systems/facility-systems.component';
import { FacilitiesSystemsProfileComponent } from 'src/app/facilities/facilities-systems-profile/facilities-systems-profile.component';
import { SystemDetailsComponent } from 'src/app/facilities/system-details/system-details.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SystemsModule } from 'src/app/systems/systems.module';

@NgModule({
    imports: [SharedModule, RouterModule, MaterialModule, SystemsModule],
    declarations: [SystemDetailsComponent, FacilitiesSystemsProfileComponent, FacilitySystemsComponent],
    exports: [SystemDetailsComponent, FacilitiesSystemsProfileComponent, FacilitySystemsComponent]
})
export class SystemDetailsSharedModule {}
