import { Component, Input } from '@angular/core';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-form-control-wrapper',
    templateUrl: './form-control-wrapper.component.html',
    styleUrls: ['./form-control-wrapper.component.scss']
})
export class FormControlWrapperComponent extends UnsubscribeOnDestroy {
    @Input() controlLabel = '';
    @Input() showRequiredAsterisk = false;
    @Input() isCentered = true;
    @Input() labelPosition: 'left' | 'top' = 'left';
    @Input() dataQaId = '';

    constructor() {
        super();
    }
}
