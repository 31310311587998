import { Injectable, Inject, Injector } from '@angular/core';
import { IdentityService } from './identity.service';
import { Observable } from 'rxjs';
import { ClientSharedData } from 'src/app/shared/models/client-shared-data.model';
import { interpolateParams } from 'src/app/core/utils';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { FacilityFeature } from 'src/app/facilities/models/facility-feature.model';
import { FeatureCategory } from 'src/app/shared/models';

export enum clientUrls {
    IS_NYU_SYSTEM = '/AyaConnect/Client/Integration/NYU/Is-NYU-System'
}

@Injectable()
export class ClientService {

	constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings,
        /* app is not starting if we will put IdentityService here because current service is injected into effect
        and looks like effects are initialized before IdentityService*/
        private readonly injector: Injector) {}

    getClientSharedData() : Observable<ClientSharedData> {
        const identityService = this.injector.get(IdentityService);
        const requestParams = identityService.isSystemLogin()
                        ? { hospId : -1, systemId: identityService.clientId }
                        : { clientId: identityService.clientId };
        
        const params = interpolateParams({
            ...requestParams
        });

        const remoteCall = identityService.isSystemLogin()
                        ? '/AyaConnect/Client/GetHospitalSharedData'
                        : '/AyaConnect/Client/GetSharedData';
        return this.http.get<ClientSharedData>(`${this.settings.CORE}${remoteCall}`, {params});
    }

    isNyuSystem(): Observable<boolean> {
        return this.http.get<boolean>(`${this.settings.CORE}${clientUrls.IS_NYU_SYSTEM}`);
    }

    getFacilityFeaturesByCategoryId(featureCategoryId: FeatureCategory, facilityId?: number, facilityProfileGroupId?: number) {
        
        const url = '/AyaConnect/Client/FacilityFeatures';

        const params = interpolateParams({
            facilityId: facilityId,
            facilityProfileGroupId: facilityProfileGroupId,
            featureCategoryId: featureCategoryId
        });

        return this.http.get<FacilityFeature[]>(`${this.settings.CORE}${url}`, { params });
    }

    
    getAttachmentNotes(featureCategory: FeatureCategory){
        let attachmentNotes = "";

        attachmentNotes = "<span><p><strong>Note: You must attach the following for your temporary request as a PDF file:"
        attachmentNotes += "</strong></p><strong><ol><li>Executive Leadership approval"

        if(featureCategory === FeatureCategory.Jobs){
            attachmentNotes += "</li><li>Job Description</li></ol></strong></span>"
        }else {
            attachmentNotes += "</li><li>Shift Description</li></ol></strong></span>"
        }

        return attachmentNotes;
    }
}
