import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { HttpClient } from '@angular/common/http';
import { SubmittalsUrl } from 'src/app/shared/models/submittals/enums/submittals-url.enum';
import { Observable } from 'rxjs';
import { SubmittalJob } from 'src/app/shared/models/submittals/submittal-job.model';
import { interpolateUrl } from 'src/app/core/utils/interpolate-url';
import { CandidateJobSubmittalProfile } from '../models';
import { IrpSubmittalUpdateStatusRequest } from 'src/app/shared/models/submittals/requests/irp-submittal-update-status.request';
import { SubmittalEmailData } from 'src/app/shared/models/submittals/submittal-email-data.model';

@Injectable({
  providedIn: 'root'
})
export class SubmittalsInternalService {
    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) { }

    getIRPSubmittalDetails(submittalId: number): Observable<CandidateJobSubmittalProfile> {
        const url = interpolateUrl(SubmittalsUrl.IRP_CANDIDATE_DETAILS, {submittalId});

        return this.http.get<CandidateJobSubmittalProfile>(`${this._settings.CORE}${url}`);
    }

    getIRPSubmittalJobs(submittalId: number): Observable<SubmittalJob[]> {
        const url = interpolateUrl(SubmittalsUrl.IRP_CANDIDATE_APPLIED, {submittalId});
        
        return this.http.get<SubmittalJob[]>(`${this._settings.CORE}${url}`);
    }

    updateIRPSubmittalStatus(data: IrpSubmittalUpdateStatusRequest): Observable<CandidateJobSubmittalProfile> {
        const urlParams = {
            submittalId: data.submittalId
        };

        const payload = {
            statusId: data.statusId,
            note: data.note,
            declineReasonId: data.declineReasonId
        }
        const url = interpolateUrl(SubmittalsUrl.IRP_SUBMITTAL_UPDATE_STATUS, urlParams);

        return this.http.put<CandidateJobSubmittalProfile>(`${this._settings.CORE}${url}`, payload);
    }

    getIRPSubmittalEmailData(submittalId: number): Observable<SubmittalEmailData> {
        const urlParams = {
            submittalId: submittalId
        };
        const url = interpolateUrl(SubmittalsUrl.IRP_SUBMITTAL_EMAIL_DATA, urlParams);

        return this.http.get<SubmittalEmailData>(`${this._settings.CORE}${url}`);
    }

    
    bookCandidate(data: {
        submittalId: number,
        days: number[],
        startDate?: string,
        endDate?: string,
        shiftDuration?: number
    }) {
        const url = SubmittalsUrl.BOOK_CANDIDATE;
        const body = {
            ...data
        };
        
        return this.http.post<unknown>(`${this._settings.CORE}${url}`, body);
    }
}
