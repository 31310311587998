import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { Unit, UnitFacilityInformation } from 'src/app/clinical/models';
import { FacilityContact } from 'src/app/clinical/models/facility-contact.model';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

@Component({
    selector: 'ayac-facility-contacts',
    templateUrl: './facility-contacts.component.html',
    styleUrls: ['./facility-contacts.component.scss']
})
export class FacilityContactsComponent extends UnsubscribeOnDestroy implements OnDestroy, OnChanges {
    @Input() set unitInfo(unitInfo: UnitFacilityInformation) {
        if (!unitInfo || !unitInfo.unit) {
            return;
        }

        this.setFormValues(unitInfo.unit);
        this.watchFormChanges();
        refreshDisableEnableState(this.facilityContactsForm, this.readOnly);
    }
    @Input() readOnly = false;

    @Output() valueChanged = new EventEmitter<Partial<Unit>>();

    @Input() canShowContact = false;

    facilityContactsForm: UntypedFormGroup;

    FeatureFlag = FeatureFlag;

    get facilityContacts(): UntypedFormArray {
        return this.facilityContactsForm.get('facilityContacts') as UntypedFormArray;
    }

    constructor(public readonly formBuilder: UntypedFormBuilder, private readonly _store: Store) {
        super();
        this.buildForm();
    }

    buildForm(): void {
        this.facilityContactsForm = this.formBuilder.group({
            facilityContacts: this.formBuilder.array([])
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.facilityContactsForm, changes.readOnly.currentValue);
        }
    }

    setFormValues(unit: Unit): void {
        unit?.contacts?.forEach((facilityContact: FacilityContact) => {
            this.facilityContacts.push(
                this.formBuilder.group({
                    id: this.formBuilder.control(facilityContact.id),
                    unitId: this.formBuilder.control(facilityContact.contact.unitId),
                    unitName: this.formBuilder.control(facilityContact.contact.unitName),
                    unitEmail: this.formBuilder.control(facilityContact.contact.unitEmail, Validators.email),
                    unitTitle: this.formBuilder.control(facilityContact.contact.unitTitle),
                    unitTelephone: this.formBuilder.control(facilityContact.contact.unitTelephone),
                    unitFax: this.formBuilder.control(facilityContact.contact.unitFax),
                    contactName: this.formBuilder.control(facilityContact.contact.contactName),
                    departmentName: this.formBuilder.control(facilityContact.contact.departmentName)
                })
            );
        });
    }

    getFormValues(): Partial<Unit> {
        const formArray: UntypedFormArray = this.facilityContactsForm.get('facilityContacts') as UntypedFormArray;

        const facilityContacts: FacilityContact[] = [];

        formArray.controls.forEach((facilityContact) => {
            facilityContacts.push({
                id: facilityContact.get('id').value ?? -1,
                contact: {
                    unitId: facilityContact.get('unitId').value ?? -1,
                    unitName: facilityContact.get('unitName').value,
                    unitEmail: facilityContact.get('unitEmail').value,
                    unitTitle: facilityContact.get('unitTitle').value,
                    unitTelephone: facilityContact.get('unitTelephone').value,
                    unitFax: facilityContact.get('unitFax').value,
                    contactName: facilityContact.get('contactName').value,
                    departmentName: facilityContact.get('departmentName').value
                }
            });
        });

        const unit: Partial<Unit> = {
            contacts: facilityContacts
        };

        return unit;
    }

    watchFormChanges(): void {
        this.facilityContactsForm.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => {
            this.valueChanged.emit(this.getFormValues());
        });
    }

    addContact(): void {
        this.facilityContacts.push(
            this.formBuilder.group({
                id: this.formBuilder.control(null),
                unitId: this.formBuilder.control(null),
                unitName: this.formBuilder.control(null),
                unitEmail: this.formBuilder.control(null, [Validators.email]),
                unitTitle: this.formBuilder.control(null),
                unitTelephone: this.formBuilder.control(null),
                unitFax: this.formBuilder.control(null),
                contactName: this.formBuilder.control(null),
                departmentName: this.formBuilder.control(null)
            })
        );
    }

    removeContact(i: number) {
        this.facilityContacts.removeAt(i);
    }
}
