import { FacilitySystem } from 'src/app/facilities/models/facility-system.model';
import { LoadingTypes } from 'src/app/shared/models';
import { SystemColorTheme } from '../../models/system-color-theme.model';

export interface FacilitySystemsProfileState {
    loadingState: LoadingTypes;
    systemId: number;
    facilitySystem: FacilitySystem;
    logo: Blob;
    colorTheme: SystemColorTheme | null;
}

export const getInitialFacilitySystemsProfileState = (): FacilitySystemsProfileState => ({
    loadingState: LoadingTypes.INIT,
    systemId: null,
    facilitySystem: undefined,
    logo: null,
    colorTheme: null
});
