import { invoicesListReducer } from './invoices-list.reducer';
import { invoicesLookupsReducer } from './invoices-lookups.reducer';
import { invoicesQueryReducer } from './invoices-query.reducer';
import { invoicesDocumentReducer } from './invoices-document';
import { invoicesExportReducer } from './invoices-export';
import { invoiceDetailsReducer } from './invoice-details.reducer';
import { approveDraftInvoicesReducer } from './approve-draft-invoice.reducer';
import { invoiceAttachmentsReducer } from './invoice-attachments.reducer';
import { invoiceAttachmentsEnhancedReducer } from './invoice-attachments-enhanced.reducer';

export const invoicesReducer = {
    list: invoicesListReducer,
    query: invoicesQueryReducer,
    lookups: invoicesLookupsReducer,
    document: invoicesDocumentReducer,
    export: invoicesExportReducer,
    details: invoiceDetailsReducer,
    attachments: invoiceAttachmentsReducer,
    attachmentsEnhanced: invoiceAttachmentsEnhancedReducer,
    approveDraft: approveDraftInvoicesReducer
};
