import { createAction, props } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { FileType } from 'src/app/shared/models/candidate';

export const loadVendorDetailFiles = createAction(
    '[VENDOR DETAIL FILES COMPONENT] Load Vendor Detail Files',
    props<{ vendorId: number }>()
);
export const loadVendorDetailFilesSuccess = createAction(
    '[VENDOR DETAIL FILES API] Load Vendor Detail Files Success',
    props<{ vendorFiles: GridDataResult; vendorId: number }>()
);
export const loadVendorDetailFilesFailure = createAction(
    '[VENDOR DETAIL FILES API] Load Vendor Detail Files Failure',
    props<{ error: any }>()
);
export const deleteVendorDetailFiles = createAction(
    '[VENDOR DETAIL FILES COMPONENT] Delete Vendor Detail Files',
    props<{ vendorId: number; fileIds: number[] }>()
);
export const deleteVendorDetailFilesSuccess = createAction(
    '[VENDOR DETAIL FILES API] Delete Vendor Detail Files Success',
    props<{ success: boolean; vendorId: number }>()
);
export const deleteVendorDetailFilesFailure = createAction(
    '[VENDOR DETAIL FILES API] Delete Vendor Detail Files Failure',
    props<{ error: any }>()
);
export const setVendorDetailFilesSearchQuery = createAction(
    '[VENDOR DETAIL FILES FILES COMPONENT] Set Search Query State',
    props<{ searchQuery: GridSearchQuery }>()
);

export const loadFileTypes = createAction('[VENDOR DETAIL FILES COMPONENT] Load File Types');
export const loadFileTypesSuccess = createAction(
    '[VENDOR DETAIL FILES API] Load File Types Success',
    props<{ fileTypes: FileType[] }>()
);
export const loadFileTypesFailure = createAction(
    '[VENDOR DETAIL FILES API] Load File Types Failure',
    props<{ error: any }>()
);
