export function memoize(fn: any, resolver?: (...args: Array<any>) => string) {
    const cache = {};

    return (...args: Array<any>) => {
        const n = resolver
            ? resolver.apply(this, args)
            : JSON.stringify(args);

        if (n in cache) {
            return cache[n];
        }

        cache[n] = fn.apply(this, args);
        return cache[n];
    };
}

export function memoized() {
    return (target, key, descriptor): PropertyDescriptor => {

        if (typeof descriptor.value !== 'function') {
            throw new Error('Memoization can be applied only to methods');
        }

        descriptor.value = memoize.call(target, descriptor.value);

        return descriptor;
    };
}
