import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { downloadBlob, interpolateParams } from 'src/app/core/utils';
import { renameKeys } from 'src/app/core/utils/rename-keys';
import { FacilitiesVendorStatusUpdate, HospitalSystemsVendorsGridItem } from 'src/app/facilities/models';
import { SortTypes } from 'src/app/shared/models';
import { FacilitiesAdminUrls } from 'src/app/shared/models/facilities/enums/facilities-admin-urls.enum';
import { PagingToken } from 'src/app/shared/models/paging-token';

@Injectable({
    providedIn: 'root'
})
export class FacilitySystemsService {
    sortField = 'sort.field';
    sortType = 'sort.type';
    constructor(private readonly _http: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    getFacilitySystemsVendors(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<PagingToken<HospitalSystemsVendorsGridItem[]>> {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj[this.sortField] = sortArgsObj['sortField'];
        sortArgsObj[this.sortType] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgsObj
        });

        return this._http.get<PagingToken<HospitalSystemsVendorsGridItem[]>>(
            `${this._settings.CORE}${FacilitiesAdminUrls.FACILITY_SYSTEMS_VENDORS_LIST}`,
            { params }
        );
    }

    getAllFacilitySystemsFacilities(
        pagination: {
            pageSize: number;
            skip: number;
        },
        filterArgs: {
            hospitalSystemId: number | null;
            statusId: number;
        }
    ): Observable<PagingToken<HospitalSystemsVendorsGridItem[]>> {
        const filterArgsObj = renameKeys(filterArgs, 'filter');

        const params = interpolateParams({
            ...pagination,
            ...filterArgsObj
        });

        return this._http.get<PagingToken<HospitalSystemsVendorsGridItem[]>>(
            `${this._settings.CORE}${FacilitiesAdminUrls.FACILITY_SYSTEMS_FACILITIES_LIST_ALL}`,
            { params }
        );
    }

    getFacilitySystemsFacilities(
        pagination: {
            pageSize: number;
            skip: number;
        },
        filterArgs: {
            facilityStatusIds: number[];
            vendorIds: number[];
            hospitalSystemId: number | null;
        }
    ): Observable<PagingToken<HospitalSystemsVendorsGridItem[]>> {
        const filterArgsObj = renameKeys(filterArgs, 'filter');

        const params = interpolateParams({
            ...pagination,
            ...filterArgsObj
        });

        return this._http.get<PagingToken<HospitalSystemsVendorsGridItem[]>>(
            `${this._settings.CORE}${FacilitiesAdminUrls.FACILITY_SYSTEMS_FACILITIES_LIST}`,
            { params }
        );
    }

    updateVendorFacilityStatus(params: FacilitiesVendorStatusUpdate): void {
        this._http
            .put(`${this._settings.CORE}${FacilitiesAdminUrls.VENDOR_FACILITIES_STATUSUPDATE}`, params)
            .subscribe();
    }

    updateVendorFacilityStatusAll(params: FacilitiesVendorStatusUpdate): void {
        this._http
            .put(`${this._settings.CORE}${FacilitiesAdminUrls.VENDOR_FACILITIES_STATUSUPDATE_ALL}`, {
                ...params
            })
            .subscribe();
    }

    exportAll(
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj[this.sortField] = sortArgsObj['sortField'];
        sortArgsObj[this.sortType] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...sortArgsObj,
            ...matchArgsObj
        });

        return this._http.get(`${this._settings.CORE}${FacilitiesAdminUrls.FACILITY_SYSTEMS_EXPORT}`, {
            params,
            responseType: 'blob'
        });
    }

    exportSelected(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj[this.sortField] = sortArgsObj['sortField'];
        sortArgsObj[this.sortType] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...sortArgsObj,
            ...matchArgsObj,
            ...pagination
        });

        return this._http
            .get(`${this._settings.CORE}${FacilitiesAdminUrls.FACILITY_SYSTEMS_EXPORT_SELECTED}`, {
                params,
                responseType: 'blob'
            })
            .pipe(
                tap((resultBlob: Blob) => {
                    downloadBlob(resultBlob, 'vendors.xlsx');
                })
            );
    }
}
