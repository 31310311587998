import { Pipe, PipeTransform } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';

@Pipe({
    name: 'multipleDayjsDatesFormat'
})
export class MultipleDayjsDatesFormatPipe implements PipeTransform {
    private readonly dateFormat = 'MM/DD/YYYY';
    transform(dates: Dayjs | Dayjs[], format = '%min - %max (%count selected)', rangeSize = 5): any {
        if (!dates) {
            return dates;
        }

        dates = Array.isArray(dates) ? dates : [dates];

        if (rangeSize && dates.length >= rangeSize) {
            const max = Math.max.apply(
                null,
                dates.map((d) => d.valueOf())
            );
            const min = Math.min.apply(
                null,
                dates.map((d) => d.valueOf())
            );

            return format
                .replace('%min', dayjs(min).format(this.dateFormat))
                .replace('%max', dayjs(max).format(this.dateFormat))
                .replace('%count', String(dates.length));
        }

        return dates.map((date) => date.format(this.dateFormat)).join(', ');
    }
}
