import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { adminClientReducers } from 'src/app/admin-clients/store/reducers/admin-clients.reducers';
import { getInitialAdminClientsState } from 'src/app/admin-clients/store/state/admin-clients.state';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { facilitiesReducers } from 'src/app/facilities/store/reducers/facilities.reducers';
import { getInitialFacilitiesState } from 'src/app/facilities/store/state/facilities.state';
import { vendorCandidateListReducer } from 'src/app/admin/store/reducers/admin.vendor-candidates-list.reducers';
import { vendorProfileReducer } from 'src/app/admin/store/reducers/vendor-profile.reducers';
import { vendorCandidateDetailFileReducer } from 'src/app/admin/store/reducers/vendor-candidate-detail-files.reducers';
import { vendorFacilitiesReducer } from 'src/app/admin/store/reducers/vendor-facilities.reducers';
import { adminSubmittalsReducer } from 'src/app/admin/store/reducers/submittals.reducers';
import { vendorNotesReducer } from 'src/app/admin/store/reducers/vendor-notes.reducers';
import { facilityAssociationVendorsReducer } from 'src/app/facilities/store/reducers/association-vendors.reducers';
import { adminJobsReducer } from 'src/app/admin/store/reducers/admin-jobs.reducers';
import { adminVendorProfileLocationsReducer } from 'src/app/admin/store/reducers/vendor-locations.reducers';
import { adminVendorProfileCertificationsReducer } from 'src/app/admin/store/reducers/vendor-profile-certifications.reducers';
import { facilitySystemsVendorsReducer } from 'src/app/admin/store/reducers/facility-systems.reducers';
import { vendorDetailFileReducer } from 'src/app/admin/store/reducers/vendor-detail-files.reducers';
import { adminSystemContactsProfilesReducer } from 'src/app/admin/store/reducers/system-contacts-profile.reducers';
import { adminSubmittalsDetailReducer } from './submittals-detail.reducers';

export const adminReducers: ActionReducerMap<AdminState, any> = {
    routerState: routerReducer,
    facilitiesState: combineReducers(facilitiesReducers, getInitialFacilitiesState()),
    vendorCandidatesState: vendorCandidateListReducer,
    vendorProfileState: vendorProfileReducer,
    vendorCandidateFileState: vendorCandidateDetailFileReducer,
    vendorFacilitiesState: vendorFacilitiesReducer,
    submittalsState: adminSubmittalsReducer,
    submittalsDetailState: adminSubmittalsDetailReducer,
    vendorNotesState: vendorNotesReducer,
    adminClientsState: combineReducers(adminClientReducers, getInitialAdminClientsState()),
    associationVendors: facilityAssociationVendorsReducer,
    adminJobsState: adminJobsReducer,
    vendorProfileCertificationsState: adminVendorProfileCertificationsReducer,
    locationsState: adminVendorProfileLocationsReducer,
    facilitySystemsState: facilitySystemsVendorsReducer,
    vendorFileState: vendorDetailFileReducer,
    systemContactsState: adminSystemContactsProfilesReducer
};
