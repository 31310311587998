import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { map, Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateParams, interpolateUrl } from 'src/app/core/utils';
import { ExpenseHistory } from 'src/app/shared/models/expenses/expense-history.model';
import { deserializeDates } from 'src/app/shared/utilities/date-serializer';
import { FacilityExpenseCaps } from '../models/facility-expense-caps.model';
import { FacilityExpenseCapsUrls } from '../models/facility-expense-urls.enum';
import { VendorExpenseDetails } from '../models/vendor-expense-approved';
import { VendorExpensesUrls } from '../models/vendor-expenses-urls.enum';
import {
    ExpenseTypeDto,
    ExpenseUpdateRequest,
    FacilityProfileLookupDto,
    ProviderLookUpDto,
    VendorAddExpenseRequestDto,
    VendorExpense,
    VendorExpenseTimecardDto
} from '../models/vendor-expenses.model';
import { GridSearchQuery } from '../../shared/grid/models';
import { PagingToken } from '../../shared/models/paging-token';
import { FilterDescriptor } from '@progress/kendo-data-query';

@Injectable({
    providedIn: 'root'
})
export class VendorExpensesService {
    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly settings: Settings) {
        dayjs.extend(utc);
    }

    getExpenses(query: GridSearchQuery): Observable<PagingToken<VendorExpense[]>> {
        let url = VendorExpensesUrls.VENDOR_EXPENSE_LIST;
        url += `?PageSize=${query.take}&Skip=${query.skip}&Paginated=true`;

        query.filter?.filters?.forEach((f: FilterDescriptor) => {
            switch (f.field) {
                case 'statusId':
                    url += `&Filter.StatusId=${f.value}`;
                    break;
                case 'expenseTypeId':
                    url += `&Filter.ExpenseTypeId=${f.value}`;
                    break;
                case 'facilityId':
                    url += `&Filter.FacilityId=${f.value}`;
                    break;
                case 'name':
                    url += `&Filter.ProviderName=${encodeURIComponent(f.value)}`;
                    break;
                default:
                    break;
            }
        });

        if (query.sort?.length) {
            query.sort.forEach((sort) => {
                url += `&Sort.Type=${sort.dir}&Sort.Field=${sort.field}`;
            });
        }

        return this.http.get<PagingToken<VendorExpense[]>>(`${this.settings.CORE}${url}`);
    }

    getExpenseDetails(expenseId: number): Observable<VendorExpenseDetails> {
        const urlParams = {
            expenseId
        };
        const url = interpolateUrl(VendorExpensesUrls.VENDOR_EXPENSE_DETAILS, urlParams);
        return this.http.get<VendorExpenseDetails>(`${this.settings.CORE}${url}`).pipe(map((e) => deserializeDates(e)));
    }

    deleteExpense(expenseId: number): Observable<void> {
        const params = { expenseId };
        const url = interpolateUrl(VendorExpensesUrls.VENDOR_EXPENSE_DELETE, params);
        return this.http.delete<void>(`${this.settings.CORE}${url}`);
    }

    getExpenseHistory(expenseId: number): Observable<ExpenseHistory[]> {
        const urlParams = {
            expenseId
        };
        const url = interpolateUrl(VendorExpensesUrls.CANDIDATE_EXPENSE_HISTORY, urlParams);
        return this.http.get<ExpenseHistory[]>(`${this.settings.CORE}${url}`).pipe(map((ehs) => deserializeDates(ehs)));
    }

    addExpense(expense: VendorAddExpenseRequestDto): Observable<any> {
        const url = `${this.settings.CORE}${VendorExpensesUrls.ADD_EXPENSE}`;
        return this.http.post<VendorAddExpenseRequestDto>(url, this.getFormData(expense));
    }

    editExpense(expense: ExpenseUpdateRequest): Observable<any> {
        const url = interpolateUrl(`${this.settings.CORE}${VendorExpensesUrls.EDIT_EXPENSE}`, {
            expenseId: expense.expenseId
        });
        return this.http.put<ExpenseUpdateRequest>(url, this.getFormData(expense));
    }

    getFacilityExpenseCap(facilityId: number, paymentTypeId: number): Observable<FacilityExpenseCaps> {
        const urlParams = {
            facilityId,
            paymentTypeId
        };
        const url = interpolateUrl(FacilityExpenseCapsUrls.FACILITY_EXPENSE_CAP, urlParams);
        return this.http.get<FacilityExpenseCaps>(`${this.settings.CORE}${url}`);
    }

    getAllFacilityExpenseCaps(facilityId: number): Observable<FacilityExpenseCaps[]> {
        const urlParams = {
            facilityId
        };
        const url = interpolateUrl(FacilityExpenseCapsUrls.FACILITY_EXPENSE_CAPS, urlParams);
        return this.http.get<FacilityExpenseCaps[]>(`${this.settings.CORE}${url}`);
    }

    getTimecardAndUnitIds(values: {
        providerId: number;
        facilityId: number;
        date: Dayjs;
    }): Observable<VendorExpenseTimecardDto> {
        const urlParams = interpolateParams({
            providerId: values.providerId,
            facilityId: values.facilityId,
            date: values.date.toJSON()
        });
        return this.http.get<VendorExpenseTimecardDto>(
            `${this.settings.CORE}${VendorExpensesUrls.GET_EXPENSE_TIMECARDID_AND_UNITID}`,
            { params: urlParams }
        );
    }

    getFormData(object: any, formData: FormData = new FormData()): FormData {
        Object.keys(object).forEach((key) => {
            const value = object[key];
            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    this.formDataAppend(formData, key, item, index);
                });
            } else {
                this.formDataAppend(formData, key, value);
            }
        });

        return formData;
    }

    formDataAppend(formData: FormData, key: string, value: any, index?: number) {
        if (value === null || value === undefined) {
            return;
        }

        if (dayjs.isDayjs(value)) {
            formData.append(key, value.toISOString());
        } else if (value instanceof File) {
            formData.append(key, value, value.name);
        } else if (typeof value === 'object') {
            Object.entries(value).forEach(([itemKey, itemValue]) => {
                const nestedKey = index !== undefined ? `${key}[${index}][${itemKey}]` : `${key}[${itemKey}]`;
                this.formDataAppend(formData, nestedKey, itemValue, index);
            });
        } else {
            formData.append(key, value);
        }
    }

    getProviders() {
        return this.http.get<ProviderLookUpDto[]>(`${this.settings.CORE}${VendorExpensesUrls.GET_PROVIDERS}`);
    }

    getProviderFacilities(providerId: number) {
        const url = interpolateUrl(`${this.settings.CORE}${VendorExpensesUrls.GET_PROVIDER_FACILITIES}`, {
            providerId
        });
        return this.http.get<FacilityProfileLookupDto[]>(url);
    }

    getVendorFacilities() {
        const url = `${this.settings.CORE}${VendorExpensesUrls.GET_VENDOR_FACILITIES}`;
        return this.http.get<FacilityProfileLookupDto[]>(url);
    }

    getExpenseTypes() {
        const url = `${this.settings.CORE}${VendorExpensesUrls.GET_EXPENSE_TYPES}`;
        return this.http.get<ExpenseTypeDto[]>(url);
    }
}
