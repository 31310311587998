import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { enableSystemFormFieldsValidator, UnsubscribeOnDestroy } from 'src/app/core/utils';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import { emptyValidator, minAgeValidator, nameValidator } from 'src/app/shared/utilities';
import { InternalPoolService } from 'src/app/internal-pool/internal-pool.service';
import * as internalPoolSelectors from 'src/app/internal-pool/store/selectors';
import * as internalPoolActions from 'src/app/internal-pool/store/actions';
import { getSystemFieldsByModule } from 'src/app/core/state';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { phoneValidator } from 'src/app/shared/utilities/phone-validator';
import { emailExtendedValidator } from 'src/app/shared/utilities/email-extended-validator';

@Component({
    selector: 'ayac-personal-details-section',
    templateUrl: './personal-details-section.component.html',
    styleUrls: ['./personal-details-section.component.scss']
})
export class PersonalDetailsSectionComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
    resource$: Observable<Resource>;
    updateForm$: Observable<any>;
    isNyuSystem$: Observable<boolean>;

    form: UntypedFormGroup;

    @Input('edit')
    editMode = false;

    phoneMask = '(999) 999-9999';
    ssnMask = '999-99-9999';
    minDob = new Date('1900-01-01');
    removeNyuRequiredFields$: Observable<boolean>;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly store: Store<{}>,
        private readonly internalPoolService: InternalPoolService,
        private readonly featureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit() {
        this.form = this.fb.group({
            id: [0],
            systemId: [''],
            firstName: ['', Validators.compose([Validators.required, nameValidator])],
            middleName: ['', nameValidator],
            lastName: ['', Validators.compose([Validators.required, nameValidator])],
            employeeId: [''],
            kerberosId: [{ value: '', disabled: true }, Validators.required],
            hireDate: [null],
            transferDate: [null],
            phone: ['', Validators.compose([Validators.required, Validators.maxLength(10), phoneValidator])],
            email: ['', Validators.compose([Validators.required, emailExtendedValidator]), [this.usernameValidator()]],
            ssn: [{ value: '', disabled: true }, [Validators.pattern('^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$')]],
            dob: [{ value: '', disabled: true }, [Validators.required]],
            relatedPersons: this.fb.array([this.createRelatedPerson()])
        });

        this.resource$ = this.store.select(internalPoolSelectors.selectCurrentResource);
        this.isNyuSystem$ = this.store.select(internalPoolSelectors.selectIsNyuSystem);

        this.updateForm$ = combineLatest([this.resource$, this.store.select(internalPoolSelectors.selectSystemId)])
            .pipe(filter(([resource]) => resource != null))
            .pipe(
                tap(([resource, systemId, isNyuSystem]) => {
                    if (Array.isArray(resource.relatedPersons)) {
                        this.relatedPersons.clear();
                        this.onAddRelatedPerson(resource.relatedPersons.length || 1);
                    }
                    const dob = resource.dob ? new Date(resource.dob) : null;
                    const hireDate = resource.hireDate ? new Date(resource.hireDate) : null;
                    const transferDate = resource.transferDate ? new Date(resource.transferDate) : null;

                    this.form.markAsPristine();
                    this.form.patchValue({
                        ...resource,
                        dob,
                        hireDate,
                        transferDate
                    });
                })
            );

        this.store.pipe(select(getSystemFieldsByModule('internalPool')), takeUntil(this.d$)).subscribe((fields) => {
            this.form.setValidators([enableSystemFormFieldsValidator(fields)]);
            this.form.updateValueAndValidity();
        });

        this.removeNyuRequiredFields$ = this.isNyuSystem$.pipe(takeUntil(this.d$));

        this.removeNyuRequiredFields$.subscribe((removeNyuRequiredFields) => {
            if (removeNyuRequiredFields) {
                this.form
                    .get('employeeId')
                    .setValidators([Validators.maxLength(14), Validators.pattern('^[A-Za-z0-9]{1,14}$')]);
                this.form.get('employeeId').updateValueAndValidity();
                this.form.get('phone').setValidators([Validators.maxLength(10), phoneValidator]);
                this.form.get('phone').updateValueAndValidity();
                this.form.get('kerberosId').setValidators(null);
                this.form.get('kerberosId').setErrors(null);
            }
        });
    }

    ngAfterViewInit() {
        if (this.form.contains('dob')) {
            // To override MatDatepicker validators.
            this.form
                .get('dob')
                .setValidators(Validators.compose([emptyValidator(), Validators.required, minAgeValidator(18)]));
        }
    }

    createRelatedPerson(): UntypedFormGroup {
        return this.fb.group({
            name: '',
            title: '',
            department: ''
        });
    }

    get relatedPersons() {
        return this.form.get('relatedPersons') as UntypedFormArray;
    }

    onAddRelatedPerson(count = 1) {
        for (; count > 0; count--) {
            this.relatedPersons.push(this.createRelatedPerson());
        }
        this.form.markAsDirty();
    }

    onRemoveRelatedPerson(i: number) {
        this.relatedPersons.removeAt(i);
        this.form.markAsDirty();
    }

    private usernameValidator(): ValidatorFn {
        const subject = new BehaviorSubject('');
        const debouncedInput$ = subject.asObservable().pipe(
            distinctUntilChanged(),
            debounceTime(300),
            switchMap((email) => {
                this.store.dispatch(internalPoolActions.checkResourceUsername({ email }));
                return this.store.select(internalPoolSelectors.selectResourceUsername).pipe(
                    filter((username) => username != null),
                    map((_) => null)
                );
            }),
            first()
        );

        return (control: AbstractControl) => {
            const resourceId = this.form.get('id').value;
            const email = control.value;

            if (resourceId > 0) {
                return of(null);
            }

            subject.next(email);
            return debouncedInput$;
        };
    }
}
