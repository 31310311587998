import {
    Directive,
    Input,
    HostBinding,
} from '@angular/core';

@Directive({ selector: '[textHighlight]' })
export class TextHighlightDirective {
    @Input()
    set search(value) {
        if (value) {
            this.setBackgroundColor(value);
        }
    }
    @Input()
    get elemValue(): any { return this._elemValue }
    set elemValue(value) {
        this._elemValue = value;
    }

    @HostBinding('class') customMatchClass: string = '';
    private _elemValue ='';
    constructor() {}

    setBackgroundColor(search) {
        if (search) {
            const value = this.elemValue;
            const newValue = isNaN(value)
            ? value.toString().toUpperCase()
            : value.toString();
            const newSearch = isNaN(search)
            ? search.toString().toUpperCase()
            : search.toString();

            this.customMatchClass = newValue.indexOf(newSearch) > -1 ? 'customMatchClass' : '';
        } else {
            this.customMatchClass = '';
        }
    }
}
