import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { downloadBlob } from 'src/app/core/utils';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import * as actions from 'src/app/admin/store/actions/facility-systems.actions';
import { SortTypes } from 'src/app/shared/models';
import * as selectors from 'src/app/admin/store/selectors/facility-systems.selectors';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { mapFilters } from 'src/app/shared/grid/utils/map-filters';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { FacilitiesService } from 'src/app/facilities/services/facilities.service';
import { FacilitySystemsService } from '../../facility-systems/facility-systems.service';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

@Injectable()
export class FacilitySystemsVendorsEffects {
    loadFacilitySystemsVendors$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadFacilitySystemsVendors, actions.setFacilitySystemsVendorsSearchQuery),
            withLatestFrom(this._store$.select(selectors.selectFacilitySystemsVendorsSearchQuery)),
            switchMap(([action, query]) => {
                const filters = this.setFilters(query, action.hospitalSystemId, action.isActive);
                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };
                const sortArgs = {
                    sortField: query.sort && query.sort[0].field,
                    sortType: query.sort && (query.sort[0].dir as SortTypes)
                };
                const matchArgs = flattenFilter({ ...query.filter, filters });

                return this._facilitySystemsService.getFacilitySystemsVendors(pagination, sortArgs, matchArgs).pipe(
                    map((res) =>
                        actions.loadFacilitySystemsVendorsSuccess({
                            vendors: res.data,
                            total: res.total,
                            filtered: res.filtered
                        })
                    ),
                    catchError((err) => {
                        return of(actions.loadFacilitySystemsVendorsFailure({ error: err }));
                    })
                );
            })
        );
    });

    updateVendorFacilitiesStatus$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.updateFacilitySystemsVendorsStatus),
            exhaustMap((action) =>
                this._facilitiesService.updateVendorFacilitiesStatus(action.params).pipe(
                    map(() => actions.updateFacilitySystemsVendorsStatusSuccess()),
                    catchError((err) => {
                        return of(actions.updateFacilitySystemsVendorsStatusFailure({ error: err }));
                    })
                )
            )
        )
    );

    exportVendors$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.exportAllFacilitySystemsVendors),
            withLatestFrom(this._store$.select(selectors.selectFacilitySystemsVendorsSearchQuery)),
            switchMap(([action, query]) => {
                const filters = this.setFilters(query, action.hospitalSystemId, action.isActive);

                const sortArgs = {
                    sortField: query.sort && query.sort[0].field,
                    sortType: query.sort && (query.sort[0].dir as SortTypes)
                };

                const matchArgs = flattenFilter({ ...query.filter, filters });

                return this._facilitySystemsService.exportAll(sortArgs, matchArgs).pipe(
                    map((blob) => actions.exportAllFacilitySystemsVendorsSuccess({ blob })),
                    catchError((err) => {
                        return of(actions.exportAllFacilitySystemsVendorsFailure({ error: err }));
                    })
                );
            })
        )
    );

    exportSelected$ = createEffect((): any =>
        this._actions$.pipe(
            ofType(actions.exportSelectedFacilitySystemsVendors),
            withLatestFrom(this._store$.select(selectors.selectFacilitySystemsVendorsSearchQuery)),
            switchMap(([action, query]) => {
                const filters = this.setFilters(query, action.hospitalSystemId, action.isActive, action.vendorIds);
                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };
                const sortArgs = {
                    sortField: query.sort && query.sort[0].field,
                    sortType: query.sort && (query.sort[0].dir as SortTypes)
                };

                const matchArgs = flattenFilter({ ...query.filter, filters });
                return this._facilitySystemsService.exportSelected(pagination, sortArgs, matchArgs).pipe(
                    map(() => actions.exportSelectedFacilitySystemsVendorsSuccess()),
                    catchError((error) => of(actions.exportSelectedFacilitySystemsVendorsFailure({ error })))
                );
            })
        )
    );

    loadVendorListStates$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadVendorListStates),
            exhaustMap(() => {
                return this._lookupService.getStates().pipe(
                    map((states) => actions.loadVendorListStatesSuccess({ states })),
                    catchError((error) => of(actions.loadVendorListStatesFailure({ error })))
                );
            })
        )
    );

    exportAssociationVendorsAllSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.exportAllFacilitySystemsVendorsSuccess),
                map((action) => {
                    return downloadBlob(action.blob, 'vendors.xlsx');
                })
            ),
        { dispatch: false }
    );

    loadFacilityAssociationVendorsFailure$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.loadFacilitySystemsVendorsFailure),
                map(
                    (action: {
                        error: { message: string };
                        type: '[FACILITY SYSTEMS VENDORS API] Load Facility Systems Vendors Failure';
                    }) => {
                        return this._dialogService.openSnackBarError(action.error?.message);
                    }
                )
            ),
        { dispatch: false }
    );

    private setFilters(
        query: GridSearchQuery,
        hospitalSystemId: number,
        isActive: boolean,
        vendorIds?: number[]
    ): (CompositeFilterDescriptor | FilterDescriptor)[] {
        let filters =
            query?.filter && query?.filter?.filters
                ? mapFilters(query.filter.filters, {
                      status: 'statusId'
                  })
                : query?.filter?.filters;

        if (!filters) {
            filters = [];
        }

        filters.push({
            field: 'hospitalSystemId',
            operator: 'eq',
            value: hospitalSystemId
        });

        filters.push({
            field: 'isActiveForContractLine',
            operator: 'eq',
            value: isActive
        });

        if (vendorIds && vendorIds.length) {
            filters.push({
                field: 'vendorIds',
                operator: 'eq',
                value: vendorIds
            });
        }

        return filters;
    }

    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store<AdminState>,
        private readonly _dialogService: DialogService,
        private readonly _lookupService: LookupsService,
        private readonly _facilitiesService: FacilitiesService,
        private readonly _facilitySystemsService: FacilitySystemsService
    ) {}
}
