import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { loadInvoiceDocument, loadInvoiceDocumentFail, loadInvoiceDocumentSuccess } from '../invoices.actions';

export interface InvoicesDocumentState {
	loading: LoadingTypes
}

const initialState: InvoicesDocumentState = {
	loading: LoadingTypes.INIT
}

export function invoicesDocumentReducer(state: InvoicesDocumentState, action: Action) {
	return createReducer(
		initialState,
		on(loadInvoiceDocument, (state) => (
			{
				...state,
				loading: LoadingTypes.LOADING
			}
		)),

		on(loadInvoiceDocumentSuccess, (state) => (
			{
				...state,
				loading: LoadingTypes.LOADED
			}
		)),

		on(loadInvoiceDocumentFail, (state) => (
			{
				...state, 
				loading: LoadingTypes.LOADED
			}
		))
	)(state, action);
}

export function reducer(state: InvoicesDocumentState | undefined, action: Action) {
	return invoicesDocumentReducer(state, action);
}
