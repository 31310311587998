import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../services/dashboard.service';
import { withLatestFrom, catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    loadAwaitingClearanceSuccess,
    loadAwaitingClearanceFail,
    loadTravelers
} from 'src/app/dashboard-new/store/actions';
import { getFilter } from 'src/app/dashboard-new/store/selectors';

@Injectable()
export class AwaitingClearanceEffect {

    constructor(
        private actions$: Actions,
        private store$: Store<{}>,
        private service: DashboardService
    ) { }

    getAwaitingClearance$ = createEffect(() => this.actions$.pipe(
        ofType(loadTravelers),
        withLatestFrom(this.store$.select(getFilter)),
        switchMap(([action, query]) => {
            const filter = {
                lineOfBusinessId: query.lineOfBusiness.id > 0 ? query.lineOfBusiness.id : null,
                facilityId: query.facility.id > 0 ? query.facility.id : null,
                unitId: query.unit.id > 0 ? query.unit.id : null
            };

            return this.service.getAwaitingClearance(filter).pipe(
                map(response =>
                    loadAwaitingClearanceSuccess({
                        awaitingClearanceCount: response
                    })
                ),
                catchError((error) => of(
                    loadAwaitingClearanceFail({error})
                ))
            );
        })
    ));
}
