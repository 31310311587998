import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VendorWebsiteType } from 'src/app/admin/vendor-details/models/vendor-website-type.model';
import { VendorWebsite } from 'src/app/admin/vendor-details/models/vendor-website.model';
import { APP_CONFIG, Settings } from 'src/app/config/settings';

@Injectable({
    providedIn: 'root'
})
export class VendorProfileWebsiteService {
    constructor(
        private readonly _httpClient: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) { }

    getVendorProfileWebsiteTypes(): Observable<VendorWebsiteType[]> {
        return this._httpClient.get<VendorWebsiteType[]>(`${this._settings.CORE}/AyaConnect/admin/vendors/website-types`);
    }

    getVendorProfileWebsites(vendorId: number): Observable<VendorWebsite[]> {
        return this._httpClient.get<VendorWebsite[]>(`${this._settings.CORE}/ayaconnect/admin/vendors/${vendorId}/websites`);
    }

    addWebsite(site: VendorWebsite): Observable<unknown> {
        return this._httpClient.post(`${this._settings.CORE}/ayaconnect/admin/vendors/website`, site);
    }

    deleteWebsite(siteId: number): Observable<unknown> {
        return this._httpClient.delete(`${this._settings.CORE}/ayaconnect/admin/vendors/website/${siteId}`)
    }

    updateWebsite(site: VendorWebsite): Observable<unknown> {
        return this._httpClient.put(`${this._settings.CORE}/ayaconnect/admin/vendors/website`, site);
    }
}
