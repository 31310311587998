import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/association-vendors.actions';
import {
    FacilityAssociationVendorsState,
    getInitialFacilityAssociationVendorsState
} from 'src/app/facilities/store/state/association-vendors.state';
import { LoadingTypes } from 'src/app/shared/models';

export function facilityAssociationVendorsReducer(state: FacilityAssociationVendorsState | undefined, action: Action) {
    return createReducer(
        getInitialFacilityAssociationVendorsState(),

        on(actions.loadFacilityAssociationVendors, (state) => ({
            ...state,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.loadFacilityAssociationVendorsSuccess, (state, action) => {
            const vendors = action.vendors.map((item) => {
                return { ...item, id: `${item.vendorId}_${item.vendorState}_${item.vendorCity}` };
            });
            return {
                ...state,
                ...action,
                vendors,
                loadingState: LoadingTypes.LOADED
            };
        }),

        on(actions.loadFacilityAssociationVendorsFailure, (state) => ({
            ...state,
            loadingState: LoadingTypes.FAIL
        })),

        on(actions.setFacilityAssociationVendorsSearchQuery, (state, action) => ({
            ...state,
            gridSearchQuery: action.searchQuery,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.exportAssociationVendorsAll, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.exportAssociationVendorsAllSuccess, (state, action) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.exportAssociationVendorsAllFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.exportAssociationVendorsSelected, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.exportAssociationVendorsSelectedSuccess, (state, action) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.exportAssociationVendorsSelectedFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.loadVendorListStates, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.loadVendorListStatesSuccess, (state, action) => ({
            ...state,
            states: action.states,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.loadVendorListStatesFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL }))
    )(state, action);
}
