export enum DocumentRequirementStatus {
    Approved = 1,
    Missing = 2,
    Pending = 3,
    PendingApproval = 4,
    Expired = 5,
    ForReview = 6,
    Expiring = 7,
    Received = 8
}
