import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';
import { AdminState } from '../state/admin.state';
import { adminFeatureKey } from './admin.selectors';

export const selectSubmittalsDetailFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectSubmittalsDetail = createSelector(
    selectSubmittalsDetailFeature,
    (state) => state.submittalsDetailState.details
);

export const selectSubmittalsDetailIsLoading = createSelector(
    selectSubmittalsDetailFeature,
    (state) => state.submittalsDetailState.loading === LoadingTypes.LOADING
);

export const selectHasChanges = createSelector(
    selectSubmittalsDetailFeature,
    (state) => state.submittalsDetailState.hasChanges
);

export const selectIsSavingDetails = createSelector(
    selectSubmittalsDetailFeature,
    (state) => state.submittalsDetailState.loadingSave === LoadingTypes.LOADING
);

export const selectAvailableSubmittalStages = createSelector(selectSubmittalsDetailFeature, (state) => {
    const items: ListItem[] = [];

    state.submittalsDetailState.details?.currentStage?.postStages?.forEach((stage) =>
        items.push({ id: stage.submittalStageId, name: stage.name })
    );
    state.submittalsDetailState.details?.currentStage?.preStages?.forEach((stage) =>
        items.push({ id: stage.submittalStageId, name: stage.name })
    );

    return items;
});

export const selectSubmittalStages = createSelector(
    selectSubmittalsDetailFeature,
    (state) => state.submittalsDetailState.stages
);

export const selectSubmittalStageReasons = createSelector(
    selectSubmittalsDetailFeature,
    (state) => state.submittalsDetailState.stageReasons
);
