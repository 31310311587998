import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';
import {
    loadProfessions,
    loadProfessionsSuccess,
    loadProfessionsFail,
    componentLoaded
} from '../perdiemscheduler.actions';

export interface PerdiemSchedulerProfessionsState {
    list: ListItem[];
    loading: LoadingTypes;
}

export const getInitialPerdiemSchedulerProfessionsState = (): PerdiemSchedulerProfessionsState => ({
    list: [],
    loading: LoadingTypes.INIT
});

export function perdiemSchedulerProfessionsReducer(state: PerdiemSchedulerProfessionsState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerProfessionsState(),
        on(loadProfessions, componentLoaded, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadProfessionsSuccess, (state, action) => ({
            ...state,
            list: action.professions,
            loading: LoadingTypes.LOADED
        })),
        on(loadProfessionsFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: PerdiemSchedulerProfessionsState | undefined, action: Action) {
    return perdiemSchedulerProfessionsReducer(state, action);
}
