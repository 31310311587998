import { createAction, props } from '@ngrx/store';
import { ListItem } from 'src/app/shared/models/list-item';
import {
    SubmittalStageEntry,
    VendorSubmittalDetails
} from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';

export const loadSubmittalsDetails = createAction(
    '[ADMIN SUBMITTALS LOAD SUBMITTAL DETAILS] Load Admin Submittals Details',
    props<{ submittalId: number }>()
);

export const loadSubmittalsDetailsSuccess = createAction(
    '[ADMIN SUBMITTALS LOAD SUBMITTAL DETAILS SUCCESS] Admin Submittals Load Details Success',
    props<{ details: VendorSubmittalDetails }>()
);

export const loadSubmittalsDetailsFail = createAction(
    '[ADMIN SUBMITTALS LOAD SUBMITTAL DETAILS FAILURE] Admin Submittals Load Details Failure',
    props<{ error: any }>()
);

export const loadSubmittalStages = createAction('[ADMIN SUBMITTALS LOAD SUBMITTAL STAGES] Load Admin Submittal Stages');

export const loadSubmittalStagesSuccess = createAction(
    '[ADMIN SUBMITTALS LOAD SUBMITTAL STAGES SUCCESS] Admin Submittals Load Stages Success',
    props<{ stages: ListItem[] }>()
);

export const loadSubmittalStagesFail = createAction(
    '[ADMIN SUBMITTALS LOAD SUBMITTAL STAGES FAILURE] Admin Submittals Load Stages Failure',
    props<{ error: any }>()
);

export const loadSubmittalStageReasons = createAction(
    '[ADMIN SUBMITTALS LOAD SUBMITTAL STAGE REASONS] Load Admin Submittal Stage Reasons'
);

export const loadSubmittalStageReasonsSuccess = createAction(
    '[ADMIN SUBMITTALS LOAD SUBMITTAL STAGE REASONS SUCCESS] Admin Submittals Load Stage Reasons Success',
    props<{ stageReasons: ListItem[] }>()
);

export const loadSubmittalStageReasonsFail = createAction(
    '[ADMIN SUBMITTALS LOAD SUBMITTAL STAGE REASONS FAILURE] Admin Submittals Load Stage Reasons Failure',
    props<{ error: any }>()
);

export const setChangeState = createAction(
    '[ADMIN SUBMITTALS SET CHANGE STATE] Set Admin Submittals Change State',
    props<{ hasChanges: boolean }>()
);

export const updateSubmittalNote = createAction(
    '[ADMIN SUBMITTALS UPDATE SUBMITTAL NOTE] Update Admin Submittal Note',
    props<{ submittalId: number; note: string }>()
);

export const updateSubmittalNoteSuccess = createAction(
    '[ADMIN SUBMITTALS UPDATE SUBMITTAL NOTE SUCCESS] Update Admin Submittal Note Success',
    props<{ submittalId: number }>()
);

export const updateSubmittalNoteFailure = createAction(
    '[ADMIN SUBMITTALS UPDATE SUBMITTAL NOTE FAILURE] Update Admin Submittal Note Failure',
    props<{ error: any }>()
);

export const updateStageHistoryEntry = createAction(
    '[ADMIN SUBMITTALS UPDATE STAGE HISTORY ENTRY] Update Stage History Entry',
    props<{ entry: SubmittalStageEntry }>()
);

export const updateStageHistoryEntrySuccess = createAction(
    '[ADMIN SUBMITTALS UPDATE STAGE HISTORY ENTRY] Update Stage History Entry Success',
    props<{ submittalId: number }>()
);

export const updateStageHistoryEntryFailure = createAction(
    '[ADMIN SUBMITTALS UPDATE STAGE HISTORY ENTRY] Update Stage History Entry Failure',
    props<{ error: any }>()
);

export const clearSubmittalDetails = createAction(
    '[ADMIN SUBMITTALS CLEAR SUBMITTAL DETAILS] Clear Submittals Details'
);
