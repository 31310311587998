import { createAction, props } from '@ngrx/store';
import { ListItem } from 'src/app/shared/models/list-item';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { UnitLookup } from 'src/app/shared/models/lookups/unit-lookup.model';


export const loadSecurityLookups = createAction('[Dashboard component] Load Security Lookup');
export const loadSecurityLookupsSuccess = createAction(
    '[Dashboard API] Security Lookup Load Succeed',
    props<
    {
        systems: ListItem[],
        facilities: FacilityLookup[],
        units: UnitLookup[],
    }>());
export const loadSecurityLookupsFail = createAction('[Dashboard API] Security Lookup Load Failed', props<{ error: any }>());
