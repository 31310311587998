import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import * as internalPoolActions from 'src/app/internal-pool/store/actions';
import { ResourceAssignment } from '../../models';
import { GridSearchQuery } from 'src/app/shared/grid/models';
export interface ResourceAssignmentsState {
    previousAssignments: ResourceAssignment[];
    previousAssignmentsTotal: number;
    previousLoading: LoadingTypes;
    previousQuery: GridSearchQuery;

    currentAssignments: ResourceAssignment[];
    currentAssignmentsTotal: number;
    currentLoading: LoadingTypes;
    currentQuery: GridSearchQuery;
}

export const initialAssignmentsState: ResourceAssignmentsState = {
    previousAssignments: [],
    previousAssignmentsTotal: 0,
    previousLoading: LoadingTypes.INIT,
    previousQuery: {
        take: 25,
        sort: [{ field: 'startdate', dir: SortTypes.DESC }]
    },

    currentAssignments: [],
    currentAssignmentsTotal: 0,
    currentLoading: LoadingTypes.INIT,
    currentQuery: {
        take: 25,
        sort: [{ field: 'startdate', dir: SortTypes.DESC }]
    }
};

export function resourceAssignmentsReducer(state: ResourceAssignmentsState, action: Action) {
    return createReducer(
        initialAssignmentsState,
        on(internalPoolActions.loadPreviousAssignments, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(internalPoolActions.loadResourcePreviousAssignmentsSuccess, (state, action) => ({
            ...state,
            previousAssignments: action.assignments,
            previousAssignmentsTotal: action.total,
            previousLoading: LoadingTypes.LOADED
        })),

        on(internalPoolActions.loadPreviousResourceAssignmentsFail, (state, action) => ({
            ...state,
            previousLoading: LoadingTypes.FAIL
        })),
        on(internalPoolActions.loadCurrentResourceAssignments, (state, action) => ({
            ...state,
            currentLoading: LoadingTypes.LOADING
        })),
        on(internalPoolActions.loadCurrentResourceAssignmentsSuccess, (state, action) => ({
            ...state,
            currentAssignments: action.assignments,
            currentAssignmentsTotal: action.total,
            currentLoading: LoadingTypes.LOADED
        })),

        on(internalPoolActions.loadCurrentResourceAssignmentsFail, (state, action) => ({
            ...state,
            currentLoading: LoadingTypes.FAIL
        })),

        on(internalPoolActions.setPreviousAssignmentsQuery, (state, action) => ({
            ...state,
            previousQuery: {
                ...state.previousQuery,
                ...action.query
            }
        })),

        on(internalPoolActions.setCurrentAssignmentsQuery, (state, action) => ({
            ...state,
            currentQuery: {
                ...state.currentQuery,
                ...action.query
            }
        })),

        on(internalPoolActions.clearResourceAssignments, (state, action) => ({
            ...initialAssignmentsState
        }))
    )(state, action);
}

export function reducer(state: ResourceAssignmentsState | undefined, action: Action) {
    return resourceAssignmentsReducer(state, action);
}
