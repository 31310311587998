export enum TimecardsPayTypeEnum {
    REGULAR = 1,
    LUNCH = 2,
    ON_CALL = 3,
    CALL_BACK = 4,
    ORIENTATION = 5,
    CHARGE = 6,
    CALLED_OFF_FORCED = 7,
    CALLED_IN_VOLUNTARY = 8,
    MODULES = 9
}
