import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringList'
})
export class StringListPipe implements PipeTransform {

  transform(value: Array<string>, separator: string = ', '): string {
    if (!value || value.length === 0) {
      return '';
    }

    return value.join(separator);
  }

}
