import { createAction, props } from '@ngrx/store';
import { ShiftEditModel } from 'src/app/shared/models/shifts/shift-edit.model';
import { CancelByFacilityModel } from 'src/app/shared/models/shifts/cancel-by-facility.model';
import { ShiftDetails } from 'src/app/shared/models/shifts/shift-details.model';
import { InternalPoolShiftEditModel } from 'src/app/shared/models/internal-pool/internal-pool-shift-edit.model';
import { ShiftClientNotificationStatus } from 'src/app/shared/models/shifts/enums/shift-client-notification-status.enum';
import { FullShiftEditModel } from 'src/app/shared/models/shifts/full-shift-edit.model';

// Add/Update/Convert Internal Pool Shift
export const addInternalPoolShiftWithReload = createAction('[SHIFTS] Add Internal Pool Shift With Reload', props<{ shift: InternalPoolShiftEditModel }>());
export const addInternalPoolShift = createAction('[SHIFTS] Add Internal Pool Shift', props<{ shift: InternalPoolShiftEditModel }>());

export const updateInternalPoolShiftWithReload = createAction('[SHIFTS] Update Internal Pool Shift With Reload', props<{ shift: InternalPoolShiftEditModel }>());
export const updateInternalPoolShift = createAction('[SHIFTS] Update Internal Pool Shift', props<{ shift: InternalPoolShiftEditModel }>());

export const convertInternalPoolShift = createAction('[SHIFTS] Convert Internal Pool Shift', props<{ shift: InternalPoolShiftEditModel }>());
export const convertInternalPoolShiftWithReload = createAction('[SHIFTS] Convert Internal Pool Shift With Reload', props<{ shift: InternalPoolShiftEditModel }>());

// Add Shift
export const addShift = createAction('[CLIENT SHIFT ADD] Add Shift', props<{ shift: FullShiftEditModel, reload?: boolean }>());
export const addShiftWithReload = createAction('[CLIENT SHIFT ADD] Add Shift With Reload', props<{ shift: FullShiftEditModel }>());
export const addShiftSuccess = createAction('[CLIENT SHIFT ADD] Add Shift Success', props<{
    shift: ShiftDetails,
    assignedNotificationStatus: ShiftClientNotificationStatus | null
}>());
export const addShiftFail = createAction('[CLIENT SHIFT ADD] Add Shift Fail', props<{ error: any }>());

// Add Series
export const addSeries = createAction('[CLIENT SHIFT ADD] Add Series', props<{ shift: FullShiftEditModel, reload?: boolean }>());
export const addSeriesSuccess = createAction('[CLIENT SHIFT ADD] Add Series Success', props<{
    shiftId: number,
    seriesId: string,
    assignedNotificationStatus: ShiftClientNotificationStatus | null
}>());
export const addSeriesFail = createAction('[CLIENT SHIFT ADD] Add Series Fail', props<{ error: any }>());

// Add Shifts
export const addShifts = createAction('[CLIENT SHIFTS ADD] Add Shifts', props<{ shifts: ShiftEditModel[] }>());
export const addShiftsSuccess = createAction('[CLIENT SHIFTS ADD] Add Shifts Success', props<{ shifts: ShiftDetails[] }>());
export const addShiftsFail = createAction('[CLIENT SHIFTS ADD] Add Shifts Fail', props<{ error: any }>());
export const addShiftBulkWithReload = createAction('[CLIENT SHIFTS ADD] Add Shift Bulk With Reload', props<{ shifts: ShiftEditModel[] }>());

// Update/Convert Shift
// TODO: SHIFTS-1979 - updateShiftWithReload obsolete
export const updateShiftWithReload = createAction('[CLIENT SHIFTS UPDATE] Update Shift With Reload', props<{ shift: ShiftEditModel }>());
export const updateShift = createAction('[CLIENT SHIFTS UPDATE] Update Shift', props<{
    shift: ShiftEditModel,
    reload?: boolean
}>());

export const updateAndAssignShift = createAction(
    '[CLIENT SHIFTS UPDATE] Update And Assign Shift',
    props<{
        shift: InternalPoolShiftEditModel,
        reload?: boolean
    }>()
);
// TODO: SHIFTS-1979 - updateAndAssignShiftWithReload obsolete
export const updateAndAssignShiftWithReload = createAction('[CLIENT SHIFTS UPDATE] Update And Assign Shift With Reload', props<{ shift: InternalPoolShiftEditModel }>());
export const updateShiftFail = createAction('[CLIENT SHIFTS UPDATE] Update Shift Fail', props<{ error: any }>());
export const updateShiftSuccess = createAction('[CLIENT SHIFTS UPDATE] Update Shift Success', props<{
        shift: ShiftDetails,
        assignedNotificationStatus: ShiftClientNotificationStatus | null,
        cancelledNotificationStatus: ShiftClientNotificationStatus | null,
    }>());
    
export const convertShift = createAction('[CLIENT SHIFTS CONVERT] Convert Internal Pool Shift', props<{ shift: InternalPoolShiftEditModel, systemId: number }>());
export const convertShiftWithReload = createAction('[CLIENT SHIFTS CONVERT] Convert Internal Pool Shift With Reload',
                                         props<{ shift: InternalPoolShiftEditModel, systemId: number }>());

// Cancel Shift by Facility
export const cancelShiftByFacility = createAction('[SHIFTS] Cancel Shift By Facility', props<{ cancelByFacilityModel: CancelByFacilityModel}>());
export const cancelShiftByFacilitySuccess = createAction('[SHIFTS] Cancel Shift By Facility Success');
export const cancelShiftByFacilityFail = createAction('[SHIFTS] Cancel Shift By Facility Fail', props<{ error: any }>());


// Shift Assignment Notification
export const sendInternalPoolShiftAssignedNotification = createAction('[SHIFTS] Send Shift Assigned Notification', props<{ shiftId: number }>());
export const sendInternalPoolShiftAssignedNotificationSuccess = createAction('[SHIFTS] Send Shift Assigned Notification Success');
export const sendInternalPoolShiftAssignedNotificationFail = createAction('[SHIFTS] Send Shift Assigned Notification Fail', props<{ error: any }>());

// Reconfirm Shift
export const reconfirmShift = createAction('[SHIFTS] Reconfirm Shift', props<{ id: number}>());
export const reconfirmShiftSuccess = createAction('[SHIFTS] Reconfirm Shift success');
export const reconfirmShiftFail = createAction('[SHIFTS] Reconfirm Shift fail', props<{ error: any}>());

// Confirm Shift Series
export const confirmShiftSeries = createAction('[SHIFTS SERIES API] Confirm Shift', props<{ id: string}>());
// Confirm Shift

export const confirmShift = createAction('[SHIFTS API] Confirm Shift', props<{ id: number}>());
export const confirmShiftSuccess = createAction('[SHIFTS] Confirm Shift success');
export const confirmShiftFail = createAction('[SHIFTS] Confirm Shift fail', props<{ error: any}>());

// Reconfirm Internal Pool Shift
export const reconfirmInternalPoolShift = createAction('[SHIFTS] Reconfirm Internal Pool Shift', props<{ id: number}>());
export const reconfirmInternalPoolShiftSuccess = createAction('[SHIFTS] Reconfirm Internal Pool Shift success');
export const reconfirmInternalPoolShiftFail = createAction('[SHIFTS] Reconfirm Internal Pool Shift fail', props<{ error: any}>());

// Reopen Shift
export const reopenShift = createAction('[SHIFTS] Reopen Shift', props<{ id: number}>());
export const reopenShiftSuccess = createAction('[SHIFTS] Reopen Shift success');
export const reopenShiftFail = createAction('[SHIFTS] Reopen Shift fail', props<{ error: any }>());

// Approval Actions
export const approveShift = createAction('[SHIFTS] Approve Shift', props<{ ids: number[], seriesId?: string, comment?: string }>());
export const approveShiftSuccess = createAction('[SHIFTS] Approve Shift success');
export const approveShiftFail = createAction('[SHIFTS] Approve Shift fail', props<{ error: any }>());
export const rejectShift = createAction('[SHIFTS] Reject Shift', props<{ ids: number[], seriesId?: string, comment?: string }>());
export const rejectShiftSuccess = createAction('[SHIFTS] Reject Shift success');
export const rejectShiftFail = createAction('[SHIFTS] Reject Shift fail', props<{ error: any }>());

//Redirect Shift    
export const redirectShift = createAction('[SHIFTS] Redirect Shift',props<{ id: number, facilityId: number, unitId?: number,comments?: string  }>());
export const redirectShiftSuccess = createAction('[SHIFTS] Redirect Shift success');
export const redirectShiftFail = createAction('[SHIFTS] Redirect Shift Fail', props<{ error: any}>());

export const updateShiftSeries = createAction(
    '[CLIENT SHIFTS UPDATE] Update Shift Series',
    props<{ shift: ShiftEditModel, reload?: boolean }>()
);
export const updateShiftSeriesFail = createAction(
    '[CLIENT SHIFTS UPDATE] Update Shift Series Fail',
    props<{ error: any }>()
);
export const updateShiftSeriesSuccess = createAction(
    '[CLIENT SHIFTS UPDATE] Update Shift Series Success',
    props<{
        shift: ShiftDetails,
        assignedNotificationStatus: ShiftClientNotificationStatus | null,
        cancelledNotificationStatus: ShiftClientNotificationStatus | null,
    }>()
);

export const updateSingleShiftSeries = createAction(
    '[CLIENT SHIFTS SINGLE UPDATE API] Update Shift Series',
    props<{ shift: ShiftEditModel, reload?: boolean }>()
);
export const updateSingleShiftSeriesFail = createAction(
    '[CLIENT SHIFTS SINGLE UPDATE] Update Shift Series Fail',
    props<{ error: any }>()
);
export const updateSingleShiftSeriesSuccess = createAction(
    '[CLIENT SHIFTS SINGLE UPDATE] Update Shift Series Success',
    props<{
        shift: ShiftDetails,
        assignedNotificationStatus: ShiftClientNotificationStatus | null,
        cancelledNotificationStatus: ShiftClientNotificationStatus | null,
    }>()
);
