import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, pairwise, startWith, map } from 'rxjs/operators';
import { FacilitySettingsHelper } from '../../helpers/facility-settings-helper';
import {
    FeatureSettingChangesEvent,
    FeatureSettingItem,
    FeatureInputTypes
} from '../../models/feature-features.models';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'ayac-feature-settings-presentation',
    templateUrl: './feature-settings-presentation.component.html',
    styleUrls: ['./feature-settings-presentation.component.scss']
})
export class FeatureSettingsPresentationComponent extends UnsubscribeOnDestroy implements OnDestroy, OnChanges {
    FeatureInputTypes = FeatureInputTypes;
    @Input() features: FeatureSettingItem[];
    @Input() categoryName: string;
    @Output() featureChanges = new EventEmitter<FeatureSettingChangesEvent>();
    clear$ = new Subject();
    changes$ = new Subject();
    form: UntypedFormGroup;

    constructor() {
        super();
    }

    ngOnDestroy(): void {
        this.clear$.next(null);
        this.clear$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.features) {
            this.features = FacilitySettingsHelper.prepareSettings(changes.features.currentValue);
        }
        this.initForm();
    }

    onFormChanges(changes) {
        for (const controlName in changes) {
            if (this.form.get(controlName).invalid) {
                this.form.get(controlName).markAsTouched();
                continue;
            }

            const feature = this.findFeature(this.features, controlName);
            const featureName = feature.name;
            const featureNameEnabled = this.featureNameEnabled(featureName);

            const update = {
                value: this.form.get(featureName).value,
                isEnabled: this.form.get(featureNameEnabled).value
            };

            this.featureChanges.emit({
                previous: feature,
                current: {
                    ...feature,
                    ...update
                }
            });
        }
    }

    initForm() {
        this.clear$.next(null);
        this.form = new UntypedFormGroup({});

        this.addFormFeatures(this.features);

        this.form.valueChanges
            .pipe(
                takeUntil(this.clear$),
                startWith(this.form.value),
                debounceTime(500),
                pairwise(),
                map(([prev, cur]) => this.compareChanges(cur, prev))
            )
            .subscribe(this.onFormChanges.bind(this));
    }

    private addFormFeatures(features: FeatureSettingItem[]) {
        if (!Array.isArray(features)) {
            return;
        }
        for (const feature of features) {
            this.addFormFeature(feature);

            if (feature.childFeatures) {
                this.addFormFeatures(feature.childFeatures);
            }
        }
    }

    private addFormFeature(feature: FeatureSettingItem) {
        const control = new UntypedFormControl();
        const controlEnabled = new UntypedFormControl(feature.isEnabled);
        const validators = [];

        switch (feature.valueTypeId) {
            case FeatureInputTypes.DECIMAL:
            case FeatureInputTypes.INTEGER:
                control.setValue(feature.value);
                validators.push(Validators.required);
                if (feature.min != null) {
                    validators.push(Validators.min(feature.min));
                }
                if (feature.max != null) {
                    validators.push(Validators.max(feature.max));
                }
                break;
            case FeatureInputTypes.BOOLEAN:
                control.setValue(feature.value);
                break;
            case FeatureInputTypes.DATETIME:
                control.setValue(feature.value);
                break;
            case FeatureInputTypes.TOGGLE:
                control.setValue(true);
                break;
            case FeatureInputTypes.STRING:
                control.setValue(feature.value);
            default:
                control.setValue(feature.value);
                validators.push(Validators.required);
                if (feature.min != null) {
                    validators.push(Validators.minLength(feature.min));
                }
                if (feature.max != null) {
                    validators.push(Validators.maxLength(feature.max));
                }
                break;
        }

        if (validators.length > 0) {
            control.setValidators(validators);
        }

        if (feature.canEdit === false) {
            control.disable();
        }

        this.form.addControl(feature.name, control);
        this.form.addControl(this.featureNameEnabled(feature.name), controlEnabled);

        this.checkAndAssignValidationToFields(feature, control, controlEnabled);

        control.updateValueAndValidity();
    }

    private checkAndAssignValidationToFields(
        feature: FeatureSettingItem,
        control: UntypedFormControl,
        controlEnabled: UntypedFormControl
    ) {
        if (!feature.isEnabled) {
            control.disable();
        }

        controlEnabled.valueChanges
            .pipe(takeUntil(this.clear$))
            .subscribe((value) => this.onControlEnabledChanged(value, control, feature));
    }

    featureNameEnabled(featureName: string): string {
        return `${featureName}__enabled`;
    }
    featureNameLabel(featureName: string): string {
        return `${featureName}__enabled-input`;
    }

    generateQaAttribute(name: string, suffix: string) {
        return `${name.replace(/ /g, '')}${suffix}`;
    }

    private compareChanges(cur: Object, prev: Object): Object {
        const changes = {};
        for (const field in cur) {
            if (prev[field] === undefined) {
                continue;
            }

            if (cur[field] !== prev[field]) {
                changes[field] = cur[field];
            }
        }
        return changes;
    }

    private findFeature(features: FeatureSettingItem[], featureName: string): FeatureSettingItem | null {
        for (const feature of features) {
            if (feature.name === featureName) {
                return feature;
            }
            if (this.featureNameEnabled(feature.name) === featureName) {
                return feature;
            }
            if (feature.childFeatures) {
                const child = this.findFeature(feature.childFeatures, featureName);
                if (child) {
                    return child;
                }
            }
        }
        return null;
    }

    private onControlEnabledChanged(value: boolean, control: UntypedFormControl, feature: FeatureSettingItem) {
        switch (feature.valueTypeId) {
            case FeatureInputTypes.DECIMAL:
            case FeatureInputTypes.INTEGER:
                if (control.value < feature.min || !value) {
                    control.setValue(feature.min);
                }
                break;
        }
        if (value) {
            control.enable();
        } else {
            control.disable();
        }
    }
}
