import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions/vendor-candidate-profile.acitons';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors/vendor-candidate-profile.selectors';

@Component({
    selector: 'ayac-candidate-shifts-app',
    templateUrl: './candidate-shifts-app.component.html',
    styleUrls: ['./candidate-shifts-app.component.scss']
})
export class CandidateShiftsAppComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() candidateId: number;
    @Output() onChangeMade = new EventEmitter();

    inviteSent$: Observable<string | null>;
    sendEmailLoading$: Observable<boolean>;
    isCredentialed$: Observable<boolean>;
    hasShiftsAppAccess$: Observable<boolean>;
    displayWarning$: Observable<boolean>;
    disableSendButton$: Observable<boolean>;

    constructor(
        private readonly store: Store
    ) { super(); }

    ngOnInit(): void {
        if (this.candidateId > 0) {
            this.store.dispatch(actions.loadVendorCandidateShiftsApp({ candidateId: this.candidateId }));
        }

        this.inviteSent$ = this.store.select(selectors.selectVendorCandidateShiftsAppInviteSent);
        this.sendEmailLoading$ = this.store.select(selectors.selectVendorCandidateSendEmailLoading);
        this.isCredentialed$ = this.store.select(selectors.selectVendorCandidateIsCredentialed);
        this.hasShiftsAppAccess$ = this.store.select(selectors.selectVendorCandidateHasAccessShiftsApp);

        this.displayWarning$ = combineLatest([this.hasShiftsAppAccess$, this.isCredentialed$]).pipe(
            map(([access, credential]) => {
                if(access){
                    return !credential;
                }
                return true;
            })
        );

        this.disableSendButton$ = combineLatest([this.sendEmailLoading$, this.hasShiftsAppAccess$, this.isCredentialed$]).pipe(
            map(([loading, access, credential]) => {
                if (loading) {
                    return loading;
                } else {
                    if(access){
                        return !credential;
                    }
                    return true;
                }
            })
        );
    }

    async onSend() {
        this.store.dispatch(actions.sendInviteVendorСandidateShiftsAppConfirmation({
            candidateId: this.candidateId
        }));
    }
}
