import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ayaLeadsStoreKey } from './aya-leads.state';
import { ayaLeadsReducer } from './reducers';
import { ayaLeadsEffects } from './effects';

@NgModule({
    imports: [StoreModule.forFeature(ayaLeadsStoreKey, ayaLeadsReducer), EffectsModule.forFeature(ayaLeadsEffects)]
})
export class AyaLeadsStoreModule {}
