import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-auto-open-menu',
  templateUrl: './auto-open-menu-component.html',
})
export class AutoOpenMenuComponent {
  timedOutCloser;
  
  /** 
   * Set the delay in miliseconds, when the popup menu will be closed
   * after mouse moved out of the trigger control
   **/
  @Input() closeDelay = 50;

  constructor() { }

  mouseEnter(trigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, this.closeDelay);
  }
}
