import { Component, OnInit } from '@angular/core';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { HelpService } from './help.service';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
import { EMPTY, Observable } from "rxjs";

@Component({
    selector: 'show-form',
    templateUrl: './show-form.component.html',
    styleUrls: ['./show-form.component.scss']
})
export class ShowFormComponent extends UnsubscribeOnDestroy implements OnInit {
    isLoading: boolean = true;
    blob$: Observable<string> = new Observable<string>();
    error: string = '';

    constructor(
        private readonly _helpService: HelpService,
        private readonly _route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        const fileName = this._route.snapshot.queryParams['fileName'];
        const fileExtension = fileName?.split('.').pop();

        if (fileExtension?.toLowerCase() === 'pdf') {
            this.blob$ = this._helpService.downloadBlobFile(fileName).pipe(
                takeUntil(this.d$),
                map((blob) => window.URL.createObjectURL(blob)),
                tap(() => this.isLoading = false),
                catchError(_ => {
                    this.isLoading = false;
                    this.error = 'File not found';
                    return EMPTY;
                })
            );
        }
        else{
            this.isLoading = false;
            this.error = 'File type not supported';
        }
    }

}
