/** angular */
import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { MatExpansionPanel } from '@angular/material/expansion';

/**ngrx and rxjs */
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, map, pairwise, takeUntil, tap } from 'rxjs/operators';

/**shared */
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { State } from 'src/app/shared/grid/models/state.model';

/**local */
import { LocationType } from 'src/app/admin/vendor-details/profile/models/location-type.model';
import * as selectors from 'src/app/admin/store/selectors/vendor-locations.selectors';
import * as actions from 'src/app/admin/store/actions/vendor-locations.actions';
import { VendorLocation } from 'src/app/admin/vendor-details/profile/models/vendor-location.model';

@Component({
    selector: 'ayac-vendor-locations',
    templateUrl: './vendor-locations.component.html',
    styleUrls: ['./vendor-locations.component.scss']
})
export class VendorLocationsComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
    @Input() set vendorId(id: number) {
        if (id) {
            this._vendorId = id;

            if (!this._isSaving) {
                if (this.locationsFormArray) {
                    this.locationsFormArray.clear();
                }

                this._store.dispatch(actions.loadVendorProfileLocations({ vendorId: id }));
            }
        } else {
            this._store.dispatch(actions.resetVendorLocations());
        }
    }

    get vendorId(): number {
        return this._vendorId;
    }

    @Input()
    set isSaving(saving: boolean) {
        this._isSaving = saving;
        if (saving) {
            this.expansionPanels.forEach((item) => {
                item.expanded = false;
            });

            this.locationsFormArray.clear();
        }
    }

    @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;

    private _vendorId = 0;
    private _isSaving = false;

    locationsForm: UntypedFormGroup;
    vendorLocations$: Observable<VendorLocation[]>;
    locationTypes: LocationType[];
    locationTypes$: Observable<LocationType[]>;

    statesLookup$: Observable<State[]>;

    get locationsFormArray(): UntypedFormArray {
        return this.locationsForm?.get('locations') as UntypedFormArray;
    }

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly _store: Store,
        private readonly lookupService: LookupsService
    ) {
        super();
    }

    ngOnInit(): void {
        this.locationsForm = this.formBuilder.group({
            locations: this.formBuilder.array([])
        });
        this.statesLookup$ = this.lookupService.getStates();
        this._store
            .select(selectors.selectLocationTypes)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                this.locationTypes = result;
            });

        this.locationTypes$ = this._store.select(selectors.selectLocationTypes);

        this.vendorLocations$ = this._store.select(selectors.selectVendorProfileLocations).pipe(
            tap((locations) => {
                if (this.locationsFormArray.length === 0) {
                    this.locationsFormArray.clear();
                    locations.forEach((location) => this.addLocation(location));
                }
            })
        );

        this._store.dispatch(actions.loadLocationTypes());
    }

    ngAfterViewInit(): void {
        this.locationsForm
            .get('locations')
            .valueChanges.pipe(
                pairwise(),
                map(([oldState, newState]) => {
                    let changes = {};
                    for (const key in newState) {
                        if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
                            changes[key] = newState[key];
                        }
                    }
                    return changes;
                }),
                filter((changes) => Object.keys(changes).length !== 0),
                takeUntil(this.d$)
            )
            .subscribe((changes) => {
                const locations: Partial<
                    {
                        id: number;
                        vendorLocationTypeId: number;
                        address1: string;
                        address2: string;
                        city: string;
                        zipCode: string;
                        stateId?: number;
                        note: string;
                    }[]
                > = Object.values(changes);
                if (this.locationsForm.controls.locations.invalid) {
                    this._store.dispatch(actions.invalidVendorProfileLocations());
                } else {
                    this._store.dispatch(actions.validVendorProfileLocations());
                    if (locations && locations.length > 0) {
                        locations.forEach((item) => {
                            this._store.dispatch(
                                actions.updateVendorProfileLocation({
                                    id: item.id,
                                    vendorLocationTypeId: item.vendorLocationTypeId,
                                    address1: item.address1,
                                    address2: item.address2,
                                    city: item.city,
                                    zipCode: item.zipCode,
                                    stateId: item.stateId,
                                    note: item.note ?? ''
                                })
                            );
                        });
                    }
                }
            });
    }

    addLocation(location?: VendorLocation) {
        const locationForm = this.formBuilder.group({
            id: this.formBuilder.control(location?.id ?? 0),
            vendorLocationTypeId: this.formBuilder.control(location?.vendorLocationTypeId ?? 0, Validators.required),
            address1: this.formBuilder.control(location?.address1, Validators.required),
            address2: this.formBuilder.control(location?.address2),
            city: this.formBuilder.control(location?.city, Validators.required),
            stateId: this.formBuilder.control(location?.stateId ?? 0, Validators.required),
            zipCode: this.formBuilder.control(location?.zipCode, [
                Validators.required,
                Validators.pattern('^[0-9]{5}$')
            ]),
            note: this.formBuilder.control(location?.note)
        });
        this.locationsFormArray.push(locationForm);
    }

    removeLocation(index: number): void {
        const locationId = this.locationsFormArray.at(index).get('id').value;
        this.locationsFormArray.removeAt(index);
        this._store.dispatch(actions.deleteVendorProfileLocation({ locationId }));
    }

    addNewLocation(): void {
        this.addLocation();
        const location: VendorLocation = {
            id: 0,
            vendorId: this.vendorId,
            vendorLocationTypeId: 0,
            address1: '',
            address2: '',
            city: undefined,
            stateId: 0,
            zipCode: undefined,
            note: undefined,
            isDeleted: false
        };
        this._store.dispatch(actions.addVendorProfileLocation({ location: location }));
    }

    getLocationTypeName(locationTypeId: number): string {
        const type = this.locationTypes.filter((x) => x.id === locationTypeId)[0];
        return type ? type.name : null;
    }
}
