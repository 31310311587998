import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FileAttachmentPreview, FileAttachmentsPreviewComponent } from 'src/app/shared/components/file-attachments-preview/file-attachments-preview.component';
import { FileAttachmentsComponent } from 'src/app/shared/components/file-attachments/file-attachments.component';
import { AttachmentConfig, FileAttachment } from 'src/app/shared/models/attachment';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import * as internalPoolSelectors from '../store/selectors';
import * as internalPoolActions from '../store/actions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ayac-documents-section',
  templateUrl: './documents-section.component.html',
  styleUrls: ['./documents-section.component.scss']
})
export class DocumentsSectionComponent implements OnInit {

    @Input() edit: boolean;

    resource$: Observable<Resource>;
    attachmentConfig = new AttachmentConfig({
        attachmentTitle: "",
        hasTemplate: false,
        viewableByRadioButton: true,
        attachmentRequired: false,
        attachmentNotes: '',
        defaultInternalOnly: true,
        hiddenNotes: true
    });
    loading$: Observable<boolean>;
    preview$: Observable<FileAttachmentPreview>;  
    attachments$: Observable<any>;
    currentFile$: Observable<FileAttachment>;

    @ViewChild(FileAttachmentsComponent)
    public attachmentsComponent: FileAttachmentsComponent;

    @ViewChild(FileAttachmentsPreviewComponent, {static: true})
    public previewComponent: FileAttachmentsPreviewComponent;

    constructor(
        private readonly store: Store<{}>,
        private readonly route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.resource$ = this.store.select(internalPoolSelectors.selectCurrentResource);
        this.attachments$  = this.store.select(internalPoolSelectors.selectDocuments); 
        this.loading$  = this.store.select(internalPoolSelectors.selectDocumentsLoading); 
        this.preview$  = this.store.select(internalPoolSelectors.selectDocumentPreview); 
        this.currentFile$  = this.store.select(internalPoolSelectors.selectDocumentCurrent); 
    }

    attachmentToUpload(file) {
        this.store.dispatch(internalPoolActions.saveResourceDocument({
            resourceId: this.resourceId,
            fileToUpload: file,
            internalOnly: file.internalOnly
        }))
    }

    onDelete(file) {
        this.store.dispatch(internalPoolActions.deleteResourceDocument({
            resourceId: this.resourceId,
            id: file.id
        }))
    }

    attachmentToPreview(file: FileAttachment): void  {
        this.store.dispatch(internalPoolActions.loadPreviewResourceDocuments({
            resourceId: this.resourceId,
            id: file.id,
            file: file,
            pageNum: 0
        }))

        this.store.dispatch(internalPoolActions.loadPreviewResourcePageCount({
            resourceId: this.resourceId,
            id: file.id
        }))

        this.previewComponent.showDialog(file);
    }

    attachmentToUpdate(event){
        this.store.dispatch(internalPoolActions.updateResourceDocument({
            resourceId: this.resourceId,
            fileToUpdate: event.file,
            internalOnly: event.internalOnly
        }))
    }

    onPageChange(page: number) {
        this.store.dispatch(internalPoolActions.loadPreviewNextPage({
            pageNum: page
        }))
    }

    onDownload(file: FileAttachment) {
        this.store.dispatch(internalPoolActions.loadResourceDocument({
            resourceId: this.resourceId,
            id: file.id,
            fileName: file.fileName
        }))
    }

    onClose() {
        this.store.dispatch(internalPoolActions.clearResourceDocumentPreview())
    }

    get resourceId() {
        return Number(this.route.snapshot.paramMap.get('id')) || 0;
    }
}
