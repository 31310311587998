import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { PerdiemSchedulerService } from '../../services/perdiemscheduler.service';
import {
    loadProfessions,
    loadProfessionsSuccess,
    loadProfessionsFail,
    componentLoaded
} from '../perdiemscheduler.actions';

@Injectable()
export class PerdiemSchedulerProfessionsEffect extends BaseEffect {
    getProfessionList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadProfessions, componentLoaded),
            switchMap(() =>
                this.shiftRepository.getNurseProfessions().pipe(
                    map((response) =>
                        loadProfessionsSuccess({
                            professions: response
                        })
                    ),
                    catchError((error: unknown) => of(loadProfessionsFail({ error })))
                )
            )
        );
    });

    getProfessionsListFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadProfessionsFail),
                map((action) => action.error),
                tap((error: HttpErrorResponse) => this.handleError(error))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly shiftRepository: PerdiemSchedulerService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
