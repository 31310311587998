import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { Store } from '@ngrx/store';
import { AyaLeadsListSelectors } from '../selectors';
import { AyaLeadsListActions } from '../actions';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { AyaLeadsService } from 'src/app/aya-leads/services/aya-leads.service';
import { AyaLeadsListItem } from 'src/app/aya-leads/models/aya-leads-list-item';
import { GridSearchQuery } from 'src/app/shared/grid/models';

@Injectable()
export class AyaLeadsListResultsEffect extends BaseEffect {
    initialAyaLeadsLoad$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsListActions.loadAyaLeadsPage),
            concatLatestFrom(() => this.store.select(AyaLeadsListSelectors.selectSubmittalQuery)),
            switchMap(
                ([, query]: [
                    TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.LoadAyaLeadsPage>,
                    GridSearchQuery
                ]) => {
                    return this.handleAyaLeadsApiResponse(query);
                }
            )
        );
    });

    updateAyaLeadsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsListActions.updateAyaLeadsResults),
            concatLatestFrom(() => this.store.select(AyaLeadsListSelectors.selectSubmittalQuery)),
            switchMap(
                ([, query]: [
                    {
                        query: GridSearchQuery;
                    } & TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.UpdateAyaLeadsResults>,
                    GridSearchQuery
                ]) => {
                    return this.handleAyaLeadsApiResponse(query);
                }
            )
        );
    });

    setAyaLeadsGridFilterStateAndUpdateResults$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsListActions.setAyaLeadsGridFilterStateAndUpdateResults),
            concatLatestFrom(() => this.store.select(AyaLeadsListSelectors.selectSubmittalQuery)),
            switchMap(
                ([, query]: [
                    {
                        query: GridSearchQuery;
                    } & TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.SetAyaLeadsGridFilterStateAndUpdateResults>,
                    GridSearchQuery
                ]): Observable<
                    | ({
                          ayaLeads: AyaLeadsListItem[];
                          total: number;
                      } & TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.GetAyaLeadsSuccess>)
                    | TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.GetAyaLeadsFail>
                > => {
                    return this.handleAyaLeadsApiResponse(query);
                }
            )
        );
    });

    getAyaLeadsExport$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AyaLeadsListActions.exportAyaLeads),
            concatLatestFrom(() => this.store.select(AyaLeadsListSelectors.selectSubmittalQuery)),
            switchMap(
                ([action, query]: [
                    { submittalIds: number[] } & TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.Export>,
                    GridSearchQuery
                ]) => {
                    return this.ayaLeadsService.export(query, action).pipe(
                        map((response) => {
                            this.saveFile(response);
                            return AyaLeadsListActions.exportAyaLeadsSuccess();
                        }),
                        catchError((error: unknown) => {
                            this.handleError(error as any);
                            return of(AyaLeadsListActions.exportAyaLeadsFail());
                        })
                    );
                }
            )
        );
    });

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        toasterService: ToasterService,
        private readonly ayaLeadsService: AyaLeadsService
    ) {
        super(toasterService);
    }

    private handleAyaLeadsApiResponse(query: GridSearchQuery): Observable<
        | ({
              ayaLeads: AyaLeadsListItem[];
              total: number;
          } & TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.GetAyaLeadsSuccess>)
        | TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.GetAyaLeadsFail>
    > {
        const request$ = this.ayaLeadsService.getAyaLeads(query);

        return request$.pipe(
            map((response) => {
                if (response) {
                    return AyaLeadsListActions.loadAyaLeadsSuccess({
                        ayaLeads: response.data,
                        total: response.total
                    });
                } else {
                    this.handleStringError('Unable to load results. Please try again.');
                    return AyaLeadsListActions.loadAyaLeadsFail();
                }
            }),
            catchError((error: unknown) => {
                this.handleError(error as any);
                return of(AyaLeadsListActions.loadAyaLeadsFail());
            })
        );
    }
}
