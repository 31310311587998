import { AdminClientDetailState, getInitialAdminClientDetailState } from './admin-client-detail.state';
import { AdminClientsListState, getInitialAdminClientsListState } from './admin-clients-list.state';

export interface AdminClientsState {
    list: AdminClientsListState;
    detail: AdminClientDetailState;
}

export const getInitialAdminClientsState = (): AdminClientsState => ({
    list: getInitialAdminClientsListState(),
    detail: getInitialAdminClientDetailState()
});
