import { Component, ContentChildren, QueryList, OnDestroy, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItemDirective } from './menu-item.directive';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlags } from 'src/app/shared/models/enums/feature-flag.enum';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'side-nav-menu',
    templateUrl: './side-nav-menu.component.html',
    styleUrls: ['./side-nav-menu.component.scss'],
})
export class SideNavMenuComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() isExpanded: boolean;
    @ContentChildren(MenuItemDirective)
    menuItems: QueryList<MenuItemDirective>;

    currentUrl: string = '';
    currentUrlAfterRedirects: string = '';

    openedModule: string;
    activeModule: string;
    activeModuleItem: string;
    menuModules: MenuItemDirective[];
    ffMenuActive: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly featureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                takeUntil(this.d$),
                filter(e => e instanceof NavigationEnd),
                map((event: NavigationEnd) => event.url),
                startWith(this.router.url)
            )
            .subscribe((url) => {
                this.currentUrl = url;
            });

        this.router.events
            .pipe(
                takeUntil(this.d$),
                filter(e => e instanceof NavigationEnd),
                map((event: NavigationEnd) => event.urlAfterRedirects),
                startWith(this.router.url)
            )
            .subscribe((url) => {
                this.currentUrlAfterRedirects = url;
            });
    }

    openModule(moduleName: string) {
        if (moduleName === this.openedModule) {
            this.openedModule = null;
        }
        else {
            this.openedModule = moduleName;
        }
    }

    private urlStartsWithRoot(root: string, current: string): boolean {
        if (!root || !current) {
            return false;
        }

        const rootPath = root.split('/');
        const currentPath = current.split('/');

        return rootPath.every((p, i) => p.toLowerCase() === currentPath[i]?.toLowerCase());
    }

    isActive(item) {
        if (item.items) {
            for (const it of item.items) {
                if (this.urlStartsWithRoot(it.path, this.currentUrlAfterRedirects)) {
                    return true;
                }
            }
        }

        return this.urlStartsWithRoot(item.path, this.currentUrlAfterRedirects);
    }
}
