import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map, startWith, switchMap } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { DomainService } from 'src/app/shared/services/domain.service';
import { IdentityService } from 'src/app/shared/services/identity.service';

@Component({
    selector: 'ayac-app-top-branding',
    templateUrl: './app-top-branding.component.html',
    styleUrls: ['./app-top-branding.component.scss']
})
export class AppTopBrandingComponent extends UnsubscribeOnDestroy implements OnInit {
    routerChanges$: Observable<string>;
    showShifts$: Observable<boolean>;

    constructor(
        private readonly identity: IdentityService,
        private readonly domain: DomainService,
        private readonly router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.routerChanges$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event: NavigationEnd) => event.url),
            startWith(this.router.url)
        );

        this.showShifts$ = this.routerChanges$.pipe(
            map((url) => {
                const isShiftsUrl =
                    url.includes('client/perdiemscheduler') ||
                    url.includes('client/shiftsschedule') ||
                    url.includes('client/internalpool') ||
                    url.includes('client/perdiempool') ||
                    url.includes('client/perdiemscheduler/approvals') ||
                    url.includes('client/shiftsschedule/approvals');

                const hasShiftsPermissions =
                    this.identity.inScope('client', 'perdiemscheduler') ||
                    this.identity.inScope('client', 'internalpool') ||
                    this.identity.inScope('client', 'perdiempool') ||
                    this.identity.inScope('client', 'shifts_approvalprocesses_view');

                const isShiftsEnv = this.domain.isEnvironment('SHFT');

                return isShiftsUrl && hasShiftsPermissions && !isShiftsEnv;
            })
        );
    }
}
