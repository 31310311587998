import { VendorNoteType } from 'src/app/admin/vendor-details/models/vendor-note-type.model';
import { VendorNote } from 'src/app/admin/vendor-details/models/vendor-note.model'
import { LoadingTypes } from 'src/app/shared/models';

export interface VendorNotesState {
    loading: LoadingTypes;
    notes: VendorNote[];
    noteTypes: VendorNoteType[];
}

export const getInitialVendorNotesState = (): VendorNotesState => ({
    loading: LoadingTypes.INIT,
    notes: [],
    noteTypes: []
})
