import { Component, Input, Output, QueryList, ViewChildren, AfterViewInit, EventEmitter } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { FacilityFeaturePermission, FacilityFeaturePermissionCategory } from '../../models/facility-feature.model';
import { FeatureConfirmationText } from '../../models/feature-confirmation-text.model';

@Component({
    selector: 'ayac-features-permissions-presentation',
    templateUrl: './features-permissions-presentation.component.html',
    styleUrls: ['./features-permissions-presentation.component.scss']
})
export class FeaturesPermissionsPresentationComponent implements AfterViewInit {
    @Input() categories: FacilityFeaturePermissionCategory[];
    @Input() showTotals = false;
    @Input() totalFacilities = 0;
    @Input() confirmationTexts: FeatureConfirmationText[];
    @Input() featureLevel = 'Facility';
    @Output() permissionUpdate = new EventEmitter<any>();
    @ViewChildren('featureCheckbox') featureCheckboxes: QueryList<MatCheckbox>;

    constructor(private readonly dialogService: DialogService) {}

    ngAfterViewInit() {}

    updatePermission(feature: FacilityFeaturePermission, isEnabled: boolean): void {
        const confirmationRequired = this.confirmationTexts.find((ct) => ct.id === feature.id);
        if (confirmationRequired) {
            this.showConfirmationDialog(feature, isEnabled, confirmationRequired);
        } else {
            this.permissionUpdate.emit({ featureId: feature.id, isEnabled, featureName: feature.name });
        }
    }

    private showConfirmationDialog(
        feature: FacilityFeaturePermission,
        isEnabled: boolean,
        configuration: FeatureConfirmationText
    ) {
        const confirmationText = isEnabled ? configuration.enabledText : configuration.disabledText;
        const confirmButtonText = isEnabled ? 'Enable' : 'Disable';
        const dialogRef = this.dialogService.openDialog(ConfirmationDialogComponent, {
            width: '800px',
            data: {
                title: `${this.featureLevel} Feature: ${feature.name}`,
                text: confirmationText,
                isHtml: true,
                confirmButtonText
            }
        } as IConfirmationDialogOptions);

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.permissionUpdate.emit({ featureId: feature.id, isEnabled, featureName: feature.name });
            } else {
                const checkbox = this.featureCheckboxes.find((f) => Number(f.id) === feature.id);
                if (checkbox) {
                    checkbox.checked = !isEnabled;
                }
            }
        });
    }
}
