import { Action, createReducer, on } from '@ngrx/store';
import { facilitiesActions } from 'src/app/facilities/store/actions';
import { LoadingTypes } from 'src/app/shared/models';
import {
    ContractGroupFeaturesState,
    getContractGroupFeaturesState
} from 'src/app/facilities/store/state/contract-group-permissions.state';

export function contractGroupPermissionsReducer(state: ContractGroupFeaturesState | undefined, action: Action) {
    return createReducer(
        getContractGroupFeaturesState(),

        on(facilitiesActions.contractGroupPermissionsActions.loadGroupFeature, (state) => ({
            ...state,
            loadingState: LoadingTypes.LOADING
        })),

        on(facilitiesActions.contractGroupPermissionsActions.loadGroupFeatureSuccess, (state, action) => ({
            ...state,
            groupFeatures: action.groupFeatures,
            notifications: action.groupFeatures.connectNotifications,
            totalFacilities: action.groupFeatures.totalFacilities,
            permissionCategories: action.groupFeatures.connectPermissions,
            error: '',
            loadingState: LoadingTypes.LOADED
        })),

        on(facilitiesActions.contractGroupPermissionsActions.loadGroupFeatureFailure, (state, action) => ({
            ...state,
            error: action.error,
            loadingState: LoadingTypes.FAIL
        }))
    )(state, action);
}
