import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { PermJobsGridQueryState } from 'src/app/root-store/perm-jobs/reducers/perm-jobs-grid-query.reducer';
import { PermJobsUrls } from '../models/perm-jobs-url.enum';
import { PermJobsGridResponse } from '../models/perm-jobs-grid.response';
import { interpolateFetchArguments2 } from 'src/app/core/utils/interpolate-fetch-arguments2';

@Injectable({
    providedIn: 'root'
})
export class PermanentJobService {
    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    getFilteredPermJobs(query: PermJobsGridQueryState): Observable<PermJobsGridResponse> {
        const queryString = interpolateFetchArguments2(query);
        const url = `${this._settings.CORE}${PermJobsUrls.PermJobsFiltered}`;

        return this.http.get<PermJobsGridResponse>(`${url}?${queryString}`);
    }
}
