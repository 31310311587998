import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Unit, UnitFacilityInformation } from 'src/app/clinical/models';
import { ProgramTypeEnum } from 'src/app/clinical/models/program-type.enum';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-other-information',
    templateUrl: './other-information.component.html',
    styleUrls: ['./other-information.component.scss']
})
export class OtherInformationComponent extends UnsubscribeOnDestroy implements OnDestroy, OnChanges {
    @Output() valueChanged = new EventEmitter<{ unit: Partial<Unit>; isValid: boolean }>();

    @Input() readOnly = false;
    @Input() isPrinting: boolean = false;

    _unitInfo: UnitFacilityInformation;

    @Input()
    set unitInfo(unitInfo: UnitFacilityInformation) {
        this._unitInfo = unitInfo;
        this.isLoading = true;
        this._unit = unitInfo?.unit;
        this.setFormValues(this._unit);
        this.isLoading = false;
        this.watchFormChanges();
    }

    otherInformationForm: UntypedFormGroup;

    isLoading = false;

    programTypeEnum = ProgramTypeEnum;

    private _unit: Unit;

    constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _store: Store) {
        super();

        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.otherInformationForm, changes.readOnly.currentValue);
        }
    }

    buildForm(): void {
        this.otherInformationForm = this._formBuilder.group({
            unitAcceptsFirstTimeTraveler: this._formBuilder.control(''),
            unitAcceptsFirstTimeTravelerOther: this._formBuilder.control('', [Validators.maxLength(50)]),
            orientationHours: this._formBuilder.control(
                0,
                Validators.compose([Validators.min(0), Validators.max(10000), Validators.maxLength(5)])
            ),
            orientationInformation: this._formBuilder.control('', [Validators.maxLength(500)]),
            scrubsProvided: this._formBuilder.control(null),
            scrubsColor: this._formBuilder.control('', [Validators.maxLength(500)]),
            preApprovedTimeOffPerContract: this._formBuilder.control(null),
            programType: this._formBuilder.control(null),
            otherInformation: this._formBuilder.control('', [Validators.maxLength(500)])
        });
    }
    setFormValues(unit: Unit) {
        if (unit) {
            this.otherInformationForm.get('unitAcceptsFirstTimeTraveler').patchValue(unit.unitAcceptsFirstTimeTraveler);
            this.otherInformationForm
                .get('unitAcceptsFirstTimeTravelerOther')
                .patchValue(unit.unitAcceptsFirstTimeTravelerOther);
            this.otherInformationForm.get('orientationHours').patchValue(unit.orientationHours);
            this.otherInformationForm.get('orientationInformation').patchValue(unit.orientationInformation);
            this.otherInformationForm.get('scrubsProvided').patchValue(unit.scrubsProvided);
            this.otherInformationForm.get('scrubsColor').patchValue(unit.scrubsColor);
            this.otherInformationForm
                .get('preApprovedTimeOffPerContract')
                .patchValue(unit.preApprovedTimeOffPerContract);
            this.otherInformationForm.get('programType').patchValue(unit.programType);
            this.otherInformationForm.get('otherInformation').patchValue(unit.otherInformation);
        }
    }

    getFormValues(): Partial<Unit> {
        const unit: Partial<Unit> = {
            unitAcceptsFirstTimeTraveler: this.otherInformationForm.get('unitAcceptsFirstTimeTraveler').value,
            unitAcceptsFirstTimeTravelerOther: this.otherInformationForm.get('unitAcceptsFirstTimeTravelerOther').value,
            orientationHours: this.otherInformationForm.get('orientationHours').value,
            orientationInformation: this.otherInformationForm.get('orientationInformation').value,
            scrubsProvided: this.otherInformationForm.get('scrubsProvided').value,
            scrubsColor: this.otherInformationForm.get('scrubsColor').value,
            preApprovedTimeOffPerContract: this.otherInformationForm.get('preApprovedTimeOffPerContract').value,
            programType: this.otherInformationForm.get('programType').value,
            otherInformation: this.otherInformationForm.get('otherInformation').value
        };

        return unit;
    }

    watchFormChanges(): void {
        this.otherInformationForm.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            if (!this.isLoading) {
                this.valueChanged.emit({ unit: this.getFormValues(), isValid: this.otherInformationForm.valid });
            }
        });
    }
}
