import { Injectable } from '@angular/core';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import { ResourceList } from 'src/app/shared/models/internal-pool/resource-list.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { PreferredShiftDialogComponent } from '../preferred-shift-dialog/preferred-shift-dialog.component';
import { Observable } from 'rxjs';
import { PreferredShift } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ResourcesService {

    constructor(
        private readonly dialog: DialogService
    ){}

    getStatus(resource: Resource | ResourceList) : 'application' | 'invited'  {
        if (!resource) {
            return null
        }
        switch (true) {
            case resource.lastAppActivityDate != null:
                return 'application';
            case resource.appInviteEmailSentDate != null:
                return 'invited';
            default:
                return null;
        }
    }

    showCancelDialog() {
        return this.dialog.openConfirmationDialog({
            data: {
                title: "Unsaved Changes Will Be Lost",
                text: "You have unsaved changes on this page. If you leave before saving, your changes will be lost."
            }
        } as IConfirmationDialogOptions);
    }

    showPreferredShiftDialog(data: PreferredShift[]): Observable<PreferredShift[]> {
        const dialog = this.dialog.openDialog(
            PreferredShiftDialogComponent,
            {
                data: {
                    preferredShifts: data
                }
            }
        )

        return dialog.afterClosed();
    }
}
