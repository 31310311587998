import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateParams } from 'src/app/core/utils';
import { GridSearchRequest } from 'src/app/shared/grid/models/grid-search-request.model';
import { PagingToken } from 'src/app//shared/models/paging-token';
import { AdminClientsSearchFilter } from 'src/app/admin-clients/models/admin-clients-search-filter.model';
import { AdminClientListItem } from 'src/app/admin-clients/models/admin-clients.model';
import {
    AdminClientFacilityProfileGroup,
    AdminClientFacilities
} from 'src/app/admin-clients/models/admin-clients-facilities.model';
import { AdminClientsUrl } from 'src/app/admin-clients/models/admin-clients-url.enum';

@Injectable({
    providedIn: 'root'
})
export class AdminClientsService {
    constructor(private _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    getAdminClient(id: number) {
        const params = interpolateParams({ id, pageSize: 1, skip: 0, sortField: 'Id', sortType: 'asc' });
        return this._httpClient.get<PagingToken<AdminClientListItem[]>>(
            `${this._settings.CORE}${AdminClientsUrl.ADMIN_CLIENTS}`,
            { params }
        );
    }

    createAdminClient(legacyId: number) {
        return this._httpClient.post<number>(`${this._settings.CORE}${AdminClientsUrl.ADMIN_CLIENTS}`, {
            legacyId
        });
    }

    updateAdminClient(id: number, legacyId: number) {
        return this._httpClient.put(`${this._settings.CORE}${AdminClientsUrl.ADMIN_CLIENTS}`, {
            id,
            legacyId
        });
    }

    getAdminClients(request: GridSearchRequest<AdminClientsSearchFilter>) {
        const params = interpolateParams({
            id: request.filter.id,
            legacyId: request.filter.legacyId,
            name: request.filter.name,
            pageSize: request.pagination.pageSize,
            skip: request.pagination.skip,
            ...request.sortArgs
        });
        return this._httpClient.get<PagingToken<AdminClientListItem[]>>(
            `${this._settings.CORE}${AdminClientsUrl.ADMIN_CLIENTS}`,
            { params }
        );
    }

    getAdminClientsForExport(request: GridSearchRequest<AdminClientsSearchFilter>): Observable<Blob> {
        const params = interpolateParams({
            id: request.filter.id,
            legacyId: request.filter.legacyId,
            Name: request.filter.name,
            pageSize: request.pagination.pageSize,
            skip: request.pagination.skip,
            ...request.sortArgs
        });
        return this._httpClient.get(`${this._settings.CORE}${AdminClientsUrl.ADMIN_CLIENTS}/export`, {
            params,
            responseType: 'blob'
        });
    }

    getUnmappedFacilityProfileGroups(searchName?: string, limit?: number, clientId?: number) {
        return this._httpClient.get<AdminClientFacilities[]>(
            `${this._settings.CORE}${AdminClientsUrl.ADMIN_CLIENTS}/unmapped-profile-groups?namePattern=${searchName}&limit=${limit}&clientId=${clientId}`
        );
    }

    getFacilitiesForProfileGroup(profileGroupId: number) {
        if (profileGroupId != null) {
            return this._httpClient.get<AdminClientFacilityProfileGroup[]>(
                `${this._settings.CORE}${AdminClientsUrl.ADMIN_CLIENTS}/profile-groups/${profileGroupId}/facilities`
            );
        }
    }

    checkLegacyId(legacyId: number): Observable<boolean> {
        return this._httpClient.get<boolean>(
            `${this._settings.CORE}${AdminClientsUrl.ADMIN_CLIENTS}/${legacyId}/checkLegacyId`
        );
    }
}
