import { createAction, props } from '@ngrx/store';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ShiftDetails } from 'src/app/shared/models/shifts/shift-details.model';
import { ShiftListTableItem } from 'src/app/vendor-perdiemscheduler/models/shift-list-table-item.model';

export const loadShiftCandidates = createAction(
    '[PERDIEM SCHEDULER DETAILS GRID COMPONENT] Load Shift Candidates',
    props<{ shift: ShiftListTableItem }>()
);
export const loadShiftCandidatesSuccess = createAction(
    '[PERDIEM SCHEDULER DETAILS GRID API] Load Shift Candidates Success',
    props<{ shifts: PagingToken<ShiftListTableItem[]> }>()
);
export const loadShiftCandidatesFailure = createAction(
    '[PERDIEM SCHEDULER DETAILS GRID API] Load Shift Candidates Failure',
    props<{ error: any }>()
);

export const loadShiftSeries = createAction(
    '[PERDIEM SCHEDULER DETAILS GRID COMPONENT] Load Shift Series',
    props<{ seriesId: string; shiftId: number }>()
);

export const loadShiftSeriesSuccess = createAction(
    '[PERDIEM SCHEDULER DETAILS GRID API] Load Series Shift Success',
    props<{ shiftSeries: ShiftDetails }>()
);

export const loadShiftSeriesFail = createAction(
    '[PERDIEM SCHEDULER DETAILS GRID API] Load Series Shift Fail',
    props<{ error: any }>()
);
