import { LoadingTypes } from 'src/app/shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { PermJob } from 'src/app/permanent-jobs/models/perm-job.model';
import { PermJobGridActions } from '../actions';

export interface PermJobsGridState {
    permJobs: PermJob[];
    permJobsTotal: number;
    loading: LoadingTypes;
}

export const initialState: PermJobsGridState = {
    permJobs: [],
    permJobsTotal: 0,
    loading: LoadingTypes.INIT
};

type GetPermJobsSuccessActionType = {
    permJobs: PermJob[];
    permJobsTotal: number;
} & TypedAction<PermJobGridActions.PermJobsActionTypes.GetPermJobsSuccess>;

export const permJobsGridReducer = (
    reducerState: PermJobsGridState,
    reducerAction: Action
): PermJobsGridState => {
    return createReducer(
        initialState,
        on(
            PermJobGridActions.getPermJobs,
            (state: PermJobsGridState): PermJobsGridState => ({
                ...state,
                loading: LoadingTypes.LOADING
            })
        ),
        on(
            PermJobGridActions.getPermJobsSuccess,
            (state: PermJobsGridState, action: GetPermJobsSuccessActionType): PermJobsGridState => ({
                ...state,
                permJobs: action.permJobs,
                permJobsTotal: action.permJobsTotal,
                loading: LoadingTypes.LOADED
            })
        ),
        on(
            PermJobGridActions.getPermJobsFail,
            (state: PermJobsGridState): PermJobsGridState => ({
                ...state,
                permJobs: [],
                permJobsTotal: 0,
                loading: LoadingTypes.FAIL
            })
        )
    )(reducerState, reducerAction);
};

export const reducer = (state: PermJobsGridState | undefined, action: Action) => {
    return permJobsGridReducer(state, action);
};
