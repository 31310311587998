import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { PerdiemSchedulerService } from '../../services/perdiemscheduler.service';
import { loadCancelReasons, loadCancelReasonsSuccess, loadCancelReasonsFail } from '../perdiemscheduler.actions';
import { selectCancelReasons } from '../perdiemscheduler.selectors';

@Injectable()
export class PerdiemSchedulerCancelReasonsEffect extends BaseEffect {
    getCancelReasonsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCancelReasons),
            withLatestFrom(this.store.select(selectCancelReasons)),
            switchMap(([, cancelReasons]) => {
                if (cancelReasons?.length) {
                    return of(loadCancelReasonsSuccess({ cancelReasons }));
                }

                return this.shiftRepository.getCancelReasons().pipe(
                    map((response) =>
                        loadCancelReasonsSuccess({
                            cancelReasons: response
                        })
                    ),
                    catchError((error: unknown) => of(loadCancelReasonsFail({ error })))
                );
            })
        );
    });

    getCancelReasonsListFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadCancelReasonsFail),
                map((action) => action.error),
                tap((error: HttpErrorResponse) => this.handleError(error))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly shiftRepository: PerdiemSchedulerService,
        private readonly store: Store,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
