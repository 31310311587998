export enum FacilityFeatureEnum {
    JobApproval = 6,
    JobRelease = 8,
    ScheduledJobRelease = 9,
    JobApprovedClientEmailNotifications = 13,
    JobEditedClientEmailNotifications = 14,
    JobPendingReminderClientEmailNotifications = 15,
    JobPendingClientEmailNotifications = 16,
    JobRejected = 17,
    JobPostedVendorEmailNotifications = 18,
    JobPostedAccountManagerEmailNotifications = 19,
    WocNotification = 24,
    JobClientAttachmentRequired = 26,
    ShiftApproval = 31,
    JobDisableClientAttachmentLegacy = 32,
    ShiftDisableClientAttachmentLegacy = 33,
    ShiftClientAttachmentRequired = 34,
    JobAttachmentVerbiageEnabled = 35,
    ShiftAttachmentVerbiageEnabled = 36,
    ExtensionEmailNotifications = 45,
    ExtensionClientEmailNotifications = 46,
    ExtensionCostCenterManagerEmailNotifications = 47,
    ExtensionAccountManagerEmailNotifications = 48,
    CandidateEvaluations = 51,
    ShiftRelease = 60,
    ShiftPendingClientEmailNotifications = 61,
    ShiftPendingReminderClientEmailNotifications = 62,
    ShiftRejectedClientEmailNotifications = 63,
    ShiftApprovedClientEmailNotifications = 64,
    EnableInternalOnlyVisibility = 65,
    EnableVisibilityOptionOnlyForEditingShift = 66,
    InvoiceDisputeOpenBillingRepEmailNotifications = 85,
    InvoiceDisputeResolvedBillingRepEmailNotifications = 86,
    InvoiceDisputeOpenClientEmailNotifications = 87,
    InvoiceDisputeResolvedClientEmailNotifications = 88,
    JobEnableClientAttachment = 89,
    ShiftEnableClientAttachment = 90,
    InvoiceDraftApprovedBillingRepEmailNotifications = 91,
    InvoiceDraftApprovedClientEmailNotifications = 92,
    RequireUnitFieldSelectionWhenAddorEditaShift = 93,
    DraftInvoiceAvailableClientEmailNotifications = 96,
    ManageExpenses = 109,
    InvoicedAvailableClientEmailNotifications = 112
}
