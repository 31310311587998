export enum FeatureInputTypes {
    INTEGER = 1,
    DECIMAL = 2,
    STRING = 3,
    DATETIME = 4,
    BOOLEAN = 5,
    TOGGLE = 6
}

export interface FeatureSettingItemBase {
    featureId: number;
    name: string;
    label: string;
    value: any;
    valueTypeId: FeatureInputTypes;
    isEnabled: boolean;
    isModuleDisabled: boolean;
    canEdit?: boolean;
    childFeatures?: FeatureSettingItem[];
}

export interface FeatureSettingItemInteger extends FeatureSettingItemBase {
    value: number;
    valueTypeId: FeatureInputTypes.INTEGER;
    min?: number;
    max?: number;
    hideMaxValue?: boolean;
}
export interface FeatureSettingItemDecimal extends FeatureSettingItemBase {
    value: number;
    valueTypeId: FeatureInputTypes.DECIMAL;
    min?: number;
    max?: number;
    hideMaxValue?: boolean;
}
export interface FeatureSettingItemString extends FeatureSettingItemBase {
    value: string;
    valueTypeId: FeatureInputTypes.STRING;
    min?: number;
    max?: number;
    hideMaxValue?: boolean;
}
export interface FeatureSettingItemDate extends FeatureSettingItemBase {
    value: Date;
    valueTypeId: FeatureInputTypes.DATETIME;
    min?: Date;
    max?: Date;
    hideMaxValue?: boolean;
}

export interface FeatureSettingItemBoolean extends FeatureSettingItemBase {
    value: boolean;
    valueTypeId: FeatureInputTypes.BOOLEAN;
}
export interface FeatureSettingItemToggle extends FeatureSettingItemBase {
    value: boolean;
    valueTypeId: FeatureInputTypes.TOGGLE;
}

export type FeatureSettingItem =
    | FeatureSettingItemInteger
    | FeatureSettingItemDecimal
    | FeatureSettingItemString
    | FeatureSettingItemDate
    | FeatureSettingItemBoolean
    | FeatureSettingItemToggle;

export interface FeatureSettingCategory {
    id: number;
    name: string;
    sortOrder: number;
    features: FeatureSettingItem[];
}

export interface FeatureSettingChangesEvent {
    current: FeatureSettingItem;
    previous: FeatureSettingItem;
}
