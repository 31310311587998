import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { LoadingTypes } from 'src/app/shared/models';

export const selectVendorSystemsFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectFacilitySystemsVendors = createSelector(selectVendorSystemsFeature, (state: AdminState) => ({
    data: state?.facilitySystemsState?.vendors,
    total: state?.facilitySystemsState?.filtered,
    nonFilteredTotal: state?.facilitySystemsState?.total
}));

export const selectFacilitySystemsVendorsIsLoading = createSelector(selectVendorSystemsFeature, (state: AdminState) =>
    [state.facilitySystemsState.loadingState].some((x) => x === LoadingTypes.LOADING)
);

export const selectFacilitySystemsVendorsError = createSelector(
    selectVendorSystemsFeature,
    (state: AdminState) => state.facilitySystemsState.error
);

export const selectFacilitySystemsVendorsSearchQuery = createSelector(
    selectVendorSystemsFeature,
    (state: AdminState) => state.facilitySystemsState.gridSearchQuery
);

export const selectStatesLookup = createSelector(
    selectVendorSystemsFeature,
    (state: AdminState) => state.facilitySystemsState.states
);
