import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayEnumStringValue'
})
export class EnumStringValuePipe implements PipeTransform {
    transform(value: number, enumType: unknown): unknown {
        return enumType[value]
            ?.split(/(?=[A-Z])/)
            .join()
            .replace(',', ' ');
    }
}
