import { createAction, props } from '@ngrx/store';
import { GridSearchQuery } from 'src/app/shared/grid/models/grid-search-query.model';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { AdminClientListItem } from 'src/app/admin-clients/models/admin-clients.model';

export const loadAdminClientsList = createAction('[ADMIN CLIENTS COMPONENT] Load admin clients list');

export const adminClientsAdminClientsSuccess = createAction(
    '[ADMIN CLIENTS API] Admin Clients List Success',
    props<{ adminClients: PagingToken<AdminClientListItem[]> }>()
);

export const adminClientsAdminClientsFailure = createAction(
    '[ADMIN CLIENTS API] Admin Clients List  Failure',
    props<{ error: any }>()
);

export const adminClientSelected = createAction(
    '[ADMIN CLIENTS COMPONENT] Admin Client selected',
    props<{ error: any }>()
);

export const setAdminClientListSearchQuery = createAction(
    '[ADMIN CLIENTS API] Admin Clients List  Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);

export const exportAdminClientList = createAction('[ADMIN CLIENTS COMPONENT EXPORT] Admin Client export');

export const exportAdminClientListSuccess = createAction(
    '[ADMIN CLIENTS COMPONENT EXPORT] Admin Client export success'
);

export const exportAdminClientListFailure = createAction(
    '[ADMIN CLIENTS COMPONENT EXPORT] Admin Client export failure',
    props<{ error: any }>()
);

export const clearAdminClientListState = createAction(
    '[ADMIN CLIENTS COMPONENT CLEAR STATE] Admin Client clear list state'
);
