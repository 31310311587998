import { FacilityAssociation } from 'src/app/facilities/models/facility-association.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { getEmptyPagingToken, PagingToken } from 'src/app/shared/models/paging-token';

export interface FacilityAssocationsQueryState extends GridSearchQuery {}

const getInitialGridState = (): FacilityAssocationsQueryState => ({
    sort: [{ field: 'hospitalSystemName', dir: SortTypes.ASC }],
    filter: { filters: [{ field: 'subContractorsOnly', value: true, operator: 'eq', logic: 'and' }], logic: 'and' },
    take: 25,
    skip: 0
});

export interface FacilityAssociationsListState {
    loadingState: LoadingTypes;
    associationsList: PagingToken<FacilityAssociation[]>;
    gridSearchQuery: FacilityAssocationsQueryState;
    subContractorsOnly: boolean;
    error: string;
}

export const getInitialFacilityAssociationsListState = (): FacilityAssociationsListState => ({
    loadingState: LoadingTypes.INIT,
    associationsList: getEmptyPagingToken<FacilityAssociation>(),
    gridSearchQuery: getInitialGridState(),
    subContractorsOnly: true,
    error: ''
});
