import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocationType } from 'src/app/admin/vendor-details/profile/models/location-type.model';
import { VendorLocation } from 'src/app/admin/vendor-details/profile/models/vendor-location.model';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities';
import { PhoneType } from 'src/app/shared/models/candidate';
import { TypeListItem, VendorEmail, VendorPhone } from '../models/vendor.model';
import { JobSettingsLookupsResponse } from '../../models/job-settings-lookups-response.model';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ContactTag, VendorContact } from 'src/app/shared/models';
import { VendorBank } from '../../models/vendor-bank.model';
import { VendorJobSettingsResponse } from '../../models/job-settings-response.model';
import { VendorDetail } from 'src/app/shared/models/vendor-detail.model';
import { AdminJobRules } from 'src/app/admin-vendors/models/admin-vendor-job-rules.model';

@Injectable({
    providedIn: 'root'
})
export class VendorService {
    private readonly coreApiPath: string;

    constructor(private readonly httpClient: HttpClient, @Inject(APP_CONFIG) private readonly settings: Settings) {
        this.coreApiPath = combine(this.settings.CORE, 'ayaconnect');
    }

    getPhoneTypes(): Observable<PhoneType[]> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'phone-number-types');
        return this.httpClient.get<PhoneType[]>(url);
    }

    getPhones(vendorId: number): Observable<VendorPhone[]> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', vendorId.toString(), 'phones');
        return this.httpClient.get<VendorPhone[]>(url);
    }

    addPhoneNumber(phone: VendorPhone): Observable<VendorPhone> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'phone-number');
        return this.httpClient.post<VendorPhone>(url, phone);
    }

    updatePhoneNumber(phone: VendorPhone): Observable<VendorPhone> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'phone-number');
        return this.httpClient.put<VendorPhone>(url, phone);
    }

    deletePhoneNumber(phoneId: number): Observable<VendorPhone> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'phone-number', phoneId.toString());
        return this.httpClient.delete<VendorPhone>(url);
    }

    addEmail(email: VendorEmail): Observable<VendorEmail> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'email');
        return this.httpClient.post<VendorEmail>(url, email);
    }

    updateEmail(email: VendorEmail): Observable<VendorEmail> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'email');
        return this.httpClient.put<VendorEmail>(url, email);
    }

    deleteEmail(emailId: number): Observable<VendorEmail> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'email', emailId.toString());
        return this.httpClient.delete<VendorEmail>(url);
    }

    getLocations(vendorId: number): Observable<VendorLocation[]> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', vendorId.toString(), 'locations');
        return this.httpClient.get<VendorLocation[]>(url);
    }

    getLocationTypes(): Observable<LocationType[]> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'location-types');
        return this.httpClient.get<LocationType[]>(url);
    }

    addVendorLocation(location: VendorLocation): Observable<boolean> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'location');

        return this.httpClient.post<boolean>(url, location);
    }

    updateVendorLocation(location: VendorLocation): Observable<boolean> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'location');

        return this.httpClient.put<boolean>(url, location);
    }

    deleteVendorLocation(locationId: number): Observable<boolean> {
        const url = combine(this.coreApiPath, 'admin', 'vendors', 'location', locationId.toString());

        return this.httpClient.delete<boolean>(url);
    }

    getVendorContacts(updatedArgs: any): Observable<PagingToken<VendorContact[]>> {
        const url = combine(this.coreApiPath, 'Admin', 'Vendors', 'contacts-filtered');
        return this.httpClient.get<PagingToken<VendorContact[]>>(`${url}?${this._getContactParams(updatedArgs)}`);
    }

    getVendorDetails(vendorId: number): Observable<VendorDetail> {
        const url = combine(this.coreApiPath, 'Admin', 'Vendors', vendorId.toString());
        return this.httpClient.get<VendorDetail>(url);
    }

    create(): Observable<VendorDetail> {
        return of({
            id: null,
            legalName: '',
            operatingName: '',
            businessTypeId: 1,
            incorporatedInStateId: null,
            dateOfIncorporation: null,
            dNBID: null,
            subAgreementSigned: null,
            subAgreementSignedDate: null,
            subAgreementExpDate: null,
            fedTaxId: null,
            dateEntered: null,
            generalLiabilityExp: null,
            generalLiabilityPerOccurrence: 1000000,
            generalLiabilityAggregrate: 3000000,
            generalLiabilityWaiverOfSubrogation: null,
            generalLiabilityUmbrellaCoverageRequired: null,
            workersCompExp: null,
            workersCompLimit: 1000000,
            workersCompWcaccident: null,
            workersCompDisease: null,
            workersCompWaiverOfSubrogation: null,
            workersCompUmbrellaCoverageRequired: null,
            profecionalLiabilityExp: null,
            profecionalLiabilityPerOccurrence: 1000000,
            profecionalLiabilityAggregrate: 3000000,
            profecionalLiabilityWaiverOfSubrogation: null,
            profecionalLiabilityUmbrellaCoverageRequired: null,
            achBeneficiaryName: '',
            achRoutingNumber: null,
            achAccountNumber: null,
            achWireRoutingNumber: null,
            achAccountTypeId: null,
            achBankId: null,
            automaticallyOfferNewFacilities: null,
            accountingId: null,
            umbrellaPerOccurrence: null,
            umbrellaAggregateLimit: null,
            umbrellaAuto: null,
            umbrellaCoverageRequired: null,
            umbrellaWaiverSubrogation: null,
            umbrellaCyber: null,
            umbrellaCyberCoverage: null,
            umbrellaCyberWaiverSubrogation: null,
            umbrellaEmployeeCrimeTheftDishonesty: null,
            umbrellaCrimeWaiverSubrogation: null,
            umbrellaSexualAssaultCoverage: null,
            umbrellaSexualAssaultCoverageRequired: null,
            umbrellaSexualAssaultWaiverSubrogation: null,
            umbrellaDocoverage: null,
            umbrellaDocoverageRequired: null,
            umbrellaDowaiverSubrogation: null,
            umbrellaRequiredLanguage: null,
            umbrellaNotes: null,
            umbrellaExp: null,
            autoExp: null,
            cyberExp: null,
            employeeCrimeExp: null,
            sexualAssaultExp: null,
            doExp: null,
            newFacilityCnt: null,
            primLocation: null,
            includeExcludeNotes: null,
            bankAccountType: null,
            businessType: null,
            incorporatedInState: null
        } as unknown as VendorDetail);
    }

    getContactTags(): Observable<ContactTag[]> {
        const url = combine(this.coreApiPath, 'admin', 'vendor-contacts', 'tags');
        return this.httpClient.get<ContactTag[]>(url);
    }

    getVendorEmailTypes(): Observable<TypeListItem[]> {
        const url = combine(this.coreApiPath, 'admin', 'vendor-contacts', 'email-types');
        return this.httpClient.get<TypeListItem[]>(url);
    }

    getVendorPhoneTypes(): Observable<TypeListItem[]> {
        const url = combine(this.coreApiPath, 'admin', 'vendor-contacts', 'phone-types');
        return this.httpClient.get<TypeListItem[]>(url);
    }

    getBanks(): Observable<VendorBank[]> {
        const url = combine(this.coreApiPath, 'banks');
        return this.httpClient.get<VendorBank[]>(url);
    }

    createBank(bankName: string) {
        const params = new HttpParams().set('bankName', bankName);
        const url = combine(this.coreApiPath, 'banks', 'bank', 'create-bank');
        return this.httpClient.post<VendorBank>(`${url}?${params}`, null);
    }

    saveVendorDetails(vendor: VendorDetail) {
        const url = combine(this.coreApiPath, 'admin', 'vendors');
        return this.httpClient.post<number | null>(url, vendor);
    }

    private _getContactParams(args) {
        const filterStringArray = [];
        filterStringArray.push(`filter.vendorId=${args.vendorId}`);
        filterStringArray.push(`skip=${args.skip}`);
        filterStringArray.push(`pagesize=${args.pageSize}`);

        return filterStringArray.join('&');
    }
}
