import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Unit, UnitFacilityInformation } from 'src/app/clinical/models';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-special',
    templateUrl: './special.component.html',
    styleUrls: ['./special.component.scss']
})
export class SpecialComponent extends UnsubscribeOnDestroy implements OnChanges {
    @Input() readOnly = false;
    @Output() valueChanged = new EventEmitter<{ unit: Partial<Unit>; isValid: boolean }>();

    specialForm: UntypedFormGroup;
    _unitInfo: UnitFacilityInformation;
    _specialProceduresMaxLength = 5000;
    _specialEquipmentMaxLength = 500;
    _patientPopulationMaxLength = 500;
    _featureFlags = FeatureFlag;

    @Input() set unitInfo(unitInfo: UnitFacilityInformation) {
        if (!unitInfo.unit) {
            return;
        }
        this._unitInfo = unitInfo;

        this.setFormValues(unitInfo);

        this.watchFormChanges();
    }
    @Input() set specialProceduresMaxLength(specialProceduresMaxLength: number) {
        this._specialProceduresMaxLength = specialProceduresMaxLength;
    }

    @Input() isPrinting: boolean = false;

    constructor(private readonly _formBuilder: UntypedFormBuilder) {
        super();
        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.specialForm, changes.readOnly.currentValue);
        }
    }

    buildForm(): void {
        this.specialForm = this._formBuilder.group({
            patientPopulation: this._formBuilder.control(null, [
                Validators.maxLength(this._patientPopulationMaxLength)
            ]),
            unitDetails: this._formBuilder.control('', [Validators.maxLength(this._specialProceduresMaxLength)]),
            specialEquipment: this._formBuilder.control('', [Validators.maxLength(this._specialEquipmentMaxLength)])
        });
    }

    setFormValues(unitInfo: UnitFacilityInformation): void {
        this.specialForm.get('patientPopulation').setValue(unitInfo.unit.patientPopulation);
        this.specialForm.get('unitDetails').setValue(unitInfo.unit.unitDetails);
        this.specialForm.get('specialEquipment').setValue(unitInfo.unit.specialEquipment);
    }

    getFormValues(): Partial<Unit> {
        const unit: Partial<Unit> = {
            patientPopulation: this.specialForm.get('patientPopulation').value,
            unitDetails: this.specialForm.get('unitDetails').value,
            specialEquipment: this.specialForm.get('specialEquipment').value
        };

        return unit;
    }

    watchFormChanges(): void {
        this.specialForm.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            this.valueChanged.emit({ unit: this.getFormValues(), isValid: this.specialForm.valid });
        });
    }
}
