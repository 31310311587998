import { Component, SimpleChanges, ViewChildren, Input, QueryList } from '@angular/core';
import { FilterBase } from '../base/filter-base';

@Component({
	selector: 'grid-date-range-filter-popup',
	templateUrl: './date-range-filter-popup.component.html'
})
export class DateRangeFilterPopupComponent extends FilterBase {
    isFilterActive: boolean = false;

    @Input() disableClear: boolean = false;
	@Input() uniqueFilterClass: string;
	@ViewChildren(FilterBase) filters: QueryList<FilterBase>;

	filterValueChanged() {
		const fromDate = this.getLatestFilterValue(null, this.field + "From");
		const toDate = this.getLatestFilterValue(null, this.field + "To");

		this.isFilterActive = fromDate != null || toDate != null;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['filter']) {
			this.filterValueChanged();
		}
	}

	onResetClick(): void {
		this.trimFilterByField(this.filter, this.field + "From");
		this.trimFilterByField(this.filter, this.field + "To");
		this.submitFilter();
		this.filterValueChanged();
	}

	onFilterClick(): void {
		this.submitFilter();
	}

	onPopupClose(): void {
		if (this.filters.toArray().some(x => x.isChanged)) {
			this.submitFilter();
		}
	}

	submitFilter() {
		this.filters.toArray().forEach(x => x.isChanged = false);
		super.submitFilter();
	}
}
