export const upgradedUrlSegments = [
    /** Portal  */
    { path: 'app/resetpassword', upgradeStyle: false },
    { path: 'help', upgradeStyle: false },
    { path: 'eval-form/eval-complete', upgradeStyle: false },
    { path: 'resetpassword', upgradeStyle: false },
    { path: 'app/performanceeval', upgradeStyle: false },
    { path: 'app/performanceeval-pdf', upgradeStyle: false },
    { path: 'app/unitdescription-complete', upgradeStyle: false },
    { path: 'app/externalAccess', upgradeStyle: false },
    { path: 'app/home', upgradeStyle: false },
    { path: 'app/signin', upgradeStyle: false },

    /** Admins */
    { path: 'admin/vendorCandidates', upgradeStyle: false },
    { path: 'admin/submittals', upgradeStyle: false },
    { path: 'admin/submittals_Detail', upgradeStyle: false },

    /** Reports Begins */
    { path: 'admin/reports_powerbi', upgradeStyle: false },
    /** Reports  Ends */

    /** Facilities Begins */
    { path: 'admin/facilities', upgradeStyle: false },
    { path: 'admin/facilities_Detail', upgradeStyle: false },
    { path: 'admin/scheduledReleaseRule', upgradeStyle: false },
    /** Facilities  Ends */

    { path: 'admin/facilitySystems', upgradeStyle: false },
    { path: 'admin/facilitySystems_Vendor', upgradeStyle: false },
    { path: 'admin/facilityAssociations', upgradeStyle: false },
    { path: 'admin/facilityAssociations_Vendor', upgradeStyle: false },
    { path: 'admin/reports', upgradeStyle: false },
    { path: 'admin/clientContacts', upgradeStyle: false },
    { path: 'admin/clientContacts_Detail', upgradeStyle: false },
    { path: 'admin/systemContacts', upgradeStyle: false },
    { path: 'admin/systemContacts_Detail', upgradeStyle: false },
    { path: 'admin/customField', upgradeStyle: false },
    { path: 'admin/vendorContacts', upgradeStyle: false },
    { path: 'admin/vendorContacts_Detail', upgradeStyle: false },
    { path: 'admin/vendorCandidates_Detail', upgradeStyle: false },
    { path: 'admin/vendor', upgradeStyle: false, startsWith: true },
    { path: 'admin/vendors', upgradeStyle: false },
    { path: 'admin/clients', upgradeStyle: false },
    { path: 'admin/clients_Detail', upgradeStyle: false },
    { path: 'admin/jobs', upgradeStyle: false },
    { path: 'admin/jobs_Detail', upgradeStyle: false },
    { path: 'admin/clientContactRoles', upgradeStyle: false },
    { path: 'admin/systemContactPemissions', upgradeStyle: false },
    { path: 'admin/clientContactPemissions', upgradeStyle: false },

    /** Vendors Begins */
    { path: 'vendor/timecards', upgradeStyle: false },
    { path: 'vendor/timecards_Detail', upgradeStyle: false },
    { path: 'vendor/dashboard', upgradeStyle: false },
    { path: 'vendor/facilities', upgradeStyle: false },
    { path: 'vendor/jobs', upgradeStyle: false },
    { path: 'vendor/jobs_Detail', upgradeStyle: false },
    { path: 'vendor/reports', upgradeStyle: false },
    { path: 'vendor/reports_Detail', upgradeStyle: false },
    { path: 'vendor/submittals', upgradeStyle: false },
    { path: 'vendor/submittals_Detail', upgradeStyle: false },
    { path: 'vendor/forms', upgradeStyle: false },
    { path: 'vendor/candidates', upgradeStyle: false },
    { path: 'vendor/candidates_Detail', upgradeStyle: false },
    { path: 'vendor/perdiemscheduler', upgradeStyle: false },
    { path: 'vendor/perdiemscheduler_Detail', upgradeStyle: false },
    { path: 'vendor/reverseinvoices', upgradeStyle: false },
    /** Vendors Ends */

    /** Offers Begins */
    { path: 'vendor/submittals_offerDetail', upgradeStyle: false },
    { path: /offers\/\d+/, upgradeStyle: false },
    /** Offers Ends */

    /** Clients */
    { path: 'client/help', upgradeStyle: true },
    { path: 'client/facilityManagement_Edit', upgradeStyle: true },

    /** Jobs Begins */
    { path: 'client/jobs', upgradeStyle: true },
    { path: 'client/jobs_Detail', upgradeStyle: true },
    { path: 'client/jobs_Edit', upgradeStyle: true },
    { path: 'client/jobstemplates_Detail', upgradeStyle: true },
    { path: 'client/jobstemplates_Edit', upgradeStyle: true },
    { path: 'client/jobs/edit', upgradeStyle: true },
    { path: 'client/jobs/templates', upgradeStyle: true },
    { path: 'client/jobs/templates/edit', upgradeStyle: true },
    { path: 'client/jobs/approvals', upgradeStyle: true },
    { path: 'client/jobsfacilityrule', upgradeStyle: true }, // Edit Facility Rules
    { path: 'client/jobsfacilityrules', upgradeStyle: true }, // Create Facility Rules
    { path: 'client/jobsunitrule', upgradeStyle: true }, // Edit Unit Rules
    { path: 'client/jobsunitrules', upgradeStyle: true }, // Create Unit Rules
    /** Jobs Ends */

    /** PlatformAccess Begins */
    { path: 'client/platformAccess/flexwise', upgradeStyle: true },
    { path: 'client/platformAccess/polaris', upgradeStyle: true },
    { path: 'client/platformAccess/doccafe', upgradeStyle: true },
    /** PlatformAccess Ends */

    /** Travelers Begins */
    { path: 'client/travelers', upgradeStyle: true },
    { path: 'client/travelers/docs', upgradeStyle: true },
    { path: 'client/travelers_Detail', upgradeStyle: true },
    { path: 'client/travelers_Detail_Documents', upgradeStyle: true },
    { path: 'client/travelers_woc', upgradeStyle: true },
    { path: 'client/workers', upgradeStyle: true },
    { path: 'client/staffingadvisory_edit', upgradeStyle: true },
    { path: 'client/travelers_compliance_documents', upgradeStyle: true },
    /** Travelers Ends */

    /** TimeCards Begins */
    { path: 'client/timecards', upgradeStyle: true },
    { path: 'client/timecards_Detail', upgradeStyle: true },
    /** TimeCards Ends */

    /** Invoices Begins */
    { path: 'client/invoiced', upgradeStyle: true },
    { path: 'client/invoiced_Detail', upgradeStyle: true },
    { path: 'client/invoiced_Attachment', upgradeStyle: true },
    /** Invoices Ends */

    /** Submittals Begins */
    { path: 'client/submittals', upgradeStyle: true },
    { path: 'client/submittals_Detail', upgradeStyle: true },
    { path: 'client/jobs_irp-submittals_Detail', upgradeStyle: true },
    /** Submittals Ends */

    /** Aya Leads Begins */
    { path: 'client/ayaleads', upgradeStyle: true },
    /** Aya Leads Ends */

    /** Permanent Jobs Begins */
    { path: 'client/permanentjobs', upgradeStyle: true },
    /** Permanent Jobs Ends */

    /** Reports Begins */
    { path: 'client/reports_powerbi', upgradeStyle: true },
    { path: 'client/reports', upgradeStyle: true },
    { path: 'client/reports/open-orders', upgradeStyle: true },
    { path: 'client/reports/order-history', upgradeStyle: true },
    { path: 'client/reports/on-assignment', upgradeStyle: true },
    { path: 'client/reports/future', upgradeStyle: true },
    { path: 'client/reports/inactive', upgradeStyle: true },
    { path: 'client/reports/сancellations', upgradeStyle: true },
    { path: 'client/reports/active-pool', upgradeStyle: true },
    { path: 'client/reports/open-shifts', upgradeStyle: true },
    { path: 'client/reports/shift-history', upgradeStyle: true },
    { path: 'client/reports/spend-by-month', upgradeStyle: true },
    { path: 'client/reports/spend-by-specialty', upgradeStyle: true },
    { path: 'client/reports/spend-by-cost-center', upgradeStyle: true },
    { path: 'client/reports/spend-by-supplier', upgradeStyle: true },
    { path: 'client/reports/spend-by-unit', upgradeStyle: true },
    { path: 'client/reports/spend-by-location', upgradeStyle: true },
    /** Reports  Ends */

    /** LocalPool Begins */
    { path: 'client/perdiempool', upgradeStyle: true },
    { path: 'client/perdiempool_Detail', upgradeStyle: true },
    { path: 'client/perdiempool/docs', upgradeStyle: true },
    { path: 'client/perdiempool_Detail_Documents', upgradeStyle: true },
    /** LocalPool Ends */

    /** LocalPool Begins */
    { path: 'client/internalpool', upgradeStyle: true },
    { path: 'client/internalpool_Edit', upgradeStyle: true },
    //"client/internalpool/docs",
    /** InternalPool Ends */

    /** Shifts Begins */
    { path: 'client/perdiemscheduler', upgradeStyle: true },
    { path: 'client/perdiemscheduler/bulk', upgradeStyle: true },
    { path: 'client/perdiemscheduler_Bulk', upgradeStyle: true },
    { path: 'client/perdiemscheduler/edit', upgradeStyle: true },
    { path: 'client/perdiemscheduler_Edit', upgradeStyle: true },
    { path: 'client/perdiemscheduler_Add', upgradeStyle: true },
    { path: 'client/perdiemscheduler/detail', upgradeStyle: true },
    { path: 'client/perdiemscheduler_Detail', upgradeStyle: true },
    { path: 'client/perdiemscheduler_Approvals', upgradeStyle: true },
    {
        path: 'client/perdiemscheduler_ApprovalsFacilityRules',
        upgradeStyle: true
    },
    {
        path: 'client/perdiemscheduler_ApprovalsFacilityRule',
        upgradeStyle: true
    },
    { path: 'client/perdiemscheduler_ApprovalsUnitRules', upgradeStyle: true },
    { path: 'client/perdiemscheduler_ApprovalsUnitRule', upgradeStyle: true },
    { path: 'client/perdiemscheduler/templates', upgradeStyle: true },
    { path: 'client/shiftstemplates_view', upgradeStyle: true },
    { path: 'client/shiftstemplates_edit', upgradeStyle: true },
    { path: 'client/resource_fulfillment_view', upgradeStyle: true },
    { path: 'client/perdiemscheduler/landing', upgradeStyle: true },
    { path: 'client/shiftsschedule', upgradeStyle: true},
    /** Shifts Ends */

    /** Shifts Submittals Begins */
    { path: 'client/shiftssubmittals', upgradeStyle: true },
    { path: 'client/shiftsubmittal_view', upgradeStyle: true },
    { path: 'client/shiftsubmittals/detail', upgradeStyle: true },
    { path: 'client/shiftsubmittal_Detail', upgradeStyle: true },
    { path: 'client/shiftsubmittal_edit', upgradeStyle: true },
    { path: 'client/shiftsubmittal_documents', upgradeStyle: true },
    /** Shifts Submittals Ends */

    /** Contacts Begins */
    { path: 'client/contacts', upgradeStyle: true },
    /** Contacts Ends */

    /** Facility Mangement Begins */
    { path: 'client/facility-management', upgradeStyle: true },
    /** Facility Mangement Ends */

    { path: 'client/home', upgradeStyle: true },
    { path: 'client/message-center', upgradeStyle: true },

    { path: 'clinical/clients', upgradeStyle: false },
    { path: 'clinical/client', upgradeStyle: false, startsWith: true },

    /** Free Access Items (no log in required) Begins */
    { path: 'app/travelers_extension', upgradeStyle: false },
    { path: 'app/travelers_signWoc', upgradeStyle: false },
    { path: 'app/devops', upgradeStyle: false },
    { path: 'app/sso', upgradeStyle: false },
    { path: 'app/login', upgradeStyle: false },
    /** Free Access Items (no log in required) Ends */

    { path: 'app/unitdescription', upgradeStyle: false },

    /** Landings Begins */
    { path: 'client/workforceai/landing', upgradeStyle: true },
    /** Landings Ends */

    { path: 'client/admin-settings', upgradeStyle: true },

    /** Notification Settings Begins */
    { path: 'client/notification-settings', upgradeStyle: true },
    /** Notification Settings Ends */

    /** Facility Mangement Begins */
    { path: 'client/professions-management', upgradeStyle: true },
    /** Facility Mangement Ends */

    /** Client Expenses Begins */
    { path: 'client/expenses', upgradeStyle: true },
    /** Client Expenses Ends */

    /** Facility Shift Bonus Management Begins */
    { path: 'client/bonus-management', upgradeStyle: true }
    /** Facility Shift Bonus Management */
];
