import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CandidateQualifications } from 'src/app/submittals/models';
import { loadCandidateQualifications } from 'src/app/submittals/store/actions/submittals-leads.actions';
import { selectCandidateQualifications } from 'src/app/submittals/store/submittals.selectors';

@Component({
    selector: 'aya-submittal-certifications-section',
    templateUrl: 'submittal-certifications-section.component.html',
    styleUrls: ['./submittal-certifications-section.component.scss']
})
export class SubmittalCertificationsSectionComponent implements OnInit {
    @Input() candidateId: number;
    @Input() isSubContractor: boolean;
    certificationDisplayedColumns = ['name', 'expirationDate'];
    licenseDisplayedColumns = ['state', 'id', 'expirationDate', 'isCompact'];
    boardCertsDisplayedColumns = ['expertiseName', 'boardStatusDescription', 'expirationDate'];
    crnaCertDisplayedColumns = ['name', 'expirationDate'];
    imlcLetterDisplayedColumns = ['name'];
    deaLicensesDisplayedColumns = ['stateName', 'expirationDate'];
    csrCdsLicensesDisplayedColumns = ['stateName', 'expirationDate'];
    qualifications$: Observable<CandidateQualifications>;

    constructor(private readonly store: Store) {}

    ngOnInit() {
        this.store.dispatch(
            loadCandidateQualifications({ candidateId: this.candidateId, isSubContractor: this.isSubContractor })
        );
        this.qualifications$ = this.store.select(selectCandidateQualifications);
    }
}
