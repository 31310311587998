export enum LineOfBusinessEnum {
    PerDiem = 1,
    Travel = 2,
    Locums = 3,
    NonClinical = 4,
    LocalLongTerm = 5,
    Staff = 6,
    WFD_Clinical = 7,
    WFD_NonClinical = 8,
    AccessControl = 9
}
