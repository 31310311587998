import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { UnitType } from 'src/app/clinical/clients/models/unit-type.model';
import { takeUntil } from 'rxjs/operators';
import { FacilityHeader } from 'src/app/clinical/clients/models/facility-header.model';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { emptyValidator, notWhiteSpaceValidator } from 'src/app/shared/utilities';

@Component({
    selector: 'ayac-facility-header',
    templateUrl: './facility-header.component.html',
    styleUrls: ['./facility-header.component.scss']
})
export class FacilityHeaderComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @ViewChild('unitTypeInput', { static: false }) unitTypeInput: ElementRef<HTMLInputElement>;
    @Input() unitTypes: UnitType[];

    @Input()
    set unitTypeIds(unitTypes: UnitType[]) {
        unitTypes = unitTypes && unitTypes.length === 0 ? null : unitTypes;
        this.form.get('unitTypeIds').setValue(unitTypes);
    }

    @Input()
    set unitName(unitName: string) {
        this.form.get('unitName').setValue(unitName);
    }

    @Input() readOnly = false;

    @Output() valueChanged = new EventEmitter<{ header: FacilityHeader; isValid: boolean }>();
    @Output() isUnitFormValid = new EventEmitter<boolean>();

    form: UntypedFormGroup;

    get unitTypeIdsControl(): AbstractControl | undefined {
        return this.form.get('unitTypeIds');
    }

    get unitTypeNameControl(): AbstractControl | undefined {
        return this.form.get('unitName');
    }

    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains'
    };

    constructor(private readonly formBuilder: UntypedFormBuilder) {
        super();
        this.form = this.formBuilder.group({
            unitTypeIds: this.formBuilder.control([], Validators.required),
            unitName: this.formBuilder.control('', [Validators.required, emptyValidator(), notWhiteSpaceValidator()])
        });
    }

    ngOnInit() {
        this.form.valueChanges.pipe(takeUntil(this.d$)).subscribe((valueChange) => {
            const model = this.getModel();

            this.valueChanged.emit({ header: model, isValid: this.form.valid });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.form, changes.readOnly.currentValue);
        }
    }

    getModel(): FacilityHeader {
        const unitTypeIds = this.unitTypeIdsControl.value;
        const unitName = this.unitTypeNameControl.value;

        return { unitTypeIds, unitName } as FacilityHeader;
    }

    markAsTouched(): void {
        this.form.get('unitName').markAsTouched();
        this.form.get('unitTypeIds').markAsTouched();
    }

    addUnitType(event: MatAutocompleteSelectedEvent): void {
        const unitType: UnitType | null = event.option?.value || null;
        if (unitType) {
            const unitTypes = this.form.get('unitTypeIds').value;

            if (unitTypes) {
                const alreadyInUnitTypes = unitTypes.find((u: UnitType) => u.unitTypeCode === unitType.unitTypeCode);
                if (!alreadyInUnitTypes) {
                    unitTypes.push(unitType);
                    this.form.patchValue({ unitTypeIds: unitTypes });
                }
            } else {
                this.form.patchValue({ unitTypeIds: [unitType] });
            }
            this.unitTypeInput.nativeElement.value = '';
        }
    }

    removeUnitType(unitToRemove: UnitType): void {
        const index: number = this.form
            .get('unitTypeIds')
            .value.findIndex((unitType: UnitType) => unitType.unitTypeCode === unitToRemove.unitTypeCode);

        if (index >= 0) {
            this.unitTypeInput.nativeElement.value = '';
            this.form.get('unitTypeIds').value.splice(index, 1);
            this.form.get('unitTypeIds').updateValueAndValidity();
        }
    }
}
