import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomainService } from '../../services/domain.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationPermissions } from '../../models/enums/application-permissions.enum';
import { IdentityService } from '../../services/identity.service';

@Component({
    selector: 'ayac-welcome-dialog',
    templateUrl: './welcome-dialog.component.html',
    styleUrls: ['./welcome-dialog.component.scss']
})
export class WelcomeDialogComponent implements OnInit {
    email$: Observable<string>;
    hasTerminologyPermission = false;
    title: string;

    constructor(
        private readonly dialogRef: MatDialogRef<WelcomeDialogComponent>,
        private readonly domain: DomainService,
        private readonly _identityService: IdentityService
    ) {
        let environment = this.domain.environment();
        this.email$ = of(environment).pipe(
            map((env) => {
                if (['AYA', 'LTS', 'ONE'].includes(env)) {
                    return this.domain.getValue('SUPPORT_EMAIL');
                }
                return 'ClientConnectSupport@ayahealthcare.com';
            })
        );
        let domainTitle = environment === 'ONE' ? this.domain.getValue('TITLE') : 'Connect';
        this.title = `Welcome to your ${domainTitle} Dashboard!`;
    }

    ngOnInit(): void {
        this.hasTerminologyPermission = this._identityService.hasSecurityPermission(
            ApplicationPermissions.LocumsTerminology
        );
    }

    dismiss() {
        this.dialogRef.close();
    }
}
