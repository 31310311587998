import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JobGridSearch } from 'src/app/admin/jobs/models/job-grid-search.model';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { Observable } from 'rxjs';
import { SortTypes } from 'src/app/shared/models';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { JobLookup } from 'src/app/admin/jobs/models/job-lookup.model';
import { renameKeys } from 'src/app/core/utils/rename-keys';
import { interpolateParams } from 'src/app/core/utils';
import { ReleaseGroup } from 'src/app/shared/models/vendor-releases/release-group.model';
import { JobDetail } from 'src/app/admin/jobs/models/job-detail.model';
import { FileAttachment } from 'src/app/shared/models/attachment';

@Injectable({
    providedIn: 'root'
})
export class AdminJobsService {
    constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    getAdminJobs(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        filters?: CompositeFilterDescriptor
    ): Observable<JobGridSearch> {
        const params = {
            take: pagination.pageSize,
            skip: pagination.skip,
            sort: [{ field: sortArgs.sortField, dir: sortArgs.sortType === 'desc' ? 'desc' : 'asc' }],
            filter: { logic: 'and', filters: filters && filters.filters.length ? filters.filters : [] }
        };

        return this._httpClient.post<JobGridSearch>(
            `${this._settings.CORE}/AyaConnect/JobAdmin/GetFilteredJobs`,
            params
        );
    }

    getJobAttachmentPreview(jobId: number, fileId: number, pageNum = 1): Observable<Blob> {
        return this._httpClient.get(
            `${this._settings.CORE}/AyaConnect/Admin/Jobs/${jobId}/Attachments/Preview/${fileId}?pageNum=${pageNum}`,
            { responseType: 'blob' }
        );
    }

    getJobAttachmentPreviewTotalPages(jobId: number, fileId: number): Observable<number> {
        return this._httpClient.get<number>(
            `${this._settings.CORE}/AyaConnect/Admin/Jobs/${jobId}/Attachments/pageCount/${fileId}`
        );
    }

    exportAdminJobs(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<Blob> {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgsObj
        });

        return this._httpClient.get(`${this._settings.CORE}/AyaConnect/JobAdmin/export-jobs`, {
            responseType: 'blob',
            params
        });
    }

    getAdminJobLookups(): Observable<JobLookup> {
        return this._httpClient.get<JobLookup>(`${this._settings.CORE}/AyaConnect/JobAdmin/GetLookups`);
    }

    getReleaseGroupsByFacilities(facilityIds: number[]): Observable<ReleaseGroup[]> {
        const params = interpolateParams({
            facilities: facilityIds
        });

        return this._httpClient.get<ReleaseGroup[]>(`${this._settings.CORE}/AyaConnect/VendorRelease/Groups`, {
            params
        });
    }

    getJobById(jobId: number): Observable<{ job: JobDetail }> {
        const params = interpolateParams({ jobId });

        return this._httpClient.get<{ job: JobDetail }>(
            `${this._settings.CORE}/AyaConnect/JobAdmin/GetJobById?${params}`
        );
    }

    getCanEditVendorBaseRate(): Observable<boolean> {
        return this._httpClient.get<boolean>(`${this._settings.CORE}/AyaConnect/JobAdmin/CanEditVendorBaseRate`);
    }

    getJobAttachments(jobId: number): Observable<FileAttachment[]> {
        return this._httpClient.get<FileAttachment[]>(
            `${this._settings.CORE}/AyaConnect/Admin/Jobs/${jobId}/Attachments`
        );
    }

    downloadJobAttachment(jobId: number, fileId: number): Observable<Blob> {
        return this._httpClient.get(`${this._settings.CORE}/AyaConnect/Admin/Jobs/${jobId}/Attachments/${fileId}`, {
            responseType: 'blob'
        });
    }

    updateVendorBaseRate(jobId: number, rate: number): Observable<unknown> {
        return this._httpClient.post<unknown>(
            `${this._settings.CORE}/AyaConnect/JobAdmin/${jobId}/VendorBaseRate/${rate}`,
            undefined
        );
    }

    releaseJobs(entityIds: number[], vendorIds: number[]): Observable<unknown> {
        const params = {
            entityIds,
            vendorIds
        };

        return this._httpClient.post(`${this._settings.CORE}/AyaConnect/JobAdmin/manual-release`, params);
    }

    // releases all positions under multiple jobs
    releaseAllJobsPositions(jobIds: number[], vendorIds: number[]): Observable<unknown> {
        const params = {
            entityIds: jobIds,
            vendorIds
        };
        const url = `${this._settings.CORE}/AyaConnect/JobAdmin/manual-release-positions`;
        return this._httpClient.put(url, params);
    }

    // releases all positions under one jobs
    releaseAllJobPositions(jobId: number, vendorIds: number[]): Observable<unknown> {
        const url = `${this._settings.CORE}/AyaConnect/JobAdmin/${jobId}/manual-release-positions`;
        return this._httpClient.put(url, vendorIds);
    }

    // releases one positions under one jobs
    releaseJobPosition(jobId: number, jobPositionId: number, vendorIds: number[]): Observable<unknown> {
        const url = `${this._settings.CORE}/AyaConnect/JobAdmin/${jobId}/positions/${jobPositionId}/manual-release-position`;
        return this._httpClient.put(url, vendorIds);
    }
}
