import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { perdiemSchedulerReducer, perdiemSchedulerStoreKey, vendorPerdiemSchedulerEffects } from './store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PerdiemSchedulerService } from './services/perdiemscheduler.service';
import { BookCandidateDialogComponent } from './book-candidate-dialog/book-candidate-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { RemoveCandidateDialogComponent } from './remove-candidate-dialog/remove-candidate-dialog.component';

@NgModule({
    declarations: [BookCandidateDialogComponent, RemoveCandidateDialogComponent],
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature(perdiemSchedulerStoreKey, perdiemSchedulerReducer),
        EffectsModule.forFeature(vendorPerdiemSchedulerEffects)
    ],
    exports: [StoreModule],
    providers: [PerdiemSchedulerService]
})
export class VendorPerdiemSchedulerDialogsModule {}
