import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from 'src/app/dashboard-new/services/dashboard.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getFilter } from 'src/app/dashboard-new/store/selectors/filter.selectors';
import {
    loadNewAyaLeadsCount,
    loadNewAyaLeadsCountSuccess,
    loadNewAyaLeadsCountFail
} from 'src/app/dashboard-new/store/actions';
import { selectAyaLeadsApplicationStatusIdsFilter } from 'src/app/dashboard-new/store/selectors/aya-leads-dashboard.selectors';

@Injectable()
export class AyaLeadsEffect {
    getNewAyaLeadsCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadNewAyaLeadsCount),
            concatLatestFrom(() => [
                this.store.select(getFilter),
                this.store.select(selectAyaLeadsApplicationStatusIdsFilter) // Add a second select here
            ]),
            switchMap(([, query, applicationStatusIds]) => {
                const filter = {
                    facilityProfileGroupId: query.facility.profileGroupId > 0 ? query.facility.profileGroupId : null,
                    unitId: query.unit.id > 0 ? query.unit.id : null,
                    facilityId: query.facility.id > 0 ? query.facility.id : null,
                    applicationStatusIds
                };

                return this.service.getAyaLeadsCount(filter).pipe(
                    map((response) => loadNewAyaLeadsCountSuccess({ ayaLeadsCount: response })),
                    catchError((error: unknown) => of(loadNewAyaLeadsCountFail({ error })))
                );
            })
        );
    });

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly service: DashboardService
    ) {}
}
