import { Component, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { addDays } from '@progress/kendo-date-math';
import { FilterBase } from '../base/filter-base';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
	selector: 'grid-date-range-filter',
	templateUrl: './date-range-filter.component.html',
	providers: [{ provide: FilterBase, useExisting: DateRangeFilterComponent }],
	styles: [`.k-form { padding: 5px; }`]
})
export class DateRangeFilterComponent extends FilterBase {
	start: Date;
	end: Date;

	@Output() filterValueChanged = new EventEmitter<CompositeFilterDescriptor>();

	public get min(): any {
		return this.start ? addDays(this.start, 1) : null;
	}

	public get max(): any {
		return this.end ? addDays(this.end, -1) : null;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['filter']) {
			this.start = this.getLatestFilterValue(null, this.field + "From");
			this.end = this.getLatestFilterValue(null, this.field + "To");
		}
	}

	public onStartChange(value: any): void {
		this.filterRange(value, this.end);
	}

	public onEndChange(value: any): void {
		this.filterRange(this.start, value);
	}

	private filterRange(start, end) {
		if (start == null) {
			this.removeFilter(this.field + "From");
		}
		else if (!end || start < end) {
			this.updateFilter({
				field: this.field + "From",
				operator: 'gte',
				value: start
			});
			this.start = start;
		}

		if (end == null) {
			this.removeFilter(this.field + "To");
		}
		else if (!start || start < end) {
			this.updateFilter({
				field: this.field + "To",
				operator: 'lte',
				value: end
			});
			this.end = end;
		}

		this.filterValueChanged.emit(this.filter);
	}
}
