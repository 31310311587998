import { Pipe, PipeTransform } from '@angular/core';

/*
  The literal "Z" is actually part of the ISO 8601 datetime standard for UTC times.
  It is necessary to designate this date as UTC and the browser will take the current offset from it.
*/
@Pipe({
    name: 'fixTimezoneOffset'
})
export class FixTimezoneOffsetPipe implements PipeTransform {
    transform(value: string): any {
        if (!value) {
            return value;
        }
        return String(value).replace(/Z?$/i, 'Z')
    }
}
