import { Action, createReducer, on } from '@ngrx/store';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { setPerdiemSchedulerSearchQuery } from '../perdiemscheduler.actions';
import * as dayjs from 'dayjs';
import { PerdiemSchedulerLocation } from '../../models/enum/perdiemscheduler-location.enum';

export interface PerdiemSchedulerQueryState extends GridSearchQuery {
    professionId: number;
    specialtyId: number;
    dateFrom: Date;
    dateTo: Date;
    minRate: number;
    statusId: number;
    facilityId: number;
    city: string;
    state: string;
    location: PerdiemSchedulerLocation;
}

export const getInitialPerdiemSchedulerQueryState = (): PerdiemSchedulerQueryState => ({
    professionId: null,
    specialtyId: null,
    dateFrom: dayjs().toDate(),
    dateTo: dayjs().add(1, 'month').toDate(),
    minRate: -1,
    statusId: 0,
    facilityId: null,
    city: null,
    state: null,
    location: PerdiemSchedulerLocation.facility,
    sort: [{ field: 'shiftDate', dir: 'asc' }],
    take: 10,
    skip: 0
});

export function perdiemSchedulerQueryReducer(state: PerdiemSchedulerQueryState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerQueryState(),
        on(setPerdiemSchedulerSearchQuery, (state, action) => ({
            ...state,
            ...action.query
        }))
    )(state, action);
}

export function reducer(state: PerdiemSchedulerQueryState | undefined, action: Action) {
    return perdiemSchedulerQueryReducer(state, action);
}
