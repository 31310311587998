import {
    ClientSharedDataState,
    initialClientSharedDataState
} from '../../core/state/reducers/client-shared-data.reducer';
import { CoreState, initialCoreState } from 'src/app/core/state/reducers/core.reducer';

export const coreStoreKey = 'core';

export interface CoreStoreState {
    core: CoreState;
    clientSharedData: ClientSharedDataState;
}

export const initialCoreStoreState = (): CoreStoreState => ({
    core: initialCoreState,
    clientSharedData: initialClientSharedDataState
});
