import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { PerdiemCandidate } from '../../models/perdiem-candidate.model';
import { PerdiemSchedulerService } from '../../services/perdiemscheduler.service';
import {
    bookCandidate,
    bookCandidateFail,
    bookCandidateSuccess,
    loadCandidates,
    loadCandidatesFail,
    loadCandidatesSuccess,
    loadFilteredCandidates,
    removeCandidate,
    removeCandidateFail,
    removeCandidateSuccess
} from '../perdiemscheduler.actions';
import { selectCandidates } from '../perdiemscheduler.selectors';

@Injectable()
export class PerdiemSchedulerCandidatesEffect extends BaseEffect {
    getCandidatesList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCandidates),
            switchMap((action) =>
                this.shiftRepository
                    .getCandidates(
                        action.professionId,
                        action.specialtyId,
                        action.facilityId,
                        action.seriesId,
                        action.shiftId
                    )
                    .pipe(
                        map((response) => this.queryCandidateData(response.data, action.query)),
                        catchError((error: unknown) => of(loadCandidatesFail({ error })))
                    )
            )
        );
    });

    getCandidatesListFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadCandidatesFail),
                map((action) => action.error),
                tap((error: HttpErrorResponse) => this.handleError(error))
            );
        },
        { dispatch: false }
    );

    getCandidatesFiltered$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadFilteredCandidates),
            withLatestFrom(this.store.select(selectCandidates)),
            map(([action, candidates]) => this.queryCandidateData(candidates.data, action))
        );
    });

    bookCandidate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(bookCandidate),
            switchMap((action) =>
                this.shiftRepository.bookCandidate(action.candidateId, action.shiftId, action.seriesId).pipe(
                    map((response) => {
                        this.dialog.getDialogById(action.dialogId)?.close(true);

                        return bookCandidateSuccess({ shift: response });
                    }),
                    catchError((error: unknown) => of(bookCandidateFail({ error })))
                )
            )
        );
    });

    notifyBookCandidateSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(bookCandidateSuccess),
                map(() => this.handleSuccess('The candidate has been booked to the shift(s)'))
            );
        },
        { dispatch: false }
    );

    notifyBookCandidateFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(bookCandidateFail),
                map(() => this.handleStringError('Unable to book candidate'))
            );
        },
        { dispatch: false }
    );

    removeCandidate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeCandidate),
            switchMap((action) =>
                this.shiftRepository.removeCandidate(action.cancelReason, action.shiftId, action.seriesId).pipe(
                    map((response) => {
                        this.dialog.getDialogById(action.dialogId)?.close(true);

                        return removeCandidateSuccess({ shift: response });
                    }),
                    catchError((error: unknown) => of(removeCandidateFail({ error })))
                )
            )
        );
    });

    notifyRemoveCandidateSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(removeCandidateSuccess),
                map(() => this.handleSuccess('The candidate has been removed from the shift(s).'))
            );
        },
        { dispatch: false }
    );

    notifyRemoveCandidateFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(removeCandidateFail),
                map(() => this.handleStringError('Unable to remove candidate.'))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly shiftRepository: PerdiemSchedulerService,
        private readonly dialog: MatDialog,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    private queryCandidateData(candidates: PerdiemCandidate[], query: { take: number; skip: number; search: string }) {
        const search = query.search?.toLowerCase() ?? '';
        const filteredCandidates = candidates.filter(
            (c) => c.fullName?.toLowerCase().includes(search) || c.email?.toLowerCase().includes(search)
        );

        return loadCandidatesSuccess({
            candidates,
            filteredCandidates: filteredCandidates.slice(query.skip, query.take + query.skip),
            total: filteredCandidates.length
        });
    }
}
