import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { LoadingTypes } from 'src/app/shared/models';

export const selectFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectSystemContactsProfile = createSelector(
    selectFeature,
    (state) => state.systemContactsState.systemContact
);

export const selectSystemContactsProfileIsLoading = createSelector(
    selectFeature,
    (state) => state.systemContactsState.loading === LoadingTypes.LOADING
);

export const selectSystemContactTypes = createSelector(
    selectFeature,
    (state) => state.systemContactsState.systemContactTypes
);

export const selectHospitalSystems = createSelector(
    selectFeature,
    (state) => state.systemContactsState.hospitalSystems
);

export const selectIdentityProviders = createSelector(
    selectFeature,
    (state) => state.systemContactsState.identityProviders
);

export const selectInvitation = createSelector(selectFeature, (state) => state.systemContactsState.invitation);
