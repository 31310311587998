import { LookupsModule } from './lookups/lookups.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UpgradeModule } from '@angular/upgrade/static';
import { APP_CONFIG, VERSION_POLL_INTERVAL } from './config/settings';
import { AppSettings } from './config/app-settings';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { appReducers } from './store/reducers/app.reducer';
import { stateParamsProvider } from './ajs-upgraded-providers';
import { InternalPoolModule } from './internal-pool/internal-pool.module';
import { SubmittalsModule } from './submittals/submittals.module';
import { ENV, getEnv } from '../environments/environment.provider';
import { RootStoreModule } from 'src/app/root-store/root-store.module';
import { DowngradedComponentsModule } from 'src/app/core/downgraded-components.module';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { DatadogLoggerComponent } from './datadog-logger/datadog-logger.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { CorrelationIdInterceptor } from '@aya/common/interceptors';
import { AppIdInterceptor } from './core/app-id-interceptor';
import { isAllowedAnonymous } from 'src/app/shared/utilities/interceptor-uri-matcher';
import { ColorThemeModule } from './core/theme/theme.module';

export const appInit = (featureManager: LDFeatureManager) => () => featureManager.init();
@NgModule({
    declarations: [AppComponent, DatadogLoggerComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AuthModule.forRoot({
            domain: AppSettings.AUTH_DOMAIN,
            clientId: AppSettings.AUTH_CLIENTID,
            authorizationParams: {
                audience: `${window.location.protocol}${AppSettings.CORE}/`
            },
            useRefreshTokens: true,
            cacheLocation: AppSettings.AUTH_CACHE_LOCATION,
            httpInterceptor: {
                allowedList: [
                    {
                        uriMatcher: (uri) => isAllowedAnonymous(uri, AppSettings.CORE),
                        allowAnonymous: true
                    },
                    {
                        uri: `${AppSettings.CORE}/*`,
                        tokenOptions: {
                            authorizationParams: {
                                audience: `${window.location.protocol}${AppSettings.CORE}/`
                            }
                        }
                    }
                ]
            }
        }),
        UpgradeModule,
        SubmittalsModule,
        AppRoutingModule,
        CoreModule,
        LookupsModule,
        SharedModule,
        // Can be removed after checking all places with state mutation
        StoreModule.forRoot(appReducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
                strictActionTypeUniqueness: true
            }
        }),
        StoreDevtoolsModule.instrument({
            name: 'Aya Connect Portal DevTools',
            logOnly: environment.production
        }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot(),
        InternalPoolModule,
        RootStoreModule,
        DowngradedComponentsModule,
        ColorThemeModule.forRoot()
        // https://ngrx.io/guide/store-devtools/recipes/exclude
        // !environment.production ? StoreDevtoolsModule.instrument() : []
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            deps: [LDFeatureManager],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CorrelationIdInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppIdInterceptor,
            multi: true
        },
        { provide: APP_CONFIG, useValue: AppSettings },
        stateParamsProvider,
        { provide: 'Window', useValue: window },
        {
            provide: VERSION_POLL_INTERVAL,
            useFactory: () => {
                // poll every 5 minutes
                return 300000;
            }
        },
        { provide: ENV, useFactory: getEnv }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
