import { createAction, props } from '@ngrx/store';
import { AyaLeadsCount } from 'src/app/dashboard-new/models';

export const loadNewAyaLeadsCount = createAction('[Dashboard] Load New Aya Leads Count');

export const loadNewAyaLeadsCountSuccess = createAction(
    '[Dashboard] Load New Aya Leads Count Success',
    props<{ ayaLeadsCount: AyaLeadsCount }>()
);

export const loadNewAyaLeadsCountFail = createAction(
    '[Dashboard] Load New Aya Leads Count Fail',
    props<{ error: any }>()
);
