import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, map, catchError } from 'rxjs/operators';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import { InvoicesState } from '../invoices.state';
import { loadInvoicesSuccess, loadInvoices, loadInvoicesFail } from '../invoices.actions';
import { selectInvoicesQuery } from '../invoices.selectors';
import { of } from 'rxjs';
import { mapFilters } from 'src/app/shared/grid/utils/map-filters';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { InvoiceStatus } from '../../models';

@Injectable()
export class InvoicesListEffect {
    getInvoicesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadInvoices),
            withLatestFrom(this.store$.select(selectInvoicesQuery)),
            switchMap(([action, query]) => {
                let updatedQuery: GridSearchQuery = {
                    ...query,
                    filter: {
                        ...query.filter,
                        filters: mapFilters(query.filter.filters, {
                            dateFrom: 'date',
                            dateTo: 'date'
                        })
                    }
                };

                updatedQuery = this.addStatusFilter(updatedQuery, action.isBillable);

                return this.invoicesRepository.getFilteredInvoices(updatedQuery).pipe(
                    map((response) =>
                        loadInvoicesSuccess({
                            invoices: response.data,
                            total: response.total
                        })
                    ),
                    catchError((error) => of(loadInvoicesFail({ error })))
                );
            })
        )
    );

    private addStatusFilter(query: GridSearchQuery, isBillable: boolean) {
        const updatedQuery = query;

        const status = isBillable ? InvoiceStatus.ClientReview : InvoiceStatus.Approved;

        updatedQuery.filter.filters.push({
            field: 'invoiceStatusId',
            operator: 'eq',
            value: status
        });

        return updatedQuery;
    }

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<InvoicesState>,
        private readonly invoicesRepository: InvoicesRepositoryService
    ) {}
}
