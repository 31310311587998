import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { renameKeys } from 'src/app/core/utils/rename-keys';
import { SortTypes, VendorContact } from 'src/app/shared/models';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { VendorCandidate } from 'src/app/admin/vendor-candidates/vendor-candidate.model';
import { interpolateParams } from 'src/app/core/utils';
import { combine } from 'src/app/shared/utilities';
import { ListItem } from 'src/app/shared/models/list-item';
import { BackgroundHistory, CandidateShiftsAppState, Certification, Expertise, StateLicense } from 'src/app/shared/models/candidate';
import { Phone } from 'src/app/shared/models/candidate/phone.model';

@Injectable({
    providedIn: 'root'
})
export class VendorCandidateService {
    private readonly adminVendorCandidatesUrl: string;
    private readonly candidatesUrl: string;

    constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {
        this.adminVendorCandidatesUrl = combine(this._settings.CORE, 'AyaConnect', 'admin', 'vendor-candidates');
        this.candidatesUrl = combine(this._settings.CORE, 'AyaConnect', 'candidates');
    }

    getVendorCandidates(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<PagingToken<VendorCandidate[]>> {
        const params = this.createQueryParams(pagination, sortArgs, matchArgs);
        return this._httpClient.get<PagingToken<VendorCandidate[]>>(this.adminVendorCandidatesUrl, { params });
    }

    getVendorCandidate(candidateId: number): Observable<VendorCandidate> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString());

        return this._httpClient.get<VendorCandidate>(url);
    }

    getVendorContacts(vendorId: number): Observable<VendorContact[]> {
        const url = combine(this._settings.CORE, 'AyaConnect', 'Admin', 'Vendors', vendorId.toString(), 'contacts');

        return this._httpClient.get<VendorContact[]>(url);
    }

    getVendors(): Observable<ListItem[]> {
        const url = combine(this._settings.CORE, 'AyaConnect', 'admin', 'vendors', 'list');

        return this._httpClient.get<ListItem[]>(url);
    }

    updateCandidate(candidateId: number, candidate: VendorCandidate): Observable<VendorCandidate> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString());
        return this._httpClient.put<VendorCandidate>(url, candidate);
    }

    createCandidate(candidate: VendorCandidate): Observable<VendorCandidate> {
        const url = combine(this.adminVendorCandidatesUrl);
        return this._httpClient.post<VendorCandidate>(url, candidate);
    }

    getCertifications(): Observable<ListItem[]> {
        const url = combine(this.candidatesUrl, 'certifications');
        return this._httpClient.get<ListItem[]>(url);
    }

    getCandidateCertifications(candidateId: number): Observable<Certification[]> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'certifications');
        return this._httpClient.get<Certification[]>(url);
    }

    saveCertifications(candidateId: number, certificationList: Certification[]): Observable<Certification[]> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'certifications');
        return this._httpClient.post<Certification[]>(url, certificationList);
    }

    getCandidateExpertiseList(candidateId: number): Observable<Expertise[]> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'profession-specialties');
        return this._httpClient.get<Expertise[]>(url);
    }

    saveExpertise(candidateId: number, expertiseList: Expertise[]): Observable<void> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'profession-specialties');
        return this._httpClient.post<void>(url, expertiseList);
    }

    getBackgroundHistories(candidateId: number): Observable<BackgroundHistory[]> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'background');
        return this._httpClient.get<BackgroundHistory[]>(url);
    }

    addCandidateV1Profile(candidateId: number): Observable<any> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'v1-profile');
        return this._httpClient.post<void>(url, null);
    }

    saveBackgroundHistory(
        candidateId: number,
        backgroundHistoryList: BackgroundHistory[]
    ): Observable<BackgroundHistory[]> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'background');
        return this._httpClient.put<BackgroundHistory[]>(url, backgroundHistoryList);
    }

    getStateLicenses(candidateId: number): Observable<StateLicense[]> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'state-licenses');
        return this._httpClient.get<StateLicense[]>(url);
    }

    saveLicenses(candidateId: number, licenseList: StateLicense[]): Observable<StateLicense[]> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'state-licenses');
        return this._httpClient.post<StateLicense[]>(url, licenseList);
    }

    getPhones(candidateId: number): Observable<Array<Phone>> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'phones');
        return this._httpClient.get<Array<Phone>>(url);
    }

    savePhone(candidateId: number, phoneList: Phone[]): Observable<void> {
        const url = combine(this.adminVendorCandidatesUrl, candidateId.toString(), 'phones');
        return this._httpClient.post<void>(url, phoneList);
    }

    getOtherInfo(ssnId: string): Observable<void> {
        const url = combine(this.adminVendorCandidatesUrl, ssnId, 'other-info');
        return this._httpClient.get<void>(url);
    }

    getVendorCandidatesExport(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<PagingToken<VendorCandidate[]>> {
        const params = this.createQueryParams(pagination, sortArgs, matchArgs);
        const url = combine(this.adminVendorCandidatesUrl, 'export');
        return this._httpClient.get<PagingToken<VendorCandidate[]>>(url, { params });
    }

    createQueryParams(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): HttpParams {
        const argsSortObj = sortArgs;
        let matchArgsObj = {};

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        argsSortObj['sort.field'] = argsSortObj['sortField'];
        argsSortObj['sort.type'] = argsSortObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete argsSortObj.sortField;
        delete argsSortObj.sortType;

        return interpolateParams({
            ...pagination,
            ...argsSortObj,
            ...matchArgsObj
        });
    }
}
