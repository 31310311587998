import { GridSearchQuery } from 'src/app/shared/grid/models';
import { SortTypes } from 'src/app/shared/models';

export interface AdminClientQueryState extends GridSearchQuery {}

export const getInitialAdminClientsGridState = (): AdminClientQueryState => ({
    sort: [{ field: 'name', dir: SortTypes.ASC }],
    take: 10,
    skip: 0,
    filter: {
        logic: 'and',
        filters: [
            { field: 'legacyId', operator: 'eq', value: 0 },
            { field: 'name', operator: 'eq', value: '' }
        ]
    }
});
