export enum VendorSubmittalStage {
    SubmittedToAya = 1,
    SubmittedToClient = 2,
    Interviewing = 3,
    Offer = 4,
    OfferAccepted = 5,
    OfferDeclined = 6,
    CandidateRejected = 7,
    Cancelled = 10,
    Placed = 12,
    AwaitingAyaApproval = 14,
    IncompleteProfile = 16,
    RequestedChanges = 17
}
