import { createAction, props } from '@ngrx/store';

import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import { FacilityLookup } from 'src/app/shared/models/lookups';
import { Shift } from 'src/app/shared/models/shifts/shift.model';


export const openRedirectShiftDialog = createAction(
    '[SHIFTS] Open Redirect Shift Dialog',
    props<{
        resource?: Resource,
        agencyFacilities?: FacilityLookup[],
    }>()
)

export const loadingRedirectShiftDialog = createAction(
    '[SHIFTS] Loading Redirect Modal'
);

export const openRedirectShiftDialogWithShift = createAction('[SHIFTS] Open Redirect Shift Dialog with Shift', props<{ shift: Shift }>());
