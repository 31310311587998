import { TAB } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteActivatedEvent, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { from, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, startWith, takeUntil, tap } from 'rxjs/operators';
import { VendorBank } from 'src/app/admin/vendor-details/models/vendor-bank.model';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { VendorDetail } from 'src/app/shared/models/vendor-detail.model';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
    selector: 'ayac-vendor-banking',
    templateUrl: './vendor-banking.component.html',
    styleUrls: ['./vendor-banking.component.scss']
})
export class VendorBankingComponent extends UnsubscribeOnDestroy implements OnInit {
    @Output()
    customBankAdded = new EventEmitter<string>();

    @Output()
    bankInfoChanged = new EventEmitter<Partial<VendorDetail>>();

    @Output()
    bankNameChanged = new EventEmitter<VendorBank>();

    @Input()
    set vendorDetails(details: VendorDetail) {
        this._details = details;

        if (this._details) {
            if (!this.bankingForm) {
                this.createBankingForm();
            }
            this.bankingForm.get('routingNum').patchValue(this._details.achRoutingNumber);
            this.bankingForm.get('accountNum').patchValue(this._details.achAccountNumber);
            this.bankingForm.get('wireRoutingNum').patchValue(this._details.achWireRoutingNumber);
            this.bankingForm.get('beneficiary').patchValue(this._details.achBeneficiaryName);
            this.bankingForm.get('accountType').patchValue(this._details.achAccountTypeId);

            this._previousBank = this.banks.find((item) => item.id === this._details.achBankId);
            this.bankingForm.get('bank').patchValue(this._previousBank);
        }
    }

    get vendorDetails(): VendorDetail {
        return this._details;
    }

    @Input()
    set banks(banks: VendorBank[]) {
        this._banks = banks;
    }

    get banks(): VendorBank[] {
        return this._banks;
    }

    bankingForm: UntypedFormGroup;
    filteredBanks$: Observable<VendorBank[]>;

    private _details: VendorDetail;
    private _banks: VendorBank[] = [];
    private _activatedBank: VendorBank | undefined = undefined;
    private _previousBank: VendorBank | undefined = undefined;

    constructor(private readonly _builder: UntypedFormBuilder, private readonly _dialogService: DialogService) {
        super();
    }

    ngOnInit(): void {
        if (!this.bankingForm) {
            this.createBankingForm();
        }

        this.bankingForm.valueChanges
            .pipe(skip(1), debounceTime(500), distinctUntilChanged(), takeUntil(this.d$))
            .subscribe((value) => {
                const emittedBankInfo: Partial<VendorDetail> = {
                    achBankId: value.bank?.id,
                    achRoutingNumber: value.routingNum,
                    achAccountNumber: value.accountNum,
                    achWireRoutingNumber: value.wireRoutingNum,
                    achBeneficiaryName: value.beneficiary,
                    achAccountTypeId: value.accountType
                };

                this.bankInfoChanged.emit(emittedBankInfo);
            });

        this.filteredBanks$ = this.bankingForm.get('bank').valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            map((value) => this._filterBanks(value)),
            tap((banks) => {
                if (banks.length === 0) {
                    this._activatedBank = undefined;
                }
            })
        );
    }

    createBankingForm(): void {
        this.bankingForm = this._builder.group({
            bank: this._builder.control(''),
            routingNum: this._builder.control(''),
            accountNum: this._builder.control(''),
            wireRoutingNum: this._builder.control(''),
            beneficiary: this._builder.control(''),
            accountType: this._builder.control('')
        });
    }

    addCustomBank(event: KeyboardEvent): void {
        const bankInputValue = this.bankingForm.get('bank').value;
        const name = bankInputValue?.hasOwnProperty('name') ? bankInputValue?.name : bankInputValue ?? '';
        const isTab = event.keyCode === TAB;
        const bank = this.banks.find((item) => item.name.toLowerCase() === name.toLowerCase());

        if (isTab && name.length > 0 && !bank && !this._activatedBank) {
            event.preventDefault();

            const dialogPromise = this._dialogService.openConfirmationDialog({
                data: {
                    title: 'Vendor Details',
                    text: `The bank [ ${name} ] is not on the list. Do you want to add a new bank?`
                }
            });

            from(dialogPromise)
                .pipe(takeUntil(this.d$))
                .subscribe((value) => {
                    if (value) {
                        // This will eventually use the correct store action
                        // for adding a custom bank
                        this.customBankAdded.emit(name);
                    } else {
                        this.bankingForm.get('bank').patchValue(this._previousBank);
                    }
                });
        } else if (isTab && this._activatedBank) {
            // It's a tab and an active option was selected via keyboard interaction or filtering
            this.bankingForm.get('bank').patchValue(this._activatedBank, { emitEvent: false });
            this.bankNameChanged.emit(this._activatedBank);
        }
    }

    optionActivated(event: MatAutocompleteActivatedEvent): void {
        this._activatedBank = event.option.value ?? undefined;
    }

    bankSelected(event: MatAutocompleteSelectedEvent): void {
        this.bankNameChanged.emit(event.option.value);
    }

    displayBank(bank: VendorBank): string {
        return bank?.name ?? '';
    }

    private _filterBanks(value: string): VendorBank[] {
        if (value && typeof value === 'string') {
            return value.length === 0
                ? this.banks
                : this.banks.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
        }

        return this.banks;
    }
}
