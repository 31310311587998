import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pluralizeDialogTitle'
})
export class PluralizeDialogTitlePipe implements PipeTransform {
    transform(value: unknown[]): string {
        return value.length > 1 ? 's' : '';
    }
}
