import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { SortTypes } from 'src/app/shared/models';
import { interpolateParams, interpolateUrl } from 'src/app/core/utils';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ResourceList } from 'src/app/shared/models/internal-pool/resource-list.model';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import { ResourceAdd } from 'src/app/shared/models/internal-pool/resource-add.model';
import { ResourceEdit } from 'src/app/shared/models/internal-pool/resource-edit.model';
import { ListItem } from 'src/app/shared/models/list-item';
import { CheckEmailResponse, ResourceAssignment, ResourceLookupsResponse } from './models';
import { FeatureFlag } from '../shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from '../shared/feature-management/ld-feature-manager';

export enum InternalPoolUrls {
    SHIFTS = '/AyaConnect/InternalPool/Shifts',
    OPEN_SHIFT = '/AyaConnect/InternalPool/Shifts/{id}/Open',
    OPEN_IP_TO_CLIENT_SHIFT = '/AyaConnect/Client/Shifts/',
    GET_SHIFT = '/AyaConnect/InternalPool/Shifts/{id}',
    RESOURCES = '/AyaConnect/InternalPool/Resources/{systemId}/Resources',
    RESOURCES_LOOKUP = '/AyaConnect/InternalPool/Resources/',
    RESOURCE_ADD_WORKER = '/AyaConnect/InternalPool/Resources/',
    RESOURCE_ADD_NYU_WORKER = '/AyaConnect/InternalPool/Resources/Nyu/',
    RESOURCE_DETAILS = '/AyaConnect/Internalpool/Resources/',
    CANCEL_IO_SHIFT_BY_FACILITY = '/AyaConnect/InternalPool/Shifts/cancel-by-facility',
    SEND_SHIFT_EMAIL_NOTIFICATION = '/AyaConnect/InternalPool/Shifts/{id}/email',
    DOES_RESOURCE_EMAIL_EXIST = '/AyaConnect/InternalPool/Resources/',
    HISTORY = '/AyaConnect/InternalPool/Shifts/{id}/History',
    RECONFIRM = '/AyaConnect/InternalPool/Shifts/{id}/Reconfirm',
    SEND_INVITATION_EMAIL = '/AyaConnect/InternalPool/Resources/{id}/Email/Invite',
    ASSIGNMENTS = '/AyaConnect/Internalpool/Resources/{systemId}/Resources/{resourceId}/assignments',
    CURRENT_ASSIGNMENTS = '/AyaConnect/Internalpool/Resources/{systemId}/Resources/{resourceId}/currentAssignments',
    ARCHIVE_WORKER = '/AyaConnect/InternalPool/Resources/{id}/Archive',
    UNARCHIVE_WORKER = '/AyaConnect/InternalPool/Resources/{id}/Unarchive',
    CHECK_USERNAME_AVAILABILITY = '/AyaConnect/Internalpool/Resources/{systemId}/resources/check-user-availability',
    CHECK_RESOURCE_ARCHIVE_STATUS = '/AyaConnect/InternalPool/Resources/{candidateId}/Archive/Status'
}

@Injectable({
    providedIn: 'root'
})
export class InternalPoolService {
    featureFlags = FeatureFlag;

    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings,
        private readonly ldFeatureManager: LDFeatureManager
    ) {}

    getLookups(systemId: number): Observable<ResourceLookupsResponse> {
        const urlLookups = `${InternalPoolUrls.RESOURCES_LOOKUP}`;
        return this.http.get<ResourceLookupsResponse>(`${this.settings.CORE}${urlLookups}${systemId}/GetLookups`);
    }

    add(resource: ResourceAdd, systemId: number, isNyu = false): Observable<number> {
        if (isNyu) {
            return this.http.post<number>(
                `${this.settings.CORE}${InternalPoolUrls.RESOURCE_ADD_NYU_WORKER}${systemId}`,
                resource
            );
        }
        return this.http.post<number>(
            `${this.settings.CORE}${InternalPoolUrls.RESOURCE_ADD_WORKER}${systemId}`,
            resource
        );
    }

    update(resource: ResourceEdit, systemId: number, isNyu = false): Observable<Resource> {
        if (isNyu) {
            return this.http.put<Resource>(
                `${this.settings.CORE}${InternalPoolUrls.RESOURCE_ADD_NYU_WORKER}${systemId}`,
                resource
            );
        }
        return this.http.put<Resource>(
            `${this.settings.CORE}${InternalPoolUrls.RESOURCE_ADD_WORKER}${systemId}`,
            resource
        );
    }

    getResourceDetails(id: number, systemId: number): Observable<Resource> {
        const url = `${InternalPoolUrls.RESOURCE_DETAILS}`;
        return this.http.get<Resource>(`${this.settings.CORE}${url}${systemId}/Resources/${id}`);
    }

    doesResourceEmailExist(email: string, systemId: number): Observable<ListItem> {
        const url = `${InternalPoolUrls.DOES_RESOURCE_EMAIL_EXIST}`;
        return this.http.get<any>(`${this.settings.CORE}${url}${systemId}/resources/emails`, {
            params: { email: email }
        });
    }

    getResources(
        systemId: number,
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        var resources: Observable<PagingToken<ResourceList[]>>;
        const url = interpolateUrl(InternalPoolUrls.RESOURCES, { systemId });
        this.ldFeatureManager
            .isEnabled(this.featureFlags.AddFacilityFilterToIrpWorkerPage)
            .subscribe((flagIsEnabled) => {
                if (flagIsEnabled) {
                    const params = {
                        ...pagination,
                        ...sortArgs,
                        ...matchArgs
                    };
                    resources = this.http.post<PagingToken<ResourceList[]>>(`${this.settings.CORE}${url}`, params);
                } else {
                    const params = interpolateParams({
                        ...pagination,
                        ...sortArgs,
                        ...matchArgs
                    });
                    resources = this.http.get<PagingToken<ResourceList[]>>(`${this.settings.CORE}${url}`, { params });
                }
            });

        return resources;
    }

    sendShiftAssignedNotification(shiftId: number) {
        const urlParams = {
            id: shiftId
        };
        const url = interpolateUrl(InternalPoolUrls.SEND_SHIFT_EMAIL_NOTIFICATION, urlParams);
        return this.http.post(`${this.settings.CORE}${url}`, {});
    }

    reconfirm(id: number): Observable<any> {
        const urlParams = {
            id: id
        };
        const url = interpolateUrl(InternalPoolUrls.RECONFIRM, urlParams);
        return this.http.put<any>(`${this.settings.CORE}${url}`, {});
    }

    resendInvitationEmail(Id: number) {
        const urlParams = {
            id: Id
        };
        const url = interpolateUrl(InternalPoolUrls.SEND_INVITATION_EMAIL, urlParams);
        return this.http.put(`${this.settings.CORE}${url}`, {});
    }

    getResourceAssignments(
        systemId: number,
        resourceId: number,
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        }
    ) {
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj
        });

        const url = interpolateUrl(InternalPoolUrls.ASSIGNMENTS, { systemId, resourceId });
        return this.http.get<PagingToken<ResourceAssignment[]>>(`${this.settings.CORE}${url}`, { params });
    }

    getCurrentResourceAssignments(
        systemId: number,
        resourceId: number,
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        }
    ) {
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj
        });

        const url = interpolateUrl(InternalPoolUrls.CURRENT_ASSIGNMENTS, { systemId, resourceId });
        return this.http.get<PagingToken<ResourceAssignment[]>>(`${this.settings.CORE}${url}`, { params });
    }

    archiveWorker(id: number): Observable<unknown> {
        var result: Observable<unknown>;
        const body = null;
        const url = interpolateUrl(InternalPoolUrls.ARCHIVE_WORKER, { id });
        result = this.http.put(`${this.settings.CORE}${url}`, body);

        return result;
    }

    unarchiveWorker(id: number): Observable<unknown> {
        var result: Observable<unknown>;
        const body = null;
        const url = interpolateUrl(InternalPoolUrls.UNARCHIVE_WORKER, { id });
        result = this.http.put(`${this.settings.CORE}${url}`, body);

        return result;
    }

    checkResourceEmail(systemId: number, email: string): Observable<CheckEmailResponse> {
        const url = interpolateUrl(InternalPoolUrls.CHECK_USERNAME_AVAILABILITY, { systemId });
        const params = { email };

        return this.http.get<CheckEmailResponse>(`${this.settings.CORE}${url}`, { params });
    }

    checkResourceArchiveStatus(candidateId: number): Observable<boolean> {
        const url = interpolateUrl(InternalPoolUrls.CHECK_RESOURCE_ARCHIVE_STATUS, { candidateId });
        return this.http.get<boolean>(`${this.settings.CORE}${url}`);
    }
}
