import { UntypedFormGroup } from "@angular/forms";
import { merge } from "rxjs";
import { map, startWith, tap } from "rxjs/operators";

export class CombinedForms {
    private readonly forms: UntypedFormGroup[];

    constructor(forms: Array<UntypedFormGroup | null>) {
        this.forms = forms.filter(form => form != null);
    }
    
    get valueChanges() {
        const changes = this.forms.map(form => form.valueChanges);
        return merge(...changes)
            .pipe(
                map(() => this.value)
            );
    }

    get value$() {
        const changes = this.forms.map(form => form.valueChanges);
        return merge(...changes)
            .pipe(
                startWith(this.value),
                map(() => this.value)
            );
    }

    get isValid() {
        return this.forms.every(form => form.valid)
    }

    get isInvalid() {
        return this.forms.some((form) => form.invalid);
    }

    get isDirty() {
        return this.forms.some((form) => form.dirty);
    }

    get isPending() {
        return this.forms.some((form) => form.pending);
    }

    get value() {
        return this.forms.reduce((v, form) => Object.assign(v, form.value), {});
    }

    get errors() {
        return this.forms.reduce((errors, form) => {
            const controlsErrors = Object.keys(form.controls)
                .filter((c) => form.controls[c].invalid)
                .reduce((errors, c) => ({ ...errors, [c]: form.get(c).errors }), {});
            return { ...errors, ...controlsErrors };
        }, {});
    }

    markAsDirty() {
        for (const form of this.forms) {
            form.markAsDirty();
        }
    }

    markAllAsTouched() {
        for (const form of this.forms) {
            form.markAllAsTouched();
        }
    }

    markAsPristine() {
        for (const form of this.forms) {
            form.markAsPristine();
        }
    }
}
