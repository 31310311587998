import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MultipleDatesFormatPipe } from 'src/app/shared/pipes/multiple-dates-format.pipe';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FeatureFlag } from '../../models/enums/feature-flag.enum';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'datepicker-multiple',
    templateUrl: './datepicker-multiple.component.html',
    styleUrls: ['./datepicker-multiple.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerMultipleComponent),
            multi: true
        },
        MultipleDatesFormatPipe
    ]
})
export class DatepickerMultipleComponent extends UnsubscribeOnDestroy implements OnInit, ControlValueAccessor {
    changed: any;
    value: Date[];

    @Input() format: (dates: Date[], viewValue: string) => string;

    @Input('min') minDate: Date;
    @Input('max') maxDate: Date;
    @Input() title = 'Select Dates';
    @Input() disabledDates: Date[];
    @Input() disabledDatesStyle: 'none' | 'fill' = 'none';
    @Input() extraDateClass: (date: Date) => string;
    @Input() multi = true;
    @Input() showConfirmationCount = false;
    @Input() panelClass: string;
    @Input() validateField: boolean = false;
    @Input() useMaterialIcon: boolean = false;
    isDisabled = false;
    dateControl: FormControl;
    featureFlag = FeatureFlag;
    @Input() showRequiredAsterisk: boolean = false;

    @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

    constructor(
        private readonly dialog: MatDialog,
        private readonly datesFormatPipe: MultipleDatesFormatPipe,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    writeValue(val: any): void {
        this.value = val;
        this.dateControl.setValue(this.viewValue);
    }

    registerOnChange(fn: any): void {
        this.changed = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
        if (isDisabled) {
            this.dateControl.disable();
        } else {
            this.dateControl.enable();
        }
    }

    ngOnInit(): void {
        this.dateControl = new FormControl(
            { value: this.value, disabled: this.isDisabled },
            this.validateField ? Validators.required : null
        );
    }

    get viewValue() {
        if (!this.value) {
            return '';
        }

        const viewValue = this.datesFormatPipe.transform(this.value);

        if (typeof this.format === 'function') {
            return this.format(this.value, viewValue);
        }
        return viewValue;
    }

    get control() {
        return this.dateControl;
    }

    onSave(newValue: Date[]) {
        this.trigger.closeMenu();
        this.value = newValue;
        this.dateControl.setValue(newValue);
        if (typeof this.changed === 'function') {
            this.changed(newValue);
        }
    }

    onCancel() {
        this.trigger.closeMenu();
    }
}
