import { LoadingTypes } from 'src/app/shared/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClinicalState, clinicalStoreKey } from '../clinical.state';

export const selectGridFeature = createFeatureSelector<ClinicalState>(clinicalStoreKey);

export const selectIsGridLoading = createSelector(
    selectGridFeature,
    (state) => state.ClientGridState.loading === LoadingTypes.LOADING
);

export const selectClientsGridData = createSelector(selectGridFeature, (state) => ({
    data: state.ClientGridState.data,
    total: state.ClientGridState.total
}));

export const selectClientsGridQuery = createSelector(selectGridFeature, (state) => state.ClientGridState.query);

export const selectClinicalManagers = createSelector(
    selectGridFeature,
    (state) => state.ClientGridState.clinicalManagers
);

export const selectSetClinicalManagersRequest = createSelector(
    selectGridFeature,
    (state) => state.ClientGridState.setClinicalManagerRequest
);
