import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from 'src/app/shared/utilities';

@Pipe({
    name: 'requestTimeOff'
})
export class RequestTimeOffPipe implements PipeTransform {
    transform(value: Date[], dateFormat: string = 'MM/DD/YYYY', isUtc = false): string {
        return DateHelper.rtoDatesToString(value, dateFormat, isUtc);
    }
}
