import { ValidatorFn } from '@angular/forms';

export function minAgeValidator(minAge: number): ValidatorFn {
    return function(control) {
        if (!control.value) {
            return null;
        }

        const currentYear = (new Date()).getFullYear();
        const selectedYear = (new Date(control.value)).getFullYear();

        if (currentYear - selectedYear < minAge) {
            return { 'min-age': minAge }
        }

        return null;
    }
}

export function maxAgeValidator(maxAge: number): ValidatorFn {
    return function(control) {
        if (!control.value) {
            return null;
        }

        if (maxAge) {
            const currentYear = (new Date()).getFullYear();
            const selectedYear = (new Date(control.value)).getFullYear();

            if (currentYear - selectedYear > maxAge) {
                return { 'max-age': maxAge }
            }
        }

        return null;
    }
}
