import { createAction, props } from '@ngrx/store';
import { PermJob } from 'src/app/permanent-jobs/models/perm-job.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';

export enum PermJobsActionTypes {
    SetPermJobsSearchQuery = '[Perm Jobs Component] Set Perm Jobs Search Query',
    GetPermJobs = '[Perm Jobs Component] Get Perm Jobs',
    GetPermJobsSuccess = '[Perm Jobs Api] Get Filtered Perm Jobs - Success',
    GetPermJobsFail = '[Perm Jobs Api] Get Filtered Perm Jobs - Fail'
}

export const getPermJobs = createAction(PermJobsActionTypes.GetPermJobs);

export const getPermJobsSuccess = createAction(
    PermJobsActionTypes.GetPermJobsSuccess,
    props<{ permJobs: PermJob[]; permJobsTotal: number }>()
);

export const getPermJobsFail = createAction(PermJobsActionTypes.GetPermJobsFail);

export const setPermJobsSearchQuery = createAction(
    PermJobsActionTypes.SetPermJobsSearchQuery,
    props<{ query: GridSearchQuery }>()
);
