import { Injectable } from '@angular/core';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractGroupDefinition } from 'src/app/shared/models/custom-fields/contract-group-definition.model';
import { CustomFieldService } from 'src/app/shared/services/custom-field.service';

export interface CustomFieldResolverData {
    isNew: boolean;
    field: ContractGroupDefinition;
    contractGroupId: number;
}

@Injectable({
    providedIn: 'root',
})
export class CustomFieldsResolver implements Resolve<CustomFieldResolverData> {
    constructor(private readonly cfService: CustomFieldService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<CustomFieldResolverData> {
        const fieldId = new Number(route.params['id']);
        const contractGroupId = new Number(route.params['contractGroup']);

        if (fieldId.valueOf()) {
            return this.cfService.getFieldById(fieldId.valueOf()).pipe(
                map((result) => {
                    return {
                        isNew: result === null || result === undefined,
                        field: result,
                        contractGroupId: contractGroupId.valueOf()
                    };
                })
            );
        } else {
            return of({ isNew: true, field: null, contractGroupId: contractGroupId.valueOf() });
        }
    }
}
