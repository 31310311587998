import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VendorCandidatesDetailFilesComponent } from 'src/app/admin/vendor-candidates-detail/components/vendor-candidates-detail-files/vendor-candidates-detail-files.component';
import { PowerBiReportComponent } from 'src/app/powerbi/powerbi-report.component';
import { CustomFieldsResolver } from './resolvers/custom-fields.resolver';
import { CustomFieldDetailsComponent } from './custom-field-details/custom-field-details.component';
import { VendorCandidatesComponent } from './vendor-candidates/vendor-candidates.component';
import { SubmittalsComponent } from 'src/app/admin/submittals/components/submittals/submittals.component';
import { SubmittalsAdminDetailsComponent } from './submittals/submittals-details/submittals-admin-details.component';
import { AdminJobsComponent } from 'src/app/admin/jobs/admin-jobs.component';
import { VendorLocationsComponent } from 'src/app/admin/vendor-details/profile/vendor-locations/vendor-locations.component';
import { AdminJobDetailsComponent } from 'src/app/admin/jobs/details/admin-job-details.component';
import { SystemContactsDetailComponent } from 'src/app/admin/system-contacts/components/system-contacts-detail/system-contacts-detail.component';
import { PendingChangesGuard } from '../shared/guards/pending-changes.guard';
import { VendorCandidateDetailsComponent } from './vendor-candidates/components/vendor-candidate-details/vendor-candidate-details.component';
import { VendorDetailsComponent } from './vendor-details/vendor-details/vendor-details.component';

export const adminModuleRoutes: Routes = [
    {
        path: 'reports/powerbi/:code',
        component: PowerBiReportComponent
    },
    {
        path: 'facilities',
        loadChildren: () => import('src/app/facilities/facilities.module').then((m) => m.FacilitiesModule)
    },
    {
        path: 'jobs',
        component: AdminJobsComponent
    },
    {
        path: 'job/:id',
        component: AdminJobDetailsComponent
    },
    {
        path: 'custom-field/:contractGroup/:id',
        component: CustomFieldDetailsComponent,
        resolve: {
            data: CustomFieldsResolver
        },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'vendorCandidates',
        component: VendorCandidatesComponent
    },
    {
        path: 'vendorCandidates/details',
        component: VendorCandidatesDetailFilesComponent
    },
    {
        path: 'submittals',
        component: SubmittalsComponent
    },
    {
        path: 'submittal/:id',
        canDeactivate: [PendingChangesGuard],
        component: SubmittalsAdminDetailsComponent
    },
    {
        path: 'vendor/:vendorId/locations',
        component: VendorLocationsComponent
    },
    {
        path: 'vendor/:id',
        canDeactivate: [PendingChangesGuard],
        runGuardsAndResolvers: 'always',
        component: VendorDetailsComponent
    },
    {
        path: 'systemContact/:id',
        canDeactivate: [PendingChangesGuard],
        runGuardsAndResolvers: 'always',
        component: SystemContactsDetailComponent
    },
    {
        path: 'vendorCandidate/:id',
        canDeactivate: [PendingChangesGuard],
        runGuardsAndResolvers: 'always',
        component: VendorCandidateDetailsComponent
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(adminModuleRoutes)],
    exports: [RouterModule]
})
export class AdminRoutingModule {}
