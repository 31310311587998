import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    loadSpecialties,
    loadSpecialtiesSuccess,
    loadSpecialtiesFail,
    componentLoaded
} from '../perdiemscheduler.actions';

export interface PerdiemSchedulerSpecialtiesState {
    list: Array<{ id: number; name: string }>;
    loading: LoadingTypes;
}

export const getInitialPerdiemSchedulerSpecialtiesState = (): PerdiemSchedulerSpecialtiesState => ({
    list: [],
    loading: LoadingTypes.INIT
});

export function perdiemSchedulerSpecialtiesReducer(state: PerdiemSchedulerSpecialtiesState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerSpecialtiesState(),
        on(loadSpecialties, componentLoaded, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadSpecialtiesSuccess, (state, action) => ({
            ...state,
            list: action.specialties,
            loading: LoadingTypes.LOADED
        })),
        on(loadSpecialtiesFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: PerdiemSchedulerSpecialtiesState | undefined, action: Action) {
    return perdiemSchedulerSpecialtiesReducer(state, action);
}
