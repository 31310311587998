import { LoadingTypes } from 'src/app/shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import { AyaLeadsListActions } from '../actions';
import { TypedAction } from '@ngrx/store/src/models';
import { AyaLeadsListItem } from 'src/app/aya-leads/models/aya-leads-list-item';

export interface AyaLeadsListResultsState {
    ayaLeads: AyaLeadsListItem[];
    ayaLeadsTotal: number;
    loading: LoadingTypes;
}

export const ayaLeadsListResultsReducerInitialState: AyaLeadsListResultsState = {
    ayaLeads: [],
    ayaLeadsTotal: 0,
    loading: LoadingTypes.INIT
};

type LoadAyaLeadsSuccessActionType = {
    ayaLeads: AyaLeadsListItem[];
    total: number;
} & TypedAction<AyaLeadsListActions.AyaLeadsActionTypes.GetAyaLeadsSuccess>;

export const ayaLeadsListResultsReducer = (
    reducerState: AyaLeadsListResultsState,
    reducerAction: Action
): AyaLeadsListResultsState => {
    return createReducer(
        ayaLeadsListResultsReducerInitialState,
        on(
            AyaLeadsListActions.loadAyaLeadsPage,
            AyaLeadsListActions.updateAyaLeadsResults,
            AyaLeadsListActions.exportAyaLeads,
            (state: AyaLeadsListResultsState): AyaLeadsListResultsState => ({
                ...state,
                loading: LoadingTypes.LOADING
            })
        ),
        on(
            AyaLeadsListActions.loadAyaLeadsSuccess,
            (state: AyaLeadsListResultsState, action: LoadAyaLeadsSuccessActionType): AyaLeadsListResultsState => ({
                ...state,
                ayaLeads: action.ayaLeads,
                ayaLeadsTotal: action.total,
                loading: LoadingTypes.LOADED
            })
        ),
        on(
            AyaLeadsListActions.loadAyaLeadsFail,
            (state: AyaLeadsListResultsState): AyaLeadsListResultsState => ({
                ...state,
                ayaLeads: [],
                ayaLeadsTotal: 0,
                loading: LoadingTypes.FAIL
            })
        ),

        on(
            AyaLeadsListActions.clearAyaLeads,
            (): AyaLeadsListResultsState => ({
                ...ayaLeadsListResultsReducerInitialState
            })
        ),

        on(
            AyaLeadsListActions.setAyaLeadsGridFilterStateAndUpdateResults,
            (state): AyaLeadsListResultsState => ({
                ...state
            })
        ),

        on(
            AyaLeadsListActions.exportAyaLeadsSuccess,
            (state: AyaLeadsListResultsState): AyaLeadsListResultsState => ({
                ...state,
                loading: LoadingTypes.LOADED
            })
        ),

        on(
            AyaLeadsListActions.exportAyaLeadsFail,
            (state: AyaLeadsListResultsState): AyaLeadsListResultsState => ({
                ...state,
                loading: LoadingTypes.FAIL
            })
        )
    )(reducerState, reducerAction);
};

export const reducer = (state: AyaLeadsListResultsState | undefined, action: Action) => {
    return ayaLeadsListResultsReducer(state, action);
};
