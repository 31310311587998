import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/facility-vendors.actions';
import { LoadingTypes } from 'src/app/shared/models';
import {
    FacilityVendorsState,
    getInitialFacilityVendorsState,
    getInitialGridState,
    setQueryTake
} from '../state/facility-vendors.state';

export function facilityVendorsReducer(state: FacilityVendorsState | undefined, action: Action) {
    return createReducer(
        getInitialFacilityVendorsState(),

        on(actions.loadFacilityVendorsGrid, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.loadFacilityVendorsGridSuccess, (state, action) => ({
            ...state,
            ...action,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.loadFacilityVendorsGridFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.loadFacilityVendorIds, (state) => {
            return { ...state, loadingState: LoadingTypes.LOADING };
        }),
        on(actions.loadFacilityVendorIdsSuccess, (state, action) => ({
            ...state,
            ids: action.ids,
            error: '',
            loadingState: LoadingTypes.LOADED
        })),
        on(actions.loadFacilityVendorIdsFailure, (state, action) => ({
            ...state,
            ids: [],
            error: JSON.stringify(action.error),
            loadingState: LoadingTypes.FAIL
        })),

        on(actions.offerFacilityVendors, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.offerFacilityVendorsSuccess, (state, action) => ({ ...state, loadingState: LoadingTypes.LOADED })),

        on(actions.offerFacilityVendorsFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.excludeFacilityVendors, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.loadFacilityVendorsGridSuccess, (state, action) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.loadFacilityVendorsGridFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.offerFacilityVendors, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.excludeFacilityVendorsSuccess, (state, action) => ({ ...state, loadingState: LoadingTypes.LOADED })),

        on(actions.excludeFacilityVendorsFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.acceptedFacilityVendors, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.acceptedFacilityVendorsSuccess, (state, action) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.acceptedFacilityVendorsFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.tsSignedFacilityVendors, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.tsSignedFacilityVendorsSuccess, (state, action) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.tsSignedFacilityVendorsFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.exportFacilityVendors, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.exportFacilityVendorsSuccess, (state, action) => ({ ...state, loadingState: LoadingTypes.LOADED })),

        on(actions.exportFacilityVendorsFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.setFacilityVendorsSearchQuery, (state, action) => ({
            ...state,
            gridSearchQuery: action.searchQuery
        })),

        on(actions.loadVendorLookups, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.loadVendorLookupsSuccess, (state, action) => ({
            ...state,
            lookups: action.lookups,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.loadVendorLookupsFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.updateTSSigned, (state, action) => ({
            ...state,
            facilityId: action.facilityId,
            params: action.params,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.updateTSSignedSuccess, (state) => ({ ...state, loadingState: LoadingTypes.LOADED })),

        on(actions.updateTSSignedFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.exportAll, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.exportAllSuccess, (state, action) => ({ ...state, loadingState: LoadingTypes.LOADED })),

        on(actions.exportAllFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.exportSelected, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.exportSelectedSuccess, (state, action) => ({ ...state, loadingState: LoadingTypes.LOADED })),

        on(actions.exportSelectedFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.clearVendorFilters, (state) => ({ ...state, gridSearchQuery: getInitialGridState() })),

        on(actions.getVendorStatusHistory, (state, action) => ({
            ...state,
            vendorId: action.vendorId,
            facilityId: action.facilityId,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.getVendorStatusHsitorySuccess, (state, action) => ({
            ...state,
            history: action.history,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.getVendorStatusHsitoryFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.saveVendorStatusHsitory, (state, action) => ({
            ...state,
            ...action,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.saveVendorStatusHsitorySuccess, (state) => ({ ...state, loadingState: LoadingTypes.LOADED })),

        on(actions.saveVendorStatusHsitoryFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.updateVendorStatusHistory, (state, action) => ({
            ...state,
            ...action,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.updateVendorStatusHistorySuccess, (state) => ({ ...state, loadingState: LoadingTypes.LOADED })),

        on(actions.updateVendorStatusHistoryFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.setFacilityVendorSelectAll, (state, action) => {
            return {
                ...state,
                gridSearchQuery: {
                    ...state.gridSearchQuery,
                    take: action.isAllSelected ? state.total : getInitialGridState().take,
                    skip: 0
                },
                loadingState: LoadingTypes.LOADING,
                isAllSelected: action.isAllSelected
            };
        }),

        on(actions.resetSearchQueryPagination, (state, action) => {
            return {
                ...state,
                gridSearchQuery: {
                    ...state.gridSearchQuery,
                    take: getInitialGridState().take
                }
            };
        })
    )(state, action);
}
