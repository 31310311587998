import { createAction, props } from '@ngrx/store';
import { ValidationErrorResponse } from 'src/app/shared/models/validation-error-response';
import { OfferCreate } from 'src/app/submittals/models';

export enum OffersCreateActionEnum {
    CreateOfferDetails = '[SUBMITTALS OFFER MODAL] Create Offer',
    CreateOfferDetailsSuccess = '[SUBMITTALS API] Create Offer success',
    CreateOfferDetailsFailure = '[SUBMITTALS API] Create Offer fail'
}

export const createOffer = createAction(
    OffersCreateActionEnum.CreateOfferDetails,
    props<{ offer: OfferCreate; useAyaAccountManagement: boolean }>()
);
export const createOfferSuccess = createAction(
    OffersCreateActionEnum.CreateOfferDetailsSuccess,
    props<{ message: string }>()
);
export const createOfferFailure = createAction(
    OffersCreateActionEnum.CreateOfferDetailsFailure,
    props<{ error: ValidationErrorResponse }>()
);
