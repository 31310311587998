import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Settings, APP_CONFIG } from 'src/app/config/settings';
import { SortTypes } from 'src/app/shared/models';
import { interpolateParams } from 'src/app/core/utils/interpolate-params';
import { Jobs, JobsLookups, VendorJobDetails, VendorJobRequirements } from 'src/app/shared/models/jobs';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ListItemWithDescription } from 'src/app/shared/models/list-item';
import { FileAttachment } from 'src/app/shared/models/attachment';
import { RequirementDescriptions } from 'src/app/shared/models/requirements/requirement-descriptions.model';

export enum JobsVendorUrls {
    JOBS = '/AyaConnect/VendorJobs'
}

@Injectable({
    providedIn: 'root'
})
export class JobsVendorService {
    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly settings: Settings) {}

    getVendorJobs(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgs
        });

        const url = `${this.settings.CORE}${JobsVendorUrls.JOBS}`;
        return this.http.get<PagingToken<Jobs[]>>(url, { params });
    }

    getJobsVendorOpenPositions(matchArgs?: { [key: string]: string }) {
        const params = interpolateParams(matchArgs);
        const url = `${this.settings.CORE}${JobsVendorUrls.JOBS}/positions/open`;
        return this.http.get<number>(url, { params });
    }

    getVendorJobsTotal(matchArgs?: { [key: string]: string }) {
        const params = interpolateParams(matchArgs);
        const url = `${this.settings.CORE}${JobsVendorUrls.JOBS}/total`;
        return this.http.get<number>(url, { params });
    }

    getJobsVendorLookups(): Observable<JobsLookups> {
        const url = `${this.settings.CORE}/AyaConnect/Vendors/Facility/lookups`;
        return this.http.get<JobsLookups>(url);
    }

    getJobDetails(jobId: number): Observable<VendorJobDetails> {
        const url = `${this.settings.CORE}${JobsVendorUrls.JOBS}/${jobId}`;
        return this.http.get<VendorJobDetails>(url);
    }

    getJobRequirements(jobId: number): Observable<VendorJobRequirements[]> {
        const url = `${this.settings.CORE}${JobsVendorUrls.JOBS}/${jobId}/requirements`;
        return this.http.get<VendorJobRequirements[]>(url);
    }

    getJobRequirementDescriptions(jobId: number): Observable<RequirementDescriptions[]> {
        const url = `${this.settings.CORE}${JobsVendorUrls.JOBS}/${jobId}/requirement-descriptions`;
        return this.http.get<RequirementDescriptions[]>(url);
    }

    getJobAttachments(jobId): Observable<FileAttachment[]> {
        const url = `/AyaConnect/Vendor/Jobs/${jobId}/Attachments`;
        return this.http.get<any[]>(`${this.settings.CORE}${url}`);
    }

    downloadJobAttachment(jobId: number, id: number) {
        const url = `/AyaConnect/Vendor/Jobs/${jobId}/Attachments/${id}`;
        return this.http.get(`${this.settings.CORE}${url}`, { responseType: 'blob' });
    }

    getJobAttachmentPreview(jobId: number, id: number, pageNum: number): Observable<Blob> {
        const url = `/AyaConnect/Vendor/Jobs/${jobId}/Attachments/Preview/${id}?pageNum=${pageNum}`;
        return this.http.get(`${this.settings.CORE}${url}`, { responseType: 'blob' });
    }

    getJobAttachmentsTotalPages(jobId: number, id: number): Observable<number> {
        const url = `/AyaConnect/Vendor/Jobs/${jobId}/Attachments/pageCount/${id}`;
        return this.http.get<number>(`${this.settings.CORE}${url}`);
    }

    export(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgs
        });

        const url = `${this.settings.CORE}${JobsVendorUrls.JOBS}/export`;
        return this.http.get(url, { params, responseType: 'blob' });
    }

    getVaccinationTypes(): Observable<ListItemWithDescription[]> {
        const url = `${this.settings.CORE}/AyaConnect/VendorJobs/vaccination-types`;
        return this.http.get<ListItemWithDescription[]>(url);
    }
}
