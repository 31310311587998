/**angular */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

/**ngrx rxjs */
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

/**shared */
import { ContactChange, ContactTag } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';

/**local */
import { SystemContact } from 'src/app/admin/system-contacts/models/system-contact.model';
import * as selectors from 'src/app/admin/store/selectors/system-contacts-profile.selectors';
import * as actions from 'src/app/admin/store/actions/system-contacts-profile.actions';
import * as rolesSelectors from 'src/app/admin/role-permissions/store/selectors/role-permissions.selectors';
import * as rolesActions from 'src/app/admin/role-permissions/store/actions/role-permissions.actions';
import { Contact } from 'src/app/shared/components/contact-profile/models/contact.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { OrganizationInvitation } from 'src/app/shared/models/account/organization-invitation.model';
import { SendOrganizationInvitation } from 'src/app/shared/models/account/send-organization-invitation.model';
import { IdentityProvider } from 'src/app/shared/models/sso/identity-provider.model';
import { Role } from "src/app/admin/role-permissions/models";
import { LDFeatureManager } from "src/app/shared/feature-management/ld-feature-manager";

@Component({
    selector: 'ayac-system-contacts-detail',
    templateUrl: './system-contacts-detail.component.html',
    styleUrls: ['./system-contacts-detail.component.scss']
})
export class SystemContactsDetailComponent extends UnsubscribeOnDestroy implements OnInit {
    isLoading$: Observable<boolean>;
    contact$: Observable<SystemContact>;
    contactTypeTags$: Observable<ContactTag[]>;
    systems$: Observable<ListItem[]>;
    identityProviders$: Observable<IdentityProvider[]>;
    invitation$: Observable<OrganizationInvitation>;
    roles$: Observable<Role[]> = of([]);
    invalidChange: boolean = false;
    hasChanges: boolean = false;
    isSaving: boolean = false;

    constructor(
        private readonly _store: Store,
        private readonly _route: ActivatedRoute,
        readonly router: Router,
        private readonly _dialog: DialogService,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.isLoading$ = this._store.select(selectors.selectSystemContactsProfileIsLoading);
        this.contact$ = this._store.select(selectors.selectSystemContactsProfile);
        this.contactTypeTags$ = this._store.select(selectors.selectSystemContactTypes);
        this.systems$ = this._store.select(selectors.selectHospitalSystems);
        this.identityProviders$ = this._store.select(selectors.selectIdentityProviders);
        this.invitation$ = this._store.select(selectors.selectInvitation);
        this.roles$ = this._store.select(rolesSelectors.selectRoles);

        this._route.params
            .pipe(
                map((params: Params) => params.id),
                takeUntil(this.d$)
            )
            .subscribe((contactId: string) => {
                if (contactId === 'new') {
                    this._store.dispatch(actions.createNewSystemContactAndLoadRelatedData());
                } else {
                    const systemContactId = Number(contactId);
                    this._store.dispatch(actions.loadSystemContactAndRelatedData({ systemContactId }));
                }
            });

        this._store.dispatch(rolesActions.getRoles());
    }

    contactInfoChanged(changedContact: ContactChange): void {
        const systemContact = this.convertContactToSystemContact(changedContact.contact);
        this.hasChanges = true;
        this.setPhoneParts(systemContact);
        this._store.dispatch(actions.updateSystemContactsProfile({ systemContact }));

        this.invalidChange = changedContact.isInvalidChange;
    }

    identityProviderChanged(identityProvider: string): void {
        this._store.dispatch(actions.changeIdentityProvider({ identityProvider }));
    }

    sendInvitaion(invitationSent: SendOrganizationInvitation): void {
        this._store.dispatch(
            actions.sendInvitaion({ userId: invitationSent.userId, identityProvider: invitationSent.identityProvider })
        );
    }

    back(): void {
        this.router.navigateByUrl('/admin/systemContacts');
    }

    cancel(): void {
        this.hasChanges = false;
        this.router.navigateByUrl('/admin/systemContacts');
    }

    save(): void {
        this.isSaving = true;
        this._store.dispatch(actions.saveSystemContactsProfile());
    }

    setPhoneParts(systemContact: SystemContact) {
        const fullNumber = systemContact.phone;
        const value = fullNumber?.replace(/\D/g, '');

        systemContact.phoneCountryCode = fullNumber ? '1' : '';
        systemContact.phoneAreaCode = fullNumber ? value.substring(0, 3) : '';
        systemContact.phoneExchange = fullNumber ? value.substring(3, 6) : '';
        systemContact.phoneStation = fullNumber ? value.substring(6, 10) : '';
        systemContact.phoneExtension = fullNumber ? value.substring(10, value.length) : '';
    }

    convertContactToSystemContact(contact: Contact): SystemContact {
        return {
            ...contact,
            systemId: contact.systemOrClientId,
            systemContactTypeTags: contact.typeTags
        };
    }

    convertSystemContactToCommonContact(systemContact: SystemContact): Contact {
        return {
            ...systemContact,
            systemOrClientId: systemContact.systemId,
            phone: `${systemContact?.phoneAreaCode ?? ''}${systemContact?.phoneExchange ?? ''}${
                systemContact?.phoneStation ?? ''
            }${systemContact?.phoneExtension ?? ''}`,
            typeTags: systemContact.systemContactTypeTags
        };
    }

    canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
        return this.hasChanges && !this.isSaving ? this.openChangedNotSavedDialog() : true;
    }

    private openChangedNotSavedDialog(): Promise<boolean> {
        return this._dialog.openConfirmationDialog({
            data: {
                title: 'System Contact Details',
                text: 'You have unsaved changes! Do you want to leave?'
            }
        });
    }
}
