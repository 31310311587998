import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class FormValueState {
    state$: Observable<any>;
    private readonly _subscription: Subscription;

    constructor(formGroup: UntypedFormGroup) {
        const formState = new BehaviorSubject(formGroup.value);
        this._subscription = formGroup.valueChanges.subscribe((values) => {
            formState.next(values);
        });

        this.state$ = formState.asObservable();
    }

    complete() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    get(controlName: string) {
        return this.state$.pipe(
            map((state) => state[controlName]),
            distinctUntilChanged()
        );
    }
}
