import { createSelector } from "@ngrx/store";
import { LoadingTypes } from "src/app/shared/models";
import { selectFeature } from "../submittals.selectors";

export const selectSubmittalsInternal = createSelector(
	selectFeature,
    (state) =>
        state.detailsInternal
);

export const selectSubmittalsInternalProfile = createSelector(
	selectSubmittalsInternal,
    (state) =>
        state.profile
);
export const selectSubmittalsInternalJobs = createSelector(
	selectSubmittalsInternal,
    (state) =>
        state.jobs
);


export const selectSubmittalsInternalLoading = createSelector(
	selectSubmittalsInternal,
    (state) =>
        [
            state.jobsLoading,
            state.detailsLoading
        ].includes(LoadingTypes.LOADING)
);

export const selectSubmittalsInternalStatusLoading = createSelector(
	selectSubmittalsInternal,
    (state) =>
        [
            state.statusLoading,
            state.bookLoading
        ].includes(LoadingTypes.LOADING)
);

