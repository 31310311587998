import { SubmittalsListState } from './reducers/submittals-list.reducer';
import { SubmittalApplicationState } from './reducers/submittal-application.reducer';
import { SubmittalDetailsState } from './reducers/submittal-details.reducer';
import { SubmittalLookupsState } from './reducers/submittals-lookups.reducer';
import { SubmittalDetailsInternalState } from './reducers/submittal-details-internal.reducer';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { SubmittalJobDetailsState } from './reducers/submittal-job-details.reducer';
import { SubmittalOfferDetailsState } from 'src/app/submittals/store/reducers/submittal-offer-details.reducer';

export const submittalsStoreKey = 'client/submittals';

export interface SubmittalsState {
    list: SubmittalsListState;
    application: SubmittalApplicationState;
    details: SubmittalDetailsState;
    detailsInternal: SubmittalDetailsInternalState;
    lookups: SubmittalLookupsState;
    query: GridSearchQuery;
    job: SubmittalJobDetailsState;
    offer: SubmittalOfferDetailsState;
}
