import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { JobUnit } from 'src/app/clinical/models/job-unit-model';
import { NyuLookup } from 'src/app/clinical/models/nyu-lookup.model';
import { UnitFacilityInformation } from 'src/app/clinical/models/unit-facility-information.model';
import { Unit } from 'src/app/clinical/models/unit.model';
import * as actions from 'src/app/clinical/store/actions/client-units.actions';
import {
    selectNyuBuildings,
    selectNyuDepartments,
    selectNyuFloors
} from 'src/app/clinical/store/selectors/client-units.selectors';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager as LdFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { emailValidator } from 'src/app/shared/utilities';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

export const hospitalTypes = {
    WorkforceDisruptionClinical: 7,
    WorkforceDisruptionNonClinical: 8
};

export const DAY_FIRST_FORMAT = {
    parse: {
        dateInput: 'DD-MMMM-YYYY'
    },
    display: {
        dateInput: 'DD-MMMM-YYYY',
        monthYearLabel: 'MMM YYYY'
    }
};

export enum nyuLookupTypes {
    building = 0,
    department = 1,
    floor = 2
}

type inputValueType = string | null | NyuLookup;

@Component({
    selector: 'ayac-facility-information',
    templateUrl: './facility-information.component.html',
    styleUrls: ['./facility-information.component.scss'],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: DAY_FIRST_FORMAT }]
})
export class FacilityInformationComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy, OnChanges {
    private _facilityInformationUnit: UnitFacilityInformation;
    featureFlag = FeatureFlag;
    unit: Unit;
    _hospId: string;
    nyuSystemId = 913;
    phoneMask = '(999) 999-9999';
    separatorKeysCodes = [ENTER, COMMA];

    placeholder = 'Select a discipline first';
    placeholderSpecialty = this.placeholder;

    @Input() canShowContact = false;

    @Input() hideTrackFieldForClientViewOfUD = false;

    @Input()
    set facilityInformationUnit(facilityInformationUnit: UnitFacilityInformation) {
        if (!facilityInformationUnit) {
            return;
        }
        this._facilityInformationUnit = facilityInformationUnit;
        this.checkWorkForceDisruption(this._facilityInformationUnit);
        this.unit = facilityInformationUnit?.unit;

        this.fillLookups(this._facilityInformationUnit);
        this.setFormValues(this._facilityInformationUnit);
        this.isLoading = false;
    }
    get facilityInformationUnit() {
        return this._facilityInformationUnit;
    }

    @Input() readOnly = false;

    @Output() valueChanged = new EventEmitter<{ unit: Partial<Unit>; isValid: boolean }>();

    @Output() clinicalTracksChanged = new EventEmitter<number | null>();

    @Input() isPrinting: boolean = false;

    facilityInformationForm: UntypedFormGroup;

    isMultipleDiscipline = false;

    lookups = {
        nyuDepartments: [],
        nyuBuildings: [],
        nyuFloors: [],
        professions: [],
        specialties: [],
        professionSpecialties: [],
        clinicalTracks: []
    };

    currentProfessionId: number;
    currentSpecialtyId: number = -1;

    unitSpecialties = [];

    isWorkForceDisruption = false;

    hideWfdRestrictedFields = false;
    isEnabledHideUnitDescriptionRestrictedFields = false;

    nyuBuildingCtrl = this._formBuilder.control('');
    @ViewChild('nyuBuildingsInput') nyuBuildingsInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoBuilding') matAutocomplete: MatAutocomplete;
    filteredNyuBuildings$: Observable<NyuLookup[]>;

    nyuDepartmentCtrl = this._formBuilder.control('');
    @ViewChild('nyuDepartmentsInput') nyuDepartmentsInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoDepartment') autoDepartmentCompleted: MatAutocomplete;
    filteredNyuDepartments$: Observable<NyuLookup[]>;

    nyuFloorCtrl = this._formBuilder.control('');
    @ViewChild('nyuFloorsInput') nyuFloorsInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoFloor') autoFloorCompleted: MatAutocomplete;
    filteredNyuFloors$: Observable<NyuLookup[]>;
    isLoading = false;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _store: Store,
        private readonly _ldFeatureManager: LdFeatureManager,
        private readonly _identityService: IdentityService
    ) {
        super();
        this.createForm();
    }

    ngOnInit(): void {
        this.isLoading = true;

        this.facilityInformationForm.get('professions').valueChanges.subscribe((value) => {
            if (value) {
                this.facilityInformationForm.get('professions').setValidators(Validators.required);
            }
        });

        this.facilityInformationForm.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => {
            const _unit = this.getFormValues();
            this.placeholderSpecialty =
                _unit.professions && _unit.professions.length > 0 ? 'Select a Specialty' : this.placeholder;
            this.valueChanged.emit({ unit: _unit, isValid: this.facilityInformationForm.valid });
        });

        this._store.dispatch(actions.loadNyuBuildings());
        this._store.dispatch(actions.loadNyuDepartments());

        this._store
            .select(selectNyuBuildings)
            .pipe(takeUntil(this.d$))
            .subscribe((value) => {
                this.setNYUBuildingLookup(value.nyuBuildings);
            });

        this._store
            .select(selectNyuDepartments)
            .pipe(takeUntil(this.d$))
            .subscribe((value) => {
                this.setNYUDepartmentsgLookup(value.nyuDepartments);
            });

        this._store
            .select(selectNyuFloors)
            .pipe(takeUntil(this.d$))
            .subscribe((value) => {
                this.setNYUFloorsLookup(value.nyuFloors);
            });

        this.filteredNyuBuildings$ = this.facilityInformationForm.get('nyuBuildingCtrl').valueChanges.pipe(
            startWith(null as string),
            debounceTime(300),
            map((value) => this.filterNyuBuilding(value)),
            takeUntil(this.d$)
        );

        this.filteredNyuDepartments$ = this.facilityInformationForm.get('nyuDepartmentCtrl').valueChanges.pipe(
            startWith(null as string),
            debounceTime(300),
            map((value) => this.filterNyuDepartment(value)),
            takeUntil(this.d$)
        );

        this.filteredNyuFloors$ = this.facilityInformationForm.get('nyuFloorCtrl').valueChanges.pipe(
            startWith(null as string),
            debounceTime(300),
            map((value) => this.filterNyuFloor(value)),
            takeUntil(this.d$)
        );

        this._ldFeatureManager
            .isEnabled(FeatureFlag.WfdHideUnitDescriptionRestrictedFields)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.isEnabledHideUnitDescriptionRestrictedFields = isEnabled;
                this.checkHideWfdRestrictedFields();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.facilityInformationForm, changes.readOnly.currentValue);
            refreshDisableEnableState(this.facilityInformationForm, this.currentProfessionId <= 0 || this.readOnly, [
                'specialties'
            ]);
        }
    }

    getExtraField(lookupType: nyuLookupTypes): number {
        let key = '';
        if (!this.unit || !this.unit.extraFields || this.unit.extraFields.length <= 0) {
            return -1;
        }
        switch (lookupType) {
            case nyuLookupTypes.building:
                key = 'BuildingId';
                break;
            case nyuLookupTypes.department:
                key = 'DepartmentId';
                break;
            case nyuLookupTypes.floor:
                key = 'FloorId';
                break;
        }

        const keys = this.unit.extraFields?.filter((x) => x.key === key);
        if (keys && keys.length > 0) {
            return Number(keys[0].value);
        }
        return -1;
    }

    setExtraField(lookupType: nyuLookupTypes, value: string) {
        let key = '';
        if (!this.unit || !this.unit.extraFields || this.unit.extraFields.length <= 0) {
            return;
        }
        switch (lookupType) {
            case nyuLookupTypes.building:
                key = 'BuildingId';
                break;
            case nyuLookupTypes.department:
                key = 'DepartmentId';
                break;
            case nyuLookupTypes.floor:
                key = 'FloorId';
                break;
        }
        const keys = this.unit.extraFields?.filter((x) => x.key === key);
        if (keys && keys.length > 0) {
            keys[0].value = value;
        }
    }

    setNYULookups(value: NyuLookup[], nyuLookupTypes: nyuLookupTypes, selectedValue) {
        if (value !== undefined && value.length > 0) {
            this.setlookup(nyuLookupTypes, value);
        }
    }

    setNYUBuildingLookup(nyuBuildings) {
        this.setNYULookups(nyuBuildings, nyuLookupTypes.building, this.getExtraField(nyuLookupTypes.building));
    }

    setNYUDepartmentsgLookup(nyuDepartments) {
        this.setNYULookups(nyuDepartments, nyuLookupTypes.department, this.getExtraField(nyuLookupTypes.department));
    }

    setNYUFloorsLookup(nyuFloors) {
        this.setNYULookups(nyuFloors, nyuLookupTypes.floor, this.getExtraField(nyuLookupTypes.floor));
    }

    createForm(): any {
        this.facilityInformationForm = this._formBuilder.group({
            unitLocation: this._formBuilder.control('', [Validators.maxLength(50)]),

            manager: this._formBuilder.control('', [Validators.maxLength(50)]),
            email: this._formBuilder.control('', [emailValidator]),

            secondaryPhone: this._formBuilder.control(''),
            averageDailyCensus: this._formBuilder.control('', [Validators.maxLength(50)]),
            visitsDays: this._formBuilder.control(
                0,
                Validators.compose([Validators.min(0), Validators.max(10000), Validators.maxLength(5)])
            ),
            rooms: this._formBuilder.control(0, [Validators.min(0), Validators.max(10000)]),
            beds: this._formBuilder.control(0, [Validators.min(0), Validators.max(10000)]),
            nyuDepartmentCtrl: this._formBuilder.control(''),
            nyuFloorCtrl: this._formBuilder.control(''),
            nyuBuildingCtrl: this._formBuilder.control(''),
            professions: this._formBuilder.control([]),
            professionsSingle: this._formBuilder.control('', [Validators.required]),
            specialties: this._formBuilder.control(null, [Validators.required]),
            clinicalTrackId: this._formBuilder.control(null),
            nyuBuildings: this._formBuilder.control([]),
            nyuDepartments: this._formBuilder.control([]),
            nyuFloors: this._formBuilder.control([]),
            primaryPhone: this._formBuilder.control('')
        });
    }

    isNyuFacility(): boolean {
        return (
            this._facilityInformationUnit?.facility &&
            this._facilityInformationUnit?.facility?.hospitalSystemId === this.nyuSystemId
        );
    }

    filterNyus(inputValue: inputValueType, lookup: nyuLookupTypes): NyuLookup[] {
        const ctrl = this.getCtrlValue(lookup);

        if (ctrl.length > 0) {
            const ids = ctrl.map((c: NyuLookup) => c.id);

            if (inputValue && typeof inputValue === 'string') {
                const filterValue = inputValue.toLowerCase();
                return this.getlookup(lookup).filter(
                    (nyuL: NyuLookup) => !ids.includes(nyuL.id) && nyuL.description.toLowerCase().includes(filterValue)
                );
            } else {
                return this.getlookup(lookup).filter((n: NyuLookup) => !ids.includes(n.id));
            }
        }

        return this.getlookup(lookup);
    }

    getlookup(lookup: nyuLookupTypes): NyuLookup[] {
        let nyuLookup = [];

        switch (lookup) {
            case nyuLookupTypes.building:
                nyuLookup = this.lookups.nyuBuildings;
                break;
            case nyuLookupTypes.department:
                nyuLookup = this.lookups.nyuDepartments;
                break;
            case nyuLookupTypes.floor:
                nyuLookup = this.lookups.nyuFloors;
                break;
        }
        return nyuLookup;
    }

    setlookup(lookup: nyuLookupTypes, values: NyuLookup[]) {
        switch (lookup) {
            case nyuLookupTypes.building:
                this.lookups.nyuBuildings = values;
                break;
            case nyuLookupTypes.department:
                this.lookups.nyuDepartments = values;
                break;
            case nyuLookupTypes.floor:
                this.lookups.nyuFloors = values;
                break;
        }
    }

    getCtrlValue(lookup: nyuLookupTypes): NyuLookup[] {
        return this.getCtrl(lookup).value;
    }

    getCtrl(lookup: nyuLookupTypes): AbstractControl {
        let ctrl: AbstractControl;

        switch (lookup) {
            case nyuLookupTypes.building:
                ctrl = this.facilityInformationForm.get('nyuBuildings');
                break;
            case nyuLookupTypes.department:
                ctrl = this.facilityInformationForm.get('nyuDepartments');
                break;
            case nyuLookupTypes.floor:
                ctrl = this.facilityInformationForm.get('nyuFloors');
                break;
        }
        return ctrl;
    }

    filterNyuBuilding(inputValue: inputValueType): NyuLookup[] {
        return this.filterNyus(inputValue, nyuLookupTypes.building);
    }

    filterNyuDepartment(inputValue: inputValueType): NyuLookup[] {
        return this.filterNyus(inputValue, nyuLookupTypes.department);
    }

    filterNyuFloor(inputValue: inputValueType): NyuLookup[] {
        return this.filterNyus(inputValue, nyuLookupTypes.floor);
    }

    add(event: MatAutocompleteSelectedEvent, lookupType: nyuLookupTypes): void {
        const value: NyuLookup | null = event.option?.value || null;
        const ctrl = this.getCtrlValue(lookupType);
        if (value) {
            if (ctrl.length <= 0) {
                this.patch(lookupType, value);
            }
            this.clear(lookupType);
        } else {
            console.error('code invalid or not found.');
        }
    }

    patch(lookup: nyuLookupTypes, nyu: NyuLookup) {
        switch (lookup) {
            case nyuLookupTypes.building:
                this.facilityInformationForm.patchValue({ nyuBuildings: [nyu] });
                break;
            case nyuLookupTypes.department:
                this.facilityInformationForm.patchValue({ nyuDepartments: [nyu] });
                break;
            case nyuLookupTypes.floor:
                this.facilityInformationForm.patchValue({ nyuFloors: [nyu] });
                break;
        }
    }

    addBuilding(event: MatAutocompleteSelectedEvent): void {
        this.add(event, nyuLookupTypes.building);
        const value = event.option?.value || null;

        if (value) {
            this.setExtraField(nyuLookupTypes.building, value.id);
            this._store.dispatch(actions.loadNyuFloors({ buildingId: value.id }));
        }
    }

    removeNyuLookupCtrls(lookup: NyuLookup, type: nyuLookupTypes): void {
        const index: number = this.getCtrlValue(type).findIndex((b: NyuLookup) => b.id === lookup.id);

        if (index >= 0) {
            this.clear(type);
            this.getCtrlValue(type).splice(index, 1);
            this.getCtrl(type).updateValueAndValidity();
        }
    }

    removeBuilding(lookup: NyuLookup): void {
        this.removeNyuLookupCtrls(lookup, nyuLookupTypes.building);
    }

    addDepartment(event: MatAutocompleteSelectedEvent): void {
        this.add(event, nyuLookupTypes.department);
    }

    removeDepartments(lookup: NyuLookup): void {
        this.removeNyuLookupCtrls(lookup, nyuLookupTypes.department);
    }

    addFloor(event: MatAutocompleteSelectedEvent): void {
        this.add(event, nyuLookupTypes.floor);
    }

    removeFloor(lookup: NyuLookup): void {
        this.removeNyuLookupCtrls(lookup, nyuLookupTypes.floor);
    }

    fillLookups(unitFacilityInformation: UnitFacilityInformation): any {
        if (unitFacilityInformation.unit?.id === '00000000-0000-0000-0000-000000000000') {
            unitFacilityInformation.unit.id = null;
        }

        unitFacilityInformation.professions?.sort((a, b) => {
            return a.professionSort - b.professionSort;
        });
        this.lookups.professions = unitFacilityInformation.professions;
        this.lookups.professionSpecialties = unitFacilityInformation.professionSpecialties;
        this.currentProfessionId =
            this._facilityInformationUnit?.unit?.professions &&
            this._facilityInformationUnit?.unit?.professions?.length > 0
                ? this._facilityInformationUnit?.unit?.professions[0].professionCode
                : -1;

        refreshDisableEnableState(this.facilityInformationForm, this.currentProfessionId <= 0 || this.readOnly, [
            'specialties'
        ]);

        this.lookups.specialties = this.getVisibleSpecialties(this.currentProfessionId);
        this.lookups.clinicalTracks = [
            { clinicalTrackId: null, clinicalTrackName: '' },
            ...(unitFacilityInformation.clinicalTracks ?? [])
        ];
    }

    getVisibleSpecialties(selectedProfession) {
        let specialties = [];

        if (selectedProfession && this.lookups?.professionSpecialties) {
            specialties = this.lookups?.professionSpecialties.filter((f) => f.professionCode === selectedProfession);
        }
        return specialties.sort((a, b) => {
            return a.specialtyName.localeCompare(b.specialtyName);
        });
    }

    professionChange() {
        const value = this.facilityInformationForm.get('professionsSingle').value;
        this.currentProfessionId = Number(value);
        this.lookups.specialties = this.getVisibleSpecialties(this.currentProfessionId);
        refreshDisableEnableState(this.facilityInformationForm, this.currentProfessionId <= 0 || this.readOnly, [
            'specialties'
        ]);
        this.facilityInformationForm.get('specialties').patchValue(null);
    }

    specialtyChange() {
        const value = this.facilityInformationForm.get('specialties').value;
        this.currentSpecialtyId = Number(value); //reset selected specialty for validation

        if (this.currentSpecialtyId === -1) {
            this._facilityInformationUnit.unit.specialties = [];
        }
    }

    setFormValues(unitInfo: UnitFacilityInformation) {
        if (this.facilityInformationForm) {
            if (this.isWorkForceDisruption) {
                this.facilityInformationForm.get('unitLocation').patchValue(unitInfo.unit?.unitLocation);
            }

            this.facilityInformationForm.get('clinicalTrackId').patchValue(unitInfo.unit?.jobUnit?.clinicalTrackId);
            this.facilityInformationForm.get('manager').patchValue(unitInfo.unit?.manager);
            this.facilityInformationForm.get('email').patchValue(unitInfo.unit?.email);
            this.facilityInformationForm.get('primaryPhone').patchValue(unitInfo.unit?.primaryPhone);
            this.facilityInformationForm.get('secondaryPhone').patchValue(unitInfo.unit?.secondaryPhone);
            this.facilityInformationForm.get('averageDailyCensus').patchValue(unitInfo.unit?.averageDailyCensus);
            this.facilityInformationForm.get('visitsDays').patchValue(unitInfo.unit?.visitsDays);
            this.facilityInformationForm.get('rooms').patchValue(unitInfo.unit?.rooms);
            this.facilityInformationForm.get('beds').patchValue(unitInfo.unit?.beds);
            this.facilityInformationForm.get('professions').patchValue(unitInfo.unit?.professions);

            if (unitInfo.unit?.specialties && unitInfo.unit?.specialties?.length) {
                this.facilityInformationForm
                    .get('specialties')
                    .patchValue(unitInfo.unit?.specialties[0]?.specialtyCode);
                this.currentSpecialtyId = unitInfo.unit?.specialties[0]?.specialtyCode;
            }

            if (this.unit?.professions && this.unit?.professions.length > 0) {
                this.facilityInformationForm
                    .get('professionsSingle')
                    .patchValue(this.unit?.professions[0].professionCode);
            }

            this.isMultipleDiscipline = unitInfo.unit?.professions && unitInfo.unit?.professions?.length > 1;
            if (this.isNyuFacility()) {
                this.getCtrl(nyuLookupTypes.building).patchValue(
                    this.getlookup(nyuLookupTypes.building).filter(
                        (b) => b.id === this.getExtraField(nyuLookupTypes.building)
                    )
                );
                this.getCtrl(nyuLookupTypes.department).patchValue(
                    this.getlookup(nyuLookupTypes.department).filter(
                        (b) => b.id === this.getExtraField(nyuLookupTypes.department)
                    )
                );
                this.getCtrl(nyuLookupTypes.floor).patchValue(
                    this.getlookup(nyuLookupTypes.floor).filter(
                        (b) => b.id === this.getExtraField(nyuLookupTypes.floor)
                    )
                );
            }
        }
    }

    getFormValues(): Partial<Unit> {
        const unit: Partial<Unit> = { jobUnit: {} as JobUnit };

        if (this.facilityInformationForm) {
            unit.unitLocation = this.isWorkForceDisruption
                ? this.facilityInformationForm.get('unitLocation')?.value
                : this.unit.unitLocation;
            unit.jobUnit.clinicalTrackId = this.facilityInformationForm.get('clinicalTrackId')?.value;
            unit.manager = this.facilityInformationForm.get('manager')?.value;
            unit.email = this.facilityInformationForm.get('email')?.value;
            unit.primaryPhone = this.facilityInformationForm.get('primaryPhone')?.value;
            unit.secondaryPhone = this.facilityInformationForm.get('secondaryPhone')?.value;
            unit.averageDailyCensus = this.facilityInformationForm.get('averageDailyCensus')?.value;
            unit.visitsDays = this.facilityInformationForm.get('visitsDays')?.value;
            unit.rooms = this.facilityInformationForm.get('rooms')?.value;
            unit.beds = this.facilityInformationForm.get('beds')?.value;

            if (!this.isMultipleDiscipline) {
                unit.professions = this.lookups.professions.filter(
                    (p) => p.professionCode === Number(this.facilityInformationForm.get('professionsSingle')?.value)
                );
            } else {
                unit.professions = this.facilityInformationForm.get('professions')?.value;
            }
            unit.specialties = this.lookups?.specialties.filter(
                (s) => s.specialtyCode === Number(this.facilityInformationForm.get('specialties')?.value)
            );

            if (this.isNyuFacility()) {
                unit.buildingId = this.facilityInformationForm.get('nyuBuildings')?.value;
                unit.departmentId = this.facilityInformationForm.get('nyuDepartments')?.value;
                unit.floorId = this.facilityInformationForm.get('nyuFloors')?.value;
            }
        }
        return unit;
    }

    checkWorkForceDisruption(unitInfo: UnitFacilityInformation) {
        this.isWorkForceDisruption = [
            hospitalTypes.WorkforceDisruptionClinical,
            hospitalTypes.WorkforceDisruptionNonClinical
        ].includes(unitInfo?.unit?.jobUnit?.facility?.hospitalType);
    }

    markAsDirty(): void {
        this.facilityInformationForm.get('specialties').markAsTouched();
        this.facilityInformationForm.get('professions').markAsTouched();
        this.facilityInformationForm.get('professionsSingle').markAsTouched();
    }

    onClinicalTrackChange(clinicalTrackId: number | null): void {
        this.clinicalTracksChanged.emit(clinicalTrackId);
    }

    private convertNyuIdsToNyuLookups(id: number, lookup: nyuLookupTypes): NyuLookup[] {
        if (id > 0) {
            switch (lookup) {
                case nyuLookupTypes.building:
                    return this.lookups.nyuBuildings.filter((b) => b.id === id);
                    break;
                case nyuLookupTypes.department:
                    return this.lookups.nyuDepartments.filter((b) => b.id === id);
                    break;
            }
        }

        return [];
    }

    private checkHideWfdRestrictedFields() {
        if (this.isWorkForceDisruption) {
            const userCanViewRestrictedFields = this._identityService.hasWfdPermissionToViewRestrictedFields(
                this._facilityInformationUnit?.unit?.jobUnit?.facility?.hospId
            );
            this.hideWfdRestrictedFields =
                this.isEnabledHideUnitDescriptionRestrictedFields && !userCanViewRestrictedFields;
            if (!this.hideWfdRestrictedFields && !this.canShowContact) {
                this.canShowContact = true;
            } else if (this.hideWfdRestrictedFields && this.canShowContact) {
                this.canShowContact = false;
            }
        }
    }

    private clear(lookup: nyuLookupTypes): void {
        switch (lookup) {
            case nyuLookupTypes.building:
                this.facilityInformationForm.get('nyuBuildingCtrl').setValue(null, { emitEvent: false });
                this.nyuBuildingsInput.nativeElement.value = '';
                break;
            case nyuLookupTypes.department:
                this.facilityInformationForm.get('nyuDepartmentCtrl').setValue(null, { emitEvent: false });
                this.nyuDepartmentsInput.nativeElement.value = '';
                break;
            case nyuLookupTypes.floor:
                this.facilityInformationForm.get('nyuFloorCtrl').setValue(null, { emitEvent: false });
                this.nyuFloorsInput.nativeElement.value = '';
                break;
        }
    }
}
