export enum TimecardsStatusEnum {
    UNSUBMITTED = 1,
    SUBMITTED = 2,
    PROCESSED = 3,
    UNCREATED = 4,
    PENDING_TIER_1_APPROVAL = 5,
    PENDING_TIER_2_APPROVAL = 6,
    APPROVED = 7,
    REJECTED_TIER_1 = 8,
    REJECTED_TIER_2 = 9
}
