import { Pipe, PipeTransform } from '@angular/core';
import { VendorContact } from '../models/vendor-contact.model';

@Pipe({
    name: 'vendorContactPhone'
})
export class VendorContactPhonePipe implements PipeTransform {
    transform(vendorContact: VendorContact): string {
        if (!vendorContact) {
            return '';
        }

        let vendorContactPhone = vendorContact.vendorContactPhones?.find((x) => x.vendorContactPhoneNumberTypeId === 1);
        if (!vendorContactPhone) {
            vendorContactPhone = vendorContact.vendorContactPhones?.find((x) => x);
        }

        if (!vendorContactPhone) {
            return '';
        }

        return `(${vendorContactPhone.areaCode}) ${vendorContactPhone.exchange}-${vendorContactPhone.station} ext: ${vendorContactPhone.extension}`;
    }
}
