import { GridSearchQuery } from 'src/app/shared/grid/models';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { SortTypes } from 'src/app/shared/models';
import { getSortCriterion } from './get-sort-criterion';
import { PaginationParameters, SortParameters, interpolateParams } from './interpolate-params';
import { HttpParams } from '@angular/common/http';

export const constructQueryParameters = <T extends GridSearchQuery>(
    query: T,
    isPaginatedQuery: boolean = true,
    additionalFlattenedFilterParameters?: (currentFilterParameters: object) => object
): HttpParams => {
    const sortCondition = getSortCriterion(query);

    const sortArgs: SortParameters = {
        sortField: sortCondition && sortCondition[0].field,
        sortType: sortCondition && (sortCondition[0].dir as SortTypes)
    };

    let matchArgs: object = flattenFilter(query.filter);

    if (additionalFlattenedFilterParameters) {
        matchArgs = additionalFlattenedFilterParameters(matchArgs);
    }

    let parameterObject: object = {
        ...sortArgs,
        ...matchArgs
    };

    if (isPaginatedQuery) {
        const pagination: PaginationParameters = {
            pageSize: query.take,
            skip: query.skip
        };

        parameterObject = {
            ...parameterObject,
            ...pagination
        };
    }

    const httpParameters: HttpParams = interpolateParams(parameterObject);

    return httpParameters;
};
