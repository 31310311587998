import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import {
    Inbox,
    CreateThreadRequest,
    InboxStats,
    Thread,
    RecipientSearchResponse,
    Message,
    MessageCenterUser,
    Facility,
    To,
    ThreadState
} from './models';
import { FetchArgs2 } from 'src/app/shared/models';
import { appendQuery, getQueryString } from 'src/app/shared/utilities';

@Injectable({ providedIn: 'root' })
export class MessageCenterService {
    private readonly _messageCenterUrl = '/AyaConnect/Client/MessageCenter';

    constructor(private readonly _http: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    searchRecipients(args: FetchArgs2): Observable<RecipientSearchResponse> {
        if (!args?.filter?.searchValue) {
            throw new Error('Missing required filter: search value.');
        } else if (!args?.filter?.facilityId) {
            throw new Error('Missing required filter: facility id.');
        }

        args.filter.searchValue = args.filter.searchValue.trim();

        let url = `${this._settings.CORE}${this._messageCenterUrl}/search-recipients`;
        url += getQueryString(args.filter);
        url = appendQuery(url, `pageSize=${args.pageSize}`, `skip=${args.skip}`);

        return this._http.get<RecipientSearchResponse>(url);
    }

    getInbox(page: number, pageSize: number, includeHasUnreadMessagesOnly: boolean = false): Observable<Inbox> {
        return this._http.get<Inbox>(
            `${this._settings.CORE}/CommunicationHub/inbox?page=${page}&pageSize=${pageSize}&includeHasUnreadMessagesOnly=${includeHasUnreadMessagesOnly}`
        );
    }

    getStatistics(): Observable<InboxStats> {
        return this._http.get<InboxStats>(`${this._settings.CORE}/CommunicationHub/statistics`);
    }

    createThread(thread: CreateThreadRequest): Observable<void> {
        thread.body = thread.body.trim();
        return this._http.post<void>(`${this._settings.CORE}/CommunicationHub/threads`, thread);
    }

    getThread(threadKey: string): Observable<Thread> {
        return this._http.get<Thread>(`${this._settings.CORE}/CommunicationHub/threads/${threadKey}`).pipe(
            map((thread: Thread) => {
                thread.messages = [...thread.messages].sort(
                    (a: Message, b: Message) => new Date(a.createdAtUtc).getTime() - new Date(b.createdAtUtc).getTime()
                );
                return thread;
            })
        );
    }

    getRecipientState(recipient: To): Observable<ThreadState> {
        const params = new HttpParams().set('recipientType', recipient.type);
        return this._http.get<ThreadState>(
            `${this._settings.CORE}${this._messageCenterUrl}/recipients/${recipient.toKey}/state-info`,
            { params }
        );
    }

    createMessage(threadKey: string, body: string): Observable<void> {
        body = body.trim();
        return this._http.post<void>(`${this._settings.CORE}/CommunicationHub/threads/${threadKey}/messages`, { body });
    }

    markThreadAsUnread(threadKey: string): Observable<void> {
        return this._http.post<void>(`${this._settings.CORE}/CommunicationHub/threads/${threadKey}/mark-unread`, null);
    }

    getFacilities(): Observable<Facility[]> {
        return this._http.get<Facility[]>(`${this._settings.CORE}${this._messageCenterUrl}/facilities`);
    }

    isMessageCenterEnabledForUser(): Observable<boolean> {
        return this._http.get<boolean>(`${this._settings.CORE}${this._messageCenterUrl}/is-enabled-for-user`);
    }

    upsertSharedMailbox(facilities: Facility[]): Observable<void> {
        return this._http.put<void>(`${this._settings.CORE}${this._messageCenterUrl}/shared-mailboxes`, {
            facilities
        });
    }

    upsertUserInfo(user: MessageCenterUser): Observable<void> {
        const update = { mailboxTypeForUsers: user.type, userMailboxes: [user] };
        return this._http.post<void>(`${this._settings.CORE}/CommunicationHub/user-mailboxes/bulk`, update);
    }
}
