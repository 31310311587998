import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions/vendor-candidate-profile.acitons';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { ToasterService } from 'src/app/core/services';
import { Store } from '@ngrx/store';
import { CandidateService } from 'src/app/shared/services/candidate.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class VendorCandidateProfileEffect extends BaseEffect {
    loadShiftsApp$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadVendorCandidateShiftsApp),
            switchMap((action) =>
                this._candidateService.getShiftAppState(action.candidateId).pipe(
                    map((response) => actions.loadVendorCandidateShiftsAppSuccess({ data: response })),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            actions.loadVendorCandidateShiftsAppFailure({
                                error: error.error || error.statusText
                            })
                        )
                    )
                )
            )
        )
    );

    sendInviteShiftsAppConfirmation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.sendInviteVendorСandidateShiftsAppConfirmation),
            switchMap((action) => {
                const dialog = this._dialogService.openConfirmationDialog({
                    data: {
                        title: 'Send Email Instructions',
                        text: 'Are you sure you want to send Shifts App email instructions to the candidate?',
                        confirmButtonText: 'Send'
                    }
                });

                return from(dialog).pipe(
                    filter((r) => r),
                    map(() => actions.sendInviteVendorСandidateShiftsApp({ candidateId: action.candidateId }))
                );
            })
        )
    );

    sendInviteShiftsApp$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.sendInviteVendorСandidateShiftsApp),
            switchMap((action) =>
                this._candidateService.sendShiftsAppInvitation(action.candidateId).pipe(
                    mergeMap((response) => [
                        actions.sendInviteVendorСandidateShiftsAppSuccess(),
                        actions.loadVendorCandidateShiftsApp({ candidateId: action.candidateId })
                    ]),
                    catchError((error) =>
                        of(
                            actions.sendInviteVendorСandidateShiftsAppFailure({
                                error: error.error || error.statusText
                            })
                        )
                    )
                )
            )
        )
    );
    sendInviteShiftsAppSuccessed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.sendInviteVendorСandidateShiftsAppSuccess),
                tap((action) => this._toasterService.success(`Invitation email successfully sent to candidate`))
            ),
        { dispatch: false }
    );

    sendInviteShiftsAppFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.sendInviteVendorСandidateShiftsAppFailure),
                tap((action) => {
                    const errorMessage = action.error;
                    this._toasterService.fail(`Failed to send invitation email: \n${errorMessage}`);
                })
            ),
        { dispatch: false }
    );

    getHospitalType$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadSubmittalJobDetails),
            switchMap((action) =>
                this._candidateService.getSubmittalJobDetails(action.jobId).pipe(
                    map((response) => {
                        const hospitalTypeId = response.hospitalTypeId;
                        return actions.loadSubmittalJobDetailsSuccess({ hospitalTypeId: hospitalTypeId });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            actions.loadSubmittalJobDetailsFailure({
                                error: error.error || error.statusText
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _candidateService: CandidateService,
        private readonly _dialogService: DialogService,
        private readonly _toasterService: ToasterService,
        private readonly _store$: Store<{}>
    ) {
        super(_toasterService);
    }
}
