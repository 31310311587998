import { Component, ViewEncapsulation } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'formly-wrapper-panel',
    template: `
        <div class="kendo-form-wrapper">
            <ng-template #fieldComponent></ng-template>
        </div>
    `,
    styleUrls: ['./kendo-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class KendoWrapperComponent extends FieldWrapper {}
