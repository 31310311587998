import { DefaultProjectorFn, MemoizedSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { selectAyaLeadsFeature } from './aya-leads-list-selectors/aya-leads-list.selectors';
import {
    AyaLeadsApplicationPageStatusStyle,
    AyaLeadsStatusAction
} from 'src/app/aya-leads/models/aya-leads-application-page-status-style.model';
import { AyaLeadApplicationDetails } from 'src/app/aya-leads/models/aya-lead-application-details.model';
import { AyaLeadsApplicationStatus } from 'src/app/aya-leads/enums/aya-leads-application-status.enum';
import {
    selectCandidateEducation,
    selectCandidateWorkHistory,
    selectCandidateQualifications
} from 'src/app/submittals/store/submittals.selectors';
import { selectAyaLeadsApplicationStatusMap } from './aya-leads-lookups.selectors';

export const selectAyaLeadsDetails = createSelector(selectAyaLeadsFeature, (state) => state.details);

export const selectIsAyaLeadsApplicationInfoLoading = createSelector(
    selectAyaLeadsFeature,
    (state) => state.details.detailsLoading === LoadingTypes.LOADING
);

export const selectApplicationDetails = createSelector(
    selectAyaLeadsDetails,
    (state) => state.applicationInfo?.applicationDetails
);

export const selectJobDetails = createSelector(selectAyaLeadsDetails, (state) => state.applicationInfo?.jobDetails);

export const selectApplicationAdditionalInformation = createSelector(
    selectAyaLeadsDetails,
    (state) => state.applicationInfo?.additionalInformation
);

export const selectApplicationInfoCanUpdateStatus = createSelector(
    selectAyaLeadsDetails,
    (state) => state.canUpdateStatus
);

export const selectResumeInfo = createSelector(selectAyaLeadsDetails, (state) => state?.resumeInfo);

export const selectAyaLeadsApplicationId = createSelector(selectAyaLeadsDetails, (state) => state?.ayaLeadsApplicationId);

export const selectFacilityId = createSelector(selectJobDetails, (state) => state?.facilityId);

export const selectAyaLeadsActionStyles: MemoizedSelector<
    object,
    AyaLeadsStatusAction,
    DefaultProjectorFn<AyaLeadsStatusAction>
> = createSelector(
    selectApplicationInfoCanUpdateStatus,
    selectApplicationDetails,
    (canUpdateStatus: boolean, applicationDetails: AyaLeadApplicationDetails): AyaLeadsStatusAction | null => {
        if (!canUpdateStatus || !applicationDetails) {
            return null;
        }

        if (
            applicationDetails.applicationStatusId === AyaLeadsApplicationStatus.New ||
            applicationDetails.applicationStatusId === AyaLeadsApplicationStatus.Viewed
        ) {
            return {
                classes: 'aya-leads-btn-reject-color',
                displayText: 'Reject Candidate',
                statusUpdate: AyaLeadsApplicationStatus.Rejected
            };
        } else if (applicationDetails.applicationStatusId === AyaLeadsApplicationStatus.Rejected) {
            return {
                classes: 'aya-leads-btn-re-engage-color',
                displayText: 'Re-Engage',
                statusUpdate: AyaLeadsApplicationStatus.Viewed
            };
        }

        return null;
    }
);

export const selectAyaLeadsBadgeStyles: MemoizedSelector<
    object,
    AyaLeadsApplicationPageStatusStyle,
    DefaultProjectorFn<AyaLeadsApplicationPageStatusStyle>
> = createSelector(
    selectApplicationDetails,
    selectAyaLeadsApplicationStatusMap,
    (applicationDetails: AyaLeadApplicationDetails, ayaLeadApplicationStatusMap): AyaLeadsApplicationPageStatusStyle | null => {
        if (!applicationDetails) {
            return null;
        }

        if (applicationDetails.applicationStatusId === AyaLeadsApplicationStatus.New) {
            return {
                classes: 'aya-lead-application-new-badge-background-color',
                displayText: ayaLeadApplicationStatusMap ? ayaLeadApplicationStatusMap[AyaLeadsApplicationStatus.New] : 'New'
            };
        } else if (applicationDetails.applicationStatusId === AyaLeadsApplicationStatus.Viewed) {
            return {
                classes: 'aya-lead-application-reviewed-background-color',
                displayText: ayaLeadApplicationStatusMap ? ayaLeadApplicationStatusMap[AyaLeadsApplicationStatus.Viewed] : 'Reviewed'
            };
        } else if (applicationDetails.applicationStatusId === AyaLeadsApplicationStatus.Rejected) {
            return {
                classes: 'aya-lead-application-rejected-background-color',
                displayText: ayaLeadApplicationStatusMap ? ayaLeadApplicationStatusMap[AyaLeadsApplicationStatus.Rejected] : 'Rejected'
            };
        }

        return null;
    }
);

export const selectResumePreviewBlobUrl = createSelector(
    selectAyaLeadsDetails,
    (state) => state?.resumePreview?.blobUrl
);

export const selectResumePreviewNotFound = createSelector(
    selectAyaLeadsDetails,
    (state) => state?.resumePreview?.resumeNotFound
);

export const selectShowEducationSection = createSelector(
    selectCandidateEducation,
    (candidateEducation) => candidateEducation.length > 0
);

export const selectShowWorkHistorySection = createSelector(
    selectCandidateWorkHistory,
    (candidateWorkHistory) => candidateWorkHistory.length > 0
);

export const selectShowEmrSystemsSection = createSelector(
    selectCandidateQualifications,
    (candidateQualifications) => candidateQualifications?.emrSystems?.length > 0
);

export const selectShowLicensesAndCertificationsSection = createSelector(
    selectCandidateQualifications,
    (candidateQualifications) =>
        candidateQualifications?.licenses?.length > 0 && candidateQualifications?.certifications?.length > 0
);
