import {
    Component,
    AfterViewInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Store } from '@ngrx/store';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import {
    FileAttachment,
    FilesToUpload,
    FileValidationResult,
    AttachmentConfig
} from 'src/app/shared/models/attachment';
import { Subject } from 'rxjs';

@Component({
    selector: 'ayac-file-attachments',
    templateUrl: './file-attachments.component.html',
    styleUrls: ['./file-attachments.component.scss']
})
export class FileAttachmentsComponent extends UnsubscribeOnDestroy implements AfterViewInit {
    document: any = { files: new Array<FilesToUpload>() };
    validationResults: FileValidationResult[] = [];
    fileExtensions: string;
    attachmentData: FileAttachment[];
    uploadInProgress = false;
    @Input()
    set attachments(data: FileAttachment[]) {
        if (data) {
            this.attachmentData = data;
            if (this.document.files.length) {
                this.clearExistingDocuments(data);
            }
        }
    }

    get attachments(): FileAttachment[] {
        return this.attachmentData;
    }

    @Input()
    attachmentConfig = new AttachmentConfig();

    @Input()
    editMode = true;

    @Input() id: number;

    @Input()
    attachmentRequired: boolean;

    @Input() loading: boolean;
    @Input() isDuplicate: boolean;

    @Output() getAttachmentFile = new EventEmitter();
    @Output() attachmentUpload = new EventEmitter();
    @Output() attachmentDelete = new EventEmitter();
    @Output() updateAttachmentFile = new EventEmitter();
    @Output() getAttachmentPreviewFile = new EventEmitter();

    @ViewChild('fileInput') fileInput: ElementRef;

    private readonly attachmentsChangedSubject$: Subject<boolean> = new Subject();
    public attachmentsChanged$ = this.attachmentsChangedSubject$.asObservable();

    constructor(
        private readonly dialog: DialogService,
        private readonly ref: ChangeDetectorRef,
        private readonly store: Store<{}>
    ) {
        super();
        this.fileExtensions = this.attachmentConfig.validFileExtensions.join(', ').replace(/\./g, '');
    }

    ngAfterViewInit(): void {
        this.ref.detectChanges();
    }

    clearExistingDocuments(data): void {
        this.document.files = this.document.files.filter((item) => item === data);
        this.uploadInProgress = false;
    }

    getAttachment(file: FileAttachment): void {
        this.getAttachmentFile.emit(file);
    }

    /**
     * this emit will call another component to view the file
     * @param file
     */
    getAttachmentPreview(file: FileAttachment): void {
        this.getAttachmentPreviewFile.emit(file);
    }

    deleteAttachment(file: any): void {
        this.dialog
            .openConfirmationDialog({
                data: {
                    title: this.attachmentConfig.deleteDialogTitle,
                    text: this.attachmentConfig.deleteDialogText
                }
            } as IConfirmationDialogOptions)
            .then((result) => {
                if (result) {
                    this.attachmentDelete.emit(file);
                }
            });
    }

    updateAttachment(file: any, internalOnly: boolean): void {
        this.updateAttachmentFile.emit({ file, internalOnly });
    }

    getDroppedFiles(e: DragEvent): void {
        this.updateDragState(e);
        this.addFiles(e.dataTransfer.files);
    }

    addSelectedFiles(): void {
        this.addFiles(this.fileInput.nativeElement.files);
        this.fileInput.nativeElement.value = null;
    }

    openExplorer(): void {
        this.fileInput.nativeElement.click();
    }

    removeFile(value: File): void {
        this.dialog
            .openConfirmationDialog({
                data: {
                    title: this.attachmentConfig.deleteDialogTitle,
                    text: this.attachmentConfig.deleteDialogText
                }
            } as IConfirmationDialogOptions)
            .then((result) => {
                if (result) {
                    this.document.files = this.document.files.filter((file) => file.name !== value.name);
                    this.attachmentsChangedSubject$.next(true);
                }
            });
    }

    removeFileForDuplicateWorkflow(value: FileAttachment): void {
        this.dialog
            .openConfirmationDialog({
                data: {
                    title: this.attachmentConfig.deleteDialogTitle,
                    text: this.attachmentConfig.deleteDialogText
                }
            } as IConfirmationDialogOptions)
            .then((result) => {
                if (result) {
                    this.attachmentData = this.attachmentData.filter(
                        (file: FileAttachment) => file.fileName !== value.fileName
                    );
                    this.attachmentsChangedSubject$.next(true);
                }
            });
    }

    updateDragState(e: DragEvent): void {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = 'copy';
        }
        this.attachmentConfig.isDragging = e.type === 'dragover';
    }

    uploadFiles(): void {
        if (this.document.files.length) {
            let fileToUpload = this.document.files;
            if (this.document.files.length > 1) {
                this.uploadInProgress = true;
                fileToUpload.forEach((file) => this.attachmentUpload.emit(file));
            } else {
                fileToUpload = this.document.files[0];
                this.uploadInProgress = true;
                this.attachmentUpload.emit(fileToUpload);
            }
        }
    }

    /**
     * used by the other component to retrieve all selected files
     */
    getFiles(): [] {
        if (this.document.files && this.document.files.length) {
            this.uploadInProgress = true;
            return this.document.files;
        }
        return [];
    }

    onRadioSelected() {
        this.attachmentsChangedSubject$.next(true);
    }

    private addFiles(value: FileList): void {
        this.validationResults = [];
        for (let i = 0; i < value.length; i++) {
            const file: any = value[i];
            if (this.validateFile(value[i])) {
                file.internalOnly = this.attachmentConfig.defaultInternalOnly;
                this.document.files.push(file);
                this.document.originalFileName = this.document.files.length > 1 ? 'Multiple files' : file.name;
            }
        }
        if (this.id > 0) {
            this.uploadFiles();
        }

        this.attachmentsChangedSubject$.next(true);
    }
    /**
     * 3 types of validations
     * @param file
     */
    private validateFile(file: File): boolean {
        if (file.size > 50 * 1024 * 1024) {
            //50Mb
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: 'File format exceeds 50MB size limit. Please save the file as a smaller size and try again.'
            });
            return false;
        }

        if (this.document.files.find((f) => f.name === file.name)) {
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: `There is already a file named ${file.name}. Please save the file with a different file name and try again.`
            });
            return false;
        }

        const ext = /(?:\.([^.]+))?$/.exec(file.name)[1];
        if (!this.attachmentConfig.validFileExtensions.some((x) => x === `.${ext}`.toLowerCase())) {
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: `Error uploading "${file.name}": Invalid file type.`
            });
            return false;
        }
        return true;
    }

    public trackItem(index: number, item: FileValidationResult) {
        return item.name;
    }

    public get isRequired() {
        return this.attachmentConfig && this.attachmentConfig.attachmentRequired;
    }

    public get isInvalid(): boolean {
        if (this.isRequired) {
            const attachments = this.getFiles();
            const existingAttachments = this.attachmentData;

            if (attachments.length === 0 && existingAttachments.length === 0) {
                return true;
            }
        }

        return false;
    }
}
