/* eslint-disable @angular-eslint/directive-selector */
import { ChangeDetectorRef, Directive, ElementRef, OnInit, AfterViewChecked } from '@angular/core';
import { isEmpty } from '../utilities';
import { findAndReplaceTerminology } from './terminology.helper';
import { IdentityService } from '../services/identity.service';
import { ApplicationPermissions } from '../models/enums/application-permissions.enum';

const NODE_TYPE_TEXT = 3;
const NODE_TYPE_ELEMENT = 1;

@Directive({
    selector: '[terminology]'
})
export class TerminologyDirective implements OnInit, AfterViewChecked {
    hasTerminologyPermission = false;

    constructor(
        private readonly _element: ElementRef,
        private readonly _ref: ChangeDetectorRef,
        private readonly _identityService: IdentityService
    ) {}

    ngOnInit(): void {
        this.hasTerminologyPermission = this._identityService.hasSecurityPermission(
            ApplicationPermissions.LocumsTerminology
        );
    }

    ngAfterViewChecked(): void {
        if (this.hasTerminologyPermission) {
            this.loopThroughNode(this._element.nativeElement);
        }
    }

    loopThroughNode(rootNode: Node): void {
        const nodes: Element[] = rootNode.childNodes as unknown as Element[];

        if (nodes?.length > 0) {
            for (const node of nodes) {
                if (node.nodeType === NODE_TYPE_TEXT) {
                    this.findAndReplaceContent(node as unknown as Text);
                } else if (node.nodeType === NODE_TYPE_ELEMENT) {
                    if (node.hasAttribute('placeholder')) {
                        const input = node as HTMLInputElement;
                        input.placeholder = findAndReplaceTerminology(input.placeholder);
                    }

                    this.loopThroughNode(node);
                }
            }
        }
    }

    getContent(node: Text): string {
        return !isEmpty(node.textContent) ? node.textContent : node.data;
    }

    setContent(node: Text, content: string): void {
        if (!isEmpty(node.textContent)) {
            node.textContent = content;
        } else {
            node.data = content;
        }
    }

    findAndReplaceContent = (node: Text) => {
        const content: string = this.getContent(node);

        if (content) {
            const updatedContent = findAndReplaceTerminology(content);

            this.setContent(node, updatedContent);
            this._ref.markForCheck();
        }
    };
}
