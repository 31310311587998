import { createAction, props } from '@ngrx/store';
import { VendorNote } from 'src/app/admin/vendor-details/models/vendor-note.model';
import { VendorNoteType } from 'src/app/admin/vendor-details/models/vendor-note-type.model';

export const loadVendorNotesByVendorId = createAction(
    '[VENDOR NOTES COMPONENT] Load Vendor Notes By Vendor Id',
    props<{ vendorId: number }>()
);
export const loadVendorNotesByVendorIdSuccess = createAction(
    '[VENDOR NOTES API] Load Vendor Notes By Vendor Id Success',
    props<{ notes: VendorNote[] }>()
);
export const loadVendorNotesByVendorIdFailure = createAction(
    '[VENDOR NOTES API] Load Vendor Notes By Vendor Id Failure',
    props<{ error: unknown }>()
);

export const loadVendorNoteTypes = createAction('[VENDOR NOTES COMPONENT] Load Vendor Note Types');
export const loadVendorNoteTypesSuccess = createAction(
    '[VENDOR NOTES API] Load Vendor Note Types Success',
    props<{ types: VendorNoteType[] }>()
);
export const loadVendorNoteTypesFailure = createAction(
    '[VENDOR NOTES API] Load Vendor Note Types Failure',
    props<{ error: unknown }>()
);

export const addNote = createAction(
    '[VENDOR NOTES COMPONENT ADD BUTTON] Add Vendor Note',
    props<{ note: Partial<VendorNote>; vendorId: number }>()
);
export const addNoteSuccess = createAction(
    '[VENDOR NOTES API] Add Vendor Note Success',
    props<{ vendorId?: number }>()
);
export const addNoteFailure = createAction('[VENDOR NOTES API] Add Vendor Note Failure', props<{ error: unknown }>());

export const resetVendorNotes = createAction('[VENDOR NOTES COMPONENT] Reset Vendor Notes');

export const vendorNotesActions = {
    loadVendorNotesByVendorId,
    loadVendorNotesByVendorIdSuccess,
    loadVendorNotesByVendorIdFailure,
    loadVendorNoteTypes,
    loadVendorNoteTypesSuccess,
    loadVendorNoteTypesFailure,
    addNote,
    addNoteSuccess,
    addNoteFailure,
    resetVendorNotes
};
