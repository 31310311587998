import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hoursRuleName' })
export class HoursRuleNamePipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'majhours': {
                return 'Majority Hours';
            }
            case 'starts': {
                return 'Starts Within';
            }
            case 'ends': {
                return 'Ends Within';
            }
            case 'falls': {
                return 'Falls Within';
            }
            default: {
                return '';
            }
        }
    }
}
