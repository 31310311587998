import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as actions from 'src/app/admin/store/actions/vendor-facilities.actions';
import * as selectors from 'src/app/admin/store/selectors/vendor-facilities.selectors';
import { catchError, map, switchMap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SortTypes } from 'src/app/shared/models';
import { VendorFacilitiesService } from '../../vendor-details/facilities/vendor-facilities.service';
import { VendorFacilitiesQueryState } from '../state/vendor-facilities.state';
import { flattenFilterPrefix } from 'src/app/shared/utilities';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { GridSearchQuery } from 'src/app/shared/grid/models';

@Injectable()
export class VendorFacilitiesEffects {
    loadVendorFacilities$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(
                actions.loadVendorFacilities,
                actions.updateFacilityStatusSuccess,
                actions.updateCtsSignedSuccess,
                actions.setVendorFacilitiesSearchQuery
            ),
            withLatestFrom(this._store$.select(selectors.selectVendorFacilitiesGridSearchQuery)),
            switchMap(([action, query]) => {
                const { pagination, sortArgs, searchQuery } = this.getFilterParams(query);

                // Make sure the vendorId filter is present
                this.ensureVendorIdFilter(searchQuery, action);

                const matchArgs = flattenFilterPrefix(searchQuery.filter);

                return this._vendorFacilitiesService.getVendorFacilities(pagination, sortArgs, matchArgs).pipe(
                    map((list) =>
                        actions.loadVendorFacilitiesSuccess({
                            facilities: list
                        })
                    ),
                    catchError((error: unknown) =>
                        of(
                            actions.loadVendorFacilitiesFailure({
                                error
                            })
                        )
                    )
                );
            })
        );
    });

    loadVendorFacilityIds$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(
                actions.loadVendorFacilities,
                actions.loadVendorFacilityIds,
                actions.updateFacilityStatusSuccess,
                actions.updateCtsSignedSuccess,
                actions.setVendorFacilitiesSearchQuery
            ),
            withLatestFrom(this._store$.select(selectors.selectVendorFacilitiesGridSearchQuery)),
            switchMap(([action, query]) => {
                const { pagination, sortArgs, searchQuery } = this.getFilterParams(query);

                // Make sure the vendorId filter is present
                this.ensureVendorIdFilter(searchQuery, action);

                const matchArgs = flattenFilterPrefix(searchQuery.filter);

                return this._vendorFacilitiesService.getAllVendorFacilityIds(pagination, sortArgs, matchArgs).pipe(
                    map((list) =>
                        actions.loadVendorFacilityIdsSuccess({
                            ids: list
                        })
                    ),
                    catchError((error: unknown) =>
                        of(
                            actions.loadVendorFacilityIdsFailure({
                                error
                            })
                        )
                    )
                );
            })
        );
    });

    loadVendorFacilitiesLookups$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadVendorFacilitiesLookups),
            switchMap(() =>
                this._vendorFacilitiesService.getVendorFacilitiesLookups().pipe(
                    map((lookups) =>
                        actions.loadVendorFacilitiesLookupsSuccess({
                            lookups
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(
                            actions.loadVendorFacilitiesLookupsFailure({
                                error: err
                            })
                        );
                    })
                )
            )
        );
    });

    updateVendorCTSSignedSheet$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.updateCtsSigned),
            switchMap((action) =>
                this._vendorFacilitiesService.updateCtsSigned(action.params).pipe(
                    map(() =>
                        actions.updateCtsSignedSuccess({
                            vendorId: action.params.vendorId
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(actions.updateCtsSignedFailure({ error: err }));
                    })
                )
            )
        );
    });

    updateFacilityStatus$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.updateFacilityStatus),
            switchMap((action) =>
                this._vendorFacilitiesService.updateFacilityStatus(action.vendorId, action.params).pipe(
                    map(() =>
                        actions.updateFacilityStatusSuccess({
                            vendorId: action.vendorId
                        })
                    ),
                    catchError((err: unknown) => {
                        return of(
                            actions.updateFacilityStatusFailure({
                                error: err
                            })
                        );
                    })
                )
            )
        );
    });

    exportSelected$ = createEffect((): any => {
        return this._actions$.pipe(
            ofType(actions.exportSelected),
            mergeMap((action) =>
                this._vendorFacilitiesService.export(action.facilities, action.vendorId).pipe(
                    map(() => actions.exportSelectedSuccess()),
                    catchError((error: unknown) => of(actions.exportSelectedFailure({ error })))
                )
            )
        );
    });

    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store,
        private readonly _vendorFacilitiesService: VendorFacilitiesService
    ) {}

    private ensureVendorIdFilter(query: VendorFacilitiesQueryState, action): void {
        if (query && query.filter && query.filter.filters) {
            const filters: any[] = query.filter.filters;
            const vendorFilter = filters.filter((f) => f.field === 'vendorId' && !f.value);

            if (vendorFilter.length) {
                filters.find((f) => f.field === 'vendorId').value = action.vendorId;
            }
        }
    }

    private getFilterParams(query: GridSearchQuery): {
        pagination: {
            pageSize: number;
            skip: number;
        };
        sortArgs: {
            sortField: string;
            sortType: SortTypes;
        };
        searchQuery: GridSearchQuery;
    } {
        const sortCondition = query.sort;

        const pagination = {
            pageSize: query.take,
            skip: query.skip
        };

        const sortArgs = {
            sortField: sortCondition && sortCondition[0].field,
            sortType: sortCondition && (sortCondition[0].dir as SortTypes)
        };

        if (query.filter && query.filter.filters) {
            const statusIds: any = query.filter.filters.find((field: FilterDescriptor) => field.field === 'statusIds');
            const includeEmptyStatus = statusIds?.value && statusIds?.value.includes(0) ? true : false;

            const emptyStatusFieldIndex = query.filter.filters.findIndex(
                (item: FilterDescriptor) => item.field === 'includeWithEmptyStatus'
            );

            if (emptyStatusFieldIndex > -1) {
                (query.filter.filters[emptyStatusFieldIndex] as FilterDescriptor).value = includeEmptyStatus;
            } else {
                query.filter.filters.push({
                    field: 'includeWithEmptyStatus',
                    value: includeEmptyStatus,
                    operator: 'eq'
                });
            }
        }
        const searchQuery = query;
        return { pagination, sortArgs, searchQuery };
    }
}
