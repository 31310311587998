import { filterReducer } from './filter.reducer';
import { wocReducer } from './woc.reducer';
import { awaitingClearanceReducer } from './awaiting-clearance.reducer';
import { workersReducer } from './workers.reducer';
import { pendingJobsReducer } from './pending-jobs.reducer';
import { pendingTimecardsReducer } from './pending-timecards.reducer';
import { securityLookupsReducer } from './security-lookups.reducer';
import { jobsReducer } from './jobs.reducer';
import { shiftsReducer } from './shifts.reducer';
import { pendingSubmittalsReducer } from './pending-submittals.reducer';
import { draftInvoicesReducer } from './draft-invoices.reducer';
import { ayaLeadsDashboardReducer } from 'src/app/dashboard-new/store/reducers/aya-leads.reducer';
import { pendingExpensesReducer } from './pending-expenses.reducer';

export const dashboardReducers = {
    filter: filterReducer,
    woc: wocReducer,
    awaitingClearance: awaitingClearanceReducer,
    workers: workersReducer,
    pendingJobs: pendingJobsReducer,
    pendingExpenses: pendingExpensesReducer,
    pendingTimecards: pendingTimecardsReducer,
    securityLookups: securityLookupsReducer,
    jobs: jobsReducer,
    shifts: shiftsReducer,
    pendingSubmittals: pendingSubmittalsReducer,
    draftInvoices: draftInvoicesReducer,
    ayaLeadsCount: ayaLeadsDashboardReducer
};
