import { OnChanges, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface DropdownValue {
    value: number;
    text: string;
}

@Component({
    selector: 'timepicker-dropdown',
    templateUrl: 'timepicker-dropdown.component.html',
    styleUrls: ['timepicker-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TimepickerDropdownComponent)
        }
    ]
})
export class TimepickerDropdownComponent implements ControlValueAccessor, OnChanges, OnInit {
    hoursList: DropdownValue[];
    minutesList: DropdownValue[];
    minutes: number;
    hours: number;
    defaultHour = { value: null, text: 'hh' };
    defaultMinute = { value: '', text: 'mm' };
    disabled: boolean;

    @Input() steps = { hour: 1, minute: 15 };

    onChange: any;

    popup = {
        width: 'auto'
    };

    ngOnInit() {
        this.updateLists();
    }

    ngOnChanges(changes) {
        if (changes.steps) {
            this.updateLists();
        }
    }

    updateLists() {
        this.hoursList = Array.from(Array(24), (v, i) => i)
            .filter((v) => v % this.steps.hour === 0)
            .map((value) => {
                const text = ('00' + String(value)).substring(String(value).length);

                return { value, text };
            });

        this.minutesList = Array.from(Array(60), (v, i) => i)
            .filter((v) => v % this.steps.minute === 0)
            .map((value) => {
                const text = ('00' + String(value)).substring(String(value).length);

                return { value, text };
            });
    }

    writeValue(obj: Date): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onHoursChange(e: DropdownValue) {
        this.hours = e.value;
        this.onChange(this.value);
    }

    onMinutesChange(e: DropdownValue) {
        this.minutes = e.value;
        this.onChange(this.value);
    }

    set value(obj: Date) {
        const date = obj === null ? null : new Date(obj);

        if (date) {
            this.minutes = date.getMinutes();
            this.hours = date.getHours();
        }
    }

    get value() {
        const date = new Date();
        date.setHours(this.hours || 0);
        date.setMinutes(this.minutes || 0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    }
}
