import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CandidateWorkHistory } from 'src/app/submittals/models';
import { loadCandidateWorkHistory } from 'src/app/submittals/store/actions/submittals-leads.actions';
import { selectCandidateWorkHistory } from 'src/app/submittals/store/submittals.selectors';

@Component({
    selector: 'aya-submittal-work-history-section',
    templateUrl: 'submittal-work-history-section.component.html',
    styleUrls: ['./submittal-work-history-section.component.scss']
})
export class SubmittalWorkHistorySectionComponent implements OnInit {
    @Input() candidateId: number;
    workHistoryDisplayedColumns = ['startDate', 'endDate', 'facility', 'profession', 'specialty'];
    workHistory$: Observable<CandidateWorkHistory[]>;

    constructor(private readonly store: Store) {}

    ngOnInit() {
        this.store.dispatch(loadCandidateWorkHistory({ candidateId: this.candidateId }));
        this.workHistory$ = this.store.select(selectCandidateWorkHistory);
    }
}
