import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

@Pipe({
    name: 'releaseDayPipe'
})
export class ReleaseDayPipe implements PipeTransform {
    transform(scheduleReleaseDate: string | null | undefined): string {
        if (!scheduleReleaseDate) {
            return '';
        }

        const now = dayjs.utc().startOf('minute');
        const releaseDate = dayjs.utc(scheduleReleaseDate).startOf('minute');

        if (releaseDate.isBefore(now)) {
            return '';
        }

        const diffInHours = releaseDate.diff(now, 'hour');

        if (diffInHours < 24) {
            return releaseDate.to(now, true);
        } else {
            const diffInDays = Math.ceil(diffInHours / 24);

            if (diffInDays < 30) {
                return diffInDays > 1 ? `${diffInDays} days` : '1 day';
            } else {
                const diffInMonths = Math.ceil(diffInDays / 30);
                return diffInMonths > 1 ? `${diffInMonths} months` : 'a month';
            }
        }
    }
}
