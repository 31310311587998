import { OnInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { GridComponent } from 'src/app/shared/grid/components/grid.component';
import { map, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';
import { CustomFieldService } from 'src/app/shared/services/custom-field.service';
import { ContractGroupDefinition } from 'src/app/shared/models/custom-fields/contract-group-definition.model';
import { EntityType } from 'src/app/shared/models/custom-fields/entity-types.enum';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { CustomFieldValueType } from 'src/app/shared/models/custom-fields/custom-field-value-type.enum';
import { CustomFieldSynchronization } from 'src/app/shared/models/custom-fields/custom-field-synchronization.enum';
import { ToasterService } from '../services';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

@Component({
    selector: 'ayac-custom-fields-grid',
    templateUrl: './custom-fields-grid.component.html',
    styleUrls: ['./custom-fields-grid.component.scss']
})
export class CustomFieldsGridComponent extends UnsubscribeOnDestroy implements OnInit {
    synchronizations = CustomFieldSynchronization;
    data: BehaviorSubject<Array<ContractGroupDefinition>> = new BehaviorSubject([]);
    contractGroupId: number;
    isLoading: boolean = false;
    searchQuery$: Observable<GridSearchQuery>;
    entityType = EntityType;
    valueType = CustomFieldValueType;
    total$ = this.data.pipe(map((x) => x.length));

    // will be replaced with api call to get list of lobs
    linesOfBusiness = {
        '9': 'Access Control',
        '10': 'Interim Leadership',
        '3': 'Locums',
        '4': 'Non-Clinical',
        '11': 'Payroll Services',
        '1': 'Per Diem',
        '6': 'Permanent',
        '2': 'Travel',
        '7': 'WFD-C',
        '8': 'WFD-NC'
    };
    readonly featureFlag = FeatureFlag;

    @ViewChild(GridComponent, { static: true }) grid: GridComponent;

    constructor(
        private readonly _cfService: CustomFieldService,
        private readonly _router: Router,
        private readonly _permissionsService: PermissionsService,
        private readonly toaster: ToasterService
    ) {
        super();
    }

    ngOnInit() {
        if (
            !this._permissionsService.customFieldsAdmin('edit') &&
            !this._permissionsService.customFieldsAdmin('view')
        ) {
            return;
        }

        //* We have to wait a second because this is loading from AngularJS
        setTimeout(() => {
            this.initCustomFields();
        }, 1000);
    }

    initCustomFields(): void {
        this.isLoading = true;
        /**
         * We can't use queryParams here because we're inside an AngularJS component.
         * So we have to use the router and split the url.
         * The contract group id should be the last element in the array.
         */
        const urlArray = this._router.url.split('/');
        const contractGroupId = urlArray[urlArray.length - 1];

        if (contractGroupId) {
            this.contractGroupId = +contractGroupId;

            this._cfService
                .getFieldsByContractGroupId(this.contractGroupId)
                .pipe(takeUntil(this.d$))
                .subscribe({
                    next: (fields) => {
                        this.data.next(fields);
                    },
                    error: (error) => {
                        console.error(error);
                    },
                    complete: () => {
                        this.isLoading = false;
                    }
                });
        }
    }

    addField(): void {
        this._router.navigateByUrl(`/admin/custom-field/${this.contractGroupId}/0`);
    }

    editField(field: ContractGroupDefinition): void {
        this._router.navigateByUrl(`/admin/custom-field/${this.contractGroupId}/${field.id}`);
    }

    deleteField(field: ContractGroupDefinition): void {
        this._cfService
            .deleteFieldDefinition(field, this.contractGroupId)
            .pipe(takeUntil(this.d$))
            .subscribe({
                next: (recordWasDeleted) => {
                    if (recordWasDeleted) {
                        this.initCustomFields();
                    } else {
                        this.toaster.fail('Custom field was not deleted.');
                    }
                },
                error: (error) => {
                    console.error(error);
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.data.next(orderBy(this.data.getValue(), sort));
    }
}
