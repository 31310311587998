import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ProviderSupport, Unit, UnitFacilityInformation } from 'src/app/clinical/models';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-provider-support',
    templateUrl: './provider-support.component.html',
    styleUrls: ['./provider-support.component.scss']
})
export class ProviderSupportComponent extends UnsubscribeOnDestroy implements OnChanges {
    @Output() valueChanged = new EventEmitter<Partial<Unit>>();

    providerSupportForm: UntypedFormGroup;

    get providerSupports(): UntypedFormArray {
        return this.providerSupportForm.get('providerSupports') as UntypedFormArray;
    }

    @Input() set unitInfo(unitInfo: UnitFacilityInformation) {
        if (!unitInfo || !unitInfo.unit) {
            return;
        }

        this.setFormValues(unitInfo);
        this.watchFormChanges();
    }

    @Input() readOnly = false;

    @Input() isPrinting: boolean = false;

    constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _store: Store) {
        super();
        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.providerSupportForm, changes.readOnly.currentValue);
        }
    }

    buildForm(): void {
        this.providerSupportForm = this._formBuilder.group({
            providerSupports: this._formBuilder.array([]),
            providerSupportOther: this._formBuilder.control('', [Validators.maxLength(500)])
        });
    }

    setFormValues(unitInfo: UnitFacilityInformation): void {
        unitInfo?.providerSupports.forEach((providerSupport: ProviderSupport) => {
            this.providerSupports.push(
                this._formBuilder.group({
                    id: this._formBuilder.control(providerSupport.id),
                    title: this._formBuilder.control(providerSupport.title),
                    sort: this._formBuilder.control(providerSupport.sort),
                    selected: this._formBuilder.control(
                        unitInfo?.unit?.providerSupports?.find((ps) => ps.id === providerSupport.id) ? true : false
                    )
                })
            );
        });

        this.providerSupportForm.get('providerSupportOther').setValue(unitInfo.unit.providerSupportOther);
    }

    getFormValues(): Partial<Unit> {
        const formArray: UntypedFormArray = this.providerSupportForm.get('providerSupports') as UntypedFormArray;
        const providerSupports: ProviderSupport[] = [];

        formArray.controls.forEach((providerSupport) => {
            if (providerSupport.get('selected').value === true) {
                providerSupports.push({
                    id: providerSupport.get('id').value,
                    title: providerSupport.get('title').value,
                    sort: providerSupport.get('sort').value
                });
            }
        });

        const unit: Partial<Unit> = {
            providerSupports: providerSupports,
            providerSupportOther: this.providerSupportForm.get('providerSupportOther').value
        };

        return unit;
    }

    watchFormChanges(): void {
        this.providerSupportForm.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            this.valueChanged.emit(this.getFormValues());
        });
    }
}
