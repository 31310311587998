import { createAction, props } from '@ngrx/store';
import { AyaLeadsListItem } from 'src/app/aya-leads/models/aya-leads-list-item';
import { GridSearchQuery } from 'src/app/shared/grid/models';

export enum AyaLeadsActionTypes {
    LoadAyaLeadsPage = '[Aya Leads Component] Get Initial Results and Lookups',
    UpdateAyaLeadsResults = '[Aya Leads Component] Update Query and Fetch Filtered Results',
    GetAyaLeadsSuccess = '[Aya Leads Api] Get Filtered Results - Success',
    GetAyaLeadsFail = '[Aya Leads Api] Get Filtered Results - Fail',
    ClearAyaLeadsStore = '[Aya Leads Component] Clear Aya Leads in Store',
    Export = '[Aya Leads Component] Export Aya Leads Filtered Results',
    ExportSuccess = '[Aya Leads Api] Export Aya Leads Filtered Results - Success',
    ExportFail = '[Aya Leads Api] Export Aya Leads Filtered Results - Fail',
    SetAyaLeadsSearchQuery = '[Aya Leads Component] Set Aya Leads Search Query',
    SetGridState = '[Aya Leads Component] Set Aya Leads Grid View',
    SetAyaLeadsGridFilterStateAndUpdateResults = '[Aya Leads Component] Set Aya Leads Grid Filter State And Update Results'
}

export const loadAyaLeadsPage = createAction(AyaLeadsActionTypes.LoadAyaLeadsPage);

export const updateAyaLeadsResults = createAction(
    AyaLeadsActionTypes.UpdateAyaLeadsResults,
    props<{ query: GridSearchQuery }>()
);

export const loadAyaLeadsSuccess = createAction(
    AyaLeadsActionTypes.GetAyaLeadsSuccess,
    props<{ ayaLeads: AyaLeadsListItem[]; total: number }>()
);

export const loadAyaLeadsFail = createAction(AyaLeadsActionTypes.GetAyaLeadsFail);

export const clearAyaLeads = createAction(AyaLeadsActionTypes.ClearAyaLeadsStore);

export const exportAyaLeads = createAction(AyaLeadsActionTypes.Export, props<{ submittalIds: number[] }>());

export const exportAyaLeadsSuccess = createAction(AyaLeadsActionTypes.ExportSuccess);

export const exportAyaLeadsFail = createAction(AyaLeadsActionTypes.ExportFail);

export const setAyaLeadsSearchQuery = createAction(
    AyaLeadsActionTypes.SetAyaLeadsSearchQuery,
    props<{ query: GridSearchQuery }>()
);

export const setAyaLeadsGridFilterStateAndUpdateResults = createAction(
    AyaLeadsActionTypes.SetAyaLeadsGridFilterStateAndUpdateResults,
    props<{ query: GridSearchQuery }>()
);
