import { Inject, OnDestroy, OnInit, Directive } from '@angular/core';
import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';

@Directive()
export abstract class MainContentOnly extends UnsubscribeOnDestroy implements OnInit, OnDestroy  {

    constructor(@Inject('Window') protected readonly _window: Window) {
        super();
    }

    ngOnInit(): void {
        this.toggleMainContentOnlyClass(true);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.toggleMainContentOnlyClass(false);
    }

    private toggleMainContentOnlyClass(isEnabled: boolean) {
        const className = 'main-content-only';
        const bodyClassList = this._window.document.body.classList;
        const viewport = this._window.document.querySelector('meta[name=viewport');

        if (isEnabled) {
            if (!bodyClassList.contains(className)) {
                bodyClassList.add(className);
                viewport.setAttribute('content', 'width=384, initial-scale=1');
            }
        }
        else {
            if (bodyClassList.contains(className)) {
                bodyClassList.remove(className);
                viewport.setAttribute('content', 'width=840, initial-scale=1');
            }
        }
    }

}
