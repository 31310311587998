import { GridSearchQuery } from '../../grid/models';
import { SortTypes } from 'src/app/shared/models';

export const initialShiftSearchQuery: GridSearchQuery = {
	sort: [{ field: 'startTime', dir: SortTypes.ASC }],
	take: 10,
	skip: 0,
	filter: { logic: "and", filters: [] }
}

