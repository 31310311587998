import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as actions from 'src/app/facilities/store/actions/facility-systems-profile.actions';
import {
    FacilitySystemsProfileState,
    getInitialFacilitySystemsProfileState
} from 'src/app/facilities/store/state/facility-systems-profile.state';

export const facilitySystemsProfileReducer = (reducerState: FacilitySystemsProfileState, reducerAction: Action) => {
    return createReducer(
        getInitialFacilitySystemsProfileState(),
        on(
            actions.loadLogo,
            actions.profileLoaded,
            (state, action): FacilitySystemsProfileState => ({
                ...state,
                loadingState: LoadingTypes.LOADING,
                systemId: action.systemId
            })
        ),

        on(
            actions.loadLogoSuccess,
            (state, action): FacilitySystemsProfileState => ({
                ...state,
                logo: action.logo,
                loadingState: LoadingTypes.LOADED,
                systemId: action.systemId
            })
        ),

        on(
            actions.loadLogoFailure,
            (state): FacilitySystemsProfileState => ({
                ...state,
                logo: null,
                loadingState: LoadingTypes.FAIL
            })
        ),

        on(
            actions.addLogo,
            (state, action): FacilitySystemsProfileState => ({
                ...state,
                logo: action.logo
            })
        ),

        on(
            actions.addLogoSuccess,
            (state): FacilitySystemsProfileState => ({
                ...state,
                logo: undefined
            })
        ),

        on(
            actions.deleteLogo,
            (state): FacilitySystemsProfileState => ({
                ...state,
                logo: null
            })
        ),

        on(
            actions.loadFacilitySystemDetailById,
            actions.profileLoaded,
            (state): FacilitySystemsProfileState => ({
                ...state,
                facilitySystem: undefined,
                loadingState: LoadingTypes.INIT
            })
        ),

        on(
            actions.loadFacilitySystemDetailByIdSuccess,
            (state, action): FacilitySystemsProfileState => ({
                ...state,
                facilitySystem: action.system,
                loadingState: LoadingTypes.LOADED
            })
        ),

        on(
            actions.loadFacilitySystemDetailByIdFailure,
            (state): FacilitySystemsProfileState => ({
                ...state,
                facilitySystem: undefined,
                loadingState: LoadingTypes.FAIL
            })
        ),
        on(
            actions.loadColorThemeSuccess,
            (state, action): FacilitySystemsProfileState => ({
                ...state,
                colorTheme: action.colorTheme
            })
        )
    )(reducerState, reducerAction);
};
