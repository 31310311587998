import { ResourceList } from 'src/app/shared/models/internal-pool/resource-list.model';
import { createReducer, on, Action } from '@ngrx/store';
import * as internalPoolActions from '../actions';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { setResourcesQuery as setResourcesQuery } from '../actions/resources.actions';

export const initialResourcesQueryState: GridSearchQuery = {
    sort: [{ field: 'name', dir: SortTypes.ASC }],
    take: 10,
    skip: 0,
    filter: { logic: "and", filters: [] }
}

export interface ResourcesState {
    resources: PagingToken<ResourceList[]>;
    currentResource: ResourceList,
    error: string;
    systemId: number;
    query: GridSearchQuery;
    loading: LoadingTypes;
};

export const initialResourcesState: ResourcesState = {
    resources: {
        total: 0,
        data: [],
        currPage: '',
        nextPage: ''
      },
    currentResource: null,
    error: '',
    systemId: null,
    query: initialResourcesQueryState,
    loading: LoadingTypes.INIT
};

const reducer = createReducer(
    initialResourcesState,
    on(internalPoolActions.loadResources, (state) => (
        {
            ...state,
            loading: LoadingTypes.LOADING
        }
    )),
    on(internalPoolActions.loadSystemIdWithLookupsAndResources, (state) => (
        {
            ...state,
            loading: LoadingTypes.LOADING
        }
    )),
    on(internalPoolActions.loadResourcesSuccess, (state, { resources }) => (
        {
            ...state,
            resources: resources,
            loading: LoadingTypes.LOADED,
            error: ''
        }
    )),
    on(internalPoolActions.loadResourcesFail, internalPoolActions.loadSystemIdFail, (state, { error }) => (
        {
            ...state,
            error: String(error),
            loading: LoadingTypes.FAIL,
            resources: initialResourcesState.resources
        }
    )),
    on(setResourcesQuery, (state, { query }) => (
        {
            ...state,
            query: { ...query }
        }
    )),
    on(internalPoolActions.loadSystemId, (state) => (
        {
            ...state,
            loading: LoadingTypes.LOADING
        }
    )),
    on(internalPoolActions.loadSystemIdSuccess, (state, { systemId }) => (
        {
            ...state,
            systemId: systemId,
            loading: LoadingTypes.LOADED,
            error: ''
        }
    ))
);

export function resourcesReducer(state: ResourcesState | undefined, action: Action) {
    return reducer(state, action);
}
