import { Action, createReducer, on } from '@ngrx/store';
import {
    getInitialSystemContactsProfileState,
    SystemContactsProfilesState
} from 'src/app/admin/store/state/system-contacts-profile.state';
import { LoadingTypes } from 'src/app/shared/models';
import { InvitationStatusEnum } from 'src/app/shared/models/enums/account/invitation-status.enum';
import * as actions from '../actions/system-contacts-profile.actions';

export function adminSystemContactsProfilesReducer(state: SystemContactsProfilesState, action: Action) {
    return createReducer(
        getInitialSystemContactsProfileState(),

        on(actions.createNewSystemContactAndLoadRelatedData, (state) => ({
            ...state,
            systemContact: undefined,
            loading: LoadingTypes.LOADING
        })),

        on(actions.loadSystemContactAndRelatedData, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING,
            error: undefined,
            systemContact: null
        })),

        on(actions.loadSystemContactAndRelatedDataSuccess, (state, action) => ({
            ...state,
            systemContact: action.systemContact,
            loading: LoadingTypes.LOADED,
            hospitalSystems: action.hospitalSystems.map((x) => {
                return {
                    id: x.id,
                    name: x.name
                };
            }),
            identityProviders: action.identityProviders,
            systemContactTypes: action.systemContactTypes.map((x) => {
                return {
                    id: x.id,
                    name: x.type
                };
            }),
            error: undefined,
            hasChanges: false
        })),

        on(actions.loadSystemContactAndRelatedDataFailure, (state, action) => ({
            ...state,
            systemContact: null,
            loading: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })),

        on(actions.updateSystemContactsProfileSuccess, (state, action) => ({
            ...state,
            systemContact: action.systemContact,
            error: undefined
        })),

        on(actions.saveSystemContactsProfileFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error),
            loading: LoadingTypes.FAIL
        })),

        on(actions.saveSystemContactsProfile, (state) => ({
            ...state,
            error: undefined,
            loading: LoadingTypes.LOADING
        })),

        on(actions.getInvitation, (state, action) => ({
            ...state,
            invitation: action.refresh ? { status: InvitationStatusEnum.InvitationPending } : null,
            error: undefined,
            loading: LoadingTypes.LOADING
        })),

        on(actions.getInvitationSuccess, (state, action) => ({
            ...state,
            error: undefined,
            invitation: action.invitation,
            loading: LoadingTypes.LOADED
        })),

        on(actions.getInvitationFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error),
            loading: LoadingTypes.FAIL
        })),

        on(actions.sendInvitaion, (state) => ({
            ...state,
            error: undefined,
            loading: LoadingTypes.LOADING
        })),

        on(actions.sendInvitaionSuccess, (state, action) => ({
            ...state,
            error: undefined,
            loading: LoadingTypes.LOADED
        })),

        on(actions.sendInvitaionFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error),
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: SystemContactsProfilesState | undefined, action: Action) {
    return adminSystemContactsProfilesReducer(state, action);
}
