/**angular */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

/**rxjs */
import { forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

/**local */
import { VendorCandidateFile } from 'src/app/admin/vendor-candidates-detail/models/vendor-candidate-file.model';
import { FileType } from 'src/app/shared/models/file-type.model';

/**shared */
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { FetchArgs2 } from 'src/app/shared/models';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { appendQuery, combine, getQueryString } from 'src/app/shared/utilities';
import { saveAs } from 'js/lib/file-saver/FileSaver';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { getStringQueryFromGridSearch } from 'src/app/shared/utilities/filter-descriptor-utility';

/**kendo */
import { GridDataResult } from '@progress/kendo-angular-grid';

@Injectable({
    providedIn: 'root'
})
export class VendorCandidatesDetailFilesService {
    private readonly apiPath: string;
    getStringQueryFromGridSearchRef = getStringQueryFromGridSearch;

    constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {
        this.apiPath = combine(_settings.CORE, 'ayaconnect', 'admin', 'vendor-candidates');
    }

    public getVendorCandidateFiles(candidateId: number, request: GridSearchQuery): Observable<GridDataResult> {
        const queryParams = this.getStringQueryFromGridSearchRef(request);
        const url = `${combine(this.apiPath, candidateId.toString(), 'files')}${queryParams}`;

        return this._httpClient.get<GridDataResult>(url);
    }

    getFiles(args: FetchArgs2, candidateId: number): Observable<PagingToken<VendorCandidateFile[]>> {
        let url = combine(this.apiPath, candidateId.toString(), 'files');
        if (args.filter) {
            url += getQueryString(args.filter);
        }
        if (args.sort && args.sort.field && args.sort.field.length) {
            url = appendQuery(url, `sortField=${args.sort.field}&sortType=${args.sort.dir || 'asc'}`);
        }

        const path = appendQuery(url, `pageSize=${args.pageSize}`, `skip=${args.skip}`);
        return this._httpClient.get<PagingToken<VendorCandidateFile[]>>(path);
    }
    public downloadFile(candidateFile: VendorCandidateFile): void {
        const url = combine(
            this.apiPath,
            candidateFile.vendorCandidateId.toString(),
            'files',
            candidateFile.fileId.toString()
        );
        this._httpClient
            .get(url, { responseType: 'blob' })
            .pipe(first())
            .subscribe((data) => {
                var blob = new Blob([data]);
                saveAs(blob, candidateFile.file.name + candidateFile.file.type);
            });
    }

    public deleteFiles(candidateId: number, fileIds: number[]): Observable<any> {
        const fileIdQuery = fileIds.map((fileId) => `fileIds=${fileId}`).join('&');
        const url = `${combine(this.apiPath, candidateId.toString(), 'files')}?${fileIdQuery}`;
        return this._httpClient.delete(url);
    }

    getFileTypes(): Observable<FileType[]> {
        const url = `${combine(this._settings.CORE, 'ayaconnect', 'candidates', 'file-types')}`;

        return this._httpClient.get<FileType[]>(url);
    }

    public addFiles(candidateId: number, candidateFiles: any[]): Observable<number[]> {
        const requests: Array<Observable<number>> = candidateFiles.map((file) => {
            const uploadData = new FormData();
            uploadData.append('fileTypeId', file.fileTypeId);
            uploadData.append('file', file.fileToUpload);
            const url = combine(this.apiPath, candidateId.toString(), 'files');
            return this._httpClient.post<number>(url, uploadData);
        });

        return forkJoin(requests);
    }
}
