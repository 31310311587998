import { createAction, props } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ListItem } from 'src/app/shared/models/list-item';

/** @deprecated use loadSubmittals_V2 */
export const loadSubmittals = createAction('[ADMIN SUBMITTALS COMPONENT] Load Admin Submittals');
/** @deprecated use loadSubmittalsSuccess_V2 */
export const loadSubmittalsSuccess = createAction(
    '[ADMIN SUBMITTALS API] Load Admin Submittals Success',
    props<{ submittals: GridDataResult }>()
);
/** @deprecated use loadSubmittalsFailure_V2 */
export const loadSubmittalsFailure = createAction(
    '[ADMIN SUBMITTALS API] Load Admin Submittals Failure',
    props<{ error: unknown }>()
);
/** @deprecated use setSubmittalsSearchQuery_V2 */
export const setSubmittalsSearchQuery = createAction(
    '[ADMIN SUBMITTALS COMPONENT] Set Search Query State',
    props<{ searchQuery: GridSearchQuery }>()
);
export const exportSubmittals = createAction('[ADMIN SUBMITTALS COMPONENT] Export Admin Submittals');
export const exportSubmittalsSuccess = createAction(
    '[ADMIN SUBMITTALS API] Export Admin Submittals Success',
    props<{ result: Blob }>()
);
export const exportSubmittalsFailure = createAction(
    '[ADMIN SUBMITTALS API] Export Admin Submittals Failure',
    props<{ error: unknown }>()
);

export const componentLoaded = createAction('[ADMIN SUBMITTALS COMPONENT] Component Loaded');

export const loadSubmittals_V2 = createAction('[ADMIN SUBMITTALS COMPONENT] Load Admin Submittals_V2');
export const loadSubmittalsSuccess_V2 = createAction(
    '[ADMIN SUBMITTALS API] Load Admin Submittals Success_V2',
    props<{ submittals: GridDataResult }>()
);
export const loadSubmittalsFailure_V2 = createAction(
    '[ADMIN SUBMITTALS API] Load Admin Submittals Failure_V2',
    props<{ error: unknown }>()
);

export const setSubmittalsSearchQuery_V2 = createAction(
    '[ADMIN SUBMITTALS SET SEARCH QUERY] Set Submittals Search Query_V2',
    props<{ searchQuery: GridSearchQuery }>()
);

export const loadSubmittalsLookupStatuses = createAction(
    '[ADMIN SUBMITTALS LOAD LOOKUP STATUSES] Admin Submittal Statuses Lookup'
);
export const loadSubmittalsLookupStatusesSuccess = createAction(
    '[ADMIN SUBMITTALS SERVICE API] Admin Submittal Statuses Lookup Success',
    props<{ statuses: ListItem[] }>()
);
export const loadSubmittalsLookupStatusesFail = createAction(
    '[ADMIN SUBMITTALS SERVICE API] Admin Submittal Statuses Lookup Failure',
    props<{ error: unknown }>()
);

export const loadSubmittalsLookupSubStatuses = createAction(
    '[ADMIN SUBMITTALS LOAD LOOKUP SUBSTATUSES] Admin Submittal Sub Statuses Lookup',
    props<{ statusId: number }>()
);

export const loadSubmittalsLookupSubStatusesSuccess = createAction(
    '[ADMIN SUBMITTALS SERVICE API] Admin Submittal Sub Statuses Lookup Success',
    props<{ subStatuses: ListItem[] }>()
);

export const loadSubmittalsLookupSubStatusesFail = createAction(
    '[ADMIN SUBMITTALS SERVICE API] Admin Submittal Sub Statuses Lookup Failure',
    props<{ error: unknown }>()
);
