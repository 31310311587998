import { createAction, props } from '@ngrx/store';
import { LocationType } from 'src/app/admin/vendor-details/profile/models/location-type.model';
import { VendorLocation } from 'src/app/admin/vendor-details/profile/models/vendor-location.model';

export const loadVendorProfileLocations = createAction(
    '[VENDOR PROFILE LOCATIONS COMPONENT] Load Vendor Profile Locations By Vendor Id',
    props<{ vendorId: number }>()
);
export const loadVendorProfileLocationsSuccess = createAction(
    '[VENDOR PROFILE LOCATIONS API] Load Vendor Profile Locations By Vendor Id Success',
    props<{ response: VendorLocation[]; vendorId: number }>()
);
export const loadVendorProfileLocationsFailure = createAction(
    '[VENDOR PROFILE LOCATIONS API] Load Vendor Profile Locations By Vendor Id Failure',
    props<{ error: unknown }>()
);
export const loadLocationTypes = createAction(
    '[VENDOR PROFILE LOCATIONS COMPONENT] Load Vendor Profile Location Types'
);
export const loadLocationTypesSuccess = createAction(
    '[VENDOR PROFILE LOCATIONS API] Load Vendor Profile Location Types Success',
    props<{ locationTypes: LocationType[] }>()
);
export const loadLocationTypesFailure = createAction(
    '[VENDOR PROFILE LOCATIONS API] Load Vendor Profile Location Types Failure',
    props<{ error: unknown }>()
);

export const updateVendorProfileLocation = createAction(
    '[VENDOR PROFILE LOCATIONS COMPONENT] Update Vendor Profile Location',
    props<{
        id: number;
        vendorLocationTypeId: number;
        address1: string;
        address2: string;
        city: string;
        zipCode: string;
        stateId?: number;
        note: string;
    }>()
);
export const deleteVendorProfileLocation = createAction(
    '[VENDOR PROFILE LOCATIONS COMPONENT] Delete Vendor Profile Location',
    props<{ locationId: number }>()
);

export const addVendorProfileLocation = createAction(
    '[VENDOR PROFILE LOCATIONS COMPONENT ADD LOCATION BUTTON] Add Vendor Profile Location',
    props<{ location: VendorLocation }>()
);

export const addVendorProfileLocations = createAction(
    '[VENDOR PROFILE LEGACY ANGULARJS] Add Location From Legacy AngularJS',
    props<{ vendorId: number }>()
);
export const addVendorProfileLocationsSuccess = createAction(
    '[VENDOR PROFILE LOCATIONS API] Add Location Successfully',
    props<{ vendorId: number }>()
);
export const addVendorProfileLocationsFailure = createAction(
    '[VENDOR PROFILE LOCATIONS API] Add Location Failure',
    props<{ error: unknown }>()
);

export const deleteVendorProfileLocations = createAction(
    '[VENDOR PROFILE LEGACY ANGULARJS] Delete Location From Legacy AngularJS',
    props<{ vendorId: number }>()
);
export const deleteVendorProfileLocationsSuccess = createAction(
    '[VENDOR PROFILE LOCATIONS API] Delete Location Successfully',
    props<{ vendorId: number }>()
);
export const deleteVendorProfileLocationsFailure = createAction(
    '[VENDOR PROFILE LOCATIONS API] Delete Location Failure',
    props<{ error: unknown }>()
);

export const updateVendorProfileLocations = createAction(
    '[VENDOR PROFILE LEGACY ANGULARJS] Update Location From Legacy AngularJS',
    props<{ vendorId: number }>()
);
export const updateVendorProfileLocationsSuccess = createAction(
    '[VENDOR PROFILE LOCATIONS API] Update Location Successfully',
    props<{ vendorId: number }>()
);
export const updateVendorProfileLocationsFailure = createAction(
    '[VENDOR PROFILE LOCATIONS API] Update Location Failure',
    props<{ error: unknown }>()
);

export const validVendorProfileLocations = createAction('[VENDOR PROFILE LOCATIONS COMPONENT] Valid Location');

export const invalidVendorProfileLocations = createAction('[VENDOR PROFILE LOCATIONS COMPONENT] Invalid Location');

export const resetVendorLocations = createAction('[VENDOR PROFILE LOCATIONS SECTION] Reset Vendor Locations');
