import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubmittalsService } from 'src/app/submittals/services/submittals.service';
import * as submittalActions from 'src/app/submittals/store/submittals.actions';
import * as fromSubmittals from 'src/app/submittals/store/submittals.selectors';
import { SubmittalsBaseEffect } from './submittals-base.effect';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { SortTypes } from 'src/app/shared/models';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { select, Store } from '@ngrx/store';
import { getSortCriterion } from 'src/app/core/utils/get-sort-criterion';

@Injectable()
export class SubmittalsListEffect extends SubmittalsBaseEffect {
    getSubmittalsList$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittals),
            withLatestFrom(this.store.pipe(select(fromSubmittals.selectSubmittalQuery))),
            switchMap(([action, query]) => {
                const sortCondition = getSortCriterion(query);

                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };
                const sortArgs = {
                    sortField: sortCondition && sortCondition[0].field,
                    sortType: sortCondition && (sortCondition[0].dir as SortTypes)
                };
                const matchArgs = flattenFilter(query.filter);
                const request = action.isConnectDetailIRPJobSubmittals
                    ? this.submittalsService.getSubmittalsInternal(pagination, sortArgs, matchArgs)
                    : this.submittalsService.getSubmittals(pagination, sortArgs, matchArgs);

                return request.pipe(
                    map((res) =>
                        res
                            ? submittalActions.loadSubmittalsSuccess({ submittals: res.data, total: res.total })
                            : submittalActions.loadSubmittalsFail({ error: 'Cannot load submittals.' })
                    ),
                    catchError((error) => of(submittalActions.loadSubmittalsFail({ error })))
                );
            })
        )
    );

    getVendorInfoForSubmittals$ = createEffect(() => {
        return this.actions.pipe(
            ofType(submittalActions.loadSubmittalDiversityInfo),
            switchMap(() => {
                return this.submittalsService.getDiversityInfo().pipe(
                    map((submittalInfo) => submittalActions.loadSubmittalDiversityInfoSuccess({ submittalInfo })),
                    catchError((error: unknown) => of(submittalActions.loadSubmittalDiversityInfoFail({ error })))
                );
            })
        );
    });

    getSubmittalsListSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalsSuccess),
                tap((res) => {
                    this.logData('Submittals loaded.');
                })
            ),
        { dispatch: false }
    );

    getSubmittalsFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalsFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );

    export$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.exportSubmittals),
            withLatestFrom(this.store.pipe(select(fromSubmittals.selectSubmittalQuery))),
            switchMap(([action, query]) => {
                const sortCondition = getSortCriterion(query);
                const sortArgs = {
                    sortField: sortCondition && sortCondition[0].field,
                    sortType: sortCondition && (sortCondition[0].dir as SortTypes)
                };

                let matchArgs = flattenFilter(query.filter);
                matchArgs = {
                    ...matchArgs,
                    submittalIds: action.submittalIds,
                    isConnectDetailIRPJobSubmittals: action.isConnectDetailIRPJobSubmittals
                };
                return this.submittalsService.export(sortArgs, matchArgs).pipe(
                    map((res) => submittalActions.exportSubmittalsSuccess({ payload: res })),
                    catchError((error) => of(submittalActions.exportSubmittalsFail({ error })))
                );
            })
        )
    );

    exportSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.exportSubmittalsSuccess),
                map((action) => {
                    this.saveFile(action.payload);
                })
            ),
        { dispatch: false }
    );

    exportFail$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.exportSubmittalsFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions: Actions,
        private readonly store: Store<{}>,
        private readonly submittalsService: SubmittalsService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
