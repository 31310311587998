import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { filter, first, Subject, takeUntil } from 'rxjs';
import { ToasterService } from 'src/app/core/services';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { SubmittalOfferDetails, SubmittalOfferDetailsUpdateRequest } from 'src/app/shared/models/submittals';
import { DateHelper } from 'src/app/shared/utilities';
import { ShiftTypeEnum } from 'src/app/shifts/models/shift-type.enum';
import { CandidateOfferShiftType } from 'src/app/submittals/models';
import { DateChangeType, SubmittalHelper } from 'src/app/submittals/shared/submittal-helper';

const RtoApprovalValidator: ValidatorFn = (formControl: FormControl) => {
    if (!formControl.parent) {
        return null;
    }
    const rtoDates = formControl.parent.get('rtoDates').value;
    const isRtoApproved = formControl.value;
    return !rtoDates?.length && isRtoApproved ? { rtoApproval: true } : null;
};

@Component({
    selector: 'ayac-submittal-offer-details',
    templateUrl: './submittal-offer-details.component.html',
    styleUrls: ['./submittal-offer-details.component.scss']
})
export class SubmittalOfferDetailsComponent extends UnsubscribeOnDestroy implements OnInit {
    private subscriptions = [];

    @Input() offer: SubmittalOfferDetails;
    @Output() saveOffer = new EventEmitter<SubmittalOfferDetailsUpdateRequest>();
    @Output() canUpdate = new EventEmitter<boolean>();
    standaloneVmsEditOfferFlag$ = this._featureManager.isEnabled(FeatureFlag.ConnectVMSClientEditOffer);
    standaloneVmsOfferNotesFlag$ = this._featureManager.isEnabled(FeatureFlag.ConnectVMSClientOfferNotes);

    minStartDate = new Date();
    minEndDate: Date;

    inEditMode = false;
    originalData: SubmittalOfferDetails;
    offerForm: FormGroup;
    changesMade = false;

    readonly collectOfferDetailsUpdateRequest$ = new Subject<boolean>();
    readonly offerInEditMode$ = new Subject<boolean>();

    shiftTimeOfDayText: string;
    shiftAltTimeOfDayText: string;

    timeSteps = { hour: 1, minute: 1 };
    hoursDropdownOptions = [1, 2, 3, 4, 5, 6];
    shiftLengthOptions = [7.5, 8, 9.5, 10, 11.5, 12];
    shiftTimeOfDayOptions = [
        { id: ShiftTypeEnum.Day, name: 'Day' },
        { id: ShiftTypeEnum.Evening, name: 'Evening' },
        { id: ShiftTypeEnum.Night, name: 'Night' }
    ];

    shiftTypeOptions = [
        { id: CandidateOfferShiftType.Standard, name: 'Standard' },
        { id: CandidateOfferShiftType.Alternating, name: 'Alternating' },
        { id: CandidateOfferShiftType.Rotating, name: 'Rotating' }
    ];

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _toasterService: ToasterService,
        private readonly _featureManager: LDFeatureManager,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    get showAlternatingFields() {
        return this.offerForm.get('shiftTypeId').value === CandidateOfferShiftType.Alternating;
    }

    get showRotatingFields() {
        return this.offerForm.get('shiftTypeId').value === CandidateOfferShiftType.Rotating;
    }

    get daysLength() {
        return SubmittalHelper.calculateContractDaysLength(
            new Date(this.offer.startDate),
            new Date(this.offer.endDate)
        );
    }

    get weeksLength() {
        return SubmittalHelper.calculateContractWeeksLength(
            new Date(this.offer.startDate),
            new Date(this.offer.endDate)
        );
    }

    get submittalOfferDetailsUpdateRequest() {
        const shiftTimeStart = this.offerForm.get('shiftTimeStart').value as Date;
        const shiftTimeEnd = this.offerForm.get('shiftTimeEnd').value as Date;
        const shiftAltTimeStart = this.offerForm.get('shiftAltTimeStart').value as Date;
        const shiftAltTimeEnd = this.offerForm.get('shiftAltTimeEnd').value as Date;
        const startDate = Array.isArray(this.offerForm.get('startDate').value)
            ? this.offerForm.get('startDate').value[0]
            : this.offerForm.get('startDate').value;
        const endDate = Array.isArray(this.offerForm.get('endDate').value)
            ? this.offerForm.get('endDate').value[0]
            : this.offerForm.get('endDate').value;

        const request: SubmittalOfferDetailsUpdateRequest = {
            offerId: this.offer.offerId,
            startDate,
            endDate,
            shiftTypeId: this.offerForm.get('shiftTypeId').value,
            shifts: this.offerForm.get('shifts').value,
            shiftsAlt: this.offerForm.get('shiftsAlt').value,
            hours: this.offerForm.get('hours').value,
            hoursAlt: this.offerForm.get('hoursAlt').value,
            shiftTimeOfDayId: this.shiftTimeOfDayOptions.find((x) => x.name === this.shiftTimeOfDayText).id,
            shiftAltTimeOfDayId: this.shiftAltTimeOfDayText
                ? this.shiftTimeOfDayOptions.find((x) => x.name === this.shiftAltTimeOfDayText).id
                : null,
            shiftH: shiftTimeStart ? shiftTimeStart.getHours() : null,
            shiftM: shiftTimeStart ? shiftTimeStart.getMinutes() : null,
            shiftToH: shiftTimeEnd ? shiftTimeEnd.getHours() : null,
            shiftToM: shiftTimeEnd ? shiftTimeEnd.getMinutes() : null,
            shiftAltH: shiftAltTimeStart ? shiftAltTimeStart?.getHours() : null,
            shiftAltM: shiftAltTimeStart ? shiftAltTimeStart?.getMinutes() : null,
            shiftAltToH: shiftAltTimeEnd ? shiftAltTimeEnd?.getHours() : null,
            shiftAltToM: shiftAltTimeEnd ? shiftAltTimeEnd?.getMinutes() : null,
            isRtoApproved: this.offerForm.get('isRtoApproved').value,
            rtoDates: this.offerForm.get('rtoDates').value ? this.offerForm.get('rtoDates').value : []
        };
        return request;
    }

    @Input()
    set collectOfferDetailsUpdateRequest(value: boolean) {
        this.collectOfferDetailsUpdateRequest$.next(value);
    }

    @Input()
    set offerInEditMode(value: boolean) {
        this.offerInEditMode$.next(value);
    }

    rtoDatesFormat = (date: Date[]) => {
        return DateHelper.rtoDatesToString(date, 'MM/DD/YYYY');
    };

    ngOnInit(): void {
        this.originalData = { ...this.offer };
        this.updateMinEndDate(this.offer.startDate);
        this.shiftTimeOfDayText = this.shiftTimeOfDayOptions.find((x) => x.id === this.offer.shiftTimeOfDayId).name;
        this.shiftAltTimeOfDayText = this.offer.shiftAltTimeOfDayId
            ? this.shiftTimeOfDayOptions.find((x) => x.id === this.offer.shiftAltTimeOfDayId).name
            : null;
        this.buildForm();
        this.collectOfferDetailsUpdateRequest$.pipe(filter(Boolean), takeUntil(this.d$)).subscribe(() => {
            const savedOffer = this.submittalOfferDetailsUpdateRequest;
            this.saveOffer.emit(savedOffer);
            this.originalData = { ...this.offer, ...savedOffer, requestedTimeOffIsApproved: savedOffer.isRtoApproved };
        });
        this.offerInEditMode$.pipe(takeUntil(this.d$)).subscribe((value) => {
            this.inEditMode = value;
            if (!this.inEditMode) {
                this.offer = { ...this.originalData };
                this.subscriptions.forEach((sub) => sub.unsubscribe());
                this.subscriptions = [];
                this.buildForm();
                this.changesMade = false;
                this.canUpdate.emit(false);
            } else {
                this.originalData = { ...this.offer };
            }
        });
    }

    canUpdateOffer(status: boolean) {
        this.canUpdate.emit(status);
    }

    updateMinEndDate(date: Date): void {
        this.minEndDate = dayjs(date).subtract(1, 'day').toDate();
    }

    calculateContractDates(changeType: DateChangeType): void {
        SubmittalHelper.calculateContractDates(
            changeType,
            this.offerForm,
            this._toasterService,
            this.updateMinEndDate.bind(this)
        );
    }

    hasError = (controlName: string, errorName: string) => {
        return this.offerForm.controls[controlName].hasError(errorName);
    };

    onShiftTimeStartChange(shiftTimeStart: Date) {
        this.shiftTimeOfDayText = this.shiftTimeOfDayOptions.find(
            (x) => x.id === SubmittalHelper.getShiftTimeTypeId(shiftTimeStart?.getHours() ?? 0)
        ).name;
        this.refreshShiftEndTime();
    }

    onShiftAltTimeStartChange(shiftAltTimeStart: Date) {
        this.shiftAltTimeOfDayText = this.shiftTimeOfDayOptions.find(
            (x) => x.id === SubmittalHelper.getShiftTimeTypeId(shiftAltTimeStart?.getHours() ?? 0)
        ).name;
        this.refreshShiftEndTimeAlt();
    }

    refreshShiftEndTime() {
        const hours = this.offerForm.get('hours').value;
        const shiftTimeStart = this.offerForm.get('shiftTimeStart').value;
        if (hours && shiftTimeStart) {
            const endTime = SubmittalHelper.getShiftEndTime(hours, shiftTimeStart);
            this.offerForm.patchValue({ shiftTimeEnd: endTime });
        }
    }

    refreshShiftEndTimeAlt() {
        const shiftType = this.offerForm.get('shiftTypeId').value;
        const hoursAlt =
            shiftType === CandidateOfferShiftType.Alternating
                ? this.offerForm.get('hoursAlt').value
                : this.offerForm.get('hours').value;
        const shiftAltTimeStart = this.offerForm.get('shiftAltTimeStart').value;
        if (hoursAlt && shiftAltTimeStart) {
            const endTimeAlt = SubmittalHelper.getShiftEndTime(hoursAlt, shiftAltTimeStart);
            this.offerForm.patchValue({ shiftAltTimeEnd: endTimeAlt });
        }
    }

    private buildForm(): void {
        this.offerForm = this._fb.group({
            offerId: [this.offer.offerId],
            startDate: [this.offer.startDate],
            endDate: [this.offer.endDate],
            weeksLength: [
                SubmittalHelper.calculateContractWeeksLength(
                    new Date(this.offer.startDate),
                    new Date(this.offer.endDate)
                ),
                [Validators.required]
            ],
            daysLength: [
                SubmittalHelper.calculateContractDaysLength(
                    new Date(this.offer.startDate),
                    new Date(this.offer.endDate)
                ),
                [Validators.required]
            ],
            shiftTypeId: [this.offer.shiftTypeId, [Validators.required]],
            shifts: [this.offer.shifts, [Validators.required]],
            shiftsAlt: [this.offer.shiftsAlt],
            hours: [this.offer.hours, [Validators.required]],
            hoursAlt: [this.offer.hoursAlt],
            shiftTimeStart: [
                dayjs().set('hour', this.offer.shiftH).set('minute', this.offer.shiftM).toDate(),
                [Validators.required]
            ],
            shiftAltTimeStart: [dayjs().set('hour', this.offer.shiftAltH).set('minute', this.offer.shiftAltM).toDate()],
            shiftTimeEnd: [
                dayjs().set('hour', this.offer.shiftToH).set('minute', this.offer.shiftToM).toDate(),
                [Validators.required]
            ],
            shiftAltTimeEnd: [
                dayjs().set('hour', this.offer.shiftAltToH).set('minute', this.offer.shiftAltToM).toDate()
            ],
            isRtoApproved: [this.offer.requestedTimeOffIsApproved, [RtoApprovalValidator]],
            rtoDates: [this.offer.rtoDates ?? []]
        });

        this.subscriptions.push(
            this.offerForm
                .get('startDate')
                .valueChanges.pipe(takeUntil(this.d$))
                .subscribe(() => this.calculateContractDates('startDateChanged'))
        );

        this.subscriptions.push(
            this.offerForm
                .get('endDate')
                .valueChanges.pipe(takeUntil(this.d$))
                .subscribe(() => this.calculateContractDates('endDateChanged'))
        );

        this.subscriptions.push(
            this.offerForm
                .get('rtoDates')
                .valueChanges.pipe(takeUntil(this.d$))
                .subscribe(() => this.offerForm.get('isRtoApproved').updateValueAndValidity())
        );

        this.subscriptions.push(
            this.offerForm.statusChanges
                .pipe(takeUntil(this.d$))
                .subscribe((status) => this.canUpdate.emit(this.changesMade && status === 'VALID'))
        );

        this.subscriptions.push(
            this.offerForm
                .get('hours')
                .valueChanges.pipe(takeUntil(this.d$))
                .subscribe(() => {
                    this.refreshShiftEndTime();
                    this.refreshShiftEndTimeAlt();
                })
        );

        this.subscriptions.push(
            this.offerForm
                .get('hoursAlt')
                .valueChanges.pipe(takeUntil(this.d$))
                .subscribe(() => {
                    this.refreshShiftEndTimeAlt();
                })
        );

        this.subscriptions.push(
            this.offerForm
                .get('shiftTimeStart')
                .valueChanges.pipe(takeUntil(this.d$))
                .subscribe((value) => this.onShiftTimeStartChange(value))
        );

        this.subscriptions.push(
            this.offerForm
                .get('shiftAltTimeStart')
                .valueChanges.pipe(takeUntil(this.d$))
                .subscribe((value) => this.onShiftAltTimeStartChange(value))
        );

        this.subscriptions.push(
            this.offerForm
                .get('shiftTypeId')
                .valueChanges.pipe(takeUntil(this.d$))
                .subscribe((shiftType: CandidateOfferShiftType) => {
                    switch (shiftType) {
                        case CandidateOfferShiftType.Standard:
                            this.offerForm.get('shiftsAlt').removeValidators([Validators.required]);
                            this.offerForm.get('hoursAlt').removeValidators([Validators.required]);
                            this.offerForm.get('shiftAltTimeStart').removeValidators([Validators.required]);
                            this.offerForm.get('shiftAltTimeEnd').removeValidators([Validators.required]);
                            this.offerForm.get('shiftsAlt').setValue(null);
                            this.offerForm.get('hoursAlt').setValue(null);
                            this.offerForm.get('shiftAltTimeStart').setValue(null);
                            this.offerForm.get('shiftAltTimeEnd').setValue(null);
                            break;
                        case CandidateOfferShiftType.Alternating:
                            this.offerForm.get('shiftsAlt').addValidators([Validators.required]);
                            this.offerForm.get('hoursAlt').addValidators([Validators.required]);
                            this.offerForm.get('shiftAltTimeStart').addValidators([Validators.required]);
                            this.offerForm.get('shiftAltTimeEnd').addValidators([Validators.required]);
                            break;
                        case CandidateOfferShiftType.Rotating:
                            this.offerForm.get('shiftsAlt').removeValidators([Validators.required]);
                            this.offerForm.get('hoursAlt').removeValidators([Validators.required]);
                            this.offerForm.get('shiftAltTimeStart').addValidators([Validators.required]);
                            this.offerForm.get('shiftAltTimeEnd').addValidators([Validators.required]);
                            break;
                    }
                    this.onShiftTimeStartChange(this.offerForm.get('shiftTimeStart').value);
                    this.onShiftAltTimeStartChange(this.offerForm.get('shiftAltTimeStart').value);
                    this.changeDetectorRef.detectChanges();
                    this.offerForm.updateValueAndValidity();
                })
        );

        this.offerForm.updateValueAndValidity();

        this.subscriptions.push(
            this.offerForm.valueChanges.pipe(first(), takeUntil(this.d$)).subscribe(() => {
                this.changesMade = true;
                this.canUpdate.emit(this.offerForm.valid);
            })
        );
    }
}
