import { Action, createReducer, on } from '@ngrx/store';
import {
    getInitialAvailableShiftListState,
    getInitialCurrentShiftListState,
    getInitialVendorCandidateContractRequirementsState,
    getInitialVendorCandidateDetailsState,
    getInitialVendorCandidateFileListState,
    AvailableShiftListState,
    ContractRequirementsState,
    CurrentShiftListState,
    VendorCandidateDetailsState,
    VendorCandidateFileListState
} from '../state/vendor-candidate-details.state';

import * as actions from '../actions/vendor-candidate-details.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { vendorCandidateProfileReducer } from 'src/app/vendor/vendor-candidate-details/store/reducers/vendor-candidate-profile.reducers';
import { vendorCandidateRequirementDocumentUploadReducer } from './vendor-candidate-requirement-document-upload.reducer';

export const vendorCandidateDetailsReducer = (reducerState: VendorCandidateDetailsState, reducerAction: Action) => {
    return createReducer(
        getInitialVendorCandidateDetailsState(),
        on(
            actions.getHasContractAtPerDiemHospitalSuccess,
            (state, action): VendorCandidateDetailsState => ({
                ...state,
                hasContractAtPerDiemHospital: action.hasContract
            })
        )
    )(reducerState, reducerAction);
};

export const vendorCandidateContractRequirementsReducer = (
    reducerState: ContractRequirementsState,
    reducerAction: Action
) => {
    return createReducer(
        getInitialVendorCandidateContractRequirementsState(),
        on(
            actions.getContractRequirementsSuccess,
            (state, action): ContractRequirementsState => ({
                ...state,
                // Remove loading from here when LazyLoadVendorCandidateContractRequirements feature flag remove
                loadingState: LoadingTypes.LOADED,
                contractRequirementsLoading: LoadingTypes.LOADED,
                contractRequirements: action.contracts
            })
        ),
        on(
            actions.getContractRequirementsFail,
            (state, action): ContractRequirementsState => ({
                ...state,
                contractRequirementsLoading: LoadingTypes.FAIL,
                error: JSON.stringify(action.error)
            })
        ),
        on(
            actions.loadViewRequirementDetails,
            (state): ContractRequirementsState => ({
                ...state,
                loadingState: LoadingTypes.LOADING
            })
        ),
        on(
            actions.loadViewRequirementDetailsSuccess,
            (state): ContractRequirementsState => ({
                ...state,
                loadingState: LoadingTypes.LOADED
            })
        ),
        on(
            actions.loadViewRequirementDetailsFailure,
            (state): ContractRequirementsState => ({
                ...state,
                loadingState: LoadingTypes.FAIL
            })
        ),
        on(
            actions.clearContractRequirements,
            (state): ContractRequirementsState => ({
                ...state,
                contractRequirementsLoading: LoadingTypes.INIT,
                contractRequirements: null
            })
        ),
    )(reducerState, reducerAction);
};

export const vendorCandidateFileListReducer = (reducerState: VendorCandidateFileListState, reducerAction: Action) => {
    return createReducer(
        getInitialVendorCandidateFileListState(),
        on(
            actions.loadVendorCandidateFileList,
            (state, action): VendorCandidateFileListState => ({
                ...state,
                request: action.request,
                candidateId: action.candidateId,
                isLoading: true
            })
        ),
        on(actions.loadVendorCandidateFileListSuccess, (state, action): VendorCandidateFileListState => {
            const fileSize = action.response.data.reduce((accumulator, record) => {
                return accumulator + (record.file?.size || 0);
            }, 0);

            return {
                ...state,
                response: action.response,
                candidateId: action.candidateId,
                totalFileSize: fileSize,
                isLoading: false
            };
        }),
        on(
            actions.loadVendorCandidateFileListFailure,
            (state, action): VendorCandidateFileListState => ({
                ...state,
                response: null,
                error: action.error,
                isLoading: false
            })
        ),
        on(
            actions.deleteVendorCandidateFiles,
            (state, action): VendorCandidateFileListState => ({
                ...state,
                deletingFileIds: action.fileIds,
                isDeleting: true,
                isDeleteSuccess: undefined
            })
        ),
        on(
            actions.deleteVendorCandidateFilesSuccess,
            (state): VendorCandidateFileListState => ({
                ...state,
                deletingFileIds: [],
                isDeleting: false,
                isDeleteSuccess: true
            })
        )
    )(reducerState, reducerAction);
};

export const currentShiftListReducer = (reducerState: CurrentShiftListState, reducerAction: Action) => {
    return createReducer(
        getInitialCurrentShiftListState(),
        on(
            actions.loadCurrentShiftList,
            (state): CurrentShiftListState => ({
                ...state,
                loadingState: LoadingTypes.LOADING
            })
        ),
        on(
            actions.loadCurrentShiftListSuccess,
            (state, action): CurrentShiftListState => ({
                ...state,
                shifts: action.shifts,
                loadingState: LoadingTypes.LOADED
            })
        ),
        on(
            actions.loadCurrentShiftListFailure,
            (state): CurrentShiftListState => ({
                ...state,
                loadingState: LoadingTypes.LOADED
            })
        ),
        on(
            actions.setCurrentShiftListSearchQuery,
            (state, action): CurrentShiftListState => ({
                ...state,
                gridSearchQuery: action.searchQuery
            })
        )
    )(reducerState, reducerAction);
};

export const availableShiftListReducer = (reducerState: AvailableShiftListState, reducerAction: Action) => {
    return createReducer(
        getInitialAvailableShiftListState(),
        on(
            actions.loadAvailableShiftList,
            (state): AvailableShiftListState => ({
                ...state,
                loadingState: LoadingTypes.LOADING
            })
        ),
        on(
            actions.loadAvailableShiftListSuccess,
            (state, action): AvailableShiftListState => ({
                ...state,
                shifts: action.shifts,
                loadingState: LoadingTypes.LOADED
            })
        ),
        on(
            actions.loadAvailableShiftListFailure,
            (state): AvailableShiftListState => ({
                ...state,
                loadingState: LoadingTypes.LOADED
            })
        ),
        on(
            actions.setAvailableShiftListSearchQuery,
            (state, action): AvailableShiftListState => ({
                ...state,
                loadingState: LoadingTypes.LOADING,
                gridSearchQuery: action.searchQuery
            })
        )
    )(reducerState, reducerAction);
};

export const vendorCandidateDetailsReducers = {
    candidateDetailsState: vendorCandidateDetailsReducer,
    candidateFileListState: vendorCandidateFileListReducer,
    currentShiftListState: currentShiftListReducer,
    availableShiftListState: availableShiftListReducer,
    contractRequirementsState: vendorCandidateContractRequirementsReducer,
    candidateProfile: vendorCandidateProfileReducer,
    documentUpload: vendorCandidateRequirementDocumentUploadReducer
};
