import { createAction, props } from '@ngrx/store';
import { ResourceList } from 'src/app/shared/models/internal-pool/resource-list.model';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { BulkShift, FilterParams, InternalPoolResouceAvailability, ViewByTypes } from 'src/app/shared/models/shifts';
import { ShiftDetails } from 'src/app/shared/models/shifts/shift-details.model';
import { ShiftFacilityFeature } from 'src/app/shared/models/shifts/shift-facility-features.model';
import { Shift } from 'src/app/shared/models/shifts/shift.model';
import { ReleaseGroup, ReleaseGroupVendorFlat } from 'src/app/shared/models/vendor-releases/release-group.model';
import { FacilityAddressInfos } from 'src/app/shifts/models/shift-facilityAddressInfo.model';
import { ReleaseHistory } from 'src/app/shifts/models';
import { FacilityLookup } from 'src/app/shared/models/lookups';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';



export const setShiftsQuery = createAction(
    '[SHIFTS] Set Shifts Search Query',
    props<{ filterParams: FilterParams; refresh?: boolean }>()
);
export const resetShiftsQuery = createAction('[SHIFTS] Reset Shifts Search Query');
export const loadShifts = createAction('[SHIFTS] Load Shifts');
export const loadShiftsSuccess = createAction('[SHIFTS] Shifts Loaded', props<{ shifts: PagingToken<Shift[]> }>());
export const loadShiftsFail = createAction('[SHIFTS] Shifts Load Error', props<{ error: any }>());
export const loadShiftDetails = createAction('[SHIFTS] Load Shift Details', props<{ id: number }>());
export const loadShiftDetailsAndHistory = createAction(
    '[SHIFTS] Load Shift Details and History',
    props<{ id: number }>()
);
export const loadShiftDetailsSuccess = createAction(
    '[SHIFTS] Load Shift Details Success',
    props<{ shift: ShiftDetails }>()
);
export const loadShiftDetailsFail = createAction('[SHIFTS] Load Shift Details Fail', props<{ error: any }>());
export const loadBulkShiftSeries = createAction('[SHIFTS] Load Bulk Shift Series', props<{ jobNumber: number }>());
export const loadBulkShiftSeriesSuccess = createAction(
    '[SHIFTS] Bulk Shift Series Loaded',
    props<{ bulkShifts: BulkShift[] }>()
);
export const loadBulkShiftSeriesFail = createAction('[SHIFTS] Bulk Shift Series Load Error', props<{ error: any }>());
export const clearShiftDetails = createAction('[SHIFTS] Clear Shift Details');
export const setSelectedShift = createAction('[SHIFTS] Set Selected Shift', props<{ selectedShift: Shift }>());
export const exportShiftsList = createAction('[SHIFTS] Export Shifts List');
export const exportShiftsListSuccess = createAction(
    '[SHIFTS] Export Shifts List Success',
    props<{ blob: Blob; filename: string }>()
);
export const exportShiftsListFail = createAction('[SHIFTS] Export Shifts List Fail', props<{ error: string }>());
export const loadShiftFacilityFeatures = createAction(
    '[SHIFTS FACILITY FEATURES API] Load Shift Facilitiy Features',
    props<{ facilityId: number }>()
);
export const loadShiftFacilityFeaturesSuccess = createAction(
    '[SHIFTS FACILITY FEATURES SUCCESS] Load Shift Facilitiy Features success',
    props<{ shiftFacilityFeatures: ShiftFacilityFeature }>()
);
export const loadShiftFacilityFeaturesFail = createAction(
    '[SHIFTS FACILITY FEATURES Fail] Load Shift Facilitiy Features fail',
    props<{ error: any }>()
);

export const loadShiftCandidates = createAction(
    '[SHIFTS] Load Shift Candidates',
    props<{
        silent?: boolean;
        systemId: number;
        internalPoolResourcesAvailability: InternalPoolResouceAvailability;
    }>()
);

export const loadShiftCandidatesSuccess = createAction(
    '[SHIFTS CANDIDATE RESOURCES API] Load Shift Candidates Success',
    props<{ candidates: ResourceList[] }>()
);
export const loadShiftCandidatesFail = createAction(
    '[SHIFTS CANDIDATE RESOURCES API] Load Shift Candidates Fail',
    props<{ error: any }>()
);
export const clearShiftCandidates = createAction('[SHIFTS] Clear Shift Candidates');
export const clearShiftDetailsAndHistory = createAction('[SHIFTS] Clear Shift Details and History');

export const setDashboardShiftsSearchQuery = createAction(
    '[SHIFTS] Set Dashboard Search Query',
    props<{
        lineOfBusinessId?: number;
        lineOfBusinessName?: string;
        facilityId?: number;
        facilityName?: string;
        unitId?: number;
        unitName?: string;
        statusId?: number;
        statusName?: string;
        dateFrom?: Date;
        dateTo?: Date;
        viewByValue: ViewByTypes;
    }>()
);

export const loadSeriesDetails = createAction(
    '[SHIFTS] Load Series Details',
    props<{ seriesId: string; shiftId: number }>()
);
export const loadSeriesDetailsSuccess = createAction(
    '[SHIFTS] Load Series Details Success',
    props<{ shift: ShiftDetails }>()
);
export const loadSeriesDetailsFail = createAction('[SHIFTS] Load Series Details Fail', props<{ error: any }>());

export const loadSingleSeriesDetails = createAction(
    '[SHIFTS] Load Single Series Details',
    props<{ shiftId: number; seriesId: string }>()
);
export const loadSingleSeriesDetailsSuccess = createAction(
    '[SHIFTS] Load Single Series Details Success',
    props<{ shift: ShiftDetails }>()
);
export const loadSingleSeriesDetailsFail = createAction(
    '[SHIFTS] Load Single Series Details Fail',
    props<{ error: any }>()
);
export const setNewShiftDetails = createAction('[SHIFTS] Load New Shift Details', props<{ shift: ShiftDetails }>());

export const loadShiftReleaseGroups = createAction(
    '[SHIFTS] Load Shift Release Groups',
    props<{
        facilityId: number;
        shiftId?: number;
    }>()
);
export const loadShiftReleaseGroupsSuccess = createAction(
    '[SHIFTS] Load Shift Release Groups Success',
    props<{ releaseGroups: ReleaseGroup[] }>()
);
export const loadShiftReleaseGroupsFail = createAction(
    '[SHIFTS] Load Shift Release Groups Fail',
    props<{ error: any }>()
);

export const preselectedVendorsChanged = createAction(
    '[SHIFTS] Preselected Vendors Changed',
    props<{
        preseletedVendors: ReleaseGroupVendorFlat[];
    }>()
);
export const manualShiftRelease = createAction(
    '[SHIFTS] Manual Shift Release',
    props<{
        shiftId: number;
    }>()
);
export const manualShiftReleaseSuccess = createAction('[SHIFTS] Manual Shift Release Success');
export const manualShiftReleaseFail = createAction('[SHIFTS] Manual Shift Release Fail', props<{ error: any }>());

export const loadShiftFacilityAddressInfo = createAction(
    '[SHIFTS] Load Shift Facility Address',
    props<{
        facilityId: number;
    }>()
);

export const loadShiftFacilityAddressInfoSuccess = createAction(
    '[SHIFTS] Load Shift FacilityAddress Success',
    props<{ facilityAddressInfo: FacilityAddressInfos }>()
);
export const loadShiftFacilityAddressInfoFail = createAction(
    '[SHIFTS] Load Shift FacilityAddress Fail',
    props<{ error: any }>()
);
export const loadShiftReleaseHistory = createAction(
    '[SHIFTS] Load Shift Release History',
    props<{ shiftId: number }>()
);
export const loadShiftReleaseHistorySuccess = createAction(
    '[SHIFTS] Load Shift Release History Success',
    props<{ history: ReleaseHistory[] }>()
);
export const loadShiftReleaseHistoryFail = createAction(
    '[SHIFTS] Load Shift Release History Fail',
    props<{ error: any }>()
);

export const loadAgencyFacilitiesByShift = createAction(
    '[SHIFTS] Load Shift Agency Facilities',
    props<{ shiftId: number }>()
);

export const loadAgencyFacilitiesSuccess = createAction(
    '[SHIFTS] Load Shift Agency Facilities Success',
    props<{ facilityShift: FacilityLookup[] }>()
);

export const resetAgencyFacilities = createAction('[SHIFTS] Reset Shift Agency Facilities');


export const loadAgencyFacilitiesFail = createAction(
    '[SHIFTS] Load Shift Agency Facilities Fail',
    props<{ error: any }>()
);


export const loadBookedResource = createAction(
    '[SHIFTS] Load Shift Booked Candidate',
    props<{ shift: Shift }>()
);

export const loadBookedResourceSuccess = createAction(
    '[SHIFTS] Load Shift Booked Candidate Success',
    props<{ bookedResource: Resource }>()
);


export const loadBookedResourceFail = createAction(
    '[SHIFTS] Load Shift Booked Candidate Fail',
    props<{ error: any }>()
);

export const resetSelectedBookedCandidate = createAction(
    '[SHIFTS] Reset Book Candidate'
);
