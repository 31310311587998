import { NgModule } from '@angular/core';

import { InternalPoolRoutingModule } from './internal-pool-routing.module';
import { InternalPoolComponent } from './internal-pool.component';
import { AddResourceComponent } from './add-resource/add-resource.component';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import * as fromInternalPool from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { internalPoolEffects } from './store/effects';
import { ResourceListDialogComponent } from './resource-list-dialog/resource-list-dialog.component';
import { internalPoolStoreKey } from './store/internal-pool.state';
import { ResourcesService } from './services/resources.service';
import { PersonalDetailsSectionComponent } from './personal-details-section/personal-details-section.component';
import { ProfessionalDetailsSectionComponent } from './professional-details-section/professional-details-section.component';
import { DocumentsSectionComponent } from './documents-section/documents-section.component';
import { NotesSectionComponent } from './notes-section/notes-section.component';
import { AssignmentsSectionComponent } from './assignments-section/assignments-section.component';
import { AssignmentsPreviousSectionComponent } from './assignments-previous-section/assignments-previous-section.component';
import { ShiftsAppSectionComponent } from './shifts-app-section/shifts-app-section.component';
import { ResourceDocumentsService } from './services/resource-documents.service';
import { PreferredShiftDialogComponent } from './preferred-shift-dialog/preferred-shift-dialog.component';
import { dashboardStoreKey } from 'src/app/dashboard-new/store/dashboard.state';
import { dashboardReducers } from 'src/app/dashboard-new/store/reducers';
import { dashboardEffects } from 'src/app/dashboard-new/store/effects';
import { ArchiveWorkerButtonComponent } from './archive-worker-button/archive-worker-button.component';

@NgModule({
    declarations: [
        InternalPoolComponent,
        AddResourceComponent,
        ResourceListDialogComponent,
        PersonalDetailsSectionComponent,
        ProfessionalDetailsSectionComponent,
        DocumentsSectionComponent,
        NotesSectionComponent,
        AssignmentsSectionComponent,
        AssignmentsPreviousSectionComponent,
        ShiftsAppSectionComponent,
        PreferredShiftDialogComponent,
        ArchiveWorkerButtonComponent
    ],
    imports: [
        InternalPoolRoutingModule,
        SharedModule,
        StoreModule.forFeature(internalPoolStoreKey, fromInternalPool.internalPoolReducer),
        EffectsModule.forFeature(internalPoolEffects),
        StoreModule.forFeature(dashboardStoreKey, dashboardReducers),
        EffectsModule.forFeature(dashboardEffects)
    ],
    providers: [
        ResourcesService,
        ResourceDocumentsService
    ]
})
export class InternalPoolModule { }
