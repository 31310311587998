import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

export function flattenFilter(filter: CompositeFilterDescriptor) {

    if (!filter) {
        return;
    }

    return Object.keys(filter).reduce((group, key) => {
        if (key === 'filters') {
            return filter[key].reduce((group, filter: any) => {
                group[filter.field] = filter.value;
                return group;
            }, group)
        }
        return group;
    }, {});
}
