import { Component, Input } from "@angular/core";

import { FilterService } from '@progress/kendo-angular-grid';
import { ListFilterBaseRow } from "./../base/listfilter-base-row";


@Component({
    selector: 'grid-dropdown-filter-row',
    templateUrl: './dropdown-filter-row.component.html'
})
export class DropDownFilterRowComponent extends ListFilterBaseRow {
    @Input() defaultItemText: string = "Select Item...";
    supportsMultipleValues = false;

    constructor(filterService: FilterService) {
        super(filterService);

    }

    public get defaultItem(): any {
        return {
            [this.textField]: this.defaultItemText,
            [this.valueField]: null
        };
    }

}
