export function mapSorting(sort, mapping) {
    if (!sort) {
        return sort;
    }

    return sort.map(s => {
        if (mapping[s.field] !== undefined) {
            return {
                field: mapping[s.field],
                dir: s.dir
            };
        } else {
            return s;
        }
    });
}
