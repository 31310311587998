import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import { ToasterService } from 'src/app/core/services';
import { isEmpty } from 'src/app/shared/utilities';
import { ShiftTypeEnum } from 'src/app/shifts/models/shift-type.enum';

export type DateChangeType = 'startDateChanged' | 'endDateChanged' | 'weeksLengthChanged' | 'daysLengthChanged';

export class SubmittalHelper {
    static calculateContractDates(
        changeType: DateChangeType,
        form: FormGroup,
        toasterService: ToasterService,
        updateMinEndDate: (newStartDate: Date) => void
    ): void {
        const locale = 'en-US';
        const dateFormat = 'M/d/yyyy';
        const startDateControl = form.get('startDate');
        const endDateControl = form.get('endDate');
        const weeksLengthControl = form.get('weeksLength');
        const daysLengthControl = form.get('daysLength');

        switch (changeType) {
            case 'startDateChanged':
            case 'weeksLengthChanged': {
                if (!startDateControl.value || weeksLengthControl.value === null) {
                    return;
                }
                const endDate = SubmittalHelper.calculateContractEndDateByWeeksLength(
                    new Date(startDateControl.value),
                    weeksLengthControl.value
                );
                const daysLength = SubmittalHelper.calculateContractDaysLength(
                    new Date(startDateControl.value),
                    endDate
                );
                endDateControl.patchValue(endDate);
                daysLengthControl.patchValue(daysLength);

                if (changeType === 'startDateChanged') {
                    const newStartDate = Array.isArray(startDateControl.value)
                        ? startDateControl.value[0]
                        : startDateControl.value;
                    updateMinEndDate(newStartDate);
                }

                if (toasterService) {
                    const changedField = changeType === 'startDateChanged' ? 'Start Date' : 'Contract Weeks Length';
                    toasterService.info(
                        `Your change to the ${changedField} updated the offer End Date to ${formatDate(
                            endDate,
                            dateFormat,
                            locale
                        )}`
                    );
                }
                break;
            }

            case 'endDateChanged': {
                if (!startDateControl.value || !endDateControl.value) {
                    return;
                }
                const weeksLength = SubmittalHelper.calculateContractWeeksLength(
                    dayjs(startDateControl.value).toDate(),
                    dayjs(endDateControl.value).toDate()
                );
                const daysLength = SubmittalHelper.calculateContractDaysLength(
                    dayjs(startDateControl.value).toDate(),
                    dayjs(endDateControl.value).toDate()
                );
                weeksLengthControl.patchValue(weeksLength);
                daysLengthControl.patchValue(daysLength);

                if (toasterService) {
                    toasterService.info(
                        `Your change to the End Date updated the offer End Date to ${formatDate(
                            new Date(endDateControl.value),
                            dateFormat,
                            locale
                        )}`
                    );
                }
                break;
            }

            case 'daysLengthChanged': {
                if (!startDateControl.value || !daysLengthControl.value) {
                    return;
                }
                const endDate = SubmittalHelper.calculateContractEndDateByDaysLength(
                    dayjs(startDateControl.value).toDate(),
                    daysLengthControl.value
                );
                const weeksLength = SubmittalHelper.calculateContractWeeksLength(
                    dayjs(startDateControl.value).toDate(),
                    endDate
                );
                endDateControl.patchValue(endDate);
                weeksLengthControl.patchValue(weeksLength);

                if (toasterService) {
                    toasterService.info(`Your change to the Contract Days Length
                    updated the offer End Date to ${formatDate(endDate, dateFormat, locale)}
                    and Contract Weeks Length to ${weeksLength}`);
                }
                break;
            }
        }
    }
    static calculateContractDaysLength(from: Date, to: Date): number {
        const startDate = dayjs(from);
        const endDate = dayjs(to);
        // Add one to diff so we account for the final day of the contract
        return endDate.diff(startDate, 'day') + 1;
    }

    static calculateContractWeeksLength(from: Date, to: Date): number {
        // Subtract the 1 back out that was added in so we get the weeks length correct
        const daysLength = SubmittalHelper.calculateContractDaysLength(from, to) - 1;
        let weeksLength = Math.floor(daysLength / 7);
        if (daysLength % 7 > 2) {
            weeksLength++;
        }
        return weeksLength;
    }

    static calculateContractEndDateByWeeksLength(from: Date, weeksLength: number): Date {
        let endDate = dayjs(from).add(weeksLength * 7, 'day');
        const endDay = endDate.day();
        if (endDay !== 6) {
            if (endDay > 3) {
                const daysToBeAdded = Math.max(0, 6 - endDay);
                endDate = endDate.add(daysToBeAdded, 'day');
            } else {
                const daysToBeAdded = -1 * Math.max(0, 7 - (6 - endDay));
                endDate = endDate.add(daysToBeAdded, 'day');
            }
        }
        return endDate.toDate();
    }

    static calculateContractEndDateByDaysLength(from: Date, daysLength: number): Date {
        return dayjs(from)
            .add(daysLength - 1, 'day')
            .toDate();
    }

    static getShiftEndTime(shiftLength: any, startTime: Date): Date {
        const result = new Date(startTime.getTime());
        const wholeHour = Math.floor(shiftLength);
        const wholeMin = shiftLength - wholeHour;
        result.setHours(startTime.getHours() + wholeHour);
        result.setMinutes(startTime.getMinutes() + (wholeMin === 0.5 ? 30 : 0));

        return result;
    }

    static getShiftTimeTypeId(shiftH: number) {
        if (isEmpty(shiftH)) {
            return null;
        }
        if (shiftH >= 5 && shiftH <= 12) {
            return ShiftTypeEnum.Day;
        } else if (shiftH > 12 && shiftH <= 15) {
            return ShiftTypeEnum.Evening;
        }
        return ShiftTypeEnum.Night;
    }
}
