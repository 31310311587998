import { Component, Input, Output, QueryList, ViewChildren, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { Store } from '@ngrx/store';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { EmailNotificationRole } from '../../models/enums/email-notifications.enum';
import { EmailNotification, FacilityFeatureNotifications } from '../../models/facility-feature.model';

@Component({
    selector: 'ayac-email-notifications-presentation',
    templateUrl: './email-notifications-presentation.component.html',
    styleUrls: ['./email-notifications-presentation.component.scss']
})
export class EmailNotificationsPresentationComponent implements OnInit, OnDestroy {
    @Input() notificationTypes: FacilityFeatureNotifications[];
    @Input() totalFacilities = 0;
    @Input() showTotals = true;
    @Output() notificationsUpdate = new EventEmitter<any>();
    @ViewChildren('featureCheckbox') featureCheckboxes: QueryList<MatCheckbox>;
    notificationRole = EmailNotificationRole;
    componentActive = true;
    displayedColumns = ['notificationType', 'client', 'vendor', 'acctManager', 'costCenterManager', 'billingRepAR'];

    constructor(private readonly _store: Store<AdminState>) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.componentActive = false;
    }

    getNotificationTypeRole(
        notificationType: FacilityFeatureNotifications,
        roleId: EmailNotificationRole
    ): EmailNotification {
        return notificationType.notifications.find((n) => n.notificationRoleId === roleId);
    }

    updateNotification(notification: EmailNotification, isEnabled: boolean): void {
        this.notificationsUpdate.emit({ featureId: notification.id, isEnabled, featureName: notification.name });
    }
}
