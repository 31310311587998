import { LoadingTypes } from 'src/app/shared/models';
import { SubmittalListItem } from 'src/app/shared/models/submittals/submittal-list-item.model';
import * as submittalActions from 'src/app/submittals/store/submittals.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { SubmittalDiversityInfo } from 'src/app/shared/models/submittals/diversity-info.model';

export interface SubmittalsListState {
    submittals: SubmittalListItem[];
    submittalsTotal: number;
    loading: LoadingTypes;
    submittalInfo: SubmittalDiversityInfo[];
}

const initialState: SubmittalsListState = {
    submittals: [],
    submittalsTotal: 0,
    loading: LoadingTypes.INIT,
    submittalInfo: []
};

export function submittalsListReducer(state: SubmittalsListState, action: Action) {
    return createReducer(
        initialState,
        on(submittalActions.loadSubmittals, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(submittalActions.loadSubmittalsSuccess, (state, action) => ({
            ...state,
            submittals: action.submittals,
            submittalsTotal: action.total,
            loading: LoadingTypes.LOADED
        })),

        on(submittalActions.loadSubmittalsFail, (state, action) => ({
            ...state,
            submittals: [],
            submittalsTotal: 0,
            loading: LoadingTypes.FAIL
        })),

        on(submittalActions.clearSubmittals, (state, action) => ({
            ...initialState
        })),

        on(submittalActions.exportSubmittals, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(submittalActions.exportSubmittalsSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(submittalActions.exportSubmittalsFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        })),

        on(submittalActions.loadSubmittalDiversityInfoSuccess, (state, action) => ({
            ...state,
            submittalInfo: action.submittalInfo
        })),

        on(submittalActions.loadSubmittalDiversityInfoFail, (state, action) => ({
            ...state,
            error: action.error
        }))
    )(state, action);
}

export function reducer(state: SubmittalsListState | undefined, action: Action) {
    return submittalsListReducer(state, action);
}
