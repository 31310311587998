import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmationDialogData } from 'src/app/shared/models/dialog.models';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CoreStoreFacade } from '../state/core-store.facade';
import { Observable } from 'rxjs';
import { DomainService } from 'src/app/shared/services/domain.service';

@Component({
    selector: 'ayac-forgot-password-dialog',
    templateUrl: './forgot-password-dialog.component.html',
    styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {
    form = new UntypedFormGroup({
        username: new UntypedFormControl('', [Validators.required])
    });

    loading$: Observable<boolean>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogData,
        public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
        private readonly _store: CoreStoreFacade,
        private readonly _domainService: DomainService
    ) {
        this.loading$ = this._store.isLoading$;
    }

    ngOnInit(): void {
        if (this.data.username) {
            this.form.get('username').setValue(this.data.username);
        }
    }

    submit() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        const { username } = this.form.value;
        this._store.forgotPassword(
            username,
            this._domainService.getValue('RESET_PASSWORD_BRAND_NAME'),
            this.dialogRef.id
        );
    }
}
