export enum InvoiceLineItemTypes {
        Regular = 1,
        Overtime = 2,
        OnCall = 3,
        CallBack = 4,
        Orientation = 5,
        Charge = 6,
        Guaranteed = 7,
        DoubleTime = 8,
        Holiday = 9,
        CreditMemo = 10,
        PermPlacement = 11,
        ConversionFee = 12,
        Mileage = 13,
        CarRental = 14,
        Airfare = 15,
        Hotel = 16,
        Gas = 17,
        Relocation = 18,
        Parking = 19,
        Bonus = 20,
        Credentialing = 21,
        Modules = 22,
        Miscellaneous = 23,
        OvertimeOrientation = 24,
        DoubletimeOrientation = 25,
        DailyOvertimeCharge = 26,
        WeeklyOvertimeCharge = 27,
        DoubletimeCharge = 28,
}
