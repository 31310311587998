import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import { PowerBiExportFormModel } from 'src/app/shared/models/powerbi/powerbi-export-form.model';

@Component({
    selector: 'ayac-powerbi-export-modal',
    templateUrl: './powerbi-export-modal.component.html',
    styleUrls: ['./powerbi-export-modal.component.scss']
})
export class PowerbiExportModalComponent {
    exportWithCurrentValuesOptions: any[];
    exportFileFormats: ListItem[];
    exportModel: PowerBiExportFormModel;
    featureFlags = FeatureFlag;
    title = 'Export report';

    constructor(
        public dialogRef: MatDialogRef<PowerbiExportModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: { supportedExportFormats: ListItem[]; reportName: string }
    ) {
        this.exportFileFormats = data.supportedExportFormats;
        this.exportWithCurrentValuesOptions = [
            { value: true, name: 'Current Values' },
            { value: false, name: 'Default Values' }
        ];

        const exportModel: PowerBiExportFormModel = {
            fileFormatId: this.exportFileFormats[0].id,
            exportWithCurrentValues: true,
            currentPageOnly: true
        };

        this.exportModel = exportModel;

        if (data.reportName) {
            this.title = `Export ${data.reportName}`;
        }
    }

    export() {
        this.dialogRef.close(this.exportModel);
    }

    cancel() {
        this.dialogRef.close();
    }
}
