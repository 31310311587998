import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'addDuration'
})
export class AddDurationPipe implements PipeTransform {
    transform(date: Date, duration: number = 0, unit: moment.unitOfTime.DurationConstructor = 'hours'): Date {
        if (!date) {
            return null;
        }

        const d = moment(date);

        if (!d.isValid()) {
            return null;
        }

        return d.add(moment.duration(duration, unit)).toDate();
    }
}
