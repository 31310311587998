import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SubmittalsVendorService } from 'src/app/submittals-vendor/submittals-vendor.service';

export const candidateClearNPIValidator = (
    submittalsVendorService: SubmittalsVendorService,
    jobId: number,
    vendorId: number
): AsyncValidatorFn => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const npiValue = control.value;

        if (npiValue === null || npiValue === undefined) {
            return of(null);
        }

        return submittalsVendorService.validateNpiCleared(npiValue, jobId, vendorId).pipe(
            map((npiCleared: boolean) => (npiCleared ? null : { npiNotCleared: true })),
            catchError(() => of({ npiValidationFailed: true }))
        );
    };
};
