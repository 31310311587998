import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FacilitiesAdminUrls } from 'src/app/shared/models/facilities/enums/facilities-admin-urls.enum';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateUrl } from 'src/app/core/utils';
import { FeatureSettingCategory, FeatureSettingItem } from 'src/app/shared/feature-settings/models/feature-features.models';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Injectable({
    providedIn: 'root'
})
export class AdminSystemsService {
    constructor(
        private readonly _http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings,
        private readonly dialogService: DialogService,
    ) {
    }

    getSystemSettings(systemId: number): Observable<FeatureSettingCategory[]> {
        const url = interpolateUrl(`${FacilitiesAdminUrls.SYSTEM_SETTINGS}`, { systemId });

        return this._http.get<FeatureSettingCategory[]>(`${this._settings.CORE}${url}`);
    }

    updateSystemSettings(systemId: number, setting: FeatureSettingItem): Observable<any> {
        const url = interpolateUrl(`${FacilitiesAdminUrls.SYSTEM_SETTINGS_UPDATE}`, {
            systemId: systemId,
            featureId: setting.featureId
        });
        const body = {
            isEnabled: setting.isEnabled,
            value: setting.value
        }

        return this._http.put<any>(`${this._settings.CORE}${url}`, body);
    }

}
