import { SortTypes } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';

export interface Facilities {
    data: Facility[];
    filtered: number;
    total: number;
}

export interface Facility {
    id: number;
    name: string;
    city: string;
    state: string;
    systemName: string;
    termSheetRequired: boolean;
    vms: string;
    statusId: number;
    status: string;
    statusDate: Date;
    isAyaMSP: boolean;
    isAyaPriority: boolean;
}

export interface VendorFacility {
    id: number;
    city: string;
    contractGroupId: number;
    contractGroupName: string;
    contractLineId: number;
    ctsRequirement: boolean;
    ctsSigned: boolean;
    name: string;
    state: string;
    status: string;
    statusBy: number;
    statusDate: string;
    statusId: number;
    statusNote: string;
    termSheetRequired: null;
    termSheetSigned: false;
    vendorId: number;
    vmsProviderId: number;
    vmsProviderName: string;
}

export interface VendorFacilityLookups {
    states: ListItem[];
    statuses: ListItem[];
}

export interface GridDataQuery extends GridSearchRequest<any> {}

export interface GridSearchRequest<TFilter> {
    pagination?: {
        pageSize: number;
        skip: number;
    };
    sortArgs?: {
        sortField?: string;
        sortType?: SortTypes;
    };
    filter?: TFilter;
}

export interface FacilitiesPagingToken<T> extends PagingToken<T> {
    filtered: number;
}

export interface PagingToken<T> {
    total: number;
    data: T;
    currPage: string;
    nextPage: string;
}

export const getEmptyPagingToken = <T>(): FacilitiesPagingToken<T[]> => ({
    data: [],
    total: 0,
    currPage: '',
    nextPage: '',
    filtered: 0,
});
