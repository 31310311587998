import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ResourceDocumentsService } from 'src/app/internal-pool/services/resource-documents.service'
import { switchMap, catchError, mergeMap, map, tap} from 'rxjs/operators';
import { of } from 'rxjs';
import * as internalPoolActions from '../actions';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { downloadBlob } from 'src/app/core/utils';

@Injectable()
export class ResourcesDocumentsEffect extends BaseEffect {

    getResourceDocuments$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.loadResourceDocuments),
        switchMap((action) => {
            return this.service.getDocuments(action.resourceId)
                .pipe(
                    map(documents => 
                        internalPoolActions.loadResourceDocumentsSuccess({ documents})),
                    catchError((error)=> 
                        of(internalPoolActions.loadResourceDocumentsFail({error})))
                )
        })
    ));

    loadResourceDocumentsFail$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.loadResourceDocumentsFail),
        tap((resource) =>
           this.toasterService.fail(`${JSON.stringify(resource.error)} has been an error loading the documents.`)
        )
    ), { dispatch: false });

    saveResourceDocument$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.saveResourceDocument),
        mergeMap((action) => {
            const fileName = action.fileToUpload.name;
            return this.service.postDocument(action.resourceId, action.fileToUpload, action.internalOnly).pipe(
                tap((resource) => {
                    this.toasterService.success(`${fileName} has been uploaded.`);
                }),
                mergeMap(resource => [
                    internalPoolActions.saveResourceDocumentSuccess({ fileValidationResult: [resource] , resourceId: action.resourceId}),
                    internalPoolActions.loadResourceDocuments({ resourceId: action.resourceId })
                ]),
                catchError((error) => 
                    of(internalPoolActions.saveResourceDocumentFail({
                        fileValidationResult: {
                            isValid: false,
                            name: fileName,
                            message: `Error uploading ${fileName}`
                        },
                        error: `Error uploading ${fileName}`
                    })))
             )
        })
    ));

    saveResourceDocumentFail$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.saveResourceDocumentFail),
        tap((resource) => 
           this.toasterService.fail(`${resource.error}.`)
        )
    ), { dispatch: false });


    deleteResourceDocument$ = createEffect(()=> this.actions.pipe(
        ofType(internalPoolActions.deleteResourceDocument),
        switchMap((action)=> {
            return this.service.deleteDocument(action.resourceId, action.id).pipe(
                mergeMap(resource => [
                    internalPoolActions.deleteResourceDocumentsuccess(action),
                    internalPoolActions.loadResourceDocuments({resourceId: action.resourceId})
                ]),
                catchError((error) => 
                    of(internalPoolActions.deleteResourceDocumentFail({ error }))
                )
            )
        })
    ))

    deleteResourceDocumentsuccess$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.deleteResourceDocumentsuccess),
        tap((action) => {
            this.toasterService.success(`Document for this resourceId ${action.resourceId} has been deleted.`);
        })
    ), { dispatch: false }); 


    getResourceDocument$ = createEffect(()=> this.actions.pipe(
        ofType(internalPoolActions.loadResourceDocument),
        switchMap((action)=> 
            this.service.getDocumentBlob(action.resourceId, action.id).pipe(
                tap((blob)=>{
                    downloadBlob(blob, action.fileName)
                }),
                map(blob => internalPoolActions.loadResourceDocumentsuccess()),
                catchError((error)=> {                    
                    this.toasterService.success(`Try re-downloading ${action.fileName}...`);
                    return of(internalPoolActions.loadResourceDocumentFail({error}))                
                })
        ))
    ));

    getPreviewResourceDocument$ = createEffect(()=> this.actions.pipe(
        ofType(internalPoolActions.loadPreviewResourceDocuments),
        switchMap((action)=>
            this.service.getPreviewDocumentBlob(action.resourceId, action.id, action.pageNum).pipe(
                map(resourcePreviewDocuments => 
                    internalPoolActions.loadPreviewResourceDocumentsSuccess({ documentPreview: resourcePreviewDocuments})
                ),              
                catchError((error)=> {                    
                    this.toasterService.success(`Error opening preview for this file: ${action.file.fileName} mode please download the file!`);
                    return of(internalPoolActions.loadPreviewResourceDocumentsFail({error}))                
                })
        ))
    ));

    getPreviewNextPage$ = createEffect(()=> this.actions.pipe(
        ofType(internalPoolActions.loadPreviewResourceDocuments),
        switchMap((action) =>
            this.actions.pipe(
                ofType(internalPoolActions.loadPreviewNextPage),
                map(({pageNum}) => 
                internalPoolActions.loadPreviewResourceDocuments({
                        ...action,
                        pageNum
                    })
                )
            )
        )
    ));

    loadPreviewResourcePageCount$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.loadPreviewResourcePageCount),
        switchMap((action) =>
            this.service.getTotalNumOfDoc(action.resourceId, action.id)
                .pipe(
                    map(totalPage => 
                        internalPoolActions.loadPreviewResourcePageCountSuccess({ total: totalPage })
                    ),
                    catchError((error) => {
                        this.toasterService.fail(`Error getting total page number for a file: ${action.id}!`);
                        return of(internalPoolActions.loadPreviewResourcePageCountFail({ error }))
                    })
                ))
    ));

    updateResourceDocument$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.updateResourceDocument),
        switchMap((action) => {
            return this.service.updateDocument(action.resourceId, action.fileToUpdate, action.internalOnly).pipe(
                tap(() => {
                    this.toasterService.success(`${action.fileToUpdate.fileName} has been updated.`);
                }),
                mergeMap(resource => [
                    internalPoolActions.updateResourceDocumentsuccess({ resourceId: action.resourceId,}),
                    internalPoolActions.loadResourceDocuments({ resourceId: action.resourceId })
                ]),
                catchError((error) => 
                    of(internalPoolActions.updateResourceDocumentFail({
                        error: `Error updating ${action.fileToUpdate.fileName}: ${error}`
                    })))
             )
        })
    ));

    updateResourceDocumentFail$ = createEffect(() => this.actions.pipe(
        ofType(internalPoolActions.updateResourceDocumentFail),
        tap((action) => {
            this.toasterService.fail(action.error);
        })
    ), { dispatch: false }); 

    constructor(
        private readonly actions: Actions,
        private readonly service: ResourceDocumentsService,
        toasterService: ToasterService
    ) { 
        super(toasterService);
    }
}
