import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'warning-message',
    templateUrl: 'warning-message.component.html',
    styleUrls: ['warning-message.component.scss']
})
export class WarningMessageComponent implements OnInit {
    @Input() message: string = '';
    @Input() control: UntypedFormControl;
    @Input() validator: (control: AbstractControl) => ValidationErrors | null;
    @Input() orientation: 'right' | 'top' = 'right';

    showWarning$: Observable<boolean>;

    ngOnInit() {
        this.showWarning$ = this.control.valueChanges.pipe(map(() => this.validator(this.control) !== null));
    }

    get orientationClassName() {
        return `orientation-${this.orientation}`;
    }
}
