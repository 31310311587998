import { createFeatureSelector, createSelector } from "@ngrx/store";
import { InternalPoolState, internalPoolStoreKey } from "src/app/internal-pool/store/internal-pool.state";

const selectInternalPoolState = createFeatureSelector<InternalPoolState>(
    internalPoolStoreKey
);

export const selectInternalPoolLookupsFeature = createSelector(
    selectInternalPoolState,
    state => state.lookups
);

export const selectAvailabilities = createSelector(
    selectInternalPoolLookupsFeature,
    state => state.availabilities
);

export const selectEpicSkills = createSelector(
    selectInternalPoolLookupsFeature,
    state => state.epicSkills
);

export const selectLanguages = createSelector(
    selectInternalPoolLookupsFeature,
    state => state.languages
);

export const selectLanguageSkills = createSelector(
    selectInternalPoolLookupsFeature,
    state => state.languageSkills
);

export const selectResourceTypesLookups = createSelector(
    selectInternalPoolLookupsFeature,
    (state) => state.resourceTypes
);

export const selectResourceSecurityLookups = createSelector(
    selectInternalPoolState,
    (state) => state.securityLookups
);

export const selectFacilitiesSecurityLookups = createSelector(
    selectResourceSecurityLookups,
    (state) => state.facilities
);
