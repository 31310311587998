import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfessionSpecialtyAssessments, Unit, UnitSkills } from 'src/app/clinical/models';
import { Certifications } from 'src/app/clinical/models/certifications.model';
import { Requirety } from 'src/app/clinical/models/requirety.enum';
import { VisibleUnitCertificationsSkills } from 'src/app/clinical/models/visible-certifications-skills.model';
import { ClinicalService } from 'src/app/clinical/services/clinical.service';
import { ToasterService } from 'src/app/core/services';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { UnitCertifications } from 'src/app/shared/models/enums/unit-certifications.enum';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-certifications-skills',
    templateUrl: './certifications-skills.component.html',
    styleUrls: ['./certifications-skills.component.scss']
})
export class CertificationsSkillsComponent extends UnsubscribeOnDestroy implements OnDestroy, OnChanges {
    @Output() valueChanged = new EventEmitter<{ unit: Partial<Unit>; isValid: boolean }>();

    @Input() readOnly = false;

    otherSpecialtyId = 1246;
    locumsCertificationsIds = [
        UnitCertifications.ABLS,
        UnitCertifications.ACLS,
        UnitCertifications.ATLS,
        UnitCertifications.BCLS,
        UnitCertifications.DOT,
        UnitCertifications.NRP,
        UnitCertifications.PALS
    ];

    skills = {};
    certifications = [];

    visibleCertificationsSkills: VisibleUnitCertificationsSkills;
    featureFlag = FeatureFlag;
    locumsCertificationOther1 = 'BLS or ACLS';

    @Input()
    set visibleUnitCertificationsSkills(visibleUnitCertificationsSkills: VisibleUnitCertificationsSkills) {
        if (JSON.stringify(this.visibleCertificationsSkills) === JSON.stringify(visibleUnitCertificationsSkills)) {
            return;
        }

        this.visibleCertificationsSkills = visibleUnitCertificationsSkills;
        this.setSkillsAndCertifications();
        const specialtyProfessionId =
            this.visibleCertificationsSkills?.unitFacilityInformation?.professionSpecialties?.find(
                (p) =>
                    p.professionCode === this.visibleCertificationsSkills.currentProfessionId &&
                    p.specialtyCode === this.visibleCertificationsSkills.selectedSpecialty
            )?.expertiseProfessionId;

        const unitSkills$ = specialtyProfessionId
            ? this._clinicalService.getUnitSkills(
                  specialtyProfessionId,
                  this.visibleCertificationsSkills?.unitFacilityInformation?.unit?.unitId
              )
            : of([] as UnitSkills[]);

        unitSkills$.subscribe({
            next: (skills) => {
                this.getUnitSkills(skills);
                this.certifications = this.getVisibleCertifications(
                    visibleUnitCertificationsSkills.selectedSpecialty,
                    visibleUnitCertificationsSkills.currentProfessionId
                );
                this.setFormValues(visibleUnitCertificationsSkills);
                this.watchFormChanges();
                refreshDisableEnableState(this.certificationsSkillsForm, this.readOnly);
            },
            error: () => this._toasterService.fail('Error loading data.')
        });
    }

    @Input() isPrinting: boolean = false;

    certificationsSkillsForm: UntypedFormGroup;
    requirementType = Requirety;

    get visibleCertifications() {
        return this.certificationsSkillsForm.get('visibleCertifications') as UntypedFormArray;
    }

    get visibleSkills() {
        return this.certificationsSkillsForm.get('visibleSkills') as UntypedFormArray;
    }

    tempSkills = [];
    isLoading = false;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _toasterService: ToasterService,
        private readonly _clinicalService: ClinicalService
    ) {
        super();

        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.certificationsSkillsForm, changes.readOnly.currentValue);
        }
    }

    buildForm(): void {
        this.certificationsSkillsForm = this._formBuilder.group({
            visibleSkills: this._formBuilder.array([]),
            visibleCertifications: this._formBuilder.array([]),

            certificationOther1Checkbox: new UntypedFormControl(),
            certificationOther2Checkbox: new UntypedFormControl(),
            certificationOther1: new UntypedFormControl('', [Validators.maxLength(50)]),
            certificationOther2: new UntypedFormControl('', [Validators.maxLength(50)]),

            skillOther1Checkbox: new UntypedFormControl(),
            skillOther2Checkbox: new UntypedFormControl(),
            skillOther1: new UntypedFormControl('', [Validators.maxLength(50)]),
            skillOther2: new UntypedFormControl('', [Validators.maxLength(50)])
        });
    }

    setFormValues(visibleUnitCertificationsSkills: VisibleUnitCertificationsSkills): void {
        this.isLoading = true;
        const visibleCertifications = this.visibleCertifications;
        const visibleSkills = this.visibleSkills;
        if (visibleCertifications) {
            visibleCertifications.clear();
        }
        if (visibleSkills) {
            visibleSkills.clear();
        }

        this.certifications?.sort((v1, v2) => v1.title.localeCompare(v2.title));
        this.certifications.forEach((visibleCertification: Certifications) => {
            this.visibleCertifications.push(
                this._formBuilder.group({
                    id: this._formBuilder.control(visibleCertification.id),
                    cetificationId: this._formBuilder.control(visibleCertification.cetificationId),
                    title: this._formBuilder.control(visibleCertification.title),
                    sort: this._formBuilder.control(visibleCertification.sort),
                    certificationRequiredOrPreferred: this.getRequirety(
                        visibleCertification.isPreferred,
                        visibleCertification.isRequired
                    )
                })
            );
        });

        this.objectKeys(this.skills).forEach((key) => {
            if (!this.skills[key].length) {
                return;
            }
            this.tempSkills = [];
            this.skills[key]?.forEach((s: any, index: number) =>
                this.tempSkills.push({
                    ...s,
                    ...{
                        sort: index,
                        skillRequiredOrPreferred: this.getRequirety(s.isPreferred, s.isRequired),
                        assessmentCategorySkillId: s.assessmentCategorySkillId ?? 0,
                        unitSkillDescriptionId: s.unitSkillDescriptionId ?? 0
                    }
                })
            );
            this.visibleSkills.push(
                this._formBuilder.group({
                    skills: this._formBuilder.array(this.tempSkills.map((r) => this._formBuilder.group(r))),
                    key: this._formBuilder.control(key)
                })
            );
        });
        const unit = visibleUnitCertificationsSkills.unitFacilityInformation.unit;

        this.certificationsSkillsForm
            .get('certificationOther1Checkbox')
            .setValue(this.getRequirety(unit?.certificationOther1Preferred, unit?.certificationOther1Required));

        this.certificationsSkillsForm
            .get('certificationOther2Checkbox')
            .setValue(this.getRequirety(unit?.certificationOther2Preferred, unit?.certificationOther2Required));

        this.certificationsSkillsForm
            .get('skillOther1Checkbox')
            .setValue(this.getRequirety(unit?.skillOther1Preferred, unit?.skillOther1Required));

        this.certificationsSkillsForm
            .get('skillOther2Checkbox')
            .setValue(this.getRequirety(unit?.skillOther2Preferred, unit?.skillOther2Required));

        this.certificationsSkillsForm.get('skillOther1').setValue(unit?.skillOther1);
        this.certificationsSkillsForm.get('skillOther2').setValue(unit?.skillOther2);
        this.certificationsSkillsForm.get('certificationOther1').setValue(unit?.certificationOther1);
        this.certificationsSkillsForm.get('certificationOther2').setValue(unit?.certificationOther2);
        this.isLoading = false;
        this.valueChanged.emit({ unit: this.getFormValues(), isValid: this.certificationsSkillsForm.valid });
    }

    getFormValues(): Partial<Unit> {
        const formArray: UntypedFormArray = this.visibleCertifications;
        const certifications: Certifications[] = [];

        formArray.controls.forEach((visibleCertification) => {
            if (
                visibleCertification.get('certificationRequiredOrPreferred').value === Requirety.Preferred ||
                visibleCertification.get('certificationRequiredOrPreferred').value === Requirety.Required
            ) {
                certifications.push({
                    id: visibleCertification.get('id').value,
                    title: visibleCertification.get('title').value,
                    sort: visibleCertification.get('sort').value,
                    isPreferred:
                        visibleCertification.get('certificationRequiredOrPreferred').value === Requirety.Preferred,
                    isRequired:
                        visibleCertification.get('certificationRequiredOrPreferred').value === Requirety.Required,
                    cetificationId: visibleCertification.get('cetificationId').value
                });
            }
        });

        const formArrayVisibleSkill: UntypedFormArray = this.visibleSkills;
        const skills = [];

        formArrayVisibleSkill.controls.forEach((visibleSkill) => {
            const _skills: UntypedFormArray = (visibleSkill as UntypedFormGroup).controls.skills as UntypedFormArray;
            _skills.controls.forEach((skill) => {
                if (
                    skill.get('skillRequiredOrPreferred').value === Requirety.Preferred ||
                    skill.get('skillRequiredOrPreferred').value === Requirety.Required
                ) {
                    skills.push({
                        title: skill.get('title').value,
                        sort: skill.get('sort').value,
                        isPreferred: skill.get('skillRequiredOrPreferred').value === Requirety.Preferred,
                        isRequired: skill.get('skillRequiredOrPreferred').value === Requirety.Required,
                        assessmentCategorySkillId: skill.get('assessmentCategorySkillId').value,
                        unitSkillDescriptionId: skill.get('unitSkillDescriptionId').value
                    });
                }
            });
        });

        const unit: Partial<Unit> = {
            skills: skills,
            certifications: certifications,
            certificationOther1Preferred:
                this.certificationsSkillsForm.get('certificationOther1Checkbox').value === Requirety.Preferred,
            certificationOther1Required:
                this.certificationsSkillsForm.get('certificationOther1Checkbox').value === Requirety.Required,
            skillOther1Preferred:
                this.certificationsSkillsForm.get('skillOther1Checkbox').value === Requirety.Preferred,
            skillOther1Required: this.certificationsSkillsForm.get('skillOther1Checkbox').value === Requirety.Required,

            certificationOther2Preferred:
                this.certificationsSkillsForm.get('certificationOther2Checkbox').value === Requirety.Preferred,
            certificationOther2Required:
                this.certificationsSkillsForm.get('certificationOther2Checkbox').value === Requirety.Required,
            skillOther2Preferred:
                this.certificationsSkillsForm.get('skillOther2Checkbox').value === Requirety.Preferred,
            skillOther2Required: this.certificationsSkillsForm.get('skillOther2Checkbox').value === Requirety.Required,

            skillOther1: this.certificationsSkillsForm.get('skillOther1').value,
            skillOther2: this.certificationsSkillsForm.get('skillOther2').value,
            certificationOther1: this.getCertificationOther1,
            certificationOther2: this.certificationsSkillsForm.get('certificationOther2').value
        };

        return unit;
    }

    get getCertificationOther1(): string {
        let certOther = this.certificationsSkillsForm.get('certificationOther1').value;
        const certificationOther1Checkbox = this.certificationsSkillsForm.get('certificationOther1Checkbox').value;

        if (
            this.visibleCertificationsSkills?.unitFacilityInformation?.isLocumsProfession &&
            (certificationOther1Checkbox === Requirety.Preferred || certificationOther1Checkbox === Requirety.Required)
        ) {
            certOther = this.locumsCertificationOther1;
        }

        return certOther;
    }

    watchFormChanges(): void {
        this.certificationsSkillsForm.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            if (!this.isLoading) {
                this.valueChanged.emit({ unit: this.getFormValues(), isValid: this.certificationsSkillsForm.valid });
            }
        });
    }

    objectKeys(obj) {
        return obj ? Object.keys(obj) : [];
    }

    getUnitSkills(data: UnitSkills[]): void {
        this.skills = data.reduce((result, current) => {
            result[current.categoryName] = result[current.categoryName] || [];
            result[current.categoryName].push({
                ...current,
                ...{
                    title: current.skillName
                }
            });
            return result;
        }, {});
    }

    getVisibleCertifications(selectedSpecialty, selectedProfessionId) {
        const filterPsa = this.getMatchedProfessionSpecialtyAssessments(selectedProfessionId, selectedSpecialty);
        let professionId = filterPsa.professionId;
        let specialtyId = filterPsa.specialtyId;

        let certBySpecialties = [];
        if (this.visibleCertificationsSkills.unitFacilityInformation.isLocumsProfession) {
            professionId = selectedProfessionId;
            specialtyId = selectedSpecialty;
        }

        certBySpecialties = this.visibleCertificationsSkills.unitFacilityInformation.unitSpecialtyCertifications
            ?.filter((f) => f.professionId === professionId && f.specialtyId === specialtyId)
            ?.map((m) => ({
                id: m.unitCertificationId,
                title: m.unitCertification.title,
                isPreferred: null,
                isRequired: null,
                cetificationId: null
            }));

        // if there are no certs selected on Configured UD then use the otherSpecialtyId to pull in those
        if (!certBySpecialties?.length) {
            certBySpecialties = this.visibleCertificationsSkills.unitFacilityInformation.fallBackCertifications.map(
                (m) => ({
                    id: m.unitCertificationId,
                    title: m.title,
                    isPreferred: null,
                    isRequired: null,
                    cetificationId: null
                })
            );
        }

        if (this.visibleCertificationsSkills.unitFacilityInformation.unit.certifications) {
            this.visibleCertificationsSkills.unitFacilityInformation.unit.certifications =
                this.visibleCertificationsSkills.unitFacilityInformation.unit.certifications.filter((orig) => {
                    return certBySpecialties.map((cert) => cert.id).includes(orig.cetificationId);
                });
        }
        certBySpecialties.forEach((item) => {
            if (this.visibleCertificationsSkills.unitFacilityInformation.unit.certifications) {
                const match = this.visibleCertificationsSkills.unitFacilityInformation.unit.certifications.filter(
                    (orig) => {
                        return orig.cetificationId === item.id;
                    }
                );

                if (match.length) {
                    item.isPreferred = match[0].isPreferred;
                    item.isRequired = match[0].isRequired;
                }
                item.cetificationId = item.id;
            }
        });

        return certBySpecialties;
    }

    getMatchedProfessionSpecialtyAssessments(
        selectedProfessionId,
        selectedSpecialtyId
    ): ProfessionSpecialtyAssessments {
        const dflt = { specialtyId: this.otherSpecialtyId } as ProfessionSpecialtyAssessments;
        if (!selectedProfessionId || !selectedSpecialtyId) {
            return dflt;
        }
        return (
            this.visibleCertificationsSkills?.unitFacilityInformation?.professionSpecialtyAssessments?.find(
                (f) =>
                    Number(f.professionId) === selectedProfessionId &&
                    Number(f.specialtyId) === selectedSpecialtyId &&
                    f.isDefaultAssessment
            ) ?? dflt
        );
    }

    private setSkillsAndCertifications() {
        this.visibleCertificationsSkills.unitFacilityInformation.certifications.sort((a, b) => {
            return a.sort - b.sort;
        });

        this.visibleCertificationsSkills.unitFacilityInformation.certifications.forEach((item) => {
            if (this.visibleCertificationsSkills.unitFacilityInformation.unit.certifications) {
                const match = this.visibleCertificationsSkills.unitFacilityInformation.unit.certifications.filter(
                    (orig) => {
                        return orig.cetificationId === item.id;
                    }
                );

                if (match.length) {
                    item.isPreferred = match[0].isPreferred;
                    item.isRequired = match[0].isRequired;
                }
                item.cetificationId = item.id;
            }
        });
    }

    private getRequirety(preferred: boolean | number, required: boolean | number) {
        return preferred ? Requirety.Preferred : required ? Requirety.Required : Requirety.None;
    }
}
