import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ConnectPortalUser } from 'src/app/core/auth/models/connect-portal-user.model';
import { UserSecurity } from 'src/app/core/auth/models/user-security.model';
import { MenuItem, SystemFormField } from '../models';
import { CustomSystemFieldLookup } from '../models/system-custom-field.model';
import { UserProfile } from 'src/app/shared/models/account/user-profile.model';

export enum CoreActionTypes {
    UniversalLogin = '[Core] Universal Login',
    LoginCheck = '[Core] Login Check',
    LoginSuccess = '[Core] Login Success',
    LoginFail = '[Core] Login Fail',
    Logout = '[Core] Logout',
    GetTermsAndConditions = '[CORE CONNECT EFFECTS] Get Terms And Conditions',
    GetTermsAndConditionsSuccess = '[CORE CONNECT EXTERNAL API] Get Terms And Conditions Success',
    GetTermsAndConditionsFailure = '[CORE CONNECT EXTERNAL API] Get Terms And Conditions Failure',
    AcceptTermsAndConditions = '[CORE CONNECT EFFECTS] Accept Terms And Conditions',
    AcceptTermsAndConditionsSuccess = '[CORE CONNECT EXTERNAL API] Accept Terms And Conditions Success',
    AcceptTermsAndConditionsFailure = '[CORE CONNECT EXTERNAL API] Accept Terms And Conditions Failure',
    RequestPortalInfo = '[CORE EFFECTS] Request Portal User Info',
    RequestPortalInfoSuccess = '[AUTH TICKET API] Request Portal User Info Success',
    RequestPortalInfoFailure = '[AUTH TICKET API] Request Portal User Info Failure',

    TermsAndConditionsNotSigned = '[CORE EFFECTS] Terms And Conditions Not Signed',

    GetSecurityList = '[CORE EFFECTS] Get Security List',
    GetSecurityListSuccess = '[SECURITY GROUP API] Get Security List Success',
    GetSecurityListFailure = '[SECURITY GROUP API] Get Security List Failure',

    LoadPermissions = '[Core] Load Permissions',
    LoadPermissionsSuccess = '[Core] Load Permissions Success',
    LoadPermissionsFail = '[Core] Load Permissions Fail',

    LoadMenu = '[Core] Load Menu',
    LoadMenuSuccess = '[Core] Load Menu Success',
    LoadMenuFail = '[Core] Load Menu Fail',
    UpdateMenu = '[Core] Update Menu',
    AppendMenu = '[Core] Append Menu',

    LoadFeaturedLogoWithSystemId = '[Core] Load Featured Logo With SystemId',
    LoadFeaturedLogo = '[Core] Load Featured Logo',
    LoadFeaturedLogoSuccess = '[Core] Load Featured Logo Success',
    LoadFeaturedLogoFail = '[Core] Load Featured Logo Fail',

    LoadSystemId = '[Core] Load System Id',
    LoadSystemIdSuccess = '[Core] Load System Id Success',
    LoadSystemIdFail = '[Core] Load System Id Fail',

    ResetPassword = '[Core] Reset Password',
    ResetPasswordSuccess = '[Core] Reset Password Success',
    ResetPasswordFail = '[Core] Reset Password Fail',

    ForgotPassword = '[Core] Forgot Password',
    ForgotPasswordSuccess = '[Core] Forgot Password Success',
    ForgotPasswordFail = '[Core] Forgot Password Fail',

    LoadSystemFields = '[Core] Load System Fields',
    LoadSystemFieldsSuccess = '[Core] Load System Fields Success',
    LoadSystemFieldsFail = '[Core] Load System Fields Fail',

    LoadCustomSystemFields = '[Core] Load Custom System Fields',
    LoadCustomSystemFieldsSuccess = '[Core] Load Custom System Fields Success',
    LoadCustomSystemFieldsFail = '[Core] Load Custom System Fields Fail',

    FeaturesLoadedSuccess = '[Feature Management Service] load features success',

    PasswordResetCore = '[LOGIN COMPONENT API ERROR CODE] Password Reset Core',
    PasswordResetCoreSuccess = '[CONNECT API] Password Reset Core Success',
    PasswordResetCoreFailure = '[CONNECT API] Password Reset Core Failure',

    LoadUserProfiles = '[Core] Load User Profiles',
    LoadUserProfilesSuccess = '[Core] Load User Profiles Success',
    LoadUserProfilesFail = '[Core] Load User Profiles Fail',

    RecordLoginAttempt = '[CONNECT API] Record Login Attempt',
    RecordLoginAttemptSuccess = '[CONNECT API] Record Login Attempt Success',
    RecordLoginAttemptFailure = '[CONNECT API] Record Login Attempt Failure'
}

export class TermsAndConditionsNotSigned implements Action {
    readonly type = CoreActionTypes.TermsAndConditionsNotSigned;

    constructor() {}
}

export class GetSecurityList implements Action {
    readonly type = CoreActionTypes.GetSecurityList;

    constructor() {}
}

export class GetSecurityListSuccess implements Action {
    readonly type = CoreActionTypes.GetSecurityListSuccess;

    constructor(public list: UserSecurity[]) {}
}

export class GetSecurityListFailure implements Action {
    readonly type = CoreActionTypes.GetSecurityListFailure;

    constructor(public error: unknown) {}
}

export class PasswordResetCore implements Action {
    readonly type = CoreActionTypes.PasswordResetCore;

    constructor(public username: string, public password: string) {}
}

export class PasswordResetCoreSuccess implements Action {
    readonly type = CoreActionTypes.PasswordResetCoreSuccess;

    constructor() {}
}

export class PasswordResetCoreFailure implements Action {
    readonly type = CoreActionTypes.PasswordResetCoreFailure;

    constructor(public error: unknown) {}
}

export class AcceptTermsAndConditions implements Action {
    readonly type = CoreActionTypes.AcceptTermsAndConditions;

    constructor() {}
}

export class AcceptTermsAndConditionsSuccess implements Action {
    readonly type = CoreActionTypes.AcceptTermsAndConditionsSuccess;

    constructor() {}
}

export class AcceptTermsAndConditionsFailure implements Action {
    readonly type = CoreActionTypes.AcceptTermsAndConditionsFailure;

    constructor(public error: unknown) {}
}

export class GetTermsAndConditions implements Action {
    readonly type = CoreActionTypes.GetTermsAndConditions;

    constructor() {}
}

export class GetTermsAndConditionsSuccess implements Action {
    readonly type = CoreActionTypes.GetTermsAndConditionsSuccess;

    constructor(public response: unknown) {}
}

export class GetTermsAndConditionsFailure implements Action {
    readonly type = CoreActionTypes.GetTermsAndConditionsFailure;

    constructor(public error: unknown) {}
}

export class RequestPortalUserInfo implements Action {
    readonly type = CoreActionTypes.RequestPortalInfo;

    constructor() {}
}

export class RequestPortalUserInfoSuccess implements Action {
    readonly type = CoreActionTypes.RequestPortalInfoSuccess;

    constructor(public response: ConnectPortalUser) {}
}

export class RequestPortalUserInfoFailure implements Action {
    readonly type = CoreActionTypes.RequestPortalInfoFailure;

    constructor(public error: HttpErrorResponse) {}
}

export class UniversalLogin implements Action {
    readonly type = CoreActionTypes.UniversalLogin;

    constructor() {}
}

export class LoginCheck implements Action {
    readonly type = CoreActionTypes.LoginCheck;

    constructor() {}
}

export class LoginSuccess implements Action {
    readonly type = CoreActionTypes.LoginSuccess;

    constructor() {}
}

export class LoginFail implements Action {
    readonly type = CoreActionTypes.LoginFail;

    constructor(public errorMessage: string, public username: string) {}
}

export class Logout implements Action {
    readonly type = CoreActionTypes.Logout;

    constructor(public ssoLogout: boolean = false) {}
}

export class LoadPermissions implements Action {
    readonly type = CoreActionTypes.LoadPermissions;

    constructor() {}
}

export class LoadPermissionsSuccess implements Action {
    readonly type = CoreActionTypes.LoadPermissionsSuccess;

    constructor(public payload: string[]) {}
}

export class LoadPermissionsFail implements Action {
    readonly type = CoreActionTypes.LoadPermissionsFail;

    constructor(public errorMessage: string) {}
}

export class LoadMenu implements Action {
    readonly type = CoreActionTypes.LoadMenu;

    constructor() {}
}
export class LoadMenuSuccess implements Action {
    readonly type = CoreActionTypes.LoadMenuSuccess;

    constructor(public menu: MenuItem[]) {}
}

export class UpdateMenu implements Action {
    readonly type = CoreActionTypes.UpdateMenu;

    constructor(public menu: MenuItem[]) {}
}

export class AppendMenu implements Action {
    readonly type = CoreActionTypes.AppendMenu;

    constructor(public menu: MenuItem) {}
}

export class LoadMenuFail implements Action {
    readonly type = CoreActionTypes.LoadMenuFail;

    constructor(public errorMessage: string) {}
}

export class LoadFeaturedLogoWithSystemId implements Action {
    readonly type = CoreActionTypes.LoadFeaturedLogoWithSystemId;

    constructor() {}
}

export class LoadSystemId implements Action {
    readonly type = CoreActionTypes.LoadSystemId;

    constructor() {}
}

export class LoadSystemIdSuccess implements Action {
    readonly type = CoreActionTypes.LoadSystemIdSuccess;

    constructor(public systemId: number) {}
}

export class LoadSystemIdFail implements Action {
    readonly type = CoreActionTypes.LoadSystemIdFail;

    constructor(public errorMessage: string) {}
}

export class LoadFeaturedLogo implements Action {
    readonly type = CoreActionTypes.LoadFeaturedLogo;

    constructor() {}
}
export class LoadFeaturedLogoSuccess implements Action {
    readonly type = CoreActionTypes.LoadFeaturedLogoSuccess;

    constructor(public featuredLogo: Blob) {}
}

export class LoadFeaturedLogoFail implements Action {
    readonly type = CoreActionTypes.LoadFeaturedLogoFail;

    constructor(public errorMessage: string) {}
}

export class ResetPassword implements Action {
    readonly type = CoreActionTypes.ResetPassword;

    constructor(public currentPassword: string, public newPassword: string) {}
}

export class ResetPasswordSuccess implements Action {
    readonly type = CoreActionTypes.ResetPasswordSuccess;

    constructor() {}
}

export class ResetPasswordFail implements Action {
    readonly type = CoreActionTypes.ResetPasswordFail;
    constructor(public errorMessage: string) {}
}

export class ForgotPassword implements Action {
    readonly type = CoreActionTypes.ForgotPassword;
    constructor(public username: string, public brand: string, public dialogId?: string) {}
}

export class ForgotPasswordSuccess implements Action {
    readonly type = CoreActionTypes.ForgotPasswordSuccess;
    constructor(public message: string) {}
}

export class ForgotPasswordFail implements Action {
    readonly type = CoreActionTypes.ForgotPasswordFail;

    constructor(public errorMessage: HttpErrorResponse | string, public dialogId?: string) {}
}

export class LoadSystemFields implements Action {
    readonly type = CoreActionTypes.LoadSystemFields;

    constructor() {}
}

export class LoadSystemFieldsSuccess implements Action {
    readonly type = CoreActionTypes.LoadSystemFieldsSuccess;

    constructor(public formFields: SystemFormField[]) {}
}

export class LoadSystemFieldsFail implements Action {
    readonly type = CoreActionTypes.LoadSystemFieldsFail;

    constructor(public errorMessage: string) {}
}

export class LoadCustomSystemFields implements Action {
    readonly type = CoreActionTypes.LoadCustomSystemFields;

    constructor(public systemId: number) {}
}

export class LoadCustomSystemFieldsSuccess implements Action {
    readonly type = CoreActionTypes.LoadCustomSystemFieldsSuccess;

    constructor(public customSystemFields: CustomSystemFieldLookup[]) {}
}

export class LoadCustomSystemFieldsFail implements Action {
    readonly type = CoreActionTypes.LoadCustomSystemFieldsFail;

    constructor(public errorMessage: string) {}
}

export class LoadUserProfiles implements Action {
    readonly type = CoreActionTypes.LoadUserProfiles;

    constructor(public enforce: boolean = false) {}
}

export class LoadUserProfilesSuccess implements Action {
    readonly type = CoreActionTypes.LoadUserProfilesSuccess;

    constructor(public profiles: UserProfile[]) {}
}

export class LoadUserProfilesFail implements Action {
    readonly type = CoreActionTypes.LoadUserProfilesFail;

    constructor(public errorMessage: string) {}
}

export class RecordLoginAttempt implements Action {
    readonly type = CoreActionTypes.RecordLoginAttempt;

    constructor(public isSuccessfulLogin: boolean, public username: string = '') {}
}

export class RecordLoginAttemptSuccess implements Action {
    readonly type = CoreActionTypes.RecordLoginAttemptSuccess;

    constructor() {}
}

export class RecordLoginAttemptFailure implements Action {
    readonly type = CoreActionTypes.RecordLoginAttemptFailure;

    constructor(public error: unknown) {}
}

export type CoreActions =
    | UniversalLogin
    | LoginSuccess
    | LoginFail
    | LoginCheck
    | Logout
    | LoadPermissions
    | LoadPermissionsSuccess
    | LoadPermissionsFail
    | LoadMenu
    | LoadMenuSuccess
    | LoadMenuFail
    | UpdateMenu
    | AppendMenu
    | LoadFeaturedLogo
    | LoadFeaturedLogoSuccess
    | LoadFeaturedLogoFail
    | ResetPassword
    | ResetPasswordFail
    | ResetPasswordSuccess
    | ForgotPassword
    | ForgotPasswordSuccess
    | ForgotPasswordFail
    | LoadSystemId
    | LoadSystemIdFail
    | LoadSystemIdSuccess
    | LoadFeaturedLogoWithSystemId
    | LoadSystemFields
    | LoadSystemFieldsFail
    | LoadSystemFieldsSuccess
    | LoadCustomSystemFields
    | LoadCustomSystemFieldsSuccess
    | LoadCustomSystemFieldsFail
    | RequestPortalUserInfo
    | RequestPortalUserInfoSuccess
    | RequestPortalUserInfoFailure
    | GetTermsAndConditions
    | GetTermsAndConditionsSuccess
    | GetTermsAndConditionsFailure
    | AcceptTermsAndConditions
    | AcceptTermsAndConditionsSuccess
    | AcceptTermsAndConditionsFailure
    | PasswordResetCore
    | PasswordResetCoreSuccess
    | PasswordResetCoreFailure
    | GetSecurityList
    | GetSecurityListSuccess
    | GetSecurityListFailure
    | TermsAndConditionsNotSigned
    | LoadUserProfiles
    | LoadUserProfilesSuccess
    | LoadUserProfilesFail;
