import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent {
    _placeholderValue;
    _filterByTerm;

    get filterByTerm(): any {
        return this._filterByTerm;
    }

    get inputPlaceholder(): any {
        return this._placeholderValue;
    }

    @Input()
    set filterByTerm(term: any) {
        if (term) this._filterByTerm = term;
    }

    @Input()
    set inputPlaceholder(val: any) {
        this._placeholderValue = val;
    }

    @Input() componentActive: boolean;

    @Input() dataToFilter: any = [];
    control = new UntypedFormControl();
    searchableItems: any = [];
    isListVisible: boolean = false;

    @Output() public getSelectedItem = new EventEmitter<string>();

    @HostListener('document:click', ['$event']) clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isListVisible = false;
        }
    }

    constructor(private eRef: ElementRef) {}

    ngOnChanges(data: SimpleChanges) {
        if (data['dataToFilter']) {
            this.searchableItems = data['dataToFilter']['currentValue'];
        }
    }

    ngOnDestroy() {}

    focusOnInput(event: any) {
        event.stopPropagation();
        this.isListVisible = true;
    }

    selectionClick(item: any) {
        this.getSelectedItem.emit(item);
        this.isListVisible = false;
    }
}
