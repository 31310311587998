import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ayac-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {

  @Input() header: string;
  @Input() collapsed?: boolean;
  isCollapsed?: boolean;
  @Input()
  sticky?: boolean;
  collapsible = false;
  constructor() { }

  ngOnInit() {
      if (this.collapsed != null) {
          this.isCollapsed = this.collapsed;
          this.collapsible = true;
      }
  }

  onHeaderClicked(event: any) {
      this.collapsed = !this.collapsed;
      event.stopPropagation();
  }

  get headerClassName() {
      return {
        'ayac-widget-header-sticky': this.sticky
      }
  }

}
