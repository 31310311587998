import { EditJobsLinesOfBusinessState } from './redusers/edit-jobs-lines-of-business.reducer';
import { JobsLinesOfBusinessState } from './redusers/jobs-lines-of-business.reducer';
import { LinesOfBusinessState, initialLinesOfBusinessState } from './redusers/lines-of-business.reducer';
import { ShiftTypesState, initialShiftTypesState } from './redusers/shift-types.reducer';
import { SubmittalsLinesOfBusinessState } from './redusers/submittals-lines-of-business.reducer';
import { TimecardsLinesOfBusinessState } from './redusers/timecards-lines-of-business.reducer';
import { WorkersLinesOfBusinessState } from './redusers/workers-lines-of-business.reducer';

export const lookupsStoreKey = 'lookups';

export interface LookupsState {
    linesOfBusiness: LinesOfBusinessState,
    shiftTypes: ShiftTypesState,
    jobsLinesOfBusiness?: JobsLinesOfBusinessState,
    submittalsLinesOfBusiness?: SubmittalsLinesOfBusinessState,
    workersLinesOfBusiness?: WorkersLinesOfBusinessState,
    timecardsLinesOfBusiness?: TimecardsLinesOfBusinessState,
    editJobsLinesOfBusiness?: EditJobsLinesOfBusinessState
}

export const initialShiftsState = (): LookupsState => ({
    linesOfBusiness: initialLinesOfBusinessState,
    shiftTypes: initialShiftTypesState
});
