import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError, tap, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubmittalsService } from 'src/app/submittals/services/submittals.service';
import { SubmittalsBaseEffect } from './submittals-base.effect';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { ServerResponseStatus } from 'src/app/shared/models/submittals/enums/server-response-status.enum';
import { SubmittalUpdateStatusRequest } from 'src/app/shared/models/submittals/requests/submittal-update-status.request';
import { SubmittalLeadsService } from '../../services/submittal-leads.service';
import { CandidateEducation, CandidateQualifications, CandidateWorkHistory, SubmittalActivity } from '../../models';
import * as submittalActions from '../submittals.actions';
import * as leadsActions from '../actions/submittals-leads.actions';

@Injectable()
export class SubmittalsDetailsEffect extends SubmittalsBaseEffect {
    getSubmittalDetails$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittalDetails),
            map((action) => action.id),
            exhaustMap((id: number) =>
                this.submittalsService.getSubmittalDetails(id).pipe(
                    switchMap((res) =>
                        res && res.responseStatus === ServerResponseStatus.SUCCESS
                            ? [
                                  submittalActions.loadSubmittalDetailsSuccess({
                                      details: res.submittal,
                                      canUpdateStatus: res.canUpdateStatus
                                  }),
                                  submittalActions.loadSubmittalEmailData({ id }),
                                  submittalActions.loadSubmittalMspCustomFieldsSuccess({
                                      mspCustomFields: res.mspCustomFields
                                  })
                              ]
                            : [
                                  submittalActions.loadSubmittalDetailsFail({
                                      error:
                                          res && res.errors && res.errors.length
                                              ? res.errors[0]
                                              : 'Failed to load submittal details.'
                                  })
                              ]
                    ),
                    catchError((error) => of(submittalActions.loadSubmittalDetailsFail({ error })))
                )
            )
        )
    );

    getSubmittalDetailsSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalDetailsSuccess),
                tap((res) => {
                    this.logData('Submittal details loaded.');
                })
            ),
        { dispatch: false }
    );

    getSubmittalDetailsFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalDetailsFail, submittalActions.updateSubmittalStatusFail),
                map((action) => action.error),
                tap((error) => {
                    if (!error.status || error.status === 401) {
                        return;
                    }
                    this.handleError(error);
                })
            ),
        { dispatch: false }
    );

    getSubmittalJobs$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittalJobs),
            map((action) => action.id),
            exhaustMap((id: number) =>
                this.submittalsService.getSubmittalJobs(id).pipe(
                    map((res) =>
                        res
                            ? submittalActions.loadSubmittalJobsSuccess({ jobs: res })
                            : submittalActions.loadSubmittalJobsFail({ error: 'Failed to load submittal jobs.' })
                    ),
                    catchError((error) => of(submittalActions.loadSubmittalJobsFail({ error })))
                )
            )
        )
    );

    getSubmittalJobsSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalJobsSuccess),
                tap((res) => {
                    this.logData('Submittal jobs loaded.');
                })
            ),
        { dispatch: false }
    );

    getSubmittalJobsFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalJobsFail),
                map((action) => action.error),
                tap((error) => {
                    if (!error.status || error.status === 401) {
                        return;
                    }
                    this.handleError(error);
                })
            ),
        { dispatch: false }
    );

    updateSubmittalStatus$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.updateSubmittalStatus),
            map((action) => action.payload),
            exhaustMap((request: SubmittalUpdateStatusRequest) =>
                this.submittalsService.updateSubmittalStatus(request).pipe(
                    map((res) =>
                        res
                            ? submittalActions.updateSubmittalStatusSuccess({ details: res })
                            : submittalActions.updateSubmittalStatusFail({ error: 'Empty submittal status response' })
                    ),
                    catchError((error) => of(submittalActions.updateSubmittalStatusFail({ error })))
                )
            )
        )
    );

    updateSubmittalStatusSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.updateSubmittalStatusSuccess),
                tap((res) => {
                    this.toasterService.success('Candidate status successfully updated.');
                    this.logData('Submittal status successfully updated.');
                })
            ),
        { dispatch: false }
    );

    updateSubmittalStatusFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.updateSubmittalStatusFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );

    getSubmittalEmailData$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittalEmailData),
            map((action) => action.id),
            exhaustMap((id: number) =>
                this.submittalsService.getSubmittalEmailData(id).pipe(
                    map((data) => submittalActions.loadSubmittalEmailDataSuccess({ data })),
                    catchError((error) => of(submittalActions.loadSubmittalEmailDataFail({ error })))
                )
            )
        )
    );

    getSubmittalEmailDataSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalEmailDataSuccess),
                tap((res) => {
                    this.logData('Submittal email data loaded.');
                })
            ),
        { dispatch: false }
    );

    getSubmittalEmailDataFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalEmailDataFail),
                map((action) => action.error),
                tap((error) => {
                    this.logError(error);
                })
            ),
        { dispatch: false }
    );

    getSubmittalMspCustomFieldsSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalMspCustomFieldsSuccess),
                tap((res) => {
                    this.logData('Submittal msp custom fields successfully updated.');
                })
            ),
        { dispatch: false }
    );

    getSubmittalAttachmentsMetaData$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittalAttachmentsMetaData),
            map((action) => action.clientSubmittalId),
            exhaustMap((id: number) =>
                this.submittalsService.getSubmittalAttachmentsMetaData(id).pipe(
                    map((res) =>
                        submittalActions.loadSubmittalAttachmentsMetaDataSuccess({ attachmentsMetaData: res })
                    ),
                    catchError((error) => of(submittalActions.loadSubmittalAttachmentsMetaDataFailure({ error })))
                )
            )
        )
    );

    getSubmittalAttachmentsBlob$ = createEffect(() =>
        this.actions.pipe(
            ofType(submittalActions.loadSubmittalAttachmentBlob),
            map((action) => action),
            exhaustMap((action) =>
                this.submittalsService.getSubmittalFile(action.attachmentId, action.submittalId).pipe(
                    map((res) => submittalActions.loadSubmittalAttachmentBlobSuccess({ submittalAttachmentBlob: res })),
                    catchError((error) => of(submittalActions.loadSubmittalAttachmentBlobFailure({ error: error })))
                )
            )
        )
    );

    getSubmittalBlobFailure$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(submittalActions.loadSubmittalAttachmentBlobFailure),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );

    getCandidateEducation$ = createEffect(() => {
        return this.actions.pipe(
            ofType(leadsActions.loadCandidateEducation),
            exhaustMap((action) =>
                this.leadsService.getEducation(action.candidateId).pipe(
                    map((education: CandidateEducation[]) => leadsActions.loadCandidateEducationSuccess({ education })),
                    catchError((error: unknown) => of(leadsActions.loadCandidateEducationFail({ error })))
                )
            )
        );
    });

    getCandidateWorkHistory$ = createEffect(() => {
        return this.actions.pipe(
            ofType(leadsActions.loadCandidateWorkHistory),
            exhaustMap((action) =>
                this.leadsService.getWorkHistory(action.candidateId).pipe(
                    map((workHistory: CandidateWorkHistory[]) =>
                        leadsActions.loadCandidateWorkHistorySuccess({ workHistory })
                    ),
                    catchError((error: unknown) => of(leadsActions.loadCandidateWorkHistoryFail({ error })))
                )
            )
        );
    });

    getCandidateQualifications$ = createEffect(() => {
        return this.actions.pipe(
            ofType(leadsActions.loadCandidateQualifications),
            exhaustMap((action) =>
                this.leadsService.getQualifications(action.candidateId, action.isSubContractor).pipe(
                    map((qualifications: CandidateQualifications) =>
                        leadsActions.loadCandidateQualificationsSuccess({ qualifications })
                    ),
                    catchError((error: unknown) => of(leadsActions.loadCandidateQualificationsFail({ error })))
                )
            )
        );
    });

    getSubmittalActivities$ = createEffect(() => {
        return this.actions.pipe(
            ofType(leadsActions.loadSubmittalActivities, leadsActions.addSubmittalNoteSuccess),
            exhaustMap((action) =>
                this.leadsService.getSubmittalActivities(action.submittalId).pipe(
                    map((activities: SubmittalActivity[]) =>
                        leadsActions.loadSubmittalActivitiesSuccess({ activities })
                    ),
                    catchError((error: unknown) => of(leadsActions.loadSubmittalActivitiesFail({ error })))
                )
            )
        );
    });

    addSubmittalNote$ = createEffect(() => {
        return this.actions.pipe(
            ofType(leadsActions.addSubmittalNote),
            exhaustMap((action) =>
                this.leadsService.addSubmittalNote(action.submittalId, action.newSubmittalNote).pipe(
                    map(() =>
                        leadsActions.addSubmittalNoteSuccess({ submittalId: action.submittalId })
                    ),
                    catchError((error: unknown) => of(leadsActions.addSubmittalNoteFail({ error })))
                )
            )
        );
    });

    addSubmittalNoteSuccess$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(leadsActions.addSubmittalNoteSuccess),
                tap(() => {
                    this.toasterService.success('Comment was successfully added.');
                })
            ),
        { dispatch: false }
    );

    addSubmittalNoteFail$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(leadsActions.addSubmittalNoteFail),
                tap((error) => {
                    this.toasterService.fail(`There is an error while adding a comment: ${error?.error?.error}`);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions: Actions,
        private readonly submittalsService: SubmittalsService,
        private readonly leadsService: SubmittalLeadsService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
