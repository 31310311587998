import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmationReasonDialogOptions } from 'src/app/shared/models/dialog.models';

export interface ConfirmationReasonResponse {
    reason: string;
}

@Component({
    selector: 'confirmation-reason-dialog',
    templateUrl: 'confirmation-reason-dialog.component.html',
    styleUrls: ['./confirmation-reason-dialog.component.scss']
})
export class ConfirmationReasonDialogComponent implements OnInit {
    form = new FormGroup({
        input: new FormControl('')
    });

    constructor(
        public dialogRef: MatDialogRef<ConfirmationReasonDialogComponent, ConfirmationReasonResponse>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmationReasonDialogOptions
    ) {}

    ngOnInit(): void {
        this.form.get('input').setValidators(this.inputValidators);
    }

    onConfirm(): void {
        const formData = this.form.value;

        this.dialogRef.close({
            reason: formData.input
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    get confirmButtonText(): string {
        return this.data && this.data.confirmButtonText ? this.data.confirmButtonText : 'Confirm';
    }

    get cancelButtonText(): string {
        return this.data && this.data.cancelButtonText ? this.data.cancelButtonText : 'Cancel';
    }

    get confirmButtonColor(): string {
        return this.data && this.data.isWarn ? 'warn' : 'accent';
    }

    get isSaveDisabled(): boolean {
        return this.data?.isInputRequired ? this.form.invalid : false;
    }

    get inputLength(): number {
        return this.form.get('input').value.length;
    }

    private get inputValidators(): ValidatorFn[] {
        const validators = [];

        if (this.data?.isInputRequired) {
            validators.push(Validators.required);
        }
        if (this.data?.inputMaxLength) {
            validators.push(Validators.maxLength(this.data.inputMaxLength));
        }

        return validators;
    }
}
