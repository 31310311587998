import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import { ContractGroupEntityValueDto } from '../models/custom-fields/contract-group-entity-value-dto.model';
import { CustomFieldValueType } from '../models/custom-fields/custom-field-value-type.enum';
import { CustomFieldModule } from '../models/enums/custom-field-module.enum';
import { FormlyService } from '../services/formly.service';

export const isDropDown = (entityValue: ContractGroupEntityValueDto) =>
    entityValue.valueType === CustomFieldValueType.RichDropdown ||
    entityValue.valueType === CustomFieldValueType.Dropdown ||
    entityValue.valueType === CustomFieldValueType.ChildDropdown;

/**
 * We have to build the formly model differntly based on the
 * form type.
 * Specifically, if a Dropdown is used while editing.
 * See below.
 */
export const buildModel = (values: ContractGroupEntityValueDto[], model: any, formType: 'read' | 'edit'): void => {
    if (formType === 'edit') {
        values.forEach((v) => {
            const val = v.value || null;
            /**
             * If the valueType is Dropdown then we have to parse the
             * value into a special format { value, label }
             * so formly doesn't yell at us!
             */
            if (isDropDown(v)) {
                model[v.name] = { value: val, label: val };
            } else {
                model[v.name] = val;
            }
        });
    } else {
        values.forEach((v) => {
            model[v.name] = v.value;
        });
    }
};

export const buildFields = (
    values: ContractGroupEntityValueDto[],
    fields: FormlyFieldConfig[],
    formlyService: FormlyService,
    formType: 'read' | 'edit', // Are we editing or just displaying the values?
    kendoWrap: boolean = true,
    module: CustomFieldModule = CustomFieldModule.JobDetails,
    saveFieldObserver$: BehaviorSubject<string>
): FormlyFieldConfig[] => {
    // Create the formly field configs
    let _fields: FormlyFieldConfig[] = [];
    // If we need to edit the fields then use formlyService.field()
    if (formType === 'edit') {
        _fields = values.map((v) => formlyService.field(v, module, saveFieldObserver$));
    } else {
        // Else if we only need to display the fields use formlyService.display()
        _fields = values.map((v) => formlyService.display(v, module));
    }

    if (kendoWrap) {
        // Wrap them in a kendo orm wrapper
        fields = formlyService.kendoWrapper(_fields);
    } else {
        fields = _fields;
    }

    return fields;
};

/**
 * Get values from formly model back into the Entity Value Dtos
 * Since all dropdowns are now kendo, we set the value when value changes
 * Use it for Saving changes
 */
export const setDefinitionValues = (entityValues: ContractGroupEntityValueDto[], model: any): void => {
    entityValues.forEach((v) => {
        let value = model[v.name];
        if (isDropDown(v) && model && model[v.name] && Object.prototype.hasOwnProperty.call(model[v.name], 'value')) {
            value = model[v.name]['value'];
        }
        v.value = value || '';
    });
};

export const setDefinitionValue = (entityValues: ContractGroupEntityValueDto[], key: any, value: any): void => {
    // Get the DTO using field name and ensure entry exists
    const valueDto = entityValues.find((v) => v.name === key);
    if (!valueDto) {
        return;
    }
    /**
     * If the valueType is Dropdown then extract the value
     * from the formly model.
     * Otherwise the dropdown tries to insert a { value, label }
     * object instead of just the value.
     */
    if (isDropDown(valueDto)) {
        valueDto.value = value ? value['value'] : value;
    } else {
        valueDto.value = value;
    }
};
