import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as shiftsActions from 'src/app/shifts/store/actions';
import { ListItem } from 'src/app/shared/models/list-item';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { FullEmailVm } from 'src/app/shared/models/full-email-vm.model';
import { ShiftSubmittalList } from 'src/app/shifts-submittals/models';
import { BookedCandidates } from '../../models';

export interface ShiftSubmittalsState {
    submittals: ShiftSubmittalList[];
    query: GridSearchQuery;
    statuses: ListItem[];
    fullEmailOffer: FullEmailVm;
    bookedStatuses: ListItem[];
    loading: LoadingTypes;
    bookedCandidates: BookedCandidates[];
}

export const initialShiftSubmittalsState: ShiftSubmittalsState = {
    submittals: [],
    fullEmailOffer: null,
    query: { take: 100 },
    statuses: [
        { id: 1, name: 'Pending Review' },
        { id: 2, name: 'Qualified' },
        { id: 3, name: 'Interview' },
        { id: 4, name: 'Offer' },
        { id: 5, name: 'Declined' },
        { id: 6, name: 'Booked' },
    ],
    bookedStatuses: [
        { id: 6, name: 'Booked' },
        { id: 9, name: 'Cancelled' },
    ],
    loading: LoadingTypes.INIT,
    bookedCandidates: []
}

export function shiftSubmittalsReducer(state: ShiftSubmittalsState, action: Action) {
    return createReducer(
        initialShiftSubmittalsState,
        on(shiftsActions.loadShiftSubmittals, (state, action) => (
            {
                ...state,
                loading: LoadingTypes.LOADING
            }
        )),

        on(shiftsActions.loadShiftSubmittalsSuccess, (state, action) => (
            {
                ...state,
                submittals: action.submittals,
                loading: LoadingTypes.LOADED
            }
        )),

        on(shiftsActions.loadShiftSubmittalsFail, (state, action) => (
            {
                ...state,
                submittals: [],
                loading: LoadingTypes.FAIL
            }
        )),

        on(shiftsActions.loadShiftBookedCandidates, (state, action) => (
            {
                ...state,
                loading: LoadingTypes.LOADING
            }
        )),

        on(shiftsActions.loadShiftBookedCandidatesSuccess, (state, action) => (
            {
                ...state,
                bookedCandidates: action.bookedCandidates,
                loading: LoadingTypes.LOADED
            }
        )),

        on(shiftsActions.loadShiftBookedCandidatesFail, (state, action) => (
            {
                ...state,
                bookedCandidates: [],
                loading: LoadingTypes.FAIL
            }
        )),

        on(shiftsActions.setShiftSubmittalsQuery, (state, action) => (
            {
                ...state,
                query: {
                    ...state.query,
                    ...action.query
                }
            }
        )),
        on(shiftsActions.updateShiftSubmittalsStatus, (state) => (
            {
                ...state,
                loading: LoadingTypes.LOADING
            }
        )),
        on(shiftsActions.updateShiftSubmittalsStatusSuccess, (state) => (
            {
                ...state,
                loading: LoadingTypes.LOADED
            }
        )),
        on(shiftsActions.updateShiftSubmittalsStatusFail, (state) => (
            {
                ...state,
                loading: LoadingTypes.FAIL
            }
        )),
        on(shiftsActions.clearShiftSubmittals, (state) => (
            {
                ...state,
                ...initialShiftSubmittalsState
            }
        )),
        on(shiftsActions.loadSubmittalEmailOfferSuccess, (state, action) => (
            {
                ...state,
                fullEmailOffer: action.fullEmailOffer,
                loading: LoadingTypes.LOADED
            }
        )),
    )(state, action);
}

export function reducer(state: ShiftSubmittalsState | undefined, action: Action) {
    return shiftSubmittalsReducer(state, action);
}
