import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, filter, startWith, tap } from 'rxjs';
import { ContractGroupEntityValueDto } from '../models/custom-fields/contract-group-entity-value-dto.model';
import { CustomFieldValueType } from '../models/custom-fields/custom-field-value-type.enum';
import { CustomFieldModule } from '../models/enums/custom-field-module.enum';
import { notWhiteSpaceValidator } from '../utilities';
import { requiredDropdownValidator } from '../utilities/required-dropdown-validator';
import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';

/**
 * These consts are used to index the formly field type by module.
 * This allows us to pass in a module to the display()/field() functions
 * and access different formly field types (based on which module the user is accessing).
 */
const moduleToFieldType = {
    [CustomFieldModule.JobDetails]: 'kendo-field',
    [CustomFieldModule.WorkerProfile]: 'kendo-worker-input',
    [CustomFieldModule.JobTemplate]: 'kendo-field'
};

export const moduleToDropdownType = {
    [CustomFieldModule.JobDetails]: 'kendo-job-dropdown',
    [CustomFieldModule.WorkerProfile]: 'kendo-worker-dropdown',
    [CustomFieldModule.JobTemplate]: 'kendo-job-dropdown'
};

type FormlyFieldSaveConfig = FormlyFieldConfig & { saveField: BehaviorSubject<string> };

@Injectable({
    providedIn: 'root'
})
export class FormlyService extends UnsubscribeOnDestroy {
    public richDropdownIsSearchable = false;
    public childDropdownIsSearchable = false;

    // This wraps the formly form in a kendo-friendly form wrapper
    kendoWrapper(items: Array<FormlyFieldConfig>): Array<FormlyFieldConfig> {
        return [{ wrappers: ['kendo'], fieldGroup: [...items] }];
    }

    /**
     * Used to display the form fields.
     * Does not allow editing of values.
     */
    display(item: ContractGroupEntityValueDto, module: CustomFieldModule = CustomFieldModule.JobDetails) {
        const config = {
            key: item.name,
            type: 'kendo-input',
            props: { label: item.name, helpText: item.helpText }
        };

        switch (module) {
            case CustomFieldModule.JobDetails:
                break;
            case CustomFieldModule.WorkerProfile:
                config.type = 'kendo-worker-display';
                break;
        }
        return config;
    }

    /**
     * Used to get the proper formly field config based on CustomFieldValueType.
     * These fields allow value editing.
     */
    field(
        item: ContractGroupEntityValueDto,
        module: CustomFieldModule = CustomFieldModule.JobDetails,
        saveFieldObserver$: BehaviorSubject<string>
    ): FormlyFieldConfig {
        const field = this.getField(item, module, saveFieldObserver$);
        return { ...field, props: { ...field.props, helpText: item.helpText } };
    }

    getField(
        item: ContractGroupEntityValueDto,
        module: CustomFieldModule = CustomFieldModule.JobDetails,
        saveFieldObserver$: BehaviorSubject<string>
    ): FormlyFieldConfig {
        switch (item.valueType) {
            case CustomFieldValueType.Text:
                return this.input(item, module, saveFieldObserver$);
            case CustomFieldValueType.Number:
                return this.number(item, module, saveFieldObserver$);
            case CustomFieldValueType.Date:
                return this.date(item, module, saveFieldObserver$);
            case CustomFieldValueType.Dropdown:
                return this.select(item, module, saveFieldObserver$);
            case CustomFieldValueType.RichDropdown:
                return this.richDropdown(item, module, saveFieldObserver$);
            case CustomFieldValueType.ChildDropdown:
                return this.childDropdown(item, module, saveFieldObserver$);
            default:
                return this.input(item, module, saveFieldObserver$);
        }
    }

    input(
        item: ContractGroupEntityValueDto,
        module: CustomFieldModule = CustomFieldModule.JobDetails,
        saveFieldObserver: BehaviorSubject<string>
    ): FormlyFieldSaveConfig {
        return {
            key: item.name,
            type: moduleToFieldType[module],
            props: {
                label: item.name,
                required: item.required,
                maxLength: 500
            },
            saveField: saveFieldObserver,
            validators: item.required
                ? {
                      validation: [notWhiteSpaceValidator()]
                  }
                : undefined
        };
    }

    number(
        item: ContractGroupEntityValueDto,
        module: CustomFieldModule = CustomFieldModule.JobDetails,
        saveFieldObserver: BehaviorSubject<string>
    ): FormlyFieldSaveConfig {
        const config: FormlyFieldSaveConfig = {
            key: item.name,
            type: moduleToFieldType[module],
            props: {
                label: item.name,
                type: 'text',
                required: item.required
            },
            saveField: saveFieldObserver
        };

        config.validators = {
            // Limit to 38 total digits since that's the default max precision of numeric/decimal in SQL Server.
            decimalNumber: {
                expression: (c: AbstractControl) => !c.value || /^\d{0,19}\.?\d{1,19}$/.test(c.value),
                message: (_: any, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid number`
            }
        };

        return config;
    }

    date(
        item: ContractGroupEntityValueDto,
        module: CustomFieldModule = CustomFieldModule.JobDetails,
        saveFieldObserver: BehaviorSubject<string>
    ): FormlyFieldSaveConfig {
        return {
            key: item.name,
            type: moduleToFieldType[module],
            props: {
                label: item.name,
                type: 'date',
                required: item.required
            },
            saveField: saveFieldObserver
        };
    }

    select(
        item: ContractGroupEntityValueDto,
        module: CustomFieldModule = CustomFieldModule.JobDetails,
        saveFieldObserver: BehaviorSubject<string>
    ): FormlyFieldSaveConfig {
        return {
            key: item.name,
            type: moduleToDropdownType[module],
            className: 'job-custom-field-dropdown',
            props: {
                label: item.name,
                options: item.options.map((o) => ({ value: o, label: o })),
                required: item.required,
                definitionId: item.definitionId
            },
            saveField: saveFieldObserver,
            validators: item.required ? { validation: [requiredDropdownValidator()] } : undefined
        };
    }

    richDropdown(
        item: ContractGroupEntityValueDto,
        module: CustomFieldModule = CustomFieldModule.JobDetails,
        saveFieldObserver: BehaviorSubject<string>
    ): FormlyFieldSaveConfig {
        let dropdownType = moduleToDropdownType[module];
        let addRequiredValidator = false;
        if (this.richDropdownIsSearchable) {
            if (module === CustomFieldModule.WorkerProfile) {
                dropdownType = 'kendo-searchable-worker-dropdown';
            } else {
                dropdownType = 'kendo-searchable-job-dropdown';
                if (item.required) {
                    addRequiredValidator = true;
                }
            }
        }

        return {
            key: item.name,
            type: dropdownType,
            className: 'job-custom-field-dropdown',
            props: {
                label: item.name,
                options: item.richOptions.map((o) => ({ value: o.value, label: o.value + ' - ' + o.description })),
                required: item.required,
                definitionId: item.definitionId,
                descriptionOnly: item.descriptionOnly,
                valueType: CustomFieldValueType.RichDropdown
            },
            saveField: saveFieldObserver,
            validators: this.addValidators(item.required, module, addRequiredValidator)
        };
    }

    childDropdown(
        item: ContractGroupEntityValueDto,
        module: CustomFieldModule = CustomFieldModule.JobDetails,
        saveFieldObserver$: BehaviorSubject<string>
    ): FormlyFieldSaveConfig {
        const options = item.parentChildOptions
            .filter((f) => f.parentOption === item.parentDropdownValue)
            .map((o) => {
                if (o.childOptionDescription) {
                    return { value: o.childOption, label: `${o.childOption} - ${o.childOptionDescription}` };
                }
                return { value: o.childOption, label: o.childOption };
            });

        let dropdownType = moduleToDropdownType[module];
        let addRequiredValidator = false;
        if (this.childDropdownIsSearchable) {
            if (module === CustomFieldModule.WorkerProfile) {
                dropdownType = 'kendo-searchable-worker-dropdown';
            } else {
                dropdownType = 'kendo-searchable-job-dropdown';
                if (item.required) {
                    addRequiredValidator = true;
                }
            }
        }

        return {
            key: item.name,
            type: dropdownType,
            className: 'job-custom-field-dropdown',
            props: {
                label: item.name,
                options,
                required: item.required,
                definitionId: item.definitionId,
                descriptionOnly: item.descriptionOnly,
                valueType: CustomFieldValueType.ChildDropdown
            },
            saveField: saveFieldObserver$,
            validators: this.addValidators(item.required, module, addRequiredValidator),
            hooks: {
                onInit: (field: FormlyFieldConfig) => {
                    if (this.childDropdownIsSearchable) {
                        const customFieldForm = field.form;
                        const customFieldDefinitionNames = Object.keys(customFieldForm.controls);

                        const parentDropdown: FormControl = this.getParentDropdown(
                            customFieldForm,
                            customFieldDefinitionNames,
                            item.parentDropdownDefinitionId
                        );
                        if (!parentDropdown) {
                            console.error('Could not find parent for: ' + item.name);
                            return;
                        }

                        field.props.parentOption = parentDropdown.valueChanges.pipe(
                            startWith(item.parentDropdownValue)
                        );
                        return;
                    }

                    return field.options.fieldChanges.pipe(
                        filter(
                            (e: any) =>
                                e.type === 'valueChanges' &&
                                e.field?.props?.definitionId === item.parentDropdownDefinitionId
                        ),
                        tap((e: any) => {
                            const parentOptionValue = e.value?.value;
                            const opts = item.parentChildOptions
                                .filter((f) => f.parentOption === (parentOptionValue?.value ?? parentOptionValue))
                                .map((o) => {
                                    if (o.childOptionDescription) {
                                        return {
                                            value: o.childOption,
                                            label: `${o.childOption} - ${o.childOptionDescription}`
                                        };
                                    }
                                    return { value: o.childOption, label: o.childOption };
                                });

                            field.props.options = opts;

                            if (module === CustomFieldModule.WorkerProfile) {
                                const needreset =
                                    (field.formControl?.value?.value !== null &&
                                        item.value !== '' &&
                                        field.formControl?.pristine) ||
                                    (field.formControl?.value?.value !== null && field.formControl?.touched);

                                needreset && field.formControl.patchValue({ value: null, label: '' });
                            } else {
                                item.parentDropdownValue !== parentOptionValue &&
                                    field.formControl.patchValue({ value: null, label: '' });
                            }
                        })
                    );
                }
            }
        };
    }

    addValidators(required: boolean, module: CustomFieldModule, addRequiredValidator: boolean) {
        return (required && module === CustomFieldModule.WorkerProfile) || addRequiredValidator
            ? {
                  validation: [requiredDropdownValidator()]
              }
            : undefined;
    }

    getParentDropdown(
        customFieldForm: FormGroup<any> | FormArray<any>,
        customFieldDefinitionNames: string[],
        parentDefinitionId: number
    ): FormControl {
        let parentDropdown: FormControl = undefined;
        for (let definitionName of customFieldDefinitionNames) {
            const customFieldFormControl = customFieldForm.controls[definitionName];
            if (parentDefinitionId === customFieldFormControl._fields[0].props.definitionId) {
                parentDropdown = customFieldFormControl;
                break;
            }
        }
        return parentDropdown;
    }
}
