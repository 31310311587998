import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FaciltyFeatureDropdown } from 'src/app/facilities/models';

@Component({
    selector: 'ayac-shared-filter-search-pop-open-component',
    templateUrl: './filter-search-pop-open.component.html',
    styleUrls: ['./filter-search-pop-open.component.scss']
})
export class SharedFilterSearchPopOpenComponent implements OnInit {

    @Input() 
    get dataToSearch(): FaciltyFeatureDropdown[] { return this.searchData; }
    set dataToSearch(value: FaciltyFeatureDropdown[]) {
        this.searchData = value;
    }
    @Input() searchInputPlaceholder = 'Search an item';
    @Input() showFilterPopUp = false;
    @Output() itemSelected = new EventEmitter<any>();
    @Input() searchTerm = "";
    searchData = [];
    searchIconVal = 'search';
    @ViewChild('inputBox') inputBox: ElementRef;
    
    constructor() { }

    ngOnInit(): void {
        this.searchIconVal = this.searchTerm?.length < 1 ? 'search' : 'close';  
    }

    onBlur() {
        let blurTimeout;

        clearTimeout(blurTimeout);
        blurTimeout += setTimeout(() => {
            if (this.inputBox) {
                this.inputBox.nativeElement.focus();
            }
        }, 100);
    }

    searchTermChange(event: string) {
        this.searchIconVal = event.length < 1 ? 'search' : 'close';
    }
    
    searchAction() {
        if (this.searchTerm.length > 0) {
            this.searchTerm = '';
            this.searchIconVal = 'search';
        }
    }

    itemClick(item: FaciltyFeatureDropdown) {
        this.itemSelected.emit(item);
    }    
}
