import { Action, createReducer, on } from '@ngrx/store';
import { adminJobsActions } from 'src/app/admin/store/actions/admin-jobs.actions';
import {
    AdminJobsState,
    getInitialAdminJobsQueryState,
    getInitialAdminJobsState
} from 'src/app/admin/store/state/admin-jobs.state';
import { LoadingTypes } from 'src/app/shared/models';

const reducer = createReducer(
    getInitialAdminJobsState(),

    on(
        adminJobsActions.loadAdminJobs,
        (currentState): AdminJobsState => ({
            ...currentState,
            jobs: [],
            filteredAllPositionsCount: 0,
            filteredOpenPositionsCount: 0,
            loadingState: LoadingTypes.LOADING,
            error: undefined
        })
    ),

    on(
        adminJobsActions.loadAdminJobsSuccess,
        (currentState, successAction): AdminJobsState => ({
            ...currentState,
            error: undefined,
            filteredAllPositionsCount: successAction.filteredAllPositionsCount,
            filteredOpenPositionsCount: successAction.filteredOpenPositionsCount,
            jobs: successAction.jobs,
            loadingState: LoadingTypes.LOADED,
            total: successAction.total
        })
    ),

    on(
        adminJobsActions.getJobById,
        (currentState): AdminJobsState => ({
            ...currentState,
            error: undefined,
            jobDetails: undefined
        })
    ),

    on(
        adminJobsActions.getJobByIdSuccess,
        (currentState, successAction): AdminJobsState => ({
            ...currentState,
            error: undefined,
            jobDetails: successAction.jobDetails
        })
    ),

    on(
        adminJobsActions.getJobByIdFailure,
        (currentState, failureAction): AdminJobsState => ({
            ...currentState,
            error: JSON.stringify(failureAction.error),
            jobDetails: undefined
        })
    ),

    on(
        adminJobsActions.getCanEditVendorBaseRate,
        (currentState): AdminJobsState => ({
            ...currentState,
            error: undefined,
            canEditVendorBaseRate: false
        })
    ),

    on(
        adminJobsActions.getCanEditVendorBaseRateSuccess,
        (currentState, successAction): AdminJobsState => ({
            ...currentState,
            error: undefined,
            canEditVendorBaseRate: successAction.canEdit
        })
    ),

    on(
        adminJobsActions.getCanEditVendorBaseRateFailure,
        (currentState, failureAction): AdminJobsState => ({
            ...currentState,
            error: JSON.stringify(failureAction.error),
            canEditVendorBaseRate: false
        })
    ),

    on(
        adminJobsActions.loadJobAttachments,
        (currentState): AdminJobsState => ({
            ...currentState,
            error: undefined,
            jobAttachments: []
        })
    ),

    on(
        adminJobsActions.loadJobAttachmentsSuccess,
        (currentState, successAction): AdminJobsState => ({
            ...currentState,
            error: undefined,
            jobAttachments: successAction.attachments
        })
    ),

    on(
        adminJobsActions.loadJobAttachmentsFailure,
        (currentState, failureAction): AdminJobsState => ({
            ...currentState,
            error: JSON.stringify(failureAction.error),
            jobAttachments: []
        })
    ),

    on(
        adminJobsActions.loadJobAttachmentPreview,
        adminJobsActions.resetJobAttachmentPreview,
        (currentState): AdminJobsState => ({
            ...currentState,
            error: undefined,
            jobAttachmentPreview: undefined
        })
    ),

    on(
        adminJobsActions.loadJobAttachmentPreviewSuccess,
        (currentState, successAction): AdminJobsState => ({
            ...currentState,
            error: undefined,
            jobAttachmentPreview: successAction.file
        })
    ),

    on(
        adminJobsActions.loadJobAttachmentPreviewFailure,
        (currentState, failureAction): AdminJobsState => ({
            ...currentState,
            error: JSON.stringify(failureAction.error),
            jobAttachmentPreview: undefined
        })
    ),

    on(
        adminJobsActions.loadJobAttachmentPreviewTotalPages,
        (currentState): AdminJobsState => ({
            ...currentState,
            error: undefined,
            jobAttachmentTotalPages: 0
        })
    ),

    on(
        adminJobsActions.loadJobAttachmentPreviewTotalPagesSuccess,
        (currentState, successAction): AdminJobsState => ({
            ...currentState,
            error: undefined,
            jobAttachmentTotalPages: successAction.totalPages
        })
    ),

    on(
        adminJobsActions.loadJobAttachmentPreviewTotalPagesFailure,
        (currentState, failureAction): AdminJobsState => ({
            ...currentState,
            error: JSON.stringify(failureAction.error),
            jobAttachmentTotalPages: 0
        })
    ),

    on(
        adminJobsActions.setVendorCandidatesListSearchQuery,
        (currentState, action): AdminJobsState => ({
            ...currentState,
            gridSearchQuery: action.searchQuery,
            loadingState: LoadingTypes.LOADING
        })
    ),

    on(
        adminJobsActions.loadAdminJobLookups,
        (currentState): AdminJobsState => ({
            ...currentState,
            jobLookups: undefined
        })
    ),

    on(
        adminJobsActions.loadAdminJobLookupsSuccess,
        (currentState, action): AdminJobsState => ({
            ...currentState,
            jobLookups: action.lookups
        })
    ),

    on(
        adminJobsActions.loadAdminJobLookupsFailure,
        (currentState, action): AdminJobsState => ({
            ...currentState,
            jobLookups: undefined,
            error: JSON.stringify(action.error)
        })
    ),

    on(
        adminJobsActions.setFacilityGroupIdFilter,
        (currentState, action): AdminJobsState => ({
            ...currentState,
            filteredFacilityGroupId: action.facilityGroupId
        })
    ),

    on(
        adminJobsActions.loadReleaseGroupsByFacilities,
        (currentState): AdminJobsState => ({
            ...currentState,
            releaseGroups: []
        })
    ),

    on(
        adminJobsActions.loadReleaseGroupsByFacilitiesSuccess,
        (currentState, action): AdminJobsState => ({
            ...currentState,
            releaseGroups: action.groups,
            error: undefined
        })
    ),

    on(
        adminJobsActions.loadReleaseGroupsByFacilitiesFailure,
        (currentState, action): AdminJobsState => ({
            ...currentState,
            releaseGroups: [],
            error: JSON.stringify(action.error)
        })
    ),

    on(
        adminJobsActions.releaseJobs,
        adminJobsActions.releaseAllJobs,
        (currentState): AdminJobsState => ({
            ...currentState,
            loadingState: LoadingTypes.LOADING
        })
    ),

    on(
        adminJobsActions.releaseJobsSuccess,
        (currentState): AdminJobsState => ({
            ...currentState,
            loadingState: LoadingTypes.LOADED
        })
    ),

    on(
        adminJobsActions.releaseJobsFailure,
        (currentState, action): AdminJobsState => ({
            ...currentState,
            loadingState: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })
    ),

    on(
        adminJobsActions.resetGridSearchQuery,
        (currentState): AdminJobsState => ({
            ...currentState,
            filteredFacilityGroupId: undefined,
            gridSearchQuery: getInitialAdminJobsQueryState()
        })
    )
);

export const adminJobsReducer = (state: AdminJobsState | undefined, action: Action) => {
    return reducer(state, action);
};
