/**angular */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

/**rxjs ngrx */
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

/**shared */
import { Role } from 'src/app/core/auth/models/auth';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ListItem } from 'src/app/shared/models/list-item';
import { ContactChange, ContactTag, UserTypeEnum } from 'src/app/shared/models';
import { emptyValidator, notWhiteSpaceValidator, hasWhiteSpaceValidator } from 'src/app/shared/utilities';

/**local */
import { SSOIdpRequiredIfNotFalseRelativeValidator } from 'src/app/admin/system-contacts/utilities/sso-user.validator';
import { availableUsernameValidator } from 'src/app/admin/vendor-details/contacts/utilities';
import { VendorContactService } from 'src/app/admin/vendor-details/services';
import { ConnectUser } from 'src/app/shared/models/connect-user.model';
import { Contact } from './models/contact.model';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';
import { FeatureFlag } from '../../models/enums/feature-flag.enum';
import { InputDropdownComponent } from '../input-dropdown-combo/input-dropdown-combo.component';
import { MatSelectChange } from '@angular/material/select';
import { OrganizationInvitation } from 'src/app/shared/models/account/organization-invitation.model';
import { InvitationStatusEnum } from 'src/app/shared/models/enums/account/invitation-status.enum';
import { SendOrganizationInvitation } from 'src/app/shared/models/account/send-organization-invitation.model';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { AdminClientsService } from 'src/app/admin-clients/admin-clients.service';
import { AdminClientFacilityProfileGroup } from 'src/app/admin-clients/models/admin-clients-facilities.model';
import { OptionModel } from 'src/app/shared/models/option.model';
import { ApplicationPermissions } from 'src/app/shared/models/enums/application-permissions.enum';

@Component({
    selector: 'ayac-contact-profile',
    templateUrl: './contact-profile.component.html',
    styleUrls: ['./contact-profile.component.scss']
})
export class ContactProfileComponent extends UnsubscribeOnDestroy implements OnInit {
    private _invitation: OrganizationInvitation;

    @ViewChild(InputDropdownComponent, { static: false })
    inputDropdownComponent: InputDropdownComponent;
    @Output() contactChanged: EventEmitter<ContactChange> = new EventEmitter<ContactChange>();
    @Output() identityProviderChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() invitaionSent: EventEmitter<SendOrganizationInvitation> = new EventEmitter<SendOrganizationInvitation>();
    @Input() contact: Contact | null = null;
    @Input() contactTypeTags: ContactTag[] | null = null;
    @Input() systemsOrClients: ListItem[] | null = null;
    @Input() identityProviders: string[] | null = null;
    @Input()
    set invitation(invitation: OrganizationInvitation) {
        if (this._invitation && !invitation) {
            this.clearUserCredentials();
        }
        if (invitation) {
            this.updateUserCredentialsForInvitation();
        }
        this.setShowPasswordField();
        this.ssoBlocked = invitation && invitation.status === this.invitationStatuses.Member;
        this._invitation = invitation;
    }
    get invitation(): OrganizationInvitation {
        return this._invitation;
    }

    @Input() roles: Role[] = [];

    get hasInvitation(): boolean {
        return !!this._invitation;
    }
    get hasParentProfile(): boolean {
        return !!this.contactProfileForm?.get('user')?.get('linkedToParentProfileId')?.value;
    }
    get requiresPasswordReset(): boolean {
        return (
            (!!this.contact.user?.linkedToParentProfileId &&
                !this.contactProfileForm?.get('user')?.get('linkedToParentProfileId')?.value) ||
            (!!this.contact.user?.identityProvider &&
                !this.contactProfileForm?.get('user')?.get('identityProvider')?.value)
        );
    }
    get hasSso(): boolean {
        return this.contactProfileForm?.get('user')?.get('ssoUser')?.value;
    }

    set ssoBlocked(value: boolean) {
        if (value) {
            this.contactProfileForm?.get('user')?.get('identityProvider')?.disable({ onlySelf: true });
        } else {
            this.contactProfileForm?.get('user')?.get('identityProvider')?.enable();
        }
    }
    get ssoBlocked(): boolean {
        return this.hasInvitation && this.contactProfileForm?.get('user')?.get('identityProvider')?.disabled;
    }

    @Input() isSystemContact: boolean = false;
    @Input() facilities: AdminClientFacilityProfileGroup[] | null = null;

    filteredTags$: Observable<ContactTag[]>;
    canImpersonate: boolean;
    canManageLinkedProfiles: boolean;

    contactProfileForm: UntypedFormGroup;
    title: string = 'System';
    showPasswordField: boolean = false;
    invitationStatuses = InvitationStatusEnum;
    featureFlag = FeatureFlag;

    searchProfilesText$: BehaviorSubject<string> = new BehaviorSubject('');
    filteredProfiles$: Observable<OptionModel[]>;
    linkedProfilesSourse: OptionModel[] = [];
    linkedToParentProfile: OptionModel = null;
    emailChanged = false;
    userNameToEmailWarning = false;

    private originalUsername: string | null = null;

    constructor(
        private readonly _authService: AuthService,
        private readonly _vendorService: VendorContactService,
        private readonly _adminClientService: AdminClientsService,
        private readonly _identityService: IdentityService,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.contactProfileForm = this.createContactProfileForm(this.contact);

        this.contactProfileForm.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.d$))
            .subscribe((contactFormValue: any) => {
                if (!this.contactProfileForm.pristine) {
                    const contact: Contact = this.convertFormForSaving(contactFormValue);
                    this.contactChanged.emit({
                        contact,
                        isInvalidChange: this.isInvalid(contact)
                    });
                }
            });

        this.contactProfileForm
            .get('emailAddress')
            .valueChanges.pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.d$))
            .subscribe((emailAddress: string) => {
                this.emailChanged = true;
                this.userNameToEmailWarning = false;
                if (
                    (this.hasInvitation || !this.hasParentProfile) &&
                    this.contactProfileForm.get('portalSignIn')?.value
                ) {
                    this.contactProfileForm.get('user')?.get('username')?.setValue(emailAddress);
                    this.contactProfileForm.get('user')?.get('username')?.markAsTouched();
                }
            });

        if (this.contact.user && this.contact.user.username) {
            this.originalUsername = this.contact.user.username;

            this.contactProfileForm.addControl('user', this.createUserForm(this.contact.user));
        }

        if (this.contact.user && this.contact.user.identityProvider) {
            this.identityProviderChanged.emit(this.contact.user.identityProvider);
        } else {
            this.identityProviderChanged.emit(null);
        }

        if (!this.isSystemContact) {
            this.title = 'Client';
            this.watchForClientChange();
        }

        this.setShowPasswordField();

        this.canImpersonate = this._identityService.hasSecurityPermission(
            ApplicationPermissions.ImpersonateUsers
        );
        this.canManageLinkedProfiles = this._identityService.hasSecurityPermission(
            ApplicationPermissions.ManageLinkedProfiles
        );

        this.refreshPossibleParentLinkedProfiles();
        this.filteredProfiles$ = this.searchProfilesText$.pipe(
            takeUntil(this.d$),
            debounceTime(250),
            switchMap((search) =>
                of(
                    search
                        ? this.linkedProfilesSourse.filter((p) =>
                              p.name.toLowerCase().includes(search.toLowerCase())
                          )
                        : this.linkedProfilesSourse
                )
            )
        );

        if (
            this.originalUsername &&
            this.contact.emailAddress &&
            this.contact.user?.isActive &&
            this.originalUsername.toLowerCase() !== this.contact.emailAddress.toLowerCase() &&
            !this.contact.user?.linkedToParentProfileId
        ) {
            this.userNameToEmailWarning = true;
        }

        this.contactProfileForm.get('role')?.patchValue(this.roles.find((r) => r.id === this.contact.user?.roleId));
    }

    createContactProfileForm(contact: Contact): UntypedFormGroup {
        const newFormGroup = new UntypedFormGroup({
            systemOrClient: new UntypedFormControl(
                contact.systemOrClientId
                    ? {
                          id: contact.systemOrClientId,
                          name: this.getSystemName(contact.systemOrClientId)
                      }
                    : null,
                this.systemsOrClients ? Validators.required : null
            ),
            firstName: new UntypedFormControl(contact.firstName, [
                Validators.required,
                emptyValidator(),
                notWhiteSpaceValidator(),
                Validators.maxLength(50)
            ]),
            lastName: new UntypedFormControl(contact.lastName, [
                Validators.required,
                emptyValidator(),
                notWhiteSpaceValidator(),
                Validators.maxLength(50)
            ]),
            emailAddress: new UntypedFormControl(contact.emailAddress),
            portalSignIn: new UntypedFormControl(
                contact.user && contact.user?.username && contact.user?.isActive ? true : false
            ),
            phone: new UntypedFormControl(contact.phone === '() - ext: ' ? '' : contact.phone),
            showEmailExternally: new UntypedFormControl(contact.showEmailExternally),
            tags: new UntypedFormControl(this.convertTagIdsToContactTags(contact.typeTags)),
            tagInput: new UntypedFormControl(),
            facilities: new UntypedFormControl(this.contact.facilities),
            facilityInput: new UntypedFormControl(),
            note: new UntypedFormControl(contact.note),
            role: new UntypedFormControl()
        });

        if (newFormGroup?.get('portalSignIn').value) {
            newFormGroup
                .get('emailAddress')
                .setValidators([Validators.required, Validators.email, Validators.maxLength(150)]);
            newFormGroup.get('emailAddress').updateValueAndValidity({ emitEvent: false });

            if (!this.isSystemContact && contact.systemOrClientId) {
                const facilitiesControl = newFormGroup.get('facilities');
                facilitiesControl.setValidators([Validators.required]);
                facilitiesControl.updateValueAndValidity({ emitEvent: false });
            }
        }

        return newFormGroup;
    }

    addUser(): void {
        const userForm = this.contactProfileForm.get('user');
        if (userForm) {
            const updatedUser: ConnectUser = {
                id: this.contact.user?.id ? this.contact.user.id : null,
                username: userForm.get('username').value,
                linkedToParentProfileId: userForm.get('linkedToParentProfileId')?.value ?? null,
                password: userForm.get('password')?.value ?? null,
                isActive: true,
                identityProvider: userForm.get('identityProvider').value
                    ? userForm.get('identityProvider').value
                    : null,
                roleId: userForm.get('roleId').value
            };
            this.contact.user = { ...this.contact.user, ...updatedUser };
            this.contactProfileForm.get('user').markAllAsTouched();
            this.contactProfileForm.get('user').updateValueAndValidity({ emitEvent: false });
        } else {
            const newUser: ConnectUser = {
                id: null,
                username: '',
                linkedToParentProfileId: null,
                password: '',
                isActive: true,
                identityProvider: null,
                roleId: 0
            };
            this.contact.user = newUser;
            this.contactProfileForm.addControl('user', this.createUserForm(newUser));
        }

        const emailAddressControl = this.contactProfileForm.get('emailAddress');
        emailAddressControl.setValidators([Validators.required, Validators.email, Validators.maxLength(150)]);
        emailAddressControl.markAsTouched();
        emailAddressControl.updateValueAndValidity({ emitEvent: false });

        this.contactProfileForm.markAllAsTouched();
        this.inputDropdownComponent.onBlur();

        if (!this.isSystemContact) {
            const facilitiesControl = this.contactProfileForm.get('facilities');
            facilitiesControl.setValidators([Validators.required]);
            facilitiesControl.markAsTouched();
            facilitiesControl.updateValueAndValidity({ emitEvent: false });
        }

        this.contactProfileForm.patchValue({ portalSignIn: true });
        this.removeLinkedToParent();
    }

    removeUser(): void {
        this.contact.user.isActive = false;

        const passwordControl = this.contactProfileForm.get('user').get('password');
        passwordControl.clearValidators();
        passwordControl.updateValueAndValidity({ emitEvent: false });

        this.contactProfileForm.patchValue({ portalSignIn: false });

        const usernameControl = this.contactProfileForm.get('user').get('username');
        usernameControl.clearValidators();
        usernameControl.clearAsyncValidators();
        usernameControl.updateValueAndValidity({ emitEvent: false });

        const emailAddressControl = this.contactProfileForm.get('emailAddress');
        emailAddressControl.clearValidators();
        emailAddressControl.clearAsyncValidators();
        emailAddressControl.updateValueAndValidity({ emitEvent: false });

        const facilitiesControl = this.contactProfileForm.get('facilities');
        facilitiesControl.clearValidators();
        facilitiesControl.clearAsyncValidators();
        facilitiesControl.updateValueAndValidity({ emitEvent: false });

        const userControl = this.contactProfileForm.get('user');
        userControl.clearValidators();
        userControl.clearAsyncValidators();
        userControl.updateValueAndValidity({ emitEvent: false });

        this.removeLinkedToParent();
    }

    convertFormForSaving(contactForm): Contact {
        let identityProvider = contactForm.user?.identityProvider;
        if (contactForm.user && !identityProvider) {
            identityProvider = this.contactProfileForm?.get('user')?.get('identityProvider')?.getRawValue();
        }
        return {
            ...this.contact,
            systemOrClientId: contactForm.systemOrClient?.id,
            firstName: contactForm.firstName,
            lastName: contactForm.lastName,
            emailAddress: contactForm.emailAddress,
            phone: contactForm.phone,
            showEmailExternally: contactForm.showEmailExternally,
            typeTags: contactForm.tags.map((tag: ContactTag) => tag.id),
            note: contactForm.note,
            facilities: contactForm.facilities,
            user: {
                ...this.contact.user,
                id: this.contact.user?.id || 0,
                username: contactForm.user?.username,
                linkedToParentProfileId: contactForm.user?.linkedToParentProfileId,
                password: contactForm.user?.password,
                isActive: contactForm.portalSignIn,
                identityProvider: identityProvider,
                roleId: contactForm.role?.id
            }
        };
    }

    isInvalid(updatedContact: Contact): boolean {
        // Have to do a manual check here as the validators
        // don't catch the invalid data if the value is an empty string
        let userInvalid = false;

        if (updatedContact.user.isActive) {
            const missingUsername: boolean = updatedContact.user?.username?.length === 0;
            const missingPassword: boolean = this.showPasswordField
                ? updatedContact.user?.password.length === 0
                : false;

            userInvalid = missingUsername || (missingPassword && updatedContact.user?.id === 0);
        }

        return (
            this.contactProfileForm.invalid ||
            updatedContact.lastName.length === 0 ||
            updatedContact.firstName.length === 0 ||
            userInvalid
        );
    }

    createUserForm(user: ConnectUser): UntypedFormGroup {
        const userForm = new UntypedFormGroup(
            {
                username: new UntypedFormControl(
                    user.username,
                    [Validators.required, emptyValidator(), notWhiteSpaceValidator(), hasWhiteSpaceValidator()],
                    [
                        availableUsernameValidator(
                            this._vendorService,
                            this.originalUsername,
                            this.contact.user?.isActive
                        )
                    ]
                ),
                linkedToParentProfileId: new UntypedFormControl(user?.linkedToParentProfileId),
                password: new UntypedFormControl(user.password, [
                    Validators.required,
                    emptyValidator(),
                    notWhiteSpaceValidator()
                ]),
                ssoUser: new UntypedFormControl(user?.identityProvider ? true : false),
                identityProvider: new UntypedFormControl(user.identityProvider),
                roleId: new UntypedFormControl(user?.roleId)
            },
            {
                validators: SSOIdpRequiredIfNotFalseRelativeValidator
            }
        );

        if (!this.showPasswordField) {
            const passwordControl = userForm.get('password');
            passwordControl.clearValidators();
            passwordControl.updateValueAndValidity({ emitEvent: false });
        }

        return userForm;
    }

    addSSO() {
        this.contactProfileForm.get('user').get('ssoUser').setValue(true);
        this.contactProfileForm.get('user').get('ssoUser').updateValueAndValidity({ emitEvent: false });
        this.contactProfileForm.get('user').patchValue({ identityProvider: null });
        this.identityProviderChanged.emit(null);
        this.removeLinkedToParent();
        this.setShowPasswordField();
    }

    removeSSO() {
        this.contactProfileForm.get('user').patchValue({ identityProvider: null });
        this.identityProviderChanged.emit(null);
        this.contactProfileForm.get('user').get('ssoUser').setValue(false);
        this.contactProfileForm.get('user').get('ssoUser').updateValueAndValidity({ emitEvent: false });
        this.setShowPasswordField();
    }

    watchForClientChange(): void {
        this.contactProfileForm
            .get('systemOrClient')
            .valueChanges.pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.d$))
            .subscribe((clientValue: ListItem) => {
                this.loadFacilities(clientValue);
            });
    }

    loadFacilities(clientValue: ListItem): void {
        if (clientValue && clientValue.legacyId && !this.isSystemContact) {
            this._adminClientService
                .getFacilitiesForProfileGroup(clientValue?.legacyId)
                .pipe(takeUntil(this.d$))
                .subscribe((facilities: AdminClientFacilityProfileGroup[]) => {
                    this.facilities = facilities;
                    this.contactProfileForm.get('facilities').setValue(null);
                    this.contactProfileForm.get('facilities').updateValueAndValidity({ emitEvent: false });
                    this.contactProfileForm.get('facilityInput').setValue(null);
                    this.contactProfileForm.get('facilityInput').updateValueAndValidity({ emitEvent: false });
                });
        }
    }

    setShowPasswordField(): void {
        if (!this.contactProfileForm) {
            return;
        }
        const passwordControl = this.contactProfileForm.get('user')?.get('password');
        this.showPasswordField =
            !this.hasParentProfile && !this.hasSso && this.contactProfileForm?.get('portalSignIn')?.value;
        if ((this.contact?.user?.id > 0 && !this.requiresPasswordReset) || !this.showPasswordField) {
            passwordControl?.clearValidators();
        } else {
            passwordControl?.setValidators([Validators.required, emptyValidator(), notWhiteSpaceValidator()]);
        }
        if (this.requiresPasswordReset) {
            passwordControl?.markAsTouched();
        }
        passwordControl?.updateValueAndValidity({ emitEvent: false });
    }

    convertTagIdsToContactTags(contactTagIds: number[]): ContactTag[] {
        if (contactTagIds && contactTagIds.length && this.contactTypeTags) {
            return this.contactTypeTags.filter((tag: ContactTag) => contactTagIds.includes(tag.id));
        }

        return [];
    }

    getSystemName(id: number): string | null {
        const system = this.systemsOrClients?.find((s: ContactTag) => s.id === id);
        if (system) {
            return system.name;
        }
        return null;
    }
    changeIdentityProvider(event$: MatSelectChange) {
        this.identityProviderChanged.emit(event$.value);
    }

    sendInvitaion() {
        if (this.contact && this.contact.user && this.contact.user.coreUserId && this.contact.user.identityProvider) {
            this.invitaionSent.emit({
                userId: this.contact.user.coreUserId,
                identityProvider: this.contact.user.identityProvider
            });
        }
    }

    impersonate() {
        if (this.contact.user.coreUserId) {
            this._identityService.changeProfile(this.contact.user.coreUserId, true);
        }
    }

    updateUserCredentialsForInvitation() {
        const usernameControl = this.contactProfileForm?.get('user')?.get('username');
        const passwordControl = this.contactProfileForm?.get('user')?.get('password');
        if (!usernameControl || !passwordControl || this.contact?.user?.id) {
            return;
        }
        const emailAddress = this.contactProfileForm.get('emailAddress')?.value;
        usernameControl.setValue(emailAddress);
        passwordControl.setValue('');
        this.setShowPasswordField();
    }
    clearUserCredentials() {
        const usernameControl = this.contactProfileForm?.get('user')?.get('username');
        const passwordControl = this.contactProfileForm?.get('user')?.get('password');
        if (!usernameControl || !passwordControl || this.contact?.user?.id) {
            return;
        }
        if (usernameControl.value || passwordControl.value) {
            passwordControl.setValue('');
            this.setShowPasswordField();
        }
    }

    parentProfileChanged(profileModel) {
        if (!profileModel?.id || !profileModel?.value) {
            this.removeLinkedToParent();
        } else {
            this.addLinkedToParent({ id: profileModel.id, name: profileModel.value });
        }
        this.contactProfileForm?.get('user')?.get('linkedToParentProfileId')?.markAsDirty();
        this.userNameToEmailWarning = false;
    }

    private addLinkedToParent(profileModel: OptionModel) {
        if (!profileModel?.id) {
            return;
        }
        this.linkedToParentProfile = profileModel;
        const linkedToParentControl = this.contactProfileForm?.get('user')?.get('linkedToParentProfileId');
        if (linkedToParentControl?.value !== profileModel?.id) {
            linkedToParentControl?.setValue(profileModel?.id);
        }
        this.searchProfilesText$.next('');
        this.setShowPasswordField();
    }

    private removeLinkedToParent() {
        this.linkedToParentProfile = null;
        const linkedToParentControl = this.contactProfileForm?.get('user')?.get('linkedToParentProfileId');
        if (linkedToParentControl?.value) {
            linkedToParentControl?.setValue(null);
        }
        this.searchProfilesText$.next('');

        if (this.contactProfileForm.get('portalSignIn')?.value) {
            const userNameControl = this.contactProfileForm?.get('user')?.get('username');
            const emailValue = this.contactProfileForm?.get('emailAddress')?.value;
            if (userNameControl?.value !== emailValue) {
                userNameControl?.setValue(emailValue);
                userNameControl?.markAsTouched();
            }
        }
        this.contactProfileForm?.get('user')?.get('password')?.setValue('');
        this.setShowPasswordField();
    }

    refreshPossibleParentLinkedProfiles(checkEmailChanged = false) {
        if (checkEmailChanged) {
            if (this.emailChanged) {
                this.emailChanged = false;
            } else {
                return;
            }
        }
        const emailAddress = this.contactProfileForm?.get('emailAddress')?.value ?? '';
        if (!emailAddress) {
            return;
        }
        this._authService
            .searchParentLinkedProfiles(
                emailAddress,
                this.isSystemContact ? UserTypeEnum.SystemContact : UserTypeEnum.ClientContact,
                this.contact.user?.coreUserId
            )
            .pipe(takeUntil(this.d$))
            .subscribe((parentProfiles: OptionModel[]) => {
                this.linkedProfilesSourse = parentProfiles;
                this.searchProfilesText$.next('');
                if (!checkEmailChanged) {
                    const linkedProfileId = this.contactProfileForm?.get('user')?.get('linkedToParentProfileId')?.value;
                    const parentProfile = this.linkedProfilesSourse.find(
                        (s) => s.id.toLowerCase() === linkedProfileId?.toLowerCase()
                    );
                    if (parentProfile) {
                        this.addLinkedToParent(parentProfile);
                    } else {
                        this.removeLinkedToParent();
                    }
                }
            });
    }
}
