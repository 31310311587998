import { MenuService } from './services/menu.service';
import { MenuItemDirective } from 'src/app/core/app-side-nav/menu-item.directive';
import { NavMenuLinkComponent } from './app-side-nav/nav-menu-link.component';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TopNavComponent } from './app-top-nav/app-top-nav.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { CoreStoreFacade } from './state/core-store.facade';
import { EffectsModule } from '@ngrx/effects';
import { coreEffects } from './state/effects';
import { RouterModule } from '@angular/router';
import { AuthService } from './auth/services/auth.service';
import { SideNavMenuComponent } from './app-side-nav/side-nav-menu.component';
import { SideNavComponent } from './app-side-nav/app-side-nav.component';
import { AppSearchComponent } from './app-search/app-search.component';
import { ToasterService } from './services/toaster.service';
import { AppShellComponent } from './app-shell/app-shell.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { CoreRepositoryService } from './services/core-repository.service';
import { CoreService } from './services/core.service';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { AutoOpenMenuComponent } from 'src/app/shared/components/auto-open-menu/auto-open-menu-component';
import { AppRouteDowngradeComponent } from './app-route-downgrade/app-route-downgrade.component';
import { coreReducers } from 'src/app/core/state/reducers';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';
import { DowngradedComponentsModule } from 'src/app/core/downgraded-components.module';
import { ConnectAuthGuard } from 'src/app/core/auth.guard';
import { AppTopBrandingComponent } from 'src/app/core/app-top-branding/app-top-branding.component';
import { ConnectVendorCheckGuard } from './vendor-check.guard';

const COMPONENTS = [
    TopNavComponent,
    SideNavComponent,
    SideNavMenuComponent,
    NavMenuLinkComponent,
    AppSearchComponent,
    AppShellComponent,
    AppFooterComponent,
    ChangePasswordDialogComponent,
    AutoOpenMenuComponent,
    AppRouteDowngradeComponent,
    ForgotPasswordDialogComponent,
    AppTopBrandingComponent
];

const DIRECTIVES = [MenuItemDirective];

@NgModule({
    declarations: [...COMPONENTS, ...DIRECTIVES],
    imports: [
        RouterModule,
        SharedModule,
        HttpClientModule,
        DowngradedComponentsModule,
        StoreModule.forFeature('core', coreReducers),
        EffectsModule.forFeature(coreEffects)
    ],
    exports: [...COMPONENTS, ...DIRECTIVES],
    providers: [
        CoreStoreFacade,
        AuthService,
        MenuService,
        ToasterService,
        CoreRepositoryService,
        CoreService,
        ConnectAuthGuard,
        ConnectVendorCheckGuard
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('Core is already loaded.  Import it in the AppModule only');
        }
    }
}
