import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Unit, UnitFacilityInformation } from 'src/app/clinical/models';
import { Shift } from 'src/app/clinical/models/shift.model';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-shift-details',
    templateUrl: './shift-details.component.html',
    styleUrls: ['./shift-details.component.scss']
})
export class ShiftDetailsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @Output() valueChanged = new EventEmitter<{ unit: Partial<Unit>; isValid: boolean }>();

    shiftDetailsForm: UntypedFormGroup;
    timePlaceholder = '    :    ';
    timeMask = '00:00';

    get shiftDetails(): UntypedFormArray {
        return this.shiftDetailsForm.get('shiftDetails') as UntypedFormArray;
    }

    @Input() set unitInfo(unitInfo: UnitFacilityInformation) {
        if (!unitInfo || !unitInfo.unit) {
            return;
        }

        this.setFormValues(unitInfo);
        this.watchFormChanges();
        this.refreshFormEnableDisableState(this.readOnly);
    }

    @Input() readOnly = false;

    @Input() isPrinting: boolean = false;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _store: Store,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
        this.buildForm();
    }

    ngOnInit(): void {
    }

    buildForm(): void {
        this.shiftDetailsForm = this._formBuilder.group({
            shiftDetails: this._formBuilder.array([]),
            callRequired: this._formBuilder.control(null),
            floatRequired: this._formBuilder.control(null),
            weekendShiftPerContract: this._formBuilder.control(null),
            schedulingType: this._formBuilder.control(null),
            isCallRequired: this._formBuilder.control(null),
            isFloatRequired: this._formBuilder.control(null),
            weekendCoverageEveryOther: this._formBuilder.control(null),
            weekendCoverageEveryThird: this._formBuilder.control(null),
            schedulingTypeOther: this._formBuilder.control(null)
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            this.refreshFormEnableDisableState(changes.readOnly.currentValue);
        }
    }

    setFormValues(unitInfo: UnitFacilityInformation): void {
        unitInfo?.shiftDetails?.sort((a, b) => {
            return a.sort - b.sort;
        });

        unitInfo?.shiftDetails?.forEach((shiftDetail: Shift) => {
            const match = unitInfo?.unit?.shifts?.filter((s) => s.shiftDetailId === shiftDetail.id);
            let found = {} as Shift;
            let selected = false;
            if (match && match.length > 0) {
                found = match[0];
                selected = true;
            }

            const isOtherShift = shiftDetail.title === 'Other';
            const otherShiftDetailsExist =
                unitInfo.unit.shiftDetailsOther && unitInfo.unit.shiftDetailsOther?.length > 0;
            selected = isOtherShift ? true : selected;

            let isRotating = null;

            if (found.isRotating !== null && found.isRotating !== undefined && !isOtherShift) {
                isRotating = found.isRotating ? 'yes' : 'no';
            }

            if (isOtherShift && otherShiftDetailsExist) {
                isRotating = found.isRotating ? 'yes' : 'no';
            }

            this.shiftDetails.push(
                this._formBuilder.group({
                    id: this._formBuilder.control(shiftDetail.id),
                    title: this._formBuilder.control(shiftDetail.title),
                    selected: this._formBuilder.control(selected),
                    dayStartTime: this._formBuilder.control(found.dayStartTime),
                    dayEndTime: this._formBuilder.control(found.dayEndTime),
                    pmStartTime: this._formBuilder.control(found.pmStartTime),
                    pmEndTime: this._formBuilder.control(found.pmEndTime),
                    nocsStartTime: this._formBuilder.control(found.nocsStartTime),
                    nocsEndTime: this._formBuilder.control(found.nocsEndTime),
                    shiftDetailId: this._formBuilder.control(found.id),
                    shiftDetailsOther: this._formBuilder.control(unitInfo.unit.shiftDetailsOther),
                    isRotating: this._formBuilder.control(isRotating),
                    startTime: this._formBuilder.control(found.startTime),
                    endTime: this._formBuilder.control(found.endTime)
                })
            );
        });
        this.shiftDetailsForm.get('isCallRequired').setValue(unitInfo.unit.callRequired !== null);
        this.shiftDetailsForm.get('callRequired').setValue(unitInfo.unit.callRequired);

        this.shiftDetailsForm.get('isFloatRequired').setValue(unitInfo.unit.floatRequired !== null);
        this.shiftDetailsForm.get('floatRequired').setValue(unitInfo.unit.floatRequired);

        this.shiftDetailsForm.get('weekendCoverageEveryOther').setValue(unitInfo.unit.weekendCoverageEveryOther);
        this.shiftDetailsForm.get('weekendCoverageEveryThird').setValue(unitInfo.unit.weekendCoverageEveryThird);

        this.shiftDetailsForm.get('weekendShiftPerContract').setValue(unitInfo.unit.weekendShiftPerContract);
        this.shiftDetailsForm.get('schedulingType').setValue(unitInfo.unit.schedulingType);

        this.shiftDetailsForm.get('schedulingTypeOther').setValue(unitInfo.unit.schedulingTypeOther);
    }

    getFormValues(): Partial<Unit> {
        const formArray: UntypedFormArray = this.shiftDetailsForm.get('shiftDetails') as UntypedFormArray;
        const shiftDetails: Shift[] = [];
        let shiftDetailsOther = '';

        formArray.controls.forEach((shift) => {
            let otherSelected = false;
            if (shift.get('title').value === 'Other' && shift.get('shiftDetailsOther').value) {
                otherSelected = true;
                shiftDetailsOther = shift.get('shiftDetailsOther').value;
            }
            if (shift.get('selected').value === true || otherSelected) {
                shiftDetails.push({
                    id: shift.get('id').value,
                    title: shift.get('title').value ?? '',
                    selected: shift.get('selected').value ?? false,
                    dayStartTime: this.splice(shift.get('dayStartTime').value),
                    dayEndTime: this.splice(shift.get('dayEndTime').value),
                    nocsEndTime: this.splice(shift.get('nocsEndTime').value),
                    nocsStartTime: this.splice(shift.get('nocsStartTime').value),
                    pmEndTime: this.splice(shift.get('pmEndTime').value),
                    pmStartTime: this.splice(shift.get('pmStartTime').value),
                    shiftDetailId: shift.get('shiftDetailId').value,
                    shiftDetailsOther: shift.get('shiftDetailsOther').value ?? '',
                    isRotating: shift.get('isRotating').value === 'yes' ? true : false,
                    startTime: shift.get('startTime').value ?? '',
                    endTime: shift.get('endTime').value ?? ''
                });
            }
        });

        shiftDetails.forEach((item) => {
            item.shiftDetailId = item.id;
            delete item.id;
        });

        const unit: Partial<Unit> = {
            shifts: shiftDetails,
            callRequired:
                this.shiftDetailsForm.get('isCallRequired').value === true &&
                    this.shiftDetailsForm.get('callRequired').value === null
                    ? ''
                    : this.shiftDetailsForm.get('isCallRequired').value === false
                        ? null
                        : this.shiftDetailsForm.get('callRequired').value,

            floatRequired:
                this.shiftDetailsForm.get('isFloatRequired').value === true &&
                    this.shiftDetailsForm.get('floatRequired').value === null
                    ? ''
                    : this.shiftDetailsForm.get('isFloatRequired').value === false
                        ? null
                        : this.shiftDetailsForm.get('floatRequired').value,

            weekendCoverageEveryOther: this.shiftDetailsForm.get('weekendCoverageEveryOther').value,
            weekendCoverageEveryThird: this.shiftDetailsForm.get('weekendCoverageEveryThird').value,
            weekendShiftPerContract: this.shiftDetailsForm.get('weekendShiftPerContract').value,
            schedulingType: this.shiftDetailsForm.get('schedulingType').value,
            schedulingTypeOther: this.shiftDetailsForm.get('schedulingTypeOther').value,
            shiftDetailsOther
        };

        return unit;
    }

    limitEndTime(endTimeControl: AbstractControl) {
        if (endTimeControl.value) {
            const hours = endTimeControl.value.substring(0, 2);
            const minutes = endTimeControl.value.substring(2, 4);
            if (hours > 23 || (hours === 23 && minutes > 59)) {
                endTimeControl.setValue('23:59'); // Set end time to '23:59'
            }
        }
    }

    limitShiftEndTime() {
        const shiftDetailsArray = this.shiftDetailsForm.get('shiftDetails') as FormArray;
        shiftDetailsArray.controls.forEach((shiftDetail, index) => {
            this.limitEndTime(shiftDetail.get('dayEndTime'));
            this.limitEndTime(shiftDetail.get('pmEndTime'));
            this.limitEndTime(shiftDetail.get('nocsEndTime'));
        });
    }

    watchFormChanges(): void {
        this.shiftDetailsForm
            .get('isCallRequired')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((value) => {
                if (!value) {
                    this.shiftDetailsForm.get('callRequired').setValue(null);
                }
            });

        this.shiftDetailsForm
            .get('shiftDetails')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe(() => {
                this.limitShiftEndTime();
            });

        this.shiftDetailsForm
            .get('isFloatRequired')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((value) => {
                if (!value) {
                    this.shiftDetailsForm.get('floatRequired').setValue(null);
                }
            });

        this.shiftDetailsForm.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            if (value?.callRequired) {
                this.shiftDetailsForm.get('isCallRequired').setValue(true, { onlySelf: true });
            }

            if (value?.floatRequired) {
                this.shiftDetailsForm.get('isFloatRequired').setValue(true, { onlySelf: true });
            }

            this.refreshFormEnableDisableState(this.readOnly);

            this.valueChanged.emit({ unit: this.getFormValues(), isValid: this.shiftDetailsForm.valid });
        });
    }

    private splice(timeStamp: string): string {
        if (!timeStamp) {
            return '';
        }
        if (timeStamp.length === 4) {
            return `${timeStamp.slice(0, 2)}:${timeStamp.slice(2, 4)}`;
        } else {
            return timeStamp;
        }
    }

    private refreshFormEnableDisableState(isReadOnly: boolean): void {
        refreshDisableEnableState(this.shiftDetailsForm, isReadOnly);

        this.shiftDetails.controls.forEach((shiftDetailForm: UntypedFormGroup) => {
            const notSelectedOrOtherTitle =
                !shiftDetailForm.get('selected').value ||
                (shiftDetailForm.get('title').value === 'Other' && !shiftDetailForm?.get('shiftDetailsOther')?.value);

            refreshDisableEnableState(shiftDetailForm, isReadOnly || notSelectedOrOtherTitle, [
                'dayStartTime',
                'dayEndTime',
                'pmStartTime',
                'pmEndTime',
                'nocsStartTime',
                'nocsEndTime',
                'isRotating'
            ]);
        });
    }
}
