import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import { InvoicesState } from '../invoices.state';
import { exportInvoices, exportInvoicesSuccess, exportInvoicesFail } from '../invoices.actions';
import { of } from 'rxjs';
import { selectInvoicesQuery } from '../invoices.selectors';
import { Utilities } from 'src/app/core/utils';
import * as moment from 'moment';
import { ToasterService } from 'src/app/core/services';

@Injectable()
export class InvoicesExportEffect {
    exportInvoices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(exportInvoices),
            withLatestFrom(this.store$.select(selectInvoicesQuery)),
            switchMap(([action, query]) => {
                const filename = `Invoices ${moment().format('MMDDYYYY')}`;
                const timeZoneOffset = moment().utcOffset() / 60;

                return this.invoicesRepository.exportInvoices(action.invoiceIds, timeZoneOffset).pipe(
                    map((response) =>
                        exportInvoicesSuccess({
                            blob: response,
                            filename: filename
                        })
                    ),
                    catchError((error) => of(exportInvoicesFail({ error })))
                );
            })
        )
    );

    downloadInvoices$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(exportInvoicesSuccess),
                tap((action) => {
                    Utilities.downloadBlob(action.blob, action.filename);
                })
            ),
        { dispatch: false }
    );

    exportInvoicesFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(exportInvoicesFail),
                tap((action) => this.toaster.fail('Failed to export Invoices'))
            ),
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<InvoicesState>,
        private readonly invoicesRepository: InvoicesRepositoryService,
        private readonly toaster: ToasterService
    ) {}
}
