import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export function refreshDisableEnableState(
    formGroup: UntypedFormGroup,
    shouldBeDisabled: boolean,
    controlNames?: string[]
): void {
    if (!formGroup) {
        return;
    }

    const allControls = getFormGroupControls(formGroup);
    let matchingControls = allControls;
    if (controlNames?.length) {
        matchingControls = matchingControls.filter((control) =>
            controlNames.map((controlName) => controlName.toLowerCase()).includes(control.name.toLowerCase())
        );
    }

    matchingControls.forEach((matchingControl) => {
        const isAlreadyInDesiredState =
            (matchingControl.control.disabled && shouldBeDisabled) ||
            (matchingControl.control.enabled && !shouldBeDisabled);
        if (isAlreadyInDesiredState) {
            return;
        }

        shouldBeDisabled
            ? matchingControl.control.disable({ emitEvent: false })
            : matchingControl.control.enable({ emitEvent: false });
    });
}

function getFormGroupControls(
    formGroup: UntypedFormGroup,
    parentName = ''
): { name: string; control: AbstractControl }[] {
    let controls: { name: string; control: AbstractControl }[] = [];

    Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];
        const name = parentName ? `${parentName}.${key}` : key;

        if (control instanceof UntypedFormGroup) {
            controls = controls.concat(getFormGroupControls(control, name));
        } else if (control instanceof UntypedFormArray) {
            control.controls.forEach((formControl, index) => {
                if (formControl instanceof UntypedFormGroup) {
                    controls = controls.concat(getFormGroupControls(formControl, `${name}[${index}]`));
                } else {
                    controls.push({ name: `${name}[${index}]`, control: formControl });
                }
            });
        } else {
            controls.push({ name, control });
        }
    });

    return controls;
}
