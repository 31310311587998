import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as actions from '../actions/submittals-detail.actions';
import { SubmittalsService } from 'src/app/admin/submittals/services/submittals.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToasterService } from 'src/app/core/services';

@Injectable()
export class SubmittalsDetailEffects {
    loadSubmittalsDetail$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(
                actions.loadSubmittalsDetails,
                actions.updateSubmittalNoteSuccess,
                actions.updateStageHistoryEntrySuccess
            ),
            switchMap((action) => {
                const submittalId = action.submittalId;

                return this._submittalsService.getSubmittalDetails(submittalId).pipe(
                    map((response) => actions.loadSubmittalsDetailsSuccess({ details: response })),
                    catchError((error: unknown) => of(actions.loadSubmittalsDetailsFail({ error })))
                );
            })
        );
    });

    loadSubmittalStages$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadSubmittalStages),
            switchMap(() =>
                this._submittalsService.getSubmittalStages().pipe(
                    map((response) => actions.loadSubmittalStagesSuccess({ stages: response })),
                    catchError((error: unknown) => of(actions.loadSubmittalStagesFail({ error })))
                )
            )
        );
    });

    loadSubmittalStageReasons$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadSubmittalStageReasons),
            switchMap(() =>
                this._submittalsService.getSubmittalStageReasons().pipe(
                    map((response) => actions.loadSubmittalStageReasonsSuccess({ stageReasons: response })),
                    catchError((error: unknown) => of(actions.loadSubmittalStageReasonsFail({ error })))
                )
            )
        );
    });

    updateSubmittalNote$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.updateSubmittalNote),
            switchMap((action) =>
                this._submittalsService.updateSubmittalNote(action.submittalId, action.note).pipe(
                    map(() => actions.updateSubmittalNoteSuccess({ submittalId: action.submittalId })),
                    catchError((error: unknown) => of(actions.updateSubmittalNoteFailure({ error })))
                )
            )
        );
    });

    updateSubmittalNoteSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.updateSubmittalNoteSuccess),
                switchMap(() => this._toaster.success('Submittal note updated'))
            );
        },
        { dispatch: false }
    );

    updateSubmittalNoteFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.updateSubmittalNoteFailure),
                switchMap(() =>
                    this._toaster.success(
                        'Error updating submittal. There was a server error updating the submittal note, please try again'
                    )
                )
            );
        },
        { dispatch: false }
    );

    updateSubmittalStage$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.updateStageHistoryEntry),
            switchMap((action) =>
                this._submittalsService.updateSubmittalStage(action.entry).pipe(
                    map(() => actions.updateStageHistoryEntrySuccess({ submittalId: action.entry.submittalId })),
                    catchError((error: unknown) => of(actions.updateStageHistoryEntryFailure({ error })))
                )
            )
        );
    });

    updateSubmittalStageSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.updateStageHistoryEntrySuccess),
                switchMap(() => this._toaster.success('Stage successfully updated'))
            );
        },
        { dispatch: false }
    );

    updateSubmittalStageFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.updateStageHistoryEntryFailure),
                switchMap(() =>
                    this._toaster.success(
                        'Error updating submittal. There was a server error updating the submittal stage, please try again'
                    )
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store,
        private readonly _submittalsService: SubmittalsService,
        private readonly _toaster: ToasterService
    ) {}
}
