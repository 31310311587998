import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'ayac-add-offer-note-dialog',
    templateUrl: './add-offer-note-dialog.component.html',
    styleUrls: ['./add-offer-note-dialog.component.scss']
})
export class AddOfferNoteDialogComponent implements OnInit {
    form: UntypedFormGroup;

    constructor(
        private readonly _dialogRef: MatDialogRef<AddOfferNoteDialogComponent>,
        private readonly _formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            note: ['', [Validators.required]]
        });
    }

    addOfferNote(): void {
        const note = this.form.get('note').value;
        this._dialogRef.close({ note });
    }
}
