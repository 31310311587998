export enum AyaLeadsUrls {
    AyaLeadsList = '/AyaConnect/Client/DirectLeadApplications',
    AyaLeadsListExport = '/AyaConnect/Client/DirectLeadApplications/Export/Excel',
    AyaLeadsRejectApplicant = '/AyaConnect/Client/DirectLeadApplications/RejectAyaLeadApplicant',
    AyaLeadsApplicationDetails = '/AyaConnect/Client/DirectLeadApplications/{ayaLeadApplicationId}/details',
    AyaLeadsUpdateApplicationStatus = '/AyaConnect/Client/DirectLeadApplications/status',
    AyaLeadsResumeInfo = '/AyaConnect/Client/DirectLeadApplications/{candidateId}/resumeInfo/{facilityId}',
    AyaLeadsResume = '/AyaConnect/Client/DirectLeadApplications/{candidateId}/resume/{facilityId}',
    AyaLeadsResumePreview = '/AyaConnect/Client/DirectLeadApplications/{candidateId}/resume-preview/{facilityId}',
    AyaLeadsJobDetails = '/AyaConnect/Client/DirectLeadJobs/{jobId}',
    AyaLeadsJobApplicants = '/AyaConnect/Client/DirectLeadJobs/{jobId}/applicants',
    RecordAyaLeadsStatistics = '/AyaConnect/Report/ayaleads/statistics',
    AyaLeadsApplicationStatuses = '/AyaConnect/Client/AyaLeadApplicationStatus'
}
