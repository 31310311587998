import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TrackingStatus } from 'src/app/admin/jobs/tracking-status.type';
import { adminJobsActions } from 'src/app/admin/store/actions/admin-jobs.actions';
import {
    selectAdminReleaseGroupsWithOriginalOrder,
    selectMappedAdminJobsReleaseGroups,
    selectReleaseGroupsAreEmpty
} from 'src/app/admin/store/selectors/admin-jobs.selectors';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { ReleaseGroup } from 'src/app/shared/models/vendor-releases/release-group.model';

@Component({
    selector: 'aya-jobs-release-dialog',
    templateUrl: './jobs-release-dialog.component.html',
    styleUrls: ['./jobs-release-dialog.component.scss']
})
export class JobsReleaseDialogComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @ViewChildren(MatExpansionPanel) accordions: QueryList<MatExpansionPanel>;

    selectedJobs: number[] = [];
    isReleaseAllPositions: boolean;
    releaseGroups$: Observable<{ [key: string]: ReleaseGroup[] }>;
    isAllChecked$: Observable<boolean>;
    areReleaseGroupsEmpty$: Observable<boolean>;
    title: string;

    selectedCount = 0;

    trackingStatus: TrackingStatus = {
        count: 0,
        isChecked: {},
        isItemOpen: {},
        vendors: {}
    };

    private _releaseGroups: ReleaseGroup[];
    private readonly _destroy$ = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private readonly _data: { jobIds: number[]; facilityIds: number[]; isReleaseAllPositions: boolean },
        @Inject(DOCUMENT) private readonly _document: Document,
        private readonly _store: Store,
        private readonly _dialogRef: MatDialogRef<JobsReleaseDialogComponent>,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.selectedJobs = this._data.jobIds;
        this.isReleaseAllPositions = this._data.isReleaseAllPositions;
        this.releaseGroups$ = this._store.select(selectMappedAdminJobsReleaseGroups);
        this.areReleaseGroupsEmpty$ = this._store.select(selectReleaseGroupsAreEmpty);

        if (this.isReleaseAllPositions) {
            this.title = 'Release All Positions to...';
        } else {
            this.title = `Release 1 Position to...`;
        }

        this.loadReleaseGroups();
    }

    loadReleaseGroups() {
        this._store
            .select(selectAdminReleaseGroupsWithOriginalOrder)
            .pipe(
                filter((result) => result.length > 0),
                takeUntil(this._destroy$)
            )
            .subscribe((value) => {
                this._releaseGroups = value;

                this.trackingStatus = {
                    count: 0,
                    isChecked: {},
                    isItemOpen: {},
                    vendors: {}
                };

                value.forEach((item, index) => {
                    if (index === 0) {
                        this.trackingStatus.isItemOpen[item.id] = true;
                    }

                    item.vendors.forEach((vendor) => {
                        this.trackingStatus.vendors[vendor.id] = false;
                    });
                });
            });

        this._store.dispatch(adminJobsActions.loadReleaseGroupsByFacilities({ facilityIds: this._data.facilityIds }));
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    accordionOpened(index: number): void {
        if (this.accordions) {
            this.accordions.toArray().forEach((item, idx) => {
                if (idx !== index) {
                    item.close();
                } else {
                    item.open();

                    // Scroll to the top of the dialog
                    this._document.getElementById('dialogContent').scrollTop = 0;
                }
            });
        }
    }

    toggleVendor(vendorId: number, event: MatCheckboxChange): void {
        this.trackingStatus.vendors[vendorId] = event.checked;
        this._releaseGroups.forEach((group) => {
            this.trackingStatus.isChecked[group.id] = group.vendors.every(
                (item) => this.trackingStatus.vendors[item.id]
            );
        });

        this._updateCount();
    }

    toggleGroup(group: ReleaseGroup, event: MatCheckboxChange): void {
        this.trackingStatus.isChecked[group.id] = event.checked;
        group.vendors.forEach((item) => {
            this.trackingStatus.vendors[item.id] = event.checked;
        });

        this._updateCount();
    }

    releaseVendors(): void {
        this._dialogRef.close({ vendorIds: this._getSelectedVendors() });
    }

    releaseAllVendors(): void {
        this._dialogRef.close({ vendorIds: this._getSelectedVendors(true) });
    }

    private _getSelectedVendors(getAll = false) {
        const selectedVendors = [];

        Object.entries(this.trackingStatus.vendors).forEach(([key, value]) => {
            if ((value && !getAll) || getAll) {
                selectedVendors.push(Number(key));
            }
        });

        return selectedVendors;
    }

    private _updateCount(): void {
        this.selectedCount = 0;

        for (const vendorId in this.trackingStatus.vendors) {
            if (this.trackingStatus.vendors[vendorId]) {
                this.selectedCount++;
            }
        }
    }
}
