import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, debounceTime, filter } from 'rxjs/operators';
import {
    AppendMenu,
    CoreActionTypes,
    LoadMenuSuccess
} from '../core.actions';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { MenuItem } from 'src/app/core/models';

@Injectable()
export class CoreMenuEffects {
    addAIMarketingMenu$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(CoreActionTypes.LoadMenuSuccess),
            filter(() => this._identityService.isClientLogin() || this._identityService.isSystemLogin()),
            debounceTime(1),
            map((action: LoadMenuSuccess) => {
                const menuItem: MenuItem = {
                    id: 0,
                    applicationId: 0,
                    name: 'Workforce AI',
                    icon: 'svg_workforceai',
                    path: 'client/workforceai/landing',
                    children: null,
                    queryParams: null,
                    menuItemOrder: 8
                };

                return new AppendMenu(menuItem);
            })
        );
    });

    constructor(
        private readonly _actions$: Actions,
        private readonly _identityService: IdentityService,
    ) {}
}
