import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { SystemFormField } from 'src/app/core/models';
import * as fromCore from 'src/app/core/state';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Directive({
    selector: '[ifSystemFieldRequired]'
})
export class IsSystemFieldsRequiredDirective extends UnsubscribeOnDestroy implements OnInit {
    input$ = new BehaviorSubject<string>('');

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly store: Store<{}>
    ) {
        super();
    }

    ngOnInit() {        
        this.store.pipe(
            select(fromCore.getSystemFields),
            switchMap((settings) => 
                this.input$
                .pipe(
                    map((input) => {
                        const field = this.findField(settings, input);
                        return field && field.isRequired
                            ? true
                            : false;
                    })
                )
            ),
            distinctUntilChanged(),
            takeUntil(this.d$),
        ).subscribe((hasSettings) => {            
            if (hasSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        })
    }

    @Input()
    set ifSystemFieldRequired(val) {
        this.input$.next(val);
    }

    findField(fields: SystemFormField[], input: string) {
        if (!fields.length) {
            return null;
        }

        const [moduleName, fieldName] = input.split('.');
        const field = fields.find(s => {
            return s.moduleName === moduleName
                && s.fieldName === fieldName
        });

        return field;
    }
}
