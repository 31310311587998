import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumListToStringPipe'
})
export class EnumListToStringPipe implements PipeTransform {
    transform(value: number[], enumType: any, separator: string = ', '): unknown {
        return value?.map((enumValue) => this.enumValueToString(enumType[enumValue]))
            ?.filter((enumName) => enumName !== undefined)
            ?.join(separator);
    }

    private enumValueToString(enumName: string): string {
        return enumName?.split(/(?=[A-Z])/)
            .join()
            .replace(',', ' ');
    }

}
