import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { clinicalStoreKey } from './clinical.state';
import { clinicalEffects } from './effects';
import { clinicalReducers } from './reducers';

@NgModule({
    imports: [StoreModule.forFeature(clinicalStoreKey, clinicalReducers), EffectsModule.forFeature(clinicalEffects)]
})
export class ClinicalStoreModule {}
