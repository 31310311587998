import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { EMPTY, Observable, of } from 'rxjs';
import { ToasterService, ToasterStatus } from 'src/app/core/services';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { SharedFilterSearchPopOpenComponent } from 'src/app/shared/components/filter-search-pop-open/filter-search-pop-open.component';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { SortTypes } from 'src/app/shared/models';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { FacilityAdministrationLookups, FaciltyFeatureDropdown, FeatureFacility } from '../../models';
import { ContractGroupFeaturesService } from '../../services/contract-group-features.service';
import { FacilitiesService } from '../../services/facilities.service';
import { FeatureConfirmationConfiguration } from '../feature-confirmation-configuration';
import { FeatureConfirmationText } from 'src/app/facilities/models/feature-confirmation-text.model';
import { FacilityFeatureEnum } from 'src/app/shared/models/facilities';

@Component({
    selector: 'ayac-manage-features-presentation',
    templateUrl: './manage-features-presentation.component.html',
    styleUrls: ['./manage-features-presentation.component.scss']
})
export class ManageFeaturesPresentationComponent implements OnInit {
    @ViewChild('toggleFeatureDropdown', { static: false }) toggleFeatureDropdown: ElementRef;
    @ViewChild('dropdownComponent', { static: false }) dropdownComponent: ElementRef;
    @ViewChild(SharedFilterSearchPopOpenComponent) filterSearch: SharedFilterSearchPopOpenComponent;

    @Input() totalFacilities = 0;
    @Input() id = 0;

    facilities$: Observable<GridDataResult>;
    loadedFacilities = [];
    isLoading$?: Observable<boolean>;
    statesLookup$: Observable<ListItem>;
    lookups$: Observable<FacilityAdministrationLookups>;
    total = 0;
    enabledTotal = 0;
    featureEnabledId$: Observable<number | null>;
    requestQuery: GridSearchQuery;
    defaultSearchInputVal = 'Select Permission';
    searchInputVal = this.defaultSearchInputVal;
    isSearchInputVisible = false;
    permissions$: Observable<FaciltyFeatureDropdown>;
    searchTermSelected = '';
    featureIsEnabledVal = null;
    placeholderInputVals = {
        textValue: '',
        featureId: null,
        setToDefault: true
    };

    featureEnabledUpdateArr = [];
    featureConfirmationTexts = FeatureConfirmationConfiguration.facilityFeatureConfirmationTextConfiguration;
    enableDisableAll = false;
    canEditAll = false;
    bannerMessage: FeatureConfirmationText = {
        id: FacilityFeatureEnum.CandidateEvaluations,
        enabledText: '',
        disabledText: ''
    };

    seeMore = true;
    viewBanner = false;
    readonly featureFlag = FeatureFlag;

    constructor(
        private readonly _contractGroupFeaturesService: ContractGroupFeaturesService,
        private readonly _facilitiesService: FacilitiesService,
        private readonly _dialogService: DialogService,
        private readonly toasterService: ToasterService
    ) {}

    @HostListener('click', ['$event'])
    public toggleSearch(evt: Event): void {
        if (
            !this.toggleFeatureDropdown.nativeElement.contains(evt.target) &&
            !this.dropdownComponent.nativeElement.contains(evt.target)
        ) {
            this.isSearchInputVisible = false;
        }
    }

    ngOnInit(): void {
        this.requestQuery = {
            filter: { logic: 'and', filters: [] },
            sort: [{ field: 'name', dir: SortTypes.ASC }],
            take: 10,
            skip: 0
        };
        this.placeholderInputVals.textValue = this.searchInputVal;
        this.permissions$ = this._contractGroupFeaturesService.getFacilityFeatures();
        this.lookups$ = this._facilitiesService.getLookups();
    }

    loadGrid() {
        this._contractGroupFeaturesService.getFacilities(this.id, this.requestQuery).subscribe((resp) => {
            this.facilities$ = of(resp);
            this.loadedFacilities = resp.data;
            this.enableDisableAll = Object.values(resp.data).every((v) => v.featureIsEnabled) ? true : false;
            this.canEditAll = Object.values(resp.data).every((v) => v.canEdit) ? true : false;
            this.total = resp.total;
        });
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this.requestQuery = state;
        this.loadGrid();
    }

    searchInputAction(event) {
        if (event.target.className.includes('clear-text-value')) {
            event.stopPropagation();
            this.clearSearchInput();
        } else {
            this.isSearchInputVisible = !this.isSearchInputVisible;
            this.filterSearch.onBlur();
        }
    }

    selectionClick(feature: ListItem) {
        if (this.featureEnabledUpdateArr.length > 0) {
            const dialogRef = this._dialogService.openDialog(ConfirmationDialogComponent, {
                width: '800px',
                data: {
                    title: '',
                    text: `You have unsaved changes. Do you want to proceed?`,
                    confirmButtonText: 'Yes'
                }
            });
            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                if (confirmed) {
                    this.updateFeatureSelection(feature);
                }
            });
        } else {
            this.updateFeatureSelection(feature);
        }
    }

    updateFeatureSelection(feature: ListItem) {
        const state = this.updateState(this.requestQuery, 'featureId', feature.id);
        this.isSearchInputVisible = false;
        this.placeholderInputVals = {
            setToDefault: false,
            featureId: feature.id,
            textValue: feature.name
        };
        this.searchTermSelected = feature.name;
        this.onDataStateChange(state);
        this.clearFeatureUpdates();
        this.getEnabledTotal();
        this.bannerMessage = this.featureConfirmationTexts.find((ct) => ct.id === this.placeholderInputVals.featureId);
    }

    getEnabledTotal() {
        this._contractGroupFeaturesService
            .getFeatureEnabledTotal(this.placeholderInputVals.featureId, this.id)
            .subscribe({
                next: (resp: number) => {
                    this.enabledTotal = resp;
                },
                error: () => {
                    this.enabledTotal = 0;
                }
            });
    }

    isFeatureEnabled(dataItem: FeatureFacility) {
        return this.featureEnabledUpdateArr.some((x) => x.id === dataItem.id)
            ? this.featureEnabledUpdateArr.some((x) => x.id === dataItem.id && x.isEnabled === true)
            : dataItem.featureIsEnabled;
    }

    featureEnabledChange(isEnabled: boolean) {
        if (isEnabled !== null) {
            const state = this.updateState(this.requestQuery, 'isEnabled', isEnabled);
            this.onDataStateChange(state);
        } else {
            const query = this.requestQuery;
            const cleanedFilters = query.filter.filters.filter((obj) => {
                return obj['field'] !== 'isEnabled';
            });
            query.filter.filters = cleanedFilters;
            this.onDataStateChange(query as GridStateChangeEvent);
        }
    }

    updateState(state, field: string, value) {
        if (state.filter.filters.find((s) => s.field === field)) {
            const updatedFilters = state.filter.filters.map((filter) => {
                if (filter.field === field) {
                    return { ...filter, value };
                }
                return filter;
            });
            state.filter.filters = updatedFilters;
        } else {
            state.filter.filters.push({
                field,
                operator: 'and',
                value
            });
        }
        return state;
    }

    checkboxClick(event: MatCheckboxChange, item: FeatureFacility) {
        const i = this.featureEnabledUpdateArr.findIndex((e) => e.id === item.id);
        if (i > -1) {
            this.featureEnabledUpdateArr.splice(i, 1);
        } else {
            this.featureEnabledUpdateArr.push({ id: item.id, isEnabled: event.checked });
        }
        this.save();
    }

    save() {
        const options = {
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
        };
        this._contractGroupFeaturesService
            .putFacilityFeature(this.placeholderInputVals.featureId, this.featureEnabledUpdateArr)
            .subscribe({
                next: () => {
                    this.toasterService.open('Permissions updated successfully!', null, ToasterStatus.success, options);
                    this.reset();
                    this.getEnabledTotal();
                },
                error: (err: HttpErrorResponse) => {
                    this.toasterService.open(
                        `Error updating permissions: ${err.message}`,
                        null,
                        ToasterStatus.fail,
                        options
                    );
                }
            });
    }

    reset() {
        this.clearFeatureUpdates();
        this.enableDisableAll = false;
        this.facilities$ = EMPTY;
        this.loadGrid();
    }

    openConfirmationDialog() {
        const dialogRef = this._dialogService.openDialog(ConfirmationDialogComponent, {
            width: '800px',
            data: {
                title: 'Confirm Changes',
                text: `You are making changes for ${this.featureEnabledUpdateArr.length} facilities. Click Save to Continue.`,
                confirmButtonText: 'Save'
            }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.save();
            } else {
                this.reset();
            }
        });
    }

    clearSearchInput() {
        this.placeholderInputVals = {
            setToDefault: true,
            featureId: null,
            textValue: this.searchInputVal
        };
        this.requestQuery.filter.filters = [];
        this.searchTermSelected = '';
        this.featureIsEnabledVal = null;
        this.facilities$ = EMPTY;
        this.total = 0;
        this.bannerMessage = null;
    }

    clearFeatureUpdates() {
        this.featureEnabledUpdateArr = [];
    }

    colSaveToggle(event: MatCheckboxChange) {
        this.featureEnabledUpdateArr = [];
        const isEnabled = event.checked;
        const facilitiesList = this.loadedFacilities.filter((f) => f.featureIsEnabled === !isEnabled);
        facilitiesList.forEach((f) => this.featureEnabledUpdateArr.push({ id: f.id, isEnabled }));

        this.facilities$.subscribe((facilities) => {
            facilities.data.forEach((f) => (f.featureIsEnabled = isEnabled));
        });
        this.openConfirmationDialog();
    }

    canEnableDisable() {
        return !this.total || !this.canEditAll;
    }

    toggleBannerMessage() {
        this.seeMore = !this.seeMore;
    }
}
