import { createAction, props } from '@ngrx/store';
import { FacilitySystem } from 'src/app/facilities/models/facility-system.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { PagingToken } from 'src/app/shared/models/paging-token';

export const loadFacilitySystemsList = createAction('[FACILITIES SYSTEMS COMPONENT] Load Facilities Systems List');
export const loadFacilitySystemsListSuccess = createAction(
    '[FACILITIES SYSTEMS API] Load Facilities Systems List Success',
    props<{ systems: PagingToken<FacilitySystem[]> }>()
);
export const loadFacilitySystemsListFailure = createAction(
    '[FACILITIES SYSTEMS API] Load Facilities Systems List Failure',
    props<{ error: unknown }>()
);
export const setFacilitySystemsListSearchQuery = createAction(
    '[FACILITIES SYSTEMS COMPONENT] Set Facilities Systems Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);
export const clearFacilitySystemsList = createAction('[FACILITIES SYSTEMS COMPONENT] Clear Facilities Systems List');
