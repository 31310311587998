import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Role } from 'src/app/core/auth/models/auth';
import { DomainService } from 'src/app/shared/services/domain.service';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities';
import { ConnectPortalUser } from 'src/app/core/auth/models/connect-portal-user.model';
import { UserSecurity } from 'src/app/core/auth/models/user-security.model';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { OptionModel } from 'src/app/shared/models/option.model';
import { UserTypeEnum } from 'src/app/shared/models';

@Injectable()
export class AuthService {
    OAuthSignedIn$ = new BehaviorSubject(false);
    private readonly BASE_URL: string;
    constructor(
        private readonly http: HttpClient,
        private readonly injector: Injector,
        @Inject(APP_CONFIG) settings: Settings
    ) {
        this.BASE_URL = settings.CORE;
    }

    getTermsAndConditions(): Observable<unknown> {
        return this.http.get(`${this.BASE_URL}/ayaconnect/auth/acceptdata`, { responseType: 'text' });
    }

    acceptTermsAndConditions(): Observable<unknown> {
        return this.http.put(`${this.BASE_URL}/ayaconnect/auth/acceptdata`, {});
    }

    logout() {
        // TODO: Replace old identity
        const identity = this.injector.get(IdentityService);
        identity.delete();
        this.toggleOAuthSignIn(false);
    }

    getUserName() {
        // TODO: Replace old identity
        const identity = this.injector.get(IdentityService);
        return identity.userName;
    }

    //This is the data Connect UI originally parsed from the legacy Connect API token. It exists to maintain legacy Connect UI application parts.
    //We will need to review this call after we finish the migration to the new Angular. Most likely we will be able to remove this endpoint.
    requestPortalUserInfo(): Observable<ConnectPortalUser> {
        return this.http.get<ConnectPortalUser>(`${this.BASE_URL}/ayaconnect/auth/ticket`);
    }

    getSecurityList(): Observable<UserSecurity[]> {
        return this.http.get<UserSecurity[]>(`${this.BASE_URL}/api/securitygroup/GetSecurityList`);
    }

    resetPassword(currentPassword: string, newPassword: string) {
        // TODO: Replace old identity
        const identity = this.injector.get(IdentityService);
        const payload = {
            UserName: identity.userName,
            OldPassword: currentPassword,
            NewPassword: newPassword
        };
        return identity.changePassword(payload);
    }

    forgotPassword(username: string, brand: string): Observable<string> {
        const url = `${this.BASE_URL}/connect/emailpasswordresetinstructions?brand=${brand}`;
        return this.http.post<string>(url, null, { headers: { userEmail: username } });
    }

    getResetTokenCore(username: string, password: string): Observable<unknown> {
        return this.http.post(`${this.BASE_URL}/connect/passwordresettoken`, { userName: username, password });
    }

    openCorpDirectory() {
        const identity = this.injector.get(IdentityService);
        const domain = this.injector.get(DomainService);
        const intranetUrl = domain.getUrl('INTRANET');
        const path = `${intranetUrl}/CorporateDirectory/Home/Index?CompanyID=0&AuthorizedUserID=${identity.oldUserId}`;

        window.open(path, '_blank');
    }

    getPortalRoles(moduleId: number): Observable<Role[]> {
        let url = combine(this.BASE_URL, 'AyaConnect', 'Auth', 'portal-roles');
        url += `?moduleId=${moduleId}`;

        return this.http.get<Role[]>(url);
    }

    validateAndStoreAccessData(connectAccessInfo: string): void {
        if (!connectAccessInfo) {
            return;
        }

        const identity = this.injector.get(IdentityService);
        identity.parseUserInformation(connectAccessInfo);

        const isSignedIn = identity.isSignedIn();

        if (isSignedIn) {
            identity.saveConnectUserInfoToStorage(connectAccessInfo);
        }
    }

    recordLoginAttempt(isSucceed: boolean, username: string): Observable<unknown> {
        return this.http.post(`${this.BASE_URL}/ayaconnect/auth/log-access`, { isSucceed, username });
    }

    searchParentLinkedProfiles(email: string, userType: UserTypeEnum, userId: string): Observable<OptionModel[]> {
        if (!email) {
            return of([]);
        }

        const queryUserId = userId ? `&userId=${userId}` : '';
        const option = { headers: { email } };
        return this.http.get<OptionModel[]>(
            `${this.BASE_URL}/ayaconnect/auth/profiles/parent/search?userType=${userType}${queryUserId}`,
            option
        );
    }

    private toggleOAuthSignIn(value: boolean): void {
        const obsValue = this.OAuthSignedIn$.value;
        if (obsValue === value) {
            return;
        }

        this.OAuthSignedIn$.next(value);
    }
}
