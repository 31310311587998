/**angular */
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

/**rxjs */
import { Observable } from 'rxjs';

/**shared */
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { PaginationRequest } from 'src/app/manage-permissions/models';
import { SortTypes } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { getStringQueryFromGridSearch } from 'src/app/shared/utilities/filter-descriptor-utility';

/**local */
import { ShiftAttachment } from 'src/app/shifts/models';
import { ShiftListTableItem } from 'src/app/vendor-perdiemscheduler/models/shift-list-table-item.model';
import { ShiftSubmittalCandidate } from 'src/app/vendor-perdiemscheduler/models/shift-submittal-candidate.model';
import { PerdiemSchedulerUrl } from '../models/enum/perdiemscheduler-url.enum';
import { PerdiemCandidateResponse } from '../models/responses/perdiem-candidate.response';
import { ShiftsFilteredResponse } from '../models/responses/shifts-filtered.response';
import { ShiftList } from '../models/shift-list.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';

@Injectable()
export class PerdiemSchedulerService {
    private readonly _viewTypeId = 4;
    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    getShifts(
        pagination: {
            take: number;
            skip: number;
        },
        sortArgs: {
            sortField: string;
            sortType: SortTypes;
        },
        filters: any[]
    ): Observable<ShiftsFilteredResponse> {
        const url = PerdiemSchedulerUrl.VENDOR_SHIFTS_GRID;
        const params = {
            take: pagination.take,
            skip: pagination.skip,
            sort: [
                {
                    field: sortArgs.sortField,
                    dir: sortArgs.sortType
                }
            ],
            filter: {
                logic: 'and',
                filters
            }
        };

        const queryParams = getStringQueryFromGridSearch(params as GridSearchQuery);
        return this.http.get<ShiftsFilteredResponse>(`${this._settings.CORE}${url}${queryParams}`);
    }

    getNurseProfessions(): Observable<ListItem[]> {
        return this.http.get<ListItem[]>(`${this._settings.CORE}${PerdiemSchedulerUrl.VENDOR_PROFESSIONS}`);
    }

    getNurseSpecialties(professionId: number): Observable<ListItem[]> {
        const url = PerdiemSchedulerUrl.VENDOR_SPECIALTIES.replace('{id}', professionId.toString());

        return this.http.get<ListItem[]>(`${this._settings.CORE}${url}`);
    }

    getVendorFacilities(candidateId?: number): Observable<ListItem[]> {
        const url = PerdiemSchedulerUrl.VENDOR_FACILITIES;
        const params = {
            viewTypeId: this._viewTypeId,
            candidateId
        };

        return this.http.post<ListItem[]>(`${this._settings.CORE}${url}`, params);
    }

    getCandidates(
        professionId: number,
        specialtyId: number,
        facilityId: number,
        seriesId: number,
        shiftId?: number
    ): Observable<PerdiemCandidateResponse> {
        const url = PerdiemSchedulerUrl.VENDOR_CANDIDATES;
        const params = {
            viewTypeId: this._viewTypeId,
            professionId,
            specialtyId,
            facilityId,
            seriesId,
            shiftId
        };
        return this.http.post<PerdiemCandidateResponse>(`${this._settings.CORE}${url}`, params);
    }

    bookCandidate(candidateId: number, shiftId: number, seriesId: number): Observable<ShiftList> {
        return this.http.post<ShiftList>(`${this._settings.CORE}${PerdiemSchedulerUrl.BOOK_CANDIDATE}`, {
            viewTypeId: this._viewTypeId,
            candidateId,
            shiftId,
            seriesId
        });
    }

    getCancelReasons(): Observable<string[]> {
        return this.http.post<string[]>(`${this._settings.CORE}${PerdiemSchedulerUrl.VENDOR_CANCEL_REASONS}`, {
            viewTypeId: this._viewTypeId
        });
    }

    removeCandidate(cancelReason: string, shiftId: number, seriesId: number): Observable<ShiftList> {
        return this.http.post<ShiftList>(`${this._settings.CORE}${PerdiemSchedulerUrl.REMOVE_CANDIDATE}`, {
            viewTypeId: this._viewTypeId,
            seriesId,
            shiftId,
            cancelReason
        });
    }

    /* Shift Attachments */
    getShiftAttachments(shiftId: number): Observable<ShiftAttachment[]> {
        return this.http.get<ShiftAttachment[]>(
            `${this._settings.CORE}${PerdiemSchedulerUrl.CLIENT_SHIFTS}/${shiftId}/Attachments`
        );
    }

    getShiftsWithCandidates(request: PaginationRequest): Observable<PagingToken<ShiftListTableItem[]>> {
        return this.http.get<PagingToken<ShiftListTableItem[]>>(
            `${this._settings.CORE}/AyaConnect/PerDiemScheduler/VendorShifts?${JSON.stringify(request)}`
        );
    }

    getShiftCandidates(shiftId: number): Observable<ShiftSubmittalCandidate[]> {
        return this.http.get<ShiftSubmittalCandidate[]>(
            `${this._settings.CORE}/AyaConnect/vendors/shifts/${shiftId}/submittals`
        );
    }
    getPerdiemSchedulerDefaultDateFrom(): Observable<Date> {
        return this.http.get<Date>(`${this._settings.CORE}/AyaConnect/PerDiemScheduler/FromDateVendorShifts`);
    }
}
