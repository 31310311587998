import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../services/dashboard.service';
import { withLatestFrom, catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getFilter, getPendingSubmittalsAgingDays } from '../selectors';
import { loadPendingSubmittals, loadPendingSubmittalsFail, loadPendingSubmittalsSuccess } from '../actions/pending-submittals.actions';

@Injectable()
export class PendingSubmittalsEffect {

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<{}>,
        private readonly service: DashboardService
    ) { }

    getPendingSubmittals$ = createEffect(() => this.actions$.pipe(
        ofType(loadPendingSubmittals),
        withLatestFrom(this.store$.select(getFilter), this.store$.select(getPendingSubmittalsAgingDays)),
        switchMap(([action, query, agingDays]) => {
            const filter = {
                lineOfBusinessId: query.lineOfBusiness.id > 0 ? query.lineOfBusiness.id : null,
                facilityId: query.facility.id > 0 ? query.facility.id : null,
                unitId: query.unit.id > 0 ? query.unit.id : null,
                shiftTypeId: query.shiftType.id > 0 ? query.shiftType.id : null,
                agingDays: agingDays
            };

            return this.service.getPendingSubmittals(filter).pipe(
                map(response =>
                    loadPendingSubmittalsSuccess({
                        total: response.total,
                        agingCount: response.agingCount,
                    })
                ),
                catchError((error) => of(
                    loadPendingSubmittalsFail({error})
                ))
            );
        })
    ));
}
