export interface PagingToken<T> {
    total: number;
    data: T;
    currPage?: string;
    nextPage?: string;
    filtered?: number;
}

export const getEmptyPagingToken = <T>(): PagingToken<T[]> => ({
    data: [],
    total: 0,
    currPage: '',
    nextPage: '',
    filtered: 0
})
