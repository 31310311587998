import { createAction, props } from '@ngrx/store';
import { FacilityAdministration } from 'src/app/facilities/models/facility-administration.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { PagingToken } from 'src/app/shared/models/paging-token';

export enum CopyReleaseSettingsToModalActionEnum {
    LoadCopyReleaseSettingsToModal = '[CopyReleaseSettingsToModal] Load Facilities List',
    LoadCopyReleaseSettingsToModalSuccess = '[CopyReleaseSettingsToModal] Load Facilities List Success',
    LoadCopyReleaseSettingsToModalFailure = '[CopyReleaseSettingsToModal] Load Facilities List Failure',
    SetCopyReleaseSettingsToModalSearchQuery = '[CopyReleaseSettingsToModal] Set Facilities List Search Query',
    ClearCopyReleaseSettingsToModal = '[CopyReleaseSettingsToModal] Clear Facilities List',
}

export const loadCopyReleaseSettingsToModal = createAction(CopyReleaseSettingsToModalActionEnum.LoadCopyReleaseSettingsToModal, props<{ currentFacilityId: number }>());
export const loadCopyReleaseSettingsToModalSuccess = createAction(CopyReleaseSettingsToModalActionEnum.LoadCopyReleaseSettingsToModalSuccess, props<{ facilities: PagingToken<FacilityAdministration[]> }>())
export const loadCopyReleaseSettingsToModalFailure = createAction(CopyReleaseSettingsToModalActionEnum.LoadCopyReleaseSettingsToModalFailure, props<{ error: any }>())
export const setCopyReleaseSettingsToModalSearchQuery = createAction(CopyReleaseSettingsToModalActionEnum.SetCopyReleaseSettingsToModalSearchQuery, props<{ searchQuery: GridSearchQuery }>())
export const clearCopyReleaseSettingsToModal = createAction(CopyReleaseSettingsToModalActionEnum.ClearCopyReleaseSettingsToModal)
