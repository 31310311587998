import { createAction, props } from '@ngrx/store';
import {
    FacilityVendorItem,
    FacilityVendorLookups,
    FacilityVendorStatusHistory,
    FacilityVendorStatusHistoryNote,
    FacilityVendorStatusUpdate,
    FacilityVendorTSUpdate
} from 'src/app/facilities/models/facility-vendor.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';

export const loadFacilityVendorsGrid = createAction(
    '[FACILITY VENDORS GRID COMPONENT] Load Vendors Grid',
    props<{ facilityId: number; isActive: boolean }>()
);
export const loadFacilityVendorsGridSuccess = createAction(
    '[FACILITY VENDORS GRID API] Load Vendors Grid Success',
    props<{
        vendors: FacilityVendorItem[];
        total: number;
        filtered: number;
    }>()
);
export const loadFacilityVendorsGridFailure = createAction(
    '[FACILITY VENDORS GRID API] Load Vendors Grid Failure',
    props<{ error: any }>()
);

export const loadFacilityVendorIds = createAction(
    '[FACILITY VENDORS COMPONENT] Load Facility Vendor Ids',
    props<{ facilityId: number; isActive: boolean }>()
);
export const loadFacilityVendorIdsSuccess = createAction(
    '[FACILITY CONTROLLER API] Load Facility Vendor Ids Success',
    props<{ ids: number[] }>()
);
export const loadFacilityVendorIdsFailure = createAction(
    '[FACILITY CONTROLLER API] Load Facility Vendor Ids Failure',
    props<{ error: unknown }>()
);

export const setFacilityVendorsSearchQuery = createAction(
    '[FACILITY VENDORS GRID COMPONENT] Set Vendors Grid Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);

export const offerFacilityVendors = createAction('[FACILITY VENDORS OFFER COMPONENT] Offer Facility Vendors');
export const offerFacilityVendorsSuccess = createAction(
    '[FACILITY VENDORS OFFER SUCCESS API] Offer Facility Vendors Success'
);
export const offerFacilityVendorsFailure = createAction(
    '[FACILITY VENDORS OFFER FAILURE API] Offer Facility Vendors Failure'
);

export const excludeFacilityVendors = createAction('[FACILITY VENDORS EXCLUDE COMPONENT] Exclude Facility Vendors');
export const excludeFacilityVendorsSuccess = createAction(
    '[FACILITY VENDORS EXCLUDE SUCCESS API] Exclude Facility Vendors Success'
);
export const excludeFacilityVendorsFailure = createAction(
    '[FACILITY VENDORS EXCLUDE FAILURE API] Exclude Facility Vendors Failure'
);

export const acceptedFacilityVendors = createAction('[FACILITY VENDORS ACCEPTED COMPONENT] Accepted Facility Vendors');
export const acceptedFacilityVendorsSuccess = createAction(
    '[FACILITY VENDORS ACCEPTED SUCCESS API] Accepted Facility Vendors Success'
);
export const acceptedFacilityVendorsFailure = createAction(
    '[FACILITY VENDORS ACCEPTED FAILURE API] Accepted Facility Vendors Failure'
);

export const tsSignedFacilityVendors = createAction('[FACILITY VENDORS TSSIGNED COMPONENT] TsSigned Facility Vendors');
export const tsSignedFacilityVendorsSuccess = createAction(
    '[FACILITY VENDORS TSSIGNED SUCCESS API] TsSigned Facility Vendors Success'
);
export const tsSignedFacilityVendorsFailure = createAction(
    '[FACILITY VENDORS TSSIGNED FAILURE API] TsSigned Facility Vendors Failure'
);

export const exportFacilityVendors = createAction('[FACILITY VENDORS EXPORT COMPONENT] Export Facility Vendors');
export const exportFacilityVendorsSuccess = createAction(
    '[FACILITY VENDORS EXPORT SUCCESS API] Export Facility Vendors Success'
);
export const exportFacilityVendorsFailure = createAction(
    '[FACILITY VENDORS EXPORT FAILURE API] Export Facility Vendors Failure'
);

export const loadVendorLookups = createAction('[FACILITY VENDORS LOOKUPS] Facility Vendors Lookup');
export const loadVendorLookupsSuccess = createAction(
    '[FACILITY VENDORS LOOKUPS SUCCESS API] Facility Vendors Lookups Success',
    props<{ lookups: FacilityVendorLookups }>()
);
export const loadVendorLookupsFailure = createAction(
    '[FACILITY VENDORS LOOKUPS FAILURE API] Facility Vendors Lookups Failure',
    props<{ error: any }>()
);

export const updateTSSigned = createAction(
    '[FACILITY VENDORS TSSIGNED UPDATE COMPONENT] Update CTS Signed',
    props<{ facilityId: number; params: FacilityVendorTSUpdate; isActive: boolean }>()
);
export const updateTSSignedSuccess = createAction(
    '[FACILITY VENDORS TSSIGNED UPDATE SUCCESS API] Update CTS Signed Success',
    props<{ facilityId: number; isActive: boolean }>()
);
export const updateTSSignedFailure = createAction(
    '[FACILITY VENDORS TSSIGNED UPDATE FAILURE API] Update CTS Signed Failure',
    props<{ error: any }>()
);

export const exportAll = createAction(
    '[FACILITY VENDORS Export ALL COMPONENT] Export All Request',
    props<{ facilityId: number; isActive: boolean }>()
);
export const exportAllSuccess = createAction('[FACILITY VENDORS Export ALL SUCCESS API] Export All Success');
export const exportAllFailure = createAction(
    '[FACILITY VENDORS Export AlALLl FAILURE API] Export All Failure',
    props<{ error: any }>()
);

export const exportSelected = createAction(
    '[FACILITY VENDORS EXPORT SELECTED COMPONENT] Export Selected Request',
    props<{ facilityId: number; vendorIds: number[] }>()
);
export const exportSelectedSuccess = createAction(
    '[FACILITY VENDORS EXPORT SELECTED SUCCESS API] Export Selected Success'
);
export const exportSelectedFailure = createAction(
    '[FACILITY VENDORS EXPORT SELECTED FAILURE API] Export Selected Failure',
    props<{ error: any }>()
);

export const updateVendorStatus = createAction(
    '[FACILITY VENDORS UPDATE STATUS COMPONENT] Update Vendor Facility Status Request',
    props<{ facilityId: number; params: FacilityVendorStatusUpdate; isActive: boolean }>()
);
export const updateVendorStatusSuccess = createAction(
    '[FACILITY VENDORS UPDATE STATUS SUCCESS API] Update Vendor Facility Status Success',
    props<{ facilityId: number; isActive: boolean }>()
);
export const updateVendorStatusFailure = createAction(
    '[FACILITY VENDORS UPDATE STATUS FAILURE API] Update Vendor Facility Status Failure',
    props<{ error: any }>()
);

export const clearVendorFilters = createAction(
    '[FACILITY VENDORS Clear Filters COMPONENT] Facility Vendors Clear Filters'
);
export const refreshVendorsGrid = createAction(
    '[FACILITY VENDORS Refresh Grid COMPONENT] Facility Vendors Refresh Grid'
);

export const getVendorStatusHistory = createAction(
    '[FACILITY VENDORS STATUS HISTORY COMPONENT] Vendor Status History Request',
    props<{ facilityId: number; vendorId: number }>()
);
export const getVendorStatusHsitorySuccess = createAction(
    '[FACILITY VENDORS STATUS HISTORY SUCCESS API] Vendor Status History Success',
    props<{ history: FacilityVendorStatusHistory[] }>()
);
export const getVendorStatusHsitoryFailure = createAction(
    '[FACILITY VENDORS STATUS HISTORY FAILURE API] Vendor Status History Failure',
    props<{ error: any }>()
);

export const saveVendorStatusHsitory = createAction(
    '[FACILITY VENDORS SAVE STATUS HISTORY COMPONENT] Save Vendor Status History Request',
    props<{ facilityId: number; vendorId: number; params: FacilityVendorStatusHistoryNote }>()
);
export const saveVendorStatusHsitorySuccess = createAction(
    '[FACILITY VENDORS SAVE STATUS HISTORY SUCCESS API] Save Vendor Status History Success'
);
export const saveVendorStatusHsitoryFailure = createAction(
    '[FACILITY VENDORS SAVE STATUS HISTORY FAILURE API] Save Vendor Status History Failure',
    props<{ error: any }>()
);

export const updateVendorStatusHistory = createAction(
    '[FACILITY VENDORS UPDATE STATUS HISTORY COMPONENT] Update Vendor Status History Request',
    props<{ facilityId: number; vendorId: number; params: FacilityVendorStatusHistoryNote }>()
);
export const updateVendorStatusHistorySuccess = createAction(
    '[FACILITY VENDORS UPDATE STATUS HISTORY SUCCESS API] Update Vendor Status History Success'
);
export const updateVendorStatusHistoryFailure = createAction(
    '[FACILITY VENDORS UPDATE STATUS HISTORY FAILURE API] Update Vendor Status History Failure',
    props<{ error: any }>()
);
export const setFacilityVendorSelectAll = createAction(
    '[FACILITY VENDORS SELECT ALL ACTION] Set Select Alll for grid',
    props<{ facilityId: number; isActive: boolean; isAllSelected: boolean }>()
);

export const resetSearchQueryPagination = createAction('[FACILITY VENDORS GRID COMPONENT] Reset Pagination');
