export interface FacilityMarketingReviewQueue {
    facilityMarketingReviewQueueId?: number;
    facilityProfileGroupId?: number;
    overview: string;
    facilityMarketingReviewQueueStatusId?: FacilityMarketingReviewQueueStatus;
    createdById?: string;
    createdDateUTC?: Date;
    updatedById?: string;
    updatedDateUTC?: Date;
}

export enum FacilityMarketingReviewQueueStatus {
    Draft = 1,
    PendingApproval = 2,
    Approved = 3
}

export interface FacilityMarketingReviewQueueGridItem {
    facilityMarketingReviewQueueId?: number;
    facilityProfileGroupId?: number;
    requesterName?: string;
    requesterEmail?: string;
    facilityId: number;
    facilityName: string;
    facilityUrl: string;
    msp: boolean;
    facilityMarketingReviewQueueStatusId?: FacilityMarketingReviewQueueStatus;
    approvedBy?: string;
    approvedDate?: Date | string;
}
