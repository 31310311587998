import { createAction, props } from '@ngrx/store';
import { ConnectFacilityFeature } from '../../models';

export const enum FacilityFacilityActionEnum {
    ClearFacilityFeaturesState = '[Facilities API] Clear Facility Features State',

    LoadConnectFacilityFeatures = '[Facilities Component] Load Connect Facility Features',
    LoadConnectFacilityFeaturesSuccess = '[Facilities API] Load Connect Facility Features Success',
    LoadConnectFacilityFeaturesFailure = '[Facilities API] Load Connect FacilityFeatures Failure',

    UpdateFacilityFeature = '[Facilities Component] Update Connect Facility Feature',
    UpdateFacilityFeatureSuccess = '[Facilities API] Update Connect Facility Facility Feature Success',
    UpdateFacilityFeatureFailure = '[Facilities API] Update Connect Facility Facility Feature Failure'
}
export const clearFacilityFeatures = createAction(FacilityFacilityActionEnum.ClearFacilityFeaturesState);

export const loadConnectFacilityFeatures = createAction(
    FacilityFacilityActionEnum.LoadConnectFacilityFeatures,
    props<{ facilityId: number }>()
);
export const loadConnectFacilityFeaturesFailure = createAction(
    FacilityFacilityActionEnum.LoadConnectFacilityFeaturesFailure
);
export const loadConnectFacilityFeaturesSuccess = createAction(
    FacilityFacilityActionEnum.LoadConnectFacilityFeaturesSuccess,
    props<{ features: ConnectFacilityFeature }>()
);

export const updateFacilityFeature = createAction(
    FacilityFacilityActionEnum.UpdateFacilityFeature,
    props<{ facilityId: number; featureId: number; featureName: string; isEnabled: boolean }>()
);
export const updateFacilityFeatureSuccess = createAction(
    FacilityFacilityActionEnum.UpdateFacilityFeatureSuccess,
    props<{ facilityId: number }>()
);
export const updateFacilityFeatureFailure = createAction(
    FacilityFacilityActionEnum.UpdateFacilityFeatureFailure,
    props<{ facilityId: number }>()
);
