import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FacilitySystem } from 'src/app/facilities/models/facility-system.model';
import {
    deleteColorTheme,
    profileLoaded,
    saveColorTheme
} from 'src/app/facilities/store/actions/facility-systems-profile.actions';
import {
    selectFacilitySystemDetail,
    selectFacilitySystemsProfileLogo
} from 'src/app/facilities/store/selectors/facility-systems-profile.selectors';
import { SystemColorTheme } from '../models';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
@Component({
    selector: 'aya-facility-system-details',
    templateUrl: './system-details.component.html',
    styleUrls: ['./system-details.component.scss']
})
export class SystemDetailsComponent extends UnsubscribeOnDestroy implements OnInit {
    readonly featureFlag = FeatureFlag;
    hospitalSystemId = 0;
    systemProfileLogo$: Observable<Blob>;
    systemDetail$: Observable<FacilitySystem>;
    canSave = false;
    colorTheme: SystemColorTheme | null = null;

    constructor(private readonly _route: ActivatedRoute, private readonly _store: Store) {
        super();
    }

    ngOnInit(): void {
        this._route.paramMap.pipe(takeUntil(this.d$)).subscribe((params) => {
            if (params.has('id')) {
                this.hospitalSystemId = Number(params.get('id'));
                this._store.dispatch(profileLoaded({ systemId: this.hospitalSystemId }));
            }
        });

        this.systemProfileLogo$ = this._store.select(selectFacilitySystemsProfileLogo);
        this.systemDetail$ = this._store.select(selectFacilitySystemDetail);
    }

    colorThemeChanged(colorTheme: SystemColorTheme | null): void {
        this.canSave = true;
        this.colorTheme = colorTheme;
    }

    saveChanges(colorTheme: SystemColorTheme | null = this.colorTheme): void {
        if (this.colorTheme) {
            this._store.dispatch(saveColorTheme({ systemId: this.hospitalSystemId, colorTheme }));
        } else {
            this._store.dispatch(deleteColorTheme({ systemId: this.hospitalSystemId }));
        }

        this.canSave = false;
    }
}
