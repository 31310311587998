export function isEmpty(val: any): boolean {
    return (
        val == null ||
        (val.hasOwnProperty('length') && val.length === 0) ||
        (val.constructor === Object && Object.keys(val).length === 0)
    );
}

export const isEmptyCheck = (value: unknown): boolean => {
    if (value === null || value === undefined) {
        return true;
    }

    if (typeof value === 'string') {
        return !value.length;
    }

    if (value instanceof Date || value instanceof File || typeof value === 'number' || typeof value === 'boolean') {
        return false;
    }

    if (Array.isArray(value)) {
        return !value.length || value.every((v) => isEmptyCheck(v));
    }

    if (Object.values(value).every((v) => isEmptyCheck(v))) {
        return true;
    }

    // Default to not empty when anything else
    return false;
};

export function isEmptyOrSpaces(str: string): boolean {
    return str === null || str.match(/^ *$/) !== null;
}
