import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as vendorsActions from 'src/app/facilities/store/actions/facility-vendors.actions';
import * as fromFacilityVendors from 'src/app/facilities/store/selectors/facility-vendors.selectors';
import { MatTableConfig } from 'src/app/shared/models/mat-table.model';
import { FacilityVendorStatusHistory } from '../../models/facility-vendor.model';

@Component({
    templateUrl: './status-history-dialog.component.html',
    styleUrls: ['./status-history-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusHistoryDialogComponent implements OnInit {
    history$: Observable<FacilityVendorStatusHistory[]>;
    matTableConfig: MatTableConfig;
    statusDisplayColumns = ['status', 'statusDate', 'updatedBy', 'note'];
    originalNote: string;
    vendorData;

    constructor(
        public dialogRef: MatDialogRef<StatusHistoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) vendorData: any,
        private readonly _store: Store<{}>
    ) {
        this.vendorData = vendorData;
    }

    ngOnInit(): void {
        this.history$ = this._store.pipe(select(fromFacilityVendors.selectStatusHistory));
    }

    editNote(note: string) {
        this.originalNote = note;
    }

    noteUpdate(isUpdated: boolean, noteId: number, note = '') {
        const params = {
            id: noteId,
            note: note
        };

        if (isUpdated) {
            this._store.dispatch(
                vendorsActions.updateVendorStatusHistory({
                    facilityId: this.vendorData.facilityId,
                    vendorId: this.vendorData.vendorId,
                    params: params
                })
            );
        }

        this.originalNote = '';
        this.history$ = this._store.pipe(select(fromFacilityVendors.selectStatusHistory));
    }
}
