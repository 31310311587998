import * as actions from 'src/app/facilities/store/actions/facilities-systems-list.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    FacilitiesSystemListState,
    getInitialFacilitiesSystemsListState
} from 'src/app/facilities/store/state/facilities-systems-list.state';

const facilitiesSystemsListReducer = createReducer(
    getInitialFacilitiesSystemsListState(),

    on(actions.loadFacilitySystemsListSuccess, (state, action) => ({
        ...state,
        systemsList: action.systems,
        error: '',
        loadingState: LoadingTypes.LOADED
    })),

    on(actions.loadFacilitySystemsListFailure, (state, action) => ({
        ...state,
        systemsList: { data: [], total: 0, filtered: 0 },
        error: JSON.stringify(action.error),
        loadingState: LoadingTypes.FAIL
    })),

    on(actions.setFacilitySystemsListSearchQuery, (state, action) => ({
        ...state,
        gridSearchQuery: action.searchQuery,
        loadingState: LoadingTypes.LOADING
    }))
);

export function systemsListReducer(state: FacilitiesSystemListState | undefined, action: Action) {
    return facilitiesSystemsListReducer(state, action);
}
