import { PendoEventBase } from './pendo-base';

export class PendoEventShiftSave implements PendoEventBase {
    public readonly name = 'shift.save';
    constructor(
        public readonly metadata: {
            shiftVisibility: 'irp' | 'perdiem';
            facilities: 'irp' | 'perdiem';
            internalAgency: 'internal' | 'agency';
        }
    ) {}
}

export class PendoEventConnectReportPageChanged implements PendoEventBase {
    public name = '';
    constructor(public readonly metadata: {}) {}
}

export type PendoEvent = PendoEventBase | PendoEventShiftSave | PendoEventConnectReportPageChanged;
