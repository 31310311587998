import { Component, Input, AfterContentInit, ViewEncapsulation, ContentChildren, QueryList } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'form-line',
  templateUrl: './form-line.component.html',
  styleUrls: ['./form-line.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'class': 'form-line'}
})
export class FormLineComponent implements AfterContentInit {
    @Input() label: string;
    @Input() markRequired: string;
    @ContentChildren(MatFormField) fields: QueryList<MatFormField>;

    ngAfterContentInit() {
        this.fields.forEach(field => {
            field.appearance = 'fill';
            field.floatLabel = 'never';            
        });
    }
}
