export interface ValidationErrorResponse {
    status: number;
    errors: { [key: string]: string[] }[];
    title: string;
}

export const mapValidationError = (error: ValidationErrorResponse, defaultMessage?: string): string => {
    const errObj = error?.errors;
    if (!errObj) {
        if (typeof error === 'string') {
            return error as string;
        }
        return defaultMessage ?? 'An error occurred.';
    }

    const errors = Object.keys(errObj).map((key) => {
        return errObj[key].join(' ');
    });

    return errors.join('\r\n');
};
