import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingChangesGuard } from 'src/app/shared/guards/pending-changes.guard';
import { VendorCandidateDetailsComponent } from './components/vendor-candidate-details/vendor-candidate-details.component';
import { DocumentUploadComponent } from 'src/app/vendor/vendor-candidate-details/components/document-upload/document-upload.component';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureFlagGuard } from 'src/app/core/ld-feature-flag.guard';

const routes: Routes = [
    {
        path: '',
        component: VendorCandidateDetailsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'document-upload',
        component: DocumentUploadComponent,
        // TODO: Remove when feature flag VENDOR_CONNECT_COMPLIANCE is removed
        data: { featureFlag: FeatureFlag.VendorConnectCompliance },
        canActivate: [LDFeatureFlagGuard],
        //* END REMOVE
        canDeactivate: [PendingChangesGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class VendorCandidateDetailsRoutingModule {}
