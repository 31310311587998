import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { Observable, ReplaySubject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { DayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS } from 'src/app/shared/adapters/dayjs-date-adapter';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { State } from 'src/app/shared/grid/models/state.model';
import { ListItem } from 'src/app/shared/models/list-item';
import { camelize } from 'src/app/shared/utilities';
import { ShiftListItem } from 'src/app/shifts/models/shift-list-item.model';
import { PerdiemSchedulerService } from 'src/app/vendor-perdiemscheduler/services/perdiemscheduler.service';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions/vendor-candidate-details.actions';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors/vendor-candidate-details.selectors';

export const DATE_FORMATS = {
    parse: {
        dateInput: 'DD-MMMM-YYYY'
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY'
    }
};

@Component({
    selector: 'ayac-open-qualified-shift-list',
    templateUrl: './open-qualified-shift-list.component.html',
    styleUrls: ['./open-qualified-shift-list.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
        {
            provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: false }
        },
        {
            provide: DateAdapter,
            useClass: DayjsDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
        }
    ]
})
export class OpenQualifiedShiftListComponent extends UnsubscribeOnDestroy implements OnInit {
    @Output() viewShiftRequested = new EventEmitter<ShiftListItem>();
    @Input() oldUserId?: number;

    rateFilter: ListItem[] = [
        { id: 0, name: 'Any' },
        { id: 30, name: '$30' },
        { id: 40, name: '$40' },
        { id: 50, name: '$50' },
        { id: 60, name: '$60' },
        { id: 70, name: '$70' },
        { id: 80, name: '$80' },
        { id: 90, name: '$90' },
        { id: 100, name: '$100' }
    ];

    dateFormat = 'MM/dd/yyyy';
    filteredMinRate$ = new ReplaySubject<ListItem[]>();
    filteredFacilities$ = new ReplaySubject<ListItem[]>();
    filteredStates$ = new ReplaySubject<State[]>();
    shiftsResult$!: Observable<GridDataResult>;
    isLoading$: Observable<boolean>;
    shiftsQuery: GridSearchQuery;
    selectedShift?: ShiftListItem;
    filterForm!: UntypedFormGroup;
    facilities: ListItem[] = [];
    states: State[] = [];
    currentDate = new Date();
    nextMonthDate = new Date(new Date().setMonth(this.currentDate.getMonth() + 1));
    filtersToRefreshOn: string[] = ['minRate', 'dateFrom', 'dateTo', 'locationFilterType', 'facility', 'city', 'state'];
    isBookingCandidateInProgress = false;

    constructor(
        private readonly _store: Store,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _lookupsService: LookupsService,
        private readonly _datePipe: DatePipe,
        private readonly _perdiemSchedulerService: PerdiemSchedulerService,
        private readonly _toasterService: ToasterService
    ) {
        super();
    }

    get locationFilterTypeControl(): AbstractControl {
        return this.filterForm.get('locationFilterType');
    }

    get dateFromControl(): AbstractControl {
        return this.filterForm.get('dateFrom');
    }

    get dateToControl(): AbstractControl {
        return this.filterForm.get('dateTo');
    }

    @Input() set candidateId(candidateId: number) {
        if (!candidateId) {
            return;
        }

        this._lookupsService
            .getPerDiemFacilitiesForVendor(candidateId, 4)
            .pipe(takeUntil(this.d$))
            .subscribe((facilities) => {
                this.facilities = [{ id: 0, name: 'All' }, ...facilities];

                this.initializeFilter(this.filterForm.get('facilityFilter'), this.filteredFacilities$, this.facilities);
            });

        this._store.dispatch(
            actions.setAvailableShiftListSearchQuery({
                searchQuery: {
                    skip: 0,
                    take: 10,
                    filter: {
                        filters: [
                            { field: 'oldUserId', operator: 'eq', value: candidateId },
                            {
                                field: 'dateFrom',
                                operator: 'eq',
                                value: this._datePipe.transform(this.currentDate, this.dateFormat)
                            },
                            {
                                field: 'dateTo',
                                operator: 'eq',
                                value: this._datePipe.transform(this.nextMonthDate, this.dateFormat)
                            },
                            { field: 'minRate', operator: 'eq', value: 0 }
                        ],
                        logic: 'and'
                    },
                    sort: [{ field: 'shiftDate, startTime', dir: 'asc' }]
                }
            })
        );
    }

    ngOnInit(): void {
        this.shiftsResult$ = this._store.select(selectors.selectAvailableShiftListView);
        this.isLoading$ = this._store.select(selectors.selectAvailableShiftListIsLoading);
        this._store
            .select(selectors.selectAvailableShiftListQuery)
            .pipe(takeUntil(this.d$))
            .subscribe((data) => (this.shiftsQuery = data));

        this.filterForm = this._formBuilder.group({
            minRate: this._formBuilder.control(0),
            minRateFilter: this._formBuilder.control(null),
            dateFrom: this._formBuilder.control(this.currentDate),
            dateTo: this._formBuilder.control(this.nextMonthDate),
            locationFilterType: this._formBuilder.control(1),
            facility: this._formBuilder.control(null),
            facilityFilter: this._formBuilder.control(null),
            city: this._formBuilder.control(null),
            state: this._formBuilder.control(null),
            stateFilter: this._formBuilder.control(null)
        });
        this._lookupsService
            .getStates(null, true)
            .pipe(takeUntil(this.d$))
            .subscribe((states) => {
                this.states = states.filter((x) => x.code);

                this.initializeFilter(this.filterForm.get('stateFilter'), this.filteredStates$, this.states);
            });

        this.initializeFilter(this.filterForm.get('minRateFilter'), this.filteredMinRate$, this.rateFilter);

        this.filtersToRefreshOn.forEach((controlName) => {
            this.filterForm
                .get(controlName)
                .valueChanges.pipe(takeUntil(this.d$))
                .subscribe(() => this.refreshShiftsBasedOnFilters());
        });
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this._store.dispatch(
            actions.setAvailableShiftListSearchQuery({
                searchQuery: state
            })
        );
    }

    onRowClicked(shift: ShiftListItem): void {
        if (this.selectedShift) {
            this.selectedShift.isSelected = false;
        }

        this.selectedShift = this.selectedShift?.id === shift.id ? undefined : shift;

        if (this.selectedShift) {
            this.selectedShift.isSelected = true;
        }
    }

    gridRowClass(context: RowClassArgs): any {
        if (!context.dataItem) {
            return {};
        }

        const statusClassName = `status-${camelize(context.dataItem.statusName)}`;

        return {
            'is-selected': context.dataItem.isSelected,
            [statusClassName]: true
        };
    }

    refreshShiftsBasedOnFilters(): void {
        const filters: {
            minRate?: number;
            dateFrom?: Date;
            dateTo?: Date;
            locationFilterType?: number;
            facility?: number;
            city?: string;
            state?: string;
        } = this.filterForm.getRawValue();
        let gridQuery = this._addOrRemoveFilter(this.shiftsQuery, 'minRate', filters.minRate);
        gridQuery = this._addOrRemoveFilter(
            gridQuery,
            'dateFrom',
            filters.dateFrom ? this._datePipe.transform(filters.dateFrom, this.dateFormat) : null
        );
        gridQuery = this._addOrRemoveFilter(
            gridQuery,
            'dateTo',
            filters.dateTo ? this._datePipe.transform(filters.dateTo, this.dateFormat) : null
        );

        gridQuery = this._addOrRemoveFilter(gridQuery, 'facilityId', undefined);
        gridQuery = this._addOrRemoveFilter(gridQuery, 'city', undefined);
        gridQuery = this._addOrRemoveFilter(gridQuery, 'state', undefined);

        if (filters.locationFilterType === 1) {
            gridQuery = this._addOrRemoveFilter(gridQuery, 'facilityId', filters.facility);
        } else if (filters.locationFilterType === 2) {
            gridQuery = this._addOrRemoveFilter(gridQuery, 'city', filters.city);
            gridQuery = this._addOrRemoveFilter(gridQuery, 'state', filters.state);
        }

        this._store.dispatch(
            actions.setAvailableShiftListSearchQuery({
                searchQuery: gridQuery
            })
        );
    }

    refreshShifts(): void {
        this._store.dispatch(actions.loadAvailableShiftList());
    }

    initializeFilter(
        filterControl: AbstractControl,
        filteredList$: ReplaySubject<ListItem[]>,
        listItems: ListItem[]
    ): void {
        filterControl.valueChanges.pipe(takeUntil(this.d$)).subscribe((filter) => {
            if (!listItems) {
                return;
            }

            if (!filter) {
                filteredList$.next(listItems.slice());
                return;
            } else {
                filter = filter.toLowerCase();
            }

            filteredList$.next(listItems.filter((vendor) => vendor.name.toLowerCase().includes(filter)));
        });
        filterControl.setValue(null);
    }

    bookCandidate(): void {
        this.isBookingCandidateInProgress = true;
        this._perdiemSchedulerService
            .bookCandidate(this.oldUserId, this.selectedShift.id, this.selectedShift.seriesId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.isBookingCandidateInProgress = false;
                    this.selectedShift = undefined;
                    this.refreshShifts();
                    this._store.dispatch(actions.loadCurrentShiftList());
                    this._toasterService.success('The candidate has been booked to the shift(s)');
                },
                error: () => {
                    this.isBookingCandidateInProgress = false;
                    this._toasterService.fail('Unable to book candidate');
                }
            });
    }

    private _addOrRemoveFilter(searchQuery: GridSearchQuery, filterName: string, value: any): GridSearchQuery {
        const result: GridSearchQuery = { ...searchQuery };
        if (!result.filter.filters) {
            result.filter.filters = [];
        }
        let filterDescriptor: FilterDescriptor = result.filter.filters?.find(
            (x: FilterDescriptor) => x.field === filterName
        ) as FilterDescriptor;

        const shouldAdd = value !== null && value !== undefined && value !== '';
        if (shouldAdd) {
            if (filterDescriptor) {
                filterDescriptor.value = value;
                return result;
            }

            filterDescriptor = {
                field: filterName,
                operator: 'and',
                value
            };

            result.filter.filters.push(filterDescriptor);
        } else {
            if (!filterDescriptor) {
                return result;
            }

            const indexToRemove = result.filter.filters.indexOf(filterDescriptor);
            result.filter.filters.splice(indexToRemove, 1);
        }
        return result;
    }
}
