import { AbstractControl, ValidationErrors } from '@angular/forms';

export function phoneValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }
    const phonePattern = new RegExp(/^[2-9][0-8][0-9][2-9][0-9]{2}[0-9]{4}$/);

    const isValid = phonePattern.test(control.value);

    return isValid ? null : { phone: { value: control.value } };
}
