import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { selectUnitInfo } from 'src/app/clinical/store/selectors/client-units.selectors';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    selector: 'locums-crna-certification',
    templateUrl: './crna-certification.component.html'
})
export class CrnaCertificationComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() unitDescriptionFormId: string;
    @Input() readOnly = false;
    @Input() isPrinting = false;

    @Output() valueChanged = new EventEmitter<boolean>();

    nbcrnaCertificationForm: FormGroup;

    constructor(private readonly _formBuilder: FormBuilder, private readonly store: Store) {
        super();
    }

    ngOnInit(): void {
        this.store
            .select(selectUnitInfo)
            .pipe(
                filter((state) => !!state.unitInfo?.unit),
                takeUntil(this.d$)
            )
            .subscribe((state) => this.createForm(state.unitInfo.unit.isNbcrnaCertRequired));
    }

    createForm(nbcrnaCertification: boolean): void {
        this.nbcrnaCertificationForm = this._formBuilder.group({
            nbcrnaCertificationCheckbox: new UntypedFormControl(nbcrnaCertification)
        });
    }

    nbcrnaCertificationChanged(): void {
        this.valueChanged.emit(this.nbcrnaCertificationForm.get('nbcrnaCertificationCheckbox').value);
    }
}
