import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trimWhiteSpace'
})
export class TrimWhiteSpacePipe implements PipeTransform {
    transform(value: string): string {
        return value.replace(/\s/g, '');
    }
}
