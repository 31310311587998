import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    selectClientUnits,
    selectClientUnitsGridSearchQuery
} from 'src/app/clinical/store/selectors/client-units.selectors';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import * as actions from 'src/app/clinical/store/actions/client-units.actions';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UnitStatusEnum } from 'src/app/clinical/models/unit-status-enum';
import { from, Observable, takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { UDOnlyLinkSecurityDto } from 'src/app/clinical/models/aya-link-security';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlags } from 'src/app/shared/models/enums/feature-flag.enum';
import { ToasterService } from 'src/app/core/services';

@Component({
    selector: 'ayac-clinical-client-units',
    templateUrl: './client-units.component.html',
    styleUrls: ['./client-units.component.scss']
})
export class ClientUnitsComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    set facilityId(id: number) {
        if (id) {
            this._store.dispatch(actions.setFacilityId({ facilityId: id }));
            this._store.dispatch(actions.loadClientUnits());
        }
    }

    @Input() clientId: string;
    @Input() updatedUserId: number;
    @Input() environment: string;
    @Input() hostName: string;

    unitStatusEnum = UnitStatusEnum;

    clinicalClientUnits$: Observable<GridDataResult>;
    clientUnitsQuery$: Observable<GridSearchQuery>;
    facility = 1;
    previewUrl = '#/units';
    accessSecureUDLinks: boolean;

    constructor(
        @Inject('Window') private readonly _window: Window,
        private readonly _store: Store,
        private readonly _dialogService: DialogService,
        private readonly _identityService: IdentityService,
        private readonly featureManager: LDFeatureManager,
        private readonly toasterService: ToasterService
    ) {
        super();
    }

    ngOnInit() {
        this.clientUnitsQuery$ = this._store.select(selectClientUnitsGridSearchQuery);
        this.clinicalClientUnits$ = this._store.select(selectClientUnits);
        this.featureManager
            .isEnabled(FeatureFlags.AccessSecureUDLinks)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled) => {
                this.accessSecureUDLinks = isEnabled;
            });
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this._store.dispatch(actions.setClientUnitsSearchQuery({ gridSearchQuery: state }));
    }

    refreshGrid(): void {
        this._store.dispatch(actions.loadClientUnits());
    }

    private openUnitLink(submit: boolean = false): void {
        const url = `${this.previewUrl}?userId=${this.updatedUserId}&hospId=${this.clientId}${
            submit ? '&submit=true' : ''
        }`;
        if (this.accessSecureUDLinks) {
            const UDOnlyLinkSecurityData: UDOnlyLinkSecurityDto = {
                connectUser: this._identityService.oldUserId.toString(),
                link: url
            };
            this._identityService.getUDOnlyLinkSecurityToken(UDOnlyLinkSecurityData).subscribe({
                next: (token) => {
                    this._window.open(`${url}&token=${token}`, '_blank');
                },
                error: (error) => this.toasterService.fail('Failed to fetch token:', error)
            });
        } else {
            this._window.open(url, '_blank');
        }
    }

    addUnit(): void {
        this.openUnitLink(true);
    }

    addUnitAndSend(): void {
        this.openUnitLink(false);
    }

    onSetUnitStatus(unitId: number) {
        const dialog = this._dialogService.openConfirmationDialog({
            data: {
                title: 'Archive Unit',
                text: 'You are about to archive this Unit Description. Are you sure?',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }
        });

        from(dialog).subscribe((result) => {
            if (result) {
                this._store.dispatch(actions.updateUnitStatus({ unitId, status: UnitStatusEnum.Expired }));
            }
        });
    }

    onDeleteUnit(unitId: number) {
        const dialog = this._dialogService.openConfirmationDialog({
            data: {
                title: 'Delete Unit',
                text: 'You are about to delete this Unit Description. Are you sure?',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }
        });

        from(dialog).subscribe((result) => {
            if (result) {
                this._store.dispatch(actions.deleteUnit({ unitId }));
            }
        });
    }

    onEditUnit(unitFormId: number) {
        const url = `${this.previewUrl}?userId=${this.updatedUserId}&formId=${unitFormId}&edit=true`;
        this._window.open(url, '_blank');
    }

    onOpenUnit(unitFormId: number, status: number) {
        const preview = status > this.unitStatusEnum.PendingReview ? '&preview=true' : '';
        const accept = status === this.unitStatusEnum.PendingReview ? '&accept=true' : '';
        const programTypeDisabled = status === this.unitStatusEnum.PendingReview ? '&programTypeDisabled=true' : '';

        const url = `${this.previewUrl}?userId=${this.updatedUserId}&formId=${unitFormId}&contact=true${preview}${accept}${programTypeDisabled}`;
        this._window.open(url, '_blank');
    }

    onDisplayMail(unitFormId: number) {
        const title = 'Unit Description Form';
        const address = '';
        const lotus = 'Lotus Connect';
        const aya = 'Aya Healthcare';

        const subject = (this.environment === 'LTS' ? lotus : aya) + ` - ${title}`;
        const body = `${this.hostName}units?formId=${unitFormId}&contact=true`;

        const mailTo = `mailto:${encodeURIComponent(address)}?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(body)}`;

        this._window.location.href = mailTo;
    }
}
