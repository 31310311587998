import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateUrl } from 'src/app/core/utils';
import { FacilitiesAdminUrls } from 'src/app/shared/models/facilities/enums/facilities-admin-urls.enum';

@Injectable({
    providedIn: 'root'
})
export class AdminSystemTearingService {
    constructor(
        private readonly _http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings
    ) {
    }

    getDisableCount(systemId: number): Observable<number> {
        const url = interpolateUrl(`${FacilitiesAdminUrls.SHIFTS_RELEASE_TEARING_DISABLE_COUNT}`, { systemId });

        return this._http.get<number>(`${this._settings.CORE}${url}`);
    }

    addReason(systemId: number, reason: string): Observable<unknown> {
        const url = `${FacilitiesAdminUrls.SHIFTS_RELEASE_TEARING_REASON}`;
        const body = {
            systemId,
            reason
        }

        return this._http.post<unknown>(`${this._settings.CORE}${url}`, body);
    }
}
