import { AdminClientListItem } from 'src/app/admin-clients/models/admin-clients.model';
import { LoadingTypes } from 'src/app/shared/models';
import { getEmptyPagingToken, PagingToken } from 'src/app/shared/models/paging-token';
import {
    AdminClientQueryState,
    getInitialAdminClientsGridState
} from 'src/app/admin-clients/store/state/admin-clients-query.state';

export interface AdminClientsListState {
    loadingState: LoadingTypes;
    adminClients: PagingToken<AdminClientListItem[]>;
    gridSearchQuery: AdminClientQueryState;
    error: string;
}

export const getInitialAdminClientsListState = (): AdminClientsListState => ({
    loadingState: LoadingTypes.INIT,
    adminClients: getEmptyPagingToken<AdminClientListItem>(),
    gridSearchQuery: getInitialAdminClientsGridState(),
    error: ''
});
