export enum GridColumnFilterEnum {
    CHECKBOX = 'checkbox',
    DROPDOWN = 'dropdown',
    MULTISELECT = 'multiselect',
    COMBOBOX = 'combobox',
    AUTOCOMPLETE = 'autocomplete',
    DATERANGE = 'daterange',
    // AUTOCOMPLETE_MULTISELECT = "autocomplete_multiselect",
    TEXT = 'text',
    IDENTIFIER = 'identifier',
    NUMERIC = 'numeric',
    DATE = 'date',
    TIME = 'time',
    NONE = 'none',
    GREATERLESSEQUALS = 'greater-less-equals',
    CUSTOM = 'custom'
}
