import { FilterDescriptor } from '@progress/kendo-data-query';
import { GridSearchQuery } from '../grid/models';

export const setSubmittalSubStatusFilter = (query: GridSearchQuery, subStatusCount: number) => {
    //For select all we want to also select the submittals w/out substatuses
    const filters = query.filter?.filters as FilterDescriptor[];
    const subStatusIdFilter = filters.find((e) => e.field === 'submittalSubStatusIds')?.value;

    if (subStatusIdFilter !== undefined && subStatusIdFilter.length === subStatusCount) {
        filters.find((e) => e.field === 'submittalSubStatusIds').value = [];
    }
};
