export enum FacilityAssociationsUrls {
    FACILITY_ASSOCIATIONS_LIST = '/AyaConnect/Admin/facility-associations',
    FACILITY_ASSOCIATION_FACILITIES = '/AyaConnect/Admin/facility-associations/facilities',
    FACILITY_ASSOCIATION_FACILITIES_ALL = '/AyaConnect/Admin/facility-associations/facilities/all',
    FACILITY_ASSOCIATION_VENDORS = '/AyaConnect/Admin/facility-associations/vendors',
    FACILITY_ASSOCIATION_VENDOR_STATUS = '/AyaConnect/Admin/facility-associations/vendors/status',
    FACILITY_ASSOCIATION_VENDOR_STATUS_ALL = '/AyaConnect/Admin/facility-associations/vendors/status/all',
    FACILITY_ASSOCIATION_VENDORS_EXPORT = '/AyaConnect/Admin/facility-associations/vendors/export',
    FACILITY_ASSOCIATION_VENDORS_EXPORT_SELECTED = '/AyaConnect/Admin/facility-associations/vendors/export-selected'
}
