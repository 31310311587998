import { createAction, props } from '@ngrx/store';
import { HospitalSystemsVendorsGridItem } from 'src/app/facilities/models';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { FacilitiesVendorStatusUpdate } from 'src/app/facilities/models/facility-vendor.model';
import { State } from 'src/app/shared/grid/models/state.model';

const actionPrefix = '[FACILITY SYSTEMS VENDORS COMPONENT]';
const resultPrefix = '[FACILITY SYSTEMS VENDORS API]';

export const loadFacilitySystemsVendors = createAction(
    `${actionPrefix} Load Facility Systems Vendors`,
    props<{ hospitalSystemId: number; isActive: boolean }>()
);
export const loadFacilitySystemsVendorsSuccess = createAction(
    `${resultPrefix} Load Facility Systems Vendors Success`,
    props<{
        vendors: HospitalSystemsVendorsGridItem[];
        total: number;
        filtered: number;
    }>()
);
export const loadFacilitySystemsVendorsFailure = createAction(
    `${resultPrefix} Load Facility Systems Vendors Failure`,
    props<{ error: unknown }>()
);
export const setFacilitySystemsVendorsSearchQuery = createAction(
    `${actionPrefix} Set Facility Systems Vendors Search Query`,
    props<{ searchQuery?: GridSearchQuery; hospitalSystemId?: number; isActive?: boolean }>()
);

export const exportAllFacilitySystemsVendors = createAction(
    `${actionPrefix} Export All Request`,
    props<{ hospitalSystemId: number; isActive: boolean }>()
);
export const exportAllFacilitySystemsVendorsSuccess = createAction(
    `${resultPrefix} Export All Success`,
    props<{ blob: any }>()
);
export const exportAllFacilitySystemsVendorsFailure = createAction(
    `${resultPrefix} Export All Failure`,
    props<{ error: unknown }>()
);

export const exportSelectedFacilitySystemsVendors = createAction(
    `${actionPrefix} Export Selected Request`,
    props<{ hospitalSystemId: number; vendorIds: number[]; isActive: boolean }>()
);
export const exportSelectedFacilitySystemsVendorsSuccess = createAction(`${resultPrefix} Export Selected Success`);
export const exportSelectedFacilitySystemsVendorsFailure = createAction(
    `${resultPrefix} Export Selected Failure`,
    props<{ error: unknown }>()
);

export const updateFacilitySystemsVendorsStatus = createAction(
    `${actionPrefix} Update Facility Systems Vendors Status Request`,
    props<{ params: FacilitiesVendorStatusUpdate }>()
);
export const updateFacilitySystemsVendorsStatusSuccess = createAction(
    `${resultPrefix} Update Facility Systems Vendors Status Success`
);
export const updateFacilitySystemsVendorsStatusFailure = createAction(
    `${resultPrefix} Update Facility Systems Vendors Status Failure`,
    props<{ error: unknown }>()
);

export const loadVendorListStates = createAction(`${actionPrefix} Load States`);
export const loadVendorListStatesSuccess = createAction(
    `${resultPrefix} Load States Success`,
    props<{ states: State[] }>()
);
export const loadVendorListStatesFailure = createAction(
    `${resultPrefix} Load States Failure`,
    props<{ error: unknown }>()
);
