import { createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { selectAyaLeadsFeature } from './aya-leads-list-selectors/aya-leads-list.selectors';
import { AyaLeadsState } from '../aya-leads.state';
import { AyaLeadsJobDetailsState } from '../reducers/aya-leads-job-details.reducer';
import { selectAyaLeadsApplicationStatuses } from './aya-leads-lookups.selectors';
import { AyaLeadsJobApplicantDetails } from 'src/app/aya-leads/models/aya-leads-job-applicant-details';

export const selectAyaLeadsJobDetailsState = createSelector(
    selectAyaLeadsFeature,
    (state: AyaLeadsState) => state.jobDetails
);

export const selectAyaLeadsJobDetails = createSelector(
    selectAyaLeadsJobDetailsState,
    (state: AyaLeadsJobDetailsState) => state.jobDetails
);

export const selectAyaLeadsJobDetailsLoading = createSelector(
    selectAyaLeadsJobDetailsState,
    (state: AyaLeadsJobDetailsState) =>
        state.jobDetailsLoading === LoadingTypes.LOADING ||
        state.jobApplicantsLoading === LoadingTypes.LOADING ||
        state.removeJobLoading === LoadingTypes.LOADING
);

export const selectAyaLeadsJobApplicants = createSelector(
    selectAyaLeadsJobDetailsState,
    (state: AyaLeadsJobDetailsState) => state.jobApplicants?.applicants
);

export const selectAyaLeadsJobApplicantsWithStatus = createSelector(
    selectAyaLeadsJobApplicants,
    selectAyaLeadsApplicationStatuses,
    (jobApplicants, applicationStatuses): AyaLeadsJobApplicantDetails[] => {
        if (jobApplicants && applicationStatuses) {
            return jobApplicants.map((applicant) => ({
                ...applicant,
                directLeadApplicationStatus:
                    applicationStatuses.find((status) => status.statusId === applicant.directLeadApplicationStatusId)
                        ?.statusName || ''
            }));
        } else {
            return [];
        }
    }
);
