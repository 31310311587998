import { ProfileMetadataResponse } from 'src/app/admin/vendor-details/certifications/models/profile-metadata-response.model';
import { VendorProfileResponse } from 'src/app/admin/vendor-details/certifications/models/vendor-profile-response.model';
import { LoadingTypes } from 'src/app/shared/models';

export interface VendorProfileCertificationsState {
    loading: LoadingTypes;
    loadingSave: LoadingTypes;
    certifications: VendorProfileResponse;
    error: unknown;
    vendorId: number;
    metadata: ProfileMetadataResponse;
}

export const getInitialVendorProfileCertificationsState = (): VendorProfileCertificationsState => ({
    loading: LoadingTypes.INIT,
    loadingSave: LoadingTypes.INIT,
    vendorId: null,
    certifications: {
        id: 0,
        certifications: []
    } as VendorProfileResponse,
    error: undefined,
    metadata: {
        locationLevels: [],
        certifications: null,
        ethnicityTypes: []
    }
});
