import { Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
    selector: 'kendo-job-dropdown',
    templateUrl: './kendo-job-dropdown.component.html',
    styleUrls: ['./kendo-job-dropdown.component.scss']
})
export class KendoJobDropdownComponent extends FieldType<FieldTypeConfig> {}
