import { LookupsService } from '../../services/lookups.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LookupActions from 'src/app/lookups/state/actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as LookupSelectors from '../lookups.selectors';
import { Store } from '@ngrx/store';
import { ListItem } from 'src/app/shared/models/list-item';

@Injectable()
export class LinesOfBusinessEffect {
    constructor(
        private actions$: Actions,
        private store: Store<{}>,
        private service: LookupsService
    ) {}

    getLookupsList$ = createEffect(() => this.actions$.pipe(
        ofType(LookupActions.loadLinesOfBusiness),
        withLatestFrom(this.store.select(LookupSelectors.getLinesOfBusiness)),
        switchMap(([action, linesOfBusiness]) => {
            // lazy loading
            if (linesOfBusiness.length > 0)
                return of(LookupActions.loadLinesOfBusinessSuccess({ linesOfBusiness: linesOfBusiness }));

            return this.service.getDashboardLinesOfBusiness()
                .pipe(
                    map(response =>
                        {
                            return LookupActions.loadLinesOfBusinessSuccess({
                                linesOfBusiness: this.setLineOfBusiness(response)
                            });
                        }
                    ),
                    catchError(error => of(
                        LookupActions.loadLinesOfBusinessFail({ error })
                    ))
                )
        })
    ));

    getJobsLinesOfBusiness$ = createEffect(() => this.actions$.pipe(
        ofType(LookupActions.loadJobsLinesOfBusiness),
        withLatestFrom(this.store.select(LookupSelectors.getJobsLinesOfBusiness)),
        switchMap(([_, linesOfBusiness]) => {
            if(linesOfBusiness.length > 0) {
                return of(LookupActions.loadJobsLinesOfBusinessSucesss({ linesOfBusiness }))
            }

            return this.service.getJobsLinesOfBusiness()
                .pipe(
                    map(response => LookupActions.loadJobsLinesOfBusinessSucesss({ linesOfBusiness: this.setLineOfBusiness(response) })),
                    catchError(error => of(
                        LookupActions.loadJobsLinesOfBusinessFail({ error })
                    ))
                )
        })
    ));

    getSubmittalsLinesOfBusiness$ = createEffect(() => this.actions$.pipe(
        ofType(LookupActions.loadSubmittalsLinesOfBusiness),
        withLatestFrom(this.store.select(LookupSelectors.getSubmittalsLinesOfBusiness)),
        switchMap(([_, linesOfBusiness]) => {
            if(linesOfBusiness.length > 0) {
                return of(LookupActions.loadSubmittalsLinesOfBusinessSucesss({ linesOfBusiness }))
            }

            return this.service.getSubmittalsLinesOfBusiness()
                .pipe(
                    map(response => LookupActions.loadSubmittalsLinesOfBusinessSucesss({ linesOfBusiness: this.setLineOfBusiness(response) })),
                    catchError(error => of(
                        LookupActions.loadSubmittalsLinesOfBusinessFail({ error })
                    ))
                )
        })
    ));

    getWorkersLinesOfBusiness$ = createEffect(() => this.actions$.pipe(
        ofType(LookupActions.loadWorkersLinesOfBusiness),
        withLatestFrom(this.store.select(LookupSelectors.getWorkersLinesOfBusiness)),
        switchMap(([_, linesOfBusiness]) => {
            if(linesOfBusiness.length > 0) {
                return of(LookupActions.loadWorkersLinesOfBusinessSucesss({ linesOfBusiness }))
            }

            return this.service.getWorkersLinesOfBusiness()
                .pipe(
                    map(response => LookupActions.loadWorkersLinesOfBusinessSucesss({ linesOfBusiness: this.setLineOfBusiness(response) })),
                    catchError(error => of(
                        LookupActions.loadWorkersLinesOfBusinessFail({ error })
                    ))
                )
        })
    ));

    getTimecardsLinesOfBusiness$ = createEffect(() => this.actions$.pipe(
        ofType(LookupActions.loadTimecardsLinesOfBusiness),
        withLatestFrom(this.store.select(LookupSelectors.getTimecardsLinesOfBusiness)),
        switchMap(([_, linesOfBusiness]) => {
            if(linesOfBusiness.length > 0) {
                return of(LookupActions.loadTimecardsLinesOfBusinessSucesss({ linesOfBusiness }))
            }

            return this.service.getTimecardsLinesOfBusiness()
                .pipe(
                    map(response => LookupActions.loadTimecardsLinesOfBusinessSucesss({ linesOfBusiness: this.setLineOfBusiness(response) })),
                    catchError(error => of(
                        LookupActions.loadTimecardsLinesOfBusinessFail({ error })
                    ))
                )
        })
    ));

    getEditJobsLinesOfBusiness$ = createEffect(() => this.actions$.pipe(
        ofType(LookupActions.loadEditJobsLinesOfBusiness),
        withLatestFrom(this.store.select(LookupSelectors.getEditJobsLinesOfBusiness)),
        switchMap(([_, linesOfBusiness]) => {
            if(linesOfBusiness.length > 0) {
                return of(LookupActions.loadEditJobsLinesOfBusinessSucesss({ linesOfBusiness }))
            }

            return this.service.getEditJobsLinesOfBusiness()
                .pipe(
                    map(response => {
                        const linesOfBusiness = response.filter(lob => lob.id !== 1 && lob.id !== 5).sort((a, b) => -a.name.localeCompare(b.name));
                        return LookupActions.loadEditJobsLinesOfBusinessSucesss({ linesOfBusiness })
                    }),
                    catchError(error => of(
                        LookupActions.loadEditJobsLinesOfBusinessFail({ error })
                    ))
                )
        })
    ));

    setLineOfBusiness(lob: ListItem[]) {
        const defaultLineOfBusiness = { id: null, name: "All Labor Types" };
        lob.sort((a, b) => -a.name.localeCompare(b.name));
        return [defaultLineOfBusiness].concat(lob);
    }
}
