export enum ShiftStatusView {
    Open = 1,
    Filled = 2,
    CancelledByFacility = 3,
    CancelledByClinician = 4,    
    CancellationConfirmed = 5,
    Reconfirmed = 6,
    PartiallyConfirmed = 7,
    PendingApproval = 8,
    Rejected = 9,
    CancelledByHospital = 13,
    FirstShift = 14
}
