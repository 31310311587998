import { createAction, props } from '@ngrx/store';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { UnitLookup } from 'src/app/shared/models/lookups/unit-lookup.model';
import { SpecialtyLookup } from 'src/app/shared/models/lookups/specialty-lookup.model';
import { PerDiemCancelReason } from 'src/app/shared/models/shifts/client-lookups.model';
import { ListItem } from 'src/app/shared/models/list-item';
import { ShiftStatusLookup } from '../../models/shift-status-lookup.model';

export const loadShiftsLookups = createAction('[SHIFTS] Do Shifts Lookup');
export const loadShiftsLookupsFromServer = createAction('[SHIFTS] Load Shifts Lookups From Server');
export const loadShiftsLookupsSuccess = createAction(
    '[SHIFTS] Shifts Lookups Load Succeed',
    props<
    {
        systems: ListItem[],
        facilities: FacilityLookup[],
        units: UnitLookup[],
        professions: ListItem[],
        specialties: SpecialtyLookup[],
        statuses: ShiftStatusLookup[],
        states: ListItem[],
        unions: ListItem[],
        submittalStatuses: ListItem[],
        availabilityStatuses: ListItem[],
        languageSkillLevels: ListItem[],
        languages: ListItem[],
        epicSkills: ListItem[],
        resourceTypes: ListItem[],
    }>());
export const loadShiftsLookupsFail = createAction('[SHIFTS] Shifts Lookups Load Failed', props<{ error: any }>());
export const loadShiftsLookupsLocal = createAction('[SHIFTS] Load Shifts Lookups From Local Store');

// Client lookups
export const loadClientLookups = createAction('[SHIFTS] Load Client Lookups');
export const loadClientLookupsSuccess = createAction('[SHIFTS] Load Client Lookups success', props<{
		perDiemCancelReasonsByNurse?: Array<PerDiemCancelReason>,
        perDiemCancelReasonsByFacility?: Array<PerDiemCancelReason>,
	}>()
);
export const loadClientLookupsFail = createAction('[SHIFTS] Load Client Lookups fail', props<{ error: any }>());

// Shift Template Lookups

// Client lookups
export const loadShiftTemplateLookups = createAction('[SHIFTS] Load Shift Template Lookups');
export const loadShiftTemplateLookupsSuccess = createAction(
    '[SHIFTS] Load Shift Template Lookups Success',
    props<{
		shiftTemplates?: ListItem[]
	}>()
);
export const loadShiftTemplateLookupsFail = createAction(
    '[SHIFTS] Load Shift Template Lookups Fail',
    props<{ error: any }>()
);
