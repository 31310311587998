import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as shiftsActions from '../actions';
import { ShiftTemplateDetails } from '../../models';

export interface ShiftTemplateDetailsState {
    error: string;
    loading: LoadingTypes;
    details: ShiftTemplateDetails;
}

export const initialState: ShiftTemplateDetailsState = {
    error: '',
    loading: LoadingTypes.INIT,
    details: null
}

const reducer = createReducer(
    initialState,
    on(shiftsActions.loadShiftTemplateDetails, (state) => (
        {
            ...state,
            loading: LoadingTypes.LOADING,
        })),
    on(shiftsActions.loadShiftTemplateDetailsFail, (state) => (
        {
            ...state,
            loading: LoadingTypes.LOADED,
            error: state.error
        })),
    on(shiftsActions.loadShiftTemplateDetailsSuccess, (state, action) => (
        {
            ...state,
            loading: LoadingTypes.LOADED,
            details: action.template
        })),

    on(shiftsActions.clearShiftTemplateDetails, (state) => (
        {
            ...state,
            ...initialState
        }))
);

export function shiftTemplateDetailsReducer(state: ShiftTemplateDetailsState | undefined, action: Action) {
    return reducer(state, action);
}
