import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ConnectDocumentUploadFormValidators {
    static dateRange(minDate: Date | null, maxDate: Date | null): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const validationError = { dateRange: true };

            if (!c.value) {
                return null;
            }

            const timestamp = Date.parse(c.value);
            if (isNaN(timestamp)) {
                return validationError;
            }

            const date = new Date(timestamp);
            if ((minDate && minDate > date) || (maxDate && maxDate < date)) {
                return validationError;
            }

            return null;
        };
    }

    static minDate(minDate: Date | null): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const validationError = { minDate: true };

            if (!c.value) {
                return null;
            }

            const timestamp = Date.parse(c.value);
            if (isNaN(timestamp)) {
                return validationError;
            }

            const date = new Date(timestamp);
            if (minDate && minDate > date) {
                return validationError;
            }

            return null;
        };
    }

    static maxDate(maxDate: Date | null): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const validationError = { maxDate: true };

            if (!c.value) {
                return null;
            }

            const timestamp = Date.parse(c.value);
            if (isNaN(timestamp)) {
                return validationError;
            }

            const date = new Date(timestamp);
            if (maxDate && maxDate < date) {
                return validationError;
            }

            return null;
        };
    }
}
