import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { RolePermissionsState, rolePermissionsStoreKey } from '../role-permissions.state';

export const selectRolePermissions = createFeatureSelector<RolePermissionsState>(rolePermissionsStoreKey);

export const selectRoles = createSelector(selectRolePermissions, (state) => state.roles);

export const selectRoleIsLoading = createSelector(
    selectRolePermissions,
    (state) => state.loadingState === LoadingTypes.LOADING
);
