import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ColorThemeService } from './theme.service';

const loadColorTheme = (service: ColorThemeService) => {
    return () => service.loadColorTheme();
};

@NgModule({})
export class ColorThemeModule {
    constructor(@Optional() @SkipSelf() parentModule: ColorThemeModule) {
        if (parentModule) {
            throw new Error('ColorThemeModule is already loaded.');
        }
    }

    static forRoot(): ModuleWithProviders<ColorThemeModule> {
        return {
            ngModule: ColorThemeModule,
            providers: [
                ColorThemeService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: loadColorTheme,
                    deps: [ColorThemeService],
                    multi: true
                }
            ]
        };
    }
}
