import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { VendorJobRuleDetails, VendorJobRulesList } from '../../vendor-details/profile/models/job-rules.model';
import { JobRuleProfessionTree } from 'src/app/admin-vendors/models/job-rule-profession-specialties.model';

export const VendorProfileJobRulesActions = createActionGroup({
    source: 'Vendor Profile Job Rules',
    events: {
        'Load Vendor Profile Job Rules List': props<{ vendorId: number }>(),
        'Load Vendor Profile Job Rules List Success': props<{ jobRules: VendorJobRulesList[] }>(),
        'Load Vendor Profile Job Rules List Failure': props<{ error: any }>(),
        'Load Vendor Profile Job Rule Details': props<{ jobRuleId: number }>(),
        'Load Vendor Profile Job Rule Details Success': props<{ details: VendorJobRuleDetails }>(),
        'Load Vendor Profile Job Rule Details Failure': props<{ error: any }>(),
        'Clear Job Rules': emptyProps(),
        'Load Vendor Profile Job Rule Profession Types': emptyProps(),
        'Load Vendor Profile Job Rule Profession Types Success': props<{ professionTypes: JobRuleProfessionTree }>(),
        'Load Vendor Profile Job Rule Profession Types Failure': props<{ error: string }>()
    }
});
