export enum JobsClientPermissions {
    JobTemplates = 'jobstemplates',
    JobTemplateEdit = 'jobstemplates_Edit',
    JobsAdd = 'jobs_Add',
    JobsEdit = 'jobs_Edit',
    JobsDetail = 'jobs_Detail',
    JobsRemove = 'jobs_Remove',
    AttachmentsView = 'jobs_attachments_view',
    AttachmentsEdit = 'jobs_attachments_edit',
    IRPSubmittalsEdit = 'jobs_irp-submittals_edit',
    IRPSubmittalsDetail = 'jobs_irp-submittals_Detail'
}
