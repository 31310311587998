import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from 'src/app/admin/store/actions/vendor-insurance.actions';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { VendorInsuranceService } from 'src/app/admin/vendor-details/insurance/vendor-insurance.service';
import { Utilities } from 'src/app/core/utils';

@Injectable()
export class VendoInsuranceEffects {
    exportVendors$ = createEffect(() => this._actions$.pipe(
        ofType(actions.exportVendorInsurance),
        exhaustMap((action) => {
            return this._vendorInsuranceService.exportVendorInsurance(action.vendorId).pipe(
                map((result) => actions.exportVendorInsuranceSuccess({ response : result })),
                catchError((error) => of(actions.exportVendorInsuranceFailure({ error })))
            )
        })
    ));

    downloadInsuranceReport$ = createEffect(() => this._actions$.pipe(
        ofType(actions.exportVendorInsuranceSuccess),
        map((action) => Utilities.downloadBlob(action.response, 'vendor_insurance_export.xlsx'))
    ), { dispatch: false })
   
    constructor(
        private readonly _actions$: Actions,
        private readonly _vendorInsuranceService: VendorInsuranceService
    ) { }
}
