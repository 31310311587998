import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortBy'
})
export class ArraySortPipe implements PipeTransform {
    transform(array: any, field: string, direction: 'asc' | 'desc' = 'asc'): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            const fieldOne = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field];
            const fieldTwo = typeof b[field] === 'string' ? b[field].toLowerCase() : b[field];

            if (fieldOne < fieldTwo) {
                return direction === 'asc' ? -1 : 1;
            } else if (fieldOne > fieldTwo) {
                return direction === 'asc' ? 1 : -1;
            }

            return 0;
        });

        return array;
    }
}
