import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { SubmittalStatuses } from 'src/app/shared/models/submittals';

@Component({
    selector: 'aya-update-status-dialog',
    templateUrl: 'update-status-dialog.component.html',
    styleUrls: ['./update-status-dialog.component.scss']
})
export class UpdateStatusDialogComponent {
    featureFlag = FeatureFlag;
    form: FormGroup = new FormGroup({ notes: new FormControl('') }, this.notesValidator());
    noteItemIsNotValid = false;
    submittalStatuses = SubmittalStatuses;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            submittalStatusName: string;
            submittalSubStatusName: string;
            submittalStatusId?: SubmittalStatuses;
        },
        private readonly _dialogRef: MatDialogRef<UpdateStatusDialogComponent>
    ) {}

    get isMoreInformationNeededSelected(): boolean {
        return this.data.submittalStatusId === SubmittalStatuses.MoreInformationNeeded;
    }

    get notes(): string {
        return this.form?.get('notes')?.value;
    }

    updateStatus() {
        this._dialogRef.close({ notes: this.form.get('notes').value });
    }

    onNoteItemValidationChanged(isValid: boolean) {
        this.noteItemIsNotValid = !isValid;
    }

    notesValidator(): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            return this.isMoreInformationNeededSelected && !this.notes ? { invalidNote: true } : null;
        };
    }
}
