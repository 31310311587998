import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
    selector: '[skeleton-overlay]'    
})
export class SkeletonOverlayDirective {
    @HostBinding('class.skeleton-overlay')
    @Input('skeleton-overlay')
    overlay;
}
