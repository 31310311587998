import { LoadingTypes } from 'src/app/shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import {
    loadPerdiemschedulerShiftAttachments,
    loadPerdiemschedulerShiftAttachmentsSuccess,
    loadPerdiemschedulerShiftAttachmentsFail,
    clearPerdiemschedulerShiftAttachments,
    loadPreviewPerdiemschedulerShiftAttachmentsSuccess,
    loadPreviewShiftPageCountSuccess,
    loadPreviewPerdiemschedulerShiftAttachments,
    loadPreviewPerdiemschedulerShiftAttachmentsFail,
    clearShiftAttachmentPreview,
    loadPreviewShiftPageCountFail,
    loadShiftAttachmentFeaturesSuccess,
    loadShiftAttachmentFeaturesFail,
    deletePerdiemschedulerShiftAttachmentSuccess
} from '../actions/perdiemscheduler-shifts-attachments.actions';
import { ShiftsAttachmentsState } from 'src/app/shifts/store/reducers/shift-attachments.reducer';

export const initialPerdiemschedulerShiftAttachmentsState: ShiftsAttachmentsState = {
    attachments: [],
    loading: LoadingTypes.INIT,
    preview: null,
    previewTotal: 0,
    loadingPreview: LoadingTypes.INIT,
    currentFile: null,
    attachmentFeatures: null,
    attachmentChange: false
};

export function perdiemschedulerShiftsAttachmentsReducer(state: ShiftsAttachmentsState, action: Action) {
    return createReducer(
        initialPerdiemschedulerShiftAttachmentsState,
        on(loadPerdiemschedulerShiftAttachments, (state) => {
            return {
                ...initialPerdiemschedulerShiftAttachmentsState,
                loading: LoadingTypes.LOADING,
                attachmentChange: state.attachmentChange
            };
        }),

        on(deletePerdiemschedulerShiftAttachmentSuccess, (state) => ({
            ...state,
            loadingPreview: LoadingTypes.LOADED,
            loading: LoadingTypes.LOADED,
            attachmentChange: true
        })),

        on(loadPerdiemschedulerShiftAttachmentsSuccess, (state, action) => ({
            ...state,
            attachments: action.attachments,
            loading: LoadingTypes.LOADED
        })),

        on(loadPerdiemschedulerShiftAttachmentsFail, (state) => ({
            ...state,
            loading: LoadingTypes.FAIL
        })),

        on(loadPreviewPerdiemschedulerShiftAttachments, (state, action) => ({
            ...state,
            currentFile: action.file,
            loadingPreview: LoadingTypes.LOADING,
            loading: LoadingTypes.LOADING
        })),

        on(loadPreviewPerdiemschedulerShiftAttachmentsSuccess, (state, action) => ({
            ...state,
            preview: action.attachmentPreview,
            loadingPreview: LoadingTypes.LOADED,
            loading: LoadingTypes.LOADED
        })),

        on(loadPreviewPerdiemschedulerShiftAttachmentsFail, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.FAIL,
            loading: LoadingTypes.FAIL
        })),

        on(loadPreviewShiftPageCountSuccess, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.LOADED,
            loading: LoadingTypes.LOADED,
            previewTotal: action.total
        })),

        on(loadPreviewShiftPageCountFail, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.FAIL,
            loading: LoadingTypes.FAIL
        })),

        on(clearShiftAttachmentPreview, (state) => ({
            ...state,
            preview: initialPerdiemschedulerShiftAttachmentsState.preview,
            previewTotal: initialPerdiemschedulerShiftAttachmentsState.previewTotal
        })),

        on(clearPerdiemschedulerShiftAttachments, (state) => ({
            ...state,
            ...initialPerdiemschedulerShiftAttachmentsState
        })),
        on(loadShiftAttachmentFeaturesSuccess, (state, action) => ({
            ...state,
            attachmentFeatures: action.attachmentFeatures,
            loading: LoadingTypes.LOADED
        })),

        on(loadShiftAttachmentFeaturesFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL
        })),

        on(loadPreviewShiftPageCountSuccess, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.LOADED,
            loading: LoadingTypes.LOADED,
            previewTotal: action.total
        })),

        on(loadPreviewShiftPageCountFail, (state, action) => ({
            ...state,
            loadingPreview: LoadingTypes.FAIL,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: ShiftsAttachmentsState | undefined, action: Action) {
    return perdiemschedulerShiftsAttachmentsReducer(state, action);
}
