import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
    selector: '[spinner]'
})
export class SpinnerDirective implements OnChanges, OnDestroy {
    private autoHideTimeoutRef: any;
    private spinnerTopRef: OverlayRef;

    @Input() spinner: boolean = false;

    constructor(private elRef: ElementRef,
        private overlay: Overlay) {
    }

    ngOnInit() {
        this.spinnerTopRef = this.cdkSpinnerCreate();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["spinner"]) {
            this.updateSpinner(this.spinner);
        }
    }

    ngOnDestroy(): void {
        this.hideSpinner();
    }

    showSpinner() {
        this.spinnerTopRef.attach(new ComponentPortal(MatProgressSpinner));
        this.autoHideTimeoutRef = setTimeout(() => { this.hideSpinner(); }, 30000);
    }

    hideSpinner() {
        clearTimeout(this.autoHideTimeoutRef);
        if (this.spinnerTopRef?.hasAttached()) {
            this.spinnerTopRef.detach();
        }
    }

    updateSpinner(isLoading: boolean): void {
        setTimeout(() => isLoading ? this.showSpinner() : this.hideSpinner(), 1);
    }

    private cdkSpinnerCreate() {
        if (!this.elRef) {
            return;
        }
        return this.overlay.create({
            hasBackdrop: true,
            positionStrategy: this.overlay.position().flexibleConnectedTo(this.elRef).withPositions([{
                originX: 'center',
                originY: 'center',
                overlayX: 'center',
                overlayY: 'center',
            }]),
            scrollStrategy: this.overlay.scrollStrategies.reposition({ scrollThrottle: 100, autoClose: false })
        });
    }
}
