import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { from, Observable, ReplaySubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { LaunchDarklyService } from 'src/app/shared/services/ld.service';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { FeatureManagementFlag } from 'src/app/shared/models';

@Injectable({
    providedIn: 'root'
})
export class LDFeatureManager {
    private readonly _ldClient: LDClient.LDClient;
    private _cachedFlags: LDClient.LDFlagSet;
    private readonly _flags$: ReplaySubject<FlagsState> = new ReplaySubject<FlagsState>(1);
    private _instantiated = false;

    constructor(
        @Inject(APP_CONFIG) readonly settings: Settings,
        private readonly launchDarklyService: LaunchDarklyService
    ) {
        this._ldClient = this.launchDarklyService.createClient(this.settings.LAUNCHDARKLY_KEY, {
            key: 'anonymous',
            anonymous: true
        });
    }

    public async init() {
        if (!this._instantiated) {
            this._instantiated = true;
            this._ldClient.on('change', (flags) => {
                this.updateFlags(flags);
            });
            await this._ldClient.waitUntilReady();
            this.loadFlags();
        }
    }

    public isEnabled(featureName: FeatureFlag): Observable<boolean> {
        let isFirstLoad = true;
        return this._flags$.pipe(
            filter((flags) => typeof flags.flagsState[featureName] === 'boolean'),
            filter((flags) => isFirstLoad || flags.changedFlags.includes(featureName)),
            tap(() => (isFirstLoad = false)),
            map((flags) => flags.flagsState[featureName])
        );
    }

    public isDisabled(featureName: FeatureFlag): Observable<boolean> {
        let isFirstLoad = true;
        return this._flags$.pipe(
            filter((flags) => typeof flags.flagsState[featureName] === 'boolean'),
            filter((flags) => isFirstLoad || flags.changedFlags.includes(featureName)),
            tap(() => (isFirstLoad = false)),
            map((flags) => !flags.flagsState[featureName])
        );
    }

    // Fetch feature flag JSON and return it as an observable
    public getJsonVariation(featureName: FeatureFlag): Observable<any[]> {
        let isFirstLoad = true;

        return this._flags$.pipe(
            // Filter to ensure flag exists and is an array or object (JSON)
            filter(
                (flags) =>
                    Array.isArray(flags.flagsState[featureName]) || typeof flags.flagsState[featureName] === 'object'
            ),
            // Filter to allow the first load or when the flag has changed
            filter((flags) => isFirstLoad || flags.changedFlags.includes(featureName)),
            // Update `isFirstLoad` after the first emission
            tap(() => (isFirstLoad = false)),
            // Map to extract the specific flag's value
            map((flags) => flags.flagsState[featureName])
        );
    }

    public selectFeatureFlag(featureName: FeatureFlag): Observable<FeatureManagementFlag> {
        let isFirstLoad = true;
        return this._flags$.pipe(
            filter((flags) => isFirstLoad || flags.changedFlags.includes(featureName)),
            tap(() => (isFirstLoad = false)),
            map((flags) => ({
                enabled: flags.flagsState[featureName] === true,
                value: flags.flagsState[featureName],
                flagName: featureName
            }))
        );
    }

    public getUser(): LDClient.LDContext {
        return this._ldClient.getContext();
    }

    public changeUser(identityUser?: any): void {
        const user = this.createUser(identityUser);
        this._ldClient.identify(user);
    }

    public dispose(): Observable<any> {
        return from(this._ldClient.close());
    }

    private loadFlags(): void {
        this._cachedFlags = this._ldClient.allFlags();
        const allFlagNames = Object.values(FeatureFlag);
        this._flags$.next({ flagsState: this._cachedFlags, changedFlags: allFlagNames });
    }

    private updateFlags(flags: LDClient.LDFlagChangeset): void {
        if (this._cachedFlags !== undefined && this._cachedFlags !== null) {
            const updatedFlags = [];
            Object.values(FeatureFlag).forEach((flagName) => {
                if (flags[flagName] !== undefined) {
                    this._cachedFlags[flagName] = flags[flagName].current;
                    if (flags[flagName].current !== flags[flagName].previous) {
                        updatedFlags.push(flagName);
                    }
                }
            });
            if (updatedFlags.length !== 0) {
                this._flags$.next({ flagsState: this._cachedFlags, changedFlags: updatedFlags });
            }
        }
    }

    private createUser(identityUser: any): LDClient.LDContext {
        if (identityUser?.coreUserId) {
            const user = {
                key: identityUser.coreUserId,
                name: identityUser.userName,
                email: identityUser.email,
                anonymous: false
            };
            return user;
        } else {
            return { key: 'anonymous', anonymous: true };
        }
    }
}

interface FlagsState {
    flagsState: LDClient.LDFlagSet;
    changedFlags: string[];
}
