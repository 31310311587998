import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Profession } from 'src/app/clinical/models';
import { UnitStateLicense } from 'src/app/clinical/models/unit-state-license.model';
import * as actions from 'src/app/clinical/store/actions/client-units.actions';
import { selectStates, selectUnitStateLicenses } from 'src/app/clinical/store/selectors/client-units.selectors';
import { ToasterService } from 'src/app/core/services';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { StateLookup } from 'src/app/jobs/models/lookups/state-lookup';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { QuesoFormaggioFeatureFlags } from 'src/app/shared/models/enums/queso-formaggio-feature-flags.enum';

export enum Professions {
    NursePractitioner = 3,
    Physician = 59,
    CRNA = 73
}

@Component({
    selector: 'locums-state-licenses',
    templateUrl: './state-licenses.component.html',
    styleUrls: ['./state-licenses.component.scss']
})
export class StateLicensesComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @Input() unitDescriptionFormId: string;
    @Input() isPrinting = false;
    @Input() readOnly = false;
    @Input() professions: Profession[];

    @Output() valuesChanged = new EventEmitter<UnitStateLicense[]>();

    states: StateLookup[];
    stateLicenses: UnitStateLicense[] = [];
    stateLicensesForm: FormGroup;
    showCompactAccepted = false;
    showImlcAccepted = false;
    isLocumsUnitDescriptionFieldValidationFlag$ = new BehaviorSubject<boolean>(false);

    private readonly compactProfessionIds = [Professions.NursePractitioner, Professions.CRNA];
    private readonly imlcProfessionIds = [Professions.Physician];

    constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly store: Store,
        private readonly toasterService: ToasterService,
        private readonly ldFeatureManager: LDFeatureManager
    ) {
        super();
        this.createForm();
    }

    get visibleLicenses() {
        return this.stateLicensesForm.get('visibleLicenses') as FormArray;
    }

    ngOnInit(): void {
        this.store.dispatch(actions.loadStateLicensesComponent({ unitId: this.unitDescriptionFormId }));

        forkJoin({
            statesState$: this.store
                .select(selectStates)
                .pipe(filter((state) => state.states !== null))
                .pipe(first()),
            stateLicenseState$: this.store
                .select(selectUnitStateLicenses)
                .pipe(filter((state) => state.stateLicenses !== null))
                .pipe(first())
        }).subscribe((state) => {
            this.states = state.statesState$.states;
            this.stateLicenses = state.stateLicenseState$.stateLicenses;
            this.createFormRows();
        });

        this.ldFeatureManager
            .isEnabled(QuesoFormaggioFeatureFlags.LocumsUnitDescriptionFieldValidation)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                if (flagIsEnabled !== this.isLocumsUnitDescriptionFieldValidationFlag$.value) {
                    this.isLocumsUnitDescriptionFieldValidationFlag$.next(flagIsEnabled);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (JSON.stringify(changes.professions?.currentValue) !== JSON.stringify(changes.professions?.previousValue)) {
            this.onProfessionsChanged();
        }
    }

    createForm(): void {
        this.stateLicensesForm = this._formBuilder.group({
            visibleLicenses: this._formBuilder.array([])
        });
    }

    createFormRows(): void {
        this.stateLicenses.forEach((license: UnitStateLicense) => {
            this.createStateLicenseFormEntry(license);
        });

        if (!this.readOnly && !this.isPrinting) {
            this.createStateLicenseFormEntry(null);
        }

        if (this.stateLicenses?.length > 0) {
            this.valuesChanged.emit(this.stateLicenses);
        }

        this.visibleLicenses.valueChanges
            .pipe(takeUntil(this.d$))
            .subscribe((x: UnitStateLicense[]) => this.stateLicensesChanged(x));
    }

    createStateLicenseFormEntry(license: UnitStateLicense | null): void {
        this.visibleLicenses.push(
            this._formBuilder.group({
                id: this._formBuilder.control(license?.id),
                stateId: this._formBuilder.control(license?.stateId),
                isRequired: this._formBuilder.control(this.getIsRequired(license)),
                isCompactAccepted: this._formBuilder.control(license?.isCompactAccepted ?? false),
                isImlcAccepted: this._formBuilder.control(license?.isImlcAccepted ?? false),
                stateName: this._formBuilder.control(this.getStateName(license?.stateId))
            })
        );
    }

    getIsRequired(license: UnitStateLicense | null): boolean {
        return this.isLocumsUnitDescriptionFieldValidationFlag$.value && license !== null
            ? true
            : license?.isRequired ?? false;
    }

    getStateName(id: number): string {
        return this.states?.find((x) => x.id === id)?.name;
    }

    validateLicense(selection: MatSelectChange, index: number): void {
        if (
            this.visibleLicenses.controls.find(
                (x: any, i: number) => x.get('stateId').value === selection.value && i !== index
            )
        ) {
            this.removeStateLicense(index);
            this.toasterService.fail('You may not enter the same state more than once.');
        } else {
            if (this.isLocumsUnitDescriptionFieldValidationFlag$.value) {
                this.visibleLicenses.controls
                    .find((x: any, i: number) => x.get('stateId').value === selection.value)
                    ?.get('isRequired')
                    .setValue(true);
            }
        }
    }

    removeStateLicense(index: number): void {
        this.visibleLicenses.removeAt(index);
    }

    stateLicensesChanged(licenses: UnitStateLicense[]): void {
        this.addNewStateLicenseIfNoneExists(licenses);
        this.valuesChanged.emit(licenses.filter((x) => x.stateId !== null));
    }

    addNewStateLicenseIfNoneExists(licenses: any[]): void {
        if (!licenses.find((y) => y.stateId === null)) {
            this.createStateLicenseFormEntry(null);
        }
    }

    onProfessionsChanged(): void {
        this.showCompactAccepted =
            this.professions?.findIndex((profession) =>
                this.compactProfessionIds.includes(profession.professionCode)
            ) !== -1;
        this.showImlcAccepted =
            this.professions?.findIndex((profession) => this.imlcProfessionIds.includes(profession.professionCode)) !==
            -1;
        this.visibleLicenses.clear();
    }
}
