import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from "@angular/core";
import { Store } from '@ngrx/store';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'grid-combobox-filter',
    templateUrl: './combobox-filter.component.html'
})
export class ComboBoxFilterComponent extends BaseFilterCellComponent implements OnChanges {
    @Input() public field: string;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() public data: any[];
    @Input() public textField: string;
    @Input() public disabled: boolean;
    @Input() public valueField: string;
    @Input() public defaultText: string;
    @Input() public width: number;
    @Input() uniqueFilterClass: string;
    @Input() filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'startsWith'
    };
    @Output() public filterChanging = new EventEmitter<any>();
    dataFiltered: any[];
    
    public get selectedValue(): any {
        const filter = this.filterByField(this.field);
        return filter ? filter.value : null;
    }

    public get placeholder() {
        return this.defaultText || 'Select item...';
    }
    
    public settings = {
        width: 'auto'
    }

    constructor(filterService: FilterService, private readonly _store: Store) {
        super(filterService);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            this.dataFiltered = this.data;
        }
        if (changes['width']){
            if (this.settings) {
                this.settings.width = changes['width'].currentValue ? changes['width'].currentValue : 'auto';
            }
        }
    }

	public onChange(value: any): void {
		this.filterChanging.emit({ value: value, filter: this.filter });

        this.applyFilter(
            value == null ?
                this.removeFilter(this.field) :
                this.updateFilter({
                    field: this.field,
                    operator: 'eq',
                    value: value
                })
		);
    }
}
