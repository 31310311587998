import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { of } from 'rxjs';
import { switchMap, withLatestFrom, map, catchError, exhaustMap } from 'rxjs/operators';
import { AdminJobsService } from 'src/app/admin/jobs/admin-jobs.service';
import { adminJobsActions } from 'src/app/admin/store/actions/admin-jobs.actions';
import {
    selectAdminJobsGridSearchQuery,
    selectFilteredFacilityGroupId
} from 'src/app/admin/store/selectors/admin-jobs.selectors';
import { ToasterService } from 'src/app/core/services';
import { Utilities } from 'src/app/core/utils';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { SortTypes } from 'src/app/shared/models/enums/sort-types.enum';
import { BaseEffect } from 'src/app/shared/store/base-effect';

@Injectable({
    providedIn: 'root'
})
export class AdminJobsEffects extends BaseEffect {
    getAdminJobs$ = createEffect(() => {
        return this._actions.pipe(
            ofType(
                adminJobsActions.loadAdminJobs,
                adminJobsActions.setFacilityGroupIdFilter,
                adminJobsActions.setVendorCandidatesListSearchQuery,
                adminJobsActions.releaseJobsSuccess
            ),
            withLatestFrom(
                this._store.select(selectAdminJobsGridSearchQuery),
                this._store.select(selectFilteredFacilityGroupId)
            ),
            switchMap(([, query, facilityGroupId]) => {
                const sortCondition =
                    query.sort && query.sort.length
                        ? query.sort.map((q) => {
                              return q;
                          })
                        : query.sort;

                const pagination = {
                    pageSize: query.take,
                    skip: query.skip
                };

                const sortArgs = {
                    sortField: sortCondition && sortCondition[0].field,
                    sortType: sortCondition && (sortCondition[0].dir as SortTypes)
                };

                if (!query.filter) {
                    query.filter = { logic: 'and', filters: [] };
                }

                if (facilityGroupId) {
                    const profileIndex = query.filter.filters.findIndex(
                        (item: FilterDescriptor) => item.field === 'facilityProfileGroupId'
                    );

                    if (profileIndex < 0) {
                        query?.filter?.filters.push({
                            field: 'facilityProfileGroupId',
                            operator: 'eq',
                            value: facilityGroupId
                        });
                    } else {
                        (query.filter.filters[profileIndex] as FilterDescriptor).value = facilityGroupId;
                    }
                } else {
                    const profileIndex = query.filter.filters.findIndex(
                        (item: FilterDescriptor) => item.field === 'facilityProfileGroupId'
                    );

                    if (profileIndex > -1) {
                        query?.filter?.filters.splice(profileIndex, 1);
                    }
                }

                return this._adminJobsService.getAdminJobs(pagination, sortArgs, query.filter).pipe(
                    map((result) =>
                        adminJobsActions.loadAdminJobsSuccess({
                            jobs: result.data,
                            filteredAllPositionsCount: result.filteredAllPositionsCount,
                            filteredOpenPositionsCount: result.filteredOpenPositionsCount,
                            total: result.total
                        })
                    ),
                    catchError((error: unknown) => of(adminJobsActions.loadAdminJobsFailure({ error })))
                );
            })
        );
    });

    getAdminJobLookups$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.loadAdminJobLookups),
            exhaustMap(() => {
                return this._adminJobsService.getAdminJobLookups().pipe(
                    map((result) => adminJobsActions.loadAdminJobLookupsSuccess({ lookups: result })),
                    catchError((error: unknown) => of(adminJobsActions.loadAdminJobLookupsFailure({ error })))
                );
            })
        );
    });

    getJobById$ = createEffect(() => {
        return this._actions.pipe(
            ofType(
                adminJobsActions.getJobById,
                adminJobsActions.updateVendorBaseRateSuccess,
                adminJobsActions.releaseAllJobPositionsSuccess,
                adminJobsActions.releaseJobPositionSuccess
            ),
            exhaustMap((action) => {
                return this._adminJobsService.getJobById(action.jobId).pipe(
                    map((result) => adminJobsActions.getJobByIdSuccess({ jobDetails: result?.job })),
                    catchError((error: unknown) => of(adminJobsActions.getJobByIdFailure({ error })))
                );
            })
        );
    });

    updateVendorBaseRate$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.updateVendorBaseRate),
            exhaustMap((action) => {
                return this._adminJobsService.updateVendorBaseRate(action.jobId, action.vendorBaseRate).pipe(
                    map(() => adminJobsActions.updateVendorBaseRateSuccess({ jobId: action.jobId })),
                    catchError((error: unknown) => of(adminJobsActions.updateVendorBaseRateFailure({ error })))
                );
            })
        );
    });

    exportAllAdminJobs$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.exportAdminJobs),
            withLatestFrom(
                this._store.select(selectAdminJobsGridSearchQuery),
                this._store.select(selectFilteredFacilityGroupId)
            ),
            exhaustMap(([, query, facilityGroupId]) => {
                const sortCondition =
                    query.sort && query.sort.length
                        ? query.sort.map((q) => {
                              return q;
                          })
                        : query.sort;

                const pagination = {
                    pageSize: 0,
                    skip: 0
                };

                const sortArgs = {
                    sortField: sortCondition && sortCondition[0].field,
                    sortType: sortCondition && (sortCondition[0].dir as SortTypes)
                };

                if (!query.filter) {
                    query.filter = { logic: 'and', filters: [] };
                }

                const jobIdsIndex = query.filter.filters.findIndex((item: FilterDescriptor) => item.field === 'JobIds');

                if (jobIdsIndex > -1) {
                    query?.filter?.filters.splice(jobIdsIndex, 1);
                }

                if (facilityGroupId) {
                    query.filter.filters.push({
                        field: 'facilityGroupId',
                        operator: 'eq',
                        value: facilityGroupId
                    });
                }

                const matchArgs = flattenFilter(query.filter);

                return this._adminJobsService.exportAdminJobs(pagination, sortArgs, matchArgs).pipe(
                    map((result) => adminJobsActions.exportAdminJobsSuccess({ result })),
                    catchError((error: unknown) => of(adminJobsActions.exportAdminJobsFailure({ error })))
                );
            })
        );
    });

    exportSelectedAdminJobs$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.exportSelectedAdminJobs),
            withLatestFrom(
                this._store.select(selectAdminJobsGridSearchQuery),
                this._store.select(selectFilteredFacilityGroupId)
            ),
            exhaustMap(([action, query, facilityGroupId]) => {
                const sortCondition =
                    query.sort && query.sort.length
                        ? query.sort.map((q) => {
                              return q;
                          })
                        : query.sort;

                const pagination = {
                    pageSize: 0,
                    skip: 0
                };

                const sortArgs = {
                    sortField: sortCondition && sortCondition[0].field,
                    sortType: sortCondition && (sortCondition[0].dir as SortTypes)
                };

                if (!query.filter) {
                    query.filter = { logic: 'and', filters: [] };
                }

                if (facilityGroupId) {
                    query.filter.filters.push({
                        field: 'facilityGroupId',
                        operator: 'eq',
                        value: facilityGroupId
                    });
                }

                if (action.selectedJobs.length) {
                    query?.filter?.filters.push({
                        field: 'JobIds',
                        operator: 'eq',
                        value: action.selectedJobs
                    });
                }

                const matchArgs = flattenFilter(query.filter);

                return this._adminJobsService.exportAdminJobs(pagination, sortArgs, matchArgs).pipe(
                    map((result) => adminJobsActions.exportAdminJobsSuccess({ result })),
                    catchError((error: unknown) => of(adminJobsActions.exportAdminJobsFailure({ error })))
                );
            })
        );
    });

    getReleaseGroups$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.loadReleaseGroupsByFacilities),
            exhaustMap((action) => {
                return this._adminJobsService.getReleaseGroupsByFacilities(action.facilityIds).pipe(
                    map((result) => adminJobsActions.loadReleaseGroupsByFacilitiesSuccess({ groups: result })),
                    catchError((error: unknown) => of(adminJobsActions.loadReleaseGroupsByFacilitiesFailure({ error })))
                );
            })
        );
    });

    getCanEditVendorBaseRate$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.getCanEditVendorBaseRate),
            exhaustMap(() => {
                return this._adminJobsService.getCanEditVendorBaseRate().pipe(
                    map((result) => adminJobsActions.getCanEditVendorBaseRateSuccess({ canEdit: result })),
                    catchError((error: unknown) => of(adminJobsActions.getCanEditVendorBaseRateFailure({ error })))
                );
            })
        );
    });

    getJobAttachments$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.loadJobAttachments),
            exhaustMap((action) => {
                return this._adminJobsService.getJobAttachments(action.jobId).pipe(
                    map((attachments) => adminJobsActions.loadJobAttachmentsSuccess({ attachments })),
                    catchError((error: unknown) => of(adminJobsActions.loadJobAttachmentsFailure({ error })))
                );
            })
        );
    });

    getJobAttachmentPreview$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.loadJobAttachmentPreview),
            exhaustMap((action) => {
                return this._adminJobsService.getJobAttachmentPreview(action.jobId, action.fileId, action.pageNum).pipe(
                    map((result) => adminJobsActions.loadJobAttachmentPreviewSuccess({ file: result })),
                    catchError((error: unknown) => of(adminJobsActions.loadJobAttachmentPreviewFailure({ error })))
                );
            })
        );
    });

    downloadJobAttachmentFile$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.downloadJobAttachment),
            exhaustMap((action) => {
                return this._adminJobsService.downloadJobAttachment(action.jobId, action.fileId).pipe(
                    map((file) => adminJobsActions.downloadJobAttachmentSuccess({ file, fileName: action.fileName })),
                    catchError((error: unknown) => of(adminJobsActions.downloadJobAttachmentFailure({ error })))
                );
            })
        );
    });

    downloadJobAttachment$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.downloadJobAttachmentSuccess),
                map((result) => Utilities.downloadBlob(result.file, result.fileName))
            );
        },
        { dispatch: false }
    );

    getJobAttachmentTotalPages$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.loadJobAttachmentPreviewTotalPages),
            exhaustMap((action) => {
                return this._adminJobsService.getJobAttachmentPreviewTotalPages(action.jobId, action.fileId).pipe(
                    map((total) => adminJobsActions.loadJobAttachmentPreviewTotalPagesSuccess({ totalPages: total })),
                    catchError((error: unknown) =>
                        of(adminJobsActions.loadJobAttachmentPreviewTotalPagesFailure({ error }))
                    )
                );
            })
        );
    });

    releaseJobs$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.releaseJobs),
            exhaustMap((action) => {
                return this._adminJobsService.releaseJobs(action.entityIds, action.vendorIds).pipe(
                    map(() => adminJobsActions.releaseJobsSuccess({ numOfReleasedJobs: action.entityIds.length })),
                    catchError((error) => of(adminJobsActions.releaseJobsFailure({ error })))
                );
            })
        );
    });

    releaseAllJobs$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.releaseAllJobs),
            exhaustMap((action) => {
                return this._adminJobsService.releaseJobs(action.jobIds, action.vendorIds).pipe(
                    map(() => adminJobsActions.releaseJobsSuccess({ numOfReleasedJobs: action.jobIds.length })),
                    catchError((error) => of(adminJobsActions.releaseJobsFailure({ error })))
                );
            })
        );
    });

    releaseAllJobsPositions$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.releaseAllJobsPositions),
            exhaustMap((action) => {
                return this._adminJobsService.releaseAllJobsPositions(action.jobIds, action.vendorIds).pipe(
                    map(() => adminJobsActions.releaseAllJobsPositionsSuccess()),
                    catchError((error) => of(adminJobsActions.releaseAllJobsPositionsFailure({ error })))
                );
            })
        );
    });

    releaseAllJobsPositionsSuccessfully$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.releaseAllJobsPositionsSuccess),
                map((action) => {
                    this.handleSuccess('All positions were released successfully.');
                })
            );
        },
        { dispatch: false }
    );

    releaseAllJobsPositionsFailure$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.releaseAllJobsPositionsFailure),
                map(() => this.handleStringError('Error occurred while release job positions'))
            );
        },
        { dispatch: false }
    );

    releaseAllJobPositions$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.releaseAllJobPositions),
            exhaustMap((action) => {
                return this._adminJobsService.releaseAllJobPositions(action.jobId, action.vendorIds).pipe(
                    map(() => adminJobsActions.releaseAllJobPositionsSuccess({ jobId: action.jobId })),
                    catchError((error) => of(adminJobsActions.releaseAllJobPositionsFailure({ error })))
                );
            })
        );
    });

    releaseAllJobPositionsSuccessfully$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.releaseAllJobPositionsSuccess),
                map((action) => {
                    this.handleSuccess('All positions were released successfully.');
                })
            );
        },
        { dispatch: false }
    );

    releaseAllJobPositionsFailure$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.releaseAllJobPositionsFailure),
                map(() => this.handleStringError('Error occurred while release job positions'))
            );
        },
        { dispatch: false }
    );

    releaseJobPosition$ = createEffect(() => {
        return this._actions.pipe(
            ofType(adminJobsActions.releaseJobPosition),
            exhaustMap((action) => {
                return this._adminJobsService
                    .releaseJobPosition(action.jobId, action.jobPositionId, action.vendorIds)
                    .pipe(
                        map(() => adminJobsActions.releaseJobPositionSuccess({ jobId: action.jobId })),
                        catchError((error) => of(adminJobsActions.releaseJobPositionFailure({ error })))
                    );
            })
        );
    });

    releaseJobPositionSuccessfully$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.releaseJobPositionSuccess),
                map((action) => {
                    this.handleSuccess('1 position was released successfully.');
                })
            );
        },
        { dispatch: false }
    );

    releaseJobPositionFailure$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.releaseJobPositionFailure),
                map(() => this.handleStringError('Error occurred while release job position'))
            );
        },
        { dispatch: false }
    );

    updateVendorBaseRateSuccess$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.updateVendorBaseRateSuccess),
                map(() => this.handleSuccess('Successfully updated vendor rate.'))
            );
        },
        { dispatch: false }
    );

    updateVendorBaseRateFailure$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.updateVendorBaseRateFailure),
                map(() => this.handleStringError('Failed to update vendor rate.'))
            );
        },
        { dispatch: false }
    );

    releaseJobsSuccessfully$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.releaseJobsSuccess),
                map((action) => {
                    const jobsText = action.numOfReleasedJobs > 1 ? 'jobs were' : 'job was';
                    this.handleSuccess(`${action.numOfReleasedJobs} ${jobsText} released successfully.`);
                })
            );
        },
        { dispatch: false }
    );

    releaseJobsFailure$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.releaseJobsFailure),
                map(() => this.handleStringError('Error occurred while release jobs'))
            );
        },
        { dispatch: false }
    );

    downloadExportsJobs$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(adminJobsActions.exportAdminJobsSuccess),
                map((action) => Utilities.downloadBlob(action.result, 'Jobs.xlsx'))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _store: Store,
        private readonly _actions: Actions,
        private readonly _adminJobsService: AdminJobsService,
        protected readonly _toasterService: ToasterService
    ) {
        super(_toasterService);
    }
}
