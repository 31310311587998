import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { VendorContact, VendorEmail } from 'src/app/shared/models';
import { isEmpty } from 'src/app/shared/utilities';
import { VendorContactEmailType } from 'src/app/admin/vendor-contact-details/models';

export const businessEmailRequired = (contact: VendorContact): ValidationErrors | null => {
    if (!contact.user || !contact.user.isActive) {
        return null;
    } else if (!contact.vendorContactEmails || contact.vendorContactEmails.length === 0) {
        return { businessEmailRequired: true };
    } else {
        const hasNoValidBusinessEmail =
            contact.vendorContactEmails.filter((email: VendorEmail) => {
                const hasValidEmailAddress =
                    !isEmpty(email.emailAddress) &&
                    Validators.email(new UntypedFormControl(email.emailAddress)) === null;
                const isBusinessType = email.vendorContactEmailTypeID === VendorContactEmailType.Business;
                return hasValidEmailAddress && isBusinessType;
            }).length === 0;

        return hasNoValidBusinessEmail ? { businessEmailRequired: true } : null;
    }
};
