import { Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
    selector: 'kendo-field',
    template: `
        <label class="k-form-field">
            <span>
                {{ to.label }}
                <ng-container *ngIf="field.props.required">
                    <span class="k-required">*</span>
                </ng-container>
                <formly-help-text [helpText]="props.helpText"></formly-help-text>
            </span>
            <input
                class="k-textbox"
                type="{{ field.props.type }}"
                [formControl]="formControl" />
            <validation-message
                [label]="to.label"
                [control]="formControl"
                [useConstantCustomMessage]="false">
            </validation-message>
        </label>
    `,
    styleUrls: ['./kendo-field.component.scss']
})
export class KendoFieldComponent extends FieldType<FieldTypeConfig> {}
