import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { loadStatesFail, loadStates, loadStatesSuccess, componentLoaded } from '../perdiemscheduler.actions';

@Injectable()
export class PerdiemSchedulerStatesEffect extends BaseEffect {
    getStateList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadStates, componentLoaded),
            switchMap(() =>
                this.lookupService.getStates(null, true).pipe(
                    map((response) =>
                        loadStatesSuccess({
                            states: response
                                .map((state) => ({ id: state.id, name: state.code }))
                                .filter((state) => state.name)
                        })
                    ),
                    catchError((error: unknown) => of(loadStatesFail({ error })))
                )
            )
        );
    });

    getStateListFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadStatesFail),
                map((action) => action.error),
                tap((error: HttpErrorResponse) => this.handleError(error))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly lookupService: LookupsService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
