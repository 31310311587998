import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'iconType'
})
export class IconTypePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }
        if (value.startsWith('fa-')) {
            return 'fas';
        }
        if (value.startsWith('svg_')) {
            return 'svg';
        }
        return 'mat';
    }
}
