import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, inject } from '@angular/core';
import { DocumentTypeConfiguration } from '../../models/requirements/document-type-configuration.model';

export interface ViewRequirementsDetailsDialogData {
    title: string;
    requirements: DocumentTypeConfiguration[];
}
@Component({
    selector: 'app-view-requirement-details-dialog',
    templateUrl: './view-requirement-details-dialog.component.html',
    styleUrls: ['./view-requirement-details-dialog.component.scss']
})
export class ViewRequirementDetailsDialogComponent {
    readonly data = inject<ViewRequirementsDetailsDialogData>(MAT_DIALOG_DATA);
}
