import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/facilities-list.actions';
import { FacilitiesListState, getInitialFacilitiesListState } from 'src/app/facilities/store/state/facilities-list.state';
import { LoadingTypes } from 'src/app/shared/models';
import { getEmptyPagingToken } from 'src/app/shared/models/paging-token';

export function facilitiesListReducer(state: FacilitiesListState | undefined, action: Action) {
    return createReducer(
        getInitialFacilitiesListState(),

        //load facilities list
        on(actions.loadFacilitiesList,
            (state, action): FacilitiesListState => ({ ...state, facilities: getEmptyPagingToken(), loadingState: LoadingTypes.LOADING })),

        on(actions.loadFacilitiesListSuccess,
            (state, action): FacilitiesListState => ({ ...state, facilities: action.facilities, loadingState: LoadingTypes.LOADED, error: '' })),

        on(actions.loadFacilitiesListFailure,
            (state, action): FacilitiesListState => ({ ...state, facilities: getEmptyPagingToken(), loadingState: LoadingTypes.FAIL, error: action.error })),

        on(actions.setFacilitiesListSearchQuery,
            (state, action): FacilitiesListState => ({ ...state, gridSearchQuery: action.searchQuery, loadingState: LoadingTypes.LOADING })),

        on(actions.clearFacilitiesList,
            (state, action): FacilitiesListState => ({ ...state, facilities: getEmptyPagingToken() })),

        //export facilities list
        on(actions.exportFacilitiesList,
            (state, action): FacilitiesListState => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.exportFacilitiesListSuccess,
            (state, action): FacilitiesListState => ({ ...state, loadingState: LoadingTypes.LOADED, error: '' })),

        on(actions.exportFacilitiesListFailure,
            (state, action): FacilitiesListState => ({ ...state, loadingState: LoadingTypes.FAIL, error: action.error })),

        on(actions.loadFacilitiesFeatures,
            (state, action): FacilitiesListState => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.loadFacilitiesFeaturesSuccess,
            (state, action): FacilitiesListState => ({ ...state, loadingState: LoadingTypes.LOADED, features: action.features, error: '' })),

        on(actions.loadFacilitiesFeaturesFailure,
            (state, action): FacilitiesListState => ({ ...state, loadingState: LoadingTypes.FAIL, error: action.error })),
    )(state, action);
}
