import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, Settings } from '../../config/settings';
import { Observable } from 'rxjs';
import { FacilityLookups } from '../models/facility-lookups.model';

export enum FacilityLookupsUrls {
    GetFacilityLookups = '/AyaConnect/Client/Shift/Lookups/facility-lookups'
}

@Injectable({
    providedIn: 'root'
})
export class FacilityLookupsService {
    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly settings: Settings) {}

    getFacilityLookups(): Observable<FacilityLookups> {
        return this.http.get<FacilityLookups>(`${this.settings.CORE}${FacilityLookupsUrls.GetFacilityLookups}`);
    }
}
