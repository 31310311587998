import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { State } from 'src/app/shared/grid/models/state.model';
import { ListItem } from 'src/app/shared/models/list-item';
import { Expertise } from 'src/app/shared/models/shifts/expertise.model';
import {
    selectAdminVendorCandidateExpertises,
    selectAdminVendorCandidatesListIsLoading,
    selectAdminVendorCandidatesListQuery,
    selectAdminVendorCandidatesListView,
    selectAdminVendorCandidatesProfessions,
    selectAdminVendorCandidateStates,
    selectAdminVendors
} from 'src/app/admin/store/selectors/admin.vendor-candidates.selectors';
import {
    exportAdminVendorCandidatesList,
    loadAdminVendorCandidateExpertises,
    loadAdminVendorCandidateProfessions,
    loadAdminVendorCandidatesList,
    loadAdminVendorCandidateStates,
    loadAdminVendorCandidateVendors,
    setVendorCandidatesListSearchQuery
} from 'src/app/admin/store/actions/admin.vendor-candidates-list.actions';
import { VendorCandidate } from 'src/app/admin/vendor-candidates/vendor-candidate.model';

@Component({
    selector: 'ayac-vendor-candidates',
    templateUrl: './vendor-candidates.component.html',
    styleUrls: ['./vendor-candidates.component.scss']
})
export class VendorCandidatesComponent implements OnInit {
    vendorCandidates$: Observable<GridDataResult>;
    vendorCandidatesQuery$: Observable<GridSearchQuery>;
    vendorExpertises$: Observable<Expertise[]>;
    vendorStates$: Observable<State[]>;
    vendors$: Observable<ListItem[]>;
    vendorProfessions$: Observable<ListItem[]>;
    isLoading$: Observable<boolean>;
    filtered = 0;

    constructor(private readonly _store$: Store<{}>, private readonly _router: Router) {}

    ngOnInit(): void {
        this.vendorCandidates$ = this._store$.select(selectAdminVendorCandidatesListView).pipe(
            tap((result) => {
                this.filtered = result.data.length;
            })
        );
        this.isLoading$ = this._store$.select(selectAdminVendorCandidatesListIsLoading);
        this.vendorCandidatesQuery$ = this._store$.select(selectAdminVendorCandidatesListQuery);
        this.vendorExpertises$ = this._store$.select(selectAdminVendorCandidateExpertises);
        this.vendorStates$ = this._store$.select(selectAdminVendorCandidateStates);
        this.vendors$ = this._store$.select(selectAdminVendors);
        this.vendorProfessions$ = this._store$.select(selectAdminVendorCandidatesProfessions);

        this._store$.dispatch(loadAdminVendorCandidatesList());
        this._store$.dispatch(loadAdminVendorCandidateExpertises());
        this._store$.dispatch(loadAdminVendorCandidateStates());
        this._store$.dispatch(loadAdminVendorCandidateVendors());
        this._store$.dispatch(loadAdminVendorCandidateProfessions());
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this._store$.dispatch(
            setVendorCandidatesListSearchQuery({
                searchQuery: state
            })
        );
    }

    refreshGrid(): void {
        this._store$.dispatch(loadAdminVendorCandidatesList());
    }

    addNewCandidate(): void {
        this._router.navigateByUrl('/admin/vendorCandidate/new');
    }

    onRowClicked(item: VendorCandidate): void {
        this._router.navigateByUrl(`/admin/vendorCandidate/${item.id}`);
    }

    exportExcel(): void {
        this._store$.dispatch(exportAdminVendorCandidatesList());
    }
}
