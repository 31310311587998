import { AbstractControl, ValidationErrors } from '@angular/forms';
import { VendorType, VendorTypeTags } from 'src/app/shared/models/vendor-type.model';

export const vendorTagValidatorKey = 'tag-dependencies';

export interface VendorTagDependency {
    tagName: string;
    dependencies: string[];
}

// Here, we define tags dependencies.
const _tagsDependencies: VendorTagDependency[] = [
    {
        tagName: VendorTypeTags.SHIFTS_APP,
        dependencies: [VendorTypeTags.PER_DIEM]
    }
];

export function vendorTagsValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }
    const controlValue: VendorType[] = control.value;
    const controlTags = controlValue.map((tag) => tag.type);
    const dependenciesRequired = [];

    for (const tag of _tagsDependencies) {
        if (controlTags.includes(tag.tagName)) {
            for (const depTag of tag.dependencies) {
                if (!controlTags.includes(depTag)) {
                    dependenciesRequired.push(tag);
                }
            }
        }
    }

    if (dependenciesRequired.length) {
        return {
            [vendorTagValidatorKey]: dependenciesRequired
        };
    }

    return null;
}
