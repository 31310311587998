import { Action, createReducer, on } from '@ngrx/store';
import { ScheduleTypes } from '../../shift-resource-fulfillment-scheduler/shift-resource-fulfillment-scheduler.component';
import * as shiftsActions from '../actions';

export interface ShiftBonusAssignmentState {
    isInternal: boolean;
    scheduleType: ScheduleTypes;
    bonusRateId?: number;
}

export const initialState: ShiftBonusAssignmentState = {
    isInternal: true,
    scheduleType: ScheduleTypes.settings,
    bonusRateId: undefined
};

const reducer = createReducer(
    initialState,
    on(shiftsActions.setShiftVisibility, (state, action) => ({ ...state, isInternal: action.isInternal })),
    on(shiftsActions.setScheduleType, (state, action) => ({ ...state, scheduleType: action.scheduleType })),
    on(shiftsActions.setBonusRate, (state, action) => ({ ...state, bonusRateId: action.bonusRateId }))
);

export const shiftBonusAssignmentReducer = (state: ShiftBonusAssignmentState | undefined, action: Action) =>
    reducer(state, action);
