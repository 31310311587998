import { Input, Directive } from '@angular/core';
import { FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, isCompositeFilterDescriptor } from '@progress/kendo-data-query';

@Directive()
export abstract class FilterBase {
	get emptyFilterDescriptor(): CompositeFilterDescriptor {
		return {
			logic: "and",
			filters: []
		}
	}
	isChanged: boolean = false;

	@Input() field: string;
	@Input() filterService: FilterService;
	@Input() filter: CompositeFilterDescriptor;
	@Input() submitOnChange: boolean = true;
	@Input() labelBefore = 'Before';
	@Input() labelAfter = 'After';

	getCurrentValue(): any {
		if (this.filter && this.filter.filters && this.filter.filters.length) {
			var filter = this.filter.filters[0];
			if (!isCompositeFilterDescriptor(filter)) {
				return filter.value;
			}
		}
		return null;
	}

	getFilter(operator?: string, field?: string): FilterDescriptor {
		const currentFilter = this.getFilters(operator, field)[0];
		return currentFilter;
	}

	getFilters(operator?: string, field?: string): FilterDescriptor[] {
		return this.flatten(this.filter || {} as CompositeFilterDescriptor).filter((x: FilterDescriptor) => x.field === (field || this.field) && (!operator || x.operator === operator));
	}

	getLatestFilter(operator?: string, field?: string): FilterDescriptor {
		const filters = this.getFilters(operator, field);
		return filters && filters.length > 0 ? filters[filters.length - 1] : null;
	}

	getLatestFilterValue(operator?: string, field?: string): any {
		const latestFilter = this.getLatestFilter(operator, field);
		return latestFilter ? latestFilter.value : null;
	}

	updateFilter(filter: FilterDescriptor): CompositeFilterDescriptor {
		this.isChanged = true;
		this.filter = this.filter || {
			filters: [],
			logic: "and"
		};
		const currentFilter = this.getLatestFilter(filter.operator as string, filter.field as string);
		if (currentFilter == null) {
			this.filter.filters.push(filter);
		}
		else {
			Object.assign(currentFilter, filter);
		}
		if (this.submitOnChange) {
			this.submitFilter();
		}
		return this.filter;
	}

	submitFilter(): void {
		this.isChanged = false;
		this.filterService.filter(Object.assign({}, this.filter));
	}

	removeFilter(field?: string): CompositeFilterDescriptor {
		this.isChanged = true;
		this.trimFilterByField(this.filter, field || this.field);
		if (this.submitOnChange) {
			this.submitFilter();
		}
		return this.filter;
	}

	flatten(filter: CompositeFilterDescriptor) {
		const filters = filter.filters;
		if (filters) {
			return filters.reduce((acc, curr: CompositeFilterDescriptor) => acc.concat(curr.filters ? this.flatten(curr) : [curr]), []);
		}
		return [];
	}

	trimFilterByField(filter: CompositeFilterDescriptor, field: string): void {
		if (filter != null && filter.filters != null) {
			filter.filters = filter.filters.filter((x: CompositeFilterDescriptor) => {
				if (isCompositeFilterDescriptor(x)) {
					this.trimFilterByField(x, field);
					return x.filters.length;
				}
				else {
					return field !== (x as FilterDescriptor).field as string;
				}
			});
		}
	}
}
