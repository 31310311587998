import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { LoadingTypes } from 'src/app/shared/models';
import { FileType } from 'src/app/shared/models/file-type.model';

export const selectVendorFileFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectVendorFiles = createSelector(selectVendorFileFeature, (state) => state.vendorFileState.vendorFiles);

export const selectVendorFilesLoading = createSelector(
    selectVendorFileFeature,
    (state) => state.vendorFileState.loading === LoadingTypes.LOADING
);

export const selectVendorFilesSearchQuery = createSelector(
    selectVendorFileFeature,
    (state) => state.vendorFileState.gridSearchQuery
);

export const selectVendorFileIsDeleting = createSelector(
    selectVendorFileFeature,
    (state): boolean => state.vendorFileState.isDeleting
);

export const selectVendorFilesDeletingFileIds = createSelector(
    selectVendorFileFeature,
    (state): number[] => state.vendorFileState.deletingFileIds
);

export const selectVendorFilesDeleteSuccess = createSelector(
    selectVendorFileFeature,
    (state): boolean => state.vendorFileState.isDeleteSuccess
);

export const selectFileTypes = createSelector(selectVendorFileFeature, (state) =>
    state.vendorFileState.fileTypes.map((fileType) => {
        return {
            id: fileType.id,
            name: fileType.type
        } as FileType;
    })
);
