import { createFeatureSelector, createSelector } from "@ngrx/store";
import { coreStoreKey, CoreStoreState } from "src/app/core/state/core.state";
import { LoadingTypes } from 'src/app/shared/models';

const selectFeature = createFeatureSelector<CoreStoreState>(coreStoreKey);

export const selectClientSharedData = createSelector(
    selectFeature,
    (state) => state.clientSharedData.data
)

export const selectIsSharedDataLoaded = createSelector(
    selectFeature,
    (state) => state.clientSharedData.loading === LoadingTypes.LOADED
)
