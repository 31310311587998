import { Component, Inject, OnInit } from '@angular/core';
import { Editor, Validators } from 'ngx-editor';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FormControl, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/core/services';
import { SubmittalActivity } from "src/app/submittals/models";
import { SubmittalNoteType } from "src/app/submittals/models/submittal-note-type.enum";
import { DateHelper } from "src/app/shared/utilities";

@Component({
    selector: 'ayac-submittal-status-update-dialog',
    templateUrl: './submittal-add-note-dialog.component.html',
    styleUrls: ['./submittal-add-note-dialog.component.scss']
})
export class SubmittalAddNoteDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    editor!: Editor;
    localTimeZoneName = '';
    maxCharacterLength = 2000;
    // The max character length for the Submittals.SubmittalNotes.Note column in the database where this note is saved
    // is 4000 characters. Text with the submittal status is prepended to the note prior to it being saved, which is why this
    // 100 characters lower
    maxRichTextCharacterLength = 3900;
    noteType = SubmittalNoteType;
    editorForm: FormGroup = new FormGroup({
        note: new FormControl('', [Validators.maxLength(this.maxCharacterLength)])
    });

    constructor(
        public readonly dialogRef: MatDialogRef<SubmittalAddNoteDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { title: string; description: string; activity: SubmittalActivity; confirmButtonText: string; required: boolean; source: string },
        public toasterService: ToasterService
    ) {
        super();
    }

    ngOnInit(): void {
        this.editor = new Editor();
        this.localTimeZoneName = DateHelper.getLocalTimezoneName();
        if (this.data.required) {
            this.editorForm.get('note')?.addValidators([Validators.required()]);
        }
    }

    updateSubmittalStatus(confirm: boolean) {
        const note = this.editorForm.get('note').value;
        if (note.length > this.maxRichTextCharacterLength) {
            this.toasterService.fail(
                'Note exceeds maximum character limit. Please reduce note length and/or note formatting.'
            );
        } else {
            this.dialogRef.close({
                updateStatus: confirm,
                note
            });
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.editor?.destroy();
    }
}
