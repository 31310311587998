import { resourcesReducer } from './resources.reducer';
import { resourcesLookupsReducer } from './resources-lookups.reducer';
import { resourceDetailsReducer } from './resource-details.reducer';
import { systemReducer } from './system.reducer';
import { resourceAssignmentsReducer } from './resource-assignments.reducer';
import { resourceDocumentsReducer } from './resource-documents.reducer';
import { facilityLookupsReducer } from './resource-facility-lookups.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { InternalPoolState } from 'src/app/internal-pool/store/internal-pool.state';
import { securityLookupsReducer } from 'src/app/dashboard-new/store/reducers/security-lookups.reducer';

export const internalPoolReducer: ActionReducerMap<InternalPoolState> = {
    resources: resourcesReducer,
    lookups: resourcesLookupsReducer,
    details: resourceDetailsReducer,
    system: systemReducer,
    securityLookups: securityLookupsReducer,
    assignments: resourceAssignmentsReducer,
    documents: resourceDocumentsReducer,
    facilityLookups: facilityLookupsReducer,
}
