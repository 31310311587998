import { createAction, props } from '@ngrx/store';
import { CandidateShiftsAppState } from 'src/app/shared/models/candidate';

export const loadVendorCandidateShiftsApp = createAction(
    '[VENDOR CANDIDATES PROFILE] Load Vendor Candidate Shifts App',
    props<{ candidateId: number }>()
);

export const loadVendorCandidateShiftsAppFailure = createAction(
    '[VENDOR CANDIDATES PROFILE] Load Vendor Candidate Shifts App Failure',
    props<{ error: string }>()
);

export const loadVendorCandidateShiftsAppSuccess = createAction(
    '[VENDOR CANDIDATES PROFILE] Load Vendor Candidate Shifts App Success',
    props<{ data: CandidateShiftsAppState }>()
);
export const sendInviteVendorСandidateShiftsAppConfirmation = createAction(
    '[VENDOR CANDIDATES PROFILE] Vendor Сandidate Shifts App Send Invite Confirmation',
    props<{ candidateId: number }>()
);

export const sendInviteVendorСandidateShiftsApp = createAction(
    '[VENDOR CANDIDATES PROFILE] Vendor Сandidate Shifts App Send Invite',
    props<{ candidateId: number }>()
);

export const sendInviteVendorСandidateShiftsAppFailure = createAction(
    '[VENDOR CANDIDATES PROFILE] Vendor Сandidate Shifts App Send Invite Failure',
    props<{ error: string }>()
);

export const sendInviteVendorСandidateShiftsAppSuccess = createAction(
    '[VENDOR CANDIDATES PROFILE] Vendor Сandidate Shifts App Send Invite Success'
);

export const loadSubmittalJobDetails = createAction(
    '[VENDOR CANDIDATES PROFILE] Load Submittal Job Details',
    props<{ jobId: number }>()
);

export const loadSubmittalJobDetailsSuccess = createAction(
    '[VENDOR CANDIDATES PROFILE] Load Submittal Job Details Success',
    props<{ hospitalTypeId: number }>()
);

export const loadSubmittalJobDetailsFailure = createAction(
    '[VENDOR CANDIDATES PROFILE] Load Submittal Job Details Failure',
    props<{ error: string }>()
);

export const clearSubmittalDetails = createAction('[VENDOR CANDIDATES PROFILE] Clear Submittal Job Details');
