import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { ShiftListItem } from 'src/app/shifts/models/shift-list-item.model';
import {
    loadShifts,
    loadShiftsSuccess,
    loadShiftsFail,
    componentLoaded,
    reloadShifts,
    setPerdiemSchedulerSearchQuery
} from '../perdiemscheduler.actions';

export interface PerdiemSchedulerListState {
    list: ShiftListItem[];
    total: number;
    loading: LoadingTypes;
}

export const getInitialPerdiemSchedulerListState = (): PerdiemSchedulerListState => ({
    list: [],
    total: 0,
    loading: LoadingTypes.INIT
});

export function perdiemSchedulerListReducer(state: PerdiemSchedulerListState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerListState(),
        on(
            loadShifts,
            componentLoaded,
            reloadShifts,
            setPerdiemSchedulerSearchQuery,
            (currentState): PerdiemSchedulerListState => ({
                ...currentState,
                loading: LoadingTypes.LOADING
            })
        ),

        on(
            loadShiftsSuccess,
            (currentState, action): PerdiemSchedulerListState => ({
                ...currentState,
                list: action.shifts,
                total: action.total,
                loading: LoadingTypes.LOADED
            })
        ),

        on(
            loadShiftsFail,
            (currentState): PerdiemSchedulerListState => ({
                ...currentState,
                loading: LoadingTypes.LOADED
            })
        )
    )(state, action);
}

export function reducer(state: PerdiemSchedulerListState | undefined, action: Action) {
    return perdiemSchedulerListReducer(state, action);
}
