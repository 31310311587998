import { ResourcesEffects } from './resources.effects';
import { ResourcesLookupsEffect } from './resources-lookups.effect';
import { ResourceDetailsEffect } from './resource-details.effects';
import { EditResourceEffect } from './edit-resources.effects';
import { ResourceInviteEffect } from './resource-invite.effects';
import { SystemEffect } from './system.effects';
import { ResourceAssignmentsEffect } from './resource-assignments.effects';
import { ResourcesDocumentsEffect } from './resource-documents.effects';
import { ResourceArchiveEffect } from './resource-archive.effects';

export const internalPoolEffects = [
    ResourcesEffects,
    ResourcesLookupsEffect,
    ResourceDetailsEffect,
    EditResourceEffect,
    ResourceInviteEffect,
    SystemEffect,
    ResourceAssignmentsEffect,
    ResourcesDocumentsEffect,
    ResourceArchiveEffect
];
