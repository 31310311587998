import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/facility-profile.actions';
import { FacilityProfileState, getInitialFacilityProfileState } from 'src/app/facilities/store/state/facility-profile.state';
import { LoadingTypes } from 'src/app/shared/models';

export function facilityProfileReducer(state: FacilityProfileState | undefined, action: Action) {
    return createReducer(
        getInitialFacilityProfileState(),

        on(actions.loadFacilityProfile,
            (): FacilityProfileState => ({ ...getInitialFacilityProfileState(), loadingState: LoadingTypes.LOADING })),

        on(actions.loadFacilityProfileSuccess,
            (state, action): FacilityProfileState => ({ ...state, facilityProfile: action.profile, loadingState: LoadingTypes.LOADED })),

        on(actions.loadFacilityProfileFailure,
            (state): FacilityProfileState => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.updateTermSheetRequired,
            (state, action): FacilityProfileState => ({ ...state, facilityProfile: { ...state.facilityProfile, termSheetRequired: action.isRequired }, loadingState: LoadingTypes.LOADING })),

        on(actions.updateTermSheetRequiredSuccess,
            (state): FacilityProfileState => ({ ...state, loadingState: LoadingTypes.LOADED })),

        on(actions.updateTermSheetRequiredFailure,
            (state): FacilityProfileState => ({ ...state, loadingState: LoadingTypes.FAIL })),

    )(state, action);
}
