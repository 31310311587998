/**angular */
import { Injectable } from '@angular/core';

/**ngrx and rxjs */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { VendorService } from 'src/app/admin/vendor-details/profile/services/vendor.service';

/**local */
import * as selectors from '../selectors/vendor-locations.selectors';
import * as actions from '../actions/vendor-locations.actions';

@Injectable()
export class VendorProfileLocationsEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store,
        private readonly _vendorService: VendorService
    ) {}

    getVendorProfileLocations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(
                actions.loadVendorProfileLocations,
                actions.addVendorProfileLocationsSuccess,
                actions.deleteVendorProfileLocationsSuccess,
                actions.updateVendorProfileLocationsSuccess
            ),
            exhaustMap((x) => {
                return this._vendorService.getLocations(x.vendorId).pipe(
                    map((result) =>
                        actions.loadVendorProfileLocationsSuccess({ response: result, vendorId: x.vendorId })
                    ),
                    catchError((error) => of(actions.loadVendorProfileLocationsFailure({ error })))
                );
            })
        )
    );

    getLocationTypes$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadLocationTypes),
            exhaustMap((x) => {
                return this._vendorService.getLocationTypes().pipe(
                    map((result) => actions.loadLocationTypesSuccess({ locationTypes: result })),
                    catchError((error) => of(actions.loadLocationTypesFailure({ error })))
                );
            })
        )
    );

    addLocations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.addVendorProfileLocations),
            withLatestFrom(this._store$.select(selectors.selectVendorProfileLocationsToAdd)),
            exhaustMap(([action, locations]) => {
                const adds = locations.map((item) => {
                    item.vendorId = action.vendorId;
                    return this._vendorService.addVendorLocation(item);
                });

                return forkJoin(adds).pipe(
                    map(() => actions.addVendorProfileLocationsSuccess({ vendorId: action.vendorId })),
                    catchError((error) => of(actions.addVendorProfileLocationsFailure({ error })))
                );
            })
        )
    );

    updateLocations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.updateVendorProfileLocations),
            withLatestFrom(this._store$.select(selectors.selectVendorProfileLocationsToUpdate)),
            exhaustMap(([action, locations]) => {
                const updates = locations.map((item) => this._vendorService.updateVendorLocation(item));

                return forkJoin(updates).pipe(
                    map(() => actions.updateVendorProfileLocationsSuccess({ vendorId: action.vendorId })),
                    catchError((error) => of(actions.updateVendorProfileLocationsFailure({ error })))
                );
            })
        )
    );

    deleteLocationss$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.deleteVendorProfileLocations),
            withLatestFrom(this._store$.select(selectors.selectVendorProfileLocationsToDelete)),
            exhaustMap(([action, locations]) => {
                const deletes = locations.map((item) => this._vendorService.deleteVendorLocation(item.id));

                return forkJoin(deletes).pipe(
                    map(() => actions.deleteVendorProfileLocationsSuccess({ vendorId: action.vendorId })),
                    catchError((error) => of(actions.deleteVendorProfileLocationsFailure({ error })))
                );
            })
        )
    );
}
