import { Pipe, PipeTransform } from '@angular/core';
import { Unit } from 'src/app/clinical/models';

@Pipe({ name: 'specialtiesName' })
export class SpecialtiesNamePipe implements PipeTransform {
    transform(unit: Unit) {
        if (unit && unit.specialties && unit.specialties.length > 0) {
            return unit.specialties.map((t) => t.specialtyName).join(', ');
        } else {
            return '';
        }
    }
}
