import { AdminClientListItem } from 'src/app/admin-clients/models/admin-clients.model';
import { LoadingTypes } from 'src/app/shared/models';
import { getEmptyPagingToken, PagingToken } from 'src/app/shared/models/paging-token';
import { AdminClientFacilityProfileGroup, AdminClientFacilities } from '../../models/admin-clients-facilities.model';

export interface AdminClientDetailState {
    loadingState: LoadingTypes;
    adminClient: PagingToken<AdminClientListItem[]>;
    error: string;
    facilityLookup: AdminClientFacilities[];
    facilityProfileGroup: AdminClientFacilityProfileGroup[];
    legacyId?: number;
    isLegacyId?: boolean;
}

export const getInitialAdminClientDetailState = (): AdminClientDetailState => ({
    loadingState: LoadingTypes.INIT,
    adminClient: getEmptyPagingToken<AdminClientListItem>(),
    error: '',
    facilityLookup: [],
    facilityProfileGroup: [],
    legacyId: null,
    isLegacyId: false
});
