import { createSelector } from '@ngrx/store';
import { selectFeature } from './client.selectors';
import { ClientState } from '../state/client.state';

export const selectInbox = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.inbox
);

export const selectLoadingInbox = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.loadingInbox
);

export const selectLoadingThreadList = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.loadingThreadList
);

export const selectUnreadThreadsCount = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.totalUnreadThreads
);

export const selectInboxStats = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.inboxStats
);

export const selectFacilities = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.facilities
);

export const selectThread = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.selectedThread
);

export const selectThreadState = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.selectedThreadState
);

export const selectOnlyShowUnread = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.onlyShowUnread
);

export const selectSetupComplete = createSelector(
    selectFeature,
    (clientState: ClientState) => clientState.messageCenterState.setupComplete
);
