import { PerDiemSchedulerDetailsEffects } from 'src/app/vendor-perdiemscheduler/store/effects/perdiemscheduler-details.effects';
import { PerdiemSchedulerCancelReasonsEffect } from './perdiemscheduler-cancel-reasons.effect';
import { PerdiemSchedulerCandidatesEffect } from './perdiemscheduler-candidates.effect';
import { PerdiemSchedulerFacilitiesEffect } from './perdiemscheduler-facilities.effect';
import { PerdiemSchedulerListEffect } from './perdiemscheduler-list.effect';
import { PerdiemSchedulerProfessionsEffect } from './perdiemscheduler-professions.effect';
import { PerdiemSchedulerSpecialtiesEffect } from './perdiemscheduler-specialties.effect';
import { PerdiemSchedulerStatesEffect } from './perdiemscheduler-states.effect';
import { PerdiemSchedulerDefaultDateFromEffect } from './perdiemscheduler-min-date.effect';

export const vendorPerdiemSchedulerEffects = [
    PerdiemSchedulerListEffect,
    PerdiemSchedulerProfessionsEffect,
    PerdiemSchedulerSpecialtiesEffect,
    PerdiemSchedulerFacilitiesEffect,
    PerdiemSchedulerStatesEffect,
    PerdiemSchedulerCandidatesEffect,
    PerdiemSchedulerCancelReasonsEffect,
    PerDiemSchedulerDetailsEffects,
    PerdiemSchedulerDefaultDateFromEffect,
];
