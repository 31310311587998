import { DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientLookupsState } from 'src/app/client/store/reducers/client-lookups.reducer';
import { clientFeatureKey } from 'src/app/client/store/selectors/client.selectors';
import { ClientState } from 'src/app/client/store/state/client.state';
import { GridLookup } from 'src/app/shared/grid/models';
import { UnitLookupConcise } from 'src/app/shared/models/lookups';

export const selectClientModuleFeature = createFeatureSelector<ClientState>(clientFeatureKey);

export const selectClientLookups: MemoizedSelector<
    object,
    ClientLookupsState,
    DefaultProjectorFn<ClientLookupsState>
> = createSelector(selectClientModuleFeature, (clientModuleState: ClientState): ClientLookupsState => {
    return clientModuleState.clientLookupsState;
});

export const selectAyaLeadsLookupFacilities: MemoizedSelector<
    object,
    GridLookup[],
    DefaultProjectorFn<GridLookup[]>
> = createSelector(selectClientLookups, (state: ClientLookupsState) => state.facilities);

export const selectAyaLeadsLookupUnits: MemoizedSelector<
    object,
    UnitLookupConcise[],
    DefaultProjectorFn<UnitLookupConcise[]>
> = createSelector(selectClientLookups, (state: ClientLookupsState) => state.units);

export const selectIsAyaLeadsLookupFacilityEnabled: MemoizedSelector<
    object,
    boolean,
    DefaultProjectorFn<boolean>
> = createSelector(
    selectAyaLeadsLookupFacilities,
    (facilities: GridLookup[]): boolean => !!facilities && facilities.length > 1
);
