import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { filter, takeUntil, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ContractGroupEntityValueDto } from '../../models/custom-fields/contract-group-entity-value-dto.model';
import { FeatureFlag } from '../../models/enums/feature-flag.enum';
import { CustomFieldService } from '../../services/custom-field.service';
import { FormlyService } from '../../services/formly.service';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { CustomFieldModule } from '../../models/enums/custom-field-module.enum';
import { buildFields, setDefinitionValue, buildModel, setDefinitionValues } from '../../formly/formly.utils';
import { PermissionsService } from '../../services/permissions.service';
import { BehaviorSubject } from 'rxjs';
import * as CustomFieldsActions from 'src/app/shared/components/custom-form/custom-form.actions';

import { Actions, ofType } from '@ngrx/effects';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';
import * as JobActions from 'src/app/jobs/store/jobs.actions';

@Component({
    selector: 'ayac-custom-form',
    templateUrl: './custom-form.component.html',
    styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    // Formly Form is set as child, if parentForm is passed in
    @Input() parentForm: UntypedFormGroup;
    // Can be JobId, WorkerId, CandidateId
    @Input() entityId: number;
    // facility id used to pull definitions on new entity create pages
    @Input() facilityId: number;
    // Default set to 'read'
    @Input() formType: 'read' | 'edit' = 'read';
    // Default set to JobDetails
    @Input() customFieldModule: CustomFieldModule = CustomFieldModule.JobDetails;

    @Output() onCustomFormChange: EventEmitter<ContractGroupEntityValueDto[]> = new EventEmitter();

    formlyForm: UntypedFormGroup = new UntypedFormGroup({});
    model = {};
    fields: FormlyFieldConfig[] = [];
    options: FormlyFormOptions = {};

    contractGroupId: number;
    entityValues: ContractGroupEntityValueDto[] = [];
    gettingCustomFields = false;
    readonly featureFlag = FeatureFlag;
    readonly customFieldModuleEnum = CustomFieldModule;
    shouldSaveField = new BehaviorSubject('');
    refreshCustomFieldsOnFacilityChange = false;
    enableJobTemplateCustomFieldsSync = false;
    initiallyLoading = false;

    constructor(
        private readonly actions$: Actions,
        private readonly _cfService: CustomFieldService,
        private readonly _formlyService: FormlyService,
        private readonly _permissionsService: PermissionsService,
        private readonly _featureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit() {
        if (this.gettingCustomFields) {
            return;
        }

        // User does not have custom field permissions
        if (!this._permissionsService.customFieldsView() && !this._permissionsService.customFieldsEdit()) {
            return;
        }

        // User does not have custom field READ permissions
        if (this.formType === 'read' && !this._permissionsService.customFieldsView()) {
            return;
        }

        // User does not have custom field EDIT permissions
        if (this.formType === 'edit' && !this._permissionsService.customFieldsEdit()) {
            return;
        }

        this.actions$
            .pipe(
                ofType(CustomFieldsActions.refreshWorkerValues),
                filter((value) => !!value && this.customFieldModule === CustomFieldModule.WorkerProfile),
                switchMap(() => this._cfService.getValuesByEntity(this.customFieldModule, this.entityId)),
                takeUntil(this.d$)
            )
            .subscribe((values) => {
                this.formlyForm = new UntypedFormGroup({});
                this.options.resetModel();
                this.handleValuesReceived(values);
            });

        this.actions$
            .pipe(
                ofType(JobActions.loadJobTemplateSuccess),
                filter(
                    (value) =>
                        this.enableJobTemplateCustomFieldsSync &&
                        !!value &&
                        this.customFieldModule === CustomFieldModule.JobDetails
                ),
                withLatestFrom(this._featureManager.isEnabled(FeatureFlag.EnableJobTemplateRequiredCustomFieldFix)),
                takeUntil(this.d$)
            )
            .subscribe(([action, flag]) => {
                action.template.customFields.forEach((e) => {
                    const entity = this.entityValues.find((v) => v.name === e.name);
                    if (entity) {
                        entity.value = e.value;
                        entity.parentDropdownValue = e.parentDropdownValue;
                    }
                });
                this.formlyForm = new UntypedFormGroup({});
                flag && this.parentForm.setControl('customFields', this.formlyForm);
                this.handleValuesReceived(this.entityValues);
            });

        this._featureManager
            .isEnabled(FeatureFlag.RefreshCustomFieldsOnFacilityChange)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => (this.refreshCustomFieldsOnFacilityChange = flagIsEnabled));

        this._featureManager
            .isEnabled(FeatureFlag.EnableJobTemplateCustomFieldsSync)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => (this.enableJobTemplateCustomFieldsSync = flagIsEnabled));

        this._featureManager
            .isEnabled(FeatureFlag.EnableCustomFieldsRichDropdownSearch)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this._formlyService.richDropdownIsSearchable = flagIsEnabled;
            });

        this._featureManager
            .isEnabled(FeatureFlag.EnableCustomFieldsChildDropdownSearch)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this._formlyService.childDropdownIsSearchable = flagIsEnabled;
            });

        this.getCustomForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // facility changed on saved job
        if (this.refreshCustomFieldsOnFacilityChange && changes.facilityId && changes.facilityId.currentValue) {
            this.loadFacilityFields();
        }

        if (!changes || !changes.entityId || changes.entityId.firstChange) {
            return;
        }
        if (changes.entityId.currentValue !== changes.entityId.previousValue) {
            this.getCustomForm();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.shouldSaveField.next('');
        this.shouldSaveField.complete();
    }

    ngAfterViewInit(): void {
        if (this.parentForm && this.formlyForm) {
            this.parentForm.addControl('customFields', this.formlyForm);
            this.formlyForm.setParent(this.parentForm);
        }
    }

    getCustomForm(): void {
        this.gettingCustomFields = true;
        if (this.entityId) {
            this._cfService
                .getValuesByEntity(this.customFieldModule, this.entityId)
                .pipe(takeUntil(this.d$))
                .subscribe((values) => this.handleValuesReceived(values));
        }

        // entity creation flow, no entity id exist yet.
        if (!this.entityId && this.facilityId) {
            this._cfService
                .getValuesByFacility(this.customFieldModule, this.facilityId)
                .pipe(takeUntil(this.d$))
                .subscribe((values) => this.handleValuesReceived(values));
        }
    }

    loadFacilityFields(): void {
        var originalValues = this.entityValues;
        var newValues: ContractGroupEntityValueDto[] = null;
        this._cfService
            .getValuesByFacility(this.customFieldModule, this.facilityId)
            .pipe(takeUntil(this.d$))
            .subscribe((values) => {
                // When a user changes the facility to a facility in a different contract group
                // the custom fields get refreshed. The block of code below is to repopulate the
                // custom fields with the values from the original facilities custom fields if
                // the name of the custom field matches and the type is the same.
                newValues = values;
                if (originalValues && originalValues.length && newValues && newValues.length) {
                    newValues.forEach((element) => {
                        element.value = originalValues.find(
                            (x) => x.name === element.name && x.valueType === element.valueType
                        )?.value;
                    });
                }
                this.handleValuesReceived(values);
            });
    }

    handleValuesReceived(values: ContractGroupEntityValueDto[]): void {
        if (!values) {
            return;
        }
        this.entityValues = values;

        // Check if there are values and set the contract group id
        this.contractGroupId = values.length && values[0].contractGroupId;

        // Attach the formly form to the parent form
        this.parentForm && this.formlyForm.setParent(this.parentForm);

        /**
         * The main difference when building the fields and model based on form type:
         *  1. Select dropdowns require a different data format
         *      - Incoming value must be parsed into { value, label } object
         *  2. ReadOnly forms have no inputs. Only display. Data model must be flat
         */
        // Build the model based on form type (read or edit)
        buildModel(values, this.model, this.formType);

        // Build the fields based on form type (read or edit)
        this.fields = buildFields(
            values,
            this.fields,
            this._formlyService,
            this.formType,
            true,
            this.customFieldModule,
            this.shouldSaveField
        );

        // We have to handle the job details page differently than the...
        if (
            this.customFieldModule === CustomFieldModule.JobDetails ||
            this.customFieldModule === CustomFieldModule.JobTemplate
        ) {
            this.handleValueChanges();
        } else if (this.customFieldModule === CustomFieldModule.WorkerProfile) {
            // Worker Profile page. Which needs to save each field individually
            this.initiallyLoading = true;
            this.handleWorkerProfileChanges();
            this.checkDtoValuesHaveIds();
        }

        this.gettingCustomFields = false;
    }

    /**
     * Make sure we emit the value changes to the parent component
     * so we can attach to the parent component's "save" functionality
     */
    handleValueChanges(): void {
        if (!this._permissionsService.customFieldsEdit()) {
            return;
        }
        this.formlyForm.valueChanges.pipe(takeUntil(this.d$)).subscribe((changes) => {
            if (changes) {
                // Attach values from the formly model into the value dtos
                setDefinitionValues(this.entityValues, this.model);
                this.onCustomFormChange.emit(this.entityValues);
            }
        });
    }

    /**
     * Ok, so this is how the worker profile fields are handled:
     *
     * We declare a BehaviorSubject (shouldSaveField) as a blank string.
     * The BehaviorSubject is cast as an observable (in the function below) and subscribed to.
     * If the string is blank, then we return;
     * If the string is not blank, then we get the value from the model, using the string
     * that was emitted by the BehaviorSubject.
     *
     * The BehaviorSubject is passed into the formly fields.
     * We do this so the fields can be independently managed and saved.
     *
     * This allows the user to edit/save a field individually, without altering other fields.
     *
     */
    handleWorkerProfileChanges(): void {
        this.shouldSaveField
            .asObservable()
            .pipe(
                filter((f) => f !== ''),
                tap((f) => setDefinitionValue(this.entityValues, f, this.model[f])),
                switchMap((f) =>
                    this._cfService
                        .saveValues(this.entityValues, this.contractGroupId, this.customFieldModule, this.entityId)
                        .pipe(
                            takeUntil(this.d$),
                            tap(() => {
                                this.formlyForm.markAsPristine();
                                this.shouldSaveField.next('');
                            })
                        )
                ),
                takeUntil(this.d$)
            )
            .subscribe();
    }

    /**
     * After the values are saved then reinit the fields because they will have
     * ids (instead of just zeros)
     */
    reInitValueDtos(): void {
        this._cfService
            .getValuesByEntity(this.customFieldModule, this.entityId)
            .pipe(takeUntil(this.d$))
            .subscribe((values) => {
                if (!values) {
                    return;
                }
                this.entityValues = values;

                this.fields = buildFields(
                    values,
                    this.fields,
                    this._formlyService,
                    this.formType,
                    true,
                    this.customFieldModule,
                    this.shouldSaveField
                );
                this.initiallyLoading = false;
            });
    }

    /**
     * Check if any of the entity values do not have IDs.
     * If one does not have an ID, then we save and reinit the fields
     */
    checkDtoValuesHaveIds(): void {
        if (!this.entityId) {
            this.initiallyLoading = false;
            return;
        }
        const dtosWithoutIds = this.entityValues.filter((v) => !v.id);
        if (!dtosWithoutIds.length) {
            this.initiallyLoading = false;
            return;
        }
        this._cfService
            .saveValues(this.entityValues, this.contractGroupId, this.customFieldModule, this.entityId)
            .pipe(takeUntil(this.d$))
            .subscribe({
                next: (res) => {
                    this.reInitValueDtos();
                    this.formlyForm.markAsPristine();
                },
                error: (err) => {
                    console.error(err);
                    this.initiallyLoading = false;
                }
            });
    }
}
