import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DashboardState, dashboardStoreKey} from '../dashboard.state';

const selectFeature = createFeatureSelector<DashboardState>(dashboardStoreKey);

export const selectShifts = createSelector(
    selectFeature,
    state => state.shifts.shifts
);

export const selectShiftsChart = createSelector(
    selectFeature,
    state => {
        const shifts = state.shifts.shifts;

        return [
            {
                label: 'Open',
                count: shifts.openedCount,
                color: '#F3BE42'
            }, {
                label: 'Filled',
                count: shifts.confirmedCount,
                color: '#19585F'
            }, {
                label: 'Reconfirmed',
                count: shifts.reconfirmedCount,
                color: '#2EB3AD'
            }, shifts.cancelledCount > 0 ?
                {
                    label: 'Worker Cancellations',
                    count: shifts.cancelledCount,
                    color: '#E04F4F'
                }
                : null
        ]
    }
);
