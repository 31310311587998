import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../services/dashboard.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getFilter } from '../selectors/filter.selectors';
import {
    loadPendingExpenses,
    loadPendingExpensesFail,
    loadPendingExpensesSuccess
} from '../actions/pending-expenses.actions';

@Injectable()
export class PendingExpensesEffect {
    getPendingExpenses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadPendingExpenses),
            concatLatestFrom(() => [this.store.select(getFilter)]),
            switchMap(([, query]) => {
                const filter = {
                    facilityId: query.facility.id > 0 ? query.facility.id : null,
                    unitId: query.unit.id > 0 ? query.unit.id : null
                };

                return this.service.getPendingExpenses(filter).pipe(
                    map((response) =>
                        loadPendingExpensesSuccess({
                            totalCount: response.totalCount,
                            agingCount: response.agingCount,
                            agingDays: response.agingDays
                        })
                    ),
                    catchError((error: unknown) => {
                        const loadError = error instanceof Error ? error : null;
                        return of(
                            loadPendingExpensesFail({ error: loadError?.message ?? 'an unknown error occurred' })
                        );
                    })
                );
            })
        );
    });

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly service: DashboardService
    ) {}
}
