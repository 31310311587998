import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { loadSubmittalDeclineReasons } from 'src/app/submittals/store/submittals.actions';
import { Observable } from 'rxjs';
import { SubmittalDeclineReasons } from 'src/app/shared/models/submittals/lookups/submittal-decline-reasons.model';
import { selectSubmittalDeclineReasons } from 'src/app/submittals/store/submittals.selectors';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ayac-decline-submittal-internal-dialog',
    templateUrl: './decline-submittal-internal-dialog.component.html',
    styleUrls: ['./decline-submittal-internal-dialog.component.scss']
})
export class DeclineSubmittalInternalDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    form: UntypedFormGroup;
    declineReasons$: Observable<SubmittalDeclineReasons[]>;
    noteLength: number;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: { declineReasonId?: number; notes: string },
        private readonly _dialogRef: MatDialogRef<DeclineSubmittalInternalDialogComponent>,
        private readonly _store: Store,
        private readonly _formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    ngOnInit() {
        this.noteLength = this.data.notes ? this.data.notes.length : 0;

        this._store.dispatch(loadSubmittalDeclineReasons());

        this.declineReasons$ = this._store.select(selectSubmittalDeclineReasons);

        this.form = this._formBuilder.group({
            reason: [this.data.declineReasonId, [Validators.required]],
            note: [this.data.notes, [Validators.required, Validators.maxLength(620)]]
        });

        this.form
            .get('note')
            .valueChanges.pipe(takeUntil(this.d$))
            .subscribe((value) => {
                this.noteLength = value.length;
            });
    }

    declineCandidate() {
        const declineReasonId = this.form.get('reason').value;
        const notes = this.form.get('note').value;

        this._dialogRef.close({ declineReasonId, notes });
    }
}
