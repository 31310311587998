import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes } from 'src/app/shared/models';
import { FileType } from 'src/app/shared/models/candidate';

export const vendorFileStoreKey = 'vendor-detail-files';

export interface VendorFileState {
    loading: LoadingTypes;
    vendorFiles: GridDataResult;
    gridSearchQuery: GridSearchQuery;
    fileTypes: FileType[];
    vendorId: number;
    error: unknown;
    isDeleting: boolean;
    deletingFileIds: number[];
    isDeleteSuccess?: boolean;
}

export const getInitialVendorFileState = (): VendorFileState => ({
    loading: LoadingTypes.INIT,
    vendorFiles: {
        data: [],
        total: 0,
        filtered: 0
    } as GridDataResult,
    vendorId: null,
    gridSearchQuery: getInitialVendorFileQueryState(),
    fileTypes: [],
    error: undefined,
    isDeleting: false,
    deletingFileIds: []
});

const getInitialVendorFileQueryState = (): GridSearchQuery => ({
    sort: [],
    filter: null,
    take: 10,
    skip: 0,
    groups: []
});
