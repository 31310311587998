import { Pipe, PipeTransform } from '@angular/core';
import { VendorNoteTypeId } from 'src/app/admin/vendor-details/enums/vendor-note-type-id.enum';
import { VendorNoteTypeLabel } from 'src/app/admin/vendor-details/enums/vendor-note-type-label.enum';

@Pipe({
    name: 'noteTypeLabel'
})
export class NoteTypeLabelPipe implements PipeTransform {
    transform(noteTypeId: number): string {
        if (noteTypeId === VendorNoteTypeId.TALKED_TO) {
            return VendorNoteTypeLabel.TALKED_TO;
        } else if (noteTypeId === VendorNoteTypeId.LEFT_MESSAGE) {
            return VendorNoteTypeLabel.LEFT_MESSAGE;
        } else if (noteTypeId === VendorNoteTypeId.EMAIL) {
            return VendorNoteTypeLabel.EMAIL;
        } else if (noteTypeId === VendorNoteTypeId.GENERAL) {
            return VendorNoteTypeLabel.GENERAL;
        } else {
            return VendorNoteTypeLabel.GENERAL;
        }
    }
}
