import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'relativeDateFormat'
})
export class RelativeDateFormatPipe implements PipeTransform {
    transform(value?: string | Date | null): string {
        if (!value) {
            return '';
        }

        const date = new Date(value);
        const today = new Date();

        if (date.toDateString() === today.toDateString()) {
            return formatDate(date, 'hh:mm a', 'en-us');
        } else {
            return formatDate(date, 'MM/dd/YYYY', 'en-us');
        }
    }
}
