import { ActionReducerMap } from '@ngrx/store';
import { ClinicalState } from '../clinical.state';
import { clientUnitsReducer } from './client-units.reducer';
import { clientReducer } from './client.reducer';
import { clientGridReducer } from './client-grid.reducer';

export const clinicalReducers: ActionReducerMap<ClinicalState> = {
    ClientUnitsState: clientUnitsReducer,
    ClientState: clientReducer,
    ClientGridState: clientGridReducer
};
