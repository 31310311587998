import { LocationType } from 'src/app/admin/vendor-details/profile/models/location-type.model';
import { VendorLocation } from 'src/app/admin/vendor-details/profile/models/vendor-location.model';
import { LoadingTypes } from 'src/app/shared/models';

export interface VendorLocationsState {
    loading: LoadingTypes;
    locations: VendorLocation[];
    locationTypes: LocationType[];
    error: unknown;
    vendorId: number;
    hasChanges: boolean;
    isValid: boolean;
}

export const getInitialVendorLocationState = (): VendorLocationsState => ({
    loading: LoadingTypes.INIT,
    vendorId: null,
    locations: [],
    locationTypes: [],
    error: undefined,
    hasChanges: false,
    isValid: true
});
