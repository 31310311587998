import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ayac-active-tab-button-bar',
    templateUrl: './active-tab-button-bar.component.html',
    styleUrls: ['./active-tab-button-bar.component.scss']
})
export class ActiveTabButtonBarComponent {
    @Input() itemsCount!: number;
    @Input() itemsFilteredCount!: number;
    @Input() selectedCount!: number;
    @Input() isActiveTab!: boolean;

    @Output() resetFilter = new EventEmitter<void>();
    @Output() removeSelection = new EventEmitter<void>();

    @Output() updateOfferStatus = new EventEmitter<void>();
    @Output() updateOfferStatusAll = new EventEmitter<void>();

    @Output() updateExcludeStatus = new EventEmitter<void>();

    @Output() updateAcceptStatus = new EventEmitter<void>();
    @Output() updateAcceptStatusAll = new EventEmitter<void>();

    @Output() exportAll = new EventEmitter<void>();
    @Output() exportSelected = new EventEmitter<void>();

    constructor() {}

    onResetFilter() {
        this.resetFilter.emit();
    }

    onUpdateOfferStatus() {
        this.updateOfferStatus.emit();
    }

    onUpdateOfferStatusAll() {
        this.updateOfferStatusAll.emit();
    }

    onUpdateExcludeStatus() {
        this.updateExcludeStatus.emit();
    }

    onUpdateAcceptStatus() {
        this.updateAcceptStatus.emit();
    }

    onUpdateAcceptStatusAll() {
        this.updateAcceptStatusAll.emit();
    }

    onExportAll() {
        this.exportAll.emit();
    }

    onExportSelected() {
        this.exportSelected.emit();
    }
}
