import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SystemColorTheme } from '../models/system-color-theme.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as selectors from 'src/app/facilities/store/selectors/facility-systems-profile.selectors';
import * as actions from 'src/app/facilities/store/actions/facility-systems-profile.actions';
import { getColorShades, shadeColor } from '../utilities/color-theme-shades';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { DomainService } from 'src/app/shared/services/domain.service';

@Component({
    selector: 'ayac-facilities-systems-profile',
    templateUrl: './facilities-systems-profile.component.html',
    styleUrls: ['./facilities-systems-profile.component.scss']
})
export class FacilitiesSystemsProfileComponent extends UnsubscribeOnDestroy implements OnInit {
    @Output() colorThemeChanged = new EventEmitter<SystemColorTheme | null>();
    readonly featureFlag = FeatureFlag;
    colorTheme: SystemColorTheme | null = null;
    colorThemeForm = new FormGroup({
        theme: new FormControl<string>('default', Validators.required),
        accent: new FormControl<string>(null),
        sidebar: new FormControl<string>(null),
        highlight: new FormControl<string>(null)
    });

    featuredLogo: string | ArrayBuffer;
    acceptableFileTypes = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        '.jpg',
        '.jpeg',
        '.png',
        '.gif',
        '.bmp'
    ];

    title: string;

    private _systemId = 0;

    constructor(private readonly _store: Store, private readonly _domain: DomainService) {
        super();

        const environment = this._domain.environment();
        this.title = environment === 'ONE' ? this._domain.getValue('TITLE') : 'Connect';
    }

    get systemId(): number {
        return this._systemId;
    }

    @Input() set systemId(id: number) {
        if (id && !Number.isNaN(Number(id))) {
            this._systemId = Number(id);
        }
    }

    @Input() set logo(picture: Blob) {
        this.createImageFromBlob(picture);
    }

    ngOnInit(): void {
        this._store
            .select(selectors.selectFacilitySystemsProfileLogo)
            .pipe(takeUntil(this.d$))
            .subscribe((logo) => {
                if (!logo || logo === null || logo === undefined) {
                    this.featuredLogo = null;
                } else {
                    this.createImageFromBlob(logo);
                }
            });

        this._store
            .select(selectors.selectFacilitySystemsColorTheme)
            .pipe(takeUntil(this.d$))
            .subscribe((colorTheme: SystemColorTheme | null) => {
                if (!colorTheme) {
                    this.colorTheme = null;
                    this.colorThemeForm.get('theme').setValue('default');
                } else {
                    this.colorTheme = colorTheme;
                    this.colorThemeForm.get('theme').setValue(colorTheme.theme, { emitEvent: false });
                    this.colorThemeForm.get('accent').setValue(colorTheme.accent.color, { emitEvent: false });
                    this.colorThemeForm.get('sidebar').setValue(colorTheme.sidebar.color, { emitEvent: false });
                    this.colorThemeForm.get('highlight').setValue(colorTheme.highlight.color, { emitEvent: false });
                }
            });

        this.colorThemeForm.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.d$))
            .subscribe((value) => {
                if (value.theme === 'default') {
                    this.colorThemeChanged.emit(null);
                } else {
                    const colorTheme: SystemColorTheme = {
                        theme: 'custom',
                        accent: getColorShades(value.accent),
                        sidebar: getColorShades(value.sidebar, true),
                        highlight: getColorShades(value.highlight, true),
                        cardBackground: shadeColor(value.accent, 0.9),
                        cardChevron: shadeColor(value.accent, 0.7),
                        activeMenuItem: shadeColor(value.accent, 0.9)
                    };

                    this.colorThemeChanged.emit(colorTheme);
                }
            });
    }

    createImageFromBlob(image: Blob): void {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.featuredLogo = reader.result;
            },
            false
        );
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    onFileSelected(event: any): void {
        const files: File[] = Array.from(event.target.files);
        if (files.length === 0) {
            return;
        }

        if (!this.acceptableFileTypes.includes(files[0].type)) {
            return null;
        }

        this._store.dispatch(actions.addLogo({ systemId: this.systemId, logo: files[0] }));
    }

    deleteFeaturedLogo(): void {
        this._store.dispatch(actions.deleteLogo({ systemId: this.systemId }));
        this.featuredLogo = undefined;
    }
}
