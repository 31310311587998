import { Inject, Injectable, Injector } from '@angular/core';
import { IdentityService } from '../shared/services/identity.service';
import { DomainService } from '../shared/services/domain.service';
import { APP_CONFIG, Settings } from '../config/settings';
import { combine } from '../shared/utilities';
import { HttpClient } from '@angular/common/http';
import { interpolateParams } from '../core/utils';
import { FacilityUserGuide } from './facility-user-guide.model';
import { Observable } from 'rxjs';
import { HelpContactResponse } from './model/reponse/helpcontact.reponse';
export interface HelpSection {
    subsectionTitle: string;
    data: {
        title: string;
        downloadLink?: string;
        link: string;
        icon?: string;
    }[];
}

@Injectable({
    providedIn: 'root'
})
export class HelpService {

    private helpPath: string;

    constructor(
        @Inject(APP_CONFIG) private readonly settings: Settings,
        private readonly http: HttpClient,
        private readonly injector: Injector,
        private readonly domain: DomainService
    ) {
        this.helpPath = combine(settings.CORE, 'AyaConnect', 'Client', 'Help', 'facility-user-guides');
    }

    helpItems() {
        const identity = this.injector.get(IdentityService);
        const documentData: HelpSection[] = [];
        const isFacility = identity.isAdminLogin() || identity.isSystemLogin() || identity.isClientLogin();
        const isAgency = identity.isAdminLogin() || identity.isVendorLogin();

        if (this.domain.isEnvironment("AYA")) {
            if (isFacility) {
                documentData.push(
                    {
                    subsectionTitle: 'Facility User Guide',
                    data: [
                        {
                            title: 'User Guide',
                            downloadLink: "/content/ayaforms/help/Aya Connect Facility User Guide.pdf",
                            link: "/client/help",
                            icon: 'insert_drive_file'
                        },
                        {
                            title: 'Contact Info',
                            link: "/client/contacts",
                            icon: 'email'
                        }
                    ]
                });             
            }
            if (isAgency) {
                documentData.push({
                    subsectionTitle: 'Agency User Guide',
                    data: [
                        {
                            title: 'Connect Agency User Guide',
                            link: "/content/ayaforms/help/Aya Connect Partner User Guide.pdf",
                            icon: 'insert_drive_file'
                        }
                    ]
                });
            }
        }
        if (this.domain.isEnvironment("LTS") || this.domain.isEnvironment("HCS")) {
            if (isFacility) {
                documentData.push({
                    subsectionTitle: 'Facility User Guide',
                    data: [
                        {
                            title: 'User Guide',
                            downloadLink: "/content/hcsforms/help/Lotus Connect Facility User Guide.pdf",
                            link: "/client/help",
                            icon: 'insert_drive_file'
                        },
                        {
                            title: 'Contact Info',
                            link: "/client/contacts",
                            icon: 'email'
                        }
                    ]
                });
            }
            if (isAgency) {
                documentData.push({
                    subsectionTitle: 'Agency User Guide',
                    data: [
                        {
                            title: 'Connect Agency User Guide',
                            link: "/content/hcsforms/help/Lotus Connect Partner User Guide.pdf",
                            icon: 'insert_drive_file'
                        }
                    ]
                });
            }
        }
        if (this.domain.isEnvironment("SHFT")) {
            if (isFacility) {
                documentData.push({
                    subsectionTitle: 'Facility User Guide',
                    data: [
                        {
                            title: 'User Guide',
                            downloadLink: "/content/forms/help/Shifts Facility User Guide.pdf",
                            link: "/client/help",
                            icon: 'insert_drive_file'
                        },
                        {
                            title: 'Contact Info',
                            link: "/client/contacts",
                            icon: 'email'
                        }
                    ]
                });
            }
        }

        return documentData;
    }

    getFacilityUserGuides(facilityProfileGroupId: number) : Observable<Array<FacilityUserGuide>> {
        const params = interpolateParams({
            'facilityProfileGroupId': facilityProfileGroupId
        });

        return this.http.get<Array<FacilityUserGuide>>(`${this.helpPath}`, {params});
    }

    getFacilityUserGuidesAttachment(facilityProfileGroupId: number, fileId: number) : Observable<Blob> {
        const url = `${this.helpPath}/${facilityProfileGroupId}/attachments/${fileId}`;

        return this.http.get<any>(url, { responseType: 'blob' as 'json' }) ;
    }

    getHelpContact(clientId: number) : Observable<HelpContactResponse> {
        const url = "/AyaConnect/Client/GetSharedData";
        const params = interpolateParams({ 
            clientId
        });

        return this.http.get<HelpContactResponse>(`${this.settings.CORE}${url}`, { params });
    }
}
