export enum FeatureCategory {
    ElectronicTimecard = 1,
    FacilityConfig = 2,
    Jobs = 3,
    TimeCardRoundingRules = 4,
    Compliance = 5,
    InternalPool = 6,
    ModuleRequirements = 7,
    Shifts = 8,
    IRPFeatures = 9
}
