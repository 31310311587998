import { WocEffect } from './woc.effect';
import { WorkersEffect } from './workers.effect';
import { PendingJobsEffect } from './pending-jobs.effect';
import { PendingTimecardsEffect } from './pending-timecards.effect';
import { SecurityLookupsEffect } from './security-lookups.effects';
import { JobsEffect } from './jobs.effect';
import { ShiftsEffect } from './shifts.effect';
import { PendingSubmittalsEffect } from './pending-submittals.effects';
import { AwaitingClearanceEffect } from 'src/app/dashboard-new/store/effects/awaiting-clearance.effect';
import { DraftInvoicesEffect } from 'src/app/dashboard-new/store/effects/draft-invoices.effect';
import { AyaLeadsEffect } from 'src/app/dashboard-new/store/effects/aya-leads.effect';
import { PendingExpensesEffect } from './pending-expenses.effect';

export const dashboardEffects = [
    WocEffect,
    AwaitingClearanceEffect,
    WorkersEffect,
    PendingJobsEffect,
    PendingTimecardsEffect,
    PendingExpensesEffect,
    SecurityLookupsEffect,
    JobsEffect,
    ShiftsEffect,
    PendingSubmittalsEffect,
    DraftInvoicesEffect,
    AyaLeadsEffect
];
