import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientState } from 'src/app/client/store/state/client.state';
import { AppState } from 'src/app/store/state/App.state';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

export const clientFeatureKey = 'client';

export const getSelectorValue = async <TResult>(observable: Observable<TResult>): Promise<TResult> => observable.pipe(first()).toPromise();

export const selectFeature = createFeatureSelector< ClientState>(clientFeatureKey);
