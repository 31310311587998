import { ActionReducerMap } from '@ngrx/store';
import { copyReleaseSettingsToModalReducer } from 'src/app/facilities/store/reducers/copy-release-settings-to-modal.reducers';
import { facilitiesListLookupsReducer } from 'src/app/facilities/store/reducers/facilities-list-lookups.reducers';
import { facilitiesListReducer } from 'src/app/facilities/store/reducers/facilities-list.reducers';
import { FacilitiesState } from 'src/app/facilities/store/state/facilities.state';
import { copyReleaseSettingsFromModalReducer } from './copy-release-settings-from-modal.reducers';
import { facilityProfileReducer } from 'src/app/facilities/store/reducers/facility-profile.reducers';
import { facilityFeaturesReducer } from 'src/app/facilities/store/reducers/facility-features.reducers';
import { scheduledReleaseRulesReducer } from 'src/app/facilities/store/reducers/scheduled-release-rules.reducers';
import { jobReleaseGroupsReducers } from './job-release-groups.reducers';
import { shiftReleaseGroupsReducers } from 'src/app/facilities/store/reducers/shift-release-groups.reducers';
import { facilityVendorsReducer } from './facility-vendors.reducers';
import { facilityAssociationsListReducer } from 'src/app/facilities/store/reducers/facility-associations-list.reducers';
import { facilityAssociationReducer } from 'src/app/facilities/store/reducers/facility-association.reducers';
import { systemsListReducer } from 'src/app/facilities/store/reducers/facilities-systems-list.reducers';
import { facilitySettingsReducer } from 'src/app/facilities/store/reducers/facility-settings.reducers';
import { contractGroupPermissionsReducer } from './contract-group-permissions.reducers';
import { facilitySystemsProfileReducer } from 'src/app/facilities/store/reducers/facility-systems-profile.reducers';

export const facilitiesReducers: ActionReducerMap<FacilitiesState, any> = {
    list: facilitiesListReducer,
    systemsList: systemsListReducer,
    lookups: facilitiesListLookupsReducer,
    copyReleaseSettingsToModal: copyReleaseSettingsToModalReducer,
    copyReleaseSettingsFromModal: copyReleaseSettingsFromModalReducer,
    facilityProfile: facilityProfileReducer,
    facilityFeatures: facilityFeaturesReducer,
    scheduledReleaseRulesState: scheduledReleaseRulesReducer,
    jobReleaseGroups: jobReleaseGroupsReducers,
    shiftReleaseGroups: shiftReleaseGroupsReducers,
    facilityVendors: facilityVendorsReducer,
    associations: facilityAssociationsListReducer,
    facilityAssociation: facilityAssociationReducer,
    facilitySettings: facilitySettingsReducer,
    contractGroupFeatures: contractGroupPermissionsReducer,
    systemsProfiles: facilitySystemsProfileReducer
};
