import { Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
    selector: 'kendo-worker-details-input',
    template: `
        <div class="worker-field-template">
            <ng-container *ngIf="!editMode">
                <div class="worker-display">
                    <div class="field-title"
                        >{{ to.label }}
                        <ng-container *ngIf="field.props.required">
                            <span class="k-required">*</span>
                        </ng-container>
                        <formly-help-text [helpText]="props.helpText"></formly-help-text>
                    </div>
                    <div class="field-value">
                        {{ model[field.key] || 'N/A' }}
                        <button
                            mat-icon-button
                            color="accent"
                            (click)="toggleEditMode()">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="editMode">
                <label class="k-form-field">
                    <span>
                        {{ to.label }}
                        <ng-container *ngIf="field.props.required">
                            <span class="k-required">*</span>
                        </ng-container>
                        <formly-help-text [helpText]="props.helpText"></formly-help-text>
                    </span>
                    <div>
                        <input
                            class="k-textbox"
                            type="{{ field.props.type }}"
                            [formControl]="formControl" />
                        <button
                            [disabled]="formControl.invalid"
                            mat-icon-button
                            color="accent"
                            (click)="onSubmit()">
                            <mat-icon>check</mat-icon>
                        </button>
                    </div>
                </label>
                <validation-message
                    class="validation-message"
                    [label]="to.label"
                    [control]="formControl">
                </validation-message>
            </ng-container>
        </div>
    `,
    styleUrls: ['./kendo-worker-details-input.component.scss']
})
export class KendoWorkerDetailsInputComponent extends FieldType<FieldTypeConfig> {
    editMode = false;

    toggleEditMode(): void {
        this.editMode = !this.editMode;
    }

    onSubmit(): void {
        // Push field.key for custom-form.component to handle field value update
        this.field['saveField'].next(this.field.key);
        this.toggleEditMode();
    }
}
