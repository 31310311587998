import { Directive, ElementRef, HostListener, Input, OnChanges } from "@angular/core";

@Directive({
    selector: "[hoverClass]"
})
export class HoverClassDirective implements OnChanges {

    @Input('hoverClass') className;
    @Input('hoverClassPersist') persist;

    hovered: boolean;

    constructor(
        private readonly elm: ElementRef
    ) {}

    ngOnChanges(changes) {
        if (changes.persist && !this.hovered) {
            this.elm.nativeElement.classList.toggle(
                this.className,
                this.persist
            );
        }
    }

    @HostListener("mouseenter", ["$event"])
    onMouseEnter(event) {
        this.elm.nativeElement.classList.add(this.className);

    }
    @HostListener("mouseleave", ["$event"])
    onMouseLeave(event) {
        if (this.persist) {
            return;
        }
        
        this.elm.nativeElement.classList.remove(this.className);
    }
}
