import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ClinicalClient } from 'src/app/clinical/models/client.model';
import * as actions from 'src/app/clinical/store/actions/client.actions';
import * as selectors from 'src/app/clinical/store/selectors/client.selectors';
import { DomainService } from 'src/app/shared/services/domain.service';
import { IdentityService } from 'src/app/shared/services/identity.service';

@Component({
    selector: 'ayac-client-detail',
    templateUrl: './client-detail.component.html',
    styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit, OnDestroy {
    client$: Observable<ClinicalClient>;

    updatedUserId: number;
    hostName: string;
    environment: string;
    loaded = false;

    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    constructor(
        private readonly store: Store,
        private readonly _route: ActivatedRoute,
        private readonly identityService: IdentityService,
        private readonly domainService: DomainService
    ) {}

    ngOnInit(): void {
        this.client$ = this.store
            .select(selectors.selectClient)
            .pipe(tap(() => (this.loaded = true), takeUntil(this.destroyed$)));
        this.environment = this.domainService.environment();
        this.hostName = window.location.origin + '/#/';
        this.updatedUserId = this.identityService.oldUserId
            ? this.identityService.oldUserId
            : this.identityService.userId;
        this.loadClient();
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    reloadClient() {
        this.loaded = false;
        this.store.dispatch(actions.loadClientInformation());
    }

    loadClient() {
        this._route.params.pipe(takeUntil(this.destroyed$)).subscribe((params: { id: number }) => {
            if (params.id) {
                this.store.dispatch(actions.setClientId({ clientId: params.id }));

                this.reloadClient();
            }
        });
    }
}
