export interface FieldMapper {
    [key: string] : {
        value: (v: any) => any, 
        name: string
    }
}

export function mapFields(obj, mappers: FieldMapper | string) {
    return Object.keys(mappers)
        .filter((k => k in obj))
        .reduce((o, k) => {
            const mapper = typeof mappers === 'string'
                ? { value: obj[k], name: k }
                : mappers[k];
            const key = mapper.name;
            const value = typeof mapper.value === 'function'
                ? mapper.value(obj[k])
                : mapper.value;

            return {
                ...o,
                [key]: value
            }
        }, obj)
}
