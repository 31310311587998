import { ValidatorFn } from '@angular/forms';

export function requiredDropdownValidator(): ValidatorFn {
    return function (control) {
        if (!control.value) {
            return { required: 'Value is required' };
        }

        return control.value.value && control.value.value !== null ? null : { required: 'Value is  required' };
    };
}
