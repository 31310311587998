import { createAction, props } from '@ngrx/store';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { VendorFacilityStatus } from 'src/app/shared/models/enums/vendor-facility-status.enum';
import { VendorFacility, VendorFacilityLookups } from 'src/app/shared/models/facilities';
import { PagingToken } from 'src/app/shared/models/paging-token';

export const loadVendorFacilities = createAction(
    '[VENDOR FACILITIES COMPONENT] Load Vendor Facilities',
    props<{ vendorId: number }>()
);
export const loadVendorFacilitiesSuccess = createAction(
    '[VENDOR CONTROLLER API] Load Vendor Facilities Success',
    props<{ facilities: PagingToken<VendorFacility[]> }>()
);
export const loadVendorFacilitiesFailure = createAction(
    '[VENDOR CONTROLLER API] Load Vendor Facilities Failure',
    props<{ error: unknown }>()
);

export const loadVendorFacilityIds = createAction(
    '[VENDOR FACILITIES COMPONENT] Load Vendor Facility Ids',
    props<{ vendorId: number }>()
);
export const loadVendorFacilityIdsSuccess = createAction(
    '[VENDOR CONTROLLER API] Load Vendor Facility Ids Success',
    props<{ ids: number[] }>()
);
export const loadVendorFacilityIdsFailure = createAction(
    '[VENDOR CONTROLLER API] Load Vendor Facility Ids Failure',
    props<{ error: unknown }>()
);
// Search Query
export const setVendorFacilitiesSearchQuery = createAction(
    '[VENDOR FACILITIES COMPONENT] Set Facilities Systems Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);
export const clearVendorFacilities = createAction('[VENDOR FACILITIES COMPONENT] Clear Vendor Facilities');

// Lookups
export const loadVendorFacilitiesLookups = createAction('[VENDOR FACILITIES COMPONENT] Facility Vendors Lookup');
export const loadVendorFacilitiesLookupsSuccess = createAction(
    '[VENDOR CONTROLLER API] Vendor Facilities Lookups Success',
    props<{ lookups: VendorFacilityLookups }>()
);
export const loadVendorFacilitiesLookupsFailure = createAction(
    '[VENDOR CONTROLLER API] Vendors Facilities Lookups Failure',
    props<{ error: unknown }>()
);

// Term Sheet
export const updateCtsSigned = createAction(
    '[VENDORS CONTROLLER COMPONENT] Update CTS Signed',
    props<{
        params: {
            vendorId: number;
            ids: number[];
            termSheetSigned: boolean;
        };
    }>()
);
export const updateCtsSignedSuccess = createAction(
    '[VENDORS CONTROLLER API] Update CTS Signed Success',
    props<{ vendorId: number }>()
);
export const updateCtsSignedFailure = createAction(
    '[VENDORS CONTROLLER API] Update CTS Signed Failure',
    props<{ error: unknown }>()
);

// Statuses
export const updateFacilityStatus = createAction(
    '[VENDOR FACILITIES COMPONENT] Update Vendor Facility Status Request',
    props<{
        vendorId: number;
        params: {
            status: VendorFacilityStatus;
            facilityIds: number[];
            note: string;
        };
    }>()
);
export const updateFacilityStatusSuccess = createAction(
    '[VENDOR CONTROLLER API] Update Vendor Facility Status Success',
    props<{ vendorId: number }>()
);
export const updateFacilityStatusFailure = createAction(
    '[VENDOR CONTROLLER API] Update Vendor Facility Status Failure',
    props<{ error: unknown }>()
);

export const exportSelected = createAction(
    '[VENDOR FACILITIES COMPONENT] Export Vendor Facilities Request',
    props<{ facilities: number[]; vendorId: number }>()
);
export const exportSelectedSuccess = createAction('[VENDOR CONTROLLER API] Export Vendor Facilities Success');
export const exportSelectedFailure = createAction(
    '[VENDOR CONTROLLER API] Export Vendor Facilities Failure',
    props<{ error: unknown }>()
);
