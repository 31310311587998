export function getEnvironmentPrefix(hostname: string): string {
    if (hostname.includes('-dev')) {
        return '-dev';
    } else if (hostname.includes('-int')) {
        return '-int';
    } else if (hostname.includes('-hot')) {
        return '-hot';
    } else if (hostname.includes('-demo')) {
        return '-demo';
    } else {
        return '';
    }
}
