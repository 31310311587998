import { createReducer, on } from '@ngrx/store';
import {
    loadBoardCertificationsComponent,
    loadClientUnits,
    loadClientUnitsFailure,
    loadClientUnitsSuccess,
    loadNyuBuildings,
    loadNyuBuildingsFailure,
    loadNyuBuildingsSuccess,
    loadNyuDepartments,
    loadNyuDepartmentsFailure,
    loadNyuDepartmentsSuccess,
    loadNyuFloors,
    loadNyuFloorsFailure,
    loadNyuFloorsSuccess,
    loadOtherRequirementComponent,
    loadOtherRequirementsFailure,
    loadOtherRequirementsSuccess,
    loadPhysicianSpecialtiesFailure,
    loadPhysicianSpecialtiesSuccess,
    loadStateLicensesComponent,
    loadStatesFailure,
    loadStatesSuccess,
    loadStateControlledSubstancesComponent,
    loadUnitStateControlledSubstancesFailure,
    loadUnitStateControlledSubstancesSuccess,
    loadUnitBoardCertificationsFailure,
    loadUnitBoardCertificationsSuccess,
    loadUnitInfo,
    loadUnitInfoFailure,
    loadUnitInfoSuccess,
    loadUnitOtherRequirementsFailure,
    loadUnitOtherRequirementsSuccess,
    loadUnitStateLicensesFailure,
    loadUnitStateLicensesSuccess,
    setClientUnitsSearchQuery,
    setFacilityId
} from 'src/app/clinical/store/actions/client-units.actions';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { UnitFacilityInformation } from '../../models/unit-facility-information.model';
import { ClientUnitsState } from '../state/client-units.state';

export const getInitialClientUnitsQueryState = (): GridSearchQuery => ({
    sort: [{ field: 'name', dir: 'asc' }],
    take: 10,
    skip: 0
});

export const getEmptyPagingToken = <T>(): PagingToken<T[]> => ({
    data: [],
    total: 0,
    currPage: '',
    nextPage: '',
    filtered: 0
});

export const initialClinicalUnitsState = (): ClientUnitsState => ({
    error: undefined,
    clientUnits: getEmptyPagingToken(),
    facilityId: -1,
    gridSearchQuery: getInitialClientUnitsQueryState(),
    unitInfo: {} as UnitFacilityInformation,
    nyuDepartments: [],
    nyuFloors: [],
    nyuBuildings: [],
    boardCertifications: null,
    physicianSpecialties: null,
    otherRequirements: null,
    unitOtherRequirements: null,
    states: null,
    stateLicenses: null,
    unitStateControlledSubstances: null,
    savedUnitId: -1,
    saved: false,
    isLoading: false
});

export const clientUnitsReducer = createReducer(
    initialClinicalUnitsState(),

    on(loadBoardCertificationsComponent, (state, action) => ({
        ...state
    })),

    on(loadUnitBoardCertificationsSuccess, (state, action) => ({
        ...state,
        boardCertifications: action.boardCertifications
    })),

    on(loadUnitBoardCertificationsFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadPhysicianSpecialtiesSuccess, (state, action) => ({
        ...state,
        physicianSpecialties: action.physicianSpecialties
    })),

    on(loadPhysicianSpecialtiesFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadStateLicensesComponent, (state, action) => ({
        ...state
    })),

    on(loadStateControlledSubstancesComponent, (state, action) => ({
        ...state
    })),

    on(loadUnitStateLicensesSuccess, (state, action) => ({
        ...state,
        stateLicenses: action.stateLicenses
    })),

    on(loadUnitStateControlledSubstancesFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadUnitStateControlledSubstancesSuccess, (state, action) => ({
        ...state,
        unitStateControlledSubstances: action.stateControlledSubstances
    })),

    on(loadUnitStateLicensesFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadStatesSuccess, (state, action) => ({
        ...state,
        states: action.states
    })),

    on(loadStatesFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadUnitInfo, (state, action) => ({
        ...state,
        unitId: action.unitId,
        hospId: action.hospId,
        isLoading: true
    })),

    on(loadUnitInfoSuccess, (state, action) => ({
        ...state,
        unitInfo: action.unitInfo,
        isLoading: false
    })),

    on(loadUnitInfoFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadClientUnits, (state, action) => ({
        ...state,
        isLoading: true
    })),

    on(loadClientUnitsSuccess, (state, action) => ({
        ...state,
        clientUnits: action.clientUnits,
        isLoading: false
    })),

    on(loadClientUnitsFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(setClientUnitsSearchQuery, (state, action) => ({
        ...state,
        gridSearchQuery: action.gridSearchQuery
    })),

    on(setFacilityId, (state, action) => ({
        ...state,
        facilityId: action.facilityId
    })),

    on(loadNyuBuildings, (state, action) => ({
        ...state,
        isLoading: true
    })),

    on(loadNyuBuildingsSuccess, (state, action) => ({
        ...state,
        nyuBuildings: action.nyuBuildings,
        isLoading: false
    })),

    on(loadNyuBuildingsFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadNyuDepartments, (state, action) => ({
        ...state,
        nyuDepartments: [],
        isLoading: true
    })),

    on(loadNyuDepartmentsSuccess, (state, action) => ({
        ...state,
        nyuDepartments: action.nyuDepartments,
        isLoading: false
    })),

    on(loadNyuDepartmentsFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadNyuFloors, (state, action) => ({
        ...state,
        buildingId: action.buildingId,
        isLoading: true
    })),

    on(loadNyuFloorsSuccess, (state, action) => ({
        ...state,
        nyuFloors: action.nyuFloors,
        isLoading: false
    })),

    on(loadNyuFloorsFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadOtherRequirementComponent, (state, action) => ({
        ...state
    })),

    on(loadOtherRequirementsSuccess, (state, action) => ({
        ...state,
        otherRequirements: action.otherRequirements
    })),

    on(loadOtherRequirementsFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    })),

    on(loadUnitOtherRequirementsSuccess, (state, action) => ({
        ...state,
        unitOtherRequirements: action.unitOtherRequirements
    })),

    on(loadUnitOtherRequirementsFailure, (state, action) => ({
        ...state,
        error: JSON.stringify(action.error),
        isLoading: false
    }))
);
