import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { APP_CONFIG, Settings } from 'src/app/config/settings';

@Injectable({
    providedIn: 'root'
})
export class HelpService {
    constructor(private readonly _http: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    downloadFile(path: string): Observable<Blob> {
        return this._http
            .get(`${this._settings.CORE}/AyaConnect/vendors/forms/download-file?fileName=${path}`, {
                responseType: 'blob'
            })
            .pipe(
                tap((blob) => {
                    const link = document.createElement('a');
                    const blobUrl = window.URL.createObjectURL(blob);
                    link.href = blobUrl;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();

                    setTimeout(() => {
                        window.URL.revokeObjectURL(blobUrl);
                        document.body.removeChild(link);
                    }, 0);
                })
            );
    }

    downloadBlobFile(path: string): Observable<Blob> {
        return this._http
            .get(`${this._settings.CORE}/AyaConnect/vendors/forms/download-file?fileName=${path}`, {
                responseType: 'blob'
            })
    }
}
