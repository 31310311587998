import { createAction, props } from '@ngrx/store';
import { ClinicalClient } from '../../models/client.model';

export const loadClientInformation = createAction('[CLIENT COMPONENT] Load Client Information');
export const setClientId = createAction('[CLIENT COMPONENT] Set Client Id', props<{ clientId: number }>());
export const loadClientInformationSuccess = createAction(
    '[CLIENT API] Load Client Successfully',
    props<{ clinicalClient: ClinicalClient }>()
);
export const loadClientInformationFailure = createAction(
    '[CLIENT API] Load Client Failure',
    props<{ error: unknown }>()
);
