import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SubmittalsUrl } from 'src/app/shared/models/submittals/enums/submittals-url.enum';
import { Observable } from 'rxjs';
import { SubmittalsFilteredResponse } from 'src/app/shared/models/submittals/responses/submittals-filtered.response';
import { SubmittalDetailsResponse } from 'src/app/shared/models/submittals/responses/submittal-details.response';
import { SubmittalStatusesResponse } from 'src/app/shared/models/submittals/responses/submittal-statuses.response';
import { SubmittalUpdateStatusRequest } from 'src/app/shared/models/submittals/requests/submittal-update-status.request';
import { SubmittalExpanded } from 'src/app/shared/models/submittals/submittal-expanded.model';
import { SubmittalJob } from 'src/app/shared/models/submittals/submittal-job.model';
import { SubmittalsLookupsResponse } from 'src/app/shared/models/submittals/responses/submittals-lookups.response';
import { interpolateParams } from 'src/app/core/utils/interpolate-params';
import { interpolateUrl } from 'src/app/core/utils/interpolate-url';
import { SortTypes } from 'src/app/shared/models';
import { SubmittalEmailData } from 'src/app/shared/models/submittals/submittal-email-data.model';
import { SubmittalDeclineReasons } from 'src/app/shared/models/submittals/lookups/submittal-decline-reasons.model';
import { SendOfferRequest } from 'src/app/shared/models/submittals/requests/send-offer.request';
import { SubmittalAttachmentMetaData } from 'src/app/shared/models/submittals/responses/submittal-attachment-meta-data.model';
import { SubmittalDiversityInfo } from 'src/app/shared/models/submittals/diversity-info.model';
import {
    SubmittalOfferDetails,
    SubmittalOfferDetailsUpdateRequest,
    SubmittalOfferNote,
    SubmittalOfferNoteAddRequest,
    SubmittalRescindOfferRequest
} from 'src/app/shared/models/submittals';
import { OfferCreate } from 'src/app/submittals/models/offer-create.model';
import { SubmittalOfferDeclineChangesRequest } from 'src/app/shared/models/submittals/submittal-offer-decline-changes-request.model';

@Injectable({
    providedIn: 'root'
})
export class SubmittalsService {
    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    getSubmittalDetails(submittalId: number): Observable<SubmittalDetailsResponse> {
        const urlParams = {
            submittalId: submittalId
        };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_DETAILS, urlParams);
        return this.http.get<SubmittalDetailsResponse>(`${this._settings.CORE}${url}`);
    }

    getLookups(): Observable<SubmittalsLookupsResponse> {
        return this.http.get<SubmittalsLookupsResponse>(
            `${this._settings.CORE}${SubmittalsUrl.SUBMITTALS_LOOKUPS_SECURITY}`
        );
    }

    getStatuses(): Observable<SubmittalStatusesResponse> {
        return this.http.get<SubmittalStatusesResponse>(
            `${this._settings.CORE}${SubmittalsUrl.SUBMITTALS_LOOKUPS_STATUSES}`
        );
    }

    getStatusesV2(isIrpJobSubmittal: boolean): Observable<SubmittalStatusesResponse> {
        const url = isIrpJobSubmittal
            ? `${this._settings.CORE}${SubmittalsUrl.SUBMITTALS_LOOKUPS_STATUSES_IRP}`
            : `${this._settings.CORE}${SubmittalsUrl.SUBMITTALS_LOOKUPS_STATUSES}`;
        return this.http.get<SubmittalStatusesResponse>(url);
    }

    getDeclineReasons(): Observable<Array<SubmittalDeclineReasons>> {
        return this.http.get<SubmittalDeclineReasons[]>(
            `${this._settings.CORE}${SubmittalsUrl.SUBMITTALS_LOOKUPS_DECLINE_REASONS}`
        );
    }

    getSubmittals(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        const params = interpolateParams({
            ...pagination,
            ...sortArgs,
            ...matchArgs
        });

        return this.http.get<SubmittalsFilteredResponse>(`${this._settings.CORE}${SubmittalsUrl.SUBMITTALS}`, {
            params
        });
    }

    getSubmittalsInternal(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        const params = interpolateParams({
            ...pagination,
            ...sortArgs,
            ...matchArgs
        });

        return this.http.get<SubmittalsFilteredResponse>(`${this._settings.CORE}${SubmittalsUrl.SUBMITTALS_IRP}`, {
            params
        });
    }

    getSubmittalJobs(submittalId: number): Observable<SubmittalJob[]> {
        const urlParams = {
            submittalId: submittalId
        };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_JOBS, urlParams);
        return this.http.get<SubmittalJob[]>(`${this._settings.CORE}${url}`);
    }

    getSubmittalEmailData(submittalId: number): Observable<SubmittalEmailData> {
        const urlParams = {
            submittalId: submittalId
        };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_EMAIL_DATA, urlParams);
        return this.http.get<SubmittalEmailData>(`${this._settings.CORE}${url}`);
    }

    updateSubmittalStatus(data: SubmittalUpdateStatusRequest): Observable<SubmittalExpanded> {
        const urlParams = {
            submittalId: data.submittalId
        };

        const payload = {
            statusId: data.statusId,
            submittalStatusId: data.submittalStatusId,
            submittalSubStatusId: data.submittalSubStatusId,
            note: data.note,
            declineReasonId: data.declineReasonId
        };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_UPDATE_STATUS, urlParams);
        return this.http.put<SubmittalExpanded>(`${this._settings.CORE}${url}`, payload);
    }

    sendSubmittalOffer(data: SendOfferRequest): Observable<SendOfferRequest> {
        const url = SubmittalsUrl.SUBMITTAL_SEND_OFFER;
        return this.http.post<SendOfferRequest>(`${this._settings.CORE}${url}`, data);
    }

    downloadFile(submittalId: number): Observable<HttpResponse<Blob>> {
        const urlParams = {
            submittalId: submittalId
        };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_DOWNLOAD_FILE, urlParams);

        return this.http.get(`${this._settings.CORE}${url}`, { observe: 'response', responseType: 'blob' });
    }

    export(
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<HttpResponse<Blob>> {
        const params = interpolateParams({
            ...sortArgs,
            ...matchArgs
        });
        return this.http.get(`${this._settings.CORE}${SubmittalsUrl.SUBMITTALS_EXPORT}`, {
            observe: 'response',
            responseType: 'blob',
            params: params
        });
    }

    getSubmittalAttachmentsMetaData(clientSubmittalId: number): Observable<SubmittalAttachmentMetaData[]> {
        return this.http.get<SubmittalAttachmentMetaData[]>(
            `${this._settings.CORE}${SubmittalsUrl.SUBMITTAL_ATTACHMENTS_META_DATA}/${clientSubmittalId}`
        );
    }

    getSubmittalFile(attachmentId: number | null, submittalId: number): Observable<Blob> {
        const urlParams = {
            submittalId: submittalId
        };

        let url = interpolateUrl(SubmittalsUrl.SUBMITTAL_MULTIPLE_ATTACHMENTS_DOWNLOAD_FILE, urlParams);
        url = attachmentId != null ? url + `?attachmentId=${attachmentId}` : url;

        return this.http.get(`${this._settings.CORE}${url}`, {
            responseType: 'blob'
        });
    }

    getDiversityInfo(): Observable<SubmittalDiversityInfo[]> {
        const url = SubmittalsUrl.SUBMITTALS_DIVERSITY_INFO;
        return this.http.get<SubmittalDiversityInfo[]>(`${this._settings.CORE}${url}`);
    }

    getSubmittalOfferDetails(clientSubmittalId: number): Observable<SubmittalOfferDetails> {
        const urlParams = { clientSubmittalId };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_OFFER_DETAILS, urlParams);
        return this.http.get<SubmittalOfferDetails>(`${this._settings.CORE}${url}`);
    }

    rescindSubmittalOffer(clientSubmittalId: number, data: SubmittalRescindOfferRequest) {
        const urlParams = { clientSubmittalId };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_OFFER_RESCIND, urlParams);
        return this.http.put<SubmittalRescindOfferRequest>(`${this._settings.CORE}${url}`, data);
    }

    updateSubmittalOfferDetails(clientSubmittalId: number, data: SubmittalOfferDetailsUpdateRequest) {
        const urlParams = { clientSubmittalId };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_OFFER_DETAILS, urlParams);
        return this.http.put<SubmittalOfferDetailsUpdateRequest>(`${this._settings.CORE}${url}`, data);
    }

    declineOfferChangesRequest(clientSubmittalId: number, data: SubmittalOfferDeclineChangesRequest) {
        const urlParams = { clientSubmittalId };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_OFFER_DECLINE_CHANGE_REQUEST, urlParams);
        return this.http.put<SubmittalOfferDetailsUpdateRequest>(`${this._settings.CORE}${url}`, data);
    }

    createOffer(offerDto: OfferCreate): Observable<void> {
        return this.http.post<void>(`${this._settings.CORE}${SubmittalsUrl.OFFER_CREATE}`, offerDto);
    }

    getSubmittalOfferNotes(clientSubmittalId: number, offerId: number): Observable<SubmittalOfferNote[]> {
        const urlParams = { clientSubmittalId };
        let url = interpolateUrl(SubmittalsUrl.SUBMITTAL_OFFER_NOTES, urlParams);
        url += `?offerId=${offerId}`;
        return this.http.get<SubmittalOfferNote[]>(`${this._settings.CORE}${url}`);
    }

    addSubmittalOfferNote(clientSubmittalId: number, data: SubmittalOfferNoteAddRequest) {
        const urlParams = { clientSubmittalId };
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_OFFER_NOTES, urlParams);
        return this.http.post<void>(`${this._settings.CORE}${url}`, data);
    }
}
