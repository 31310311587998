export enum ApprovalProcessLevel {
	System = 1,
	Facility = 2,
	Unit = 3
}

export enum ApprovalProcessType {
	None = 1,
	TierBased = 2
}

export enum ApprovalProcessTierType {
	//At least one approver in the tier will need to approve before it moves to the next tier
	OnlyOneNeeded = 1,
	//All approvers in the tier will need to approve before it moves to the next tier
	Unanimous = 2
}

export enum JobApprovalStatus {
	None = 0,
	Approved = 1,
	Pending = 2,
	Rejected = 3,
}
