import { LoadingTypes } from '../../../shared/models';
import { FacilityLookup, SpecialtyLookup } from '../../../shared/models/lookups';
import { ListItem } from '../../../shared/models/list-item';
import { FacilityConfiguration } from '../../models/facility-lookups.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as facilityLookupsActions from '../actions/facility-lookups.actions';

export interface FacilityLookupsState {
    error: string;
    loading: LoadingTypes;
    facilities: FacilityLookup[];
    facilityConfigurations: FacilityConfiguration[];
    professions: ListItem[];
    specialties: SpecialtyLookup[];
}

export const initialState: FacilityLookupsState = {
    error: '',
    loading: LoadingTypes.INIT,
    facilities: [],
    facilityConfigurations: [],
    professions: [],
    specialties: []
};

const reducer = createReducer(
    initialState,
    on(facilityLookupsActions.loadFacilityLookups, (state) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),
    on(facilityLookupsActions.loadFacilityLookupsFail, (state) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        error: state.error
    })),
    on(facilityLookupsActions.loadFacilityLookupsSuccess, (state, action) => ({
        ...state,
        loading: LoadingTypes.LOADED,
        facilities: action.facilityLookups.facilities,
        facilityConfigurations: action.facilityLookups.facilityConfigurations,
        professions: action.facilityLookups.professions,
        specialties: action.facilityLookups.specialties
    }))
);

export function facilityLookupsReducer(state: FacilityLookupsState | undefined, action: Action) {
    return reducer(state, action);
}
