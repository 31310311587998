import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Unit, UnitFacilityInformation } from 'src/app/clinical/models';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { emailValidator } from 'src/app/shared/utilities';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss']
})
export class SignatureComponent extends UnsubscribeOnDestroy implements OnChanges {
    @Output() valueChanged = new EventEmitter<{ unit: Partial<Unit>; isValid: boolean }>();

    @Input() readOnly = false;

    @Input() unitFormSubmitted = true;

    @Input() signatureRequired = false;

    @Input() canShowContact = false;

    @Input()
    set unitInfo(unitInfo: UnitFacilityInformation) {
        this.isLoading = true;
        this.unit = unitInfo?.unit;
        this.setFormValues(this.unit);
        this.isLoading = false;
        this.watchFormChanges();
    }

    signatureForm: UntypedFormGroup;

    isLoading = false;

    unit: Unit;

    constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _store: Store) {
        super();

        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.signatureForm, changes.readOnly.currentValue);
        }
    }

    buildForm(): void {
        this.signatureForm = this._formBuilder.group({
            signatureName: this._formBuilder.control(null, Validators.maxLength(50)),
            signatureTitle: this._formBuilder.control(null, [Validators.maxLength(50)]),
            signatureEmail: this._formBuilder.control(null, emailValidator)
        });
        if (this.signatureRequired) {
            this.signatureForm.get('signatureName').setValidators(Validators.required);
            this.signatureForm.get('signatureEmail').setValidators(Validators.required);
        }
    }
    setFormValues(unit: Unit) {
        if (unit) {
            this.signatureForm.get('signatureName').patchValue(unit.signatureName);
            this.signatureForm.get('signatureTitle').patchValue(unit.signatureTitle);
            this.signatureForm.get('signatureEmail').patchValue(unit.signatureEmail);
        }
    }

    getFormValues(): Partial<Unit> {
        const unit: Partial<Unit> = {
            signatureName: this.signatureForm.get('signatureName').value,
            signatureTitle: this.signatureForm.get('signatureTitle').value,
            signatureEmail: this.signatureForm.get('signatureEmail').value
        };

        return unit;
    }

    watchFormChanges(): void {
        this.signatureForm.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            if (!this.isLoading) {
                this.valueChanged.emit({ unit: this.getFormValues(), isValid: this.signatureForm.valid });
            }
        });
    }

    markAsTouched(): void {
        this.signatureForm.get('signatureName').markAsTouched();
        this.signatureForm.get('signatureEmail').markAsTouched();
    }
}
