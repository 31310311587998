import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { SortTypes } from 'src/app/shared/models';
import { ClinicalService } from 'src/app/clinical/services/clinical.service';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import {
    loadGrid,
    loadGridSuccess,
    loadGridFailure,
    loadClinicalManagers,
    loadComponent,
    loadClinicalManagersSuccess,
    loadClinicalManagersFailure,
    setClinicalManagers,
    setClinicalManagersSuccess,
    setClinicalManagersFailure,
    setQuery,
    removeClinicalManagers,
    removeClinicalManagersSuccess,
    removeClinicalManagersFailure
} from '../actions/client-grid.actions';
import { selectClientsGridQuery, selectSetClinicalManagersRequest } from '../selectors/client-grid.selectors';

@Injectable()
export class ClientGridEffects extends BaseEffect {
    setQuery$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(setQuery),
            switchMap(() => [loadGrid()])
        );
    });

    getClientGrid$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadGrid, setClinicalManagersSuccess, removeClinicalManagersSuccess),
            withLatestFrom(this.store.select(selectClientsGridQuery)),
            switchMap(([, query]) => {
                const { pagination, sortArgs } = this.prepareParams(query);
                const matchArgs = flattenFilter(query.filter);
                return this._clinicalService.getClients(pagination, sortArgs, matchArgs).pipe(
                    map((result) =>
                        loadGridSuccess({
                            data: result.data,
                            total: result.total
                        })
                    ),
                    catchError((error: unknown) => of(loadGridFailure({ error })))
                );
            })
        );
    });

    getClientGridFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(loadGridFailure),
                tap(() => this._toasterService.fail('Failed to load grid.'))
            );
        },
        { dispatch: false }
    );

    getClinicalManagers$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadClinicalManagers, loadComponent),
            switchMap(() => {
                return this._clinicalService.getClinicalManagers().pipe(
                    mergeMap((result) => [
                        loadClinicalManagersSuccess({
                            clinicalManagers: result
                        })
                    ]),
                    catchError((error: unknown) => of(loadClinicalManagersFailure({ error })))
                );
            })
        );
    });

    setClinicalManagers$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(setClinicalManagers),
            withLatestFrom(this.store.select(selectSetClinicalManagersRequest)),
            switchMap(([, request]) => {
                return this._clinicalService.setClinicalManagers(request.clinicalManagerId, request.facilityIds).pipe(
                    map(() => setClinicalManagersSuccess()),
                    catchError((error: unknown) => of(setClinicalManagersFailure({ error })))
                );
            })
        );
    });

    setClinicalCLientsSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(setClinicalManagersSuccess),
                tap(() => this._toasterService.success('Clinical Manager assignment was successfully saved.'))
            );
        },
        { dispatch: false }
    );

    setClinicalCLientsFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(setClinicalManagersFailure),
                tap(() => this._toasterService.fail('Failed to save Clinical Manager assignment.'))
            );
        },
        { dispatch: false }
    );

    removeClinicalManagers$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(removeClinicalManagers),
            switchMap(({ facilityIds }) => {
                return this._clinicalService.removeClinicalManagers(facilityIds).pipe(
                    map(() => removeClinicalManagersSuccess()),
                    catchError((error: unknown) => of(removeClinicalManagersFailure({ error })))
                );
            })
        );
    });

    removeClinicalManagersSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(removeClinicalManagersSuccess),
                tap(() => this._toasterService.success('Clinical Manager assignment was successfully removed.'))
            );
        },
        { dispatch: false }
    );

    removeClinicalManagersFailure$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(removeClinicalManagersFailure),
                tap(() => this._toasterService.fail('Failed to remove Clinical Manager.'))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _clinicalService: ClinicalService,
        private readonly _toasterService: ToasterService,
        private readonly store: Store
    ) {
        super(_toasterService);
    }

    private prepareParams(query: GridSearchQuery): { pagination: any; sortArgs: any } {
        const sortCondition =
            query.sort && query.sort.length
                ? query.sort.map((q) => {
                      return q;
                  })
                : query.sort;

        const pagination = {
            pageSize: query.take,
            skip: query.skip
        };

        const sortArgs = {
            sortField: sortCondition && sortCondition[0].field,
            sortType: sortCondition && (sortCondition[0].dir as SortTypes)
        };

        return { pagination, sortArgs };
    }
}
