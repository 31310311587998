import { createAction, props } from '@ngrx/store';
import {
    InvoiceModel,
    InvoiceDetails,
    FacilityLookup,
    SystemLookup,
    InvoiceDisputeStatusLookup,
    InvoiceDisputeReasonLookup,
    UnitLookup,
    InvoiceAttachment,
    InvoiceDisputeReasonWithDisputeTypeLookup
} from '../models';
import { InvoiceCaregiverListingTypeLookup } from '../models/lookups/invoice-caregiver-listing-types.lookup';
import { InvoicesQueryState } from './reducers/invoices-query.reducer';
import { FileAttachment } from 'src/app/shared/models/attachment';
import { HttpResponse } from '@angular/common/http';

export enum InvoicesActionEnum {
    LoadInvoices = '[INVOICES] Load Invoices',
    LoadInvoicesSuccess = '[INVOICES] Load Invoices success',
    LoadInvoicesFail = '[INVOICES] Load Invoices fail',
    SetInvoicesSearchQuery = '[INVOICES] Set Invoices search query',

    LoadInvoicesLookups = '[INVOICES] Load Invoices Lookups',
    LoadInvoicesLookupsSuccess = '[INVOICES] Load Invoices Lookups success',
    LoadInvoicesLookupsFail = '[INVOICES] Load Invoices Lookups fail',

    ExportInvoices = '[INVOICES] Export Invoices',
    ExportInvoicesSuccess = '[INVOICES] Export Invoices Success',
    ExportInvoicesFail = '[INVOICES] Export Invoices Fail',

    ExportInvoiceLineDisputes = '[INVOICES] Export Invoice Line Disputes',
    ExportInvoiceLineDisputesSuccess = '[INVOICES] Export Invoice Line Disputes Success',
    ExportInvoiceLineDisputesFail = '[INVOICES] Export Invoice Line Disputes Fail',

    ExportInvoiceLineDisputesHistory = '[INVOICES] Export Invoice Line Disputes History',
    ExportInvoiceLineDisputesHistorySuccess = '[INVOICES] Export Invoice Line Disputes History Success',
    ExportInvoiceLineDisputesHistoryFail = '[INVOICES] Export Invoice Line Disputes History Fail',

    LoadInvoiceDocument = '[INVOICES] Load Invoice Document',
    LoadInvoiceDocumentSuccess = '[INVOICES] Load Invoice Document Success',
    LoadInvoiceDocumentFail = '[INVOICES] Load Invoice Document Fail',

    ClearInvoiceDetails = '[INVOICES] Clear Invoice Details',

    LoadInvoiceDetails = '[INVOICE DETAILS] Load Invoice Details',
    LoadInvoiceDetailsSuccess = '[INVOICE SERVICE] Load Invoice Details Success',
    LoadInvoiceDetailsFail = '[INVOICE SERVICE] Load Invoice Details Fail',

    LoadInvoiceAttachments = '[INVOICE DETAILS] Load Invoice Attachments',
    LoadInvoiceAttachmentsSuccess = '[INVOICE SERVICE] Load Invoice Attachments Success',
    LoadInvoiceAttachmentsFail = '[INVOICE SERVICE] Load Invoice Attachments Fail',

    LoadInvoiceAttachmentsEnhanced = '[INVOICE DETAILS] Load Invoice Attachments Enhanced',
    LoadInvoiceAttachmentsEnhancedSuccess = '[INVOICE SERVICE] Load Invoice Attachments Enhanced Success',
    LoadInvoiceAttachmentsEnhancedFail = '[INVOICE SERVICE] Load Invoice Attachments Enhanced Fail',

    CreateInvoiceDispute = '[INVOICE DISPUTES] Create Invoice Dispute',
    CreateInvoiceDisputeSuccess = '[INVOICE DISPUTES] Create Invoice Dispute Success',
    CreateInvoiceDisputeFail = '[INVOICE DISPUTES] Create Invoice Dispute Fail',

    ApproveDraftInvoice = '[INVOICES] Approve Draft Invoice',
    ApproveDraftInvoiceSuccess = '[INVOICES] Approve Draft Invoice Success',
    ApproveDraftInvoiceFail = '[INVOICES] Approve Draft Invoice Fail',
    ApproveDraftInvoiceReset = '[INVOICES] Approve Draft Invoice Reset',

    SetDashboardDraftInvoicesSearchQuery = '[INVOICES DASHBOARD] Set Draft Invoice Search Query',

    LoadDashboardDraftInvoicesCount = '[INVOICES DASHBOARD] Load Draft Invoices Count',
    LoadDashboardDraftInvoicesCountSuccess = '[INVOICES DASHBOARD] Load Draft Invoices Count Success',
    LoadDashboardDraftInvoicesCountFail = '[INVOICES DASHBOARD] Load Draft Invoices Count Fail',

    OpenAttachmentInViewer = '[INVOICES] Open Attachment In Viewer',

    OpenAttachmentInViewerEnhanced = '[INVOICES] Open Attachment In Viewer Enhanced',

    LoadInvoiceAttachmentPreview = '[INVOICES] Load Invoice Attachment Preview',
    LoadInvoiceAttachmentPreviewSuccess = '[INVOICES] Load Invoice Attachment Preview Success',
    LoadInvoiceAttachmentPreviewFailure = '[INVOICES] Load Invoice Attachment Preview Failure',

    DownloadInvoiceAttachment = '[INVOICES] Download Invoice Attachment',
    DownloadInvoiceAttachmentSuccess = '[INVOICES] Download Invoice Attachment Success',
    DownloadInvoiceAttachmentFailure = '[INVOICES] Download Invoice Attachment Failure',

    LoadInvoiceAttachmentPreviewTotalPages = '[INVOICES] Load Invoice Attachment Preview Total Pages',
    LoadInvoiceAttachmentPreviewTotalPagesSuccess = '[INVOICES] Load Invoice Attachment Preview Total Pages Success',
    LoadInvoiceAttachmentPreviewTotalPagesFailure = '[INVOICES] Load Invoice Attachment Preview Total Pages Failure',

    ResetInvoiceAttachmentPreview = '[INVOICES] Reset Invoice Attachment Preview'
}

// List
export const loadInvoices = createAction(InvoicesActionEnum.LoadInvoices, props<{ isBillable: boolean }>());

export const loadInvoicesSuccess = createAction(
    InvoicesActionEnum.LoadInvoicesSuccess,
    props<{ invoices: Array<InvoiceModel>; total: number }>()
);

export const loadInvoicesFail = createAction(InvoicesActionEnum.LoadInvoicesFail, props<{ error: any }>());

export const setInvoicesSearchQuery = createAction(
    InvoicesActionEnum.SetInvoicesSearchQuery,
    props<{ query: InvoicesQueryState }>()
);

// Lookups
export const loadInvoicesLookups = createAction(InvoicesActionEnum.LoadInvoicesLookups);

export const loadInvoicesLookupsSuccess = createAction(
    InvoicesActionEnum.LoadInvoicesLookupsSuccess,
    props<{
        facilities: Array<FacilityLookup>;
        systems: Array<SystemLookup>;
        disputeStatuses: Array<InvoiceDisputeStatusLookup>;
        disputeReasons: Array<InvoiceDisputeReasonLookup>;
        disputeReasonsWithDisputeType: Array<InvoiceDisputeReasonWithDisputeTypeLookup>;
        caregiverListingTypes: Array<InvoiceCaregiverListingTypeLookup>;
        units: Array<UnitLookup>;
    }>()
);

export const loadInvoicesLookupsFail = createAction(
    InvoicesActionEnum.LoadInvoicesLookupsFail,
    props<{ error: any }>()
);

// Export
export const exportInvoices = createAction(InvoicesActionEnum.ExportInvoices, props<{ invoiceIds: Array<number> }>());

export const exportInvoicesSuccess = createAction(
    InvoicesActionEnum.ExportInvoicesSuccess,
    props<{ blob: Blob; filename: string }>()
);

export const exportInvoicesFail = createAction(InvoicesActionEnum.ExportInvoicesFail, props<{ error: any }>());

// Document
export const loadInvoiceDocument = createAction(
    InvoicesActionEnum.LoadInvoiceDocument,
    props<{ id: number; brandName: string }>()
);

export const loadInvoiceDocumentSuccess = createAction(
    InvoicesActionEnum.LoadInvoiceDocumentSuccess,
    props<{ blob: Blob; filename: string }>()
);

export const loadInvoiceDocumentFail = createAction(
    InvoicesActionEnum.LoadInvoiceDocumentFail,
    props<{ error: any }>()
);

//Clear
export const clearInvoiceDetails = createAction(InvoicesActionEnum.ClearInvoiceDetails);

// Invoice details
export const loadInvoiceDetails = createAction(
    InvoicesActionEnum.LoadInvoiceDetails,
    props<{ id: number; isBillable: boolean }>()
);

export const loadInvoiceDetailsSuccess = createAction(
    InvoicesActionEnum.LoadInvoiceDetailsSuccess,
    props<{ invoiceDetails: InvoiceDetails }>()
);

export const loadInvoiceDetailsFail = createAction(InvoicesActionEnum.LoadInvoiceDetailsFail, props<{ error: any }>());

// Invoice attachments
export const loadInvoiceAttachments = createAction(InvoicesActionEnum.LoadInvoiceAttachments, props<{ id: number }>());

export const loadInvoiceAttachmentsSuccess = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentsSuccess,
    props<{ invoiceAttachments: InvoiceAttachment[] }>()
);

export const loadInvoiceAttachmentsFail = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentsFail,
    props<{ error: string }>()
);

export const openInvoiceAttachmentInViewer = createAction(
    InvoicesActionEnum.OpenAttachmentInViewer,
    props<{ invoiceId: number; documentId: number; documentName: string }>()
);

// Invoice attachments (Enhanced)
export const loadInvoiceAttachmentsEnhanced = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentsEnhanced,
    props<{ id: number }>()
);

export const loadInvoiceAttachmentsEnhancedSuccess = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentsEnhancedSuccess,
    props<{ invoiceFileAttachments: FileAttachment[] }>()
);

export const loadInvoiceAttachmentsEnhancedFail = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentsEnhancedFail,
    props<{ error: string }>()
);

export const openInvoiceAttachmentInViewerEnhanced = createAction(
    InvoicesActionEnum.OpenAttachmentInViewerEnhanced,
    props<{ invoiceId: number; documentId: number; documentName: string }>()
);

export const loadInvoiceAttachmentPreview = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentPreview,
    props<{ invoiceAttachmentId: number; pageNum: number }>()
);

export const loadInvoiceAttachmentPreviewSuccess = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentPreviewSuccess,
    props<{ file: Blob }>()
);

export const loadInvoiceAttachmentPreviewFailure = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentPreviewFailure,
    props<{ error: unknown }>()
);

export const loadInvoiceAttachmentPreviewTotalPages = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentPreviewTotalPages,
    props<{ invoiceAttachmentId: number }>()
);

export const loadInvoiceAttachmentPreviewTotalPagesSuccess = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentPreviewTotalPagesSuccess,
    props<{ totalPages: number }>()
);

export const loadInvoiceAttachmentPreviewTotalPagesFailure = createAction(
    InvoicesActionEnum.LoadInvoiceAttachmentPreviewTotalPagesFailure,
    props<{ error: unknown }>()
);

export const downloadInvoiceAttachment = createAction(
    InvoicesActionEnum.DownloadInvoiceAttachment,
    props<{ invoiceAttachmentId: number }>()
);

export const downloadInvoiceAttachmentSuccess = createAction(
    InvoicesActionEnum.DownloadInvoiceAttachmentSuccess,
    props<{ file: HttpResponse<Blob> }>()
);

export const downloadInvoiceAttachmentFailure = createAction(
    InvoicesActionEnum.DownloadInvoiceAttachmentFailure,
    props<{ error: unknown }>()
);

// Dispute create
/**
 * Action dispatched to create the invoice dispute.
 * @param invoiceId - id of the disputed invoice
 * @param notes - any additional notes about the dispute
 * @param invoiceLines - array of the disputed invoice lines.
 * @param isBillable - if the dispute invoice is drafted.  Each
 * element contains id of the line and id of the dispute reason
 */
export const createInvoiceDispute = createAction(
    InvoicesActionEnum.CreateInvoiceDispute,
    props<{
        invoiceNumber: number;
        invoiceId: number;
        notes: string;
        isBillable: boolean;
        lineItems: Array<{
            /** Id of the disputed invoice line */
            invoiceLineId: number;
            /** Id of the reason - why the line is disputed */
            invoiceDisputeReasonId: number;
        }>;
    }>()
);

/**
 * Action dispatched when invoice dispute is created succesfully
 * @param invoiceDetails - updated invoice details object
 * @param isNew - indicates whether new invoice dispute was created
 * or updated existing one
 * */
export const createInvoiceDisputeSuccess = createAction(
    InvoicesActionEnum.CreateInvoiceDisputeSuccess,
    props<{ invoiceDetails: InvoiceDetails; isNew: boolean }>()
);

/**
 * Action dispatched when invoice dispute is not created succesfully.
 * @param error - any additional information regarding the error
 */
export const createInvoiceDisputeFail = createAction(
    InvoicesActionEnum.CreateInvoiceDisputeFail,
    props<{ error: any }>()
);

export const exportInvoiceLineDisputes = createAction(
    InvoicesActionEnum.ExportInvoiceLineDisputes,
    props<{ invoiceIds: Array<number> }>()
);

export const exportInvoiceLineDisputesSuccess = createAction(
    InvoicesActionEnum.ExportInvoiceLineDisputesSuccess,
    props<{ blob: Blob; filename: string }>()
);

export const exportInvoiceLineDisputesFail = createAction(
    InvoicesActionEnum.ExportInvoiceLineDisputesFail,
    props<{ error: any }>()
);

export const exportInvoiceLineDisputesHistory = createAction(
    InvoicesActionEnum.ExportInvoiceLineDisputesHistory,
    props<{ invoiceIds: Array<number> }>()
);

export const exportInvoiceLineDisputesHistorySuccess = createAction(
    InvoicesActionEnum.ExportInvoiceLineDisputesHistorySuccess,
    props<{ blob: Blob; filename: string }>()
);

export const exportInvoiceLineDisputesHistoryFail = createAction(
    InvoicesActionEnum.ExportInvoiceLineDisputesHistoryFail,
    props<{ error: any }>()
);

export const approveDraftInvoices = createAction(
    InvoicesActionEnum.ApproveDraftInvoice,
    props<{ invoiceIds: number[] }>()
);

export const approveDraftInvoicesSuccess = createAction(InvoicesActionEnum.ApproveDraftInvoiceSuccess);

export const approveDraftInvoicesFail = createAction(
    InvoicesActionEnum.ApproveDraftInvoiceFail,
    props<{ error: any }>()
);

export const approveDraftInvoicesReset = createAction(InvoicesActionEnum.ApproveDraftInvoiceReset);

export const setDashboardDraftInvoicesSearchQuery = createAction(
    InvoicesActionEnum.SetDashboardDraftInvoicesSearchQuery,
    props<{
        facilityId?: number;
        unitId?: number;
    }>()
);

export const loadDashboardDraftInvoicesCount = createAction(InvoicesActionEnum.LoadDashboardDraftInvoicesCount);

export const loadDashboardDraftInvoicesCountSuccess = createAction(
    InvoicesActionEnum.LoadDashboardDraftInvoicesCountSuccess,
    props<{ totalCount: number }>()
);

export const loadDashboardDraftInvoicesCountFail = createAction(
    InvoicesActionEnum.LoadDashboardDraftInvoicesCountFail,
    props<{ error: any }>()
);

export const resetInvoiceAttachmentPreview = createAction(InvoicesActionEnum.ResetInvoiceAttachmentPreview);
