import { LookupsService } from './services/lookups.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { lookupsStoreKey } from 'src/app/lookups/state/lookups.state';
import { lookupsReducer } from 'src/app/lookups/state/redusers';
import { lookupsEffects } from 'src/app/lookups/state/effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(lookupsStoreKey, lookupsReducer),
    EffectsModule.forFeature(lookupsEffects),
  ],
  providers: [LookupsService]
})

export class LookupsModule {
    constructor(@Optional() @SkipSelf() parentModule: LookupsModule) {
      if (parentModule) {
        throw new Error('Core is already loaded.  Import it in the AppModule only');
      }
    }
  }
