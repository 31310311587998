import { createSelector } from '@ngrx/store';
import { selectFeature } from '../submittals.selectors';
import { LoadingTypes } from 'src/app/shared/models';

export const selectSubmittalOffer = createSelector(selectFeature, (state) => state.offer);

export const selectSubmittalOfferDetails = createSelector(selectSubmittalOffer, (state) => state.submittalOfferDetails);

export const selectSubmittalOfferDetailsUpdating = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferDetailsUpdating === LoadingTypes.LOADING
);

export const selectSubmittalOfferCreating = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferCreating
);

export const selectSubmittalOfferNotes = createSelector(selectSubmittalOffer, (state) => state.submittalOfferNotes);

export const selectSubmittalOfferNotesLoading = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferNotesLoading === LoadingTypes.LOADING
);

export const selectSubmittalOfferNotesAdding = createSelector(
    selectSubmittalOffer,
    (state) => state.submittalOfferNoteAdding === LoadingTypes.LOADING
);
