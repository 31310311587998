import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ayac-submittals-modal',
    templateUrl: './submittals-modal.component.html',
    styleUrls: ['./submittals-modal.component.scss']
})
export class SubmittalsModalComponent implements OnInit {
    @Input() title: string = '';
    @Input() loading: boolean = false;
    @Input() loadingText: string = '';

    @Input() actionButtonColor: string = 'accent';
    @Input() actionButtonName: string = 'OK';
    @Input() actionButtonDisabled: boolean = false;
    @Input() displayRedActionButton = false;

    @Output() actionButtonClick = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
    }
}
