import { createAction, props } from '@ngrx/store';
import { FileAttachment, FileValidationResult } from 'src/app/shared/models/attachment';
import { AttachmentFeature } from 'src/app/shared/models';
import { ShiftAttachment } from 'src/app/shifts/models';

export const loadPerdiemschedulerShiftAttachments = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Load Attachments',
    props<{ shiftId: number }>()
);
export const loadPerdiemschedulerShiftAttachmentsSuccess = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Attachments Success',
    props<{ attachments: ShiftAttachment[] }>()
);

export const loadPerdiemschedulerShiftAttachmentsFail = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Attachments Failed',
    props<{ error: any }>()
);

export const deletePerdiemschedulerShiftAttachment = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Delete Shift Attachments',
    props<{ shiftId: number; id: number }>()
);
export const deletePerdiemschedulerShiftAttachmentSuccess = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Delete Shift Attachments success',
    props<{ shiftId: number; id: number }>()
);
export const deletePerdiemschedulerShiftAttachmentFail = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Delete Shift Attachments fail',
    props<{ error: unknown }>()
);

export const loadPerdiemschedulerShiftAttachment = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Load Shift Attachment',
    props<{ shiftId: number; id: number; fileName?: string }>()
);
export const loadPerdiemschedulerShiftAttachmentSuccess = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Shift Attachment success',
    props<{ blob: Blob; fileName?: string }>()
);
export const loadPerdiemschedulerShiftAttachmentFail = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Shift Attachment fail',
    props<{ error: unknown }>()
);

export const loadPreviewPerdiemschedulerShiftAttachments = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Load Preview',
    props<{ shiftId: number; id: number; pageNum: number; file: FileAttachment }>()
);
export const loadPreviewPerdiemschedulerShiftAttachmentsSuccess = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Preview Success',
    props<{ attachmentPreview: Blob }>()
);
export const loadPreviewPerdiemschedulerShiftAttachmentsFail = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Preview Fail',
    props<{ error: unknown }>()
);
export const loadPreviewNextPage = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Load Preview Next Page',
    props<{ pageNum: number }>()
);

export const loadPreviewShiftPageCount = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Load Preview Page Count Shift Attachments',
    props<{ shiftId: number; id: number }>()
);
export const loadPreviewShiftPageCountSuccess = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Preview Page Count Shift Attachments Success',
    props<{ total: number }>()
);
export const loadPreviewShiftPageCountFail = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Preview Page Count Shift Attachments fail',
    props<{ error: unknown }>()
);

export const clearPerdiemschedulerShiftAttachments = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Clear Shift Attachments'
);
export const clearShiftAttachmentPreview = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Clear Shift Attachment Preview'
);

export const loadShiftAttachmentFeatures = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Load Shift Attachment Features',
    props<{ facilityId: number }>()
);

export const loadShiftAttachmentFeaturesSuccess = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Shift Attachment Features success',
    props<{ attachmentFeatures: AttachmentFeature }>()
);

export const loadShiftAttachmentFeaturesFail = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Load Shift Attachment Features fail',
    props<{ error: unknown }>()
);

export const savePerdiemschedulerShiftAttachment = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS COMPONENT] Save Shift Attachment',
    props<{ shiftId: number; fileToUpload: File; internalOnly?: boolean }>()
);
export const savePerdiemschedulerShiftAttachmentSuccess = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Save Shift Attachment success',
    props<{ fileValidationResult: FileValidationResult[]; shiftId: number }>()
);
export const savePerdiemschedulerShiftAttachmentFail = createAction(
    '[PERDIEM SCHEDULER SHIFTS ATTACHMENTS API] Save Shift Attachment fail',
    props<{ fileValidationResult: FileValidationResult; error: unknown }>()
);
