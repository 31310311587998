import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesPermissionsPresentationComponent } from '../facilities-shared/features-permissions-presentation/features-permissions-presentation.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EmailNotificationsPresentationComponent } from '../facilities-shared/email-notifications-presentation/email-notifications-presentation.component';
import { ManageFeaturesPresentationComponent } from 'src/app/facilities/facilities-shared/manage-features-presentation/manage-features-presentation.component';
import { CustomFieldsGridComponent } from 'src/app/core/custom-fields-grid/custom-fields-grid.component';
import { AssociationVendorsComponent } from 'src/app/facilities/associations/association-vendors/association-vendors.component';

@NgModule({
    declarations: [
        ManageFeaturesPresentationComponent,
        CustomFieldsGridComponent,
        FeaturesPermissionsPresentationComponent,
        EmailNotificationsPresentationComponent,
        AssociationVendorsComponent
    ],
    imports: [SharedModule, CommonModule],
    exports: [
        ManageFeaturesPresentationComponent,
        CustomFieldsGridComponent,
        FeaturesPermissionsPresentationComponent,
        EmailNotificationsPresentationComponent,
        AssociationVendorsComponent
    ]
})
export class FacilityFeatureModule {}
