import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { VendorContact, ContactPhoneType, VendorPhone } from 'src/app/shared/models';
import { VendorContactChange } from '../vendor-contacts-change.model';

@Component({
    selector: 'ayac-vendor-contact-phone',
    templateUrl: 'vendor-contact-phone.component.html',
    styleUrls: ['./vendor-contact-phone.component.scss']
})
export class VendorContactPhoneComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
    @Output() contactChanged: EventEmitter<VendorContactChange | null> = new EventEmitter<VendorContactChange | null>();
    @Input() contact: VendorContact;
    @Input() phoneTypes: ContactPhoneType[];
    contactPhoneForm: UntypedFormGroup;

    constructor() {
        super();
    }

    get phones(): UntypedFormArray {
        return this.contactPhoneForm.get('phones') as UntypedFormArray;
    }

    ngOnInit(): void {
        this.contactPhoneForm = this.createPhoneListForm(this.contact.vendorContactPhones);
    }

    ngAfterViewInit(): void {
        this.contactPhoneForm.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.d$))
            .subscribe((updatedContact: { phones: any[] }) => {
                this.contactChanged.emit({
                    vendorContact: this.convertContactForSaving(updatedContact.phones),
                    changeType: 'phone',
                    isInvalidChange: this.contactPhoneForm.invalid
                });
            });
    }

    addPhone(): void {
        const phoneTypeId = this.getNextPhoneTypeId(this.phoneTypes, this.phones.value);
        const newPhone: any = {
            id: 0,
            vendorContactId: this.contact.id,
            vendorContactPhoneNumberTypeId: phoneTypeId,
            areaCode: '',
            exchange: '',
            station: '',
            extension: ''
        };

        this.phones.push(this.createPhoneForm(newPhone));
    }

    removePhone(index: number): void {
        this.phones.removeAt(index);
    }

    getNextPhoneTypeId(phoneTypes: ContactPhoneType[], phoneTypesAlreadyAdded: any[]): number {
        const unusedPhoneTypes = phoneTypes.filter(
            (type: ContactPhoneType) =>
                !phoneTypesAlreadyAdded.find((formValue: any) => formValue.phoneTypeId === type.id)
        );

        if (unusedPhoneTypes.length === 0) {
            return phoneTypes[0].id;
        }

        return unusedPhoneTypes[0].id;
    }

    createPhoneListForm(phones: VendorPhone[] | null): UntypedFormGroup {
        return new UntypedFormGroup({
            phones: this.createPhoneListArrayForm(phones)
        });
    }

    private createPhoneListArrayForm(phones: VendorPhone[]): UntypedFormArray {
        if (phones) {
            const phoneControls = phones.map((phone: VendorPhone) => {
                return this.createPhoneForm(phone);
            });

            return new UntypedFormArray(phoneControls);
        }

        return new UntypedFormArray([]);
    }

    private createPhoneForm(phone: VendorPhone): UntypedFormGroup {
        return new UntypedFormGroup({
            id: new UntypedFormControl(phone.id),
            note: new UntypedFormControl(phone.note),
            phoneTypeId: new UntypedFormControl(phone.vendorContactPhoneNumberTypeId),
            phoneNumber: new UntypedFormControl(
                `${phone.areaCode}${phone.exchange}${phone.station}${phone.extension}`,
                Validators.required
            ),
            vendorContactId: new UntypedFormControl(phone.vendorContactId),
            countryCode: new UntypedFormControl(phone.countryCode)
        });
    }

    private convertContactForSaving(phones: any[]): VendorContact {
        return {
            ...this.contact,
            vendorContactPhones: phones.map((phone): VendorPhone => {
                const phoneNumber = phone.phoneNumber.replace(/\D/g, '');
                const areaCode = phoneNumber.slice(0, 3);
                const exchange = phoneNumber.slice(3, 6);
                const station = phoneNumber.slice(6, 10);
                const extension = phoneNumber.slice(10);

                return {
                    id: phone.id,
                    areaCode: areaCode,
                    countryCode: phone.countryCode,
                    exchange: exchange,
                    extension: extension,
                    note: phone.note,
                    station: station,
                    vendorContactId: phone.phoneTypeId,
                    vendorContactPhoneNumberTypeId: phone.phoneTypeId
                };
            })
        };
    }
}
