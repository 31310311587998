import { GridSearchQuery } from 'src/app/shared/grid/models/grid-search-query.model';
import { Action, createReducer, on } from '@ngrx/store';
import { PermJobGridActions } from '../actions';
import { TypedAction } from '@ngrx/store/src/models';
import { SortTypes } from 'src/app/shared/models';
import { PermJobsSortTypes } from 'src/app/permanent-jobs/models/perm-jobs-sort-types.enum';

export type PermJobsGridQueryState = GridSearchQuery;

export const initialState: PermJobsGridQueryState = {
    sort: [{ field: PermJobsSortTypes.JobInfoId, dir: SortTypes.DESC }],
    take: 10,
    skip: 0,
    filter: {
        logic: 'and',
        filters: []
    }
};

type SetPermJobsSearchQueryActionType = {
    query: GridSearchQuery;
} & TypedAction<PermJobGridActions.PermJobsActionTypes.SetPermJobsSearchQuery>;

export const permJobsQueryReducer = (
    reducerState: PermJobsGridQueryState,
    reducerAction: Action
): PermJobsGridQueryState => {
    return createReducer(
        initialState,
        on(PermJobGridActions.setPermJobsSearchQuery,
            (state: PermJobsGridQueryState, action: SetPermJobsSearchQueryActionType) => {
                return {
                    ...state,
                    ...action.query
                }
            }
        ),
    )(reducerState, reducerAction);
};

export const reducer = (state: PermJobsGridQueryState | undefined, action: Action) => {
    return permJobsQueryReducer(state, action);
};
