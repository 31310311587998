import {
    CopyReleaseSettingsToModalState,
    getInitialCopyReleaseSettingsToModalState
} from 'src/app/facilities/store/state/copy-release-settings-to-modal.state';
import {
    FacilitiesListLookupsState,
    getInitialFacilitiesListLookupsState
} from 'src/app/facilities/store/state/facilities-list-lookups.state';
import {
    FacilitiesListState,
    getInitialFacilitiesListState
} from 'src/app/facilities/store/state/facilities-list.state';
import {
    CopyReleaseSettingsFromModalState,
    getInitialCopyReleaseSettingsFromModalState
} from './copy-release-settings-from-modal.state';
import {
    FacilityProfileState,
    getInitialFacilityProfileState
} from 'src/app/facilities/store/state/facility-profile.state';
import {
    FacilityFeaturesState,
    getInitialFacilityFeaturesState
} from 'src/app/facilities/store/state/facility-features.state';
import { ScheduledReleaseRulesState, getInitialScheduledReleaseRulesState } from './scheduled-release-rules.state';
import { getInitialReleaseGroupsState, ReleaseGroupsState } from './release-groups.state';
import { FacilityVendorsState, getInitialFacilityVendorsState } from './facility-vendors.state';
import {
    FacilityAssociationsListState,
    getInitialFacilityAssociationsListState
} from 'src/app/facilities/store/state/facility-associations-list.state';
import {
    FacilitiesSystemListState,
    getInitialFacilitiesSystemsListState
} from 'src/app/facilities/store/state/facilities-systems-list.state';
import {
    FacilitySettingsState,
    getInitialFacilitySettingsState
} from 'src/app/facilities/store/state/facility-settings.state';
import {
    FacilityAssociationState,
    getInitialFacilityAssociationState
} from 'src/app/facilities/store/state/facility-association.state';
import { ContractGroupFeaturesState, getContractGroupFeaturesState } from './contract-group-permissions.state';
import {
    FacilitySystemsProfileState,
    getInitialFacilitySystemsProfileState
} from 'src/app/facilities/store/state/facility-systems-profile.state';

export interface FacilitiesState {
    list: FacilitiesListState;
    systemsList: FacilitiesSystemListState;
    associations: FacilityAssociationsListState;
    facilityAssociation: FacilityAssociationState;
    lookups: FacilitiesListLookupsState;
    copyReleaseSettingsToModal: CopyReleaseSettingsToModalState;
    copyReleaseSettingsFromModal: CopyReleaseSettingsFromModalState;
    facilityProfile: FacilityProfileState;
    facilityFeatures: FacilityFeaturesState;
    scheduledReleaseRulesState: ScheduledReleaseRulesState;
    jobReleaseGroups: ReleaseGroupsState;
    shiftReleaseGroups: ReleaseGroupsState;
    facilityVendors: FacilityVendorsState;
    facilitySettings: FacilitySettingsState;
    contractGroupFeatures: ContractGroupFeaturesState;
    systemsProfiles: FacilitySystemsProfileState;
}

export const getInitialFacilitiesState = (): FacilitiesState => ({
    list: getInitialFacilitiesListState(),
    systemsList: getInitialFacilitiesSystemsListState(),
    lookups: getInitialFacilitiesListLookupsState(),
    copyReleaseSettingsToModal: getInitialCopyReleaseSettingsToModalState(),
    copyReleaseSettingsFromModal: getInitialCopyReleaseSettingsFromModalState(),
    facilityProfile: getInitialFacilityProfileState(),
    facilityFeatures: getInitialFacilityFeaturesState(),
    scheduledReleaseRulesState: getInitialScheduledReleaseRulesState(),
    jobReleaseGroups: getInitialReleaseGroupsState(),
    shiftReleaseGroups: getInitialReleaseGroupsState(),
    facilityVendors: getInitialFacilityVendorsState(),
    associations: getInitialFacilityAssociationsListState(),
    facilityAssociation: getInitialFacilityAssociationState(),
    facilitySettings: getInitialFacilitySettingsState(),
    contractGroupFeatures: getContractGroupFeaturesState(),
    systemsProfiles: getInitialFacilitySystemsProfileState()
});
