import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableConfig } from '../../models/mat-table.model';

@Component({
  selector: 'ayac-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss']
})
export class MatTableComponent implements OnInit {
  constructor() { }

  tableDataSource: MatTableDataSource<any>;

  @ViewChild(MatSort, {static: true}) public sort: MatSort;
  @Input() matTableConfig: MatTableConfig;

  get isTableDataEmpty() {
    return this.tableDataSource ? this.tableDataSource.data.length === 0 : null;
  }

  get tableDisplayedColumns() {
    return this.matTableConfig.columns.map(c => c.field)
  }

  ngOnInit() {   
    if (this.matTableConfig.data.length > 0) {
        const tableDataSourceItems = this.matTableConfig.data.filter(f => f !== null).map((item) => item);
        /**
        * Add range both in one array.
        */
        this.tableDataSource = new MatTableDataSource(tableDataSourceItems);
        /**
            * Sort MatTable using a variable matSort.
            */
        this.tableDataSource.sort = this.sort;
        if (this.sort && this.sort.sort) {
            this.sort.sort((this.matTableConfig.sort) as MatSortable);
        }
    }

  }

}
