import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { VendorJobRuleDetails, VendorJobRulesList } from '../profile/models/job-rules.model';
import {
    JobRuleProfessionTree,
    JobRuleProfessionTreePayload
} from 'src/app/admin-vendors/models/job-rule-profession-specialties.model';

@Component({
    selector: 'ayac-vendor-job-rules-panel',
    templateUrl: './job-rules-panel.component.html',
    styleUrls: ['./job-rules-panel.component.scss']
})
export class VendorJobRulesPanelComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() jobRules: VendorJobRulesList[];
    @Input() jobRuleDetails: VendorJobRuleDetails;
    @Input() isNew = false;
    @Input() isLoading = false;
    @Input() professionTypeTree: JobRuleProfessionTree;
    @Input() professionTypeValues: JobRuleProfessionTreePayload;
    @Output() jobRuleDetailEmit = new EventEmitter<number>();
    hasUserPermissions = false;
    isReadOnly = true;
    preSelectedProfessionTree: JobRuleProfessionTreePayload[];

    constructor(private readonly _identityService: IdentityService) {
        super();
    }

    ngOnInit(): void {
        this.hasUserPermissions = this._identityService.inScope('admin', 'vendors_jobRules');
    }

    accordionOpened(ruleId: number): void {
        this.jobRuleDetailEmit.emit(ruleId);
    }
}
