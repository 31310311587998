import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class CommonService {
    constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

    hidePageLayoutElement(element: string, referId: string) {
        if (element.toUpperCase() === 'FACILITY_DROPDOWN') {
            const css = '#selectFacility { display: none; }';
            const selectedElement = this._document.getElementById(referId);
            const styleTag = this._document.createElement('style');

            if (selectedElement) {
                if (styleTag.sheet) {
                    styleTag.sheet.insertRule(css, 0);
                } else {
                    styleTag.appendChild(this._document.createTextNode(css));
                }

                selectedElement.appendChild(styleTag);
            }
        }
    }
}
