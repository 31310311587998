import { Component, Input, EventEmitter, Output, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'timepicker-inline',
    templateUrl: 'timepicker-inline.component.html',
    styleUrls: ['timepicker-inline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimepickerInlineComponent {
    @ViewChild('inputHour', { static: true }) inputHour: ElementRef;
    @ViewChild('inputMinute', { static: true }) inputMinute: ElementRef;

    @Input() displayEmptyTime = false;

    @Output() dateModelChange: EventEmitter<Date> = new EventEmitter<Date>();

    @Output() edited: EventEmitter<Date> = new EventEmitter<Date>();

    @Input() freeText: boolean;
    @Input() disabled = false;
    viewHour: string;
    viewMinute: string;
    hasChanges: boolean;
    get dateModel() {
        if (this.viewHour === null && this.viewMinute === null) {
            return null;
        }
        const _date = moment([this.viewHour || 0, this.viewMinute || 0], 'HH:mm');
        return _date.isValid() ? _date.toDate() : null;
    }

    @Input()
    set dateModel(value: Date) {
        const date = moment(value);
        if (date.isValid() && !this.isIosMinDate(value)) {
            this.viewHour = date.format('HH');
            this.viewMinute = date.format('mm');
        } else if (this.displayEmptyTime) {
            this.viewHour = '';
            this.viewMinute = '';
        }
    }

    onChange() {
        this.hasChanges = true;
    }

    onBlur() {
        if (this.hasChanges) {
            this.dateModelChange.emit(this.dateModel);

            setTimeout(() => {
                if (
                    this.inputHour.nativeElement !== document.activeElement &&
                    this.inputMinute.nativeElement !== document.activeElement
                ) {
                    this.edited.emit(this.dateModel);
                    this.hasChanges = false;
                }
            });
        }
    }

    onViewHourInput() {
        if (this.inputHour.nativeElement.value.length === 2 || parseInt(this.viewHour, 10) > 2) {
            if (parseInt(this.viewHour, 10) > 23) {
                this.viewHour = '00';
            }
            this.inputMinute.nativeElement.focus();
            setTimeout(() => this.inputMinute.nativeElement.select());
        }
    }

    onViewMinuteInput() {
        if (this.inputMinute.nativeElement.value.length === 2 || parseInt(this.viewMinute, 10) > 5) {
            if (parseInt(this.viewMinute, 10) > 59) {
                this.viewMinute = '00';
            }
            this.inputMinute.nativeElement.blur();
        }
    }

    onViewHourClick() {
        this.inputHour.nativeElement.select();
    }

    onViewMinuteClick() {
        this.inputMinute.nativeElement.select();
    }

    private isIosMinDate(value: any) {
        const date = moment(value);
        //IOS issue
        let isMinDate = false;
        if (value && value.toString()) {
            if (value.toString().includes('0001')) {
                if (date.year() === 2001) {
                    isMinDate = true;
                }
            }
        }
        return isMinDate;
    }
}
