/**angular */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

/**rxjs */
import { Observable } from 'rxjs';

/**local */
import { CertificationMultipleRequest } from 'src/app/admin/vendor-details/certifications/models/certification-multiple-request.model';
import { ProfileMetadataResponse } from 'src/app/admin/vendor-details/certifications/models/profile-metadata-response.model';
import { VendorProfileResponse } from 'src/app/admin/vendor-details/certifications/models/vendor-profile-response.model';

/**shared */
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { generateGUID } from 'src/app/shared/utilities/generate-guid';
import { VendorDetailsUrls } from '../../enums/vendor-detail-urls.enum';

@Injectable({
    providedIn: 'root'
})
export class VendorProfileCertificationsService {
    private readonly apiPath: string;

    constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {
        this.apiPath = `${_settings.CORE}${VendorDetailsUrls.VENDOR_CERTIFICATION}`;
    }

    getCertificationsByVendorId(vendorId: number): Observable<VendorProfileResponse> {
        const url = `${this.apiPath}/${vendorId}`;
        return this._httpClient.get<VendorProfileResponse>(url);
    }

    getCertificationsMetadata(): Observable<ProfileMetadataResponse> {
        return this._httpClient.get<ProfileMetadataResponse>(`${this.apiPath}`);
    }

    updateVendorCertifications(certification: CertificationMultipleRequest): Observable<any> {
        const formData = new FormData();

        certification.certifications.forEach((x) => {
            x.uploadFiles?.forEach((f) => {
                const fileId = generateGUID();
                x.uploadFileIds.push(fileId);
                formData.append(fileId, f);
            });
            formData.append('certifications', JSON.stringify(x));
        });

        return this._httpClient.post<any>(`${this.apiPath}/${certification.vendorId}`, formData);
    }

    downloadFile(vendorId: number, fileId: number): Observable<HttpResponse<Blob>> {
        return this._httpClient.get(`${this.apiPath}/${vendorId}/${fileId}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportVendorCertifications(vendorId: number): Observable<Blob> {
        const url = `${this.apiPath}/${vendorId}/export`;
        return this._httpClient.get(url, { responseType: 'blob' });
    }
}
