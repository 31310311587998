import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials'
})
export class InitialsFromNamePipe implements PipeTransform {
    transform(fullName: string | null): string {
        if (!fullName) {
            return '';
        }

        const names = fullName.split(' ');
        let initials = '';

        if (names.length > 0) {
            initials += names[0].charAt(0);
        }
        if (names.length > 1) {
            initials += names[names.length - 1].charAt(0);
        }

        return initials.toUpperCase();
    }
}
