import { FacilityAdministrationLookups, getEmptyFacilitiesLookups } from 'src/app/facilities/models/facility-administration-lookups.model';
import { LoadingTypes } from 'src/app/shared/models';

export interface FacilitiesListLookupsState extends FacilityAdministrationLookups {
    loadingState: LoadingTypes,
    error: string
}

export const getInitialFacilitiesListLookupsState = (): FacilitiesListLookupsState => ({
    ...getEmptyFacilitiesLookups(),
    loadingState: LoadingTypes.INIT,
    error: ''
});
