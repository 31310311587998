import { ChangeDetectionStrategy, Component, ContentChild, Input, OnChanges, TemplateRef } from '@angular/core';

@Component({
    selector: 'ayac-columns-layout',
    templateUrl: './columns-layout.component.html',
    styleUrls: ['./columns-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsLayoutComponent implements OnChanges {
    @Input() numColumns = 3;
    @Input() minFirstColumn = 5;
    @Input() items = [];
    @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;

    columns = [];

    ngOnChanges(): void {
        this.updateColumns();
    }
    
    updateColumns() {
        const items = this.items.slice();
        const columnItemsCount = Math.ceil(this.items.length / this.numColumns);

        this.columns = Array(this.numColumns).fill([])
            .map((c, i) => {
                const take = Math.max.apply(null, [this.minFirstColumn, columnItemsCount]);
                return items.splice(0, take);
            });
    }

    get columnWidth(): string {
        return `${(100 / this.numColumns).toFixed(2)}%`;
    }

}
