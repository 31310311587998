import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubmittalActivity } from 'src/app/submittals/models';
import { SubmittalNoteType } from 'src/app/submittals/models/submittal-note-type.enum';
import { SortTypes } from 'src/app/shared/models';
import { MatSelectChange } from '@angular/material/select';
import { DateHelper } from 'src/app/shared/utilities';

@Component({
    selector: 'aya-submittal-activity-and-comments-section',
    templateUrl: 'activity-and-comments-section.component.html',
    styleUrls: ['activity-and-comments-section.component.scss']
})
export class ActivityAndCommentsSectionComponent implements OnInit {
    @Output() changeSortOrder = new EventEmitter<SortTypes>();
    @Output() replyToComment = new EventEmitter<SubmittalActivity>();

    @Input() source: 'Client' | 'Vendor' = 'Client';
    @Input() activities!: SubmittalActivity[];
    @Input() showChangeSortOrder = true;
    @Input() showReplyButton = true;
    @Input() isPendingOverdue = false;

    noteType = SubmittalNoteType;
    localTimeZoneName = '';
    sortForm = new FormGroup({
        sortOrder: new FormControl('asc', Validators.required)
    });

    ngOnInit(): void {
        this.localTimeZoneName = DateHelper.getLocalTimezoneName();
    }

    replyTo(activity: SubmittalActivity): void {
        this.replyToComment.emit(activity);
    }

    updateSortOrder(event: MatSelectChange): void {
        this.changeSortOrder.emit(event.value as SortTypes);
    }
}
