import { ConfigOption } from '@ngx-formly/core';
import { KendoDropdownComponent } from './templates/kendo-dropdown/kendo-dropdown.component';
import { KendoFieldComponent } from './templates/kendo-field/kendo-field.component';
import { KendoInputComponent } from './templates/kendo-input/kendo-input.component';
import { KendoWorkerDetailsInputComponent } from './templates/kendo-worker-details-input/kendo-worker-details-input.component';
import { KendoWorkerDetailsComponent } from './templates/kendo-worker-details-label/kendo-worker-details-label.component';
import { OptionsInputComponent } from './templates/options-input/options-input.component';
import { KendoWrapperComponent } from './wrappers/kendo-wrapper.component';
import { RichOptionsInputComponent } from './templates/rich-options-input/rich-options-input.component';
import { ChildDropdownInputComponent } from './templates/child-dropdown/child-dropdown-input.component';
import { KendoSearchableJobDropdownComponent } from './templates/kendo-searchable-job-dropdown/kendo-searchable-job-dropdown.component';
import { KendoSearchableWorkerDropdownComponent } from './templates/kendo-searchable-worker-dropdown/kendo-searchable-worker-dropdown.component';
import { KendoJobDropdownComponent } from './templates/kendo-job-dropdown/kendo-job-dropdown.component';

export const formlyConfig: ConfigOption = {
    validators: [],
    validationMessages: [
        { name: 'min', message: 'Number is too low' },
        { name: 'max', message: 'Number is too high' }
    ],
    wrappers: [{ name: 'kendo', component: KendoWrapperComponent }],
    types: [
        { name: 'child-dropdown-input', component: ChildDropdownInputComponent },
        { name: 'options-input', component: OptionsInputComponent },
        { name: 'rich-options-input', component: RichOptionsInputComponent },
        { name: 'kendo-input', component: KendoInputComponent },
        { name: 'kendo-field', component: KendoFieldComponent },
        {
            name: 'kendo-worker-display',
            component: KendoWorkerDetailsComponent
        },
        {
            name: 'kendo-worker-input',
            component: KendoWorkerDetailsInputComponent
        },
        { name: 'kendo-worker-dropdown', component: KendoDropdownComponent },
        { name: 'kendo-searchable-job-dropdown', component: KendoSearchableJobDropdownComponent },
        { name: 'kendo-searchable-worker-dropdown', component: KendoSearchableWorkerDropdownComponent },
        { name: 'kendo-job-dropdown', component: KendoJobDropdownComponent }
    ]
};
