
import { Action, createReducer, on } from '@ngrx/store';
import { JobsCount } from '../../models';
import { LoadingTypes } from 'src/app/shared/models';
import * as dashboardActions from '../actions';

export interface JobsState {
    loading: LoadingTypes,
    count: JobsCount,
    submittalsAgingHours: number
}

export const initialJobsState: JobsState = {
    loading: LoadingTypes.INIT,
    count: {
        jobsCount: 0,
        totalPositionsCount: 0,
        filledPositionsCount: 0,
        openPositionsCount: 0,
        submittalsCount: 0,
        facilityHasJobApproval: false
    },
    submittalsAgingHours: 72
}

export function jobsReducer(state: JobsState, action: Action) {
    return createReducer(
        initialJobsState,
        on(dashboardActions.loadJobsCount, (state, action) => (
            {
                ...state,
                loading: LoadingTypes.LOADING,
            }
        )),

        on(dashboardActions.loadJobsCountSuccess, (state, action) => (
            {
                ...state,
                count: action.count,
                loading: LoadingTypes.LOADED,
            }
        )),

        on(dashboardActions.loadJobsCountFail, (state, action) => (
            {
                ...state,
                loading: LoadingTypes.LOADED,
            }
        )),

        on(dashboardActions.clearJobsCount, (state, action) => (
            {
                ...state,
                ...initialJobsState
            }
        )),
    )(state, action);
}

export function reducer(state: JobsState | undefined, action: Action) {
    return jobsReducer(state, action);
}
