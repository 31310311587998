import { Action, createReducer, on } from "@ngrx/store";
import { loadDefaultDateFromSuccess } from "../perdiemscheduler.actions";

export interface PerdiemSchedulerDefaultDateFromState  {
    minDate: Date;
}

export const getInitialDefaultDateFromState = (): PerdiemSchedulerDefaultDateFromState => ({
    minDate: null
});

export function perdiemSchedulerDefaultDateFromReducer(state: PerdiemSchedulerDefaultDateFromState, action: Action) {
    return createReducer(
        getInitialDefaultDateFromState(),
        on(
            loadDefaultDateFromSuccess,
            (currentState, action): PerdiemSchedulerDefaultDateFromState => ({
                ...currentState,
                minDate: action.minDate
            })
        ))(state, action);
}

export function reducer(state: PerdiemSchedulerDefaultDateFromState | undefined, action: Action) {
    return perdiemSchedulerDefaultDateFromReducer(state, action);
}
