import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ayac-card-alert',
    templateUrl: './card-alert.component.html',
    styleUrls: ['./card-alert.component.scss']
})
export class CardAlertComponent implements OnInit {
    @Input() icon: string;
    @Input() color: 'info' | 'danger' | 'warn' | 'info-2';
    @Input() isOutlineIcon = false;

    className = [];

    ngOnInit(): void {
        if (this.color) {
            this.className.push(`card-alert-${this.color}`);
        }
    }
}
