import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, tap, mergeMap, takeUntil, take, filter } from 'rxjs/operators';
import { InternalPoolService } from 'src/app/internal-pool/internal-pool.service';
import * as resourceActions from 'src/app/internal-pool/store/actions';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { Router } from '@angular/router';
import { ResourceDocumentsService } from 'src/app/internal-pool/services/resource-documents.service';

@Injectable()
export class EditResourceEffect extends BaseEffect {
    constructor(
        private readonly actions: Actions,
        private readonly resourceService: InternalPoolService,
        private readonly documentService: ResourceDocumentsService,
        private readonly router: Router,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    addResource$ = createEffect(() =>
        this.actions.pipe(
            ofType(resourceActions.addResource),
            mergeMap((action) => {
                return this.resourceService
                    .add(action.resource, action.systemId, action.isNyuSystem)
                    .pipe(
                        switchMap((resourceId) => {
                            const hasDocuments = Array.isArray(action.documents) && action.documents.length;
                            if (hasDocuments && resourceId) {
                                return forkJoin(
                                    action.documents.map((doc) =>
                                        this.documentService.postDocument(resourceId, doc, false)
                                    )
                                ).pipe(map(() => resourceId));
                            }
                            return of(resourceId);
                        })
                    )
                    .pipe(
                        map((resourceId) =>
                            resourceActions.addResourceSuccess({
                                systemId: action.systemId
                            })
                        ),
                        catchError((error) => of(resourceActions.addResourceFail({ error })))
                    );
            })
        )
    );

    addResourceSuccess$ = createEffect(() =>
        this.actions.pipe(
            ofType(resourceActions.addResourceSuccess),
            map((action) => {
                this.toasterService.success('Resource successfully saved.');
                return resourceActions.loadResources({ systemId: action.systemId });
            })
        )
    );

    addResourceFailed$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(resourceActions.addResourceFail),
                map((action) => action.error),
                tap((error) => {
                    this.handleError(error);
                })
            ),
        { dispatch: false }
    );

    updateResource$ = createEffect(() =>
        this.actions.pipe(
            ofType(resourceActions.updateResource),
            mergeMap((action) => {
                return this.resourceService.update(action.resource, action.systemId, action.isNyuSystem).pipe(
                    map((resource) =>
                        resourceActions.updateResourceSuccess({ resource: resource, systemId: action.systemId })
                    ),
                    catchError((error) => of(resourceActions.updateResourceFail({ error })))
                );
            })
        )
    );

    updateResourceSuccess$ = createEffect(() =>
        this.actions.pipe(
            ofType(resourceActions.updateResourceSuccess),
            map((resource) => {
                this.toasterService.success('Resource successfully saved.');
                return resourceActions.loadResources({ systemId: resource.systemId });
            })
        )
    );

    updateResourceFailed$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(resourceActions.updateResourceFail),
                map((action) => action.error),
                tap((error) => this.handleError(error))
            ),
        { dispatch: false }
    );

    resourceReload$ = createEffect(() =>
        this.actions.pipe(
            ofType(resourceActions.addResource, resourceActions.updateResource),
            filter((action) => action.reload),
            switchMap(() => {
                const success$ = this.actions.pipe(
                    ofType(resourceActions.addResourceSuccess, resourceActions.updateResourceSuccess)
                );
                const fail$ = this.actions.pipe(
                    ofType(resourceActions.addResourceFail, resourceActions.updateResourceFail)
                );
                return success$.pipe(takeUntil(fail$), take(1));
            }),
            tap(() => {
                this.router.navigate(['/client', 'internalpool']);
            })
        )
    );
}
