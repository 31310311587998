import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneFormat',
    pure: true
})
export class PhonePipe implements PipeTransform {
    constructor() {}

    transform(value: string): string {
        if (!value) {
            return '';
        }

        let countryCode: string | number;
        let areaCode: string;
        let phoneNumber: string;
        let phoneExtension = '';

        const phoneSections = value.split(/ext/gi);
        const phone = phoneSections[0].replace(/\D/g, '');
        if (phoneSections.length > 1) {
            phoneExtension = phoneSections[1].replace(/\D/g, '');
            phoneExtension = phoneExtension.length > 0 ? `Ext.${phoneExtension}` : '';
        }

        switch (phone.length) {
            case 10: // PPP####### -> 1 (PPP) ###-####
                countryCode = 1;
                areaCode = phone.slice(0, 3);
                phoneNumber = phone.slice(3);
                break;

            case 11: // +CPPP####### -> C (PPP) ###-####
                countryCode = phone[0];
                areaCode = phone.slice(1, 4);
                phoneNumber = phone.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                countryCode = phone.slice(0, 3);
                areaCode = phone.slice(3, 5);
                phoneNumber = phone.slice(5);
                break;

            default:
                return `${phone} ${phoneExtension}`.trim();
        }

        if (countryCode === 1) {
            countryCode = '';
        }

        return `${countryCode} (${areaCode}) ${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
            3
        )} ${phoneExtension}`.trim();
    }
}
