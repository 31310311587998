import { ListItem } from 'src/app/shared/models/list-item';

export interface FacilityAdministrationLookups {
    states: ListItem[];
    contractStatuses: ListItem[];
    releaseSettings: ListItem[];
    jobApprovalsAvailabilityLookup: { id: boolean, name: string }[];
    linesOfBusiness: ListItem[];
}

export const getEmptyFacilitiesLookups = (): FacilityAdministrationLookups => ({
    states: [],
    contractStatuses: [],
    releaseSettings: [],
    jobApprovalsAvailabilityLookup: [
        { id: true, name: 'Yes' },
        { id: false, name: 'No' }
    ],
    linesOfBusiness: []
});
