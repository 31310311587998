import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { FacilityVendorItem, FacilityVendorStatusHistory } from 'src/app/facilities/models/facility-vendor.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ListItem } from 'src/app/shared/models/list-item';

export const getInitialGridState = (): GridSearchQuery => ({
    sort: [{ field: 'name', dir: SortTypes.ASC }],
    filter: { logic: 'and', filters: [] },
    take: 10,
    skip: 0
});

export const setQueryTake = (take: number): GridSearchQuery => ({
    sort: [{ field: 'name', dir: SortTypes.ASC }],
    filter: { logic: 'and', filters: [] },
    take: take,
    skip: 0
});

export interface FacilityVendorsState {
    vendors: FacilityVendorItem[];
    total: number;
    filtered: number;
    loadingState: LoadingTypes;
    gridSearchQuery: GridSearchQuery;
    lookups: FacilityVendorsLookupsState;
    history: FacilityVendorStatusHistory[];
    error: string;
    ids: number[];
}

export const getInitialFacilityVendorsState = (): FacilityVendorsState => ({
    vendors: [],
    total: 0,
    filtered: 0,
    loadingState: LoadingTypes.INIT,
    gridSearchQuery: getInitialGridState(),
    lookups: getInitialFacilityVendorLookupsState(),
    history: [],
    error: '',
    ids: []
});

export interface FacilityVendorsLookupsState {
    states: ListItem[];
    statuses: ListItem[];
}

const getInitialFacilityVendorLookupsState = () => ({
    states: [],
    statuses: []
});
