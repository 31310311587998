import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const serializeDates = (obj: any): any => {
    if (obj === undefined) {
        return undefined;
    }
    if (obj === null) {
        return null;
    }
    if (dayjs.isDayjs(obj)) {
        return obj.toISOString(); // Convert dayjs object to ISO string
    } else if (Array.isArray(obj)) {
        return obj.map(serializeDates); // Recurse into arrays
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            acc[key] = serializeDates(obj[key]); // Recurse into object properties
            return acc;
        }, {} as { [key: string]: any });
    }
    return obj;
};

export const deserializeDates = (obj: any): any => {
    if (obj === undefined) {
        return undefined;
    }
    if (obj === null) {
        return null;
    }
    if (typeof obj === 'string' && dayjs(obj).isValid() && isIso8601String(obj)) {
        if (isUTCString(obj)) {
            return dayjs.utc(obj); // Convert UTC ISO string to dayjs object
        }
        console.warn('non-UTC string found. Please address.');
        return dayjs(obj); // Convert Non-UTC ISO string to dayjs object. Hopefully we don't have any of these!!
    } else if (Array.isArray(obj)) {
        return obj.map(deserializeDates); // Recurse into arrays
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            acc[key] = deserializeDates(obj[key]); // Recurse into object properties
            return acc;
        }, {} as { [key: string]: any });
    }
    return obj;
};

const isIso8601String = (str: string): boolean => {
    // This regex covers basic ISO 8601 date formats
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z?$/;
    return iso8601Regex.test(str);
};

const isUTCString = (str: string): boolean => {
    return str.charAt(str.length - 1) === 'Z';
}
