import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DateHelper } from './date-helper';

export function minDateValidator(minDays = 7) {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        if (!DateHelper.isValidDate(control.value)) {
            return null;
        }
        const dateValue = control.value instanceof Date ? DateHelper.getUTCDate(control.value) : control.value;

        const controlDate = DateHelper.dayNumber(dateValue);
        const currentDate = DateHelper.dayNumber(new Date());

        const isDateValid = controlDate < currentDate || controlDate - currentDate > minDays;

        return isDateValid ? null : { minDate: { value: control.value } };
    };
}

export function fullYearDateValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        if (!DateHelper.isValidDate(control.value)) {
            return null;
        }
        const dateValue = new Date(control.value);
        const dateYear = dateValue.getFullYear();
        return dateYear < 1000 ? { fullYear: { value: control.value } } : null;
    };
}

export function minDateMultiValidator(minDays = 7) {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!Array.isArray(control.value)) {
            return null;
        }

        for (const v of control.value) {
            const dateValue = v instanceof Date ? DateHelper.getUTCDate(v) : v;
            const controlDate = DateHelper.dayNumber(dateValue);
            const currentDate = DateHelper.dayNumber(new Date());

            const isDateValid = controlDate < currentDate || controlDate - currentDate > minDays;

            if (!isDateValid) {
                return { minDateMulti: { value: v } };
            }
        }

        return null;
    };
}

export const oneWeekMinDateValidator = minDateValidator(7);
export const oneWeekMinDateMultiValidator = minDateMultiValidator(7);

export function expirationDateValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }
    const expDate = new Date(control.value);

    if (expDate) {
        const min = new Date(1753, 1, 1);
        const max = new Date(9999, 12, 31);

        const isValid = expDate > min && expDate < max;

        return isValid ? null : { InvalidExpDate: { value: true } };
    }
}
