import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { VendorContactService } from 'src/app/admin/vendor-details/services';

export const availableUsernameValidator = (
    vendorService: VendorContactService,
    originalUsername: string | null,
    contactIsActive: boolean
): AsyncValidatorFn => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const notOriginalUsername = originalUsername
            ? originalUsername.toLowerCase() !== control.value.toLowerCase()
            : true;

        if (contactIsActive && notOriginalUsername) {
            // Timer is here to act as a debounce to prevent hitting the server on every keystroke
            return timer(1).pipe(
                switchMap(() =>
                    vendorService
                        .doesUsernameExist(control.value)
                        .pipe(map((userExists: boolean) => (userExists ? { usernameUnavailable: true } : null)))
                )
            );
        }

        return of(null);
    };
};
