import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ayac-unit-description-loading',
    templateUrl: 'loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class UnitDescriptionLoadingDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public loadingText: string) {}
}
