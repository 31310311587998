import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anyCannotBeApproved'
})
export class AnyCannotBeApprovedPipe implements PipeTransform {

  transform(value: any[]): boolean {
    return value.length === 0 || value.some(x => x.canUserApprove === false);
  }

}
