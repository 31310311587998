import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateParams, interpolateUrl } from 'src/app/core/utils';
import { SubmittalsUrl } from 'src/app/shared/models/submittals';
import { CandidateEducation, CandidateWorkHistory, CandidateQualifications, SubmittalActivity } from '../models';
import { Observable } from 'rxjs';
import { NewSubmittalNote } from "src/app/shared/models/submittals-vendor/submittals-vendor-details.model";

@Injectable({ providedIn: 'root' })
export class SubmittalLeadsService {
    constructor(private readonly _http: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {}

    getEducation(candidateId: number): Observable<CandidateEducation[]> {
        const url = interpolateUrl(SubmittalsUrl.CANDIDATE_EDUCATION, { candidateId });

        return this._http.get<CandidateEducation[]>(`${this._settings.CORE}${url}`);
    }

    getWorkHistory(candidateId: number): Observable<CandidateWorkHistory[]> {
        const url = interpolateUrl(SubmittalsUrl.CANDIDATE_WORK_HISTORY, { candidateId });

        return this._http.get<CandidateWorkHistory[]>(`${this._settings.CORE}${url}`);
    }

    getQualifications(candidateId: number, isSubcontractor: boolean = false): Observable<CandidateQualifications> {
        const url = interpolateUrl(SubmittalsUrl.CANDIDATE_QUALIFICATIONS, { candidateId });
        const params = interpolateParams({
            isSubcontractor
        });

        return this._http.get<CandidateQualifications>(`${this._settings.CORE}${url}`, { params });
    }

    getSubmittalActivities(submittalId: number): Observable<SubmittalActivity[]> {
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_ACTIVITIES, { submittalId });

        return this._http.get<SubmittalActivity[]>(`${this._settings.CORE}${url}`);
    }

    addSubmittalNote(submittalId: number, newSubmittalNote: NewSubmittalNote): Observable<any> {
        const url = interpolateUrl(SubmittalsUrl.SUBMITTAL_NOTES, { submittalId });

        return this._http.post<any>(`${this._settings.CORE}${url}`, newSubmittalNote);
    }
}
