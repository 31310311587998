import { FacilityFeatures } from 'src/app/facilities/models/facility-features.model';
import { LoadingTypes } from 'src/app/shared/models';
import { FacilityFeaturePermissionCategory, FacilityFeatureNotifications } from '../../models';

export interface FacilityFeaturesState {
    facilityFeatures: FacilityFeatures;
    loadingState: LoadingTypes;
    permissionCategories: FacilityFeaturePermissionCategory[];
    notifications: FacilityFeatureNotifications[];
}

export const getInitialFacilityFeaturesState = (): FacilityFeaturesState => ({
    facilityFeatures: null,
    loadingState: LoadingTypes.INIT,
    permissionCategories: [],
    notifications: []
});
