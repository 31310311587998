import { Action, createReducer, on } from "@ngrx/store";
import { LoadingTypes } from "src/app/shared/models";
import { FacilityAddressInfos } from "../../models/shift-facilityAddressInfo.model";
import * as shiftsActions from 'src/app/shifts/store/actions';

export interface ShiftFacilityAddressInfoState{ 
    facilityAddressInfos: FacilityAddressInfos,
    loading: LoadingTypes
}

export const initialShiftFacilityAddressInfoState: ShiftFacilityAddressInfoState = {
	facilityAddressInfos: null,
	loading: LoadingTypes.INIT,
}

export function shiftAddressInfoReducer(state: ShiftFacilityAddressInfoState, action: Action) {
	return createReducer(
		initialShiftFacilityAddressInfoState,
		on(shiftsActions.loadShiftFacilityAddressInfo, (state, action) => (
			{
                ...state,
                loading: LoadingTypes.LOADING
			}
		)),

		on(shiftsActions.loadShiftFacilityAddressInfoSuccess, (state, action) => (
			{
				...state,
				facilityAddressInfos: action.facilityAddressInfo,
                loading: LoadingTypes.LOADED
			}
		)),

		on(shiftsActions.loadShiftFacilityAddressInfoFail, (state, action) => (
			{
                ...state,
                facilityAddressInfos: null,
                loading: LoadingTypes.FAIL
			}
        ))           

        
	)(state, action);
}

export function reducer(state: ShiftFacilityAddressInfoState | undefined, action: Action) {
	return shiftAddressInfoReducer(state, action);
}
