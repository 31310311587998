import { Inject, Pipe, PipeTransform } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { FacilityMarketingProfile } from '../models/facility-marketing-profile.model';

@Pipe({
    name: 'facilityImg',
})
export class FacilityImagePipe implements PipeTransform {
    constructor(@Inject(APP_CONFIG) private readonly _settings: Settings) {}

    transform(facility: FacilityMarketingProfile): string | null {
        let result: string | null = '/content/images/hospital_placeholder.svg';

        if (!facility || !facility.photoPrimary) {
            return result;
        }

        result = `${this._settings.CDN_DOMAIN}/appresources/Images/facilities/${facility.photoPrimary}`;

        return result;
    }
}
