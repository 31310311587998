import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringDate'
})
export class StringDatePipe implements PipeTransform {
    transform(value: string): string {
        if (value && value.length === 6) {
            value = '0' + value;
        }
        return value;
    }
}
