import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermJobsState, permJobsStoreKey } from '../perm-jobs.state';
import { PermJobsGridQueryState } from '../reducers/perm-jobs-grid-query.reducer';

export const selectFeature = createFeatureSelector<PermJobsState>(permJobsStoreKey);

export const selectResults = createSelector(selectFeature, (state) => state.results);

export const selectPermJobsData = createSelector(selectResults, (state) => ({
    data: state.permJobs,
    total: state.permJobsTotal
}));

export const selectLoadingState = createSelector(selectResults, (state) => ({
    loadingState: state.loading
}));

export const selectPermJobsQuery = createSelector(selectFeature,
    (state: PermJobsState): PermJobsGridQueryState => state.query);
