import {
    Component,
    AfterViewInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FileAttachment, FilesToUpload, FileValidationResult } from 'src/app/shared/models/attachment';
import { ToasterService } from 'src/app/core/services';

@Component({
    selector: 'ayac-file-attachments-form',
    templateUrl: './file-attachments-form.component.html',
    styleUrls: ['./file-attachments-form.component.scss']
})
export class FileAttachmentsFormsComponent extends UnsubscribeOnDestroy implements AfterViewInit {
    document: any = { files: new Array<FilesToUpload>() };
    validationResults: FileValidationResult[] = [];
    fileExtensions: string;
    uploadInProgress = false;
    isDragging = false;
    defaultFileTypes = 'jpg, png, pdf';
    @Input() isMultiple = true;
    @Input() acceptableFileTypes = this.defaultFileTypes;
    @Input() showBrowseButton = true;
    @Input() maxFileSizeMb = 25;
    @Output() getAttachmentFile = new EventEmitter();
    @Output() attachmentUpload = new EventEmitter();

    @ViewChild('fileInput') fileInput: ElementRef;

    constructor(private readonly ref: ChangeDetectorRef, private readonly _toasterService: ToasterService) {
        super();
    }

    ngAfterViewInit(): void {
        this.ref.detectChanges();
    }

    clearExistingDocuments(data): void {
        this.document.files = this.document.files.filter((item) => item === data);
        this.uploadInProgress = false;
    }

    getAttachment(file: FileAttachment): void {
        this.getAttachmentFile.emit(file);
    }

    getDroppedFiles(e: DragEvent): void {
        e.preventDefault();
        this.isDragging = false;
        this.addFiles(e?.dataTransfer?.files);
    }

    addSelectedFiles(): void {
        this.addFiles(this.fileInput.nativeElement.files);
        this.fileInput.nativeElement.value = null;
    }

    openExplorer(): void {
        this.fileInput.nativeElement.click();
    }

    updateDragState(e: DragEvent, isDragging: boolean): void {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = 'copy';
        }

        this.isDragging = isDragging;
    }

    uploadFiles(): void {
        if (this.document.files.length) {
            let fileToUpload = this.document.files;
            if (this.document.files.length > 1) {
                this.uploadInProgress = true;
                fileToUpload.forEach((file) => this.attachmentUpload.emit(file));
            } else {
                fileToUpload = this.document.files[0];
                this.uploadInProgress = true;
                this.attachmentUpload.emit(fileToUpload);
            }
        }
    }

    addFiles(value: FileList) {
        this.validationResults = [];
        this.clearExistingDocuments(value);

        for (let i = 0; i < value.length; i++) {
            const file: any = value[i];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!this.acceptableFileTypes.includes(fileExtension)) {
                this._toasterService.fail(`File type not supported. Supported file types are
                    ${ this.acceptableFileTypes }.`);
                return false;
            }
            if (this.validateFile(file)) {
                this.document.files.push(file);
                this.document.originalFileName = this.document.files.length > 1 ? 'Multiple files' : file.name;
            }
        }

        this.uploadFiles();
        return true;
    }

    validateFile(file: File): boolean {
        const megabyte = 1024 * 1024;
        if (file.size > this.maxFileSizeMb * megabyte) {
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: `File format exceeds ${this.maxFileSizeMb}MB size limit. Please save the file as a smaller size and try again.`
            });
            this._toasterService.fail(
                `File size too large. File '${file.name}' exceeds the maximum file size of ${this.maxFileSizeMb} MB.`
            );
            return false;
        }

        if (this.document.files.find((f) => f.name === file.name)) {
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: `There is already a file named ${file.name}. Please save the file with a different file name and try again.`
            });
            this._toasterService.fail(`File could not be validated. There is already a file named ${file.name}.`);
            return false;
        }

        return true;
    }
}
