import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { AdminState } from '../state/admin.state';
import { adminFeatureKey } from './admin.selectors';

export const selectVendorFacilitiesFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectVendorFacilities = createSelector(selectVendorFacilitiesFeature, (adminState: AdminState) => ({
    data: adminState.vendorFacilitiesState.facilities.data,
    total: adminState.vendorFacilitiesState.facilities.filtered,
    nonFilteredTotal: adminState.vendorFacilitiesState.facilities.total
}));

export const selectIsLoading = createSelector(
    selectVendorFacilitiesFeature,
    (adminState: AdminState) => adminState.vendorFacilitiesState.loadingState === LoadingTypes.LOADING
);

export const selectFacilitiesListError = createSelector(
    selectVendorFacilitiesFeature,
    (adminState: AdminState) => adminState.vendorFacilitiesState.error
);

export const selectVendorFacilitiesGridSearchQuery = createSelector(
    selectVendorFacilitiesFeature,
    (adminState: AdminState) => adminState.vendorFacilitiesState.gridSearchQuery
);

export const selectStatesLookup = createSelector(
    selectVendorFacilitiesFeature,
    (state: AdminState) => state.vendorFacilitiesState.lookups.states
);

export const selectStatusLookup = createSelector(
    selectVendorFacilitiesFeature,
    (state: AdminState) => state.vendorFacilitiesState.lookups.statuses
);

export const selectFacilityIds = createSelector(
    selectVendorFacilitiesFeature,
    (state: AdminState) => state.vendorFacilitiesState.ids
);
