import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MultiSelectCalendarHeaderDayjsComponent } from '../multi-select-calendar-header-dayjs/multi-select-calendar-header-dayjs.component';
import dayjs, { Dayjs } from 'dayjs';

@Component({
    selector: 'ayac-multi-select-calendar-dayjs',
    templateUrl: './multi-select-calendar-dayjs.component.html',
    styleUrls: ['./multi-select-calendar-dayjs.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MultiSelectCalendarDayjsComponent implements OnInit {
    @Input() minDate: Dayjs;
    @Input() maxDate: Dayjs;
    @Input() disabledDates: Dayjs[];
    @Input() disabledDatesStyle: string;
    @Input() multi: boolean;
    @Input() showConfirmationCount: boolean;
    @Input() extraDateClass: (date: Dayjs) => string;

    @ViewChild(MatCalendar, { static: true }) calendar: MatCalendar<Dayjs>;
    @Output() save = new EventEmitter<Dayjs[]>();
    @Output() cancel = new EventEmitter();
    multiHeader = MultiSelectCalendarHeaderDayjsComponent;
    selected: Dayjs[] = [];

    @Input()
    set dates(dates: Dayjs[]) {
        if (Array.isArray(dates)) {
            this.selected = dates.slice();
        } else {
            this.selected = [dates];
        }
    }

    constructor() {}

    ngOnInit() {}

    onSelect(d: Dayjs) {
        if (this.multi) {
            const index = this.selected.findIndex((s) => s.utc().isSame(d.utc(), 'day'));

            if (index >= 0) {
                this.selected.splice(index, 1);
            } else {
                this.selected.push(d);
            }
        } else {
            this.selected.length = 0;
            this.selected.push(d);
        }

        this.calendar.updateTodaysDate();
    }

    dateClass = (d: Dayjs) => {
        const className = [];

        // added the utc, because I had problem debugging locally outside US
        const isSelected = this.selected.some((s) => s.utc().isSame(d.utc(), 'day'));

        if (isSelected) {
            className.push('selected-date-class');
        }

        if (this.disabledDatesStyle && this.disabledDates && this.disabledDates.length > 0) {
            const isDisabled = this.disabledDates.some((s) => s.utc().isSame(d.utc(), 'day'));

            if (isDisabled) {
                className.push(`disabled-date-${this.disabledDatesStyle}`);
            }
        }

        const dayClass = typeof this.extraDateClass === 'function' ? this.extraDateClass(d) : undefined;

        if (dayClass) {
            className.push(dayClass);
        }

        return className.join(' ');
    };

    dateFilter = (d: Dayjs) => {
        if (Array.isArray(this.disabledDates)) {
            const sameDay = this.disabledDates.some((s) => s.utc().isSame(d.utc(), 'day'));

            if (sameDay) {
                return false;
            }
        }

        const isAfterMinDate = dayjs(d).isAfter(dayjs(this.minDate));

        return isAfterMinDate;
    };

    onSave() {
        this.save.emit(this.selected);
    }

    onCancel() {
        this.cancel.emit();
    }
}
