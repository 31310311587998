import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';
import { componentLoaded, loadStates, loadStatesFail, loadStatesSuccess } from '../perdiemscheduler.actions';

export interface PerdiemSchedulerStatesState {
    list: ListItem[];
    loading: LoadingTypes;
}

export const getInitialPerdiemSchedulerStatesState = (): PerdiemSchedulerStatesState => ({
    list: [],
    loading: LoadingTypes.INIT
});

export function perdiemSchedulerStatesReducer(state: PerdiemSchedulerStatesState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerStatesState(),
        on(loadStates, componentLoaded, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadStatesSuccess, (state, action) => ({
            ...state,
            list: action.states,
            loading: LoadingTypes.LOADED
        })),
        on(loadStatesFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: PerdiemSchedulerStatesState, action: Action) {
    return perdiemSchedulerStatesReducer(state, action);
}
