import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { withLatestFrom, switchMap, catchError, map, tap } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { SortTypes } from 'src/app/shared/models';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { PerdiemSchedulerLocation } from '../../models/enum/perdiemscheduler-location.enum';
import { PerdiemSchedulerService } from '../../services/perdiemscheduler.service';
import {
    loadShiftsFail,
    loadShiftsSuccess,
    loadShifts,
    componentLoaded,
    reloadShifts,
    setPerdiemSchedulerSearchQuery
} from '../perdiemscheduler.actions';
import { selectShiftsQuery } from '../perdiemscheduler.selectors';
import { PerdiemSchedulerQueryState } from '../reducers/perdiemscheduler-query.reducer';
import * as dayjs from 'dayjs';

@Injectable()
export class PerdiemSchedulerListEffect extends BaseEffect {
    getShiftsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadShifts, componentLoaded, setPerdiemSchedulerSearchQuery),
            withLatestFrom(this.store.select(selectShiftsQuery)),
            switchMap(([, query]) => {
                return this.shiftRepository
                    .getShifts(
                        {
                            take: query.take,
                            skip: query.skip
                        },
                        {
                            sortField: query.sort[0].field,
                            sortType: query.sort[0].dir === 'asc' ? SortTypes.ASC : SortTypes.DESC
                        },
                        this.buildFilters(query)
                    )
                    .pipe(
                        map((response) =>
                            loadShiftsSuccess({
                                shifts: response.data,
                                total: response.total
                            })
                        ),
                        catchError((error: unknown) => {
                            return of(loadShiftsFail({ error }));
                        })
                    );
            })
        );
    });

    reloadShiftsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(reloadShifts),
            withLatestFrom(this.store.select(selectShiftsQuery)),
            switchMap(([action, query]) => {
                const updatedQuery: PerdiemSchedulerQueryState = {
                    ...query,
                    location: action.location,
                    city: action.city,
                    facilityId: action.facilityId,
                    state: action.state
                };

                return this.shiftRepository
                    .getShifts(
                        {
                            take: query.take,
                            skip: query.skip
                        },
                        {
                            sortField: query.sort[0].field,
                            sortType: query.sort[0].dir === 'asc' ? SortTypes.ASC : SortTypes.DESC
                        },
                        this.buildFilters(updatedQuery)
                    )
                    .pipe(
                        map((response) =>
                            loadShiftsSuccess({
                                shifts: response.data,
                                total: response.total
                            })
                        ),
                        catchError((error: unknown) => {
                            return of(loadShiftsFail({ error }));
                        })
                    );
            })
        );
    });

    getShiftListFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadShiftsFail),
                map((action) => action.error),
                tap((error: HttpErrorResponse) => this.handleError(error))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly shiftRepository: PerdiemSchedulerService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    private buildFilters(query: PerdiemSchedulerQueryState): Array<{ field: string; operator: string; value: any }> {
        const filters: Array<{ field: string; operator: string; value: any }> = [];

        if (query.dateFrom !== null) {
            filters.push({
                field: 'dateFrom',
                operator: 'gte',
                value: dayjs(query.dateFrom).format('YYYY-MM-DD')
            });
        }

        if (query.dateTo !== null) {
            filters.push({
                field: 'dateTo',
                operator: 'lte',
                value: dayjs(query.dateTo).format('YYYY-MM-DD')
            });
        }

        if (query.professionId !== null) {
            filters.push({ field: 'professionId', operator: 'eq', value: query.professionId });
        }

        if (query.specialtyId !== null) {
            filters.push({ field: 'specialtyId', operator: 'eq', value: query.specialtyId });
        }

        if (query.minRate) {
            filters.push({ field: 'minRate', operator: 'eq', value: query.minRate });
        }

        if (query.statusId !== null) {
            filters.push({ field: 'statusId', operator: 'eq', value: query.statusId });
        }

        if (query.location === PerdiemSchedulerLocation.facility) {
            if (query.facilityId !== null) {
                filters.push({ field: 'facilityId', operator: 'eq', value: query.facilityId });
            }
        } else {
            if (query.city !== null) {
                filters.push({ field: 'city', operator: 'eq', value: query.city });
            }

            if (query.state !== null) {
                filters.push({ field: 'state', operator: 'eq', value: query.state });
            }
        }

        return filters;
    }
}
