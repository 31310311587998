import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClinicalState, clinicalStoreKey } from '../clinical.state';

export const selectFeature = createFeatureSelector<ClinicalState>(clinicalStoreKey);
export const selectClientSelectFeature = createSelector(selectFeature, (state) => state.ClientState);

export const selectClient = createSelector(selectClientSelectFeature, (state) => {
    return state.client;
});

export const selectClientId = createSelector(selectClientSelectFeature, (state) => state.clientId);
