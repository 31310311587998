import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ToasterService } from 'src/app/core/services';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { FileType } from 'src/app/shared/models/file-type.model';

@Component({
    selector: 'ayac-file-attachments-grid',
    templateUrl: './file-attachments-grid.component.html',
    styleUrls: ['./file-attachments-grid.component.scss']
})
export class FileAttachmentsGridComponent extends UnsubscribeOnDestroy implements AfterViewInit {
    @ViewChild('fileUpload', { static: false }) fileUpload: any;
    @Input() id: number;
    gridData: GridDataResult;
    searchQueryData: GridSearchQuery;
    deletingFileIdsData: number[];
    fileTypesData: FileType[] = null;

    @Input()
    set grid(data: GridDataResult) {
        if (data) {
            this.gridData = data;
        }
    }

    get grid(): GridDataResult {
        return this.gridData;
    }

    @Input()
    set searchQuery(query: GridSearchQuery) {
        if (query) {
            this.searchQueryData = query;
        }
    }

    get searchQuery(): GridSearchQuery {
        return this.searchQueryData;
    }

    @Input()
    set deletingFileIds(ids: number[]) {
        if (ids) {
            this.deletingFileIdsData = ids;
        }
    }

    get deletingFileIds(): number[] {
        return this.deletingFileIdsData;
    }

    @Input() isDeleting: boolean;
    @Input() message: string = 'data';
    @Input() isUploading: boolean;
    selectedFileType: FileType = null;

    @Input()
    set fileTypes(fileTypes: FileType[]) {
        if (fileTypes) {
            this.fileTypesData = fileTypes;
        }
    }
    get fileTypes(): FileType[] {
        return this.fileTypesData;
    }

    get isNew(): boolean {
        if (Number.isNaN(Number(this.id))) {
            return true;
        }
        return !this.id || this.id <= 0;
    }

    @Input() loading: boolean;

    @Output() addFiles = new EventEmitter();
    @Output() downloadFile = new EventEmitter();
    @Output() deleteFile = new EventEmitter();
    @Output() refreshGrid = new EventEmitter();
    @Output() dataStateChange = new EventEmitter();

    constructor(private readonly toasterService: ToasterService, private readonly ref: ChangeDetectorRef) {
        super();
    }

    ngAfterViewInit(): void {
        this.ref.detectChanges();
    }

    download(file: any): void {
        this.downloadFile.emit(file);
    }

    delete(file: any): void {
        this.deleteFile.emit(file);
    }

    onFileSelected(event: any): void {
        const files: File[] = Array.from(event.target.files);

        const newFiles = files
            .map((fileToUpload) => {
                if (fileToUpload.size / 1024 / 1024 > 20) {
                    this.toasterService.fail(`File '${fileToUpload.name}' exceeds the maximum file size.`);
                    return null;
                }
                const fileParts = fileToUpload.name.split('.');

                const file = {
                    name: fileParts[0],
                    type: fileParts[1],
                    size: fileToUpload.size
                };
                const candidateFileType = { type: this.selectedFileType.name };
                return {
                    vendorCandidateFileType: candidateFileType,
                    fileTypeId: this.selectedFileType.id,
                    file: file,
                    fileToUpload: fileToUpload
                };
            })
            .filter((file) => file !== null && file !== undefined);

        if (newFiles.length === 0) {
            return;
        }
        this.addFiles.emit(newFiles);
    }
    uploadFile(fileType: any): void {
        this.selectedFileType = fileType;
        this.fileUpload.nativeElement.click();
    }

    refresh(): void {
        this.refreshGrid.emit();
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this.dataStateChange.emit(state);
        this.refresh();
    }
}
