/* eslint-disable prettier/prettier */
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { BehaviorSubject, Observable, Subject, debounceTime, switchMap, takeUntil, tap } from 'rxjs';
import { CustomFieldValueType } from 'src/app/shared/models/custom-fields/custom-field-value-type.enum';
import { DropdownOption } from 'src/app/shared/models/custom-fields/dropdown-option.model';
import { CustomFieldService } from 'src/app/shared/services/custom-field.service';

@Component({
    selector: 'formly-kendo-searchable-job-dropdown',
    templateUrl: './kendo-searchable-job-dropdown.component.html',
    styleUrls: ['./kendo-searchable-job-dropdown.component.scss']
})
export class KendoSearchableJobDropdownComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
    customFieldService = inject(CustomFieldService);

    searchText$ = new BehaviorSubject<string>('');
    defaultChildOptions$ = new BehaviorSubject<DropdownOption[]>([]);
    d$ = new Subject<void>();
    isLoading = false;
    data$: Observable<any>;
    parentOption: string;
    pageFirstLoading = true;

    searchTerm$ = this.searchText$.pipe(debounceTime(200), takeUntil(this.d$));

    getOptions = (searchTerm): Observable<DropdownOption[]> =>
        this.props.valueType === CustomFieldValueType.RichDropdown
            ? this.customFieldService.getRichOptions(this.props.definitionId, searchTerm, this.props.descriptionOnly)
            : this.customFieldService.getChildOptions(
                  this.props.definitionId,
                  searchTerm,
                  this.parentOption,
                  this.props.descriptionOnly
              );

    ngOnInit(): void {
        if (this.props.valueType === CustomFieldValueType.ChildDropdown) {
            this.props.parentOption.pipe(takeUntil(this.d$)).subscribe((x) => {
                this.parentOption = x?.value ?? x;
                if (this.pageFirstLoading) {
                    this.pageFirstLoading = false;
                } else {
                    this.field.formControl.patchValue({ value: null, label: '' });
                }
                this.searchText$.next('');
            });
        }

        this.data$ = this.searchTerm$.pipe(
            tap(() => (this.isLoading = true)),
            switchMap((searchTerm) => this.getOptions(searchTerm)),
            tap(() => (this.isLoading = false))
        );
    }

    ngOnDestroy(): void {
        this.d$.next();
        this.d$.complete();
    }

    handleFilter(value) {
        this.searchText$.next(value);
    }
}
