import { securityLookupsReducer } from '../../../dashboard-new/store/reducers/security-lookups.reducer';
import { editJobsLinesOfBusinessReducer } from './edit-jobs-lines-of-business.reducer';
import { jobsLinesOfBusinessReducer } from './jobs-lines-of-business.reducer';
import { lineOfBusinessReducer } from './lines-of-business.reducer';
import { shiftTypesReducer } from './shift-types.reducer';
import { submittalsLinesOfBusinessReducer } from './submittals-lines-of-business.reducer';
import { timecardsLinesOfBusinessReducer } from './timecards-lines-of-business.reducer';
import { workersLinesOfBusinessReducer } from './workers-lines-of-business.reducer';

export const lookupsReducer = {
    linesOfBusiness: lineOfBusinessReducer,
    shiftTypes: shiftTypesReducer,
    jobsLinesOfBusiness: jobsLinesOfBusinessReducer,
    submittalsLinesOfBusiness: submittalsLinesOfBusinessReducer,
    workersLinesOfBusiness: workersLinesOfBusinessReducer,
    timecardsLinesOfBusiness: timecardsLinesOfBusinessReducer,
    editJobsLinesOfBusiness: editJobsLinesOfBusinessReducer
}
