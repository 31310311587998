export const OverDueBy = 2;

export const IsPendingReviewOverDue = (
    checkPendingReview: boolean,
    submissionSentOn: Date | string,
    overDueBy: number
) => {
    if (checkPendingReview) {
        const oneDay = 24 * 60 * 60 * 1000;
        const now = new Date();
        const submissionDate = new Date(submissionSentOn);
        const daysNotReviewed = Math.round(Math.abs((submissionDate.getTime() - now.getTime()) / oneDay));
        return daysNotReviewed > overDueBy;
    }

    return false;
};
