import { createAction, props } from '@ngrx/store';
import { CertificationMultipleRequest } from 'src/app/admin/vendor-details/certifications/models/certification-multiple-request.model';
import { ProfileMetadataResponse } from 'src/app/admin/vendor-details/certifications/models/profile-metadata-response.model';
import { VendorProfileResponse } from 'src/app/admin/vendor-details/certifications/models/vendor-profile-response.model';
import { HttpResponse } from "@angular/common/http";

export const loadVendorProfileCertifications = createAction(
    '[VENDOR PROFILE CERTIFICATIONS COMPONENT] Load Vendor Profile Certifications',
    props<{ vendorId: number }>()
);
export const loadVendorProfileCertificationsSuccess = createAction(
    '[VENDOR PROFILE CERTIFICATIONS API] Load Vendor Profile Certifications Success',
    props<{ response: VendorProfileResponse; vendorId: number }>()
);
export const loadVendorProfileCertificationsFailure = createAction(
    '[VENDOR PROFILE CERTIFICATIONS API] Load Vendor Profile Certifications Failure',
    props<{ error: any }>()
);
export const updateVendorProfileCertifications = createAction(
    '[VENDOR PROFILE CERTIFICATIONS COMPONENT] Update Vendor Profile Certifications',
    props<{ certificationRequest: CertificationMultipleRequest }>()
);
export const updateVendorProfileCertificationsSuccess = createAction(
    '[VENDOR PROFILE CERTIFICATIONS API] Update Vendor Profile Certifications Success',
    props<{ vendorId: number }>()
);
export const updateVendorProfileCertificationsFailure = createAction(
    '[VENDOR PROFILE CERTIFICATIONS API] Update Vendor Profile Certifications Failure',
    props<{ error: unknown }>()
);
export const loadVendorProfileMetadata = createAction(
    '[VENDOR PROFILE METADATA COMPONENT] Load Vendor Profile Metadata'
);
export const loadVendorProfileMetadataSuccess = createAction(
    '[VENDOR PROFILE METADATA API] Load Vendor Profile Metadata Success',
    props<{ response: ProfileMetadataResponse }>()
);
export const loadVendorProfileMetadataFailure = createAction(
    '[VENDOR PROFILE METADATA API] Load Vendor Profile Metadata Failure',
    props<{ error: any }>()
);
export const downloadCertificationFile = createAction(
    '[VENDOR PROFILE CERTIFICATIONS COMPONENT] Download Certification File',
    props<{ vendorId: number, fileId: number }>()
);
export const downloadCertificationFileSuccess = createAction(
    '[VENDOR PROFILE CERTIFICATIONS API] Download Certification File Success',
    props<{payload: HttpResponse<Blob>}>()
);
export const downloadCertificationFileFailure = createAction(
    '[VENDOR PROFILE CERTIFICATIONS API] Download Certification File Failure',
    props<{ error: unknown }>()
);
export const exportVendorCertifications = createAction(
    '[EXPORT VENDOR CERTIFICATIONS] Export Vendor Certifications',
    props<{ vendorId: number; vendorName: string }>()
);

export const exportVendorCertificationsSuccess = createAction(
    '[EXPORT VENDOR CERTIFICATIONS] Export Vendor Certifications Successful',
    props<{ result: Blob }>()
);

export const exportVendorCertificationsFail = createAction(
    '[EXPORT VENDOR CERTIFICATIONS] Export Vendor Certifications Failed',
    props<{ error: any }>()
);
