import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShiftSchedule } from 'src/app/shared/models/shifts';

@Component({
    selector: 'ayac-shift-schedule-modal',
    templateUrl: './shift-schedule-modal.component.html',
    styleUrls: ['./shift-schedule-modal.component.scss']
})
export class ShiftScheduleModalComponent {
    constructor(
        private readonly dialogRef: MatDialogRef<ShiftScheduleModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        private readonly data: {
            schedules: ShiftSchedule[];
        }
    ) {}

    get schedules() {
        return this.data.schedules;
    }

    onClose() {
        this.dialogRef.close();
    }
}
