import { Pipe, PipeTransform } from '@angular/core';

/**
 * Example PendingReview -> Pending Review
 * keyProperyName: property name if the value is an array. 
 */
@Pipe({
    name: 'addSpaceBeforeCapitalLetters'
})
export class AddSpaceBeforeCapitalLettersPipe implements PipeTransform {

    transform(value: any, keyPropertyName: string): any {

        if (!value) {
            return value;
        }

        if (Array.isArray(value) && value.length > 0) {
            value.forEach(e => {
                if (typeof e === 'object' && e[keyPropertyName]) {
                    e[keyPropertyName] = e[keyPropertyName].replace(/([A-Z])/g, ' $1').trim();
                }
            });
            return value;
        }

        else if (typeof value === 'string') {
            return value.replace(/([A-Z])/g, ' $1').trim();
        }

        else {
            return value;
        }
    }
}
