import { createAction, props } from '@ngrx/store';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';

export const loadResourceDetails = createAction('[Internal Pool Resource Details] Load Resource Details', props<{ id: number, systemId: number }>());
export const loadResourceDetailsCurrentSystem = createAction(
    '[Internal Pool Resource Details] Load Resource Details Current System',
    props<{ id: number }>()
);
export const loadResourceDetailsSuccess = createAction('[Internal Pool Resource Details] Load Resource Details Success', props<{ resource: Resource }>());
export const loadResourceDetailsFail = createAction('[Interal Pool Resource Details] Load Resource Details Fail', props<{ error: any }>());
export const clearResourceDetails = createAction('[Interal Pool Resource Details] Clear Resource Details');
export const initializeResourceDetails = createAction('[Interal Pool Resource Details] Initialize Resource');
export const loadSystemIdWithLookupsAndResourceDetails = createAction('[Internal Pool Resource Details] Load System Id With Lookups and Resource Details', props<{ id: number }>());

export const checkResourceUsername = createAction(
    '[Internal Pool Resource Details] Check Resource Email',
    props<{ email: string }>()
);
export const checkResourceUsernameSuccess = createAction(
    '[Internal Pool Resource Details] Check Resource Email Success',
    props<{ username: string, isInUse: boolean }>()
);
export const checkResourceUsernameFail = createAction(
    '[Interal Pool Resource Details] Check Resource Email Fail',
    props<{ error: any }>()
);
