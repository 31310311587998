import { createAction, props } from '@ngrx/store';

export const openCancelFacilityDialog = createAction(
    '[SHIFTS] Open Cancel Facility Dialog'
);

export const openCancelSeriesDialog = createAction(
    '[SHIFTS] Open Cancel Series Dialog'
);

export const closeCancelSeriesDialog = createAction(
    '[SHIFTS] Close Cancel Series Dialog'
);

export const cancelShiftSeries = createAction(
    '[SHIFTS] Cancel Shift Series',
    props<{
        shiftId: number,
        seriesId: string,
        reason: number,
        other?: string,
        shiftType: 'single' | 'series',
    }>()
)

export const cancelShiftSeriesSuccess = createAction(
    '[SHIFTS] Cancel Shift Series Success'
)

export const cancelShiftSeriesFail = createAction(
    '[SHIFTS] Cancel Shift Series Fail',
    props<{ error: any }>()
)
