import { IdentityService } from 'src/app/shared/services/identity.service';
import { Injectable } from '@angular/core';
import { InternalPoolClientPermissions } from 'src/app/shared/models/enums/client-permissions.enum';

@Injectable({
    providedIn: 'root'
})
export class InternalPoolAuthService {

    constructor(private readonly identityService: IdentityService){}

    canEditResource() : boolean {
        return this.identityService.inScope("client", InternalPoolClientPermissions.InternalPoolEdit);
    }
    
    canViewInternalPool(): boolean {
        return this.identityService.inScope("client", InternalPoolClientPermissions.InternalPoolView)
    }

    canArchiveResource() : boolean {
        return this.identityService.inScope("client", InternalPoolClientPermissions.InternalPoolArchive);
    }
}
