import { createAction, props } from '@ngrx/store';
import { FacilityLookups } from '../../models/facility-lookups.model';

export const loadFacilityLookups = createAction('[SHIFTS] Load Facility Lookups');

export const loadFacilityLookupsFail = createAction('[SHIFTS] Load Facility Lookups Fail', props<{ error: any }>());

export const loadFacilityLookupsSuccess = createAction(
    '[SHIFTS] Load Facility Lookups Success',
    props<{ facilityLookups: FacilityLookups }>()
);
