import { FacilityAdministration } from 'src/app/facilities/models/facility-administration.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { getEmptyPagingToken, PagingToken } from 'src/app/shared/models/paging-token';

export const getInitialJobGridState = (): GridSearchQuery => ({
    sort: [{ field: 'name', dir: SortTypes.ASC }],
    take: 20,
    skip: 0
})

export const getInitialShiftGridState = (): GridSearchQuery => ({
    filter: { logic: 'and', filters: [{ field: 'lineOfBusinessId', operator: 'eq', value: '1' }] },
    sort: [{ field: 'name', dir: SortTypes.ASC }],
    take: 20,
    skip: 0
})

export interface CopyReleaseSettingsToModalState {
    loadingState: LoadingTypes,
    facilities: PagingToken<FacilityAdministration[]>,
    gridSearchQuery: GridSearchQuery,
    error: string
}

export const getInitialCopyReleaseSettingsToModalState = (): CopyReleaseSettingsToModalState => ({
    loadingState: LoadingTypes.INIT,
    facilities: getEmptyPagingToken<FacilityAdministration>(),
    gridSearchQuery: getInitialJobGridState(),
    error: ''
});
