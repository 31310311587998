import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '../models/list-item';

@Pipe({
    name: 'getOptionName'
})
export class GetOptionNamePipe implements PipeTransform {
    transform(option: ListItem): string {
        return option ? option.name : '';
    }
}
