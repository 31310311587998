import { Action, createReducer, on } from '@ngrx/store';
import { PaginationRequest } from 'src/app/manage-permissions/models';
import { LoadingTypes } from 'src/app/shared/models';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ShiftDetails } from 'src/app/shared/models/shifts/shift-details.model';
import { ShiftListTableItem } from 'src/app/vendor-perdiemscheduler/models/shift-list-table-item.model';
import * as actions from 'src/app/vendor-perdiemscheduler/store/actions/perdiemscheduler-details.actions';

export interface PerdiemSchedulerDetailsState {
    shifts: PagingToken<ShiftListTableItem[]>;
    searchQuery: PaginationRequest;
    loading: LoadingTypes;
    error: string;
    shiftSeries: ShiftDetails;
}

export const getInitialPerdiemSchedulerDetailsState = (): PerdiemSchedulerDetailsState => ({
    shifts: { data: [], total: 0 },
    searchQuery: new PaginationRequest(10, 0, [{ field: 'dateFrom', dir: 'asc' }]),
    loading: LoadingTypes.INIT,
    error: '',
    shiftSeries: {} as ShiftDetails
});

export function perdiemSchedulerDetailsReducer(state: PerdiemSchedulerDetailsState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerDetailsState(),
        on(actions.loadShiftCandidatesSuccess, (state, action) => ({
            ...state,
            shifts: action.shifts
        })),
        on(actions.loadShiftSeries, (state, action) => ({
            ...state,
            loadingState: LoadingTypes.LOADING
        })),
        on(actions.loadShiftSeriesSuccess, (state, action) => ({
            ...state,
            shiftSeries: action.shiftSeries,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: PerdiemSchedulerDetailsState | undefined, action: Action) {
    return perdiemSchedulerDetailsReducer(state, action);
}
