import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { DashboardState, dashboardStoreKey } from 'src/app/dashboard-new/store/dashboard.state';

export const loading = createSelector(
    createFeatureSelector<DashboardState>(dashboardStoreKey),
    state => [
        state.pendingJobs.loading,
        state.pendingTimecards.loading,
        state.jobs.loading,
        state.securityLookups.loading
    ].some(a => a === LoadingTypes.LOADING)
);
