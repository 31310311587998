import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'grid-switch-filter',
  templateUrl: './switch-filter.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class SwitchFilterComponent extends BaseFilterCellComponent {
    @Input() public field: string;
    @Input() public filter: CompositeFilterDescriptor;
    @Input() public label: string;
    @Input() public toggleValue: any;
    @Input() uniqueFilterClass: string;
    
    public get selectedValue(): any {
        const filter = this.filterByField(this.field);

        if (!filter) {
            return null;
        }
        if (this.toggleValue !== undefined) {
            return filter.value === this.toggleValue;
        }
        return Boolean(filter.value);
    }

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public onChange(value: boolean): void {
        if (this.toggleValue !== undefined) {
            this.applyFilter(
                value === false
                    ? this.removeFilter(this.field)
                    : this.updateFilter({
                        field: this.field,
                        operator: "eq",
                        value: this.toggleValue
                    })
            );
        }
        else {
            this.applyFilter(
                value === null
                    ? this.removeFilter(this.field)
                    : this.updateFilter({
                        field: this.field,
                        operator: "eq",
                        value: value
                    })
            );
        }
    }

}
