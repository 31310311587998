import { Action, createReducer, on } from '@ngrx/store';
import { getInitialVendorProfileState, VendorProfileState } from 'src/app/admin/store/state/vendor-profile.state';
import { vendorBankActions } from 'src/app/admin/store/actions/vendor-banking.actions';
import { vendorProfileActions } from 'src/app/admin/store/actions/vendor-profile.actions';
import { vendorProfileWebsiteActions } from 'src/app/admin/store/actions/vendor-profile-website.actions';
import { VendorType } from 'src/app/shared/models/vendor-type.model';
import { VendorProfileJobRulesActions } from '../actions/vendor-profile-job-rules.actions';
import { LoadingTypes } from 'src/app/shared/models';

export function vendorProfileReducer(state: VendorProfileState, action: Action) {
    return createReducer(
        getInitialVendorProfileState(),

        on(vendorProfileActions.loadContractLineTypesSuccess, (state, action) => ({
            ...state,
            contractLineTypes: action.lineTypes
        })),

        on(vendorProfileActions.loadContractLineTypesFailure, (state, action) => ({
            ...state,
            contractLineTypes: [],
            error: JSON.stringify(action.error)
        })),

        on(vendorProfileActions.loadContractLineTypesByVendorIdSuccess, (state, action) => ({
            ...state,
            vendorContractLineTypes: action.types
        })),

        on(vendorProfileActions.loadContractLineTypesbyVendorIdFailure, (state, action) => ({
            ...state,
            vendorContractLineTypes: [],
            error: JSON.stringify(action.error)
        })),

        on(vendorProfileActions.loadVendorDetailsSuccess, (state, action) => ({
            ...state,
            details: action.details
        })),

        on(vendorProfileActions.loadVendorDetailsFailure, (state, action) => ({
            ...state,
            details: undefined,
            error: JSON.stringify(action.error)
        })),

        on(vendorBankActions.loadVendorBanks, (state) => ({
            ...state,
            banks: [],
            error: undefined
        })),

        on(vendorBankActions.loadVendorBanksSuccess, (state, action) => ({
            ...state,
            banks: action.banks,
            error: undefined
        })),

        on(vendorBankActions.loadVendorBanksFailure, (state, action) => ({
            ...state,
            banks: [],
            error: JSON.stringify(action.error)
        })),

        on(vendorBankActions.addCustomBankSuccess, (state, action) => ({
            ...state,
            banks: [...state.banks, action.bank],
            error: undefined
        })),

        on(vendorBankActions.addCustomBankFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error)
        })),

        on(vendorProfileActions.loadVendorBusinessTypesSuccess, (state, action) => ({
            ...state,
            businessTypes: action.businessTypes,
            error: undefined
        })),

        on(vendorProfileActions.loadVendorBusinessTypesFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error),
            businessTypes: []
        })),

        on(vendorProfileWebsiteActions.loadVendorProfileWebsiteTypes, (state) => ({
            ...state,
            websiteTypes: [],
            error: undefined
        })),

        on(vendorProfileWebsiteActions.loadVendorProfileWebsiteTypesSuccess, (state, action) => ({
            ...state,
            websiteTypes: action.types,
            error: undefined
        })),

        on(vendorProfileWebsiteActions.loadVendorProfileWebsiteTypesFailure, (state, action) => ({
            ...state,
            websiteTypes: [],
            error: JSON.stringify(action.error)
        })),

        on(vendorProfileWebsiteActions.loadVendorProfileWebsites, (state) => ({
            ...state,
            websites: [],
            hasChanges: false,
            error: undefined
        })),

        on(vendorProfileWebsiteActions.resetWebsitesSection, (state) => ({
            ...state,
            websites: []
        })),

        on(vendorProfileWebsiteActions.loadVendorProfileWebsitesSuccess, (state, action) => ({
            ...state,
            websites: action.sites,
            error: undefined,
            hasChanges: false
        })),

        on(vendorProfileWebsiteActions.loadVendorProfileWebsitesFailure, (state, action) => ({
            ...state,
            websites: [],
            error: JSON.stringify(action.error)
        })),

        on(vendorProfileWebsiteActions.updateWebsite, (state, action) => ({
            ...state,
            websites: state.websites.map((item) => {
                if (item.id === action.siteId) {
                    return {
                        ...item,
                        vendorWebsiteTypeId: action.siteType,
                        url: action.siteUrl
                    };
                }

                return item;
            }),
            hasChanges: true
        })),

        on(vendorProfileWebsiteActions.addWebsite, (state, action) => ({
            ...state,
            websites: [...state.websites, action.site]
        })),

        on(vendorProfileWebsiteActions.deleteWebsite, (state, action) => ({
            ...state,
            websites: state.websites.map((item) => {
                if (item.id === action.siteId) {
                    return {
                        ...item,
                        isDeleted: true
                    };
                }

                return item;
            }),
            hasChanges: true
        })),

        on(
            vendorProfileActions.loadVendorTypes,
            (currentState): VendorProfileState => ({
                ...currentState,
                vendorTypes: []
            })
        ),

        on(
            vendorProfileActions.loadVendorTypesSuccess,
            (currentState, action): VendorProfileState => ({
                ...currentState,
                vendorTypes: action.vendorTypes,
                error: undefined
            })
        ),

        on(
            vendorProfileActions.loadVendorTypesFailure,
            (currentState, action): VendorProfileState => ({
                ...currentState,
                vendorTypes: [],
                error: JSON.stringify(action.error)
            })
        ),

        on(
            vendorProfileActions.loadSelectedVendorTypesWithVendorId,
            (currentState): VendorProfileState => ({
                ...currentState,
                selectedVendorTypes: []
            })
        ),

        on(
            vendorProfileActions.updateSelectedVendorTypes,
            (currentState, action): VendorProfileState => ({
                ...currentState,
                selectedVendorTypes: action.vendorTypes.map((type: VendorType) => {
                    return {
                        vendorId: action.vendorId,
                        vendorType: type,
                        vendorTypeId: type.vendorTypeId
                    };
                })
            })
        ),

        on(
            vendorProfileActions.loadSelectedVendorTypesWithVendorIdSuccess,
            (currentState, action): VendorProfileState => ({
                ...currentState,
                selectedVendorTypes: action.selectedVendorTypes,
                error: undefined
            })
        ),

        on(
            vendorProfileActions.loadSelectedVendorTypesWithVendorIdFailure,
            (currentState, action): VendorProfileState => ({
                ...currentState,
                selectedVendorTypes: [],
                error: JSON.stringify(action.error)
            })
        ),

        on(vendorProfileActions.updateSelectedVendorContractLines, (currentState, action) => ({
            ...currentState,
            vendorContractLineTypes: action.lines
        })),

        on(vendorProfileActions.resetVendorProfile, () => ({
            ...state,
            vendorTypes: [],
            selectedVendorTypes: [],
            contractLineTypes: [],
            vendorContractLineTypes: []
        })),

        on(vendorProfileActions.setVendorContractsPagination, (state, action) => {
            const args = action.pageSize
                ? { pageSize: action.pageSize, skip: action.skip, vendorId: Number(action.vendorId) }
                : { ...state.contactsPagination, vendorId: Number(action.vendorId) };
            return {
                ...state,
                contactsPagination: args
            };
        }),

        on(
            VendorProfileJobRulesActions.loadVendorProfileJobRulesList,
            VendorProfileJobRulesActions.loadVendorProfileJobRuleDetails,
            VendorProfileJobRulesActions.loadVendorProfileJobRuleProfessionTypes,
            (state) => ({
                ...state,
                loading: LoadingTypes.LOADING
            })
        ),
        on(VendorProfileJobRulesActions.loadVendorProfileJobRulesListSuccess, (state, action) => ({
            ...state,
            jobRulesList: action.jobRules,
            loading: LoadingTypes.LOADED
        })),
        on(VendorProfileJobRulesActions.loadVendorProfileJobRuleDetailsSuccess, (state, action) => {
            const details = action.details;
            let professionTreeValues;

            if (details.professionTypes && details.professions && details.specialties) {
                professionTreeValues = [
                    {
                        professionTypeIds: details?.professionTypes.map((item) => item.id),
                        professionIds: details?.professions.map((item) => item.id),
                        expertiseProfessionIds: details?.specialties.map((item) => item.id)
                    }
                ];
            }

            return {
                ...state,
                jobRuleDetails: action.details,
                professionTreeValues: professionTreeValues,
                loading: LoadingTypes.LOADED
            };
        }),
        on(VendorProfileJobRulesActions.loadVendorProfileJobRuleProfessionTypesSuccess, (state, action) => ({
            ...state,
            professionTypes: action.professionTypes,
            loading: LoadingTypes.LOADED
        })),
        on(
            VendorProfileJobRulesActions.loadVendorProfileJobRulesListFailure,
            VendorProfileJobRulesActions.loadVendorProfileJobRuleDetailsFailure,
            VendorProfileJobRulesActions.loadVendorProfileJobRuleProfessionTypesFailure,
            (state, action) => ({
                ...state,
                error: JSON.stringify(action.error),
                loading: LoadingTypes.FAIL
            })
        ),
        on(VendorProfileJobRulesActions.clearJobRules, (state) => ({
            ...state,
            jobRulesList: [],
            jobRuleDetails: null,
            professionTypes: null,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: VendorProfileState | undefined, action: Action) {
    return vendorProfileReducer(state, action);
}
