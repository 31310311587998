import { Directive, ElementRef, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, fromEvent } from "rxjs";

@Directive({
    selector: "[follow-back-url]"
})
export class FollowBackUrlDirective implements OnDestroy {

    subscription = new Subscription();

    constructor(
        private readonly elRef: ElementRef,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) { }

    ngOnInit() {
        const element = this.elRef.nativeElement;

        this.subscription = fromEvent(element.parentNode, 'click', { capture: true })
            .subscribe((e: MouseEvent) => {
                const backUrl = this.route.snapshot.queryParamMap.get('backUrl');

                if (this.inTarget(e.target)) {
                    if (backUrl) {
                        e.stopPropagation();
                        this.router.navigateByUrl(backUrl);
                    }
                }
            });
    }

    inTarget(elm): boolean {
        while (elm) {
            if (this.elRef.nativeElement === elm) {
                return true;
            }
            elm = elm['parentElement'];
        }
        return false;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
