import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { JobRuleLookup } from 'src/app/admin-vendors/models/job-rule-lookup.model';
import {
    JobRuleExpandType,
    JobRuleProfession,
    JobRuleProfessionTreePayload,
    JobRuleProfessionType,
    JobRuleSpecialty
} from 'src/app/admin-vendors/models/job-rule-profession-specialties.model';
import { FeatureFlag } from '../../models/enums/feature-flag.enum';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'shared-nested-checkbox-tree',
    templateUrl: './nested-checkbox-tree.component.html',
    styleUrls: ['./nested-checkbox-tree.component.scss']
})
export class NestedCheckboxTreeComponent implements OnChanges {
    @Input() professionTypes: JobRuleProfessionType[];
    @Input() preselectedOptions = [];
    @Input() isReadOnly = false;
    @Input() uniqueClass = '';
    @Output() checkboxChange: EventEmitter<any> = new EventEmitter<any>();
    featureFlag = FeatureFlag;
    private readonly destroy$: Subject<void> = new Subject();

    payloadVals: JobRuleProfessionTreePayload = {
        professionTypeIds: [],
        professionIds: [],
        expertiseProfessionIds: []
    };

    constructor(private readonly _ldFeatureManager: LDFeatureManager) {}

    ngOnChanges(changes: SimpleChanges): void {
        if ('professionTypes' in changes || 'preselectedOptions' in changes) {
            this.matchPreselectedOptions();
        }
    }

    matchPreselectedOptions() {
        if (this.professionTypes && this.preselectedOptions.length) {
            const { professionTypeIds, professionIds, expertiseProfessionIds } = this.preselectedOptions[0];

            const matchedExpertiseIds = this.findMatches(expertiseProfessionIds, this.getAllSpecialties());
            const matchedProfessionIds = this.findMatches(professionIds, this.getAllProfessions());
            const matchedProfessionTypeIds = this.findMatches(professionTypeIds, this.professionTypes);

            this.clearSelections();

            matchedExpertiseIds.forEach((expertise) => {
                this.onSpecialtyChange(true, expertise);
            });
            matchedProfessionIds.forEach((profession) => {
                this.onProfessionChange(true, profession);
            });
            matchedProfessionTypeIds.forEach((professionType) => {
                this.onProfessionTypeChange(true, professionType);
            });
            this.payloadVals = this.preselectedOptions[0];
        }
    }

    clearSelections() {
        this.professionTypes.forEach((pt) => {
            pt.isChecked = false;
            pt.isIndeterminate = false;
            pt.professions?.forEach((profession) => {
                profession.isChecked = false;
                profession.isIndeterminate = false;
                profession.specialties?.forEach((specialty) => {
                    specialty.isChecked = false;
                });
            });
        });
    }

    findMatches(ids: number[], items: JobRuleLookup[]): any[] {
        return ids.map((id) => items.find((item) => item.id === id)).filter((match) => !!match);
    }

    getAllProfessions(): JobRuleProfession[] {
        return this.professionTypes.flatMap((pt) => pt.professions || []);
    }

    getAllSpecialties(): JobRuleSpecialty[] {
        return this.getAllProfessions().flatMap((p) => p.specialties || []);
    }

    toggleExpand(type: JobRuleExpandType): void {
        type.expanded = !type.expanded;
    }

    onProfessionTypeChange(isChecked: boolean, professionType: JobRuleProfessionType) {
        professionType.professions?.forEach((profession) => {
            this.updateAllSpecialtiesForProfession(isChecked, profession);
        });
        this.updateProfessionTree(professionType);
    }

    onProfessionChange(isChecked: boolean, profession: JobRuleProfession) {
        this.updateAllSpecialtiesForProfession(isChecked, profession);
        const professionType = this.findProfessionTypeContainingProfession(profession);
        this.updateProfessionTree(professionType);
    }

    onSpecialtyChange(isChecked: boolean, specialty: JobRuleSpecialty) {
        specialty.isChecked = isChecked;
        const profession = this.findAndUpdateProfessionContainingSpecialty(specialty);
        const professionType = this.findProfessionTypeContainingProfession(profession);
        this.updateProfessionTree(professionType);
    }

    updateAllSpecialtiesForProfession(isChecked: boolean, profession: JobRuleProfession) {
        profession.specialties?.forEach((specialty) => {
            specialty.isChecked = isChecked;
        });
        this.setCheckbox(profession, profession.specialties);
    }

    updateProfessionTree(professionType: JobRuleProfessionType) {
        this.updateProfessionTypeForProfessionTree(professionType);
        this.updatePayloadValues();
        this.emitCheckboxChange();
    }

    updateProfessionTypeForProfessionTree(professionType: JobRuleProfessionType) {
        if (professionType) {
            this.setCheckbox(professionType, professionType.professions);
        }
    }

    updatePayloadValues() {
        this.payloadVals.professionTypeIds = this.professionTypes.filter((pt) => pt.isChecked).map((pt) => pt.id);

        /* professionIds should only contain profession Ids that are checked for a professionType that is not selected */
        this.payloadVals.professionIds = this.getProfessionsForUncheckedProfessionTypes()
            .filter((p) => p.isChecked)
            .map((p) => p.id);

        /* expertiseProfessionIds should only contain specialty Ids that are checked for a profession that is not selected */
        this.payloadVals.expertiseProfessionIds = this.getProfessionsForUncheckedProfessionTypes()
            .filter((p) => !p.isChecked)
            .flatMap((p) => p.specialties?.filter((s) => s.isChecked))
            .map((p) => p.id);
    }

    /* expertiseProfessionIds should only contain specialty Ids that are checked for a profession that is not selected */
    updateExpertiseProfessionIds() {
        this.payloadVals.expertiseProfessionIds = this.professionTypes
            .filter((pt) => !pt.isChecked)
            .filter((pt) => pt.professions)
            .flatMap((pt) => pt.professions?.filter((p) => !p.isChecked))
            .filter((pt) => pt.specialties)
            .flatMap((p) => p.specialties?.filter((s) => s.isChecked))
            .map((p) => p.id);
    }

    emitCheckboxChange() {
        this.checkboxChange.emit(this.payloadVals);
    }

    trackByProfessionType(index: number, item: JobRuleProfessionType): number {
        return item.id;
    }

    private getProfessionsForUncheckedProfessionTypes(): JobRuleProfession[] {
        return this.professionTypes.filter((pt) => !pt.isChecked).flatMap((pt) => pt.professions);
    }

    private setCheckbox(object: JobRuleExpandType, childObjects: JobRuleExpandType[]): void {
        object.isChecked = !childObjects || childObjects.every((child) => child.isChecked);
        object.isIndeterminate =
            !object.isChecked && childObjects && childObjects.some((child) => child.isChecked || child.isIndeterminate);
    }

    private findProfessionTypeContainingProfession(profession: JobRuleProfession): JobRuleProfessionType | undefined {
        return this.professionTypes.find((pt) => pt.professions.some((p) => p.id === profession.id));
    }

    private findAndUpdateProfessionContainingSpecialty(specialty: JobRuleSpecialty): JobRuleProfession | undefined {
        const professions = this.professionTypes.flatMap((pt) => ('professions' in pt ? pt.professions : []));
        const profession = professions.find((p) => p.specialties.some((s) => s.id === specialty.id));
        if (profession) {
            this.setCheckbox(profession, profession.specialties);
        }

        return profession;
    }

    private _isLdFeatureFlagEnabled(featureName: FeatureFlag): boolean {
        let isFeatureEnabled = false;
        this._ldFeatureManager
            .isEnabled(featureName)
            .pipe(takeUntil(this.destroy$))
            .subscribe((flagIsEnabled) => {
                isFeatureEnabled = flagIsEnabled;
            });
        return isFeatureEnabled;
    }
}
