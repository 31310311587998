import { ListItem } from 'src/app/shared/models/list-item';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { UnitLookup } from 'src/app/shared/models/lookups/unit-lookup.model';
import { Action, createReducer, on } from '@ngrx/store';
import { setFilter } from 'src/app/dashboard-new/store/actions';

export interface FilterState {
    lineOfBusiness: ListItem;
    facility: FacilityLookup;
    unit: UnitLookup;
    shiftType: ListItem;
    shiftRange: ListItem;
}

export const initialFilterState: FilterState = {
    lineOfBusiness: { id: 0, name: 'All Labor Types' },
    facility: {
        id: 0,
        profileGroupName: 'All Facilities',
        profileGroupId: 0,
        name: 'All Facilities',
        clientId: null,
        facilityTypeId: null,
        systemId: null
    },
    unit: { id: 0, facilityId: 0, name: 'All Units', profileGroupId: 0, deleted: null },
    shiftType: { id: 0, name: 'All Shift Times' },
    shiftRange: { id: 0, name: 'All Future Shifts' }
};

export const filterReducer = (state: FilterState, action: Action) => {
    return createReducer(
        initialFilterState,
        on(
            setFilter,
            (_state, setFilterAction): FilterState => ({
                lineOfBusiness: setFilterAction.lineOfBusiness,
                facility: setFilterAction.facility,
                unit: setFilterAction.unit,
                shiftType: setFilterAction.shiftType,
                shiftRange: setFilterAction.shiftRange
            })
        )
    )(state, action);
};

export const reducer = (state: FilterState | undefined, action: Action) => {
    return filterReducer(state, action);
};
