import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'boolean' })
export class BooleanPipe implements PipeTransform {
    transform(value?: boolean | null, trueValue: string = 'Yes', falseValue: string = 'No'): string {
        if (value === null || value === undefined) {
            return '';
        }
        return value ? trueValue : falseValue;
    }
}
