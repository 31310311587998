import dayjs from 'dayjs';
import { isEmptyCheck } from 'src/app/shared/utilities/is-empty';
import { ExtensionString, mimeTypeMap, MimeTypeString } from 'src/app/shared/utilities/mimetype-map';

export function downloadBlob(blob: Blob, filename: string) {
    const link = document.createElement('a');
    // HOT-2481 - enable downloads in Edge and IE
    const blobUrl = window.URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
    }, 0);
}

export const downloadFile = (file: File): void => {
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(file);
    downloadLink.href = url;
    downloadLink.download = file.name;
    downloadLink.dispatchEvent(new MouseEvent('click'));
};

export const mapExportFields = (result) => {
    const mapping = [
        { name: 'Reference Number', key: 'referenceNumber' },
        { name: 'Reverse Invoice Date', key: 'referenceDate' },
        { name: 'Hospital System', key: 'hospitalSystemName' },
        { name: 'Facility Name', key: 'facilityName' },
        { name: 'Employee Name', key: 'employeeName' },
        { name: '"Last, First"', key: 'lastFirst' },
        { name: 'Profession', key: 'professionAbbreviation' },
        { name: 'Specialty', key: 'specialtyAbbreviation' },
        { name: 'Unit', key: 'unitName' },
        { name: 'Cost Center', key: 'costCenterName' },
        { name: 'Shift Date', key: 'shiftDate' },
        { name: 'Shift Details', key: 'shiftDetails' },
        { name: 'Type', key: 'typeName' },
        { name: 'Quantity(Total Hours)', key: 'totalHours' },
        { name: 'Rate', key: 'rate' },
        { name: 'Gross Amount', key: 'grossAmount' },
        { name: 'Brand', key: 'brandName' },
        { name: 'Client Invoice Number', key: 'clientInvoiceNumber' },
        { name: 'Contract Id', key: 'contractId' }
    ];

    return (
        mapping.map((x) => x.name).join(',') +
        '\n' +
        result
            .map((line) => {
                return mapping
                    .map((x) => {
                        if (x.key === 'referenceDate' || x.key === 'shiftDate') {
                            return dayjs(line[x.key]).format('M/D/YYYY');
                        }
                        if (
                            (x.key === 'hospitalSystemName' ||
                                x.key === 'facilityName' ||
                                x.key === 'employeeName' ||
                                x.key === 'unitName' ||
                                x.key === 'costCenterName' ||
                                x.key === 'shiftDetails' ||
                                x.key === 'vendorLegalName' ||
                                x.key === 'lastFirst' ||
                                x.key === 'mspVms') &&
                            !isEmptyCheck(line[x.key])
                        ) {
                            line[x.key] = `"${line[x.key]
                                .replace(/\s{2,}/g, ' ')
                                .split(',')
                                .map((a) => a.trim())
                                .join(', ')}"`;
                            return line[x.key];
                        }
                        return isEmptyCheck(line[x.key]) ? '' : line[x.key];
                    })
                    .join(',');
            })
            .join('\n')
    );
};

export class Utilities {
    public static downloadBlob(blob: Blob, filename: string): void {
        downloadBlob(blob, filename);
    }

    public static downloadFile(file: File): void {
        downloadFile(file);
    }
}

export const createEmptyFileWithName = (fileName: string, mimeType?: MimeTypeString, lastModified?: dayjs.Dayjs) => {
    // Get the MIME type from the map if not provided
    const resolvedMimeType = mimeType ? mimeType : getMimeType(fileName); // default to 'application/octet-stream'
    // This is one of the very few places we won't use utc. The browser sets the lastModifiedDate to a local date. We are
    const modifiedDate = lastModified?.valueOf() ?? new Date().valueOf();

    const propsBag: FilePropertyBag = {
        type: resolvedMimeType,
        lastModified: modifiedDate
    };

    return new File([new Blob([''], { type: resolvedMimeType })], fileName, propsBag);
};

export const getExtensionWithoutDot = (fileName: string): ExtensionString => {
    // Casting to SupportedFileExtensions just to allow the Map key searching.
    return fileName.slice(fileName.lastIndexOf('.') + 1) as ExtensionString;
};

const getMimeType = (fileName: string): MimeTypeString => {
    // Casting to SupportedFileExtensions just to allow the Map key searching.
    const fileExtension = getExtensionWithoutDot(fileName);
    return mimeTypeMap.has(fileExtension) ? mimeTypeMap.get(fileExtension) : mimeTypeMap.get('');
};
