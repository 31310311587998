import { createAction, props } from '@ngrx/store';

export const archiveWorkerConfirmation = createAction(
    '[Internal Pool Resource Details] Archive Worker Confirmation',
    props<{ id: number }>()
);

export const archiveWorker = createAction(
    '[Internal Pool Resource Details] Archive Worker',
    props<{ id: number }>()
);

export const archiveWorkerSuccess = createAction(
    '[Internal Pool Resource Details] Archive Worker Success'
);

export const archiveWorkerFail = createAction(
    '[Internal Pool Resource Details] Archive Worker Fail',
    props<{ error: any }>()
);

export const unarchiveWorkerConfirmation = createAction(
    '[Internal Pool Resource Details] Unarchive Worker Confirmation',
    props<{
        id: number,
        refreshList?: boolean,
        refreshDetails?: boolean,
        navigateBack?: boolean
    }>()
);

export const unarchiveWorker = createAction(
    '[Internal Pool Resource Details] Unarchive Worker',
    props<{ id: number }>()
);

export const unarchiveWorkerSuccess = createAction(
    '[Internal Pool Resource Details] Unarchive Worker Success'
);

export const unarchiveWorkerFail = createAction(
    '[Internal Pool Resource Details] Unarchive Worker Fail',
    props<{ error: any }>()
);
