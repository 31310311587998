import { createAction, props } from '@ngrx/store';
import { FacilityLookups } from 'src/app/shifts/models/facility-lookups.model';

export const loadFacilityLookups = createAction('[Internal Pool Resources] Load Facility Lookups');

export const loadFacilityLookupsFail = createAction(
    '[Internal Pool Resources] Load Facility Lookups Fail',
    props<{ error: any }>()
);

export const loadFacilityLookupsSuccess = createAction(
    '[Internal Pool Resources] Load Facility Lookups Success',
    props<{ facilityLookups: FacilityLookups }>()
);
