import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/admin/store/actions/submittals-detail.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { getInitialSubmittalsDetailState, SubmittalsDetailState } from '../state/submittals-detail.state';

export function adminSubmittalsDetailReducer(state: SubmittalsDetailState, action: Action) {
    return createReducer(
        getInitialSubmittalsDetailState(),

        on(actions.loadSubmittalsDetails, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(actions.loadSubmittalsDetailsSuccess, (state, action) => ({
            ...state,
            details: action.details,
            loading: LoadingTypes.LOADED,
            loadingSave: LoadingTypes.LOADED,
            hasChanges: false
        })),
        on(actions.loadSubmittalsDetailsFail, (state, action) => ({
            ...state,
            details: null,
            loading: LoadingTypes.FAIL,
            loadingSave: LoadingTypes.FAIL,
            error: JSON.stringify(action.error),
            hasChanges: false
        })),

        on(actions.loadSubmittalStages, (state) => ({
            ...state,
            loadingStages: LoadingTypes.LOADING
        })),
        on(actions.loadSubmittalStagesSuccess, (state, action) => ({
            ...state,
            loadingStages: LoadingTypes.LOADED,
            stages: action.stages
        })),
        on(actions.loadSubmittalStagesFail, (state, action) => ({
            ...state,
            loadingStages: LoadingTypes.FAIL,
            error: action.error,
            stages: []
        })),

        on(actions.loadSubmittalStageReasons, (state) => ({
            ...state,
            loadingStageReasons: LoadingTypes.LOADING
        })),
        on(actions.loadSubmittalStageReasonsSuccess, (state, action) => ({
            ...state,
            loadingStageReasonss: LoadingTypes.LOADED,
            stageReasons: action.stageReasons
        })),
        on(actions.loadSubmittalStageReasonsFail, (state, action) => ({
            ...state,
            loadingStageReasonss: LoadingTypes.FAIL,
            error: action.error,
            stageReasons: []
        })),

        on(actions.updateSubmittalNote, (state) => ({
            ...state,
            loadingSave: LoadingTypes.LOADING
        })),

        on(actions.setChangeState, (state, action) => ({
            ...state,
            hasChanges: action.hasChanges
        })),

        on(actions.clearSubmittalDetails, () => getInitialSubmittalsDetailState())
    )(state, action);
}

export function reducer(state: SubmittalsDetailState | undefined, action: Action) {
    return adminSubmittalsDetailReducer(state, action);
}
