import { createAction, props } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { FileType } from 'src/app/shared/models/file-type.model';

export const loadVendorCandidateDetailFiles = createAction(
    '[VENDOR CANDIDATES DETAIL FILES COMPONENT] Load Vendor Candidate Detail Files',
    props<{ candidateId: number }>()
);
export const loadVendorCandidateDetailFilesSuccess = createAction(
    '[VENDOR CANDIDATES DETAIL API] Load Vendor Candidate Detail Files Success',
    props<{ candidateFiles: GridDataResult; candidateId: number }>()
);
export const loadVendorCandidateDetailFilesFailure = createAction(
    '[VENDOR CANDIDATES DETAIL API] Load Vendor Candidate Detail Files Failure',
    props<{ error: any }>()
);
export const deleteVendorCandidateDetailFiles = createAction(
    '[VENDOR CANDIDATES DETAIL FILES COMPONENT] Delete Vendor Candidate Detail Files',
    props<{ candidateId: number; fileIds: number[] }>()
);
export const deleteVendorCandidateDetailFilesSuccess = createAction(
    '[VENDOR CANDIDATES DETAIL API] Delete Vendor Candidate Detail Files Success',
    props<{ success: boolean }>()
);
export const deleteVendorCandidateDetailFilesFailure = createAction(
    '[VENDOR CANDIDATES DETAIL API] Delete Vendor Candidate Detail Files Failure',
    props<{ error: any }>()
);
export const setVendorCandidateDetailFilesSearchQuery = createAction(
    '[VENDOR CANDIDATES DETAIL FILES COMPONENT] Set Search Query State',
    props<{ searchQuery: GridSearchQuery }>()
);

export const loadFileTypes = createAction('[VENDOR CANDIDATES DETAIL FILES COMPONENT] Load File Types');
export const loadFileTypesSuccess = createAction(
    '[VENDOR CANDIDATES DETAIL API] Load File Types Success',
    props<{ fileTypes: FileType[] }>()
);
export const loadFileTypesFailure = createAction(
    '[VENDOR CANDIDATES DETAIL API] Load File Types Failure',
    props<{ error: any }>()
);
