import { createAction, props } from '@ngrx/store';
import { GridSearchQuery } from 'src/app/shared/grid/models/grid-search-query.model';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { AdminClientListItem } from 'src/app/admin-clients/models/admin-clients.model';
import {
    AdminClientFacilityProfileGroup,
    AdminClientFacilities
} from 'src/app/admin-clients/models/admin-clients-facilities.model';

export const loadAdminClientDetails = createAction(
    '[ADMIN CLIENT COMPONENT] Load admin client',
    props<{ id: number }>()
);

export const adminClientDetailsSuccess = createAction(
    '[ADMIN CLIENT API] Admin Client Detail Success',
    props<{ adminClient: PagingToken<AdminClientListItem[]> }>()
);

export const adminClientDetailsFailure = createAction(
    '[ADMIN CLIENT API] Admin Client Detail Failure',
    props<{ error: any }>()
);

export const setAdminClientDetailSearchQuery = createAction(
    '[ADMIN CLIENT API] Admin Clients Details Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);

export const loadFacilityLookup = createAction(
    '[ADMIN CLIENT COMPONENT FACILTITIES] Admin Clients FacilitiesLookup',
    props<{ searchName: string; legacyId: number }>()
);

export const loadFacilityLookupSuccess = createAction(
    '[ADMIN CLIENT COMPONENT FACILTITIES] Admin Clients FacilitiesLookup success',
    props<{ facilityLookup: AdminClientFacilities[] }>()
);

export const loadFacilityLookupError = createAction(
    '[ADMIN CLIENT COMPONENT FACILTITIES] Admin Clients FacilitiesLookup error',
    props<{ error: any }>()
);

export const loadFacilityProfileGroups = createAction(
    '[ADMIN CLIENT COMPONENT FACILTITIES] Admin Clients Facilities Profile Group',
    props<{ clientId?: number }>()
);

export const loadFacilityProfileGroupsSuccess = createAction(
    '[ADMIN CLIENT COMPONENT FACILTITIES] Admin Clients Facilities Profile Group success',
    props<{ facilityProfileGroup: AdminClientFacilityProfileGroup[] }>()
);

export const loadFacilityProfileGroupsError = createAction(
    '[ADMIN CLIENT COMPONENT FACILTITIES] Admin Clients Facilities Profile Group error',
    props<{ error: any }>()
);

export const createAdminClient = createAction(
    '[ADMIN CLIENT COMPONENT CREATE] Admin Client Create',
    props<{ facilityProfileGroupId: number }>()
);

export const createClientSuccess = createAction(
    '[ADMIN CLIENT COMPONENT CREATE] Admin Clients create success',
    props<{ legacyId: number }>()
);

export const createClientError = createAction(
    '[ADMIN CLIENT COMPONENT CREATE] Admin Clients create error',
    props<{ error: any }>()
);

export const updateAdminClient = createAction(
    '[ADMIN CLIENT COMPONENT UPDATE] Admin Client Update',
    props<{ id: number; legacyId: number }>()
);

export const updateClientSuccess = createAction('[ADMIN CLIENT API] Admin Clients update success');

export const updateClientError = createAction('[ADMIN CLIENT API] Admin Clients update error', props<{ error: any }>());

export const loadIsLegacyId = createAction(
    '[ADMIN CLIENT COMPONENT CHECK LEGACYID] Admin Client Check LegacyId',
    props<{ legacyId: number }>()
);

export const isLegacyIdSuccess = createAction(
    '[ADMIN CLIENT API] Admin Client Check LegacyId Success',
    props<{ isLegacyId: boolean }>()
);

export const isLegacyIdError = createAction(
    '[ADMIN CLIENTS API] Admin Client Check LegacyId Error',
    props<{ error: any }>()
);

export const clearDetailState = createAction('[ADMIN CLIENT COMPONENT CLEAR STATE] Admin Client Clear  State');
