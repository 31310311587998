import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import * as shiftsActions from '../actions';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ShiftTemplate } from '../../models';

export interface ShiftTemplatesQuery extends GridSearchQuery {

}

export interface ShiftTemplatesState {
    error: string;
    loading: LoadingTypes;
    templates: ShiftTemplate[];
    query: ShiftTemplatesQuery;
    total: number;
}

export const initialState: ShiftTemplatesState = {
    error: '',
    loading: LoadingTypes.INIT,
    templates: [],
    total: 0,
    query: {
        sort: [],
        take: 10,
        skip: 0,
        filter: { logic: "and", filters: [] }
    }
}

const reducer = createReducer(
    initialState,
    on(shiftsActions.loadShiftTemplates, (state) => (
        {
            ...state,
            loading: LoadingTypes.LOADING,
        })),
    on(shiftsActions.loadShiftTemplatesFail, (state) => (
        {
            ...state,
            loading: LoadingTypes.LOADED,
            error: state.error
        })),
    on(shiftsActions.loadShiftTemplatesSuccess, (state, action) => (
        {
            ...state,
            loading: LoadingTypes.LOADED,
            templates: action.templates,
            total: action.total
        })),
    on(shiftsActions.setShiftTemplatesQuery, (state, action) => (
        {
            ...state,
            query: {
                ...state.query,
                ...action.query
            }
        })),

    on(shiftsActions.clearShiftTemplates, (state) => (
        {
            ...state,
            ...initialState
        }))
);


export function shiftTemplatesReducer(state: ShiftTemplatesState | undefined, action: Action) {
    return reducer(state, action);
}
