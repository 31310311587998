import { createAction, props } from '@ngrx/store';
import { PowerBiSettings } from 'src/app/shared/models/powerbi/powerbi-settings.model';
import { PowerBiAccessConfig } from 'src/app/shared/models/powerbi/powerbi-access-config.model';
import { PowerBiExportRequest } from 'src/app/shared/models/powerbi/powerbi-export-request.model';
import { PowerBiExportStatus } from 'src/app/shared/models/powerbi/powerbi-export-status.model';
import { HttpResponse } from '@angular/common/http';

export const loadPowerBiReportAccessConfig = createAction(
    '[POWER BI REPORT] Load Power Bi Report Access Config',
    props<{ id: number; isRefresh?: boolean }>()
);
export const loadPowerBiReportAccessConfigSuccess = createAction(
    '[POWER BI REPORT] Load Power Bi Report Access Config Success',
    props<{ config: PowerBiAccessConfig }>()
);
export const loadPowerBiReportAccessConfigFail = createAction(
    '[POWER BI REPORT] Load Power Bi Report Access Config Fail',
    props<{ error: any }>()
);

export const loadPowerBiReportSettings = createAction(
    '[POWER BI REPORT] Load Power Bi Report Settings',
    props<{ id: number; page: string }>()
);
export const loadPowerBiReportSettingsSuccess = createAction(
    '[POWER BI REPORT] Load Power Bi Report Settings Success',
    props<{ settings: PowerBiSettings }>()
);
export const loadPowerBiReportSettingsFail = createAction(
    '[POWER BI REPORT] Load Power Bi Report Settings Fail',
    props<{ error: any }>()
);

export const savePowerBiReportSettings = createAction(
    '[POWER BI REPORT] Save Power Bi Report Settings',
    props<{ id: number; page: string; settings: PowerBiSettings }>()
);
export const savePowerBiReportSettingsSuccess = createAction('[POWER BI REPORT] Save Power Bi Report Settings Success');
export const savePowerBiReportSettingsFail = createAction(
    '[POWER BI REPORT] Save Power Bi Report Settings Fail',
    props<{ error: any }>()
);

export const clearPowerBiReportSettings = createAction(
    '[POWER BI REPORT] Clear Power Bi Report Settings',
    props<{ id: number }>()
);
export const clearPowerBiReportSettingsSuccess = createAction(
    '[POWER BI REPORT] Clear Power Bi Report Settings Success'
);
export const clearPowerBiReportSettingsFail = createAction(
    '[POWER BI REPORT] Clear Power Bi Report Settings Fail',
    props<{ error: any }>()
);

export const exportPowerBiReport = createAction(
    '[POWER BI REPORT] Export Power Bi Report',
    props<{ id: number; requestModel: PowerBiExportRequest }>()
);
export const exportPowerBiReportSuccess = createAction(
    '[POWER BI REPORT] Export Power Bi Report Success',
    props<{ status: PowerBiExportStatus }>()
);
export const exportPowerBiReportFail = createAction(
    '[POWER BI REPORT] Export Power Bi Report Fail',
    props<{ error: any }>()
);

export const getPowerBiReportExportStatus = createAction(
    '[POWER BI REPORT] Get Power Bi Report Export Status',
    props<{ id: number; exportId: string }>()
);
export const getPowerBiReportExportStatusSuccess = createAction(
    '[POWER BI REPORT] Get Power Bi Report Export Status Success',
    props<{ status: PowerBiExportStatus }>()
);
export const getPowerBiReportExportStatusFail = createAction(
    '[POWER BI REPORT] Get Power Bi Report Export Status Fail',
    props<{ error: any }>()
);

export const downloadPowerBiReportFile = createAction(
    '[POWER BI REPORT] Download Power Bi Report Export File',
    props<{ id: number; exportId: string }>()
);
export const downloadPowerBiReportFileSuccess = createAction(
    '[POWER BI REPORT] Download Power Bi Report Export File Success',
    props<{ document: HttpResponse<Blob> }>()
);
export const downloadPowerBiReportFileFail = createAction(
    '[POWER BI REPORT] Download Power Bi Report Export File Fail',
    props<{ error: any }>()
);

export const clearPowerBiReport = createAction('[POWER BI REPORT] Clear Power Bi Report');
