import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ListItem } from 'src/app/shared/models/list-item';
import { PreferredShift } from '../models';

interface PreferredShiftGroup {
    days: number[];
    shiftHours: number;
    startTime: Date;
}

@Component({
    templateUrl: './preferred-shift-dialog.component.html',
    styleUrls: ['./preferred-shift-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreferredShiftDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    form = this.fb.group({
        preferredShifts: this.fb.array([this.createPreferredShift()])
    });

    dayLookups$: Observable<ListItem[]>;
    shiftLengthLookups$: Observable<ListItem[]>;

    constructor(
        public readonly dialogRef: MatDialogRef<PreferredShiftDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            preferredShifts: PreferredShift[];
        },
        private readonly fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnInit() {
        this.dayLookups$ = of(
            ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((d, i) => ({
                id: i + 1,
                name: d
            }))
        );

        this.shiftLengthLookups$ = of(
            [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5].map(
                (d, i) => ({ id: d, name: String(d) })
            )
        );

        if (Array.isArray(this.data.preferredShifts)) {
            const data = this.preferredShiftsGroup;
            this.preferredShifts.clear();
            this.onAdd(data.length || 1);
            this.form.patchValue({
                preferredShifts: data
            });
        }
    }

    get preferredShiftsGroup(): PreferredShiftGroup[] {
        if (!this.data.preferredShifts) {
            return [];
        }

        const groups = this.data.preferredShifts.reduce((g, shift) => {
            const key = `${shift.shiftHours}_${shift.startTime}`;
            if (!(key in g)) {
                g[key] = [];
            }
            g[key].push(shift);
            return g;
        }, {});

        return Object.keys(groups).map((k) => {
            const shift = groups[k][0];
            return {
                days: groups[k].map((d) => d.day),
                shiftHours: shift.shiftHours,
                startTime: shift.startTime
            };
        });
    }

    get formValue(): PreferredShift[] {
        const { preferredShifts } = this.form.value;
        const result = [];

        for (const g of preferredShifts.filter((g) => g.days && g.shiftHours && g.startTime)) {
            if (!g.days) {
                continue;
            }
            if (!g.days.length) {
                continue;
            }

            for (const d of g.days) {
                result.push({
                    day: d,
                    shiftHours: g.shiftHours,
                    startTime: new Date(g.startTime)
                });
            }
        }

        return result;
    }

    onSave() {
        this.dialogRef.close(this.formValue);
    }

    onClose() {
        this.dialogRef.close();
    }

    onAdd(count = 1) {
        for (; count > 0; count--) {
            this.preferredShifts.push(this.createPreferredShift());
        }
    }

    onRemove(index: number) {
        this.preferredShifts.removeAt(index);
        this.preferredShifts.markAsDirty();

        if (!this.preferredShifts.controls.length) {
            this.onAdd();
        }
    }

    createPreferredShift() {
        const group = this.fb.group({
            days: [''],
            shiftHours: [''],
            startTime: [''],
            endTime: ['']
        });

        return group;
    }

    private formatTime(time: Date): string {
        time = new Date(time);
        const hours = time.getHours();
        const minutes = time.getMinutes();

        return `0001-01-01T${hours}:${minutes}:00`;
    }

    get preferredShifts() {
        return this.form.get('preferredShifts') as UntypedFormArray;
    }
}
