import { AbstractControl, ValidationErrors } from "@angular/forms";

export function emailExtendedValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }
    const emailPattern = /^(?=[a-zA-Z0-9])[a-zA-Z0-9_.%+'-]{0,64}(?<=[a-zA-Z0-9])@(?=[a-zA-Z0-9])[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}$/;
    const doubleDotsPattern = /\.{2,}/;
    const isValid = emailPattern.test(control.value) && !doubleDotsPattern.test(control.value);

    return isValid ? null : {'email': {value: control.value}};
}
