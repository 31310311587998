import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commentsStatusLabel'
})
export class CommentsStatusLabelPipe implements PipeTransform {
    transform(expenseStatus: string) {
        let statusLabel = expenseStatus;
        switch (expenseStatus) {
            case 'Pending Approval':
                statusLabel = 'Edit Reason';
                break;
            case 'Approved':
                statusLabel = 'Approval Comments';
                break;
            case 'Rejected':
                statusLabel = 'Rejection Reason';
                break;
        }
        return statusLabel;
    }
}
