import { SystemContact } from 'src/app/admin/system-contacts/models/system-contact.model';
import { ContactTag, LoadingTypes } from 'src/app/shared/models';
import { OrganizationInvitation } from 'src/app/shared/models/account/organization-invitation.model';
import { ListItem } from 'src/app/shared/models/list-item';
import { IdentityProvider } from 'src/app/shared/models/sso/identity-provider.model';

export interface SystemContactsProfilesState {
    loading: LoadingTypes;
    systemContact: SystemContact;
    systemContactTypes: ContactTag[];
    error: unknown;
    systemContactId: number;
    hospitalSystems: ListItem[];
    identityProviders: IdentityProvider[];
    invitation: OrganizationInvitation;
}

export const getInitialSystemContactsProfileState = (): SystemContactsProfilesState => ({
    loading: LoadingTypes.INIT,
    systemContactId: null,
    systemContact: null,
    systemContactTypes: [],
    error: undefined,
    hospitalSystems: [],
    identityProviders: [],
    invitation: null
});
