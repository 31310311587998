import { InvoicesListState } from './reducers/invoices-list.reducer';
import { InvoicesLookupsState } from './reducers/invoices-lookups.reducer';
import { InvoicesQueryState } from './reducers/invoices-query.reducer';
import { InvoicesDocumentState } from './reducers/invoices-document';
import { InvoicesExportState } from './reducers/invoices-export';
import { InvoiceDetailsState } from './reducers/invoice-details.reducer';
import { ApproveDraftInvoiceState } from './reducers/approve-draft-invoice.reducer';
import { InvoiceAttachmentsState } from './reducers/invoice-attachments.reducer';
import { InvoiceAttachmentsEnhancedState } from './reducers/invoice-attachments-enhanced.reducer';

export const invoicesStoreKey = 'client/invoiced';

export interface InvoicesState {
    list: InvoicesListState;
    query: InvoicesQueryState;
    lookups: InvoicesLookupsState;
    document: InvoicesDocumentState;
    export: InvoicesExportState;
    details: InvoiceDetailsState;
    attachments: InvoiceAttachmentsState;
    attachmentsEnhanced: InvoiceAttachmentsEnhancedState;
    approveDraft: ApproveDraftInvoiceState;
}
