import { HttpClient } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { State } from 'src/app/shared/grid/models/state.model';
import { ContactPhoneType, ContactTag, VendorContact, ContactEmailType } from 'src/app/shared/models';
import { InvalidContactChange } from '../models/invalid-contact-change.model';
import { setInvalidChanges } from './utilities/set-invalid-changes.function';
import { VendorContactMigrationService } from './vendor-contact-migration.service';
import { VendorContactProfileComponent } from './vendor-contact-profile/vendor-contact-profile.component';
import { VendorContactChange } from './vendor-contacts-change.model';
import { filter, map } from 'rxjs/operators';
import { VendorContactsPagination } from 'src/app/shared/models/vendor-contacts-pagination.model';
import { Observable, ReplaySubject } from 'rxjs';
import { VendorContactEmailType } from 'src/app/admin/vendor-contact-details/models';

@Component({
    selector: 'aya-vendor-contacts',
    templateUrl: './vendor-contacts.component.html',
    styleUrls: ['./vendor-contacts.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VendorContactsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @Output() vendorContactsChanged = new EventEmitter<VendorContactMigrationService[]>();
    @Output() vendorContactsPaginationChange = new EventEmitter<PageEvent>();
    @Input() vendorId: number;
    @Input() vendorContacts: VendorContact[];
    @Input() vendorContactsTotal: number;
    @Input() states: State[];
    @Input() contactTags: ContactTag[];
    @Input() phoneTypes: ContactPhoneType[];
    @Input() emailTypes: ContactEmailType[];
    @Input() isSaving = false;
    @Input() contactsPaginationArgs: VendorContactsPagination;

    contactMainEmailChanged$ = new ReplaySubject<[contact: { contactId: number; newContactId: number }, value: string]>(
        1
    );

    checkMainEmailChanged(contact: VendorContact): Observable<string> {
        return this.contactMainEmailChanged$.pipe(
            filter((email) =>
                email[0].contactId === 0
                    ? email[0].newContactId === contact.newContactId
                    : email[0].contactId === contact.id
            ),
            map((email) => email[1])
        );
    }

    paginatedVendorContacts: VendorContact[] = [];
    pageIndex: number;
    pageSize = 10;
    selectedContact: VendorContact;

    private pendingContactChanges: VendorContactMigrationService[] = [];
    private invalidChange: InvalidContactChange = { email: false, profile: false, phone: false, businessEmail: false };
    private _addingNewContact = false;

    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private readonly settings: Settings,
        private readonly featureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.vendorContacts && !changes.vendorContacts.previousValue?.length) {
            // Clear out any pending changes, since we have changes come through
            // for vendor contacts. Which means a fresh list
            this.pendingContactChanges = [];

            if (!changes.vendorContacts.previousValue?.length) {
                this.pageIndex = 0;
            }
        }

        if (changes.isSaving && changes.isSaving.currentValue) {
            this.pageIndex = 0;

            // Do an explicit check since 'currentValue' is an 'any' type
            // Clear out any pending changes if we are saving other wise
            // they have the potential to get caught and re-saved
            if (changes.isSaving.currentValue === true) {
                this.pendingContactChanges = [];
            }
        }
    }

    updateServerSidePaginatedContacts(
        event: PageEvent = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            length: this.vendorContactsTotal
        }
    ): void {
        const args = {
            pageSize: event.pageSize,
            skip: event.pageIndex * event.pageSize,
            vendorId: this.vendorId
        };

        this.vendorContactsPaginationChange.emit(args as any);
    }

    addContact(): void {
        const newContact: any = {
            id: 0,
            newContactId: new Date().valueOf(),
            vendorId: this.vendorId
        };

        this.vendorContacts = [newContact, ...this.vendorContacts];
        this.vendorContactsTotal++;
        // Do not page. Paging on new record introduces numerous issues.
        // A more robust cross page change tracking will be needed to support this.
    }

    deleteContact(contactToDelete: VendorContact): void {
        const vendorIndex = this.findContactIndex(this.vendorContacts, contactToDelete);

        if (vendorIndex > -1) {
            this.vendorContacts.splice(vendorIndex, 1);
        }

        this.vendorContactsTotal--;
        this.contactInfoChanged({ vendorContact: contactToDelete, toBeDeleted: true });
    }

    contactInfoChanged(
        changedContact: VendorContactChange,
        profileComponent: VendorContactProfileComponent = null
    ): void {
        const pendingIndex = this.findContactIndex(
            this.pendingContactChanges.map((x: VendorContactMigrationService) => x.vendorContact),
            changedContact.vendorContact
        );
        const updatedVendor = new VendorContactMigrationService(this.http, this.settings, changedContact);
        this.updateVendorContact(changedContact);

        this.invalidChange = setInvalidChanges(changedContact, this.invalidChange);
        profileComponent?.setUsernameRequiresBusinessEmailError(changedContact.vendorContact);
        updatedVendor.isInvalid = Object.values(this.invalidChange).filter((invalid: boolean) => invalid).length > 0;

        if (pendingIndex > -1) {
            if (changedContact.toBeDeleted && changedContact.vendorContact.id === 0) {
                this.pendingContactChanges.splice(pendingIndex, 1);
            } else {
                this.pendingContactChanges[pendingIndex] = updatedVendor;
            }
        } else {
            this.pendingContactChanges.push(updatedVendor);
        }

        this.vendorContactsChanged.emit(this.pendingContactChanges);
    }

    private findContactIndex(contacts: any[], contactToFind: VendorContact): number {
        let index = -1;

        if (contactToFind.id === 0) {
            index = contacts.findIndex((contact: VendorContact) => contact.newContactId === contactToFind.newContactId);
        } else {
            index = contacts.findIndex((contact: VendorContact) => contact.id === contactToFind.id);
        }

        return index;
    }

    public selectContact(contact: VendorContact) {
        if (this.selectedContact !== contact) {
            this.currentContactEmailChange(contact);
        }
        this.selectedContact = contact;
    }

    private updateVendorContact(changedContact: VendorContactChange): void {
        if (changedContact.toBeDeleted) {
            return;
        }

        const index = this.findContactIndex(this.vendorContacts, changedContact.vendorContact);

        if (index > -1) {
            if (changedContact.changeType === 'phone') {
                this.vendorContacts[index].vendorContactPhones = changedContact.vendorContact.vendorContactPhones;
            } else if (changedContact.changeType === 'email') {
                this.vendorContacts[index].vendorContactEmails = changedContact.vendorContact.vendorContactEmails;
                this.currentContactEmailChange(this.vendorContacts[index]);
            } else if (changedContact.changeType === 'profile') {
                this.vendorContacts[index].firstName = changedContact.vendorContact.firstName;
                this.vendorContacts[index].lastName = changedContact.vendorContact.lastName;
                this.vendorContacts[index].states = changedContact.vendorContact.states;
                this.vendorContacts[index].tags = changedContact.vendorContact.tags;
                this.vendorContacts[index].user = changedContact.vendorContact.user;
            }
        }
    }
    private currentContactEmailChange(currentContact: VendorContact) {
        const username = currentContact?.user?.username;
        var emailFound = (currentContact?.vendorContactEmails ?? [])
            .sort((a, b) =>
                username &&
                a.emailAddress === username &&
                a.vendorContactEmailTypeID === VendorContactEmailType.Business
                    ? -1
                    : 1
            )
            .find((e) => e.emailAddress && e.vendorContactEmailTypeID === VendorContactEmailType.Business);
        this.contactMainEmailChanged$.next([
            { contactId: currentContact?.id, newContactId: currentContact?.newContactId },
            emailFound?.emailAddress ?? ''
        ]);
    }
}
