import { Component } from '@angular/core';

@Component({
  selector: 'ayac-not-found',
  template: '<p>This module is still in development</p>'
})
export class NotFoundComponent {

  constructor() { }

}
