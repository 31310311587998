import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { State } from 'src/app/shared/grid/models/state.model';
import { HospitalSystemsVendorsGridItem } from 'src/app/facilities/models';

export const getInitialGridState = (): GridSearchQuery => ({
    filter: { logic: 'and', filters: [] },
    sort: [{ field: '', dir: SortTypes.ASC }],
    skip: 0,
    take: 10
});

export interface FacilitySystemsVendorsState {
    vendors: HospitalSystemsVendorsGridItem[];
    total: number;
    filtered: number;
    loadingState: LoadingTypes;
    gridSearchQuery: GridSearchQuery;
    states: State[];
    error: string;
}

export const getInitialFacilitySystemsVendorsState = (): FacilitySystemsVendorsState => ({
    vendors: [],
    total: 0,
    filtered: 0,
    loadingState: LoadingTypes.INIT,
    gridSearchQuery: getInitialGridState(),
    states: [],
    error: ''
});
