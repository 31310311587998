import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { SystemFormField } from 'src/app/core/models';
import * as fromCore from 'src/app/core/state';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Directive({
    selector: '[hasSystemFields]'
})
export class HasSystemFieldsDirective extends UnsubscribeOnDestroy implements OnInit {
    input$ = new BehaviorSubject<string | string[]>('');
    inputOperation$ = new BehaviorSubject<'OR' | 'AND'>('AND');

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly store: Store<{}>
    ) {
        super();
    }

    ngOnInit() {        
        this.store.pipe(
            select(fromCore.getSystemFields),
            switchMap((settings) => 
                combineLatest([this.input$, this.inputOperation$])
                .pipe(
                    map(([input, op]) => this.hasField(settings, input, op))
                )
            ),
            distinctUntilChanged(),
            takeUntil(this.d$)
        ).subscribe((hasSettings) => {
            if (hasSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        })
    }

    @Input()
    set hasSystemFields(val) {
        this.input$.next(val);
    }

    @Input()
    set hasSystemFieldsOperation(op) {
        this.inputOperation$.next(op);
    }

    hasField(fields: SystemFormField[], input: string | string[], op: 'OR' | 'AND' | 'NOT') {
        if (!input) {
            return false;
        }

        const inputSettings = Array.isArray(input) ? input : [input];
        const compare = a => fields.some(s => {
            const [moduleName, fieldName] = a.split('.');
            
            return s.moduleName === moduleName
                && s.fieldName === fieldName
        });
        
        switch(op) {
            case 'OR': 
                return inputSettings.some(compare)
            case 'NOT':
                return !inputSettings.some(compare)
            case 'AND':
            default:
                return Boolean(fields.length) 
                    && inputSettings.every(compare)
        }
    }
}
