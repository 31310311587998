import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FileAttachment } from '../../models/attachment';
import { FileAttachmentsViewerComponent } from '../file-attachments-viewer/file-attachments-viewer.component';

export interface FileAttachmentPreview {
    blob: Blob;
    totalPages: number;
    fileName?: string;
}

@Component({
    selector: 'ayac-file-attachments-preview',
    templateUrl: './file-attachments-preview.component.html',
    styleUrls: ['./file-attachments-preview.component.scss']
})
export class FileAttachmentsPreviewComponent extends UnsubscribeOnDestroy implements OnChanges {
    @Input() preview: FileAttachmentPreview;
    @Input() file: FileAttachment;
    @Input() isAttachmentPreviewLoading: boolean;

    @Output() pageChange = new EventEmitter<number>();
    @Output() download = new EventEmitter<FileAttachment>();
    @Output() close = new EventEmitter<unknown>();

    attachmentPreviewProxy$ = new BehaviorSubject<FileAttachmentPreview>(null);
    attachmentPreviewIsLoadingProxy$ = new BehaviorSubject<boolean>(false);

    constructor(private readonly dialogService: MatDialog) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.preview && !changes.preview.firstChange) {
            this.attachmentPreviewProxy$.next(changes.preview.currentValue);
        }

        if (changes.isAttachmentPreviewLoading && !changes.isAttachmentPreviewLoading.firstChange) {
            this.attachmentPreviewIsLoadingProxy$.next(changes.isAttachmentPreviewLoading.currentValue);
        }
    }

    showDialog(file?: FileAttachment) {
        const dialogRef = this.dialogService.open(FileAttachmentsViewerComponent, {
            width: '832px',
            data: {
                file: file || this.file,
                attachmentPreview$: this.attachmentPreviewProxy$,
                isAttachmentPreviewLoading$: this.attachmentPreviewIsLoadingProxy$
            }
        });

        dialogRef.componentInstance.onPageChange.pipe(takeUntil(this.d$)).subscribe((result) => {
            this.pageChange.emit(result);
        });

        dialogRef.componentInstance.onDownload.pipe(takeUntil(this.d$)).subscribe((result) => {
            this.download.emit(result);
        });

        dialogRef.afterClosed().subscribe(() => {
            this.close.emit();
        });
    }
}
