import { KEY_TERMS } from './terminology.constants';

const TERMS: string = Object.keys(KEY_TERMS)
    .filter((key) => typeof key === 'string')
    .join('|');

const FIND_AND_REPLACE_REGEX = new RegExp(`\\b(${TERMS})s?\\b`, 'gi');

export const findAndReplaceTerminology = (value?: string | null): string | null | undefined => {
    if (typeof value !== 'string') {
        return value;
    }

    return value.replace(FIND_AND_REPLACE_REGEX, (matched: string) => {
        let key = matched.toLowerCase();

        const isPlural = key.endsWith('s');
        if (isPlural) {
            key = key.slice(0, -1);
        }

        let translation = KEY_TERMS[key];
        if (isPlural && translation) {
            translation += 's';
        }

        if (matched[0] === matched[0].toUpperCase()) {
            translation = translation.charAt(0).toUpperCase() + translation.slice(1);
        }

        return translation;
    });
};
