import { createAction, props } from '@ngrx/store';
import { FacilityAssociation } from 'src/app/facilities/models/facility-association.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { PagingToken } from 'src/app/shared/models/paging-token';

export const loadFacilityAssociationsList = createAction(
    '[FACILITY ASSOCIATIONS COMPONENT] Load Facility Associations List'
);
export const loadFacilityAssociationsListSuccess = createAction(
    '[FACILITY ASSOCIATIONS COMPONENT] Load Facility Associations List Success',
    props<{ associations: PagingToken<FacilityAssociation[]> }>()
);
export const loadFacilityAssociationsListFailure = createAction(
    '[FACILITY ASSOCIATIONS COMPONENT] Load Facility Associations List Failure',
    props<{ error: any }>()
);
export const setFacilityAssociationsListSearchQuery = createAction(
    '[FACILITY ASSOCIATIONS COMPONENT] Set Facility Associations Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);
export const setFacilityAssociationsListSubcontractorsQuery = createAction(
    '[FACILITY ASSOCIATIONS COMPONENT] Set Facility Associations SubContractors Query',
    props<{ subContractorsOnly: boolean }>()
);
export const clearFacilityAssociationsListList = createAction(
    '[FACILITY ASSOCIATIONS COMPONENT] Clear Facility Associations List'
);
