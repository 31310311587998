import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridStateChangeEvent } from 'src/app/shared/grid/models';
import { VendorFacilityStatusCode } from '../../models/enums/vendor-facility-status-code.enum';
import { HospitalAssociationFacilitiesGridItem } from '../../models/hospital-association-facilities-grid-item.model';
import { FacilityAssociationsService } from '../../services/facility-associations.service';
import { GridDataResult } from 'src/app/shared/grid/models/grid-data-result.model';
import { GridComponent } from 'src/app/shared/grid/components/grid.component';
import { UntypedFormBuilder } from '@angular/forms';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { FacilitySystemsService } from 'src/app/admin/facility-systems/facility-systems.service';
import { HospitalSystemsVendorsGridItem } from '../../models';

@Component({
    selector: 'ayac-update-status-modal',
    templateUrl: './update-status-modal.component.html',
    styleUrls: ['./update-status-modal.component.scss']
})
export class UpdateStatusModalComponent implements OnInit {
    vendorCode = VendorFacilityStatusCode;
    form = this._formBuilder.group({
        note: ['']
    });
    currentDate = new Date();
    selectedRows: HospitalAssociationFacilitiesGridItem[] = [];
    cachedSelection: { index: number; data: HospitalAssociationFacilitiesGridItem[] }[] = [];

    loading = false;
    gridData: GridDataResult;
    pagination = {
        pageSize: 10,
        skip: 0
    };

    query = {
        take: this.pagination.pageSize,
        skip: this.pagination.skip
    };

    @ViewChild(GridComponent, { static: false }) childGrid: GridComponent;

    constructor(
        private readonly dialogRef: MatDialogRef<UpdateStatusModalComponent>,
        private readonly _facilitiesAssociationService: FacilityAssociationsService,
        private readonly _facilitiesSystemsService: FacilitySystemsService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            statusId: VendorFacilityStatusCode;
            selectedVendorIds: number[];
            facilityStatusIds: number[];
            systemId: number;
            fromComponent: 'associations' | 'system';
        },
        private readonly _formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.loading = true;
        this.selectedRows = [];
        this.cachedSelection = [];

        this.loadFacilityData();
    }

    get titleText() {
        switch (this.data.statusId) {
            case VendorFacilityStatusCode.Accepted:
                return 'Accept';
            case VendorFacilityStatusCode.Offered:
                return 'Offer';
            case VendorFacilityStatusCode.Excluded:
                return 'Exclude';
        }
    }

    get alertText() {
        return `These agencies were not ${
            VendorFacilityStatusCode[this.data.statusId]
        } due to the following existing statuses`;
    }

    onRowSelected(rows: HospitalAssociationFacilitiesGridItem[]) {
        this.selectedRows = rows;
    }

    onDataStateChange(state: GridStateChangeEvent): void {
        if (this.selectedRows.length) {
            // Check for cached entry
            const entry = this.cachedSelection.find((x) => x.index === this.pagination.skip);
            // If entry then add selection to cache else create new cache entry
            if (entry) {
                entry.data = this.selectedRows;
            } else {
                this.cachedSelection.push({
                    index: this.pagination.skip,
                    data: this.selectedRows
                });
            }
        }

        // If the page size changes then clear the selection
        if (this.pagination.pageSize !== state.take) {
            this.cachedSelection = [];
            state.skip = 0;
        }

        this.pagination.pageSize = state.take;
        this.pagination.skip = state.skip;

        this.childGrid?.clearSelection();
        this.loadFacilityData();
    }

    loadFacilityData(): void {
        this.loading = true;

        const filterArgs = {
            statusId: this.data.statusId,
            hospitalSystemId: this.data.systemId
        };

        if (this.data.fromComponent === 'associations') {
            this._facilitiesAssociationService
                .getAllFacilityAssociationFacilities(this.pagination, filterArgs)
                .pipe(first())
                .subscribe((res) => this.loadGridResults(res));
        } else if (this.data.fromComponent === 'system') {
            this._facilitiesSystemsService
                .getAllFacilitySystemsFacilities(this.pagination, filterArgs)
                .pipe(first())
                .subscribe((res) => this.loadGridResults(res));
        }
    }

    loadGridResults(
        results: PagingToken<HospitalSystemsVendorsGridItem[] | HospitalAssociationFacilitiesGridItem[]>
    ): void {
        this.gridData = results;
        this.loading = false;

        // Check if any results are cached selections
        if (this.cachedSelection && this.cachedSelection.length) {
            const cachedSelection = this.cachedSelection.find((x) => x.index === this.pagination.skip)?.data;
            if (cachedSelection) {
                this.selectedRows = cachedSelection;
            }
        }
    }

    override(): void {
        const facilityIds = this.cachedSelection
            .map((cs) => cs.data)
            .flat()
            .map((x) => x.facilityId)
            .concat(this.selectedRows.map((sf) => sf.facilityId));

        const vendorIds = this.cachedSelection
            .map((cs) => cs.data)
            .flat()
            .map((x) => x.vendorId)
            .concat(this.selectedRows.map((sf) => sf.vendorId))
            .filter((x, i, s) => s.indexOf(x) === i);

        if (!facilityIds || !facilityIds.length) {
            return;
        }

        const params = {
            statusId: this.data.statusId,
            facilityIds,
            vendorIds,
            note: this.form.controls.note.value || null
        };

        this.dialogRef.close(params);
    }
}
