import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {
    transform(plainText: string, target: string): string {
        return this.linkify(plainText, target);
    }

    private linkify(plainText: string, target: string): string {
        if (!plainText)
        {
            return "";
        }

        let replacedText: string;

        // URLs starting with http://, https://, or ftp://
        const replacePattern1 = /(http|ftp|https):(\/)*[\w-]+(\.[\w-]+)+([\w.,@$!*?^=%&amp;:\/~+#-]*)?/gi;
        replacedText = plainText.replace(
            replacePattern1,
            '<a target=' + target + ' href="$&">$&</a>'
        );

        // URLs starting with "www." (without // before it).
        const replacePattern2 = /(^|[^\/])(www)+(\.[\w-]+)+([\w.,@$!*?^=%&amp;:\/~+#-]*)?/gi;
        replacedText = replacedText.replace(
            replacePattern2,
            '<a target=' + target + ' href="http://$&">$&</a>'
        );

        //Change email addresses to mailto:: links.
        const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText.replace(/(\r\n|\n|\r)/g,"<br />");
    }
}
