/**angular */
import { Injectable } from '@angular/core';

/**ngrx and rxjs */
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';

/**local */
import * as actions from '../actions/system-contacts-profile.actions';
import * as selectors from '../selectors/system-contacts-profile.selectors';

import { SystemContactsService } from 'src/app/admin/system-contacts/services/system-contacts.service';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { SystemContact } from 'src/app/admin/system-contacts/models/system-contact.model';
import { SystemContactSave } from 'src/app/admin/system-contacts/models/system-contact-save.model';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { OrganizationInvitation } from 'src/app/shared/models/account/organization-invitation.model';
import { InvitationStatusEnum } from 'src/app/shared/models/enums/account/invitation-status.enum';

@Injectable()
export class SystemContactsProfileEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _store$: Store,
        private readonly _service: SystemContactsService,
        private readonly _lookupsService: LookupsService,
        private readonly _router: Router,
        private readonly _dialogService: DialogService
    ) {}

    defaultContact: SystemContact = {
        id: null,
        systemId: 0,
        firstName: '',
        lastName: '',
        userId: null,
        emailAddress: '',
        showEmailExternally: false,
        phone: '',
        phoneCountryCode: '',
        phoneAreaCode: '',
        phoneExchange: '',
        phoneStation: '',
        phoneExtension: '',
        note: null,
        systemContactTypeTags: []
    };

    createNewSystemContactAndLoadRelatedData$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.createNewSystemContactAndLoadRelatedData),
            exhaustMap(() => {
                return forkJoin({
                    systemContact: of(this.defaultContact),
                    hospitalSystems: this._lookupsService.getHospitalSystems(),
                    systemContactTypes: this._service.getSystemContactTypes(),
                    identityProviders: this._service.getIdentityProviders()
                }).pipe(
                    map((response) => actions.loadSystemContactAndRelatedDataSuccess(response)),
                    catchError((error: unknown) => of(actions.loadSystemContactAndRelatedDataFailure({ error })))
                );
            })
        );
    });

    getSystemContractsProfileAndRelatedData$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadSystemContactAndRelatedData),
            exhaustMap((action) => {
                return forkJoin({
                    systemContact: this._service.getSystemContact(action.systemContactId),
                    hospitalSystems: this._lookupsService.getHospitalSystems(),
                    systemContactTypes: this._service.getSystemContactTypes(),
                    identityProviders: this._service.getIdentityProviders()
                }).pipe(
                    map((response) => actions.loadSystemContactAndRelatedDataSuccess(response)),
                    catchError((error: unknown) => of(actions.loadSystemContactAndRelatedDataFailure({ error })))
                );
            })
        );
    });

    saveSystemContactsProfile$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.saveSystemContactsProfile),
            concatLatestFrom(() => this._store$.select(selectors.selectSystemContactsProfile)),
            exhaustMap(([action, contact]) => {
                return this._service.saveSystemContact(this.convertSystemContactToRequestModel(contact)).pipe(
                    map((response: number | null) => {
                        return actions.saveSystemContactsProfileSuccess({
                            systemContactId: response,
                            contact
                        });
                    }),
                    catchError((error: unknown) => {
                        return of(actions.saveSystemContactsProfileFailure({ error, contact }));
                    })
                );
            })
        );
    });

    updateSystemContactsProfile$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.updateSystemContactsProfile),
            switchMap((action: { systemContact: SystemContact }) => {
                return of(actions.updateSystemContactsProfileSuccess({ systemContact: action.systemContact })).pipe(
                    catchError((error: unknown) => of(actions.updateSystemContactsProfileFailure({ error })))
                );
            })
        );
    });

    showSuccessMessage$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveSystemContactsProfileSuccess),
                map((action) => {
                    const actionType = action.contact?.id ? 'updated' : 'added';
                    this._dialogService.openMigratedSnackBarSuccess(
                        `${action.contact.firstName} ${action.contact.lastName} was ${actionType} successfully!`
                    );
                })
            );
        },
        { dispatch: false }
    );

    showErrorMessage$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveSystemContactsProfileFailure),
                map((action) => {
                    this._dialogService.openMigratedSnackBarErrorFromErrorResponse(
                        action.error,
                        `Error occurred trying to update ${action.contact.firstName} ${action.contact.lastName}.`
                    );
                    return action;
                })
            );
        },
        { dispatch: false }
    );

    changeIdentityProvider$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.changeIdentityProvider),
            map((action) => {
                if (action.identityProvider) {
                    return actions.getInvitation({ identityProvider: action.identityProvider, refresh: false });
                } else {
                    return actions.getInvitationSuccess({ invitation: null });
                }
            })
        );
    });

    getInvitation$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.getInvitation),
            concatLatestFrom(() => this._store$.select(selectors.selectSystemContactsProfile)),
            exhaustMap(([action, contact]) => {
                const identityProvider = action.identityProvider;
                const userId = contact?.user?.coreUserId;
                if (!identityProvider) {
                    return of(actions.getInvitationSuccess({ invitation: null }));
                }
                if (!userId) {
                    return of(
                        actions.getInvitationSuccess({ invitation: { status: InvitationStatusEnum.InvitationNotSent } })
                    );
                }
                return this._service.getInvitation(userId, identityProvider).pipe(
                    map((response: OrganizationInvitation) => {
                        return actions.getInvitationSuccess({
                            invitation: response
                        });
                    }),
                    catchError((error: unknown) => of(actions.getInvitationFailure({ error })))
                );
            })
        );
    });

    getInvitationErrorMessage$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.getInvitationFailure),
                map((action) =>
                    this._dialogService.openMigratedSnackBarError(`Error occurred trying to get invitation`)
                )
            );
        },
        { dispatch: false }
    );

    sendInvitation$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.sendInvitaion),
            exhaustMap((action) => {
                if (!action.userId || !action.identityProvider) {
                    return of(
                        actions.sendInvitaionFailure({ error: `User Id or Identity Provider was not specified` })
                    );
                }
                return this._service.sendInvitation(action.userId, action.identityProvider).pipe(
                    mergeMap(() => [
                        actions.sendInvitaionSuccess(),
                        actions.getInvitation({ identityProvider: action.identityProvider, refresh: true })
                    ]),
                    catchError((error: unknown) => of(actions.sendInvitaionFailure({ error })))
                );
            })
        );
    });

    sendInvitationSuccess$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.sendInvitaionSuccess),
                map((action) =>
                    this._dialogService.openMigratedSnackBarSuccess(
                        `A new invitation has been successfully sent to the user.`
                    )
                )
            );
        },
        { dispatch: false }
    );

    sendInvitationErrorMessage$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.sendInvitaionFailure),
                map((action) =>
                    this._dialogService.openMigratedSnackBarError(`Error occurred trying to send invitation.`)
                )
            );
        },
        { dispatch: false }
    );

    routeToContacts$ = createEffect(
        () => {
            return this._actions$.pipe(
                ofType(actions.saveSystemContactsProfileSuccess),
                map(() => this._router.navigateByUrl('/admin/systemContacts'))
            );
        },
        { dispatch: false }
    );

    private convertSystemContactToRequestModel(systemContact: SystemContact): SystemContactSave {
        const result = {
            id: systemContact.id,
            firstName: systemContact.firstName,
            lastName: systemContact.lastName,
            userId: systemContact.userId,
            emailAddress: systemContact.emailAddress,
            showEmailExternally: systemContact.showEmailExternally,
            phoneCountryCode: systemContact.phoneCountryCode,
            phoneAreaCode: systemContact.phoneAreaCode,
            phoneExchange: systemContact.phoneExchange,
            phoneStation: systemContact.phoneStation,
            phoneExtension: systemContact.phoneExtension,
            note: systemContact.note,
            systemContactTypeTags: systemContact.systemContactTypeTags,
            user: systemContact.user,
            title: 'System Contact',
            systemId: systemContact.systemId
        } as SystemContactSave;
        return result;
    }
}
