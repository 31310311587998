import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getAllFacilitiesCount'
})
export class GetAllFacilitiesCountPipe implements PipeTransform {
    transform(facilities: any[], forUnitRule: boolean): number {
        if (!facilities || !facilities.length) {
            return 0;
        }

        return facilities.filter((facility) => {
            if (forUnitRule && facility.units === null) {
                return false; // Exclude facilities with no units if forUnitRule is true
            }
            return !facility.hasExistingProcess; // Include facilities without existing process
        }).length;
    }
}
