import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions/vendor-candidate-profile.acitons';
import {
    VendorCandidateProfileState,
    getInitialVendorCandidateProfileState
} from 'src/app/vendor/vendor-candidate-details/store/state/vendor-candidate-profile.state';
import { LoadingTypes } from 'src/app/shared/models';

export function vendorCandidateProfileReducer(state: VendorCandidateProfileState, action: Action) {
    return createReducer(
        getInitialVendorCandidateProfileState(),

        on(actions.sendInviteVendorСandidateShiftsApp, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING,
            sendEmailLoading: LoadingTypes.LOADING
        })),

        on(actions.sendInviteVendorСandidateShiftsAppSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,
            sendEmailLoading: LoadingTypes.LOADED
        })),

        on(actions.sendInviteVendorСandidateShiftsAppFailure, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            sendEmailLoading: LoadingTypes.FAIL,
            error: action.error
        })),

        on(actions.loadVendorCandidateShiftsApp, actions.loadSubmittalJobDetails, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(actions.loadVendorCandidateShiftsAppSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,
            inviteSent: action.data.inviteSent,
            canAccessShiftsApp: action.data.canAccess,
            isCredentialed: action.data.isCredentialed
        })),

        on(actions.loadVendorCandidateShiftsAppFailure, actions.loadSubmittalJobDetailsFailure, (state, action) => ({
            ...state,
            loading: LoadingTypes.FAIL,
            error: action.error
        })),

        on(actions.loadSubmittalJobDetailsSuccess, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,

            hospitalTypeId: action.hospitalTypeId
        })),

        on(actions.clearSubmittalDetails, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED,

            hospitalTypeId: null
        }))
    )(state, action);
}

export function reducer(state: VendorCandidateProfileState | undefined, action: Action) {
    return vendorCandidateProfileReducer(state, action);
}
