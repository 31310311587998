import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, Settings } from '../../config/settings';
import { SortTypes } from 'src/app/shared/models';
import { interpolateParams } from 'src/app/core/utils/interpolate-params';
import { Observable } from 'rxjs';
import { Shift } from 'src/app/shared/models/shifts/shift.model';
import { interpolateUrl } from 'src/app/core/utils';
import { ShiftEditModel } from 'src/app/shared/models/shifts/shift-edit.model';
import { ShiftDetails } from 'src/app/shared/models/shifts/shift-details.model';
import { ClientLookups } from 'src/app/shared/models/shifts/client-lookups.model';
import { CancelByFacilityModel } from 'src/app/shared/models/shifts/cancel-by-facility.model';
import { PagingToken } from 'src/app/shared/models/paging-token';
import {
    ShiftsLookups,
    BulkShift,
    ShiftAddResponse,
    InternalPoolResouceAvailability
} from 'src/app/shared/models/shifts';
import { ShiftHistory } from 'src/app/shared/models/shifts/shift-history.model';
import { InternalPoolShiftEditModel } from 'src/app/shared/models/internal-pool/internal-pool-shift-edit.model';
import { FullShiftEditModel } from 'src/app/shared/models/shifts/full-shift-edit.model';
import { ResourceList } from 'src/app/shared/models/internal-pool/resource-list.model';
import { BookedCandidates, ConsolidatedShiftSubmittals, ReleaseHistory, ShiftSubmittalCandidate } from '../models';
import { FullEmailVm } from 'src/app/shared/models/full-email-vm.model';
import { ApprovalStatus } from 'src/app/approvals/models/approvals-dto.enums';
import { FacilityAddressInfos } from 'src/app/shifts/models/shift-facilityAddressInfo.model';
import { ShiftListItem } from '../models/shift-list-item.model';
import { renameKeys } from 'src/app/core/utils/rename-keys';
import { combine } from 'src/app/shared/utilities';
import { FacilityLookup } from 'src/app/shared/models/lookups/facility-lookup.model';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';

export enum ShiftsClientUrls {
    ALL_SHIFTS = '/AyaConnect/Client/Shifts/all',
    SHIFTS_EXPORT = '/AyaConnect/Client/Shifts/export',
    SHIFTS_LOOKUP = '/AyaConnect/PerDiemSchedulerClient/GetLookups?ViewTypeId=3',
    SHIFT_DETAILS = '/AyaConnect/Client/Shifts/{shiftId}',
    SHIFT_SERIES = '/AyaConnect/Client/Shifts/Series/{seriesId}/shifts/{shiftId}',
    UPDATE_SHIFT = '/AyaConnect/Client/Shifts',
    UPDATE_SHIFT_SERIES = '/AyaConnect/Client/Shifts/series',
    UPDATE_SINGLE_SHIFT_SERIES = '/AyaConnect/Client/Shifts/series/',
    UPDATE_AND_ASSIGN_SHIFT = '/AyaConnect/Client/Shifts/Assign/',
    ADD_SHIFT = '/AyaConnect/Client/Shifts',
    ADD_BLOCK_BOOKING_SHIFT = '/AyaConnect/Client/Shifts/Add',
    ADD_SHIFTS = '/AyaConnect/Client/Shifts/Bulk',
    CLIENT_LOOKUPS = '/AyaConnect/Client/Lookups',
    COMBINED_HISTORY = '/AyaConnect/Client/Shifts',
    CANCEL_SHIFT_BY_FACILITY = '/AyaConnect/Client/Shifts/cancelShiftByFacility',
    CANCEL_SHIFT_SERIES = '/AyaConnect/Client/Shifts/cancel-series',
    CANCEL_SINGLE_SHIFT_SERIES = '/AyaConnect/Client/Shifts/cancel-single-shift-series',
    RECONFIRM = '/AyaConnect/Client/Shifts/{id}/Reconfirm',
    CONFIRM = '/AyaConnect/Client/Shifts/{id}/Confirm',
    CONFIRM_SERIES = '/AyaConnect/Client/Shifts/Series/{id}/Confirm',
    BULK_SHIFT_SERIES = '/AyaConnect/Client/Shifts/Bulk-Series/{jobNumber}',
    REOPEN = '/AyaConnect/Client/Shifts/Reopen',
    APPROVAL_ACTIONS = '/AyaConnect/Client/Shifts/approval-statuses',
    RESOURCES = '/AyaConnect/Client/Shifts/{systemId}/Resources',
    RESOURCE_DETAILS = '/AyaConnect/Internalpool/Resources/',
    SUBMITTALS = '/AyaConnect/Client/Shifts/{id}/Submittals',
    Booked = '/AyaConnect/Client/Shifts/{id}/Booked-Candidates',
    UPDATE_SUBMITTAL_STATUS = '/AyaConnect/Client/Submittals/{submittalId}/Shift/Statuses/{id}',
    SUBMIT_CANDIDATES = '/AyaConnect/Client/Submittals/Shifts',
    SERIES_CANDIDATES = '/AyaConnect/Client/Submittals/Series',
    SHIFT_SUBMITTAL_CANDIDATES = '/AyaConnect/Client/Shifts/{systemId}/Candidates/{shiftId}',
    SERIES_SUBMITTAL_CANDIDATES = '/AyaConnect/Client/Shifts/{systemId}/Candidates/Series/{seriesId}',
    SUBMITTALS_EMAIL_OFFER = '/AyaConnect/Client/Submittals/{submittalId}/Shift/Offer-Email',
    SHIFT_FACILITY_ADDRESS = '/AyaConnect/Facility/{facilityId}/address',
    SHIFT_RELEASE_HISTORY = '/AyaConnect/Client/Shifts/{shiftId}/release-history',
    CURRENT_SHIFTS = '/AyaConnect/PerDiemScheduler/Vendor/Candidate/CurrentShifts',
    AVAILABLE_SHIFTS = '/AyaConnect/PerDiemScheduler/Vendor/Candidate/AvailableShifts',
    REDIRECT_SHIFT = '/AyaConnect/Client/ShiftRedirect/{shiftId}/',
    SHIFT_FACILITIES_AGENCIES = '/AyaConnect/Client/ShiftRedirect/perdiem/{shiftId}/shift-facilities',
}

@Injectable({
    providedIn: 'root'
})
export class ShiftsService {

    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly settings: Settings) {}

    getLookups(): Observable<ShiftsLookups> {
        const url = `${ShiftsClientUrls.SHIFTS_LOOKUP}`;
        return this.http.get<ShiftsLookups>(`${this.settings.CORE}${url}`);
    }

    getClientLookups(): Observable<ClientLookups> {
        const url = `${this.settings.CORE}${ShiftsClientUrls.CLIENT_LOOKUPS}`;
        return this.http.get<ClientLookups>(url);
    }

    getShifts(
        pagination: {
            pageSize: number;
            skip: number;
        },
        filterArgs: {
            candidateName: string;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },

        matchArgs?: {
            [key: string]: string;
        }
    ) {
        let facilityIds = null;

        if (matchArgs?.facilitySearchIds.length > 1) {
            facilityIds = matchArgs.facilitySearchIds;
            matchArgs.facilitySearchIds = null;
        } else {
            facilityIds = [];
        }
        const params = interpolateParams({
            ...pagination,
            ...filterArgs,
            ...sortArgs,
            ...matchArgs
        });
        const url = `${ShiftsClientUrls.ALL_SHIFTS}`;
        return this.http.post<PagingToken<Shift[]>>(`${this.settings.CORE}${url}`, facilityIds, { params: params });
    }

    getShiftsForExport(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        const params = interpolateParams({
            ...pagination,
            ...sortArgs,
            ...matchArgs
        });
        const url = `${ShiftsClientUrls.SHIFTS_EXPORT}`;
        return this.http.get(`${this.settings.CORE}${url}`, { params, responseType: 'blob' });
    }

    getBulkShifts(jobNumber: number): Observable<BulkShift[]> {
        const urlParams = {
            jobNumber: jobNumber
        };
        const url = interpolateUrl(ShiftsClientUrls.BULK_SHIFT_SERIES, urlParams);
        return this.http.get<BulkShift[]>(`${this.settings.CORE}${url}`);
    }

    getShiftDetails(id: number): Observable<ShiftDetails> {
        const urlParams = {
            shiftId: id
        };
        const url = interpolateUrl(ShiftsClientUrls.SHIFT_DETAILS, urlParams);
        return this.http.get<ShiftDetails>(`${this.settings.CORE}${url}`);
    }

    getShiftFacilityAddressInfo(faciltyId: number): Observable<FacilityAddressInfos> {
        const urlParams = {
            facilityId: faciltyId
        };
        const url = interpolateUrl(ShiftsClientUrls.SHIFT_FACILITY_ADDRESS, urlParams);
        return this.http.get<FacilityAddressInfos>(`${this.settings.CORE}${url}`);
    }

    getSeriesDetails(seriesId: string, shiftId: number): Observable<ShiftDetails> {
        const urlParams = {
            seriesId,
            shiftId
        };
        const url = interpolateUrl(ShiftsClientUrls.SHIFT_SERIES, urlParams);
        return this.http.get<ShiftDetails>(`${this.settings.CORE}${url}`);
    }

    addShift(shift: FullShiftEditModel): Observable<ShiftAddResponse> {
        return this.http.post<ShiftAddResponse>(`${this.settings.CORE}${ShiftsClientUrls.ADD_SHIFT}`, shift);
    }

    addShiftSeries(shift: FullShiftEditModel): Observable<ShiftAddResponse> {
        return this.http.post<ShiftAddResponse>(
            `${this.settings.CORE}${ShiftsClientUrls.ADD_BLOCK_BOOKING_SHIFT}`,
            shift
        );
    }

    addShifts(shifts: ShiftEditModel[]): Observable<ShiftDetails[]> {
        return this.http.post<ShiftDetails[]>(`${this.settings.CORE}${ShiftsClientUrls.ADD_SHIFTS}`, shifts);
    }

    updateShift(shift: ShiftEditModel): Observable<ShiftDetails> {
        return this.http.put<ShiftDetails>(`${this.settings.CORE}${ShiftsClientUrls.UPDATE_SHIFT}`, shift);
    }

    updateBlockBookingShift(shift: ShiftEditModel): Observable<ShiftDetails> {
        return this.http.put<ShiftDetails>(`${this.settings.CORE}${ShiftsClientUrls.UPDATE_SHIFT_SERIES}`, shift);
    }

    updateSingleShiftSeries(shift: ShiftEditModel): Observable<ShiftDetails> {
        return this.http.put<ShiftDetails>(
            `${this.settings.CORE}${ShiftsClientUrls.UPDATE_SINGLE_SHIFT_SERIES}${shift.seriesId}`,
            shift
        );
    }

    updateAndAssignShift(shift: InternalPoolShiftEditModel): Observable<ShiftDetails> {
        return this.http.put<ShiftDetails>(`${this.settings.CORE}${ShiftsClientUrls.UPDATE_AND_ASSIGN_SHIFT}`, shift);
    }

    getShiftCombinedHistory(shiftId: number): Observable<Array<ShiftHistory>> {
        const url = `${ShiftsClientUrls.COMBINED_HISTORY}/${shiftId}/history`;
        return this.http.get<Array<ShiftHistory>>(`${this.settings.CORE}${url}`);
    }

    cancelShiftByFacility(model: CancelByFacilityModel): Observable<CancelByFacilityModel> {
        return this.http.post<CancelByFacilityModel>(
            `${this.settings.CORE}${ShiftsClientUrls.CANCEL_SHIFT_BY_FACILITY}`,
            model
        );
    }

    cancelShiftSeries(model: {
        shiftId: number;
        seriesId: string;
        perdiemReasonCode: number;
        other?: string;
        shiftType: 'single' | 'series';
    }): Observable<unknown> {
        let url = '';
        if (model.shiftType === 'single') {
            url = `${this.settings.CORE}${ShiftsClientUrls.CANCEL_SINGLE_SHIFT_SERIES}`;
        } else {
            url = `${this.settings.CORE}${ShiftsClientUrls.CANCEL_SHIFT_SERIES}`;
        }
        return this.http.post(url, model);
    }

    reconfirm(id: number): Observable<any> {
        const urlParams = {
            id: id
        };

        const url = interpolateUrl(ShiftsClientUrls.RECONFIRM, urlParams);
        return this.http.put<any>(`${this.settings.CORE}${url}`, {});
    }

    getFacilitiesAgenciesByShift(shiftId:number):Observable<FacilityLookup[]> {

        var url = ShiftsClientUrls.SHIFT_FACILITIES_AGENCIES.replace('{shiftId}', shiftId.toString());
        return this.http.get<Array<FacilityLookup>>(`${this.settings.CORE}${url}`);
    }

    confirm(id: number): Observable<any> {
        var url = ShiftsClientUrls.CONFIRM.replace('{id}', id.toString());
        return this.http.put<any>(`${this.settings.CORE}${url}`, id);
    }

    redirect(id: number, facilityId: number, unitId?: number, comments?: string): Observable<any> {
        var url = ShiftsClientUrls.REDIRECT_SHIFT.replace('{shiftId}', id.toString());

        return this.http.post<any>(`${this.settings.CORE}${url}`, {
            facilityId,
            unitId,
            comments
        });
    }


    confirmSeries(id: string): Observable<any> {
        var url = ShiftsClientUrls.CONFIRM_SERIES.replace('{id}', id);
        return this.http.put<any>(`${this.settings.CORE}${url}`, id);
    }

    reopen(id: number): Observable<unknown> {
        const url = ShiftsClientUrls.REOPEN;
        const body = {
            ids: [id]
        };
        return this.http.put(`${this.settings.CORE}${url}`, body);
    }

    approve(ids: number[], seriesId: string | null, comment: string | null): Observable<unknown> {
        return this.takeApprovalAction(ids, seriesId, comment, ApprovalStatus.Approved);
    }

    reject(ids: number[], seriesId: string | null, comment: string | null): Observable<unknown> {
        return this.takeApprovalAction(ids, seriesId, comment, ApprovalStatus.Rejected);
    }

    getResources(systemId: number, request: InternalPoolResouceAvailability) {
        const url = interpolateUrl(ShiftsClientUrls.RESOURCES, { systemId });

        return this.http.post<PagingToken<ResourceList[]>>(`${this.settings.CORE}${url}`, request);
    }

    getResourceDetails(candidateId: number, systemId: number):  Observable<Resource> {
        const url = `${ShiftsClientUrls.RESOURCE_DETAILS}`;
        return this.http.get<Resource>(`${this.settings.CORE}${url}${systemId}/Resources/${candidateId}`);

    }

    getSubmittals(shiftId: number): Observable<ConsolidatedShiftSubmittals> {
        const url = interpolateUrl(ShiftsClientUrls.SUBMITTALS, { id: shiftId });
        return this.http.get<ConsolidatedShiftSubmittals>(`${this.settings.CORE}${url}`);
    }

    getBookedCandidates(shiftId: number): Observable<Array<BookedCandidates>> {
        const url = interpolateUrl(ShiftsClientUrls.Booked, { id: shiftId });
        return this.http.get<BookedCandidates[]>(`${this.settings.CORE}${url}`);
    }

    updateSubmitStatus(submittalId: number, status: number, note: string, declineReasonId?: number) {
        const url = interpolateUrl(ShiftsClientUrls.UPDATE_SUBMITTAL_STATUS, { submittalId: submittalId, id: status });
        const body = {
            note,
            declineReasonId
        };

        return this.http.put<unknown>(`${this.settings.CORE}${url}`, body);
    }

    getShiftSubmittalCandidates(
        systemId: number,
        shiftId: number | string
    ): Observable<PagingToken<ShiftSubmittalCandidate[]>> {
        const url =
            typeof shiftId === 'number'
                ? interpolateUrl(ShiftsClientUrls.SHIFT_SUBMITTAL_CANDIDATES, { systemId, shiftId })
                : interpolateUrl(ShiftsClientUrls.SERIES_SUBMITTAL_CANDIDATES, { systemId, seriesId: shiftId });

        return this.http.get<PagingToken<ShiftSubmittalCandidate[]>>(`${this.settings.CORE}${url}`);
    }

    submitCandidates(shiftId: number | string, seriesId: string, canidatesIds: number[]) {
        const url = seriesId ? ShiftsClientUrls.SERIES_CANDIDATES : ShiftsClientUrls.SUBMIT_CANDIDATES;
        const body = {
            shiftId: shiftId,
            seriesId: seriesId,
            candidateIds: canidatesIds
        };

        return this.http.post<unknown>(`${this.settings.CORE}${url}`, body);
    }

    getSubmittalEmailOffer(submittalId: number): Observable<FullEmailVm> {
        const url = interpolateUrl(ShiftsClientUrls.SUBMITTALS_EMAIL_OFFER, { submittalId: submittalId });
        return this.http.get<FullEmailVm>(`${this.settings.CORE}${url}`);
    }

    getShiftReleaseHistory(shiftId: number): Observable<ReleaseHistory[]> {
        const url = interpolateUrl(ShiftsClientUrls.SHIFT_RELEASE_HISTORY, { shiftId });
        return this.http.get<ReleaseHistory[]>(`${this.settings.CORE}${url}`);
    }

    getCurrentShifts(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<PagingToken<ShiftListItem[]>> {
        const params = this.getGridParameters(pagination, sortArgs, matchArgs);
        const url = combine(this.settings.CORE, ShiftsClientUrls.CURRENT_SHIFTS);

        return this.http.get<PagingToken<ShiftListItem[]>>(url, { params });
    }

    getAvailableShifts(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<PagingToken<ShiftListItem[]>> {
        const params = this.getGridParameters(pagination, sortArgs, matchArgs);
        const url = combine(this.settings.CORE, ShiftsClientUrls.AVAILABLE_SHIFTS);

        return this.http.get<PagingToken<ShiftListItem[]>>(url, { params });
    }

    private takeApprovalAction(
        ids: number[],
        seriesId: string,
        comment: string,
        approvalStatus: ApprovalStatus
    ): Observable<unknown> {
        const url = ShiftsClientUrls.APPROVAL_ACTIONS;
        const body = {
            shiftIds: ids,
            seriesId: seriesId,
            userApprovalStatus: approvalStatus,
            comment: comment
        };
        return this.http.put(`${this.settings.CORE}${url}`, body);
    }

    private getGridParameters(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): any {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgsObj
        });

        return params;
    }

    getShiftSeries(seriesId: string): Observable<ShiftDetails> {
        return this.http.get<ShiftDetails>(`${this.settings.CORE}/AyaConnect/Client/Shifts/Series/${seriesId}`);
    }
}
