import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { PerdiemSchedulerService } from '../../services/perdiemscheduler.service';
import {
    loadSpecialties,
    loadSpecialtiesSuccess,
    loadSpecialtiesFail,
    componentLoaded
} from '../perdiemscheduler.actions';

@Injectable()
export class PerdiemSchedulerSpecialtiesEffect extends BaseEffect {
    getSpecialtiesList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadSpecialties, componentLoaded),
            switchMap((action) =>
                this.shiftRepository.getNurseSpecialties(action.professionId).pipe(
                    map((response) =>
                        loadSpecialtiesSuccess({
                            specialties: response
                        })
                    ),
                    catchError((error: unknown) => of(loadSpecialtiesFail({ error })))
                )
            )
        );
    });

    getSpecialtiesListFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadSpecialtiesFail),
                map((action) => action.error),
                tap((error: HttpErrorResponse) => this.handleError(error))
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly actions$: Actions,
        private readonly shiftRepository: PerdiemSchedulerService,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
