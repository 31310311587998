import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { LoadingTypes } from 'src/app/shared/models';

export const selectFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectVendorProfileLocations = createSelector(selectFeature, (state) => state.locationsState.locations);

export const selectVendorProfileLocationsIsLoading = createSelector(
    selectFeature,
    (state) => state.locationsState.loading === LoadingTypes.LOADING
);

export const selectLocationTypes = createSelector(selectFeature, (state) => state.locationsState.locationTypes);

export const selectVendorProfileLocationsToAdd = createSelector(selectVendorProfileLocations, (locations) =>
    locations.filter((item) => item.id <= 0 && !item.isDeleted)
);

export const selectVendorProfileLocationsToUpdate = createSelector(selectVendorProfileLocations, (locations) =>
    locations.filter((item) => item.id > 0 && !item.isDeleted)
);

export const selectVendorProfileLocationsToDelete = createSelector(selectVendorProfileLocations, (locations) =>
    locations.filter((item) => item.id > 0 && item.isDeleted)
);

export const selectHasChanges = createSelector(selectFeature, (state) => state.locationsState.hasChanges);

export const selectIsValid = createSelector(selectFeature, (state) => state.locationsState.isValid);

export const selectLocationsWithChanges = createSelector(
    selectHasChanges,
    selectVendorProfileLocations,
    selectIsValid,
    (hasChanges, locations, isValid) => {
        return {
            hasChanges,
            locations,
            isValid
        };
    }
);
