import { Candidate } from 'src/app/shared/models/candidate';
import { DocumentAttribute } from '../models/document-attribute';
import { DocumentAttributeField } from '../models/document-attribute-field';
import { LoadingTypes } from 'src/app/shared/models';

export interface VendorCandidateRequirementDocumentUploadState {
    candidate: Candidate;
    documentPreviewUploadLoading: LoadingTypes;
    documentPreviewDownloadLoading: LoadingTypes;
    metadataFieldsLoading: LoadingTypes;
    filteredMetadataFieldsLoading: LoadingTypes;
    setDocumentAttributesLoading: LoadingTypes;
    saveDocumentMetadataCalled: boolean;
    saveDocumentMetadataLoading: LoadingTypes;
    deleteDocumentCalled: boolean;
    deleteDocumentLoading: LoadingTypes;
    deleteDocumentCalledWithNavigation: boolean;
    deleteDocumentCalledWithNavigationSuccess: boolean;
    documentAttributeFields: DocumentAttributeField[];
    documentAttributes: DocumentAttribute[];
    error: string;
    candidateId: number;
    candidateOldUserId: number;
    contractId: number;
    docTypeId: number;
    candidateDocumentId: number;
    isValid: boolean;
    docReviewerExpirationDate: string;
    file: Blob;
    documentMetadataInputForSave: any;
}

export const getInitialVendorCandidateRequirementDocumentUploadState =
    (): VendorCandidateRequirementDocumentUploadState => ({
        candidate: null,
        documentPreviewUploadLoading: LoadingTypes.INIT,
        documentPreviewDownloadLoading: LoadingTypes.INIT,
        metadataFieldsLoading: LoadingTypes.INIT,
        filteredMetadataFieldsLoading: LoadingTypes.INIT,
        setDocumentAttributesLoading: LoadingTypes.INIT,
        saveDocumentMetadataCalled: false,
        saveDocumentMetadataLoading: LoadingTypes.INIT,
        deleteDocumentCalled: false,
        deleteDocumentLoading: LoadingTypes.INIT,
        deleteDocumentCalledWithNavigation: false,
        deleteDocumentCalledWithNavigationSuccess: false,
        documentAttributeFields: [],
        documentAttributes: [],
        error: null,
        candidateId: -999,
        candidateOldUserId: -999,
        contractId: -999,
        docTypeId: -999,
        candidateDocumentId: -999,
        isValid: false,
        docReviewerExpirationDate: null,
        file: null,
        documentMetadataInputForSave: null
    });
