import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import {
    loadPendingExpenses,
    loadPendingExpensesFail,
    loadPendingExpensesSuccess
} from '../actions/pending-expenses.actions';

export interface PendingExpensesState {
    totalCount: number;
    agingCount: number;
    agingDays: number;
    loading: LoadingTypes;
    error: string;
}

export const initialPendingExpensesState: PendingExpensesState = {
    totalCount: 0,
    agingCount: 0,
    agingDays: 0,
    loading: LoadingTypes.INIT,
    error: ''
};

export const pendingExpensesReducer = (expenseState: PendingExpensesState, expenseAction: Action) =>
    createReducer(
        initialPendingExpensesState,
        on(
            loadPendingExpenses,
            (state): PendingExpensesState => ({
                ...state,
                loading: LoadingTypes.LOADING
            })
        ),
        on(
            loadPendingExpensesSuccess,
            (state, action): PendingExpensesState => ({
                ...state,
                totalCount: action.totalCount,
                agingCount: action.agingCount,
                agingDays: action.agingDays,
                loading: LoadingTypes.LOADED
            })
        ),
        on(
            loadPendingExpensesFail,
            (state, action): PendingExpensesState => ({
                ...state,
                error: action.error,
                loading: LoadingTypes.FAIL
            })
        )
    )(expenseState, expenseAction);

export const reducer = (state: PendingExpensesState | undefined, action: Action) =>
    pendingExpensesReducer(state, action);
