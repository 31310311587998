import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ProviderCoverage as ProviderCoverage, Unit, UnitFacilityInformation } from 'src/app/clinical/models';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { refreshDisableEnableState } from 'src/app/shared/utilities/form-disabler';

@Component({
    selector: 'ayac-provider-coverage',
    templateUrl: './provider-coverage.component.html',
    styleUrls: ['./provider-coverage.component.scss']
})
export class ProviderCoverageComponent extends UnsubscribeOnDestroy implements OnChanges {
    @Output() valueChanged = new EventEmitter<Partial<Unit>>();

    providerCoverageForm: UntypedFormGroup;

    get providerCoverages(): UntypedFormArray {
        return this.providerCoverageForm.get('providerCoverages') as UntypedFormArray;
    }

    @Input() set unitInfo(unitInfo: UnitFacilityInformation) {
        if (!unitInfo.unit) {
            return;
        }

        this.setFormValues(unitInfo);

        this.watchFormChanges();
    }
    @Input() readOnly = false;

    @Input() isPrinting: boolean = false;

    constructor(private readonly _formBuilder: UntypedFormBuilder, private readonly _store: Store) {
        super();
        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readOnly) {
            refreshDisableEnableState(this.providerCoverageForm, changes.readOnly.currentValue);
            this.providerCoverages.controls.forEach((providerCoverageForm: UntypedFormGroup) => {
                const isMatched = providerCoverageForm.get('selected').value;
                refreshDisableEnableState(providerCoverageForm, !isMatched || changes.readOnly.currentValue, [
                    'inHouse',
                    'text'
                ]);
            });
        }
    }

    buildForm(): void {
        this.providerCoverageForm = this._formBuilder.group({
            providerCoverages: this._formBuilder.array([])
        });
    }

    setFormValues(unitInfo: UnitFacilityInformation): void {
        unitInfo?.providerCoverage?.forEach((providerCoverage: ProviderCoverage) => {
            const matched = unitInfo.unit?.providerCoverage?.find(
                (ps) => ps.providerCoverageId === providerCoverage.id
            );

            this.providerCoverages.push(
                this._formBuilder.group({
                    id: this._formBuilder.control(providerCoverage.id),
                    title: this._formBuilder.control(providerCoverage.title),
                    sort: this._formBuilder.control(providerCoverage.sort),
                    selected: this._formBuilder.control(matched ? true : false),
                    inHouse: this._formBuilder.control(matched?.inHouse),
                    text: this._formBuilder.control(matched?.text),
                    providerCoverageId: this._formBuilder.control(providerCoverage.id)
                })
            );

            this.providerCoverages.controls.forEach((providerCoverage: UntypedFormGroup) => {
                providerCoverage
                    .get('selected')
                    .valueChanges.pipe(takeUntil(this.d$))
                    .subscribe((value) => {
                        refreshDisableEnableState(providerCoverage, !value || this.readOnly, ['inHouse', 'text']);
                    });
            });
        });
    }

    getFormValues(): Partial<Unit> {
        const formArray: UntypedFormArray = this.providerCoverageForm.get('providerCoverages') as UntypedFormArray;
        const providerCoverages: ProviderCoverage[] = [];

        formArray.controls.forEach((providerCoverage) => {
            if (providerCoverage.get('selected').value === true) {
                providerCoverages.push({
                    id: providerCoverage.get('id').value,
                    title: providerCoverage.get('title').value,
                    sort: providerCoverage.get('sort').value,
                    inHouse: providerCoverage.get('inHouse').value,
                    text: providerCoverage.get('text').value,
                    providerCoverageId: providerCoverage.get('providerCoverageId').value
                });
            }
        });

        const unit: Partial<Unit> = {
            providerCoverage: providerCoverages
        };

        return unit;
    }

    watchFormChanges(): void {
        this.providerCoverageForm.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            this.valueChanged.emit(this.getFormValues());
        });
    }
}
