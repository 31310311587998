import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import { loadJobsLinesOfBusiness, loadJobsLinesOfBusinessSucesss, loadJobsLinesOfBusinessFail } from 'src/app/lookups/state/actions';

export interface JobsLinesOfBusinessState {
    linesOfBusiness: Array<ListItem>;
    loading: LoadingTypes;
    error: unknown;
}

export const initialJobsLinesOfBusinessState: JobsLinesOfBusinessState = {
    linesOfBusiness: [],
    loading: LoadingTypes.INIT,
    error: null
}

export function jobsLinesOfBusinessReducer(state: JobsLinesOfBusinessState, action: Action) {
    return createReducer(
        initialJobsLinesOfBusinessState,
        on(loadJobsLinesOfBusiness, (state, action) => (
            {
                ...state,
                loading: LoadingTypes.LOADING
            }
        )),
        on(loadJobsLinesOfBusinessSucesss, (state, action) => (
            {
				...state,
                linesOfBusiness: action.linesOfBusiness,
				loading: LoadingTypes.LOADED
			}
        )),
        on(loadJobsLinesOfBusinessFail, (state, action) => (
			{
                ...state,
                error: action.error,
				loading: LoadingTypes.FAIL
			}
		))
    )(state, action);
}

export function reducer(state: JobsLinesOfBusinessState | undefined, action: Action) {
    return jobsLinesOfBusinessReducer(state, action);
}
