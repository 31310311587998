/**angular */
import { Component, Input, OnInit, ViewChild } from '@angular/core';

/**ngrx rxjs */
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap, take, takeUntil } from 'rxjs/operators';

/**shared */
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ListItem } from 'src/app/shared/models/list-item';
import { GridComponent } from 'src/app/shared/grid/components/grid.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UnsubscribeOnDestroy } from 'src/app/core/utils/unsubscribe-ondestroy';

/**local */
import * as vendorsActions from 'src/app/admin/store/actions/facility-systems.actions';
import * as vendorsSelectors from 'src/app/admin/store/selectors/facility-systems.selectors';
import { ExcludeVendorModalComponent } from 'src/app/facilities/facility-details/facility-vendors/exclude-vendor-modal/exclude-vendor-modal.component';
import { OfferAcceptVendorModalComponent } from 'src/app/facilities/facilities-shared/modals/offer-accept-vendor-modal/offer-accept-vendor-modal.component';
import {
    FACILITY_STATUS_IDS,
    VendorFacilityStatusCode
} from 'src/app/facilities/models/enums/vendor-facility-status-code.enum';
import { FacilitySystemsService } from 'src/app/admin/facility-systems/facility-systems.service';
import { UpdateStatusModalComponent } from 'src/app/facilities/facilities-shared/update-status-modal/update-status-modal.component';
import { HospitalSystemsVendorsGridItem, HospitalAssociationVendorsGridItem } from 'src/app/facilities/models';
import { getInitialGridState } from '../store/state/facility-systems.state';

@Component({
    selector: 'ayac-facility-systems',
    templateUrl: './facility-systems.component.html',
    styleUrls: ['./facility-systems.component.scss']
})
export class FacilitySystemsComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild(GridComponent, { static: true }) grid: GridComponent;

    hospSystemId!: number;
    active!: boolean;
    selectedRows: HospitalSystemsVendorsGridItem[] = [];
    vendorStatus = VendorFacilityStatusCode;
    isAllSelected = false;
    hospitalSystemFacilities: HospitalSystemsVendorsGridItem[] = [];
    vendors$: Observable<GridDataResult>;
    isLoading$: Observable<boolean>;
    searchQuery$: Observable<GridSearchQuery>;
    statesLookup$: Observable<ListItem[]>;
    currentCount = 0;
    totalCount = 0;
    yesNoOptions = [
        { name: 'Yes', value: true },
        { name: 'No', value: false }
    ];

    constructor(
        private readonly _store: Store,
        private readonly _dialogService: DialogService,
        private readonly _facilitiesSystemsService: FacilitySystemsService
    ) {
        super();
    }

    @Input() set hospitalSystemId(id: number) {
        if (id) {
            this.hospSystemId = id;
        }
    }

    @Input() set isActive(active: boolean) {
        this.active = active;
    }

    ngOnInit(): void {
        this.initialize();
    }

    initialize(): void {
        this.vendors$ = this._store.select(vendorsSelectors.selectFacilitySystemsVendors).pipe(
            tap((result: { total: number; nonFilteredTotal: number; data: HospitalAssociationVendorsGridItem[] }) => {
                this.currentCount = result.total;
                this.totalCount = result.nonFilteredTotal;
            })
        );
        this.isLoading$ = this._store.select(vendorsSelectors.selectFacilitySystemsVendorsIsLoading);
        this.searchQuery$ = this._store.select(vendorsSelectors.selectFacilitySystemsVendorsSearchQuery);
        this.statesLookup$ = this._store.select(vendorsSelectors.selectStatesLookup);

        this._store.dispatch(
            vendorsActions.loadFacilitySystemsVendors({
                hospitalSystemId: this.hospSystemId,
                isActive: this.active
            })
        );
        this._store.dispatch(vendorsActions.loadVendorListStates());
    }

    onDataStateChange(state: GridStateChangeEvent): void {
        this._store.dispatch(
            vendorsActions.setFacilitySystemsVendorsSearchQuery({
                searchQuery: state,
                hospitalSystemId: this.hospSystemId,
                isActive: this.active
            })
        );
    }

    onRowSelected(rows: HospitalAssociationVendorsGridItem[]): void {
        this.selectedRows = rows;
    }

    exportAll() {
        this._store.dispatch(
            vendorsActions.exportAllFacilitySystemsVendors({
                hospitalSystemId: this.hospSystemId,
                isActive: this.active
            })
        );
    }

    exportSelected() {
        const vendorIds = this.selectedRows.map((row) => row.vendorId);
        this._store.dispatch(
            vendorsActions.exportSelectedFacilitySystemsVendors({
                hospitalSystemId: this.hospSystemId,
                vendorIds,
                isActive: this.active
            })
        );
    }

    refreshGrid() {
        this.grid.clearSelection();
        this._store.dispatch(
            vendorsActions.setFacilitySystemsVendorsSearchQuery({
                searchQuery: getInitialGridState(),
                hospitalSystemId: this.hospSystemId,
                isActive: this.active
            })
        );
    }

    removeSelection() {
        this.isAllSelected = false;
        this.onRowSelected([]);
        this.refreshGrid();
    }

    updateStatusAll(statusId: VendorFacilityStatusCode) {
        const dialogRef = this._dialogService.openDialog(UpdateStatusModalComponent, {
            width: statusId === VendorFacilityStatusCode.Excluded ? '35%' : '75%',
            data: {
                statusId: statusId,
                facilityStatusIds: FACILITY_STATUS_IDS.filter((f) => f !== statusId),
                systemId: this.hospSystemId,
                fromComponent: 'system'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.d$))
            .subscribe((shouldUpdate) => {
                if (!shouldUpdate) {
                    return;
                }
                const params = {
                    statusId: statusId,
                    facilityIds: shouldUpdate.facilityIds,
                    vendorIds: shouldUpdate.vendorIds,
                    note: shouldUpdate.note,
                    hospitalSystemId: this.hospitalSystemId
                };

                this._facilitiesSystemsService.updateVendorFacilityStatusAll(params);
            });
    }

    updateStatus(statusId: VendorFacilityStatusCode) {
        if (!this.selectedRows.length) {
            return;
        }

        const pagination = {
            pageSize: 5000000,
            skip: 0
        };

        const filterArgs = {
            facilityStatusIds: FACILITY_STATUS_IDS.filter((f) => f !== statusId),
            vendorIds: this.selectedRows.map((row) => row.vendorId),
            hospitalSystemId: this.hospSystemId
        };

        this._facilitiesSystemsService
            .getFacilitySystemsFacilities(pagination, filterArgs)
            .pipe(take(1))
            .subscribe((results) => {
                this.hospitalSystemFacilities = [results]
                    .flatMap((r) => r.data)
                    .map((item, index) => {
                        return { ...item, id: `${index}` };
                    });

                if (this.hospitalSystemFacilities && this.hospitalSystemFacilities.length) {
                    if (statusId === VendorFacilityStatusCode.Excluded) {
                        this.openExcludeDialog();
                    } else {
                        this.openOfferAcceptDialog(statusId);
                    }
                } else {
                    this._dialogService.openSnackBarError(
                        `System Facilities already have status: ${VendorFacilityStatusCode[statusId]}`
                    );
                    this.removeSelection();
                }
            });
    }

    openExcludeDialog() {
        const dialogRef = this._dialogService.openDialog(ExcludeVendorModalComponent, {
            width: '25%'
        });

        dialogRef.afterClosed().subscribe((excludeData) => {
            if (excludeData) {
                const params = {
                    statusId: VendorFacilityStatusCode.Excluded,
                    facilityIds: this.hospitalSystemFacilities.map((r) => r.facilityId),
                    vendorIds: this.selectedRows.map((row) => row.vendorId),
                    note: excludeData.note
                };

                this._facilitiesSystemsService.updateVendorFacilityStatus(params);
                this.removeSelection();
            }
        });
    }

    openOfferAcceptDialog(statusId: VendorFacilityStatusCode) {
        const dialogRef = this._dialogService.openDialog(OfferAcceptVendorModalComponent, {
            width: '60%',
            data: {
                statusId: statusId,
                hospitalSystemFacilities: this.hospitalSystemFacilities,
                selectedVendorIds: this.selectedRows.map((row) => row.vendorId)
            }
        });

        dialogRef.afterClosed().subscribe((refresh) => {
            if (refresh) {
                this.hospitalSystemFacilities = [];
                this.removeSelection();
            }
        });
    }
}
