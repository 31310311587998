import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { LoadingTypes } from 'src/app/shared/models';

export const selectFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectFacilitySystemsProfileLogo = createSelector(
    selectFeature,
    (state) => state.facilitiesState.systemsProfiles.logo
);

export const selectFacilitySystemsColorTheme = createSelector(
    selectFeature,
    (state) => state.facilitiesState.systemsProfiles.colorTheme
);

export const selectFacilitySystemsProfileIsLoading = createSelector(
    selectFeature,
    (state) => state.facilitiesState.systemsProfiles.loadingState === LoadingTypes.LOADING
);

export const selectFacilitySystemDetail = createSelector(
    selectFeature,
    (state: AdminState) => state.facilitiesState.systemsProfiles.facilitySystem
);
