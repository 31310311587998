import { Action, createReducer, on } from '@ngrx/store';
import { getInitialVendorFileState, VendorFileState } from 'src/app/admin/store/state/vendor-detail-files.state';
import * as actions from 'src/app/admin/store/actions/vendor-details-files.actions';
import { LoadingTypes } from 'src/app/shared/models';

export function vendorDetailFileReducer(state: VendorFileState, action: Action) {
    return createReducer(
        getInitialVendorFileState(),

        on(actions.loadVendorDetailFiles, (state, action) => ({
            ...state,
            vendorId: action.vendorId,
            loading: LoadingTypes.LOADING
        })),

        on(actions.loadVendorDetailFilesSuccess, (state, action) => ({
            ...state,
            vendorFiles: action.vendorFiles,
            vendorId: action.vendorId,
            loading: LoadingTypes.LOADED
        })),

        on(actions.loadVendorDetailFilesFailure, (state, action) => ({
            ...state,
            vendorFiles: null,
            loading: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })),

        on(actions.loadFileTypes, (state) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(actions.loadFileTypesSuccess, (state, action) => ({
            ...state,
            fileTypes: action.fileTypes,
            loading: LoadingTypes.LOADED
        })),

        on(actions.setVendorDetailFilesSearchQuery, (state, action) => ({
            ...state,
            gridSearchQuery: action.searchQuery,
            loading: LoadingTypes.LOADING
        })),

        on(actions.deleteVendorDetailFiles, (state, action) => ({
            ...state,
            deletingFileIds: action.fileIds,
            isDeleting: true,
            isDeleteSuccess: undefined
        })),

        on(actions.deleteVendorDetailFilesSuccess, (state, action) => ({
            ...state,
            deletingFileIds: [],
            isDeleting: false,
            isDeleteSuccess: true
        })),

        on(actions.deleteVendorDetailFilesFailure, (state, action) => ({
            ...state,
            error: JSON.stringify(action.error)
        }))
    )(state, action);
}

export function reducer(state: VendorFileState | undefined, action: Action) {
    return vendorDetailFileReducer(state, action);
}
