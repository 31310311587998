import { Pipe, PipeTransform } from '@angular/core';
import { AppModule } from '../models';

@Pipe({
    name: 'isActive'
})
export class IsActivePipe implements PipeTransform {
    transform(menuItem: AppModule, currentState: string): boolean {
        if (!menuItem.states) {
            return false;
        }
        const routes = menuItem?.states.map((s) => s.potentialRoutes);
        if (!routes) {
            return false;
        }
        return routes
            .flat()
            .map((r) => currentState === r)
            .filter((r) => r)[0]
            ? true
            : false;
    }
}
