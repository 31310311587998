import { Component, Input } from '@angular/core';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

@Component({
    selector: 'formly-help-text',
    templateUrl: './help-text.component.html',
    styleUrls: ['./help-text.component.scss']
})
export class HelpTextComponent {
    @Input() helpText;
    enabledFeatureFlag = FeatureFlag.EnableCustomFieldsHelpText;
    constructor() {}
}
