import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

/* Borrowed from
 * https://stackoverflow.com/questions/41280471/how-to-implement-routereusestrategy-shoulddetach-for-specific-routes-in-angular */

interface StoredRoute {
    route: ActivatedRouteSnapshot;
    handle: DetachedRouteHandle;
}

function compareObjects(base: any, compare: any): boolean {
    for (let baseProperty in base) {
        if (compare.hasOwnProperty(baseProperty)) {
            switch (typeof base[baseProperty]) {
                case 'object':
                    if (
                        typeof compare[baseProperty] !== 'object' ||
                        !this.compareObjects(base[baseProperty], compare[baseProperty])
                    ) {
                        return false;
                    }
                    break;
                case 'function':
                    if (
                        typeof compare[baseProperty] !== 'function' ||
                        base[baseProperty].toString() !== compare[baseProperty].toString()
                    ) {
                        return false;
                    }
                    break;
                default:
                    if (base[baseProperty] != compare[baseProperty]) {
                        return false;
                    }
            }
        } else {
            return false;
        }
    }

    return true;
}

function getFullPath(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map((seg) => (seg.routeConfig || {}).path).join('/');
}

@Injectable()
export class MasterDetailRouteReuseStrategy implements RouteReuseStrategy {
    private cachedRoute: StoredRoute = null;
    private routeToCache: string = null;

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        if (this.cachedRoute) {
            const fullPath = getFullPath(route),
                cachedPath = getFullPath(this.cachedRoute.route);
            if (fullPath === cachedPath) return this.cachedRoute.handle;
        }
        return null;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const fullPath = getFullPath(route);

        if (
            this.cachedRoute &&
            fullPath === this.routeToCache &&
            compareObjects(route.params, this.cachedRoute.route.params) &&
            compareObjects(route.queryParams, this.cachedRoute.route.queryParams)
        ) {
            console.log('Attaching to cached route:', fullPath);
            /* clear 'routeToCache' so that we don't attempt to attach from any other route. DO NOT clear the 'cachedRoute' variable yet since
             * retrieve() is actually called several times */
            this.routeToCache = null;
            return true;
        }
        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const fullPath = getFullPath(route);
        return fullPath === this.routeToCache;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        /* TODO - the argument order is wrong in this case - 'future' is actually the current route and 'curr' is the future route.
         * See https://github.com/angular/angular/pull/26949 for more info. */
        if (future.data.forceDestroyComponent) {
            return false;
        }
        if (curr.data && curr.data.cacheParent) {
            this.routeToCache = curr.data.cacheParent;
            return false;
        }
        return future.routeConfig === curr.routeConfig;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle) {
        if (!handle) return;

        console.log('Caching route:', route.routeConfig.path);
        this.cachedRoute = { route, handle };
    }
}
