import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LoadingTypes } from "src/app/shared/models";
import { InternalPoolState, internalPoolStoreKey } from "src/app/internal-pool/store/internal-pool.state";

const selectInternalPoolState = createFeatureSelector<InternalPoolState>(
    internalPoolStoreKey
);

export const selectDetailsFeature = createSelector(
    selectInternalPoolState,
    (state) => 
        state.details
);

export const selectCheckingUsername = createSelector(
    selectDetailsFeature,
    (state) => state.checkingUsername === LoadingTypes.LOADING
);

export const selectResourceUsername = createSelector(
    selectDetailsFeature,
    (state) => 
        state.resource?.userName || state.suggestedUsername || state.resource?.email
);
