import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities/route';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListItem } from 'src/app/shared/models/list-item';
import { Expertise } from 'src/app/shared/models/shifts/expertise.model';
import { State } from 'src/app/shared/grid/models/state.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LookupsService {
    private readonly _coreApiPath: string;

    constructor(@Inject(APP_CONFIG) readonly _settings: Settings, private readonly _http: HttpClient) {
        this._coreApiPath = combine(_settings.CORE, 'ayaconnect');
    }

    getDashboardLinesOfBusiness(): Observable<ListItem[]> {
        const url = `${this._coreApiPath}/line-of-business/client/dashboard`;

        return this._http.get<ListItem[]>(url);
    }

    getJobsLinesOfBusiness(): Observable<ListItem[]> {
        const url = `${this._coreApiPath}/line-of-business/client/jobs`;

        return this._http.get<ListItem[]>(url);
    }

    getWorkersLinesOfBusiness(): Observable<ListItem[]> {
        const url = `${this._coreApiPath}/line-of-business/client/workers`;

        return this._http.get<ListItem[]>(url);
    }

    getSubmittalsLinesOfBusiness(): Observable<ListItem[]> {
        const url = `${this._coreApiPath}/line-of-business/client/submittals`;

        return this._http.get<ListItem[]>(url);
    }

    getTimecardsLinesOfBusiness(): Observable<ListItem[]> {
        const url = `${this._coreApiPath}/line-of-business/client/timecards`;

        return this._http.get<ListItem[]>(url);
    }

    getEditJobsLinesOfBusiness(): Observable<ListItem[]> {
        const url = `${this._coreApiPath}/line-of-business/client/edit-jobs`;

        return this._http.get<ListItem[]>(url);
    }

    getShiftTypes(): Observable<ListItem[]> {
        const url = `${this._coreApiPath}/shift-types`;

        return this._http.get<ListItem[]>(url);
    }

    getExpertise(): Observable<Expertise[]> {
        return this._http
            .get<Expertise[]>(`${this._coreApiPath}/Lookup/Expertises`)
            .pipe(
                map((expertise: Expertise[]) =>
                    [...expertise].sort((first, second) =>
                        first.name.toLowerCase().localeCompare(second.name.toLowerCase())
                    )
                )
            );
    }

    getStates(countryId: number | null = 1, includeAll = false): Observable<State[]> {
        const url = countryId
            ? `${this._coreApiPath}/States?countryId=${countryId}&includeAll=${includeAll}`
            : `${this._coreApiPath}/States?includeAll=${includeAll}`;
        return this._http.get<State[]>(url);
    }

    getHospitalSystems(): Observable<ListItem[]> {
        return this._http.get<ListItem[]>(`${this._coreApiPath}/AdminModule/HospitalSystems`);
    }

    getAdminVendors(): Observable<ListItem[]> {
        return this._http.get<ListItem[]>(`${this._coreApiPath}/admin/vendors/list`);
    }

    getProfessions(): Observable<ListItem[]> {
        return this._http.get<ListItem[]>(`${this._coreApiPath}/candidates/professions`);
    }

    getPerDiemFacilitiesForVendor(candidateId: number, viewTypeId: number): Observable<ListItem[]> {
        return this._http.post<ListItem[]>(`${this._coreApiPath}/PerDiemScheduler/GetPerDiemFacilitiesForVendor`, {
            candidateId,
            viewTypeId
        });
    }
}
