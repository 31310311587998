import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/admin/store/actions/facility-systems.actions';
import {
    FacilitySystemsVendorsState,
    getInitialFacilitySystemsVendorsState
} from 'src/app/admin/store/state/facility-systems.state';
import { LoadingTypes } from 'src/app/shared/models';

export function facilitySystemsVendorsReducer(state: FacilitySystemsVendorsState | undefined, action: Action) {
    return createReducer(
        getInitialFacilitySystemsVendorsState(),

        on(actions.loadFacilitySystemsVendors, (state) => ({
            ...state,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.loadFacilitySystemsVendorsSuccess, (state, action) => {
            const vendors = action.vendors.map((item) => {
                return { ...item, id: `${item.vendorId}_${item.vendorState}_${item.vendorCity}` };
            });
            return {
                ...state,
                ...action,
                vendors,
                loadingState: LoadingTypes.LOADED
            };
        }),

        on(actions.loadFacilitySystemsVendorsFailure, (state) => ({
            ...state,
            loadingState: LoadingTypes.FAIL
        })),

        on(actions.setFacilitySystemsVendorsSearchQuery, (state, action) => ({
            ...state,
            gridSearchQuery: action.searchQuery,
            loadingState: LoadingTypes.LOADING
        })),

        on(actions.exportAllFacilitySystemsVendors, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.exportAllFacilitySystemsVendorsSuccess, (state, action) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.exportAllFacilitySystemsVendorsFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.exportSelectedFacilitySystemsVendors, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.exportSelectedFacilitySystemsVendorsSuccess, (state, action) => ({
            ...state,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.exportAllFacilitySystemsVendorsFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL })),

        on(actions.loadVendorListStates, (state) => ({ ...state, loadingState: LoadingTypes.LOADING })),

        on(actions.loadVendorListStatesSuccess, (state, action) => ({
            ...state,
            states: action.states,
            loadingState: LoadingTypes.LOADED
        })),

        on(actions.loadVendorListStatesFailure, (state) => ({ ...state, loadingState: LoadingTypes.FAIL }))
    )(state, action);
}
