import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findOne',
    pure: true
})
export class FindOnePipe implements PipeTransform {
    transform<T>(items: T[], term: { [key: string]: any }): T | null {
        if (!Array.isArray(items) || !term) {
            return null
        }
        return items.find(item =>
            Object.keys(term).every(t => item[t] === term[t])
        ) || null;
    }
}
