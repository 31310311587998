import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { loadCancelReasons, loadCancelReasonsFail, loadCancelReasonsSuccess } from '../perdiemscheduler.actions';

export interface PerdiemSchedulerCancelReasonsState {
    list: string[];
    loading: LoadingTypes;
}

export const getInitialPerdiemSchedulerCancelReasonsState = (): PerdiemSchedulerCancelReasonsState => ({
    list: [],
    loading: LoadingTypes.INIT
});

export function perdiemSchedulerCancelReasonsReducer(state: PerdiemSchedulerCancelReasonsState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerCancelReasonsState(),
        on(loadCancelReasons, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadCancelReasonsSuccess, (state, action) => ({
            ...state,
            list: action.cancelReasons,
            loading: LoadingTypes.LOADED
        })),
        on(loadCancelReasonsFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: PerdiemSchedulerCancelReasonsState | undefined, action: Action) {
    return perdiemSchedulerCancelReasonsReducer(state, action);
}
