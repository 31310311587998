import { createReducer, on, Action } from '@ngrx/store';
import * as internalPoolActions from '../actions';
import { LoadingTypes } from 'src/app/shared/models';

export interface InternalPoolSystemState {
    isNyuSystem: boolean
    error: string;
    loading: LoadingTypes;
};

export const initialInternalPoolSystemState: InternalPoolSystemState = {
    isNyuSystem: null,
    error: '',
    loading: LoadingTypes.INIT
};

const reducer = createReducer(
    initialInternalPoolSystemState,
    on(internalPoolActions.loadIsNyuSystem, (state) => (
        {
            ...state,
            loading: LoadingTypes.LOADING
        }
    )),
    on(internalPoolActions.loadIsNyuSystemSuccess, (state, { isNyuSystem }) => (
        {
            ...state,
            isNyuSystem: isNyuSystem,
            loading: LoadingTypes.LOADED,
            error: ''
        }
    )),
    on(internalPoolActions.loadIsNyuSystemFail, (state, { error }) => (
        {
            ...state,
            error: error,
            loading: LoadingTypes.FAIL
        }
    ))
);

export function systemReducer(state: InternalPoolSystemState | undefined, action: Action) {
    return reducer(state, action);
}
