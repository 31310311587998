import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { LoadingTypes, SortTypes } from 'src/app/shared/models';
import { SubmittalVendorLookupsState } from 'src/app/submittals-vendor/store/submittals-vendor.reducers';

export const SubmittalsStoreKey = 'admin-submittals';

export interface SubmittalsQueryState extends GridSearchQuery {}
export interface SubmittalsState {
    loading: LoadingTypes;
    submittals: GridDataResult;
    /** @deprecated use searchQuery */
    gridSearchQuery: GridSearchQuery;
    error: unknown;
    searchQuery: GridSearchQuery;
    lookups: SubmittalVendorLookupsState;
}

export const getInitialSubmittalsState = (): SubmittalsState => ({
    loading: LoadingTypes.INIT,
    submittals: {
        data: [],
        total: 0,
        filtered: 0
    } as GridDataResult,
    /** @deprecated use searchQuery */
    gridSearchQuery: getInitialSubmittalsQueryState(),
    error: undefined,
    searchQuery: initialGridState(),
    lookups: {
        statuses: [],
        subStatuses: []
    }
});
/** @deprecated use initialGridState */
export const getInitialSubmittalsQueryState = (): GridSearchQuery => ({
    sort: [{ field: 'submitted', dir: SortTypes.DESC }],
    filter: null,
    take: 10,
    skip: 0,
    groups: []
});

export const initialGridState = (): GridSearchQuery => ({
    sort: [{ field: 'submitted', dir: SortTypes.DESC }],
    take: 10,
    skip: 0,
    filter: {
        logic: 'and',
        filters: [{ field: 'statusId', operator: 'eq', value: [] }]
    }
});
