import { PermJobsGridQueryState } from "./reducers/perm-jobs-grid-query.reducer";
import { PermJobsGridState } from "./reducers/perm-jobs-grid-results.reducer";

export const permJobsStoreKey = 'client/permanentjobs';

export interface PermJobsState {
    results: PermJobsGridState;
    query: PermJobsGridQueryState;
}

