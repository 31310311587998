import { Action, createReducer, on } from '@ngrx/store';
import { getInitialVendorLocationState, VendorLocationsState } from 'src/app/admin/store/state/vendor-locations.state';
import { LoadingTypes } from 'src/app/shared/models';
import * as actions from '../actions/vendor-locations.actions';

export function adminVendorProfileLocationsReducer(state: VendorLocationsState, action: Action) {
    return createReducer(
        getInitialVendorLocationState(),

        on(actions.loadVendorProfileLocations, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING,
            vendorId: action.vendorId,
            error: undefined,
            locations: []
        })),

        on(actions.loadVendorProfileLocationsSuccess, (state, action) => ({
            ...state,
            locations: action.response,
            loading: LoadingTypes.LOADED,
            vendorId: action.vendorId,
            error: undefined,
            hasChanges: false
        })),

        on(actions.loadVendorProfileLocationsFailure, (state, action) => ({
            ...state,
            locations: [],
            loading: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })),

        on(actions.loadLocationTypes, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(actions.loadLocationTypesSuccess, (state, action) => ({
            ...state,
            locationTypes: action.locationTypes,
            loading: LoadingTypes.LOADED
        })),

        on(actions.loadLocationTypesFailure, (state, action) => ({
            ...state,
            locationTypes: null,
            loading: LoadingTypes.FAIL,
            error: JSON.stringify(action.error)
        })),

        on(actions.updateVendorProfileLocation, (state, action) => ({
            ...state,
            locations: state.locations.map((item) => {
                if (item.id === action.id) {
                    return {
                        ...item,
                        vendorLocationTypeId: action.vendorLocationTypeId,
                        address1: action.address1,
                        address2: action.address2,
                        city: action.city,
                        zipCode: action.zipCode,
                        stateId: action.stateId,
                        note: action.note
                    };
                }

                return item;
            }),
            hasChanges: true
        })),

        on(actions.addVendorProfileLocation, (state, action) => ({
            ...state,
            locations: [...state.locations, action.location]
        })),

        on(actions.deleteVendorProfileLocation, (state, action) => ({
            ...state,
            locations: state.locations.map((item) => {
                if (item.id === action.locationId) {
                    return {
                        ...item,
                        isDeleted: true
                    };
                }

                return item;
            }),
            hasChanges: true
        })),
        on(actions.validVendorProfileLocations, (state, action) => ({
            ...state,
            isValid: true
        })),
        on(actions.invalidVendorProfileLocations, (state, action) => ({
            ...state,
            isValid: false
        })),

        on(actions.resetVendorLocations, (state) => ({
            ...state,
            locations: []
        }))
    )(state, action);
}

export function reducer(state: VendorLocationsState | undefined, action: Action) {
    return adminVendorProfileLocationsReducer(state, action);
}
