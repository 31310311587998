import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreStoreFacade } from '../state/core-store.facade';
import { MenuItem } from '../models';
import { UnsubscribeOnDestroy } from '../utils';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-side-nav',
    templateUrl: './app-side-nav.component.html',
    styleUrls: ['./app-side-nav.component.scss']
})
export class SideNavComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() isExpanded: boolean;
    menuItems$: Observable<any[]>;

    featureFlagEnum = FeatureFlag;
    flagsToCheck: BehaviorSubject<Array<{ flag: FeatureFlag; path: string }>> = new BehaviorSubject([]);
    customIcons = [
        {
            name: 'svg_shifts_logo',
            url: 'content/images/shifts-logo-mat-icon.svg'
        },
        {
            name: 'svg_flexwise',
            url: 'content/images/flexwise_menu_icon.svg'
        },
        {
            name: 'svg_polaris',
            url: 'content/images/polaris_menu_icon.svg'
        },
        {
            name: 'svg_doccafe',
            url: 'content/images/doccafe_menu_icon.svg'
        },
        {
            name: 'svg_workforceai',
            url: 'content/images/workforceai_menu_icon.svg'
        },
        {
            name: 'svg_stethoscope_check',
            url: 'content/images/stethoscope_check_menu_icon.svg'
        },
        {
            name: 'svg_attach_money',
            url: 'content/images/attach_money_icon.svg'
        }
    ];

    constructor(
        private readonly store: CoreStoreFacade,
        private readonly matIconRegistry: MatIconRegistry,
        private readonly domSanitizer: DomSanitizer
    ) {
        super();
        this.menuItems$ = this.store.menu$.pipe(map((menu) => this.normalizeMenu(menu)));
        this.registerCustomIcons();
    }

    ngOnInit() {
        this.initMenuCheck();
    }

    initMenuCheck(): void {
        this.menuItems$ = this.store.menu$.pipe(map((menu) => this.normalizeMenu(menu)));
    }

    normalizeMenu(menu: Array<MenuItem>) {
        if (!Array.isArray(menu)) {
            return menu;
        }

        return menu.map((item) => {
            return {
                ...item,
                path: `/${item.path}`,
                icon: item.icon.replace('fa ', ''),
                children: this.normalizeMenu(item.children),
                queryParams: JSON.parse(item.queryParams)
            };
        });
    }

    private registerCustomIcons() {
        for (const item of this.customIcons) {
            this.matIconRegistry.addSvgIcon(item.name, this.domSanitizer.bypassSecurityTrustResourceUrl(item.url));
        }
    }
}
