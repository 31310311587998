import { Pipe, PipeTransform } from '@angular/core';
import { VaccinationTypeEnum } from '../models/submittals/enums/vaccination-type.enum';

@Pipe({ name: 'vaccinationType' })
export class VaccinationTypePipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case VaccinationTypeEnum.Covid19: {
                return 'COVID-19 Vaccine (Facility Guideline)';
            }
            case VaccinationTypeEnum.Flu: {
                return 'Flu Vaccine (Facility Guideline)';
            }
            default: {
                return '';
            }
        }
    }
}
