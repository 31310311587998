import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateParams } from 'src/app/core/utils/interpolate-params';
import * as data from 'src/app/reports/assets/report.data';
import { ActivePoolReport } from 'src/app/reports/models/active-pool-report/active-pool-report.model';
import { CancellationsReport } from 'src/app/reports/models/cancellations-report/cancellations-report.model';
import { ChartReport } from 'src/app/reports/models/chart-report.model';
import { ReportType } from 'src/app/reports/models/enums/report-type.enum';
import { FutureReport } from 'src/app/reports/models/future-report/future-report.model';
import { GridReportResponse } from 'src/app/reports/models/grid-report-response.model';
import { InactiveReport } from 'src/app/reports/models/inactive-report/inactive-report.model';
import { LinesOfBusiness } from 'src/app/reports/models/lines-of-business.model';
import { OnAssignmentReport } from 'src/app/reports/models/on-assignment-report/on-assignment-report.model';
import { OpenOrdersReport } from 'src/app/reports/models/open-orders-report/open-orders-report.model';
import { OpenShiftsReport } from 'src/app/reports/models/open-shifts-report/open-shifts-report.model';
import { OrderHistoryReport } from 'src/app/reports/models/order-history-report/order-history-report.model';
import { ReportListItem } from 'src/app/reports/models/report-list-item.model';
import { ReportUrls } from 'src/app/reports/models/report-urls';
import { ShiftsHistoryReport } from 'src/app/reports/models/shifts-history-report/shifts-history-report.model';
import { SpendReportFilter } from 'src/app/reports/models/spend-report/spend-report-filter.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ReportUserSettings } from '../models/report-user-settings';

@Injectable({
    providedIn: 'root'
})
export class ReportsRepositoryService {
    private useMockData = false;

    constructor(
        private readonly http: HttpClient,
        @Inject(APP_CONFIG) private settings: Settings,
        private readonly dialogService: DialogService
    ) {}

    getDashboardNumbers(hospitalSystemId: number): Observable<LinesOfBusiness> {
        if (this.useMockData) {
            return of(data.LinesOfBusinessResponse).pipe(delay(1000));
        }
        const params = interpolateParams({ hospitalSystemId });
        return this.http.get<LinesOfBusiness>(`${this.settings.CORE}${ReportUrls.LinesOfBusiness.Api}`, { params });
    }

    getReportList(): Observable<ReportListItem[]> {
        if (this.useMockData) {
            return of(data.ReportListResponse);
        }
        return this.http.get<ReportListItem[]>(`${this.settings.CORE}${ReportUrls.Reports.Api}`);
    }

    getUserSettings(): Observable<ReportUserSettings> {
        if (this.useMockData) {
            return of(data.UserSettings);
        }
        return this.http.get<ReportUserSettings>(`${this.settings.CORE}${ReportUrls.UserSettings.Api}`);
    }

    saveUserSettings(userSettings: ReportUserSettings): Observable<any> {
        if (this.useMockData) {
            return of(data.UserSettings);
        }
        return this.http.post(`${this.settings.CORE}${ReportUrls.UserSettings.Api}`, userSettings);
    }

    //grid reports
    getOpenOrders(searchQuery: GridSearchQuery): Observable<GridReportResponse<OpenOrdersReport>> {
        this.updateFilterDateToRange('enteredDate', searchQuery);
        this.updateFilterDateToRange('startDate', searchQuery);
        return this.getGridReportResponse<OrderHistoryReport>(ReportType.TravelJobsOpen, searchQuery);
    }

    getOrderHistory(searchQuery: GridSearchQuery): Observable<GridReportResponse<OrderHistoryReport>> {
        this.updateFilterDateToRange('enteredDate', searchQuery);
        this.updateFilterDateToRange('startDate', searchQuery);
        return this.getGridReportResponse<OrderHistoryReport>(ReportType.TravelJobHistory, searchQuery);
    }

    getOnAssignment(searchQuery: GridSearchQuery): Observable<GridReportResponse<OnAssignmentReport>> {
        this.updateFilterDateToRange('startDate', searchQuery);
        this.updateFilterDateToRange('endDate', searchQuery);
        return this.getGridReportResponse<OnAssignmentReport>(ReportType.TravelAssignmentsCurrent, searchQuery);
    }

    getFuture(searchQuery: GridSearchQuery): Observable<GridReportResponse<FutureReport>> {
        this.updateFilterDateToRange('startDate', searchQuery);
        this.updateFilterDateToRange('endDate', searchQuery);
        return this.getGridReportResponse<FutureReport>(ReportType.TravelAssignmentsFuture, searchQuery);
    }

    getInactive(searchQuery: GridSearchQuery): Observable<GridReportResponse<InactiveReport>> {
        this.updateFilterDateToRange('startDate', searchQuery);
        this.updateFilterDateToRange('endDate', searchQuery);
        return this.getGridReportResponse<InactiveReport>(ReportType.TravelAssignmentsInactive, searchQuery);
    }

    getCancellations(searchQuery: GridSearchQuery): Observable<GridReportResponse<CancellationsReport>> {
        this.updateFilterDateToRange('startDate', searchQuery);
        this.updateFilterDateToRange('endDate', searchQuery);
        return this.getGridReportResponse<CancellationsReport>(ReportType.TravelAssignmentsCancellations, searchQuery);
    }

    getActivePool(searchQuery: GridSearchQuery): Observable<GridReportResponse<ActivePoolReport>> {
        this.updateFilterDateToRange('dateCompliant', searchQuery);
        return this.getGridReportResponse<ActivePoolReport>(ReportType.PerDiemPoolActive, searchQuery);
    }

    getOpenShifts(searchQuery: GridSearchQuery): Observable<GridReportResponse<OpenShiftsReport>> {
        this.updateFilterDateToRange('shiftStartDate', searchQuery);
        return this.getGridReportResponse<OpenShiftsReport>(ReportType.PerDiemShiftsOpen, searchQuery);
    }

    getShiftsHistory(searchQuery: GridSearchQuery): Observable<GridReportResponse<ShiftsHistoryReport>> {
        return this.getGridReportResponse<ShiftsHistoryReport>(ReportType.PerDiemShiftHistory, searchQuery);
    }

    //chart reports
    getSpend(reportType: ReportType, filter: SpendReportFilter): Observable<ChartReport[]> {
        if (!reportType) {
            this.dialogService.openSnackBarError('Report is not identified');
        }
        return this.getChartReportResponse(reportType, { ...filter });
    }

    exportSpend(reportType: ReportType, filter: SpendReportFilter) {
        if (!reportType) {
            this.dialogService.openSnackBarError('Report is not identified');
        }

        return this.http.post(`${this.settings.CORE}${ReportUrls.getExportUrl(reportType)}`, filter, {
            responseType: 'blob'
        });
    }

    //private
    private getGridReportResponse<T>(
        reportType: ReportType,
        searchQuery: GridSearchQuery
    ): Observable<GridReportResponse<T>> {
        let response: Observable<GridReportResponse<T>> = null;

        if (this.useMockData) {
            response = of(data.getGridReportMockDataByType(reportType)).pipe(delay(1000));
        } else {
            response = this.http.post<GridReportResponse<T>>(
                `${this.settings.CORE}${ReportUrls.get(reportType).Api}`,
                searchQuery
            );
        }

        return response;
    }

    private getChartReportResponse(reportType: ReportType, query: any): Observable<ChartReport[]> {
        let response: Observable<ChartReport[]> = null;

        if (this.useMockData) {
            response = of(data.getChartReportMockDataByType(reportType)).pipe(delay(1000));
        } else {
            response = this.http.post<ChartReport[]>(`${this.settings.CORE}${ReportUrls.get(reportType).Api}`, query);
        }

        return response;
    }

    //TODO: remove when backend part will be converted from using breeze
    private updateFilterDateToRange(field: string, searchQuery: GridSearchQuery): void {
        let dateValue = this.getFilterValue(field, searchQuery.filter);
        if (!dateValue) {
            return;
        }
        let dateValueFrom = moment(dateValue).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        let dateValueTo = moment(dateValue).endOf('day').format('YYYY-MM-DDTHH:mm:ss');

        //convert FilterDescriptor to CompositeFilterDescriptor
        let dateFilter: any = this.getFilterByField(field, searchQuery.filter);
        delete dateFilter.field;
        delete dateFilter.operator;
        delete dateFilter.value;
        dateFilter.logic = 'and';
        dateFilter.filters = [
            {
                field: field,
                operator: 'gte',
                value: dateValueFrom
            },
            {
                field: field,
                operator: 'lte',
                value: dateValueTo
            }
        ];
    }

    private readonly getFilterByField = (field: string, filter: CompositeFilterDescriptor): any =>
        ((filter || ({} as CompositeFilterDescriptor)).filters || []).find((x: FilterDescriptor) => x.field === field);

    private readonly getFilterValue = (field: string, filter: CompositeFilterDescriptor): any =>
        (flattenFilter(filter) || {})[field];
}
