import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    VendorCandidateState,
    vendorCandidateDetailsStoreKey
} from 'src/app/vendor/vendor-candidate-details/store/state/vendor-candidate-details.state';

const selectFeature = createFeatureSelector<VendorCandidateState>(vendorCandidateDetailsStoreKey);

export const selectVendorCandidateProfile = createSelector(selectFeature, (state) => state.candidateProfile);
export const selectVendorCandidateProfileLoading = createSelector(
    selectVendorCandidateProfile,
    (state) => state.loading === LoadingTypes.LOADING
);
export const selectVendorCandidateShiftsAppInviteSent = createSelector(
    selectVendorCandidateProfile,
    (state) => state.inviteSent
);
export const selectVendorCandidateSendEmailLoading = createSelector(
    selectVendorCandidateProfile,
    (state) => state.sendEmailLoading === LoadingTypes.LOADING
);
export const selectVendorCandidateHasAccessShiftsApp = createSelector(
    selectVendorCandidateProfile,
    (state) => state.canAccessShiftsApp
);
export const selectVendorCandidateIsCredentialed = createSelector(
    selectVendorCandidateProfile,
    (state) => state.isCredentialed
);

export const selectHospitalType = createSelector(selectVendorCandidateProfile, (state) => state.hospitalTypeId);
