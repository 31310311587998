import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { Resource } from 'src/app/shared/models/internal-pool/resource.model';
import { ShiftDetails } from 'src/app/shared/models/shifts/shift-details.model';
import { ReleaseGroup, ReleaseGroupVendorFlat } from 'src/app/shared/models/vendor-releases/release-group.model';
import { ReleaseHistory } from 'src/app/shifts/models';
import * as shiftsActions from 'src/app/shifts/store/actions';

export interface ShiftDetailsState {
    shift: ShiftDetails;
    loading: LoadingTypes;
    saving: LoadingTypes;
    history: ReleaseHistory[];
    releaseGroups: ReleaseGroup[];
    preseletedVendors: ReleaseGroupVendorFlat[];
    bookedResource: Resource;
}

export const initialDetailsState: ShiftDetailsState = {
    shift: {} as ShiftDetails,
    loading: LoadingTypes.INIT,
    saving: LoadingTypes.INIT,
    history: [],
    releaseGroups: [],
    preseletedVendors: [],
    bookedResource: null
}

export function shiftDetailsReducer(state: ShiftDetailsState, action: Action) {
    return createReducer(
        initialDetailsState,
        on(
            shiftsActions.loadShiftDetails,
            shiftsActions.loadShiftDetailsAndHistory,
            shiftsActions.loadSeriesDetails,
            shiftsActions.loadSingleSeriesDetails,
            shiftsActions.loadShiftReleaseGroups,
            shiftsActions.loadShiftReleaseHistory,
            shiftsActions.loadingRedirectShiftDialog,
            (state, action) => (
                {
                    ...state,
                    loading: LoadingTypes.LOADING,
                }
            )),
        on(shiftsActions.loadShiftDetailsSuccess,
            shiftsActions.loadSeriesDetailsSuccess,
            shiftsActions.loadSingleSeriesDetailsSuccess,
            (state, action) => (
                {
                    ...state,
                    shift: action.shift,
                    loading: LoadingTypes.LOADED
                }
            )),
        on(shiftsActions.loadShiftDetailsFail,
            shiftsActions.loadSeriesDetailsFail,
            shiftsActions.loadSingleSeriesDetailsFail,
            shiftsActions.loadShiftReleaseGroupsFail,
            shiftsActions.loadBookedResourceFail,
            (state, action) => (
                {
                    ...state,
                    loading: LoadingTypes.FAIL
                }
            )),
        on(
            shiftsActions.preselectedVendorsChanged,
            (state, action) => (
                {
                    ...state,
                    preseletedVendors: action.preseletedVendors
                }
            )),
        on(
            shiftsActions.updateShift,
            shiftsActions.updateShiftWithReload,
            shiftsActions.updateInternalPoolShift,
            shiftsActions.updateInternalPoolShiftWithReload,
            shiftsActions.convertInternalPoolShift,
            shiftsActions.convertInternalPoolShiftWithReload,
            shiftsActions.convertShift,
            shiftsActions.convertShiftWithReload,
            shiftsActions.updateAndAssignShift,
            shiftsActions.updateAndAssignShiftWithReload,
            shiftsActions.updateSingleShiftSeries,
            shiftsActions.manualShiftRelease,
            (state, action) => (
                {
                    ...state,
                    saving: LoadingTypes.LOADING
                }
            )),
        on(
            shiftsActions.updateShiftSuccess,
            shiftsActions.manualShiftReleaseSuccess,
            (state, action) => (
                {
                    ...state,
                    saving: LoadingTypes.LOADED
                }
            )),
        on(
            shiftsActions.updateShiftFail,
            shiftsActions.manualShiftReleaseFail,
            (state, action) => (
                {
                    ...state,
                    saving: LoadingTypes.FAIL
                }
            )),
        on(
            shiftsActions.addShift,
            shiftsActions.addSeries,
            shiftsActions.addShiftWithReload,
            shiftsActions.addInternalPoolShift,
            shiftsActions.addInternalPoolShiftWithReload,
            (state, action) => (
                {
                    ...state,
                    saving: LoadingTypes.LOADING
                }
            )),
        on(
            shiftsActions.addShiftSuccess,
            shiftsActions.addSeriesSuccess,
            (state, action) => (
                {
                    ...state,
                    saving: LoadingTypes.LOADED
                }
            )),
        on(
            shiftsActions.addShiftFail,
            shiftsActions.addSeriesFail,
            (state, action) => (
                {
                    ...state,
                    saving: LoadingTypes.FAIL
                }
            )),
        on(
            shiftsActions.approveShift,
            shiftsActions.rejectShift,
            (state, action) => (
                {
                    ...state,
                    saving: LoadingTypes.LOADING
                }
            )),
        on(
            shiftsActions.approveShiftSuccess,
            shiftsActions.rejectShiftSuccess,
            (state, action) => (
                {
                    ...state,
                    saving: LoadingTypes.LOADED
                }
            )),
        on(
            shiftsActions.approveShiftFail,
            shiftsActions.rejectShiftFail,
            (state, action) => (
                {
                    ...state,
                    saving: LoadingTypes.FAIL
                }
            )),
        on(shiftsActions.addShifts, (state, action) => (
            {
                ...state,
                saving: LoadingTypes.LOADING
            }
        )),
        on(shiftsActions.addShiftBulkWithReload, (state, action) => (
            {
                ...state,
                saving: LoadingTypes.LOADING
            }
        )),
        on(shiftsActions.clearShiftDetails,
           shiftsActions.clearShiftDetailsAndHistory, (state, action) => (
            {
                ...initialDetailsState
            }
        )),
        on(shiftsActions.setNewShiftDetails, (state, action) => (
            {
                ...state,
                shift: action.shift
            }
        )),
        on(shiftsActions.loadShiftReleaseGroupsSuccess, (state, action) => (
            {
                ...state,
                releaseGroups: action.releaseGroups,
                loading: LoadingTypes.LOADED
            }
        )),
        on(shiftsActions.loadShiftReleaseHistorySuccess, (state, action) => (
            {
                ...state,
                history: action.history,
                loading: LoadingTypes.LOADED
            }
        )),
        on(shiftsActions.loadShiftFacilityAddressInfoFail, (state, action) => (
            {
                ...state,
                loading: LoadingTypes.FAIL,
                error: action.error
            }
        )),on(shiftsActions.loadBookedResourceSuccess, (state, action) => (
            {
                ...state,
                bookedResource: action.bookedResource,
                loading: LoadingTypes.LOADED
            }
        )),on(shiftsActions.resetSelectedBookedCandidate, (state, action) => (
            {
                ...state,
                bookedResource: null,
            }
        ))
    )(state, action);
}
export function reducer(state: ShiftDetailsState | undefined, action: Action) {
    return shiftDetailsReducer(state, action);
}
