import { createAction, props } from '@ngrx/store';

export const loadPendingExpenses = createAction('[Dashboard Component] Pending Expenses Loading');
export const loadPendingExpensesSuccess = createAction(
    '[Dashboard Api] Load Pending Expenses Success',
    props<{ totalCount: number; agingCount: number; agingDays: number }>()
);
export const loadPendingExpensesFail = createAction(
    '[Dashboard Api] Load Pending Expenses Fail',
    props<{ error: string }>()
);
