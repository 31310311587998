import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { VendorContact, VendorEmail, ContactEmailType } from 'src/app/shared/models';
import { VendorContactChange } from '../vendor-contacts-change.model';
import { VendorContactEmailType } from 'src/app/admin/vendor-contact-details/models';

@Component({
    selector: 'ayac-vendor-contact-email',
    templateUrl: 'vendor-contact-email.component.html',
    styleUrls: ['./vendor-contact-email.component.scss']
})
export class VendorContactEmailComponent extends UnsubscribeOnDestroy implements OnInit {
    @Output() contactChanged: EventEmitter<VendorContactChange | null> = new EventEmitter<VendorContactChange | null>();
    @Input() contact: VendorContact;
    @Input() emailTypes: ContactEmailType[];
    contactEmailAddressForm: UntypedFormGroup;

    constructor() {
        super();
    }

    get emails(): UntypedFormArray {
        return this.contactEmailAddressForm.get('emails') as UntypedFormArray;
    }

    ngOnInit() {
        this.contactEmailAddressForm = this.createEmailListForm(this.contact.vendorContactEmails);

        this.contactEmailAddressForm.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.d$))
            .subscribe((updatedContact: { emails: any[] }) => {
                this.contactChanged.emit({
                    vendorContact: this.convertContactForSaving(updatedContact.emails),
                    changeType: 'email',
                    isInvalidChange: this.contactEmailAddressForm.invalid
                });
            });
    }

    addEmail(): void {
        const emailTypeId = this.getNextEmailTypeId(this.emailTypes, this.emails.value);
        const newEmail: VendorEmail = {
            id: 0,
            emailAddress: '',
            showEmailExternally: false,
            vendorContactEmailTypeID: emailTypeId,
            vendorContactID: this.contact.id
        };
        this.emails.push(this.createEmailForm(newEmail));
    }

    removeEmail(index: number): void {
        this.emails.removeAt(index);
    }

    getNextEmailTypeId(emailTypes: ContactEmailType[], emailTypesAlreadyAdded: any[]): number {
        const unusedPhoneTypes = emailTypes.filter(
            (type: ContactEmailType) =>
                !emailTypesAlreadyAdded.find((formValue: any) => formValue.emailTypeId === type.id)
        );

        if (unusedPhoneTypes.length === 0) {
            return emailTypes[0].id;
        }

        return unusedPhoneTypes[0].id;
    }

    createEmailListForm(emails: VendorEmail[]): UntypedFormGroup {
        return new UntypedFormGroup({
            emails: this.createEmailListArrayForm(emails)
        });
    }

    private createEmailListArrayForm(emails: VendorEmail[] | null): UntypedFormArray {
        if (emails && emails.length > 0) {
            const emailControls = emails
                .sort((a, b) =>
                    this.contact?.user?.username &&
                    a.emailAddress === this.contact?.user?.username &&
                    a.vendorContactEmailTypeID === VendorContactEmailType.Business
                        ? -1
                        : 1
                )
                .map((email: VendorEmail) => this.createEmailForm(email));

            return new UntypedFormArray(emailControls);
        }

        return new UntypedFormArray([]);
    }

    private createEmailForm(email: VendorEmail): UntypedFormGroup {
        return new UntypedFormGroup({
            id: new UntypedFormControl(email.id),
            emailAddress: new UntypedFormControl(email.emailAddress, [Validators.required, Validators.email]),
            showExternally: new UntypedFormControl(email.showEmailExternally),
            emailTypeId: new UntypedFormControl(email.vendorContactEmailTypeID),
            vendorContactId: new UntypedFormControl(email.vendorContactID)
        });
    }

    private convertContactForSaving(emails: any[]): VendorContact {
        return {
            ...this.contact,
            vendorContactEmails: emails.map((email): VendorEmail => {
                return {
                    id: email.id,
                    emailAddress: email.emailAddress,
                    showEmailExternally: email.showExternally,
                    vendorContactEmailTypeID: email.emailTypeId,
                    vendorContactID: email.vendorContactId
                };
            })
        };
    }
}
