import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InternalPoolState, internalPoolStoreKey } from 'src/app/internal-pool/store/internal-pool.state';

const selectInternalPoolState = createFeatureSelector<InternalPoolState>(internalPoolStoreKey);

export const selectInternalPoolFacilityLookupsFeature = createSelector(
    selectInternalPoolState,
    (state) => state.facilityLookups
);

export const selectInternalPoolFacilityLookups = createSelector(
    selectInternalPoolFacilityLookupsFeature,
    (state) => state.facilities
);

export const selectAvailableFacilityLookups = (professionIds: number[], specialtyIds: number[]) => {
    return createSelector(selectInternalPoolFacilityLookupsFeature, (state) => {
        if (professionIds.length === 0 || specialtyIds.length === 0) {
            return [];
        }

        const facilityIds = state.facilityConfigurations
            .filter((conf) =>
                conf.professions.some((profession) => {
                    if (!professionIds.includes(profession.professionId)) {
                        return false;
                    }
                    return profession.expertiseIds.some((expertise) => specialtyIds.includes(expertise));
                })
            )
            .map((conf) => conf.facilityId);

        return state.facilities.filter((facility) => facilityIds.includes(facility.id));
    });
};
