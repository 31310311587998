import { LoadingTypes } from 'src/app/shared/models';

export interface VendorCandidateProfileState {
    loading: LoadingTypes;
    sendEmailLoading: LoadingTypes;
    inviteSent: string | null;
    canAccessShiftsApp: boolean;
    isCredentialed: boolean;
    hospitalTypeId: number;
}

const initialState: VendorCandidateProfileState = {
    loading: LoadingTypes.INIT,
    sendEmailLoading: LoadingTypes.INIT,
    inviteSent: null,
    canAccessShiftsApp: false,
    isCredentialed: false,
    hospitalTypeId: null
};

export const getInitialVendorCandidateProfileState = () => initialState;
