import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import { loadInvoicesLookupsFail, loadInvoicesLookupsSuccess, loadInvoicesLookups } from '../invoices.actions';
import { of } from 'rxjs';
import { selectLookups } from '../invoices.selectors';
import { Store } from '@ngrx/store';
import { findAndReplaceTerminology } from '../../../shared/terminology/terminology.helper';
import { InvoiceCaregiverListingTypeLookup } from '../../models/lookups/invoice-caregiver-listing-types.lookup';
import { IdentityService } from '../../../shared/services/identity.service';
import { ApplicationPermissions } from '../../../shared/models/enums/application-permissions.enum';

@Injectable()
export class InvoicesLookupsEffect {
    getInvoicesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadInvoicesLookups),
            withLatestFrom(this.store.select(selectLookups)),
            switchMap(([action, lookups]) => {
                if (lookups) {
                    return of(
                        loadInvoicesLookupsSuccess({
                            facilities: lookups.facilities,
                            systems: lookups.systems,
                            disputeStatuses: lookups.disputeStatuses,
                            disputeReasons: lookups.disputeReasons,
                            disputeReasonsWithDisputeType: lookups.disputeReasonsWithDisputeType,
                            caregiverListingTypes: lookups.caregiverListingTypes,
                            units: lookups.units
                        })
                    );
                }

                return this.invoicesRepository.getInvoiceLookups().pipe(
                    map((response) =>
                        loadInvoicesLookupsSuccess({
                            facilities: response.securityLookups.facilities,
                            systems: response.securityLookups.systems,
                            disputeStatuses: response.invoiceDisputeStatuses,
                            disputeReasons: response.invoiceDisputeReasons,
                            disputeReasonsWithDisputeType: response.invoiceDisputeReasonsWithDisputeType,
                            caregiverListingTypes: response.invoiceCaregiverListingTypes.map((x) => {
                                return {
                                    id: x.id,
                                    name: this.hasTerminologyPermission ? findAndReplaceTerminology(x.name) : x.name
                                } as InvoiceCaregiverListingTypeLookup;
                            }),
                            units: response.securityLookups.units
                        })
                    ),
                    catchError((error) => of(loadInvoicesLookupsFail({ error })))
                );
            })
        )
    );

    hasTerminologyPermission = false;

    constructor(
        private readonly actions$: Actions,
        private readonly invoicesRepository: InvoicesRepositoryService,
        private readonly store: Store<{}>,
        private readonly identityService: IdentityService
    ) {
        this.hasTerminologyPermission = this.identityService.hasSecurityPermission(
            ApplicationPermissions.LocumsTerminology
        );
    }
}
