import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SystemFormField } from '../models';

export function systemFormFieldsValidator(fields: SystemFormField[]): ValidatorFn {
    return function (group: UntypedFormGroup): ValidationErrors {
        for (const field of fields) {
            if (!field.isRequired) {
                continue;
            }
            if (!(field.fieldName in group.controls)) {
                continue;
            }
            const control = group.get(field.fieldName);

            if (control.dirty && !control.value) {
                control.setErrors({
                    required: true
                });
            }
        }

        return null;
    };
}

export function enableSystemFormFieldsValidator(fields: SystemFormField[]): ValidatorFn {
    return function (group: UntypedFormGroup): ValidationErrors {
        for (const field of fields) {
            if (!(field.fieldName in group.controls)) {
                continue;
            }
            const control = group.get(field.fieldName);

            if (control.disabled) {
                control.enable();
            }
        }

        return null;
    };
}
