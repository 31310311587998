import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClientsGridComponent } from 'src/app/clinical/components/clients/clients-grid/clients-grid.component';
import { VerifyComponent } from 'src/app/clinical/components/clinical-unit-description/modals/verify.component';
import { CertificationsSkillsComponent } from 'src/app/clinical/components/clinical-unit-description/sections/certifications-and-skills/certifications-skills.component';
import { FacilityContactsComponent } from 'src/app/clinical/components/clinical-unit-description/sections/facility-contacts/facility-contacts.component';
import { FacilityHeaderComponent } from 'src/app/clinical/components/clinical-unit-description/sections/facility-header/facility-header.component';
import { FacilityInformationComponent } from 'src/app/clinical/components/clinical-unit-description/sections/facility-information/facility-information.component';
import { FacilityPatientAgeGroupComponent } from 'src/app/clinical/components/clinical-unit-description/sections/facility-patient-age-group-section/facility-patient-age-group.component';
import { OtherInformationComponent } from 'src/app/clinical/components/clinical-unit-description/sections/other-information/other-information.component';
import { ProviderCoverageComponent } from 'src/app/clinical/components/clinical-unit-description/sections/provider-coverage/provider-coverage.component';
import { ProviderSupportComponent } from 'src/app/clinical/components/clinical-unit-description/sections/provider-support/provider-support.component';
import { SignatureComponent } from 'src/app/clinical/components/clinical-unit-description/sections/signature/signature.component';
import { StateControlledSubstancesComponent } from 'src/app/clinical/components/clinical-unit-description/sections/state-controlled-substances/state-controlled-substances.component';
import { SpecialComponent } from 'src/app/clinical/components/clinical-unit-description/sections/unit-info/special.component';
import { UnitSystemsComponent } from 'src/app/clinical/components/clinical-unit-description/sections/unit-systems/unit-systems.component';
import { WorkExperienceComponent } from 'src/app/clinical/components/clinical-unit-description/sections/work-experience/work-experience.component';
import { UnitDescriptionComponent } from 'src/app/clinical/components/clinical-unit-description/unit-description.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClientDetailComponent } from '../components/clients/client-detail/client-detail.component';
import { ClinicalClientProfileComponent } from '../components/clients/tabs/client-profile/client-profile.component';
import { ClientUnitsComponent } from '../components/clients/tabs/client-unit/client-units.component';
import { ClinicalClientDetailsComponent } from '../components/clients/tabs/clinical-client-details/clinical-client-details.component';
import { UnitDescriptionLoadingDialogComponent } from '../components/clinical-unit-description/modals/loading/loading.component';
import { ChartingSystemNamePipe } from '../components/clinical-unit-description/pipes/charting-system-name-pipe';
import { ClinicalTrackNamePipe } from '../components/clinical-unit-description/pipes/clinical-track-name-pipe';
import { RequirementNamePipe } from '../components/clinical-unit-description/pipes/requirement-name-pipe';
import { DisciplinesNamePipe } from '../components/clinical-unit-description/pipes/disciplines-name-pipe';
import { SpecialtiesNamePipe } from '../components/clinical-unit-description/pipes/specialties-name-pipe';
import { BoardCertificationsComponent } from '../components/clinical-unit-description/sections/board-certifications/board-certifications.component';
import { CrnaCertificationComponent } from '../components/clinical-unit-description/sections/crna-certification/crna-certification.component';
import { OtherRequirementComponent } from '../components/clinical-unit-description/sections/other-requirement/other-requirement.component';
import { ShiftDetailsComponent } from '../components/clinical-unit-description/sections/shift-details/shift-details.component';
import { StateLicensesComponent } from '../components/clinical-unit-description/sections/state-licenses/state-licenses.component';
import { ClinicalService } from '../services/clinical.service';
import { clinicalEffects, clinicalReducers, clinicalStoreKey } from '../store';
import { SendLinkModalComponent } from '../components/clinical-unit-description/modals/send-link-modal/send-link-modal.component';

@NgModule({
    exports: [
        FacilityInformationComponent,
        FacilityContactsComponent,
        UnitDescriptionComponent,
        FacilityPatientAgeGroupComponent,
        FacilityHeaderComponent,
        UnitSystemsComponent,
        ProviderSupportComponent,
        CertificationsSkillsComponent,
        WorkExperienceComponent,
        OtherInformationComponent,
        SignatureComponent,
        ProviderCoverageComponent,
        SpecialComponent,
        VerifyComponent,
        ShiftDetailsComponent,
        UnitDescriptionLoadingDialogComponent,
        ClientUnitsComponent,
        ClinicalClientProfileComponent,
        ClinicalClientDetailsComponent,
        ClientDetailComponent,
        ClientsGridComponent,
        BoardCertificationsComponent,
        OtherRequirementComponent,
        StateLicensesComponent,
        StateControlledSubstancesComponent,
        CrnaCertificationComponent,
        SendLinkModalComponent
    ],
    imports: [
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        RouterModule,
        StoreModule.forFeature(clinicalStoreKey, clinicalReducers),
        EffectsModule.forFeature(clinicalEffects)
    ],
    declarations: [
        FacilityHeaderComponent,
        FacilityInformationComponent,
        FacilityContactsComponent,
        UnitDescriptionComponent,
        FacilityPatientAgeGroupComponent,
        UnitSystemsComponent,
        ProviderSupportComponent,
        CertificationsSkillsComponent,
        WorkExperienceComponent,
        OtherInformationComponent,
        SignatureComponent,
        ProviderCoverageComponent,
        SpecialComponent,
        VerifyComponent,
        ShiftDetailsComponent,
        UnitDescriptionLoadingDialogComponent,
        ClientUnitsComponent,
        ClinicalClientProfileComponent,
        ClinicalClientDetailsComponent,
        ClientDetailComponent,
        ClientsGridComponent,
        SpecialtiesNamePipe,
        DisciplinesNamePipe,
        ChartingSystemNamePipe,
        ClinicalTrackNamePipe,
        RequirementNamePipe,
        BoardCertificationsComponent,
        OtherRequirementComponent,
        StateLicensesComponent,
        StateControlledSubstancesComponent,
        CrnaCertificationComponent,
        SendLinkModalComponent
    ],
    providers: [ClinicalService]
})
export class ClinicalSharedModule {}
