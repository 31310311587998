import { FeatureConfirmationText } from 'src/app/facilities/models/feature-confirmation-text.model';
import { FacilityFeatureEnum } from 'src/app/shared/models/facilities/enums/facility-feature.enum';

export class FeatureConfirmationConfiguration {
    private static readonly jobEntityString = 'job';
    private static readonly shiftEntityString = 'shift';

    private static readonly facilityJobApprovalDisableText =
        FeatureConfirmationConfiguration.getDisableApprovalText(FeatureConfirmationConfiguration.jobEntityString);
    private static readonly facilityJobApprovalEnableText =
        FeatureConfirmationConfiguration.getEnableApprovalText(FeatureConfirmationConfiguration.jobEntityString);
    private static readonly facilityJobReleaseEnableText =
        FeatureConfirmationConfiguration.getEnableReleaseText(FeatureConfirmationConfiguration.jobEntityString);
    private static readonly facilityJobReleaseDisableText =
        FeatureConfirmationConfiguration.getDisableReleaseText(FeatureConfirmationConfiguration.jobEntityString);
    private static readonly facilityShiftApprovalDisableText =
        FeatureConfirmationConfiguration.getDisableApprovalText(FeatureConfirmationConfiguration.shiftEntityString);
    private static readonly facilityShiftApprovalEnableText =
        FeatureConfirmationConfiguration.getEnableApprovalText(FeatureConfirmationConfiguration.shiftEntityString);
    private static readonly facilityShiftReleaseEnableText =
        FeatureConfirmationConfiguration.getEnableReleaseText(FeatureConfirmationConfiguration.shiftEntityString);
    private static readonly facilityShiftReleaseDisableText =
        FeatureConfirmationConfiguration.getDisableReleaseText(FeatureConfirmationConfiguration.shiftEntityString);

    public static contractGroupFeatureConfirmationTextConfiguration = [] as FeatureConfirmationText[];

    public static facilityFeatureConfirmationTextConfiguration =
        [
            {
                id: FacilityFeatureEnum.JobApproval,
                enabledText: FeatureConfirmationConfiguration.facilityJobApprovalEnableText,
                disabledText: FeatureConfirmationConfiguration.facilityJobApprovalDisableText
            },
            {
                id: FacilityFeatureEnum.JobRelease,
                enabledText: FeatureConfirmationConfiguration.facilityJobReleaseEnableText,
                disabledText: FeatureConfirmationConfiguration.facilityJobReleaseDisableText
            },
            {
                id: FacilityFeatureEnum.ShiftApproval,
                enabledText: FeatureConfirmationConfiguration.facilityShiftApprovalEnableText,
                disabledText: FeatureConfirmationConfiguration.facilityShiftApprovalDisableText
            },
            {
                id: FacilityFeatureEnum.ShiftRelease,
                enabledText: FeatureConfirmationConfiguration.facilityShiftReleaseEnableText,
                disabledText: FeatureConfirmationConfiguration.facilityShiftReleaseDisableText
            },
        ] as FeatureConfirmationText[];

    private static getDisableApprovalText(entityType: string): string {
        return `<strong>Please be advised for the following: </strong><br />
        <ul>
            <li>All ${entityType}s in a pending status will be automatically changed to approved.</li>
            <li>All ${entityType}s in a rejected status will be automatically deleted.</li>
            <li>All ${entityType}s added after this feature has been disabled will default to approved.</li>
        </ul>`;
    }

    private static getEnableApprovalText(entityType: string): string {
        return `<strong>Please be advised for the following: </strong><br />
        <ul>
            <li>All ${entityType}s added after this feature has been enabled will default into a pending approval status and will need to be approved by an authorized approver before becoming visible to vendors.</li>
            <li>Be sure to set up Client users with ${entityType} approval permissions.</li>
            <li>Be sure to set up Client users with ${entityType} approval process permissions.</li>
        </ul>`;
    }

    private static getDisableReleaseText(entityType: string): string {
        return `<strong>Please be advised for the following: </strong><br />
        <ul>
            <li>All ${entityType}s in not released or partially released will be released to all eligible vendors.</li>
        </ul>`;
    }

    private static getEnableReleaseText(entityType: string): string {
        return `<strong>Please be advised for the following: </strong><br />
        <ul>
            <li>All ${entityType}s added after this feature has been enabled will by default NOT be visible to vendors until released.</li>
            <li>Be sure to setup appropriate client and/or admin users with permissions to create release groups.</li>
            <li>Be sure to setup at least one client and/or admin user to have permissions to release ${entityType}s.</li>
        </ul>
        To do this, reach out to your support contact for assistance.`;
    }
}
