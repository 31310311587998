import { VendorCandidatesListEffect } from 'src/app/admin/store/effects/admin.vendor-candidates.effects';
import { SubmittalsEffects } from 'src/app/admin/store/effects/submittals.effects';
import { VendorProfileEffects } from 'src/app/admin/store/effects/vendor-profile.effects';
import { VendorCandidateFilesEffects } from 'src/app/admin/store/effects/vendor-candidate-detail-files.effects';
import { VendoInsuranceEffects } from './vendor-insurance.effects';
import { VendorFacilitiesEffects } from 'src/app/admin/store/effects/vendor-facilities.effects';
import { VendorBankingEffects } from 'src/app/admin/store/effects/vendor-banking.effects';
import { VendorNotesEffects } from 'src/app/admin/store/effects/vendor-notes.effects';
import { VendorProfileWebsiteEffects } from 'src/app/admin/store/effects/vendor-profile-website.effects';
import { FacilityAssociationVendorsEffects } from 'src/app/facilities/store/effects/association-vendors.effects';
import { AdminJobsEffects } from 'src/app/admin/store/effects/admin-jobs.effects';
import { VendorProfileLocationsEffects } from 'src/app/admin/store/effects/vendor-locations.effects';
import { VendorProfileCertificationsEffects } from 'src/app/admin/store/effects/vendor-profile-certifications.effects';
import { FacilitySystemsVendorsEffects } from 'src/app/admin/store/effects/facility-systems.effects';
import { VendorFilesEffects } from 'src/app/admin/store/effects/vendor-detail-files.effects';
import { SystemContactsProfileEffects } from 'src/app/admin/store/effects/system-contacts-profile.effects';
import { SubmittalsDetailEffects } from './submittals-detail.effects';
import { VendorProfileJobRulesEffects } from './vendor-profile-job-rules.effects';

export const adminEffects = [
    VendorCandidatesListEffect,
    VendorProfileEffects,
    VendorCandidateFilesEffects,
    VendorFacilitiesEffects,
    SubmittalsEffects,
    SubmittalsDetailEffects,
    VendoInsuranceEffects,
    VendorNotesEffects,
    VendorBankingEffects,
    VendorProfileWebsiteEffects,
    FacilityAssociationVendorsEffects,
    AdminJobsEffects,
    VendorProfileLocationsEffects,
    VendorProfileCertificationsEffects,
    FacilitySystemsVendorsEffects,
    VendorFilesEffects,
    SystemContactsProfileEffects,
    VendorProfileJobRulesEffects
];
