import { CopyFromFacility } from 'src/app/facilities/models/copy-from-facility.lookup';
import { AcceptedVendor } from 'src/app/facilities/models/accepted-vendor.model';
import { ProfessionEdit } from 'src/app/facilities/models/profession-edit.model';
import { FacilityProfile } from 'src/app/facilities/models/facility-profile.model';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { interpolateParams } from 'src/app/core/utils/interpolate-params';
import { FacilityAdministrationLookups } from 'src/app/facilities/models/facility-administration-lookups.model';
import { AdminFacilityFeatures, FacilityAdministration } from 'src/app/facilities/models/facility-administration.model';
import { FacilitiesAdminUrls } from 'src/app/shared/models/facilities/enums/facilities-admin-urls.enum';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { ReleasePermissions } from 'src/app/facilities/models/release-permissions.model';
import { tap } from 'rxjs/operators';
import { ReleaseGroup } from 'src/app/facilities/models/release-group.model';
import { ScheduledReleaseRule } from 'src/app/jobs/models/lookups/scheduled-release-rule.lookup';
import { Expertise } from 'src/app/shared/models/shifts/expertise.model';
import { ReleaseGroupType } from 'src/app/facilities/models/enums/release-group-type.enum';
import { downloadBlob, interpolateUrl } from 'src/app/core/utils';
import {
    FacilitiesVendorStatusUpdate,
    FacilityVendor,
    FacilityVendorLookups,
    FacilityVendorStatusHistory,
    FacilityVendorStatusHistoryNote,
    FacilityVendorStatusUpdate,
    FacilityVendorTSUpdate
} from 'src/app/facilities/models/facility-vendor.model';
import { SortTypes } from 'src/app/shared/models';
import {
    FeatureSettingCategory,
    FeatureSettingItem
} from 'src/app/shared/feature-settings/models/feature-features.models';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { interpolateFetchArguments2 } from 'src/app/core/utils/interpolate-fetch-arguments2';
import { ConnectFacilityFeature } from '../models';

@Injectable({
    providedIn: 'root'
})
export class FacilitiesService {
    constructor(private _http: HttpClient, @Inject(APP_CONFIG) private _settings: Settings) {}

    getLookups(): Observable<FacilityAdministrationLookups> {
        const url = `${FacilitiesAdminUrls.FACILITIES_LOOKUPS}`;
        return this._http.get<FacilityAdministrationLookups>(`${this._settings.CORE}${url}`);
    }

    getFacilities(request: GridSearchQuery) {
        const params = interpolateFetchArguments2(request);
        const url = `${this._settings.CORE}${FacilitiesAdminUrls.FACILITIES_LIST}`;
        return this._http.get<PagingToken<FacilityAdministration[]>>(`${url}?${params}`);
    }

    getFacilitiesForExport(request: GridSearchQuery): Observable<Blob> {
        const params = interpolateFetchArguments2(request);
        const url = `${this._settings.CORE}${FacilitiesAdminUrls.FACILITIES_LIST_FOR_EXPORT}`;
        return this._http.get(`${url}?${params}`, { responseType: 'blob' });
    }

    getFacilityProfile(id: number): Observable<FacilityProfile> {
        const url = `${FacilitiesAdminUrls.FACILITY}`;
        return this._http.get<FacilityProfile>(`${this._settings.CORE}${url}/${id}`);
    }

    getConnectFacilityFeatures(facilityId: number): Observable<ConnectFacilityFeature> {
        const url = interpolateUrl(`${FacilitiesAdminUrls.CONNECT_FACILITY_FEATURES}`, { facilityId });
        return this._http.get<ConnectFacilityFeature>(`${this._settings.CORE}${url}`);
    }

    getFacilitySettings(facilityId: number): Observable<FeatureSettingCategory[]> {
        const url = interpolateUrl(`${FacilitiesAdminUrls.FACILITY_SETTINGS}`, { facilityId });

        return this._http.get<FeatureSettingCategory[]>(`${this._settings.CORE}${url}`);
    }

    getContractGroupFeatures(contractGroupId: number): Observable<ConnectFacilityFeature> {
        const url = interpolateUrl(`${FacilitiesAdminUrls.CONTRACT_GROUP_FEATURES}`, { contractGroupId });
        return this._http.get<ConnectFacilityFeature>(`${this._settings.CORE}${url}`);
    }

    updateFacilitySettings(facilityId: number, setting: FeatureSettingItem): Observable<any> {
        const url = interpolateUrl(`${FacilitiesAdminUrls.FACILITY_SETTINGS_UPDATE}`, {
            facilityId,
            featureId: setting.featureId
        });
        const body = {
            isEnabled: setting.isEnabled,
            value: setting.value
        };

        return this._http.put<any>(`${this._settings.CORE}${url}`, body);
    }

    updateFacilityTermSheetRequired(facilityId: number, isRequired: boolean): Observable<any> {
        const url = `${FacilitiesAdminUrls.FACILITY}`;
        return this._http.put<any>(`${this._settings.CORE}${url}/${facilityId}`, { TermSheetRequired: isRequired });
    }

    getReleaseGroupsPermissions(id: number, releaseType: ReleaseGroupType): Observable<ReleasePermissions> {
        const url = `${FacilitiesAdminUrls.FACILITY_RELEASE_GROUPS}`;
        return this._http.get<ReleasePermissions>(
            `${this._settings.CORE}${url}/secure?facilityId=${id}&releaseType=${releaseType}`
        );
    }

    getScheduledReleaseRulesPermissions(id: number): Observable<ReleasePermissions> {
        const url = `${FacilitiesAdminUrls.FACILITY_SCHEDULED_RELEASE_RULE}`;
        return this._http.get<ReleasePermissions>(`${this._settings.CORE}${url}/secure?facilityId=${id}`);
    }

    getReleaseGroups(id: number, releaseType: ReleaseGroupType): Observable<ReleaseGroup[]> {
        const url = `${FacilitiesAdminUrls.FACILITY_RELEASE_GROUPS}`;
        return this._http.get<ReleaseGroup[]>(
            `${this._settings.CORE}${url}?facilityId=${id}&releaseType=${releaseType}`
        );
    }

    getReleaseGroupVendors(id: number, releaseType: ReleaseGroupType): Observable<AcceptedVendor[]> {
        const url = `${FacilitiesAdminUrls.FACILITY_RELEASE_GROUP_VENDORS}`;
        return this._http.get<AcceptedVendor[]>(
            `${this._settings.CORE}${url}?facilityId=${id}&releaseType=${releaseType}`
        );
    }

    saveReleaseGroup(group: any): Observable<any> {
        const url = `${FacilitiesAdminUrls.FACILITY_RELEASE_GROUPS}`;
        if (!group.id) {
            return this._http.post<any>(`${this._settings.CORE}${url}`, group);
        } else {
            return this._http.put<any>(`${this._settings.CORE}${url}`, group);
        }
    }

    deleteReleaseGroup(groupId: number): Observable<any> {
        const url = `${FacilitiesAdminUrls.FACILITY_RELEASE_GROUPS}`;
        return this._http.delete(`${this._settings.CORE}${url}/${groupId}`);
    }

    getScheduledReleaseRules(id: number): Observable<ScheduledReleaseRule[]> {
        const url = `${FacilitiesAdminUrls.FACILITY_SCHEDULED_RELEASE_RULE}`;
        return this._http.get<ScheduledReleaseRule[]>(`${this._settings.CORE}${url}?facilityId=${id}`);
    }

    getScheduledReleaseRule(id: number): Observable<ScheduledReleaseRule> {
        const url = `${FacilitiesAdminUrls.FACILITY_SCHEDULED_RELEASE_RULE}`;
        return this._http.get<ScheduledReleaseRule>(`${this._settings.CORE}${url}/${id}`);
    }

    deleteScheduledReleaseRule(ruleId: number): Observable<any> {
        const url = `${FacilitiesAdminUrls.FACILITY_SCHEDULED_RELEASE_RULE}`;
        return this._http.delete(`${this._settings.CORE}${url}/${ruleId}`);
    }

    copyReleaseSettingsToFacilities(
        fromFacilityId: number,
        toFacilityIds: number[],
        releaseType: ReleaseGroupType
    ): Observable<any> {
        const url = `${FacilitiesAdminUrls.FACILITY_RELEASE_GROUPS}`;
        return this._http.post(`${this._settings.CORE}${url}/clone`, {
            from: fromFacilityId,
            to: toFacilityIds,
            releaseType: releaseType
        });
    }

    cloneScheduledReleaseRulesToFacilities(fromFacilityId: number, toFacilityIds: number[]): Observable<any> {
        const url = `${FacilitiesAdminUrls.FACILITY_SCHEDULED_RELEASE_RULE}`;
        return this._http.post(`${this._settings.CORE}${url}/clone`, { from: fromFacilityId, to: toFacilityIds });
    }

    getCopyFromFacilities(facilityId: number, releaseType: ReleaseGroupType): Observable<CopyFromFacility[]> {
        const url = `${FacilitiesAdminUrls.COPY_FROM_FACILITIES}`;
        return this._http.get<CopyFromFacility[]>(
            `${this._settings.CORE}${url}?facilityId=${facilityId}&releaseType=${releaseType}`
        );
    }

    copyReleaseSettingsFromFacility(
        fromFacilityId: number,
        toFacilityId,
        releaseType: ReleaseGroupType
    ): Observable<any> {
        const url = `${FacilitiesAdminUrls.COPY_SETTINGS_FROM}`;
        return this._http.post<any>(`${this._settings.CORE}${url}`, {
            from: fromFacilityId,
            to: [toFacilityId],
            releaseType: releaseType
        });
    }

    getScheduledReleaseRuleProfessions(professionId: number): Observable<ProfessionEdit[]> {
        const url = `${FacilitiesAdminUrls.FACILITY_RELEASE_RULE_PROFESSIONS}`;
        return this._http.get<ProfessionEdit[]>(`${this._settings.CORE}${url}?facilityId=${professionId}`);
    }

    getScheduledReleaseRuleExpertises(professionId: number): Observable<Expertise[]> {
        const url = `${FacilitiesAdminUrls.FACILITY_RELEASE_RULE_EXPERTISES}`;
        return this._http.get<Expertise[]>(`${this._settings.CORE}${url}/${professionId}/expertises`);
    }

    getScheduledReleaseRuleVendors(facilityId: number): Observable<AcceptedVendor[]> {
        const url = `${FacilitiesAdminUrls.FACILITY_RELEASE_VENDORS}`;
        return this._http.get<AcceptedVendor[]>(`${this._settings.CORE}${url}?facilityId=${facilityId}`);
    }

    saveScheduledReleaseRule(rule: any): Observable<any> {
        const url = `${FacilitiesAdminUrls.SAVE_RULE}/${rule.timeOfDayUtc ? 'Delayed' : 'Immediate'}`;
        if (!rule.id) {
            return this._http.put<any>(`${this._settings.CORE}${url}`, rule);
        } else {
            return this._http.post<any>(`${this._settings.CORE}${url}`, rule);
        }
    }

    getVendors(
        facilityId: number,
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = this.renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgsObj
        });

        const url = interpolateUrl(FacilitiesAdminUrls.VENDORS_GRID, { facilityId });
        return this._http.get<FacilityVendor>(`${this._settings.CORE}${url}`, { params });
    }

    getFacilityVendorIds(
        facilityId: number,
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ): Observable<number[]> {
        const sortArgsObj = sortArgs;
        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        let matchArgsObj = {};
        if (matchArgs) {
            matchArgsObj = this.renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgsObj
        });

        const url = interpolateUrl(FacilitiesAdminUrls.VENDOR_IDS, { facilityId });
        return this._http.get<number[]>(`${this._settings.CORE}${url}`, { params });
    }

    getVendorLookups() {
        const url = FacilitiesAdminUrls.VENDOR_LOOKUPS;
        return this._http.get<FacilityVendorLookups>(`${this._settings.CORE}${url}`);
    }

    updateTermSheetSigned(facilityId: number, params: FacilityVendorTSUpdate) {
        const url = interpolateUrl(FacilitiesAdminUrls.VENDOR_TSSSIGNED, { facilityId });
        return this._http.put<FacilityVendorTSUpdate>(`${this._settings.CORE}${url}`, params);
    }

    getVendorCount(facilityId: number, isActive: boolean): Observable<number> {
        const url = interpolateUrl(FacilitiesAdminUrls.VENDOR_COUNT, { facilityId, isActive });
        return this._http.get<number>(`${this._settings.CORE}${url}`);
    }

    exportAllVendors(
        facilityId: number,
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;
        const paginationObj = pagination;

        sortArgsObj['sort.field'] = sortArgsObj['sortField'];
        sortArgsObj['sort.type'] = sortArgsObj['sortType'] === 'desc' ? 'descending' : 'asc';
        paginationObj['pagesize'] = paginationObj['pageSize'];
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;
        delete paginationObj.pageSize;

        if (matchArgs) {
            matchArgsObj = this.renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgsObj
        });

        const url = interpolateUrl(`${FacilitiesAdminUrls.VENDORS_GRID}/export`, { facilityId });
        return this._http.get(`${this._settings.CORE}${url}`, { params, responseType: 'blob' });
    }

    exportSelectedVendors(facilityId: number, vendorIds: number[]): Observable<Blob> {
        const url = interpolateUrl(`${FacilitiesAdminUrls.VENDOR_EXPORT_SELECTED}`, { facilityId });
        return this._http.post(`${this._settings.CORE}${url}`, vendorIds, { responseType: 'blob' }).pipe(
            tap((resultBlob) => {
                downloadBlob(resultBlob, 'vendors.xlsx');
            })
        );
    }

    updateVendorStatus(facilityId: number, params: FacilityVendorStatusUpdate) {
        const url = interpolateUrl(FacilitiesAdminUrls.VENDOR_STATUSUPDATE, { facilityId });
        return this._http.put<FacilityVendorStatusUpdate>(`${this._settings.CORE}${url}`, params);
    }

    updateVendorFacilitiesStatus(params: FacilitiesVendorStatusUpdate) {
        return this._http.put(`${this._settings.CORE}${FacilitiesAdminUrls.VENDOR_FACILITIES_STATUSUPDATE}`, params);
    }

    getVendorHistory(facilityId: number, vendorId: number): Observable<FacilityVendorStatusHistory[]> {
        const url = interpolateUrl(FacilitiesAdminUrls.VENDOR_HISTORY, { facilityId, vendorId });
        return this._http.get<FacilityVendorStatusHistory[]>(`${this._settings.CORE}${url}`);
    }

    postVendorHistory(facilityId: number, vendorId: number, params: FacilityVendorStatusHistoryNote) {
        const url = interpolateUrl(FacilitiesAdminUrls.VENDOR_HISTORY, { facilityId, vendorId });
        return this._http.post<any>(`${this._settings.CORE}${url}`, params);
    }

    updateVendorHistory(
        facilityId: number,
        vendorId: number,
        params: FacilityVendorStatusHistoryNote
    ): Observable<any> {
        const url = interpolateUrl(FacilitiesAdminUrls.VENDOR_HISTORY, { facilityId, vendorId });
        return this._http.put<any>(`${this._settings.CORE}${url}`, params);
    }

    getFeatures(): Observable<AdminFacilityFeatures> {
        const url = FacilitiesAdminUrls.FACILITY_ADMIN_FEATURES;
        return this._http.get<AdminFacilityFeatures>(`${this._settings.CORE}${url}`);
    }

    // make shared
    private readonly renameKeys = (obj, prefix) =>
        Object.keys(obj).reduce(
            (acc, key) => ({
                ...acc,
                ...{ [`${prefix}.${key}`]: obj[key] }
            }),
            {}
        );
}
