import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import {
    loadInvoiceAttachmentsEnhancedSuccess,
    loadInvoiceAttachmentsEnhanced,
    loadInvoiceAttachmentsEnhancedFail,
    loadInvoiceAttachmentPreview,
    loadInvoiceAttachmentPreviewSuccess,
    loadInvoiceAttachmentPreviewFailure,
    resetInvoiceAttachmentPreview,
    loadInvoiceAttachmentPreviewTotalPagesSuccess
} from '../invoices.actions';
import { FileAttachment } from 'src/app/shared/models/attachment';

export interface InvoiceAttachmentsEnhancedState {
    invoiceFileAttachments: FileAttachment[];
    invoiceAttachmentPreview: Blob;
    loading: LoadingTypes;
    error: string;
    totalPages: number;
}

export const initialState: InvoiceAttachmentsEnhancedState = {
    invoiceFileAttachments: [],
    invoiceAttachmentPreview: new Blob(),
    loading: LoadingTypes.INIT,
    error: '',
    totalPages: 0
};

export function invoiceAttachmentsEnhancedReducer(state: InvoiceAttachmentsEnhancedState, action: Action) {
    return createReducer(
        initialState,
        on(loadInvoiceAttachmentsEnhanced, (state, action) => ({
            ...state,
            invoiceFileAttachments: [],
            error: '',
            loading: LoadingTypes.LOADING,
            totalPages: 0
        })),

        on(loadInvoiceAttachmentsEnhancedSuccess, (state, action) => ({
            ...state,
            invoiceFileAttachments: action.invoiceFileAttachments,
            error: '',
            loading: LoadingTypes.LOADED,
            totalPages: 0
        })),

        on(loadInvoiceAttachmentsEnhancedFail, (state, action) => ({
            ...state,
            invoiceFileAttachments: [],
            error: action.error,
            loading: LoadingTypes.FAIL,
            totalPages: 0
        })),

        on(loadInvoiceAttachmentPreview, resetInvoiceAttachmentPreview, (currentState) => ({
            ...currentState,
            error: '',
            invoiceAttachmentPreview: new Blob()
        })),

        on(loadInvoiceAttachmentPreviewSuccess, (currentState, successAction) => ({
            ...currentState,
            error: '',
            invoiceAttachmentPreview: successAction.file
        })),

        on(loadInvoiceAttachmentPreviewFailure, (currentState, failureAction) => ({
            ...currentState,
            error: JSON.stringify(failureAction.error),
            jobAttachmentPreview: undefined
        })),

        on(loadInvoiceAttachmentPreviewTotalPagesSuccess, (currentState, successAction) => ({
            ...currentState,
            error: '',
            totalPages: successAction.totalPages
        }))
    )(state, action);
}

export function reducer(state: InvoiceAttachmentsEnhancedState, action: Action) {
    return invoiceAttachmentsEnhancedReducer(state, action);
}
