import { Component, Input, ContentChild, TemplateRef } from '@angular/core';
import { PortalHeaderBadge } from '../../models/page-header-badge.model';
import { PortalHeaderLink } from '../../models/portal-header-link.model';

@Component({
    selector: 'ayac-portal-page-header',
    templateUrl: './portal-page-header.component.html',
    styleUrls: ['./portal-page-header.component.scss']
})
export class PortalPageHeaderComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() isOneRow: boolean;
    @Input() badge: PortalHeaderBadge;
    @Input() link: PortalHeaderLink;
    @ContentChild('pageHeaderToolbar', { static: true }) pageHeaderToolbar: TemplateRef<any>;
    @ContentChild('pageHeaderTitle', { static: true }) pageHeaderTitle: TemplateRef<any>;
    @ContentChild('pageHeaderBanner') pageHeaderBanner: TemplateRef<any>;
    @ContentChild('pageHeaderSuffix') pageHeaderSuffix: TemplateRef<any>;

    constructor() {}

    get classNames() {
        const ngClass = {
            'portal-page-header-one-row': this.isOneRow,
            'portal-page-header-has-banner': !!this.pageHeaderBanner
        };
        if (this.pageHeaderBanner) {
            ngClass['portal-page-header-one-row'] = false;
        }

        return ngClass;
    }
}
