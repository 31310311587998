export enum SubmittalTypeEnum {
    Aya = 0,
    APN = 1
}

export enum OfferType {
    Agency = 1,
    Direct = 2
}

export enum CandidateOfferShiftType {
    Standard = 1,
    Alternating = 2,
    Rotating = 3
}
