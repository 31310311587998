import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CandidateJobBook, CandidateJobSubmittalProfile } from 'src/app/submittals/models';
import * as dayjs from 'dayjs';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';

@Component({
    selector: 'ayac-book-candidate-modal',
    templateUrl: './book-candidate-modal.component.html',
    styleUrls: ['./book-candidate-modal.component.scss']
})
export class BookCandidateModalComponent extends UnsubscribeOnDestroy implements OnInit {
    form = this.fb.group({
        startDate: ['', Validators.required],
        endDate: [''],
        shiftDuration: ['', Validators.required],
        workDays: [[], Validators.required]
    });

    featureFlag = FeatureFlag;

    constructor(
        private readonly dialogRef: MatDialogRef<BookCandidateModalComponent, CandidateJobBook>,
        @Inject(MAT_DIALOG_DATA) private readonly data: { profile: CandidateJobSubmittalProfile },
        private readonly fb: UntypedFormBuilder,
        private readonly featureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.profile) {
            this.form.patchValue({
                startDate: new Date(this.profile.startDate),
                endDate: new Date(this.profile.startDate),
                shiftDuration: Number(this.profile.shiftDuration)
            });
        }
    }

    get profile(): CandidateJobSubmittalProfile {
        return this.data.profile;
    }

    onSave() {
        const data = this.form.value;

        const result = {
            startDate: dayjs(data.startDate).format('YYYY-MM-DDT[00:00:00]'),
            endDate: dayjs(data.endDate).format('YYYY-MM-DDT[00:00:00]'),
            shiftDuration: data.shiftDuration,
            workDays: data.workDays
        } as CandidateJobBook;

        this.dialogRef.close(result);
    }

    onClose() {
        this.dialogRef.close();
    }
}
