import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminFeatureKey } from 'src/app/admin/store/selectors/admin.selectors';
import { AdminState } from 'src/app/admin/store/state/admin.state';
import { LoadingTypes } from 'src/app/shared/models';

export const selectVendorProfileFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectContractLines = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.contractLineTypes
);

export const selectVendorSpecificContractLineTypes = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.vendorContractLineTypes
);

export const selectContractLinesWithVendorSpecific = createSelector(
    selectContractLines,
    selectVendorSpecificContractLineTypes,
    (lines, vendorSpecific) => ({ lines, vendorSpecific })
);

export const selectVendorDetails = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.details
);

export const selectVendorBanks = createSelector(selectVendorProfileFeature, (state) => state.vendorProfileState.banks);

export const selectVendorBusinessTypes = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.businessTypes
);

export const selectVendorBanksWithSelectedName = (bankId: number) =>
    createSelector(selectVendorBanks, selectVendorBankName(bankId), (banks, bankName) => {
        return {
            banks,
            bankName
        };
    });

export const selectFilteredVendorBanksByName = (bankName: string) =>
    createSelector(selectVendorBanks, (banks) =>
        bankName.length === 0 ? banks : banks.filter((item) => item.name.toLowerCase().includes(bankName.toLowerCase()))
    );

export const selectVendorBankName = (bankId: number) =>
    createSelector(selectVendorBanks, (banks) => banks.find((item) => item.id === bankId)?.name ?? '');

export const selectHasChanges = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.hasChanges
);

export const selectVendorWebsiteTypes = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.websiteTypes
);

export const selectVendorWebsites = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.websites
);

export const selectVisibleVendorWebsites = createSelector(selectVendorWebsites, (sites) =>
    sites.filter((item) => !item.isDeleted)
);

export const selectWebsitesToDelete = createSelector(selectVendorWebsites, (sites) =>
    sites.filter((item) => item.id > 0 && item.isDeleted)
);

export const selectWebsitesToUpdate = createSelector(selectVendorWebsites, (sites) =>
    sites.filter((item) => item.url.length > 0 && item.id > 0 && !item.isDeleted)
);

export const selectWebsitesToAdd = createSelector(selectVendorWebsites, (sites) =>
    sites.filter((item) => item.url.length > 0 && item.id <= 0 && !item.isDeleted)
);

export const selectWebsitesWithChanges = createSelector(selectHasChanges, selectVendorWebsites, (hasChanges, sites) => {
    return {
        hasChanges,
        sites
    };
});

export const selectVendorTypes = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.vendorTypes
);

export const selectSelectedVendorTypes = createSelector(selectVendorProfileFeature, (state) =>
    state.vendorProfileState.selectedVendorTypes.map((item) => {
        return {
            vendorTypeId: item.vendorTypeId,
            type: item.vendorType.type
        };
    })
);

export const selectVendorTypesWithSelected = createSelector(
    selectVendorTypes,
    selectSelectedVendorTypes,
    (types, selectedTypes) => ({ types, selectedTypes })
);

export const selectVendorJobLookups = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.jobLookups
);

export const selectVendorJobPermissions = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.jobPermissions
);

export const selectVendorContactsPagination = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.contactsPagination
);

export const selectJobRulesList = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.jobRulesList
);

export const selectJobRuleDetails = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.jobRuleDetails
);

export const selectJobRuleProfessionTreeValues = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.professionTreeValues
);

export const selectIsVendorJobRulesLoading = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.loading === LoadingTypes.LOADING
);

export const selectRuleProfessionTypes = createSelector(
    selectVendorProfileFeature,
    (state) => state.vendorProfileState.professionTypes
);
