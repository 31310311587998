import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TimepickerPopupDialogComponent } from './timepicker-popup-dialog.component';
import * as moment from 'moment';

@Component({
    selector: 'timepicker-popup',
    templateUrl: 'timepicker-popup.component.html',
    styleUrls: ['timepicker-popup.component.scss']
})
export class TimepickerPopupComponent {
    _dateModel: Date;
    viewModel: string;
    @Input()
    set dateModel(value: Date) {
        const date = moment(value);
        this._dateModel = !this.isIosMinDate(value) && date.isValid() ? date.toDate() : null;
        this.viewModel = !this.isIosMinDate(value) && date.isValid() ? date.format('HH:mm') : null;
    }
    get dateModel() {
        return this._dateModel;
    }
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Output() dateModelChange: EventEmitter<Date> = new EventEmitter<Date>();
    timeMenu: any;
    constructor(public dialog: MatDialog) {}

    onChange(event: Date) {
        this.dateModelChange.emit(event);
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(TimepickerPopupDialogComponent, {
            data: {
                dateModel: this.dateModel,
                minDate: this.minDate,
                maxDate: this.maxDate
            },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.dateModel = result;
                this.onChange(result);
            }
        });
    }

    private isIosMinDate(value: any) {
        const date = moment(value);
        //IOS issue
        let isMinDate = false;
        if (value && value.toString()) {
            if (value.toString().includes('0001')) {
                if (date.year() === 2001) {
                    isMinDate = true;
                }
            }
        }

        return isMinDate;
    }
}
