import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Inject, Injectable } from '@angular/core';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { InvoicesRepositoryService } from '../../services/invoices-repository.service';
import {
    loadInvoiceDetails,
    loadInvoiceDetailsFail,
    loadInvoiceDetailsSuccess,
    openInvoiceAttachmentInViewer
} from '../invoices.actions';
import { of, throwError } from 'rxjs';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { ToasterService } from 'src/app/core/services';
import { VendorExpensesService } from 'src/app/vendor-expenses/services/vendor-expenses.service';
import { FacilityExpenseCaps } from 'src/app/vendor-expenses/models/facility-expense-caps.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { InvoiceDetails, InvoiceLineItem, InvoiceLineItemTypes } from '../../models';

@Injectable()
export class InvoiceDetailsEffect extends BaseEffect {
    getInvoiceDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadInvoiceDetails),
            switchMap((action) =>
                this.invoicesRepository.getInvoiceDetails(action.id, action.isBillable).pipe(
                    switchMap((invoiceDetails) =>
                        this.ld.isEnabled(FeatureFlag.EnableConnectExpenses).pipe(
                            switchMap((isEnabled) => {
                                if (isEnabled) {
                                    return this.vendorExpenseService
                                        .getAllFacilityExpenseCaps(invoiceDetails.facilityId)
                                        .pipe(
                                            catchError((error: HttpErrorResponse) => {
                                                return throwError(error);
                                            }),
                                            map((facilityExpenseCaps: FacilityExpenseCaps[]) => {
                                                const updatedInvoiceDetails = this.updateInvoiceDetailsWithCaps(
                                                    invoiceDetails,
                                                    facilityExpenseCaps
                                                );
                                                return loadInvoiceDetailsSuccess(updatedInvoiceDetails);
                                            })
                                        );
                                } else {
                                    return of(loadInvoiceDetailsSuccess({ invoiceDetails }));
                                }
                            })
                        )
                    ),
                    catchError((error: unknown) => of(loadInvoiceDetailsFail({ error })))
                )
            )
        );
    });

    loadInvoiceDetailsFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadInvoiceDetailsFail),
                tap((action) => this.handleError(action.error))
            );
        },
        { dispatch: false }
    );

    openAttachmentInViewer$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openInvoiceAttachmentInViewer),
                tap((action) => {
                    this.window.open(
                        `/#/client/invoiced/${action.invoiceId}/attachments/${action.documentId}/${action.documentName}`,
                        '_blank'
                    );
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        @Inject('Window') private readonly window: Window,
        private readonly actions$: Actions,
        private readonly invoicesRepository: InvoicesRepositoryService,
        private readonly vendorExpenseService: VendorExpensesService,
        private readonly ld: LDFeatureManager,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }

    updateInvoiceDetailsWithCaps(invoiceDetails: InvoiceDetails, facilityExpenseCaps: FacilityExpenseCaps[]) {
        const updatedLineItems = invoiceDetails.lineItems.map((lineItem) => {
            // find matching FacilityExpenseCaps by matching lineItem.paymentTypeId to cap.paymentTypeId
            const matchingCaps = facilityExpenseCaps?.find((cap) => cap.paymentTypeId === lineItem.paymentTypeId);
            // if a matching cap is found, update facilityExpenseCaps for that line item
            if (matchingCaps && lineItem.expenseId && lineItem.expenseId > 0) {
                lineItem.isAmountOverCap = this.isAmountOverCaps(lineItem, matchingCaps);
            }
            return {
                ...lineItem,
                facilityExpenseCaps: matchingCaps || null // set to null if no match
            };
        });
        // Now calculate hasExpenses and hasCap
        invoiceDetails.hasExpenses = updatedLineItems.some((item) => item.expenseId && item.expenseId > 0);
        invoiceDetails.hasCap = updatedLineItems.some((item) => item.facilityExpenseCaps);
        return loadInvoiceDetailsSuccess({
            invoiceDetails: {
                ...invoiceDetails,
                lineItems: updatedLineItems
            }
        });
    }

    isAmountOverCaps(invoiceDetails: InvoiceLineItem, facilityExpenseCaps: FacilityExpenseCaps): boolean {
        const expenseAmount = invoiceDetails.gross;
        const capAmount = facilityExpenseCaps.amount;

        switch (invoiceDetails.lineItemTypeId) {
            case InvoiceLineItemTypes.Airfare:
                return expenseAmount > capAmount;
            case InvoiceLineItemTypes.CarRental:
            case InvoiceLineItemTypes.Hotel:
                return this.calculateIsAmountOverCaps(capAmount, invoiceDetails.totalDays, expenseAmount);
            default:
                return false;
        }
    }

    calculateIsAmountOverCaps(capAmount: number, totalDays: number, expenseAmount: number): boolean {
        const totalExpenseCaps = capAmount * totalDays;
        return expenseAmount > totalExpenseCaps;
    }
}
