import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';
import {
    componentLoaded,
    loadFacilities,
    loadFacilitiesFail,
    loadFacilitiesSuccess
} from '../perdiemscheduler.actions';

export interface PerdiemSchedulerFacilitiesState {
    list: ListItem[];
    loading: LoadingTypes;
}

export const getInitialPerdiemSchedulerFacilitiesState = (): PerdiemSchedulerFacilitiesState => ({
    list: [],
    loading: LoadingTypes.INIT
});

export function perdiemSchedulerFacilitiesReducer(state: PerdiemSchedulerFacilitiesState, action: Action) {
    return createReducer(
        getInitialPerdiemSchedulerFacilitiesState(),
        on(loadFacilities, componentLoaded, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),
        on(loadFacilitiesSuccess, (state, action) => ({
            ...state,
            list: action.facilities,
            loading: LoadingTypes.LOADED
        })),
        on(loadFacilitiesFail, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADED
        }))
    )(state, action);
}

export function reducer(state: PerdiemSchedulerFacilitiesState | undefined, action: Action) {
    return perdiemSchedulerFacilitiesReducer(state, action);
}
