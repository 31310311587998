import { Component, Input } from '@angular/core';

import { ExpenseAttachmentDto } from 'src/app/shared/models/expenses/expense-attachments.model';
import { ExpensesService } from '../../services/expenses.service';
import { takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy, Utilities } from 'src/app/core/utils';

@Component({
    selector: 'ayac-expense-attachments',
    templateUrl: './expense-attachments.component.html',
    styleUrls: ['./expense-attachments.component.scss']
})
export class ExpenseAttachmentsComponent extends UnsubscribeOnDestroy {
    @Input() attachments: ExpenseAttachmentDto[] | undefined;

    constructor(private readonly expensesService: ExpensesService) {
        super();
    }

    downloadFile(item: ExpenseAttachmentDto): void {
        if (item) {
            this.expensesService
                .getExpenseAttachment(item?.attachmentId)
                .pipe(takeUntil(this.d$))
                .subscribe((file) => Utilities.downloadFile(new File([file], item.fileName ?? '')));
        }
    }
}
