export function parseBoolean(val: string | boolean | number): boolean {
    if (typeof val === 'boolean') {
        return val;
    }
    if (typeof val === 'string') {
        return val === 'true';
    }
    if (typeof val === 'number') {
        return val !== 0;
    }
    return Boolean(val);
}