import { Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
    selector: 'kendo-worker-details-label',
    template: `
        <div class="worker-details-container">
            <div class="field-title">
                <span>
                    <span>{{ to.label }}</span>
                    <formly-help-text [helpText]="props.helpText"></formly-help-text>
                </span>
            </div>
            <div class="field-value">
                {{ model[field.key.toString()] || 'N/A' }}
            </div>
        </div>
    `,
    styleUrls: ['./kendo-worker-details-label.component.scss']
})
export class KendoWorkerDetailsComponent extends FieldType<FieldTypeConfig> {}
