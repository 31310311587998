import { Directive, HostListener, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import * as coreActions from 'src/app/core/state/actions'

@Directive({
    selector: "[pendo-track]"
})
export class PendoTrackClickDirective {

    constructor(
        private store: Store
    ){}

    @Input('pendo-track')
    name: string;

    @Input('pendo-metadata')
    metadata: {[key: string]: any};

    @HostListener("click", ["$event"])
    public onClick(): void {
        const event = {
            name: this.name,
            metadata: this.metadata
        }
        this.store.dispatch(coreActions.trackPendo({event}));
    }
}
