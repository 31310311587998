import { Directive, EmbeddedViewRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ifLdFeature]',
    exportAs: 'ifLdFeature'
})
export class IfLDFeatureDirective extends UnsubscribeOnDestroy implements OnInit {
    @Input() ifLdFeature?: FeatureFlag;
    @Input() ifLdFeatureEnabled = true;
    @Input() ifLdFeatureElse: TemplateRef<any> | null = null;

    view!: EmbeddedViewRef<unknown>;
    constructor(
        private readonly _templateRef: TemplateRef<unknown>,
        private readonly _viewContainer: ViewContainerRef,
        private readonly _featureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit() {
        if (this.ifLdFeature !== undefined) {
            this._featureManager
                .isEnabled(this.ifLdFeature)
                .pipe(takeUntil(this.d$))
                .subscribe((isEnabled: boolean) => {
                    this._viewContainer.clear();
                    if ((isEnabled && this.ifLdFeatureEnabled) || (!isEnabled && !this.ifLdFeatureEnabled)) {
                        this.view = this._viewContainer.createEmbeddedView(this._templateRef);
                    } else if (isEnabled === false && this.ifLdFeatureElse) {
                        this.view = this._viewContainer.createEmbeddedView(this.ifLdFeatureElse);
                    }
                });
        } else {
            this.view = this._viewContainer.createEmbeddedView(this._templateRef);
        }
    }
}
