/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Component, OnInit } from '@angular/core';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { VendorService } from '../profile/services/vendor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NEVER, Observable, Subject, combineLatest, forkJoin, of } from 'rxjs';
import { delay, map, switchMap, takeUntil } from 'rxjs/operators';
import { vendorProfileActions } from '../../store/actions/vendor-profile.actions';
import { Store } from '@ngrx/store';
import { ToasterService } from 'src/app/core/services';
import {
    addVendorProfileLocations,
    deleteVendorProfileLocations,
    loadVendorProfileLocations,
    updateVendorProfileLocations,
    vendorProfileWebsiteActions
} from '../../store/actions';
import {
    selectVendorContactsPagination,
    selectVendorJobLookups,
    selectVendorJobPermissions,
    selectWebsitesWithChanges,
    selectJobRulesList,
    selectJobRuleDetails,
    selectIsVendorJobRulesLoading,
    selectRuleProfessionTypes,
    selectJobRuleProfessionTreeValues
} from '../../store/selectors/vendor-profile.selectors';
import { selectLocationsWithChanges } from '../../store/selectors/vendor-locations.selectors';
import { PhoneType } from 'src/app/shared/models/candidate';
import { ContactTag, VendorContact } from 'src/app/shared/models';
import { VendorBank } from '../models/vendor-bank.model';
import { State } from 'src/app/shared/grid/models/state.model';
import { VendorDetail } from 'src/app/shared/models/vendor-detail.model';
import { SelectedVendorType } from 'src/app/shared/models/vendor-type.model';
import { PendingChangesComponent } from 'src/app/shared/guards/pending-changes.guard';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { PagingToken } from 'src/app/shared/models/paging-token';
import { CertificationsResult } from 'src/app/shared/models/enums/certifications-result.enum';
import { TypeListItem } from '../profile/models/vendor.model';
import { VendorContactMigrationService } from '../contacts/vendor-contact-migration.service';
import { JobSettingsLookupsDto } from '../models/job-settings-lookups-response.model';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { MetadataCertification } from '../certifications/models/metadata-certification.model';
import { VendorProfileCertificationsService } from '../certifications/services/vendor-profile-certifications.service';
import { VendorContactsPagination } from 'src/app/shared/models/vendor-contacts-pagination.model';
import { VendorProfileJobRulesActions } from '../../store/actions/vendor-profile-job-rules.actions';
import { VendorJobRulesList } from '../profile/models/job-rules.model';
import {
    JobRuleProfessionTree,
    JobRuleProfessionTreePayload
} from 'src/app/admin-vendors/models/job-rule-profession-specialties.model';

@Component({
    selector: 'ayac-vendor-details',
    templateUrl: './vendor-details.component.html',
    styleUrls: ['./vendor-details.component.scss']
})
export class VendorDetailsComponent implements OnInit, PendingChangesComponent {
    vendor: VendorDetail;
    states: State[];
    jobLookups: JobSettingsLookupsDto;
    certifications: MetadataCertification;
    banks: VendorBank[] = [];
    contactTags: ContactTag[];
    contactPhoneTypes: PhoneType[];
    contactEmailTypes: TypeListItem[];

    jobSettings: any;
    vendorContacts: VendorContact[] = [];
    vendorContactsTotal = 0;

    vendorTypeTags: SelectedVendorType[] = [];
    vendorTypeTagsUpdated: boolean;
    upgradedLocationsIsValid: boolean;

    isSaving = false;
    isNew = false;
    isLoading = false;
    isVendorContactsLoading = false;

    invalidContactForms = false;
    invalidProfileForms = false;
    hasChanges = false;
    canSaveForm = true;

    notesToTop = true;
    contactsPagination: VendorContactsPagination;

    featureFlag = FeatureFlag;

    vendorContactForms: VendorContactMigrationService[] = [];
    vendorEmploymentTypes: any;
    saveVendorPhonesCalled$ = new Subject();
    saveVendorEmailsCalled$ = new Subject();
    vendorId: number;

    jobRules$: Observable<VendorJobRulesList[]>;
    jobRuleDetails$: Observable<any>;
    isVendorRulesLoading$: Observable<boolean>;
    professionTypeTree$: Observable<JobRuleProfessionTree>;
    professionTypeValues$: Observable<JobRuleProfessionTreePayload[]> = of([]);

    private readonly destroy$: Subject<void> = new Subject();

    constructor(
        private readonly vendorService: VendorService,
        private readonly vendorCertificationService: VendorProfileCertificationsService,
        private readonly lookupsService: LookupsService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        private readonly toasterService: ToasterService,
        private readonly _dialogService: DialogService,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {}

    get isFormsValid(): boolean {
        return !this.invalidContactForms && !this.invalidProfileForms;
    }

    canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
        return this.hasChanges ? this.openChangedNotSavedDialog() : true;
    }

    ngOnInit(): void {
        this.vendorId = this.getVendorId();
        this.isLoading = true;
        combineLatest([this.initLookups(), this.getRequestedVendor()])
            .pipe(delay(1000), takeUntil(this.destroy$))
            .subscribe(() => {
                this.isLoading = false;
            });

        if (!this.isNew) {
            this.store.dispatch(
                VendorProfileJobRulesActions.loadVendorProfileJobRulesList({ vendorId: this.vendorId })
            );
            this.store.dispatch(VendorProfileJobRulesActions.loadVendorProfileJobRuleProfessionTypes());
        }

        this.listenToStore();
    }

    ngOnDestroy(): void {
        this.store.dispatch(VendorProfileJobRulesActions.clearJobRules());

        this.destroy$.next();
        this.destroy$.complete();
    }

    listenToStore() {
        this.store
            .select(selectWebsitesWithChanges)
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
                this.hasChanges = value.hasChanges;
            });

        this.store
            .select(selectLocationsWithChanges)
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
                this.hasChanges = value.hasChanges;
                this.upgradedLocationsIsValid = value.isValid;
            });

        this.store
            .select(selectVendorContactsPagination)
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => (this.contactsPagination = value));

        this.isVendorRulesLoading$ = this.store.select(selectIsVendorJobRulesLoading);
        this.jobRules$ = this.store.select(selectJobRulesList);
        this.jobRuleDetails$ = this.store.select(selectJobRuleDetails);
        this.professionTypeTree$ = this.store.select(selectRuleProfessionTypes);
        this.professionTypeValues$ = this.store.select(selectJobRuleProfessionTreeValues);
    }

    setLoading(event: boolean) {
        this.isLoading = event;
    }

    formChanged(event: { typeTags: SelectedVendorType[]; vendor: VendorDetail; isValid: boolean }) {
        this.hasChanges = true;
        this.vendorTypeTags = event.typeTags;
        this.vendorTypeTagsUpdated = true;
        this.invalidContactForms = !event.isValid;
    }

    addNewBankFromAngular(bankName: string) {
        this.vendorService
            .createBank(bankName)
            .pipe(takeUntil(this.destroy$))
            .subscribe((bank) => {
                this.banks.push(bank);
                this.vendor.achBankId = bank.id;
                this.hasChanges = true;
            });
    }

    bankInfoChangedFromAngular(event: {
        achBankId: number;
        achAccountNumber: string;
        achAccountTypeId: number;
        achBeneficiaryName: string;
        achRoutingNumber: string;
        achWireRoutingNumber: string;
    }) {
        this.vendor.achBankId = event.achBankId;
        this.vendor.achAccountNumber = event.achAccountNumber;
        this.vendor.achAccountTypeId = event.achAccountTypeId;
        this.vendor.achBeneficiaryName = event.achBeneficiaryName;
        this.vendor.achRoutingNumber = event.achRoutingNumber;
        this.vendor.achWireRoutingNumber = event.achWireRoutingNumber;
        this.hasChanges = true;
    }

    bankNameChangedFromAngular(event: { id: number }) {
        this.vendor.achBankId = event.id;
        this.hasChanges = true;
    }

    vendorContactsChanged(event: VendorContactMigrationService[]) {
        const hasInvalidChanges = event.some((item) => item.isInvalid);

        if (hasInvalidChanges) {
            this.hasChanges = false;
            this.invalidContactForms = true;
        } else {
            this.hasChanges = true;
            this.invalidContactForms = false;
        }
        this.vendorContactForms = event;
    }

    vendorContactsPaginationChange(event: any) {
        this.store.dispatch(
            vendorProfileActions.setVendorContractsPagination({
                pageSize: event.pageSize,
                skip: event.skip,
                vendorId: this.vendor.id
            })
        );
        this.loadVendorContactsServerSide();
    }

    setNoteChange(event: any) {
        this.vendor.includeExcludeNotes = event;
        this.hasChanges = true;
    }

    insuranceFormChanged() {
        this.hasChanges = true;
    }

    save() {
        if (!this.canSave()) {
            return;
        }

        this.isLoading = true;
        this.isSaving = true;

        return this.vendorService
            .saveVendorDetails(this.vendor)
            .pipe(
                switchMap((saveResponse) => {
                    this.vendor.id = saveResponse;
                    this.store.dispatch(vendorProfileActions.saveVendorSuccess());
                    this.store.dispatch(
                        vendorProfileActions.updateContractLineTypesByVendorId({ vendorId: this.vendor.id })
                    );

                    if (this.upgradedLocationsIsValid) {
                        this.store.dispatch(addVendorProfileLocations({ vendorId: this.vendor.id }));
                        this.store.dispatch(updateVendorProfileLocations({ vendorId: this.vendor.id }));
                        this.store.dispatch(deleteVendorProfileLocations({ vendorId: this.vendor.id }));
                    } else {
                        this.store.dispatch(loadVendorProfileLocations({ vendorId: this.vendor.id }));
                    }

                    if (this.vendorTypeTagsUpdated) {
                        this._updateVendorTypeTags();
                    }

                    this._updateWebsites();

                    this.saveVendorEmailsCalled$.next(true);
                    this.saveVendorPhonesCalled$.next(true);

                    this.vendorContacts.forEach((x) => (x.vendorId = this.vendor.id));

                    //simplified the logic to generate an array of observables that are monitored for completion
                    return forkJoin(
                        this.vendorContactForms
                            .filter((x) => x.hasChanges)
                            .map((t) => {
                                //after creating a new vendor map the contacts to the new vendor id
                                if (t.vendorContact.vendorId === null || t.vendorContact.vendorId === undefined) {
                                    t.vendorContact.vendorId = this.vendor.id;
                                }
                                return t.save();
                            })
                    );
                })
            )
            .subscribe({
                complete: () => {
                    this.isLoading = false;
                    this.isSaving = false;
                    this.hasChanges = false;
                    this.toasterService.success('Vendor was saved successfully');
                    if (this.isNew) {
                        this.router.navigate(['/', 'admin', 'vendor', this.vendor.id]);
                    } else {
                        this.refreshContacts();
                    }
                },
                error: () => {
                    this.toasterService.fail('Vendor could not be saved. Please try again.');
                    this.isLoading = false;
                    this.isSaving = false;
                }
            });
    }

    saveResultFromAngular(result: CertificationsResult): void {
        if (result === CertificationsResult.SUCCESS) {
            this.toasterService.success('Certifications saved successfully.');
        } else if (result === CertificationsResult.FAIL) {
            this.toasterService.fail('Error occurred while trying to persist certifications.');
        } else {
            this.toasterService.fail('Unknown error');
        }
    }

    goBack() {
        this.router.navigate(['/', 'admin', 'vendors']);
    }

    cancel(): void {
        this.hasChanges = false;
        this.goBack();
    }

    canSave() {
        return this.hasChanges && this.isFormsValid && !this.isLoading;
    }

    refreshContacts() {
        this.isVendorContactsLoading = true;
        this.vendorContactForms = [];
        this.loadVendorContactsServerSide();
    }

    getJobRuleDetails(ruleId: number) {
        this.store.dispatch(VendorProfileJobRulesActions.loadVendorProfileJobRuleDetails({ jobRuleId: ruleId }));
    }

    private _updateWebsites(): void {
        this.store.dispatch(vendorProfileWebsiteActions.addWebsites({ vendorId: this.vendor.id }));
        this.store.dispatch(vendorProfileWebsiteActions.updateWebsites({ vendorId: this.vendor.id }));
        this.store.dispatch(vendorProfileWebsiteActions.deleteWebsites({ vendorId: this.vendor.id }));
    }

    private _updateVendorTypeTags(): void {
        this.vendorTypeTagsUpdated = false;

        this.store.dispatch(
            vendorProfileActions.updateVendorTypeTags({
                vendorId: this.vendor.id,
                tagIds: this.vendorTypeTags.map((x) => x.vendorTypeId)
            })
        );
        const electronicOfferTagTypeId = 12;
        const vendorId = this.vendor ? this.vendor.id : null;
        const hasElectronicOfferTag =
            this.vendorTypeTags && this.vendorTypeTags.some((tag) => tag.vendorTypeId === electronicOfferTagTypeId);
        if (hasElectronicOfferTag) {
            this.store.dispatch(vendorProfileActions.addVendorContactsPermissions({ vendorId }));
        } else {
            this.store.dispatch(vendorProfileActions.removeVendorContactsPermissions({ vendorId }));
        }
    }

    private openChangedNotSavedDialog(): Promise<boolean> {
        const les: IConfirmationDialogOptions = {
            data: {
                title: 'Are you sure you want to go back',
                text: 'Any unsaved changes will be lost. This cannot be undone.'
            }
        };
        return this._dialogService.openConfirmationDialog(les);
    }

    private initLookups() {
        return forkJoin([
            this.vendorCertificationService.getCertificationsMetadata().pipe(
                map((meta) => {
                    this.certifications = meta.certifications;
                }),
                takeUntil(this.destroy$)
            ),
            this.lookupsService.getStates().pipe(
                map((x) => {
                    this.states = [].concat(x);
                }),
                takeUntil(this.destroy$)
            ),
            this.vendorService.getBanks().pipe(
                map((x) => {
                    this.banks = x;
                }),
                takeUntil(this.destroy$)
            ),
            this.vendorService.getVendorPhoneTypes().pipe(
                map((x) => {
                    this.contactPhoneTypes = [].concat(x);
                }),
                takeUntil(this.destroy$)
            ),
            this.vendorService.getVendorEmailTypes().pipe(
                map((x) => {
                    this.contactEmailTypes = [].concat(x);
                }),
                takeUntil(this.destroy$)
            ),
            this.vendorService.getContactTags().pipe(
                map((x) => (this.contactTags = [].concat(x))),
                takeUntil(this.destroy$)
            )
        ]);
    }

    private getRequestedVendor() {
        return this.getOrCreateVendor().pipe(
            map((vendor) => {
                this.vendor = vendor;
                this.loadVendorContactsServerSide();
            }),
            takeUntil(this.destroy$)
        );
    }

    private getOrCreateVendor(id: number = null) {
        const vendorId = id || this.activatedRoute.snapshot.params.id;

        if (vendorId === 'new') {
            this.isNew = true;
            this.store.dispatch(VendorProfileJobRulesActions.clearJobRules());
            return this.vendorService.create();
        }

        this.store.dispatch(vendorProfileActions.setVendorContractsPagination({ vendorId }));

        return this.vendorService.getVendorDetails(vendorId);
    }

    private loadVendorContactsServerSide() {
        const defaultArgs = {
            vendorId: this.getVendorId(),
            pageSize: 10,
            skip: 0
        };

        const updatedArgs = this.contactsPagination || defaultArgs;

        if (updatedArgs.vendorId) {
            this.vendorService.getVendorContacts(updatedArgs).subscribe((response: PagingToken<VendorContact[]>) => {
                response.data.forEach((c) => {
                    c.fullName = `${c.firstName} ${c.lastName}`;
                    if (c.user) {
                        c.originalUsername = c.user.username;
                    } else {
                        c.user = { ...c.user, isActive: false };
                    }
                    if (c.vendorContactPhones) {
                        c.vendorContactPhones.forEach(
                            (x) => (x.fullNumber = x.areaCode + x.exchange + x.station + x.extension)
                        );
                    }
                });

                this.vendorContactsTotal = response.total;
                this.vendorContacts = [...response.data];
                this.isVendorContactsLoading = false;
            });
        }
    }

    private getVendorId() {
        return this.vendor?.id ?? this.activatedRoute.snapshot.params.id;
    }

    private _isLdFeatureFlagEnabled(featureName: FeatureFlag): boolean {
        let isFeatureEnabled = false;
        this._ldFeatureManager
            .isEnabled(featureName)
            .pipe(takeUntil(this.destroy$))
            .subscribe((flagIsEnabled) => {
                isFeatureEnabled = flagIsEnabled;
            });
        return isFeatureEnabled;
    }
}
