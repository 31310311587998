export enum FacilitiesAdminUrls {
    FACILITIES_LIST = '/AyaConnect/FacilityAdmin',
    FACILITIES_LIST_FOR_EXPORT = '/AyaConnect/FacilityAdmin/Export',
    FACILITIES_LOOKUPS = '/AyaConnect/FacilityAdmin/Lookups',
    FACILITY = '/AyaConnect/Admin/Facility',
    FACILITY_FEATURES = '/AyaConnect/Facility/Features',
    FACILITY_SETTINGS = '/AyaConnect/Facility/{facilityId}/Settings',
    CONNECT_FACILITY_FEATURES = '/AyaConnect/admin/facility/{facilityId}/features',
    FACILITY_SETTINGS_UPDATE = '/AyaConnect/Facility/{facilityId}/Settings/{featureId}',
    FACILITY_RELEASE_GROUPS = '/AyaConnect/ReleaseGroup',
    FACILITY_RELEASE_GROUP_VENDORS = '/AyaConnect/ReleaseGroup/Vendor',
    FACILITY_SCHEDULED_RELEASE_RULE = '/AyaConnect/ScheduledReleaseRule',
    COPY_FROM_FACILITIES = '/AyaConnect/ScheduledReleaseRule/Facilities/From',
    COPY_SETTINGS_FROM = '/AyaConnect/ScheduledReleaseRule/Clone',
    FACILITY_RELEASE_RULE_PROFESSIONS = '/AyaConnect/ScheduledReleaseRule/Professions',
    FACILITY_RELEASE_RULE_EXPERTISES = '/AyaConnect/Lookup/professions',
    FACILITY_RELEASE_VENDORS = '/AyaConnect/ScheduledReleaseRule/Vendors',
    SAVE_RULE = '/AyaConnect/ScheduledReleaseRule',
    VENDORS_GRID = '/AyaConnect/Admin/Facility/{facilityId}/vendors',
    VENDOR_LOOKUPS = '/AyaConnect/Admin/Facility/vendors/lookups',
    VENDOR_TSSSIGNED = '/AyaConnect/Admin/Facility/{facilityId}/vendors/term-sheet-signed',
    VENDOR_COUNT = '/AyaConnect/Admin/Facility/{facilityId}/vendors/count?isActive={isActive}',
    VENDOR_IDS = '/AyaConnect/Admin/Facility/{facilityId}/vendors/ids',
    VENDOR_EXPORT_SELECTED = '/AyaConnect/Admin/Facility/{facilityId}/vendors/export-selected',
    VENDOR_EXPORT_SELECTED_ALL = '/AyaConnect/Admin/Facility/{facilityId}/vendors/export-selected/all',
    VENDOR_STATUSUPDATE = '/AyaConnect/Admin/Facility/{facilityId}/vendors/status',
    VENDOR_FACILITIES_STATUSUPDATE = '/AyaConnect/Admin/Facility/vendors/status',
    VENDOR_FACILITIES_STATUSUPDATE_ALL = '/AyaConnect/Admin/Facility/vendors/status/all',
    VENDOR_HISTORY = '/AyaConnect/Admin/Facility/{facilityId}/vendors/{vendorId}/status-history',
    FACILITY_ADMIN_FEATURES = '/AyaConnect/facility-features/with-facility-count',
    SYSTEM_SETTINGS = '/AyaConnect/Admin/facility-systems/{systemId}/Settings',
    SYSTEM_SETTINGS_UPDATE = '/AyaConnect/Admin/facility-systems/{systemId}/Settings/{featureId}',
    FACILITY_SYSTEMS_FACILITIES_LIST = '/AyaConnect/Admin/facility-systems/facilities',
    FACILITY_SYSTEMS_FACILITIES_LIST_ALL = '/AyaConnect/Admin/facility-systems/facilities/all',
    FACILITY_SYSTEMS_VENDORS_LIST = '/AyaConnect/Admin/facility-systems/vendors',
    FACILITY_SYSTEMS_EXPORT = '/AyaConnect/Admin/facility-systems/export',
    FACILITY_SYSTEMS_EXPORT_SELECTED = '/AyaConnect/Admin/facility-systems/export/selected',
    CONTRACT_GROUP_FEATURES = '/AyaConnect/Admin/contract-groups/{contractGroupId}/features',

    SHIFTS_RELEASE_TEARING_DISABLE_COUNT = '/AyaConnect/Client/ShiftReleaseTiers/system/{systemId}/shift-count',
    SHIFTS_RELEASE_TEARING_REASON = '/AyaConnect/Client/ShiftReleaseTiers/change-log/'
}
