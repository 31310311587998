import { GridSearchQuery } from 'src/app/shared/grid/models';
import {FilterDescriptor} from "@progress/kendo-data-query";

export function interpolateFetchArguments2(query: GridSearchQuery): string {
    let result = `skip=${query.skip}&pagesize=${query.take}`;
    query.sort?.forEach(sort => {
        result += `&sort.field=${sort.field}&sort.type=${sort.dir}`;
    });

    query.filter?.filters?.forEach((filter: FilterDescriptor) => {
        if (filter.field === 'facilityFeatures') {
            filter.value.map(val => result += `&filter.${filter.field}=${encodeURIComponent(val)}`);
        } else {
            result += `&filter.${filter.field}=${encodeURIComponent(filter.value)}`;
        }
    });

    return result;
}
