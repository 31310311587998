import { Action, createReducer, on } from '@ngrx/store';
import { loadPendingJobsFail } from 'src/app/dashboard-new/store/actions';
import { LoadingTypes } from 'src/app/shared/models/enums/loading.enum';
import { loadDashboardDraftInvoicesCount, loadDashboardDraftInvoicesCountSuccess } from 'src/app/invoices/store';

export interface DraftInvoicesState {
    totalCount: number;
    loading: LoadingTypes;
    error: string;
}

export const initialDraftInvoicesState: DraftInvoicesState = {
    totalCount: 0,
    loading: LoadingTypes.INIT,
    error: ''
};

export function draftInvoicesReducer(state: DraftInvoicesState, action: Action) {
    return createReducer(
        initialDraftInvoicesState,
        on(loadDashboardDraftInvoicesCount, (state, action) => ({
            ...state,
            loading: LoadingTypes.LOADING
        })),

        on(loadDashboardDraftInvoicesCountSuccess, (state, action) => ({
            ...state,
            totalCount: action.totalCount,
            loading: LoadingTypes.LOADED
        })),

        on(loadPendingJobsFail, (state, action) => ({
            ...state,
            error: action.error,
            loading: LoadingTypes.FAIL
        }))
    )(state, action);
}

export function reducer(state: DraftInvoicesState | undefined, action: Action) {
    return draftInvoicesReducer(state, action);
}
