import { Action, createReducer, on } from '@ngrx/store';
import { LoadingTypes } from 'src/app/shared/models';
import { 
    loadInvoiceDetails, 
    loadInvoiceDetailsFail, 
    loadInvoiceDetailsSuccess,
    createInvoiceDispute,
    createInvoiceDisputeSuccess,
    createInvoiceDisputeFail,
} from '../invoices.actions';
import { InvoiceDetails } from '../../models/invoice-details.model';

export interface InvoiceDetailsState {
    invoicesDetails: InvoiceDetails;
    loading: LoadingTypes,
    error: string;
}

const initialState: InvoiceDetailsState = {
    invoicesDetails: null,
    loading: LoadingTypes.INIT,
    error: null
}

export function invoiceDetailsReducer(state: InvoiceDetailsState, action: Action) {
    return createReducer(
        initialState,
        on(loadInvoiceDetails, (state, action) => (
            {
                invoicesDetails: null,
                error: null,
                loading: LoadingTypes.LOADING
            }
        )),

        on(loadInvoiceDetailsSuccess, (state, action) => (
            {
                invoicesDetails: action.invoiceDetails,
                error: null,
                loading: LoadingTypes.LOADED
            }
        )),

        on(loadInvoiceDetailsFail, (state, action) => (
            {
                invoicesDetails: null,
                error: action.error,
                loading: LoadingTypes.FAIL
            }
        )),

        on(createInvoiceDispute, (state, action) =>(
            {
                invoicesDetails: state.invoicesDetails,
                error: null,
                loading: LoadingTypes.LOADING,
            }
        )),

        on(createInvoiceDisputeSuccess, (state, action) => (
            {
                invoicesDetails: action.invoiceDetails,
                error: null,
                loading: LoadingTypes.LOADED,
            }
        )),

        on(createInvoiceDisputeFail, (state, action) => (
            {
                invoicesDetails: state.invoicesDetails,
                error: action.error,
                loading: LoadingTypes.LOADED,
            }
        )),
    )(state, action);
}

export function reducer(state: InvoiceDetailsState | undefined, action: Action) {
    return invoiceDetailsReducer(state, action);
}
