import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HospitalAssociationFacilitiesGridItem } from 'src/app/facilities/models/hospital-association-facilities-grid-item.model';
import { VendorFacilityStatusCode } from 'src/app/facilities/models/enums/vendor-facility-status-code.enum';
import * as vendorsActions from 'src/app/facilities/store/actions/association-vendors.actions';

@Component({
    selector: 'ayac-offer-accept-vendor-modal',
    templateUrl: './offer-accept-vendor-modal.component.html',
    styleUrls: ['./offer-accept-vendor-modal.component.scss']
})
export class OfferAcceptVendorModalComponent {
    filteredFacilities: HospitalAssociationFacilitiesGridItem[];
    selectedFacilities: HospitalAssociationFacilitiesGridItem[];

    constructor(
        private readonly dialogRef: MatDialogRef<OfferAcceptVendorModalComponent>,
        private readonly _store: Store<{}>,
        @Inject(MAT_DIALOG_DATA) public data: { 
            statusId: VendorFacilityStatusCode,
            hospitalSystemFacilities: HospitalAssociationFacilitiesGridItem[],
            selectedVendorIds: number[] }
    ) { 

    }

    ngOnInit() {
        this.selectedFacilities = [];
        this.filteredFacilities = this.data.hospitalSystemFacilities.filter(item => item.facilityStatusId && item.facilityStatusId !== this.data.statusId);
    }

    get titleText() {
        return this.data.statusId === VendorFacilityStatusCode.Offered ? 'Offer' : 'Accept';
    }

    get alertText() {
        return `These agencies were not ${VendorFacilityStatusCode[this.data.statusId]} due to the following existing statuses`;
    }

    onRowSelected(rows: HospitalAssociationFacilitiesGridItem[]) {
        this.selectedFacilities = rows;
    }

    override(): void {
        const facilityIds = this.selectedFacilities.map(sf => sf.facilityId);
        if (!facilityIds || !facilityIds.length) {
            return;
        }
        
        const params = {
            statusId: this.data.statusId,
            facilityIds,
            vendorIds: this.data.selectedVendorIds,
            note: null
        };

        this._store.dispatch(vendorsActions.updateVendorFacilitiesStatus({ params: params }));

        this.dialogRef.close(true);
    }
}
