import {
    Component,
    Input,
} from '@angular/core';
import { ContractGroupEntityValueDto } from '../../models/custom-fields/contract-group-entity-value-dto.model';

@Component({
    selector: 'ayac-custom-form-grid-cell',
    templateUrl: './custom-form-grid-cell.component.html',
    styleUrls: ['./custom-form-grid-cell.component.scss'],
})
export class CustomFormGridCellComponent
{
    @Input()
    customFields: ContractGroupEntityValueDto[] = [];
}
