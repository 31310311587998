import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform {
    transform(value: string, replacedValue: string, newValue: string): string {
        return value.replace(replacedValue, newValue);
    }
}
