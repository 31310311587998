import { Pipe, PipeTransform } from '@angular/core';
import { AppModuleState } from '../models';

@Pipe({
    name: 'isSubMenuActive'
})
export class IsSubmenuActivePipe implements PipeTransform {
    transform(menuItem: AppModuleState, currentState: string): boolean {
        if (!menuItem.potentialRoutes) {
            return false;
        }
        // Does currentState include any route? If any hits, there will be 1 true value
        return menuItem?.potentialRoutes?.map((r) => currentState === r).filter((r) => r)[0] ? true : false;
    }
}
