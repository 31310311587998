import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { JobsReleaseDialogComponent } from 'src/app/admin/jobs/jobs-release-dialog.component';
import { JobDetail } from 'src/app/admin/jobs/models/job-detail.model';
import { JobReleaseHistoryDialogComponent } from 'src/app/shared/release-history/release-history-dialog.component';
import { adminJobsActions } from 'src/app/admin/store/actions';
import {
    selectAdminJobCanEditVendorRate,
    selectAdminJobDetails,
    selectHasJobAttachments,
    selectJobAttachmentPreview,
    selectJobAttachments
} from 'src/app/admin/store/selectors/admin-jobs.selectors';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { JobPosition } from 'src/app/jobs/models/job-position.model';
import {
    FileAttachmentPreview,
    FileAttachmentsPreviewComponent
} from 'src/app/shared/components/file-attachments-preview/file-attachments-preview.component';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FileAttachment, FileAttachmentStyle } from 'src/app/shared/models/attachment';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { billRateFormat } from 'src/app/shared/utilities/bill-rate-format';
import { JobApprovalStatus } from 'src/app/jobs/approvals/models/approvals-dto.enums';

@Component({
    selector: 'aya-admin-jobs-details',
    templateUrl: './admin-job-details.component.html',
    styleUrls: ['./admin-job-details.component.scss']
})
export class AdminJobDetailsComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild(FileAttachmentsPreviewComponent, { static: false })
    previewComponent: FileAttachmentsPreviewComponent;

    jobDetails$: Observable<JobDetail>;
    jobAttachments$: Observable<FileAttachment[]>;
    hasJobAttachments$: Observable<boolean>;
    canEditVendorRate$: Observable<boolean>;
    novaUrl = '';
    vendorRateOverrideCtrl: UntypedFormControl;
    fileAttachmentStyle = FileAttachmentStyle;
    currentFile: FileAttachment;
    filePreview$: Observable<FileAttachmentPreview>;
    unitFormLink = '';
    isJobStopped = false;
    isEditing = false;
    showApprovalRequiredWarning = false;
    openPositions: JobPosition[] = [];
    jobId: number;

    vendorRateCtrl = new UntypedFormControl(0);

    userId = 0;
    featureFlags = FeatureFlag;
    payFormat = billRateFormat;

    constructor(
        private readonly _store: Store,
        private readonly _identityService: IdentityService,
        private readonly _route: ActivatedRoute,
        @Inject(APP_CONFIG) private readonly _settings: Settings,
        private readonly _dialogService: DialogService,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    ngOnInit(): void {
        this.novaUrl = this._settings.NOVA;
        this.userId = this._identityService.userId;

        this.jobDetails$ = this._store.select(selectAdminJobDetails).pipe(
            tap((details) => {
                if (details) {
                    this.unitFormLink = `${this.novaUrl}/#/accountmanagers/jobs/${details.id}?isEditMode=true`;
                    this.isJobStopped = details.isStopped;
                    this.vendorRateCtrl.patchValue(details.vendorRate);
                    if (details.releaseStatus) {
                        this.openPositions = details?.jobPositions?.filter((pos) => pos.jobPositionStatus === 0);
                        this.openPositions?.sort((a, b) => a.id - b.id);

                        this.showApprovalRequiredWarning = details?.approvalStatusId === JobApprovalStatus.Pending;
                    }
                }
            })
        );

        this.jobAttachments$ = this._store.select(selectJobAttachments);
        this.hasJobAttachments$ = this._store.select(selectHasJobAttachments);
        this.filePreview$ = this._store.select(selectJobAttachmentPreview);
        this.canEditVendorRate$ = this._store.select(selectAdminJobCanEditVendorRate);

        this._route.paramMap.pipe(takeUntil(this.d$)).subscribe((params) => {
            if (params.has('id')) {
                this.jobId = Number(params.get('id'));

                this._store.dispatch(adminJobsActions.getJobById({ jobId: this.jobId }));
                this._store.dispatch(adminJobsActions.loadJobAttachments({ jobId: this.jobId }));
            }
        });

        this._store.dispatch(adminJobsActions.getCanEditVendorBaseRate());
    }

    attachmentToPreview(file: FileAttachment, job: JobDetail): void {
        this.currentFile = file;

        this._store.dispatch(
            adminJobsActions.loadJobAttachmentPreview({
                jobId: job.id,
                fileId: file.id,
                pageNum: 0
            })
        );

        this._store.dispatch(
            adminJobsActions.loadJobAttachmentPreviewTotalPages({
                jobId: job.id,
                fileId: file.id
            })
        );

        this.previewComponent.showDialog(file);
    }

    releaseJob(job: JobDetail): void {
        this._dialogService
            .openDialog(JobsReleaseDialogComponent, {
                data: {
                    facilityIds: [job.facilityId],
                    jobIds: [job.id]
                },
                width: 'auto'
            })
            .afterClosed()
            .pipe(filter(Boolean), first())
            .subscribe((value: { vendorIds: number[] }) => {
                this._store.dispatch(adminJobsActions.releaseJobs({ entityIds: [job.id], vendorIds: value.vendorIds }));
            });
    }

    releaseJobPositions(job: JobDetail, isReleaseAllPositions: boolean, jobPositionId?: number): void {
        this._dialogService
            .openDialog(JobsReleaseDialogComponent, {
                data: {
                    facilityIds: [job.facilityId],
                    jobIds: [job.id],
                    isReleaseAllPositions: isReleaseAllPositions
                },
                width: 'auto'
            })
            .afterClosed()
            .pipe(filter(Boolean), first())
            .subscribe((value: { vendorIds: number[] }) => {
                this.openConfirmDialog(isReleaseAllPositions, job, value.vendorIds, jobPositionId);
            });
    }

    async openConfirmDialog(
        isReleaseAllPositions: boolean,
        job: JobDetail,
        vendorIds: number[],
        jobPositionId?: number
    ) {
        const selectedVendorCount = vendorIds?.length;
        if (selectedVendorCount && selectedVendorCount > 0) {
            const vendorSuffix = vendorIds?.length > 1 ? 's' : '';
            const title = isReleaseAllPositions ? 'Release All Positions' : 'Release Position';
            const text = this.generateConfirmationText(
                isReleaseAllPositions,
                this.openPositions,
                selectedVendorCount,
                vendorSuffix
            );

            const options: IConfirmationDialogOptions = {
                data: {
                    title: title,
                    text: text,
                    confirmButtonText: 'confirm',
                    cancelButtonText: 'cancel'
                }
            };

            const isRelease = await this._dialogService.openConfirmationDialog(options);
            if (isRelease) {
                if (isReleaseAllPositions) {
                    this.releaseAllPositions(job, vendorIds);
                } else {
                    this.releaseSelectedPosition(job, jobPositionId, vendorIds);
                }
            }
        }
    }

    generateConfirmationText(
        isReleaseAllPositions: boolean,
        openPositions: JobPosition[],
        selectedVendorCount: number,
        vendorSuffix: string
    ): string {
        if (isReleaseAllPositions) {
            const openPositionCount = openPositions?.length || 0;
            const positionText = openPositionCount === 1 ? '1 position' : `All ${openPositionCount} positions`;
            return `${positionText} in this job will be released to ${selectedVendorCount} vendor${vendorSuffix} immediately and can not be undone. Are you sure you would like to proceed?`;
        } else {
            return `This position will be released to ${selectedVendorCount} vendor${vendorSuffix} immediately and can not be undone. Are you sure you would like to proceed?`;
        }
    }

    private releaseSelectedPosition(job: JobDetail, jobPositionId: number, vendorIds: number[]) {
        const vendorCount = vendorIds?.length;
        if (vendorCount > 0) {
            this._store.dispatch(
                adminJobsActions.releaseJobPosition({
                    jobId: job.id,
                    jobPositionId: jobPositionId,
                    vendorIds: vendorIds
                })
            );
        }
    }

    private releaseAllPositions(job: JobDetail, vendorIds: number[]) {
        const vendorCount = vendorIds?.length;
        if (vendorCount > 0) {
            this._store.dispatch(adminJobsActions.releaseAllJobPositions({ jobId: job.id, vendorIds: vendorIds }));
        }
    }

    downloadFile(file: FileAttachment, job: JobDetail): void {
        this._store.dispatch(
            adminJobsActions.downloadJobAttachment({
                jobId: job.id,
                fileId: file.id,
                fileName: file.fileName
            })
        );
    }

    closePreview(): void {
        this._store.dispatch(adminJobsActions.resetJobAttachmentPreview());
    }

    viewReleaseHistory(detail: JobDetail): void {
        this._dialogService.openDialog(JobReleaseHistoryDialogComponent, {
            data: {
                history: detail.releaseHistory
            }
        });
    }

    viewPositionReleaseHistory(detail: JobDetail, positionId: number): void {
        var history = detail.releaseHistory.filter((pos) => pos.positionId === positionId);
        this._dialogService.openDialog(JobReleaseHistoryDialogComponent, {
            data: {
                history: history,
                columns: ['method', 'agency', 'when', 'who']
            }
        });
    }

    updateVendorBaseRate(jobId: number): void {
        this._store.dispatch(
            adminJobsActions.updateVendorBaseRate({
                jobId,
                vendorBaseRate: Number(this.vendorRateCtrl.value)
            })
        );
        this.isEditing = false;
    }
}
