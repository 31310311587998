import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Directive({
    selector: '[app-kendo-autocomplete-fix]',
})
export class KendoAutocompleteFixDirective implements OnChanges {
    @Input() data: any;

    constructor(
        private readonly host:ComboBoxComponent,
        private readonly cd: ChangeDetectorRef,
        private readonly element: ElementRef
    ) {}

    ngOnChanges(changes) {
        if (changes.data) {
            this.host.toggle(this.host.isOpen);
        }
    }
}
