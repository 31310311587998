import { Component, OnInit } from '@angular/core';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { HelpService } from './help.service';
import { takeUntil } from 'rxjs/operators';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import {
    ayaContactLinksFull,
    ayaSystemDocumentLinks,
    ayaAdminDocumentLinks,
    ayaVendorDocumentLinks,
    ayaVideoLinks,
    HelpData,
    ltsContactLinks,
    ltsSystemDocumentLinks,
    ltsVendorDocumentLinks,
    ltsVideoLinks,
    UserType
} from 'src/app/shared/models';
import { DomainService } from 'src/app/shared/services/domain.service';

@Component({
    selector: 'ayac-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent extends UnsubscribeOnDestroy implements OnInit {
    titleMain = 'Connect Help';
    title = 'Resources';
    identity: UserType | null = null;
    documents: HelpData[] = [];
    videos: HelpData[] = [];
    contacts: HelpData[] = [];

    constructor(
        private readonly _helpService: HelpService,
        private readonly _identityService: IdentityService,
        private readonly _featureManager: LDFeatureManager,
        private readonly _domainService: DomainService
    ) {
        super();
    }

    ngOnInit() {
        this.identity = this._identityService.type;

        this.init();
    }

    init(): void {
        this.documents = [];
        this.videos = [];
        this.contacts = [];

        if (this._domainService.environment() === 'AYA') {
            this.initAyaLinks();
        }

        if (this._domainService.environment() === 'LTS' || this._domainService.environment() === 'HCS') {
            this.initLtsLinks();
        }
    }

    initAyaLinks(): void {
        if (this.identity === 'system' || this.identity === 'client') {
            this.documents.push(...ayaSystemDocumentLinks);
        }

        if (this.identity === 'admin') {
            this.documents.push(...ayaAdminDocumentLinks);
        }

        if (this.identity === 'admin' || this.identity === 'vendor') {
            this.documents.push(...ayaVendorDocumentLinks());
            this.videos.push(...ayaVideoLinks());

            this.contacts.push(...ayaContactLinksFull);
        }
    }

    initLtsLinks(): void {
        if (this.identity === 'admin' || this.identity === 'system' || this.identity === 'client') {
            this.documents.push(...ltsSystemDocumentLinks);
        }

        if (this.identity === 'admin' || this.identity === 'vendor') {
            this.documents.push(...ltsVendorDocumentLinks);
            this.videos.push(...ltsVideoLinks);
            this.contacts.push(...ltsContactLinks);
        }
    }

    download(path: string, event: Event): void {
        event.preventDefault();
        this._helpService.downloadFile(path).pipe(takeUntil(this.d$)).subscribe();
    }
}
