import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Settings, APP_CONFIG } from 'src/app/config/settings';
import { interpolateParams, interpolateUrl } from '../core/utils';
import { SortTypes } from '../shared/models';
import { PagingToken } from '../shared/models/paging-token';
import { ListItem } from '../shared/models/list-item';
import {
    CandidateInfo,
    NewSubmittal,
    NewSubmittalNote,
    SubmittalPreferences,
    SubmittalProfessionSpecialties,
    SubmittalStageEntry,
    VendorSubmittalDetails
} from '../shared/models/submittals-vendor/submittals-vendor-details.model';
import { JobsVendorUrls } from '../jobs-vendor/services/jobs-vendor.service';
import { VendorJobDetails } from '../shared/models/jobs';
import { renameKeys } from 'src/app/core/utils/rename-keys';
import { SubmittalVendorListItem } from '../shared/models/submittals-vendor/submittal-vendor-list-item.model';
import { SubmittalActivity } from '../submittals/models';

export enum SubmittalsVendorUrls {
    SUBMITTALS = '/ayaconnect/vendors/submittals',
    SUBMITTALS_LOOKUPS_STATUSES = '/ayaconnect/vendors/submittals/statuses',
    SUBMITTALS_LOOKUPS_SUBSTATUSES = '/ayaconnect/vendors/submittals/sub-statuses',
    SUBMITTALS_NPI_CLEARED = '/ayaconnect/vendors/submittals/job/{jobId}/npi-cleared',
    SUBMITTAL_NOTES = '/ayaconnect/vendors/submittals/{submittalId}/notes'
}

@Injectable({
    providedIn: 'root'
})
export class SubmittalsVendorService {
    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly settings: Settings) {}

    getVendorSubmittals(
        pagination: {
            pageSize: number;
            skip: number;
        },
        sortArgs?: {
            sortField?: string;
            sortType?: SortTypes;
        },
        matchArgs?: {
            [key: string]: string;
        }
    ) {
        let matchArgsObj = {};
        const sortArgsObj = sortArgs;

        sortArgsObj['sort.field'] = sortArgsObj.sortField;
        sortArgsObj['sort.type'] = sortArgsObj.sortType === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;

        if (matchArgs) {
            matchArgsObj = renameKeys(matchArgs, 'filter');
        }

        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgsObj
        });

        const url = `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS}`;

        return this.http.get<PagingToken<SubmittalVendorListItem[]>>(url, { params });
    }

    getVendorContacts(): Observable<ListItem[]> {
        const url = `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS}/vendor-contacts`;
        return this.http.get<ListItem[]>(url);
    }

    getVendorSubmittalDetails(id: number): Observable<VendorSubmittalDetails> {
        const url = `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS}/${id}`;
        return this.http.get<VendorSubmittalDetails>(url);
    }

    updateSubmittalNote(submittalId: number, note: string) {
        const params = {
            vendorSubmittalId: submittalId,
            note
        };
        const url = `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS}`;
        return this.http.put<any>(url, params);
    }

    updateSubmittalPreferences(submittalId: number, preferences: SubmittalPreferences): Observable<void> {
        const url = `${this.settings.CORE}/AyaConnect/vendors/submittals/${submittalId}/preferences`;
        return this.http.put<void>(url, preferences);
    }

    updateSubmittalStageHistoryEntry(actionsObj: SubmittalStageEntry) {
        const params = {
            submittalId: actionsObj.submittalId,
            submittalStageId: actionsObj.currentStageId,
            submittalStageReasonId: actionsObj.submittalStageReasonId,
            note: actionsObj.note,
            richTextNote: actionsObj.richTextNote,
            submittalStatusId: actionsObj.submittalStatusId
        };
        const url = `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS}/stage`;
        return this.http.put<any>(url, params);
    }

    getSubmittalStages(): Observable<ListItem[]> {
        const url = `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS}/stages`;
        return this.http.get<ListItem[]>(url);
    }

    getSubmittalStagesReasons(showVendor = false): Observable<ListItem[]> {
        const urlSuffix = showVendor ? `stage-reasons?showToVendor=${showVendor}` : `stage-reasons`;
        const url = `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS}/${urlSuffix}`;
        return this.http.get<ListItem[]>(url);
    }

    getCandidateProfessionSpecialties(candidateId: number): Observable<SubmittalProfessionSpecialties[]> {
        const url = `${this.settings.CORE}/AyaConnect/vendor/candidates/${candidateId}/profession-specialties`;
        return this.http.get<SubmittalProfessionSpecialties[]>(url);
    }

    getSubmittalVendorJob(jobId: number): Observable<VendorJobDetails> {
        const url = `${this.settings.CORE}${JobsVendorUrls.JOBS}/${jobId}`;
        return this.http.get<VendorJobDetails>(url);
    }

    getCandidateInfo(candidateId: number): Observable<CandidateInfo> {
        const url = `${this.settings.CORE}/AyaConnect/vendor/candidates/${candidateId}`;
        return this.http.get<CandidateInfo>(url);
    }

    createSubmittal(submittalParams: NewSubmittal) {
        const params = {
            ...submittalParams,
            vendorCandidateId: submittalParams.candidateId
        };
        delete params.candidateId;
        const url = `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS}`;
        return this.http.post<any>(url, params);
    }

    export(
        pagination: { pageSize: number; skip: number },
        sortArgs?: { sortField?: string; sortType?: SortTypes },
        matchArgs?: { [key: string]: string }
    ) {
        const sortArgsObj = sortArgs;
        sortArgsObj['sort.field'] = sortArgsObj.sortField;
        sortArgsObj['sort.type'] = sortArgsObj.sortType === 'desc' ? 'descending' : 'asc';
        delete sortArgsObj.sortField;
        delete sortArgsObj.sortType;
        const params = interpolateParams({
            ...pagination,
            ...sortArgsObj,
            ...matchArgs
        });

        const url = `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS}/export`;
        return this.http.get(url, { params, responseType: 'blob' });
    }

    getStatuses(): Observable<ListItem[]> {
        return this.http.get<ListItem[]>(`${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS_LOOKUPS_STATUSES}`);
    }

    getSubStatuses(statusId: number): Observable<ListItem[]> {
        return this.http.get<ListItem[]>(
            `${this.settings.CORE}${SubmittalsVendorUrls.SUBMITTALS_LOOKUPS_SUBSTATUSES}/${statusId}`
        );
    }

    validateNpiCleared(npi: string, jobId: number, vendorId: number): Observable<boolean> {
        const params = { npi, vendorId };
        const url = interpolateUrl(SubmittalsVendorUrls.SUBMITTALS_NPI_CLEARED, { jobId });
        return this.http.post<boolean>(`${this.settings.CORE}${url}`, params);
    }

    addSubmittalNote(submittalId: number, newSubmittalNote: NewSubmittalNote): Observable<any> {
        const url = interpolateUrl(SubmittalsVendorUrls.SUBMITTAL_NOTES, { submittalId });

        return this.http.post<any>(`${this.settings.CORE}${url}`, newSubmittalNote);
    }

    getSubmittalActivities(submittalId: number, sortBy?: string): Observable<SubmittalActivity[]> {
        const queryParams = sortBy
            ? `?sortField=createdOn&sortType=${sortBy}&sort.field=createdOn&sort.type=${sortBy}`
            : '';
        const url = interpolateUrl(SubmittalsVendorUrls.SUBMITTAL_NOTES, { submittalId });
        return this.http.get<SubmittalActivity[]>(`${this.settings.CORE}${url}${queryParams}`);
    }
}
