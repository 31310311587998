import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { CalendarScheduleComponent } from 'src/app/shared/components/calendar-schedule/calendar-schedule.component';
import { ShiftSchedule } from 'src/app/shared/models/shifts';
import { DateHelper } from 'src/app/shared/utilities';

@Component({
    selector: 'ayac-shift-schedule-calendar',
    templateUrl: './shift-schedule-calendar.component.html',
    styleUrls: ['./shift-schedule-calendar.component.scss']
})
export class ShiftScheduleCalendarComponent implements OnChanges {
    @Input() schedules: ShiftSchedule[] = [];
    @ViewChild(CalendarScheduleComponent, { static: true }) calendar: CalendarScheduleComponent;
    today = new Date();
    startDate: Date;
    schedulesFirstDate: Date;

    constructor() {
        this.dateClass = this.dateClass.bind(this);
        this.dateDisabled = this.dateDisabled.bind(this);
        this.dateText = this.dateText.bind(this);
    }

    ngOnChanges(changes) {
        if (changes.schedules) {
            this.calendar.fillMonth();
            this.preselectMonth();
        }
    }

    dateClass(date: Date) {
        const schedule = this.getSchedule(date);

        if (schedule) {
            const className = ['schedule-line'];

            if (this.isPastDate(date)) {
                className.push('schedule-past');
            }

            const lineIndex = this.schedules.indexOf(schedule);
            className.push(`schedule-${lineIndex + 1}`);

            return className.join(' ');
        }

        return '';
    }

    isPastDate(date): boolean {
        return moment(date).diff(this.today, 'day') < 0;
    }

    dateDisabled(date: Date) {
        return false;
    }

    dateText(date: Date) {
        const schedule = this.getSchedule(date);

        if (schedule && schedule.startTime && !isNaN(schedule.startTime.getTime())) {
            const startTimeFormat = moment(schedule.startTime).format('HH:mm');
            const endTimeFormat = moment(schedule.startTime)
                .add(moment.duration(schedule.shiftLength, 'hour'))
                .format('HH:mm');
            return `${startTimeFormat}-${endTimeFormat}`;
        }

        return '';
    }

    selectCalendarMonth(date: Date) {
        this.startDate = date;
    }

    private getSchedule(date: Date): ShiftSchedule {
        return this.schedules.find(
            (s) =>
                s.startDate &&
                s.endDate &&
                DateHelper.inWorkday(date, s.workDays) &&
                DateHelper.inRangeDay(date, s.startDate, s.endDate)
        );
    }

    private preselectMonth() {
        if (this.schedules && this.schedules.length) {
            const firstLine = this.schedules[0];
            if (!firstLine.startDate) {
                return;
            }

            if (this.schedulesFirstDate && DateHelper.isSameDay(firstLine.startDate, this.schedulesFirstDate)) {
                return;
            }

            this.schedulesFirstDate = new Date(firstLine.startDate);
            this.selectCalendarMonth(new Date(firstLine.startDate));
        } else {
            this.schedulesFirstDate = null;
        }
    }
}
