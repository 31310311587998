import { createAction, props } from '@ngrx/store';
import { HttpResponse } from '@angular/common/http';
import { SubmittalExpanded } from 'src/app/shared/models/submittals/submittal-expanded.model';
import { SubmittalJob } from 'src/app/shared/models/submittals/submittal-job.model';
import { SubmittalUpdateStatusRequest } from 'src/app/shared/models/submittals/requests/submittal-update-status.request';
import { SubmittalEmailData } from 'src/app/shared/models/submittals/submittal-email-data.model';
import { SubmittalStatusLookup } from 'src/app/shared/models/submittals/lookups/submittal-status-lookup.model';
import { SubmittalListItem } from 'src/app/shared/models/submittals/submittal-list-item.model';
import { FacilityLookup } from 'src/app/shared/models/submittals/lookups/facility-lookup.model';
import { SystemLookup } from 'src/app/shared/models/submittals/lookups/system-lookup.model';
import { UnitLookup } from 'src/app/shared/models/submittals/lookups/unit-lookup.model';
import { MspCustomFieldsList } from 'src/app/shared/models/submittals/submittal-msp-fields.model';
import { SubmittalStatusMappedLookup } from 'src/app/shared/models/submittals/lookups/submittal-status-mapped-lookup.model';
import { SubmittalDeclineReasons } from 'src/app/shared/models/submittals/lookups/submittal-decline-reasons.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { SubmittalAttachmentMetaData } from 'src/app/shared/models/submittals/responses/submittal-attachment-meta-data.model';
import { SubmittalDiversityInfo } from 'src/app/shared/models/submittals/diversity-info.model';
import {
    SubmittalOfferDetails,
    SubmittalOfferDetailsUpdateRequest,
    SubmittalOfferNote,
    SubmittalOfferNoteAddRequest,
    SubmittalRescindOfferRequest
} from 'src/app/shared/models/submittals';
import { SubmittalOfferDeclineChangesRequest } from 'src/app/shared/models/submittals/submittal-offer-decline-changes-request.model';
import { ValidationErrorResponse } from 'src/app/shared/models/validation-error-response';

export enum SubmittalsActionTypes {
    GetSubmittals = '[Submittals] Get Filterd',
    GetSubmittalsSuccess = '[Submittals] Get Filterd Success',
    GetSubmittalsFail = '[Submittals] Get Filterd Fail',
    ClearSubmittals = '[Submittals] Clear',
    GetDetails = '[Submittals] Get Details',
    GetDetailsSuccess = '[Submittals] Get Details Success',
    GetDetailsFail = '[Submittals] Get Details Fail',
    GetSubmittalJobs = '[Submittals] Get Submittal Jobs',
    GetSubmittalJobsSuccess = '[Submittals] Get Submittal Jobs Success',
    GetSubmittalJobsFail = '[Submittals] Get Submittal Jobs Fail',
    GetLookupsSecurity = '[Submittals] Get Lookups Securtiy',
    GetLookupsSecuritySuccess = '[Submittals] Get Lookups Securtiy Success',
    GetLookupsSecurityFail = '[Submittals] Get Lookups Securtiy Fail',
    GetLookupsStatuses = '[Submittals] Get Lookups Statuses',
    GetLookupsStatusesV2 = '[Submittals] Get Lookups Statuses V2',
    GetLookupsStatusesSuccess = '[Submittals] Get Lookups Statuses Success',
    GetIrpLookupsStatusesSuccess = '[Submittals] Get IRP Lookups Statuses Success',
    GetLookupsStatusesFail = '[Submittals] Get Lookups Statuses Fail',
    UpdateStatus = '[Submittals] Update Status',
    UpdateStatusSuccess = '[Submittals] Update Status Success',
    UpdateStatusFail = '[Submittals] Update Status Fail',
    Export = '[Submittals] Export',
    ExportSuccess = '[Submittals] Export Success',
    ExportFail = '[Submittals] Export Fail',
    DownloadApplication = '[Submittals] Download Application',
    DownloadApplicationSuccess = '[Submittals] Download Application Success',
    DownloadApplicationFail = '[Submittals] Download Application Fail',
    GetSubmittalEmailData = '[Submittals] Get Email Data',
    GetSubmittalEmailDataSuccess = '[Submittals] Get Email Data Success',
    GetSubmittalEmailDataFail = '[Submittals] Get Email Data Fail',
    SetSubmittalsSearchQuery = '[Submittals] Set Submittals Search Query',
    SetDashboardSubmittalsQuery = '[Dashboard Component] Set Submittals Search Query',
    SetDashboardPendingSubmittalsQuery = '[Dashboard Component] Set Pending Submittals Search Query',
    GetMspCustomFieldsSuccess = '[Submittals] Get Msp Custom Fields Success',
    ClearSubmittalDetails = '[Submittals] Clear Submittal Details',
    MapStatus = '[Submittals] Map Status',
    LoadDeclineReasons = '[Submittals Decline Candidate Modal] Load Submittal Decline Reasons',
    LoadDeclineReasonsSuccess = '[Submittals Service] Load Submittal Decline Reasons Success',
    LoadDeclineReasonsFail = '[Submittals Service] Load Submittal Decline Reasons Fail',
    GetSubmittalAttachmentsMetaData = '[Submittals Details] Get Submittal Attachments Meta Data',
    GetSubmittalAttachmentsMetaDataSuccess = '[Submittals Details] Get Submittal Attachments Meta Data Success',
    GetSubmittalAttachmentsMetaDataFailure = '[Submittals Details] Get Submittal Attachments Meta Data Fail',
    GetSubmittalAttachmentBlob = '[Submittals Details] Download Submittal Attachment Blob',
    GetSubmittalAttachmentBlobSuccess = '[Submittals Details] Download Submittal Attachment Blob Success',
    GetSubmittalAttachmentBlobFailure = '[Submittals Details] Download Submittal Attachment Blob Fail',
    LoadSubmittalDiversityInfo = '[SUBMITTALS COMPONENT] Load Vendor Info For Submittals',
    LoadSubmittalDiversityInfoSuccess = '[SUBMITTALS COMPONENT API] Load Vendor Info For Submittals Success',
    LoadSubmittalDiversityInfoFail = '[SUBMITTALS COMPONENT API] Load Vendor Info For Submittals Fail',
    GetOfferDetails = '[Submittals Component] Get Offer Details',
    GetOfferDetailsSuccess = '[Submittals API] Get Offer Details Success',
    GetOfferDetailsFailure = '[Submittals API] Get Offer Details Failure',
    RescindOffer = '[Submittals Component] Rescind Offer',
    RescindOfferSuccess = '[Submittals API] Rescind Offer Success',
    RescindOfferFailure = '[Submittals API] Rescind Offer Failure',
    UpdateOfferDetails = '[Submittals Component] Update Offer Details',
    UpdateOfferDetailsSuccess = '[Submittals API] Update Offer Details Success',
    UpdateOfferDetailsFailure = '[Submittals API] Update Offer Details Failure',
    GetOfferNotes = '[Submittals Component] Get Offer Notes',
    GetOfferNotesSuccess = '[Submittals API] Get Offer Notes Success',
    GetOfferNotesFailure = '[Submittals API] Get Offer Notes Failure',
    AddOfferNote = '[Submittals Component] Add Offer Note',
    AddOfferNoteSuccess = '[Submittals API] Add Offer Note Success',
    AddOfferNoteFailure = '[Submittals API] Add Offer Note Failure',
    DeclineOfferChangeRequest = '[Submittals Component] Decline Offer Change Request',
    DeclineOfferChangeRequestSuccess = '[Submittals API] Decline Offer Change Request Success',
    DeclineOfferChangeRequestFailure = '[Submittals API] Decline Offer Change Request Failure',
    ClearOfferState = '[Submittals Component] Clear Offer State'
}

export const loadSubmittals = createAction(
    SubmittalsActionTypes.GetSubmittals,
    props<{ isConnectDetailIRPJobSubmittals?: boolean }>()
);

export const loadSubmittalsSuccess = createAction(
    SubmittalsActionTypes.GetSubmittalsSuccess,
    props<{ submittals: SubmittalListItem[]; total: number }>()
);

export const loadSubmittalsFail = createAction(SubmittalsActionTypes.GetSubmittalsFail, props<{ error: any }>());

export const clearSubmittals = createAction(SubmittalsActionTypes.ClearSubmittals);

export const loadSubmittalDetails = createAction(SubmittalsActionTypes.GetDetails, props<{ id: number }>());

export const loadSubmittalDetailsSuccess = createAction(
    SubmittalsActionTypes.GetDetailsSuccess,
    props<{ details: SubmittalExpanded; canUpdateStatus?: boolean }>()
);

export const loadSubmittalDetailsFail = createAction(SubmittalsActionTypes.GetDetailsFail, props<{ error: any }>());
export const loadSubmittalJobs = createAction(SubmittalsActionTypes.GetSubmittalJobs, props<{ id: number }>());

export const loadSubmittalJobsSuccess = createAction(
    SubmittalsActionTypes.GetSubmittalJobsSuccess,
    props<{ jobs: SubmittalJob[] }>()
);

export const loadSubmittalJobsFail = createAction(SubmittalsActionTypes.GetSubmittalJobsFail, props<{ error: any }>());

export const loadSubmittalsLookupsSecurity = createAction(SubmittalsActionTypes.GetLookupsSecurity);

export const loadSubmittalsLookupsSecuritySuccess = createAction(
    SubmittalsActionTypes.GetLookupsSecuritySuccess,
    props<{ systems: SystemLookup[]; facilities: FacilityLookup[]; units: UnitLookup[] }>()
);

export const loadSubmittalsLookupsSecurityFail = createAction(
    SubmittalsActionTypes.GetLookupsSecurityFail,
    props<{ error: any }>()
);

export const loadSubmittalsLookupsStatuses = createAction(SubmittalsActionTypes.GetLookupsStatuses);

export const loadSubmittalsLookupsStatusesV2 = createAction(
    SubmittalsActionTypes.GetLookupsStatusesV2,
    props<{ isIrpJobSubmittal: boolean }>()
);

export const loadSubmittalsLookupsStatusesSuccess = createAction(
    SubmittalsActionTypes.GetLookupsStatusesSuccess,
    props<{ statuses: SubmittalStatusLookup[]; mappedStatuses: SubmittalStatusMappedLookup[] }>()
);

export const loadIrpSubmittalStatusesSuccess = createAction(
    SubmittalsActionTypes.GetIrpLookupsStatusesSuccess,
    props<{ irpStatuses: SubmittalStatusLookup[] }>()
);

export const loadSubmittalsLookupsStatusesFail = createAction(
    SubmittalsActionTypes.GetLookupsStatusesFail,
    props<{ error: any }>()
);

export const exportSubmittals = createAction(
    SubmittalsActionTypes.Export,
    props<{ submittalIds: number[]; isConnectDetailIRPJobSubmittals?: boolean }>()
);

export const exportSubmittalsSuccess = createAction(
    SubmittalsActionTypes.ExportSuccess,
    props<{ payload: HttpResponse<Blob> }>()
);

export const exportSubmittalsFail = createAction(SubmittalsActionTypes.ExportFail, props<{ error: any }>());

export const downloadSubmittalsApplication = createAction(
    SubmittalsActionTypes.DownloadApplication,
    props<{ submittalId: number }>()
);

export const downloadSubmittalsApplicationSuccess = createAction(
    SubmittalsActionTypes.DownloadApplicationSuccess,
    props<{ submittalId: number; payload: HttpResponse<Blob> }>()
);

export const downloadSubmittalsApplicationFail = createAction(
    SubmittalsActionTypes.DownloadApplicationFail,
    props<{ submittalId: number; error: any }>()
);

export const setSubmittalsSearchQuery = createAction(
    SubmittalsActionTypes.SetSubmittalsSearchQuery,
    props<{ query: GridSearchQuery }>()
);

export const setDashboardSubmittalsQuery = createAction(
    SubmittalsActionTypes.SetDashboardSubmittalsQuery,
    props<{ lineOfBusinessId: number; facilityIds?: number[]; unitId: number }>()
);

export const setDashboardPendingSubmittalsQuery = createAction(
    SubmittalsActionTypes.SetDashboardPendingSubmittalsQuery,
    props<{
        lineOfBusinessId: number;
        facilityIds?: number[];
        unitId?: number;
        statusId?: number;
        submittalStatusId?: number[];
        statusIds?: number[];
    }>()
);

export const updateSubmittalStatus = createAction(
    SubmittalsActionTypes.UpdateStatus,
    props<{ payload: SubmittalUpdateStatusRequest }>()
);

export const updateSubmittalStatusSuccess = createAction(
    SubmittalsActionTypes.UpdateStatusSuccess,
    props<{ details: SubmittalExpanded }>()
);

export const updateSubmittalStatusFail = createAction(SubmittalsActionTypes.UpdateStatusFail, props<{ error: any }>());

export const loadSubmittalEmailData = createAction(
    SubmittalsActionTypes.GetSubmittalEmailData,
    props<{ id: number }>()
);

export const loadSubmittalEmailDataSuccess = createAction(
    SubmittalsActionTypes.GetSubmittalEmailDataSuccess,
    props<{ data: SubmittalEmailData }>()
);

export const loadSubmittalEmailDataFail = createAction(
    SubmittalsActionTypes.GetSubmittalEmailDataFail,
    props<{ error: any }>()
);

export const loadSubmittalMspCustomFieldsSuccess = createAction(
    SubmittalsActionTypes.GetMspCustomFieldsSuccess,
    props<{ mspCustomFields: MspCustomFieldsList }>()
);

export const clearSubmittalDetails = createAction(SubmittalsActionTypes.ClearSubmittalDetails);

export const mapStatus = createAction(SubmittalsActionTypes.MapStatus, props<{ id: number }>());

export const loadSubmittalDeclineReasons = createAction(SubmittalsActionTypes.LoadDeclineReasons);

export const loadSubmittalDeclineReasonsSuccess = createAction(
    SubmittalsActionTypes.LoadDeclineReasonsSuccess,
    props<{ declineReasons: SubmittalDeclineReasons[] }>()
);

export const loadSubmittalDeclineReasonsFail = createAction(
    SubmittalsActionTypes.LoadDeclineReasonsFail,
    props<{ error: any }>()
);

export const loadSubmittalAttachmentsMetaData = createAction(
    SubmittalsActionTypes.GetSubmittalAttachmentsMetaData,
    props<{ clientSubmittalId: number }>()
);

export const loadSubmittalAttachmentsMetaDataSuccess = createAction(
    SubmittalsActionTypes.GetSubmittalAttachmentsMetaDataSuccess,
    props<{ attachmentsMetaData: SubmittalAttachmentMetaData[] }>()
);

export const loadSubmittalAttachmentsMetaDataFailure = createAction(
    SubmittalsActionTypes.GetSubmittalAttachmentsMetaDataFailure,
    props<{ error: any }>()
);

export const loadSubmittalAttachmentBlob = createAction(
    SubmittalsActionTypes.GetSubmittalAttachmentBlob,
    props<{ attachmentId: number | null; submittalId: number }>()
);

export const loadSubmittalAttachmentBlobSuccess = createAction(
    SubmittalsActionTypes.GetSubmittalAttachmentBlobSuccess,
    props<{ submittalAttachmentBlob: Blob }>()
);

export const loadSubmittalAttachmentBlobFailure = createAction(
    SubmittalsActionTypes.GetSubmittalAttachmentBlobFailure,
    props<{ error: any }>()
);

export const loadSubmittalDiversityInfo = createAction(SubmittalsActionTypes.LoadSubmittalDiversityInfo);

export const loadSubmittalDiversityInfoSuccess = createAction(
    SubmittalsActionTypes.LoadSubmittalDiversityInfoSuccess,
    props<{ submittalInfo: SubmittalDiversityInfo[] }>()
);

export const loadSubmittalDiversityInfoFail = createAction(
    SubmittalsActionTypes.LoadSubmittalDiversityInfoFail,
    props<{ error: any }>()
);

export const loadOfferDetails = createAction(
    SubmittalsActionTypes.GetOfferDetails,
    props<{ clientSubmittalId: number }>()
);

export const loadOfferDetailsSuccess = createAction(
    SubmittalsActionTypes.GetOfferDetailsSuccess,
    props<{ offerDetails: SubmittalOfferDetails }>()
);

export const loadOfferDetailsFailure = createAction(
    SubmittalsActionTypes.GetOfferDetailsFailure,
    props<{ error: ValidationErrorResponse }>()
);

export const rescindOffer = createAction(
    SubmittalsActionTypes.RescindOffer,
    props<{ clientSubmittalId: number; request: SubmittalRescindOfferRequest }>()
);

export const rescindOfferSuccess = createAction(
    SubmittalsActionTypes.RescindOfferSuccess,
    props<{ clientSubmittalId: number; offerId: number; message: string }>()
);

export const rescindOfferFailure = createAction(
    SubmittalsActionTypes.RescindOfferFailure,
    props<{ error: ValidationErrorResponse }>()
);

export const updateOfferDetails = createAction(
    SubmittalsActionTypes.UpdateOfferDetails,
    props<{ clientSubmittalId: number; request: SubmittalOfferDetailsUpdateRequest }>()
);

export const updateOfferDetailsSuccess = createAction(
    SubmittalsActionTypes.UpdateOfferDetailsSuccess,
    props<{ clientSubmittalId: number; offerId: number; message: string }>()
);

export const updateOfferDetailsFailure = createAction(
    SubmittalsActionTypes.UpdateOfferDetailsFailure,
    props<{ clientSubmittalId: number; error: ValidationErrorResponse }>()
);

export const loadOfferNotes = createAction(
    SubmittalsActionTypes.GetOfferNotes,
    props<{ clientSubmittalId: number; offerId: number }>()
);

export const loadOfferNotesSuccess = createAction(
    SubmittalsActionTypes.GetOfferNotesSuccess,
    props<{ offerNotes: SubmittalOfferNote[] }>()
);

export const loadOfferNotesFailure = createAction(
    SubmittalsActionTypes.GetOfferNotesFailure,
    props<{ error: ValidationErrorResponse }>()
);

export const addOfferNote = createAction(
    SubmittalsActionTypes.AddOfferNote,
    props<{ clientSubmittalId: number; request: SubmittalOfferNoteAddRequest }>()
);

export const addOfferNoteSuccess = createAction(
    SubmittalsActionTypes.AddOfferNoteSuccess,
    props<{ clientSubmittalId: number; offerId: number; message: string }>()
);

export const addOfferNoteFailure = createAction(
    SubmittalsActionTypes.AddOfferNoteFailure,
    props<{ error: ValidationErrorResponse }>()
);

export const declineOfferChangeRequest = createAction(
    SubmittalsActionTypes.DeclineOfferChangeRequest,
    props<{ clientSubmittalId: number; request: SubmittalOfferDeclineChangesRequest }>()
);

export const declineOfferChangeRequestSuccess = createAction(
    SubmittalsActionTypes.DeclineOfferChangeRequestSuccess,
    props<{ clientSubmittalId: number; offerId: number; message: string }>()
);

export const declineOfferChangeRequestFailure = createAction(
    SubmittalsActionTypes.DeclineOfferChangeRequestFailure,
    props<{ error: ValidationErrorResponse }>()
);

export const clearOfferState = createAction(SubmittalsActionTypes.ClearOfferState);
