import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LoadingTypes } from "src/app/shared/models";
import { InternalPoolState, internalPoolStoreKey } from "src/app/internal-pool/store/internal-pool.state";

const selectInternalPoolState = createFeatureSelector<InternalPoolState>(
    internalPoolStoreKey
);

export const selectDocumentsFeature = createSelector(
    selectInternalPoolState,
    (state) => 
        state.documents
)

export const selectDocuments = createSelector(
    selectDocumentsFeature,
    (state) => 
        state.documents.filter(a => a.isDeleted !== true)
)

export const selectDocumentPreview = createSelector(
    selectDocumentsFeature,
    (state) => {
        return {
            blob: state.preview,
            totalPages: state.previewTotal
        }
    }
)

export const selectDocumentCurrent = createSelector(
    selectDocumentsFeature,
    (state) => 
        state.currentFile
)

export const selectDocumentsPreviewLoading = createSelector(
    selectDocumentsFeature,
    (state) => 
        state.previewTotal
)

export const selectDocumentsLoading = createSelector(
    selectDocumentsFeature,
    (state) => 
        state.loading === LoadingTypes.LOADING
)
