import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from './help.component';
import { ConnectVendorCheckGuard } from 'src/app/core/vendor-check.guard';
import { ShowFormComponent } from "src/app/admin/help/show-form.component";

const routes: Routes = [
    {
        path: '',
        canActivate: [ConnectVendorCheckGuard],
        component: HelpComponent
    },
    {
        path: 'show-form',
        canActivate: [ConnectVendorCheckGuard],
        component: ShowFormComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HelpRoutingModule {}
