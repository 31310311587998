import { Action, createReducer, on } from '@ngrx/store';
import { loadNewAyaLeadsCount, loadNewAyaLeadsCountSuccess } from 'src/app/dashboard-new/store/actions';
import { AyaLeadsCount } from 'src/app/dashboard-new/models';
import { AyaLeadsApplicationStatus } from 'src/app/aya-leads/enums/aya-leads-application-status.enum';

export interface AyaLeadsDashboardState {
    ayaLeadsCount: AyaLeadsCount;
    applicationStatusIds: AyaLeadsApplicationStatus[];
}

export const initialAyaLeadsDashboardState: AyaLeadsDashboardState = {
    ayaLeadsCount: null,
    applicationStatusIds: [AyaLeadsApplicationStatus.New]
};

export const ayaLeadsDashboardReducer = (state: AyaLeadsDashboardState, action: Action) => {
    return createReducer(
        initialAyaLeadsDashboardState,
        on(
            loadNewAyaLeadsCount,
            (loadState): AyaLeadsDashboardState => ({
                ...loadState
            })
        ),

        on(
            loadNewAyaLeadsCountSuccess,
            (successState, successAction): AyaLeadsDashboardState => ({
                ...successState,
                ayaLeadsCount: successAction.ayaLeadsCount
            })
        )
    )(state, action);
};

export const reducer = (state: AyaLeadsDashboardState | undefined, action: Action) => {
    return ayaLeadsDashboardReducer(state, action);
};
