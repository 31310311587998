import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
    FormGroup,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ClientRescindOfferReasons } from 'src/app/shared/models/submittals';

@Component({
    selector: 'ayac-rescind-offer-dialog',
    templateUrl: './rescind-offer-dialog.component.html',
    styleUrls: ['./rescind-offer-dialog.component.scss']
})
export class RescindOfferDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    form: UntypedFormGroup;
    rescindReasons = [
        { id: ClientRescindOfferReasons.BudgetChanged, name: 'Budget Changed' },
        { id: ClientRescindOfferReasons.CensusDropped, name: 'Census Dropped' },
        { id: ClientRescindOfferReasons.FoundPermCandidate, name: 'Found Perm Candidate' },
        { id: ClientRescindOfferReasons.CandidateUnresponsive, name: 'Candidate Unresponsive' },
        { id: ClientRescindOfferReasons.Other, name: 'Other' }
    ];
    noteLength = 0;
    noteItemIsNotValid = false;

    constructor(
        private readonly _dialogRef: MatDialogRef<RescindOfferDialogComponent>,
        private readonly _formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    get selectedReason(): ClientRescindOfferReasons {
        return this.form?.get('reason')?.value;
    }

    get note(): string {
        return this.form?.get('note')?.value;
    }

    get isOtherSelected(): boolean {
        return this.selectedReason === ClientRescindOfferReasons.Other;
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            reason: [null, [Validators.required]],
            note: []
        });

        this.form.addValidators(this.customNotesValidator());
    }

    customNotesValidator(): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            return this.isOtherSelected && !this.note ? { invalidNote: true } : null;
        };
    }

    rescindOffer() {
        const reasonId = this.form.get('reason').value;
        const notes = this.form.get('note').value;

        this._dialogRef.close({ reasonId, notes });
    }

    onNoteItemValidationChanged(isValid: boolean) {
        this.noteItemIsNotValid = !isValid;
    }
}
