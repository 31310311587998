import { Pipe, PipeTransform } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

@Pipe({
    name: 'dayJSFormat',
    pure: true
})
export class DayJSFormatPipe implements PipeTransform {
    transform(dateTime: Dayjs | undefined, format: string = 'L'): string {
        dayjs.extend(localizedFormat);

        if (!dateTime) {
            return '';
        }
        return dayjs(dateTime).local().format(format);
    }
}
