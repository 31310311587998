import { initialState, ShiftsListState } from './reducers/shifts-list.reducers';
import { initialCombinedHistoryState, ShiftCombinedHistoryState } from './reducers/shift-combined-history.reducer';
import { initialLookupsState, ShiftsLookupsState } from './reducers/shifts-lookups.reducer';
import { initialDetailsState, ShiftDetailsState } from './reducers/shift-details.reducer';
import { ClientLookupsState, initialClientLookupsState } from './reducers/client-lookup.reducer';
import { initialShiftCandidatesState, ShiftCandidatesState } from './reducers/shift-candidates.reducer';
import { BulkShiftsListState, initialBulkShiftsListState } from './reducers/bulk-shifts-list.reducers';
import { initialShiftAttachmentsState, ShiftsAttachmentsState } from './reducers/shift-attachments.reducer';
import { ApprovalState, getInitialApprovalState } from 'src/app/approvals/store/state/approval.state';
import {
    ApprovalDetailsState,
    getInitialApprovalDetailsState
} from 'src/app/approvals/store/state/approval-details.state';
import { initialShiftSubmittalsState, ShiftSubmittalsState } from './reducers/shift-submittals.reducer';
import {
    initialShiftBookedCandidatesState,
    initialShiftSubmittalCandidatesState,
    ShiftBookedCandidatesState,
    ShiftSubmittalCandidatesState
} from './reducers/shift-submittal-candidates.reducer';
import {
    initialShiftFacilityAddressInfoState,
    ShiftFacilityAddressInfoState
} from './reducers/shift-facilityaddressinfo.reducer';
import { initialState as initialTemplatesState, ShiftTemplatesState } from './reducers/shift-templates.reducers';
import {
    initialState as initialTemplateDetailsState,
    ShiftTemplateDetailsState
} from './reducers/shift-template-details.reducers';
import {
    initialState as initialShiftReleaseSettingTemplateState,
    ShiftReleaseSettingTemplateState
} from 'src/app/shifts/store/reducers/shift-release-tiers.reducers';
import { FacilityLookupsState, initialState as initialFacilityLookupsState } from './reducers/facility-lookups.reducer';
import {
    initialState as initialShiftBonusAssignmentState,
    ShiftBonusAssignmentState
} from './reducers/shift-bonus-assignment.reducer';

export const shiftsStoreKey = 'shifts';

export interface ShiftsState {
    list: ShiftsListState;
    lookups: ShiftsLookupsState;
    details: ShiftDetailsState;
    combinedHistory: ShiftCombinedHistoryState;
    clientLookups: ClientLookupsState;
    shiftCandidates: ShiftCandidatesState;
    bulkShifts: BulkShiftsListState;
    attachments: ShiftsAttachmentsState;
    shiftApprovals: ApprovalState;
    approvalDetails: ApprovalDetailsState;
    shiftSubmittals: ShiftSubmittalsState;
    shiftSubmittalCandidates: ShiftSubmittalCandidatesState;
    shiftBookedCandidates: ShiftBookedCandidatesState;
    shiftFacilityAddressInfo: ShiftFacilityAddressInfoState;
    templates: ShiftTemplatesState;
    templateDetails: ShiftTemplateDetailsState;
    shiftReleaseSettingsTemplate: ShiftReleaseSettingTemplateState;
    facilityLookups: FacilityLookupsState;
    shiftBonusAssignment: ShiftBonusAssignmentState;
}

export const initialShiftsState = (): ShiftsState => ({
    details: initialDetailsState,
    list: initialState,
    lookups: initialLookupsState,
    combinedHistory: initialCombinedHistoryState,
    clientLookups: initialClientLookupsState,
    shiftCandidates: initialShiftCandidatesState,
    bulkShifts: initialBulkShiftsListState,
    shiftApprovals: getInitialApprovalState(),
    attachments: initialShiftAttachmentsState,
    approvalDetails: getInitialApprovalDetailsState(),
    shiftSubmittals: initialShiftSubmittalsState,
    shiftSubmittalCandidates: initialShiftSubmittalCandidatesState,
    shiftBookedCandidates: initialShiftBookedCandidatesState,
    shiftFacilityAddressInfo: initialShiftFacilityAddressInfoState,
    templates: initialTemplatesState,
    templateDetails: initialTemplateDetailsState,
    shiftReleaseSettingsTemplate: initialShiftReleaseSettingTemplateState,
    facilityLookups: initialFacilityLookupsState,
    shiftBonusAssignment: initialShiftBonusAssignmentState
});
