import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notEqualsFilter',
})
export class NotEqualsFilterPipe implements PipeTransform {
    transform(list: any[], value: any, propertyName?: string): any[] {
        if (!list) {
            return list;
        }

        if (!propertyName) {
            return list?.filter((x) => x !== value);
        }

        const result = [];
        list.forEach((listItem) => {
            const properties = propertyName.split('.');

            let propertyValue = listItem;
            for (const propertyName of properties) {
                const objectKey = Object.keys(propertyValue).find(
                    (key) => key === propertyName
                );
                if (!objectKey) {
                    propertyValue = undefined;
                    break;
                }

                propertyValue = propertyValue[propertyName];
            }

            if (propertyValue === value) {
                return;
            }

            result.push(listItem);
        });

        return result;
    }
}
