import { ReportType } from 'src/app/reports/models/enums/report-type.enum';

export abstract class ReportUrls {
    private static readonly reportAPIUrlTmpl = '/AyaConnect/Report/{0}?ViewTypeId=3';
    private static readonly reportNavUrlTmpl = '{0}'; //'/client/reports/{0}';

    public static get Reports(): ReportUrl {
        return {
            Title: 'Reports',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'GetReports'),
            Nav: this.reportNavUrlTmpl.replace('{0}', '')
        };
    }

    public static get UserSettings(): ReportUrl {
        return {
            Title: 'UserSettings',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'UserSettings'),
            Nav: this.reportNavUrlTmpl.replace('{0}', '')
        };
    }

    public static get LinesOfBusiness(): ReportUrl {
        return {
            Title: 'Dashboard numbers',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'LinesOfBusiness'),
            Nav: this.reportNavUrlTmpl.replace('{0}', '')
        };
    }

    public static get TravelJobsOpen(): ReportUrl {
        return {
            Title: 'Open Orders',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'OpenJobs'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'open-orders')
        };
    }

    public static get TravelJobHistory(): ReportUrl {
        return {
            Title: 'Order History',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'JobHistory'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'order-history')
        };
    }

    public static get TravelAssignmentsCurrent(): ReportUrl {
        return {
            Title: 'On Assignment',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'CurrentAssignments'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'on-assignment')
        };
    }

    public static get TravelAssignmentsFuture(): ReportUrl {
        return {
            Title: 'Future',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'FutureAssignments'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'future')
        };
    }

    public static get TravelAssignmentsInactive(): ReportUrl {
        return {
            Title: 'Inactive',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'InactiveAssignments'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'inactive')
        };
    }

    public static get TravelAssignmentsCancellations(): ReportUrl {
        return {
            Title: 'Cancellations',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'CancelledAssignments'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'сancellations')
        };
    }

    public static get PerDiemPoolActive(): ReportUrl {
        return {
            Title: 'Active Pool',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'PerDiemActivePool'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'active-pool')
        };
    }

    public static get PerDiemShiftsOpen(): ReportUrl {
        return {
            Title: 'Open Shifts',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'PerDiemOpenShifts'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'open-shifts')
        };
    }

    public static get PerDiemShiftHistory(): ReportUrl {
        return {
            Title: 'Shift History',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'PerDiemShiftHistory'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'shift-history')
        };
    }

    public static get SpendingByMonth(): ReportUrl {
        return {
            Title: 'Spend by Month',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'spending-by-month'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'spend-by-month')
        };
    }

    public static get SpendingByLocation(): ReportUrl {
        return {
            Title: 'Spend by Location',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'spending-by-location'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'spend-by-location')
        };
    }

    public static get SpendingByUnit(): ReportUrl {
        return {
            Title: 'Spend by Unit',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'spending-by-unit'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'spend-by-unit')
        };
    }

    public static get SpendingBySpecialty(): ReportUrl {
        return {
            Title: 'Spend by Specialty',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'spending-by-specialty'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'spend-by-specialty')
        };
    }

    public static get SpendingByCostCenter(): ReportUrl {
        return {
            Title: 'Spend by Cost Center',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'spending-by-cost-center'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'spend-by-cost-center')
        };
    }

    public static get SpendingBySupplier(): ReportUrl {
        return {
            Title: 'Spend by Supplier',
            Api: this.reportAPIUrlTmpl.replace('{0}', 'spending-by-supplier'),
            Nav: this.reportNavUrlTmpl.replace('{0}', 'spend-by-supplier')
        };
    }

    public static get(reportType: ReportType): ReportUrl {
        switch (reportType) {
            case ReportType.TravelJobsOpen:
                return this.TravelJobsOpen;
            case ReportType.TravelJobHistory:
                return this.TravelJobHistory;
            case ReportType.TravelAssignmentsCurrent:
                return this.TravelAssignmentsCurrent;
            case ReportType.TravelAssignmentsFuture:
                return this.TravelAssignmentsFuture;
            case ReportType.TravelAssignmentsInactive:
                return this.TravelAssignmentsInactive;
            case ReportType.TravelAssignmentsCancellations:
                return this.TravelAssignmentsCancellations;
            case ReportType.PerDiemPoolActive:
                return this.PerDiemPoolActive;
            case ReportType.PerDiemShiftsOpen:
                return this.PerDiemShiftsOpen;
            case ReportType.PerDiemShiftHistory:
                return this.PerDiemShiftHistory;
            case ReportType.SpendingByMonth:
                return this.SpendingByMonth;
            case ReportType.SpendingByLocation:
                return this.SpendingByLocation;
            case ReportType.SpendingByUnit:
                return this.SpendingByUnit;
            case ReportType.SpendingBySpecialty:
                return this.SpendingBySpecialty;
            case ReportType.SpendingByCostCenter:
                return this.SpendingByCostCenter;
            case ReportType.SpendingBySupplier:
                return this.SpendingBySupplier;
        }
        return { Title: '', Api: '', Nav: '' };
    }

    public static getExportUrl(reportType: ReportType): string | null {
        switch (reportType) {
            case ReportType.SpendingByMonth:
                return this.reportAPIUrlTmpl.replace('{0}', 'spending-by-month-csv');
            case ReportType.SpendingByLocation:
                return this.reportAPIUrlTmpl.replace('{0}', 'spending-by-location-csv');
            case ReportType.SpendingByUnit:
                return this.reportAPIUrlTmpl.replace('{0}', 'spending-by-unit-csv');
            case ReportType.SpendingBySpecialty:
                return this.reportAPIUrlTmpl.replace('{0}', 'spending-by-specialty-csv');
            case ReportType.SpendingByCostCenter:
                return this.reportAPIUrlTmpl.replace('{0}', 'spending-by-cost-center-csv');
            case ReportType.SpendingBySupplier:
                return this.reportAPIUrlTmpl.replace('{0}', 'spending-by-supplier-csv');
        }
        return null;
    }
}

//API URLs - report data
//Navigation URLs - browser's path
export interface ReportUrl {
    Title: string;
    Api: string;
    Nav: string;
}
