import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map, takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import * as submittalsSelectors from 'src/app/submittals/store/submittals.selectors';
import * as submittalsActions from 'src/app/submittals/store/submittals.actions';

@Component({
    selector: 'app-submittal-document-viewer',
    templateUrl: './submittal-document-viewer.component.html',
    styleUrls: ['./submittal-document-viewer.component.css']
})
export class SubmittalDocumentViewerComponent extends UnsubscribeOnDestroy implements OnInit {
    documentUrl$: Observable<string>;
    submittalId: number;
    attachmentId: number | null = null;

    constructor(
        @Inject('Window') private readonly _window: Window,
        private readonly store: Store<{}>,
        private readonly _route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        const attachmentIdParam = this._route.snapshot.queryParamMap.get('attachmentId');

        if (attachmentIdParam) {
            this.attachmentId = +attachmentIdParam;
        }

        this._route.paramMap.pipe(takeUntil(this.d$)).subscribe((params: ParamMap) => {
            this.submittalId = +params.get('id');

            this.store.dispatch(
                submittalsActions.loadSubmittalAttachmentBlob({
                    attachmentId: this.attachmentId,
                    submittalId: this.submittalId
                })
            );
        });

        this.documentUrl$ = this.store
            .select(submittalsSelectors.selectSubmittalAttachmentsBlob)
            .pipe(map((blob: Blob) => this._getBlobUrl(blob)));
    }

    private _getBlobUrl(blob: Blob): string {
        const url = blob ? this._window.window.URL.createObjectURL(blob) : null;
        return url;
    }
}
