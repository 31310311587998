import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from 'src/app/clinical/store/actions/client.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ClinicalService } from 'src/app/clinical/services/clinical.service';
import { Store } from '@ngrx/store';
import { selectClientId } from '../selectors/client.selectors';

@Injectable()
export class ClientEffects {
    getClient$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(actions.loadClientInformation),
            withLatestFrom(this.store.select(selectClientId)),
            switchMap(([, clientId]) => {
                return this._clinicalService.getClient(clientId).pipe(
                    map((result) => {
                        result.traumaList = [];
                        if (result.isTraumaLevel1) {
                            result.traumaList.push('I');
                        }
                        if (result.isTraumaLevel2) {
                            result.traumaList.push('II');
                        }
                        if (result.isTraumaLevel3) {
                            result.traumaList.push('III');
                        }
                        if (result.isTraumaLevel4) {
                            result.traumaList.push('IV');
                        }
                        return actions.loadClientInformationSuccess({
                            clinicalClient: result
                        });
                    }),
                    catchError((error: unknown) => of(actions.loadClientInformationFailure({ error })))
                );
            })
        );
    });

    constructor(
        private readonly _actions$: Actions,
        private readonly _clinicalService: ClinicalService,
        private readonly store: Store
    ) {}
}
