import { createAction, props } from '@ngrx/store';
import { DocumentAttribute } from '../models/document-attribute';
import { DocumentAttributeField } from '../models/document-attribute-field';
import { Candidate } from 'src/app/shared/models/candidate';

export const setCandidateForDocumentUpload = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Set Candidate For Document Upload',
    props<{ candidate: Candidate }>()
);

export const uploadRequirementFilePreview = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Upload Requirement File Preview',
    props<{
        docTypeId: number;
        fileToUpload: any;
        contractId: number;
        candidateId: number;
        candidateOldUserId: number;
    }>()
);

export const uploadRequirementFilePreviewSuccess = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Upload Requirement File Preview Success',
    props<{
        candidateDocumentId: number;
    }>()
);

export const uploadRequirementFilePreviewFailure = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Upload Requirement File Preview Failure',
    props<{ error: any; fileName: string }>()
);

export const getDocumentUploaded = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Get Requirement Document For Preview',
    props<{ candidateDocumentId: number }>()
);

export const getDocumentUploadedSuccess = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Get Requirement Document For Preview Success',
    props<{ file: any }>()
);

export const getDocumentUploadedFailure = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Get Requirement Document For Preview Failure',
    props<{ error: any }>()
);

export const getMetadataForRequirementFile = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Get Meta Data For Requirement File',
    props<{ file: any }>()
);

export const getMetadataForRequirementFileSuccess = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Get Meta Data For Requirement File Success',
    props<{ documentRequirementAttributeFields: DocumentAttributeField[]; documentAttributes: DocumentAttribute[] }>()
);

export const getMetadataForRequirementFileFailure = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Get Meta Data For Requirement File Failure',
    props<{ error: any }>()
);

export const setIsFormValid = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Set Is Form Valid',
    props<{ isFormValid: boolean }>()
);

export const setDocumentAttributeValue = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Set Document Attribute Value',
    props<{ fieldName: string; fieldValue: any }>()
);

export const setDocumentAttributeValueSuccess = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Set Document Attribute Value Success',
    props<{ documentAttributes: DocumentAttribute[]; docReviewerExpirationDate: string }>()
);

export const setDocumentAttributeValueEnd = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Set Document Attribute Value End'
);

export const getFilteredMetadataFieldsForRequirementFile = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Filter Document Attribute Fields',
    props<{
        documentTypeId: string;
        documentAttributes: DocumentAttribute[];
        fieldName: string;
        docReviewerExpirationDate: any;
    }>()
);

export const getFilteredMetadataForRequirementFileSuccess = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Get Filtered Meta Data For Requirement File Success',
    props<{ documentRequirementAttributeFields: DocumentAttributeField[]; documentAttributes: DocumentAttribute[] }>()
);

export const getFilteredMetadataForRequirementFileFailure = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Get Filtered Meta Data For Requirement File Failure',
    props<{ error: any }>()
);

export const saveDocumentMetadata = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Save Document Metadata',
    props<{ documentMetadataInputForSave: DocumentAttribute[] }>()
);

export const saveDocumentMetadataSuccess = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Save Document Metadata Success'
);

export const saveDocumentMetadataFailure = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Save Document Metadata Failure',
    props<{ error: any }>()
);

export const deleteDocumentCalled = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Delete Document Called',
    props<{ deleteDocumentCalledWithNavigation: boolean }>()
);

export const deleteDocumentCancelled = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Delete Document Cancelled'
);

export const deleteDocumentSuccess = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Delete Document Success',
    props<{ deleteDocumentCalledWithNavigation: boolean }>()
);

export const deleteDocumentFailure = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Delete Document Failure',
    props<{ error: any }>()
);

export const resetDocumentUploadStateAfterSaveOrDelete = createAction(
    '[VENDOR CANDIDATE REQUIREMENT DOCUMENT UPLOAD] Reset Document Upload State After Save Or Delete'
);
