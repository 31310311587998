import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomainService } from './domain.service';
import { Brands, DomainConfigurationKeys } from '../models';

@Injectable()
export class BrandingService {
    env = this.domainService.environment();

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly domainService: DomainService
    ) { }

    enable(brand: Brands) {

        this.removeAll();

        this.updateBodyClass(brand);
        this.updateStylesAndMeta(brand);
    }

    disable() {
        this.enable(this.env as Brands);
    }

    removeAll() {
        const elements = this.document.querySelectorAll('[data-brand-meta]');
        Array.prototype.forEach.call(elements, elm => {
            elm.parentNode.removeChild(elm);
        });

        const logoContainer = this.document.getElementById('top-nav-logo');

        if (logoContainer) {
            logoContainer.classList.remove('brand-logo');
        }
    }

    updateStylesAndMeta(brand: Brands) {
        const configurations = this.domainService.configurations
            .filter(c => c.environment === brand);

        for (const conf of configurations) {
            switch (conf.key) {
                case DomainConfigurationKeys.STYLES_AND_META: {
                    for (const meta of conf.value) {
                        this.addMeta(meta.key, meta.value)
                    }
                    break;
                }
            }
        }
    }

    updateLogo(data: string) {
        const img = this.document.createElement('img');
        img.setAttribute('src', data);
        img.setAttribute('data-brand-meta', '');

        const container = this.document.getElementById('top-nav-logo');
        container.classList.add('brand-logo');
        container.appendChild(img);
    }

    toggleBodyClass(className: string, force?: boolean) {
        this.bodyElement.classList.toggle(className, force);
    }

    private addMeta(metaName = 'link', attrs: {[key: string]: string}) {
        var meta = document.createElement(metaName);
        meta.setAttribute('data-brand-meta', '');

        for (const attr in attrs) {
            meta.setAttribute(attr, attrs[attr]);
        }
        this.headElement.appendChild(meta);
    }

    private updateBodyClass(brand: Brands) {
        const reg = /env-[\w\d]{3,}/i;
        const classList = Array.from(this.bodyElement.classList);

        for (const className of classList) {
            if (reg.test(className)) {
                this.bodyElement.classList.remove(className)
            }
        }

        const brandClassName = `env-${brand.toLowerCase()}`;
        this.bodyElement.classList.add(brandClassName);
    }

    private get headElement() {
        return this.document.getElementsByTagName('head')[0];
    }
    private get bodyElement() {
        return this.document.getElementsByTagName('body')[0];
    }

}
