import { Action, createReducer, on } from '@ngrx/store';
import * as actions from 'src/app/facilities/store/actions/job-release-settings.actions';
import { LoadingTypes } from 'src/app/shared/models';
import { ScheduledReleaseRulesState, getInitialScheduledReleaseRulesState } from 'src/app/facilities/store/state/scheduled-release-rules.state';
import { ScheduledReleaseRule } from 'src/app/jobs/models/lookups/scheduled-release-rule.lookup';

export function scheduledReleaseRulesReducer(state: ScheduledReleaseRulesState | undefined, action: Action) {
    return createReducer(
        getInitialScheduledReleaseRulesState(),
        on(actions.loadJobReleaseSettingsPermissions,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRulesPermissionsLoadingState: LoadingTypes.LOADING
                })),

        on(actions.loadScheduledJobReleaseRulesPermissionsSuccess,
            (state, action): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRulesPermissionsLoadingState: LoadingTypes.LOADED,
                    scheduledReleaseRulesPermissions: action.scheduledReleaseRulePermissions
                })),

        on(actions.loadScheduledJobReleaseRulesPermissionsFailure,
            actions.loadScheduledJobReleaseRulesFailure,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRulesLoadingState: LoadingTypes.LOADED
                })),
        on(actions.loadScheduledJobReleaseRules,
            (state): ScheduledReleaseRulesState => ({ ...state, scheduledReleaseRulesLoadingState: LoadingTypes.LOADING })),

        on(actions.loadScheduledJobReleaseRulesSuccess,
            (state, action): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRulesLoadingState: LoadingTypes.LOADED,
                    scheduledReleaseRules: action.scheduledReleaseRules
                })),

        on(actions.loadScheduledJobReleaseRulesFailure,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRulesLoadingState: LoadingTypes.FAIL
                })),

        on(actions.cleanScheduledJobReleaseRule,
            (state): ScheduledReleaseRulesState => ({
                ...state,
                scheduledReleaseRule: {} as ScheduledReleaseRule,
                // releaseGroups: [],
                scheduledReleaseRuleProfessions: [],
                scheduledReleaseRuleExpertises: [],
                scheduledReleaseRuleVendors: []
            })),

        on(actions.loadScheduledJobReleaseRule,
            (state): ScheduledReleaseRulesState => ({
                ...state,
                scheduledReleaseRuleLoadingState: LoadingTypes.LOADING,
                // releaseGroups: [],
                scheduledReleaseRuleProfessions: [],
                scheduledReleaseRuleExpertises: [],
                scheduledReleaseRuleVendors: []
            })),

        on(actions.loadScheduledJobReleaseRuleSuccess,
            (state, action): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRuleLoadingState: LoadingTypes.LOADED,
                    scheduledReleaseRule: {
						...action.scheduledReleaseRule,
						includeWeekends: action.scheduledReleaseRule.includeWeekends ?? false
					}
                })),

        on(actions.loadScheduledJobReleaseRuleFailure,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRuleLoadingState: LoadingTypes.LOADED
                })),

        on(actions.deleteScheduledJobReleaseRule,
            (state): ScheduledReleaseRulesState => ({ ...state, scheduledReleaseRulesLoadingState: LoadingTypes.LOADING })),

        on(actions.deleteScheduledJobReleaseRuleSuccess,
            (state): ScheduledReleaseRulesState => ({ ...state, scheduledReleaseRules: [], scheduledReleaseRulesLoadingState: LoadingTypes.LOADED })),

        on(actions.deleteScheduledJobReleaseRuleFailure,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRulesLoadingState: LoadingTypes.LOADED,
                })),

        on(actions.loadScheduledJobReleaseRuleProfessions,
            (state): ScheduledReleaseRulesState => ({ ...state, scheduledReleaseRuleProfessionsLoadingState: LoadingTypes.LOADING })),

        on(actions.loadScheduledJobReleaseRuleProfessionsSuccess,
            (state, action): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRuleProfessionsLoadingState: LoadingTypes.LOADED,
                    scheduledReleaseRuleProfessions: action.professions
                })),

        on(actions.loadScheduledJobReleaseRuleProfessionsFailure,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRuleProfessionsLoadingState: LoadingTypes.FAIL
                })),

        on(actions.loadScheduledJobReleaseRuleExpertises,
            (state): ScheduledReleaseRulesState => ({ ...state, scheduledReleaseRuleExpertisesLoadingState: LoadingTypes.LOADING })),

        on(actions.loadScheduledJobReleaseRuleExpertisesSuccess,
            (state, action): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRuleExpertisesLoadingState: LoadingTypes.LOADED,
                    scheduledReleaseRuleExpertises: action.expertises
                })),

        on(actions.loadScheduledJobReleaseRuleExpertisesFailure,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRuleExpertisesLoadingState: LoadingTypes.FAIL
                })),

        on(actions.loadScheduledJobReleaseRuleVendors,
            (state): ScheduledReleaseRulesState => ({ ...state, scheduledReleaseRuleExpertisesLoadingState: LoadingTypes.LOADING })),

        on(actions.loadScheduledJobReleaseRuleVendorsSuccess,
            (state, action): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRuleExpertisesLoadingState: LoadingTypes.LOADED,
                    scheduledReleaseRuleVendors: action.vendors
                })),

        on(actions.loadScheduledJobReleaseRuleVendorsFailure,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    scheduledReleaseRuleExpertisesLoadingState: LoadingTypes.FAIL
                })),

        on(actions.saveScheduledJobReleaseRule,
            (state): ScheduledReleaseRulesState => ({ ...state, saveScheduledReleaseRuleLoadingState: LoadingTypes.LOADING })),

        on(actions.saveScheduledJobReleaseRuleSuccess,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    saveScheduledReleaseRuleLoadingState: LoadingTypes.LOADED
                })),

        on(actions.saveScheduledJobReleaseRuleFailure,
            (state): ScheduledReleaseRulesState =>
                ({
                    ...state,
                    saveScheduledReleaseRuleLoadingState: LoadingTypes.FAIL
                })),

    )(state, action);
}
