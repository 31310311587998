import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, catchError, tap, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubmittalsService } from 'src/app/submittals/services/submittals.service';
import { SubmittalsBaseEffect } from './submittals-base.effect';
import { ToasterService } from 'src/app/core/services/toaster.service';
import * as submittalActions from 'src/app/submittals/store/actions';
import { mapValidationError } from 'src/app/shared/models/validation-error-response';
import { HttpErrorResponse } from '@angular/common/http';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

const defaultFailureMessages = new Map<string, string>([
    [submittalActions.loadOfferDetailsFailure.type, 'Failed to retrieve offer details.'],
    [submittalActions.createOfferFailure.type, 'Failed to create offer.'],
    [submittalActions.updateOfferDetailsFailure.type, 'Failed to update offer details.'],
    [submittalActions.rescindOfferFailure.type, 'Failed to rescind offer.'],
    [submittalActions.declineOfferChangeRequestFailure.type, 'Failed to decline offer change request.'],
    [submittalActions.loadOfferNotesFailure.type, 'Failed to load comments.'],
    [submittalActions.addOfferNoteFailure.type, 'Failed to add comment.']
]);

@Injectable()
export class SubmittalOfferDetailsEffect extends SubmittalsBaseEffect {
    getSubmittalOfferDetails$ = createEffect(() => {
        return this._actions.pipe(
            ofType(
                submittalActions.loadOfferDetails,
                submittalActions.rescindOfferSuccess,
                submittalActions.updateOfferDetailsSuccess,
                submittalActions.updateOfferDetailsFailure,
                submittalActions.declineOfferChangeRequestSuccess
            ),
            map((action) => action.clientSubmittalId),
            exhaustMap((id: number) =>
                this._submittalsService.getSubmittalOfferDetails(id).pipe(
                    tap(() => this.logData('Submittal offer details loaded.')),
                    map((offerDetails) => submittalActions.loadOfferDetailsSuccess({ offerDetails })),
                    catchError((error: HttpErrorResponse) =>
                        of(submittalActions.loadOfferDetailsFailure({ error: error.error }))
                    )
                )
            )
        );
    });

    getSubmittalOfferNotes$ = createEffect(() => {
        return this._actions.pipe(
            ofType(
                submittalActions.loadOfferNotes,
                submittalActions.addOfferNoteSuccess,
                submittalActions.updateOfferDetailsSuccess,
                submittalActions.declineOfferChangeRequestSuccess,
                submittalActions.rescindOfferSuccess
            ),
            concatLatestFrom(() => this._featureManager.isEnabled(FeatureFlag.ConnectVMSClientOfferNotes)),
            exhaustMap(([action, featureFlag]) => {
                if (!featureFlag) {
                    return of();
                }
                return this._submittalsService.getSubmittalOfferNotes(action.clientSubmittalId, action.offerId).pipe(
                    tap(() => this.logData('Submittal offer notes loaded.')),
                    map((offerNotes) => submittalActions.loadOfferNotesSuccess({ offerNotes })),
                    catchError((error: HttpErrorResponse) =>
                        of(submittalActions.loadOfferNotesFailure({ error: error.error }))
                    )
                );
            })
        );
    });

    addSubmittalOfferNote$ = createEffect(() => {
        return this._actions.pipe(
            ofType(submittalActions.addOfferNote),
            exhaustMap((action) =>
                this._submittalsService.addSubmittalOfferNote(action.clientSubmittalId, action.request).pipe(
                    map(() =>
                        submittalActions.addOfferNoteSuccess({
                            clientSubmittalId: action.clientSubmittalId,
                            offerId: action.request.offerId,
                            message: 'Comment added successfully.'
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(submittalActions.addOfferNoteFailure({ error: error.error }))
                    )
                )
            )
        );
    });

    updateSubmittalOfferDetails$ = createEffect(() => {
        return this._actions.pipe(
            ofType(submittalActions.updateOfferDetails),
            exhaustMap((action) =>
                this._submittalsService.updateSubmittalOfferDetails(action.clientSubmittalId, action.request).pipe(
                    map(() =>
                        submittalActions.updateOfferDetailsSuccess({
                            clientSubmittalId: action.clientSubmittalId,
                            offerId: action.request.offerId,
                            message: 'Submittal offer details successfully updated.'
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            submittalActions.updateOfferDetailsFailure({
                                clientSubmittalId: action.clientSubmittalId,
                                error: error.error
                            })
                        )
                    )
                )
            )
        );
    });

    createOffer$ = createEffect(() => {
        return this._actions.pipe(
            ofType(submittalActions.createOffer),
            exhaustMap((action) =>
                this._submittalsService.createOffer(action.offer).pipe(
                    map(() =>
                        submittalActions.createOfferSuccess({
                            message: action.useAyaAccountManagement
                                ? 'Candidate status successfully updated.'
                                : 'Offer successfully created.'
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(submittalActions.createOfferFailure({ error: error.error }))
                    )
                )
            )
        );
    });

    rescindSubmittalOffer$ = createEffect(() => {
        return this._actions.pipe(
            ofType(submittalActions.rescindOffer),
            exhaustMap((action) =>
                this._submittalsService.rescindSubmittalOffer(action.clientSubmittalId, action.request).pipe(
                    map(() =>
                        submittalActions.rescindOfferSuccess({
                            clientSubmittalId: action.clientSubmittalId,
                            offerId: action.request.offerId,
                            message: 'Offer rescinded successfully.'
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(submittalActions.rescindOfferFailure({ error: error.error }))
                    )
                )
            )
        );
    });

    declineOfferChangeRequest$ = createEffect(() => {
        return this._actions.pipe(
            ofType(submittalActions.declineOfferChangeRequest),
            exhaustMap((action) =>
                this._submittalsService.declineOfferChangesRequest(action.clientSubmittalId, action.request).pipe(
                    map(() =>
                        submittalActions.declineOfferChangeRequestSuccess({
                            clientSubmittalId: action.clientSubmittalId,
                            offerId: action.request.offerId,
                            message: 'Offer change request declined successfully.'
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(submittalActions.declineOfferChangeRequestFailure({ error: error.error }))
                    )
                )
            )
        );
    });

    showSuccessToaster$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(
                    submittalActions.createOfferSuccess,
                    submittalActions.updateOfferDetailsSuccess,
                    submittalActions.rescindOfferSuccess,
                    submittalActions.declineOfferChangeRequestSuccess,
                    submittalActions.addOfferNoteSuccess
                ),
                map((action) => this.toasterService.success(action.message))
            );
        },
        { dispatch: false }
    );

    showFailureToaster$ = createEffect(
        () => {
            return this._actions.pipe(
                ofType(
                    submittalActions.loadOfferDetailsFailure,
                    submittalActions.createOfferFailure,
                    submittalActions.updateOfferDetailsFailure,
                    submittalActions.rescindOfferFailure,
                    submittalActions.declineOfferChangeRequestFailure,
                    submittalActions.loadOfferNotesFailure,
                    submittalActions.addOfferNoteFailure
                ),
                map((action) =>
                    this.toasterService.fail(mapValidationError(action.error, defaultFailureMessages.get(action.type)))
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private readonly _actions: Actions,
        private readonly _submittalsService: SubmittalsService,
        private readonly _featureManager: LDFeatureManager,
        toasterService: ToasterService
    ) {
        super(toasterService);
    }
}
