import { createAction, props } from '@ngrx/store';
import { Brands } from 'src/app/shared/models';

export const enableBranding = createAction(
    '[CORE] Enable Branding',
    props<{
        brand: Brands
    }>()
);

export const disableBranding = createAction(
    '[CORE] Disable Branding'
);

export const loadBranding = createAction(
    '[CORE] Load Branding'
);
