import { Component, OnInit, Input, ViewEncapsulation, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MultiSelectCalendarHeaderComponent } from './multi-select-calendar-header.component';

@Component({
    selector: 'multi-select-calendar',
    templateUrl: './multi-select-calendar.component.html',
    styleUrls: ['./multi-select-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MultiSelectCalendarComponent implements OnInit {
    selected: Date[] = [];

    @Input()
    set dates(dates: Date[]) {
        if (Array.isArray(dates)) {
            this.selected = dates.slice();
        } else {
            this.selected = [dates];
        }
    }

    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() disabledDates: Date[];
    @Input() disabledDatesStyle: string;
    @Input() multi: boolean;
    @Input() showConfirmationCount: boolean;
    @Input() extraDateClass: (date: Date) => string;

    @ViewChild(MatCalendar, { static: true }) calendar: MatCalendar<Date>;
    @Output() save = new EventEmitter<Date[]>();
    @Output() cancel = new EventEmitter();

    multiHeader = MultiSelectCalendarHeaderComponent;

    constructor() {}

    ngOnInit() {}

    onSelect(d: Date) {
        if (this.multi) {
            var index = this.selected.findIndex((s) => this.getDayNumber(s) === this.getDayNumber(d));

            if (index >= 0) {
                this.selected.splice(index, 1);
            } else {
                this.selected.push(d);
            }
        } else {
            this.selected.length = 0;
            this.selected.push(d);
        }

        this.calendar.updateTodaysDate();
    }

    dateClass = (d: Date) => {
        const className = [];

        const isSelected = this.selected.some((s) => this.getDayNumber(s) === this.getDayNumber(d));

        if (isSelected) {
            className.push('selected-date-class');
        }

        if (this.disabledDatesStyle && this.disabledDates && this.disabledDates.length > 0) {
            const isDisabled = this.disabledDates.some((s) => this.getDayNumber(s) === this.getDayNumber(d));

            if (isDisabled) {
                className.push(`disabled-date-${this.disabledDatesStyle}`);
            }
        }

        const dayClass = typeof this.extraDateClass === 'function' ? this.extraDateClass(d) : undefined;

        if (dayClass) {
            className.push(dayClass);
        }

        return className.join(' ');
    };

    dateFilter = (d: Date) => {
        if (Array.isArray(this.disabledDates)) {
            const dayNumber = this.getDayNumber(d);
            const sameDay = this.disabledDates.some((a) => this.getDayNumber(a) === dayNumber);

            if (sameDay) {
                return false;
            }
        }

        return this.minDate instanceof Date ? d.getTime() > this.minDate.getTime() : true;
    };

    onSave() {
        this.save.emit(this.selected);
    }

    onCancel() {
        this.cancel.emit();
    }

    private getDayNumber(d: Date): number {
        return (new Date(d).getTime() / (1000 * 60 * 60 * 24)) | 0;
    }
}
