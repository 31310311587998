import { Pipe, PipeTransform } from '@angular/core';
import { PhoneType } from 'src/app/shared/models/candidate';

@Pipe({
    name: 'vendorPhoneNumber'
})
export class VendorPhoneNumberPipe implements PipeTransform {
    constructor() {}

    transform(phoneNumber: string, phoneTypeId: number, phoneTypes: PhoneType[]): string {
        const phoneType = phoneTypes?.find((x) => x.id === phoneTypeId);
        if (!phoneNumber) {
            return `${phoneType.type}: () ext:`;
        }

        let result = `${phoneType?.type ?? ''}: (___) ___-____ ext: ____`;
        const phoneDigitsOnly = phoneNumber.replace(/\D/g, '');

        for (const digit of phoneDigitsOnly) {
            result = result.replace('_', digit);
        }

        return result;
    }
}
