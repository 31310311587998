import {
    initialSystemSettingsState,
    SystemSettingsState
} from 'src/app/systems/store/reducers/system-settings.reducers';


export const systemsFeatureKey = 'systems';

export interface SystemsState {
    settings: SystemSettingsState;
}

export const initialSystemState = (): SystemsState => ({
    settings: initialSystemSettingsState
});
