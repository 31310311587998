import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { GridSearchRequest } from 'src/app/shared/grid/models/grid-search-request.model';
import { flattenFilter } from 'src/app/shared/grid/utils/flatten-filter';
import { SortTypes } from 'src/app/shared/models';

export function convertToSearchRequest<TFilter>(query: GridSearchQuery, filter: TFilter = null): GridSearchRequest<TFilter> {
    if (!query) {
        return null;
    }
    let sort: SortDescriptor = query.sort != null && query.sort.length > 0 ? query.sort[0] : null;

    return {
        pagination: {
            pageSize: query.take,
            skip: query.skip
        },
        sortArgs: sort ? {
            sortField: sort.field,
            sortType: sort.dir === 'desc' ? SortTypes.DESC : SortTypes.ASC
        } : null,
        filter: { ...flattenFilter(query.filter) as TFilter, ...filter }
    };
}
