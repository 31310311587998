/**angular */
import { Injectable } from '@angular/core';

/**ngrx and rxjs */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';

/**shared */
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BaseEffect } from 'src/app/shared/store/base-effect';
import { ToasterService } from 'src/app/core/services';

/**local */
import { VendorFilesService } from 'src/app/admin/vendor-details/services/vendor-files.service';
import * as actions from 'src/app/admin/store/actions/vendor-details-files.actions';
import * as selectors from 'src/app/admin/store/selectors/vendor-detail-files.selectors';

@Injectable()
export class VendorFilesEffects extends BaseEffect {
    loadVendorFileList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadVendorDetailFiles, actions.deleteVendorDetailFilesSuccess),
            withLatestFrom(this._store$.pipe(select(selectors.selectVendorFilesSearchQuery))),
            exhaustMap(([action, query]) =>
                this._vendorFileService.getVendorFiles(action.vendorId, query).pipe(
                    map((response) =>
                        actions.loadVendorDetailFilesSuccess({
                            vendorFiles: response,
                            vendorId: action.vendorId
                        })
                    ),
                    catchError((error) => of(actions.loadVendorDetailFilesFailure({ error })))
                )
            )
        )
    );

    loadFileTypes$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.loadFileTypes),
            mergeMap((x) =>
                this._vendorFileService.getFileTypes().pipe(
                    map((response) =>
                        actions.loadFileTypesSuccess({
                            fileTypes: response
                        })
                    ),
                    catchError((error) => of(actions.loadFileTypesFailure({ error })))
                )
            )
        )
    );

    deleteVendorFiles$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.deleteVendorDetailFiles),
            exhaustMap((action) => {
                return this._vendorFileService.deleteFiles(action.vendorId, action.fileIds).pipe(
                    map(() => actions.deleteVendorDetailFilesSuccess({ success: true, vendorId: action.vendorId })),
                    catchError((error) => of(actions.deleteVendorDetailFilesFailure({ error })))
                );
            })
        )
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _vendorFileService: VendorFilesService,
        private readonly _dialogService: DialogService,
        private readonly _toasterService: ToasterService,
        private readonly _store$: Store<{}>
    ) {
        super(_toasterService);
    }
}
