import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CoreService } from '../../services/core.service';

@Component({
    selector: 'ayac-forgot-password-wrapper',
    template: ''
})
export class ForgotPasswordWrapperComponent {
    @Output() onOpenModal = new EventEmitter<boolean>();

    @Input() username!: string;
    @Input() set openModal(open: boolean) {
        if (!open) {
            return;
        }
        this._coreService.openForgotPasswordDialog(this.username);
        this.onOpenModal.emit(false);
    }

    constructor(private readonly _coreService: CoreService) {}
}
