import { Injectable } from '@angular/core';
import { InternalPoolClientPermissions } from 'src/app/shared/models/enums/client-permissions.enum';
import { IdentityService } from 'src/app/shared/services/identity.service';

@Injectable({
    providedIn: 'root'
})
export class SubmittalAuthService {
    constructor(
        private readonly identityService: IdentityService
    ) {
    }

    hasInternalPool(): boolean {
        return this.identityService.inScope('client', InternalPoolClientPermissions.InternalPoolView);
    }

    hasConnectDetailIRPJobSubmittals(): boolean {
        return this.identityService.inScope('client', InternalPoolClientPermissions.ConnectDetailIRPJobSubmittals);
    }

    canEditIRPJobSubmittals() {
        return this.identityService.inScope('client', InternalPoolClientPermissions.EditIRPJobSubmittal);
    }
}
