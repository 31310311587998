import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { UrlEscapeable } from '../models/url-escapeable';

export function combine(path: string, ...otherPaths: string[]): string {
    const sep = '/';
    let result = path;
    otherPaths.forEach((seg) => {
        if (seg.length) {
            if (!result.endsWith(sep) && !seg.startsWith(sep)) result += sep;
            result += seg;
        }
    });
    return result;
}

export function appendQuery(path: string, ...args: string[]): string {
    let result = path;
    if (path.indexOf('?') < 0) {
        if (path.endsWith('/')) result = path.substring(0, path.length - 1) + '?';
        else result += '?';
    } else if (!result.endsWith('&')) result += '&';
    return result + args.join('&');
}

export function arrayToQuery(key: string, vals: any[]): string {
    if (vals && vals.length) return vals.map((val) => `${key}=${encodeURIComponent(val)}`).join('&');
    return '';
}

export function getQueryString(filter: CompositeFilterDescriptor): string {
    let queryString = '';
    for (const prop in filter) {
        const val = filter[prop];
        if (Array.isArray(val)) {
            queryString = appendQuery(queryString, arrayToQuery(prop, val));
        } else if (val instanceof Date) {
            queryString = appendQuery(queryString, `${prop}=${formatDate(val)}`);
        } else if (val != null) {
            queryString = appendQuery(queryString, `${prop}=${val}`);
        }
    }
    return queryString;
}

export function formatDate(date: Date): string {
    const pad = (number): string => {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    };

    return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds()) +
        '.' +
        (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        'Z'
    );
}

export function uriFormat(uri: string) {
    const escapeDict: UrlEscapeable[] = [
        { original: ' ', escaped: '%20' },
        { original: '(', escaped: '%28' },
        { original: ')', escaped: '%29' },
        { original: '-', escaped: '%2D' },
        { original: '\\', escaped: '%5C' },
        { original: '/', escaped: '%2F' },
        { original: '_', escaped: '%5F' },
        { original: '[', escaped: '%5B' },
        { original: ']', escaped: '%5D' },
        { original: '~', escaped: '%7E' },
        { original: '#', escaped: '%23' }
    ];
    for (const d of escapeDict) {
        while (uri.includes(d.original)) {
            uri = uri.replace(d.original, d.escaped);
        }
    }
    return uri;
}
