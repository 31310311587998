import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './help.component';
import { HelpService } from './help.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { HelpRoutingModule } from './help-page-routing.module';
import { ShowFormComponent } from "src/app/admin/help/show-form.component";

@NgModule({
    declarations: [HelpComponent, ShowFormComponent],
    exports: [HelpComponent, ShowFormComponent],
    imports: [CommonModule, HelpRoutingModule, SharedModule, RouterModule],
    providers: [HelpService]
})
export class HelpPageModule {}
