import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ToasterService } from 'src/app/core/services';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { GridDataResult } from 'src/app/shared/grid/models/grid-data-result.model';
import { CandidateFile } from 'src/app/shared/models/candidate';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { FileService } from 'src/app/vendor/vendor-candidate-details/services/file.service';
import * as actions from 'src/app/vendor/vendor-candidate-details/store/actions/vendor-candidate-details.actions';
import * as selectors from 'src/app/vendor/vendor-candidate-details/store/selectors/vendor-candidate-details.selectors';

@Component({
    selector: 'ayac-files-tab',
    templateUrl: './files-tab.component.html',
    styleUrls: ['./files-tab.component.scss']
})
export class FilesTabComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @Input() candidateId?: number;
    request?: GridSearchQuery = undefined;
    gridData?: GridDataResult = { data: [], total: 0 };
    isLoading$?: Observable<boolean>;
    isExporting = false;
    timedOutCloser?: NodeJS.Timeout;
    isUploading = false;
    deletingFileIds: number[];
    isDeleting$: Observable<boolean>;
    totalFileSize$: Observable<number>;

    constructor(
        private readonly _store: Store,
        private readonly _fileService: FileService,
        private readonly _toasterService: ToasterService,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    get isNew(): boolean {
        return !this.candidateId || this.candidateId <= 0;
    }

    ngOnInit(): void {
        this._store
            .select(selectors.selectVendorCandidateFileListRequest)
            .pipe(first())
            .subscribe((request) => {
                this.request = request;
                this.refresh();
            });

        this._store
            .select(selectors.selectVendorCandidateFileListResponse)
            .pipe(takeUntil(this.d$))
            .subscribe((response) => {
                this.gridData = response;
            });

        this._store
            .select(selectors.selectVendorCandidateDeletingFileIds)
            .pipe(takeUntil(this.d$))
            .subscribe((response) => {
                this.deletingFileIds = response;
            });

        this._store
            .select(selectors.selectVendorCandidateFileDeleteSuccess)
            .pipe(takeUntil(this.d$))
            .subscribe((response) => {
                if (!response) {
                    return;
                }

                this.refresh();
            });

        this.isLoading$ = this._store.select(selectors.selectVendorCandidateFileListIsLoading);

        this.isDeleting$ = this._store.select(selectors.selectVendorCandidateFileIsDeleting);

        this.totalFileSize$ = this._store.select(selectors.selectFileSize);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.candidateId.currentValue) {
            this.refresh();
        }
    }

    refresh(): void {
        if (!this.candidateId || this.isNew) {
            return;
        }

        this._store.dispatch(
            actions.loadVendorCandidateFileList({
                request: this.request,
                candidateId: this.candidateId
            })
        );
    }

    onDataStateChange(state: GridStateChangeEvent): void {
        this.request = state;
        this.refresh();
    }

    onDownloadFile(candidateFile: CandidateFile): void {
        this._fileService.downloadFile(candidateFile);
    }

    onDeleteFile(candidateFile: CandidateFile): void {
        this._store.dispatch(
            actions.deleteVendorCandidateFiles({
                fileIds: [candidateFile.fileId],
                candidateId: this.candidateId
            })
        );
    }

    onFileSelected(event: any, typeId: number, type: string): void {
        const files: File[] = Array.from(event.target.files);

        const newFiles = files
            .map((fileToUpload) => {
                if (fileToUpload.size / 1024 / 1024 > 20) {
                    this._toasterService.fail(`File '${fileToUpload.name}' exceeds the maximum file size.`);
                    return null;
                }
                const fileParts = fileToUpload.name.split('.');

                const file = {
                    name: fileParts[0],
                    type: fileParts[1],
                    size: fileToUpload.size
                };
                const candidateFileType = { type };
                return {
                    vendorCandidateFileType: candidateFileType,
                    fileTypeId: typeId,
                    file,
                    fileToUpload
                };
            })
            .filter((file) => file !== null && file !== undefined);

        if (newFiles.length === 0) {
            return;
        }

        this.isUploading = true;
        this._fileService
            .addFiles(this.candidateId, newFiles)
            .pipe(takeUntil(this.d$))
            .subscribe(
                () => {
                    this.isUploading = false;
                    this.refresh();
                },
                () => {
                    this.isUploading = false;
                }
            );
    }
}
