import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'src/app/shared/shared.module';
import { OpenQualifiedShiftListComponent } from './components/open-qualified-shift-list/open-qualified-shift-list.component';
import { UpcomingShiftsListComponent } from './components/upcoming-shifts-list/upcoming-shifts-list.component';
import { ShiftsTabComponent } from './components/shifts-tab/shifts-tab.component';
import { ContractRequirementsTabComponent } from './components/contract-requirements-tab/contract-requirements-tab.component';
import { FilesTabComponent } from './components/files-tab/files-tab.component';
import { ProfileTabComponent } from './components/profile-tab/profile-tab.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DAYJS_DATE_FORMATS } from 'src/app/shared/adapters/dayjs-date-adapter';
import { vendorCandidateDetailsStoreKey } from './store/state/vendor-candidate-details.state';
import { VendorCandidateDetailsEffects } from './store/effects/vendor-candidate-details.effects';
import { vendorCandidateDetailsReducers } from './store/reducers/vendor-candidate-details.reducers';
import { VendorCandidateDetailsComponent } from './components/vendor-candidate-details/vendor-candidate-details.component';
import { VendorCandidateDetailsRoutingModule } from './vendor-candidate-details-routing.module';
import { VendorCandidateProfileEffect } from 'src/app/vendor/vendor-candidate-details/store/effects/vendor-candidate-profile.effect';
import { CandidateShiftsAppComponent } from 'src/app/vendor/vendor-candidate-details/components/candidate-shifts-app/candidate-shifts-app.component';
import { DocumentUploadComponent } from 'src/app/vendor/vendor-candidate-details/components/document-upload/document-upload.component';
import { DocumentAttributesFormComponent } from './components/document-attributes-form/document-attributes-form.component';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { VendorCandidateRequirementDocumentUploadEffects } from './store/effects/vendor-candidate-requirement-document-upload.effects';
import { DocumentUploadInfoComponent } from './components/document-upload-info/document-upload-info.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxExtendedPdfViewerModule,
        VendorCandidateDetailsRoutingModule,
        StoreModule.forFeature(vendorCandidateDetailsStoreKey, vendorCandidateDetailsReducers),
        EffectsModule.forFeature([
            VendorCandidateDetailsEffects,
            VendorCandidateProfileEffect,
            VendorCandidateRequirementDocumentUploadEffects
        ])
    ],
    declarations: [
        ShiftsTabComponent,
        OpenQualifiedShiftListComponent,
        UpcomingShiftsListComponent,
        ContractRequirementsTabComponent,
        FilesTabComponent,
        ProfileTabComponent,
        VendorCandidateDetailsComponent,
        CandidateShiftsAppComponent,
        DocumentUploadComponent,
        DocumentAttributesFormComponent,
        DocumentPreviewComponent,
        DocumentUploadInfoComponent
    ],
    exports: [
        ShiftsTabComponent,
        OpenQualifiedShiftListComponent,
        UpcomingShiftsListComponent,
        ContractRequirementsTabComponent,
        FilesTabComponent,
        ProfileTabComponent,
        VendorCandidateDetailsComponent,
        CandidateShiftsAppComponent,
        DocumentUploadComponent,
        DocumentAttributesFormComponent,
        DocumentPreviewComponent,
        DocumentUploadInfoComponent
    ],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS }]
})
export class VendorCandidateDetailsModule {}
