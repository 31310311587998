import { createAction, props } from '@ngrx/store';
import { AyaLeadsApplicationStatus } from 'src/app/aya-leads/enums/aya-leads-application-status.enum';
import { AyaLeadsStatisticsType } from 'src/app/aya-leads/enums/aya-leads-statistics-type.enum';

export const rejectAyaLeadApplicant = createAction(
    '[Aya Leads Page] Reject Aya Lead Applicant',
    props<{ ayaLeadsApplicationId: number }>()
);

export const updateAyaLeadApplicationReviewStatus = createAction(
    '[Aya Leads Page] Update Review Status for Aya Lead Application',
    props<{ id: number; newStatus: AyaLeadsApplicationStatus }>()
);

export const updateAyaLeadApplicationReviewStatusFromButton = createAction(
    '[Aya Leads Page - Update Status Button] Update Review Status for Aya Lead Application',
    props<{ id: number; newStatus: AyaLeadsApplicationStatus }>()
);

export const loadAyaLeadApplicationInfo = createAction(
    '[Aya Leads Page] Get Aya Lead Application Info',
    props<{ id: number }>()
);

export const loadAyaLeadResumeInfo = createAction(
    '[Aya Leads Page] Get Aya Lead Resume Info',
    props<{ candidateId: number; facilityId: number }>()
);

export const downloadAyaLeadResume = createAction(
    '[Aya Leads Page] Download Aya Lead Resume',
    props<{ candidateId: number; facilityId: number }>()
);

export const loadAyaLeadResumePreview = createAction(
    '[Aya Leads Page] Load Aya Lead Resume Preview',
    props<{ candidateId: number; facilityId: number }>()
);

export const clearAyaLeadResumePreview = createAction('[Aya Leads Page] Clear Aya Lead Resume Preview');
export const clearAyaLeadResumePreviewSuccess = createAction('[Aya Leads Page] Clear Aya Lead Resume Preview Success');
export const clearAyaLeadResumePreviewFail = createAction('[Aya Leads Page] Clear Aya Lead Resume Preview Fail');

export const clearAyaLeadApplicationDetails = createAction('[Aya Leads Page] Clear Application Details from Store');

export const dashboardShowAyaLeadsFilteredByStatus = createAction(
    '[Aya Leads Page] Dashboard Show Aya Leads Filtered By Application Status',
    props<{ applicationStatuses: AyaLeadsApplicationStatus[] }>()
);

export const recordAyaLeadsStatistics = createAction(
    '[Aya Leads Page] Record Aya Leads Statistics',
    props<{ ayaLeadsApplicationId: number; statisticType: AyaLeadsStatisticsType }>()
);

export const loadAyaLeadApplicationStatuses = createAction('[Aya Leads Page] Load Aya Lead Application Statuses');

export const loadAyaLeadApplicationStatusesFromStoreSuccess = createAction('[Aya Leads Page] Load Aya Lead Application Statuses Success');
