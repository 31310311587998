import { Pipe, PipeTransform } from '@angular/core';
import { VendorContact } from '../models';
import { isEmptyOrSpaces } from '../utilities/is-empty';

@Pipe({
    name: 'vendorContactName'
})
export class VendorContactNamePipe implements PipeTransform {
    transform(contact: VendorContact): string {
        const nameParts: string[] = [contact?.firstName, contact?.lastName].filter((x) => x && !isEmptyOrSpaces(x));
        return nameParts.join(' ');
    }
}
