export enum GridQuickFilterEnum {
    SWITCH = 'switch',
    DATE = 'date',
    TIME = 'time',
    DROPDOWN = 'dropdown',
    COMBOBOX = 'combobox',
    TEXT = 'text',
    MULTISELECT = 'multiselect',
    GROUPMULTISELECT = 'groupmultiselect'
}
