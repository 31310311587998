import { PendingJobsState } from './reducers/pending-jobs.reducer';
import { FilterState } from 'src/app/dashboard-new/store/reducers/filter.reducer';
import { WocState } from './reducers/woc.reducer';
import { AwaitingClearanceState } from './reducers/awaiting-clearance.reducer';
import { WorkersState } from './reducers/workers.reducer';
import { PendingTimecardsState } from './reducers/pending-timecards.reducer';
import { SecurityLookupsState } from './reducers/security-lookups.reducer';
import { JobsState } from './reducers/jobs.reducer';
import { ShiftsState } from './reducers/shifts.reducer';
import { PendingSubmittalsState } from './reducers/pending-submittals.reducer';
import { DraftInvoicesState } from './reducers/draft-invoices.reducer';
import { AyaLeadsDashboardState } from 'src/app/dashboard-new/store/reducers/aya-leads.reducer';
import { PendingExpensesState } from './reducers/pending-expenses.reducer';

export const dashboardStoreKey = 'dashboard';

export interface DashboardState {
    filter: FilterState;
    woc: WocState;
    awaitingClearance: AwaitingClearanceState;
    workers: WorkersState;
    pendingJobs: PendingJobsState;
    pendingTimecards: PendingTimecardsState;
    pendingExpenses: PendingExpensesState;
    securityLookups: SecurityLookupsState;
    jobs: JobsState;
    shifts: ShiftsState;
    pendingSubmittals: PendingSubmittalsState;
    draftInvoices: DraftInvoicesState;
    ayaLeadsCount: AyaLeadsDashboardState;
}
