import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DashboardService } from 'src/app/dashboard-new/services/dashboard.service';
import { withLatestFrom, catchError, map, switchMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { getFilter } from 'src/app/dashboard-new/store/selectors/filter.selectors';
import {
    loadDashboardDraftInvoicesCount,
    loadDashboardDraftInvoicesCountFail,
    loadDashboardDraftInvoicesCountSuccess
} from 'src/app/invoices/store';

@Injectable()
export class DraftInvoicesEffect {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<{}>,
        private readonly service: DashboardService
    ) {}

    loadDraftInvoicesCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDashboardDraftInvoicesCount),
            withLatestFrom(this.store$.select(getFilter)),
            switchMap(([action, query]) => {
                const filter = {
                    facilityId: query.facility.id > 0 ? query.facility.id : null,
                    unitId: query.unit.id > 0 ? query.unit.id : null
                };

                return this.service.getDraftInvoicesCount(filter).pipe(
                    map((response) =>
                        loadDashboardDraftInvoicesCountSuccess({
                            totalCount: response?.totalCount
                        })
                    ),
                    catchError((error) => of(loadDashboardDraftInvoicesCountFail({ error })))
                );
            })
        )
    );
}
