import { Action, createReducer, on } from '@ngrx/store';
import { facilitiesActions } from 'src/app/facilities/store/actions';
import {
    FacilityAssociationsListState,
    getInitialFacilityAssociationsListState
} from 'src/app/facilities/store/state/facility-associations-list.state';
import { LoadingTypes } from 'src/app/shared/models';

const associationsListReducer = createReducer(
    getInitialFacilityAssociationsListState(),

    on(facilitiesActions.facilityAssociationActions.loadFacilityAssociationsListSuccess, (state, action) => ({
        ...state,
        associationsList: action.associations,
        error: '',
        loadingState: LoadingTypes.LOADED
    })),

    on(facilitiesActions.facilityAssociationActions.loadFacilityAssociationsListFailure, (state, action) => ({
        ...state,
        associationsList: { data: [], total: 0, filtered: 0 },
        error: action.error,
        loadingState: LoadingTypes.FAIL
    })),

    on(facilitiesActions.facilityAssociationActions.setFacilityAssociationsListSearchQuery, (state, action) => ({
        ...state,
        gridSearchQuery: action.searchQuery,
        loadingState: LoadingTypes.LOADING
    })),

    on(
        facilitiesActions.facilityAssociationActions.setFacilityAssociationsListSubcontractorsQuery,
        (state, action) => ({
            ...state,
            subContractorsOnly: action.subContractorsOnly,
            loadingState: LoadingTypes.LOADING
        })
    )
);

export function facilityAssociationsListReducer(state: FacilityAssociationsListState | undefined, action: Action) {
    return associationsListReducer(state, action);
}
