import { createAction, props } from '@ngrx/store';

export const resendInvitationEmail = createAction(
    '[Internal Pool Resource Details] Resend Invitation Email',
    props<{ id: number }>()
);

export const resendInvitationEmailSuccess = createAction(
    '[Internal Pool Resource Details] Resend Invitation Email Success'
);

export const resendInvitationEmailFail = createAction(
    '[Internal Pool Resource Details] Resend Invitation Email Fail',
    props<{ error: any }>()
);
