import { Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ClinicalClient } from 'src/app/clinical/models/client.model';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';

@Component({
    selector: 'ayac-clinical-client-profile',
    templateUrl: './client-profile.component.html',
    styleUrls: ['./client-profile.component.scss']
})
export class ClinicalClientProfileComponent extends UnsubscribeOnDestroy {
    readonly formatPhoneNumber$ = this.ldFeatureManager
        .isEnabled(FeatureFlag.FormatFacilityPhoneNumber)
        .pipe(takeUntil(this.d$));

    loaded = false;
    private _client: ClinicalClient;
    constructor(private readonly ldFeatureManager: LDFeatureManager) {
        super();
    }

    get client(): ClinicalClient {
        return this._client;
    }

    @Input() set client(value: ClinicalClient) {
        this._client = value;
        if (value && !this.loaded) {
            this.loaded = true;
        }
    }
}
