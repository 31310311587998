import { SubmittalJob } from 'src/app/shared/models/submittals/submittal-job.model';
import { LoadingTypes } from 'src/app/shared/models';
import * as sumittalActions from 'src/app/submittals/store/actions';
import { createReducer, on, Action } from '@ngrx/store';
import { CandidateJobSubmittalProfile } from '../../models';

export interface SubmittalDetailsInternalState {
	profile: CandidateJobSubmittalProfile;
	jobs: SubmittalJob[];
	detailsLoading: LoadingTypes,
	jobsLoading: LoadingTypes,
	statusLoading: LoadingTypes,
    bookLoading: LoadingTypes
}

const initialState: SubmittalDetailsInternalState = {
	profile: null,
	jobs: [],
	detailsLoading: LoadingTypes.INIT,
	jobsLoading: LoadingTypes.INIT,
	statusLoading: LoadingTypes.INIT,
    bookLoading: LoadingTypes.INIT
}

export function submittalDetailsInternalReducer(state: SubmittalDetailsInternalState, action: Action) {
	return createReducer(
		initialState,
		on(sumittalActions.loadIRPSubmittalDetails, (state, action) => (
			{
				...state,
				detailsLoading: LoadingTypes.LOADING
			}
		)),

		on(sumittalActions.loadIRPSubmittalDetailsSuccess, (state, action) => (
			{
				...state,
				profile: action.profile,
				detailsLoading: LoadingTypes.LOADED
			}
		)),

		on(sumittalActions.loadIRPSubmittalDetailsFail,
			(state, action) => (
				{
					...state,
					profile: null,
					detailsLoading: LoadingTypes.FAIL
				}
			)),

		on(sumittalActions.loadIRPSubmittalJobs, (state, action) => (
			{
				...state,
				jobsLoading: LoadingTypes.LOADING
			}
		)),

		on(sumittalActions.loadIRPSubmittalJobsSuccess, (state, action) => (
			{
				...state,
				jobs: action.jobs,
				jobsLoading: LoadingTypes.LOADED
			}
		)),

		on(sumittalActions.loadIRPSubmittalJobsFail, (state, action) => (
			{
				...state,
				jobs: [],
				jobsLoading: LoadingTypes.FAIL
			}
		)),

		on(sumittalActions.updateIRPSubmittalStatus, (state, action) => (
			{
				...state,
				statusLoading: LoadingTypes.LOADING
			}
		)),

		on(sumittalActions.updateIRPSubmittalStatusSuccess, (state, action) => (
			{
				...state,
				statusLoading: LoadingTypes.LOADED,
				profile: action.profile
			}
		)),

		on(sumittalActions.updateIRPSubmittalStatusFail, (state, action) => (
			{
				...state,
				statusLoading: LoadingTypes.LOADED
			}
		)),

        on(sumittalActions.bookCandidate, (state) => (
            {
                ...state,
                bookLoading: LoadingTypes.LOADING
            }
        )),
        on(sumittalActions.bookCandidateSuccess, (state) => (
            {
                ...state,
                bookLoading: LoadingTypes.LOADED
            }
        )),
        on(sumittalActions.bookCandidateFail, (state) => (
            {
                ...state,
                bookLoading: LoadingTypes.LOADED
            }
        )),

		on(sumittalActions.clearIRPSubmittals, (state, action) => (
			{
				...initialState
			}
		)),
	)(state, action);
}

export function reducer(state: SubmittalDetailsInternalState | undefined, action: Action) {
	return submittalDetailsInternalReducer(state, action);
}
