/**angular */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

/**rxjs */
import { Observable } from 'rxjs';

/**shared */
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { combine } from 'src/app/shared/utilities';

/**local */
import { SystemContactSave } from 'src/app/admin/system-contacts/models/system-contact-save.model';
import { SystemContactType } from 'src/app/admin/system-contacts/models/system-contact-type.model';
import { SystemContact } from 'src/app/admin/system-contacts/models/system-contact.model';
import { OrganizationInvitation } from 'src/app/shared/models/account/organization-invitation.model';
import { IdentityProvider } from 'src/app/shared/models/sso/identity-provider.model';

@Injectable({
    providedIn: 'root'
})
export class SystemContactsService {
    private readonly apiPath: string;
    private readonly ssoRootPath: string;
    private readonly authUserPath: string;

    constructor(private readonly _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _settings: Settings) {
        this.apiPath = combine(_settings.CORE, 'ayaconnect', 'admin', 'system-contacts');
        this.ssoRootPath = combine(_settings.CORE, 'sso');
        this.authUserPath = combine(_settings.CORE, 'auth-user');
    }

    getSystemContactTypes(): Observable<SystemContactType[]> {
        return this._httpClient.get<SystemContactType[]>(`${this.apiPath}/types`);
    }
    getSystemContact(systemContactId: number): Observable<SystemContact> {
        return this._httpClient.get<SystemContact>(`${this.apiPath}/${systemContactId}`);
    }

    updateSystemContact(systemContact: SystemContactSave): Observable<number> {
        return this._httpClient.put<number>(`${this.apiPath}`, systemContact);
    }

    saveSystemContact(contact: SystemContactSave): Observable<number | null> {
        let response;
        const url = `${this.apiPath}`;

        if (contact.id && contact.id > 0) {
            response = this._httpClient.put<number | null>(url, contact);
        } else {
            response = this._httpClient.post<number | null>(url, contact);
        }

        return response;
    }

    getIdentityProviders(): Observable<IdentityProvider[]> {
        return this._httpClient.get<IdentityProvider[]>(`${this.ssoRootPath}/identity-providers`);
    }

    getInvitation(userId: string, identityProvider: string): Observable<OrganizationInvitation> {
        const url = `${this.authUserPath}/invitation/user/${userId}?identityProvider=${identityProvider}`;
        const response = this._httpClient.get<OrganizationInvitation>(url);
        return response;
    }

    sendInvitation(userId: string, identityProvider: string): Observable<any> {
        const data = {
            userId: userId,
            identityProvider: identityProvider
        };
        return this._httpClient.post(`${this.authUserPath}/invitation`, data);
    }
}
