import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { ColorShade, SystemColorTheme } from 'src/app/facilities/models';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { catchError, map, Observable, of, take } from 'rxjs';
import { IdentityService } from 'src/app/shared/services/identity.service';

@Injectable({
    providedIn: 'root'
})
export class ColorThemeService {
    constructor(
        private readonly _http: HttpClient,
        @Inject(APP_CONFIG) private readonly _settings: Settings,
        @Inject(DOCUMENT) private readonly _document: Document,
        private readonly _identityService: IdentityService
    ) {}

    loadColorTheme(isSignedIn: boolean = false): Observable<boolean> {
        if (isSignedIn || this._identityService.isSignedIn()) {
            return this._http.get<SystemColorTheme>(`${this._settings.CORE}/AyaConnect/Client/ColorTheme`).pipe(
                map((colorTheme: SystemColorTheme | null) => {
                    if (colorTheme) {
                        this.setColorTheme(colorTheme);
                    }

                    return true;
                }),
                catchError(() => of(false)),
                take(1)
            );
        }

        return of(false);
    }

    private setCssVars(theme: ColorShade, document: CSSStyleDeclaration, type: string): void {
        document.setProperty(`--${type}-color`, theme.color);
        document.setProperty(`--${type}-lighter-color`, theme.lighter);
        document.setProperty(`--${type}-darker-color`, theme.darker);
        document.setProperty(`--${type}-text`, theme.text);
        document.setProperty(`--${type}-lighter-text`, theme.textLighter);
        document.setProperty(`--${type}-darker-text`, theme.textDarker);

        if (theme.textActive) {
            document.setProperty(`--${type}-text-active`, theme.textActive);
        }
    }

    private setColorTheme(colorTheme: SystemColorTheme): void {
        try {
            const document = this._document.documentElement.style;

            if (colorTheme.primary) {
                this.setCssVars(colorTheme.primary, document, 'primary');
            }
            if (colorTheme.accent) {
                this.setCssVars(colorTheme.accent, document, 'accent');
            }
            if (colorTheme.highlight) {
                this.setCssVars(colorTheme.highlight, document, 'highlight');
            }
            if (colorTheme.sidebar) {
                this.setCssVars(colorTheme.sidebar, document, 'sidebar');
                this.setCssVars(colorTheme.sidebar, document, 'vendor-sidebar');
            }
            if (colorTheme.cardBackground) {
                document.setProperty(`--card-background`, colorTheme.cardBackground);
            }
            if (colorTheme.cardChevron) {
                document.setProperty(`--card-chevron`, colorTheme.cardChevron);
            }
            if (colorTheme.activeMenuItem) {
                document.setProperty(`--active-menu-item`, colorTheme.activeMenuItem);
            }
        } catch (error: unknown) {
            throw new Error('Unable to set color theme.');
        }
    }
}
