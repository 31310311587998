import { createAction, props } from '@ngrx/store';
import { FacilitySystem } from 'src/app/facilities/models/facility-system.model';
import { SystemColorTheme } from '../../models/system-color-theme.model';

export const profileLoaded = createAction(
    '[FACILITY SYSTEM DETAILS COMPONENT] Profile Loaded',
    props<{ systemId: number }>()
);

export const loadLogo = createAction(
    '[FACILITY SYSTEMS PROFILE COMPONENT] Load Facility System Profile Logo',
    props<{ systemId: number }>()
);

export const loadLogoSuccess = createAction(
    '[FACILITY SYSTEMS PROFILE API] Load Facility System Profile Logo Success',
    props<{ logo: Blob; systemId: number }>()
);

export const loadLogoFailure = createAction(
    '[FACILITY SYSTEMS PROFILE API] Load Facility System Profile Logo Failure',
    props<{ error: unknown }>()
);

export const addLogo = createAction(
    '[FACILITY SYSTEMS PROFILE COMPONENT] Add Facility System Profile Logo',
    props<{ logo: Blob; systemId: number }>()
);

export const addLogoSuccess = createAction(
    '[FACILITY SYSTEMS PROFILE API] Add Facility System Profile Logo Successfully',
    props<{ systemId: number }>()
);
export const addLogoFailure = createAction(
    '[FACILITY SYSTEMS PROFILE API] Add Facility System Profile Logo Failure',
    props<{ error: unknown }>()
);

export const deleteLogo = createAction(
    '[FACILITY SYSTEMS PROFILE COMPONENT] Delete Facility System Profile Logo',
    props<{ systemId: number }>()
);

export const deleteLogoSuccess = createAction(
    '[FACILITY SYSTEMS PROFILE API] Delete Facility System Profile Logo Successfully',
    props<{ systemId: number }>()
);
export const deleteLogoFailure = createAction(
    '[FACILITY SYSTEMS PROFILE API] Delete Facility System Profile Logo Failure',
    props<{ error: unknown }>()
);

export const loadFacilitySystemDetailById = createAction(
    '[FACILITIES SYSTEM DETAIL COMPONENT] Load System Detail By Id',
    props<{ systemId: number }>()
);

export const loadFacilitySystemDetailByIdSuccess = createAction(
    '[FACILITY SYSTEMS API] Load System Detail By Id Success',
    props<{ system: FacilitySystem }>()
);

export const loadFacilitySystemDetailByIdFailure = createAction(
    '[FACILITY SYSTEMS API] Load System Detail By Id Failure',
    props<{ error: unknown }>()
);

export const loadColorTheme = createAction(
    '[FACILITY SYSTEMS PROFILE COMPONENT] Load Facility System Profile Color Theme',
    props<{ systemId: number }>()
);
export const loadColorThemeSuccess = createAction(
    '[FACILITY SYSTEMS PROFILE API] Load Facility System Profile Color Theme Success',
    props<{ colorTheme: SystemColorTheme; systemId: number }>()
);
export const loadColorThemeFailure = createAction(
    '[FACILITY SYSTEMS PROFILE API] Load Facility System Profile Color Theme Failure',
    props<{ error: unknown }>()
);

export const saveColorTheme = createAction(
    '[FACILITY SYSTEMS PROFILE COMPONENT] Save Facility System Profile Color Theme',
    props<{ systemId: number; colorTheme: SystemColorTheme }>()
);
export const saveColorThemeSuccess = createAction(
    '[FACILITY SYSTEMS PROFILE COMPONENT] Save Facility System Profile Color Theme Success'
);
export const saveColorThemeFailure = createAction(
    '[FACILITY SYSTEMS PROFILE COMPONENT] Save Facility System Profile Color Theme Failure',
    props<{ error: unknown }>()
);

export const deleteColorTheme = createAction(
    '[FACILITY SYSTEMS PROFILE API] Delete Facility System Profile Color Theme Success',
    props<{ systemId: number }>()
);
export const deleteColorThemeSuccess = createAction(
    '[FACILITY SYSTEMS PROFILE COMPONENT] Delete Facility System Profile Color Theme Success'
);
export const deleteColorThemeFailure = createAction(
    '[FACILITY SYSTEMS PROFILE COMPONENT] Delete Facility System Profile Color Theme Failure',
    props<{ error: unknown }>()
);
