import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { default as jspdf } from 'jspdf';
import { BehaviorSubject, fromEvent, timer } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { UnitOtherRequirement } from 'src/app/clinical/models/unit-other-requirement.model';
import { UnitStateControlledSubstances } from 'src/app/clinical/models/unit-state-controlled-substances';
import { Unit } from 'src/app/clinical/models/unit.model';
import * as actions from 'src/app/clinical/store/actions/client-units.actions';
import { selectIsLoading, selectUnitInfo } from 'src/app/clinical/store/selectors/client-units.selectors';
import { MainContentOnly } from 'src/app/core/utils/main-content-only';
import { LDFeatureManager as LdFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IdentityService } from 'src/app/shared/services/identity.service';
import { ClinicalService } from 'src/app/clinical/services/clinical.service';
import { Certifications } from '../../models/certifications.model';
import { DialogProgramTypesData } from '../../models/dialog-program-types-data.model';
import { SaveUnitEnum } from '../../models/save-unit.enum';
import { Skills } from '../../models/skills.model';
import { ClinicalTrackRequirements } from 'src/app/clinical/models';
import { UnitBoardCertification } from '../../models/unit-board-certification.model';
import { UnitFacilityInformation } from '../../models/unit-facility-information.model';
import { UnitStateLicense } from '../../models/unit-state-license.model';
import { UnitStatusEnum } from '../../models/unit-status-enum';
import { VisibleUnitCertificationsSkills } from '../../models/visible-certifications-skills.model';
import { VisibleSkill } from '../../models/visible-skill.model';
import { UnitDescriptionLoadingDialogComponent } from './modals/loading/loading.component';
import { VerifyComponent } from './modals/verify.component';
import { CertificationsSkillsComponent } from './sections/certifications-and-skills/certifications-skills.component';
import { FacilityContactsComponent } from './sections/facility-contacts/facility-contacts.component';
import { FacilityHeaderComponent } from './sections/facility-header/facility-header.component';
import { FacilityInformationComponent } from './sections/facility-information/facility-information.component';
import { FacilityPatientAgeGroupComponent } from './sections/facility-patient-age-group-section/facility-patient-age-group.component';
import { OtherInformationComponent } from './sections/other-information/other-information.component';
import { ProviderCoverageComponent } from './sections/provider-coverage/provider-coverage.component';
import { ProviderSupportComponent } from './sections/provider-support/provider-support.component';
import { ShiftDetailsComponent } from './sections/shift-details/shift-details.component';
import { SignatureComponent } from './sections/signature/signature.component';
import { SpecialComponent } from './sections/unit-info/special.component';
import { UnitSystemsComponent } from './sections/unit-systems/unit-systems.component';
import { WorkExperienceComponent } from './sections/work-experience/work-experience.component';
import { BoardCertificationsComponent } from './sections/board-certifications/board-certifications.component';
import { QuesoFormaggioFeatureFlags } from 'src/app/shared/models/enums/queso-formaggio-feature-flags.enum';
import { DomainService } from 'src/app/shared/services/domain.service';
import { Facility } from '../../models/job-unit-model';

dayjs.extend(utc);

const html2canvas = require('../../../../../node_modules/html2canvas/dist/html2canvas.min.js');
const ALL_FACILITIES = 'AllFacilities';
export const DAY_FIRST_FORMAT = {
    parse: {
        dateInput: 'DD-MMMM-YYYY'
    },
    display: {
        dateInput: 'DD-MMMM-YYYY',
        monthYearLabel: 'MMM YYYY'
    }
};

export const hospitalTypes = {
    WorkforceDisruptionClinical: 7,
    WorkforceDisruptionNonClinical: 8
};

export enum nyuLookupTypes {
    building = 0,
    department = 1,
    floor = 2
}

@Component({
    selector: 'ayac-unit-description',
    templateUrl: './unit-description.component.html',
    styleUrls: ['./unit-description.component.scss'],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: DAY_FIRST_FORMAT }],
    encapsulation: ViewEncapsulation.None
})
export class UnitDescriptionComponent extends MainContentOnly implements OnInit, OnDestroy {
    private readonly locumsProfessionTypeId = 5;
    @Input() canShowContact = false;

    @Input() readOnly = true;

    @Input()
    set hospId(hospId: string) {
        this._hospId = hospId;
    }

    @Input()
    set unitId(unitId: string) {
        this._unitId = unitId;
    }

    @Output() valueChanged = new EventEmitter<{ unit: any; valid: any }>();

    @ViewChild('facilityheader') facilityheaderCtrl: FacilityHeaderComponent;
    @ViewChild('certificationSkills') certificationSkillsCtrl: CertificationsSkillsComponent;
    @ViewChild('facilityInformation') facilityInformationCtrl: FacilityInformationComponent;
    @ViewChild('otherInformation') otherInformationComponent: OtherInformationComponent;
    @ViewChild('facilityPatientAgeGroup') facilityPatientAgeGroupCtrl: FacilityPatientAgeGroupComponent;
    @ViewChild('providerCoverage') providerCoverageCtrl: ProviderCoverageComponent;
    @ViewChild('providerSupport') providerSupportCtrl: ProviderSupportComponent;
    @ViewChild('shiftDetails') shiftDetailsCtrl: ShiftDetailsComponent;

    @ViewChild('special') specialComponent: SpecialComponent;
    @ViewChild('unitSystems') unitSystemsCtrl: UnitSystemsComponent;
    @ViewChild('workExperience') workExperienceCtrl: WorkExperienceComponent;
    @ViewChild('facilityContacts', { static: false }) facilityContactsCtrl: FacilityContactsComponent;

    @ViewChild('signatureCtrl', { static: false }) set content(content: SignatureComponent) {
        if (content) {
            // initially setter gets called with undefined
            this.signatureCtrl = content;
        }
    }
    @ViewChild('boardCertifications') boardCertificationsCntrl: BoardCertificationsComponent;

    isPrinting: boolean = false;
    _unitId: string;
    _hospId: string;
    nyuSystemId = 913;
    otherSpecialtyId = 1246;
    showUnitDetails = false;
    dateFormat = 'YYYY-MM-DD';
    certificationsSkillsFormValid = true;
    userId: any;
    token: string;
    formId: any;
    contact: any;
    preview: any;
    accept: any;
    edit: any;
    loadingDialog: any;
    ProviderSupportComponent;
    programTypeRequired: boolean;
    environment: string;
    hostName: any;
    unitFormSubmitted: boolean;
    searchParams = {
        hospId: '',
        userId: '',
        formId: '',
        contact: false,
        preview: false,
        accept: false,
        programTypeDisabled: false,
        edit: true,
        submit: false,
        am: false,
        name: '',
        types: []
    };
    editMode: any;

    unitDescriptionForm: UntypedFormGroup;
    isMultipleDiscipline = false;
    facilityInformationUnit: UnitFacilityInformation;
    unit: Unit;
    lookups = {
        types: [],
        nyuDepartments: [],
        nyuBuildings: [],
        nyuFloors: [],
        professions: [],
        specialties: [],
        professionSpecialties: [],
        visibleSkills: {} as VisibleSkill,
        visibleCertifications: [],
        professionSpecialtyAssessments: [],
        fallBackCertifications: [],
        unitSpecialtyCertifications: [],
        unitSpecialtySkills: [],
        shiftDetails: [],
        providerSupports: [],
        providerCoverage: [],
        patientAgeGroups: [],
        certifications: [] as Certifications[],
        skills: [] as Skills[],
        programTypes: [],
        chartingSystems: []
    };
    currentProfessionId = -1;
    unitSpecialties = [];
    isLoading = false;
    saveAndSendClicked = false;
    extraBuildingId: number;
    extraDepartmentId: number;
    extraFloorID: number;
    visibleUnitCertificationsSkills: VisibleUnitCertificationsSkills;
    private signatureCtrl: SignatureComponent;
    canSave = false;
    canSubmit = false;
    canAccept = false;
    canEdit = true;
    programTypeDisabled = false;
    signatureRequired = false;
    upgradeBuildingId = -1;
    upgradeDepartmentId = -1;
    upgradeFloorID = -1;
    isUpgradedSectionsValid = true;
    isWorkForceDisruption = false;
    featureFlags = FeatureFlag;
    isLocumsProfession$ = new BehaviorSubject<boolean>(false);
    isPhysicianProfession$ = new BehaviorSubject<boolean>(false);
    isCrnaProfession$ = new BehaviorSubject<boolean>(false);
    showLastEditedFlag = false;
    isAccessSecureUDLinks = false;
    isAccessSecureUDLinksByFacility = false;
    isLoadingVisibleUnitCertificationsSkills = false;
    enableHideTrackFieldForClientViewOfUD = false;
    hideTrackFieldForClientViewOfUD = false;
    physicianProfessionCodes = [3, 59, 72];
    crnaProfessionCode = 73;
    clinicalTrackId: number | null = null;
    isLocumsUnitDescriptionFieldValidationFlag: boolean = false;
    isEnabledHideUnitDescriptionRestrictedFields = false;
    hideWfdRestrictedFields = false;
    isTokenAvailable = false;
    jobUnitFacility: Facility;
    accessSecureUDLinksFacilities = [];
    canShare = false;

    constructor(
        @Inject('Window') protected readonly _window: Window,
        @Inject(DOCUMENT) private readonly _document: Document,
        private readonly _store: Store,
        private readonly _route: ActivatedRoute,
        private readonly _dialogService: DialogService,
        private readonly _dialog: DialogService,
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _ldFeatureManager: LdFeatureManager,
        private readonly _identityService: IdentityService,
        private readonly _clinicalService: ClinicalService,
        private readonly _domainService: DomainService
    ) {
        super(_window);
        this.createForm();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loadFeatureFlags();
        this.subscribeToCrnaProfessionChanges();
        this.updateIsLocumsProfession();
        this.loadingDialog = this._dialog.openDialog(UnitDescriptionLoadingDialogComponent, {
            data: 'Loading required data...',
            width: '60%',
            hasBackdrop: true
        });
        this.hostName = `${this._window.location.protocol}://${this._window.location.host}/#/`;
        this._store
            .select(selectIsLoading)
            .pipe(takeUntil(this.d$))
            .subscribe((value) => {
                this.isLoading = value.isLoading;
            });

        this._route.queryParams.pipe(takeUntil(this.d$)).subscribe((params) => {
            this.userId = params.userId;
            this.formId = params.formId;
            this.token = params.token;
            this.searchParams.hospId = params.hospId;
            this.searchParams.formId = params.formId;
            this.searchParams.contact = !!params.contact;
            this.searchParams.preview = !!params.preview;
            this.searchParams.accept = !!params.accept;
            this.searchParams.programTypeDisabled = !!params.programTypeDisabled;
            this.searchParams.edit = !!params.edit;
            this.searchParams.submit = !!params.submit;
            this.searchParams.am = !!params.am;
            this.searchParams.name = params.name;
            this.searchParams.types = params.types;
            this.searchParams.userId = params.userId;
            if (!this.isAccessSecureUDLinks) {
                this.getUnit();
            }
        });
        /* istanbul ignore next */
        if (this.isAccessSecureUDLinks) {
            fromEvent<MessageEvent>(window, 'message')
                .pipe(
                    filter((event) => {
                        const novaUrl = this._domainService.getUrl('NOVA').replace('//', '');
                        return event.origin.includes(novaUrl);
                    }),
                    filter(() => this.isTokenAvailable === false),
                    tap((event) => {
                        if (event.data.type === 'PING') {
                            event.source.postMessage({ type: 'PONG' }, { targetOrigin: event.origin });
                        }
                    }),
                    filter((event) => event.data.nova_access_token),
                    map((event) => {
                        if (event.data.nova_access_token) {
                            this._window.localStorage.setItem('nova_access_token', event.data.nova_access_token);
                            this.isTokenAvailable = true;
                        }
                    })
                )
                .subscribe(() => {
                    this.getUnit();
                });

            timer(5000)
                .pipe(take(1), takeUntil(this.d$))
                .subscribe(() => {
                    this.getUnit();
                });
        }
    }
    subscribeToCrnaProfessionChanges(): void {
        this.isCrnaProfession$.pipe(takeUntil(this.d$)).subscribe((value) => {
            if (this.unit) {
                this.unit.isNbcrnaCertRequired = value ? this.unit.isNbcrnaCertRequired ?? false : null;
            }
        });
    }
    createForm(): any {}

    getDisciplines(): any {
        if (!this.facilityInformationUnit.unit || !this.facilityInformationUnit.unit.professions) {
            return '';
        }
        return this.facilityInformationUnit.unit.professions.map((t) => t.professionName).join(', ');
    }

    isNyuFacility(): any {
        return (
            this.facilityInformationUnit?.facility &&
            this.facilityInformationUnit?.facility?.hospitalSystemId === this.nyuSystemId
        );
    }

    fillLookups(unitFacilityInformation: UnitFacilityInformation) {
        if (!unitFacilityInformation || !unitFacilityInformation.unit) {
            return;
        }

        this.lookups.types = unitFacilityInformation.types;

        this.lookups.professions = unitFacilityInformation.professions;
        this.currentProfessionId =
            this.unit?.professions && this.unit?.professions?.length > 0 ? this.unit.professions[0].professionCode : -1;

        this.lookups.professionSpecialties = unitFacilityInformation.professionSpecialties;

        this.lookups.shiftDetails = unitFacilityInformation.shiftDetails;

        this.lookups.patientAgeGroups = unitFacilityInformation.patientAgeGroups;

        this.lookups.providerSupports = unitFacilityInformation.providerSupports;

        this.lookups.providerCoverage = unitFacilityInformation.providerCoverage;

        this.lookups.certifications = unitFacilityInformation.certifications;
        this.lookups.fallBackCertifications = unitFacilityInformation.fallBackCertifications;
        this.lookups.unitSpecialtyCertifications = unitFacilityInformation.unitSpecialtyCertifications;
        this.lookups.professionSpecialtyAssessments = unitFacilityInformation.professionSpecialtyAssessments;

        this.lookups.skills = unitFacilityInformation.skills;

        this.lookups.unitSpecialtySkills = unitFacilityInformation.unitSpecialtySkills;

        if (this.unit.jobUnit && this.unit.jobUnit.facility) {
            this.unit.jobUnit.facility.hospState = this.unit.jobUnit?.facility?.hospState?.substring(0, 2);
        }

        this.lookups.programTypes = unitFacilityInformation.programTypes;

        this.lookups.chartingSystems = unitFacilityInformation.chartingSystems;

        this.isWorkForceDisruption = [
            hospitalTypes.WorkforceDisruptionClinical,
            hospitalTypes.WorkforceDisruptionNonClinical
        ].includes(unitFacilityInformation?.unit?.jobUnit?.facility?.hospitalType);
        this.getClinicalTrackRequirements();
        this.updateIsLocumsProfession();
        unitFacilityInformation.isLocumsProfession = this.isLocumsProfession$.value;
    }

    setting() {
        const search = this.searchParams;
        this.unit.patientAgeGroups = this.unit.patientAgeGroups ? this.unit.patientAgeGroups : [];
        this.unit.certifications = this.unit.certifications ? this.unit.certifications : [];
        this.unit.skills = this.unit.skills ? this.unit.skills : [];
        this.unit.providerSupports = this.unit.providerSupports ? this.unit.providerSupports : [];
        this.isMultipleDiscipline = this.unit.professions && this.unit.professions.length > 1;

        this.canSave = this.unit.status === UnitStatusEnum.New && !search.submit;
        this.canSubmit = !!search.submit || this.unit.status === UnitStatusEnum.Incomplete;
        this.canAccept = this.unit.status === UnitStatusEnum.PendingReview && search.accept;
        this.readOnly =
            (!!search.am &&
                [UnitStatusEnum.Incomplete, UnitStatusEnum.PendingReview, UnitStatusEnum.Live].indexOf(
                    this.unit.status
                ) >= 0) ||
            this.unit.status === UnitStatusEnum.Expired;
        this.editMode =
            !!search.edit ||
            (!!search.am && this.unit.status === UnitStatusEnum.Incomplete && this.unit.createdByAccountManager);
        this.canShowContact = this.unit.status === UnitStatusEnum.New || !!search.contact || this.editMode;
        this.programTypeDisabled = this.unit.status !== UnitStatusEnum.New && search.programTypeDisabled;

        this.hideTrackFieldForClientViewOfUD =
            this.enableHideTrackFieldForClientViewOfUD &&
            search.contact &&
            !search.preview &&
            this.unit.status !== UnitStatusEnum.New;

        this.signatureRequired = this.canSubmit || this.canAccept;

        this.buttonSetting(search.preview, search.am);
        this.checkHideWfdRestrictedFields();
    }

    unitPatientAgeGroupsChanged(event) {
        if (!this.unit) {
            return;
        }

        this.unit.patientAgeGroups = event.patientAgeGroups;
        this.unit.staffingRatioDays = event.staffingRatioDays;
        this.unit.staffingRatioNights = event.staffingRatioNights;
        this.unit.staffingRatioWeekends = event.staffingRatioWeekends;
        this.areSectionsValid();
    }

    facilityInformationFormChanged(event) {
        if (event.unit && this.unit) {
            this.unit.jobUnit.clinicalTrackId = event.unit.jobUnit?.clinicalTrackId;
            this.unit.beds = event.unit.beds;
            this.unit.email = event.unit.email;
            this.unit.manager = event.unit.manager;
            this.unit.rooms = event.unit.rooms;
            this.unit.unitLocation = event.unit.unitLocation;
            this.unit.professions = event.unit.professions;
            this.unit.primaryPhone = event.unit.primaryPhone;
            this.unit.secondaryPhone = event.unit.secondaryPhone;
            this.unit.specialties = event.unit.specialties;
            this.unit.averageDailyCensus = event.unit.averageDailyCensus;
            this.unit.visitsDays = event.unit.visitsDays;
            this.unit.buildingId = event.unit.buildingId;
            this.unit.departmentId = event.unit.departmentId;
            this.unit.floorId = event.unit.floorId;
        }

        if (event.unit && event.unit.specialties) {
            this.currentProfessionId = event.unit.professions[0]?.professionCode;
            this.updateIsLocumsProfession();
            this.facilityInformationUnit.isLocumsProfession = this.isLocumsProfession$.value;
            this.getClinicalTrackRequirements();
        }

        this.canShare = this.areSectionsValid();
    }

    getClinicalTrackRequirements(): void {
        if (this.isLoadingVisibleUnitCertificationsSkills) {
            return;
        }
        this.isLoadingVisibleUnitCertificationsSkills = true;
        if (!this.unit?.jobUnit?.clinicalTrackId) {
            this.setVisibleUnitCertificationsSkills();
        } else if (this.unit.jobUnit.clinicalTrackId === this.visibleUnitCertificationsSkills?.clinicalTrackId) {
            this.setVisibleUnitCertificationsSkills(this.visibleUnitCertificationsSkills.clinicalTrackRequirements);
        } else {
            this._clinicalService
                .getClinicalTrackRequirements(this.unit.jobUnit.clinicalTrackId)
                .subscribe((c) => this.setVisibleUnitCertificationsSkills(c));
        }
    }

    setVisibleUnitCertificationsSkills(clinicalTrackRequirements: ClinicalTrackRequirements = null): void {
        this.visibleUnitCertificationsSkills = {
            unitFacilityInformation: this.facilityInformationUnit,
            currentProfessionId: this.currentProfessionId,
            selectedSpecialty: this.unit?.specialties?.length > 0 ? this.unit?.specialties[0].specialtyCode : -1,
            clinicalTrackId: this.unit?.jobUnit?.clinicalTrackId,
            clinicalTrackRequirements: clinicalTrackRequirements
        };
        this.isLoadingVisibleUnitCertificationsSkills = false;
    }

    updateIsLocumsProfession(): void {
        if (this.unit && this.unit.professions) {
            this.isLocumsProfession$.next(
                this.unit.professions.some(
                    (p) =>
                        p.professionCode === this.currentProfessionId &&
                        p.professionTypeId === this.locumsProfessionTypeId
                )
            );

            this.isPhysicianProfession$.next(
                this.unit.professions.some((p) => this.physicianProfessionCodes.find((x) => x === p.professionCode))
            );

            this.isCrnaProfession$.next(
                this.unit.professions.some((p) => this.crnaProfessionCode === p.professionCode)
            );
        }
    }

    facilityHeaderChanged(event) {
        if (this.isAccessSecureUDLinks && this.isAccessSecureUDLinksByFacility) {
            this.canShare = this.areSectionsValid();
        }
        if (!this.unit) {
            return;
        }
        this.unit.jobUnit.types = event.header.unitTypeIds;
        this.unit.jobUnit.name = event.header.unitName;
        this.areSectionsValid();
    }

    unitCertificationsSkillsChanged(event) {
        if (event.unit && this.unit) {
            this.unit.skills = event.unit.skills;
            this.unit.certifications = event.unit.certifications;

            this.unit.skillOther2 = event.unit.skillOther2;
            this.unit.certificationOther2 = event.unit.certificationOther2;
            this.unit.certificationOther1 = event.unit.certificationOther1;
            this.unit.skillOther1 = event.unit.skillOther1;
            this.unit.skillOther2Required = event.unit.skillOther2Required;
            this.unit.skillOther2Preferred = event.unit.skillOther2Preferred;
            this.unit.skillOther1Required = event.unit.skillOther1Required;
            this.unit.skillOther1Preferred = event.unit.skillOther1Preferred;
            this.unit.certificationOther2Required = event.unit.certificationOther2Required;
            this.unit.certificationOther2Preferred = event.unit.certificationOther2Preferred;
            this.unit.certificationOther1Required = event.unit.certificationOther1Required;
            this.unit.certificationOther1Preferred = event.unit.certificationOther1Preferred;
        }

        this.certificationsSkillsFormValid = event.isValid;
        this.areSectionsValid();
    }

    unitBoardCertificationsChanged(certifications: UnitBoardCertification[]): void {
        this.unit.boardCertifications = certifications;
        this.areSectionsValid();
    }

    unitStateLicensesChanged(licenses: UnitStateLicense[]): void {
        this.unit.stateLicenses = licenses;
    }

    unitStateControlledSubstancesChanged(unitStateControlledsubstances: UnitStateControlledSubstances[]): void {
        this.unit.stateControlledSubstances = unitStateControlledsubstances;
    }

    signatureChanged(event) {
        if (!this.unit) {
            return;
        }

        this.unit.signatureEmail = event.unit.signatureEmail;
        this.unit.signatureName = event.unit.signatureName;
        this.unit.signatureTitle = event.unit.signatureTitle;
        this.isUpgradedSectionsValid = event.isValid && this.isUpgradedSectionsValid;
        this.areSectionsValid();
    }

    unitOtherInformationChanged(event) {
        if (!this.unit) {
            return;
        }

        this.unit.unitAcceptsFirstTimeTraveler = event.unit.unitAcceptsFirstTimeTraveler;
        this.unit.unitAcceptsFirstTimeTravelerOther = event.unit.unitAcceptsFirstTimeTravelerOther;
        this.unit.orientationHours = event.unit.orientationHours;
        this.unit.orientationInformation = event.unit.orientationInformation;
        this.unit.scrubsProvided = event.unit.scrubsProvided;
        this.unit.scrubsColor = event.unit.scrubsColor;
        this.unit.preApprovedTimeOffPerContract = event.unit.preApprovedTimeOffPerContract;
        this.unit.programType = event.unit.programType;
        this.unit.otherInformation = event.unit.otherInformation;
        this.areSectionsValid();
    }

    unitWorkExperienceChanged(event) {
        if (!this.unit) {
            return;
        }
        this.unit.previousChargeExperience = event.unit.previousChargeExperience;
        this.unit.yearOfExperience = event.unit.yearOfExperience;
        this.unit.minimumExperienceRequiredOther = event.unit.minimumExperienceRequiredOther;
        this.unit.patientRatioExperience = event.unit.patientRatioExperience;
        this.unit.chartingExperience = event.unit.chartingExperience;
        this.unit.chartingExperienceCode = event.unit.chartingExperienceCode;
        this.unit.communityHospitalExperience = event.unit.communityHospitalExperience;
        this.unit.ltacExperience = event.unit.ltacExperience;
        this.unit.traumaLevelIExperience = event.unit.traumaLevelIExperience;
        this.unit.traumaLevelIIExperience = event.unit.traumaLevelIIExperience;
        this.unit.teachingHospitalExperience = event.unit.teachingHospitalExperience;

        this.isUpgradedSectionsValid = event.isValid && this.isUpgradedSectionsValid;
        this.areSectionsValid();
    }

    unitSpecialChanged(event) {
        if (!this.unit) {
            return;
        }

        this.unit.patientPopulation = event.unit.patientPopulation;
        this.unit.unitDetails = event.unit.unitDetails;
        this.unit.specialEquipment = event.unit.specialEquipment;
        this.isUpgradedSectionsValid = event.isValid && this.isUpgradedSectionsValid;
        this.areSectionsValid();
    }

    unitProviderSupportChanged(event) {
        if (!this.unit) {
            return;
        }
        this.unit.providerSupports = event.providerSupports;
        this.unit.providerSupportOther = event.providerSupportOther;
        this.areSectionsValid();
    }

    unitProviderCoverageChanged(event) {
        if (!this.unit) {
            return;
        }
        this.unit.providerCoverage = event.providerCoverage;
        this.areSectionsValid();
    }

    unitFacilityContactsChanged(event) {
        if (!this.unit) {
            return;
        }

        this.unit.contacts = event.contacts;
        this.areSectionsValid();
    }

    unitSystemFormChanged(event) {
        if (!this.unit || !event.unit) {
            return;
        }
        this.unit.medicationSystemPyxis = event.unit.medicationSystemPyxis;
        this.unit.medicationSystemAccudose = event.unit.medicationSystemAccudose;
        this.unit.medicationSystemOmnicell = event.unit.medicationSystemOmnicell;
        this.unit.communicationSBAR = event.unit.communicationSBAR;
        this.unit.communicationOther = event.unit.communicationOther;
        this.unit.medicationSystemOther = event.unit.medicationSystemOther;
        this.unit.documentationSystem = event.unit.documentationSystem;
        this.isUpgradedSectionsValid = event.isValid && this.isUpgradedSectionsValid;
        this.areSectionsValid();
    }

    shiftDetailsChanged(event) {
        if (!this.unit || !event.unit) {
            return;
        }
        this.unit.shifts = event.unit.shifts;
        this.unit.providerSupportOther = event.unit.providerSupportOther;
        this.unit.callRequired = event.unit.callRequired;
        this.unit.floatRequired = event.unit.floatRequired;
        this.unit.weekendCoverageEveryOther = event.unit.weekendCoverageEveryOther;
        this.unit.weekendCoverageEveryThird = event.unit.weekendCoverageEveryThird;
        this.unit.weekendShiftPerContract = event.unit.weekendShiftPerContract;
        this.unit.schedulingType = event.unit.schedulingType;
        this.unit.schedulingTypeOther = event.unit.schedulingTypeOther;
        this.unit.shiftDetailsOther = event.unit.shiftDetailsOther;
        this.areSectionsValid();
    }

    unitOtherRequirementsChanged(otherRequirements: UnitOtherRequirement[]): void {
        this.unit.otherRequirements = otherRequirements;
    }

    verify() {
        const isValid = this.areSectionsValid();
        if (!isValid) {
            this.makeFormsDirty();
            return;
        }
        this.unitFormSubmitted = true;
        const dialogRef = this._dialogService.openDialog(VerifyComponent, {
            data: {
                programTypes: this.lookups.programTypes.filter((p) => [2, 1, 4, 5].includes(p.id)),
                unitProgramType: this.unit.programType,
                programTypeRequired: this.programTypeRequired
            } as DialogProgramTypesData,
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((id: number) => {
            if (id > 0) {
                this.unit.programType = id;
                this.saveAndAccept();
            }
        });
    }

    areSectionsValid(): boolean {
        const validSignatureCtrl = this.signatureCtrl ? this.signatureCtrl?.signatureForm?.valid : true;
        const validBoardCertificationsCntrl = this.isBoardCertificationsCntrlValid();

        let isValid = false;
        if (this.isLocumsProfession$.value) {
            isValid =
                validSignatureCtrl &&
                this.workExperienceCtrl?.workExperienceForm?.valid &&
                this.facilityInformationCtrl?.facilityInformationForm?.valid &&
                this.certificationSkillsCtrl?.certificationsSkillsForm?.valid &&
                this.facilityPatientAgeGroupCtrl?.patientAgeGroupForm?.valid &&
                this.facilityheaderCtrl.form.valid &&
                this.workExperienceCtrl.isValidForSubmit &&
                validBoardCertificationsCntrl;
        } else {
            isValid =
                validSignatureCtrl &&
                this.unitSystemsCtrl?.unitSystemsform?.valid &&
                this.shiftDetailsCtrl?.shiftDetailsForm?.valid &&
                this.workExperienceCtrl?.workExperienceForm?.valid &&
                this.providerSupportCtrl?.providerSupportForm?.valid &&
                this.providerCoverageCtrl?.providerCoverageForm?.valid &&
                this.facilityInformationCtrl?.facilityInformationForm?.valid &&
                this.certificationSkillsCtrl?.certificationsSkillsForm?.valid &&
                this.facilityPatientAgeGroupCtrl?.patientAgeGroupForm?.valid &&
                this.facilityheaderCtrl.form.valid &&
                this.workExperienceCtrl.isValidForSubmit;
        }

        return isValid;
    }

    isBoardCertificationsCntrlValid(): boolean {
        return this.isLocumsUnitDescriptionFieldValidationFlag &&
            this.isLocumsProfession$.value &&
            this.isPhysicianProfession$.value
            ? this.boardCertificationsCntrl?.isValidForSubmit
            : true;
    }

    clarification() {
        const isValid = this.areSectionsValid();
        if (!isValid) {
            this.makeFormsDirty();
            return;
        }

        this.unit.status = UnitStatusEnum.Incomplete;
        const savedUnit = this.generateSaveUnit(this.unit, this.dateFormat, this.hostName);

        this._store.dispatch(
            actions.saveUnit({
                unit: savedUnit,
                saveUnitEnum: SaveUnitEnum.Send,
                shouldSaveExtraFeilds: this.isNyuFacility(),
                isAccessSecureUDLinksEnabled: this.isAccessSecureUDLinks,
                isEditMode: this.editMode,
                clinicalManager: this.jobUnitFacility?.clinicalManagerName,
                clinicalManagerEmail: this.jobUnitFacility?.clinicalManagerEmail
            })
        );
    }

    startPrint(): void {
        const pdfDialog = this._dialog.openDialog(UnitDescriptionLoadingDialogComponent, {
            data: 'Rendering PDF...',
            width: '60%',
            hasBackdrop: true,
            disableClose: true
        });

        pdfDialog.afterOpened().subscribe(() => {
            this.convertPageToPdf(pdfDialog);
        });
    }

    loadFeatureFlags() {
        this._ldFeatureManager
            .isEnabled(this.featureFlags.ConnectClinicalUDGridShowsLastEdited)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.showLastEditedFlag = flagIsEnabled;
            });

        this._ldFeatureManager
            .isEnabled(FeatureFlag.EnableHideTrackFieldForClientViewOfUD)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled) => (this.enableHideTrackFieldForClientViewOfUD = isEnabled));

        this._ldFeatureManager
            .isEnabled(QuesoFormaggioFeatureFlags.LocumsUnitDescriptionFieldValidation)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.isLocumsUnitDescriptionFieldValidationFlag = isEnabled;
            });

        this._ldFeatureManager
            .isEnabled(FeatureFlag.WfdHideUnitDescriptionRestrictedFields)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.isEnabledHideUnitDescriptionRestrictedFields = isEnabled;
            });

        this._ldFeatureManager
            .isEnabled(this.featureFlags.AccessSecureUDLinks)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.isAccessSecureUDLinks = flagIsEnabled;
            });

        this._ldFeatureManager
            .getJsonVariation(this.featureFlags.AccessSecureUdLinksByFacilities)
            .pipe(takeUntil(this.d$))
            .subscribe((flagVariations) => {
                this.accessSecureUDLinksFacilities = flagVariations;
            });
    }

    updateUnitCrnaCertification(crnaCertification: boolean): void {
        this.unit.isNbcrnaCertRequired = crnaCertification;
    }

    private checkHideWfdRestrictedFields() {
        if (this.isWorkForceDisruption) {
            const facilityId = Number(this.facilityInformationUnit?.unit?.jobUnit?.facility?.hospId);
            const userCanViewRestrictedFields =
                this._identityService.hasWfdPermissionToViewRestrictedFields(facilityId);
            this.hideWfdRestrictedFields =
                this.isEnabledHideUnitDescriptionRestrictedFields && !userCanViewRestrictedFields;
            if (!this.hideWfdRestrictedFields && !this.canShowContact) {
                this.canShowContact = true;
            } else if (this.hideWfdRestrictedFields && this.canShowContact) {
                this.canShowContact = false;
            }
        }
    }

    private generateSaveUnit(unit: Unit, dateFormat: string, hostName: string): Unit {
        const deepCopyUnit: Unit = JSON.parse(JSON.stringify(unit));
        deepCopyUnit.url = hostName;
        deepCopyUnit.jobUnit.hospId = deepCopyUnit.jobUnit.facility.hospId;
        deepCopyUnit.jobUnit.unitDescriptionFormId = deepCopyUnit.id;
        deepCopyUnit.professions =
            deepCopyUnit.professions && deepCopyUnit.professions.length > 0 ? [deepCopyUnit.professions[0]] : [];
        deepCopyUnit.specialties =
            deepCopyUnit.specialties && deepCopyUnit.specialties.length > 0 ? [deepCopyUnit.specialties[0]] : [];
        deepCopyUnit.contacts = deepCopyUnit.contacts ?? [];
        deepCopyUnit.providerCoverage = deepCopyUnit.providerCoverage ?? [];
        deepCopyUnit.providerSupports = deepCopyUnit.providerSupports ?? [];
        deepCopyUnit.patientAgeGroups = deepCopyUnit.patientAgeGroups ?? [];
        deepCopyUnit.shifts = deepCopyUnit.shifts ?? [];
        deepCopyUnit.date = deepCopyUnit.date ? dayjs(deepCopyUnit.date).utc().toDate() : new Date();
        deepCopyUnit.enrolledDate = deepCopyUnit.enrolledDate
            ? dayjs(deepCopyUnit.enrolledDate).utc().toDate()
            : new Date();
        return deepCopyUnit;
    }

    private convertPageToPdf(pdfDialog): void {
        this.isPrinting = true;
        this._changeDetectorRef.detectChanges();
        const element: HTMLElement | null = this._document.getElementById('unit-description-form');
        this.isPrinting = false;

        html2canvas(element, {
            scale: 1,
            scrollX: 0,
            scrollY: 0
        }).then((canvas: HTMLCanvasElement) => {
            const encodedPdfData = canvas.toDataURL('image/png', 1.0);
            const pdf = new jspdf('p', 'mm', 'a4');

            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(encodedPdfData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(encodedPdfData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                heightLeft -= pageHeight;
            }

            pdf.save('unit-description.pdf');
            pdfDialog.close();
        });
    }

    private saveAndAccept() {
        const isValid = this.areSectionsValid();
        if (!isValid) {
            return;
        }
        this.unit.status = UnitStatusEnum.Live;

        if (!this.unit.programType) {
            this.programTypeRequired = true;
            return;
        }
        this.isLoading = true;
        const savedUnit = this.generateSaveUnit(this.unit, this.dateFormat, this.hostName);

        this._store.dispatch(
            actions.saveUnit({
                unit: savedUnit,
                saveUnitEnum: SaveUnitEnum.Accept,
                shouldSaveExtraFeilds: this.isNyuFacility(),
                isAccessSecureUDLinksEnabled: this.isAccessSecureUDLinks && this.isAccessSecureUDLinksByFacility,
                isEditMode: this.editMode,
                clinicalManager: this.jobUnitFacility?.clinicalManagerName,
                clinicalManagerEmail: this.jobUnitFacility?.clinicalManagerEmail
            })
        );
    }

    private makeFormsDirty() {
        this.facilityheaderCtrl.markAsTouched();
        this.facilityInformationCtrl.facilityInformationForm.markAsDirty();
        this.signatureCtrl.markAsTouched();
        this.facilityInformationCtrl.markAsDirty();
        this.workExperienceCtrl.markAsTouched();
        if (this.isLocumsUnitDescriptionFieldValidationFlag && this.isPhysicianProfession$.value) {
            this.boardCertificationsCntrl.markAsTouched();
        }
    }

    private saveAndSubmit() {
        const isValid = this.areSectionsValid();
        if (!isValid) {
            this.makeFormsDirty();
            return;
        }
        this.unitFormSubmitted = true;
        this.isLoading = true;

        this.updateUnitUpdatedBy();

        this.unit.status = UnitStatusEnum.PendingReview;
        this.unit.enrolledDate = new Date();
        const savedUnit = this.generateSaveUnit(this.unit, this.dateFormat, this.hostName);
        savedUnit.token = this.token;
        savedUnit.user = this.userId;
        savedUnit.formId = this.formId;

        this._store.dispatch(
            actions.saveUnit({
                unit: savedUnit,
                saveUnitEnum: SaveUnitEnum.Submit,
                shouldSaveExtraFeilds: this.isNyuFacility(),
                isAccessSecureUDLinksEnabled: this.isAccessSecureUDLinks && this.isAccessSecureUDLinksByFacility,
                isEditMode: this.editMode,
                clinicalManager: this.jobUnitFacility?.clinicalManagerName,
                clinicalManagerEmail: this.jobUnitFacility?.clinicalManagerEmail
            })
        );
    }

    private saveAndSend() {
        const isValid = this.areSectionsValid();
        if (!isValid) {
            this.saveAndSendClicked = true;
            this.makeFormsDirty();
            return;
        } else {
            this.saveAndSendClicked = false;
        }

        this.unitFormSubmitted = true;
        this.isLoading = true;

        if (!this.editMode) {
            this.unit.status = UnitStatusEnum.Incomplete;
            this.unit.enrolledDate = null;
            if (!this.unit.jobUnit.name) {
                this.unit.jobUnit.name = this.unit.name;
            }
        }

        this.updateUnitUpdatedBy();

        const savedUnit = this.generateSaveUnit(this.unit, this.dateFormat, this.hostName);
        savedUnit.token = this.token;
        savedUnit.user = this.userId;
        savedUnit.formId = this.formId;

        this._store.dispatch(
            actions.saveUnit({
                unit: savedUnit,
                saveUnitEnum: SaveUnitEnum.Send,
                shouldSaveExtraFeilds: this.isNyuFacility(),
                isAccessSecureUDLinksEnabled: this.isAccessSecureUDLinks && this.isAccessSecureUDLinksByFacility,
                isEditMode: this.editMode,
                clinicalManager: this.jobUnitFacility?.clinicalManagerName,
                clinicalManagerEmail: this.jobUnitFacility?.clinicalManagerEmail
            })
        );
    }

    updateUnitUpdatedBy() {
        if (this.showLastEditedFlag && this._identityService.oldUserId != null) {
            this.unit.jobUnit.updatedBy = this._identityService.oldUserId.toString();
        } else {
            this.unit.jobUnit.updatedBy = this.searchParams.userId;
        }
    }

    private parseUnitParams() {
        if (this.searchParams.formId != null) {
            this.unit.id = this.searchParams.formId;
        }
    }

    getUnit() {
        this.isLoading = true;
        //oldUserId is populated when connect is logged in
        //userId is picked up from query string when coming from nova
        //so basically Connect user has priority over nova
        this._store.dispatch(
            actions.loadUnitInfo({
                unitId: this.searchParams.formId,
                hospId: this.searchParams.hospId,
                token: this.token ?? null,
                formId: this.formId ?? null
            })
        );

        this._store
            .select(selectUnitInfo)
            .pipe(takeUntil(this.d$))
            .subscribe((unitInfo) => {
                if (unitInfo && unitInfo.unitInfo && Object.keys(unitInfo.unitInfo).length > 0) {
                    this.facilityInformationUnit = unitInfo.unitInfo;
                    this.jobUnitFacility = unitInfo.unitInfo.unit?.jobUnit?.facility;
                    this.unit = unitInfo.unitInfo.unit;
                    this.unit.date = this.unit?.date ? dayjs.utc(this.unit?.date).local().toDate() : new Date();
                    this.unit.enrolledDate = this.unit?.enrolledDate
                        ? dayjs.utc(this.unit?.enrolledDate).local().toDate()
                        : new Date();

                    if (this.unit?.id === '00000000-0000-0000-0000-000000000000') {
                        this.unit.id = null;
                    }
                    this.fillLookups(this.facilityInformationUnit);
                    this.setting();
                    this.isLoading = false;
                    this.loadingDialog.close();
                    this.loadAccessSecureUdLinksByFacilities(this.unit?.jobUnit?.facility?.hospId);
                }
            });
    }

    loadAccessSecureUdLinksByFacilities(facilityId: number) {
        if (
            typeof this.accessSecureUDLinksFacilities === 'undefined' ||
            !Array.isArray(this.accessSecureUDLinksFacilities)
        ) {
            this.isAccessSecureUDLinksByFacility = false;
        } else if (
            this.accessSecureUDLinksFacilities.length === 1 &&
            String(this.accessSecureUDLinksFacilities[0]) === ALL_FACILITIES
        ) {
            this.isAccessSecureUDLinksByFacility = true;
        } else {
            this.isAccessSecureUDLinksByFacility = this.accessSecureUDLinksFacilities.includes(facilityId);
        }
    }

    private buttonSetting(preview: boolean, am: boolean) {
        if (this.editMode) {
            this.readOnly = false;
            this.canSave = true;
            this.canSubmit = false;
            this.canAccept = false;
            this.signatureRequired = !!am;
        }

        if (preview) {
            this.readOnly = true;
            this.canSave = false;
            this.canSubmit = false;
            this.canAccept = false;
        }
    }

    onClinicalTracksChanged(clinicalTrackId: number | null): void {
        this.clinicalTrackId = clinicalTrackId;
    }
}
