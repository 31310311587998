import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { JobDetail } from 'src/app/admin/jobs/models/job-detail.model';
import { JobGridItem } from 'src/app/admin/jobs/models/job-grid-item.model';
import { JobLookup } from 'src/app/admin/jobs/models/job-lookup.model';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { ReleaseGroup } from 'src/app/shared/models/vendor-releases/release-group.model';
import { FileAttachment } from 'src/app/shared/models/attachment';

const loadAdminJobs = createAction('[ADMIN JOBS COMPONENT] Load Admin Jobs');
const loadAdminJobsSuccess = createAction(
    '[ADMIN JOBS API] Load Admin Jobs Successfully',
    props<{
        jobs: JobGridItem[];
        filteredAllPositionsCount: number;
        filteredOpenPositionsCount: number;
        total: number;
    }>()
);
const loadAdminJobsFailure = createAction('[ADMIN JOBS API] Load Admin Jobs Failure', props<{ error: unknown }>());

const setVendorCandidatesListSearchQuery = createAction(
    '[ADMIN JOBS COMPONENT] Set Vendor Candidates Search Query',
    props<{ searchQuery: GridSearchQuery }>()
);

const loadAdminJobLookups = createAction('[ADMIN JOBS API] Load Admin Jobs Lookups');
const loadAdminJobLookupsSuccess = createAction(
    '[ADMIN JOBS API] Load Admin Jobs Lookups Success',
    props<{ lookups: JobLookup }>()
);
const loadAdminJobLookupsFailure = createAction(
    '[ADMIN JOBS API] Load Admin Jobs Lookups Failure',
    props<{ error: unknown }>()
);

const exportAdminJobs = createAction('[ADMIN JOBS COMPONENT EXPORT BUTTON] Export Admin Jobs Grid');
const exportAdminJobsSuccess = createAction('[ADMIN JOBS API] Export Admin Jobs Success', props<{ result: Blob }>());
const exportAdminJobsFailure = createAction('[ADMIN JOBS API] Export Admin Jobs Failure', props<{ error: unknown }>());

const exportSelectedAdminJobs = createAction(
    '[ADMIN JOBS COMPONENT EXPORT SELECTED BUTTON] Export Selected Admin Jobs',
    props<{ selectedJobs: number[] }>()
);

const setFacilityGroupIdFilter = createAction(
    '[ADMIN JOBS COMPONENT FACILITY GROUP SELECT] Search Grid By Facility Group Id',
    props<{ facilityGroupId: number }>()
);

const loadReleaseGroupsByFacilities = createAction(
    '[JOBS RELEASE DIALOG COMPONENT] Load Release Groups by Facilities',
    props<{ facilityIds: number[] }>()
);
const loadReleaseGroupsByFacilitiesSuccess = createAction(
    '[VENDOR RELEASE API] Load Release Groups by Facilities Success',
    props<{ groups: ReleaseGroup[] }>()
);
const loadReleaseGroupsByFacilitiesFailure = createAction(
    '[VENDOR RELEASE API] Load Release Groups by Facilities Failure',
    props<{ error: unknown }>()
);

const releaseJobs = createAction(
    '[JOBS RELEASE DIALOG COMPONENT] Release Jobs',
    props<{ entityIds: number[]; vendorIds: number[] }>()
);
const releaseAllJobs = createAction(
    '[JOBS RELEASE DIALOG COMPONENT RELEASE ALL BUTTON] Release All Jobs',
    props<{ jobIds: number[]; vendorIds: number[] }>()
);

const releaseJobsSuccess = createAction(
    '[VENDOR RELEASE API] Release Jobs Success',
    props<{ numOfReleasedJobs: number }>()
);

const releaseJobsFailure = createAction(
    '[VENDOR RELEASE API] Release Jobs Failure',
    props<{ error: HttpErrorResponse }>()
);

const releaseAllJobsPositions = createAction(
    '[JOBS RELEASE DIALOG COMPONENT] Release Multiple Positions Under Multiple Jobs',
    props<{ jobIds: number[]; vendorIds: number[] }>()
);

const releaseAllJobsPositionsSuccess = createAction(
    '[VENDOR RELEASE API] Release Multiple Positions Under Multiple Jobs Success'
);

const releaseAllJobsPositionsFailure = createAction(
    '[VENDOR RELEASE API] Release Multiple Positions Under Multiple Jobs Failure',
    props<{ error: HttpErrorResponse }>()
);

const releaseAllJobPositions = createAction(
    '[JOBS RELEASE DIALOG COMPONENT] Release Multiple Positions Under One Job',
    props<{ jobId: number; vendorIds: number[] }>()
);

const releaseAllJobPositionsSuccess = createAction(
    '[VENDOR RELEASE API] Release Multiple Positions Under One Job Success',
    props<{ jobId: number }>()
);

const releaseAllJobPositionsFailure = createAction(
    '[VENDOR RELEASE API] Release Multiple Positions Under One Job Failure',
    props<{ error: HttpErrorResponse }>()
);

const releaseJobPosition = createAction(
    '[JOBS RELEASE DIALOG COMPONENT] Release One Position',
    props<{ jobId: number; jobPositionId: number; vendorIds: number[] }>()
);

const releaseJobPositionSuccess = createAction(
    '[VENDOR RELEASE API] Release One Position Success',
    props<{ jobId: number }>()
);

const releaseJobPositionFailure = createAction(
    '[VENDOR RELEASE API] Release One Position Failure',
    props<{ error: HttpErrorResponse }>()
);

const resetGridSearchQuery = createAction('[ADMIN JOBS COMPONENT] Reset Grid Search Query');

const getJobById = createAction('[ADMIN JOB DETAILS COMPONENT] Get Job By Job Id', props<{ jobId: number }>());
const getJobByIdSuccess = createAction('[ADMIN JOBS API] Get Job Details Success', props<{ jobDetails: JobDetail }>());
const getJobByIdFailure = createAction('[ADMIN JOBS API] Get Job Details Failure', props<{ error: unknown }>());

const getCanEditVendorBaseRate = createAction('[ADMIN JOB DETAILS COMPONENT] Get Can Edit Vendor Base Rate');
const getCanEditVendorBaseRateSuccess = createAction(
    '[ADMIN JOB API] Get Can Edit Vendor Base Rate Success',
    props<{ canEdit: boolean }>()
);
const getCanEditVendorBaseRateFailure = createAction(
    '[ADMIN JOB API] Get Can Edit Vendor Base Rate Failure',
    props<{ error: unknown }>()
);

const loadJobAttachments = createAction(
    '[ADMIN JOB DETAILS COMPONENT] Load Job Attachments',
    props<{ jobId: number }>()
);
const loadJobAttachmentsSuccess = createAction(
    '[ADMIN JOB API] Load Job Attachments Success',
    props<{ attachments: FileAttachment[] }>()
);
const loadJobAttachmentsFailure = createAction(
    '[ADMIN JOB API] Load Job Attachments Failure',
    props<{ error: unknown }>()
);

const loadJobAttachmentPreview = createAction(
    '[ADMIN JOB DETAILS COMPONENT] Load Job Attachment Preview',
    props<{ jobId: number; fileId: number; pageNum: number }>()
);
const loadJobAttachmentPreviewSuccess = createAction(
    '[ADMIN JOB API] Load Job Attachment Preview Success',
    props<{ file: Blob }>()
);
const loadJobAttachmentPreviewFailure = createAction(
    '[ADMIN JOB API] Load Job Attachment Preview Failure',
    props<{ error: unknown }>()
);

const loadJobAttachmentPreviewTotalPages = createAction(
    '[ADMIN JOB DETAILS COMPONENT] Load Job Attachment Preview Total Pages',
    props<{ jobId: number; fileId: number }>()
);
const loadJobAttachmentPreviewTotalPagesSuccess = createAction(
    '[ADMIN JOB API] Load Job Attachment Preview Total Pages Success',
    props<{ totalPages: number }>()
);
const loadJobAttachmentPreviewTotalPagesFailure = createAction(
    '[ADMIN JOB API] Load Job Attachment Preview Total Pages Failure',
    props<{ error: unknown }>()
);

const downloadJobAttachment = createAction(
    '[ADMIN JOB DETAIL ATTACHMENT PREVIEW COMPONENT] Download Job Attachment',
    props<{ jobId: number; fileId: number; fileName: string }>()
);
const downloadJobAttachmentSuccess = createAction(
    '[ADMIN JOB API] Download Job Attachment Success',
    props<{ file: Blob; fileName: string }>()
);
const downloadJobAttachmentFailure = createAction(
    '[ADMIN JOB API] Download Job Attachment Failure',
    props<{ error: unknown }>()
);

const resetJobAttachmentPreview = createAction(
    '[ADMIN JOB DETAIL ATTACHMENT PREVIEW COMPONENT] Reset Job Attachment Preview'
);

const updateVendorBaseRate = createAction(
    '[ADMIN JOB DETAIL VENDOR BASE RATE CONFIRM] Update Vendor Base Rate',
    props<{ jobId: number; vendorBaseRate: number }>()
);
const updateVendorBaseRateSuccess = createAction(
    '[ADMIN JOB API] Update Vendor Base Rate Successfully',
    props<{ jobId: number }>()
);
const updateVendorBaseRateFailure = createAction(
    '[ADMIN JOB API] Update Vendor Base Rate Failure',
    props<{ error: unknown }>()
);

export const adminJobsActions = {
    loadAdminJobs,
    loadAdminJobsSuccess,
    loadAdminJobsFailure,
    setVendorCandidatesListSearchQuery,
    loadAdminJobLookups,
    loadAdminJobLookupsSuccess,
    loadAdminJobLookupsFailure,
    exportAdminJobs,
    exportAdminJobsSuccess,
    exportAdminJobsFailure,
    exportSelectedAdminJobs,
    setFacilityGroupIdFilter,
    loadReleaseGroupsByFacilities,
    loadReleaseGroupsByFacilitiesSuccess,
    loadReleaseGroupsByFacilitiesFailure,
    releaseJobs,
    releaseAllJobs,
    releaseJobsSuccess,
    releaseJobsFailure,
    releaseAllJobsPositions,
    releaseAllJobsPositionsSuccess,
    releaseAllJobsPositionsFailure,
    releaseAllJobPositions,
    releaseAllJobPositionsSuccess,
    releaseAllJobPositionsFailure,
    releaseJobPosition,
    releaseJobPositionSuccess,
    releaseJobPositionFailure,
    resetGridSearchQuery,
    getJobById,
    getJobByIdSuccess,
    getJobByIdFailure,
    getCanEditVendorBaseRate,
    getCanEditVendorBaseRateSuccess,
    getCanEditVendorBaseRateFailure,
    loadJobAttachments,
    loadJobAttachmentsSuccess,
    loadJobAttachmentsFailure,
    loadJobAttachmentPreview,
    loadJobAttachmentPreviewSuccess,
    loadJobAttachmentPreviewFailure,
    loadJobAttachmentPreviewTotalPages,
    loadJobAttachmentPreviewTotalPagesSuccess,
    loadJobAttachmentPreviewTotalPagesFailure,
    downloadJobAttachment,
    downloadJobAttachmentSuccess,
    downloadJobAttachmentFailure,
    resetJobAttachmentPreview,
    updateVendorBaseRate,
    updateVendorBaseRateSuccess,
    updateVendorBaseRateFailure
};
