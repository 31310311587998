export const termsOfUse = (url: string) => `
<h4>General Uses and Restrictions</h4>
<p>
    By accessing and using the Aya Sites, you agree on your behalf and on behalf of any entity or person on whose
    behalf you are accessing the Aya Sites (collectively, “You”) to abide by the Terms. Aya permits You to use and
    access the Aya Sites for lawful purposes only and only in a manner consistent with the Terms. Your agreement to
    the Terms is a condition of Your being granted access to and use of the Aya Sites. If You do not agree with the
    Terms, You may not access the Aya Sites and must exit the Aya Sites immediately. The Terms apply to all visits
    and use of the Aya Sites, as well as to the content, information, recommendations or services provided or
    transmitted on or through the Aya Sites. By using and accessing the Aya Sites, You represent that You are at
    least 18 years of age, have the authority to enter these Terms on Your own behalf and on behalf of the
    organization or entity on whose behalf You are using or accessing the Aya Sites, and all information provided
    by You, including, but not limited to Your identity, contact information, experience, qualifications, available
    positions, and facility details, is accurate and current. You shall be responsible for any violation by You,
    Your agents or Your employees of the Terms.
</p>
<h4>Restricted Sites; Registered Users</h4>
<p>
    Aya has restricted access to certain portions of the Aya Sites, including its Aya Connect portal at
    ${url}, and its application, profile, shift, and other sections of ayahealthcare.com (collectively the
    “Restricted Sites”), to users with legitimate Aya-related business reasons for using such Restricted Sites who
    have registered with Aya or to whom Aya has otherwise provided an account, user name or password (the
    “Registered Users”). You agree that the entirety of the Restricted Sites, including, but not limited to their
    design, source code, databases, content, and data or materials stored on, generated by, created using, or
    transmitted through them shall be considered Confidential Information, as defined below. You agree that Your
    use of and access to the Restricted Sites shall be limited only to the extent such access and use directly
    relates to or is necessary for an existing or prospective employment relationship with Aya or an existing or
    prospective business relationship or agreement between You and Aya (the “Permitted Uses”).
</p>
<p>
    You agree any user name or password issued to You by Aya is Confidential Information, as defined below. You
    agree not to use another Registered User’s user name and password nor to allow any other person, other than
    Your employees or agents, to use the user name or password issued to You. You are solely responsible for all
    activity conducted using Your user name or password. You will immediately notify Aya if You become aware of any
    loss or theft of Your user name or password or any unauthorized use of Your user name and password. Aya is not
    be liable for any loss or damage arising from your failure to comply with these obligations. Aya reserves the
    right to delete or change a user name or password at any time and for any reason. You shall be responsible for
    notifying Aya if Your relationship with any employee or agent who had access to the Restricted Sites is severed
    so passwords may be updated or accounts disabled as appropriate in Aya’s sole discretion.
</p>
<h4>Confidential Information</h4>
<p>
    When accessing or using the Restricted Sites, you may be able to upload, download, receive, transmit, view,
    create, enter, access, post, share, display, or otherwise use or access information, documents, or other
    material that is confidential in nature (collectively “Confidential Information”). Confidential Information
    includes, but is not limited to: (i) marketing, job orders, personal identifying information, and other
    information relating to Aya’s business, customers, agents, employees, or subcontractors or (ii) private or
    sensitive information relating to healthcare professionals and other workers provided by Aya, its affiliates,
    agents, or subcontractors, such as personnel, medical, financial, credentialing, or other compliance
    documentation or records. You agree to only use Confidential Information for lawful, Permitted Uses or as
    otherwise permitted by law. You agree to limit access to Confidential Information to only those employees or
    agents of Yours who reasonably require access to such information for the Permitted Uses. If You provide any
    Confidential Information to Your employees or agents, You shall require all such employees or agents abide by
    these Terms as well as any applicable laws or agreements relating such information. You shall take all
    necessary precautions to protect the confidential nature of the Confidential Information, including, without
    limitation, all precautions You use with respect to Your own confidential information.
</p>
<p>
    In the event You or any of Your representatives are requested or required by any government or governmental
    agency or authority (by oral questions, interrogatories, requests for information or documents, subpoena, civil
    investigative demand, any informal or formal investigation by any government or governmental agency or
    authority or otherwise) to disclose any of the Confidential Information, You will notify Aya promptly in
    writing so that Aya may at seek a protective order or other appropriate remedy or, in Aya’s sole discretion,
    waive compliance with the confidentiality terms. You agree not to oppose any action by Aya to obtain a
    protective order or other appropriate remedy. In the event that no protective order or other remedy is obtained
    or Aya waives compliance with the terms of this Agreement, You agree to furnish only that portion of the
    Confidential Information, which You are advised is legally required.
</p>
<p>
    If You discover or are notified of a breach or potential breach of security with respect to any Confidential
    Information provided or made available by Aya, You shall immediately: (i) notify Aya of such breach or such
    potential breach as the address specified below, (ii) immediately investigate such breach or such potential
    breach and inform Aya of the results of such investigation, and (iii) assist Aya as reasonably necessary to
    enforce Aya’s rights and to enable Aya to comply with any state or federal law requiring the provision of
    notice of any security breach with respect to the breach.
</p>
<p>
    Notwithstanding any other provision of this Agreement, Confidential Information shall not include any
    information, data, material, document, patent or idea, which: (i) is within the public domain prior to the time
    of disclosure or thereafter becomes within the public domain other than as a result of disclosure by You or
    Your representatives in violation of this Agreement; (ii) was, on or before the date of disclosure in Your
    possession, as shown by You, unless it was acquired by You from a third party who was under an obligation of
    confidentiality Aya; (iii) is acquired by You from a third party not under an obligation of confidentiality to
    Aya; or (iv) was independently developed by the You without the use of Confidential Information or other breach
    of these Terms.
</p>
<h4>Aya’s Retention of Proprietary Rights</h4>
<p>
    Aya hereby grants You with the limited and revocable license to access and use the Aya Sites in accordance with
    these Terms. You expressly acknowledge and agree that Aya transfers no ownership, intellectual property
    interest, or title in or to the Aya Sites, the Confidential Information, or any information or data contained
    therein to You or anyone else. All text, graphics, user interfaces, visual interfaces, photographs, sounds,
    artwork, computer code (including html code), programs, software, products, information, and documentation as
    well as the design, structure, selection, coordination, expression, “look and feel,” and arrangement of any
    content contained on or available through the Aya Sites, unless otherwise indicated, are owned, controlled, or
    licensed by Aya. You may not copy, store, reproduce, transmit, distribute, display, rent, lease, sell, modify,
    alter, license, sublicense, or commercially exploit the Aya Sites, Aya’s name, Aya’s logo, Aya’s marks, or any
    other data or information provided on or through the Aya Sites in any manner or use use the Aya Sites in any
    manner not expressly permitted by the Terms.
</p>
<p>
    The Aya Sites are Copyright © 2015, 2016 Aya Healthcare, Inc. All rights reserved. Your unauthorized use of the
    Aya Sites, Confidential Information, Aya logos, names, or marks, or any other information or data contained
    therein may violate intellectual property or other proprietary rights laws as well as other laws, regulations,
    and statutes.
</p>
<h4>Compliance with Applicable Laws</h4>
<p>
    You agree to abide by all applicable local, state, national, and international laws and regulations with respect
    to Your use of the Aya Sites or any Confidential or other information obtained using the Aya Sites. The Terms
    are also subject to any applicable export laws, orders, restrictions, or regulations. You shall not export the
    Aya Sites or access or use thereto without complying with such laws, orders, restrictions, or regulations
</p>
<h4>Prohibited Uses; Your Transmissions</h4>
<p>
    You may not use the Aya Sites to send, upload, share, email, post, publish, edit, modify, or otherwise transmit
    material or information, or use information obtained through the Aya Sites, in any manner that: (i) violates
    any applicable law or regulations; (ii) is false or misleading; (iii) is defamatory, offensive, obscene,
    harassing, or discriminatory, including on any basis protected by applicable federal, state or local law; (iv)
    violates or infringes on the intellectual property or privacy rights of any other person, including but not
    limited to rights arising from copyrights, trademarks, patents, or a person’s likeness; (v) constitutes
    unsolicited marketing efforts, including “junk email” “spam” or chain letters’; (vi) interferes with or
    disrupts the performance of the Aya Sites or servers or systems used to host or operate the Aya Sites, or of
    Aya, or of other users of the Aya Sites; or (vii) attempts to circumvent or misuse the user authentication or
    security measures of the Aya Sites.
</p>
<p>
    By sending, uploading, sharing, emailing, posting, publishing, editing, modifying, or otherwise transmitting
    content to the Aya Sites or submitting any content to or through the Aya Sites or to Aya relating to the Aya
    Sites (including, documentation, feedback), you automatically grant (or warrant that the owner of such rights
    has expressly granted), Aya a worldwide, perpetual, royalty-free, irrevocable, nonexclusive right and license
    to use, reproduce, modify, adapt, publish, transmit and distribute such content on in any form, medium, or
    technology now known or later developed, including, but not limited to, the Aya Sites. In addition, You warrant
    that all so-called moral rights in the content have been waived. Aya assumes no responsibility for the deletion
    of or failure to store any posting, transmission, documents or information stored or submitted on the Aya
    Sites. Notwithstanding the foregoing, or section 14(h) below, this section is not intended to and does not
    supersede any applicable laws or separate written agreements between You and Aya that are signed by the CEO of
    Aya, to the extent such laws or agreements restrict or govern Aya’s use of confidential information provided by
    You to Aya as part of a business transaction.
</p>
<p>
    Aya is not responsible for screening, policing, editing, or monitoring Your or another Registered User’s
    transmissions, submissions, and postings. Accordingly, Aya encourages its Registered Users to use reasonable
    discretion and caution in submitting or reviewing any transmission or posting. Moreover, Aya does not make any
    representation with respect to, nor does it endorse the accuracy, completeness, timeliness, or reliability of
    any advice, opinion, statement, or other material displayed, uploaded, or distributed by You or any other
    Registered User. Nevertheless, Aya reserves the right to delete or take other action with respect to
    transmissions or postings (or parts thereof) that Aya believes in good faith violate the Terms and/or are, or
    are potentially, unlawful or harmful to Aya or its products, services, and goodwill.
</p>
<h4>Privacy Policy</h4>
<p>
    1. Your use of the Aya Sites may be monitored, including to monitor usage levels, collect data and information,
    and to ensure compliance with applicable laws and agreements. Accordingly, if You access the Aya Sites you
    acknowledge and consent to Aya’s monitoring, collection and use practices. Please see Aya’s Privacy Policy for
    a summary of its practices in this regard.
</p>
<h4>Alleged Copyright Violations</h4>
<p>
    It is the policy of Aya to take appropriate actions under the Digital Millennium Copyright Act under U.S.
    Copyright Law and other applicable intellectual property laws. If you become aware of transmissions or postings
    that violate these rules or believe your copyrighted work has been inappropriately use or made accessible
    through the Aya Sites, please notify Aya in writing, as provided below.
</p>
<h4>&nbsp;Links to Other Sites</h4>
<p>
    Aya may provide links, in its sole discretion, to other sites on the World Wide Web for your convenience in
    locating or accessing related information, products, and services. These sites have not necessarily been
    reviewed by Aya and are maintained by third parties over which Aya exercises no control. Accordingly, Aya
    expressly disclaims any responsibility for the content, the materials, the accuracy of the information, and/or
    the quality of the products or services provided by, available through, or advertised on these third-party Web
    sites. Moreover, these links do not imply an endorsement with respect to any third party or any Web site or the
    products or services provided by any third party.
</p>
<h4>Disclaimer</h4>
<p>
    THE AYA SITES AND ALL INFORMATION, SERVICES, PROGRAMS, SOFTWARE, AND MATERIALS AVAILABLE ON OR THROUGH THE AYA
    SITES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS AND MAY INCLUDE ERRORS, OMISSIONS, OR OTHER
    INACCURACIES. AYA MAY MAKE MODIFICATIONS AND/OR CHANGES IN THE AYA SITES OR IN THE INFORMATION, SERVICES,
    PROGRAMS, SOFTWARE, AND MATERIALS AVAILABLE ON THE AYA SITES AT ANY TIME AND FOR ANY REASON. YOU ASSUME THE
    SOLE RISK OF MAKING USE AND/OR RELYING ON THE INFORMATION, SERVICES, PROGRAMS, AND MATERIALS AVAILABLE ON THE
    AYA SITES. AYA MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE RESULTS THAT CAN BE ACHIEVED FROM OR THE
    SUITABILITY, COMPLETENESS, TIMELINESS, RELIABILITY, LEGALITY, OR ACCURACY OF THE INFORMATION, SERVICES,
    PROGRAMS, AND MATERIALS AVAILABLE ON THE AYA SITES FOR ANY PURPOSE. AYA EXPRESSLY DISCLAIMS ALL WARRANTIES,
    EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
    PURPOSE, OR NON-INFRINGEMENT OR ANY OTHER IMPLIED WARRANTY INCLUDING, BUT NOT LIMITED ANY IMPLIED WARRANTIES
    UNDER THE UNIFORM COMPUTER INFORMATION TRANSACTIONS ACT AS ENACTED BY ANY STATE. AYA ALSO MAKES NO
    REPRESENTATION OR WARRANTY THAT THE AYA SITES WILL OPERATE ERROR FREE OR IN AN UNINTERRUPTED FASHION OR THAT
    ANY FILES OR INFORMATION THAT YOU DOWNLOAD FROM THE SITE WILL BE FREE OF VIRUSES OR CONTAMINATION OR
    DESTRUCTIVE FEATURES. YOU UNDERSTAND AND ACKNOWLEDGE THAT YOUR SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY
    DEFECT IN OR DISSATISFACTION WITH THE AYA SITES OR ANY INFORMATION THEREIN IS TO CEASE USE OF THE AYA SITES.
</p>
<p>
    MOREOVER, IN NO EVENT SHALL AYA BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
    DAMAGES ARISING OUT OF OR RELATED TO THE USE OF OR ACCESS TO THE AYA SITES OR WITH THE DELAY OR INABILITY TO USE
    THE AYA SITES WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, EVEN IF AYA HAS BEEN ADVISED OF
    THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
    LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY.
</p>
<p>
    NOTWITHSTANDING THE FOREGOING, TOTAL LIABILITY OF AYA FOR ANY REASON WHATSOEVER RELATED TO YOUR USE THE USE OF
    OR ACCESS TO THE AYA SITES AND ALL INFORMATION AND SERVICES THEREIN, OR WITH THE DELAY OR INABILITY TO USE THE
    AYA SITES, INFORMATION OR SERVICES THEREIN SHALL NOT EXCEED THE TOTAL AMOUNT PAID BY/TO YOU TO/FROM AYA IN
    CONNECTION WITH THE SUBJECT MATTER OF THE PARTICULAR DISPUTE DURING THE THREE MONTHS PRIOR TO THE ACT GIVING
    RISE TO THE DISPUTE
</p>
<h4>Indemnification</h4>
<p>
    You agree to defend, indemnify, and hold harmless Aya, its affiliates, officers, directors, employees, agents,
    licensors, clients, subcontractors, and harmless from and against any and all claims, damages, losses,
    liabilities, costs, and expenses (including attorneys’ fees) relating to or arising from: (i) Your use of the
    Aya Sites, (ii) Your violation of the Terms; (iii) Your violation of any third party rights relating to or
    arising out of Your use of the Aya Sites, or (iv) Your violation of any law or regulation relating to or
    arising out of Your use or access to the Aya Site
</p>
<h4>Term and Termination</h4>
<p>
    The Terms shall take effect immediately upon: Your access to or use of the Aya Sites after the Terms are posted,
    or if you have executed a separate agreement incorporating or referencing these Terms, the effective date of
    such agreement. The Aya Sites are provided as a convenience to assist Aya in conducting its business. Aya
    reserves the right to modify, change, or disable the Aya Sites or Your use of or access to the Aya sites, at
    any time with or without notice. Aya shall not be liable for damages in connection with such terminations,
    disabling, modifications, or changes.
</p>
<p>
    In addition, Aya reserves the right to modify or terminate the Terms and Privacy Policy at any time upon
    reasonable notice to You. You agree that Aya may provide reasonable notice of such modifications by posting the
    new or modified terms (the “Modified Terms”) on the Aya Sites and specifying an effective date that is at least
    10 days after the date the Modified Terms are initially posted. You agree that if You continue to use the Aya
    Sites without written objection to Aya after the effective date of the Modified Terms, You shall be considered
    to have accepted the Modified Terms as of the effective date stated therein. Such Modified Terms shall apply
    prospectively only; in the event of termination or Modified Terms becoming effective, these Terms shall survive
    as they relate to any pre-termination or pre-modification use, access, actions, or omissions, unless otherwise
    agreed to by the parties.
</p>
<p>
    You may terminate the Terms at any time by ceasing to use the Aya Sites, and if You are a Registered User, by
    notifying Aya of your desire to terminate Your account. Upon termination, You must destroy all copies of
    Confidential Information in your possession, custody or control, except that You may be permitted to obtain a
    copy of certain Confidential Information to the extent permitted in writing by Aya or where You are legally
    required to retain such records.
</p>
<h4>Notices</h4>
<p>
    All notices, requests, demands or other communications to Aya required under this Agreement shall be made in
    writing and shall be deemed to have been duly given on the second day after mailing if mailed to the address
    below by certified mail, postage prepaid or via overnight carrier with delivery confirmation, and properly
    addressed to:
</p>
<p>Aya Healthcare, Inc</p>
<p>Attn: Legal Department</p>
<p>5930 Cornerstone Court West, Suite 300</p>
<p>San Diego, CA 92121</p>
<p>
    Aya may provide notice to You by posting on the Aya Sites or otherwise contacting You at any last known email or
    mailing address.
</p>
<h4>MISCELLANEOUS</h4>
<ol type="a" style="list-style-type: upper-roman;">
    <li>
        The Terms and any claims arising out of or relating to the Terms or Your use of the Aya Sites shall be
        construed in accordance with and subject to the laws of the State of California, without reference to its
        conflict of law principles. You hereby consent to the exclusive personal jurisdiction in any state or
        federal court or arbitral forum located in San Diego, California. If any provision of this Agreement is
        held to be invalid, void or unenforceable, all other provisions shall remain valid and be enforced and
        construed as if such invalid provision were never a part of this Agreement. The parties hereto agree that
        this Agreement will be governed by and construed in accordance with the laws of the State of California
        without reference to its choice of law rules and as if wholly performed within the State of California.
        Unless otherwise provided herein, any dispute, controversy or claim arising out of or relating to this
        Agreement shall be submitted to binding arbitration before the office of Judicial Arbitration and Mediation
        Services (“JAMS”) located in San Diego, California, as the exclusive remedy.
    </li>
    <li>
        You acknowledge that any breach, threatened or actual, of the Terms will cause irreparable injury to Aya,
        such injury would not be quantifiable in monetary damages, and Aya would not have an adequate remedy at
        law. You therefore agree that Aya shall be entitled, in addition to other available remedies, to seek and
        be awarded an injunction or other appropriate equitable relief from a court of competent jurisdiction
        restraining any breach, threatened or actual, of your obligations under any provision of the Terms.
        Accordingly, You hereby waive any requirement that Aya post any bond or other security in the event any
        injunctive or equitable relief is sought by or awarded to Aya to enforce any provision of the Terms.
    </li>
    <li>
        You may not assign Your rights or obligations to any other person or entity without Aya’s prior written
        consent.
    </li>
    <li>
        No waiver, delay or failure by Aya with respect to any right or provision under the Terms shall be deemed a
        waiver of any other provision, or constitute a continuing waiver.
    </li>
    <li>
        If any provision (or part thereof) contained in the Terms is determined to be void, invalid, or otherwise
        unenforceable by a court of competent jurisdiction or on account of a conflict with an applicable
        government regulation, such determination shall not affect the remaining provisions (or parts thereof)
        contained herein and the illegal, invalid, or unenforceable clause shall be modified in compliance with
        applicable law in a manner that most closely matches the intent of the original language.
    </li>
    <li>
        No joint venture, partnership, employment, or agency relationship exists between you and Aya as result of
        the Terms or your utilization of the Aya Sites.
    </li>
    <li>
        The heading the Terms are for convenience of reference only and are not to be considered in construing this
        Agreement.
    </li>
    <li>
        The Terms represent the entire agreement between You and Aya with respect to use of the Aya Sites, and it
        supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written
        between you and Aya that govern Your use of or access to the Aya Sites. No amendments to this Agreement may
        be made except by a written agreement signed by both the parties. However, in the event You are or were an
        employee of Aya, the terms of any employment policies or written agreements signed with the requisite
        authority from Aya’s CEO relating to Your employment with Aya (including any arbitration agreements) shall
        control to the extent deemed inconsistent with the Terms.
    </li>
</ol>`;

export const electronicConsent = `
<h4><i>Updated April 11, 2018</i></h4>
<div class="text-justify">
    <p>
        This Electronic Signature and Transactions Disclosure and Consent Agreement (“<b>Consent</b>”) relates
        to the websites or online platforms, including mobile applications, owned or operated by Aya
        Healthcare, Inc. (“<b>Aya</b>”) and its affiliates (each, a “<b>Website</b>”) and any Communications
        (as defined below) created, generated, sent, communicated, received, signed, or stored by electronic
        means between you and Aya or its affiliates. This Consent describes your consent to do business with
        Aya and its affiliates electronically and how Aya and its affiliates deliver communications and
        electronic records to you electronically. Aya may amend this policy at any time by posting a revised
        version on the Website.
    </p>
    <h4 class="text-title text-underline">
        Consent to do Business and Receive and Sign Communications
        Electronically
    </h4>
    <p>
        By entering into and accepting this Consent, you agree and consent to electronically receive all
        Communications and to otherwise do business with Aya and its affiliates electronically. You also agree
        that this Consent and any other Communications may be signed electronically, and that such electronic
        signatures will have the same legal effect for all purposes, including validity, enforceability and
        admissibility, as a handwritten signature. Aya may provide Communications to you through the Website or
        by emailing them to you at the primary email address designated by you to Aya.
    </p>
    <p>
        “<b>Communications</b>” shall include, without limitation, all agreements, policies, notices,
        disclosures, the Terms&nbsp;of&nbsp;Use,
        time cards, pay stubs, employment applications, personnel records or notices, work order confirmations,
        , invoices, annual disclosures, transaction receipts or confirmations statements, and transaction
        history, relating to your use of the Website or your relationship with Aya or its affiliates, whether
        presented through the Website or a third-party platform.
    </p>
    <h3 class="text-title text-uppercase text-underline">DISCLOSURE AND CONSENT APPLICABLE ONLY TO CONSUMERS</h3>
    <p>
        The following terms also apply to you if you are a “consumer” as defined under the Electronic Signatures
        in Global and National Commerce Act, or as otherwise required by law:
    </p>
    <h4 class="text-title text-underline">Hardware and Software Requirements</h4>
    <p>
        In order to access and retain electronic Communications, you will need the following: access to a
        computer hardware and software: a computer with an Internet connection; current “web browser” that
        includes 128-bit encryption with cookies enabled; a current version of Adobe Acrobat Reader or other
        pdf reader software to open documents in pdf format; a valid email address; and sufficient storage
        space to save past Communications or an installed printer to print them.
    </p>
    <p>
        Aya will notify you if there are any material changes to the hardware or software needed to receive
        electronic Communications from Aya. By giving your consent, you are confirming that you have access to
        the necessary equipment and are able to receive, open, and print or download a copy of any
        Communications for your records. You may print or save a copy of these Communications for your records
        as they may not be accessible online at a later date, or where required by law Aya will retain copies
        and provide you with copies to upon written request.
    </p>
    <h4 class="text-title text-underline">How to Withdraw Your Consent</h4>
    <p>
        You have the right to withdraw your consent at any time. To withdraw consent, you may send a request by
        email to <a href="mailto:legalprocess@ayahealthcare.com">legalprocess@ayahealthcare.com</a>. If consent
        is withdrawn, Aya reserves the right to discontinue your access to the Website.
    </p>
    <h4 class="text-title text-underline">Requesting Paper Copies of Electronic Communications</h4>
    <p>
        If, after you consent to receive Communications electronically, you would like a paper copy of a
        Communication, you may request a copy within one hundred eighty (180) days (or within any longer time
        period where required by law) of the date Aya provided the Communication to you by contacting Aya at <a href="mailto:Ayait@ayahealthcare.com">Ayait@ayahealthcare.com</a>. Aya will send a paper copy to
        you by U.S. mail at the current street address on file with Aya as your primary mailing address. If you
        request paper copies from Aya, Aya may charge you at the current rate for copies.
    </p>
    <h4 class="text-title text-underline">Updating Your Contact Information</h4>
    <p>
        You are responsible for keeping your primary email address on file with Aya up to date so that Aya can
        communicate with you electronically. You understand and agree that if Aya sends you an electronic
        Communication but you do not receive it because your primary email address on file is incorrect, out of
        date, blocked by your internet service provider, or you are otherwise unable to receive electronic
        Communications, Aya still will be deemed to have provided the Communication to you, to the extent
        permitted by applicable law. If you use a spam filter or other technology that blocks or re-routes
        emails from senders not listed in your email address book, you must add Aya to your email address book
        so that you will be able to receive the Communications Aya sends to you.
    </p>
    <p>
        You can update your primary email address or street address at any time by sending the updated
        information to Aya by email to <a href="mailto:Ayait@ayahealthcare.com">Ayait@ayahealthcare.com</a> or
        through the Website. If your email address becomes invalid such that electronic Communications sent to
        you by Aya are returned, Aya reserves the right to discontinue your access to the Website.
    </p>
    <p>If you have any questions about this Consent, please contact <a href="mailto:legalprocess@ayahealthcare.com">legalprocess@ayahealthcare.com</a>.</p>
</div>
`;
