import { Action, createReducer, on } from '@ngrx/store';
import { SortTypes } from 'src/app/shared/models';
import { setDashboardDraftInvoicesSearchQuery, setInvoicesSearchQuery } from 'src/app/invoices/store/invoices.actions';
import { GridSearchQuery } from 'src/app/shared/grid/models';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

export interface InvoicesQueryState extends GridSearchQuery {}

const initialState: InvoicesQueryState = {
    sort: [{ field: 'date', dir: SortTypes.DESC }],
    filter: { logic: 'and', filters: [] },
    take: 10,
    skip: 0
};

export function invoicesQueryReducer(state: InvoicesQueryState, action: Action) {
    return createReducer(
        initialState,
        on(setInvoicesSearchQuery, (state, action) => ({
            ...state,
            ...action.query
        })),
        on(setDashboardDraftInvoicesSearchQuery, (state, action) => {
            const mapFields = {
                facilityId: 'facilityId'
            };

            const filters = Object.keys(mapFields)
                .filter((key) => key in action && action[key] > 0)
                .map((key) => ({
                    field: mapFields[key],
                    operator: 'eq',
                    value: Array.isArray(action[key]) || isNaN(action[key]) ? action[key] : Number(action[key])
                }));

            return {
                ...state,
                filter: {
                    logic: 'and',
                    filters: filters
                } as CompositeFilterDescriptor
            };
        })
    )(state, action);
}

export function reducer(state: InvoicesQueryState | undefined, action: Action) {
    return invoicesQueryReducer(state, action);
}
